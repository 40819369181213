import React from 'react'

export const PdfIcon = ({ width, height, color }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" cursor="pointer" version="1.1" width={width ? width : "20"} height={height ? height : "20"} x="0" y="0" viewBox="0 0 43.917 43.916" >
      <g>
        <g xmlns="https://www.w3.org/2000/svg">
          <path d="M12.745,10.14h-1.563V7.397h1.524c0.987,0,1.603,0.474,1.603,1.358V8.78C14.309,9.55,13.73,10.14,12.745,10.14z    M23.976,10.126v-0.025c0-1.59-1.088-2.704-2.703-2.704h-1.525v5.408h1.525C22.888,12.805,23.976,11.716,23.976,10.126z M39.394,5   v33.916c0,2.761-2.237,5-5,5H9.522c-2.762,0-5-2.239-5-5V5c0-2.761,2.238-5,5-5h24.871C37.154,0,39.394,2.239,39.394,5z    M17.772,13.6c0,0.551,0.437,0.987,0.987,0.987h2.512c2.819,0,4.769-1.961,4.769-4.486v-0.025c0-2.525-1.947-4.46-4.769-4.46   h-2.512c-0.551,0-0.987,0.436-0.987,0.987V13.6z M9.208,13.676c0,0.552,0.436,0.987,0.987,0.987c0.551,0,0.986-0.436,0.986-0.987   v-1.78h1.5c2.012,0,3.626-1.076,3.626-3.152V8.717c0-1.832-1.294-3.101-3.435-3.101h-2.678c-0.552,0-0.987,0.436-0.987,0.987   L9.208,13.676L9.208,13.676z M34.708,36.301c0-1.104-0.896-2-2-2h-21.5c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h21.5   C33.812,38.301,34.708,37.405,34.708,36.301z M34.708,28.801c0-1.104-0.896-2-2-2h-21.5c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2   h21.5C33.812,30.801,34.708,29.905,34.708,28.801z M34.708,21.301c0-1.104-0.896-2-2-2h-14c-1.104,0-2,0.896-2,2   c0,1.104,0.896,2,2,2h14C33.812,23.301,34.708,22.405,34.708,21.301z M34.708,6.513c0-0.5-0.396-0.897-0.896-0.897h-5.024   c-0.552,0-0.985,0.436-0.985,0.987v7.073c0,0.552,0.436,0.987,0.985,0.987c0.551,0,0.986-0.436,0.986-0.987v-2.562h3.46   c0.5,0,0.897-0.397,0.897-0.897S33.734,9.32,33.234,9.32h-3.46V7.41h4.037C34.311,7.409,34.708,7.013,34.708,6.513z" fill={color ? color : "#213265"} data-original="#000000" />
        </g>
      </g>
    </svg>
  )
}
