import { Fragment, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Rating from 'react-rating';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';


export const IdentificacionRiesgos = () => {

    const [fileInputs, setFileInputs] = useState(false)


    const [calificaciones, setCalificaciones] = useState({
        gestionAutoridadesLocales: 1,
        ordenPublico: 1,
        presenciaInfluenciaFP: 1,
        presenciaInfluenciaAML: 1,
        indiceAceptacionAL: 1,
        indiceAceptacionFP: 1,
        historicoPosicionPolitica: 1
    });

    const [calificacionesImpacto, setCalificacionesImpacto] = useState({
        gestionAutoridadesLocales: 1,
        ordenPublico: 1,
        presenciaInfluenciaFP: 1,
        presenciaInfluenciaAML: 1,
        indiceAceptacionAL: 1,
        indiceAceptacionFP: 1,
        historicoPosicionPolitica: 1
    });

    /*    const handleChange = (e) => {
           console.log(e)
       } */

    /*   const handleFile = (e) => {
          console.log(e)
          setFileInputs(true)
      } */


    const handleRate = (rate, name) => {
        setCalificaciones({
            ...calificaciones,
            [name]: rate
        })

    }


    const hanldeRateImpacto = (rate, name) => {
        setCalificacionesImpacto({
            ...calificacionesImpacto,
            [name]: rate
        });
    }


    // console.log(calificacionesImpacto)

    return (
        <Fragment>
            <h5 className='titleInsideCard'>Identificación de riesgos</h5>
            <Card.Body>


                <div className='formEstudioBasicoContainer' >
                    <div className='formItem' id='basicFormA' style={{ display: 'flex', justifyContent: 'center' }}>
                        <h3 style={{ textAlign: 'center', color: "#707070", margin: '0 auto 0 10px' }}>Probabilidad</h3>
                        <br />
                        <Form.Group >
                            <Form.Label>
                                Gestión de las Autoridades Locales
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    initialRating={calificaciones.gestionAutoridadesLocales}
                                    onChange={(rate) => handleRate(rate, 'gestionAutoridadesLocales')}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificaciones.gestionAutoridadesLocales}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Orden Público
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    initialRating={calificaciones.ordenPublico}
                                    onChange={(rate) => handleRate(rate, 'ordenPublico')}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificaciones.ordenPublico}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Presencia e influencia de la Fuerza Pública en el territorio
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => handleRate(rate, 'presenciaInfluenciaFP')}

                                    initialRating={calificaciones.presenciaInfluenciaFP}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificaciones.presenciaInfluenciaFP}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Presencia e influencia de actores al margen de la Ley (GAO-GDO-GAOR)
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating
                                    onChange={(rate) => handleRate(rate, 'presenciaInfluenciaAML')}
                                    initialRating={calificaciones.presenciaInfluenciaAML}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificaciones.presenciaInfluenciaAML}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Índice de aceptación de las autoridades locales
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => handleRate(rate, 'indiceAceptacionAL')}
                                    initialRating={calificaciones.indiceAceptacionAL}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificaciones.indiceAceptacionAL}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Índice de aceptación de la Fuerza Pública
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => handleRate(rate, 'indiceAceptacionFP')}
                                    initialRating={calificaciones.indiceAceptacionFP}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificaciones.indiceAceptacionFP}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Histórico de posición política de los alcaldes en cuanto al sector de hidrocarburos
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => handleRate(rate, 'historicoPosicionPolitica')}
                                    initialRating={calificaciones.historicoPosicionPolitica}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificaciones.historicoPosicionPolitica}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>



                        {/* <Form.Group >
                            <Form.Label>Fuerza Pública</Form.Label>
                            <div className='inputContainer'>
                                <Form.Control
                                    name="politico"
                                    onChange={handleChange}
                                    required={true}
                                    as='select'
                                    type='text'
                                >

                                    <option value="0">
                                        Seleccione
                                        </option>

                                    <option value="armada">
                                        Armada
                                        </option>

                                    <option value="policia">
                                        Polícia
                                    </option>
                                    <option value="ejercito">
                                        Ejército
                                    </option>
                                    <option value="fuerza_aerea">
                                        Fuerza Aérea
                                    </option>

                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Fecha
                            </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Link
                                    </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='link' required type='text' placeholder='Link' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Fuente</Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='fuente' required type='text' placeholder='Fuente' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa una fuente</Form.Control.Feedback>
                            </div>
                        </Form.Group>
 */}



                    </div>
                    <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>



                        <h3 style={{ textAlign: 'center', color: "#707070", margin: '0 auto 0 10px' }}>Impacto</h3>
                        <br />
                        <Form.Group >
                            <Form.Label>
                                Gestión de las Autoridades Locales
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    initialRating={calificacionesImpacto.gestionAutoridadesLocales}
                                    onChange={(rate) => hanldeRateImpacto(rate, 'gestionAutoridadesLocales')}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificacionesImpacto.gestionAutoridadesLocales}</b>

                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Orden Público
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    initialRating={calificacionesImpacto.ordenPublico}
                                    onChange={(rate) => hanldeRateImpacto(rate, 'ordenPublico')}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificacionesImpacto.ordenPublico}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Presencia e influencia de la Fuerza Pública en el territorio
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => hanldeRateImpacto(rate, 'presenciaInfluenciaFP')}

                                    initialRating={calificacionesImpacto.presenciaInfluenciaFP}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificacionesImpacto.presenciaInfluenciaFP}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Presencia e influencia de actores al margen de la Ley (GAO-GDO-GAOR)
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => hanldeRateImpacto(rate, 'presenciaInfluenciaAML')}
                                    initialRating={calificacionesImpacto.presenciaInfluenciaAML}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificacionesImpacto.presenciaInfluenciaAML}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Índice de aceptación de las autoridades locales
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => hanldeRateImpacto(rate, 'indiceAceptacionAL')}
                                    initialRating={calificacionesImpacto.indiceAceptacionAL}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificacionesImpacto.indiceAceptacionAL}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Índice de aceptación de la Fuerza Pública
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => hanldeRateImpacto(rate, 'indiceAceptacionFP')}
                                    initialRating={calificacionesImpacto.indiceAceptacionFP}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificacionesImpacto.indiceAceptacionFP}</b>

                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Histórico de posición política de los alcaldes en cuanto al sector de hidrocarburos
                            </Form.Label>
                            <div style={{ display: 'flex', width: '90%' }}>
                                <Rating

                                    onChange={(rate) => hanldeRateImpacto(rate, 'historicoPosicionPolitica')}
                                    initialRating={calificacionesImpacto.historicoPosicionPolitica}
                                    stop={5}
                                    fullSymbol={<RadioButtonCheckedIcon style={{ color: '#213265' }} />}
                                    emptySymbol={<RadioButtonUncheckedIcon style={{ color: '#213265' }} />}
                                    style={{ margin: '0 auto' }}
                                />
                                <b>{calificacionesImpacto.historicoPosicionPolitica}</b>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>



                        {/*  <Form.Group>
                            <Form.Label>
                                Archivos soporte
                                    </Form.Label>
                            <div className='addButtonContainer' style={{ cursor: 'pointer', width: '100%', marginTop: '-25px', paddingTop: '0px' }} >
                                <input id='selectFile' className='selectFile' name='selectFile' type='file' onChange={handleFile} style={{ cursor: 'pointer' }} />
                                <label id='addButton' htmlFor='selectFile' style={{ cursor: 'pointer' }} >
                                    Añadir documento
                                <AddIcon />
                                </label>
                            </div>

                        </Form.Group>

                        {
                            fileInputs
                                ?
                                <Fragment>
                                    <Form.Group>
                                        <Form.Label>
                                            Fecha documento
                                    </Form.Label>
                                        <div className='inputContainer'>

                                            <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                    <Form.Group id='observacionesFormGroup'>
                                        <Form.Label className='observacionesLabel'>
                                            Descripción archivo
                                    </Form.Label>
                                        <div className='textAreaContainer'>
                                            <Form.Control name='descripcionFile' id='observacionesTextArea' maxLength={2000} style={{ height: '50px' }} required as='textarea' placeholder='Observaciones'></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa la descripcion</Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </Fragment>
                                : <Form.Group id='observacionesFormGroup'>
                                    <Form.Label className='observacionesLabel'>
                                        Descripción
                                    </Form.Label>
                                    <div className='textAreaContainer'>
                                        <Form.Control onChange={handleChange} style={{ height: '70px' }} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                        } */}

                    </div>

                    {/*   {
                        fileInputs
                        &&
                        <div id='observacionesContainerEstudioBasico'>
                            <Form.Group id='observacionesFormGroup'>
                                <Form.Label className='observacionesLabel'>
                                    Descripción
                                    </Form.Label>
                                <div className='textAreaContainer'>
                                    <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    } */}
                </div>
            </Card.Body>
        </Fragment>
    )
}
