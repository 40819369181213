import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { Col, Row, Nav } from 'react-bootstrap';
import { validarEntorno } from '../../../../helpers/validarHost';
import FormFactores from './factoresMapa/FormFactores';
import FormFuerzas from './factoresMapa/FormFuerzas';
import FormAmenazas from './factoresMapa/FormAmenazas';

import './styles/Factores.scss'
import { getKeyObject } from '../../../../helpers/textHelpers';

export const Factores = (props) => {

    const {id} = useParams()

    // console.log(id);

    const hash = window.location.hash

    const [permisos, setPermisos] = useState({})
    const [activeFormKey, setActiveFormKey] = useState(1);
    const [areaOpen, setAreaOpen] = useState(1);
    const [secciones, setSecciones] = useState([]);
    const [dataSelect, setDataSelect] = useState([]);
    const [dataSecondSelect, setDataSecondSelect] = useState([]);
    const [dataZonasSelect, setDataZonasSelect] = useState([]);
    const [factor, setFactor ] = useState(1)
    // const [poligono, setPoligono ] = useState({})
    // const [infoSeccionFactor, setInfoSeccionFactor ] = useState({})



    const [actividades, setActividades] = useState([])

    const industrial = [
        {
            "id": 1,
            "valor": "Hidrocarburos"
        },
        {
            "id": 2,
            "valor": "Minera"
        },
        {
            "id": 3,
            "valor": "Energética"
        }
    ];

    const subsecciones = [
        {
            "id": 1,
            "nombre": "Social"
        },
        {
            "id": 2,
            "nombre": "Político"
        },
        {
            "id": 3,
            "nombre": "Económico"
        },
        {
            "id": 4,
            "nombre": "Fuerza disponible"
        },
        {
            "id": 5,
            "nombre": "Amenazas"
        }
    ];

    useEffect(() => {
        getSecciones(1);
    }, [])

    useEffect(() => {
        getFactoresSelect();
        getZonasSelect();
        getDepartamentosSelect();
        setFactor(activeFormKey)
    }, [activeFormKey])


    const eventFormIdValue = (value) => {

        //console.log(value)

        getSecciones( value.id_seccion );
        const { llave } = value;
        getFactoresSecondSelect(parseInt(value.id_seccion), llave, areaOpen);
        setActiveFormKey(parseInt(value.id_seccion));
    }

    const getFactoresSecondSelect = async (value, key, iden) => {

        const dataReq = {
            hash: hash,
            id: value
        }

        let req = '';

        switch (iden) {
            case 1:
                req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/FactorSocial/factor_social.html', 'POST', dataReq);
                break;
            case 2:
                req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/FactorPolitico/factor_politico.html', 'POST', dataReq);
                break;
            case 3:
                req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/FactorEconomico/factor_economico.html', 'POST', dataReq);
                break;
            case 4:
                req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/FactorFuerza/factor_fuerza.html', 'POST', dataReq);
                break;
            case 5:
                req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/FactorAmenazas/factor_amenazas.html', 'POST', dataReq);
                break;
            default:
                break;
        }

        if (req.ok) {

            const res = await req.json();

            setActividades(res.actividades)

            // console.log(actividades)

            const dataFilter = res[key];

            //console.log(dataFilter);

            // console.log(dataFilter)

            setDataSecondSelect(dataFilter);

            // console.log(dataSecondSelect)
        }
    }

    const getFactoresSelect = async () => {
        const dataReq = {
            hash: hash,
            id: id,
            id_item: activeFormKey
        }

        // console.log(dataReq)

        const req = await validarEntorno('JSON/panoramaRiesgos/factores_select.html', 'POST', dataReq);
        const res = await req.json();

        //console.log(res)

        if (req.ok) {

            const dataFilter = res?.lista?.filter((item) => {
                return item.id_seccion === activeFormKey;
            });

            setDataSelect( dataFilter);

        }
    }

    const getZonasSelect = async () =>  {
        if (activeFormKey === "10") {

            const dataReq = {
                hash: hash
            }

            const req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/FactorSocial/zona_programas.html', 'POST', dataReq);

            if (req.ok) {
                const res = await req.json();

                setDataZonasSelect(res.data);
            }
        } else {
            setDataZonasSelect([]);
        }
    }

    const getDepartamentosSelect = async () =>  {
        if (activeFormKey === 3) {

            const dataReq = {
                hash: hash
            }

            const req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/FactorSocial/departamentos.html', 'POST', dataReq);

            if (req.ok) {
                const res = await req.json();

                setDataZonasSelect(res.data);
            }
        } else {
            setDataZonasSelect([]);
        }
    }

    const getSecciones = async (identificador) => {

        // console.log(typeof(identificador))

        const dataReq = {
            hash: hash
        }

        const req = await validarEntorno('JSON/panoramaRiesgos/get_secciones_componente.html', 'POST', dataReq)

        if(req.ok){
            const res = await req.json()

            setPermisos({...permisos, registros: res.permisos.registros, actividades: res.permisos.actividades})

            switch (identificador) {
                case 1:
                    setSecciones(res.social)
                    setAreaOpen(parseInt(identificador));
                    setActiveFormKey(parseInt(res.social[0].id_seccion));
                    getFactoresSecondSelect(res.social[0].id_seccion, res.social[0].llave, parseInt(identificador));
                    break;
                case 2:
                    setSecciones(res.politico)
                    setAreaOpen(parseInt(identificador));
                    setActiveFormKey(parseInt(res.politico[0].id_seccion));
                    getFactoresSecondSelect(res.politico[0].id_seccion, res.politico[0].llave, parseInt(identificador));
                    break;
                case 3:
                    setSecciones(res.economico)
                    setAreaOpen(parseInt(identificador));
                    setActiveFormKey(parseInt(res.economico[0].id_seccion));
                    getFactoresSecondSelect(res.economico[0].id_seccion, res.economico[0].llave, parseInt(identificador));
                    break;
                case 4:
                    setSecciones(res.fuerza_disponible)
                    setAreaOpen(parseInt(identificador));
                    setActiveFormKey(parseInt(res.fuerza_disponible[0].id_seccion));
                    getFactoresSecondSelect(res.fuerza_disponible[0].id_seccion, res.fuerza_disponible[0].llave, parseInt(identificador));
                    break;
                case 5:
                    setSecciones(res.amenazas)
                    setAreaOpen(parseInt(identificador));
                    setActiveFormKey(parseInt(res.amenazas[0].id_seccion));
                    getFactoresSecondSelect(res.amenazas[0].id_seccion, res.amenazas[0].llave, parseInt(identificador));
                    break;
                default:
                    break;
            }
        }
    }

    const eventIdValue = async (value) => {

        setFactor(value)

        if(areaOpen !== value) {
            setAreaOpen(value);
            getSecciones(value);
        }
    }

    // const getRegistrosSeccion = async (item) => {

    //     console.log(item)

    //     const dataReq = {
    //         hash: window.location.hash,
    //         id: id,
    //         id_factor: factor,
    //         id_seccion: item?.id_seccion
    //     }

    //     console.log(dataReq)

    //     const req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/registros_seccion_factor.html', 'POST', dataReq)

    //     if(req.ok){
    //         const res = await req.json()
    //         setPoligono(res.ubicacion);
    //         setInfoSeccionFactor(res)

    //         // setInitialState({
    //         //     ...initialState,
    //         //     loading: false,
    //         //     error: null,
    //         //     data: res
    //         // })

    //     }
    // }


    return (
        <div>
            <Row className="" >
                    <div className='bodyWidgets' style={{marginBottom:'20px'}}>
                        {subsecciones.length > 0 && subsecciones.map((item, index) => {
                            return(
                                <div
                                    style={{ borderBottom: areaOpen === item.id ? '8px solid #E4A731' : '8px solid #213265' }}
                                    className='shadow widgetFactores '
                                    key={item.id}
                                    onClick={() => eventIdValue(item.id)}
                                    eventKey={item.id}
                                >
                                    <p>{item.nombre}</p>
                                    <p className='widgetPorcentaje'>{item.porcentaje}</p>
                                </div>
                            )
                            })
                        }
                    </div>
                    <hr style={{color: 'gray', width:'90%', margin:'0 auto', marginBottom:'20px'}}/>
                <Col
                    xl={2}  lg={3} md={4} xs={0} id="sidebar-wrapper"
                    className="shadow p-2 flex-column nav-items"
                >
                    <Nav
                        defaultActiveKey={activeFormKey}
                        activeKey={activeFormKey}
                        className=" p-2 flex-column nav-items"
                    >
                        {secciones.map((item) => (
                            <Nav.Link
                                className="item-a"
                                eventKey={item.id_seccion}
                                onClick={() => {
                                    eventFormIdValue(item);
                                    getFactoresSelect();
                                    // getRegistrosSeccion(item)
                                }}
                                key={item.id_seccion}
                            >
                                {item.valor}
                            </Nav.Link>
                        ))}
                    </Nav>
                </Col>
                <Col xl={10} lg={9} md={8} xs={12} id="page-content-wrapper">
                    { areaOpen <= 3  ?
                        (<FormFactores
                            // getRegistrosSeccion={getRegistrosSeccion}
                            // poligono={poligono}
                            // dataSeccion={infoSeccionFactor}
                            factor={areaOpen} idPanorama={id} permisos={permisos} actividades={actividades} idSubseccion={activeFormKey} dataSelect={dataSelect} dataSelect2={dataSecondSelect} dataZonasSelect={dataZonasSelect} />)
                            : (areaOpen === 4 ? <FormFuerzas factor={areaOpen} permisos={permisos} idPanorama={id} idSubseccion={activeFormKey} dataSelect={dataSelect} dataSelect2={dataSecondSelect} /> :
                                (areaOpen === 5 ? <FormAmenazas factor={areaOpen} permisos={permisos} idPanorama={id} idSubseccion={activeFormKey} dataSelect={dataSelect} dataSelect2={dataSecondSelect} /> : null))
                    }
                </Col>
            </Row>
            <br/>

        </div>
    )
}
