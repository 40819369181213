import React from 'react'
import { Check, Close, Info } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import './ValidacionContraseña.scss';

export default function ValidacionContrasena({ longitudValida, minusculasMayusculas, numericos, especiales, sinEspaciosVacios, formularioValido, coinciden, cambioInicial }) {
  const { t } = useTranslation();

  return (
    <div className='contenedor__condiciones'>
      <div className='condiciones__header'>
        <p> {t('formulario.label.contra_condiciones')}</p>
      </div>
      <div className='condiciones__item'>
        <p className={`${longitudValida ? 'condicion__text--valido' : (cambioInicial ? 'condicion__text--invalido' : 'condicion__text--info')}`}>
          {t('formulario.label.pwd_longitud')}</p>
          {cambioInicial ? (longitudValida ? <Check className='icono__valido' /> : <Close className='icono__invalido' />) : null}
      </div>
      <div className='condiciones__item'>
        <p className={`${minusculasMayusculas ? 'condicion__text--valido' : (cambioInicial ? 'condicion__text--invalido' : 'condicion__text--info')}`}>
          {t('formulario.label.contra_mayusculas')}
        </p>
      {cambioInicial ? (minusculasMayusculas ? <Check className='icono__valido' /> : <Close className='icono__invalido' />) : null}
      </div>
      <div className='condiciones__item'>
        <p className={`${numericos ? 'condicion__text--valido' : (cambioInicial ? 'condicion__text--invalido' : 'condicion__text--info')}`}>
          {t('formulario.label.pwd_numericos')}</p>
        {cambioInicial ? (numericos ? <Check className='icono__valido' /> : <Close className='icono__invalido' />) : null}
      </div>
      <div className='condiciones__item'>
        <p className={`${especiales ? 'condicion__text--valido' : (cambioInicial ? 'condicion__text--invalido' : 'condicion__text--info')}`}>
          {t('formulario.label.pwd_especiales')}</p>
        {cambioInicial ? (especiales ? <Check className='icono__valido' /> : <Close className='icono__invalido' />) : null}
      </div>
      <div className='condiciones__item'>
        <p className={`${cambioInicial ? (sinEspaciosVacios ? 'condicion__text--valido' : 'condicion__text--invalido') : 'condicion__text--info'}`}>
          {t('formulario.label.pwd_espacios_blanco')}</p>
        {cambioInicial ? (sinEspaciosVacios ? <Check className='icono__valido' /> : <Close className='icono__invalido' />) : null}
      </div>
      <div className='condiciones__item'>
        <p className={`${cambioInicial ? (coinciden ? 'condicion__text--valido' : 'condicion__text--invalido') : 'condicion__text--info'}`}>
          {t('formulario.label.pwd_coinciden')}</p>
        {cambioInicial ? (coinciden ? <Check className='icono__valido' /> : <Close className='icono__invalido' />) : null}
      </div>
      <div className='condiciones__item'>
        <p className={'condicion__text--info'}>{t('formulario.label.pwd_anterior')}</p>
        <Info className='icono__info' />
      </div>
    </div>
  )
}
