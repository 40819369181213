import { useState } from "react";
import { Form } from "react-bootstrap";
import { BtnAyuda } from "./BtnAyuda";


const Textarea = ({ item, handleInputChange, index, idx, reg, nombre, error, esEditable }) => {

  const [text, setText] = useState('');

  const handleChangeText = (e) => {
    const { value } = e.target;
    setText(value);
  }

  return (
    <Form.Group style={{ display: 'block' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
        <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : "" }} >{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
        {item.ayuda &&
          <BtnAyuda ayuda={item.ayuda} />
        }
      </div>
      <console className="log"></console>
      <textarea
        className="form-control"
        defaultValue={item.valor}
        name={`asesoria.${index}.${item.nombre}`}
        key={`${index} - ${idx}`}
        ref={reg}
        {...reg(`asesoria.${index}.${item.nombre}`, {
          required: item.es_requerido
        })}
        style={{ border: error ? '1px solid #dc3545' : null, height: '100px' }}
        disabled={esEditable}
        onChange={(e) => { handleChangeText(e) }}
      />
      {error ?
        (
          error.type === 'required' ?
            (
              <span className="inputsInvalid">
                Este campo es requerido
              </span>
            )
            : null)
        : null}
    </Form.Group>
  );

}

export default Textarea;
