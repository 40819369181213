import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom';


import { Card } from 'react-bootstrap'
import { HelpSVG } from '../../../components/icons/HelpSVG'
import GetAppIcon from '@material-ui/icons/GetApp';

import PopUpModal from '../../../helpers/PopUpModal'
// import { StoredColumns } from '../../../pages/gerenciaGeneral/Investigaciones/InvestigacionesAdministrativas/GraficasReportes/StoredColumns'
// import RadarColumns from '../../../pages/gerenciaGeneral/Investigaciones/InvestigacionesAdministrativas/GraficasReportes/RadarColumns'
// import FunnelReportes from '../../../pages/gerenciaGeneral/Investigaciones/InvestigacionesAdministrativas/GraficasReportes/FunnelReportes'
// import { datosDivergentes } from '../../../helpers/datosDivergentes';


import './DashboardReportes.scss'
// import { RadiusDonut1 } from '../../../components/dashboard/RadiusDonut1';
// import { MediaTorta } from '../../../components/dashboard/MediaTorta';
// import FunnelChart from '../../../components/dashboard/FunnelChart';
import DivergentChartAmplia from '../../../components/dashboard/DivergentChartAmplia';
import HerraduraExpandible from '../../../components/dashboard/HerraduraExpandible';
// import FunnelGradient from '../../../components/dashboard/FunnelGradient';

// import DivergentChart from '../../../components/dashboard/Divergente'
// import { FullDonut3D } from '../../../components/dashboard/FullDonut3D';
import DonaDosniveles from '../../../components/dashboard/DonaDosniveles'
import SortedBarChart from '../../../components/dashboard/SortedBarChart'
import Dona2 from '../../../components/dashboard/Dona2'

import { obtenerDataSegunFiltro } from '../../../reducers/filtroTipoEstudio';

export default function DashboardReportes(props) {

  // const idReporte = props.match.params.idInforme

  const history = useHistory()

  const dispatch = useDispatch()

  let { id } = useParams();

  const dataReportes = useSelector(store => store.reportesReducer)

  console.log(dataReportes);

  const { reporteGenerado } = dataReportes

  // console.log(reporteGenerado)

  // const [desplegarTabla, setDesplegarTabla] = useState(false)

  const [tablaMostrada, setTablaMostrada] = useState({
    showTable: false,
    idTable: ''
  })


  const [open, setOpen] = useState(false)



  return (
    <>
      <div className='titleContainer'>
        <h3 className='pagesTitles'>Panel de reporte</h3>

      </div>
      <br />

      <Card className="shadow panelFull cardGraficaGeneral cardReporte">
        <div
          className='botonesAyuda'
          onClick={() => setOpen(true)}
        >
          <HelpSVG

          />
        </div>
        <Card.Body>

          {/* {reporteGenerado?.id === 'investigaciones_administrativas' &&
          <>

          </>

        } */}
          {reporteGenerado
            ?
            <>
              <h2 className='pagesTitles'>{reporteGenerado.titulo_reporte}</h2>
              <br />
              <br />
              <h5 style={{ textAlign: 'justify' }} className='titleCard'>{reporteGenerado.descripcion_reporte}</h5>
              <br />
              <br />
              {reporteGenerado?.graficas?.map((grafica) => {
                return (
                  <>
                    {grafica?.tipo === 'dona'
                      ?
                      <>
                        <Dona2
                          data={grafica.data_grafica}
                          title={grafica.titulo}
                          total={grafica.total}
                          id={grafica.id}
                        />
                        <br />
                        {grafica.data_tabla &&
                          <>
                            <div className='botonesGraficasReportes'>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button className='buttonGenerarReporte'
                                  onClick={() => setTablaMostrada({ showTable: !tablaMostrada.showTable, idTable: grafica.data_tabla.idTabla })}
                                > {
                                    tablaMostrada.showTable === true && tablaMostrada.idTable === grafica.data_tabla.idTabla
                                      ? 'Ocultar tabla'
                                      : 'Mostrar tabla'
                                  }

                                </button>
                              </div>
                              <br />
                              <div className='contenedorBotonGenerarReporte'>
                                <a href={`https://ciacosinte.site/crm/upload/descargar_reporte.html?id=${id}`} target='_blank' rel='noreferrer'>
                                  <button className='buttonGenerarReporte'>
                                    Descargar
                                    <GetAppIcon />
                                  </button>
                                </a>
                              </div>
                              <br />
                            </div>
                            <br />

                          </>
                        }
                      </>
                      : grafica?.tipo === 'barras_horizontales'
                        ?
                        <>
                          <SortedBarChart
                            data={grafica.data_grafica}
                            title={grafica.titulo}
                            total={grafica.total}
                            id={grafica.id}
                          />
                          <br />
                          {grafica.data_tabla &&
                            <>
                              <div className='botonesGraficasReportes'>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <button className='buttonGenerarReporte'
                                    onClick={() => setTablaMostrada({ showTable: !tablaMostrada.showTable, idTable: grafica.data_tabla.idTabla })}
                                  > {
                                      tablaMostrada.showTable === true && tablaMostrada.idTable === grafica.data_tabla.idTabla
                                        ? 'Ocultar tabla'
                                        : 'Mostrar tabla'
                                    }

                                  </button>
                                </div>
                                <br />
                                <div className='contenedorBotonGenerarReporte'>
                                  <a href={`https://ciacosinte.site/crm/upload/descargar_reporte.html?id=${id}`} target='_blank' rel='noreferrer'>
                                    <button className='buttonGenerarReporte'>
                                      Descargar
                                      <GetAppIcon />
                                    </button>
                                  </a>
                                </div>
                                <br />
                              </div>
                              <br />

                            </>
                          }
                        </>
                        : grafica?.tipo === 'torta3d'
                          ?
                          <>
                            <DonaDosniveles
                              data={grafica.data_grafica}
                              title={grafica.titulo}
                              total={grafica.total}
                              id={grafica.id}
                            />
                            <br />
                            {grafica.data_tabla &&
                              <>
                                <div className='botonesGraficasReportes'>
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button className='buttonGenerarReporte'
                                      onClick={() => setTablaMostrada({ showTable: !tablaMostrada.showTable, idTable: grafica.data_tabla.idTabla })}
                                    > {
                                        tablaMostrada.showTable === true && tablaMostrada.idTable === grafica.data_tabla.idTabla
                                          ? 'Ocultar tabla'
                                          : 'Mostrar tabla'
                                      }

                                    </button>
                                  </div>
                                  <br />
                                  <div className='contenedorBotonGenerarReporte'>
                                    <a href={`https://ciacosinte.site/crm/upload/descargar_reporte.html?id=${id}`} target='_blank' rel='noreferrer'>
                                      <button className='buttonGenerarReporte'>
                                        Descargar
                                        <GetAppIcon />
                                      </button>
                                    </a>
                                  </div>
                                  <br />
                                </div>
                                <br />

                              </>
                            }
                          </>
                          : grafica?.tipo === 'herradura_expandible'
                            ?
                            <>
                              <HerraduraExpandible
                                data={grafica.data_grafica}
                                title={grafica.titulo}
                                total={grafica.total}
                                id={grafica.id}
                              />
                              <br />
                              {grafica.data_tabla &&
                                <>
                                  <div className='botonesGraficasReportes'>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                      <button className='buttonGenerarReporte'
                                        onClick={() => setTablaMostrada({ showTable: !tablaMostrada.showTable, idTable: grafica.data_tabla.idTabla })}
                                      > {
                                          tablaMostrada.showTable === true && tablaMostrada.idTable === grafica.data_tabla.idTabla
                                            ? 'Ocultar tabla'
                                            : 'Mostrar tabla'
                                        }

                                      </button>
                                    </div>
                                    <br />
                                    <div className='contenedorBotonGenerarReporte'>
                                      <a href={`https://ciacosinte.site/crm/upload/descargar_reporte.html?id=${id}`} target='_blank' rel='noreferrer'>
                                        <button className='buttonGenerarReporte'>
                                          Descargar
                                          <GetAppIcon />
                                        </button>
                                      </a>
                                    </div>
                                    <br />
                                  </div>
                                  <br />

                                </>
                              }
                            </>
                            : grafica?.tipo === 'divergente'
                              ?
                              <>
                                <DivergentChartAmplia
                                  data={grafica.data_grafica}
                                  title={grafica.titulo}
                                  total={grafica.total}
                                  id={grafica.id}
                                  limite={grafica.limite}
                                />
                                <br />
                                {grafica.data_tabla &&
                                  <>
                                    <div className='botonesGraficasReportes'>
                                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button className='buttonGenerarReporte'
                                          onClick={() => setTablaMostrada({ showTable: !tablaMostrada.showTable, idTable: grafica.data_tabla.idTabla })}
                                        > {
                                            tablaMostrada.showTable === true && tablaMostrada.idTable === grafica.data_tabla.idTabla
                                              ? 'Ocultar tabla'
                                              : 'Mostrar tabla'
                                          }

                                        </button>
                                      </div>
                                      <br />
                                      <div className='contenedorBotonGenerarReporte'>
                                        <a href={`https://ciacosinte.site/crm/upload/descargar_reporte.html?id=${id}`} target='_blank' rel='noreferrer'>
                                          <button className='buttonGenerarReporte'>
                                            Descargar
                                            <GetAppIcon />
                                          </button>
                                        </a>
                                      </div>
                                      <br />
                                    </div>
                                    <br />

                                  </>
                                }
                              </>
                              :
                              ''
                    }
                    {tablaMostrada.showTable === true && tablaMostrada.idTable === grafica.data_tabla.idTabla
                      &&
                      <>
                        <div id={grafica.data_tabla.idTabla} className="container tableContainer" >
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                {grafica?.data_tabla?.columnas.map((columna) => {
                                  return (
                                    <th key={columna.index}>{columna.label}</th>
                                  )
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {grafica?.data_tabla?.registros.map((registro) => {
                                return (
                                  <tr key={registro.index}>
                                    <td>{registro.id}</td>
                                    <td>{registro.documento}</td>
                                    <td>{registro.nombre}</td>
                                    <td>{registro.valor}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                          <button className='buttonGenerarReporte' onClick={() => {


                            dispatch(obtenerDataSegunFiltro({
                              hash: window.location.hash, reporte: {
                                id,
                                tipoReporte: 'seguimiento_etapa',
                              }
                            }))

                            history.push(`/gerencia/${reporteGenerado?.seccion}/administrar/${id}/${grafica.id}`)

                          }
                          } > Ver más </button>
                        </div>
                      </>
                    }
                    {/* <h1>{grafica.titulo}</h1> */}
                  </>
                )
              })}
            </>
            :
            <>
              <h3 calssName='titleCard' style={{ textAlign: 'center' }} >Error, no hay información en este reporte.</h3>
            </>
          }
          <br />
        </Card.Body>
      </Card>


      <PopUpModal
        titulo={reporteGenerado?.modal?.titulo}
        texto={reporteGenerado?.modal?.contenido}
        open={open}
        setOpen={setOpen}
      />
      {/* <PopUpModal open={open} setOpen={setOpen}/> */}
    </>
  )
}
