import React from 'react'
import { Card } from '../../components/ui/Card'

import { Histograma } from '../../components/dashboard/Histograma'
import Graficaconsolidado from '../../components/dashboard/GraficaConsolidado'
import SortedBarChart from '../../components/dashboard/SortedBarChart'
import Divergente from '../../components/dashboard/Divergente'
import { MediaTorta } from '../../components/dashboard/MediaTorta'
import Treemap from '../../components/dashboard/Treemap'
import Dona2 from '../../components/dashboard/Dona2'
import DonaDosniveles from '../../components/dashboard/DonaDosniveles'
import HerraduraExpandible from '../../components/dashboard/HerraduraExpandible'
import DonaConTotal from '../../components/dashboard/DonaConTotal'
import { BarrasLabelsHorizontales } from '../../components/dashboard/BarrasLabelsHorizontales'
import { RadialRangos } from '../../components/gerenciaGeneral/operaciones/graficas/RadialRangos'
import TimelineMaterial from '../../components/dashboard/TimelineMaterial'
import SaveIcon from '@material-ui/icons/Save';
import CardTextIcon from '../../components/dashboard/bloques/CardTextIcon';
import Mapa from '../../components/dashboard/graficas/Mapa';
import PieDonutText from '../../components/dashboard/graficas/PieDonutText';
import ChartLayout from '../../components/dashboard/layout/ChartLayout';
import BarFooter from '../../components/dashboard/bloques/BarFooter';
import AreaInteractive from '../../components/dashboard/bloques/AreaInteractive';
import Lista from '../../components/dashboard/bloques/Lista';
import LineHeader from '../../components/dashboard/bloques/LineHeader';
import CardProgress from '../../components/dashboard/bloques/CardProgress';
import CardBarSimple from '../../components/dashboard/bloques/CardBarSimple';
import RadialBarInfo from '../../components/dashboard/bloques/RadialBarInfo';
import AreaStacked from '../../components/dashboard/graficas/AreaStacked';
import BarMultiple from '../../components/dashboard/graficas/BarMultiple';
import HorizontalBarInfo from '../../components/dashboard/bloques/HorizontalBarInfo';
import "./ThemeGraficas.scss"
import BarChartComponent from '../../components/dashboard/graficas/Bar'
import HorizontalBarChartComponent from '../../components/dashboard/graficas/HorizontalBar'
import { BarStacked } from '../../components/dashboard/graficas/BarStacked'
import BarNegative from '../../components/dashboard/graficas/BarNegative'
import { RadarMultiple } from '../../components/dashboard/graficas/RadarMultiple'
import Tabla from '../../components/common/Tabla'

function FetchGrafica({ id, tipo, grafica }) {

  const old_chart_id = "cui__chart__" + id; //Necesario para aplicar el estilo de altura a las graficas antiguas

  switch (tipo) {
    case 'timeline':

      return (
        <Card className='wrapper__grafica'>
          <TimelineMaterial
            title={'Linea del tiempo'}
            data={grafica.data}
            position="left"
          />
        </Card>
      )
    case 'radial_rangos':

      return (
        <Card className='wrapper__grafica'>
          <RadialRangos
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            id={old_chart_id}
            data={grafica.data}
          />
        </Card>
      )
    case 'series':

      return (
        <Card className='wrapper__grafica'>
          <Graficaconsolidado
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            id={old_chart_id}
            series={grafica.lista}
            data={grafica.data}
          />
        </Card>
      )
    case 'barras_h':
      return (
        <Card className='wrapper__grafica'>
          <SortedBarChart
            title={grafica.titulo}
            data={grafica.data || grafica.data_grafica}
            parametrizado={grafica.parametrizado}
            id={old_chart_id}
            // periodo={grafica.periodo}
            total={grafica.total}
            height={grafica.height ? grafica.height : '340px'}
            icon={<SaveIcon />}
            labels={grafica.labels}
          />
        </Card>
      )

    case 'histograma':
      return (
        <Card className='wrapper__grafica'>
          <Histograma
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
            limite={grafica.limite}
            series={grafica.series}
          />
        </Card>
      )
    case 'tortacontorta':
      return (
        <Card className='wrapper__grafica'>
          <MediaTorta
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>
      )
    case 'mediatorta':
      return (
        <Card className='wrapper__grafica'>
          <MediaTorta
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
            startAngle={grafica.startAngle ? grafica.startAngle : 180}
            endAngle={grafica.endAngle ? grafica.endAngle : 360}
          />
        </Card>
      )
    case 'divergente':
      return (
        <Card className='wrapper__grafica'>
          <Divergente
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>
      )
    case 'barras_horizontales_new':
      return (
        <Card className='wrapper__grafica'>
          <BarrasLabelsHorizontales
            id={old_chart_id}
            data={grafica.data_grafica}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>

      )
    case 'treemap':
      return (
        <Card className='wrapper__grafica'>
          <Treemap
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
            height={grafica.height}
          />
        </Card>

      )
    case 'dona':
      return (
        <Card className='wrapper__grafica'>
          <Dona2
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>
      )
    case 'dona_doble':
      return (
        <Card className='wrapper__grafica'>
          <DonaDosniveles
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>
      )
    case 'dona_total':
      return (
        <Card className='wrapper__grafica'>
          <DonaConTotal
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>
      )
    case 'herradura_expandible':
      return (
        <Card className='wrapper__grafica'>
          <HerraduraExpandible
            id={old_chart_id}
            data={grafica.data}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>
      )
    case 'b_tarjeta':
      return (<CardTextIcon
        title={grafica?.titulo}
        value={grafica?.valor}
        description={grafica?.descripcion}
        textColor={grafica?.text_color}
        backgroundColor={grafica?.background_color}
         />)
    case 'mapa':
      return (<Mapa colores={grafica?.colores} puntos={grafica?.data} pais_inicial={grafica?.pais_inicial} />)
    case 'dona_texto':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}>
          <PieDonutText data={grafica?.data} maxHeight={grafica?.max_height} />
        </ChartLayout>)
    case 'area_apilada':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}
        >
          <AreaStacked data={grafica?.data} color1={grafica?.color1} color2={grafica?.color2} label1={grafica?.label1} label2={grafica?.label2} />
        </ChartLayout>)
    case 'barras_multiple':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}>
          <BarMultiple data={grafica?.data} labels={grafica?.labels} colors={grafica?.colors} />
        </ChartLayout>)
    case "b_barrash":
      return (<BarFooter
        textos={grafica?.textos}
        data={grafica?.data}
      />)
    case "b_linea":
      return (<LineHeader
        textos={grafica?.textos}
        data={grafica?.data}
        color={grafica?.color}
        label={grafica?.label}
      />)
    case "area_interactiva":
      return (<AreaInteractive></AreaInteractive>)
    case "b_tarjeta_progreso":
      return (
        <CardProgress
          title={grafica?.titulo}
          value={grafica?.valor}
          description={grafica?.descripcion}
          progressValue={grafica?.valor_progreso}
          color={grafica?.color}
        />)
    case "b_tarjeta_barras_simple":
      return (<CardBarSimple></CardBarSimple>)
    case "b_radialbar":
      return (<RadialBarInfo></RadialBarInfo>)
    case "lista":
      return (<Lista></Lista>)
    case "b_barrash_dual":
      return (<HorizontalBarInfo
        titulo={grafica?.titulo}
        descripcion={grafica?.descripcion}
        valores={grafica?.valores}
      />)
    case 'barrasv_simple':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
        >
          <BarChartComponent
            data={grafica?.data}
            maxHeight={grafica?.maxHeight}
            color={grafica?.color}
            label={grafica?.label} />
        </ChartLayout>)

    case 'barrash_simple':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}
        >
          <HorizontalBarChartComponent
            data={grafica?.data}
            maxHeight={grafica?.maxHeight}
            color={grafica?.color}
            label={grafica?.label} />
        </ChartLayout>)
    case 'barrash_apilada':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}
        >
          <BarStacked data={grafica?.data} labels={grafica?.labels} colors={grafica?.colors} />
        </ChartLayout>)
    case 'barrash_negativo':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}
        >
          <BarNegative
            data={grafica?.data}
            maxHeight={grafica?.maxHeight}
            color={grafica?.color}
            color_negativo={grafica?.color_negativo}
            label={grafica?.label} />
        </ChartLayout>)
    case 'radar_multiple':
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}
        >
          <RadarMultiple data={grafica?.data} labels={grafica?.labels} colors={grafica?.colors} />
        </ChartLayout>)

    case "tabla":
      return (
        <ChartLayout
          title={grafica?.titulo}
          description={grafica?.descripcion}
          footerTitle={grafica?.pie}
          footerDescription={grafica?.descripcion_pie}
          footerTitleIcon={grafica?.icono_pie}
        >
          <div>
            <Tabla
              data={grafica?.data}
              botonCrearVisible={false}
              botonEditarVisible={false}
              columnas={grafica?.cols}
              scrollHeight={grafica?.scrollHeight}
              filas={grafica?.regs} />
          </div>
        </ChartLayout>)

    default:
      return null;
  }
}

export { FetchGrafica }
