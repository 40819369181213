import React, { useRef, useState } from 'react'
import {Form, Col} from 'react-bootstrap'
import Select from 'react-select';

export default function  OpcionesBuscador(props) {

  const [data, setData] = useState('');
  
  return  (
    <Form.Group as={Col} lg={6} className='inputGroup' style={{paddingLeft: '8px', maxWidth: '100%'}}>
      <Form.Label>{props.label}</Form.Label>
      <Select
        options={props.data}
        isSearchable={true}
        name={props.name}
        onChange={(e) => {props.handleChange(e)}}
        placeholder={props.placeholder}
        isClearable={true}
        style={props.style}
        instanceId={props.id}
        ref={props.ref} 
      />
    </Form.Group>
  )
}
