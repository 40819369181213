import React from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

import { AfterSVG } from '../icons/AfterSVG'
import NotificationModal from '../modales/NotificationModal';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { IconoPanel } from '../icons/IconoPanel'
import { LaterSVG } from '../icons/LaterSVG'
import { RiesgoSVG } from '../icons/RiesgoSVG'
import { DeinedSVG } from '../icons/DeinedSVG'
import { HelperSVG } from '../icons/HelperSVG'
import { PdfIcon } from '../icons/PdfIcon';
import { RelojIcon } from '../icons/RelojIcon';
import ProgressBar from '../../helpers/ProgressBar'
import ProgressBarComplete from '../../helpers/ProgressBarComplete'
import moment from 'moment'
import { SuccessSVG } from '../icons/SuccessSVG'
import { useTranslation } from 'react-i18next';
import PayULatamForm from '../PayULatamForm';

const Table = ({
  data,
  variableMostarTabla,
  filtros,
  formNew,
  setFormNew,
  tipoModal,
  openModal,
  setOpenModal,
  contenido,
  setContenido,
  confirmacioneliminarsolicitud,
  dataEnviarEnModal,
  ModalTablaRows,
  modalTablaCampos,
  setModalTablaCampos,
  showLoader,
  handleOpenAndDataModal,
  validarLocacion,
  handleDeleteSolicitud,
  setNumeroPaginas,
  getDataPaginado,
  numeroPaginas,
}) => {

  const location = window.location.href;
  const history = useHistory();
  const { t } = useTranslation();

  const pageClicked = (page) => {

    const { numeroRegistros, ...rest } = formNew;

    const obj = {
      ...rest,
      changePage: page,
      limite: numeroPaginas
    }

    getDataPaginado(obj)
  }

  return (
    <>
      {data !== null &&
        <>
          {
            !variableMostarTabla
            &&
            <div style={{ display: 'flex' }} >
              <p>{t('tabla_estudios.mostrando_resultados')}<b>{t('tabla_estudios.tipo_solicitud')}</b> = {filtros?.tipoSolicitud} - <b>{t('tabla_estudios.estado')} </b>= {filtros?.estado} -<b> {t('tabla_estudios.busqueda')}</b> = {filtros?.busqueda} </p>

            </div>
          }
          <Card className="shadow CardStyleSolicitud" style={{ borderRadius: '10px' }}>
            <Card.Body className='cardbodySollicitud'>
              <div className="container" style={{ width: '100% !important', padding: 0, display: 'flex', margin: '0 !important', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <label className='mt-1 mr-2' style={{ flex: 'none' }}> <b>{t('tabla_estudios.mostrar')}</b></label>
                  <select className="form-control" id='select' style={{ width: '65px', textAlign: 'center' }}
                    onChange={e => {
                      const { numeroRegistros, ...rest } = formNew
                      const newObj = {
                        ...rest,
                        pagina: data.pagina,
                        limite: Number(e.target.value)
                      }
                      setNumeroPaginas(Number(e.target.value));
                      getDataPaginado(newObj)
                    }} defaultValue={numeroPaginas}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </select>
                  <label style={{ flex: 'none' }} className='mt-1 ml-2'>
                    <b>{t('tabla_estudios.registros')}</b>
                  </label>
                </div>
                {data?.total && data?.total !== '' &&
                  <div style={{ display: 'flex', fontSize: '16px', fontWeight: '600', justifyContent: 'space-between', alignItems: 'center' }} >
                    {t('tabla_estudios.total_registros')}:
                    <p style={{
                      backgroundColor: '#213265',
                      padding: '0 10px',
                      fontWeight: '600',
                      color: 'white',
                      borderRadius: '7px',
                      margin: '0 0 0 10px'



                    }}> {data?.total}</p>
                  </div>
                }
                {/*   <div className='filtroItem' id='buscadorGeneral' style={{ display: 'flex' }}>
                                <div id="inputGroup">
                                    <SearchIcon fontSize="small" />
                                    <DebounceInput value={palabra} debounceTimeout={1000} id="input" name="busqueda" />
                                    <CloseIcon fontSize="small" style={{ cursor: 'pointer', color: "#CBD7E6" }} />
                                </div>
                                <button style={{
                                    marginLeft: '10px',
                                    borderRadius: '50%',
                                    height: '30px',
                                    width: '40px',
                                    background: '#213265',
                                    color: 'white'
                                }}
                                >
                                    <SearchIcon fontSize="small" />
                                </button>
                            </div> */}


              </div>
              <br />

              <div className='tableContainer' >
                <br />

                <table className="table ">
                  <thead >
                    <tr style={{ textAlign: 'left' }} >


                      {
                        data.columns?.informes?.activo
                        && <th >{data.columns?.informes?.label}</th>
                      }

                      {
                        data.columns?.observaciones?.activo
                        && <th>{data.columns?.observaciones?.label}</th>
                      }

                      {
                        data.columns?.observacionSolicitud?.activo
                        &&
                        <th>{data.columns?.observacionSolicitud?.label}</th>
                      }

                      {
                        data.columns?.candidato?.activo
                        &&
                        <th style={{ textAlign: 'center', minWidth: '97px' }}>
                          {data.columns?.candidato?.label}
                        </th>
                      }

                      {
                        data.columns?.id?.activo
                        && <th>{data.columns?.id?.label}</th>

                      }

                      {
                        data.columns?.cargo?.activo
                        && <th >{data.columns?.cargo?.label}</th>

                      }

                      {
                        data.columns?.tiempos?.activo
                        && <th style={{ textAlign: 'center', minWidth: '135px' }}>
                          {data.columns?.tiempos?.label}

                        </th>
                      }
                      {
                        data.columns?.ciudad?.activo
                        &&
                        <th >{data.columns?.ciudad?.label}</th>
                      }

                      {
                        data.columns?.radicado?.activo
                        && <th>{data.columns?.radicado?.label}</th>
                      }


                      {

                        data.columns?.devolucion?.activo
                        && <th>{data.columns?.devolucion?.label}</th>

                      }

                      {
                        data.columns?.solicitud?.activo
                        && <th>{data.columns?.solicitud?.label}</th>

                      }

                      {
                        data.columns?.analista?.activo
                        && <th>{data.columns?.analista?.label}</th>
                      }






                      {
                        data.columns?.afiliado?.activo
                        && <th>{data.columns?.afiliado?.label}</th>
                      }

                      {
                        data.columns?.identificacion?.activo
                        && <th>{data.columns?.identificacion?.label}</th>
                      }


                      {
                        data.columns?.fechaSolicitud?.activo
                        && <th style={{ textAlign: 'center', minWidth: '135px' }}>
                          {data.columns?.fechaSolicitud?.label}
                        </th>

                      }

                      {
                        data.columns?.fechaEstimada?.activo
                        && <th style={{ textAlign: 'center', minWidth: '135px' }}>
                          {data.columns?.fechaEstimada?.label}
                        </th>
                      }

                      {
                        data.columns?.fechaFinal?.activo
                        && <th style={{ textAlign: 'center', minWidth: '105px' }}>
                          {data.columns?.fechaFinal?.label}

                        </th>
                      }

                      {
                        data.columns?.tipoInvestigacion?.activo
                        && <th>{data.columns?.tipoInvestigacion?.label}</th>

                      }







                      {
                        data.columns?.estado?.activo
                        &&
                        <th style={{ textAlign: 'center', minWidth: '72px' }}>
                          {data.columns?.estado?.label}
                        </th>
                      }

                      {
                        data.columns?.nivelRiesgo?.activo
                        &&
                        <th>{data.columns?.nivelRiesgo?.label}</th>

                      }

                      {
                        data.columns?.cuenta?.activo
                        &&
                        <th >{data.columns?.cuenta?.label}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {((data.jsonData && data.jsonData.length > 0) && Object.keys(data.columns).length > 0) && data.jsonData.map((ele, index) => {
                      return <tr className='rowAdministrarRegistros' style={{ textAlign: 'left' }} key={index}>
                        {
                          data?.columns?.informes?.activo &&
                          <td className="td" style={{ maxWidth: 500, padding: '4px', display: 'flex', justifyContent: 'space-evenly' }}>

                            {/* Editar */}

                            {

                              ele?.informes?.editar?.visualizar
                              &&
                              <div style={{ marginLeft: 2 }} title="Editar"
                                onClick={() => {
                                  history.push(`/gerencia/inteligencia/editar/${ele.informes.gestionar.id}`)
                                }}
                              >
                                <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                                  <AssignmentRoundedIcon style={{ fontSize: 27, color: 'rgb(33, 50, 101)' }} />
                                </a>
                              </div>
                            }

                            {/* Gestionar PANORAMA RIESGOS*/}


                            {

                              ele?.informes?.gestionarPanorama?.visualizar
                              &&
                              <div style={{ marginLeft: 2 }} title="Panorama"
                                onClick={() => {
                                  // history.push(`/gerencia/inteligencia/solicitar`)
                                  history.push(`/gerencia/auditorias/panorama_riesgos/${ele.informes.gestionarPanorama.id}/gestionar`)
                                }}
                              >
                                <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                                  <ReportProblemOutlinedIcon width="24" height="24" fill="rgb(33, 50, 101)" />
                                </a>
                              </div>
                            }

                            {/* Panel Panorama*/}

                            {

                              ele?.informes?.panel_panorama?.visualizar
                              &&
                              <div style={{ marginLeft: 2 }} title="Panorama"
                                onClick={() => {
                                  // history.push(`/gerencia/inteligencia/solicitar`)
                                  history.push(`/panel_panorama/${ele.informes.panel_panorama.id}`)
                                }}
                              >
                                <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                                  <IconoPanel width="24" height="24" fill="rgb(33, 50, 101)" />
                                </a>
                              </div>
                            }

                            {/* Función para cargar iconos de gestores con enlaces que dependen de la locación(window.location.href) */}

                            {validarLocacion(location, ele)}



                            {/* Gestionar */}

                            {

                              // ele?.informes?.gestionar?.visualizar
                              // &&
                              // <a target="_blank" href={`{https://www.cosinte.co/cia2}/#/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`}  style={{ cursor: 'pointer' }}

                              // >
                              // <div style={{ marginLeft: 2 }} title="Gestionar solicitud"


                              //     >
                              //         <EditSVG width="24" height="24" fill="rgb(33, 50, 101)" />
                              // </div>
                              //     </a>
                            }

                            {/* Gestor dinamico */}

                            {

                              // ele?.informes?.gestor_dinamico?.visualizar
                              // &&
                              //     <a target="_blank" href={`https://www.cosinte.co/cia2/#/gerencia/${data.seccion}/gestor/creada/${ele.informes.gestor_dinamico.id}`} rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                              //             <div style={{ marginLeft: 2 }} title="Gestionar registro"

                              //             >
                              //             <EditSVG width="24" height="24" fill="rgb(33, 50, 101)" />
                              //         </div>
                              //     </a>
                            }


                            {/* PDFS */}
                            {
                              ele?.informes?.pdfs?.visualizar
                              &&
                              ele?.informes?.pdfs?.data?.map((pdf, iPdf) => {

                                return <div key={iPdf} onClick={() => {

                                }}>
                                  <a href={pdf.url} target="_blank" rel="noopener noreferrer" title={pdf.nombre}>
                                    {pdf.icono
                                      ?
                                      <img
                                        // style={{
                                        //     display:'flex',
                                        //     justifyContent:'center',
                                        //     alignItems:'center'
                                        // }}
                                        src={pdf.icono}
                                        width="24"
                                        height="24"
                                      />
                                      :
                                      <PdfIcon width="24" height="24" color={pdf.color && pdf.color} />
                                    }

                                  </a>


                                </div>
                              })
                            }

                            {/* Visualizar PDFS */}
                            {
                              ele?.informes?.ver_pdf?.visualizar === true
                              &&

                              <div>
                                <a target="_blank" rel="noopener noreferrer" title='Ver PDF'>
                                  <PdfIcon width="24" height="24" color={ele?.informes?.ver_pdf?.color && ele?.informes?.ver_pdf?.color} />
                                </a>
                              </div>
                            }


                            {/* Excel */}
                            {ele?.informes?.excel?.visualizar
                              &&
                              <div onClick={() => {
                                /*  setModal(!modal)
                                 setDataSelected(ele) */
                              }}>
                                <a href={ele?.informes?.excel?.url} target="_blank" rel="noopener noreferrer" title="Historial">
                                  <RelojIcon width="24" height="24" />
                                </a>
                              </div>
                            }


                            {/* Eliminar */}

                            {
                              ele?.informes?.eliminar?.visualizar
                              &&
                              <div style={{ marginLeft: 2 }} onClick={() => {

                                handleDeleteSolicitud(ele.idSolicitud.label)
                              }} title="Cancelar">
                                <a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                                  <DeinedSVG width="24" height="24" fill="rgb(33, 50, 101)" />
                                </a>
                              </div>
                            }
                            {/* Muestra un botón de pago a PAYU */}
                            {ele?.informes?.boton_pago?.visualizar &&
                            <PayULatamForm  sol={ele.informes.boton_pago} />
                            }
                          </td>
                        }
                        {
                          data.columns?.observaciones.activo
                            &&
                            ele.observaciones.modal
                            ?
                            <td
                              className="td"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleOpenAndDataModal(ele.observaciones)}>
                              <p style={{ display: 'flex', justifyContent: 'center' }} >
                                {ele.observaciones.label}
                              </p>
                              {
                                ele.observaciones.modal
                                // &&  <a> ...Ver más</a>
                              }
                            </td>
                            :
                            <td className="td" >
                              <p style={{ display: 'flex', justifyContent: 'center' }} >
                                {ele.observaciones.label}
                              </p>
                            </td>
                        }

                        {
                          data.columns?.observacionSolicitud.activo
                            &&
                            ele.observacionSolicitud.modal
                            ?
                            <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.observacionSolicitud)}>{ele.observacionSolicitud.label}</td>
                            :
                            <td className="td" style={{ textAlign: 'center' }}>{ele.observacionSolicitud.label}</td>
                        }
                        {
                          data.columns?.candidato.activo
                            && ele.nombreCandidato.modal
                            ?
                            <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.nombreCandidato)}>{ele.nombreCandidato.label}</td>
                            : <td className="td" style={{ textAlign: 'center' }}>{ele.nombreCandidato.label}</td>
                        }
                        {
                          data.columns?.id.activo
                            ?
                            data.columns?.id.activo
                              &&
                              ele.id.modal
                              ?
                              <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.id)}>{ele.id.label}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{ele.id.label}</td>
                            : <></>
                        }

                        {/* {
                                                        data.columns?.radicado.activo
                                                            ?
                                                            data.columns?.radicado.activo
                                                                && ele.radicado.modal
                                                                ?
                                                                <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.radicado)}>{ele.radicado.label}</td>
                                                                : <td className="td" style={{ textAlign: 'center' }}>{ele.radicado.label}</td>
                                                            : <></>
                                                    } */}

                        {
                          data.columns?.devolucion.activo
                            ?
                            data.columns?.devolucion.activo && ele.devolucion.modal

                              ? <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.devolucion)}>{ele.devolucion.label}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{ele.devolucion.label}</td>
                            : <></>
                        }

                        {
                          data.columns?.solicitud.activo
                            ?
                            data.columns?.solicitud.activo && ele.solicitud.modal
                              ? <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.solicitud)} >{ele.solicitud.label}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{ele.solicitud.label}</td>
                            : <></>
                        }

                        {/* {
                                                        data.columns?.analista.activo
                                                            && ele.analista.modal
                                                            ?
                                                            <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.analista)}>{ele.analista.label}</td>
                                                            : <td className="td" style={{ textAlign: 'center' }}>{ele.analista.label}</td>
                                                    } */}



                        {/* {
                                                        data.columns?.afiliado.activo
                                                            ?
                                                            data.columns?.afiliado.activo
                                                                && ele.afiliado.modal
                                                                ?
                                                                <td className="td" style={{ textAlign: 'center', cursor: 'pionter' }} onClick={() => handleOpenAndDataModal(ele.afiliado)} >{ele.afiliado.label}</td>
                                                                : <td className="td" style={{ textAlign: 'center' }}>{ele.afiliado.label}</td>
                                                            : <></>
                                                    } */}

                        {/* {
                                                        data.columns?.identificacion.activo
                                                            && ele.identificacion.modal
                                                            ?
                                                            <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.identificacion)}>{ele.identificacion.label}</td>
                                                            : <td className="td" style={{ textAlign: 'center' }}>{ele.identificacion.label}</td>
                                                    } */}

                        {
                          data.columns?.cargo.activo
                            && ele.cargo.modal
                            ?
                            <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.cargo)}>{ele.cargo.label === '' ? 'N/A' : ele.cargo.label}</td>
                            : <td className="td" style={{ textAlign: 'center' }}>{ele.cargo.label === '' ? 'N/A' : ele.cargo.label}</td>

                        }

                        {
                          data.columns?.tiempos.activo
                            ?
                            data.columns?.tiempos.activo
                            &&
                            <td id={data.columns?.id} className="td" style={{ minWidth: 300, textAlign: 'center', zIndex: '1' }}>
                              {/* {moment(ele.fechaSolicitud).format('LL')} */}
                              {ele.tiempos.diaActual > ele.tiempos.diasTotales
                                ?
                                <ProgressBarComplete dataTiempos={ele.tiempos} />
                                :
                                <ProgressBar dataTiempos={ele.tiempos} />
                              }

                            </td>
                            : <></>
                        }

                        {
                          data.columns?.fechaSolicitud.activo
                            ?
                            data.columns?.fechaSolicitud.activo && ele.fechaSolicitud.modal

                              ? <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.fechaSolicitud)}>{moment(ele.fechaSolicitud.label).format('LL')}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{moment(ele.fechaSolicitud.label).format('LL')}</td>
                            : <></>
                        }

                        {
                          data.columns?.fechaEstimada.activo
                            ?
                            data.columns?.fechaEstimada.activo
                              && ele.fechaEstimada.modal
                              ?
                              <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.fechaEstimada)} >{moment(ele.fechaEstimada.label).format('LL')}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{moment(ele.fechaEstimada.label).format('LL')}</td>
                            : <></>
                        }

                        {
                          data.columns?.fechaFinal.activo
                            ?
                            data.columns?.fechaFinal.activo
                              && ele.fechaFinal.modal
                              ? <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.fechaFinal)} >{moment(ele.fechaFinal.label).format('LL')}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{moment(ele.fechaFinal.label).format('LL')}</td>
                            : <></>
                        }


                        {
                          data.columns?.tipoInvestigacion.activo
                            ?
                            data.columns?.tipoInvestigacion.activo
                              && ele.tipoInvestigacion.modal
                              ?
                              <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.tipoInvestigacion)}>{ele.tipoInvestigacion.label}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{ele.tipoInvestigacion.label}</td>
                            : <></>
                        }



                        {
                          data.columns?.ciudad.activo
                            && ele.ciudad.modal
                            ?
                            <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.ciudad)}>{ele.ciudad.label === '' ? 'N/A' : ele.ciudad.label}</td>
                            : <td className="td" style={{ textAlign: 'center' }}>{ele.ciudad.label === '' ? 'N/A' : ele.ciudad.label}</td>
                        }

                        {
                          data.columns?.estado.activo
                            ?
                            data.columns?.estado.activo
                              && ele.estado.modal
                              ?
                              <td className="td" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.estado)} >{`${ele.estado.label} `}</td>
                              : <td className="td" style={{ textAlign: 'center' }}>{`${ele.estado.label} `}</td>

                            : <></>

                        }
                        {
                          data.columns?.nivelRiesgo.activo
                          &&
                          <td >
                            {
                              ele.nivelRiesgo.label === 'Aprobado'
                                ? <SuccessSVG />
                                : ele.nivelRiesgo.label === 'Riesgo'
                                  ? <RiesgoSVG />
                                  : ele.nivelRiesgo.label === 'Negado'
                                    ? <DeinedSVG />
                                    : ele.nivelRiesgo.label === 'Preliminar'
                                      ? <HelperSVG />
                                      : ''

                            }

                          </td>

                        }
                        {
                          data.columns?.cuenta.activo
                            && ele.cuenta.modal
                            ?
                            <td className="td" style={{ textAlign: 'center', curso: 'pointer' }} onClick={() => handleOpenAndDataModal(ele.cuenta)}>
                              {ele.cuenta.label}
                            </td>
                            : <td className="td" style={{ textAlign: 'center' }}>
                              {ele.cuenta.label}
                            </td>
                        }



                      </tr>
                    })
                    }

                  </tbody>
                </table>
              </div>



              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>

                <div onClick={() => {

                  if (data.pagina !== 1) {


                    setFormNew({ ...formNew, })


                    const { numeroRegistros, ...rest } = formNew

                    const obj = {
                      ...rest,
                      changePage: Number(data.pagina) - 1
                    }

                    getDataPaginado(obj);
                  }

                }} style={{ marginRight: '10px' }}>
                  {
                    data.pagina !== 1
                    &&
                    <AfterSVG />
                  }

                </div >


                <div >
                  {`${data.pagina} ${t('tabla_estudios.de')} ${data.totalNumeroPaginas}`}
                  {/* {
                                        data.totalNumeroPaginas >= 2
                                            ?
                                            Number(data.pagina) === 1
                                                ? <p><b style={{ fontWeight: '900' }}>{data.pagina}</b> {`${Number(data.pagina) + 1}`}</p>
                                                : Number(data.pagina) === 2
                                                    ? <p>{`${Number(data.pagina) - 1}`}<b style={{ fontWeight: '900', marginLeft: '3px' }}>{data.pagina}</b></p>
                                                    : Number(data.pagina) > 2
                                                        ? <p>{`${Number(data.pagina - 2)}${Number(data.pagina) - 1}`} <b style={{ fontWeight: '900', marginLeft: '3px' }}>{Number(data.pagina)}</b>  {`${Number(data.pagina) + 1} ${Number(data.pagina) + 2}`}</p>
                                                        : Number(data.pagina + 1) === Number(data.totalNumeroPaginas)
                                                            ? <p>{`${Number(data.pagina - 2)}${Number(data.pagina) - 1}`}<b className={{ fontWeight: '900', marginLeft: '3px' }}> {Number(data.pagina)} </b> {`${Number(data.pagina) + 1}`}</p>
                                                            : <p>{`${Number(data.pagina - 2)}${Number(data.pagina) - 1}`}<b className={{ fontWeight: '900', marginLeft: '3px' }}>{Number(data.pagina)}</b></p>

                                            : <p>{data.pagina}</p>
                                    } */}
                </div>
                {/* <Paginador
                                    totPages={data.totalNumeroPaginas}
                                    currentPage={data.pagina}
                                    pageClicked={pageClicked}
                                /> */}
                <div onClick={() => {

                  const paginaSiguiente = Number(data.pagina) + 1
                  if (paginaSiguiente <= Number(data.totalNumeroPaginas)) {

                    const { numeroRegistros, ...rest } = formNew
                    const obj = {
                      ...rest,
                      changePage: paginaSiguiente,
                      limite: numeroPaginas
                    }
                    getDataPaginado(obj)
                  }
                }} style={{ marginLeft: '10px' }}>
                  {
                    data.pagina < data.totalNumeroPaginas
                    &&
                    <LaterSVG />
                  }
                </div>
                {/* <div style={{ marginLeft: '5px' }}>
                                    <b>de {data.totalNumeroPaginas}</b>
                                </div> */}
              </div>
            </Card.Body>
            <NotificationModal typeModal={tipoModal} isOpen={openModal} setIsOpen={setOpenModal} detalle={contenido} setDetalle={setContenido} funciones={confirmacioneliminarsolicitud} />

          </Card>
          {dataEnviarEnModal && <ModalTablaRows open={modalTablaCampos} setOpen={setModalTablaCampos} data={dataEnviarEnModal} />}
        </>
      }
    </>
  )
}

export default Table;
