import React from 'react'

export const ClientesSVG = (props) => {
    return (
      <svg version="1.1" id="Layer_1" xmlns="https://www.w3.org/2000/svg"  x="0px" y="0px" fill={props.color} width="25" height="25"
      viewBox="0 0 511.999 511.999" >
   <g>
     <g>
       <path d="M468.15,157.858h-21.558v-13.863l13.451-14.814c6.983-7.69,10.828-17.645,10.828-28.033V66.017
         c0-24.565-19.986-44.55-44.551-44.55h-20.492c-24.565,0-44.55,19.986-44.55,44.55v35.131c0,10.388,3.846,20.344,10.827,28.033
         l13.453,14.816v13.861H364c-20.944,0-38.497,14.764-42.821,34.43c-2.201-0.308-4.445-0.478-6.729-0.478h-25.252v-16.958
         l15.404-16.963c7.701-8.482,11.943-19.463,11.943-30.92V87.4c0-27.132-22.073-49.203-49.204-49.203
         c-4.252,0-7.697,3.445-7.697,7.697s3.445,7.697,7.697,7.697c18.643,0,33.81,15.166,33.81,33.809v39.568
         c0,7.623-2.822,14.928-7.945,20.571l-15.862,17.469c-2.128,1.369-3.538,3.758-3.538,6.478v28.022c0,4.252,3.445,7.697,7.697,7.697
         h32.949c18.207,0,33.021,14.813,33.021,33.021v48.298H164.13v-48.298c0-14.196,9.006-26.326,21.602-30.984
         c0.592-0.124,1.156-0.321,1.689-0.573c3.077-0.95,6.345-1.465,9.729-1.465h32.949c4.251,0,7.697-3.445,7.697-7.697v-28.022
         c0-2.719-1.41-5.109-3.538-6.478l-15.862-17.469c-5.124-5.643-7.947-12.949-7.947-20.571V87.4
         c0-18.642,15.166-33.809,33.81-33.809c4.251,0,7.697-3.445,7.697-7.697s-3.446-7.697-7.697-7.697
         c-27.132,0-49.204,22.072-49.204,49.203v39.568c0,11.456,4.242,22.437,11.943,30.92l15.402,16.962v16.959h-25.252
         c-2.151,0-4.265,0.155-6.342,0.429c-4.342-19.641-21.881-34.38-42.808-34.38H126.44v-13.863l13.451-14.814
         c6.983-7.69,10.828-17.645,10.828-28.033V66.017c0-24.565-19.986-44.55-44.551-44.55H85.678c-24.565,0-44.55,19.986-44.55,44.55
         v35.131c0,10.388,3.845,20.344,10.828,28.033l13.452,14.815v13.862H43.85c-24.179,0-43.85,19.671-43.85,43.85v49.716
         c0,4.252,3.446,7.697,7.697,7.697h119.758c4.251,0,7.697-3.445,7.697-7.697s-3.446-7.697-7.697-7.697H15.394v-42.019
         c0-15.69,12.765-28.456,28.456-28.456h29.255c4.251,0,7.697-3.445,7.697-7.697v-24.88c0-2.677-1.367-5.035-3.441-6.414
         l-14.009-15.428c-4.405-4.851-6.831-11.131-6.831-17.684V66.017c0-16.077,13.079-29.156,29.156-29.156h20.492
         c16.077,0,29.157,13.079,29.157,29.156v35.131c0,6.553-2.426,12.834-6.831,17.684l-14.01,15.429
         c-2.073,1.379-3.439,3.737-3.439,6.413v24.88c0,4.252,3.446,7.697,7.697,7.697h29.255c13.975,0,25.621,10.129,28.002,23.43
         c-16.127,7.864-27.265,24.424-27.265,43.542v55.995c0,4.252,3.446,7.697,7.697,7.697h198.736c4.252,0,7.697-3.445,7.697-7.697
         v-55.995c0-18.971-10.973-35.419-26.901-43.355c2.305-13.392,13.995-23.617,28.036-23.617h29.255c4.252,0,7.697-3.445,7.697-7.697
         v-24.88c0-2.678-1.367-5.036-3.442-6.415l-14.008-15.427c-4.405-4.85-6.83-11.131-6.83-17.684V66.017
         c0-16.077,13.079-29.156,29.156-29.156h20.492c16.077,0,29.157,13.079,29.157,29.156v35.131c0,6.553-2.426,12.834-6.831,17.684
         l-14.009,15.429c-2.074,1.379-3.44,3.737-3.44,6.413v24.88c0,4.252,3.445,7.697,7.697,7.697h29.255
         c15.69,0,28.456,12.765,28.456,28.456v42.019H385.868c-4.252,0-7.697,3.445-7.697,7.697s3.445,7.697,7.697,7.697h118.434
         c4.252,0,7.697-3.445,7.697-7.697v-49.716C512,177.529,492.329,157.858,468.15,157.858z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M333.406,401.466c-0.888-2.738-3.231-4.751-6.073-5.218l-42.046-6.902l-19.557-37.856
         c-1.321-2.557-3.959-4.165-6.838-4.165s-5.517,1.606-6.838,4.165l-19.557,37.856l-42.046,6.902
         c-2.841,0.467-5.184,2.479-6.074,5.218c-0.89,2.738-0.177,5.743,1.847,7.791l29.958,30.297l-6.429,42.12
         c-0.434,2.846,0.755,5.697,3.085,7.388c1.34,0.974,2.928,1.471,4.525,1.471c1.178,0,2.362-0.271,3.454-0.82l38.073-19.13
         l38.073,19.13c2.573,1.293,5.652,1.043,7.979-0.651c2.33-1.691,3.519-4.542,3.085-7.388l-6.43-42.12l29.958-30.297
         C333.584,407.209,334.296,404.204,333.406,401.466z M287.936,431.479c-1.711,1.731-2.502,4.169-2.136,6.573l4.765,31.214
         l-28.216-14.176c-2.175-1.092-4.737-1.092-6.911,0l-28.216,14.176l4.764-31.214c0.367-2.406-0.425-4.844-2.136-6.573
         l-22.204-22.454l31.16-5.114c2.401-0.395,4.475-1.902,5.591-4.063l14.494-28.055l14.493,28.055
         c1.117,2.161,3.19,3.668,5.591,4.063l31.16,5.114L287.936,431.479z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M501.894,344.869c-0.89-2.738-3.231-4.751-6.071-5.218l-42.046-6.902l-19.558-37.856
         c-1.321-2.557-3.959-4.165-6.838-4.165c-2.88,0-5.517,1.606-6.838,4.165l-19.557,37.856l-42.047,6.902
         c-2.841,0.467-5.184,2.479-6.074,5.218c-0.89,2.738-0.178,5.744,1.847,7.791l29.959,30.297l-6.43,42.121
         c-0.434,2.846,0.755,5.697,3.085,7.388c2.33,1.692,5.408,1.945,7.979,0.651l38.073-19.13l38.073,19.13
         c1.092,0.549,2.276,0.82,3.454,0.82c1.598,0,3.186-0.496,4.525-1.471c2.33-1.691,3.519-4.542,3.085-7.388l-6.43-42.121
         l29.959-30.297C502.072,350.613,502.784,347.607,501.894,344.869z M456.424,374.883c-1.711,1.73-2.502,4.169-2.136,6.573
         l4.765,31.215l-28.216-14.176c-1.088-0.546-2.271-0.82-3.456-0.82s-2.369,0.274-3.456,0.82l-28.216,14.176l4.765-31.215
         c0.366-2.406-0.425-4.844-2.136-6.573l-22.203-22.453l31.16-5.114c2.402-0.395,4.475-1.902,5.591-4.063l14.493-28.055
         l14.494,28.055c1.117,2.161,3.19,3.668,5.591,4.063l31.159,5.114L456.424,374.883z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M164.919,344.87c-0.889-2.739-3.233-4.752-6.074-5.219l-42.046-6.902l-19.557-37.856
         c-1.322-2.557-3.959-4.165-6.839-4.165c-2.88,0-5.517,1.606-6.839,4.165l-19.555,37.857l-42.046,6.902
         c-2.841,0.467-5.184,2.479-6.074,5.218c-0.89,2.738-0.177,5.744,1.847,7.791l29.959,30.297l-6.429,42.121
         c-0.434,2.846,0.755,5.697,3.085,7.388c2.329,1.692,5.407,1.945,7.979,0.651l38.073-19.13l38.072,19.13
         c1.092,0.549,2.276,0.82,3.454,0.82c1.597,0,3.186-0.496,4.525-1.471c2.33-1.691,3.519-4.542,3.085-7.388l-6.429-42.121
         l29.958-30.297C165.095,350.613,165.809,347.608,164.919,344.87z M119.447,374.883c-1.711,1.73-2.503,4.169-2.136,6.573
         l4.764,31.215l-28.215-14.176c-1.087-0.547-2.271-0.82-3.456-0.82s-2.369,0.274-3.456,0.82l-28.216,14.176l4.765-31.215
         c0.367-2.406-0.425-4.844-2.136-6.573L39.161,352.43l31.16-5.114c2.401-0.395,4.475-1.902,5.592-4.063l14.493-28.055
         l14.493,28.055c1.117,2.161,3.191,3.668,5.592,4.063l31.16,5.114L119.447,374.883z"/>
     </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>

    )
}
