import { Card, CardContent, CardHeader, CardTitle } from '../../ui/Card';
import styles from './CardTextIcon.module.scss';

export default function CardTextIcon({
  title = 'Total Revenue',
  value = '$45,231.89',
  description = '+20.1% from last month',
  textColor,
  backgroundColor = 'white',
  props
}) {

  return (
    <Card {...props} style={{ background: backgroundColor }}>
      <CardHeader className={styles['card-header']}>
        <CardTitle className={styles['card-title']} style={{color:textColor}}>{title}</CardTitle>
      </CardHeader>
      <CardContent className={styles['card-content']}>
        <div className={styles['revenue']} style={{color:textColor}}>{value}</div>
        <p className={styles['percentage']} style={{color:textColor}}>{description}</p>
      </CardContent>
    </Card>
  );
}