import { forwardRef, Fragment, React, useEffect, useState } from 'react'
// import { Grid } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import NotificationModal from '../../modales/NotificationModal'

import {CambioContraseña} from '../../../pages/Navbar/Perfil/CambioContraseña'

import SolicitarDinamico from './Pestañas/SolicitarDinamico';
import { useTranslation } from 'react-i18next';

import './PestanasDinamicas.scss'


export default function PestanasDinamicas({ selectSeleccionado, setValorSelectInicial, pestanas, padre, ...props}) {

  // console.log(pestanas)

  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pestaña, setPestaña] = useState();
  const [formState, setFormState] = useState(false)

  const perfil = {}



  useEffect(() => {
    setPestaña(padre === 'perfil' ? 'perfil' : pestanas[0]?.pestana)
  }, [])

  return (
    <div>
      {
        <div className='rowPestanas' >
        {padre === 'perfil' &&
          <div
            className="pestana"
            style={{ background: pestaña !== 'perfil' && '#CBD7E6' }}
            onClick={() => setPestaña('perfil')}
          >
            <p className=" p-0 m-0">
              {t('formulario.label.cambiar_contrasena')}
            </p>
          </div>

        }
        {pestanas?.map((ele, index) => {
          return (
          <div
            key={index}
            className="pestana"
            style={{ background: pestaña !== ele.pestana && '#CBD7E6' }}
            onClick={() => setPestaña(ele.pestana)}
          >
            <p className=" p-0 m-0">
              {ele.label}
            </p>
          </div>)
        })}
        </div>
      }
      {pestaña === 'perfil' && <CambioContraseña />}
      {pestanas?.map((ele, index) => {



        if(ele.pestana === pestaña){
        return(

          <div>
            <SolicitarDinamico
              ele={ele}
              key={index}
              contenido={ele.contenido}
              propiedades={props}
              index={index}
              selects={props.selectSelected }
              pestaña={pestaña}
              selectSeleccionado={selectSeleccionado ? selectSeleccionado : null}
              setValorSelectInicial={setValorSelectInicial}
            />
          </div>
        )
      }
      })
      }

    </div>
  )
}
