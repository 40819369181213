import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';


import * as am4core from "@amcharts/amcharts4/core";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useTranslation } from 'react-i18next';

function RadialRangos(props) {

  const { t } = useTranslation();

  useEffect(() => {
    let chart = am4core.create(props.id ? props.id : "chartdiv", am4charts.RadarChart);
    chart.data = props.data;

    chart.bulletsContainer.zIndex = 10;

    // am4core.useTheme(am4themes_animated);

    // var chart = am4core.create("chartdiv", am4charts.RadarChart);

    /* Create axes */
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "caracteristica";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.fontSize = 11;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fillOpacity = 0.5;
    valueAxis.min = 0;
    valueAxis.max = props.limite ? props.limite : 11;
    valueAxis.renderer.minGridDistance = 12;
    valueAxis.renderer.labels.template.fontSize = 11

    var range = categoryAxis.axisRanges.create();
    range.category = t('formulario.label.fortalezas')
    range.endCategory = t('formulario.label.fortalezas')
    range.axisFill.fill = am4core.color("#D6FFD9");
    range.axisFill.fillOpacity = 0.9;
    range.axisFill.visible = true;

    var range2 = categoryAxis.axisRanges.create();
    range2.category = t('formulario.label.amenazas')
    range2.endCategory = t('formulario.label.amenazas')
    range2.axisFill.fill = am4core.color("#FFE7D6");
    range2.axisFill.fillOpacity = 0.9;
    range2.axisFill.visible = true;

    var range3 = categoryAxis.axisRanges.create();
    range3.category = t('formulario.label.oportunidades')
    range3.endCategory = t('formulario.label.oportunidades')
    range3.axisFill.fill = am4core.color("#D9F9FC");
    range3.axisFill.visible = true;
    range3.axisFill.fillOpacity = 0.9;

    var range4 = categoryAxis.axisRanges.create();
    range4.category = t('formulario.label.debilidades')
    range4.endCategory = t('formulario.label.debilidades')
    range4.axisFill.fill = am4core.color("#FFF3D6");
    range4.axisFill.visible = true;
    range4.axisFill.fillOpacity = 0.9;


    /* Create and configure series */
    var series = chart.series.push(new am4charts.RadarSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "valor";
    series.dataFields.categoryX = "caracteristica";
    series.name = "caracteristica";
    series.strokeWidth = 3;
    series.fillOpacity = 0.3;
    series.sequencedInterpolation = true;
    series.dataItems.template.locations.categoryX = 0.5;

    // series.fill = am4core.color("#67B7DC");
    // series.stroke = am4core.color("#67B7DC");


    //series.appear(1000);
    chart.appear(1000, 100);

    var circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.radius = 8;
    circleBullet.circle.align = "center";
    circleBullet.circle.valign = "middle";

    var shadow = new am4core.DropShadowFilter();
    shadow.dx = 1;
    shadow.dy = 3;
    circleBullet.filters.push(shadow);

    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valor}";
    labelBullet.label.fontSize = 11;

    // const exportGrafica = async () => {

    //   let imag3e = await chart.exporting.getImage("png")

    //   console.log(imag3e);
    //   setImgGenerada(imag3e)

    // }

    if (props.setImgGenerada) {

      setTimeout(() => {
        chart.exporting.getImage("png").then((img) => {

          props.setImgGenerada(img)
        })

      }, 2000);

    }



  }, [props?.data]);

  // useLayoutEffect(() => {
  //   setImgGenerada(chart)
  // }, [])

  return (
    <>
      <div className='' style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        {/* {props.children}
        <button
          className='confirmButton'
          style={{ width: '120px', margin: '0' }}
          onClick={() => exportGrafica()}
        >
          Capturar
        </button> */}
        {props.title &&
          <h4 className='pagesTitles' style={{ textAlign: 'center', width: '100%', margin: '0 auto', height: '35px' }}>{props.title}</h4>
        }
        <div id={props.id ? props.id : 'chartdiv'} style={{ width: '100%', height: '350px' }}>

        </div>


      </div>

    </>
  )
}

export { RadialRangos }
