import React, {useState, useEffect} from 'react'
import reactDOM from 'react-dom'
// import { Col, Form, Button, Tabs, Tab, ListGroup, Table } from 'react-bootstrap'
import {validarEntorno} from '../../helpers/validarHost'
import { RenderGrafica } from '../../helpers/graficas/RenderGrafica'

import Loader from '../icons/Loader'
import CloseIcon from '@material-ui/icons/Close';

import './EditModal.scss'
import './styles/ResumenComponenteModal.scss'
import { useTranslation } from 'react-i18next'

export default function ResumenComponenteModal(props){

  const { t } = useTranslation()
  const [initialState, setInitialState] = useState({
    mensaje_sesion: '',
    loading: true,
    error: null,
    data: {}
  })

  const getReporte = async () => {

    setInitialState({ ...initialState, loading: true })

    const dataReq = {
      hash: window.location.hash,
      id: props.item.id_widget
    }

    const req = await validarEntorno('JSON/resumen_factor_ejemplo.html', 'POST', dataReq)

    if(req.ok){
      const res = await req.json()
      setInitialState({ ...initialState, loading: false, data: res })

      // if(res.mensaje_sesion === "ok"){
      //   //console.log(initialState)
      // }else{
      //   // <Route>
      //   //   <Redirect to="/login" />
      //   // </Route>
      // }
    }
  }

  useEffect(() => {
    getReporte()
  }, [])




  // const ComponentToPrint = React.forwardRef((props, ref) => (
  //   <div ref={ref}>Hello World</div>
  // ));

  if(!props.isOpen){
    return null;
  };

  const switchGraficas = (grafica) => {

    console.log(grafica)
    return (<>
      <RenderGrafica grafica={grafica} />
      <br/>
    </>
    )
  }

  return reactDOM.createPortal(
    <div className='editMmodalContainer' >
      <div id='cardResumenFactor'  className='editModalCard'
        style={{
          width: '70%',
          backgroundColor: 'white'


        }}
      >
        <div className='closeButtonContainer'>
          <CloseIcon onClick={() => { props.setIsOpen(false) }} />
        </div>
        <div
          style={{
            height:'100px',
            display: 'flex',
            justifyContent:'center',
            alignItems: 'center'
          }}
        >
        <h3 className='pagesTitles' > {t('panorama_riesgos.actividades_factor')} { props.item.concepto.toLowerCase()}</h3>
        </div>

        {initialState.loading === false
          // && initialState.data.graficas
          // && initialState.data.graficas.length > 0
          && initialState.error === null
          ?

        <div id='contenedorGraficas'  className='scrollSecundario'>
          <div id ='graficasList' >

            {props?.item?.id_widget === 1 && initialState?.data?.social?.graficas.length
              ? initialState?.data?.social?.graficas?.map((grafica) => { return switchGraficas(grafica)})
              : null
            }

            {props?.item?.id_widget === 2 && initialState?.data?.politico?.graficas.length
              ? initialState?.data?.politico?.graficas?.map((grafica) => { return switchGraficas(grafica)})
              : null
            }

            {props?.item?.id_widget === 3 && initialState?.data?.economico?.graficas.length
              ? initialState?.data?.economico?.graficas?.map((grafica) => { return switchGraficas(grafica)})
              : null
            }

            {props?.item?.id_widget === 4 && initialState?.data?.fuerza?.graficas.length
              ? initialState?.data?.fuerza?.graficas?.map((grafica) => { return switchGraficas(grafica)})
              : null
            }

            {props?.item?.id_widget === 5 && initialState?.data?.amenazas?.graficas.length
              ? initialState?.data?.amenazas?.graficas?.map((grafica) => { return switchGraficas(grafica)})
              : null

            }
            <br />
            <br />
          </div>
        </div>

          : initialState.loading === true && initialState.error === null
            ?
              <Loader/>
            : initialState.loading === false && initialState.data.graficas && initialState.data.graficas.length < 0 && initialState.error === null
                ? <p>No se encontró información relacionada a los filtros realizados.</p>
                : initialState.loading === false  && initialState.error !== null
                    && <p>Error en la generación de resumen. </p>

        }
      </div>
    </div>,
    document.getElementById('EditModal')
  )




}
