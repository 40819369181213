import { peticionSinToken } from "./peticiones"
import { validarHash } from '../helpers/validarHash'
// import { ValidarSesion } from './validarSesion';


export const validarEntorno = async (url, tipoPeticion = "POST", data = {}) => {

    if (window.location.host === "localhost:3000"|| window.location.host === "localhost:3001") {

        //ValidarSesion(peticionSinToken(url));
        
        return peticionSinToken(url)
        //return peticionSinToken(url, tipoPeticion, data)
    } else {

        // console.log(data.hash)

        // await validarHash(data.hash)

        return peticionSinToken(url, tipoPeticion, data)
    }
}