import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { IconSVG } from '../icons/CloseSVG'
import '../styles/TablaEstudios.scss'
import Logo from '../../static/icons/cosinte-logo.png';
//import Logo from '../../static/icons/cosinte-logo.png';
import { SuccessSVG } from '../icons/SuccessSVG';
import moment from 'moment';
import { RiesgoSVG } from '../icons/RiesgoSVG';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';

export const ModalTablaEstudios = ({ modal, setModal, dataSelected }) => {


    const handleClose = () => {
        setModal(false)
    }

    return (
        <Modal show={modal} onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            
        >
            <Modal.Body>
                <div style={{ textAlign: 'right' }} onClick={handleClose}>
                    <IconSVG />
                </div>
                <Row>
                    <Col md={6}>
                        <div className=" titleContainer">
                            <h4 className='pagesTitles'>Detalle de registro</h4>
                            <div className='titleLine'>  </div>
                        </div>

                    </Col>
                    <Col md={6}>
                        <div className="container text-right mb-3">
                           <img src={Logo} alt="logo cosinte ltda" className='logoCosinte' />
                        </div>
                    </Col>

                    <Col  xs={4} className='text-center'>
                        <img alt="imgPerfile" style={{ height: '150px', width: '150px', borderRadius: '50%' }} src="https://image.freepik.com/vector-gratis/perfil-avatar-hombre-icono-redondo_24640-14044.jpg" />
                    </Col>
                    <Col  xs={4} className='listPopUp'>
                        <p>Nombre:</p>
                        <p>Identificación:</p>
                        <p>Teléfono</p>
                        <p>Correo</p>
                    </Col>
                    <Col  xs={4} className='listPopUp'>
                        <p>Dirección:</p>
                        <p>Barrio:</p>
                        <p>Ciudad:</p>
                        <p>País:</p>
                    </Col>
                    <Col xs={4} className='text-center mt-4 colEstadoRegistro'>
                        <p>Nivel de riesgo</p>
                        <SuccessSVG width={'100px'} height={'100px'} />
                    </Col>
                    <Col xs={8} className='mt-4'>
                        <p className='conceptoGeneral'>Concepto general</p>
                        <p className='pr-5'>Esse eiusmod consequat velit veniam in cupidatat nisi. Eiusmod officia excepteur ea ad eu qui ullamco ex. Enim ex mollit fugiat qui ea eiusmod sunt nostrud ullamco deserunt consequat voluptate qui id. Occaecat id mollit qui anim et Lorem sint adipisicing qui et dolor non incididunt. Minim consequat mollit commodo duis dolor laboris eiusmod commodo esse exercitation consectetur anim mollit.</p>
                    </Col>
                    <div className="overView">

                    
                    <Col>
                        <div className="container mt-2">
                            <h5 className='conceptoGeneral'>Última actualización </h5>
                            <table className='mx-auto'>
                                <thead>
                                    <tr className='trThead'>
                                        <th> Cuenta</th>
                                        <th> Cargo</th>
                                        <th> Area</th>
                                        <th> Activo</th>
                                        <th> Fecha de contratación</th>
                                        <th> Fecha de contratación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <th> Cosinte</th>
                                        <th> Diseñador</th>
                                        <th> Tecnologia</th>
                                        <th> Si</th>
                                        <th> {moment(new Date()).format('LLL')}</th>
                                        <th> {moment(new Date()).format('LLL')}</th>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </Col>
                    <Col>
                        <div className="container mt-4">
                            <h5 className='conceptoGeneral'>Datos de solicitud </h5>
                            <h5 className='conceptoGeneralD'>Aspecto Academico </h5>

                            <table className='mx-auto'>
                                <thead>
                                    <tr className='trThead'>
                                        <th> Nivel Educativo</th>
                                        <th> Programa</th>
                                        <th> Institución</th>
                                        <th> Año</th>
                                        <th> Estado</th>
                                        <th> Resultado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <th> Bachiller Académico</th>
                                        <th> </th>
                                        <th> Colegio John F.Kennedy</th>
                                        <th> 2004</th>
                                        <th> Verificado</th>
                                        <th><SuccessSVG /></th>
                                    </tr>
                                    <tr >
                                        <th> Tecnologo</th>
                                        <th> </th>
                                        <th> Servicio Nacional de Aprendizaje Sena</th>
                                        <th> En Curso</th>
                                        <th> Pendiente</th>
                                        <th><RiesgoSVG /></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>    
                    <Col>
                        <div className="container mt-4 mr-3">
                            <h5 className='conceptoGeneral'>Última actualización </h5>
                            <table className='mx-auto'>
                                <thead>
                                    <tr className='trThead'>
                                        <th> Cuenta</th>
                                        <th> Cargo</th>
                                        <th> Area</th>
                                        <th> Activo</th>
                                        <th> Fecha de contratación</th>
                                        <th> Fecha de contratación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <th> Cosinte</th>
                                        <th> Diseñador</th>
                                        <th> Tecnologia</th>
                                        <th> Si</th>
                                        <th> {moment(new Date()).format('LLL')}</th>
                                        <th> {moment(new Date()).format('LLL')}</th>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </Col>
                    <Col>
                        <div className="container mt-4">
                            <h5 className='conceptoGeneral'>Datos de solicitud </h5>
                            <h5 className='conceptoGeneralD'>Aspecto Academico </h5>

                            <table className='mx-auto'>
                                <thead>
                                    <tr className='trThead'>
                                        <th> Nivel Educativo</th>
                                        <th> Programa</th>
                                        <th> Institución</th>
                                        <th> Año</th>
                                        <th> Estado</th>
                                        <th> Resultado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <th> Bachiller Académico</th>
                                        <th> </th>
                                        <th> Colegio John F.Kennedy</th>
                                        <th> 2004</th>
                                        <th> Verificado</th>
                                        <th><SuccessSVG /></th>
                                    </tr>
                                    <tr >
                                        <th> Tecnologo</th>
                                        <th> </th>
                                        <th> Servicio Nacional de Aprendizaje Sena</th>
                                        <th> En Curso</th>
                                        <th> Pendiente</th>
                                        <th><RiesgoSVG /></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>    
                    </div>             
                    <Col xs={12}>
                        <div className="container mt-4 d-flex justify-content-end">
                            <Button 
                             className='mr-3 buttonPopUpEstudio'>
                                Compartir Informe
                                <ShareIcon style={{height:'20px'}} />
                            </Button>
                            <Button className='buttonPopUpEstudio'>
                                Descargar informe
                                
                                <GetAppIcon style={{height:'20px'}} />

                            </Button>
                        </div>
                    </Col>  
                </Row>

            </Modal.Body>
        </Modal>


    )
}
