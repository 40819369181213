import React from 'react'

import './Loader.scss'

export default function Loader() {
  return (
    <div className='loaderContainerModal'>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}
