import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../ui/Card';
import { ChartContainer } from "../../ui/Chart";
import styles from './HorizontalBarInfo.module.scss';

export default function HorizontalBarInfo({ titulo, descripcion, valores }) {
  // Encuentra el valor máximo de todos los valores para ajustar la escala del gráfico
  const maxValor = Math.max(...valores.map(v => v.valor));

  return (
    <Card className="max-w-xs">
      <CardHeader className={styles.card__header}>
        <CardTitle className={styles.card__title}>{titulo}</CardTitle>
        <CardDescription className={styles.card__description}>
          {descripcion}
        </CardDescription>
      </CardHeader>
      <CardContent className={styles.card__content}>
        {valores?.map((valor, index) => (
          <div key={index} className={styles.card__item}>
            <div className={styles.card__stat}>
              {valor?.valor}
              <span className={styles.card__stat__unit}>
                {valor?.unidad}
              </span>
            </div>
            <ChartContainer
              config={{
                steps: {
                  label: "Steps",
                  color: valor?.color
                },
              }}
              className={styles.card__chart__container}
            >
              <BarChart
                layout="vertical"
                margin={{
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                }}
                data={[
                  {
                    date: "2023",
                    steps: valor?.valor,
                  },
                ]}
              >
                <Bar
                  dataKey="steps"
                  fill={valor?.color}
                  radius={4}
                  barSize={32}
                />
                <YAxis
                  dataKey="date"
                  type="category"
                  tickCount={1}
                  hide
                />
                <XAxis
                  dataKey="steps"
                  type="number"
                  domain={[0, maxValor]} // Establece el rango del eje X desde 0 hasta el valor máximo
                  hide
                />
              </BarChart>
            </ChartContainer>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
