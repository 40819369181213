import React from 'react';
import { DragIndicator } from '@material-ui/icons';
import * as ResizablePrimitive from "react-resizable-panels";

class ResizablePanelGroup extends React.Component {
  render() {
    const { className, ...props } = this.props;
    return (
      <ResizablePrimitive.PanelGroup
        className={className}
        {...props}
      />
    );
  }
}

class ResizablePanel extends React.Component {
  render() {
    const { className, ...props } = this.props;
    return (
      <ResizablePrimitive.Panel
        className={className}
        {...props}
      />
    );
  }
}

class ResizableHandle extends React.Component {
  render() {
    const { withHandle, className, indicatorClassName, iconClassName, ...props } = this.props;
    return (
      <ResizablePrimitive.PanelResizeHandle
        className={className}
        {...props}
      >
        {withHandle && (
          <div className= {`indicator__handle ${indicatorClassName}`}>
            <div className={`indicator__handle__icon ${iconClassName}`}>
            <DragIndicator style={{ fontSize: '1rem' }} />
            </div>
          </div>
        )}
      </ResizablePrimitive.PanelResizeHandle>
    );
  }
}

export { ResizablePanelGroup, ResizablePanel, ResizableHandle }
