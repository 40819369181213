import { useEffect } from "react";
import { Form } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';

const Number = ({ item, handleInputChange, index, idx, reg, nombre, error, getValues, total, setTotal}) => {

    return(
        <Form.Group style={{ display: 'block' }}>
            <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : ""  }} >{ item.titulo }{item.es_requerido === true && '*'}</Form.Label>
            <CurrencyInput 
                value={parseInt(item.valor)}
                name={`asesoria.${index}.${item.nombre}`}
                key={`${index} - ${idx}`}
                className="form-control"
                ref={reg}
                {...reg(`asesoria.${index}.${item.nombre}`, {
                    required: item.es_requerido
                })}
                decimalsLimit={6}
                intlConfig={{
                    locale: 'es-CO',
                    currency: 'COP'
                }}  
                style={{ border: error ? '1px solid #dc3545' : null }}
                disabled
            />
        </Form.Group>
    );

}

export default Number;