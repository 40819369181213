import { React, useState } from 'react'
import { Grid } from '@material-ui/core';
import { Card } from 'react-bootstrap';

import EstudioBasico from './gerenciaGeneral/inteligencia/EstudioBasico'

import { HelpSVG } from '../components/icons/HelpSVG';
import PopUpModal from '../helpers/PopUpModal';


import './styles/FiltroSolicitudRegistro.scss'
import { useDispatch, useSelector } from 'react-redux';
import { solicitarData } from '../reducers/solicitudesEstudio';
import { useTranslation } from 'react-i18next';

function Filtro(props) {

  const [open, setOpen] = useState(false)


  const dispatch = useDispatch()

  const { selects } = props


  // const [tipoEstudio, setTipoEstudio] = useState();
  const [openModal] = useState(false);
  const [selectSelected, setSelectSelected] = useState('')


  // const showModal = () => {
  //   return setOpenModal(!openModal)
  // }

  const { data } = useSelector(state => state.solicitudEstudio)


  const [valorSelectInicial, setValorSelectInicial] = useState('0')

  const { t } = useTranslation();


  const handleChangeForm = (valor) => {

    console.log(valor);

    setValorSelectInicial(valor)
    const dataEnviar = {
      idSeleccionado: valor
    }
    dispatch(solicitarData(dataEnviar))

  }


  const filterRender = (props) => {

    switch (props.path) {
      case '/gerencia/inteligencia':
        return (
          <div style={{ height: 'auto', marginTop: '5px' }} alignItems="center" >
            {/* <h1>Estamos en inteligencia</h1>   */}
            <div className='helpIcon'>
              <div className='contenedorIcono' onClick={() => setOpen(true)}>
                <HelpSVG />
              </div>
            </div>
            <div className='contenedorSelectSolicitar'>
              <p className='tituloSelectSolicitar mb-3' >{t('solicitud_registro.genera_tu_solicitud')}</p>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <p className='pagesTitles' style={{ fontSize: '1.1rem', width: '170px', color: 'white', padding: 0, height: '100%', margin: 'auto 0' }} >{t('solicitud_registro.elige_un_tipo')} </p>
                <select className="selectSolicitar" id="" onChange={(e) => {
                  handleChangeForm(e.target.value)
                }}>
                  {
                    selects?.selects
                    &&
                    selects.selects.map((ele, index) => {
                      return <option value={ele.id} key={index}>{ele.label}</option>

                    })
                  }
                </select>

              </div>

            </div>
          </div>
        );


      // break;
      case '/gerencia/auditorias':
        return (
          <Grid className='' container alignItems="center" spacing={2}>
            {/* <h1>Estamos en inteligencia</h1>   */}
            <Grid item md={5} xs={11} style={{ display: 'flex' }}>
              <p style={{ width: '230px', marginTop: '5px', margin: 'auto 0' }} >Tipo </p>
              <select className="" id="">
                <option value="default">Seleccione el tipo de solicitud</option>
                <option value="Inteligencia 1">Estudio Auditorias 1</option>
                <option value="Inteligencia 2">Estudio Auditorias 2</option>
                <option value="Inteligencia 3">Estudio Auditorias 3</option>
              </select>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  }
  return (
    <>
      <div className='shadow contenedorTipoSolicitud'>
        <Card.Body className='cardBodySelectSolicitar'>
          {filterRender(props)}
        </Card.Body>
      </div>
      <br />
      {/* {
        data !== null
        && <FormBase contenido={data} />
      } */}
      <PopUpModal open={open} setOpen={setOpen} titulo={selects.titulo} texto={selects.texto} />

      {


        data !== null
        && <EstudioBasico modalState={openModal} data={data} selectSelected={selectSelected} selectSeleccionado={valorSelectInicial} setValorSelectInicial={setValorSelectInicial} />

      }



    </>
  )
}

export default Filtro;
