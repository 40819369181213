import { Fragment, React, useEffect, useState } from 'react'
// import { Grid } from '@material-ui/core';


import './EstudioBasico.scss'
import GestorDocumentos from './GestorDocumentos';
import { useSelector } from 'react-redux';

import { InformacionGeneral } from './Pestañas/InformacionGeneral';
import SolicitarDinamico from './Pestañas/SolicitarDinamico';
import { InformacionSolicitud } from './Pestañas/InformacionSolicitud';
import { EstudioBasicoPestaña } from './Pestañas/EstudioBasicoPestaña';
import { EstudioConfiabilidadPestaña } from './Pestañas/EstudioConfiabilidadPestaña';
import { Pqr } from './Pestañas/Pqr';
import Investigacion from '../investigaciones/Investigacion';
import SolicitudVisita from '../investigaciones/SolicitudVisita';
import VisitaCredito from '../investigaciones/VisitaCredito';
import { EstudioRiesgos } from '../investigaciones/EstudioRiesgos';

import { ContenidoForms } from '../../Ocensa/componentsSolicitar/ContenidoForms'
import { SolPanoramaRiesgos } from '../../Ocensa/componentsSolicitar/SolPanoramaRiesgos'

import SolicitudRegistroAuditorias from '../operaciones/SolicitudRegistroAuditorias'


import { BeneficiariosEditarEstudio } from '../BeneficiariosEditarEstudio';

export default function EstudioBasico({ selectSeleccionado, setValorSelectInicial, ...props }) {

  const crearSolicitud = useSelector(state => state.crearSolicitud)
  const codigoFuncion = crearSolicitud.codigo ? crearSolicitud.codigo : undefined

  // console.log(crearSolicitud);
  // console.log(codigoFuncion);


  const { data } = props


  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pestaña, setPestaña] = useState(data.data[0].pestana);
  const [formState, setFormState] = useState(false)



  const { data: camp } = useSelector(state => state.solicitudEstudio)


  const { titulo } = camp


  useEffect(() => {
    setPestaña(data?.data[0].pestana)
  }, [data])

  return (
    <div>
      {selectSeleccionado !== '0' && (
        <>
          <div style={{ display: 'flex', textAlign: 'center' }}>

            {
              data.data.map((ele, index) => {

                return <div
                  key={index}
                  className="d-flex pestañasLi"
                  style={{ background: pestaña !== ele.pestana && '#CBD7E6' }}
                  onClick={() => setPestaña(ele.pestana)}
                >
                  <p className=" p-0 m-0">
                    {ele.label ? ele.label :
                      ele.pestana === 'Estudio básico'
                        ? 'Información general'
                        : ele.pestana === 'Estudio confiabilidad'
                          ? 'Información general'
                          : ele.pestana === 'registro_operaciones'
                            ? 'Información general'
                            : ele.pestana === 'informe_panorama_riesgos'
                              ? 'Información general'
                              : ele.pestana === 'solicitud_visita'
                                ? 'Solicitud visita'
                                : ele.pestana === 'visita_credito'
                                  ? 'Visita crédito'
                                  : ele.pestana === 'investigacion'
                                    ? 'Investigación'
                                    : ele.pestana === 'estudio_riesgos'
                                      ? 'Estudio de riesgo en proceso'
                                      : ele.pestana === 'panorama_riesgos'
                                        ? 'Información general'
                                        : ele.pestana === 'solicitar'
                                          ? ele.label
                                          : ele.pestana




                    }
                  </p>
                  <b style={{
                    width: '25px',
                    fontSize: '30px',
                    marginTop: '-20px',
                    fontWeight: '900',
                    color: index === 0 && crearSolicitud.pestaña1.estado
                      ? '#00A20B'
                      : index === 1 && crearSolicitud.pestaña2
                        ? '#00A20B'
                        : index === 2 && crearSolicitud.pestaña3
                          ? '#00A20B'
                          : index === 3 && crearSolicitud.pestaña4
                            ? '#00A20B'
                            : index === 4 && crearSolicitud.pestaña5
                              ? '#00A20B'
                              : 'red'
                  }}>.</b>

                </div>
              })
            }
          </div>
        </>
      )}
      {data?.data?.map((ele, index) => {
        if (ele.pestana === pestaña) {
          return pestaña === 'Información general'
            ? <InformacionGeneral ele={ele} key={index} propiedades={props} index={index} selects={props.selectSelected} pestaña={pestaña} selectSeleccionado={selectSeleccionado} />
            : pestaña === 'Activar investigaciones'
              ? <InformacionSolicitud setValidated={setValidated} index={index} key={index} selects={props.selectSelected} selectSeleccionado={selectSeleccionado} />
              : pestaña === 'Estudio básico'
                ? <EstudioBasicoPestaña ele={ele} key={index} propiedades={props} index={index} selects={props.selectSelected} pestaña={pestaña} selectSeleccionado={selectSeleccionado} />
                : pestaña === 'Estudio confiabilidad'
                  ? <EstudioConfiabilidadPestaña ele={ele} key={index} propiedades={props} index={index} selects={props.selectSelected} pestaña={pestaña} selectSeleccionado={selectSeleccionado} />
                  : pestaña === 'Beneficiarios'
                    ? <BeneficiariosEditarEstudio key={index} setFormState={setFormState} formState={formState} setValidated={setValidated} selectSeleccionado={selectSeleccionado} />
                    : pestaña === 'Gestor documental'
                      ? <GestorDocumentos key={index} />
                      : pestaña === 'PQR'
                        ? <Pqr key={index} />
                        : pestaña === 'registro_operaciones'
                          ? <SolicitudRegistroAuditorias contenido={ele.contenido} selectSeleccionado={selectSeleccionado} key={index} />
                          : pestaña === 'informe_panorama_riesgos'
                            ? <ContenidoForms contenido={ele.contenido} />
                            : pestaña === 'investigacion'
                              ? <Investigacion contenido={ele.contenido} key={index} />
                              : pestaña === 'solicitud_visita'
                                ? <SolicitudVisita contenido={ele.contenido} key={index} />
                                : pestaña === 'visita_credito'
                                  ? <VisitaCredito contenido={ele} key={index} />
                                  : pestaña === 'estudio_riesgos'
                                    ? <EstudioRiesgos ele={ele} key={index} propiedades={props} index={index} selects={props.selectSelected} pestaña={pestaña} selectSeleccionado={selectSeleccionado} />
                                    : pestaña === 'panorama_riesgos'
                                      ? <SolPanoramaRiesgos ele={ele} key={index} propiedades={props} index={index} selects={props.selectSelected} pestaña={pestaña} selectSeleccionado={selectSeleccionado} />
                                      : pestaña === 'solicitar' && selectSeleccionado !== '0'
                                        ? <SolicitarDinamico ele={ele} key={index} contenido={ele.contenido} propiedades={props} index={index} selects={props.selectSelected} pestaña={pestaña} selectSeleccionado={selectSeleccionado} setValorSelectInicial={setValorSelectInicial} />
                                        : null


        }
        return <Fragment key={index}></Fragment>
      })
      }
    </div >
  )
}
