import React from 'react';

export const ErrorSVG = ({ width, height }) => {
  return (
    // <svg id="Grupo_415" data-name="Grupo 415" xmlns="http://www.w3.org/2000/svg" width={width ? width:"18"} height={height ? height:"18"} viewBox="0 0 28 28">
    //     <g id="Elipse_38" data-name="Elipse 38" transform="translate(0)" fill="#fff" stroke="#fff" style={{ strokeWidth: "1" }} >
    //         <circle cx="14" cy="14" r="14" stroke="none" />
    //         <circle cx="14" cy="14" r="13.5" fill="none" />
    //     </g>
    //     <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M26.954,13.758a13.2,13.2,0,1,1-13.2-13.2A13.2,13.2,0,0,1,26.954,13.758ZM12.232,20.746l9.791-9.791a.851.851,0,0,0,0-1.2l-1.2-1.2a.851.851,0,0,0-1.2,0L11.63,16.532,7.9,12.8a.851.851,0,0,0-1.2,0l-1.2,1.2a.851.851,0,0,0,0,1.2l5.534,5.534A.851.851,0,0,0,12.232,20.746Z" transform="translate(0.194 0.194)" fill="#00a20b" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width ? width : "150"}
      height={height ? height : "150"}
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M257,0C116.39,0,0,114.39,0,255s116.39,257,257,257s255-116.39,255-257S397.61,0,257,0z M383.22,338.79
              c11.7,11.7,11.7,30.73,0,42.44c-11.61,11.6-30.64,11.79-42.44,0L257,297.42l-85.79,83.82c-11.7,11.7-30.73,11.7-42.44,0
              c-11.7-11.7-11.7-30.73,0-42.44l83.8-83.8l-83.8-83.8c-11.7-11.71-11.7-30.74,0-42.44c11.71-11.7,30.74-11.7,42.44,0
              L257,212.58l83.78-83.82c11.68-11.68,30.71-11.72,42.44,0c11.7,11.7,11.7,30.73,0,42.44l-83.8,83.8L383.22,338.79z"
              fill="#cc1a3a"
              data-original="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
