import React from 'react'
import { AttachFile, PictureAsPdf } from '@material-ui/icons';
import openDataUri from '../../helpers/openDataUri'

/**
 * Funcion que genera el recurso (enlace) del archivo
 * sin depender de donde provenga
 * @param {*} file
 * @returns
 */
const getTypeFile = (file) => {
  if (typeof (file) === 'string') {
    if (file.startsWith('data:')) {
      const type = file.split(';')[0].split('/')[1].toLowerCase();
      return type;
    } else if (file.startsWith('https:') || file.startsWith('http:')) {
      const typeUrl = file.split('.');

      if (typeUrl[1] === 'ciacosinte') {
        const typeUrl = file.split('.');
        const extension = typeUrl[typeUrl.length - 1].toLowerCase();
        return extension;
      } else {
        return 'external_resource';
      }
    }
  }
}

export default function FilePreview({src}) {
  return (
    <div style={{ width: '100%', marginTop: '10px' }}>
      {src !== '' ? ((getTypeFile(src) === 'jpeg' || getTypeFile(src) === 'png' || getTypeFile(src) === 'jpg' || getTypeFile(src) === 'gif' ? (
        <div className="container-previous-image container-previous-image--small" onClick={() => { openDataUri(src) }}>
          <img className="previous-image" width="100%" height="100%" src={src} alt="" style={{ objectFit:"contain" }} />
          <span className="previous-content previous-content--small">Ver imagen</span>
        </div>
      ) : (getTypeFile(src) === 'pdf' ? (
        <PictureAsPdf style={{ fontSize: 100, cursor: 'pointer' }} onClick={() => { openDataUri(src) }} />
      ) : (getTypeFile(src) === 'mp3' || getTypeFile(src) === 'mpeg' || getTypeFile(src) === 'wav' || getTypeFile(src) === 'ogg' ? (
        <>
          <audio id="my-audio" src={src} controls="controls" autobuffer="autobuffer">
            <source src={src} />
          </audio>
        </>
      ) : (getTypeFile(src) !== '' ? (<AttachFile style={{ fontSize: 100, cursor: 'pointer' }} onClick={() => { openDataUri(src) }} />) : null))
      ))
      ) : null}
    </div>
  )
}
