import React, {useState, useEffect} from 'react'
import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal'
import { HelpSVG } from '../icons/HelpSVG'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default function HerraduraExpandible(props) {

  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.RadarChart);
    chart.responsive.enabled = true;

    let label = chart.createChild(am4core.Label);
    label.text = "Deslice el slider para expandir";
    label.exportable = false;

    chart.data = props.data;
    chart.radius = am4core.percent(90);
    chart.startAngle = 270 - 180;
    chart.endAngle = 270 + 180;
    chart.innerRadius = am4core.percent(60);
    chart.colors.list = [
      // am4core.color("#2c5e99"),
      // am4core.color("#05a4db"),
      // am4core.color("#009688"),
      am4core.color("#F46036"),
      am4core.color("#8BC34A")
    ];
    // chart.height = am4core.percent(80);

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.labels.template.location = 0.4;
    categoryAxis.renderer.grid.template.strokeOpacity = 0.1;
    categoryAxis.mouseEnabled = true;
    // categoryAxis.renderer.labels.template.text = "[bold]{category}";
    // categoryAxis.renderer.ticks.template = "[bold]{category}"

    categoryAxis.renderer.labels.template.adapter.add("text", function(text) {
      return "[bold]" + text ;
    });


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = false;
    valueAxis.renderer.grid.template.strokeOpacity = 0.05;
    valueAxis.renderer.axisAngle = 260;
    valueAxis.renderer.labels.template.horizontalCenter = "bottom";
    // valueAxis.renderer.template.labels = '[bold]{category}'
    valueAxis.min = 0;

    // pieSeries2.labels.template.text = "[bold]{category}: {value.percent.formatNumber('.')}%";



    let series1 = chart.series.push(new am4charts.RadarColumnSeries());
    series1.columns.template.radarColumn.strokeOpacity = 1;
    series1.name = "Con anotación";
    series1.dataFields.categoryX = "category";
    series1.columns.template.text = "[bold]{category}";

    series1.columns.template.tooltipText = "{name}: {valueY.value}";
    series1.columns.template.propertyFields.fill = "fill";
  //  series1.columns.labels.template.text = "[bold]{category}";



    series1.dataFields.valueY = "Con";
    series1.stacked = true;

    // let series2 = chart.series.push(new am4charts.RadarColumnSeries());
    // series2.columns.template.radarColumn.strokeOpacity = 1;
    // series2.columns.template.tooltipText = "{name}: {valueY.value}";
    // series2.name = "Series 2";
    // series2.dataFields.categoryX = "category";
    // series2.dataFields.valueY = "value2";
    // series2.stacked = true;

    let series3 = chart.series.push(new am4charts.RadarColumnSeries());
    series3.columns.template.radarColumn.strokeOpacity = 1;
    series3.columns.template.tooltipText = "{name}: {valueY.value}";  
    series3.name = "Sin anotación";
    series3.dataFields.categoryX = "category";
    series3.dataFields.valueY = "Sin";
    series3.stacked = true;

    // let series4 = chart.series.push(new am4charts.RadarColumnSeries());
    // series4.columns.template.radarColumn.strokeOpacity = 1;
    // series4.columns.template.tooltipText = "{name}: {valueY.value}";
    // series4.name = "Series 4";
    // series4.dataFields.categoryX = "category";
    // series4.dataFields.valueY = "value4";
    // series4.stacked = true;

    chart.seriesContainer.zIndex = -1;

    let slider = chart.createChild(am4core.Slider);
    slider.start = 0.5;
    slider.exportable = false;
    slider.events.on("rangechanged", function() {
      let start = slider.start;

      chart.startAngle = 270 - start * 179 - 1;
      chart.endAngle = 270 + start * 179 + 1;

      valueAxis.renderer.axisAngle = chart.startAngle;
    });

    chart.legend = new am4charts.Legend();

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "Imagen" },
        // { "type": "csv", "label": "Tabla" },
        { "type": "print", "label": "Imprimir" }
      ]
    }];

  }, [])

  return (
    <Card className='shadow cardGraficaGeneral cardDivergente' >
     {/*  <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br />
      <Card.Body>
        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}>{props.title} 
        <br/>
        {props.parametrizado
          ?
            <>
              {props.parametrizado} - {props.periodo }
            </>

          : null
        }
        </h4>
        <br/>
        <br/>
        <div id={props.id} style={{ width: '100%', minHeight: '500px', margin: '0 auto ', height: 'auto' }}></div>
        <br/>
        <h4 className='pagesTitles' >
          Total: {props.total}
        </h4>
        {props.id === 'anotaciones' &&
          <>
            <br/>
            <div className='convencionesContainer'>
                <div className='contenedorx'>
                    <b className='convencion' id='sinAnotacion'> . </b>
                    Sin anotación
                </div>
                <div className='contenedorx'>
                    <b className='convencion' id='conAnotacion'> . </b>
                    Con anotación
                </div>
            </div>
          </>
        }
      
      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
