import React from 'react'
import { Card } from './Card'
import styles from './SelectorIndice.module.scss'

export default function SelectorIndice({ pestanas, activeIndex, setActiveIndex }) {
  return (
    <Card className={styles['selector-indice-container']}>
        {pestanas?.map((pestana, index) => (
          <div
            key={index}
            className={`${styles['selector-indice']} ${activeIndex === index ? styles['active'] : ''}`}
            onClick={() => setActiveIndex(index)}
          >
            {pestana?.nombre}
          </div>
        ))}
    </Card>
  )
}
