import React from 'react'
import { Modal } from 'react-bootstrap'
import { IconSVG } from '../components/icons/CloseSVG'

import './PopUpModal.scss'


const textoDefault = '2 1Proident excepteur elit do deserunt sunt sit irure culpa dolor proident amet. Do laborum reprehenderit mollit anim. Et culpa qui culpa ipsum proident labore anim velit do ullamco in cillum. Reprehenderit laborum veniam excepteur dolore aliquip. Cillum exercitation minim amet laborum sunt Lorem dolore aliquip.Ut ullamco deserunt qui velit non ut duis aute et labore irure labore. Quis aliquip nostrud labore est aute pariatur id cupidatat laboris non ipsum nisi consequat aute. Ipsum duis cillum adipisicing occaecat veniam nostrud incididunt occaecat. Do proident est cupidatat labore sunt sit laboris elit do elit voluptate nostrud nostrud. Irure in consectetur eu eu excepteur dolore quis tempor sit dolor eiusmod consectetur cillum adipisicing. Quis tempor irure voluptate nostrud aliquip dolor non id amet veniam labore amet ullamco elit.'
const tituloDefault = 'Ayuda'

export default function PopUpModal({titulo=tituloDefault, texto= textoDefault, open, setOpen, dataModal}) {

    const handleClose = () => {
        setOpen(false)
    }


    return (
        <Modal className='loginModal backdropFilt ' show={open} centered onHide={handleClose} >
        <Modal.Header  >
            {/* <Modal.Title> */}
                {/* <Row>
                    <Col xs={11} md={11}>
                        Ayuda
                    </Col>
                    <Col xs={1} md={1} onClick={handleClose}>
                        <IconSVG />
                    </Col>
                </Row> */}
                {/* <div className='modalTitle'> */}
                <Modal.Title>
                    <h4 className='pagesTitles'>{dataModal.tituloPopup}</h4>
                </Modal.Title>
                    <div onClick={handleClose}>
                        <IconSVG/>
                    </div>
                {/* </div> */}
            {/* </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
            {dataModal.contenidoPopup}
        </Modal.Body>
    </Modal>
    )
}
