import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { Card, CardContent, CardFooter } from '../../ui/Card';
import { ChartContainer, ChartTooltipContent, ChartTooltip } from "../../ui/Chart";
import styles from './BarFooter.module.scss';

export default function BarFooter({
  data,
  textos, 
  nameKey="categoria",
  dataKey="valor"
}) {

  const generateChartConfig = (data) => {
      const config = {};
      data?.forEach(item => {
          config[item[nameKey]] = { label: item.label };
      });
      return config;
  };

  const chartConfig = generateChartConfig(data);

  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <ChartContainer
          config={chartConfig}
          className={styles.chartContainer}>
          <BarChart
            margin={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 10,
            }}
            data={data}
            layout="vertical"
            barSize={32}
            barGap={2}
          >
            <XAxis type="number" dataKey={dataKey} hide />
            <YAxis
              dataKey="y"
              type="category"
              tickLine={false}
              tickMargin={4}
              axisLine={false}
              className="capitalize"
            />
            <Bar dataKey={dataKey} radius={5}></Bar>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel/>}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className={styles.cardFooter}>
        <div className={styles.footerItem}>
          {textos && textos.length > 0 && textos.map((texto, index) => (
            <>
              <div key={index} className={styles.footerItemGrid}>
                <div className={styles.footerItemText}>{texto.titulo}</div>
                <div className={styles.footerItemValue}>
                  {texto.valor}
                  <span className={styles.footerItemUnit}>
                    {texto.unidad}
                  </span>
                </div>
              </div>
              {index < textos.length - 1 && <div className={styles.verticalSeparator}></div>}
            </>
          ))}
        </div>
      </CardFooter>
    </Card>
  );
}