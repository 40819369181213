import React, { useState } from 'react'
import { PrimeReactProvider } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { Search } from '@material-ui/icons';
import { Pencil, Plus, Trash } from 'lucide-react';
import { AttachFile } from '@material-ui/icons';

//theme
import "primereact/resources/themes/lara-light-blue/theme.css";
//core
import "primereact/resources/primereact.min.css";
import './Tabla.scss'
import openDataUri from '../../helpers/openDataUri';
import FilePreview from './FilePreview';

export default function Tabla({
  columnas,
  filas,
  paginaActual,
  numeroPaginas = 1,
  numeroRegistrosTotal,
  numeroRegistrosPorPagina,
  handlePaginacion,
  handleSearch,
  handleEditar,
  handleCrear,
  handleEliminar,
  botonCrearDisabled = false,
  botonEditarVisible = true,
  botonCrearVisible = true,
  botonEliminarVisible = false,
  scrollHeight
}) {
  const columns = columnas?.map((columna) => {
    return {
      field: columna.value,
      label: columna.label
    }
  }) //Almacena las columnas de la tabla
  const [first, setFirst] = useState((paginaActual - 1) * numeroRegistrosPorPagina); //Indica el numero de registro inicial
  const [rows, setRows] = useState(numeroRegistrosPorPagina); //Indica el numero de registros por pagina
  const [busqueda, setBusqueda] = useState(''); //Almacena el valor de la busqueda
  const [columnasVisibles, setColumnasVisibles] = useState(columns); //Almacena las columnas visibles de la tabla

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }); //Almacena los filtros de la tabla

  const { t } = useTranslation(); //Hook para traducir palabras en el componente

  /**
   * Metodo que se ejecuta cuando se realizan cambios en la paginación de la tabla
   * @param {*} event Parámetro que contiene los datos de la paginación
   */

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    const filtro = {
      limite: event.rows,
      pagina: event.page + 1,
      busqueda: ""
    }
    handlePaginacion(filtro);
  }

  /**
   * Método que se ejecuta cuando se realiza una búsqueda en la tabla
   * Si el número de páginas es mayor a 1, se realiza búsqueda externa
   * Si el número de páginas es igual a 1, se realiza búsqueda interna con los filtros
   */

  const onSearch = () => {
    setFirst(0);
    const filtro = {
      limite: rows,
      pagina: 1,
      busqueda: busqueda
    }
    handleSearch(filtro);
  }

  /**
   * Método que se ejecuta cuando se realiza una búsqueda en la tabla (local),
   * actualiza el filtro global de la tabla
   * @param {*} e Parámetro que contiene el evento del input de búsqueda
   */

  const onLocalSearch = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setBusqueda(value);
  }

  /**
   * Plantilla de los botones de acción de la tabla
   */

  const actionTemplate = (rowData) => {
    return (
      <div className="d-flex justify-content-end gap-2">
        {botonEditarVisible &&
          <Button label={t('botones.editar')} icon={<Pencil size={18}></Pencil>} onClick={() => handleEditar(rowData)} size='small' />
        }
        {botonEliminarVisible &&
          <Button label={t('botones.eliminar')} icon={<Trash size={18}></Trash>} size='small' severity='danger' onClick={()=> handleEliminar(rowData)} />
        }
      </div>
    );
  }

  /**
   * Plantilla del componente de paginación de la tabla
   */

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none', fontSize: '14px' }}>
            Items por página:{' '}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center', fontSize: '14px' }}>
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    }
  };

  /**
   * Renderiza el header de la tabla, el cual contiene el input de búsqueda y el botón de crear
   * @returns Componente de header de la tabla
   */

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center pt-3">
        <div>
          <span className="p-input-icon-left">
            <Search style={{ fontSize: '18px' }}></Search>
            <InputText placeholder={t("formulario.label.buscar")} className='p-inputtext-sm' onChange={(e) => { onLocalSearch(e) }} value={busqueda} />
          </span>
          <MultiSelect
            value={columnasVisibles}
            options={columns}
            optionLabel="label"
            onChange={onColumnToggle}
            placeholder={t('formulario.label.columnas_visibles')}
            tooltip={t('formulario.label.seleccione_columnas')}
            tooltipOptions={{ style: { fontSize: '12px', padding: '0' } }}
            className="p-inputtext-sm p-0 ms-2 mw-300" />
          {numeroPaginas > 1 &&
            <Button label="Buscar" size='small' className='confirm__button ms-2' onClick={onSearch} />
          }
        </div>
        {botonCrearVisible &&
          <Button label={t('botones.agregar')} icon={<Plus size={18}></Plus>} size='small' className='confirm__button' onClick={handleCrear} disabled={botonCrearDisabled} />
        }
      </div>
    );
  };

  /**
   * Método que se ejecuta cuando se cambian las columnas visibles de la tabla
   * @param {*} event Parámetro que contiene las columnas visibles
   */

  const onColumnToggle = (event) => {
    console.log(event.value);
    setColumnasVisibles(event.value);
  };

  const registerTemplate = (e, campo) => {
    if (e[campo] !== undefined) {
      if (e[campo].includes('http') || e[campo].startsWith('/crm')) {
        return (
          <Button
            onClick={() => openDataUri(e[campo])}
            className='button__link'
            color='#213265'
            label="Abrir archivo" link
            icon={<AttachFile style={{ fontSize: '18px', marginRight: '8px' }}>
            </AttachFile>} />
        );
      }
      //si es base64
      if (e[campo].includes('data:image')) {
        return (
          <FilePreview src={e[campo]} />
        );
      }
    }
    return e[campo];
  }



  /**
   * Renderiza el componente de la tabla
   * @returns Componente de la tabla
   * @see https://primereact.org/datatable/
   */

  return (
    <PrimeReactProvider>
      <div className='py-1 mb-3'>
        <DataTable 
          scrollHeight={scrollHeight}
          value={filas}
          emptyMessage={t('formulario.label.no_registros')}
          header={renderHeader}
          className='datatable__dinamica'
          size='small'
          filters={filters}
          scrollable
          removableSort>
          {columnasVisibles?.map((columna) => {
            return <Column key={columna.field} field={columna.field} header={columna.label} sortable body={e => registerTemplate(e, columna.field)} />
          })}
          <Column body={actionTemplate} headerClassName="w-10rem" frozen alignFrozen="right" />
        </DataTable>
        {numeroPaginas > 1 &&
          <Paginator
            template={paginatorTemplate}
            first={first}
            rows={rows}
            totalRecords={numeroRegistrosTotal}
            onPageChange={(e) => onPageChange(e)}
            className="justify-content-end" />
        }
      </div>
    </PrimeReactProvider>
  )
}
