import React, { useState } from 'react';
import { Form, Col, Button, Table } from 'react-bootstrap';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../../../helpers/validarHost';
import NotificationModal from '../../../../modales/NotificationModal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const Bibliografia = ({ dataG, permisos }) => {
  const { t } = useTranslation();
  const [inputList, setInputList] = useState([{ bibliografia: "" }]);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [showModalDeleteNotification, setShowModalDeleteNotification] = useState(false);
  const [showModalConfirmNotification, setShowModalConfirmNotification] = useState(false);
  const [showModalEditNotification, setShowModalEditNotification] = useState(false);
  const { id } = useParams();
  const [dataTable, setDataTable] = useState(dataG ? dataG : []);
  const [dataTableForm, setDataTableForm] = useState({});
  const [indexDelete, setIndexDelete] = useState('');
  const [indexForm, setIndexForm] = useState('');

  const handleOpenModalNotification = () => setShowModalNotification(true);
  const handleCloseModalNotification = () => setShowModalNotification(false);

  const handleOpenModalDeleteNotification = (index) => {
    setShowModalDeleteNotification(true)
    setIndexDelete(index);
  };
  const handleCloseModalDeleteNotification = () => setShowModalDeleteNotification(false);

  const handleOpenModalConfirmNotification = () => setShowModalConfirmNotification(true);
  const handleCloseModalConfirmNotification = () => setShowModalConfirmNotification(false);

  const handleOpenModalEditNotification = () => setShowModalEditNotification(true);
  const handleCloseModalEditNotification = () => setShowModalEditNotification(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { bibliografia: "" }]);
  };

  const sendDataForm = async (e) => {

    e.preventDefault();

    const result = {};

    const dataSend = {
      tipo: 'bibliografia',
      formData: inputList,
      id_registro: id,
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    //console.log(dataSend)

    const response = await validarEntorno('JSON/respuesta_cuestionario_json.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();;

      if (respuesta.data.message) {
        setDataTable([...dataTable, ...inputList]);
        setInputList([{ bibliografia: "" }]);
        setShowModalNotification(true);
      }
    }
  }

  const deleteItem = async (index) => {

    const dst = dataTable[index];

    const dataSend = {
      tipo: 'bibliografia',
      id_bibliografia: dst.id_bib,
      id_registro: id,
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    const response = await validarEntorno('JSON/respuesta_eliminacion_item_json.html', 'POST', dataSend);

    if (response.ok) {
      const respuesta = await response.json();

      if (respuesta.data.message) {

        const dt = [...dataTable];

        dt.splice(index, 1);

        setDataTable(dt);

        handleCloseModalDeleteNotification();

        handleOpenModalConfirmNotification();
      }
    }
  }

  const editItem = async (index) => {

    const dataSend = {
      tipo: 'bibliografia',
      formData: dataTableForm,
      id_registro: id,
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    //console.log(dataSend);

    const response = await validarEntorno('JSON/respuesta_edicion_item_json.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();

      if (respuesta.data.message) {
        let dt = [...dataTable];

        let item = { ...dt[index] };

        item = dataTableForm;

        dt[index] = item;

        setDataTable(dt);

        setDataTableForm({});

        setIndexForm('');

        handleOpenModalEditNotification();
      }
    }

  }

  const availableInput = (index, item) => {
    //console.log(item);
    if (indexForm !== index) {
      setIndexForm(index);
      setDataTableForm(item);
    } else {
      setIndexForm('');
      setDataTableForm({});
    }
  }

  const handleTableChangeData = async (e) => {
    const value = e.target.value;
    setDataTableForm({
      ...dataTableForm,
      [e.target.name]: value
    });
  }

  return (
    <div className="container-audit-form">
      <h4 style={{ textAlign: 'start' }} className="pagesTitles">{t('gestion_realizada.cierre_informe.bibliografia')}</h4>
      <Form className="audit-form" onSubmit={sendDataForm}>
        <Form.Row>
          {inputList.map((item, index) => (
            <Fragment key={index}>
              <Form.Group as={Col} lg={12} role="form" controlId="formGridTextArea">
                <Form.Control
                  className="audit-form-textarea"
                  as="textarea"
                  name="bibliografia"
                  value={item.bibliografia}
                  onChange={e => handleInputChange(e, index)}
                  required
                />
                {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />}
                {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />}
              </Form.Group>
            </Fragment>
          ))}
        </Form.Row>
        <Form.Row className="btn-form-row">
        {permisos?.editar!==false && <Button className="confirmButton" type="submit">{t('formulario.actions.enviar')}</Button>}
        </Form.Row>
      </Form>
      <br />
      <br />
      <div className="table-audit-container">
        {dataTable.length > 0 ? (
          <>
            <h4 className="pagesTitles">{t('gestion_realizada.cierre_informe.lista_bibliografias')}</h4>
            <table responsive>
              <thead>
                <tr>
                  <th>{t('gestion_realizada.cierre_informe.definicion')}</th>
                  <th>{t('gestion_realizada.cierre_informe.acciones')}</th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((item, index) => (
                  <tr key={index} className='rowTabla' style={{ fontSize: '1rem' }}>
                    {indexForm === index ? (
                      <td style={{ width: '70%', padding: '0 20px' }} >
                        <div key={index} as={Col} lg={12} role="form" controlId="formGridTextArea">
                          <Form.Control
                            className="audit-form-textarea-obj"
                            as="textarea"
                            name="bibliografia"
                            defaultValue={item.bibliografia}
                            onChange={handleTableChangeData}
                          />
                        </div>
                      </td>
                    ) : (
                      <td style={{ textAlign: 'justify', width: '80%', paddingLeft: '10px' }} >{item.bibliografia}</td>
                    )}
                    <td >
                    {permisos?.editar!==false &&
                      <div className='botonesTabla' >
                        {indexForm === index && (
                          <button style={{ cursor: 'pointer' }} className='botonGuardarTabla botonTablaGeneral' onClick={() => { editItem(index) }}>
                            <SaveIcon fontSize='small' />
                            {t('botones.guardar')}
                          </button>
                        )}
                        <button style={{ cursor: 'pointer' }} className='botonEditarTabla botonTablaGeneral' onClick={() => { availableInput(index, item) }}>
                          {indexForm === index
                            ?
                            <>
                              <CloseIcon />
                              {t('botones.cancelar')}
                            </>
                            :
                            <>
                              <EditIcon fontSize='small' />
                              {t('botones.editar')}
                            </>

                          }
                        </button>
                        {indexForm !== index && (
                          <div style={{ cursor: 'pointer' }} className='botonEliminarTabla botonTablaGeneral' onClick={() => { handleOpenModalDeleteNotification(index) }}>
                            <DeleteIcon fontSize='small' />
                            {t('botones.eliminar')}
                          </div>
                        )}
                      </div>
                    }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal='saveQuestions'
      />
      <NotificationModal
        isOpen={showModalDeleteNotification}
        itemName="Bibliografía"
        setIsOpen={handleCloseModalDeleteNotification}
        typeModal='deleteItemConfirm'
        handleEliminar={deleteItem}
        indexDelete={indexDelete}
      />
      <NotificationModal
        isOpen={showModalConfirmNotification}
        itemName="Bibliografía"
        setIsOpen={handleCloseModalConfirmNotification}
        typeModal='notificacionItemEliminado'
        handleEliminar={deleteItem}
        indexDelete={indexDelete}
      />
      <NotificationModal
        isOpen={showModalEditNotification}
        itemName="Bibliografía"
        setIsOpen={handleCloseModalEditNotification}
        typeModal='notificacionItemActualizacion'
      />
    </div>
  )
}

export default Bibliografia;
