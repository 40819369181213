import { Form } from "react-bootstrap";
import { BtnAyuda } from "./BtnAyuda";

const Date = ({ item, handleInputChange, index, idx, reg, nombre, error, esEditable}) => {

    return(
        <Form.Group style={{ display: 'block' }}>
            <div style={{display: 'flex', justifyContent: 'flex-start'}} >
                <Form.Label style={{ color: item.label_color ? item.label_color : '#000',  fontSize: item.font_size ? item.font_size : ""   }} >{ item.titulo  }{item.es_requerido === true && '*'}</Form.Label>
                {item.ayuda &&
                    <BtnAyuda ayuda={item.ayuda}/>
                }
            </div>
            <Form.Control
                name={`asesoria.${index}.${item.nombre}`}
                as='select'
                required={item.es_requerido}
                key={`${index} - ${idx}`}
                ref={reg}
                {...reg(`asesoria.${index}.${item.nombre}`, {
                    required: item.es_requerido
                })}
                defaultValue={item.valor}
                style={{ border: error ? '1px solid #dc3545' : null }}
                disabled={esEditable}
            >
                <option value='' disabled selected>-- seleccione --</option>
                {item.valores_opcion?.map((opcion, index) => (
                    <option key={index} value={opcion.value}>{ opcion.label }</option>
                ))}
            </Form.Control>
            { error ?
                (
                    error.type === 'required' ?
                    (
                        <span className="inputsInvalid">
                            Este campo es requerido
                        </span>
                    )
                    : null )
            : null }
        </Form.Group>
    );

}

export default Date;
