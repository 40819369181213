import React, {useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Alert({ alert, setAlert}) {




  const { vertical, horizontal, content, open, type} = alert;


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({...alert, open: false});
  };

  return (
    <Snackbar 
      autoHideDuration={5000}
      severity="success"
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
    >
      <MuiAlert 
        variant='filled'
        elevation={6}
        severity={
          type === 'ok' 
          ? 'success'
          : type === 'error'
            ? 'error'
            : type === 'warning'
              ? 'warning'
              : type === 'info'
                && 'info'
        }
      >
        {content}
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </MuiAlert>

    </Snackbar>
  )
}

export { Alert }
