import React from 'react'
import { Form } from "react-bootstrap";
import { BtnAyuda } from "./BtnAyuda";
import { EmailOutlined } from '@material-ui/icons';
import InputGroup from 'react-bootstrap/InputGroup';

function EmailInput({ item, index, idx, reg, error, esEditable }) {

  return (
    <div>
      <Form.Group style={{display:'block', padding: '0px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
          <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : "" }}>{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
          {item.ayuda &&
            <BtnAyuda ayuda={item.ayuda} />
          }
        </div>
        <InputGroup className="mb-3">
          {/*
          <InputGroup.Text aria-describedby="basic-addon1">@</InputGroup.Text>
          */}
          <Form.Control
            aria-describedby="basic-addon1"
            defaultValue={item.valor}
            name={`asesoria.${index}.${item.nombre}`}
            key={`${index} - ${idx}`}
            ref={reg}
            {...reg(`asesoria.${index}.${item.nombre}`, {
              required: item.es_requerido,
              pattern: {
                value: /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})(\s+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})*$/i,
                message: "Dirección de correo electrónico inválida"
              }
            })}
            style={{ border: error ? '1px solid #dc3545' : null }}
            disabled={esEditable}
          />
        </InputGroup>
        {error &&
          (
            <>
              {
                error.type === 'required' && (
                  <span className="inputsInvalid">
                    Este campo es requerido
                  </span>
                )
              }
              {
                error.type === 'pattern' && (
                  <span className="inputsInvalid">
                    {error.message}
                  </span>
                )
              }
            </>

          )
        }

      </Form.Group>
    </div>
  )
}

export default EmailInput
