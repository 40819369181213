import React, { Fragment, useState } from 'react'
import { Card, Form } from 'react-bootstrap';
import '../../gerenciaGeneral/inteligencia/EstudioBasico.scss';
import { Alertas } from './criterios/Alertas';
import { EscenariosProspectivos } from './criterios/EscenariosProspectivos';
import { Estadisitica } from './criterios/Estadisitica';
import { FuentesPropias } from './criterios/FuentesPropias';
import { IdentificacionRiesgos } from './criterios/IdentificacionRiesgos';
import { InformacionSuministradaCliente } from './criterios/InformacionSuministradaCliente';
import { LineaTiempo } from './criterios/LineaTiempo';
import { MapasCalor } from './criterios/MapasCalor';
import { MediosComunicacion } from './criterios/MediosComunicacion';
import { PriorizacionEventos } from './criterios/PriorizacionEventos';
import { DebounceInput } from 'react-debounce-input';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import NotificationModal from '../../modales/NotificationModal';


import { validarEntorno } from '../../../helpers/validarHost'
import { useTranslation } from 'react-i18next';


// import InputType from '../../../helpers/InputType'


export const ContenidoForms = ({ titulo, contenido }) => {

  //COMPONENTE GENERADOR DE INFORMES DE PANORAMAS DE RIESGOS

  const { t } = useTranslation();

  const { register, reset, formState: { errors }, getValues } = useForm();
  const [isDisabled, setIsDisabled] = useState(false)
  const [validated, setValidated] = useState(false);
  const [mostrarSubseccion, setMostrarSubseccion] = useState(false)
  const [form, setForm] = useState({})
  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    data: {
      mensaje: '',
      id: ''
    },
  })
  const [selectsInitials, setSelectsInitials] = useState({
    componente: false,
    criterio: false,
    seccion: false,
    cuenta: false,
    departamento: false,
    titulo: false
  })

  const getMultiples = (value, tipo) => {

    // console.log(value)
    // console.log(tipo)
    setForm({
      ...form,
      [tipo]: value
    })
  }

  const handleChange = (e) => {

    setSelectsInitials({
      ...selectsInitials,
      [e.target.name]: e.target.value
    })

    setForm({
      ...form,
      [e.target.name]: e.target.value

    })

    console.log(form)

    if (selectsInitials.componente && selectsInitials.criterio && selectsInitials.seccion && selectsInitials.cuenta && selectsInitials.departamento && selectsInitials.titulo) {
      setMostrarSubseccion(true)
    }
  }

  const handleSubmit = async (e) => {

    e.preventDefault()

    const f = e.currentTarget;
    if (f.checkValidity() === false) {
      e.preventDefault();
    }

    setValidated(true)

    if (f.checkValidity() === true) {

      setIsDisabled(true)


      const dataReq = {
        hash: window.location.hash,
        dataForm: form
      }

      console.log(dataReq)

      const req = await validarEntorno('JSON/sol_inf_panorama.html', 'POST', dataReq);

      if (req.ok) {
        const res = await req.json()
        if (res.estado === "ok") {
          setModal({
            ...modal,
            show: true,
            tipo: 'sol_inf_panorama_ok',
            data: { mensaje: res.mensaje, id: res.id }

          })
          setIsDisabled(false)
        } else {
          setModal({
            ...modal,
            show: true,
            tipo: 'errorFormSolicitud',
            data: { mensaje: res.mensaje }
          })
          setIsDisabled(false)

        }

        setIsDisabled(false)

      }
    }




  }


  return (
    <Fragment>



      {/* Para Primer select */}
      <Card className='shadow cardContainerSolicitud'>
        <h5 className='titleCard'>{t('formulario.label.informacion_solicitud')}</h5>
        <Card.Body>
          <br />
          <Form noValidate validated={validated} onSubmit={handleSubmit} className='formEstudioBasicoContainer' >
            <div className='formItem' id='basicFormA'>
              <Form.Group>
                <Form.Label>
                  {t('formulario.label.nombre')}
                </Form.Label>
                <div className='inputContainer'>
                  <Form.Control

                    onChange={handleChange} name='nombre' required type='text' placeholder={t('formulario.label.nombre')} ></Form.Control>
                  <Form.Control.Feedback type='invalid'>{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  {t('formulario.label.fecha_inicial')}
                </Form.Label>
                <div className='inputContainer'>
                  <Form.Control name="fecha_inicial" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                  <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_fecha')} </Form.Control.Feedback>
                </div>
              </Form.Group>


              {/* <Form.Group>
                            <Form.Label>
                                Fecha vencimiento
                            </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control name="fecha_ven" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa una fecha </Form.Control.Feedback>
                            </div>
                        </Form.Group> */}
              {/* <Form.Group>
                            <Form.Label>
                                Analistas asignados
                            </Form.Label>
                            <div className='inputContainer'>
                                <Select
                                    id='selectPersonalGerencia'
                                    // ref={reg}
                                    classNamePrefix="addl-class"
                                    // options={item.valores_opcion}
                                    name={'analistas'}
                                    // defaultValue={item.valor}
                                    options={contenido?.analistas}
                                    onChange={(e) => getMultiples(e, 'analistas')}
                                    // onChange={(e) => onChange(getMultiples(e))}
                                    closeMenuOnSelect={false}
                                    isMulti= {true}
                                    placeholder='-- Seleccione --'
                                    // isDisabled={esEditable}
                                />
                                <Form.Control.Feedback type='invalid'>Ingresa el nombre del responsable </Form.Control.Feedback>
                            </div>
                        </Form.Group> */}




            </div>
            <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.panoramas')}</Form.Label>
                <Select
                  id='selectPersonalGerencia'
                  // ref={reg}
                  classNamePrefix="addl-class"
                  // options={item.valores_opcion}
                  name={'panoramas'}
                  // defaultValue={item.valor}
                  // value={item.valores_opcion.find(c => c.value === value)}
                  // onChange={(e) => onChange(getMultiples(e))}
                  onChange={(e) => getMultiples(e, 'panoramas')}
                  options={contenido?.panoramas}

                  closeMenuOnSelect={false}
                  isMulti={true}
                  placeholder={t('formulario.label.seleccione')}
                // isDisabled={esEditable}
                />
                <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_cuenta')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  {t('formulario.label.fecha_final')}
                </Form.Label>
                <div className='inputContainer'>
                  <Form.Control name="fecha_fin" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                  <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_fecha')} </Form.Control.Feedback>
                </div>
              </Form.Group>



            </div>
            <div id='obsInfPan'>
              <Form.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'flex-start'
                }}
              >
                <Form.Label className='observacionesLabel'>
                  {t('formulario.label.observaciones')}
                </Form.Label>
                <Form.Control name='observaciones' id='observacionesTextArea' as='textarea'
                  placeholder={t('formulario.label.observaciones')}
                  // className="place"
                  onChange={handleChange}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_observaciones')}</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='buttonContainer'>
              <button disabled={isDisabled} type='submit' className='confirmButton' >{t('formulario.actions.enviar')}</button>
            </div>
          </Form>
        </Card.Body>

        <NotificationModal
          modal={modal}
          typeModal={modal.tipo}
          setIsOpen={setModal}
          isOpen={modal.show}
          mensaje={modal.data.mensaje}
          codigoSuccess={modal.data.id}
        />





        {/* componentes que se muestran despues del select de criterio.  */}


        {
          selectsInitials.criterio === "Información fuentes propias" && mostrarSubseccion
          &&
          <FuentesPropias />
        }

        {
          selectsInitials.criterio === "Información suministrada por el cliente" && mostrarSubseccion
          &&
          <InformacionSuministradaCliente />

        }

        {
          selectsInitials.criterio === "Medios de comunicación" && mostrarSubseccion
          &&
          <MediosComunicacion />

        }

        {
          selectsInitials.criterio === "Estadísitica" && mostrarSubseccion
          &&
          <Estadisitica seccion={selectsInitials.seccion} />
        }

        {
          selectsInitials.criterio === "Línea de tiempo" && mostrarSubseccion
          &&
          <LineaTiempo />
        }
        {
          selectsInitials.criterio === "Alertas" && mostrarSubseccion
          &&
          <Alertas seccion={selectsInitials.seccion} />
        }


        {
          selectsInitials.criterio === "Escenarios prospectivos" && mostrarSubseccion
          &&
          <EscenariosProspectivos seccion={selectsInitials.seccion} />
        }

        {
          selectsInitials.criterio === "Mapas de calor" && mostrarSubseccion
          &&
          <MapasCalor seccion={selectsInitials.seccion} />
        }
        {
          selectsInitials.criterio === "Priorización de eventos" && mostrarSubseccion
          &&
          <PriorizacionEventos />
        }

        {
          selectsInitials.seccion === "Identificación de riesgos"
          &&
          <IdentificacionRiesgos />
        }

      </Card>
    </Fragment>
  )
}
