import { validarEntorno } from './validarHost';

const fetchApi = async (
  url,
  method,
  data,
  setNotificationModal,
  handleOnClose,
  handleOnCloseError
) => {
  try {
    const response = await validarEntorno(url, method, data);
    const json = await response.json();
    if (response.ok) {
      setNotificationModal({
        show: true,
        tipo: json.estado,
        typeModal: 'notificacion',
        titulo: json.titulo,
        mensaje: json.mensaje,
        handleOnClose: () => {
          if (json.estado === 'ok' || json.estado === 'OK') {
            handleOnClose?.();
          } else {
            handleOnCloseError?.();
          }
        },
      });
    }
  } catch (error) {
    setNotificationModal({
      show: true,
      tipo: 'error',
      typeModal: 'notificacion',
      titulo: 'Error',
      mensaje: 'Error al realizar la peticion',
      handleOnClose: () => {
        handleOnCloseError?.();
      },
    });
  }
};

/**
 * Se encarga de hacer una peticion a un servidor y mostrar una notificacion dependiendo de la respuesta
 * @param {*} url URL del endpoint
 * @param {*} method Metodo de la peticion
 * @param {*} data Datos a enviar
 * @param {*} setNotificationModal Funcion para mostrar la notificacion
 */

export const fetchAndNotify = async (
  url,
  method,
  data,
  setNotificationModal,
  shouldConfirm = false,
  handleOnClose,
  handleOnCloseError
) => {
  if (shouldConfirm) {
    setNotificationModal({
      show: true,
      typeModal: 'confirmacion',
      titulo: 'Confirmar',
      mensaje: '¿Estas seguro de realizar esta acción?',
      handleConfirm: () => {
        fetchApi(url, method, data, setNotificationModal, handleOnClose, handleOnCloseError);
      },
      handleOnClose: () => {
        handleOnCloseError?.();
      }
    });
  } else {
    fetchApi(url, method, data, setNotificationModal, handleOnClose, handleOnCloseError);
  }
};
