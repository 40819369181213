import { useState } from "react";
import Map from '../Mapas/Investigaciones/Heatmap';
import {credenciales} from  '../credentials.google';
import Loader from '../../components/icons/Loader';
import { Col, Form, Row } from "react-bootstrap";
import OpcionesBuscador from "../OpcionesBuscador";
import { municipios } from '../municipiosPanorama';


const Mapa = ({ item, reg, nombre}) => {

    //console.log(item);

    const [municipio, setMunicipio] = useState('');

    const [coordsMunicipio, setCoordsMunicipio] = useState({
        lat: item.ciudad_coords.lat !== "" ? item.ciudad_coords.lat : "4.612639",
        lng: item.ciudad_coords.lng !== "" ? item.ciudad_coords.lng : "-74.0705",
    });

    const [punto, setPunto] = useState({
        lat: item.valores.lat,
        lng: item.valores.lng
    });

    const [zoom, setZoom] = useState(5);
    const [descripcion, setDescripcion] = useState('');

    const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;

    /**
     * Según el punto seleccionado se cargan municipios
     * @param {*} e 
     */
    const chargePointMun = (e) => {
        if (e) {
            showMunicipio(e.value);
            setZoom(10);
            setMunicipio(e.value);
        } else {
            setMunicipio('');
            showMunicipio('');
            setZoom(5);
        }
    }

    /**
     * Establece coordenadas segun el municipio seleccionado
     * @param {*} value 
     */
    const showMunicipio = (value) => {
        let geocoder;
        if(value !== ''){
          // eslint-disable-next-line array-callback-return
          // eslint-disable-next-line no-undef
            geocoder = new google.maps.Geocoder();

            geocoder.geocode({ 
            'address': value
            }, function(results, status) {
             // eslint-disable-next-line array-callback-return
          // eslint-disable-next-line no-undef  
            if (status === google.maps.GeocoderStatus.OK) {

                let Lat = results[0].geometry.location.lat();
                let Lng = results[0].geometry.location.lng();

                setCoordsMunicipio({
                    ...coordsMunicipio,
                    lat: Lat,
                    lng: Lng
                }); 
            } else {
                console.log("Something got wrong " + status);
            }
        })
    }
}

    return (
        <>
            <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                    <Form.Label>Ciudad/Municipio</Form.Label>
                        <OpcionesBuscador 
                            data={municipios} 
                            name="tipo" 
                            label="Tipo" 
                            handleChange={(e) => chargePointMun(e)}
                            placeholder="Seleccione un tipo de demarción..." 
                            style={{width: "100%", minWidth: "350px" }}  
                            id="tipo-seleccion"
                        />
                        <input type="hidden" ref={reg} name={`${nombre}.ciudad.municipio`} value={municipio} />
                        <input type="hidden" ref={reg} name={`${nombre}.ciudad.lat-mun`} value={coordsMunicipio.lat} />
                        <input type="hidden" ref={reg} name={`${nombre}.ciudad.lng-mun`} value={coordsMunicipio.lng} />
                </Form.Group> 
            </Col> 
                <Row>
                    <Col xs={6}>
                        <Form.Group className='formGroupVertical' >
                            <Form.Label>Latitud</Form.Label>
                            <Form.Control 
                                value={punto.lat}
                                ref={reg}
                                name={`${nombre}.valores.lat`} 
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group className='formGroupVertical' >
                            <Form.Label>Longitud</Form.Label>
                            <Form.Control 
                                value={punto.lng}
                                ref={reg}
                                name={`${nombre}.valores.lng`} 
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group className='formGroupVertical' >
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control 
                                as="textarea"
                                value={descripcion}
                                ref={reg}
                                name={`${nombre}.valores.descripcion`} 
                                readOnly
                                style={{ height: '100px' }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            <div className="containerOcs">
                <div id='' className="mapa">
                    <Map 
                        googleMapURL= {mapURL}
                        containerElement = {<div style={{height:'100%'}}></div>}
                        mapElement = {<div style={{height:'100%'}}></div>}
                        loadingElement = {<Loader />}
                        latitud={coordsMunicipio.lat} 
                        longitud={coordsMunicipio.lng}
                        marker={punto}
                        setPunto={setPunto}
                        zoom={zoom}
                        descripcion={descripcion}
                        setDescripcion={setDescripcion}
                    />
                </div>
            </div>


        </>
    );
}

export default Mapa;