import React, { useState } from 'react';
import reactDOM from 'react-dom'

import { Button, Modal, Form } from 'react-bootstrap';
import FormObservacion from '../gestionAuditorias/FormObservaciones/FormObs';
import FormCuestionObservacion from '../gestionAuditorias/FormObservaciones/FormCuesObs';
import FormObservacionThird from '../gestionAuditorias/FormObservaciones/FormObsThird';
import ListObservations from '../gestionAuditorias/FormObservaciones/ListObservations';
import { validarEntorno } from '../../helpers/validarHost';
import NotificationModal from './NotificationModal';

import CloseIcon from '@material-ui/icons/Close';

import './GestionAuditoriasModal.scss';
import { useTranslation } from 'react-i18next';

const GestionAuditoriasModal = ({
  handleSubmit, show, handleClose, isOpen, index, mode, id_registro, id_cuestionario, id_tab, id_pregunta,
  handleOpenModalNotification, validated, setValidated, validatedFile, setValidatedFile, fetchQuestions
}) => {

  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [showModalNotification, setShowModalNotification] = useState(false);

  const [pieHallazgo, setPieHallazgo] = useState([{ imagen: "", pie_imagen: "" }]);
  const [pieRecomendacion, setPieRecomendacion] = useState([{ imagen: "", pie_imagen: "" }]);

  const handleCloseModalNotification = () => { setShowModalNotification(false) }

  const submitData = async (e) => {

    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      return null;
    }

    setValidated(true);

    const dataSend = {
      formData: data,
      pie_hallazgo: mode === 'form' ? pieHallazgo : {},
      pie_recomendacion: mode === 'form' ? pieRecomendacion : {},
      id_registro: id_registro,
      id_tab: id_tab,
      id_cuestionario: id_cuestionario,
      id_pregunta: id_pregunta,
      hash: window.location.hash
    };

    console.log(dataSend);

    let response = '';

    if (mode === 'form') {
      response = await validarEntorno('JSON/respuesta_observacion_pregunta_denegada.html', 'POST', dataSend);
    } else if (mode === 'form-obs') {
      response = await validarEntorno('JSON/respuesta_observacion_cuestionario.html', 'POST', dataSend);
    } else if (mode === 'form-obs-third' && Object.keys(data).length > 0) {
      response = await validarEntorno('JSON/respuesta_observacion_cuestionario_tercer_nivel_json.html', 'POST', dataSend);
    }

    if (response.ok) {
      const respuesta = await response.json();

      if (respuesta.data.message) {
        handleClose(index, 'Guardar', id_pregunta, e);
        setValidated(false);
        setValidatedFile(false);
        // setShowModalNotification(true);
        setPieHallazgo([{ imagen: "", pie_imagen: "" }]);
        setPieRecomendacion([{ imagen: "", pie_imagen: "" }])
        setData({});
      }
      // setShowModalNotification(true);
    }
  }

  if (!isOpen) {
    return null;
  };

  return reactDOM.createPortal(
    <div
      show={isOpen}
      size={mode === 'report' || mode === 'report-third' ? 'lg' : 'md'}
      onHide={handleClose}
      className="editMmodalContainer mdl-aud"
    >

      {mode === 'report' || mode === 'report-third' ? (
        <div className='editModalCard'
          style={{ width: '120%', minHeight: '500px' }}
        >
          <div
            className='closeButtonContainer'
            onClick={(e) => handleClose(index, 'Cerrar', e)}
          >
            <CloseIcon />
          </div>
          <Modal.Header className="mdl-audit-hdr">
            <Modal.Title className="pagesTitles">
              {t('gestion_realizada.areas_diagnostico.listado_observaciones')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListObservations
              handleOpenModalNotification={handleOpenModalNotification}
              id_pregunta={id_pregunta}
              handleClose={handleClose}
              mode={mode}
            />
          </Modal.Body>
          <Modal.Footer className="mdl-audit-ftr">
            <div className="button-modal-container">
              <button className="dangerButton" onClick={(e) => handleClose(index, 'Cerrar', e)}>
                {t('botones.cerrar')}
              </button>
            </div>
          </Modal.Footer>
        </div>
      ) : null}
      {mode === 'form' || mode === 'form-obs' || mode === 'form-obs-third' ? (
        <>
          <Form validated={validated} noValidate autoComplete="off" className="form-modal-audit"
            onSubmit={(e) => { submitData(e); (data.descripcion && data.descripcion !== '') && fetchQuestions() }}
          >
            <Modal.Header className="mdl-audit-hdr">
              <div className='d-flex justify-content-end w-100'>
                <div
                  className='closeButtonContainerAud'
                  onClick={(e) => handleClose(index, 'Cerrar', e)}
                >
                  <CloseIcon />
                </div>
              </div>
              <Modal.Title className="pagesTitles">
                {mode === 'form' ? t('modales.nuevo_hallazgo') : (mode === 'form-obs' ? 'Nueva observación' : (mode === 'form-obs-third' ? t('modales.nueva_observacion_componente') : null))}

              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {mode === 'form' ?
                <FormObservacion
                  data={data}
                  setData={setData}
                  validatedFile={validatedFile}
                  setValidatedFile={setValidatedFile}
                  pieHallazgo={pieHallazgo}
                  setPieHallazgo={setPieHallazgo}
                  pieRecomendacion={pieRecomendacion}
                  setPieRecomendacion={setPieRecomendacion}
                  submitData={() => submitData()}

                /> : null}
              {mode === 'form-obs' ? <FormCuestionObservacion data={data} setData={setData} validatedFile={validatedFile} setValidatedFile={setValidatedFile} /> : null}
              {mode === 'form-obs-third' ? <FormObservacionThird data={data} handleSubmit={submitData} setData={setData} validated={validated} validatedFile={validatedFile} setValidatedFile={setValidatedFile} /> : null}
              <br />
            </Modal.Body>
            <div className="contenedorMultiplesBotones">
              {mode !== 'report'
                ? mode === 'form-obs-third'
                  ?
                  <button
                    className="confirmButton"
                    type="submit"
                  // onClick={(e) => {fetchQuestions()}}
                  >
                    {t('botones.guardar')}
                  </button>
                  :
                  (
                    <button
                      className="confirmButton"
                      // type="submit"
                      onClick={(e) => { submitData(e); fetchQuestions() }}>
                      {t('botones.guardar')}
                    </button>
                  )
                : null}
              <button className="dangerButton" onClick={(e) => handleClose(index, 'Cerrar', e)}>
                {t('botones.cerrar')}
              </button>
            </div>
          </Form>
        </>
      ) : null}
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal="saveDataModal"
      />
    </div>
    ,
    document.getElementById('EditModal')

  );
}

export default GestionAuditoriasModal;
