import React, { useEffect } from 'react'

import Dashboard from '../pages/Dashboard';
import Inteligencia from '../pages/gerenciaGeneral/Inteligencia';
import Investigaciones from '../pages/gerenciaGeneral/Investigaciones/Investigaciones';
import BuscadorRegistro from '../pages/gerenciaGeneral/Investigaciones/InvestigacionesAdministrativas/BuscadorRegistro';
import Operaciones from '../pages/gerenciaGeneral/Operaciones';
import PanelGerencia from '../pages/gerenciaGeneral/PanelGerencia';
import Notifications from '../pages/Navbar/Notifications/Notifications';
import Perfil from '../pages/Navbar/Perfil/Perfil';
import EditarRegistroInvestigacionesAdministrativas from '../pages/gerenciaGeneral/Investigaciones/InvestigacionesAdministrativas/EditarRegistro'
import DashboardReportes from '../pages/gerenciaGeneral/Investigaciones/DashboardReportes'
// import { Route } from 'react-router';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { obtenerMenu } from '../reducers/menuReducer';

import { SolicitudRealizada } from '../pages/gerenciaGeneral/SolicitudRealizada';
import { Solicitar } from '../components/Ocensa/Solicitar';
// import { Administrar } from '../components/Ocensa/Administrar';
// import { Reportes } from '../components/Ocensa/Reportes';
// import PanelOcensa from '../components/Ocensa/PanelOcensa'
import { GestionRealizada } from '../pages/gestionAuditorias/GestionRealizada';
import GestorInformes from '../components/gestionAuditorias/PanoramaRiesgos/GestorInformes';
import { GestionInvestigaciones } from '../pages/Investigaciones/GestionInvestigaciones';
import GestionDinamica from '../components/GestionadorDinamico/Main';
import DetalleDep from '../components/presidencia/DetalleDep'

import PruebaMapa from '../components/mapaEjemplo/main';

import Panel from '../components/presidencia/Panel'
import ReportesOperativos from '../components/ReportesOperativos/Main'

import {GeneradorPDF} from '../components/GestorDocumentos/GeneradorPDF'

import CargueMasivo from '../components/ManejoArchivos/Cargue/Main';
import PanelDinamico from '../components/paneles/PanelDinamico';

export const DashboardRoutes = () => {

    const dispatch = useDispatch()

    // const login = useSelector(state => state.login)

    useEffect(() => {
        dispatch(obtenerMenu())
    }, [dispatch])

    return (

        <Dashboard>
            <Router>
                <Switch>
                    {/* NAVBAR */}
                    <Route exact path="/Notifications" component={Notifications} />
                    <Route exact path="/perfil" component={Perfil} />

                    {/* PRESIDENCIA */}
                    <Route exact path="/" component={PanelGerencia} />
                    <Route exact path="/home" component={PanelGerencia} />
                    <Route exact path="/dashboard-presidencia" component={PanelGerencia} />
                    {/* <Redirect to="/dashboard-presidencia" /> */}
                    {/* Administrativo y financiero */}
                    {/* <Route exact path='/presidencia/ayf/cartera' component={Cartera} /> */}
                    <Route exact path='/presidencia/administrativo/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/administrativo/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/administrativo/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/administrativo/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/administrativo/procesos' component={ReportesOperativos} />

                    <Route exact path='/presidencia/ayf/cartera/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/ayf/cartera/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/ayf/cartera/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/ayf/cartera/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/ayf/cartera/procesos' component={ReportesOperativos} />
                    <Route exact path='/presidencia' component={Operaciones} />


                    {/* <Route exact path='/presidencia/ayf/compras' component={Compras} /> */}
                    <Route exact path='/presidencia/ayf/compras/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/ayf/compras/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/ayf/compras/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/ayf/compras/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/ayf/compras/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/ayf/contabilidad' component={Contabilidad} /> */}
                    <Route exact path='/presidencia/ayf/contabilidad/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/ayf/contabilidad/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/ayf/contabilidad/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/ayf/contabilidad/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/ayf/contabilidad/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/ayf/facturacion' component={Facturacion} /> */}
                    <Route exact path='/presidencia/ayf/facturacion/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/ayf/facturacion/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/ayf/facturacion/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/ayf/facturacion/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/ayf/facturacion/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/ayf/servicios-generales' component={ServiciosGenerales} /> */}
                    <Route exact path='/presidencia/ayf/servicios-generales/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/ayf/servicios-generales/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/ayf/servicios-generales/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/ayf/servicios-generales/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/ayf/servicios-generales/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/ayf/SGI' component={GestionIntegral} /> */}
                    <Route exact path='/presidencia/ayf/SGI/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/ayf/SGI/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/ayf/SGI/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/ayf/SGI/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/ayf/SGI/procesos' component={ReportesOperativos} />


                    {/* Comercial */}
                    {/* <Route exact path='/presidencia/comercial/gestionComercial' component={GestionComercial} /> */}
                    <Route exact path='/presidencia/comercial/gestionComercial/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/comercial/gestionComercial/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/comercial/gestionComercial/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/comercial/gestionComercial/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/comercial/gestionComercial/procesos' component={ReportesOperativos} />
                    

                    {/* <Route exact path='/presidencia/comunicaciones/comunicaciones' component={Comunicaciones} /> */}
                    <Route exact path='/presidencia/comunicaciones/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/comunicaciones/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/comunicaciones/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/comunicaciones/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/comunicaciones/procesos' component={ReportesOperativos} />

                    {/* Comercial - Encuestas */}
                    <Route exact path='/presidencia/encuestas/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/encuestas/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/encuestas/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/encuestas/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/encuestas/procesos' component={ReportesOperativos} />

                    {/* gestion humana */}
                    {/* <Route exact path='/presidencia/gestionHumana/bienestarYdesarrollo' component={Bienestar} /> */}
                    <Route exact path='/presidencia/gestionHumana/bienestarYdesarrollo/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/bienestarYdesarrollo/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/bienestarYdesarrollo/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/bienestarYdesarrollo/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/bienestarYdesarrollo/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/gestionHumana/capacitacion' component={Capacitacion} /> */}
                    <Route exact path='/presidencia/gestionHumana/capacitacion/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/capacitacion/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/capacitacion/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/capacitacion/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/capacitacion/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/gestionHumana/contratacionYdesvinculacion' component={Contratacion} /> */}
                    <Route exact path='/presidencia/gestionHumana/contratacionYdesvinculacion/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/contratacionYdesvinculacion/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/contratacionYdesvinculacion/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/contratacionYdesvinculacion/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/contratacionYdesvinculacion/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/gestionHumana/nominaYseguridadSocial' component={Nomina} /> */}
                    <Route exact path='/presidencia/gestionHumana/nominaYseguridadSocial/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/nominaYseguridadSocial/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/nominaYseguridadSocial/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/nominaYseguridadSocial/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/nominaYseguridadSocial/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/gestionHumana/reclutamiento' component={Reclutamiento} /> */}
                    <Route exact path='/presidencia/gestionHumana/reclutamiento/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/reclutamiento/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/reclutamiento/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/reclutamiento/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/reclutamiento/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/gestionHumana/SSTyAmbiental' component={SST} /> */}
                    <Route exact path='/presidencia/gestionHumana/SSTyAmbiental/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/SSTyAmbiental/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/SSTyAmbiental/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/SSTyAmbiental/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/SSTyAmbiental/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/gestionHumana/gestion' component={gestion} /> */}
                    <Route exact path='/presidencia/gestionHumana/gestion/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/gestion/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/gestion/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/gestion/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/gestion/procesos' component={ReportesOperativos} />


                    {/* <Route exact path='/presidencia/gestionHumana/sgsst' component={sgsst} /> */}
                    <Route exact path='/presidencia/gestionHumana/sgsst/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/gestionHumana/sgsst/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/gestionHumana/sgsst/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/gestionHumana/sgsst/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/gestionHumana/sgsst/procesos' component={ReportesOperativos} />


                    {/* Juridico */}
                    <Route exact path='/presidencia/juridico/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/juridico/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/juridico/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/juridico/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/juridico/procesos' component={ReportesOperativos} />


                    {/* <Route exact path='/presidencia/juridico/procesos-administrativos' component={ProcesosAdministrativos} /> */}
                    <Route exact path='/presidencia/juridico/procesos-administrativos/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/juridico/procesos-administrativos/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/juridico/procesos-administrativos/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/juridico/procesos-administrativos/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/juridico/procesos-administrativos/procesos' component={ReportesOperativos} />
                    

                    {/* <Route exact path='/presidencia/juridico/procesos-civiles' component={ProcesosCiviles} /> */}
                    <Route exact path='/presidencia/juridico/procesos-civiles/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/juridico/procesos-civiles/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/juridico/procesos-civiles/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/juridico/procesos-civiles/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/juridico/procesos-civiles/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/juridico/procesos-comerciales' component={ProcesosComerciales} /> */}
                    <Route exact path='/presidencia/juridico/procesos-comerciales/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/juridico/procesos-comerciales/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/juridico/procesos-comerciales/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/juridico/procesos-comerciales/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/juridico/procesos-comerciales/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/juridico/procesos-laborales' component={ProcesosLaborales} /> */}
                    <Route exact path='/presidencia/juridico/procesos-laborales/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/juridico/procesos-laborales/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/juridico/procesos-laborales/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/juridico/procesos-laborales/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/juridico/procesos-laborales/procesos' component={ReportesOperativos} />

                    {/* TI */}
                    {/* <Route exact path='/presidencia/TI/configuracion' component={Configuracion} /> */}
                    <Route exact path='/presidencia/TI/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/TI/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/TI/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/TI/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/presidencia/TI/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/presidencia/TI/infraestructura' component={Infraestructura} /> */}
                    {/* <Route exact path='/presidencia/TI/infraestructura/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/TI/infraestructura/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/TI/infraestructura/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/TI/infraestructura/reportes_operativos' component={ReportesOperativos} /> */}

                    {/* <Route exact path='/presidencia/TI/seguridad-informacion' component={Seguridad} /> */}
                    {/* <Route exact path='/presidencia/TI/seguridad-informacion/solicitar' component={Inteligencia} />
                    <Route exact path='/presidencia/TI/seguridad-informacion/administrar' component={Investigaciones} />
                    <Route exact path='/presidencia/TI/seguridad-informacion/reportes' component={Operaciones} />
                    <Route exact path='/presidencia/TI/seguridad-informacion/reportes_operativos' component={ReportesOperativos} /> */}



                    {/* GERENCIA GENERAL */}
                    <Route exact path="/dashboard-gerencia" component={PanelGerencia} />
                    <Route exact path="/panel_panorama/:id" component={PanelGerencia} />
                    {/* <Route exact path="/dashboard-gerencia" component={PanelOcensa} /> */}

                    <Route exact path='/gerencia/auditorias/panorama_riesgos/:id/gestionar' component={Solicitar} />
                    <Route exact path='/gerencia/auditorias/panorama_riesgos/informe_:id' component={GestorInformes} />
                    <Route exact path='/gerencia/auditorias/solicitar' component={Inteligencia} />
                    <Route exact path='/gerencia/auditorias/administrar' component={Investigaciones} />

                    {/* <Route exact path='/gerencia/auditorias/solicitar/creada/:id' component={SolicitudRealizada} /> */}
                    <Route exact path='/gerencia/auditorias/reportes' component={PanelGerencia} />
                    <Route exact path='/gerencia/auditorias/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/gerencia/auditorias/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/gerencia/inteligencia' component={Inteligencia} /> */}
                    {/* <Route exact path='/gerencia/inteligencia/solicitar' component={Inteligencia} /> */}
                    <Route exact path='/gerencia/inteligencia/solicitar' component={Inteligencia} />
                    <Route exact path='/gerencia/inteligencia/administrar' component={Investigaciones} />
                    <Route exact path='/gerencia/inteligencia/administrar/:variable' component={Investigaciones} />
                    <Route exact path='/gerencia/inteligencia/administrar/:id/:tipoReporte' component={Investigaciones} />
                    <Route exact path='/gerencia/inteligencia/reportes' component={Operaciones} />
                    <Route exact path='/gerencia/inteligencia/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/gerencia/inteligencia/solicitar/creada/:id' component={SolicitudRealizada} />
                    <Route exact path="/gerencia/inteligencia/reportes/:id" component={DashboardReportes} />
                    <Route exact path='/gerencia/inteligencia/editar/:id' component={EditarRegistroInvestigacionesAdministrativas} />
                    <Route exact path='/gerencia/inteligencia/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/gerencia/:seccion/solicitar/creada/:id' component={SolicitudRealizada} /> */}



                    <Route exact path='/gerencia/operaciones' component={Operaciones} />

                    {/* Investigaciones */}
                    {/* <Route exact path='/gerencia/investigaciones' component={Investigaciones} /> */}
                    <Route exact path='/gerencia/investigaciones/solicitar' component={Inteligencia} />
                    <Route exact path='/gerencia/investigaciones/administrar' component={Investigaciones} />
                    <Route exact path='/gerencia/investigaciones/administrar/:variable' component={Investigaciones} />
                    <Route exact path='/gerencia/investigaciones/reportes' component={Operaciones} />
                    <Route exact path='/gerencia/investigaciones/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/gerencia/investigaciones/solicitar/creada/:id' component={SolicitudRealizada} />
                    <Route exact path='/gerencia/investigaciones/procesos' component={ReportesOperativos} />

                    {/* <Route exact path='/gerencia/investigaciones-administrativas' component={InvestigacionesAdministrativas} /> */}
                    <Route exact path='/gerencia/investigaciones-administrativas/solicitar' component={Inteligencia} />
                    <Route exact path='/gerencia/investigaciones-administrativas/solicitar/creada/:id' component={SolicitudRealizada} />

                    <Route exact path="/gerencia/investigaciones-administrativas/administrar" component={Investigaciones} />
                    <Route exact path='/gerencia/investigaciones-administrativas/administrar/:id/:tipoReporte' component={Investigaciones} />

                    {/* <Route exact path='/gerencia/:idSeccion/administrar/:id/:tipoReporte' component={Investigaciones} /> */}

                    <Route exact path="/gerencia/investigaciones-administrativas/administrar/:idSolicitud/editar_registro/" component={EditarRegistroInvestigacionesAdministrativas} />
                    <Route exact path="/gerencia/investigaciones-administrativas/reportes" component={Operaciones} />
                    <Route exact path="/gerencia/investigaciones-administrativas/reportes_operativos" component={ReportesOperativos} />
                    <Route exact path="/gerencia/investigaciones-administrativas/busqueda" component={BuscadorRegistro} />
                    <Route exact path="/gerencia/investigaciones-administrativas/reportes/:id" component={DashboardReportes} />
                    <Route exact path='/gerencia/investigaciones-administrativas/procesos' component={ReportesOperativos} />

                    {/* Reportes operativos llamado dinamico */}

                    <Route exact path='/presidencia/reportes' component={ReportesOperativos} />



                    {/* Operaciones / Ocensa */}
                    <Route exact path='/gerencia/operaciones/solicitar' component={Inteligencia} />
                    <Route exact path='/gerencia/operaciones/administrar' component={Investigaciones} />
                    <Route exact path='/gerencia/operaciones/reportes' component={Operaciones} />
                    <Route exact path='/gerencia/operaciones/reportes_operativos' component={ReportesOperativos} />
                    <Route exact path='/gerencia/operaciones/procesos' component={ReportesOperativos} />

                    {/* Gestion / Auditorias */}
                    <Route exact path='/gerencia/operaciones/solicitar/creada/:id' component={GestionRealizada} />
                    <Route exact path='/gerencia/auditorias/solicitar/creada/:id' component={GestionRealizada} />

                    {/* Gestion / Investigaciones */}
                    <Route exact path='/gerencia/investigaciones/creada/:id' component={GestionInvestigaciones} />

                    {/* Gestion dinamica */}
                    <Route exact path="/gerencia/:seccion/gestor/creada/:id" component={GestionDinamica} />
                    <Route exact path="/gerencia/:seccion/:subseccion/gestor/creada/:id" component={GestionDinamica} />

                    <Route exact path="/presidencia/:seccion/gestor/creada/:id" component={GestionDinamica} />
                    <Route exact path="/presidencia/:seccion/:subseccion/gestor/creada/:id" component={GestionDinamica} />

                    {/* PRESIDENCIA */}

                    <Route exact path="/dashboard-gerencia/:departamento/detalle_departamento/" component={DetalleDep} />
                    <Route exact path="/dashboard-gerencia/:departamento/detalle_departamento_panel/" component={Panel} />

                    {/* Reportes operativos */}


                    <Route exactn path="/pdf/" component={GeneradorPDF} />
                    <Route exact path="/reportes-operativos/" component={ReportesOperativos} />
                    <Route exact path="/prueba_mapa/" component={PruebaMapa} />

                    {/* Cargue - Descargue */}
                    <Route exact path="/cargue-masivo/" component={CargueMasivo} />
                    <Route exact path="/descargue/" component={ReportesOperativos} />

                </Switch>

            </Router>


        </Dashboard>


    )
}
