import RadialBarInfo from '../dashboard/bloques/RadialBarInfo';
import { Row, Col } from 'react-bootstrap';
import AreaGradientText from '../dashboard/bloques/AreaGradientText';
import CardBarSimple from '../dashboard/bloques/CardBarSimple';
import VerticalBarInfo from '../dashboard/bloques/VerticalBarInfo';


import { Responsive, WidthProvider } from 'react-grid-layout';
import { useState, useEffect } from 'react';
import Filtros from '../GestionadorDinamico/Filtros';
import { FetchGrafica } from '../../helpers/graficas/FetchGrafica';
import { validarEntorno } from '../../helpers/validarHost';
import LoadingCard from '../ui/LoadingCard';
import SelectorIndice from '../ui/SelectorIndice';
import Widgets from '../ui/Widgets';
import {useEventBus } from '../../context/EventBus';
const ResponsiveGridLayout = WidthProvider(Responsive);

function PanelDinamico({info}) {
    const [currentBreakpoint, setCurrentBreakpoint] = useState(''); //Breakpoint actual de la pantalla
    const [currentGraphIndex, setCurrentGraphIndex] = useState(0); //Indice de la gráfica actual en la cola
    const [currentTabIndex, setCurrentTabIndex] = useState(0); //Indice de la pestaña actual en el panel
    const [payload, setPayload] = useState({}); //Payload de los filtros
    const [data, setData] = useState(info); //Datos de las gráficas y pestañas
    const eventBus = useEventBus();

    /**
     * Carga de datos de las gráficas - Las gráficas se cargan de manera secuencial en una cola
     * Se realiza una petición a la URL de la gráfica para obtener los datos
     * Si la petición es exitosa se actualiza el estado de la gráfica con los datos obtenidos
     * Si la petición falla se continua con la siguiente gráfica
     */

    useEffect(() => {
        const cargarGrafica = async (index) => {
            if (index >= data.pestanas[currentTabIndex].graficas.length) return;
            const grafica = data.pestanas[currentTabIndex].graficas[index];
            try {
                //Simulate 3000 ms request
                //await new Promise(resolve => setTimeout(resolve, 100));

                const requestData = {
                    id: grafica.id,
                    tipo: grafica.tipo
                };

                if (payload) {
                    requestData.payload = payload;
                }

                //console.log("Request data", requestData);

                const request = await validarEntorno(grafica.url, "POST", requestData);
                if (request.ok) {
                    const response = await request.json();
                    setData((prevData) => {
                        const newData = { ...prevData };
                        newData.pestanas[currentTabIndex].graficas[index].data = response;
                        return newData;
                    });

                    setCurrentGraphIndex(index + 1);
                } else {
                    setCurrentGraphIndex(index + 1);
                }
            } catch (error) {
                console.error(`Error cargando datos para la gráfica ${grafica.id}`, error);
                setCurrentGraphIndex(index + 1);
            }
        };
        if (data?.pestanas[currentTabIndex]?.graficas?.length > 0) {
            cargarGrafica(currentGraphIndex);
        }
    }, [currentGraphIndex]);

    /**
     * Cuando se cambia dece de la gráf pestaña se limpian los datos de las gráficas
     * y se reinicia el índiica actual para iniciar la carga de datos
     */

    useEffect(() => {
        if (data?.pestanas[currentTabIndex]?.graficas?.length > 0) {
            setData((prevData) => {
                const newData = { ...prevData };
                newData?.pestanas[currentTabIndex]?.graficas?.forEach((grafica) => {
                    grafica.data = null;
                }
                );
                return newData;
            });

            setCurrentGraphIndex(0);
        }
    }, [currentTabIndex]);

    /**
     * Manejar actualización de filtros
     */

    useEffect(() => {
        const handleCallback = (filtros) => {
            setData((prevData) => {
                const newData = { ...prevData };
                newData.pestanas[currentTabIndex].graficas.forEach((grafica) => {
                    grafica.data = null;
                }
                );
                return newData;
            });
            setPayload(filtros);
            setCurrentGraphIndex(0);
        }

        const handleNavigate = (datos) => {
            const index = datos?.index !== undefined ? datos.index : currentTabIndex;
            setData((prevData) => {
                const newData = { ...prevData };
                newData?.pestanas[index]?.graficas?.forEach((grafica) => {
                    grafica.data = null;
                });
                return newData;
            });
            setPayload(datos?.payload);
            setCurrentTabIndex(index);
            if (data?.pestanas[index]?.graficas?.length > 0) {
                setCurrentGraphIndex(0);
            }
        }

        eventBus?.subscribe('FILTROS_ACTUALIZADOS', handleCallback);
        eventBus?.subscribe('NAVEGAR_PESTANA', handleNavigate);
        return () => {
            eventBus?.unsubscribe('FILTROS_ACTUALIZADOS', handleCallback);
            eventBus?.unsubscribe('NAVEGAR_PESTANA', handleNavigate);
        };
    }, [eventBus, currentTabIndex]);


    return (

        <div className={"mt-10"}>
            <div className='d-flex align-items-center justify-content-between'>
                <h1 className="titulo__panel">{data.titulo}</h1>
                <SelectorIndice pestanas={data.pestanas} activeIndex={currentTabIndex} setActiveIndex={setCurrentTabIndex}></SelectorIndice>
            </div>
            {data.pestanas[currentTabIndex]?.widgets && data.pestanas[currentTabIndex]?.widgets.length > 0 &&
                <Widgets className={"mt-10"} widgets={data.pestanas[currentTabIndex]?.widgets} />
            }
            {data.pestanas[currentTabIndex]?.filtros && data.pestanas[currentTabIndex]?.filtros.length > 0 &&
                <Filtros filtros={data.pestanas[currentTabIndex]?.filtros} classname={"mt-10"} />
            }

            <ResponsiveGridLayout
                layouts={data.pestanas[currentTabIndex]?.layouts}
                isDraggable={false}
                rowHeight={10}
                containerPadding={[0, 10]}
                compactType={"vertical"}
                onBreakpointChange={setCurrentBreakpoint}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}>
                {data?.pestanas[currentTabIndex]?.graficas?.map((grafica) => (
                    <div key={grafica.id} style={{ backgroundColor: 'transparent' }}>
                        {grafica.data ?
                            <FetchGrafica id={grafica.id} grafica={grafica.data} tipo={grafica.tipo} /> :
                            <LoadingCard></LoadingCard>
                        }
                    </div>
                ))}
            </ResponsiveGridLayout >

                {/*
            <Row className='mt-3'>
                <Col xs={3}>
                    <RadialBarInfo></RadialBarInfo>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={3}>
                    <AreaGradientText></AreaGradientText>
                </Col>
                <Col xs={3}>
                    <CardBarSimple></CardBarSimple>
                </Col>
                <Col xs={3}>
                    <VerticalBarInfo></VerticalBarInfo>
                </Col>

            </Row>
            */}
            
      
        </div>
    )
}

export default PanelDinamico