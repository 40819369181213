import React from 'react'

export const LaterSVG = () => {
    return (
        <svg cursor="pointer" xmlns="https://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="Icon_ionic-md-arrow-dropright-circle" data-name="Icon ionic-md-arrow-dropright-circle" d="M12.375,21.375a9,9,0,1,0-9-9A9,9,0,0,0,12.375,21.375ZM10.99,16.529V8.221l4.154,4.154Z" transform="translate(-3.375 -3.375)" fill="#213265" />
        </svg>

    )
}
