import { useState } from "react";
import { Form } from "react-bootstrap";

const Input = ({ item, handleInputChange, index, idx, reg, nombre, error, handleButtonEstado, titulo }) => {

  // const [valor, setValor] = useState()

  // function clearValue() {
  //   setValor(item.valor)

  //   const clear = valor.replace('\n\t\t', ' ')
  //   setValor(clear)
  // }

  
  return (
    <>
      <div style={{ justifyContent: 'flex-start' }}>
        <div className='headerRegistro' >
          <p style={{ fontSize: '1.2rem' }} className=''>{item.titulo}</p>

        </div>
        <p style={{ width: '100%', textAlign: 'justify', color: "#4a4a4a", margin: '0px', marginLeft: '1%', whiteSpace: 'pre-line' }}>
          {item.valor ? item.valor : null}
        </p>
        <br />
      </div>
    </>
  );

}

export default Input;
