import React from 'react'

export const RiesgoSVG = () => {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 28">
            <g id="Grupo_590" data-name="Grupo 590" transform="translate(16338 13996)">
                <g id="Grupo_585" data-name="Grupo 585">
                    <g id="Elipse_72" data-name="Elipse 72" transform="translate(-16338 -13996)" fill="#fff" style={{ stroke: "#fff", strokeWidth: "1" }} >
                        <circle cx="14" cy="14" r="14" style={{ stroke: "none" }} />
                        <circle cx="14" cy="14" r="13.5" fill="none" />
                    </g>
                </g>
                <g id="Grupo_586" data-name="Grupo 586" transform="translate(0.004)">
                    <circle id="Elipse_73" data-name="Elipse 73" cx="13.243" cy="13.243" r="13.243" transform="translate(-16337.248 -13995.243)" fill="#ffc164" />
                </g>
                <path id="Trazado_601" data-name="Trazado 601" d="M9078.1,901.434l4.766-.033,6.281.033" transform="translate(-25407.398 -14883.4)" fill="none" style={{ stroke: "#fff", strokeLinecap: "round", strokeWidth: "5" }} />
            </g>
        </svg>


    )
}
