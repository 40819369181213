import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Nav, Row, Accordion, Table } from 'react-bootstrap';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Form } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import './styles/otros.scss';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal';
import { camelize } from '../../../../helpers/textHelpers';

import { DropzoneArea, DropzoneAreaBase, DropzoneDialogBase, DropzoneDialog } from 'material-ui-dropzone';

import OpcionesCiudades from '../../../../helpers/OpcionesCiudades';

import EditModal from '../../../modales/EditModal'
import { useTranslation } from 'react-i18next';


export default function OtrosItemsInforme({ idSubseccion, titulo, tipo, pestana }) {

  // console.log(tipo)
  const { t } = useTranslation();
  const { id } = useParams()

  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    data: {}
  })
  const [editModal, setEditModal] = useState({
    show: false,
    tipo: '',
    data: {}
  })

  const [imageToLoad, setImageToLoad] = useState([])
  const [addSolucion, setAddSolucion] = useState(false)
  const [inputList, setInputList] = useState([{ valor: "" }]);
  const [stateInforme, setStateInforme] = useState({

  })

  const [stateNoticia, setStateNoticia] = useState({
    descripcion: '',
    enlace: '',
    factor: '',
    fecha: '',
    imagen: {
      data_img: '',
      titulo: ''
    },
    titulo: '',
    ubicacion: ''
  })
  const [dataTable, setDataTable] = useState([]);
  const [dataTableForm, setDataTableForm] = useState({});
  const [indexForm, setIndexForm] = useState('');
  const [indexDelete, setIndexDelete] = useState('');
  // const [showModalNotification, setShowModalNotification] = useState(false);
  // const [showModalConfirmNotification, setShowModalConfirmNotification] = useState(false);
  // const [showModalDeleteNotification, setShowModalDeleteNotification] = useState(false);
  // const [showModalEditNotification, setShowModalEditNotification] = useState(false);

  // const handleOpenModalNotification = () => setShowModalNotification(true);
  // const handleCloseModalNotification = () => setShowModalNotification(false);

  // const handleOpenModalConfirmNotification = () => setShowModalConfirmNotification(true);
  // const handleCloseModalConfirmNotification = () => setShowModalConfirmNotification(false);

  const handleOpenModalDeleteNotification = (id) => {
    setModal({ ...modal, show: true, tipo: 'panoramaEliminadoConfirmacion' })
    // setShowModalNotification(true)
    setIndexDelete(id);
  };
  // const handleCloseModalDeleteNotification = () => setShowModalDeleteNotification(false);
  // const handleOpenModalEditNotification = () => setShowModalEditNotification(true);
  // const handleCloseModalEditNotification = () => setShowModalEditNotification(false);

  const editarNoticiaModal = (item) => {
    // console.log(item)
    setEditModal({ ...editModal, show: true, tipo: 'editarNoticiaPanorama', data: item })
    setStateNoticia({
      ...stateNoticia,
      id: item.id,
      fecha: item.fecha,
      ubicacion: item.ubicacion,
      descripcion: item.descripcion,
      titulo: item.titulo,
      factor: item.factor,
      enlace: item.enlace,


      imagen: { data_img: item.imagen.data, titulo: item.imagen.titulo }

    })
  }

  const handleInputChange = (e, index) => {

    if (idSubseccion === 3 && pestana === 'analisis') {
      setStateNoticia({
        ...stateNoticia,
        [e.target.name]: e.target.value
      })

    } else {
      const { value } = e.target;
      const list = [...inputList];
      list[index][camelize(tipo)] = value;
      setInputList(list);
      //console.log(inputList)
      setStateInforme({
        ...stateInforme,
        [e.target.name]: value
      })

      //console.log(stateInforme)
    }
  };

  const getDataItemCierre = async () => {
    const dataReq = {
      id_registro: idSubseccion,
      id: id,
      hash: window.location.hash,
      tipo: tipo
    }

    //console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/get_data_item_generic_panorama.html', 'POST', dataReq)
    const res = await req.json()

    //console.log(req)
    //console.log(res)

    if (req.ok) {

      //console.log(res)

      if (pestana === 'cierre') {

        switch (idSubseccion) {
          case 1:
            setDataTable(res.conclusiones)
            break;
          case 2:
            setDataTable(res.recomendaciones)
            break;
          default:
            break;
        }

      } else if (pestana === 'analisis') {

        switch (idSubseccion) {
          case 1:
            setDataTable(res.causas)
            break;
          case 2:
            setDataTable(res.proyecciones)
            break;
          case 3:
            setDataTable(res.noticias)
            break;
          default:
            break;
        }

      }

    }
  }

  useEffect(() => {
    getDataItemCierre()
    setInputList([]);
    setAddSolucion(false);
    setDataTable([]);
    setDataTableForm({});
    createFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSubseccion])

  const createFields = () => {
    setInputList([{ [camelize(tipo)]: "" }])
  }

  const handleAddClick = () => {
    setInputList([...inputList, { [camelize(tipo)]: "" }]);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const sendDataForm = async (act) => {

    //console.log(act + 'aqui es')

    const dataSend = {
      // id_conclusion: dst.id_con,
      formData: (idSubseccion === 3 && pestana === 'analisis') ? stateNoticia : inputList,
      id_registro: idSubseccion,
      hash: window.location.hash,
      tipo: tipo,
      id: id,
      accion: act === 'editar' ? 'editar' : 'insertar'
    };

    //console.log(dataSend)

    const response = await validarEntorno('JSON/panoramaRiesgos/respuesta_otros_panoramas.html', 'POST', dataSend);

    const respuesta = await response.json();

    if (response.ok) {
      if (respuesta.status === 'OK') {
        setDataTable([...dataTable, ...inputList])
        cleanForm();
        // handleOpenModalNotification();
      }

      act === 'editar'
        ? setModal({ ...modal, show: true, tipo: 'panoramaActualizado' })
        : setModal({ ...modal, show: true, tipo: 'panoramasCreados' })

      setEditModal({ ...editModal, show: false })
    }

  }

  const cleanForm = () => {
    setInputList([{ [camelize(tipo)]: "" }]);
    setAddSolucion(false);
  }

  const handleTableChangeData = async (e) => {
    const value = e.target.value;
    setDataTableForm({
      ...dataTableForm,
      [e.target.name]: value
    });
  }

  const availableInput = (index, data) => {
    if (indexForm !== index) {
      setIndexForm(index);
      setDataTableForm(data);
    } else {
      setIndexForm('');
      setDataTableForm({});
    }
  }

  const editItem = async (index) => {

    const dataSend = {
      formData: dataTableForm,
      id_registro: idSubseccion,
      hash: window.location.hash,
      id: id,
      tipo: tipo,
      accion: 'editar'
    };

    //console.log(dataSend)

    const response = await validarEntorno('JSON/panoramaRiesgos/respuesta_otros_panoramas_edicion.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      setModal({ ...modal, show: true, tipo: 'panoramaActualizado' })

      setIndexForm('')

      const respuesta = await response.json();

      if (respuesta.status === 'OK') {
        let dt = [...dataTable];
        let item = { ...dt[index] };
        item = dataTableForm;
        dt[index] = item;
        setDataTable(dt);
        setDataTableForm({});
        setIndexForm('');
        // handleOpenModalEditNotification();
      }
    }
  }

  const deleteItem = async (index) => {

    const dst = dataTable[index];

    const dataSend = {
      // id_conclusion: dst.id_con,
      id_registro: idSubseccion,
      id: id,
      hash: window.location.hash,
      id_item: indexDelete,
      tipo: tipo

    };

    // console.log(dataSend)

    const response = await validarEntorno('JSON/panoramaRiesgos/respuesta_otros_panoramas_eliminacion.html', 'POST', dataSend);

    if (response.ok) {
      setModal({ ...modal, show: true, tipo: 'panoramaEliminado' })
      const respuesta = await response.json();

      if (respuesta.status === 'OK') {
        const dt = [...dataTable];
        // dt.splice(index, 1);
        setDataTable(dt);
        // handleCloseModalDeleteNotification();
        // handleOpenModalConfirmNotification();
        setIndexDelete('');
      }
    }
  }

  const handleImageNoticia = (img) => {
    // console.log(img);
    setStateNoticia({
      ...stateNoticia,
      imagen: {
        data_img: img[0].data,
        titulo: img[0].file.name
      }
    })
    // console.log(stateInforme);

  }

  const sendNoticiaEdit = async () => {
    const dataReq = {
      formData: (idSubseccion === 3 && pestana === 'analisis') ? stateNoticia : inputList,
      id_registro: idSubseccion,
      hash: window.location.hash,
      tipo: tipo,
      id: id,
      accion: 'editar'
    }

    //console.log(dataReq)

    const response = await validarEntorno('JSON/panoramaRiesgos/respuesta_otros_panoramas.html', 'POST', dataReq);

    const respuesta = await response.json();

    if (response.ok) {
      if (respuesta.status === 'OK') {
        setDataTable([...dataTable, ...inputList])
        cleanForm();
        // handleOpenModalNotification();
      }
      setModal({ ...modal, show: true, tipo: 'panoramasCreados' })
      setEditModal({ ...editModal, show: false })
    }
  }

  return (
    <div>
      <section style={{ width: '85%', margin: 'auto' }} >
        <div className='headerRegistro'>
          <p>{titulo}</p>
          <button
            style={{ marginRight: '0px', width: '180px' }}
            className={addSolucion === true ? 'warningButton' : 'confirmButton'}
            onClick={() => setAddSolucion(!addSolucion)}
          >
            {
              addSolucion === true
                ? t('botones.cancelar')
                : `${t('botones.agregar')} ${titulo.toLowerCase()}`
            }

          </button>
        </div>
        <br />
        {addSolucion === false
          ?
          <div className='contenedorMensajeNoticiasVacio'>
          </div>
          :
          <div>

            {inputList.map((item, index) => (
              <>
                {idSubseccion !== 3
                  ?
                  <Form.Group className='formGroupVertical' >
                    <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                    <Form.Control
                      value={item[camelize(tipo)]}
                      style={{ minHeight: '100px' }}
                      as='textarea'
                      placeholder={t('formulario.label.escribe_descripcion')}
                      onChange={(e) => handleInputChange(e, index)}
                      name={camelize(tipo)}
                    />
                  </Form.Group>
                  :
                  <>
                    <Row>
                      <Col>
                        <OpcionesCiudades
                          defaultValue={''}
                          placeholder='Escribe el titular de la noticia '
                          onChange={(e) => handleInputChange(e, index)}
                          name='ubicacion'
                        />

                      </Col>
                      <Col>
                        <Form.Group className='formGroupVertical' >
                          <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                          <Form.Control
                            // value={item[camelize(tipo)]}
                            // style={{minHeight:'100px'}}
                            // as='textarea'
                            type='date'
                            onChange={(e) => handleInputChange(e, index)}
                            name='fecha'
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='formGroupVertical' >
                          <Form.Label>{t('formulario.label.factor')}</Form.Label>
                          <Form.Control
                            // value={item[camelize(tipo)]}
                            // style={{minHeight:'100px'}}
                            // as='textarea'
                            defaultValue=''
                            as='select'
                            onChange={(e) => handleInputChange(e, index)}
                            name='factor'
                          >
                            <option disabled value=''>{t('formulario.label.seleccione')}</option>
                            <option value='1'>{t('formulario.select.social')}</option>
                            <option value='2'>{t('formulario.select.politico')}</option>
                            <option value='3'>{t('formulario.select.economico')}</option>
                            <option value='4'>{t('formulario.select.fuerza_disponible')}</option>
                            <option value='5'>{t('formulario.select.amenazas')}</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>


                    <Form.Group className='formGroupVertical' >
                      <Form.Label>{t('formulario.label.titulo')}</Form.Label>
                      <Form.Control
                        // value={item[camelize(tipo)]}
                        // style={{minHeight:'100px'}}
                        as='textarea'
                        type='text'
                        placeholder={t('formulario.label.escribe_titular_noticia')}
                        onChange={(e) => handleInputChange(e, index)}
                        name='titulo'
                      />
                    </Form.Group>
                    <Form.Group className='formGroupVertical' >
                      <Form.Label>{t('formulario.label.enlace')}</Form.Label>
                      <Form.Control
                        // value={item[camelize(tipo)]}
                        // style={{minHeight:'100px'}}
                        // as='textarea'
                        type='text'
                        placeholder=''
                        onChange={(e) => handleInputChange(e, index)}
                        name='enlace'
                      />
                    </Form.Group>
                    <Form.Group className='formGroupVertical' >
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control
                        // value={item[camelize(tipo)]}
                        style={{ minHeight: '100px' }}
                        as='textarea'
                        placeholder={t('formulario.label.escribe_descripcion')}
                        onChange={(e) => handleInputChange(e, index)}
                        name='descripcion'
                      />
                    </Form.Group>
                    {stateNoticia.imagen.data_img !== ''
                      ?
                      <table>
                        <thead>
                          <tr>
                            <th>{t('formulario.label.titulo_imagen')}</th>
                            <th>{t('formulario.label.acciones')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='rowTabla' >
                            <td>
                              {stateNoticia.imagen.titulo}
                            </td>
                            <td>
                              <div className='botonesTabla'
                                style={{ cursor: 'pointer' }}
                                onClick={() => setStateNoticia({ ...stateNoticia, imagen: { data_img: '', titulo: '' } })}

                              >
                                <button
                                  className='botonTablaGeneral botonEliminarTabla'
                                >
                                  {t('botones.eliminar')}
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>

                      </table>
                      :
                      <Form.Group className='formGroupVertical' >
                        <Form.Label>{t('formulario.label.imagen_opcional')}</Form.Label>

                        <DropzoneAreaBase
                          acceptedFiles={['image/*']}
                          dropzoneText={t('formulario.label.arrastra_suelta_imagen')}
                          onAdd={(files) => handleImageNoticia(files)}
                          onChange={(files) => handleImageNoticia(files)}
                          onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                          onDelete={deleteFileObj => {
                            console.log('onDelete', deleteFileObj);
                          }}

                          fileObjects={stateInforme.imagen}
                          showPreviews={true}
                          showFileNamesInPreview={true}
                          showPreviewsInDropzone={true}
                          filesLimit={1}
                        />
                      </Form.Group>
                    }
                  </>

                }


                {(!(pestana === 'analisis' && idSubseccion === 3) && inputList.length !== 1) && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />}
                {(!(pestana === 'analisis' && idSubseccion === 3) && inputList.length - 1 === index) && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />}

              </>
            ))}
          </div>
        }
      </section>
      {/* <div className='buttonContainer'>
                    <button onClick={() => { sendDataForm() }} className='confirmButton' >Enviar</button>
                </div> */}

      {addSolucion === true && (
        <div className='buttonContainer'>
          <button
            onClick={() => { sendDataForm(); getDataItemCierre() }}
            className='confirmButton'
          >
            {t('botones.enviar')}
          </button>
        </div>)}
      <div>

        {dataTable.length > 0 ? (
          <div className="">
            <table className=" table-forms-static"
              style={{
                borderRadius: '10px',
                overflow: 'hidden'
              }}
            >
              <thead>
                <tr>
                  <th>{t('formulario.label.descripcion')}</th>
                  <th
                    style={{
                      width: '300px',
                      maxWidth: '300px'
                    }}
                  >
                    {t('formulario.label.acciones')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((data, index) => (
                  pestana === 'analisis' && idSubseccion === 3
                    ?
                    <tr className='rowTabla'>
                      <td>
                        <p>{data.titulo ? data.titulo : data.valor}</p>
                      </td>
                      <td>
                        <div className='botonesTabla'>

                          <button
                            style={{ cursor: 'pointer' }}
                            className='botonTablaGeneral botonEditarTabla'
                            onClick={() => editarNoticiaModal(data)}
                          >
                            <EditIcon fontSize='small' />
                            {t('botones.editar')}
                          </button>
                          <button
                            style={{ cursor: 'pointer' }}
                            className='botonTablaGeneral botonEliminarTabla'
                            onClick={() => handleOpenModalDeleteNotification(data.id)}
                          >
                            <DeleteIcon fontSize='small' />
                            {t('botones.eliminar')}
                          </button>
                        </div>

                      </td>
                    </tr>
                    :
                    <tr className='rowTabla' key={index} style={{ fontSize: '1rem' }}>
                      {indexForm === index ? (
                        <td>
                          <Form.Group key={index} as={Col} lg={12} role="form" className="form-other-factor" controlId="formGridTextArea">
                            <Form.Control
                              className={indexForm === index ? "audit-form-textarea-obj" : "audit-form-textarea-obj dis"}
                              as="textarea"
                              name={data.descripcion ? 'descripcion' : "valor"}
                              defaultValue={
                                data.titulo ? data.titulo : data.valor
                                // data[camelize(tipo)]
                              }
                              onChange={handleTableChangeData}
                              disabled={indexForm !== index}
                            />
                          </Form.Group>
                        </td>
                      ) : (
                        <td style={{ textAlign: 'center', width: '80%' }} >{data.titulo ? data.titulo : data.valor}</td>
                      )}
                      <td>
                        <div className='botonesTabla '

                        >
                          {indexForm === index ? (
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonGuardarTabla'
                              onClick={() => { editItem(data.id); getDataItemCierre() }}>
                              <SaveIcon fontSize='small' />
                              {t('botones.guardar')}
                            </button>
                          ) : null}
                          <button style={{ cursor: 'pointer' }} className='botonTablaGeneral botonEditarTabla' onClick={() => { availableInput(index, data) }}>
                            {indexForm === index
                              ?
                              <>
                                <CloseIcon />
                                {t('botones.cancelar')}
                              </>
                              :
                              <>
                                <EditIcon fontSize='small' />
                                {t('botones.editar')}
                              </>

                            }
                          </button>
                          {indexForm !== index && (
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonEliminarTabla'
                              onClick={() => handleOpenModalDeleteNotification(data.id)}>
                              <DeleteIcon fontSize='small' />
                              {t('botones.eliminar')}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>

          </div>
        ) :
          <div
            className='mensajeTablaSinInfo'
            style={{ width: '85%', margin: '0 auto' }}
          >
            {`${t('formulario.label.aun_no_has_agregado')} ${titulo.toLowerCase()}...`}
          </div>
        }
      </div>
      {/* <NotificationModal
                    isOpen={showModalDeleteNotification}
                    itemName={tipo}
                    setIsOpen={handleCloseModalDeleteNotification}
                    typeModal='panoramaEliminadoConfirmacion'
                    handleEliminar={deleteItem}
                    indexDelete={indexDelete}
                />
                <NotificationModal
                    isOpen={showModalConfirmNotification}
                    itemName={tipo}
                    setIsOpen={handleCloseModalConfirmNotification}
                    typeModal='panoramaEliminado'
                    handleEliminar={deleteItem}
                    indexDelete={indexDelete}
                /> */}
      {/* <NotificationModal
                    isOpen={showModalEditNotification}
                    itemName={tipo}
                    setIsOpen={handleCloseModalEditNotification}
                    typeModal='panoramaActualizado'
                />
                <NotificationModal
                    isOpen={showModalNotification}
                    setIsOpen={handleCloseModalNotification}
                    typeModal='panoramasCreados'
                    itemName={titulo}
                /> */}
      <NotificationModal
        typeModal={modal.tipo}
        isOpen={modal.show}
        setIsOpen={setModal}
        itemName={tipo}
        handleEliminar={() => { deleteItem(); getDataItemCierre() }}
        indexDelete={indexDelete}

      />
      <EditModal
        typeModal={editModal.tipo}
        isOpen={editModal.show}
        setIsOpen={setEditModal}
      >
        {editModal.tipo === 'editarNoticiaPanorama' &&
          <div id='contenedorFormNoticiaPanorama'>
            <h2 className='pagesTitles' style={{ marginBottom: '20px' }} >{t('formulario.label.editar_noticia')}</h2>
            <div id='contenedorInputsNoticiaPanorama' className='scrollSecundario' >
              <Row

              >
                <Col>
                  <OpcionesCiudades
                    valor={editModal.data.ubicacion}
                    onChange={(e) => handleInputChange(e)}
                    name='ubicacion'
                  />

                </Col>
                <Col>
                  <Form.Group className='formGroupVertical' >
                    <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                    <Form.Control
                      // value={item[camelize(tipo)]}
                      // style={{minHeight:'100px'}}
                      // as='textarea'
                      type='date'
                      defaultValue={editModal.data.fecha}
                      onChange={(e) => handleInputChange(e)}
                      name='fecha'
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='formGroupVertical' >
                    <Form.Label>{t('formulario.label.factor')}</Form.Label>
                    <Form.Control
                      // value={item[camelize(tipo)]}
                      // style={{minHeight:'100px'}}
                      // as='textarea'
                      defaultValue={editModal.data.factor}
                      as='select'
                      onChange={(e) => handleInputChange(e)}
                      name='factor'
                    >
                      <option disabled value=''>{t('formulario.label.seleccione')}</option>
                      <option value='1'>{t('formulario.select.social')}</option>
                      <option value='2'>{t('formulario.select.politico')}</option>
                      <option value='3'>{t('formulario.select.economico')}</option>
                      <option value='4'>{t('formulario.select.fuerza_disponible')}</option>
                      <option value='5'>{t('formulario.select.amenazas')}</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>


              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.titulo')}</Form.Label>
                <Form.Control
                  // value={item[camelize(tipo)]}
                  // style={{minHeight:'100px'}}
                  as='textarea'
                  type='text'
                  defaultValue={editModal.data.titulo}
                  placeholder={t('formulario.label.escribe_titular_noticia')}
                  onChange={(e) => handleInputChange(e)}
                  name='titulo'
                />
              </Form.Group>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.enlace')}</Form.Label>
                <Form.Control
                  // value={item[camelize(tipo)]}
                  // style={{minHeight:'100px'}}
                  // as='textarea'
                  type='text'
                  defaultValue={editModal.data.enlace}
                  placeholder={t('formulario.label.copia_enlace_noticia')}
                  onChange={(e) => handleInputChange(e)}
                  name={'enlace'}
                />
              </Form.Group>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                <Form.Control
                  // value={item[camelize(tipo)]}
                  style={{ minHeight: '100px' }}
                  as='textarea'
                  defaultValue={editModal.data.descripcion}
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => handleInputChange(e)}
                  name={'descripcion'}
                />
              </Form.Group>
              {stateNoticia.imagen.data_img !== ''
                ?
                <table>
                  <thead>
                    <tr>
                      <th>{t('formulario.label.titulo_imagen')}</th>
                      <th>{t('formulario.label.acciones')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='rowTabla'>
                      <td>
                        {(stateNoticia.imagen.titulo && stateNoticia.imagen.titulo !== '') ? stateNoticia.imagen.titulo : editModal.data.imagen.titulo}
                      </td>
                      <td>
                        <div className='botonesTabla'
                          style={{ cursor: 'pointer' }}
                          onClick={() => setStateNoticia({ ...stateNoticia, imagen: { data_img: '', titulo: '' } })}

                        >
                          <button
                            className='botonTablaGeneral botonEliminarTabla'
                          >
                            {t('botones.eliminar')}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                </table>
                :
                <Form.Group className='formGroupVertical' >
                  <Form.Label>{t('formulario.label.imagen')}</Form.Label>

                  <DropzoneAreaBase
                    acceptedFiles={['image/*']}
                    dropzoneText={t('formulario.label.arrastra_suelta_imagen')}
                    onAdd={(files) => handleImageNoticia(files)}
                    onChange={(files) => handleImageNoticia(files)}
                    // onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                    // onDelete={deleteFileObj => {
                    //     console.log('onDelete', deleteFileObj);
                    //   }}

                    // fileObjects={stateInforme.imagen}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    showPreviewsInDropzone={true}
                    filesLimit={1}
                  />
                </Form.Group>
              }

            </div>
            <br />
            <div>
              <button
                onClick={() => sendDataForm('editar')}
              >{t('botones.guardar')}</button>
              <button>{t('botones.cancelar')}</button>
            </div>
          </div>
        }
      </EditModal>
    </div>
  )
}
