import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from '../reducers/auth.reducer'
import { solicitudReducer } from '../reducers/crearSolicitudReducer';
import { editarSolicitudes } from '../reducers/editSolicitudes';
import { filtroReducer } from '../reducers/filtroTipoEstudio';
import { menuReducer } from '../reducers/menuReducer';
import { solicitudEstudioReducer } from '../reducers/solicitudesEstudio';
import { tablasolicitudReducer } from '../reducers/tablaEstudio.reducer';
import profileReducer from '../reducers/profileReducer'
import reportesReducer from '../reducers/reportesReducer'
import inputFilterOcensa from '../reducers/inputFilterOcensa';

const rootReducer = combineReducers({
    //Aqui van los reducers que se vallan creando

    login: authReducer,
    filtroEstudio: filtroReducer,
    edicionSolicitud: editarSolicitudes,
    menu: menuReducer,
    solicitudEstudio: solicitudEstudioReducer,
    tablaSolicitudReducer: tablasolicitudReducer,
    crearSolicitud: solicitudReducer,
    profileReducer: profileReducer,
    reportesReducer: reportesReducer,
    inputFilterOcensa: inputFilterOcensa
})


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    return store
}
