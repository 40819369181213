import { IconButton } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { Card, Form, } from 'react-bootstrap'
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import {  editarBeneficiario, eliminarBeneficiario } from '../../reducers/editSolicitudes';

import EditModal from '../modales/EditModal'
import NotificationModal from '../modales/NotificationModal'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './BeneficiarioEditarEstudio.scss'
// import { envioTerceraPestaña } from '../../reducers/crearSolicitudReducer';


export const BeneficiariosEditarEstudio = ({ setFormState, formState, dataSolicitud  }, props  ) => {

    // const { codigo } = useSelector(state => state.crearSolicitud)


    const dispatch = useDispatch()
    const [validated, setValidated] = useState(false);
    // const { beneficiarios } = useSelector(state => state.edicionSolicitud

    const [formEdit, setFormEdit] = useState({})


    const handleInputChangeEdit = ({ target }) => {
            validarCampos()
            setFormEdit({
                ...formEdit,
                [target.name]: target.value
            })
    }

    const validarContenido = (value) => {
        const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑ ]/g);

        const validado = reg1.test(value);

        return validado;
    }

    const validarCampos = () => {
        if(formEdit.identificacion && formEdit.primer_nombre && formEdit.primer_apellido && formEdit.parentesco  !== ""){
            setValidated(true)
        }
    }



    const idRegistro = props.idSolicitud
    const URL_BASE = `https://www.cosinte.co/cia_2/build/editar_investigacion.html?id=${idRegistro}`;

    const [modalAgregarConfirmacion, setModalAgregarConfirmacion] = useState(false)


    const submitForm = async (e) => {

        e.preventDefault()
        const formularioAddBeneficiario = e.currentTarget;

        // si hay errores en el formulario
        if (formularioAddBeneficiario.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }



        await fetch(URL_BASE, {
            method: 'POST',
            headers: {
                'content-Type' : 'application/json'
            },
            body: JSON.stringify(formEdit)
        })

        setModalAgregarConfirmacion(true)

    }

    const [deleteModal, setDeleteModal] = useState(false)

    const handleEliminar = (id) => {
        dispatch(eliminarBeneficiario(id))
        setDeleteModal(false)
    }

    const [editRows, setEditRows] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const handleEdit = (ele, index) => {
        if (editRows[1] === index) {
            setEditRows(false)
        } else {
            setEditRows([ele, index])
            setEditModal(true)
        }
    }

    const handleEditElement = (ele) => {

        dispatch(editarBeneficiario(ele, formEdit))
        setEditRows(false)
        setFormEdit({
            identificacion: '',
            identificacionTipo: '',
            primerNombre: '',
            segundoNombre: '',
            primerApellido: '',
            segundoApellido: '',
            parentesco: '',
        })

        setEditModal(false)
    }

    const [tipoIDValue, setTipoIDValue] = useState({
        tipo_identificacion: '',
    })

    const handleChangeTipoID = ({target}) => {

        setTipoIDValue({
            tipo_identificacion : target.value
        })

        setFormEdit({...formEdit, tipo_identificacion : tipoIDValue.tipo_identificacion})
    }

    const actualizarData = (props) => {
        setModalAgregarConfirmacion(false)
    }



    return (
        <Fragment>

            <Card className="shadow cardContainerSolicitud" style={{ borderRadius: '10px' }}>
                <Card.Body>
                    <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h6 className=' ' style={{ color: '#707070', width: '200px', margin: '0px', marginRight: '0px' }}>Beneficiarios</h6>

                        <div className="addIconContainer" >
                            <IconButton onClick={() => setFormState(!formState)}>
                                <AddIcon fontSize='large' style={{ background: '#213265', borderRadius: '50%', color: 'white' }} />
                            </IconButton>
                        </div>

                    </div>

                    <br />
                    {
                        formState
                        &&
                        <>
                        <Form className='formAddBeneficiarioContainer' noValidate validated={validated} onSubmit={submitForm} >
                            <div className='formItem' id='basicFormA'>

                                <Form.Group>
                                    <Form.Label>
                                        Nombres
                                    </Form.Label>
                                    <div className='namesContainer'>
                                        <div >
                                            <Form.Control name='primer_nombre' required type='text' placeholder='Primer nombre'
                                                style={{ marginRight: '10px' }}
                                                onChange={handleInputChangeEdit}

                                            ></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa el primer nombre</Form.Control.Feedback>
                                        </div>
                                        <div>
                                            <Form.Control name='segundo_nombre'  type='text'
                                                placeholder='Segundo nombre'
                                                onChange={handleInputChangeEdit}
                                            ></Form.Control>
                                        </div>
                                    </div>
                                </Form.Group>

                                    <Form.Group>
                                        <Form.Label>
                                            Apellidos
                                </Form.Label>
                                    <div className='namesContainer'>
                                        <div>
                                            <Form.Control name='primer_apellido' required type='text'
                                                // className="place"
                                                placeholder='Primer apellido' style={{ marginRight: '10px' }}
                                                onChange={handleInputChangeEdit}

                                            ></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa el primer apellido</Form.Control.Feedback>

                                        </div>
                                        <div>
                                            <Form.Control name='segundo_apellido'  type='text'
                                                // className="place"
                                                placeholder='Segundo apellido'
                                                onChange={handleInputChangeEdit}
                                            ></Form.Control>
                                        </div>
                                        </div>
                                    </Form.Group>

                                </div>
                                <div className='formItem' id='basicFormB'>
                                    <Form.Group>
                                        <Form.Label>
                                            Identificación
                                    </Form.Label>
                                    <div className='idContainer'>
                                        <Form.Control name='tipo_identificacion' id='input_select' as='select' custom
                                            onChange={handleChangeTipoID}
                                            value='Seleccione un tipo de identificación'
                                        // className="place"
                                        >
                                            <option value='2' disabled>Seleccione un tipo de identificación</option>
                                            <option value='2'>Cédula de ciudadanía</option>
                                            <option value='3'>Cédula de extranjería</option>
                                            <option value='4'>Tarjeta de identidad</option>
                                            <option value='9'>Registro civíl</option>
                                            <option value='5'>Pasaporte</option>
                                        </Form.Control>
                                        <div>
                                            <Form.Control
                                                name='identificacion'
                                                type='number'
                                                placeholder='Identificación'
                                                onChange={handleInputChangeEdit}
                                                min='0'
                                                max='9999999999'
                                                required
                                            ></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa identificación</Form.Control.Feedback>
                                        </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Parentesco
                                </Form.Label>
                                    <div className='inputContainer'>
                                        <Form.Control name='parentesco' id='typeIdSelect' as='select' custom required
                                            className="place form-control" style={{ width: '100%' }}
                                            onChange={handleInputChangeEdit}
                                        >
                                            <option disabled selected>Parentesco</option>
                                            <option value="Padre">Padre</option>
                                            <option value="Hijo(a)">Hijo(a)</option>
                                            <option value="Hijo(a) Menor">Hijo(a) Menor</option>
                                            <option value="Hijo(a) Estudiante">Hijo(a) Estudiante</option>
                                            <option value="Hijo(a) invalido">Hijo(a) inválido</option>
                                            <option value="Hermano(a)">Hermano(a)</option>
                                            <option value="Hermano(a) Invalido">Hermano(a) Invalido</option>
                                            <option value="Conyuge/Compañero(a)">Conyuge/Compañero(a)</option>
                                            <option value="Herederos indeterminados"> Herederos indeterminados </option>
                                            <option value="Esposo(a)"> Esposo(a) </option>
                                            <option value="Compañero(a)"> Compañero(a) </option>
                                            <option value="Otro">Otro</option>
                                        </Form.Control>
                                    </div>
                                </Form.Group>
                            </div>
                            <div id='observacionesAddBeneficiario'>
                                <Form.Group id='observacionesFormGroup'>
                                    <Form.Label className='observacionesLabel'>
                                        Observaciones
                                    </Form.Label>
                                    <Form.Control name='observaciones' id='observacionesTextArea' as='textarea'
                                        placeholder='Observaciones'
                                        // className="place"
                                        onChange={handleInputChangeEdit}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                                {validated === false
                                    ?
                                    <button disabled className='botonAddBeneficiario botonDeshabilitado'>
                                    Llenar todos los campos
                                    </button>
                                    :
                                    <button type="submit" className='botonAddBeneficiario confirmButton'>
                                        enviar
                                    </button>
                                }

                            </div>

                        </Form>
                        <NotificationModal  isOpen={modalAgregarConfirmacion} setIsOpen={setModalAgregarConfirmacion} typeModal='addBeneficiarioConfirm' />
                        </>

                    }
                </Card.Body>
            </Card>
            <br></br>
            <Card className="shadow " style={{ borderRadius: '10px' }}>
                <Card.Body>
                    <h6 className='pt-4' style={{ color: '#707070' }}>Lista de Beneficiarios</h6>
                    {
                        dataSolicitud.beneficiariosCausanteRegisro.length === 0
                            ? <div className="container text-center">
                                <h1 className='p-4' style={{ color: '#707070' }}>Aún no has agregado beneficiarios</h1>
                            </div>

                            : <div className='tableContainer'>

                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Identificación</th>
                                        <th>Primer nombre</th>
                                        <th>Segundo nombre</th>
                                        <th>Primer Apellido</th>
                                        <th>Segundo Apellido</th>
                                        <th>Parentesco</th>
                                        <th>Observaciones</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataSolicitud.beneficiariosCausanteRegisro.map((ele, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{ele.identificacionTipo + ' ' + ele.identificacion}</td>
                                                    <td>{ele.primer_nombre}</td>
                                                    <td>{ele.segundo_nombre}</td>
                                                    <td>{ele.primer_apellido}</td>
                                                    <td>{ele.segundo_apellido}</td>
                                                    <td>{ele.parentesco}</td>
                                                    <td>{ele.observaciones}</td>
                                                    <td style={{ display: 'flex', justifyContent:'space-evenly' }}>
                                                        {dataSolicitud.beneficiariosOpcionEditar === true
                                                            ?
                                                            <div className='editBeneficiarios buttonBeneficiarios'   onClick={() => handleEdit(ele, index)} >
                                                                <EditIcon fontSize='small'/>
                                                                Editar
                                                            </div>
                                                            : null
                                                        }
                                                        {dataSolicitud.beneficiariosOpcionEliminar === true
                                                            ?
                                                            <div className='deleteBeneficiarios buttonBeneficiarios'  onClick={() => setDeleteModal(true)}>
                                                                <DeleteIcon fontSize='small'/>
                                                                Eliminar
                                                            </div>
                                                            : null
                                                        }
                                                        <EditModal isOpen={editModal} setIsOpen={setEditModal} typeModal='EditBeneficiario' editHandler={() => handleEditElement(ele)}>
                                                            <div className='formEditBeneficiarioContainer'>
                                                            <div>
                                                                <label>Identificación</label>

                                                            </div>
                                                            <div className="" id='idContainerEditBeneficiario'>
                                                                <select
                                                                    defaultValue={ele.identificacionTipo}
                                                                    name="tipo_identificacion"
                                                                    onChange={handleInputChangeEdit}
                                                                    className="noneStiles">
                                                                        <option value='2' selected>Seleccione un tipo de identificación</option>
                                                                        <option value='2'>Cédula de ciudadanía</option>
                                                                        <option value='3'>Cédula de extranjería</option>
                                                                        <option value='4'>Tarjeta de identidad</option>
                                                                        <option value='9'>Registro civíl</option>
                                                                        <option value='5'>Pasaporte</option>
                                                                </select>
                                                                <input
                                                                    type='number'
                                                                    onChange={handleInputChangeEdit}
                                                                    name="identificacion"
                                                                    defaultValue={ele.identificacion}
                                                                />
                                                            </div>
                                                            <div className='nombresContenedor'>
                                                                <div className='nombresContainerEditBeneficiario'>
                                                                    <label>Nombres</label>
                                                                        <input placeholder="Primer nombre"
                                                                        defaultValue={ele.primerNombre}
                                                                        onChange={handleInputChangeEdit}
                                                                        name="primer_nombre"
                                                                        />
                                                                </div>
                                                                    <div className='nombresContenedor'>
                                                                        <div className='nombresContainerEditBeneficiario'>
                                                                            <label>Nombres</label>
                                                                            <input placeholder="Primer nombre"
                                                                                defaultValue={ele.primerNombre}
                                                                                onChange={handleInputChangeEdit}
                                                                                name="primer_nombre"
                                                                            />
                                                                            <input
                                                                                placeholder="Segundo nombre"
                                                                                defaultValue={ele.segundoNombre}
                                                                                onChange={handleInputChangeEdit}
                                                                                name="segundo_nombre"
                                                                            />

                                                                        </div>
                                                                        <div className='nombresContainerEditBeneficiario'>
                                                                            <label>Apellidos</label>
                                                                            <input
                                                                                placeholder="Primer apellido"
                                                                                defaultValue={ele.primerApellido}
                                                                                onChange={handleInputChangeEdit}
                                                                                name="primer_apellido"
                                                                            />
                                                                            <input
                                                                                placeholder="Segundo apellido"
                                                                                defaultValue={ele.segundoApellido}
                                                                                onChange={handleInputChangeEdit}
                                                                                name="segundo_apellido"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <label>Parentesco</label>
                                                                    <select
                                                                        defaultValue={ele.parentesco}
                                                                        name="parentesco"
                                                                        onChange={handleInputChangeEdit}
                                                                        className="noneStiles2">
                                                                        <option disabled >Parentesco</option>
                                                                        <option value="Padre">Padre</option>
                                                                        <option value="Hijo(a)">Hijo(a)</option>
                                                                        <option value="Hijo(a) Menor">Hijo(a) Menor</option>
                                                                        <option value="Hijo(a) Estudiante">Hijo(a) Estudiante</option>
                                                                        <option value="Hijo(a) invalido">Hijo(a) inválido</option>
                                                                        <option value="Hermano(a)">Hermano(a)</option>
                                                                        <option value="Hermano(a) Invalido">Hermano(a) Invalido</option>
                                                                        <option value="Conyuge/Compañero(a)">Conyuge/Compañero(a)</option>
                                                                        <option value="Herederos indeterminados"> Herederos indeterminados </option>
                                                                        <option value="Esposo(a)"> Esposo(a) </option>
                                                                        <option value="Compañero(a)"> Compañero(a) </option>
                                                                        <option value="Otro">Otro</option>
                                                                    </select>
                                                                </div>
                                                                </div>
                                                                <div className='formEditBeneficiarioObservacionesContainer'>
                                                                    <label>Observaciones</label>
                                                                    <textarea
                                                                        id='inputObservacionesBeneficiarios'
                                                                        defaultValue={ele.observaciones}
                                                                        name="observaciones"
                                                                        onChange={handleInputChangeEdit}
                                                                    />
                                                                </div>
                                                            </EditModal>
                                                            <NotificationModal isOpen={deleteModal} setIsOpen={setDeleteModal} typeModal='deleteBeneficiarioConfirm' handleEliminar={() => handleEliminar(ele.identificacion)} ></NotificationModal>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>

                    }

                </Card.Body>
            </Card>
        </Fragment>


    )
}
