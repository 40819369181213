import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import FormularioDinamico from '../../components/GestionadorDinamico/FormularioDinamico';
import { Close, Launch } from '@material-ui/icons';
import { Dialog } from 'primereact/dialog';
import './styles/controles.scss';

export default function ModalForm({ item, handleInputChange, index, idx, reg, nombre, error, esEditable, idSegundoNivel, idTercerNivel }) {
  const [show, setShow] = useState(false); //Indica si se muestra o no la modal
  const handleClose = () => setShow(false); //Cierra la modal
  const handleShow = () => setShow(true); //Abre la modal

  return (
    <div className="modal__nested__container">
      <div className="modal__nested__title">
        <Launch className="modal__nested__icon" />
        {item?.titulo}
      </div>
      <div onClick={handleShow} className='confirmButton modal__open__button'>
        {item?.boton}
      </div>
      {/*
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal__form__nested__dialog"
        className='modal__form__nested'>
        <Modal.Body className="modal__form__nested__body" style={{ zIndex: zIndex }}>
          <div className="modal__form__nested__close">
            <Close onClick={handleClose} />
          </div>
          <FormularioDinamico anidado={true} titulo={item?.titulo} idSegundoNivel={idSegundoNivel} idTercerNivel={idTercerNivel} subseccionCampo={item?.nombre}></FormularioDinamico>
        </Modal.Body>
      </Modal>
  */}
      {
        <Dialog
          visible={show}
          onHide={handleClose}
          breakpoints={{ '992px': '100vw', '5000px': '75vw' }}
        >
          <div className="modal__form__nested__body" >
            <FormularioDinamico anidado={true} titulo={item?.titulo} idSegundoNivel={idSegundoNivel} idTercerNivel={idTercerNivel} subseccionCampo={item?.nombre}></FormularioDinamico>
          </div>
        </Dialog>
      }
    </div>
  )
}
