// import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { HashRouter as Router, Switch } from 'react-router-dom';
// Link


//Administrativo y financiero
// import Cartera from '../pages/presidencia/administrativo/Cartera'
// import Compras from '../pages/presidencia/administrativo/Compras'
// import Contabilidad from '../pages/presidencia/administrativo/Contabilidad'
// import Facturacion from '../pages/presidencia/administrativo/Facturacion'
// import GestionIntegral from '../pages/presidencia/administrativo/GestionIntegral'
// import ServiciosGenerales from '../pages/presidencia/administrativo/ServiciosGenerales'

// //comercial
// import Comunicaciones from '../pages/presidencia/Comercial/Comunicaciones'
// import GestionComercial from '../pages/presidencia/Comercial/GestionComercial'

// //Gestion Humana
// import Bienestar from '../pages/presidencia/Gestion-humana/Bienestar'
// import Capacitacion from '../pages/presidencia/Gestion-humana/Capacitacion'
// import Contratacion from '../pages/presidencia/Gestion-humana/Contratacion'
// import Nomina from '../pages/presidencia/Gestion-humana/Nomina'
// import Reclutamiento from '../pages/presidencia/Gestion-humana/Reclutamiento'
// import SST from '../pages/presidencia/Gestion-humana/SST'

// //juridico
// import ProcesosAdministrativos from '../pages/presidencia/Juridico/ProcesosAdministrativos'
// import ProcesosCiviles from '../pages/presidencia/Juridico/ProcesosCiviles'
// import ProcesosComerciales from '../pages/presidencia/Juridico/ProcesosComerciales'
// import ProcesosLaborales from '../pages/presidencia/Juridico/ProcesosLaborales'

// //TI
// import Configuracion from '../pages/presidencia/TI/Configuracion'
// import Infraestructura from '../pages/presidencia/TI/Infraestructura'
// import Seguridad from '../pages/presidencia/TI/Seguridad'

//paginas GERENCIA

//actions reducer

import { LoginRoute } from './LoginRoute';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { DashboardRoutes } from './DashboardRoutes'



export const Routes = () => {




  /*  */

  const login = useSelector(state => state.login)



  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/login" component={LoginRoute} isAuthenticated={login.logged} />
        <PrivateRoute path="/" component={DashboardRoutes} isAuthenticated={login.logged} />
      </Switch>
    </Router>

  );
}

