import React, {useState, useEffect, Fragment} from 'react'
import { Card, Row, Form } from 'react-bootstrap'
import useState2 from 'react-usestateref';
import { useForm } from 'react-hook-form';
import InputType from '../../../helpers/InputType'

import './Filtros.scss'


const Filtros = (props) => {
  const functions = require('./Filtros')

  const [ inputGroup, setInputGroup, inputGroupRef ] = useState2([]);
  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();
  const [inputList, setInputList] = useState([{  }]);

  const {perfil} = props

  useEffect(() => {
    setInputGroup(props?.campos)
  }, [])

  return (
    <Card className='shadow'  style={{padding: '30px' , borderRadius: '20px', width: '100%'}} >
      <Card.Body>
        {!inputGroup.length
            ?
              <p  className='pagesTitles' style={{height: '100%'}} >Cargando filtros...</p>
            :
            <>
              {(inputGroupRef.current && inputGroupRef.current.length > 0) &&
              <>
                <Form  noValidate onSubmit={handleSubmit(props.function)} style={{ width:'98%', margin:'0 auto', padding: '0'}}>
                  <div id='contenedorFiltrosGerencia'>
                    {(inputGroupRef.current?.map( (campo, index) => {
                      const fieldName = `asesoria.0`;
                      return (
                        <InputType
                          item={campo}
                          index={index}
                          idx={index}
                          key={`${0} - ${index}`}
                          reg={register}
                          nombre={`${fieldName}`}
                          errors={errors}
                          col={campo.col}
                          type=""
                          inputList={inputList}
                          setInputList={setInputList}
                        />
                      )
                    }))}
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: '20px auto 0'
                    }}
                  >
                    <button
                      type="submit"
                      className='confirmButton'
                      style={{
                        marginRight: '0 !important'
                      }}
                    >
                      Filtrar
                    </button>
                  </div>
                </Form>
              </>
              }
            </>
          }
      </Card.Body>
    </Card>
  )
}

export { Filtros }