import React from 'react'
import { Page, Text, View, Document, Image, StyleSheet, PDFViewer, Canvas } from '@react-pdf/renderer';

function Lista({color, lista}) {

  

  const styles = StyleSheet.create({
    contenedor: {
      backgroundColor: '#F2F2F2',
      width: '110%',
      padding: '3% 2%',
      // borderRadius: '0pt 0pt 10pt',
      borderBottomLeftRadius: '15pt',
      borderBottomRightRadius: '15pt',
      margin: '0 -25px'
      // boxShadow: '0px 3px 14px -6px rgba(0,0,0,0.75)'
    },
    item: {
      backgroundColor: 'white',
      borderRadius: '6pt',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      margin: '5px 0',
      padding: '5px'
    },
    item_header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%', 
      color: `${color}`,
      padding: '3px 0'     
    },

    text_title:{
      fontSize: '10pt'
    },
    text_ubi:{
      fontSize: '9pt'
    },
    text_desc:{
      fontSize: '9pt',
      color: '#666666',
      textAlign: 'justify'
    },

  })

  return (
    <View style={styles.contenedor} >
      {lista.map((item, index) => {
        return(
          <View key={index} style={styles.item} >
            <View style={styles.item_header} >
              <Text style={styles.text_title} >{item.titulo}</Text>
              <View>
                <Text style={styles.text_ubi} >{item.ubicacion},{item.fecha}</Text>
              </View>
            </View>
            <View>
              <Text style={styles.text_desc} >{item.descripcion}</Text>
            </View>
          </View>

        )
      })

      }
    </View>
  )
}

export {Lista}
