import React from 'react'

import { SuccessSVG } from '../components/icons/SuccessSVG'
import { EditSVG } from '../components/icons/EditSVG'
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { IconoPanel } from '../components/icons/IconoPanel'
import { RiesgoSVG } from '../components/icons/RiesgoSVG'
import { DeinedSVG } from '../components/icons/DeinedSVG'
import { PdfIcon } from '../components/icons/PdfIcon';
import { RelojIcon } from '../components/icons/RelojIcon'
import { IconoDinamico } from '../components/icons/IconoDinamico';

function RenderIcon({ icon }) {

  switch (icon?.tipo) {

    case 'precaucion':
      return (
        <ReportProblemOutlinedIcon
          style={{
            color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

    case 'lista':
      return (
        <AssignmentRoundedIcon
          style={{
            color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

    case 'pdf':
      return (
        <PdfIcon
          color={icon.color ? icon.color : '#213265'}
          style={{
            cursor: 'pointer'
          }}
        />
      );

    case 'reloj':
      return (
        <RelojIcon
          color={icon.color ? icon.color : '#213265'}
          style={{
            cursor: 'pointer'
          }}
        />
      );

    case 'panel':
      return (
        <IconoPanel

          color={icon.color ? icon.color : '#213265'}
          style={{
            // color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

    case 'eliminar':
      return (
        <DeinedSVG

          color={icon.color ? icon.color : '#213265'}
          style={{
            // color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

    case 'gestionar':
      return (
        <EditSVG

          fill={icon.color ? icon.color : '#213265'}
          style={{
            // color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

      ;

    case 'aprobado':
      return (
        <SuccessSVG

          fill={icon.color ? icon.color : '#213265'}
          style={{
            // color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

    case 'riesgo':
      return (
        <RiesgoSVG

          fill={icon.color ? icon.color : '#213265'}
          style={{
            // color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

    case 'negado':
      return (
        <DeinedSVG

          fill={icon.color ? icon.color : '#213265'}
          style={{
            // color: icon.color ? icon.color : '#213265',
            cursor: 'pointer'
          }}
        />
      );

    default:
      return <IconoDinamico icon={icon} />;
  }


}

export { RenderIcon }
