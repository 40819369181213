import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { validarEntorno } from '../../../helpers/validarHost';
import SuccessIcon from '../../../helpers/SuccessIcon/SuccessIcon';
import { ErrorSVG } from '../../icons/ErrorSVG';
import { WarningModalSVG } from '../../icons/WarningModalSVG';
import GetAppIcon from '@material-ui/icons/GetApp';

import InfoGeneral from '../../gestionAuditorias/PanoramaRiesgos/PestanasInforme/InfoGeneral'
import Factores from '../../gestionAuditorias/PanoramaRiesgos/PestanasInforme/Factores'
import MatrizRiesgos from '../../gestionAuditorias/PanoramaRiesgos/PestanasInforme/MatrizRiesgos'
import AnalisisProspectivo from '../../gestionAuditorias/PanoramaRiesgos/PestanasInforme/AnalisisProspectivo'
import Preview from '../../gestionAuditorias/PanoramaRiesgos/PestanasInforme/Preview'
import { useTranslation } from 'react-i18next';

export default function GestorInformes() {

  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })

  const { id } = useParams()
  const [estadoRegistro, setEstadoRegistro] = useState('')
  const [pestaña, setPestaña] = useState('general')
  const { t } = useTranslation();

  const renderFunction = () => {
    switch (pestaña) {
      case 'general':
        return <InfoGeneral pestana='descripcion_fisica' />
      case 'factores':
        return <Factores pestana='factores' />
      case 'riesgos':
        return <MatrizRiesgos pestana='analisis' dataG={initialState?.data?.riesgos} />
      case 'analisis':
        return <AnalisisProspectivo pestana='cierre' />
      case 'preview':
        return <Preview pestana='preview' />
      default:
        break;
    }
  }

  const getInforme = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: id
    };

    //console.log(dataReq);

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      // //console.log(res)
      setInitialState({
        ...initialState,
        data: res
      })

      setEstadoRegistro(res.informacion_solicitud.estado.valor)

      //console.log(initialState.data);
    }
  }

  useEffect(() => {
    getInforme()
  }, [])


  return (
    <>
      {/* <p>{titulo}</p> */}
      <Card className='card__box__surface mt-3'>
        <Card.Body>
          <Row style={{ display: 'flex', justifyContent: 'center' }} >

            <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
              {initialState?.data?.informacion_solicitud?.estado?.valor
                ?
                <>
                  <div>
                    {estadoRegistro === 'aprobado'
                      ? <div>
                        <SuccessIcon />
                        <h3>{`${estadoRegistro}`}</h3>
                      </div>
                      : estadoRegistro === 'denegado'
                        ? <div>
                          <ErrorSVG />
                          <h3>{`${estadoRegistro}`}</h3>
                        </div>
                        : estadoRegistro === 'pendiente de aprobacion' || ''
                          ? <div>
                            <WarningModalSVG />
                            <h3>{`${estadoRegistro}`}</h3>
                          </div>
                          : <div>
                            <WarningModalSVG />
                            <h3>Verificar estado </h3>
                          </div>
                    }
                  </div>
                  {initialState?.data?.informacion_solicitud?.generarPDF?.visualizar === true &&
                    <>
                      <br />
                      <div className='col-12 buttonGenerarPDF'>
                        {/* <button className='confirmButton' onClick={() => setOpenDocumentModal()}>
                                    Generar PDF
                                    <GetAppIcon/>
                                </button> */}
                        {/* <button className='confirmButton'>
                                    Generar PDF
                                    <GetAppIcon/>
                                </button> */}
                      </div>
                    </>
                  }
                </>

                : null
              }
            </Col>
            <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
              {initialState?.data?.informacion_solicitud?.estado.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p className='labelInfo'>{t('formulario.label.estado')}:</p>
                  <p className='labelInfoContent'>{initialState?.data?.informacion_solicitud.estado.valor}</p>
                </div>
              }
              {initialState?.data?.informacion_solicitud?.fecha_solicitud.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p className='labelInfo'>{initialState?.data.informacion_solicitud.fecha_solicitud.nombre}</p>
                  <p className='labelInfoContent'>{initialState?.data.informacion_solicitud.fecha_solicitud.valor}</p>
                </div>

              }
              {initialState?.data?.informacion_solicitud?.perfil.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.perfil.tipo}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.perfil.nombre}
                  </p>
                </div>
              }
              {initialState?.data?.informacion_solicitud?.analista.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.analista.nombre}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.analista.valor}
                  </p>
                </div>
              }

              {initialState?.data?.informacion_solicitud?.fecha_finalizacion?.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p className='labelInfo'>{t('formulario.label.fecha_finalizacion')}</p>
                  <p className='labelInfoContent'>{initialState?.data?.informacion_solicitud?.fecha_finalizacion?.valor}</p>
                </div>
              }
            </Col>
            <Col xs={6} lg={4} md={3} className='listPopUp mt-3' >
              {initialState?.data?.informacion_solicitud?.identificador.visualizar === true
                ?
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.identificador.tipo}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.identificador.valor}
                  </p>
                </div>
                : null
              }
              <div className='infoBasicaEditarRegistro'>
                <p>ID</p>
                <p className='labelInfoContent'>
                  {id}
                </p>
              </div>


              {initialState?.data?.informacion_solicitud?.fecha_limite &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{t('formulario.label.fecha_limite')}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.fecha_limite.valor}
                  </p>
                </div>
              }



            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      {/* PESTAÑAS */}
      <div className="card__box__surface containerPestañas p-3 mb-3 mt-3 card d-flex flex-row gap-3" id='pestanasGestor'>
        <div className='d-flex flex-row gap-3 mr-auto'>
        {initialState?.data?.permisos?.descripcion_fisica &&
          <div
            className="   pestanaGestorPanorama"
            onClick={() => setPestaña('general')}
            style={{
              background: pestaña !== 'general' && 'rgb(203, 215, 230)',
              color: pestaña !== 'general' && '#213265'

            }}
          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.informacion_general')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>
        }
        {initialState?.data?.permisos?.factores &&
          <div
            className="  pestanaGestorPanorama"
            onClick={() => setPestaña('factores')}
            style={{
              background: pestaña !== 'factores' && 'rgb(203, 215, 230)',
              color: pestaña !== 'factores' && '#213265'
            }}

          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.factores')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>
        }
        {initialState?.data?.permisos?.analisis &&
          <div
            className="d-flex  pestanaGestorPanorama"
            onClick={() => setPestaña('riesgos')}
            style={{
              background: pestaña !== 'riesgos' && 'rgb(203, 215, 230)',
              color: pestaña !== 'riesgos' && '#213265'

            }}

          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.matriz_riesgos')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>
        }
        {initialState?.data?.permisos?.cierre_informe &&

          <div
            className="d-flex  pestanaGestorPanorama"
            onClick={() => setPestaña('analisis')}
            style={{
              background: pestaña !== 'analisis' && 'rgb(203, 215, 230)',
              color: pestaña !== 'analisis' && '#213265'

            }}

          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.analisis_prospectivo')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>

        }

        {initialState?.data?.permisos?.preview &&

          <div
            className="d-flex  pestanaGestorPanorama"
            onClick={() => setPestaña('preview')}
            style={{
              background: pestaña !== 'preview' && 'rgb(203, 215, 230)',
              color: pestaña !== 'preview' && '#213265'

            }}

          >
            <p className=" p-0 m-0  ">{t('panorama_riesgos.vista_geografica_previa')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>

        }

        {/* <div
                  className="d-flex pestañasLi"
                  onClick={() => setPestaña('Información general')}
                  style={{ background: pestaña !== 'Información general' && 'rgb(203, 215, 230)' }}

              >
                  <p className=" p-0 m-0"> Información general </p>
                  <b style={{
                      width: '25px',
                      fontSize: '30px',
                      marginTop: '-20px',
                      fontWeight: '900',
                      color: "#00A20B"
                  }} >.</b>
              </div> */}
              </div>
      </div>
      <Card className='card__box__surface'>
        <Card.Body >
          {renderFunction()}
        </Card.Body>
      </Card>
    </>
  )
}
