
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, withStyles } from "@material-ui/core";
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 7,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#7eebaa',
      transition: 'transform 0.2s linear',
      transformOrigin: 'left'
    },
  }))(LinearProgress);


const ProgressPercentageBar = ({ data }) => {

    const classes = useStyles();
    
    const [progress, setProgress] = useState(0);

    return (
        <div className={classes.root}>
            <BorderLinearProgress variant="determinate" value={data} />
        </div>
    )

}

export default ProgressPercentageBar;