import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Nav, Row, Accordion } from 'react-bootstrap';
import OtrosItemsInforme from './OtrosItemsInforme';
import { useTranslation } from "react-i18next";

export const Causas = ({ pestana }) => {
  const { t } = useTranslation();
  const [listItems, setListItems] = useState({});
  const [eventFormId, setEventFormId] = useState(1);
  const [activeFormKey, setActiveFormKey] = useState(1);
  const [titulo, setTitulo] = useState(t('panorama_riesgos.casuisticas'));
  const [tipo, setTipo] = useState('casuistica');


  const eventFormIdValue = (value, titulo, tipo) => {
    setEventFormId(value);
    setActiveFormKey(value);
    setTitulo(titulo);
    setTipo(tipo);

    // console.log(activeFormKey);
  }

  const getDataAnalisis = async () => {

    const dataReq = {}

    // pendiente por definicion

  }

  useEffect(() => {
    getDataAnalisis()
  }, [])


  return (
    <Card className=' cardUnderPestana' >
      <Card.Body>
        <Row className="row-content">
          <Col xl={2} lg={3} md={4} xs={0} id="sidebar-wrapper">
            <Nav defaultActiveKey={activeFormKey} activeKey={activeFormKey} className="shadow p-2 flex-column nav-items">
              <Nav.Link className="item-a" eventKey="1" onClick={() => eventFormIdValue(1, t('panorama_riesgos.casuisticas'), 'casuistica')}>
                {t('panorama_riesgos.casuisticas')}
              </Nav.Link>
              <Nav.Link className="item-a" eventKey="2" onClick={() => eventFormIdValue(2, t('panorama_riesgos.proyecciones'), 'Proyeccion')}>
                {t('panorama_riesgos.proyecciones')}
              </Nav.Link>
              <Nav.Link className="item-a" eventKey="3" onClick={() => eventFormIdValue(3, t('panorama_riesgos.noticias'), 'noticias')}>
                {t('panorama_riesgos.noticias')}
              </Nav.Link>
            </Nav>
          </Col>
          <Col xl={10} lg={9} md={8} xs={12} id="page-content-wrapper">
            <OtrosItemsInforme
              idSubseccion={activeFormKey}
              titulo={titulo}
              tipo={tipo}
              pestana={pestana}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
