import { Card } from 'react-bootstrap';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { envioDataCrearSolicitud } from '../../../../reducers/crearSolicitudReducer';
import { CalendarSVG } from '../../../icons/CalendarSVG';
import GenericModal from '../../../modales/NotificationModal';
import { validarEntorno } from '../../../../helpers/validarHost';
import '../EstudioBasico.scss'
import moment from 'moment';
import { useTranslation } from 'react-i18next';


export const InformacionGeneral = ({ ele, index, selects, pestaña, selectSeleccionado }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const crearSolicitud = useSelector(state => state.crearSolicitud)
  const solicitud = useSelector(state => state.solicitudEstudio)


  // const {idSolicitud} = solicitud?.data?.idSolicitud



  const codigoFuncion = crearSolicitud.codigo ? crearSolicitud.codigo : undefined
  const [validated, setValidated] = useState(false);
  const { data } = useSelector(state => state.solicitudEstudio)
  const [dataSelects, setDataSelects] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [tipoRiesgoSelected, setTipoRiesgoSelected] = useState(false)
  const [primerNombreVal, setPrimerNombreVal] = useState('')
  const [segundoNombreVal, setSegundoNombreVal] = useState('')
  const [primerApellidoVal, setPrimerApellidoVal] = useState('')
  const [SegundoApellidoVal, setSegundoApellidoVal] = useState('')
  const [selectCiudad, setSelectCiudad] = useState(false)

  // const hash = window.location.hash

  useEffect(() => {

    dataSelectsFuncion(data.data)
  }, [])



  const dataSelectsFuncion = (data) => {
    data.forEach(element => {
      if (element.pestana === pestaña) {
        setDataSelects(element.contenido[0].data)

      }
    });

  }

  const [campoReg, setCampoReg] = useState('')


  const [tipoModal, setTipoModal] = useState('')
  const [modal, setModal] = useState(false)
  const [mensajeModal, setMensajeModal] = useState('')
  const [codigoSucces, setCodigoSucces] = useState('')

  const { label } = selects


  const [info, setInfo] = useState({
    hash: window.location.hash,
    idSolicitud: solicitud.data.idSolicitud,
    riesgo_detalle: '',
    primer_nombre: '',
    primer_apellido: '',
    tipo_identificacion: '',
    identificacion: '',
    radicado: '',
    observaciones: '',
    n_dictamen: '',
    junta: '',
    fecha_dictamen: '',
    select: label
  });

  const [startDate, setStartDate] = useState('')


  const ExampleCustomInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className="dateBox" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
        <div style={{ display: 'flex', paddingLeft: '7px', paddingRight: '7px' }}>
          <label onClick={onClick} ref={ref} className='mr-3'>
            {value}
          </label>
          <i onClick={onClick} ref={ref} >
            <CalendarSVG />
          </i>
        </div>
      </div>

    ),
  );


  const handleChange = e => {

    const reg = new RegExp(/[^0-9_]/g)

    // const regName = /^[([a-zA-ZáéíóúüñÑ])]$/u


    if (e?.target?.name === 'primer_nombre' || e?.target?.name === 'segundo_nombre' || e?.target?.name === 'primer_apellido' || e?.target?.name === 'segundo_apellido') {

      // console.log(e.target.name)

      // const regular =  e?.target?.value?.replace(reg,'')
      const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑ ]/g)

      const validado = reg1.test(e.target.value)


      if (!validado) {

        if (e.target.name === 'primer_nombre') {
          setPrimerNombreVal(e.target.value)
        } else if (e?.target?.name === 'segundo_nombre') {
          setSegundoNombreVal(e.target.value)
        } else if (e?.target?.name === 'primer_apellido') {
          setPrimerApellidoVal(e.target.value)
        } else if (e?.target?.name === 'segundo_apellido') {
          setSegundoApellidoVal(e.target.value)
        }


        setInfo({
          ...info,
          [e.target.name]: e.target.value,
        })

      }


    } else

      if (e?.target?.name === 'riesgo_detalle' && e?.target?.value !== ('' || "1" || 1)) {
        setTipoRiesgoSelected(true)
        setInfo({
          ...info,
          riesgo_detalle: e.target.value,
        })

      } else if (e?.target?.name === 'radicado') {
        const validate = reg.test(e.target.value)
        if (!validate) {
          setCampoReg(e.target.value)
          setInfo({
            ...info,
            radicado: e.target.value,
          })

        }



      } else if (e?.target?.name === 'n_dictamen') {
        // n_dictamen
        const reg2 = new RegExp(/[^0-9-]/g)

        const validado2 = reg2.test(e.target.value)

        if (!validado2) {
          // setCampoReg(e.target.value)
          setInfo({
            ...info,
            [e.target.name]: e.target.value,
          })

        }
      } else {

        if (e?.target?.name) {
          setInfo({
            ...info,
            [e.target.name]: e.target.value,
          })
        } else {
          setInfo({
            ...info,
            fecha_dictamen: e,
          })
        }

      }

    // console.log(info)



  };


  const handleSubmit = async (e) => {
    e.preventDefault()

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }

    setValidated(true)

    if (form.checkValidity() === true && info.riesgo_detalle !== '') {

      setIsDisabled(true)

      if (info.tipo_identificacion === "") {
        let infoEnviar = info
        infoEnviar.tipo_identificacion = "2"
        infoEnviar.fecha_dictamen = startDate;

        console.log(infoEnviar);
        //Local
        //const respuesta = await peticionSinToken('https://127.0.0.1:5500/public/JSON/11.respuestaSolicitudInicialJson.html')
        //Produccion
        const respuesta = await validarEntorno('JSON/informacion_general_json.html', 'POST', infoEnviar)

        if (respuesta.ok) {
          const body = await respuesta.json();
          if (body.ok.estado) {
            dispatch(envioDataCrearSolicitud(body))
            setTipoModal('crearSolicitudInvEspecificas')
            setMensajeModal('')
            setModal(true)
            setIsDisabled(false)
            setCodigoSucces(body.idSolicitud)
            dispatch(envioDataCrearSolicitud(body))

          } else {
            setIsDisabled(false);
            setMensajeModal(body.ok.mensaje)
            setModal(true)
            setTipoModal('errorFormSolicitud')
          }
        }


      } else {
        // console.log(info)
        const respuesta = await validarEntorno('JSON/informacion_general_json.html', 'POST', info)
        if (respuesta.ok) {
          const body = await respuesta.json()
          if (body.ok.estado === 'ok') {
            dispatch(envioDataCrearSolicitud(body))
            setTipoModal('crearSolicitud')
            setMensajeModal('')
            setModal(true)
            setIsDisabled(false)

            setCodigoSucces(body.idSolicitud)

          } else {
            setIsDisabled(false)
            setMensajeModal(body.ok.mensaje)
            setModal(true)
            setTipoModal('errorFormSolicitud')
          }
        }
      }
      //setModal(true)
    }
  };


  return (
    <Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ margin: '0px', paddingRight: '12px' }}>
        {ele.contenido[0].visualizar
          &&
          <Card className='shadow cardContainerSolicitud'>
            <h5 className='titleInsideCard'>{t('formulario.label.informacion_solicitud')}</h5>
            <Card.Body>
              <div className='formEstudioBasicoContainer' >
                <div className='formItem' id='basicFormA'>
                  <Form.Group >
                    <Form.Label>{t('formulario.label.tipo_riesgo')}</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="riesgo_detalle"
                        onChange={handleChange}
                        required={true}
                        as='select'
                        type='text'
                        readOnly={codigoFuncion === undefined}
                        isInvalid={!tipoRiesgoSelected}
                        isValid={tipoRiesgoSelected}
                        defaultValue=''
                      >
                        <option value='' disabled >{t('formulario.label.seleccione_opcion')}</option>
                        <optgroup label="Vejez">
                          <option value="Vejez - Pensión familiar"> Pensión familiar </option>
                          <option value="Vejez - Pensión de vejez"> Pensión de vejez </option>
                          <option value="Vejez - Pensión vejez alto riesgo"> Pensión vejez alto riesgo </option>
                          <option value="Vejez - Pensión vejez convenios internacionales"> Pensión vejez convenios internacionales </option>
                          <option value="Vejez - Pensión vejez compartida"> Pensión vejez compartida </option>
                          <option value="Vejez - Pensión vejez periodista"> Pensión vejez periodista </option>
                          <option value="Vejez - Pensión vejez madre o padre trabajador hijo inválido"> Pensión vejez madre o padre trabajador hijo inválido </option>
                          <option value="Vejez - Pensión vejez convenios internacionales"> Pensión vejez convenios internacionales </option>
                          <option value="Vejez - Pensión especial de vejez anticipada por invalidez"> Pensión especial de vejez anticipada por invalidez </option>
                        </optgroup>
                        <optgroup label="Invalidez">
                          <option value="Invalidez - Pensión invalidez convenios internacionales"> Pensión invalidez convenios internacionales </option>
                          <option value="Invalidez - Pensión invalidez"> Invalidez - Pensión invalidez </option>
                          <option value="Pensión Invalidez - Enfermedad Congénita"> Pensión Invalidez - Enfermedad Congénita </option>

                        </optgroup>
                        <optgroup label="Muerte">
                          <option value="Muerte - Pensión sobrevivientes convenios internacionales"> Pensión sobrevivientes convenios internacionales </option>
                          <option value="Muerte - Pensión sobrevivientes"> Pensión sobrevivientes </option>
                          <option value="Muerte - Pensión sobrevivientes - 2900"> Pensión sobrevivientes - 2900</option>
                          <option value="Muerte - Sustitución pensional"> Sustitución pensional </option>
                          <option value="Muerte - Sustitución provisional ley 1204/08"> Sustitución provisional ley 1204/08 </option>
                        </optgroup>
                        <optgroup label="Indemnización sustitutiva">
                          <option value="Indemnización sustitutiva - Indemnización vejez"> Indemnización vejez </option>
                          <option value="Indemnización sustitutiva - Indemnización invalidez"> Indemnización invalidez </option>
                          <option value="Indemnización sustitutiva - Indemnización sobrevivencia"> Indemnización sobrevivencia </option>
                        </optgroup>
                        <optgroup label="Auxilio funerario">
                          <option value="Auxilio funerario - Auxilio funerario"> Auxilio funerario </option>
                        </optgroup>
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_tipo_riesgo')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <div className='inputContainer'>
                    <Form.Group>
                      <Form.Label>
                        {t('formulario.label.nombre')}
                      </Form.Label>
                      <div className='inputContainer'>
                        <Form.Control value={primerNombreVal} onChange={handleChange} name='primer_nombre' required type='text' placeholder={t('formulario.label.primer_nombre')}></Form.Control>
                        <Form.Control value={segundoNombreVal} onChange={handleChange} name='segundo_nombre' type='text' placeholder={t('formulario.label.segundo_nombre')}></Form.Control>
                        <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_nombres')}</Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.apellido')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control value={primerApellidoVal} onChange={handleChange} name='primer_apellido' required type='text' placeholder={t('formulario.label.primer_apellido')}></Form.Control>
                      <Form.Control value={SegundoApellidoVal} onChange={handleChange} name='segundo_apellido' type='text' placeholder={t('formulario.label.segundo_apellido')}></Form.Control>

                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_apellidos')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
                <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>

                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.identificacion')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} defaultValue="2" name='tipo_identificacion' id='typeIdSelect' as='select' className='form-control' custom required>
                        <option value="2" > {t('formulario.select.cedula_ciudadania')} </option>
                        <option value="3"> {t('formulario.select.cedula_extranjeria')} </option>
                        <option value="4"> {t('formulario.select.tarjeta_identidad')} </option>
                        <option value="9"> {t('formulario.select.registro_civil')} </option>
                        <option value="5"> {t('formulario.select.pasaporte')} </option>
                        <option value="1">{t('formulario.label.nit')} </option>
                      </Form.Control>
                      <Form.Control
                        onChange={handleChange}
                        name='identificacion'
                        type='number'
                        placeholder={t('formulario.label.identificacion')}
                        min='0'
                        max='9999999999'
                        onWheel={(e) => e.target.blur()}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_numero_identificacion')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.radicado')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='radicado' required type='text' placeholder={t('formulario.label.radicado')} value={campoReg} maxLength={20}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_numero_radicado')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  {
                    dataSelects &&
                    dataSelects.map((selects, index) => {
                      return <Form.Group key={index}>
                        <Form.Label>
                          {selects.label}
                        </Form.Label>
                        <div className='inputContainer'>
                          <Form.Control
                            name={selects.name}
                            onChange={handleChange}
                            required={true}
                            as='select'
                            type='text'
                            readOnly={codigoFuncion === undefined}
                            style={{ background: 'white' }}
                          >
                            {
                              selects.options.map(option => {
                                return <option key={option.value} value={option.value}>{option.label}</option>
                              })
                            }

                          </Form.Control>
                          <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    })
                  }


                </div>
                <div id='observacionesContainerEstudioBasico'>
                  <Form.Group id='observacionesFormGroup'>
                    <Form.Label className='observacionesLabel'>
                      {t('formulario.label.observaciones')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} as='textarea' placeholder={t('formulario.label.observaciones')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_observaciones')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
                <div className='buttonContainer'>
                  <button className='confirmButton' disabled={isDisabled}>{t('botones.guardar')}</button>
                  {/* <ModalConfirmacion typeModal='error' isOpen={showModal} setIsOpen={setShowModal} />                */}
                </div>
              </div>
            </Card.Body>
          </Card>

        }
        <br />
        {
          (ele?.contenido[1]?.visualizar)
          && <Card className='shadow cardContainerSolicitud' style={{ borderTopLeftRadius: '10px' }}>
            <h5 className='titleInsideCard'>{t('formulario.label.informacion_dictamen')}</h5>
            <Card.Body>
              <Form className='' noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='formItem' id='contenedorInputsInfoDictamen' >



                  <Form.Group className='formgroupInputsInfoDictamen' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                    <Form.Label style={{ marginLeft: '0px' }}>{t('formulario.label.junta')}</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="junta"
                        onChange={handleChange}
                        required={selectSeleccionado === "4" ? true : false}
                        as='select'
                        type='text'
                      >
                        <option value="" >{t('formulario.label.seleccione_opcion')}</option>
                        <option value="No Aplica"> No Aplica </option>
                        <option value="Junta Nacional de Calificación de Invalidez"> Junta Nacional de Calificación de Invalidez </option>
                        <option value="Junta Regional de Calificación de Invalidez de Antioquia"> Junta Regional de Calificación de Invalidez de Antioquia </option>
                        <option value="Junta Regional de Calificación de Invalidez de Atlántico"> Junta Regional de Calificación de Invalidez de Atlántico </option>
                        <option value="Junta Regional de Calificación de Invalidez de Bogotá y Cundinamarca"> Junta Regional de Calificación de Invalidez de Bogotá y Cundinamarca </option>
                        <option value="Junta Regional de Calificación de Invalidez de Bolívar"> Junta Regional de Calificación de Invalidez de Bolívar </option>
                        <option value="Junta Regional de Calificación de Invalidez de Boyaca"> Junta Regional de Calificación de Invalidez de Boyaca </option>
                        <option value="Junta Regional de Calificación de Invalidez de Caldas"> Junta Regional de Calificación de Invalidez de Caldas </option>
                        <option value="Junta Regional de Calificación de Invalidez de Cauca"> Junta Regional de Calificación de Invalidez de Cauca </option>
                        <option value="Junta Regional de Calificación de Invalidez de Cesar"> Junta Regional de Calificación de Invalidez de Cesar </option>
                        <option value="Junta Regional de Calificación de Invalidez de Huila"> Junta Regional de Calificación de Invalidez de Huila </option>
                        <option value="Junta Regional de Calificación de Invalidez de Magdalena"> Junta Regional de Calificación de Invalidez de Magdalena </option>
                        <option value="Junta Regional de Calificación de Invalidez de Meta"> Junta Regional de Calificación de Invalidez de Meta </option>
                        <option value="Junta Regional de Calificación de Invalidez de Nariño"> Junta Regional de Calificación de Invalidez de Nariño </option>
                        <option value="Junta Regional de Calificación de Invalidez de Norte de Santander"> Junta Regional de Calificación de Invalidez de Norte de Santander </option>
                        <option value="Junta Regional de Calificación de Invalidez de Quindío"> Junta Regional de Calificación de Invalidez de Quindío </option>
                        <option value="Junta Regional de Calificación de Invalidez de Risaralda"> Junta Regional de Calificación de Invalidez de Risaralda </option>
                        <option value="Junta Regional de Calificación de Invalidez de Santander"> Junta Regional de Calificación de Invalidez de Santander </option>
                        <option value="Junta Regional de Calificación de Invalidez de Sucre"> Junta Regional de Calificación de Invalidez de Sucre </option>
                        <option value="Junta Regional de Calificación de Invalidez de Tolima"> Junta Regional de Calificación de Invalidez de Tolima </option>
                        <option value="Junta Regional de Calificación de Invalidez Valle del Cauca"> Junta Regional de Calificación de Invalidez Valle del Cauca </option>
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className='formgroupInputsInfoDictamen' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start' }}>
                    <Form.Label style={{ marginLeft: '0px' }}>
                      {t('formulario.label.numero_dictamen')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='n_dictamen' type='text' placeholder={t('formulario.label.numero_dictamen')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa los nombres</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className='formgroupInputsInfoDictamen' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                    <Form.Label style={{ marginLeft: '0px' }}>
                      {t('formulario.label.fecha_dictamen')}
                    </Form.Label>
                    <div className='dateContainer' >
                      <div className='dateItem' id='dateInicio' >
                        <DatePicker
                          selected={startDate !== '' ? moment(startDate).toDate() : null}
                          onChange={d => {
                            setStartDate(moment(d).format('YYYY-MM-DD'))
                            handleChange(d)
                          }}
                          customInput={<ExampleCustomInput />}
                          name="fecha_dictamen"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>

                  </Form.Group>
                </div>
              </Form>
            </Card.Body>
          </Card>
        }
      </Form>

      <GenericModal typeModal={tipoModal} setIsOpen={setModal} isOpen={modal} mensaje={mensajeModal} codigoSuccess={codigoSucces} />
      {/* <GenericModal typeModal="crearSolicitud" setIsOpen={setModal} isOpen={modal} /> */}

    </Fragment>

  )
}
