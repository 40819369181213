import React from 'react'

export const IconoCompromisos = (props) => {
    return (
      <svg version="1.1" id="Capa_1" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 511.999 511.999" fill={props.color} width="25" height="25" >
   <g>
     <g>
       <path d="M487.82,386.762l-76.821-210.191V53.604c0-5.522-4.477-10-10-10l-38.598-0.002L348.865,6.569
         c-1.896-5.188-7.637-7.853-12.825-5.96L218.424,43.596h-2.633c0.154-4.554-1.488-9.158-4.956-12.627l-15.031-15.031
         c2.669-3.894,2.281-9.255-1.178-12.714c-3.905-3.906-10.236-3.905-14.143,0l-40.379,40.379H75.308c-5.523,0-10,4.477-10,10v45.954
         l-35.169,12.855c-2.491,0.911-4.519,2.773-5.636,5.179c-1.118,2.405-1.234,5.156-0.324,7.646l41.128,112.53v220.629
         c0,5.523,4.477,10,10,10h74.291l13.536,37.035c1.482,4.055,5.313,6.57,9.393,6.57c1.14,0,2.299-0.197,3.432-0.61l117.638-42.995
         h107.401c5.522,0,10-4.477,10-10v-32.909l70.861-25.899c2.491-0.911,4.519-2.773,5.636-5.178
         C488.614,392.004,488.73,389.253,487.82,386.762z M65.308,179.505l-18.911-51.742l18.911-6.911V179.505z M333.512,22.827
         l7.594,20.775l-64.426-0.003L333.512,22.827z M150.633,61.364l31.157-31.155L194.78,43.2c-2.168,3.101-3.264,6.869-3.054,10.698
         c0.005,0.098,0.025,0.194,0.032,0.292c0.006,0.103,0.002,0.206,0.012,0.31c0.019,0.212,0.063,0.419,0.09,0.629
         c0.035,0.275,0.069,0.55,0.117,0.823c0.051,0.286,0.118,0.568,0.184,0.851c0.069,0.296,0.135,0.592,0.219,0.883
         c0.07,0.241,0.154,0.475,0.235,0.712c0.109,0.322,0.215,0.643,0.343,0.958c0.08,0.197,0.174,0.386,0.261,0.58
         c0.152,0.338,0.303,0.676,0.476,1.004c0.09,0.169,0.193,0.33,0.289,0.496c0.192,0.334,0.382,0.669,0.596,0.99
         c0.113,0.169,0.241,0.328,0.36,0.493c0.217,0.301,0.429,0.604,0.665,0.891c0.177,0.215,0.374,0.414,0.562,0.62
         c0.2,0.22,0.39,0.448,0.602,0.657c0.41,0.406,0.841,0.791,1.292,1.155c0.005,0.004,0.01,0.01,0.016,0.014l18.126,14.538
         l-22.774,22.775l-14.537-18.126c-1.058-1.319-2.306-2.448-3.677-3.385c-0.099-0.068-0.19-0.146-0.29-0.212
         c-0.08-0.052-0.166-0.093-0.247-0.143c-2.428-1.529-5.224-2.45-8.144-2.61c-0.31-0.017-0.619-0.025-0.928-0.025
         c-0.089,0-0.177,0.009-0.266,0.01c-0.203,0.003-0.405,0.013-0.607,0.023c-0.255,0.013-0.509,0.03-0.762,0.055
         c-0.202,0.02-0.403,0.043-0.604,0.07c-0.252,0.034-0.503,0.073-0.753,0.118c-0.197,0.035-0.394,0.072-0.589,0.115
         c-0.252,0.054-0.501,0.116-0.749,0.182c-0.189,0.05-0.378,0.099-0.566,0.155c-0.252,0.076-0.501,0.161-0.749,0.249
         c-0.179,0.063-0.358,0.123-0.534,0.191c-0.256,0.1-0.506,0.211-0.757,0.323c-0.163,0.073-0.327,0.14-0.487,0.218
         c-0.273,0.132-0.539,0.278-0.804,0.424c-0.131,0.072-0.265,0.137-0.394,0.213c-0.391,0.229-0.774,0.472-1.147,0.733
         l-12.994-12.994L150.633,61.364z M178.486,489.173l-7.594-20.777h64.442L178.486,489.173z M85.308,448.395V63.604h34.798
         l-4.243,4.243c-3.905,3.905-3.905,10.237,0,14.142c1.953,1.953,4.512,2.929,7.071,2.929c1.975,0,3.947-0.588,5.643-1.751
         l15.031,15.033c2.816,2.816,6.443,4.524,10.355,4.907c0.559,0.055,1.123,0.082,1.692,0.082c0.43,0,0.857-0.016,1.281-0.047
         c2.241-0.166,4.391-0.773,6.361-1.765c0.641-0.323,1.268-0.679,1.867-1.084l14.734,18.37c1.891,2.357,4.359,4.145,7.123,5.22
         c1.658,0.645,3.423,1.032,5.234,1.131c0.311,0.017,0.62,0.025,0.929,0.025c0.153,0,0.304-0.018,0.457-0.023
         c0.271-0.007,0.541-0.02,0.81-0.04c0.307-0.023,0.612-0.056,0.917-0.096c0.27-0.035,0.538-0.072,0.805-0.12
         c0.302-0.054,0.601-0.121,0.899-0.191c0.261-0.061,0.522-0.122,0.779-0.196c0.3-0.086,0.595-0.186,0.89-0.288
         c0.247-0.085,0.495-0.167,0.737-0.264c0.3-0.119,0.592-0.256,0.885-0.392c0.227-0.106,0.457-0.205,0.679-0.32
         c0.303-0.157,0.594-0.335,0.888-0.51c0.201-0.12,0.406-0.231,0.602-0.36c0.32-0.21,0.625-0.442,0.931-0.674
         c0.155-0.118,0.318-0.224,0.47-0.347c0.451-0.366,0.886-0.754,1.3-1.169l6.656-6.656l13.255,13.255
         c1.953,1.953,4.512,2.929,7.071,2.929s5.119-0.977,7.071-2.929c3.905-3.905,3.905-10.237,0-14.142l-13.255-13.254l6.655-6.655
         c3.419-3.419,5.228-8.149,4.964-12.977c-0.265-4.828-2.58-9.333-6.352-12.358l-4.561-3.658h164.261v384.791H85.308z
          M410.998,404.192V234.834l54.603,149.402L410.998,404.192z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M370.398,180.99c-0.13-0.64-0.319-1.27-0.569-1.88c-0.25-0.6-0.561-1.18-0.92-1.72c-0.37-0.55-0.78-1.06-1.25-1.52
         c-0.46-0.46-0.971-0.88-1.521-1.24c-0.54-0.36-1.12-0.67-1.72-0.92c-0.61-0.25-1.23-0.45-1.87-0.57c-1.29-0.26-2.62-0.26-3.91,0
         c-0.64,0.12-1.27,0.32-1.87,0.57c-0.6,0.25-1.189,0.56-1.729,0.92c-0.55,0.36-1.061,0.78-1.521,1.24
         c-0.46,0.46-0.88,0.97-1.239,1.52c-0.36,0.54-0.671,1.12-0.921,1.72c-0.25,0.61-0.439,1.24-0.569,1.88
         c-0.13,0.64-0.2,1.3-0.2,1.95c0,2.63,1.07,5.21,2.93,7.07c0.459,0.46,0.97,0.879,1.519,1.24c0.54,0.36,1.13,0.67,1.729,0.92
         c0.601,0.25,1.23,0.44,1.87,0.57c0.641,0.13,1.3,0.2,1.95,0.2c0.66,0,1.31-0.07,1.96-0.2c0.64-0.13,1.26-0.32,1.87-0.57
         c0.6-0.25,1.18-0.56,1.72-0.92c0.55-0.36,1.061-0.78,1.521-1.24c1.87-1.86,2.93-4.44,2.93-7.07
         C370.588,182.29,370.528,181.63,370.398,180.99z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M324.303,172.94h-80.958c-5.523,0-10,4.477-10,10s4.477,10,10,10h80.958c5.522,0,10-4.477,10-10
         S329.825,172.94,324.303,172.94z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M360.592,214.032H243.345c-5.523,0-10,4.477-10,10s4.477,10,10,10h117.247c5.522,0,10-4.477,10-10
         S366.115,214.032,360.592,214.032z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M159.67,149.529c-29.752,0-53.957,24.205-53.957,53.957c0,29.753,24.205,53.957,53.957,53.957
         c29.752,0,53.956-24.205,53.956-53.957C213.627,173.734,189.422,149.529,159.67,149.529z M159.67,237.443
         c-18.724,0-33.958-15.233-33.958-33.958c0-18.725,15.233-33.958,33.958-33.958c18.725,0,33.957,15.233,33.957,33.958
         C193.627,222.21,178.394,237.443,159.67,237.443z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M181.777,187.75c-3.905-3.905-10.237-3.904-14.142,0.001l-12.625,12.625l-6.591-2.694
         c-5.112-2.088-10.95,0.36-13.041,5.472c-2.09,5.112,0.36,10.951,5.472,13.041l12.745,5.21c1.225,0.5,2.509,0.744,3.782,0.744
         c2.603,0,5.161-1.017,7.073-2.93l17.327-17.327C185.682,197.987,185.682,191.655,181.777,187.75z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M370.398,314.259c-0.13-0.63-0.319-1.26-0.569-1.87c-0.25-0.6-0.561-1.18-0.92-1.72c-0.37-0.55-0.78-1.06-1.25-1.52
         c-0.46-0.46-0.971-0.88-1.521-1.25c-0.54-0.36-1.12-0.67-1.72-0.92c-0.61-0.25-1.23-0.44-1.87-0.57c-1.29-0.26-2.62-0.26-3.91,0
         c-0.64,0.13-1.27,0.32-1.87,0.57c-0.6,0.25-1.189,0.56-1.729,0.92c-0.55,0.37-1.061,0.79-1.521,1.25
         c-0.46,0.46-0.88,0.97-1.239,1.52c-0.36,0.54-0.671,1.12-0.921,1.72c-0.25,0.61-0.439,1.24-0.569,1.87
         c-0.13,0.65-0.2,1.31-0.2,1.96c0,0.65,0.07,1.31,0.2,1.95s0.319,1.27,0.569,1.87c0.25,0.61,0.56,1.19,0.921,1.73
         c0.359,0.55,0.779,1.06,1.239,1.52c0.46,0.46,0.971,0.88,1.521,1.24c0.54,0.36,1.13,0.67,1.729,0.92
         c0.601,0.25,1.23,0.44,1.87,0.57c0.641,0.13,1.3,0.2,1.95,0.2c0.66,0,1.31-0.07,1.96-0.2c0.64-0.13,1.26-0.32,1.87-0.57
         c0.6-0.25,1.18-0.56,1.72-0.92c0.55-0.36,1.061-0.78,1.521-1.24c0.47-0.46,0.88-0.97,1.25-1.52c0.359-0.54,0.67-1.12,0.92-1.73
         c0.25-0.6,0.439-1.23,0.569-1.87c0.131-0.64,0.19-1.3,0.19-1.95S370.528,314.909,370.398,314.259z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M324.303,306.217h-80.958c-5.523,0-10,4.477-10,10s4.477,10,10,10h80.958c5.522,0,10-4.477,10-10
         S329.825,306.217,324.303,306.217z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M360.592,347.309H243.345c-5.523,0-10,4.477-10,10s4.477,10,10,10h117.247c5.522,0,10-4.477,10-10
         S366.115,347.309,360.592,347.309z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M159.67,282.806c-29.752,0-53.957,24.205-53.957,53.958c0,29.753,24.205,53.957,53.957,53.957
         c29.752,0,53.956-24.205,53.956-53.957C213.627,307.01,189.422,282.806,159.67,282.806z M159.67,370.721
         c-18.724,0-33.958-15.233-33.958-33.958c0-18.725,15.233-33.958,33.958-33.958c18.725,0,33.957,15.233,33.957,33.958
         C193.627,355.488,178.394,370.721,159.67,370.721z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M181.777,321.028c-3.905-3.905-10.237-3.905-14.142,0.001l-12.625,12.625l-6.591-2.694
         c-5.112-2.088-10.95,0.36-13.041,5.472c-2.09,5.112,0.36,10.951,5.472,13.041l12.745,5.21c1.225,0.5,2.509,0.744,3.782,0.744
         c2.603,0,5.161-1.017,7.073-2.93l17.327-17.327C185.682,331.265,185.682,324.933,181.777,321.028z"/>
     </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>


    )
}
