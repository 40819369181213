// IdSegundoNivelContext.jsx
import React, { createContext, useContext, useState } from 'react';

// Crear el contexto
const IdSegundoNivelContext = createContext();

// Proveedor del contexto
export function IdSegundoNivelProvider({ children }) {
    const [idSegundoNivel, setIdSegundoNivel] = useState(null); // Inicializa con null o algún valor predeterminado

    return (
        <IdSegundoNivelContext.Provider value={{ idSegundoNivel, setIdSegundoNivel }}>
            {children}
        </IdSegundoNivelContext.Provider>
    );
}

// Hook personalizado para usar el contexto de idSegundoNivel
export function useIdSegundoNivel() {
    return useContext(IdSegundoNivelContext);
}
