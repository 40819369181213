import { validarEntorno } from '../helpers/validarHost'


// estado incial
const initialState = {
    data: null,
    tipoEstudio: '',
    ordenarPor: false,
    estado: '',
    personalizado: '',
    selectBusqueda: []
}

// types
const types = {
    obtenerData: '[Filtro] Data inicial',
    busquedaEstudio: '[Filtro] busqueda por filtro',
    ordenarPor: '[Filtrado] Ordenar Por',
    buscarPorCarcter: '[Filtro] buscar por caracter',
    cargaInicial: '[Tabla Filtros] carga inicial',
    restablecerData: '[Borrar Data]  Establecer de 0 el componente'
}

// reducer 
export const filtroReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.obtenerData:

            //console.log(action.payload)
            state = {
                ...state,
                data: action.payload
            }
            return state
   
        case types.cargaInicial:

            state = {
                ...state,
                selectBusqueda: action.payload
            }

            return state


        case types.restablecerData:
            state = initialState

            return state

        default:
            return state
    }

}


//actions

// filtro de tipo de estudio
export const filtroTipoEstudio = (valor) => {
    return (dispatch) => {
        dispatch({
            type: types.busquedaEstudio,
            payload: valor
        })
    }
}

/* // obtener Data 
export const obtenerData = () => {
    return (dispatch) => {
        dispatch({
            type: types.obtenerData,
            payload: data
        })
    }
} */


// ordenar por ??????
export const ordenarPor = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.ordenarPor,
            payload: data
        })
    }
}

// buscar por caracteres

export const buscarPorCarateres = (data) => {
    return (dispatch) => {


        dispatch({
            type: types.buscarPorCarcter,
            payload: data
        })

    }
}




export const opcionesBusquedaInicial = (data) => {

    return async (dispatch) => {

        const dataFinal = {
            ...data,
            hash : window.location.hash
        }

        const respuesta = await validarEntorno('JSON/selectsTablaSolicitudes_json.html', 'POST', dataFinal)
        if (respuesta.ok) {

            const response = await respuesta.json();

            const lsItem = {
                data: response,
                date: new Date(),
                hash : window.location.hash
            }

            localStorage.setItem('data-graphs', JSON.stringify(lsItem));

            dispatch({
                type: types.cargaInicial,
                payload: response
            })
        }
    }
}

export const obtenerDataSegunFiltro = (data) => {

    return async (dispatch) => {
      
        const dataFinal = {
            ...data,
            hash : window.location.hash
        }

        const respuesta = await validarEntorno('JSON/ejemploRespuestaTabla.json.html', 'POST', dataFinal)

        //ejemplo para la respuesta que vendria en el json
        if (respuesta.ok) {
            dispatch({
                type: types.obtenerData,
                payload: await respuesta.json(),
            })
        }

    }
}

export const propsPaginado = (data) => {

    return async (dispatch) => {

        const dataFinal = {
            ...data,
            hash : window.location.hash
        }
        // LOCAL
        //const respuesta = await peticionSinToken('http://127.0.0.1:5500/public/JSON/2.ejemploRespuestaTabla.json.html')

        // PRODUCCION
        const respuesta = await validarEntorno('JSON/ejemploRespuestaTabla.json.html', 'POST', dataFinal)
        //ejemplo para la respuesta que vendria en el json
        if (respuesta.ok) {
            dispatch({
                type: types.obtenerData,
                payload: await respuesta.json(),
                loader: false,
            })
        }
    }
}


export const restablecerData = () => {
    return (dispatch) => {
        dispatch({
            type: types.restablecerData
        })
    }
}