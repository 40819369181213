import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ErrorSVG } from '../../components/icons/ErrorSVG';
import { HelpSVG } from '../../components/icons/HelpSVG';
import { WarningModalSVG } from '../../components/icons/WarningModalSVG';
import GetAppIcon from '@material-ui/icons/GetApp';
import SuccessIcon from '../../helpers/SuccessIcon/SuccessIcon';
import { validarEntorno } from '../../helpers/validarHost';
import GestionInv from '../../components/inteligencia/Main';

export const GestionInvestigaciones = () => {

    let { id } = useParams();
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [pestaña, setPestaña] = useState('');
    const [item, setItem] = useState({});

    const Items = () => {
        const dataEnviar = {
            idSolicitud: id,
            hash: window.location.hash,
        }

        validarEntorno('JSON/gestionar_investigaciones_cliente.json', 'POST', dataEnviar)
            .then(res => {
                if (res.ok) {
                    res.json()
                        .then(dataRes => {
                            setData(dataRes)
                            setPestaña(dataRes.data[0].titulo);
                            setItem(dataRes.data[0])
                        })
                }
            })
    }

    useEffect(() => {
        Items()
    }, [id])

    const informacionSolicitud = data.informacion_solicitud;

    const getItemSelected = (pestana, item) => {
        setItem(item);
        setPestaña(pestana);
    }

    return(
        <>
            { data.data &&
                <>
                    <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <div className='titleBox'>
                            <div className='titleContainer'>
                                <h3 className='pagesTitles'>{data.titulo}</h3>
                                <div className='titleLine'> </div>
                            </div>
                            <div onClick={() => setOpen(true)}>
                                <HelpSVG />
                            </div>
                        </div>
                    </div>
                    {data.informacion_solicitud &&
                        <>
                            <Card className='shadow cardGeneral'>
                            <Card.Body>
                                <Row style={{display: 'flex', justifyContent:'center'}}>
                                    <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
                                        {data?.informacion_solicitud?.conclusion !== {}
                                            ?
                                            <div style={{display:'flex', height: '100%', flexDirection:'column', alignItems:'space-around'}}>
                                            <div
                                                style={{
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'space-around',
                                                    justifyContent: 'space-around'

                                                }}

                                            >
                                                {informacionSolicitud.conclusion.icono === 'exito'
                                                    ?   <div>
                                                            <SuccessIcon/>
                                                        </div>
                                                    : informacionSolicitud.conclusion.icono === 'error'
                                                        ?   <div>
                                                                <ErrorSVG/>
                                                            </div>
                                                            : informacionSolicitud.conclusion.icono === 'advertencia'
                                                                ?   <div>
                                                                        <WarningModalSVG/>
                                                                    </div>
                                                                :  informacionSolicitud.conclusion.icono === 'proceso'
                                                                    ?   <div>
                                                                            <img alt='icono de carga' style={{width:'95px'}}  src="https://www.ciacosinte.site/cia2/static/media/rombo.svg"/>
                                                                            {/* <a href="https://icons8.com/icon/TXtRzGNlGEgi/rhombus-loader">Rhombus Loader icon by Icons8</a> */}
                                                                        </div>
                                                                    :  informacionSolicitud.conclusion.icono === '' &&
                                                                        <div>
                                                                            <WarningModalSVG/>

                                                                        </div>
                                                }
                                                {informacionSolicitud.conclusion.valor !== ''
                                                    ?

                                                <h3>{informacionSolicitud.conclusion.valor}</h3>
                                                    :
                                                    <h3>Verificar estado</h3>

                                                }
                                            </div>
                                            <br/>
                                            {informacionSolicitud.generarPDF.visualizar === true &&
                                                <div className='buttonGenerarPDF'>
                                                    <a href={informacionSolicitud.generarPDF.link} rel='noreferrer' target='_blank'>
                                                        <button className='confirmButton'>
                                                            Generar PDF
                                                            <GetAppIcon/>
                                                        </button>
                                                    </a>
                                                </div>
                                            }

                                            </div>

                                            :
                                            <div>
                                                <WarningModalSVG/>
                                                <h3>Verificar estado </h3>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
                                        {informacionSolicitud.estado.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p className='labelInfo'>Estado:</p>
                                                <p className='labelInfoContent'>{informacionSolicitud.estado.valor}</p>
                                            </div>
                                        }
                                        {informacionSolicitud.fecha_solicitud.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p className='labelInfo'>{informacionSolicitud.fecha_solicitud.nombre}</p>
                                                <p className='labelInfoContent'>{informacionSolicitud.fecha_solicitud.valor}</p>
                                            </div>

                                        }
                                        {informacionSolicitud.perfil.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>{informacionSolicitud.perfil.tipo}</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.perfil.nombre}
                                                </p>
                                            </div>
                                        }
                                        {informacionSolicitud.analista.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>{informacionSolicitud.analista.nombre}</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.analista.valor}
                                                </p>
                                            </div>
                                        }
                                        <div className='infoBasicaEditarRegistro'>
                                            <p className='labelInfo'>Tipo de solicitud</p>
                                            <p className='labelInfoContent'>{data.tipoSolicitud}</p>
                                        </div>
                                        {informacionSolicitud.fecha_finalizacion.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p className='labelInfo'>Fecha de finalización</p>
                                                <p className='labelInfoContent'>{informacionSolicitud.fecha_finalizacion.valor}</p>
                                            </div>
                                        }

                                    </Col>
                                    <Col xs={6} lg={4} md={3} className='listPopUp mt-3'>
                                        {informacionSolicitud.identificador.visualizar === true
                                            ?
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>{informacionSolicitud.identificador.tipo}</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.identificador.valor}
                                                </p>
                                            </div>
                                            : null
                                        }
                                        <div className='infoBasicaEditarRegistro'>
                                            <p>ID</p>
                                            <p className='labelInfoContent'>
                                                {id}
                                            </p>
                                        </div>
                                        {informacionSolicitud.estado_cos.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>Estado-cos</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.estado_cos.valor}
                                                </p>
                                            </div>
                                        }
                                        {informacionSolicitud.fecha_cosinte.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>{informacionSolicitud.fecha_cosinte.nombre}</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.fecha_cosinte.valor}
                                                </p>
                                            </div>
                                        }
                                        {informacionSolicitud.fecha_limite.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>Fecha límite</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.fecha_limite.valor}
                                                </p>
                                            </div>
                                        }
                                        <div className='infoBasicaEditarRegistro'>
                                            <p>{informacionSolicitud.tipo_proceso.nombre}</p>
                                            <p className='labelInfoContent'>
                                                {informacionSolicitud.tipo_proceso.valor}
                                            </p>
                                        </div>
                                        {informacionSolicitud.responsable.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>{ informacionSolicitud.responsable.nombre }</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.responsable.valor}
                                                </p>
                                            </div>
                                        }
                                        {informacionSolicitud.acompanante.visualizar === true &&
                                            <div className='infoBasicaEditarRegistro'>
                                                <p>{ informacionSolicitud.acompanante.nombre }</p>
                                                <p className='labelInfoContent'>
                                                    {informacionSolicitud.acompanante.valor}
                                                </p>
                                            </div>
                                        }
                                    </Col>
                                    {/* <Col className='col-12 buttonGenerarPDF'>
                                        <a href={`https://ciacosinte.site/crm/upload/informe_investigacion_PDF.html?id=${idRegistro}`} target='_blank'>
                                            <button className='confirmButton'>
                                                Generar PDF
                                                <GetAppIcon/>
                                            </button>

                                        </a>
                                    </Col> */}
                                </Row>
                            </Card.Body>
                        </Card>
                            <br />
                            <br />
                        </>
                        }

                        <p>{data.tipoSolicitud}</p>
                        <br />

                        <div style={{ display: 'flex', textAlign: 'center' }}>

                            {
                                data.data.map((item, index) => (
                                    <div
                                        key={index}
                                        className="pestanasGestionAud"
                                        id=''
                                        style={{ background: pestaña !== item.titulo && '#CBD7E6' }}
                                        onClick={() => getItemSelected(item.titulo, item)}
                                    >
                                        <p>{item.titulo}</p>
                                        <b style={{
                                            width: '25px',
                                            fontSize: '30px',
                                            marginTop: '-20px',
                                            fontWeight: '900',
                                            color: '#00A20B'
                                        }}>.</b>
                                    </div>
                                ))
                            }
                        </div>
                        <GestionInv data={item} />
                </>
            }
        </>
    );
}
