import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '../../ui/Card';
import { ProgressBar } from 'primereact/progressbar';
import styles from './CardProgress.module.scss';

export default function CardProgress({
    title,
    value,
    description,
    progressValue,
    color
}) {
    return (
        <Card>
            <CardHeader className={styles['card-header']}>
                <CardDescription className={styles['card-tag']}>{title}</CardDescription>
                <CardTitle className={styles['card-title']}>{value}</CardTitle>
            </CardHeader>
            <CardContent className={styles['card-content']}>
                <div className={styles['text-muted']}>{description}</div>
            </CardContent>
            <CardFooter className={styles['card-footer']}>
                <div className={styles['progress-bar-container']}>
                    <ProgressBar value={progressValue} color={color} showValue={false}></ProgressBar>
                </div>
            </CardFooter>
        </Card>
    )
}