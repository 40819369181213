import { Form } from "react-bootstrap";

const LabelDate = ({ item, handleInputChange, index, idx, reg, nombre, error, handleButtonEstado }) => {

    return(
        <Form.Group style={{ display: 'block' }}>
            <p>
                <Form.Label style={{ justifyContent: 'right' }} className="tituloSelectsProcesos">{ item.titulo  }</Form.Label>
            </p>    
            <p style={{width: '100%', textAlign: 'right', color: "#90A0CE", fontSize: '22px', fontWeight: 700 }}>
                {item.valor}
            </p>
        </Form.Group>
    );

}

export default LabelDate;