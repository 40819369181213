import { useState, useEffect } from 'react';

import { Col } from "react-bootstrap";
import Input from './controles/input';
import Date from "./controles/date";
import Textarea from "./controles/textarea";
import Select from './controles/select';
import Multiple from './controles/multiple';
import Number from './controles/number';
import Dropzone from './controles/dropzone';
import Datepicker from './controles/datepicker';
import MultipleUnique from './controles/multipleUniqueValue';
import NumberOperable from './controles/numberOperable';
import AddInput from './controles/suma';
import ButtonConfirm from './controles/buttonConfirm';
import Label from './controles/label';
import Titulo from './controles/titulo';
import DropzoneFiles from './controles/dropzoneFiles';
import LabelDate from './controles/labelDate';
import DropzoneBigSize from './controles/dropzoneBigSize';
import Encuesta from './controles/encuesta';
import CalculoBase from "./controles/calculoBase";
import Mapa from './controles/mapa';
import { MultipleText } from './controles/MultipleText';
import { useLocation } from "react-router-dom";
import TablaDinamica from '../components/TablaDinamica';
import ButtonRedirect from './controles/buttonRedirect'
import RangeDatePicker from './controles/RangeDatePicker';
import ModalForm from './controles/ModalForm';
import EmailInput from './controles/EmailInput';
import Check from './controles/Check';


/**
 * Componente que carga los campos de los usuarios dependiendo
 * del tipo de campo que se haya enviado desde el backend,
 * se manejan diversos tipos de campos para diferentes necesidades
 * de los usuarios de la plataforma.
 * @param {*} params
 * @returns
 */
const InputType = ({
  item,
  index,
  idx,
  handleInputChange,
  nombre,
  reg,
  errors,
  getSumValues,
  total,
  getTotal,
  setTotal,
  handleButtonEstado,
  esEditable,
  esCargueIndividual,
  col,
  type,
  lastIndex,
  idGestion,
  esMultiple,
  inputList,
  setInputList,
  handleButtonLink,
  idSegundoNivel,
  idFormulario,
  selectedPages,
  setSelectedPages
  

}) => {

  let itemError;

  /**
   * Validamos que existan errores de validacion en los campos
   * Enviamos el error a cada campo en caso de ser necesario.
   */
  if (errors) {
    const asesoria = errors.asesoria ? errors : null;
    const itemAsesoria = asesoria ? asesoria.asesoria[index] : null;
    itemError = itemAsesoria ? itemAsesoria[item.nombre] : null;
  }

  let location = useLocation();

  // console.log(location.pathname);

  const getInputType = () => {
    switch (item.tipo) {
      case "1":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* input de texto */}
            <Input item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "2":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* input de fecha */}
            <Date item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "3":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* input numerico */}
            <Number item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "4":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            {/* textarea */}
            <Textarea item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "5":
        return (
          <Col xs={esCargueIndividual ? 12 : 6} key={`${index} - ${idx}`} >
            {/* dropzone subida de archivos (base 64) */}
            <Dropzone item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} esCargueIndividual={esCargueIndividual} selectedPages={selectedPages}/>
          </Col>
        );
      case "6":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Select de busqueda, varios items */}
            <Multiple item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "7":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Select simple */}
            <Select item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "8":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Campo de fecha con hora */}
            <Datepicker item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} type={type} />
          </Col>
        );
      case "9":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Select de busqueda, unico item */}
            <MultipleUnique item={item} esMultiple={esMultiple} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "10":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Campo numerico operable, involucrado con el campo 11 */}
            <NumberOperable item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} getSumValues={getSumValues} getTotal={getTotal} esEditable={esEditable} />
          </Col>
        );
      case "11":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Select de busqueda, varios items */}
            <AddInput item={item} handleInputChange={handleInputChange} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "12":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Boton de confirmación para cambio de un estado */}
            <ButtonConfirm item={item} nombre={nombre} index={index} idx={idx} reg={reg} handleButtonEstado={handleButtonEstado} esEditable={esEditable} />
          </Col>
        );
      case "13":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            {/* Titulo pequeño, sirve como separador de campos */}
            <Label item={item} nombre={nombre} index={index} idx={idx} />
          </Col>
        );
      case "14":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            {/* Titulo grande, sirve como separador de contenidos del formulario*/}
            <Titulo item={item} nombre={nombre} index={index} idx={idx} />
          </Col>
        );
      case "15":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            {/* Envio masivo de archivos, (base64) */}
            <DropzoneFiles item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} lastIndex={lastIndex} idGestion={idGestion} esMultiple={esMultiple} />
          </Col>
        );
      case "16":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* label de fechas */}
            <LabelDate item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} />
          </Col>
        );
      case "17":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Envio varios archivos, (base64) */}
            <DropzoneBigSize item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} esCargueIndividual={esCargueIndividual} />
          </Col>
        );
      case "18":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Formulario de encuestas */}
            <Encuesta item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} />
          </Col>
        );
      case "19":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            {/*Envio calculo base, (base64) */}
            <CalculoBase item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} />
          </Col>
        );
      case "20":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            {/* Formulario de mapa (en desuso)) */}
            <Mapa item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} />
          </Col>
        );
      case "21":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            <MultipleText item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} inputList={inputList} setInputList={setInputList} />
          </Col>
        );
      case "22":
        return (
          <Col xs={col ? col : 12} key={`${index} - ${idx}`}>
            <TablaDinamica initialState={{ data: item }} setInitialState={setInitialState} item={item} nombre={nombre} reg={reg} error={itemError} esEditable={esEditable} index={index} inputList={inputList} setInputList={setInputList} />
          </Col>
        );
      case "23":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Boton redireccion link*/}
            <ButtonRedirect item={item} nombre={nombre} index={index} idx={idx} reg={reg} handleButtonLink={handleButtonLink} esEditable={esEditable} />
          </Col>
        );
      case "24":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Selector de fecha en rango */}
            < RangeDatePicker item={item} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} />
          </Col>
        );
      case "25":
        return (
          <>
            {(idGestion || !esMultiple) &&
              <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
                {/* Selector de fecha en rango */}
                <ModalForm item={item} nombre={nombre} index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable} idSegundoNivel={idSegundoNivel} idTercerNivel={esMultiple? idGestion : item.id_tercer_nivel} />
              </Col>
            }
          </>
        );
      case "26":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* input de correo */}
            <EmailInput item={item}index={index} idx={idx} reg={reg} error={itemError} esEditable={esEditable}/>
          </Col>
        );
      case "27":
        return (
          <Col xs={col ? col : 6} key={`${index} - ${idx}`}>
            {/* Checkbox */}
            <Check
              item={item}
              reg={reg}
              index={index}
              idx={idx}
              error={itemError}
              esEditable={esEditable}
            /> 
          </Col>
        );
      default:
        break;
    }
  }

  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false,
  });


  return (
    <>
      {getInputType()}
    </>
  )
}

export default InputType;
