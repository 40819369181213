import { React, useState, useEffect } from 'react'
import reactDOM from 'react-dom'
// import {Link} from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close';
// import SuccesIcon from '../../../helpers/SuccessIcon/SuccessIcon'
import SuccessIcon from '../../helpers/SuccessIcon/SuccessIcon'
import { ErrorSVG } from '../icons/ErrorSVG'
import { WarningModalSVG } from '../icons/WarningModalSVG'
// import Video from '../../static/Video.mp4'

import './NotificationModal.scss'
import { Link, useHistory } from 'react-router-dom';
import { Image, Form } from 'react-bootstrap';
import Loader from '../../components/icons/Loader'
import { useTranslation } from 'react-i18next';
import LoaderButton from '../icons/LoaderButton';

export default function GenericModal(props) {

  // const [bandera, setBandera] = useState(false)

  // useEffect(() => {
  //   console.log(props.banderaC, 'props')
  // }, [])


  // console.log(props.media)
  const { t } = useTranslation();

  const [newItem, setNewItem] = useState('')

  let history = useHistory();

  const handleChangeNewItem = ({ target }) => {
    setNewItem({
      newItem,
      [target.name]: target.value
    })
  }

  const handleClick = () => {
    history.push("/home");
  }

  useEffect(() => {

  }, [props])


  // const cambiarAPanel = () => {
  //   props.setIsOpen(false)
  //   // props.closeNews(() => setCarga({...stateContainer, media: false }))
  // }

  const h = window.location.hash

  // console.log(h)

  const variableRedirect = h === "#/gerencia/inteligencia/solicitar" ? 'inteligencia' : '#/gerencia/investigaciones-administrativas/solicitar' ? 'investigaciones-administrativas' : '#/gerencia/auditorias/solicitar' ? 'auditorias' : '#/gerencia/operaciones/solicitar' ? 'operaciones' : ''

  // switch (h) {
  //   case '#/gerencia/inteligencia/solicitar':
  //     variableRedirect = 'inteligencia'
  //     break;

  //   default:
  //     break;
  // }

  if (!props.isOpen) {
    return null;
  };

  const defaultMessage = (tipo) => {

    switch (tipo) {
      case ('ok' || 'OK'):

        return 'Solicitud exitosa';
      case ('warning' || 'WARNING'):

        return 'Atención!';
      case ('error' || 'ERROR'):

        return 'Solicitud rechazada';
      default:

        return '';
    }

  }



  const printInfo = (props) => {
    switch (props.typeModal) {

      case 'notificacion':
        return (
          <>
            <div className='closeButtonContainer'>
              <CloseIcon onClick={() => { props.setIsOpen({ ...props.modal, show: false }) }} />
            </div>
            {(props.tipo === 'ok' || props.tipo === 'OK') && <SuccessIcon />}
            {(props.tipo === 'warning' || props.tipo === 'WARNING') && <WarningModalSVG />}
            {(props.tipo === 'error' || props.tipo === 'ERROR') && <ErrorSVG />}
            <h3 className='pagesTitles'>{props.modal.titulo ? props.modal.titulo : defaultMessage(props.tipo)}</h3>
            {props.modal.mensaje && <p style={{ fontSize: '18px' }}>{props.modal.mensaje}</p>}
            <button className='confirmButton' onClick={() => { props.setIsOpen({ ...props.modal, show: false }); props.onClose?.() }} >Aceptar</button>

          </>
        )

      case 'obsUpdated':
        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>{t('modales.observacion_actualizada')}</h1>
            <p>{props.mensaje}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>

          </>
        )

      case 'edicionRechazada':
        return (
          <>
            <ErrorSVG />
            <h2 className='pagesTitles'>Error</h2>
            <p>{props.mensaje}</p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>

          </>
        )

      case 'multimedia':
        return (
          <>
            {props.media.tipo === 'video'
              ?
              <>
                <video
                  // src={Video}
                  // src='https://res.cloudinary.com/da6bzgewk/video/upload/v1620697541/Video_ikf8gs.mp4'
                  // src='https://www.ciacosinte.site/cia2/static/media/video_cosinte_cumpleanos.mp4'
                  src={props.media.contenido}
                  type='video/mp4'
                  controls
                  autoPlay={true}
                  muted

                  style={{ height: 'auto', width: '100%' }}
                >
                </video>
              </>
              : props.media.tipo === 'imagen' &&
              <div

                //si props.media.linkMedia es true, al hacer click en la imagen se redirige a la url de la imagen
                onClick={() => { props?.media?.linkMedia && window.open(props?.media?.linkMedia, '_blank') }}
                  
                style={{
                  backgroundImage: `url("${props.media.contenido}")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  minHeight: '500px',
                  minWidth: '520px',
                  //si props.media.linkMedia es true, se muestra el link de la imagen
                  cursor: props?.media?.linkMedia ? 'pointer' : 'default',
                  //Si en las propiedades del modal se envia un color de fondo, se asigna, de lo contrario se asigna un color por defecto
                  backgroundColor: props?.media?.colorFondo ? props?.media?.colorFondo : '#fff',
                }}
              >
                {/* <image
                  src={props.media.contenido}
                  style={{
                    height: '500px',
                    width: '100%',
                    borderRadius: '30px' ,
                    zIndex: 4
                  }}
                >

                </image> */}

              </div>
            }
          </>
        )

      case 'investigaciones_administrativas':
        return (
          <>
            <video
              // src={Video}
              // src='https://www.ciacosinte.site/cia2/static/media/video_inv_adm.mp4'
              src='https://www.ciacosinte.site/cia2/static/media/video_cosinte_cumpleanos.mp4'

              type='video/mp4'
              controls
              autoPlay={true}
              muted

              style={{ height: 'auto', width: '50vw', borderRadius: '30px' }}
            >
            </video>
            {/* <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button> */}

          </>
        )


      case 'edicionDataRealizada':
        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>{t('modales.archivo_editado')}</h1>
            <p>{props.mensaje}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        );


      case 'ErrorFile':
        return (
          <>
            <ErrorSVG />
            <h1 className='pagesTitles'>{t('modales.error')}</h1>
            <p>{props.mensaje}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>

          </>
        )

      case 'SuccessDeletefile':
        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>{t('modales.archivo_eliminado')}</h1>
            <p>{t('modales.archivo_eliminado_exitosamente')}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        );


      case 'SuccessFile':
        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>{t('modales.carga_exitosa')} </h1>
            <p>{t('modales.archivo_cargado_exitosamente')}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>

          </>
        );



      case 'solicitudSinCrear':
        return (
          <>
            <ErrorSVG />
            <h1 className='pagesTitles'>Genere la solicitud </h1>
            <p>Diríjase a la primera pestaña y genere su solicitud</p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>

          </>
        )
      case 'Verificacioneliminarsolicitud':
        return (
          <>
            <WarningModalSVG />
            <h3 className='pagesTitles'>Desea cancelar la solicitud?</h3>
            <p>Presione el botón de confirmar para cancelar la solicitud. De lo contrario, cierre la ventana. </p>
            <button onClick={() => {
              props.setDetalle(true)
              if (props.detalle) {

                props.funciones()
              }

            }} >Confirmar</button>

          </>
        )

      case 'ConfirmacionEliminarSolicitud':
        return (
          <>

            {
              props.detalle.ok
                ?
                <SuccessIcon />
                :
                <ErrorSVG />
            }
            <h1 className='pagesTitles'>Exitosa </h1>
            <p>{props.detalle.mensaje ? props.detalle.mensaje : 'El registro seleccionado fue eliminado satisfactoriamente'}</p>
            <button onClick={() => {
              props.setIsOpen(false)
            }} >Aceptar</button>

          </>
        )
      case 'loginUserError':
        return (
          <>
            {props?.tipoMensaje === 'nr' ? <WarningModalSVG /> : <ErrorSVG />}
            <h3 className='pagesTitles'>
              {props?.tipoMensaje === 'nr' ? props?.mensaje : 'Error en el inicio de sesión'}
            </h3>
            {props?.tipoMensaje !== 'nr' &&
              <p>{props.mensaje}</p>
            }
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
          </>
        )
      case 'successAlt':
        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>{t('modales.solicitud_completa')}</h1>
            <p>{props.mensaje ? props.mensaje : 'Diríjase a la opción de administrar y escriba el radicado en el filtro de busqueda. Desde allí podrá gestionar la solicitud.'}</p>
            <button className='confirmButton' onClick={() => { props.setIsOpen({ ...props.modal, show: false }) }} >{t('botones.aceptar')}</button>

          </>
        )
      case 'success':
        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>Solicitud completa</h1>
            <p>A continuación, diríjase a la opción de administrar y escriba el radicado en el filtro de busqueda. Desde allí podrá gestionar la solicitud.</p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>

          </>
        )
      case 'pqrSuccess':
        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>Solicitud completa</h1>
            <p>Gracias por tu solicitud. En los próximos 3 días hábiles te enviaremos la información relacionada al seguimiento de tu solicitud.  </p>
            <button onClick={() => { props.redireccion() }} >Aceptar</button>
          </>
        )
      case 'error':
        return (
          <>
            <ErrorSVG />
            <h3 className='pagesTitles'>Solicitud rechazada</h3>
            <p>Verifique la información ingresada y vuelva a intentarlo</p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>

          </>
        )
      case 'passwordChangedSuccess':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.contrasena_actualizada')}</h3>
            <p>{props.detalle}</p>
            <button onClick={() => { props.setIsOpen(false); if (props?.onClose) { props.onClose() } }} >{t('botones.aceptar')}</button>

          </>
        )
      case 'passwordChangedError':
        return (
          <>
            <ErrorSVG />
            <h3 className='pagesTitles'>{t('modales.error_actualizacion_contrasena')}</h3>
            <br />
            <p>{props.detalle}</p>
            <button onClick={() => { props.setIsOpen(false); if (props?.onClose) { props.onClose() } }} >{t('botones.aceptar')}</button>

          </>
        )
      case 'passwordChangeNewPasswordNotMatch':
        return (
          <>
            <WarningModalSVG />
            <h3 className='pagesTitles'>{t('modales.error_actualizacion_contrasena')}</h3>
            <br />
            <p>{t('modales.no_coincide_contrasena')}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'rellenarCampos':
        return (
          <>
            <WarningModalSVG />
            <h3 className='pagesTitles'>Campos sin llenar</h3>
            <br />
            <p>Los campos que se demarcan con el simbolo (*) son obligatorios. Verifique los datos ingresados e intente nuevamente.</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'passwordChangeCurrentlyPasswordNotMatch':
        return (
          <>
            <WarningModalSVG />
            <h3 className='pagesTitles'>{t('modales.error_actualizacion_contrasena')}</h3>
            <br />
            <p>La contraseña actual no coincide. Verifique los datos ingresados e intente nuevamente.</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'deleteFileConfirm':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>Esta seguro de eliminar este archivo?</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={props.handleEliminar()}>Eliminar</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )
      case 'eliminarArchivoGestionar':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{t('modales.seguro_eliminar_archivo')}</h4>
            <div className='buttonModalContainer'>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
              <button className='deniedButton' onClick={() => { props.accionEliminar(props.itemAEliminar) }}>{t('botones.eliminar')}</button>
            </div>
          </>
        )
      case 'addBeneficiarioConfirm':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{t('modales.beneficiario_anadido')}</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen() }} >{t('botones.cerrar')}</button>
            </div>
          </>
        )
      case 'deleteBeneficiarioConfirm':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{t('modales.seguro_eliminar_beneficiario')}</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={props.handleEliminar}>{t('botones.eliminar')}</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'notificacionBeneficiarioEliminado':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{t('modales.beneficiario_eliminado')}</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen() }}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'notificacionBeneficiarioEditado':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{t('modales.beneficiario_editado')}</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'deleteSeguimientoConfirm':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>Esta seguro de anular este seguimiento?</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={props.handleEliminar}>Anular</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )
      case 'deleteEntrevistaConfirm':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>Esta seguro de eliminar esta entrevista?</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={props.handleEliminar}>Eliminar</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )
      case 'actualizacionInfoGeneral':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>Cambios guardados correctamente</h4>
            <div className='buttonModalContainer'>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cerrar</button>
            </div>
          </>
        )
      case 'seleccionarFechas':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{t('modales.seleccione_rango_fechas_informe')}</h4>
            <div className='buttonModalContainer'>
              <button className='' onClick={() => { props.setIsOpen(false) }}>{t('botones.cerrar')}</button>
            </div>
          </>
        )
      case 'crearSolicitud':

        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>{t('modales.solicitud_completa')}</h1>
            <p>{t('modales.haz_clic_gestionar_solicitud')}</p>
            {

              props.codigoSuccess
                ? <Link to={`/gerencia/${variableRedirect}/solicitar/creada/${props.codigoSuccess}`}>
                  {/* ? <Link to={`/gerencia/${variableRedirect}/solicitar`}> */}
                  <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
                </Link>
                :
                <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            }
          </>
        )

      case 'sol_panorama_ok':

        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>Solicitud completa</h1>
            <p>{props.mensaje ? props.mensaje : 'Haz clic en aceptar para gestionar la solicitud'}</p>
            {
              props.codigoSuccess !== ''
                ?
                <Link to={`/gerencia/auditorias/panorama_riesgos/${props.codigoSuccess}/gestionar`}>
                  <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
                </Link>
                :
                <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>

            }
          </>
        )

      case 'sol_inf_panorama_ok':

        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>Solicitud completa</h1>
            <p>{props.mensaje ? props.mensaje : 'Haz clic en aceptar para gestionar la solicitud'}</p>
            {
              props.codigoSuccess !== ''
                ?
                <Link to={`/gerencia/auditorias/panorama_riesgos/informe_${props.codigoSuccess}`}>
                  <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
                </Link>
                :
                <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>

            }
          </>
        )

      case 'crearSolicitudInvEspecificas':

        return (
          <>
            <SuccessIcon />
            <h1 className='pagesTitles'>{t('modales.solicitud_iniciada')}</h1>
            <p>{t('modales.haz_clic_gestionar_solicitud')}</p>
            {

              props.codigoSuccess
                ? <Link to={`/gerencia/${variableRedirect}/solicitar/creada/${props.codigoSuccess}`}>
                  {/* ? <Link to={`/gerencia/${variableRedirect}/solicitar`}> */}
                  <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
                </Link>
                :
                <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            }
          </>
        )

      case 'aprobarSolicitud':

        return (
          <>
            <SuccessIcon />

            <h3 className='pagesTitles'>Solicitud completa</h3>

            <p>Haz clic en aceptar para gestionar la solicitud</p>

            <button className='confirmButton' onClick={() => window.location.reload()} >Aceptar</button>
          </>
        )

      case 'editarSolicitud':

        return (
          <>
            <SuccessIcon />
            <h2 className='pagesTitles'>{t('modales.solicitud_completa')}</h2>
            <p>{props.mensaje}</p>

            <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'errorFormSolicitud':
        return (
          <>
            <div className='closeButtonContainer'>
              <CloseIcon onClick={() => { props.setIsOpen({ ...props.modal, show: false }) }} />
            </div>
            <ErrorSVG />
            <h3 className='pagesTitles'>{t('modales.solicitud_rechazada')}</h3>
            <p>{props?.mensaje !== '' ? props.mensaje : 'Verifique la información ingresada y vuelva a intentarlo.'}</p>
            <button className='confirmButton' onClick={() => { props.setIsOpen({ ...props.modal, show: false }) }} >{t('botones.aceptar')}</button>

          </>
        )
      case 'informeGenerado':
        return (
          <>
            <SuccessIcon />

            <h3 className='pagesTitles'>{t('modales.informe_generado')}</h3>
            <p>{t('modales.mensaje_informe_generado')}</p>

            <Link to={props.perfil === 'presidencia_op' ? '/presidencia/reportes' : `/gerencia/${props.perfil}/reportes/${props.idGrafica}`}>
              <button className='buttonSuccess' >{t('botones.continuar')}</button>

            </Link>

          </>
        )
      case 'saveQuestions':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.solicitud_completa')}</h3>
            <p>{t('modales.enviado_cuestionario')}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'saveQuestionsFail':
        return (
          <>
            <ErrorSVG />
            <h3 className='pagesTitles'>Solicitud rechazada</h3>
            <p>Verifique la información ingresada y vuelva a intentarlo</p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
          </>
        )
      case 'saveDataModal':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>Solicitud completa</h3>
            <p>Se ha enviado la observación</p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
          </>
        )
      case 'deleteObservation':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.solicitud_completa')}</h3>
            <p>{t('modales.eliminado_observacion')}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )

      case 'registroAuditoriaCreado':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>Solicitud completa</h3>
            {props.identificador &&
              <p>
                Su solicitud se ha creado con el identificador <b>{props.identificador}</b>. Pulse en aceptar para gestionar la solicitud creada.
              </p>
            }
            <Link to={
              props.seccion
                ? `/gerencia/${props.seccion}/gestor/creada/${props.identificador}`
                : `/gerencia/auditorias/gestor/creada/aud_2-${props.identificador}`}
            >
              <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
            </Link>
          </>
        )
      case 'success_din':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>Solicitud completa</h3>
            {props.mensaje && <p> {props.mensaje} </p>}
            {props.redireccion !== ''
              ?
              <Link to={props.redireccion}
              >
                <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
              </Link>
              :
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
            }
            {/* <Link to={
                props.seccion
                ? `/gerencia/${props.seccion}/gestor/creada/${props.identificador}`
                : `/gerencia/auditorias/solicitar/creada/${props.identificador}`}
              >
                <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
              </Link> */}
          </>
        )
      case 'informacion':
        return (

          <>
            <WarningModalSVG />
            <h3 className='pagesTitles'>Información</h3>
            <div style={{ marginTop: '3%', overflowY: 'auto', marginBottom: '4%', width: '90%' }}>
              {props.mensaje ? props.mensaje.map((e) => (
                <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', marginBottom: '2%', alignItems: 'flex-start', textAlign: 'start', marginLeft: '2%' }}>
                  <p style={{ fontWeight: 'bolder' }}>{e.titulo}</p>
                  <p> {e.descripcion}</p>
                </div>
              )) : null}
            </div>

            {/* {console.log(props.mensaje) && props.mensaje ? <p>{props.mensaje.titulo}</p> && <p> {props.mensaje.descripcion} </p> : 'null'} */}
            {props.redireccion !== ''
              ?
              <Link to={props.redireccion}
              >
                <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
              </Link>
              :
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
            }
            {/* <Link to={
                props.seccion
                ? `/gerencia/${props.seccion}/gestor/creada/${props.identificador}`
                : `/gerencia/auditorias/solicitar/creada/${props.identificador}`}
              >
                <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
              </Link> */}
          </>
        )
      case 'registroInv':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>Solicitud completa</h3>
            {props.identificador &&
              <p>
                Su solicitud se ha creado con el identificador <b>{props.identificador}</b>. Pulse en aceptar para gestionar la solicitud creada.
              </p>
            }
            <Link to={`/gerencia/investigacion/gestor/creada/${props.identificador}`}>
              <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
            </Link>
          </>
        )
      case 'registroSolVis':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>Solicitud completa</h3>
            {props.identificador &&
              <p>
                Su solicitud se ha creado con el identificador <b>{props.identificador}</b>. Pulse en aceptar para gestionar la solicitud creada.
              </p>
            }
            <Link to={`/gerencia/investigacion/gestor/creada/${props.identificador}`}>
              <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
            </Link>
          </>
        )
      case 'registroAuditoriaError':
        return (
          <>
            <ErrorSVG />
            <h3 className='pagesTitles'>Solicitud rechazada </h3>
            <p>{props.infoNotificacion}</p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
          </>
        )
      case 'registroOperacionesCreado':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>Solicitud completa</h3>
            {props.identificador &&
              <p>
                Su solicitud se ha creado con el identificador <b>{props.identificador}</b>. Pulse en aceptar para gestionar la solicitud creada.
              </p>
            }
            <Link to={`/gerencia/auditorias/gestor/creada/aud_2-${props.identificador}`}>
              <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
            </Link>
          </>
        )
      case 'dofaActualizado':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.solicitud_completa')}</h3>
            <p>
              {t('modales.registro_actualizado')}.
            </p>
            <div style={{ display: 'flex' }}>
              {/* <Link to={`/gerencia/auditorias/administrar`}>
                  <button className='' onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
                </Link> */}
              <button className='' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>

            </div>

          </>
        )
      case 'errorActualizacionDofa':
        return (
          <>
            <ErrorSVG />
            <h3 className='pagesTitles'>Error en la solicitud</h3>
            <p>
              Ocurrió un error. Actualiza tu navegador y vuelve a intentarlo.
            </p>
            <button onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
          </>
        )
      case 'agregarItemDofa':
        return (
          <>
            <h3 className='pagesTitles'>{t('modales.agregar')} {props.tipoItem}</h3>
            <Form.Control as='textarea' onChange={(e) => handleChangeNewItem(e)} name='item' type='text' className='shadow' placeholder={t('formulario.label.nuevo_item')}>

            </Form.Control>
            <div style={{ display: 'flex' }}>
              <button className='confirmButton' onClick={() => { props.sendItem(newItem) }} >{t('botones.anadir')}</button>
              <button className='warningButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.cancelar')}</button>
            </div>
          </>
        )

      case 'updateRisks':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.solicitud_completa')}</h3>
            <p>{t('modales.actualizado_riesgo')}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'tituloActualizado':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{t('modales.titulo_actualizado')}</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'objAgregado':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.objetivo_anadido')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'objEliminado':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.objetivo_eliminado')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'objActualizado':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.objetivo_actualizado')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'metaAgregada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.meta_anadida')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'tareaAgregada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.tarea_anadida')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'metaActualizada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.meta_actualizada')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'metaEliminada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.meta_eliminada')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'actividadAgregada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.actividad_anadida')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'actividadActualizada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.actividad_actualizada')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'actividadEliminada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.actividad_eliminada')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'accionAgregada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.accion_anadida')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'accionActualizada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.accion_actualizada')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >Aceptar</button>
            </div>
          </>
        )
      case 'accionEliminada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.accion_eliminada')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'tareaEliminada':
        return (
          <>
            <SuccessIcon />
            <h3 className='pagesTitles'>{t('modales.tarea_eliminada')}</h3>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false); props.setIsOpenTarea(false) }} >{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'deleteItemConfirm':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{t('modales.seguro_eliminar')} {props.itemName}</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={() => { props.handleEliminar(props.indexDelete) }}>{t('botones.eliminar')}</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'notificacionItemEliminado':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{props.itemName} {t('modales.eliminado_correctamente')}.</h4>

            <div className='buttonModalContainer'>


              <button className='confirmButton' onClick={() => { props.setIsOpen() }}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'notificacionItemActualizacion':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{props.itemName} {t('modales.actualizada_correctamente')}.</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen() }}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'panoramaCreado':
        return (
          <>
            <SuccessIcon />
            {props.message ? (<h4 className='pagesTitles'>{props.message}</h4>) :
              (props.mensaje ? <h4 className='pagesTitles'>{props.mensaje}</h4> : null)
            }
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen() }}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'panoramaEliminadoConfirmacion':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{t('modales.seguro_eliminar_este_a')}</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={() => props.handleEliminar(props.indexDelete)}>{t('botones.eliminar')}</button>
              <button className='cancelButton' onClick={() => props.setIsOpen(false)}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'panoramaEliminado':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{t('modales.eliminado_a_correctamente')}.</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.setIsOpen(false)}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'panoramaActualizado':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{t('modales.actualizado_a_correctamente')}.</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.setIsOpen(false)}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'panoramasCreados':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>{t('modales.elemento_creado_correctamente')}</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.aceptar')}</button>
            </div>
          </>
        )
      case 'error_panorama':
        return (
          <>
            <ErrorSVG />
            <h2 className='pagesTitles'>{t('modales.error')}</h2>
            <p>{props.mensaje ? props.mensaje : props.message}</p>
            <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'successGestion':
        return (
          <>
            <SuccessIcon />
            {/* <h1 className='pagesTitles'>Gestión exitosa</h1> */}
            <p style={{ fontSize: '18px' }}>{props.mensaje}</p>
            <button className='confirmButton' onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'gestionEliminadoConfirmacion':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>Esta seguro de eliminar esta gestion?</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={() => { props.handleEliminar(props.index, props.item) }}>Eliminar</button>
              <button onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )
      case 'falloPeticionesGestion':
        return (
          <>
            <ErrorSVG />
            <h1 className='pagesTitles'>{t('modales.gestion_fallida')}</h1>
            <p>{props.mensaje ? props.mensaje : props.message}</p>
            <button onClick={() => { props.setIsOpen(false) }} >{t('botones.aceptar')}</button>
          </>
        )
      case 'gestionEliminadoConfirmacion':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>Esta seguro de eliminar esta gestion?</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={() => { props.handleEliminar(props.index, props.item) }}>Eliminar</button>
              <button onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )

      case 'confirmacion_eliminacion_ra':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{props.message}</h4>
            <div className=''
              style={{
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <button className='botonEliminar' onClick={() => { props.handleEliminar(props.indexDelete) }}>{t('botones.eliminar')}</button>
              <button className='botonEditar' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'confirmacion_eliminacion_coords':
        return (
          <>
            <WarningModalSVG />
            {props.message ? (<h4 className='pagesTitles'>{props.message}</h4>) :
              (props.mensaje ? <h4 className='pagesTitles'>{props.mensaje}</h4> : null)
            }
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={() => { props.handleEliminar(props.indexItem) }}>Eliminar</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )
      case 'confirmacion_edicion_coords':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{props.message}</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.handleEditar(props.indexItem) }}>Actualizar</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )
      case 'eliminacion_confirmada':
        return (
          <>
            <SuccessIcon />
            {props.message ? (<h2 className='pagesTitles'>{props.message}</h2>) :
              (props.mensaje ? <h2 className='pagesTitles'>{props.mensaje}</h2> : null)
            }
            <button
              style={{ marginTop: '20px' }}
              className='confirmButton'
              onClick={() => { props.setIsOpen() }} >{t('botones.aceptar')}</button>
          </>
        );
      case 'confirmacion_eliminacion_evidencia':
        return (
          <>
            <WarningModalSVG />
            {props.message ? (<h4 className='pagesTitles'>{props.message}</h4>) :
              (props.mensaje ? <h4 className='pagesTitles'>{props.mensaje}</h4> : null)
            }
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={(e) => { props.handleEliminar(e, props.indexDetail, 'total_cuestionario') }}>{t('botones.eliminar')}</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'deleteImagen':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{t('modales.esta_seguro_eliminar_imagen')}</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={props.deleteImage}>{t('botones.eliminar')}</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'deleteImagenDetail':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{t('modales.esta_seguro_eliminar_imagen')}</h4>
            <div className='buttonModalContainer'>
              <button className='deniedButton' onClick={() => { props.deleteDescriptionImage(props.item, props.index, props.tipo); props.items() }}>{t('botones.eliminar')}</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'showImage':
        return (
          <>
            <h4 className='pagesTitles'>Imagen</h4>
            <Image src={props.img} fluid />
            <div className='buttonModalContainer'>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cerrar</button>
            </div>
          </>
        )
      case 'edicion_estado_financiero':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>¿Esta seguro de actualizar el estado de esta gestión?</h4>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.handleButtonEstado(props.indexFinancialEdit, props.subIndexSubFinancialEdit) }}>Actualizar</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )
      case 'cambio_estado_gestion':
        return (
          <>
            <WarningModalSVG />
            <h5 className='pagesTitles'>{props.message}</h5>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.handleEstadoSolicitud() }} disabled={props?.isLoading}>
                {props?.isLoading ? <LoaderButton text={"Guardando"}></LoaderButton> : "Actualizar"}
              </button>
              <button className='dangerButton' onClick={() => { props.setIsOpen() }}>Cancelar</button>
            </div>
          </>
        )
      case 'envio_peticion_correcto':
        return (
          <>
            <SuccessIcon />
            <h4 className='pagesTitles'>Petición correcta</h4>
            <p>{props.mensaje}</p>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen() }}>Cerrar</button>
            </div>
          </>
        )
      case 'envio_peticion_erroneo':
        return (
          <>
            <ErrorSVG />
            <h4 className='pagesTitles'>Petición fallida</h4>
            <p>{props.mensaje}</p>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen() }}>Cerrar</button>
            </div>
          </>
        )
      case 'envio_peticion_alerta':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>Vuelve a validar</h4>
            <p>{props.mensaje}</p>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpen() }}>Cerrar</button>
            </div>
          </>
        )
      case 'envio_peticion_alerta_warning':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>Vuelve a validar</h4>
            <p>{props.mensaje}</p>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.setIsOpenWarning() }}>Cerrar</button>
            </div>
          </>
        )
      case 'alerta_permisos':
        return (
          <>
            <ErrorSVG />
            <h4 className='pagesTitles'>Error</h4>
            <p>{props.message}</p>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { handleClick() }}>Aceptar</button>
            </div>
          </>
        )
      case 'contenedor':
        return (
          <>
            {props.Contenido}
          </>
        )
      case 'confirmacion':
        return (
          <>
            <WarningModalSVG />
            <h4 className='pagesTitles'>{props?.modal?.titulo}</h4>
            <p>{props?.modal?.mensaje}</p>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => { props.handleConfirm?.() }}>Aceptar</button>
              <button className='cancelButton' onClick={() => { props.setIsOpen(false); props.onClose?.() }}>Cancelar</button>
            </div>
          </>
        )
      default:
        return null;

    }
  }

  if (props.typeModal === 'inteligencia' || props.typeModal === 'investigaciones_administrativas') {
    return reactDOM.createPortal(
      <div className='modalNewsContainer'>
        <div className='modalNewsCard'>
          <div className='closeButtonNewsContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          {printInfo(props)}
        </div>
      </div>,
      document.getElementById('NotificationModal')
    )
  } else if (props.typeModal === 'multimedia') {
    return reactDOM.createPortal(
      <div className='modalNewsContainer' onClick={() => { props.setIsOpen(false) }}>
        <div className='modalNewsCard'  >
          <div className='closeButtonNewsContainer'>
            <CloseIcon style={{ fontSize: '14px' }} onClick={() => { props.setIsOpen(false) }} />
          </div>
          {printInfo(props)}
        </div>
      </div>,
      document.getElementById('NotificationModal')
    )
  } else if (props.typeModal === 'loader') {
    return reactDOM.createPortal(
      <div className='modalNewsContainer' style={{ color: 'white' }}>
        <Loader color={'white'} />
      </div>,
      document.getElementById('NotificationModal')
    )
  }
  else if (props.typeModal === 'contenedor') {
    return reactDOM.createPortal(
      <div className='modalContainer'>
        <div className='modalCard' style={{ width: '80%', maxWidth: '1320px', height: 'auto', maxHeight: '80%' }}>
          <div className='closeButtonContainer modern__close'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <div style={{ overflowY: 'auto', height: '100%', marginTop: '2rem', width: "100%" }}>
            {printInfo(props)}
          </div>
        </div>
      </div>,
      document.getElementById('NotificationModal')
    )
  }
  else {
    return reactDOM.createPortal(
      <div className='modalContainer'>
        <div className='modalCard' style={{ width: '530px', height: '550px' }}>
          {/* <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div> */}
          {printInfo(props)}
        </div>
      </div>,
      document.getElementById('NotificationModal'),
    )

  }

}
