import React, { useState } from 'react'

import { Form, Card } from 'react-bootstrap';

import { validarEntorno } from '../../../helpers/validarHost';
import NotificationModal from '../../modales/NotificationModal'

import './styles/SolicitudRegistroAuditorias.scss'

import { useTranslation } from 'react-i18next';

export default function SolicitudRegistroAuditorias(props) {

  //console.log(props.selectSeleccionado)

  const { t } = useTranslation();

  const [tipoModal, setTipoModal] = useState({
    tipo: '',
    identificador: '',
    mensaje: '',
    seccion: ''
  });
  const [modalConfirmacionSolicitud, setModalConfirmacionSolicitud] = useState(false);
  const [validarEmpresa, setValidarEmpresa] = useState(true);
  const [validated, setValidated] = useState(false);
  const [formulario, setFormulario] = useState({
    fecha: '',
    id_empresa: '',
    descripcion: '',
    tipo_proceso: '',
    funcionario2: '',
    funcionario1: '',
    id_select: props.selectSeleccionado ? props.selectSeleccionado : ''

  });
  const [isDisabled, setIsDisabled] = useState(false)


  const { empresas } = props.contenido

  const handleDataFormulario = ({ target }) => {
    setFormulario({
      ...formulario,
      [target.name]: target.value
    })

    // //console.log(formulario)
  }

  const enviarFormulario = async (e) => {

    e.preventDefault()

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      if (formulario.id_empresa === '') {
        setValidarEmpresa(false)
      }

    }
    setValidated(true)


    if (form.checkValidity() === true) {

      setValidarEmpresa(false)
      setIsDisabled(true)

      const dataReq = {
        hash: window.location.hash,
        formulario

      }

      //console.log(dataReq)

      const req = await validarEntorno('JSON/solicitud_registro_auditoria.html', 'P0ST', dataReq)

      //console.log(req)

      if (req.ok) {
        const res = await req.json()
        // registroAuditoriaCreado
        if (res.estado === 'ok') {
          setTipoModal({
            ...tipoModal,
            tipo: 'registroAuditoriaCreado',
            identificador: res.idSolicitud,
            seccion: res.seccion
          })
          setModalConfirmacionSolicitud(true)

        } else if (res.estado === 'false') {
          setTipoModal({
            ...tipoModal,
            tipo: 'registroAuditoriaError',
            mensaje: res.mensaje
          })
          setModalConfirmacionSolicitud(true)
        }
        setIsDisabled(false)
      } else {
        setTipoModal({
          ...tipoModal,
          tipo: 'errorActualizacionDofa'
        })
        setIsDisabled(false)
      }

    }





  }


  return (
    <Card
      className='shadow cardContainerSolicitud'
      style={{ borderRadius: '0px 10px 10px 10px' }}
    >
      <h5 className='titleCard'>{t('formulario.label.informacion_solicitud')}</h5>

      <Card.Body>
        <Form className='formEstudioBasicoContainer' noValidate validated={validated} onSubmit={enviarFormulario}>
          <Form.Group style={{ width: '95%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Form.Label style={{ marginLeft: '0px' }}>
              {t('formulario.label.fecha')}
            </Form.Label>
            <div className='inputContainer'>
              <Form.Control
                onChange={(e) => handleDataFormulario(e)}
                name='fecha' required type='date' lang='en'></Form.Control>
              <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_fecha')} </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Form.Label style={{ marginLeft: '0px' }}>
              {t('formulario.label.empresa')}
            </Form.Label>
            <div className='inputContainer'>
              <Form.Control
                onChange={(e) => handleDataFormulario(e)}
                name='id_empresa' required as='select'
                isInvalid={validarEmpresa}
                isValid={!validarEmpresa}
              >
                <option value='' selected disabled>{t('formulario.label.seleccione')}</option>
                {empresas.map((opcion) => {
                  return (
                    <>
                      <option key={opcion.id} value={opcion.id}>{opcion.nombre}</option>
                    </>
                  )
                })}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>{t('formulario.error.seleccione_empresa')} </Form.Control.Feedback>
            </div>
          </Form.Group>

          <div id='observacionesContainerEstudioBasico'>
            <Form.Group id='observacionesFormGroup'>
              <Form.Label className='observacionesLabel'>
                {t('formulario.label.descripcion')}
              </Form.Label>
              <div className='textAreaContainer'>
                <Form.Control
                  onChange={(e) => handleDataFormulario(e)}
                  name='descripcion' id='observacionesTextArea' required as='textarea' placeholder={t('formulario.label.descripcion')}></Form.Control>
                <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_descripcion')}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
          {/* <hr style={{gridColumn: '1 / 3'}}/>
              <p>Proceso que audita</p>
              <br/>
              <Form.Group style={{ width:'95%', gridColumn: '1 / 2', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Tipo de proceso
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control
                        onChange={(e) => handleDataFormulario(e)}
                        name='tipo_proceso'
                        required
                        as='select'
                      >
                        <option selected disabled value=''>-- Seleccione -- </option>
                        <option value="1">Administrativo</option>
                        <option value="2">Comercial</option>
                        <option value="3">Financiero</option>
                        <option value="4">Logístico</option>
                        <option value="5">Operativo</option>
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Selecciona el tipo de proceso </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group style={{ display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Responsable del proceso
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control
                      onChange={(e) => handleDataFormulario(e)}
                      name='funcionario1'  type='text' ></Form.Control>
                  </div>
              </Form.Group>
              <Form.Group style={{width:'95%', gridColumn: '1 / 2', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Acompañante
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control
                      onChange={(e) => handleDataFormulario(e)}
                      name='funcionario2'  type='text' ></Form.Control>
                  </div>
              </Form.Group> */}
          <div className='buttonContainers'>
            <button className='confirmButton' disabled={isDisabled} >{t('formulario.actions.guardar')}</button>
          </div>
        </Form>
      </Card.Body>
      {
        modalConfirmacionSolicitud === true &&
        <NotificationModal
          infoNotificacion={tipoModal.mensaje}
          identificador={tipoModal.identificador}
          typeModal={tipoModal.tipo}
          isOpen={modalConfirmacionSolicitud}
          setIsOpen={setModalConfirmacionSolicitud}
          seccion={tipoModal.seccion}
        />
      }

    </Card>
  )
}
