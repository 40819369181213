import React from 'react'

export const AfterSVG = () => {
    return (
        <svg cursor="pointer" xmlns="https://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="Icon_ionic-md-arrow-dropleft-circle" data-name="Icon ionic-md-arrow-dropleft-circle" d="M21.375,12.375a9,9,0,1,0-9,9A9,9,0,0,0,21.375,12.375Zm-11.769,0L13.76,8.221v8.308Z" transform="translate(-3.375 -3.375)" fill="#213265" />
        </svg>

    )
}
