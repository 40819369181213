import React from 'react'
import { Card, Form } from "react-bootstrap";
import InputType from '../../helpers/InputType';
import { useForm } from "react-hook-form";
import { Button } from 'primereact/button';
import { useEventBus } from '../../context/EventBus';
import { useTranslation } from 'react-i18next';

function Filtros({ filtros, classname, props }) {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const { t } = useTranslation();

  const eventBus = useEventBus();

  const onSubmit = (data) => {
    eventBus.emit('FILTROS_ACTUALIZADOS', data.asesoria[0]);
  }

  return (
    <Card className={`bg-white shadow-1 p-3 card__box__surface card ${classname}`} {...props}>
      <Form onSubmit={handleSubmit(onSubmit)} className='mt-0 p-0'>
        <div className="filtros_input-cont fix-inputs ">
          {(filtros?.map((campo, index) => {
            const fieldName = `asesoria.0`;
            return (
              <InputType
                className='filtros_input-item'
                item={campo}
                index={0}
                idx={index}
                key={`${0} - ${index}`}
                reg={register}
                nombre={`${fieldName}`}
                errors={errors}
                col={12}
                type="reporte-operacional"
              />
            )
          }))}

        </div>
        <div className="d-flex justify-content-end">
          <Button size='small'>
            {t('botones.confirmar')}
        
          </Button>
        </div>
      </Form>
    </Card>

  )
}

export default Filtros
