import React from 'react'
import {Form, Col} from 'react-bootstrap'
import Select from 'react-select';

export default function BuscadoresEstudioRiesgos(props) {

  console.log(props.valor);

  return  (
    <div style={{paddingLeft: '8px' }}>
      <Select 
        options={props.data}
        isSearchable={true}
        name={props.name}
        onChange={(e) => {props.handleChange(e)}}
        placeholder={props.placeholder}
        isClearable={true}
        style={props.style}
      />
    </div>
  )
}
