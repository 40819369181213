import React from "react";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "../../ui/Chart";

function HorizontalBarChartComponent({ data, dataKey = "valor", nameKey = "x", maxHeight, color = "var(--blue-500)", label = "Label" }) {

  const generateChartConfig = () => {
    const config = {};
    config[dataKey] = { label: label };
    return config;
  };

  const chartConfig = generateChartConfig();

  return (
    <ChartContainer
      config={chartConfig}
      className="mx-auto"
      style={{ maxHeight: maxHeight }}
    >
      <BarChart
        data={data}
        layout="vertical"
        margin={{ left: -20 }}
      >
        <XAxis type="number" dataKey={dataKey} hide />
        <YAxis
          dataKey={nameKey}
          type="category"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
        <Bar dataKey={dataKey} fill={color} radius={5} />
      </BarChart>
    </ChartContainer>
  );
}

export default HorizontalBarChartComponent;
