import React from 'react';


import './ProgressBar.scss'

export default function ProgressBar(props) {

  const progress = props.dataTiempos.progreso

  return (
    <div>
      <div className='containerProgress'>
        <div className='labelsProgresoContainer'>
          <div className='labelsWidthData'>
            <p>Días hábiles:</p>
            <p>{props.dataTiempos.diasHabiles}</p>
          </div>
          <div className='labelsWidthData'>
            <p>Fecha inicial:</p>
            <p>{props.dataTiempos.fechaInicial}</p>
          </div>

          <div className='labelsWidthData'>
            <p className='labelProgreso'>Fecha final: </p>
            <p className='dataProgreso'>{props.dataTiempos.fechaFinal}</p>
          </div>
          <div className='labelsWidthData'>
            <p>Fecha entrega:</p>
            <p>{props.dataTiempos.fechaEntrega}</p>
          </div>
          <div className='labelsWidthData'>
            <p>Dia actual:</p>
            <p>{props.dataTiempos.diaActual}</p>
          </div>
          <div className='labelsWidthData'>
            <p>Dias restantes: </p>
            <p>{props.dataTiempos.diasRestantes}</p>
          </div>
        </div>
        <div className='shadow progressbar-container'>
          <div className=' progressbar-complete' style={{width: `${progress}%`}}>
            <div className='progressbar-liquid'></div>
          </div>
          <span className='progress'>
            <p>
              {props.dataTiempos.diaActual < 0
                ?
                'Días hábiles finalizados!'
                :
                <>
                  Día {props.dataTiempos.diaActual} de {props.dataTiempos.diasTotales}
                </>
              }
            </p>
          </span>
        </div>
      </div>
    </div>
  )
}
