import React, {useState} from 'react'

import {GoogleMap, withScriptjs, withGoogleMap, Marker,  Polygon } from 'react-google-maps'
import Polyline from 'react-google-maps/lib/components/Polyline';

import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
// import EditModal from '../../../components/modales/EditModal';

// 3.4334185,-76.4517656, 1.2047533,-77.2699356

// const cali = { lat: 3.4334185, lng: -76.4517656}

import '../styles/Heatmap.scss'


// const iconos = {
//   cultivo_ilicito: {
//     icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743798/planta_bt7n25.svg'
//   },
//   atentado: {
//     icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743964/explosion_qisd6j.svg'
//   },
// }


const HeatMap = (props) => {

  const { 
    getCoordinates, 
    index,
    idx, 
    setFormNumber,
    lat,
    lng,
    seccionPoligono,
    latitud,
    longitud,
    data
  } = props



  const [ descripcion, setDescripcion ] = useState('');

  // const getCoord = (latitud, longitud) => {
  //     setFormNumber(idx);
  //     getCoordinates(latitud, longitud, index, descripcion);
  //     setDescripcion('');
  // }
  
  const getPoints = (coords) => {
    let arr = [];
    if (coords.length > 0) {
      // eslint-disable-next-line array-callback-return
      coords.map((hm) => {      // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(hm.lat, hm.lng)
        arr.push(latlng);
      });
    }

    return arr;
  }

  const getCenter = () => {
    let puntoCentral = {};

    if (seccionPoligono) {
      if (seccionPoligono[0].length > 1) {
        /* eslint-disable array-callback-return */
        /* eslint-disable no-undef */
        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < seccionPoligono[0].length; i++) {
            bounds.extend(seccionPoligono[0][i]);
        }

        puntoCentral = {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng()
        }

        return puntoCentral;
      } if (coords.length === 1) {

          puntoCentral = {
              lat: seccionPoligono[0].lat,
              lng: seccionPoligono[0].lng
          }

          return puntoCentral;
      }
    } else {
      puntoCentral = {
        lat: latitud,
        lng: longitud
      }

      return puntoCentral;
    }
        
  }

  const getMapType = (item) => {

    const { tipo, puntos } = item;

    console.log(tipo, puntos);

      switch (tipo) {
        case 'puntos_calor':
            return (
              <HeatmapLayer
                data={getPoints(puntos)}
                radius={1000}
              />
            );
        case 'trazado':
          return (
            <Polyline
              path={puntos}
              geodesic={true}
              options={{
                strokeColor: "#ff2527",
                strokeOpacity: 0.75,
                strokeWeight: 2
              }}
            />
          );
        case 'puntos_simples':
          return (
            coords.map((coord, index) => (
              <Marker 
               position={puntos}
              />
          )));
        case 'poligono': 
          return (
            <Polygon
              path={puntos}
              //key={1}
              options={{
                  fillColor: "#000",
                  fillOpacity: 0.4,
                  strokeColor: "#000",
                  strokeOpacity: 1,
                  strokeWeight: 1
              }} 
            />
          )
        default:
          break;
      }
  }

  // useEffect(() => {
  //   showMunicipio()
  // }, [municipioSelected ? municipioSelected : null])

  const defaultMapOptions = {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    panControl: false,
    zoomControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  return(
    <>
        <GoogleMap 
          defaultZoom={props.zoom ? props.zoom : 5} 
          defaultCenter={{
            lat: lat,
            lng: lng
          }}
          defaultOptions={defaultMapOptions}
        >
          { (data.datos && data.datos.length > 0) && data.datos.map((panorama, index) => (
              ( panorama.tipo === 'puntos_calor' ? (
                <HeatmapLayer
                  data={getPoints(panorama.puntos)}
                  radius={1000}
                />
              ) : (panorama.tipo === 'trazado' ? (
                  <Polyline
                    path={panorama.puntos}
                    geodesic={true}
                    options={{
                      strokeColor: "#ff2527",
                      strokeOpacity: 0.75,
                      strokeWeight: 2
                    }}
                  />
              ) : (panorama.tipo === 'puntos_simple' ? (
                (panorama.puntos && panorama.puntos.map((item, index) => (
                  <Marker 
                    position={item}
                    key={index}
                  />
                )))
              ) : (panorama.tipo === 'poligono' ? (
                <Polygon
                  path={panorama.puntos}
                  //key={1}
                  options={{
                      // fillColor: getColorPolygone(item.tipo),
                      fillOpacity: 0.4,
                      strokeColor: "#000",
                      strokeOpacity: 1,
                      strokeWeight: 1
                  }}
                  onClick={() => getCenterPolygon(item.coords)}
                />
              ) : null ) )))
          ))} 
        </GoogleMap>
    </>   
  );
}

export default withScriptjs(
  withGoogleMap(
    HeatMap
  )
)

