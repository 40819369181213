import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';

const NumberOperable = ({ item, handleInputChange, index, idx, reg, nombre, error, getSumValues, getTotal, esEditable }) => {

  const [valor, setValor] = useState(parseInt(item.valor));

  return (
    <Form.Group style={{ display: 'block' }}>
      <Form.Label>{item.titulo}</Form.Label>
      {/* <Form.Control
                defaultValue={parseInt(item.valor)}
                type="number"
                name={`asesoria.${index}.${item.nombre}`}
                key={`${index} - ${idx}`}
                ref={reg}
                {...reg(`asesoria.${index}.${item.nombre}`, {
                    required: item.es_requerido
                })}
                style={{ border: error ? '1px solid #dc3545' : null }}
                onChange={(e) => { getSumValues(e, index)}}
                onBlur={() => {getTotal(index)}}
                disabled={esEditable}
            /> */}
      <CurrencyInput
        defaultValue={valor}
        className="form-control"
        decimalsLimit={6}
        name={`asesoria.${index}.${item.nombre}`}
        intlConfig={{
          locale: 'es-CO',
          currency: 'COP'
        }}
        style={{ border: error ? '1px solid #dc3545' : null }}
        onValueChange={(value, name) => { getSumValues(value, name, index); setValor(value) }}
        onBlur={() => { getTotal(index) }}
        disabled={esEditable}
      />

      <input
        type="hidden"
        name={`asesoria.${index}.${item.nombre}`}
        ref={reg}
        {...reg(`asesoria.${index}.${item.nombre}`, {
          required: item.es_requerido
        })}
        value={valor}
      />

      {error ?
        (
          error.type === 'required' ?
            (
              <span className="inputsInvalid">
                Este campo es requerido
              </span>
            )
            : null)
        : null}
    </Form.Group>
  );

}

export default NumberOperable;
