import React, {useState, useEffect} from 'react'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { validarEntorno } from "../../helpers/validarHost";
import {Accordion } from 'react-bootstrap'

import DonaConTotal from "../dashboard/DonaConTotal";


import Graficaconsolidado from '../dashboard/GraficaConsolidado'
import Dona2 from '../dashboard/Dona2'
import SortedBarChart from '../dashboard/SortedBarChart'
import DonaDosniveles from '../dashboard/DonaDosniveles'
import HerraduraExpandible from '../dashboard/HerraduraExpandible'
import DivergentChartAmplia from '../dashboard/DivergentChartAmplia'

import './styles/Panel.scss'


export default function Panel() {

  const [initialState, setInitialState] = useState({
    error: null,
    loading: false,
    data: {}
  })

  const HASH = window.location.hash 

  const getPanel = async () => {

    const dataReq = {
      hash: HASH
    }

    const req = await validarEntorno('JSON/PanelPresidencia/detalle_dep_presidencia_grafica.html', 'POST', dataReq);
    if (req.ok) {   
        const res = await req.json();
        setInitialState({...initialState, data: res})
    }
  }

  useEffect(() => {
    getPanel()
    console.log(initialState?.data)

  }, [])

  return (
    <div
      style={{
        marginTop: '20px'
      }}
    >
    <Card className='shadow cardGeneral'>
      <Card.Body id='bodyWidgetsDetalleDep' >
        <div id='infoGeneralDetalleDep' className='colWidgetBar'>
          <h2 id='tituloDep'>{initialState?.data?.departamento}</h2>
          <p id='corteDep' >{initialState?.data?.corte}</p>
          <h3 id='tituloDep' style={{fontSize:'1.7rem'}}>{ initialState?.data?.total ? `Total: ${initialState?.data?.total}` : null }</h3>
        </div>
        <div id='contenedorWidgetsDetalleDep' className='scrollSecundario colWidgetBar' >
          {initialState?.data?.widgets?.map((widget, index) => {
            return(
              <Card 
                style={{
                  borderBottom: `10px solid ${widget.color}`
                }}
                
                // id={
                //   widget.id === '1' 
                //     ? 'enProceso'
                //     : widget.id === '2'
                //       ? 'canceladas'
                //       : widget.id === '3'
                //         ? 'diasFinalizados'
                //         : null
                // }
                // id='diasVencimientso' 
                className="shadow  widgetPr" 
                // onClick={() => handleClickWidget('widgetFinalizados')}
                key={index}
              >
                <Card.Body>
                  <div className='contenidoWidget-panelPresidencia'>
                    <div className='labelWidget'>{widget.nombre}</div>
                    <div className='cantidadWidget'>{widget.valor}</div>                      
                  </div>
                </Card.Body>
              </Card>
            )
          })

          }
        </div>
      </Card.Body>
    </Card>
    <br/>
    <Card className='shadow cardGeneral' >
      <Card.Body>
        <div id='dep_main' >
          <Graficaconsolidado 
            title={initialState?.data?.main?.titulo}
            parametrizado={initialState?.data?.main?.parametrizado}
            id='consolidado_general'
            data={initialState?.data?.main?.data}
            series={initialState?.data?.main?.series?.lista}
            height='350px'
          />

        </div>
        <br/>
        <div id='dep_items' >
        <Accordion defaultActiveKey={0} >
          {initialState?.data?.items?.map((item, index) => {
            return(
              <Card key={item.id} >
                <Accordion.Toggle className='item_toggle' eventKey={item.id}>
                  {/* <button >Click me!</button> */}
                  {item.nombre}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={item.id}>
                  <Card.Body>
                    {item?.graficas?.map((grafica) => {

                      return(
                      <>
                        {grafica.tipo === 'donaTotal'
                          ?
                          <DonaConTotal
                            id={grafica.titulo}
                            data={grafica.gestiones}
                            // title={item.nombre}
                            total={grafica?.total}
                            tipo={grafica.tipo_valor}

                            // #ededed
                          
                          />
                          : grafica?.tipo === 'dona'
                          ?
                          <Dona2
                            data={grafica.data_grafica}
                            title={grafica.titulo}
                            total={grafica.total}
                            id={grafica.id}
                          />
                          : grafica?.tipo === 'barras_horizontales' 
                          ?
                          <SortedBarChart
                            data={grafica.data_grafica}
                            title={grafica.titulo}
                            total={grafica.total}
                            id={grafica.id}
                          />
                          : grafica?.tipo === 'torta3d' 
                          ?
                          <DonaDosniveles
                            data={grafica.data_grafica}
                            title={grafica.titulo}
                            total={grafica.total}
                            id={grafica.id}
                          />
                          : grafica?.tipo === 'herradura_expandible'
                          ?
                          <HerraduraExpandible
                            data={grafica.data_grafica}
                            title={grafica.titulo}
                            total={grafica.total}
                            id={grafica.id}
                          />
                          : grafica?.tipo === 'divergente'
                          ?
                          <DivergentChartAmplia
                            data={grafica.data_grafica}
                            title={grafica.titulo}
                            total={grafica.total}
                            id={grafica.id}
                            limite={grafica.limite}
                          />
                          : grafica?.tipo === 'consolidado'
                          ?
                          <Graficaconsolidado 
                            title={grafica.titulo}
                            parametrizado={grafica.parametrizado}
                            id={grafica.id ? grafica.id : 'consolidado'}
                            data={grafica.data}
                            series={grafica.series.lista}
                            height='350px'
                            seriesOcultas={true}
                          />
                          : null

                        }
                        <br/>
                      </>
                      )
                      })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })}
          
        </Accordion>
        </div>
      </Card.Body>
    </Card>
    </div>


  )
}
