import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { getFormControlByType } from '../../helpers/getFormControlByType';
import Loader from '../icons/Loader';


const Main = ({ data }) => {

    const [areaOpen, setAreaOpen] = useState('');
    const [segment, setSegment] = useState({});

    const getSegment = (segment) => {
        setSegment(segment);
        setAreaOpen(segment.titulo)
    }
    
    return (
        <>
            { Object.keys(data).length > 0 ? (
                <div className="container-inv">
                    <div className=' bodyWidgets'>
                        {data.formulario.seccion.map((item, index) => {
                            return(
                                <div 
                                    style={{ borderBottom:  areaOpen === item.id ? '8px solid #E4A731' : '8px solid #213265' }}
                                    className='shadow widget ' 
                                    key={item.id} 
                                    eventKey={item.id}
                                    onClick={() => getSegment(item)}
                                >

                                    <p>{item.titulo}</p>
                                    {/* <p className='widgetPorcentaje'>{item.porcentaje}</p> */}
                                </div>
                            )
                            }) 
                        }
                    </div>
                    <hr/>
                    <h4 className="pagesTitles">{ data.titulo }</h4>
                    { data.tiene_formulario ? (  
                        <Form className="audit-form">
                            <Form.Row>
                                { data.formulario.seccion.map( (sec, index)  => (
                                    <>
                                        <h6 className="pagesTitles">{sec.titulo}</h6>
                                        <>
                                            { sec.campos.map((campo, index) => (
                                                <>
                                                    { getFormControlByType(campo) }
                                                </>
                                            ))}
                                        </>
                                    </>
                                ))}
                            </Form.Row>
                            <Form.Row className="btn-form-row">
                                <Button className="confirmButton" type="submit">Enviar</Button> 
                            </Form.Row>  
                        </Form>
                        ) : null}
                        { data.tiene_formulario_documentos_relacionados ? (
                            <>
                                <Form className="audit-form">
                                    <Form.Row>
                                        <h4 className="pageTitles">{ data.formulario_documentos.titulo }</h4>
                                        { data.formulario_documentos.campos.map( (campo, index) => (
                                            <>
                                                { getFormControlByType(campo) }
                                            </>
                                        ))}
                                    </Form.Row>
                                    <Form.Row className="btn-form-row">
                                        <Button className="confirmButton" type="submit">Enviar</Button> 
                                    </Form.Row>  
                                </Form>
                            </>
                        ) : null}
                        { data.tiene_documentos_relacionados ? (
                            <h1>Aqui va una tabla para documentos</h1>
                        ) : null}  
                        {  data.tiene_formulario_imagenes_relacionadas ? (
                            <>
                                <Form className="audit-form">
                                    <Form.Row>
                                        <h4 className="pageTitles">{ data.formulario_imagenes.titulo }</h4>
                                        { data.formulario_imagenes.campos.map( (campo, index) => (
                                            <>
                                                { getFormControlByType(campo) }
                                            </>
                                        ))}
                                    </Form.Row>
                                    <Form.Row className="btn-form-row">
                                        <Button className="confirmButton" type="submit">Enviar</Button> 
                                    </Form.Row>  
                                </Form>
                            </>
                        ) : null}
                        { data.tiene_imagenes_relacionados ? (
                            <h1>Aqui va una tabla para imagenes</h1>
                        ) : null}  
                        {  data.tiene_datos_personales ? (
                            <h1>Aqui va una tabla para datos personales</h1>
                        ) : null }
                        { data.tiene_implicados ? (
                             <h1>Aqui va una tabla para implicados</h1>
                        ) : null}
                </div>
            ) : <Loader />}
        </>
    );
}

export default Main;