
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts"
import { ChartContainer, ChartTooltipContent, ChartTooltip } from "../../ui/Chart";

export function AreaStacked({ data, dataKey1 = "valor1", dataKey2 = "valor2", nameKey = "x", color1, color2, label1, label2 }) {
    
    const generateChartConfig = (data) => {
        const config = {};
        data?.forEach(item => {
          config[[dataKey1]] = { label: label1 };
          config[[dataKey2]] = { label: label2 };
        });
        return config;
      };
    
      const chartConfig = generateChartConfig(data);

    return (

        <ChartContainer config={chartConfig}>
            <AreaChart
                accessibilityLayer
                data={data}
                margin={{
                    left: 12,
                    right: 12,
                }}
            >
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey={nameKey}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    
                />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent indicator="dot" />}
                />

                <Area
                    dataKey={dataKey1}
                    type="natural"
                    fill={color1}
                    fillOpacity={0.4}
                    stroke={color1}
                    stackId="a"
                />
                <Area
                    dataKey={dataKey2}
                    type="natural"
                    fill={color2}
                    fillOpacity={0.4}
                    stroke={color2}
                    stackId="a"
                />
                
            </AreaChart>
        </ChartContainer>
    )
}

export default AreaStacked
