import React, { useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Card } from 'react-bootstrap';
import { HelpSVG } from '../icons/HelpSVG';
import PopUpModal from '../../helpers/PopUpModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

am4core.useTheme(am4themes_animated);

export const MediaTorta = ({ data, title, id, parametrizado, periodo, total, startAngle, endAngle }) => {

    const [open, setOpen] = useState(false)

    const [popUp, setPopUp] = useState('')
    const { t } = useTranslation();


    useEffect(() => {

        let chart = am4core.create(id, am4charts.PieChart);
        chart.hiddenState.properties.opacity = 0.5; // this creates initial fade-in

        chart.colors.list = [
            am4core.color("#2c5e99"),
            am4core.color("#05a4db"),
            am4core.color("#8BC34A"),
            am4core.color("#009688"),
            am4core.color("#F9CF00")
          ];
        chart.data = data;
        chart.radius = am4core.percent(70);
        chart.innerRadius = am4core.percent(0);
        chart.startAngle = startAngle ? startAngle : 180;
        chart.endAngle = endAngle ? endAngle : 360;
        chart.responsive.enabled = true;

        let series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "value";
        series.dataFields.category = "category";

        series.slices.template.cornerRadius = 10;
        series.slices.template.innerCornerRadius = 7;
        series.slices.template.draggable = true;
        series.slices.template.inert = true;
        series.labels.template.text = "[bold]{category}: {value.percent.formatNumber('.')}%";
        series.slices.template.tooltipText = "[bold]{category}: {value}";

        series.slices.template.colors = true;
        series.colors.list = [
            am4core.color("#2c5e99"),
            am4core.color("#05a4db"),
            am4core.color("#8BC34A"),
            am4core.color("#009688"),
            am4core.color("#F9CF00")
          ];
        // series.slices.template.propertyFields.fill = "fill";

        series.alignLabels = false;

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;

        chart.legend = new am4charts.Legend();
        chart.legend.position = "right"
        chart.legend.valueLabels.template.text = "";
        // chart.legendSettings.valueText = "{category.close}";


        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.horizontalAlign = "right";
        chart.exporting.formatOptions.getKey("svg").disabled = true;

        chart.exporting.menu.items = [{
          "label": "...",
          "menu": [
            { "type": "png", "label": t('botones.imagen') },
            // { "type": "csv", "label": "Tabla" },
            { "label": t('botones.imprimir'), "type": "print" }
          ]
        }];



    }, [])

    return (
        <Card className="shadow cardGraficaGeneral cardGraficaAnalistas cardDivergente">
            {/* <div className="d-flex flex-row-reverse bd-highlight p-1" style={{ cursor: 'pointer' }}
                onClick={() => setOpen(true)}
            >
                <HelpSVG className="bd-highlight" />
            </div> */}
            <br/>

            <Card.Body>
            <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> {title}
            <br/>
            {parametrizado
              ?
                <>
                  {parametrizado} {periodo ? `- ${periodo}` : null }
                </>

              : null
            }
            </h4>
            <br />
            <div id={id} style={{ width: '96%', minHeight: '360px', margin: '0 2.5% ' }}></div>
            <br />
            {total &&
              <h4 className='pagesTitles' >
                Total: {total}
              </h4>
            }

            </Card.Body>
            <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />

        </Card >
    )
}
