import React, { useState, useEffect, useRef } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import HeatmapUbi from '../../../../../helpers/Mapas/Ubicacion/Heatmap';
import Heatmap from '../../../../../helpers/Mapas/Descripcion/Heatmap';
import Loader from '../../../../icons/Loader';
import { credenciales } from '../../../../../helpers/credentials.google';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { validarEntorno } from '../../../../../helpers/validarHost';
import NotificationModal from '../../../../modales/NotificationModal';
import ModalPanorama from '../../../../modales/ModalPanorama';

import OpcionesCiudades from '../../../../../helpers/OpcionesCiudades'

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { municipios } from '../../../../../helpers/municipiosPanorama';
import { municipiosPoligono } from '../../../../../helpers/municipiosPoligono';


import './styles/Ubicacion.scss'
import { useParams } from 'react-router-dom';
import OpcionesBuscador from '../../../../../helpers/OpcionesBuscador';
import OpcionesBuscadorUbicacion from '../../../../../helpers/OpcionesBuscadorUbicacion';
import { useTranslation } from 'react-i18next';

const Ubicacion = ({ idPanorama, idSubseccion, titulo, tipo }) => {
  const { t } = useTranslation();
  const [regToDel, setRegToDel] = useState({})
  const [coordsMunicipio, setCoordsMunicipio] = useState({
    lat: '',
    lng: ''
  })
  const [municipio, setMunicipio] = useState('')
  const [addSolucion, setAddSolucion] = useState(false);
  const [inputList, setInputList] = useState([{ municipio: "", descripcion: "", fecha: "", tipo_riesgo: "", tipo: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
  const [indicador, setIndicador] = useState('');
  const [formNumber, setFormNumber] = useState(0);
  const [isForm, setIsForm] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [typeModalNotification, setTypeModalNotification] = useState('');
  const [invalidLat, setInvalidLat] = useState(false);
  const [invalidLng, setInvalidLng] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [coord, setCoord] = useState({
    lat: '',
    lng: ''
  });

  const [criterio, setCriterio] = useState('');
  const [ubicacionSelect, setUbicacionSelect] = useState('');
  const [riesgoSelect, setRiesgoSelect] = useState('');

  const [coordEdit, setCoordEdit] = useState({
    lat: '',
    lng: ''
  });

  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })

  const [marker, setMarker] = useState({
    lat: '',
    lng: ''
  });

  const ubicacionBase = {
    lat: 1.206845,
    lng: -77.2756767
  };
  const [isUbicacion, setIsUbicacion] = useState(true);

  const [selectMunicipio, setSelectMunicipio] = useState([]);

  const tiposRiesgo = [
    {
      "label": t('formulario.select.bajo'),
      "value": "bajo"
    },
    {
      "label": t('formulario.select.medio'),
      "value": "medio"
    },
    {
      "label": t('formulario.select.alto'),
      "value": "alto"
    },
  ]

  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;
  const hash = window.location.hash;
  const [showCreateModalNotification, setShowCreateModalNotification] = useState(false);
  const [showErrorModalNotification, setShowErrorModalNotification] = useState(false)
  const [messageNotification, setMessageNotification] = useState('');
  const [showEditDeleteModalNotification, setShowEditDeleteModalNotification] = useState(false);
  const [messageShowEditDeleteNotification, setMessageEditDeleteNotification] = useState('');
  const [typeNotification, setTypeNotification] = useState('');
  const [itemToErase, setItemToErase] = useState('');
  const [indexDelete, setIndexDelete] = useState('');
  const [typeOperation, setTypeOperation] = useState('');
  const [color, setColor] = useState('#000');
  const [depPolygon, setDepPolygon] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [seccionPoligono, setSeccionPoligono] = useState([]);
  const [depCoords, setDepCoords] = useState([]);

  const handleCloseCreateModalNotification = () => setShowCreateModalNotification(false);
  const handleCloseEModalNotification = () => setShowErrorModalNotification(false);

  const handleCloseModalEditDeleteNotification = () => {
    setShowEditDeleteModalNotification(false);
    setMessageEditDeleteNotification('');
    setTypeNotification('');
    setItemToErase('');
    setTypeOperation('')
  }

  const selectMun = useRef(null);
  const selectRie = useRef(null);

  // const clearValue = () => {
  //   selectRef.Select.clearValue();
  // }

  const handleOpenMessageModalDeleteEdit = (tipo, index, item) => {
    let message = '';
    if (tipo === 'registro') {
      message = t('modales.seguro_eliminar_registro');
      setRegToDel(item);
    } else if (tipo === 'actividad') {
      message = t('modales.seguro_eliminar_actividad');
    }
    setTypeOperation(tipo);
    setIndexDelete(index);
    setTypeNotification('confirmacion_eliminacion_ra');
    setMessageEditDeleteNotification(message)
    setShowEditDeleteModalNotification(true);
  }

  useEffect(() => {
    cleanComponent();
    getRegistrosSeccion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSubseccion]);

  const getCoordinates = (lat, lng, index, descripcion) => {
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[formNumber] };
    const coords = [...ubi.coords];
    const crd = {
      lat: lat,
      lng: lng,
      descripcion
    };
    coords.push(crd);
    ubi.coords = coords;
    ubi.setOpenMdl = false;
    ubicaciones[formNumber] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
    setIsForm(false);
  }


  const getCoordinatesUbi = (lat, lng) => {
    const list = [...inputList];
    const obj = { ...list[0] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[formNumber] };
    const coords = [...ubi.coords];
    const crd = {
      lat: lat,
      lng: lng,
      descripcion: ''
    };
    if (inputList[0].tipo === 'poligono') {
      coords.push(crd);
    } else if (inputList[0].tipo === 'punto') {
      coords[0] = crd;
    }
    ubi.coords = coords;
    ubi.setOpenMdl = false;
    ubicaciones[formNumber] = ubi;
    obj.ubicaciones = ubicaciones;
    list[0] = obj;
    setInputList(list);
    setIsForm(false);
  }

  const submitLatLng = (e, index, descripcion) => {
    e.preventDefault();
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[formNumber] };
    const coords = [...ubi.coords];
    const crd = {
      lat: coord.lat,
      lng: coord.lng,
      descripcion
    }
    coords.push(crd);
    ubi.coords = coords;
    ubi.setOpenMdl = false;
    ubi.descripcion = descripcion;
    ubicaciones[formNumber] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
    setInvalidLat(false);
    setInvalidLng(false);
    setCoord({
      lat: 0,
      lng: 0
    });
    const lat = document.getElementById("input-lat");
    const lng = document.getElementById("input-lng");

    lat.value = 0;
    lng.value = 0;
  }

  const getRegistrosSeccion = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id: idPanorama
    }

    //console.log(dataReq);

    let req = '';

    if (tipo === 'otros aspectos') {
      req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/registros_seccion_otros_aspectos.html', 'POST', dataReq);
    } else {
      req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/registros_seccion_ubicacion.html', 'POST', dataReq);
    }

    if (req.ok) {
      const res = await req.json()
      //console.log(res);

      setSeccionPoligono(res.ubicacion);

      setInitialState({
        ...initialState,
        loading: false,
        error: null,
        data: res
      })
    }
  }


  const handleAddClickUbi = (index) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    if (ubi.length >= 1) {
      const ubic = { setOpenMdl: false, municipio: "", descripcion: "", tipo_mapa: "puntos_simples", coords: [] };
      ubi.push(ubic);
      item.ubicaciones = ubi;
      list[index] = item;
      setInputList(list);
    }
  }

  const handleInputChangeOpenModal = (index, idx) => {
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
  }

  const handleInputChangeOpenModalForm = (e, index, idx) => {
    e.preventDefault();
    //console.log(e, index, idx);
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setFormNumber(idx);
    setInputList(list);
    setIsForm(true);
  }

  const handleRemoveClickUbi = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    ubi.splice(idx, 1);
    item.ubicaciones = ubi;
    list[index] = item;
    setInputList(list);
  }

  const handleAddClick = () => {
    setInputList([...inputList, { municipio: "", descripcion: "", fecha: "", tipo_riesgo: "", tipo: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: tipo !== 'otros aspectos' ? "poligono" : "", coords: [] }] }]);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const enviarDatosFormulario = async () => {

    const dataReq = {
      ubicaciones: addSolucion ? inputList : {},
      hash,
      tipo: tipo,
      id_subseccion: idSubseccion,
      id: idPanorama
    }

    console.log(JSON.stringify(dataReq));

    const req = await validarEntorno('JSON/panoramaRiesgos/respuesta_envio_descripcion_geo.html', 'POST', dataReq);

    const res = await req.json()

    if (req.ok) {
      if (res.status === "OK") {
        setMessageNotification(res.message);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');
        getRegistrosSeccion();
        cleanComponent();
      } else {
        setMessageNotification(res.message);
        setTypeNotification('falloPeticionesGestion');
        setShowErrorModalNotification(true);
      }
    } else {
      setMessageNotification('Ha ocurrido un fallo en el envio, intente nuevamente');
      setTypeNotification('falloPeticionesGestion');
      setShowErrorModalNotification(true);
    }
  }

  const handleLatLng = (e) => {
    const { name, value } = e.target;

    setCoord({
      ...coord,
      [name]: parseFloat(value)
    })
  }

  const showModal = (e, index, idx) => {

    e.preventDefault();

    // console.log(index, idx);

    if ((!Number.isNaN(coord.lat) && coord.lat !== 0) && (!Number.isNaN(coord.lng) && coord.lng !== 0)) {
      setShowDescriptionModal(true);
      setIndicador(index);
      setIsForm(true);
      setFormNumber(idx);
    } else {
      if (Number.isNaN(coord.lat) || coord.lat === 0) {
        setInvalidLat(true);
      } else {
        setInvalidLat(false);
      }

      if (Number.isNaN(coord.lng) || coord.lng === 0) {
        setInvalidLng(true);
      } else {
        setInvalidLng(false);
      }
    }
  }

  const handleInputChange = (e, index, idx) => {
    const { name, value } = e.target;
    const list = [...inputList];
    const obj = { ...list[index] };
    obj[name] = value;
    list[index] = obj;
    setInputList(list);
  };


  const cleanComponent = () => {
    setAddSolucion(false);
    setInputList([{ municipio: "", descripcion: "", fecha: "", tipo_riesgo: "", tipo: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: tipo !== 'otros aspectos' ? "poligono" : "", coords: [] }] }]);
    setIsForm(false);
    setCoord({ lat: 0, lng: 0 });
    setInvalidLat(false);
    setInvalidLng(false);
    setIndicador(0);
    setFormNumber(0);
    setShowDescriptionModal(false);
    setLat('');
    setLng('');
    setColor('#000');
  }

  // const getCoordsMunicipio = ({target}) => {

  //     //console.log(target.value)
  //     // console.log(target.name);
  //     // console.log(target.value);
  //     // setMunicipio(target.value)

  //     // showMunicipio(target.value)

  //     // const input = [...inputList];
  //     // const item = {...input[0]};
  //     // item.municipio = target.value;
  //     // input[0] = item;
  //     // setInputList(input);
  // }

  const getCoordsMunicipioBuscador = (e) => {
    if (e) {
      setUbicacionSelect(e.value);
      setMunicipio(e.value)
      showMunicipio(e.value);
      getPolygonState(e.value);
      const input = [...inputList];
      const item = { ...input[0] };
      item.municipio = e;
      input[0] = item;
      setInputList(input);
    } else {
      setUbicacionSelect('');
      setMunicipio('')
      showMunicipio('');
      getPolygonState('');
      const input = [...inputList];
      const item = { ...input[0] };
      item.municipio = '';
      input[0] = item;
      setInputList(input);
    }
  }

  const getPolygonState = (value) => {

    if (inputList[0].tipo === 'departamental') {
      const { Placemark } = municipiosPoligono.kml.Document.Folder;

      const valueSplit = value.trim().split('-');

      const department = valueSplit[0].trim();

      const filterPoints = Placemark.filter((mun, index) => {
        return mun.ExtendedData.SchemaData.SimpleData[1].text === department;
      });

      if (filterPoints.length > 0) {
        const MultiGeometryCoordinates = buildCoordinatesArrayFromString(filterPoints[0].MultiGeometry.Polygon[0].outerBoundaryIs.LinearRing.coordinates);
        setDepCoords(MultiGeometryCoordinates);
      }
    }
  }

  /**
   * Crea una matriz de coordenadas a partir del contenido del nodo MultiGeometryCoordinates de la base de datos GADM.
   */
  const buildCoordinatesArrayFromString = (MultiGeometryCoordinates) => {
    const finalData = [];
    const grouped = MultiGeometryCoordinates.split(" ");

    grouped.forEach(function (item, i) {
      let a = item.trim().split(',');

      finalData.push({
        lng: parseFloat(a[0]),
        lat: parseFloat(a[1])
      });
    });

    return finalData;
  }

  const showMunicipio = (value) => {
    let geocoder;
    if (value !== '') {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line no-undef
      geocoder = new google.maps.Geocoder();

      // const municipio = municipio
      // console.log(municipio);
      // console.log(typeof(municipio));

      geocoder.geocode({
        'address': value
      }, function (results, status) {
        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line no-undef
        if (status === google.maps.GeocoderStatus.OK) {

          let Lat = results[0].geometry.location.lat();
          let Lng = results[0].geometry.location.lng();

          if (inputList[0].tipo !== 'departamental') {
            getCoordinatesUbi(Lat, Lng);
          }

          setCoordsMunicipio({
            ...coordsMunicipio,
            lat: Lat,
            lng: Lng
          });
        } else {
          console.log("Something got wrong " + status);
        }
      })
    }
  }

  const eliminarRegistroActividad = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_res: regToDel.id_res,
      id_seccion: idSubseccion,
      tipo: 'desc_geo'
    }

    //console.log(dataReq);

    if (typeOperation === 'registro') {

      const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminacion_registro.html', 'POST', dataReq);

      if (req.ok) {

        const res = await req.json();

        if (res.estado === "OK") {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('eliminacion_confirmada');
          const dataDel = { ...initialState.data };
          const dataRegitroDel = [...dataDel.registros];
          dataRegitroDel.splice(index, 1);
          dataDel.registros = dataRegitroDel;
          setInitialState({
            ...initialState,
            data: dataDel
          });
          getRegistrosSeccion();
          setIndexDelete('');
        } else {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('falloPeticionesGestion');
        }
      } else {
        setShowEditDeleteModalNotification(true);
        setMessageNotification('Ha ocurrido un fallo en el envio, intente nuevamente');
        setTypeNotification('falloPeticionesGestion');
      }
    }
  }

  const editItem = (item) => {
    setShowEditModal(true);
    setEditData(item);
  }

  const closeEditItem = () => {
    setShowEditModal(false);
    setEditData({});
  }

  const getNewCoords = ({ target }) => {
    let geocoder;
    if (target.value !== '') {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line no-undef
      geocoder = new google.maps.Geocoder();

      // const municipio = municipio
      // console.log(municipio);
      // console.log(typeof(municipio));

      geocoder.geocode({
        'address': target.value
      }, function (results, status) {
        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line no-undef
        if (status === google.maps.GeocoderStatus.OK) {

          let Lat = results[0].geometry.location.lat();
          let Lng = results[0].geometry.location.lng();

          setCoordsMunicipio({
            ...coordsMunicipio,
            lat: Lat,
            lng: Lng
          })

          //   console.log(coordsMunicipio);

          //   console.log(Lat);
          //   console.log(Lng);

          const dataDel = { ...editData };
          const ubi = { ...dataDel.ubicaciones };

          const punto = {
            lat: Lat,
            lng: Lng
          };

          ubi.puntos[0] = punto;

          setEditData({
            ...editData,
            ubicacion: target.value,
            ubicaciones: ubi
          });

        } else {
          //console.log(status);
          alert("Something got wrong " + status);
        }
      })
    }
  }

  const handleEditData = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    });
  }

  const handleEditDataOption = (e) => {
    setEditData({
      ...editData,
      tipo_riesgo: e
    });
  }


  const editarDatosFormulario = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      data: editData,
      id_res: editData.id_res
    }

    //console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/registros_seccion_ubicacion_edicion.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const response = res;
        const { mensaje } = res;
        delete response.mensaje;
        delete response.estado;

        getRegistrosSeccion();
        setMessageNotification(mensaje);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');
        closeEditItem();
      } else {
        setMessageNotification('Ha ocurrido un fallo en el envio, intente nuevamente');
        setShowErrorModalNotification(true);
      }
    }
  }

  const deleteLastCoord = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubicaciones = [...item.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    const coords = [...ubi.coords];
    coords.pop();
    ubi.coords = coords;
    ubicaciones[idx] = ubi;
    item.ubicaciones = ubicaciones;
    list[index] = item;
    setInputList(list);
  }

  const handleInputChangeUbi = (e) => {
    const { value } = e.target;
    const list = [...inputList];
    const obj = { ...list[0] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[0] };
    ubi.tipo_mapa = value;
    ubicaciones[0] = ubi;
    obj.ubicaciones = ubicaciones;
    list[0] = obj;
    setInputList(list);
  }

  const handleInputChangeColor = (value) => {
    const list = [...inputList];
    const obj = { ...list[0] };
    obj.tipo_riesgo = value;
    list[0] = obj;
    setInputList(list);
  }


  const handleTypeDelimiter = (e, index) => {

    setRiesgoSelect(null);
    setUbicacionSelect(null);

    const { value } = e ? e : '';
    setCoordsMunicipio({
      lat: '',
      lng: ''
    });
    setMunicipio('');

    if (value === "departamental") {
      const arrFilter = municipios.filter((mun, index) => {
        return mun.es_departamento
      });
      setSelectMunicipio(arrFilter);
    } else {
      const arrFilter = municipios.filter((mun, index) => {
        return !mun.es_departamento
      });
      setSelectMunicipio(arrFilter);
    }

    handleInputTypeDelimeter(value);

  }

  const handleInputTypeDelimeter = (value) => {
    const list = [...inputList];
    const obj = { ...list[0] };
    obj.tipo = value;
    obj.tipo_riesgo = '';
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[0] };
    ubi.coords = [];
    ubicaciones[0] = ubi;
    obj.ubicaciones = ubicaciones;
    list[0] = obj;
    setInputList(list);
  }

  const handleColorMap = (e) => {

    const { value } = e ? e : '';

    switch (value) {
      case 'bajo':
        setColor('#23A835');
        break;
      case 'medio':
        setColor('#DBB93D');
        break;
      case 'alto':
        setColor('#EA5335');
        break;
      default:
        setColor('#000');
        break;
    }

    setRiesgoSelect(e);

    handleInputChangeColor(value);
  }

  const tipoSelects = [
    {
      value: 'departamental',
      label: t('formulario.select.departamento')
    },
    {
      value: 'punto',
      label: t('formulario.select.municipio')
    },
    {
      value: 'poligono',
      label: t('formulario.select.area')
    }
  ]

  return (
    <section style={{ width: '90%', margin: 'auto' }} >
      <div className='headerRegistro'>
        <p className='' >{titulo}</p>
        <button
          style={{ marginRight: '0px', width: '170px' }}
          className={addSolucion === true ? 'warningButton' : 'confirmButton'}
          onClick={() => setAddSolucion(!addSolucion)}
        >
          {
            addSolucion === true
              ? t('botones.cancelar')
              : `${t('botones.agregar')} ${titulo}`
          }
        </button>
      </div>
      <br />
      {addSolucion === false ?
        (
          null
        ) : (
          <div>
            {inputList.map((item_input, index) => (
              <>
                <>
                  <Row>
                    {/* <Col xs={8}>
                                            <Form.Group className='formGroupVertical' >
                                                <Form.Label>Fecha</Form.Label>
                                                <Form.Control
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item_input.fecha}
                                                    type="date"
                                                    name='fecha'
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                            <OpcionesCiudades valor={item_input.municipio} onChange={getCoordsMunicipio}/>
                                        </Col> */}
                    {tipo !== 'otros aspectos' && (
                      <Col xs={6}>
                        <OpcionesBuscador
                          data={tipoSelects}
                          name="tipo"
                          label={t('formulario.label.tipo')}
                          handleChange={(e) => handleTypeDelimiter(e)}
                          placeholder={t('formulario.label.seleccione_tipo_demarcacion')}
                          style={{ width: "100%", minWidth: "350px" }}
                          id="tipo-seleccion"
                        />
                      </Col>
                    )}
                    {(item_input.tipo !== '' && (tipo !== 'otros aspectos' && item_input.tipo !== 'poligono')) ? (
                      <Col xs={6}>
                        <OpcionesBuscadorUbicacion
                          data={selectMunicipio}
                          name="ubicacion"
                          label={t('formulario.label.ubicacion')}
                          handleChange={getCoordsMunicipioBuscador}
                          placeholder="Seleccione un municipio..."
                          style={{ width: "100%", minWidth: "350px" }}
                          id="municipio-ubicacion"
                          ref={selectMun}
                          value={ubicacionSelect}
                          setValue={setUbicacionSelect}
                        />
                      </Col>
                    ) : null}
                    {item_input.tipo !== '' && tipo !== 'otros aspectos' ? (
                      <Col xs={6}>
                        <OpcionesBuscadorUbicacion
                          data={tiposRiesgo}
                          name="riesgo"
                          label={t('formulario.label.nivel_riesgo')}
                          handleChange={(e) => { handleColorMap(e) }}
                          placeholder="Seleccione una opción..."
                          style={{ width: "100%", minWidth: "350px" }}
                          id="tipo-riesgo"
                          ref={selectRie}
                          value={riesgoSelect}
                          setValue={setRiesgoSelect}
                        />
                      </Col>
                    ) : null}
                    <Col xs={12}>
                      <Form.Group className='formGroupVertical' >
                        <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                        <Form.Control
                          as='textarea'
                          placeholder={t('formulario.label.escribe_descripcion')}
                          onChange={(e) => handleInputChange(e, index)}
                          value={item_input.descripcion}
                          name='descripcion'
                          style={{ height: 100 }}
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                </>
                <div>
                  {(item_input.ubicaciones) && item_input.ubicaciones.map((ubicacion, idx) => (
                    <>
                      <section style={{ width: '85%', margin: 'auto' }} >
                        {tipo === 'otros aspectos' && (
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.tipo_ubicacion')}</Form.Label>
                            <Form.Control
                              as='select'
                              onChange={(e) => handleInputChangeUbi(e, index, idx)}
                              name='tipo_mapa'
                              style={{ width: '50%' }}
                            >
                              <option selected disabled>{t('formulario.label.seleccione')}</option>
                              <option value='trazado'>{t('formulario.select.trazado')}</option>
                              <option value='puntos_calor'>{t('formulario.select.puntos_calor')}</option>
                              <option value='poligono' >{t('formulario.select.poligono')}</option>
                              <option value='oleoducto' >{t('formulario.select.oleoducto')}</option>
                            </Form.Control>
                          </Form.Group>
                        )}
                        <div className="containerOcs">
                          <div id='' className="mapa">
                            <>
                              {/* <div className="form-latlng">
                                                                <p>Agrega las coordenadas en el siguiente formulario o</p>
                                                                <Form>
                                                                    <Form.Group className='formGroupVertical'>
                                                                        <Row>
                                                                            <Col>
                                                                                <Form.Control
                                                                                    placeholder="Latitud"
                                                                                    name="lat"
                                                                                    defaultValue={coord.lat}
                                                                                    type="number"
                                                                                    onChange={handleLatLng}
                                                                                    isInvalid={invalidLat}
                                                                                    id="input-lat"
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Control
                                                                                    placeholder="Longitud"
                                                                                    name="lng"
                                                                                    defaultValue={coord.lng}
                                                                                    type="number"
                                                                                    onChange={handleLatLng}
                                                                                    isInvalid={invalidLng}
                                                                                    id="input-lng"
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <button className="confirmButton" onClick={(e) => handleInputChangeOpenModalForm(e, index, idx)}>Ubicar</button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form.Group>
                                                                </Form>
                                                            </div> */}
                              <>
                                <p>{t('formulario.label.selecciona_ciudad_municipio')}</p>
                                {tipo === 'otros aspectos' ? (
                                  <Heatmap
                                    googleMapURL={mapURL}
                                    containerElement={<div style={{ height: '100%' }}></div>}
                                    mapElement={<div style={{ height: '100%' }}></div>}
                                    loadingElement={<Loader />}
                                    latitud={municipio !== '' ? coordsMunicipio.lat : ubicacionBase?.lat}
                                    longitud={municipio !== '' ? coordsMunicipio.lng : ubicacionBase?.lng}
                                    zoom={10}
                                    coords={ubicacion.coords}
                                    getCoordinates={getCoordinates}
                                    tipoMapa={ubicacion.tipo_mapa}
                                    index={index}
                                    idx={idx}
                                    showDescriptionModal={ubicacion.setOpenMdl}
                                    setShowDescriptionModal={handleInputChangeOpenModal}
                                    submitLatLng={submitLatLng}
                                    isForm={isForm}
                                    setFormNumber={setFormNumber}
                                    setLat={setLat}
                                    setLng={setLng}
                                    lat={lat}
                                    lng={lng}
                                    marker={coordsMunicipio}
                                    tipo={tipo === 'ubicacion' ? "ubicacion" : "otros aspectos"}
                                    seccionPoligono={tipo === 'ubicacion' ? undefined : seccionPoligono}
                                    color={color}
                                  // municipioSelected={municipio}
                                  // handleCoordsMunicipio={setCoordsMunicipio}
                                  />
                                ) : (
                                  <HeatmapUbi
                                    googleMapURL={mapURL}
                                    containerElement={<div style={{ height: '100%' }}></div>}
                                    mapElement={<div style={{ height: '100%' }}></div>}
                                    loadingElement={<Loader />}
                                    latitud={municipio !== '' ? coordsMunicipio.lat : ubicacionBase?.lat}
                                    longitud={municipio !== '' ? coordsMunicipio.lng : ubicacionBase?.lng}
                                    zoom={item_input.tipo !== 'poligono' ? 7 : 10}
                                    coords={item_input.tipo !== 'departamental' ? ubicacion.coords : depCoords}
                                    getCoordinates={getCoordinates}
                                    index={index}
                                    idx={idx}
                                    showDescriptionModal={ubicacion.setOpenMdl}
                                    setShowDescriptionModal={handleInputChangeOpenModal}
                                    submitLatLng={submitLatLng}
                                    isForm={isForm}
                                    setFormNumber={setFormNumber}
                                    setLat={setLat}
                                    setLng={setLng}
                                    lat={lat}
                                    lng={lng}
                                    marker={coordsMunicipio}
                                    tipo={tipo === 'ubicacion' ? "ubicacion" : "otros aspectos"}
                                    seccionPoligono={tipo === 'ubicacion' ? undefined : seccionPoligono}
                                    color={color}
                                    tipoSeleccion={inputList[0].tipo}
                                  // municipioSelected={municipio}
                                  // handleCoordsMunicipio={setCoordsMunicipio}
                                  />
                                )}
                              </>
                            </>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {/* {item_input.ubicaciones.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickUbi(index, idx)} />}
                                                {item_input.ubicaciones.length - 1 === idx && <AddCircleIcon className="icon-gray add" onClick={() => handleAddClickUbi(index)} />}
                                                 */}


                        {(ubicacion.coords.length > 0 && item_input.ubicaciones[0].tipo !== 'poligono') && (
                          <div className="table-ubi-container">
                            <Table striped bordered hover className="tabla-ubicaciones">
                              <thead>
                                <tr>
                                  <th>{t('formulario.label.latitud')}</th>
                                  <th>{t('formulario.label.longitud')}</th>
                                  <th>{t('formulario.label.descripcion')}</th>
                                  <th>{t('formulario.label.accion')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ubicacion.coords.map((ubi, tbx) => (
                                  <tr className="rowTabla">
                                    <td>{ubi.lat}</td>
                                    <td>{ubi.lng}</td>
                                    <td>{ubi.descripcion}</td>
                                    {tbx === (ubicacion.coords.length - 1) ? (
                                      <td className='tdBotones'>
                                        <div className='botonesTabla '>
                                          <button
                                            style={{ cursor: 'pointer' }}
                                            className='botonTablaGeneral botonEliminarTabla'
                                            onClick={() => { deleteLastCoord(index, idx) }}
                                          >
                                            <DeleteIcon fontSize='small' />
                                            {t('botones.eliminar')}
                                          </button>
                                        </div>
                                      </td>
                                    ) : <td></td>}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </section>
                    </>
                  ))}
                </div>
                {/* <hr/> */}
                {/* {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />}
                                {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />} */}
              </>
            ))}
          </div>
        )
      }
      <br />
      {addSolucion === true &&
        <div className='buttonContainer'>
          <button
            onClick={() => { enviarDatosFormulario() }}
            className='confirmButton'
          >
            {t('botones.enviar')}
          </button>
        </div>
      }
      {initialState?.data?.registros && initialState?.data?.registros.length > 0
        ?
        <>
          <hr />
          <br />
          <h3 className='pagesTitles' >{t('panorama_riesgos.lista_registros')}</h3>
          <br />

          <table>
            <thead>
              <th>{t('formulario.label.ubicacion')}</th>
              <th>{t('formulario.label.descripcion')}</th>
              <th>{t('formulario.label.acciones')}</th>
            </thead>
            <tbody>
              {initialState.data.registros.map((registro, index) => (
                <tr className="rowTabla" key={index} style={{ fontSize: '1rem' }}>
                  <td>{registro.municipio}</td>
                  <td>{registro.descripcion}</td>
                  {/* <td>{registro.fecha}</td> */}
                  <td className='tdBotones'>
                    <div className='botonesTabla'>
                      {initialState?.data?.permisos?.editar &&
                        <button
                          style={{ cursor: 'pointer' }}
                          className='botonTablaGeneral botonEditarTabla'
                          onClick={() => { editItem(registro) }}
                        >
                          <EditIcon fontSize='small' />
                          {t('botones.editar')}
                        </button>
                      }
                      {initialState?.data?.permisos?.eliminar &&
                        <button
                          style={{ cursor: 'pointer' }}
                          className='botonTablaGeneral botonEliminarTabla'
                          onClick={() => { handleOpenMessageModalDeleteEdit('registro', index, registro) }}
                        >
                          <DeleteIcon fontSize='small' />
                          {t('botones.eliminar')}
                        </button>
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
        :
        <div className='mensajeTablaSinInfo'>
          {t('formulario.label.no_agregado_registros')}
        </div>

      }
      <NotificationModal
        isOpen={showCreateModalNotification}
        setIsOpen={handleCloseCreateModalNotification}
        typeModal={typeModalNotification}
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showErrorModalNotification}
        setIsOpen={setShowErrorModalNotification}
        typeModal="error_panorama"
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showEditDeleteModalNotification}
        setIsOpen={handleCloseModalEditDeleteNotification}
        typeModal={typeNotification}
        message={messageShowEditDeleteNotification}
        item={itemToErase}
        indexDelete={indexDelete}
        handleEliminar={eliminarRegistroActividad}
      />
      <ModalPanorama
        typeModal="ubicacion"
        isOpen={showEditModal}
        setIsOpen={closeEditItem}
        getCoordsMunicipio={getNewCoords}
        handleInputChange={handleEditData}
        data={editData}
        googleMapURL={mapURL}
        editarDatosFormulario={editarDatosFormulario}
        handleColorMap={handleColorMap}
        handleEditDataOption={handleEditDataOption}
        tipo={tipo === 'ubicacion' ? "ubicacion" : "otros aspectos"}
      />
    </section>
  )
}

export default Ubicacion;
