import React, {useState, useEffect} from 'react'
import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal'
import { HelpSVG } from '../icons/HelpSVG'

import { capitalizeFirstLetter } from '../../helpers/textHelpers'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { LabelList } from 'recharts'

am4core.useTheme(am4themes_animated);

function Histograma(props) {

  // console.log(props.id)
  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')


  useEffect(()=>{
    let chart = am4core.create(props.id, am4charts.XYChart)


    // Themes begin
    am4core.useTheme(am4themes_animated);

    let colorsArr = [
      '#2c5e99',
      '#05a4db',
      '#009688',
      '#8BC34A',
      '#F9CF00',
      '#F46036'
    ]

    const mapTypes = (series) => {

      // console.log(series)

      const arrItem = series.filter(key => key !== 'year')

      arrItem.map((key, index) => {
        return createSeries(capitalizeFirstLetter(key),key,colorsArr[index])
      })

    }



    chart.marginRight = 400;

    // chart.layout = 'verticalLayout'
    // Create chart instance

    // Add data
    chart.data = props?.data;

    // chart.colors = [
    //   am4core.color("#2c5e99"),
    //   am4core.color("#05a4db"),
    //   am4core.color("#009688"),
    //   am4core.color("#8BC34A"),
    //   am4core.color("#F9CF00")
    // ]

    // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "year";
  categoryAxis.title.text = "Días del mes";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 20;
  categoryAxis.renderer.axisFills.template.disabled = false;


  var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = "Total de solicitudes";
  valueAxis.calculateTotals = true;
  valueAxis.min = 0;
  // valueAxis.max = 100;
  valueAxis.strictMinMax = false;
  valueAxis.renderer.minGridDistance = 50;
  valueAxis.renderer.ticks.template.disabled = false;
  valueAxis.renderer.ticks.template.strokeOpacity = 0.4;

  // valueAxis.renderer.labels.template.adapter.add("text", function(text) {
  //   return text + "%";
  // });


    // Create axes
    // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.dataFields.category = "year";
    // // categoryAxis.renderer.grid.template.location = 0;
    // categoryAxis.renderer.inversed = false;
    // categoryAxis.renderer.grid.template.location = 0;
    // categoryAxis.renderer.minGridDistance = 20;
    // // categoryAxis.renderer.minGridDistance = -1;
    // // categoryAxis.renderer.axisFills.template.disabled = false;
    // // categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;
    // // categoryAxis.sortBySeries = categoryAxis;

    // // categoryAxis.renderer.labels.template.adapter.add("text", function(text) {
    // //   return "[bold]" + text ;
    // // });


    // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min = 0;
    // valueAxis.max = props.limite ? props.limite : 150;
    // valueAxis.calculateTotals = false
    // valueAxis.renderer.minGridDistance = 50;
    // valueAxis.renderer.ticks.template.length = 17;
    // valueAxis.renderer.ticks.template.disabled = false;
    // valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
    // valueAxis.renderer.labels.template.adapter.add("text", function(text) {
    //   return text;
    // })

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.position =  props.convenciones ? props.convenciones : "right";

    // // Use only absolute numbers
    // chart.numberFormatter.numberFormat = "#.#s";

    // Create series
    // function createSeries(name, field, color) {

    //   // console.log(field)

    //   let series = chart.series.push(new am4charts.ColumnSeries());
    //   series.name = name;
    //   // series.stacked = false;
    //   series.dataFields.valueY = field;
    //   series.dataFields.categoryX = "year";
    //   // series.data = field;
    //   series.stroke = color;
    //   series.fill = color;
    //   // series.tooltipText = "{valueY}";
    //   // series.xAxis= categoryAxis;
    //   // series.yAxis= valueAxis;

    //   let label = series.bullets.push(new am4charts.LabelBullet());

    //   label.label.text = "{valueY}";
    //   label.label.fill = am4core.color("#fff");
    //   // label.verticalCenter = am4core.percent(50);
    //   // label.horizontalCenter = am4core.percent(50);
    //   label.populateString = true
    //   label.label.strokeWidth = 0;
    //   label.label.truncate = false;
    //   label.label.hideOversized = false;
    //   // label.align = "center";
    //   // label.x = 0;
    //   // label.y = 0;
    //   label.tooltipText = "{name}: {valueY}";

    //   // series.appear();


    //   return series;
    // }



      // let a = '#2c5e99';
      // let b = '#05a4db';
      // let c = '#009688';
      // let d = '#8BC34A';
      // let e = '#F9CF00';

      mapTypes(props.series)

      function createSeries(name, field, color) {

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.stacked = true;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "year";
        // series.data = field;
        series.stroke = color;
        series.fill = color;
        // series.tooltipText = "{valueY}";
        // series.xAxis= categoryAxis;
        // series.yAxis= valueAxis;

        let label = series.bullets.push(new am4charts.LabelBullet());
        label.label.text = "{valueY}";
        label.label.fill = am4core.color("#fff");
        label.label.fontSize = "11px"
        label.label.verticalCenter = "middle"
        label.label.horizontalCenter = "middle"
        label.label.populateString = true
        label.label.strokeWidth = 0;
        label.label.truncate = false;
        label.label.hideOversized = true;
        // label.x = 0;
        label.locationY = 0.5;
        label.tooltipText = "{name}: {valueY}";
        label.align = "center";

        // series.appear();
        return series;
      }

      // createSeries("Escolaridad", "escolaridad", a);
      // createSeries("Convivencia", "convivencia", b);
      // createSeries("Investigaciones", "inv_adm", c);
      // createSeries("Dependencia", "deco", d);
      // createSeries("Validación", "vdoc", e);



    // chart.legend.events.on("layoutvalidated", function(event){
    //   chart.legend.itemContainers.each((container)=>{
    //     if(container.dataItem.dataContext.name == "Never"){
    //       container.toBack();
    //     }
    //   })
    // })

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "Imagen" },
        // { "type": "csv", "label": "Tabla" },
        { "type": "print", "label": "Imprimir" }
      ]
    }];



  },[props.data])


  return (
    <Card className='shadow cardGraficaGeneral cardDivergente' >
     {/*  <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br />
      <Card.Body>
        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}>{props.title}
        <br/>
        {props.parametrizado
          ?
            <>
              {props.parametrizado} - {props.periodo }
            </>

          : null
        }
        </h4>
        <br/>
        <br/>
        <div id={props?.id}
        style={{ width: '100%', minHeight: '500px', margin: '0 auto ', height: 'auto' }}></div>
        <br/>
        <h4 className='pagesTitles' >
          Total: {props.total}
        </h4>
        {props.id === 'anotaciones' &&
          <>
            <br/>
            <div className='convencionesContainer'>
                <div className='contenedorx'>
                    <b className='convencion' id='sinAnotacion'> . </b>
                    Sin anotación
                </div>
                <div className='contenedorx'>
                    <b className='convencion' id='conAnotacion'> . </b>
                    Con anotación
                </div>
            </div>
          </>
        }

      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}


export { Histograma }
