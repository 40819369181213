import React, { useState } from 'react';
// import { Button } from '@material-ui/core'
//import { useDispatch } from 'react-redux'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NotificationModal from '../modales/NotificationModal';
import ReCAPTCHA from "react-google-recaptcha";
import '../styles/SignIn.scss';
import { Col, Row, Modal } from 'react-bootstrap';

//import { inicioSesion } from '../../reducers/auth.reducer'
import { IconSVG } from '../icons/CloseSVG';
import { useForm } from 'react-hook-form';
import { Alert } from 'react-bootstrap';
import { peticionSinToken } from '../../helpers/peticiones';
import Swal from 'sweetalert2';
import { validarEntorno } from '../../helpers/validarHost';

export const FormRecuperarPass = ({ setForm }) => {

  // const dispatch = useDispatch()

  const { register, handleSubmit, errors } = useForm();


  const [validateCaptcha, setValidateCaptcha] = useState(false)


  const [captchaValue, setCaptchaValue] = useState('')

  const [open, setOpen] = useState(false)

  function onChange(value) {

    setCaptchaValue(value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    mensaje: ''
  })

  const onSubmit = async (data) => {

    setValidateCaptcha(false)

    if (captchaValue === '') {

      setValidateCaptcha(true)

    } else {
      data.captcha = captchaValue

      const respuesta = await peticionSinToken('JSON/respuestaSolicitudRecuperarPass_json.html', 'POST', data);

      if (respuesta.ok) {

        const body = await respuesta.json()

        if (body.estado === 'OK') {
          setModal({
            ...modal,
            show: true,
            tipo: 'envio_peticion_correcto',
            mensaje: body.mensaje
          });
        } else {
          setModal({
            ...modal,
            show: true,
            tipo: 'envio_peticion_erroneo',
            mensaje: body.mensaje
          });
        }
      }
    }
  }

  const closeModal = () => {
    setModal({
      ...modal,
      show: false,
      tipo: '',
      mensaje: ''
    });
  }

  return (
    <div id='signInContainer'>
      <p><b>Recuperar contraseña</b></p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label >Usuario</label>
        <input type='text' id='username' name="username" ref={register({
          required: { value: true, message: 'El usuario es requerido' },
          minLength: { value: 5, message: 'El usuario debe tener mas de 5 caracteres' },

        })}></input>
        {errors?.username && <Alert className="mt-2" variant="danger">{errors?.username?.message}</Alert>}

        <label>Correo</label>
        <input type='email' id='email' name="email"
          ref={register({
            required: { value: true, message: 'El email es requerido' },
            minLength: { value: 5, message: 'El email  debe tener mas de 5 caracteres' },

          })}></input>
        {errors?.email && <Alert className="mt-2" variant="danger">{errors?.email?.message}</Alert>}



        <br />
        <ReCAPTCHA
          sitekey="6LfxJ78mAAAAAPcy6fqRRgmoZUBXrKDxgw6wRhpm"
          onChange={onChange}
        />
        <div id='formOptions'>
          <p id='helpText' style={{ cursor: 'pointer' }} onClick={() => setForm(true)}>Iniciar Sesión</p>
          <button id='loginButton' type='submit' variant="contained">
            Ingresar
          </button>
        </div>
      </form>
      <NotificationModal mensaje={modal.mensaje} isOpen={modal.show} typeModal={modal.tipo} setIsOpen={closeModal} />
      <div id='help' onClick={() => setOpen(true)}>
        Ayuda
        <HelpOutlineIcon />
      </div>

      <div>
        <Modal show={open} centered onHide={handleClose} style={{ borderRadius: '20px' }}>
          <Modal.Header style={{ textAlign: 'center', display: 'inline-block', background: '#E4A731', color: '#213265' }}>
            <Modal.Title>
              <Row>
                <Col className='pagesTitles' style={{ height: 'auto' }} >
                  CIA 2.0 - Central de Información Actualizada
                </Col>
                <Col xs={1} md={1} onClick={handleClose}>
                  <IconSVG />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Para recuperar la contraseña, por favor ingrese su nombre de usuario y correo electrónico.
          </Modal.Body>
        </Modal>
      </div>
    </div >
  )
}
