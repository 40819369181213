import { BorderBottom } from '@material-ui/icons';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import useState from 'react-usestateref';
import { Col, Card, Row, Form, Button } from 'react-bootstrap';
import { validarEntorno } from '../../helpers/validarHost';
import Loader from '../icons/Loader'
import { Resaltado } from '../icons/Resaltado'
import { LeftSVG } from '../icons/LeftSVG';
import { RightSVG } from '../icons/RightSVG';
import InputType from '../../helpers/InputType';
import { RenderIcon } from '../../helpers/RenderIcon';
import { useForm } from 'react-hook-form';
import NotificationModal from '../modales/NotificationModal';
import ProgressBar from '../../helpers/ProgressBar'
import ProgressBarComplete from '../../helpers/ProgressBarComplete'
import EditModal from '../../components/modales/EditModal';
import './style/Tabla.scss';
import GeneralWidgets from '../dashboard/GeneralWidgets';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchAndNotify } from '../../helpers/fetchAndNotify';
import FilePreview from '../common/FilePreview';
import { PanelRightOpen } from '../icons/PanelRightOpen';
import { PanelRightClose } from '../icons/PanelRightClose';

const Tabla = ({ idTipoReporte, initialState, setInitialState, setFiltrosForm, filtrosForm, url }) => {

  const { t } = useTranslation(); // Constante de traducción
  const reportesData = useSelector(store => store.reportesReducer)
  const { filtros_presidencia } = reportesData
  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();
  const [registro, setRegistro] = useState()
  const [renderKey, setRenderKey] = useState(0); //Estado para actualizar el componente de input y que se fuerce a renderizar
  const HASH = window.location.hash;
  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    data: {}
  }) //Almacena el estado del modal y su respectiva información
  const [checkedState, setCheckedState] = useState(
    //Save the item.valor_check and the id of the item
    initialState?.data?.regs?.map((item) => {
      return {
        value: item.valor_check,
        id: item.id
      };
    })
  ); //Guarda el estado de los checkbox de la tabla y su respectivo id
  const [allChecked, setAllChecked] = useState(false); //Guarda el estado del checkbox de la tabla "Seleccionar todos"

  const [regSelected, setRegSelected] = useState('')
  const [allCheckedSelected, setAllCheckedSelected] = useState('');

  const [filtros, setFiltros, filtrosRef] = useState({
    limite: initialState?.limite,
    pagina: 1
  });

  const [popoverMode, setPopoverMode] = useState(false); // Indica si el modo popover está activo o no
  const [showAllModalsRegisterId, setShowAllModalsRegisterId] = useState(null); // Indica el id del cual se quieren mostrar todos los modales

  /**
   * Cada vez que se envía el formulario de filtros es decir
   * cuando cambia filtrosForm, se actualiza el numero de registros a 10
   * y se actualizan los estados de los check con los valores mas actuales
   */

  useEffect(() => {
    setFiltros({
      limite: 10,
      pagina: 1
    })
  }, [filtrosForm])


  useEffect(() => {
    setCheckedState(
      initialState?.data?.regs?.map((item) => {
        return {
          value: item.valor_check,
          id: item.id
        };
      })
    )
    setAllChecked(false);
  }, [initialState?.data?.regs])



  const [colores, setColores, coloresRef] = useState(initialState?.data?.resaltado ? initialState.data.resaltado : []);
  const [notificationModal, setNotificationModal] = useState({
    show: false,
    tipo: '',
    typeModal: '',
    data: {}
  })

  //FUNCION PARA PINTAR LOS RESALTADORES

  const resaltarMarker = async (tipo, color, id, idx) => {

    const data = initialState.data
    const regs = initialState.data.regs

    regs[idx].valor_estado = tipo ? tipo : '';


    // console.log(regs)

    data.regs = regs

    // const x = { ...initialState }
    // const a = { ...x.data }
    // const b = [...a.regs]
    // const c = { ...b[idx] }
    // // c.valor_estado = c.valor_estado === '' ? tipo : '';
    // c.valor_estado = tipo ? tipo : '';
    // // c.resaltado = c.resaltado === '' ? color : '';
    // b[idx] = c
    // a.regs = b
    // x.data = a
    // await setInitialState(x)
    underlineReg(regs[idx].valor_estado)
    await setInitialState({ ...initialState, data: data, })

    updateRegs()
  }

  //FUNCION PARA PINTAR TODOS
  const resaltarTodos = async (valor, color) => {

    // console.log(valor, color);

    const data = initialState.data
    const regs = initialState.data.regs

    const todosResaltados = regs.map((item) => {
      return {
        ...item,
        valor_estado: valor,
        resaltado: color
      }
    })

    data.regs = todosResaltados
    await setInitialState({ ...initialState, data: data, })
    updateRegs()
    setAllCheckedSelected(valor)
  }

  const updateRegs = async () => {

    setNotificationModal({
      ...notificationModal,
      show: true,
      typeModal: 'loader'
    })

    const dataReq = {
      hash: HASH,
      data: initialState?.data,
      tipo_reporte: filtrosForm.tipo_reporte,
      dataForm: filtrosForm.filtros,
      limite: filtrosRef.current.limite,
      pagina: filtrosRef.current.pagina,
      filtros_presidencia: Object.keys(filtros_presidencia) ? filtros_presidencia : null,
      tipo: "form",
      pag_actual: 1

    }


    // Aqui vamos a enviar la data de los filtros de la parte superior

    // console.log(dataReq)

    const req = await validarEntorno(url ? url : 'JSON/ReportesOperativos/actualizar_rep_op_res.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      // console.log(res)

      setNotificationModal({
        ...notificationModal,
        show: true,
        typeModal: 'notificacion',
        tipo: res.estado,
        mensaje: res.mensaje,
        titulo: res.titulo ? res.titulo : null
      })
    } else {

      setNotificationModal({
        ...notificationModal,
        // show: false,
        typeModal: 'notificacion',
        tipo: 'error',
        mensaje: 'Server error'
      })

    }

  }

  // useLayoutEffect(() => {
  //     updateRegs()
  // }, [initialState])

  const filterData = async (e) => {

    setInitialState({ ...initialState, loading: true })

    const { name, value } = e.target;

    setFiltros({
      ...filtros,
      [name]: value
    });

    const dataReq = {
      hash: HASH,
      dataForm: filtrosForm.filtros,
      tipo_reporte: filtrosForm.tipo_reporte,
      limite: filtrosRef.current.limite,
      pagina: 1,
    };

    // console.log(dataReq)

    const req = await validarEntorno(url ? url : 'JSON/ReportesOperativos/rep_op_res.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      setCheckedState(
        res?.regs?.map((item) => {
          return {
            value: item.valor_check,
            id: item.id
          };
        })
      )
      setAllChecked(false);
      setInitialState({ ...initialState, data: res, loading: false })


      // console.log(res)
    } else {
      setInitialState({ ...initialState, loading: false, error: req.error })
    }
  }

  const paginateData = async (page) => {
    setInitialState({ ...initialState, loading: true })
    const limit = filtros.limite
    setFiltros({
      ...filtros,
      limite: limit,
      pagina: page
    });

    const dataReq = {

      hash: HASH,
      dataForm: filtrosForm.filtros,
      limite: filtrosRef.current.limite,
      pagina: filtrosRef.current.pagina,
      tipo_reporte: idTipoReporte
    };

    // console.log(dataReq)

    const req = await validarEntorno(url ? url : 'JSON/ReportesOperativos/rep_op_res.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, data: res, loading: false })

      // document.getElementById('cantidad_regs').value = '10'
      // console.log(res)
    } else {
      setInitialState({ ...initialState, loading: false, error: req.error })
    }
  }

  const linkOrTxt = (valor, link, preview = false, titulo = '') => {

    // console.log(valor)

    const http = 'http'
    const validacion = (link?.startsWith(http) || valor?.startsWith(http))
    //const validacion_titulo = (titulo)

    if (validacion === true) {
      return (
        <>
          {!preview ?
            <a
              style={{ textAlign: 'center' }}
              target={'_blank'}
              rel="noreferrer"
              className={`myElement ${registro === valor && popoverMode && 'colreg--selected'}`}
              href={link ? link : valor}
            >
              {link ? valor : valor}
            </a>
            :
            <FilePreview src={link ? link : valor} />
          }
        </>
      )
    }
    if (titulo !== '') {
return (
  <> <h2 className='pagesTitles pagesTitles--small'style={{ fontSize: 'medium', height: 'auto' }} >{titulo}</h2> {valor} </>
  )
}else{
    return (
      <>{valor}</>
    )
}
  }

  const validateLink = (valor, link) => {
    const http = 'http'
    const validacion = (link?.includes(http) || valor?.includes(http))
    if (validacion === true) {
      return true
    }
    return false
  }


  const renderIcon = (icons) => {


    return (
      <div clasName='icons-cont-arr' style={{ display: 'flex', margin: '0 8px' }} >
        {icons?.map((icon, index) => {

          return (
            <>
              {icon.enlace ?
                <a href={icon.enlace} target='_blank' rel="noreferrer" className='icon-cont' key={index} style={{ padding: '3px' }} title={icon.tooltip}>
                  <RenderIcon icon={icon} />
                </a>
                : icon.accion ?
                  <div className='icon-cont'
                    key={index}
                    style={{ padding: '3px' }}
                    title={icon.tooltip}
                    onClick={() => {
                      fetchAndNotify(icon.accion, 'POST', { hash: HASH, id_reg: regSelected }, setNotificationModal, icon.solicitarConfirmacion)
                    }}
                  >
                    <RenderIcon icon={icon} />
                  </div>
                  :
                  <div className='icon-cont' key={index} style={{ padding: '3px' }} title={icon.tooltip}>
                    <RenderIcon icon={icon} />
                  </div>
              }
            </>


          )
        })
        }
      </div>
    )


  }

  useEffect(() => {
    //   setModal({...modal, show: false})
    setFiltros({
      limite: filtros.limite !== '' ? '' : null,
      pagina: 1
    });
  }, []);

  const funcionx = (modal, reg) => {
    setModal({ ...modal, show: true, data: modal, tipo: modal.tipo })
    setRegistro(reg)
  }

  const onSubmit = async (data, e) => {
    const { asesoria } = data;
    const arrayToObject = Object.assign({}, ...asesoria);
    const dataReq = {
      dataForm: arrayToObject,
      id: idTipoReporte,
      hash: HASH,
      id_reg: regSelected
    }
    //console.log(dataReq)
    const req = await validarEntorno('JSON/ReportesOperativos/fom_regs.html', 'POST', dataReq)
    if (req.ok) {
      const res = await req.json()
      setModal({ ...modal, show: false })
      setNotificationModal({ ...notificationModal, show: true, mensaje: res.mensaje, tipo: res.estado, typeModal: 'notificacion' })
      reset()
    }
  }

  /**
   * Maneja el submit del formulario desde el modal que corresponde al check
   * @param {*} data Los datos del formulario
   * @param {*} e El evento del formulario
   */

  const onSubmitToLink = async (data, e) => {
    const { asesoria } = data;
    const arrayToObject = Object.assign({}, ...asesoria);
    const dataReq = {
      dataForm: arrayToObject,
      hash: HASH,
      checked: checkedState,
      tipo_reporte: filtrosForm.tipo_reporte,
      limite: filtrosRef.current.limite,
      pagina: filtrosRef.current.pagina,
      filtros_presidencia: Object.keys(filtros_presidencia) ? filtros_presidencia : null,
    }
    console.log(dataReq)
    const req = await validarEntorno(modal.link, 'POST', dataReq)
    if (req.ok) {
      const res = await req.json()
      setModal({ ...modal, show: false })
      setNotificationModal({ ...notificationModal, show: true, mensaje: res.mensaje, tipo: res.estado, typeModal: 'notificacion' })
      reset()
    }
  }

  /**
   * Actualiza el estado del array de los checks, para saber si estan activos o no
   * @param {*} position La posicion del check
   */

  const handleCheckChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? { ...item, value: !item.value } : item
    );
    setCheckedState(updatedCheckedState);
  }

  /**
   * Actualiza el estado del array de los checks, haciendo toggle sobre todos los checks
   * Si allChecked es true, todos los checks se activan, si es false, todos se desactivan
   */

  const handleAllChecked = () => {
    const updatedCheckedState = checkedState.map((item) => {
      return { ...item, value: !allChecked }
    });
    setCheckedState(updatedCheckedState);
    setAllChecked(!allChecked)
  }


  const underlineReg = (valor_estado) => {

    const valorExists = initialState.data.resaltado.filter((valor) => {
      return valor.valor === valor_estado
    })

    if (valorExists) {

      return valorExists[0]?.color
    }

  }

  useLayoutEffect(() => {
    document.getElementById('cantidad_regs').value = '10'
  }, [])

  const renderContenidoModal = (paramModal, paramRegistro) => {

    const modalToUse = paramModal ? paramModal : modal;
    const registroToUse = paramRegistro ? paramRegistro : registro;

    return (
      <>
        <h2 className='pagesTitles pagesTitles--small' >
          {modalToUse?.data?.contenido?.titulo ? modalToUse?.data?.contenido?.titulo : modalToUse?.tipo === 'form' ? registroToUse?.valor : ''}
        </h2>
        {modalToUse.tipo === 'tabla' &&
          <div className='table_reps-container' id='tabla_reportes mt-0' style={{
            minHeight: popoverMode ? 'auto' : '361px',
            display: 'flex',
            justifyContent: 'center',
            width: '96%',
            marginBottom: '16px'
          }}>
            <div className='table_reps' style={{ width: '100%', paddingRight: modalToUse?.data?.contenido?.regs.length >= 10 ? '10px' : '0px' }}>
              {
                modalToUse?.data?.contenido?.cols?.map((col, idx) => {
                  return (
                    <div id='cols'>
                      <div className='col_item' key={idx}>
                        {col}
                      </div>
                    </div>
                  )
                })
              }
              <div id='regs'>
                {
                  modalToUse?.data?.contenido?.regs?.map((reg, idx) => {
                    return (
                      <div className='modal__list__item' key={idx}
                      // style={{borderBottom : `4px solid ${ reg.resaltado === 'verde' ? '#0AC51D' : reg.resaltado === 'amarillo' ? '#F5CF06' : '#f7f7f7' }` }}
                      >
                        {reg?.cols?.map((colreg, idx) => {
                          return (
                            <div
                              className='colreg salto__linea'
                              style={{ overflow: 'hidden' }}
                              key={idx}
                            // style={{ cursor: colreg?.modal?.show && 'pointer' }}
                            // onClick={ () => { colreg?.modal?.show ? funcionx(colreg?.modal) : console.log('no') }}
                            >
                              {linkOrTxt(colreg?.valor, '', true, colreg?.titulo)}
                            </div>
                          )
                        })
                        }

                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        }
        {modalToUse.tipo === 'form' &&
          <>

            <Form noValidated onSubmit={handleSubmit(modalToUse?.link ? onSubmitToLink : onSubmit)}
              style={{
                width: '100%',
                padding: '0',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  width: '100%',
                  margin: '0 auto',
                  padding: '0',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}

              >

                {modalToUse.data.campos.map((campo, index) => {
                  const fieldName = `asesoria.0`;

                  return (
                    <>
                      <InputType
                        item={campo}
                        index={index}
                        idx={index}
                        key={`${renderKey} - ${index}`}
                        reg={register}
                        nombre={`${fieldName}`}
                        errors={errors}
                        col={campo.col}
                        type=""
                      />
                    </>
                  )
                })
                }
              </div>
              <div style={{ display: 'flex', marginTop: '1em', justifyContent: 'center' }}>
                {/* <button type="button" className="configButton" onClick={()=> {sendConfig()}}>Guardar Configuración</button> */}
                <button
                  type="submit"
                  disabled={false}
                  style={{ margin: '20px 0 0 0' }}
                  className="confirmButton"
                >
                  Guardar
                </button>
              </div>
            </Form>
          </>
        }
        {modalToUse.tipo === '' &&
          <div className='vacio__container'>
            <h1 className='vacio__titulo'>Panel lateral</h1>
            <p className='vacio__descripcion'>
              <ul>
                <li>Al seleccionar un ID de registro se mostrará toda la información correspondiente a este (descripciones, formularios, soportes).</li>
                <li>Al hacer click sobre un enlace se mostrará la información del elemento de manera independiente.</li>
              </ul>
            </p>
          </div>
        }

      </>
    )
  }

  useEffect(() => {
    setColores(initialState?.data?.resaltado ? initialState.data.resaltado : [])
  }, [initialState?.data?.resaltado, setColores])

  return (
    <>
      {initialState.data.file && initialState.data.file !== '' &&
        <a href={initialState.data.file} target='_blank'>
          <button style={{ margin: '0 0 20px', width: '140px' }} className="confirmButton">Descargar archivo</button>
        </a>
      }
      {(initialState.data?.widgets) ? (
        <div style={{ width: '100%', marginBottom: '20px', height: 'auto' }} >
          <GeneralWidgets cards={initialState.data.widgets} seccion={initialState.data.seccion} setInitialState={setInitialState}
            idTipoReporte={idTipoReporte}
            initialState={initialState}
            modal={setModal}
            setFiltrosForm={setFiltrosForm}
            filtrosForm={filtrosForm}
            filtrosRef={filtrosRef}

          />
        </div>
      ) : ''}
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', gap: '10px' }}>
        <Card className={`shadow generalCard ${popoverMode ? 'w-70' : 'w-full'}`}>
          {initialState.loading === true && initialState.error === null
            ?
            <Loader />
            :
            <Card.Body  >
              <div id='header_tabla' className='shadow' >
                <div id='header_tabla-numero_registros'>

                  <p> {t('tabla_estudios.registros')}: </p>
                  <Form.Control
                    className='inputGroup'
                    id='cantidad_regs'
                    name='limite'
                    as='select'
                    onChange={(e) => { filterData(e) }}
                    value={filtros.limite}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                  </Form.Control>
                </div>
                <div id='header_tabla-paginado'>
                  {initialState?.data?.pag_total > 1 && initialState?.data?.pag_actual !== 1 ?
                    (
                      <div style={{ cursor: 'pointer' }} onClick={() => { paginateData(initialState?.data?.pag_ant) }}>
                        <LeftSVG />
                      </div>
                    ) : null
                  }
                  <div className='paginado__numeros'>
                    <span>Pag {initialState?.data?.pag_actual}</span>
                    <span>{t('tabla_estudios.de')} {initialState?.data?.pag_total}</span>
                  </div>

                  {initialState?.data?.pag_total !== initialState?.data?.pag_actual && (initialState?.data?.pag_total !== 0 && initialState?.data?.pag_total !== 1) ? (
                    <div style={{ cursor: 'pointer' }} onClick={() => { paginateData(initialState?.data?.pag_sig) }}>
                      <RightSVG />
                    </div>
                  ) : null}
                </div>
                <div id='header_tabla-total_registros'>
                  <p>
                    {t('tabla_estudios.total_registros')}:
                  </p>
                  <p id='total_regs'>
                    {initialState?.data?.total_regs}
                  </p>
                  <div style={{ marginLeft: "8px" }}>
                    <div className='icon__button'>
                      <button onClick={() => {
                        setPopoverMode(!popoverMode);
                        setShowAllModalsRegisterId();
                        setRegistro(null);
                        setModal({
                          show: false,
                          tipo: '',
                          data: {}
                        })
                      }} title={popoverMode ? 'Desactivar panel lateral' : 'Activar panel lateral'} className={`${popoverMode ? 'active' : ''}`}>
                        {popoverMode ? <PanelRightClose /> : <PanelRightOpen />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='table_reps-container' id='tabla_reportes'>
                <div className='table_reps'>
                  <div id='cols'>
                    {idTipoReporte !== '4' && (coloresRef?.current && coloresRef?.current?.length > 0) ? (
                      <div className='resaltados' >
                        <div className='colors_container' style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }} >
                          {coloresRef?.current?.map((item, index) => (
                            <div className='contenedor_resaltador'
                              style={{
                                backgroundColor: allCheckedSelected === item.valor ? item.color : '#f7f7f7',
                                cursor: 'pointer'
                              }}
                              onClick={() => resaltarTodos(item.valor, item.color)}
                            >
                              <Resaltado fill={allCheckedSelected === item.valor ? '#f7f7f7' : item.color} />
                            </div>
                          ))}
                        </div>
                      </div>) : null
                    }
                    {initialState?.data?.check &&
                      <div className='col_item col_check'>
                        <input type='checkbox' checked={allChecked} onChange={handleAllChecked} style={{ marginRight: '5px' }} />
                        <span onClick={() => setModal({ ...modal, show: true, data: initialState?.data?.check?.modal, tipo: initialState?.data?.check?.modal?.tipo, link: initialState?.data?.check?.link })}>
                          {initialState?.data?.check?.nombre}
                        </span>
                      </div>
                    }
                    {
                      initialState?.data?.cols?.map((col, idx) => {
                        return (
                          <div className='col_item col_tabla' key={idx}>
                            {col}
                          </div>
                        )
                      })
                    }
                  </div>
                  <div id='regs'>
                    {
                      initialState?.data?.regs?.map((reg, idx) => {
                        return (
                          <div className='shadow reg_item' key={idx}
                            // style={{ borderBottom: `4px solid ${reg.resaltado === 'verde' ? '#0AC51D' : reg.resaltado === 'amarillo' ? '#F5CF06' : '#f7f7f7'}` }}
                            style={{ borderBottom: `3px solid ${reg.valor_estado ? underlineReg(reg.valor_estado) : ''}` }}
                            onMouseOver={() => { if (!popoverMode) { setRegSelected(reg.id) } }}
                          >
                            {idTipoReporte !== '4' && (coloresRef?.current && coloresRef?.current?.length > 0) ? (
                              <div className='col_item '>
                                <div className='colors_container' style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }} >

                                  {(reg?.resaltado_mostrar === true && coloresRef?.current?.length > 0) ? coloresRef?.current?.map((item, index) => (
                                    <div className='contenedor_resaltador'
                                      style={{
                                        backgroundColor: reg.valor_estado === item.valor ? item.color : '#f7f7f7',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => resaltarMarker(item.valor, item.color, reg.id, idx)}
                                    >
                                      <Resaltado fill={reg.valor_estado === item.valor ? '#f7f7f7' : item.color} />
                                    </div>
                                  ))
                                    :
                                    <div className='contenedor_resaltador'
                                      style={{
                                        backgroundColor: '#fff',
                                        cursor: 'pointer',
                                        width: '155px'
                                      }}
                                    >

                                    </div>
                                  }
                                </div>
                              </div>) : null
                            }
                            {initialState?.data?.check &&
                              <div className='colreg_cont colreg_check'>
                                <label class="container_check">
                                  <input
                                    className='check_item'
                                    type='checkbox'
                                    id={`check-${idx}`}
                                    checked={checkedState[idx] && checkedState[idx].value}
                                    onChange={() => handleCheckChange(idx)}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            }
                            {reg?.cols?.map((colreg, idx) => {
                              return (
                                <div className='colreg_cont'>
                                  <div className='label_col' >{initialState?.data?.cols[idx]}</div>
                                  <div
                                    className={`colreg ${colreg?.modal?.show && 'link_modal'} ${registro === colreg && popoverMode && 'colreg--selected'} `}
                                    key={idx}
                                    style={{ cursor: colreg?.modal?.show && 'pointer', textDecoration: colreg?.modal?.show && 'underline' }}

                                    onClick={() => {
                                      if (colreg?.modal && colreg?.modal?.show) {
                                        funcionx(colreg?.modal, colreg);
                                        if (popoverMode) {
                                          setShowAllModalsRegisterId(null)
                                          setRenderKey(prevKey => prevKey + 1);
                                          setRegSelected(reg.id)
                                        }
                                      }
                                    }}
                                  >
                                    {colreg?.icono && renderIcon(colreg?.icono)}
                                    {colreg?.tiempos &&
                                      <div style={{ minWidth: 200, textAlign: 'center', zIndex: '1' }}>
                                        {colreg?.tiempos.diaActual > colreg?.tiempos.diasTotales
                                          ?
                                          <ProgressBarComplete dataTiempos={colreg?.tiempos} />
                                          :
                                          <ProgressBar dataTiempos={colreg?.tiempos} />
                                        }
                                      </div>
                                    }
                                    {!(colreg?.icono || colreg?.tiempos) && colreg?.valor !== '' && <span
                                      onMouseEnter={() => {
                                        if (validateLink(colreg.valor, colreg.link) && popoverMode) {
                                          setRegSelected(reg.id)
                                          setRenderKey(prevKey => prevKey + 1);
                                          setShowAllModalsRegisterId(reg);
                                          setRegistro(colreg.valor)
                                        }
                                      }}>
                                      {linkOrTxt(colreg.valor, colreg.link, false, colreg?.titulo)}
                                    </span>}
                                  </div>
                                </div>
                              )
                            })
                            }

                          </div>
                        )
                      })
                    }

                  </div>
                </div>
              </div>
            </Card.Body>
          }
        </Card>

        {popoverMode &&
          <div style={{ width: '30%', paddingBottom: "24px" }}>
            <Card
              className='shadow generalCard'
              style={{
                width: '100%', paddingTop: "24px", paddingBottom: "24px", position: "sticky", top: 80, maxHeight: "90vh", overflowY: "scroll", overflowX: "hidden",
                flexGrow: 1
              }}>
              {showAllModalsRegisterId ?
                <>
                  {showAllModalsRegisterId?.cols?.map((colreg, idx) => {
                    return (
                      <>
                        {colreg?.modal && colreg?.modal?.show &&
                          <>{renderContenidoModal({
                            show: true,
                            tipo: colreg?.modal?.tipo,
                            data: colreg?.modal,
                          }, colreg)} </>
                        }
                      </>
                    )
                  })}
                </> : <>{renderContenidoModal()}</>}
            </Card>
          </div>
        }
      </div>


      {/* Modal: corresponde a la ventana emergente que se muestra al hacer click en un elemento de la tabla

      <OverlayPanel
        ref={op}
        showCloseIcon={true}
        dismissable={false}
      >
        {popoverMode &&
          <>
            {renderContenidoModal()}
          </>
        }
      </OverlayPanel>
      */}

      {!popoverMode &&
        <EditModal
          isOpen={modal.show}
          setIsOpen={setModal}
          modal={modal}>
          {renderContenidoModal()}
        </EditModal>
      }

      <NotificationModal
        typeModal={notificationModal.typeModal}
        setIsOpen={setNotificationModal}
        isOpen={notificationModal.show}
        mensaje={notificationModal.mensaje}
        modal={notificationModal}
        tipo={notificationModal.tipo}
        handleConfirm={() => { notificationModal.handleConfirm() }}
      />
    </>


  );
}

export default Tabla;
