import { Fragment, useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import InputType from '../../helpers/InputType';
import Loader from "../icons/Loader";
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import NotificationModal from '../modales/NotificationModal';
import { useParams } from "react-router-dom";
import { validarEntorno } from "../../helpers/validarHost";
import { useTranslation } from "react-i18next";
import Tabla from "../common/Tabla";
import { render } from "react-dom";
import { Dialog } from 'primereact/dialog';
import Modal from 'react-bootstrap/Modal';
import useNestedForm from "../../hooks/useNestedForm";
import { Sparkles } from "lucide-react";

/**
 * Formulario de que contiene los campos de la gestión dinamica
 * dependiendo de los tipos de campos que se envien se maquetara
 * dentro de la aplicación, ademas de hacer las tareas de envio de datos
 * de la gestión de nuevo al backend
 * @param {*} props
 * @returns
 */
const Formdinamico = (props) => {
  const [selectedPages, setSelectedPages] = useState([]);
  const { t } = useTranslation();
  const [inputs, setInputs] = useState(props.inputs ? props.inputs : {});
  const { handleSubmit, register, formState: { errors, isDirty } } = useNestedForm(); //useForm() - isDirty es necesario para el correcto funcionamiento de la validación de campos requeridos
  const [total, setTotal, totalRef] = useState(0);
  // const [sumValues, setSumValues] = useState({});
  const [indexFinancialEdit, setIndexFinancialEdit] = useState('');
  const [subIndexSubFinancialEdit, setSubIndexFinancialEdit] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [tipoModal, setTipoModal] = useState('');
  const [mensajeModal, setMensajeModal] = useState('');
  const [indexDelete, setIndexDelete] = useState('');
  const [itemDelete, setItemDelete] = useState({});
  const [camposEditar, setCamposEditar] = useState([]);
  const [modalCrear, setModalCrear] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [dataModal, setDataModal] = useState([{}]);
  const { seccion: seccionParams , id } = useParams();
  const seccion = seccionParams || props.seccionPt;
  const hash = window.location.hash;

  const [regsToPush, setRegsToPush] = useState([])
  const [banderaBoton, setBanderaBoton] = useState(false)

  useEffect(() => {
    const handlePagesSelected = (event) => {
      setSelectedPages(event.detail);
    };

    window.addEventListener('pagesSelected', handlePagesSelected);

    return () => {
      window.removeEventListener('pagesSelected', handlePagesSelected);
    };
  }, []);


  /**
   * Se valida que los campos enviados el el formulario
   * no queden vacios en caso contrario una bandera declarada al principio
   * en valor true pasara a false y no dejara enviar la gestión
   * @param {} data
   * @returns
   */
  const validateEmpty = (data) => {
    const iterator = [];

    let band = true;

    data.forEach((item) => (
      iterator.push(Object.keys(item))
    ));

    const { campos } = inputs;

    campos.forEach((element, index) => {
      if ((element.nombre === iterator[0][index]) && element.es_requerido) {
        if (data[0][iterator[0][index]] === '') {
          band = false;
        }
      }
    });

    return band;
  }

  /**
   * Funcion que envia los datos del formulario al backend
   * Se hacen validaciones tanto de campos como a nivel de contenidos
   * la primera vez mostrando un modal y la segunda ya cargando en cada campo
   * los errores mostrados de obligatoriedad
   * @param {*} dataform
   * @returns
   */

  const onSubmit = async (dataform) => {
    if (props?.inputs?.hasOwnProperty("confirmacion")) {
      setNotificationModal(true);
      setTipoModal('confirmacion');
      setDataModal(
        {
          titulo: 'Confirmación',
          mensaje: props?.inputs?.confirmacion,
          handleConfirm: () => { onSubmitConfirmation(dataform) }
        }
      );
    } else {
      onSubmitConfirmation(dataform);
    }
  };

  const onSubmitConfirmation = async (dataform) => {
    setBanderaBoton(true)
    const formattedData = formatedValues(dataform.asesoria);

    if (!validateEmpty(formattedData)) {
      setShowModalMessage(null, null, 'alerta_validacion', 'Recuerda validar todos los campos obligatorios');
      setBanderaBoton(false)
      return;
    }

    const data = {};

    data.asesoria = formattedData;


    let dataReq = {
      data,
      id,
      seccion,
      subseccion: props.idSegundoNivel,
      hash,
      paginas_seleccionadas_pdf_img: selectedPages
    }
    
    console.log(JSON.stringify(data, null, 2));

    let req = '';

    if (props.idTercerNivel) {
      dataReq.id_tercer_nivel = props.idTercerNivel;
    }

    if (props.subseccionCampo) {
      dataReq.subseccion_campo = props.subseccionCampo;
    }

    if (props?.botonIA) {
      dataReq.boton_ia = true;
    }

    if (props?.datosSolicitud) {
      dataReq = { ...dataReq, ...props.datosSolicitud }
    }

    //console.log("Solicitud de envio",dataReq);

    try {
      if (props.esMultiple) {
        req = await validarEntorno('JSON/GestionDinamica/agregar_gestion_multiple.php', 'POST', dataReq);

      } else {
        req = await validarEntorno('JSON/GestionDinamica/agregar_gestion_simple.php', 'POST', dataReq);
      }
      setModalEditar(false);

      if (req.ok) {
        const res = await req.json();
        if (res.estado === "OK") {
          //aqui deberia desactivarlo.
          props.setInputsGroup([]);

          setTimeout(() => {
            props.getEstructura();
          }, 2000);
          setShowModalMessage(null, null, 'gestion_realizada', res.mensaje);
          setBanderaBoton(false)
        } else {
          setShowModalMessage(null, null, 'gestion_fallos', res.mensaje);
          setBanderaBoton(false)
        }
        if (res?.cerrar_modal) {
          props?.handleEnviar?.(); //Cuando se indica cerrar el modal, se ejecuta la función handleEnviar del componente padre
        }
      } else {
        const res = await req.json();
        setShowModalMessage(null, null, 'gestion_fallos', res.mensaje);
        setBanderaBoton(false)
      }
    } catch (error) {
      setBanderaBoton(false)
    }
  };

  /**
   * Función para formatear los valores que vienen de la gestion,
   * En casos de valores numericos de moneda para que pasen por el
   * backend de manera correcta.
   * @param {*} arr
   * @returns
   */

  const formatedValues = (arr) => {

    const formatedData = [];

    arr.map((item, index) => {
      const keys = Object.keys(item);
      keys.map((key, idx) => {
        if (item[`${key}`]) {
          if (typeof (item[`${key}`]) === 'string' && item[`${key}`].startsWith('$')) {
            const str = item[`${key}`];
            const straux = str.replace(/\./g, '').replace('$', '').trim();
            const strtoint = parseInt(straux);
            item[`${key}`] = strtoint;
          }
        }
      })
      formatedData.push(item);
    })

    return formatedData;
  }

  /**
   * Handler para llenar los campos del formulario
   * @param {*} e
   * @param {*} index
   * @param {*} idx
   */

  const handleInputChange = (e, index, idx) => {
    const { value } = e.target;
    const list = [...props.inputs];
    const item = list[index];
    const it = item[idx];
    it.valor = value;
    item[idx] = it;
    list[index] = item;
    props.setInputsGroup(list);
  };

  /**
   * Funcion para sumar valores del campo 10
   * Se suman N campos que se envien y asi mostrar
   * un resultado en un campo tipo 11
   * @param {*} value
   * @param {*} name
   * @param {*} index
   */

  const getSumValues = (value, name, index) => {

    const items = { ...inputs };

    const campos = [...items.campos];

    const splitName = name.split('.');

    const nameInput = splitName[2];

    const { idx } = searchByName(nameInput, campos);

    const campo = campos[idx];

    campo.valor = value;

    campos[idx] = campo;

    items.campos = campos;

    setInputs(items);
  }

  /**
   * Funcion que obtiene los valores sumados
   * de N campos tipo 10 para mostrarlos en un tipo 11
   * @param {*} index
   */

  const getTotal = (index) => {

    const items = { ...inputs };

    const campos = [...items.campos];

    const sum = sumArray(campos);

    const { obj, idx } = searchByTotal(campos);

    obj.valor = parseInt(sum.valor);

    campos[idx] = obj;

    items.campos = campos;

    setInputs(items);
  }

  const confirmationStatus = (index, idx) => {
    setIndexFinancialEdit(index);
    setSubIndexFinancialEdit(idx);
    setShowModalMessage(null, null, 'edicion_estado_financiero');
  }

  /**
   * Funcion para sumar elementos de un array, es este caso
   * toma todos los campos de tipo 10 y los suma devolviendo el total
   * como resultado
   * @param {*} myArray
   * @returns
   */

  const sumArray = (myArray) => {

    const sumFilter = myArray.filter(({ tipo }) => tipo === "10");

    const sum = sumFilter.reduce((sum, record) => (
      { valor: parseInt(sum.valor) + parseInt(record.valor) }
    ));

    return sum;
  }

  const searchByName = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].nombre === nameKey) {
        return {
          idx: i
        }
      }
    }
  }

  const searchByTotal = (myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].tipo === "11") {
        return {
          obj: myArray[i],
          idx: i
        }
      }
    }
  }

  const setCloseModalMessage = () => {
    setNotificationModal(false);
    setMensajeModal('');
    setTipoModal('');
    setIndexDelete('');
    setItemDelete({});
    setIsDisabled(false);
  }

  /**
   * Funcion para eliminar una gestion hecha, se multiple o
   * simple
   * @param {} index
   * @param {*} item
   */
  const handleRemoveClick = async (index, item) => {
    if (item.campos[0].id_gestion) {

      const id_gestion = item.campos[0].id_gestion;

      let dataReq = {
        id_gestion,
        id_segundo_nivel: props.idSegundoNivel,
        seccion,
        id,
        hash
      }

      //git console.log(dataReq, "Eliminando");

      if (props.idTercerNivel) {
        dataReq.id_tercer_nivel = props.idTercerNivel;
      }

      if (props.subseccionCampo) {
        dataReq.subseccion_campo = props.subseccionCampo;
      }

      if (props?.datosSolicitud) {
        dataReq = { ...dataReq, ...props.datosSolicitud }
      }

      const req = await validarEntorno('JSON/GestionDinamica/eliminar_gestion.php', 'POST', dataReq);
      setModalEditar(false);
      if (req.ok) {
        const res = await req.json();
        setCloseModalMessage();
        setShowModalMessage(null, null, 'eliminacion_confirmada', res.mensaje);
        props.setInputsGroup([]);

        setTimeout(() => {
          props.getEstructura();
        }, 2000);


      } else {
        const res = await req.json();
        setCloseModalMessage();
        setShowModalMessage(null, null, 'gestion_fallos', res.mensaje);
      }
    } else {
      const list = [...props.inputsGroup];
      list.splice(index, 1);
      props.setInputsGroup(list);
      setCloseModalMessage();
    }
  };

  const setShowModalMessage = (index, item, tipo, mensaje) => {

    switch (tipo) {
      case 'eliminacion_confirmacion':
        setIndexDelete(index);
        setItemDelete(item);
        setNotificationModal(true);
        setMensajeModal('Esta seguro de borrar esta gestion?');
        setTipoModal('gestionEliminadoConfirmacion');
        break;
      case 'eliminacion_confirmada':
        setNotificationModal(true);
        setMensajeModal(mensaje);
        setTipoModal('successGestion');
        break;
      case 'gestion_realizada':
        setNotificationModal(true);
        setMensajeModal(mensaje);
        setTipoModal('successGestion');
        break;
      case 'gestion_fallos':
        setNotificationModal(true);
        setMensajeModal(mensaje);
        setTipoModal('falloPeticionesGestion');
        break;
      case 'edicion_estado_financiero':
        setNotificationModal(true);
        setMensajeModal(mensaje);
        setTipoModal('edicion_estado_financiero');
        break;
      case 'alerta_validacion':
        setNotificationModal(true);
        setMensajeModal(mensaje);
        setTipoModal('envio_peticion_alerta');
        break;
      default:
        break;
    }
  }

  /**
   * Función para cambiar el valor de una gestion
   * segun un criterio defino desde el backend
   * @param {} index
   * @param {*} idx
   */
  const handleButtonEstado = async (index, idx) => {
    const dataReq = {
      id,
      seccion,
      subseccion: props.idSegundoNivel,
      hash,
      id_gestion: props.inputs.campos[0].id_gestion,
      subseccion_campo: props.inputs.campos[idx].nombre
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/GestionDinamica/gestion_seccion_formulario.php', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK" ) {
        setShowModalMessage(null, null, 'gestion_realizada', res.mensaje);
        const list = [...props.inputsGroup];
        const item = { ...list[index] };
        const campos = [...item.campos];
        const it = { ...campos[idx] };
        it.valor = !it.valor;
        campos[idx] = it;
        item.campos = campos;
        list[index] = item;
        props.setInputsGroup(list);
      } else if (res.estado === "ERROR") {
        setShowModalMessage(null, null, 'gestion_fallos', res.mensaje);
      }
    }
  }

  const editar = async (rowData) => {
    let dataReq = {
      id,
      seccion,
      id_segundo_nivel: props.idSegundoNivel,
      hash,
      id_gestion: rowData?.id_gestion
    }

    if (props?.datosSolicitud) {
      dataReq = { ...dataReq, ...props.datosSolicitud }
    }

    if (props?.idTercerNivel) {
      dataReq.id_tercer_nivel = props.idTercerNivel;
    }

    if (props?.subseccionCampo) {
      dataReq.subseccion_campo = props.subseccionCampo;
    }



    //console.log(dataReq)
    const req = await validarEntorno('JSON/GestionDinamica/gestion_datos_formulario.php', 'POST', dataReq);
    if (req.ok) {
      const res = await req.json();
      setCamposEditar(res);
    }
    setModalEditar(true);
  }

  const crear = () => { setModalCrear(true); }

  const renderForm = () => {
    return (
      props.inputs.campos[0].id_gestion !== "no_aplica" &&
      <Form onSubmit={handleSubmit(onSubmit)}
        style={{
          margin: '0 auto',
          padding: '0'
        }}
      >
        <div className='dinamicInput rounded-xl'>
          <Col xs={12} style={{ padding: '0' }}>
            {props.esMultiple ? (
              <>
                <p className="tituloRegDin" style={{ paddingLeft: '2%' }}>{props.index === props.len - 1 ? (`${t('formulario.label.nuevo')} ${props.nombreForm}`) : (`${props.nombreForm} ${props.index + 1}`)}</p>
                <hr style={{ color: 'gray' }} />
              </>
            ) : null}
          </Col>
          {(props.inputs.campos) ? (
            <input type={"hidden"} ref={register} name={`${props.fieldName}.id_gestion`} value={(props.inputs.campos[0].id_gestion && props.inputs.campos[0].id_gestion !== "") ? props.inputs.campos[0].id_gestion : ""} />
          ) : null}
          {(props.inputs['campos']) ? (props.inputs.campos.map((campo, cdx) => {
            return (
              <Fragment key={cdx}>
                <>
                  {/* Componente que carga los inputs que necesite el formulario  */}
                  <InputType
                    item={campo}
                    index={props.index}
                    lastIndex={props.len}
                    idGestion={props.inputs.campos[0].id_gestion}
                    idx={cdx}
                    handleInputChange={handleInputChange}
                    key={`${props.index} - ${cdx}`}
                    reg={register}
                    nombre={props.fieldName}
                    errors={errors}
                    getSumValues={getSumValues}
                    getTotal={getTotal}
                    total={totalRef}
                    setTotal={setTotal}
                    handleButtonEstado={confirmationStatus}
                    esEditable={props.inputs.es_editable}
                    esMultiple={props.esMultiple}
                    col={campo.col}
                    idSegundoNivel={props.idSegundoNivel}
                  />
                </>
              </Fragment>
            )
          })) : <Loader />}
          <br />
          <br />
          <br />
          <br />
          <Col xs={12}>
            {props.esEditable && !props.esMultiple ? null : (

              <div style={{ display: 'flex', justifyContent: "flex-end", gap: "16px" }}>
                {
                  props.botonEnviar === true ? <div className='buttonContainer' >
                    <Button disabled={props.isDisabled || banderaBoton} type="submit" className='confirmButton m-0' >
                      {props.botonIA ? <Sparkles size={18} color='#F6CD52' className="mr-2" /> : null}

                      {t('botones.guardar')}
                    </Button>
                  </div> : null
                }
                {props.inputs.es_eliminable && props.esMultiple ? (
                  <div className='buttonContainer'>
                    <Button type="button" className='deniedButton m-0 ml-2' onClick={() => setShowModalMessage(props.index, props.inputs, 'eliminacion_confirmacion', null)}>{t('botones.eliminar')}</Button>
                  </div>
                ) : null}
              </div>
            )}
          </Col>
          <br />
          <br />
          <br />
        </div>
      </Form>
    )
  }

  const renderEditar = () => {
    return (
      camposEditar?.campos &&
      <Form onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '95%',
          margin: '0 auto',
          padding: '0'
        }}
      >
        <div className='dinamicInput'>
          <Col xs={12} style={{ padding: '0' }}>
            {props.esMultiple ? (
              <>
                <p className="tituloRegDin" style={{ paddingLeft: '2%' }}>Editar {props.nombreForm} </p>
                <hr style={{ color: 'gray' }} />
              </>
            ) : null}
          </Col>
          {(props.inputs.campos) ? (
            <input type={"hidden"} ref={register} name={`${props.fieldName}.id_gestion`} value={camposEditar.campos[0].id_gestion} />
          ) : null}
          {(camposEditar?.campos) ? (camposEditar.campos.map((campo, cdx) => {
            return (
              <Fragment key={cdx}>
                <>
                  {/* Componente que carga los inputs que necesite el formulario  */}
                  <InputType
                    item={campo}
                    index={props.index}
                    lastIndex={props.len}
                    idGestion={camposEditar.campos[0].id_gestion}
                    idx={cdx}
                    handleInputChange={handleInputChange}
                    key={`${props.index} - ${cdx}`}
                    reg={register}
                    nombre={props.fieldName}
                    errors={errors}
                    getSumValues={getSumValues}
                    getTotal={getTotal}
                    total={totalRef}
                    setTotal={setTotal}
                    handleButtonEstado={handleButtonEstado}
                    esEditable={camposEditar.es_editable}
                    esMultiple={props.esMultiple}
                    col={campo.col}
                    idSegundoNivel={props?.idSegundoNivel}
                  />
                </>
              </Fragment>
            )
          })) : <Loader />}
          <br />
          <br />
          <br />
          <br />
          <Col xs={12}>
            {props.esEditable && !props.esMultiple ? null : (
              <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                {
                  props.botonEnviar === true ? <div className='buttonContainer' >
                    <Button disabled={camposEditar.es_editable || banderaBoton} type="submit" className='confirmButton  m-0' >
                      {t('botones.guardar')}
                    </Button>
                  </div> : null
                }
              </div>
            )}
          </Col>
        </div>
      </Form>
    )
  }

  //Componente del formulario
  return (
    <>
      {props?.tabla ? (

        <>
          <Dialog
            visible={modalEditar}
            style={{ width: '50vw', boxShadow: 'none' }}
            onHide={() => setModalEditar(false)}
            contentStyle={{ backgroundColor: '#f8f8f8', borderRadius: '0 0 30px 30px' }}
            headerStyle={{ backgroundColor: '#f8f8f8', borderRadius: '30px 30px 0 0' }}
            blockScroll>
            {renderEditar()}
          </Dialog>

          <Dialog
            visible={modalCrear}
            style={{ width: '50vw', boxShadow: 'none' }}
            onHide={() => setModalCrear(false)}
            contentStyle={{ backgroundColor: '#f8f8f8', borderRadius: '0 0 30px 30px' }}
            headerStyle={{ backgroundColor: '#f8f8f8', borderRadius: '30px 30px 0 0' }}
            blockScroll>
            {renderForm()}
          </Dialog>

          <Tabla
            columnas={props.tabla.cols}
            filas={props.tabla.regs}
            handlePaginacion={props.handlePaginacion}
            paginaActual={props.tabla.pag_actual}
            numeroPaginas={props.tabla.pag_total}
            numeroRegistrosTotal={props.tabla.total_regs}
            numeroRegistrosPorPagina={props.tabla.limite}
            handleSearch={props.handlePaginacion}
            handleCrear={crear}
            handleEditar={editar}
            botonCrearVisible={props?.tabla?.es_agregable}
            botonEditarVisible={!props?.tabla?.es_editable}
            botonEliminarVisible={props?.tabla?.es_eliminable}
            handleEliminar={(rowData) => { setShowModalMessage(props.index, { campos: [{ id_gestion: rowData?.id_gestion }] }, 'eliminacion_confirmacion', null) }}
            botonCrearDisabled={false}>
          </Tabla>
        </>
      ) : (
        //Si no hay una tabla se utiliza el comportamiento normal del formulario
        (props.inputs && Object.keys(props.inputs).length > 0) ? renderForm() : <Loader />
      )}
      <NotificationModal
        typeModal={tipoModal}
        setIsOpen={setCloseModalMessage}
        isOpen={notificationModal}
        mensaje={mensajeModal}
        handleEliminar={handleRemoveClick}
        index={indexDelete}
        item={itemDelete}
        indexFinancialEdit={indexFinancialEdit}
        subIndexSubFinancialEdit={subIndexSubFinancialEdit}
        handleButtonEstado={handleButtonEstado}
        modal={dataModal}
        handleConfirm={() => { dataModal.handleConfirm() }}
      />

    </>
  )
}

export default Formdinamico;
