import React, { createContext, useState, useContext } from 'react';

// Creamos el contexto
const PagesContext = createContext();

// Proveedor del contexto
export const PagesProvider = ({ children }) => {
  const [selectedPages, setSelectedPages] = useState([]);

  return (
    <PagesContext.Provider value={{ selectedPages, setSelectedPages }}>
      {children}
    </PagesContext.Provider>
  );
};

// Hook para consumir el contexto
export const usePagesContext = () => {
  return useContext(PagesContext);
};
