import React, { Fragment, useEffect, useState } from 'react'
import PopUpModal from '../../helpers/PopUpModal'
import { HelpSVG } from '../icons/HelpSVG'
import '../../pages/styles/Inteligencia.scss'
import { Filtros } from './componentsSolicitar/Filtros'
import { ContenidoForms } from './componentsSolicitar/ContenidoForms'
import { GestorPanoramaRiesgos } from './componentsSolicitar/GestorPanoramaRiesgos'
import { useTranslation } from 'react-i18next'



export const Solicitar = () => {

  const [open, setOpen] = useState(false)

  const [titulo, setTitulo] = useState('');
  const { t } = useTranslation();


  return (
    <main>
      <Fragment>
        <div className='titleBox' >
          {/* <h3 className="mt-5" style={{ color: '#213265', fontWeight: '800' }}>Solicitudes de estudio____</h3> */}
          <div className='titleContainer'>
            <h3 className='pagesTitles'>{t('panorama_riesgos.solicitudes')}</h3>
          </div>
          <div onClick={() => setOpen(true)}>
            <HelpSVG />
          </div>
        </div>


        <PopUpModal open={open} setOpen={setOpen} titulo='ejemplo modal' texto='Ejemplo texto modal Enim laboris officia adipisicing sint veniam labore exercitation est minim nisi.' />

        <br />

        {/* <Filtros setTitulo={setTitulo} /> */}

        <GestorPanoramaRiesgos titulo={titulo} />

      </Fragment>


    </main>
  )
}

