import React from 'react'

import {  Text, View, StyleSheet } from '@react-pdf/renderer';

function Tabla({data}) {

  // console.log(data)

  const styles = StyleSheet.create({

    tabla: {
      borderRadius: '6pt',
      overflow: 'hidden',
      display: 'flex',
      height: 'auto',

      fontSize: '12pt'

    },
    columnas: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      backgroundColor: '#4471C4',
      color: 'white',
      fontSize: '10pt',
      padding: '5pt 0'

    },
    filas: {
      backgroundColor:'white'
      
    },
    lista_item:{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width:'100%',
      padding: '2pt 1pt'
    },

    lista_item_label: {
      width: '50%',
      fontSize: '9pt',
      textAlign:'center'
    }

  })

  const iterarObj = (obj) => {

    const keyss = Object.values(obj)

    console.log(JSON.stringify(keyss))

    const a = 
    <View> 
      <Text style={{ fontSize: '10pt'     }} >{keyss[0]}</Text>
      <Text style={{ fontSize: '10pt'     }} >{keyss[1]}</Text>  
    </View>

    return a;

    

    // for (let clave in obj){
    //   // console.log(obj[clave]);
    //   return (<Text>{obj[clave]}</Text>)
    // }

    // keyss.map((label) => {
    //   return (
    //     <Text>{label}</Text>
    //   )
    // })

    // for (let i=0; i < keyss?.length; i++) {
    //   // let property = keyss[i];
    //   // console.log(property  )
    //   return <Text style={{ fontSize: '10pt'     }} >{keyss[i]}</Text>
    // }
  }

  return (
    <View style={styles.tabla} >
      <View style={styles.columnas} >
        {data?.cols?.map((col, index) => {
          return(
            <Text key={index}>{col}</Text>
          )
        })}            
      </View>
      <View style={styles.filas} >
      {data?.regs?.map((reg, index) => {
          // console.log(reg);
          return(
            <View style={styles.lista_item} key={index} >
              <Text style={[styles.lista_item_label, {color: '#4471C4'}]} >{reg.nombre}</Text>
              <Text style={[styles.lista_item_label, {
                color: 
                (reg.rango > 0 && reg.rango <= 2 ) 
                  ? '#0070C0'
                  : (reg.rango > 2 && reg.rango <= 4 )
                    ? '#00B050'
                    : (reg.rango > 4 && reg.rango <= 6 )
                      ? '#EED70B'
                      : (reg.rango > 6 && reg.rango <= 12 )
                        ? '#F58617'
                        : (reg.rango > 12 && reg.rango <= 25 )
                          ? '#FF0000'
                          : 'black'

                          
              }]} 
              >
                {(reg.rango > 0 && reg.rango <= 2 ) 
                  ? 'Improbable'
                  : (reg.rango > 2 && reg.rango <= 4 )
                    ? 'Remoto'
                    : (reg.rango > 4 && reg.rango <= 6 )
                      ? 'Probable'
                      : (reg.rango > 6 && reg.rango <= 12 )
                        ? 'Latente'
                        : (reg.rango > 12 && reg.rango <= 25 )
                          ? 'Evidente'
                          : 'black'}
              </Text>

              {/* {
                {for(label in reg){

                }}
              } */}
              {/* {
                reg.map((label, index) => {
                  return <Text key={index} >{label}</Text>
                })
              } */}
              {/* {iterarObj(reg)} */}
            </View>
          )
        })}
      </View>
    </View>
  )
}

export {Tabla} 
