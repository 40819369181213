import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Card, Col, Container, Nav, Row, Accordion } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { validarEntorno } from '../../../helpers/validarHost';
import Loader from '../../icons/Loader';
import GetAppIcon from '@material-ui/icons/GetApp';

// import GestionTabs from '../Tabs/GestionTabs';
// import Alcance from './forms/introduccion/Alcance';
// import DatosGenerales from './forms/introduccion/DatosGenerales';
// import Definiciones from './forms/introduccion/Definiciones';
// import Introduccion from './forms/introduccion/Introduccion';
// import Objetivos from './forms/introduccion/Objetivos';
// import Conclusiones from './forms/Conclusiones/Conclusion';
// import Recomendaciones from './forms/Conclusiones/Recomendaciones';
// import Bibliografia from './forms/Conclusiones/Bibliografia';
import './gestionAuditorias.scss';
import '../../../root.scss';
import ContainerQuestion from '../Questions/ContainerQuestion';
import { useEventBus } from '../../../context/EventBus';
import { NotepadTextDashed } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const GestionAuditorias = ({ type, dataGestion }) => {
    const eventBus = useEventBus();
    const { t } = useTranslation();
    const [eventId, setEventId] = useState(1);
    const [sistemaOpen, setSistemaOpen] = useState('0')
    const [areaOpen, setAreaOpen] = useState('')
    const { id } = useParams()
    const [activeKey, setActiveKey] = useState(1);
    const [sistemaSegList, setSistemaSegList] = useState([])
    const [areasList, setAreasList] = useState({})
    const [tabId, setTabId] = useState(0);
    const [tipo, setTipo] = useState(2);
    const [compSelected, setCompSelected] = useState({})
    const [loading, setLoading] = useState(true)
    const [filtrosEnviar, setFiltrosEnviar] = useState({})
    const [urls, setUrls] = useState({
        urlEstructura: '',
        urlEnvio: ''
    })

    const Items = async (filtros) => {

        setLoading(true)

        const dataSend = {
            id_cues: eventId,
            hash: window.location.hash
        };

        if (filtros) {
            dataSend.filtros = filtros;
        }

        const request = await validarEntorno('JSON/gestor_auditorias_json.html', 'POST', dataSend);

        if (request.ok) {
            const respuesta = await request.json()

            //console.log(respuesta)

            setAreasList(respuesta.data)

            setSistemaSegList(respuesta.subsistemas);
            setSistemaOpen(respuesta.subsistemas[0]?.id)
            setCompSelected(respuesta?.subsistemas[0]?.componentes[0])
            setFiltrosEnviar(filtros)
            setTabId(respuesta?.subsistemas[0]?.componentes[0]?.id_com)

            setAreaOpen(respuesta.data[0].id)
            setUrls({
                urlEstructura: respuesta.url_estructura,
                urlEnvio: respuesta.url_envio
            })

            // //console.log(areaOpen)
            // //console.log(sistemaOpen)
            // //console.log(respuesta.subsistemas[0].id)
            // changeId(respuesta.subsistemas[0].componentes[0])
        }
        setLoading(false)
    }

    useEffect(() => {
        Items();
        //console.log(compSelected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const eventIdValue = async (value) => {

        //console.log(value)


        if (areaOpen !== value) {


            setAreaOpen(value)
            //console.log(areaOpen)   
            // setTabId( value);
            // //console.log(tabId)

            const dataSend = {
                id_cues: value,
                hash: window.location.hash,
                id: id
            };

            
            if (filtrosEnviar) {
                dataSend.filtros = filtrosEnviar;
            }

            try {
                const request = await validarEntorno('JSON/gestor_auditorias_registro_json.html', 'POST', dataSend);

                if (request.ok) {
                    const response = await request.json()
                    setSistemaSegList(response.data)
                    setSistemaOpen(response.data[0].id)
                    changeId(response?.data[0]?.componentes[0])
                    // setCompSelected(response?.subsistemas[0]?.componentes[0])
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    const changeId = (item) => {

        setTabId(item?.id_com)
        // setTipo(type);
        setCompSelected(item)
        setActiveKey(item?.id_com)

    }

    const cambiarEventId = (id) => {
        setTabId(id)
    }

    useEffect(() => {
        const handleCallback = (filtros) => {
            Items(filtros);
        }
        eventBus?.subscribe('FILTROS_ACTUALIZADOS', handleCallback);
        return () => {
            eventBus?.unsubscribe('FILTROS_ACTUALIZADOS', handleCallback);
        };
    }, [eventBus]);

    return (
        <Fragment>
            <Card className='card__box__surface p-2'>
                <Card.Body>
                    <div className=' bodyWidgets custom__scrollbar '>
                        {areasList.length > 0 && areasList.map((item, index) => {
                            return (
                                <div
                                    style={{ borderBottom: areaOpen === item.id ? '8px solid #E4A731' : '8px solid #213265' }}
                                    className='shadow widgetOp '
                                    key={item.id}
                                    onClick={() => eventIdValue(item.id)}
                                    eventKey={item.id}
                                >

                                    <div className='widgetOp__title'>
                                        <p>{item.value}</p>
                                        <p className='widgetPorcentaje'>{item.porcentaje}</p>
                                    </div>
                                    {item.descarga && item.descarga.show &&
                                        <div className='buttonDescargaPDF' >
                                            <a
                                                href={item.descarga.url}
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                <button className='buttonWidget'>
                                                    <GetAppIcon style={{ color: 'white', fontSize: '15px' }} />
                                                </button>
                                            </a>
                                        </div>
                                    }
                                </div>
                            )
                        })
                        }
                    </div>

                    <Container className="audit-container mt-3" fluid>
                        {loading ? <Loader /> :
                            sistemaSegList.length > 0 ?
                                <Row id='rowAreasDiagnostico'>
                                    <Col id="sidebar-wrapper">
                                        <Nav className=" columnaComponentes scrollSecundario card__box__surface shadow-none flex-column nav-items w-100">
                                            <Accordion
                                                className='d-flex flex-column'
                                                style={{ gap: '4px', padding: '16px 12px' }}
                                                activeKey={sistemaOpen}
                                            >
                                                {sistemaSegList?.map((sistema) => {
                                                    return (
                                                        <>
                                                            <Card eventKey={sistema.id} key={sistema.id}
                                                                style={{
                                                                    width: '100%',
                                                                    margin: '0 auto'
                                                                }}
                                                            >
                                                                <Accordion.Toggle
                                                                    style={{ backgroundColor: sistemaOpen === sistema.id ? '#213265' : 'var(--gray-200)', color: sistemaOpen === sistema.id ? 'white' : '#213265' }}
                                                                    className='sistemaSegItemToggle'
                                                                    eventKey={sistema.id}
                                                                    onClick={() => setSistemaOpen(sistema.id)}
                                                                >
                                                                    <p style={{ textAlign: 'start' }}>
                                                                        {sistema.value}
                                                                    </p>
                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse
                                                                    className='sistemaSegItemCollapse'
                                                                    eventKey={sistema.id}
                                                                >
                                                                    <>
                                                                        {sistema?.componentes?.map((com) => {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        key={com.id}
                                                                                        className='componenteItem'
                                                                                        onClick={() => changeId(com)}
                                                                                        style={{
                                                                                            backgroundColor: activeKey?.toString() === com.id_com ? '#E4A731' : '#CECECE'
                                                                                        }}
                                                                                    >
                                                                                        {com.nombre}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}

                                                                    </>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </>
                                                    )
                                                })}
                                            </Accordion>

                                        </Nav>
                                    </Col>
                                    <Col id="page-content-wrapper">
                                        <ContainerQuestion
                                            tabId={tabId}
                                            tipo={tipo}
                                            ele={compSelected}
                                            filtros={filtrosEnviar}
                                            urlEstructura={urls.urlEstructura}
                                            urlEnvio={urls.urlEnvio}
                                        />
                                    </Col>
                                </Row>
                                : <div className='empty__card'>
                                    <div className="icon">
                                        <NotepadTextDashed size='48' />
                                    </div>
                                    <span>{t('formulario.label.no_registros')}</span>
                                </div>
                        }


                    </Container>
                </Card.Body>
            </Card>
        </Fragment>
    );
}

export default GestionAuditorias;