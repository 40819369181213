import React, {useState} from 'react'
import reactDOM from 'react-dom'
import { Form } from 'react-bootstrap'

import CloseIcon from '@material-ui/icons/Close';

import './ModalAgregarNota.scss'


export default function ModalAgregarNota(props) {

  const [validaNota, setValidaNota] = useState(false);
  // //console.log(validaNota)

  const [nota, setNota] = useState({
    checkCliente: false,
    asuntoNota:'',
    nota: '',
  })

  const handleChangeNota = ({target}) => {
    setNota({
      ...nota,
      [target.name] : target.value
    })
    // //console.log(target.value);
    // //console.log(nota);
  }

  const handleAddNotaSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget
    //si hay errores en form
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    // //console.log(nota);
    setValidaNota(true)
  }

  if(!props.isOpen){
    return null;
  };



  return reactDOM.createPortal (
    <div className='agregarNotaMmodalContainer'>
      <div className='agregarNotaCard'>
        <div className='closeButtonContainer'>
          <CloseIcon id='closeIconNota' onClick={() => {props.setIsOpen(false)}}/>
        </div>
        <div>
          <h4 className='pagesTitles'>Agregar Nota</h4>
        </div>
        <br/>

        <Form noValidate className='formAddNotas' onSubmit={handleAddNotaSubmit}>
        <div id='checkboxNotaContainer'>
            <Form.Label>Seleccione esta casilla si es cliente</Form.Label>
            <Form.Check name='checkCliente' 
              onChange={handleChangeNota}
              value={nota.checkCliente.value}
            />
        </div>
          <div className='asuntoNotaContainer'>
            <Form.Label> Asunto*</Form.Label>
            <Form.Control name='asuntoNota' id='asuntoNotaInput'  required
              onChange={handleChangeNota}
              value={nota.asuntoNota.value}
            ></Form.Control>
            <Form.Control.Feedback type='invalid'> Ingrese el asunto de la nota </Form.Control.Feedback>
          </div>
          <br/>
          <div>
            <Form.Group className='textAreaNotaContainer'>
              <Form.Label>Texto*</Form.Label>
              <Form.Control name='nota' id='textAreaNotas' required as='textarea'
                onChange={handleChangeNota}
                value={nota.nota.value}
              ></Form.Control>
            </Form.Group>
          </div>
          <br/>
          <div>
            <button className='buttonNota' id='enviarNota' type='submit'>Enviar</button>
            <button className='buttonNota' id='CancelarAddNota' onClick={() => props.setIsOpen(false)}>Cancelar</button>
          </div>
        </Form>
      </div>
    </div>,
    document.getElementById('AgregarNotaModal')
  )
}
