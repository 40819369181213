import { useState, useEffect } from "react";
import Map from '../../helpers/Mapas/Investigaciones/Heatmap';
import { credenciales } from '../../helpers/credentials.google';
import Loader from '../../components/icons/Loader';
import { Button, Col, Form, Row } from "react-bootstrap";
import OpcionesBuscador from "../../helpers/OpcionesBuscadorEditar";
import { municipios } from '../../helpers/municipiosPanorama';
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import { validarEntorno } from '../../helpers/validarHost';
import NotificationModal from '../modales/NotificationModal';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

/**
 * Componente de formulario mapa,
 * ejecuta la accion sobre el mismo para apuntar una ubicación
 * y retorna las coordenadas para su posterior ingreso en el sistema
 * @param {*} param0
 * @returns
 */
const Mapa = ({ idSegundoNivel, item, getEstructura, cleanEstructure }) => {

  // console.log(item)

  const [nuevoZoom, setNuevoZoom] = useState('')

  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();
  const [regSelected, setRegSelected] = useState('')

  const [modal, setModal] = useState({
    tipo: '',
    show: false,
    data: {}
  })
  const [municipio, setMunicipio] = useState(item.ciudad !== '' ? item.ciudad : '');
  const [municipioSelected, setMunicipioSelected] = useState(item.ciudad !== '' ? item.ciudad : '');
  const [editarMapa, setEditarMapa] = useState(false);
  const [coordsMunicipio, setCoordsMunicipio] = useState({
    lat: '',
    lng: '',
  });
  const [validated, setValidated] = useState(false);

  const [punto, setPunto] = useState({
    lat: item.valores.lat ? item.valores.lat : "",
    lng: item.valores.lng ? item.valores.lng : ""
  });

  const handleChangeNewPunto = (e) => {

    let re = new RegExp(/[0-9]$/g);
    let valor = re.test(e.target.value)

    if (valor) {
      setNewPunto({
        ...newPunto,
        [e.target.name]: e.target.value
      })
    }

    // console.log(newPunto)
  }

  const handleChangeSigno = (e, target) => {
    setNewPunto({
      ...newPunto,
      [target]: e.value
    })
  }

  const [newPunto, setNewPunto] = useState({
    unidad_lat: "",
    decimal_lat: "",
    unidad_lng: "",
    decimal_lng: "",
    signo_lat: "+",
    signo_lng: "-"
  });

  // const [imgPrev, setImgPrev] = useState('');

  const [zoom, setZoom] = useState((item.valores.lat !== '' && item.valores.lng !== '') ? 15 : 15);
  const [descripcion, setDescripcion] = useState(item.descripcion);
  const { id } = useParams();
  const HASH = window.location.hash;

  const [notificationModal, setNotificationModal] = useState({
    tipo: '',
    message: '',
    show: false
  });

  const setCloseModalMessage = () => {
    setNotificationModal({
      tipo: '',
      message: '',
      show: false
    })
  }

  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;

  /**
   * Función para obtener los datos del punto seleccionado,
   * en funcion de su lugar de origen (municipio o ciudad)
   * @param {*} e
   */
  const chargePointMun = (e) => {
    if (e) {
      showMunicipio(e.value);
      setZoom(10);
      setMunicipio(e.value);
      setMunicipioSelected(e);
    } else {
      setMunicipio('');
      showMunicipio('');
      setPunto({
        lat: "",
        lng: ""
      })
      setZoom(5);
    }
    setEditarMapa(true)
  }

  /**
   * Controla el cambio manual de ciudad/municipio
   * @param {*} e Evento
   */

  const handleChangeMunicipio = (e) => {
    setMunicipio(e.target.value);
    setMunicipioSelected({
      value: e.target.value,
      label: e.target.value
    });
  }

  /**
   * Función para obtener las coordenadas del punto
   * seleccionado
   * @param {*} value
   */
  const showMunicipio = (value) => {
    let geocoder;
    if (value !== '') {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line no-undef
      geocoder = new google.maps.Geocoder();

      geocoder.geocode({
        'address': value
      }, function (results, status) {
        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line no-undef
        if (status === google.maps.GeocoderStatus.OK) {

          let Lat = results[0].geometry.location.lat();
          let Lng = results[0].geometry.location.lng();

          setPunto({
            ...punto,
            lat: Lat,
            lng: Lng
          });
        } else {
          console.log("Something got wrong " + status);
        }
      })
    }
  }

  /**
   * Funcion para cargar los datos del mapa al formulario de envio
   * Esto convirtiendo la imagen generada en un base64 y enviado la
   * petición al backend
   * @param {*} e
   */
  const onSubmitMap = async (e) => {
    e.preventDefault();

    if (municipio !== '' || (punto.lat !== '' && punto.lng !== '')) {
      const dataImage = await html2canvas(document.querySelector('#mapa-inv'), {
        allowTaint: false,
        useOverflow: false,
        useCORS: true,
        width: 400,
        height: 400
      })
        .then((canvas) => {
          // setImgPrev(canvas.toDataURL());
          return canvas.toDataURL();
        }).catch((error) => console.error('No se ha podido capturar la imagen'));

      const dataReq = {
        id,
        id_segundo_nivel: idSegundoNivel,
        hash: HASH,
        data: {
          municipio,
          punto,
          descripcion,
          imagen: dataImage,
          nuevo_punto: newPunto
        }
      }

      //console.log(dataReq)

      const req = await validarEntorno('JSON/GestionDinamica/enviar_mapa.html', 'POST', dataReq);

      if (req.ok) {
        const res = await req.json();

        if (res.estado === 'OK') {
          setNotificationModal(
            {
              ...notificationModal,
              tipo: 'envio_peticion_correcto',
              message: res.mensaje,
              show: true
            }
          );
          setModal({ ...modal, show: false })
          cleanEstructure()
          getEstructura()
        } else {
          setNotificationModal(
            {
              ...notificationModal,
              tipo: 'envio_peticion_erroneo',
              message: res.mensaje,
              show: true
            }
          );
        }
      } else {
        setNotificationModal(
          {
            ...notificationModal,
            tipo: 'envio_peticion_erroneo',
            message: 'Ha ocurrido un error, intente nuevamente',
            show: true
          }
        );
      }
    } else {
      setNotificationModal(
        {
          ...notificationModal,
          tipo: 'envio_peticion_alerta',
          message: 'Diligencia todos los campos',
          show: true
        }
      );
    }

  }

  const onSubmit = async (data, e) => {

    setValidated(true)

    const { asesoria } = data;

    const arrayToObject = Object.assign({}, ...asesoria);


    const dataReq = {
      dataForm: arrayToObject,
      // id: idTipoReporte,
      hash: HASH,
      id_reg: regSelected

    }

    // console.log(dataReq)

    setModal({ ...modal, show: false })
  }

  useEffect(() => {
    setCoordsMunicipio({
      ...coordsMunicipio,
      lat: item.valores.lat ? item.valores.lat : '',
      lng: item.valores.lng ? item.valores.lng : ''
    })
  }, [])

  const getZoom = (number) => {

    console.log(number)

    // setNuevoZoom()

  }


  /**
   * Construcción del componente
   */
  return (
    <>
      <Form onSubmit={onSubmitMap}>
        <Row>
          <Col xs={6}>
            <div className="containerOcs inv">
              <div id='mapa-inv' className="mapa-invt">
                {!editarMapa && item?.imagen ? (
                  <img src={item?.imagen} alt="mapa guardado" style={{ height: '100%', maxHeight: 400, borderRadius: '20px' }}
                    onClick={() => {
                      setEditarMapa(true)
                    }}
                  >
                  </img>
                )
                  : (
                    <Map
                      googleMapURL={mapURL}
                      containerElement={<div style={{ height: '100%', maxHeight: 400 }}></div>}
                      mapElement={<div className="shadow" style={{ height: '100%', maxHeight: 400, borderRadius: '20px' }}></div>}
                      loadingElement={<Loader />}
                      marker={punto}
                      setPunto={setPunto}
                      zoom={zoom}
                      descripcion={descripcion}
                      setDescripcion={setDescripcion}
                      getZoom={getZoom}
                    />
                  )}
              </div>
            </div>
          </Col>
          <Col xs={6} >
            <Col xs={12} style={{ marginTop: '20px', marginBottom: '20px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              {modal.show === false &&
                <Button
                  type="submit"
                  style={{ margin: '0' }}
                  className='confirmButton'
                  onClick={() => {
                    setModal({
                      ...modal,
                      show: !modal.show
                    })
                    setEditarMapa(true)
                  }
                  }
                >
                  Editar
                </Button>
              }
              {modal.show === true &&
                <Button
                  className='warningButton'
                  style={{ margin: '0 0 0 8px' }}
                  onClick={() => setModal({
                    ...modal,
                    show: !modal.show
                  })}
                >
                  Cancelar
                </Button>
              }
            </Col>
            {modal.show === false
              ?
              <>
                <Col xs={12}>
                  <Form.Label>Ciudad/Municipio</Form.Label>
                  <OpcionesBuscador
                    data={municipios}
                    name="tipo"
                    handleChange={(e) => {chargePointMun(e)}}
                    handleFocus={(e) => {setEditarMapa(true)}}
                    placeholder="Seleccione una opción"
                    style={{ width: "100%" }}
                    id="tipo-seleccion"
                    valor={municipioSelected}
                    col={6}
                    noClass
                  />
                  <input type="hidden" name={`municipio`} value={municipio} />
                  <input type="hidden" name={`lat-mun`} value={coordsMunicipio.lat} />
                  <input type="hidden" name={`lng-mun`} value={coordsMunicipio.lng} />
                </Col>
                <Col xs={12}>
                  <Form.Group className='formGroupVertical' >
                    <Form.Label>Latitud</Form.Label>
                    <Form.Control
                      value={punto.lat}
                      name={`valores.lat`}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className='formGroupVertical' >
                    <Form.Label>Longitud</Form.Label>
                    <Form.Control
                      value={punto.lng}
                      name={`valores.lng`}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </>
              :
              <>
                <Col xs={12}>
                  <Form.Group className='formGroupVertical' >
                    <Form.Label>Ciudad, País - Dirección, Ciudad/Municipio</Form.Label>
                    <Form.Control
                      value={municipio[0]?.label !== undefined ? municipio[0]?.label : municipio}
                      name={`municipio`}
                      onChange={(e) => { handleChangeMunicipio(e) }}
                      onBlur={(e) => { showMunicipio(e.target.value) }}
                    />
                  </Form.Group>
                  <input type="hidden" name={`municipio`} value={municipio} />
                  <input type="hidden" name={`lat-mun`} value={coordsMunicipio.lat} />
                  <input type="hidden" name={`lng-mun`} value={coordsMunicipio.lng} />
                </Col>
                <Col xs={12}>
                  <Form.Group className='formGroupVertical' >
                    <Form.Label mb={'10px'}>Latitud</Form.Label>
                    <Row>
                      <Col xs={2} >
                          <Select options={[
                            { value: '-', label: '-' },
                            { value: '+', label: '+' },
                          ]}
                            defaultValue={{ value: '+', label: '+' }}
                            onChange={(e) => handleChangeSigno(e, "signo_lat")}
                          />
                          <input type="hidden" name={`signo_lat`} value={"test"} />
                      </Col>
                      <Col xs={4}>
                        <Form.Control
                          type='number'
                          name={`unidad_lat`}
                          onChange={(e) => handleChangeNewPunto(e)}
                        // value={punto.lat}

                        />

                      </Col>
                      .
                      <Col>
                        <Form.Control
                          type='number'
                          name={`decimal_lat`}
                          // value={punto.lat}
                          onChange={(e) => handleChangeNewPunto(e)}

                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className='formGroupVertical' >
                    <Form.Label>Longitud</Form.Label>
                    <Row>
                      <Col xs={2} >
                        <Select options={[
                          { value: '-', label: '-' },
                          { value: '+', label: '+' },
                        ]}
                          defaultValue={{ value: '-', label: '-' }}
                          onChange={(e) => handleChangeSigno(e, "signo_lng")}
                        />
                      </Col>
                      <Col xs={4} >
                        <Form.Control
                          name={`unidad_lng`}
                          type='number'
                          // value={punto.lng}
                          onChange={(e) => handleChangeNewPunto(e)}
                        />
                      </Col>
                      .
                      <Col>
                        <Form.Control
                          name={`decimal_lng`}
                          type='number'
                          // value={punto.lng}
                          onChange={(e) => handleChangeNewPunto(e)}
                        />

                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </>
            }


          </Col>

        </Row>
        {/* <div className="containerOcs">
                    <div id='mapa-inv' className="mapa-invt">
                        <img className="img-prv" src={imgPrev} alt="imagen cargada" />
                    </div>
                </div> */}
        <div className="buttonContainer">
          <Button
            type="submit"
            className="confirmButton"

          >
            Guardar
          </Button>
        </div>
      </Form>
      <NotificationModal
        typeModal={notificationModal.tipo}
        setIsOpen={setCloseModalMessage}
        isOpen={notificationModal.show}
        mensaje={notificationModal.message}
      />
      {/* <EditModal
        setIsOpen={setModal}
        isOpen={modal.show}
      >
        {modal.tipo === 'form' &&
          <>
            <Form noValidated onSubmit={handleSubmit(onSubmit)}
              style={{
                width: '100%',
                padding: '0',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  width: '100%',
                  margin: '0 auto',
                  padding: '0',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}

              >

                {modal.data.campos.map((campo, index) => {
                  const fieldName = `asesoria.0`;
                  return (
                    <>
                      <InputType
                        item={campo}
                        index={index}
                        idx={index}
                        key={`${0} - ${index}`}
                        reg={register}
                        nombre={`${fieldName}`}
                        errors={errors}
                        col={campo.col}
                        type=""
                      />
                    </>
                  )
                })
                }
              </div>
              <div style={{ display: 'flex', marginTop: '1em', justifyContent: 'center' }}>
                <button type="button" className="configButton" onClick={()=> {sendConfig()}}>Guardar Configuración</button>
                <button
                  type="submit"
                  style={{ margin: '20px 0 0 0' }} className="confirmButton">Guardar</button>
              </div>
            </Form>
          </>
        }
      </EditModal> */}
    </>
  );
}

export default Mapa;
