import { Form } from "react-bootstrap";
import { useForm } from 'react-hook-form';
// import { getBase64, getFile, toArrayBuffer } from "../fileToBase64";
import { useState } from "react";
// import { AttachFile, PictureAsPdf } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import './styles/controles.scss';


const DropzoneBigSize = ({ item, handleInputChange, index, idx, reg, nombre, esEditable, esCargueIndividual }) => {

  // const  { control } = useForm();

  const [data, setData] = useState({ imagen: item.valor ? item.valor : '' });

  // const [name, setName] = useState('');

  const handleImageData = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      setData({
        ...data,
        imagen: files
      });
    }
  }

  const getNameFile = (file) => {
    if (typeof (file) === 'string') {
      const typeUrl = file.split('/');
      const extension = typeUrl[typeUrl.length - 1].toLowerCase();
      return extension;
    } else {
      if (file.length > 0) {
        return file[0].name;
      }
    }
  }

  return (
    <Form.Group style={{ display: 'block' }}>
      <Form.Label>{item.titulo}{item.es_requerido && '*'}</Form.Label>
      <div style={{ display: 'flex' }}>
        {!esEditable ? (
          <div style={{ width: '100%', marginRight: '0.5em' }}>
            <label className="custom-file-upload">
              <input type="file" ref={reg} name={`asesoria.${index}.${item.nombre}`} onChange={(e) => handleImageData(e)} />
              {data.imagen !== '' ? (<p>{getNameFile(data.imagen)} </p>) : <CloudUploadIcon style={{ fontSize: 51 }} />}
            </label>
          </div>
        ) : null}
        {esCargueIndividual ? (
          <div>
            <Form.Control
              name={`asesoria.${index}.descripcion`}
              key={`${index} - ${idx}`}
              ref={reg}
              {...reg(`asesoria.${index}.${item.nombre}`, {
                required: item.es_requerido
              })}
              // style={{ border: error ? '1px solid #dc3545' : null }}
              disabled={esEditable}
            />
            <button className="confirmButton">Enviar</button>
          </div>
        ) : null}
      </div>
    </Form.Group>
  );

}

export default DropzoneBigSize;
