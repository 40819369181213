import React from 'react'

export const Resaltado = ({fill, width, height}) => {
    return (
      <svg fill={fill} id="_30" height="13" width="15" data-name="30" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 25.17 25.17">
      <path d="M17.33,1.11A7.94,7.94,0,0,1,20,2.43a2.64,2.64,0,0,1,1,3.38c-.78,1.63-1.61,3.23-2.43,4.83l-2.88,5.71-.13.25L8.17,12.33l.15-.24,6.13-9.51a3,3,0,0,1,2.11-1.44.32.32,0,0,0,.09,0ZM10.8,11.56a1.4,1.4,0,0,0,.3-.3q2.53-4,5-8a1.28,1.28,0,0,0,.11-.2.38.38,0,0,0-.17-.48.37.37,0,0,0-.48.09,1.18,1.18,0,0,0-.13.18l-5,8a1.41,1.41,0,0,0-.13.24C10.25,11.31,10.44,11.57,10.8,11.56Z"/>
      <path d="M15.18,17.3l-1.18,2a.35.35,0,0,1-.44.16,1.42,1.42,0,0,0-1.44.27,5.63,5.63,0,0,0-1.53,1.62l-.17.27c-.2.32-.36.36-.69.17L6.57,20c-.33-.19-.38-.36-.2-.7a5.85,5.85,0,0,0,.75-2.22,3.37,3.37,0,0,0-.06-1,1.23,1.23,0,0,0-.36-.53c-.15-.16-.23-.33-.12-.51.37-.68.76-1.35,1.16-2Z"/>
      <path d="M6,20.58l3.32,1.93a.69.69,0,0,1-.07.15l-1,1.67a.51.51,0,0,1-.47.28H4.4c-.38,0-.57-.3-.38-.62.64-1.13,1.3-2.25,2-3.37C6,20.6,6,20.6,6,20.58Z"/>
      </svg>

    )
}
