import React from 'react'
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
// import Popover from '@material-ui/core/Popover';


import iconPanel from '../../static/icons/icon-panel.png'
import iconAuditorias from '../../static/icons/iconAuditorias.png'
import iconInteligencia from '../../static/icons/iconInteligencia.png'
import iconInvestigaciones from '../../static/icons/iconoInvestigaciones.png'
import iconOperaciones from '../../static/icons/Icono-operaciones.png';
import iconDocs from '../../static/icons/docs.png';
import fileDocs from '../../static/icons/icon-files.png';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

// import GrUserManager from 'react-icons'


import '../styles/Menu.scss'
import { useSelector } from 'react-redux';
import { BorderColor } from '@material-ui/icons';
import { useTranslation } from 'react-i18next'





export default function MenuGerencia(props) {

  const { menu } = useSelector(state => state.menu)

  const { gerenciaGeneral } = menu;

  const { t } = useTranslation();

  const dinamicTitle = (props) => {
    if (props.menuIsOpen === true) {
      return (
        <p> {t('navegacion.menu_gerencia.titulo')} </p>
      )
    } else if (props.menuIsOpen === false) {
      return <div className='iconMenuGerencia' ><HomeWorkIcon /></div>
    }
  }

  // let [anchorEl, setAnchorEl] = React.useState(null);

  // const closePopOver = (event) => {
  //   // setAnchorEl(0);
  //   setAnchorEl(event.null);

  // }


  // const handleClick = (event) => {
  //   setAnchorEl(event.target);
  // };

  // const handleClose = () => {
  //   setAnchorEl(0);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;


  return (
    <div id='menuGerenciaContainer'>
      <div id='menuGerenciaTitulo'>
        {dinamicTitle(props)}
      </div>
      <Nav defaultActiveKey="/home" className="flex-column options">
        <Accordion>
          {
            gerenciaGeneral.panel
            &&
            <Card>
              <Accordion.Toggle eventKey='1' onClick={props.onOpenMenu}>
                <Link onClick={props.modalIsOpen} className='menuOptions' to='/dashboard-gerencia'>
                  <img className='menuIcon' src={iconPanel} alt='panel_gerencia'></img>
                  {t('navegacion.menu_gerencia.panel')}
                </Link>
              </Accordion.Toggle>
            </Card>
          }
          {
            gerenciaGeneral.auditorias.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle onClick={props.onOpenMenu} eventKey='2' className='menuOptions'>
                <img className='menuIcon' src={iconAuditorias} alt='panel_gerencia'></img>
                {t('navegacion.menu_gerencia.analisis_procesos')}
              </Accordion.Toggle>
              {
                !gerenciaGeneral.auditorias.solicitar &&
                  !gerenciaGeneral.auditorias.administrar &&
                  !gerenciaGeneral.auditorias.reportes
                  ? <></>
                  : <Accordion.Collapse eventKey='2'>
                    <div className='accordionLevel3'>

                      {
                        gerenciaGeneral.auditorias.solicitar
                        &&
                        <Link className='optionsLevel3 crearOption' to='/gerencia/auditorias/solicitar'>
                          <p >
                            {t('navegacion.comun.solicitar')}
                          </p>
                        </Link>

                      }

                      {
                        gerenciaGeneral.auditorias.administrar
                        &&
                        <Link className='optionsLevel3 adminOption' to="/gerencia/auditorias/administrar">
                          <p >
                            {t('navegacion.comun.procesos')}
                          </p>
                        </Link>
                      }

                      {
                        gerenciaGeneral.auditorias.procesos
                        &&
                        <Link className='optionsLevel3 adminOption' to="/gerencia/auditorias/procesos">
                          <p >
                            {t('navegacion.comun.procesos')}
                          </p>
                        </Link>
                      }

                      {
                        gerenciaGeneral.auditorias.reportes
                        && <Link className='optionsLevel3 informeOption ' to="/gerencia/auditorias/reportes">
                          <p >
                            {t('navegacion.comun.reportes')}
                          </p>
                        </Link>

                      }

                      {
                        gerenciaGeneral.auditorias.reportes_operativos
                        && <Link className='optionsLevel3 informeOption-op ' to="/gerencia/auditorias/reportes_operativos">
                          <p >
                            {t('navegacion.comun.operativo')}
                          </p>
                        </Link>

                      }
                    </div>
                  </Accordion.Collapse>

              }
            </Card>
          }

          {
            gerenciaGeneral.operaciones.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle onClick={props.onOpenMenu} eventKey='1' className='menuOptions'>
                <img className='menuIcon' src={iconOperaciones} alt='operaciones'></img>
                {t('navegacion.menu_gerencia.operaciones')}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='1'>
                <div className='accordionLevel3'>

                  {
                    gerenciaGeneral.operaciones.solicitar
                      ?

                      <Link className='optionsLevel3 crearOption' to='/gerencia/operaciones/solicitar'>
                        <p >
                          {t('navegacion.comun.solicitar')}
                        </p>
                      </Link>
                      : null
                  }

                  {
                    gerenciaGeneral.operaciones.administrar
                      ?
                      <Link className='optionsLevel3 adminOption' to="/gerencia/operaciones/administrar">
                        <p >
                          {t('navegacion.comun.procesos')}
                        </p>
                      </Link>
                      : null
                  }

                  {
                    gerenciaGeneral.operaciones.procesos
                      ?
                      <Link className='optionsLevel3 adminOption' to="/gerencia/operaciones/procesos">
                        <p >
                          {t('navegacion.comun.procesos')}
                        </p>
                      </Link>
                      : null
                  }

                  {
                    gerenciaGeneral.operaciones.reportes
                      ?
                      <Link className='optionsLevel3 informeOption ' to="/gerencia/operaciones/reportes">
                        <p >
                          {t('navegacion.comun.reportes')}
                        </p>
                      </Link>
                      : null
                  }
                  {
                    gerenciaGeneral.operaciones.reportes_operativos
                    &&
                    <Link className='optionsLevel3 informeOption-op ' to="/gerencia/operaciones/reportes_operativos">
                      <p >
                        {t('navegacion.comun.operativo')}
                      </p>
                    </Link>
                  }
                </div>
              </Accordion.Collapse>
            </Card>
          }

          {
            gerenciaGeneral.inteligencia.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle onClick={props.onOpenMenu} eventKey='3' className='menuOptions'>
                <img className='menuIcon' src={iconInteligencia} alt='panel_gerencia'></img>
                {t('navegacion.menu_gerencia.inteligencia')}
              </Accordion.Toggle>

              {
                !gerenciaGeneral.inteligencia.solicitar &&
                  !gerenciaGeneral.inteligencia.administrar &&
                  !gerenciaGeneral.inteligencia.reportes
                  ? <></>
                  : <Accordion.Collapse eventKey='3'>
                    <div className='accordionLevel3'>

                      {
                        gerenciaGeneral.inteligencia.solicitar
                        &&
                        <Link className='optionsLevel3 crearOption' to='/gerencia/inteligencia/solicitar'>
                          <p >
                            {t('navegacion.comun.solicitar')}
                          </p>
                        </Link>

                      }

                      {
                        gerenciaGeneral.inteligencia.administrar
                        &&
                        <Link className='optionsLevel3 adminOption' to="/gerencia/inteligencia/administrar">
                          <p >
                            {t('navegacion.comun.procesos')}
                          </p>
                        </Link>
                      }

                      {
                        gerenciaGeneral.inteligencia.procesos
                        &&
                        <Link className='optionsLevel3 adminOption' to="/gerencia/inteligencia/procesos">
                          <p >
                            {t('navegacion.comun.procesos')}
                          </p>
                        </Link>
                      }

                      {
                        gerenciaGeneral.inteligencia.reportes
                        && <Link className='optionsLevel3 informeOption ' to="/gerencia/inteligencia/reportes">
                          <p >
                            {t('navegacion.comun.reportes')}
                          </p>
                        </Link>

                      }

                      {
                        gerenciaGeneral.inteligencia.reportes_operativos
                        && <Link className='optionsLevel3 informeOption-op ' to="/gerencia/inteligencia/reportes_operativos">
                          <p >
                            {t('navegacion.comun.operativo')}
                          </p>
                        </Link>

                      }
                    </div>
                  </Accordion.Collapse>
              }
            </Card>
          }

          {
            gerenciaGeneral.investigaciones.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle eventKey='4' onClick={props.onOpenMenu} className='menuOptions'>
                <img className='menuIcon' src={iconInvestigaciones} alt='panel de presidencia'></img>
                {t('navegacion.menu_gerencia.investigaciones.investigaciones')}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='4'>
                <Card.Body className='accordionOptionsContainer'>
                  <Accordion>
                    {
                      gerenciaGeneral.investigaciones.investigaciones.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='1' className='accordionOptionsItem'>
                          {/* <img className='menuIcon' src={iconComercial} alt='panel de presidencia'></img> */}
                          {/* <Link style={{ textDecoration: 'none', color: '#778da9' }} to="/gerencia/investigaciones"> */}
                          {t('navegacion.menu_gerencia.investigaciones.investigaciones')}
                          {/* </Link> */}
                        </Accordion.Toggle>
                        {
                          !gerenciaGeneral.investigaciones.investigaciones.solicitar &&
                            !gerenciaGeneral.investigaciones.investigaciones.administrar &&
                            !gerenciaGeneral.investigaciones.investigaciones.reportes
                            ? <></>
                            : <Accordion.Collapse eventKey='1'>
                              <div className='accordionLevel3'>

                                {
                                  gerenciaGeneral.investigaciones.investigaciones.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/gerencia/investigaciones/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  gerenciaGeneral.investigaciones.investigaciones.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/gerencia/investigaciones/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  gerenciaGeneral.investigaciones.investigaciones.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/gerencia/investigaciones/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  gerenciaGeneral.investigaciones.investigaciones.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/gerencia/investigaciones/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  gerenciaGeneral.investigaciones.investigaciones.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/gerencia/investigaciones/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      gerenciaGeneral.investigaciones.investigacionesAdministrativas.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='2' className='accordionOptionsItem'>
                          {/* <img className='menuIcon' src={iconComercial} alt='panel de presidencia'></img> */}
                          {/* <Link style={{ textDecoration: 'none', color: '#778da9' }} to="/gerencia/investigaciones-administrativas"> */}
                          {t('navegacion.menu_gerencia.investigaciones.investigaciones_administrativas')}
                          {/* </Link> */}
                        </Accordion.Toggle>

                        {
                          !gerenciaGeneral.investigaciones.investigacionesAdministrativas.solicitar &&
                            !gerenciaGeneral.investigaciones.investigacionesAdministrativas.administrar &&
                            !gerenciaGeneral.investigaciones.investigacionesAdministrativas.reportes &&
                            !gerenciaGeneral.investigaciones.investigacionesAdministrativas.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='2'>
                              <div className='accordionLevel3'>

                                {
                                  gerenciaGeneral.investigaciones.investigacionesAdministrativas.solicitar
                                  &&

                                  <Link className='optionsLevel3 crearOption' to='/gerencia/investigaciones-administrativas/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  gerenciaGeneral.investigaciones.investigacionesAdministrativas.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/gerencia/investigaciones-administrativas/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  gerenciaGeneral.investigaciones.investigacionesAdministrativas.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/gerencia/investigaciones-administrativas/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  gerenciaGeneral.investigaciones.investigacionesAdministrativas.reportes
                                  &&
                                  <Link className='optionsLevel3 informeOption ' to="/gerencia/investigaciones-administrativas/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }
                                {
                                  gerenciaGeneral.investigaciones.investigacionesAdministrativas.reportes_operativos
                                  &&
                                  <Link className='optionsLevel3 informeOption-op ' to="/gerencia/investigaciones-administrativas/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }

                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          }




          {
            gerenciaGeneral.documentos.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle eventKey='11' onClick={props.onOpenMenu} className='menuOptions'>
                {/* <DescriptionOutlinedIcon/> */}
                <img className='menuIcon' src={iconDocs} alt='operaciones'></img>
                {gerenciaGeneral.documentos.nombre}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='11'>
                <Card.Body className='accordionOptionsContainer'>
                  <Accordion>
                    {
                      gerenciaGeneral.documentos?.secciones?.map((e, index) => {
                        // console.log(e, 'first');
                        return (
                          <>
                            {e.activo === true ?
                              <Card className='AccordionCard'>

                                <Accordion.Toggle eventKey={index + 1} className='accordionOptionsItem'>
                                  {/* <img className='menuIcon' src={iconComercial} alt='panel de presidencia'></img> */}
                                  {/* <Link style={{ textDecoration: 'none', color: '#778da9' }} to="/gerencia/investigaciones"> */}
                                  {/* {console.log(e.nombre, 'this is name de la seccion')} */}
                                  {e.nombre ? e.nombre : null}

                                  {/* </Link> */}
                                </Accordion.Toggle>

                                {
                                  <Accordion.Collapse eventKey={index + 1}>
                                    {/* <div className='accordionLevel3'> */}
                                    <div accordionOptionsItemstyle={{ display: 'flex', flexDirection: 'column' }}>
                                      {
                                        e?.documentos?.map((doc, indx) => {
                                          return (

                                            <a id='linkDoc' target='_blank' key={indx} href={doc.url} rel='noreferrer' style={{ borderTop: "101%", borderColor: "#213265", backgroundColor: "white" }}>
                                              <p className='nameDoc' style={{ fontWeight: 'normal' }}> {doc.nombre} </p>
                                            </a>

                                          )

                                        })
                                      }
                                    </div>
                                    {/* </div> */}
                                  </Accordion.Collapse>
                                }
                              </Card>
                              : null
                            }

                          </>
                        )
                      })
                    }
                    {gerenciaGeneral.documentos.docs.documentos.map((doc, index) => {
                      return (
                        <>
                          <a id='linkDoc' target='_blank' key={index} href={doc.url} rel='noreferrer' >
                            <p className='nameDoc' > {doc.nombre}</p>
                          </a>
                        </>
                      )
                    })}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
              {/* <Accordion.Collapse eventKey='11'>
                  <div style={{display:'flex', flexDirection:'column'}}>
                    {gerenciaGeneral.documentos.documentos.map((doc, index) => {
                      return(
                        <>

                        <a id='linkDoc' target='_blank' key={index} href={doc.url} rel='noreferrer' >
                          <p className='nameDoc' > {doc.nombre}</p>
                        </a>
                        </>
                      )
                    })}

                  </div>
                </Accordion.Collapse> */}
            </Card>
          }

          {
            gerenciaGeneral.cia1 && gerenciaGeneral.cia1.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle className='menuOptions'>
                <a
                  id='enlacev1'
                  target='_blank'
                  href='https://www.ciacosinte.site/crm/home.html'
                  style={{
                    textDecoration: 'none',
                    color: '#707070 !important'
                  }}

                >
                  <svg style={{ marginRight: '18px' }} className='menuIcon' version="1.0" xmlns="https://www.w3.org/2000/svg"
                    width="20" height="20" viewBox="0 0 376.000000 165.000000"
                    preserveAspectRatio="xMidYMid meet"  >

                    <g transform="translate(0.000000,165.000000) scale(0.100000,-0.100000)"
                      fill="#000000" stroke="none">
                      <path d="M705 1640 c-99 -16 -215 -57 -300 -106 -94 -55 -233 -190 -284 -277
                  -192 -326 -148 -729 109 -997 276 -287 713 -333 1051 -111 41 27 84 60 96 73
                  l23 24 -124 123 -123 123 -54 -41 c-90 -69 -145 -86 -279 -86 -112 0 -117 1
                  -193 37 -301 144 -368 530 -131 758 173 167 421 183 610 40 l51 -38 122 122
                  122 123 -38 34 c-136 123 -312 194 -507 203 -50 3 -118 1 -151 -4z"/>
                      <path d="M1580 830 l0 -820 175 0 175 0 0 820 0 820 -175 0 -175 0 0 -820z" />
                      <path d="M2793 1636 c-273 -52 -477 -206 -602 -453 -56 -112 -75 -201 -76
                  -353 0 -191 34 -308 136 -460 91 -136 206 -231 359 -296 138 -60 183 -64 653
                  -64 234 0 439 3 456 6 l32 7 -3 476 c-4 457 -5 479 -26 549 -68 225 -224 412
                  -429 516 -138 69 -351 100 -500 72z m294 -367 c77 -29 127 -62 185 -121 50
                  -52 100 -145 117 -218 6 -25 11 -163 11 -307 l0 -263 -267 0 c-148 0 -288 5
                  -312 11 -249 57 -410 325 -346 574 29 110 107 218 201 277 39 25 137 63 184
                  72 50 9 170 -4 227 -25z"/>
                      <path d="M3626 1629 c-36 -28 -36 -80 0 -108 30 -24 43 -26 78 -10 33 15 50
                  57 37 92 -19 49 -71 61 -115 26z m67 0 c27 -15 37 -28 37 -53 0 -52 -75 -76
                  -104 -34 -22 31 -20 45 9 73 28 29 29 29 58 14z"/>
                      <path d="M3640 1575 c0 -19 5 -35 10 -35 6 0 10 7 10 15 0 20 10 19 25 -2 11
                  -15 13 -13 13 20 0 34 -2 37 -29 37 -26 0 -29 -3 -29 -35z m40 15 c0 -5 -4
                  -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
                    </g>
                  </svg>

                  CIA 1.0
                </a>
              </Accordion.Toggle>


            </Card>
          }

          {
            gerenciaGeneral.archivos && gerenciaGeneral.archivos.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle onClick={props.onOpenMenu} eventKey='11' className='menuOptions'>
                <img className='menuIcon' src={fileDocs} alt='panel_gerencia'></img>
                {t('navegacion.menu_gerencia.archivos.archivo')}
              </Accordion.Toggle>

              {
                !gerenciaGeneral.archivos.cargue &&
                  !gerenciaGeneral.archivos.descargue
                  ? <></>
                  : <Accordion.Collapse eventKey='11'>
                    <div className='accordionLevel3'>

                      {
                        gerenciaGeneral.archivos.cargue
                        &&
                        <Link className='optionsLevel3 crearOption' to='/cargue-masivo/'>
                          <p >
                            {t('navegacion.menu_gerencia.archivos.cargue')}
                          </p>
                        </Link>

                      }

                      {
                        gerenciaGeneral.archivos.descargue
                        &&
                        <Link className='optionsLevel3 adminOption' to="/descargue/">
                          <p >
                            {t('navegacion.menu_gerencia.archivos.descargue')}
                          </p>
                        </Link>

                      }
                      {/*
                      {
                        gerenciaGeneral.inteligencia.reportes
                          && <Link className='optionsLevel3 informeOption ' to="/gerencia/inteligencia/reportes">
                            <p >
                              Reportes
                            </p>
                          </Link>

                      }

                      {
                        gerenciaGeneral.inteligencia.reportes_operativos
                          && <Link className='optionsLevel3 informeOption ' to="/gerencia/inteligencia/reportes_operativos">
                            <p >
                              Reportes
                            </p>
                          </Link>

                      } */}
                    </div>
                  </Accordion.Collapse>
              }
            </Card>
          }

          {/* <Link to='/gerencia/operaciones' className='menuOptions' >

gerencia/operaciones */}

        </Accordion>
      </Nav>
    </div>
  )
}
