const candidatos = [
  {
    target: '#tarjetaInformacionRegistro',
    disableBeacon: true,
    content: <div>
      <h3>Información de la gestión</h3>
      <p>En esta sección se muestra la información de la solicitud, como el estado, la fecha de creación y el cargo, entre otros .</p>
    </div>
  },
  {
    target: '#pestanasGestor',
    content: <div>
    <h3>Secciones</h3>
    <p>Se muestran las secciones de la gestión, al dar clic en una de ellas se mostrarán los formularios correspondientes.</p>
  </div>
  },
  {
    target: '#seccion-0',
    content: <div>
    <h3>Información de la solicitud</h3>
    <p>En esta sección se debe cargar la información general de la solicitud, como sus nombres, apellidos, dirección, entre otros.</p>
  </div>
  },
  {
    target: '#seccion-1',
    content: <div>
    <h3>Documentación</h3>
    <p> En esta sección se debe cargar la documentación necesaria para completar la gestión.</p>
  </div>
  },
  {
    target: '#contenedorFormulario',
    content: <div>
    <h3>Formulario de la solicitud</h3>
    <p>Diligencie todos los campos obligatorios, al finalizar de clic en el botón "Guardar" para guardar la información.</p>
  </div>
  },
  {
    target: '.MuiButtonGroup-grouped',
    content: <div>
    <h3>Finalizar solicitud</h3>
    <p>Una vez que se haya completado la información de la solicitud y cargado la documentación necesaria, debe dar clic en este botón para finalizar la gestión.</p>
  </div>
  }
]

export {candidatos}
