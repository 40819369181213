import React, { useState } from 'react';
import { Fragment } from 'react';
import { getBase64 } from '../../../helpers/fileToBase64';
import { Col, Form, Row } from 'react-bootstrap';
import { DropzoneArea } from 'material-ui-dropzone';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import './FormObs.scss';
import { useTranslation } from 'react-i18next';
import Check from '../../common/Check';

const FormObs = ({ data, setData, pieHallazgo, setPieHallazgo, pieRecomendacion, setPieRecomendacion }) => {

  const [imagenIsLoad, setImagenIsLoad] = useState(false);
  const { t } = useTranslation();

  const handleChangeData = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  }

  const handleChangeHallazgos = (e, index) => {
    const { name, value } = e.target;
    const list = [...pieHallazgo];
    list[index][name] = value;
    setPieHallazgo(list);
  };

  const handleChangeRecomendaciones = (e, index) => {
    const { name, value } = e.target;
    const list = [...pieRecomendacion];
    list[index][name] = value;
    setPieRecomendacion(list);
  };

  const handleImageData = async (file, index) => {
    let imageB64 = '';

    if (file.length > 0) {
      imageB64 = await getBase64(file[0]);

      const hallazgo = [...pieHallazgo];
      const item = { ...hallazgo[index] };
      item.imagen = imageB64;
      hallazgo[index] = item;
      setPieHallazgo(hallazgo);
      setImagenIsLoad(true)
    }
  }

  const handleImageDataRecomendacion = async (file, index) => {
    let imageB64 = '';

    if (file.length > 0) {
      imageB64 = await getBase64(file[0]);
      const hallazgo = [...pieRecomendacion];
      const item = { ...hallazgo[index] };
      item.imagen = imageB64;
      hallazgo[index] = item;
      setPieRecomendacion(hallazgo);
      setImagenIsLoad(true)
    }
  }

  const deleteImageData = (file, index) => {
    setData({
      ...data,
      imagen: ''
    });
  }

  const handleAddClick = () => {
    setPieHallazgo([...pieHallazgo, { imagen: "", pie_imagen: "" }]);
  };

  const handleRemoveClick = index => {
    const list = [...pieHallazgo];
    list.splice(index, 1);
    setPieHallazgo(list);
  };

  const handleAddClickRecomendacion = () => {
    setPieRecomendacion([...pieRecomendacion, { imagen: "", pie_imagen: "" }]);
  };

  const handleRemoveClickRecomendacion = index => {
    const list = [...pieRecomendacion];
    list.splice(index, 1);
    setPieRecomendacion(list);
  };

  return (
    <Fragment>
      <div className='d-flex justify-content-between'>
        <h5 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.hallazgo')}</h5>
        <div className="d-flex align-items-center gap-2">
          <Form.Label>{t('formulario.label.matriz_riesgos_activo')}</Form.Label>
          <Check onChange={handleChangeData} name="activo" performInitialUpdate={true}/>
        </div>
      </div>
      <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
        <Form.Label>{t('formulario.label.hallazgo_concreto')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="hallazgo"
          onChange={handleChangeData}
          className="audit-form-textarea"
          style={{ height: '100px' }}
          required
        />
        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
        <Form.Label>{t('formulario.label.redacte_detalle_hallazgo')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="descripcion_hallazgo"
          onChange={handleChangeData}
          className="audit-form-textarea"
          style={{ height: '100px' }}
          required
        />
        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
      </Form.Group>
      <br />
      {pieHallazgo.map((input, index) => (
        <>
          <Row>
            <Col xs={6}>
              <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t('formulario.label.imagen')}</Form.Label>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={t('formulario.label.arrastra_suelta_imagen')}
                  name="imagen"
                  onChange={(file) => handleImageData(file, index)}
                  filesLimit={1}
                  showAlerts={false}
                  onDelete={(file) => { deleteImageData(file) }}
                // dropzoneClass={validatedFile ? 'validated-dropzone' : null}
                />
                {/* {validatedFile ? (<p className="invalid-feedback audit-form">Este campo es obligatorio</p>) : null}   */}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t('formulario.label.pie_imagen')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="pie_imagen"
                  style={{ height: '100px' }}
                  onChange={(e) => { handleChangeHallazgos(e, index) }}
                  className="audit-form-textarea"
                  required={input.imagen !== "" ? true : false} sc
                />
                <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              {pieHallazgo.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={() => { handleAddClick() }} />}
              {pieHallazgo.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />}
            </Col>
          </Row>
        </>
      ))}
      <hr />
      <br />
      <h5 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.riesgos_asociados_consecuencias')}</h5>
      <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          as="textarea"
          rows={3}
          name="comentario"
          onChange={handleChangeData}
          className="audit-form-textarea"
          style={{ height: '100px' }}
          required
        />
        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
      </Form.Group>
      <br />
      <hr />
      <h5 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.recomendaciones')}</h5>
      <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          as="textarea"
          rows={3}
          name="observacion"
          onChange={handleChangeData}
          className="audit-form-textarea"
          style={{ height: '100px' }}
          required
        />
        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
      </Form.Group>
      <br />
      {pieRecomendacion.map((input, index) => (
        <>
          <Row>
            <Col xs={6}>
              <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t('formulario.label.imagen')}</Form.Label>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={t('formulario.label.arrastra_suelta_imagen')}
                  name="imagen"
                  onChange={(file) => handleImageDataRecomendacion(file, index)}
                  filesLimit={1}
                  showAlerts={false}
                  onDelete={(file) => { deleteImageData(file) }}
                // dropzoneClass={validatedFile ? 'validated-dropzone' : null}
                />
                {/* {validatedFile ? (<p className="invalid-feedback audit-form">Este campo es obligatorio</p>) : null}   */}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t('formulario.label.pie_imagen')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="pie_imagen"
                  onChange={(e) => { handleChangeRecomendaciones(e, index) }}
                  className="audit-form-textarea"
                  style={{ height: '100px' }}
                  required={input.imagen !== "" ? true : false}
                />
                <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              {pieRecomendacion.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={() => { handleAddClickRecomendacion() }} />}
              {pieRecomendacion.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickRecomendacion(index)} />}
            </Col>
          </Row>
        </>
      ))}
      <br />
      <h5 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.responsable')}</h5>
      <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
        <Form.Control
          as="textarea"
          rows={3}
          name="responsable"
          onChange={handleChangeData}
          className="audit-form-textarea"
          style={{ height: '100px' }}
          required
        />
        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
      </Form.Group>
    </Fragment>
  );
}

export default FormObs;
