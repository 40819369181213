import React, { Fragment, useState } from 'react'
import { Card, Form} from 'react-bootstrap'

import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { useDispatch, useSelector } from 'react-redux';
import {  editarBeneficiario, eliminarBeneficiario } from '../../../../reducers/editSolicitudes';

import EditModal from '../../../modales/EditModal'
import NotificationModal from '../../../modales/NotificationModal'

import './Seguimientos.scss'


export default function Seguimientos(props) {

    const URL_BASE = `https://www.ciacosinte.site/cia2/editar_investigacion.html?id=${props.idSolicitud}`;


    const setFormState = props.setFormState
    const formState = props.formState
    
    const dispatch = useDispatch()

    const [validated, setValidated] = useState(false);
    // const { beneficiarios } = useSelector(state => state.edicionSolicitud)
    const [identificacionError, setIdentificacionError] = useState('')

    const [modalAddSeguimiento, setModalAddSeguimiento] = useState({})

    const [formNewSeguimiento, setFormNewSeguimiento] = useState({})


    const [formEdit, setFormEdit] = useState({
        identificacion: '',
        identificacionTipo: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        parentesco: ''

    })

    const handleInputChangeNew = ({ target }) => {

        // console.log(target.value)
        setFormNewSeguimiento({
            ...formNewSeguimiento,
            [target.name]: target.value
        })

        // console.log(formNewSeguimiento);
    }


    const handleInputChangeEdit = ({ target }) => {
        setFormEdit({
            ...formEdit,
            [target.name]: target.value
        })
    }

    const submitForm =async (e) => {
        e.preventDefault()
        setIdentificacionError('')

    const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
    // } else {
    //     let id = false
    //     beneficiarios.forEach((ele) => {
    //         if (ele.identificacion === formNewSeguimiento.identificacion) {
    //             id = true
    //         }
    //     })
    //     if (id) {
    //         setIdentificacionError('Numero de identificacion ya esta registrado')
    //     } else {

    //         dispatch(agregarBeneficiario(formNewSeguimiento))

    //         console.log(identificacionError)
    //     }

    // }
    setValidated(true);
    setModalAddSeguimiento(true);

    await fetch(URL_BASE, {
        method: 'POST',
        headers: {
            'content-Type' : 'application/json'
        },
        body: JSON.stringify(formNewSeguimiento)
    })

  }

  const [deleteModal, setDeleteModal] = useState(false)

  const handleEliminar = (id) => {
      dispatch(eliminarBeneficiario(id))
      setDeleteModal(false)
  }

  const [editRows, setEditRows] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const handleEdit = (ele, index) => {
      if (editRows[1] === index) {
          setEditRows(false)
      } else {
          setEditRows([ele, index])
          setEditModal(true)
      }


  }

  const handleEditElement = (ele) => {

    dispatch(editarBeneficiario(ele, formEdit))
    setEditRows(false)
    setFormEdit({
        identificacion: '',
        identificacionTipo: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        parentesco: ''
    })

    setEditModal(false)
}


  return (
    <>
    <Card className='shadow cardGeneral'>
      <Card.Body>
        <div className="" style={{ display: 'flex', justifyContent:'space-between', alignItems:'center' }}>
        <h4 className='titleCard'> Seguimientos</h4>
        {props.dataSolicitud.seguimientosOpcionAgregar === true
            ?
            <div className="addIconContainer" >
                <IconButton onClick={() => setFormState(!formState)}>
                    <AddIcon fontSize='large' style={{ background: '#213265', borderRadius: '50%', color: 'white' }} />
                </IconButton>
            </div>
            : null
        }
        </div>
        <br/>
        {
          formState
          &&
          <>
            <Form className='formAddSeguimientoContainer' noValidate validated={validated} onSubmit={submitForm}>
            <div className='InputsSeguimientosContainer'>
                <div >
                    <Form.Label>
                        Tipo *
                    </Form.Label>
                    <Form.Control name='tipo' required as='select' placeholder='Primer nombre'
                        style={{ marginRight: '10px' }}
                        onChange={handleInputChangeNew}
                    >
                    <option disabled>Seleccione una opción</option>
                    <option value='labores de campo' >Labores de campo</option>
                    <option value='tickets' >Tickets</option>
                    <option value='oficios' >Oficios</option>
                    <option value='llamadas'>llamadas</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>Ingresa el primer nombre</Form.Control.Feedback>

                </div>
                <div>
                    <Form.Label>
                    Fecha inicial *
                    </Form.Label>
                    <Form.Control  name='fecha_inicial' required type='date'
                        onChange={handleInputChangeNew}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>Ingrese la fecha inicial</Form.Control.Feedback>
                </div>
                <div>
                    <Form.Label>
                        Fecha final * 
                    </Form.Label>
                    <Form.Control  name='fecha_final' required type='date'
                        onChange={handleInputChangeNew}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>Ingrese la fecha final</Form.Control.Feedback>
                </div>
            </div>
              <div className='textAreaFormGroupSeguimientos' id='solicitudAddBeneficiario'>
                  <Form.Group id='solicitudFormGroup'>
                      <Form.Label className='observacionesLabel'>
                          Solicitud *
                      </Form.Label>
                          <Form.Control className='seguimientosTextArea' name='solicitud' id='solicitudTextArea' required as='textarea'
                              placeholder='Solicitud'
                              // className="place"
                              onChange={handleInputChangeNew}
                          ></Form.Control>
                          <Form.Control.Feedback type='invalid'>Ingresa la solicitud</Form.Control.Feedback>
                  </Form.Group>
              </div>
              <br/>
              <div className='textAreaFormGroupSeguimientos' id='respuestaAddBeneficiario'>
                  <Form.Group id='respuestaFormGroup'>
                      <Form.Label className='observacionesLabel'>
                          Respuesta *
                      </Form.Label>
                          <Form.Control className='seguimientosTextArea' name='respuesta' id='respuestaTextArea' required as='textarea'
                              placeholder='Respuesta'
                              // className="place"
                              onChange={handleInputChangeNew}
                          ></Form.Control>
                          <Form.Control.Feedback type='invalid'>Ingresa las respuestas</Form.Control.Feedback>
                  </Form.Group>
              </div>
              <br/>
              <div className='textAreaFormGroupSeguimientos' id='observacionesAddBeneficiario'>
                  <Form.Group id='observacionesFormGroup'>
                      <Form.Label className='observacionesLabel'>
                          Observaciones *
                      </Form.Label>
                          <Form.Control className='seguimientosTextArea' name='observaciones' id='observacionesTextArea' required as='textarea'
                              placeholder='Observaciones'
                              // className="place"
                              onChange={handleInputChangeNew}
                          ></Form.Control>
                          <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                  </Form.Group>
              </div>
              <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                  <button type="submit" className='confirmButton'>
                      enviar
                  </button>
              </div>
          </Form>
          <NotificationModal isOpen={modalAddSeguimiento} setIsOpen={setModalAddSeguimiento}/>
          </>
        }
      </Card.Body>
      
    </Card>
    <br/>
    <Card className="shadow " style={{ borderRadius: '10px' }}>
                <Card.Body>
                    <h6 className='pt-4' style={{ color: '#707070' }}>Lista de seguimientos</h6>
                    {
                        props.dataSolicitud.seguimientos.length === 0
                            ? <div className="container text-center">
                                <h3 className='p-4' style={{ color: '#707070' }}>Aún no se han realizado seguimientos.</h3>
                            </div>

                            : <div className='tableContainer'>

                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Fecha inicial</th>
                                        <th>Fecha final</th>
                                        <th>Solicitud</th>
                                        <th>Respuesta</th>
                                        <th>Observaciones</th>
                                        {props.dataSolicitud.seguimientosOpcionEditar === true || props.dataSolicitud.seguimientosOpcionEliminar === true
                                            ? <th>Acciones</th>
                                            : null
                                        }
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.dataSolicitud.seguimientos.map((ele, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{ele.tipoSeguimiento}</td>
                                                    <td>{ele.fechaInicialSeguimiento}</td>
                                                    <td>{ele.fechaFinalSeguimiento}</td>
                                                    <td>{ele.solicitudSeguimiento}</td>
                                                    <td>{ele.respuestaSeguimiento}</td>
                                                    <td>{ele.observacionSeguimiento}</td>
                                                    {props.dataSolicitud.seguimientosOpcionEditar === true || props.dataSolicitud.seguimientosOpcionEliminar === true
                                                        ?<td style={{ display: 'flex', justifyContent:'space-evenly' }}>
                                                            {props.dataSolicitud.seguimientosOpcionEditar === true
                                                                ?
                                                                <div className='editBeneficiarios buttonBeneficiarios'   onClick={() => handleEdit(ele, index)} >
                                                                    <EditIcon fontSize='small'/>
                                                                    Editar
                                                                </div>
                                                                : null
                                                            }
                                                            {props.dataSolicitud.seguimientosOpcionEliminar === true
                                                                ?
                                                                <div className='deleteBeneficiarios buttonBeneficiarios'  onClick={() => setDeleteModal(true)}>
                                                                    <DeleteIcon fontSize='small'/>
                                                                    Eliminar
                                                                </div>
                                                                : null
                                                            }
                                                            <EditModal isOpen={editModal} setIsOpen={setEditModal} typeModal='editSeguimiento' editHandler={() => handleEditElement(ele)}>
                                                                <div className='formEditBeneficiarioContainer'>
                                                                <div>
                                                                    <label>Identificación</label>

                                                                </div>
                                                                <div className="" id='idContainerEditBeneficiario'>
                                                                    <select
                                                                        defaultValue={ele.identificacionTipo}
                                                                        name="identificacionTipo"
                                                                        onChange={handleInputChangeEdit}
                                                                        className="noneStiles">
                                                                        <option value="" selected>seleccione su tipo de identificación</option>
                                                                        <option value="CC" >Cédula de ciudadanía</option>
                                                                        <option value="CE" >Cédula de extranjería</option>
                                                                    </select>
                                                                    <input
                                                                        
                                                                        onChange={handleInputChangeEdit}
                                                                        name="identificacion"
                                                                        defaultValue={ele.identificacion}
                                                                    />
                                                                </div>
                                                                <div className='nombresContenedor'>
                                                                    <div className='nombresContainerEditBeneficiario'>
                                                                    <label>Nombres</label>
                                                                    <input placeholder="Primer nombre"
                                                                            defaultValue={ele.primerNombre}
                                                                            onChange={handleInputChangeEdit}
                                                                            name="primerNombre"
                                                                            />
                                                                    <input
                                                                        placeholder="Segundo nombre"
                                                                        defaultValue={ele.segundoNombre}
                                                                        onChange={handleInputChangeEdit}
                                                                        name="segundoNombre"
                                                                        />
                                                                            
                                                                    </div>
                                                                    <div className='nombresContainerEditBeneficiario'>
                                                                        <label>Apellidos</label>
                                                                        <input
                                                                        placeholder="Primer apellido"
                                                                            defaultValue={ele.primerApellido}
                                                                            onChange={handleInputChangeEdit}
                                                                            name="primerApellido"
                                                                            />
                                                                        <input
                                                                            placeholder="Segundo apellido"
                                                                            defaultValue={ele.segundoApellido}
                                                                            onChange={handleInputChangeEdit}
                                                                            name="segundoApellido"
                                                                            />
                                                                    </div>

                                                                </div>
                                                                    <label>Parentesco</label>
                                                                    <select
                                                                    defaultValue={ele.parentesco}
                                                                    name="parentesco"
                                                                    onChange={handleInputChangeEdit}
                                                                    className="noneStiles2">
                                                                        <option value="" selected>selecciona</option>

                                                                        <option value="Padre">Padre</option>
                                                                        <option value="Hijo(a)"> Hijo(a)</option>
                                                                        <option value="Hijo(a) Menor"> Hijo(a) Menor</option>
                                                                        <option value="Hijo(a) Estudiante">Hijo(a) Estudiante</option>
                                                                        <option value="Hijo(a) invalido"> Hijo(a) invalido</option>
                                                                        <option value="Hermano(a)"> Hermano(a)</option>
                                                                        <option value="Hermano(a) Invalido">Hermano(a) Invalido</option>
                                                                        <option value="Conyuge/Compañero(a)"> Conyuge/Compañero(a)</option>
                                                                        <option value="Otro">Otro</option>
                                                                    </select>

                                                                </div>
                                                                <div className='formEditBeneficiarioObservacionesContainer'>
                                                                    <label>Observaciones</label>
                                                                    <textarea
                                                                    id='inputObservacionesBeneficiarios'
                                                                    defaultValue={ele.observaciones}
                                                                    name="observaciones"
                                                                    onChange={handleInputChangeEdit}
                                                                    />
                                                                </div>
                                                            </EditModal>
                                                            <NotificationModal isOpen={deleteModal} setIsOpen={setDeleteModal} typeModal='deleteSeguimientoConfirm' handleEliminar={() => handleEliminar(ele.identificacion)} ></NotificationModal>
                                                        </td>
                                                        : null
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                            </div> 

                    }

                </Card.Body>
            </Card>
    </>
  )
}
