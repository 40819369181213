import React, {useState} from 'react'
import { Card, Form } from 'react-bootstrap'

import './BuscadorRegistro.scss'

export default function BuscadorRegistro() {

  const [palabraClave, setPalabraClave] = useState({
    palabraClave: ''
  })

  const handleChangePalabraClave = ({target}) => {
    // console.log(target.value);
    setPalabraClave({
      [target.name] : target.value
    })
    // console.log(palabraClave);
  }

  const [dataBusqueda, setDataBusqueda] = useState({

  });

  const fetchBusqueda = async () => {
    try {
      const request = await fetch('https://127.0.0.1:5500/public/JSON/10.buscadorGeneralEjemploRespuesta.json.html')
      const data = await request.json();
      // console.log(data);
      setDataBusqueda(data)
      // console.log(dataBusqueda);
      printTable()
    } catch (error) {
      console.log(error.message);
    }
  }

  // const [dataFiltrada, setDataFiltrada] = useState('')
  // const dataFiltrada = () =>  dataBusqueda.filter(registro => {
  //   return registro.nombreCausante.includes(palabraClave.palabraClave)
  // })

  const printData = () => {
    fetchBusqueda();

    // dataFiltrada()

  }

  const printTable = () => {
    return(
      <div>
        {dataBusqueda.data.map((ele, index) => {
          return(
            <tr key={index}>
              <td>{ele.informe}</td>
              <td>{ele.fechaSolicitud}</td>
              <td>{ele.fechaAprobacion}</td>
              <td>{ele.fechaEstimada}</td>
              <td>{ele.idCosinte}</td>
              <td>{ele.radicado}</td>
              <td>{ele.analista}</td>
              <td>{ele.nombreCausante}</td>
              <td>{ele.idCausante}</td>
              <td>{ele.estadoRegistro}</td>
            </tr>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
        <div >
            <div className='titleContainer'>
              <h3 className='pagesTitles'>
                  Solicitudes
              </h3>
              <div className='titleLine'> </div>
            </div>
        </div>
      </div>
      <Card className='shadow cardGeneral'>
        <Card.Body className='busquedaContainer'>
          <h4 className='pagesTitles'>Buscador: </h4>
          <Form >
            <div className='busquedaContainer controlBusquedaContainer' >
              <Form.Control name='palabraClave' className='inputBusqueda' type='text' placeholder='Escriba una palabra clave para realizar la busqueda'
                value={palabraClave.palabraClave.value}
                onChange={handleChangePalabraClave}
              ></Form.Control>
              <button onClick={printData} className='busquedaButton'>Buscar</button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <br/>
      {
         <Card>
            <table>
              <thead>
                <tr>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                  <th>7</th>
                  <th>8</th>
                  <th>9</th>
                  <th>10</th>
                </tr>
              </thead>
              <tbody>
                {
                  dataBusqueda === null
                    ? <h3>No hemos encontrado nada relacionado con tu busqueda </h3>
                    : printTable
                }
              </tbody>
            </table>
         {/* <h3>No hemos encontrado nada relacionado con tu busqueda :(</h3> */}
          </Card>
      }
    </>
  )
}
