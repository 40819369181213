import React, { useState, useEffect } from 'react'
import { Col, Form, Button, Tabs, Tab, ListGroup, Table, Row } from 'react-bootstrap'
import reactDOM from 'react-dom'
// import { DropzoneDialogBase, DropzoneArea } from 'material-ui-dropzone';
// import { getBase64 } from '../../helpers/fileToBase64';
import { municipios } from '../../helpers/municipios';


// import SortedBarChart from '../dashboard/SortedBarChart';
// import ModalContent from '../gestionAuditorias/PlanEstrategico/ModalContent';
import CloseIcon from '@material-ui/icons/Close';

import './EditModal.scss'
// import { clippingParents } from '@popperjs/core';
// import OpcionesCiudades from '../../helpers/OpcionesCiudades';
import HeatMap from '../../helpers/Mapas/Edicion/Heatmap';
import Loader from '../icons/Loader';
import './styles/ModalPanorama.scss';

// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
// import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import NotificationModal from './NotificationModal';
import { useTranslation } from 'react-i18next';

import './EditModal.scss'

export default function ModalActividades(props) {

  // console.log('dataselect: '+ props.dataSelect)
  const { t } = useTranslation();
  const [addItem, setAddItem] = useState({
    titulo: '',
    descripcion: ''
  })

  const [fecha, setFecha] = useState(moment(props.data.fecha).format('YYYY-MM-DD'));

  const [showModalNotification, setModalNotification] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [message, setMessage] = useState('');
  const [indexItem, setIndexItem] = useState('');

  const handleOpenShowModalNotification = (tipo, mensaje, index) => {
    setTypeModal(tipo);
    setMessage(mensaje);
    setIndexItem(index);
    setModalNotification(true);
  }

  const handleCloseShowModalNotification = () => {
    setTypeModal('');
    setMessage('');
    setIndexItem('');
    setModalNotification(false);
  }

  const deleteCoords = () => {
    props.eliminarCoordenadas(indexItem);
    handleCloseShowModalNotification();
    handleOpenShowModalNotification('eliminacion_confirmada', 'Coordenadas eliminadas correctamente', '');
  }

  const editCoords = () => {
    props.editarCoordenadas(indexItem);
    handleCloseShowModalNotification();
    handleOpenShowModalNotification('eliminacion_confirmada', 'Coordenadas actualizadas correctamente', '');
  }

  if (!props.isOpen) {
    return null;
  };

  const NotiModal = () => (
    <NotificationModal
      isOpen={showModalNotification}
      setIsOpen={handleCloseShowModalNotification}
      typeModal={typeModal}
      message={message}
      handleOpenMessageModalDeleteEdit={props.handleOpenMessageModalDeleteEdit}
      editarCoordenadas={props.editarCoordenadas}
      indexItem={indexItem}
      handleEliminar={deleteCoords}
      handleEditar={editCoords}
    />
  );

  return reactDOM.createPortal(
    <div className='editMmodalContainer'>
      <div className='editModalCard' style={{ width: '75%', minHeight: '65%' }}>
        <div className='closeButtonContainer'>
          <CloseIcon onClick={() => { props.setIsOpen(false) }} />
        </div>
        <h3 className='pagesTitles' style={{ textAlign: 'start' }} >{t('formulario.label.editar_actividad')}</h3>
        <Row style={{ width: '80%', margin: '0 auto', overflowY: 'scroll' }}>
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.fecha')}</Form.Label>
              <Form.Control
                onChange={(e) => { setFecha(e.target.value); props.handleInputChange(e) }}
                defaultValue={props?.data?.fecha}
                type="date"
                name='fecha'
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
              // onChange={(e) => setFecha(e.target.value)}
              >
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.accion')}</Form.Label>
              <Form.Control
                as='select'
                defaultValue={props?.data?.actividad ? props?.data?.actividad : ''}
                onChange={(e) => props.handleInputChange(e)}
                name='actividad'
              >
                <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                {props.dataSelect.length > 0 && (props.dataSelect.map((item, index) => (
                  <option value={item.valor}>{item.valor}</option>
                )))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.resumen')}</Form.Label>
              <Form.Control
                style={{ minHeight: '50px' }}
                as='textarea'
                placeholder={t('formulario.label.escribe_descripcion')}
                onChange={(e) => props.handleInputChange(e)}
                defaultValue={props.data.resumen}
                name='resumen'
              >
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
              <Form.Control
                style={{ minHeight: '100px' }}
                as='textarea'
                placeholder={t('formulario.label.escribe_descripcion')}
                onChange={(e) => props.handleInputChange(e)}
                defaultValue={props.data.descripcion}
                name='descripcion'
              >
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.tipo')}</Form.Label>
              <Form.Control
                // style={{minHeight:'100px'}}
                as='select'
                onChange={(e) => props.handleInputChange(e)}
                defaultValue={props.data.tipo ? props.data.tipo : ''}
                name='tipo'
              >
                <option value="" disabled>{t('formulario.label.seleccione')}</option>
                {(props.dataSelect2.map((item, index) => (
                  <option value={item.valor}>{item.valor}</option>
                )))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6} >
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.ubicacion')}</Form.Label>
              <Form.Control
                as='select'
                defaultValue={props.data.ubicacion}
                onChange={(e) => props.handleInputChange(e)}
                name='ubicacion'
              >
                <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                {municipios.length > 0 && (municipios.map((item, index) => (
                  <option value={item.value}>{item.label}</option>
                )))}
              </Form.Control>
            </Form.Group>
          </Col>
          <HeatMap
            googleMapURL={props.googleMapURL}
            coords={props.data.ubicaciones.puntos}
            tipoMapa={props.data.ubicaciones.tipo}
            containerElement={<div style={{ height: '100%' }}></div>}
            mapElement={<div style={{ height: '100%' }}></div>}
            loadingElement={<Loader />}
          />
          <div className='buttonContainer'>
            <button
              onClick={() => { props.editarDatosActividad() }}
              className='confirmButton'
            >
              {t('botones.enviar')}
            </button>
            <button className='deniedButton' onClick={() => { props.setIsOpen() }}>{t('botones.cancelar')}</button>
          </div>
          {props.data.ubicaciones.puntos.length > 0 && (
            <div className="table-ubi-container">
              <Table striped bordered hover className="tabla-ubicaciones">
                <thead>
                  <tr>
                    <th>{t('formulario.label.latitud')}</th>
                    <th>{t('formulario.label.longitud')}</th>
                    {/* <th>Acciones</th> */}
                  </tr>
                </thead>
                <tbody>
                  {props.data.ubicaciones.puntos.map((ubi, index) => (
                    <tr className="rowTabla">
                      {props.indexEdit === index ? (
                        <td>
                          <Form.Control
                            onChange={(e) => props.handleInputCoord(e)}
                            defaultValue={ubi.lat}
                            name='lat'
                            type="number"
                          />
                        </td>
                      ) : (<td>{ubi.lat}</td>)}
                      {props.indexEdit === index ? (
                        <td>
                          <Form.Control
                            onChange={(e) => props.handleInputCoord(e)}
                            defaultValue={ubi.lng}
                            name='lng'
                            type="number"
                          />
                        </td>
                      ) : (<td>{ubi.lng}</td>)}
                      {/* <td className='tdBotones'>
                                                    <div className='botonesTabla '>
                                                            { props.indexEdit === index ? (
                                                                <button
                                                                    style={{ cursor: 'pointer' }}
                                                                    className='botonTablaGeneral botonGuardarTabla'
                                                                    onClick = { () => {handleOpenShowModalNotification('confirmacion_edicion_coords', 'Esta seguro de actualizar estas coordenadas?', index)} }
                                                                    >
                                                                    <SaveIcon fontSize='small'/>
                                                                    Guardar
                                                            </button>
                                                            ) : null }
                                                            <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonEditarTabla'
                                                                onClick={() => { props.getIndexEdit(index, ubi)  }}
                                                            >
                                                                <EditIcon fontSize='small'/>
                                                                Editar
                                                            </button>
                                                            <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonEliminarTabla'
                                                                onClick = { () => {handleOpenShowModalNotification('confirmacion_eliminacion_coords', 'Esta seguro de eliminar estas coordenadas?', index)} }
                                                            >
                                                                <DeleteIcon fontSize='small'/>
                                                                Eliminar
                                                            </button>
                                                    </div>
                                                </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Row>
      </div>
      {NotiModal()}
    </div>,
    document.getElementById('EditModal')
  )

}
