import React from 'react'

export const OportunidadSVG = (props) => {
    return (
      <svg version="1.1" id="Capa_1" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px" width='25' height='25'
      viewBox="0 0 512 512" fill={props.color}  >
   <g>
     <g>
       <path d="M255.985,59.99c-8.284,0-15,6.716-15,15s6.716,15,15,15c49.634,0,90.015,40.374,90.015,90c0,8.284,6.716,15,15,15
         s15-6.716,15-15C376,113.822,322.162,59.99,255.985,59.99z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M217.335,4.03c-67.77,14.161-122.72,68.585-137.179,136.776c-12.209,57.582,2.836,115.392,41.277,158.607
         C140.224,320.536,151,348.419,151,375.99v30c0,19.96,13.067,36.917,31.093,42.79c5.928,35.025,36.328,63.209,73.907,63.209
         c37.569,0,67.977-28.175,73.907-63.209C347.933,442.907,361,425.95,361,405.989v-30c0-27.625,10.812-55.173,30.442-77.569
         C420.176,265.64,436,223.58,436,179.99C436,66.425,332.051-19.936,217.335,4.03z M256,481.99c-19.282,0-36.188-13.268-42.431-31.1
         h84.861C292.188,468.722,275.282,481.99,256,481.99z M331,405.99c0,8.271-6.729,15-15,15H196c-8.271,0-15-6.729-15-15v-15h150
         V405.99z M368.882,278.647c-20.92,23.867-33.791,52.647-37.057,82.343H180.178c-3.262-29.712-16.1-58.775-36.328-81.516
         c-32.038-36.016-44.557-84.291-34.346-132.445C121.423,90.815,167.223,45.15,223.472,33.397C319.496,13.33,406,85.442,406,179.99
         C406,216.302,392.818,251.339,368.882,278.647z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M45,179.99H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S53.284,179.99,45,179.99z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M51.213,104.99L30,83.777c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L30,126.203
         c5.857,5.858,15.355,5.859,21.213,0C57.071,120.345,57.071,110.848,51.213,104.99z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M51.213,263.777c-5.858-5.858-15.356-5.858-21.213,0L8.787,284.99c-5.858,5.858-5.858,15.355,0,21.213
         c5.857,5.858,15.355,5.859,21.213,0l21.213-21.213C57.071,279.132,57.071,269.635,51.213,263.777z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M497,179.99h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S505.284,179.99,497,179.99z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M503.213,83.777c-5.857-5.858-15.355-5.858-21.213,0l-21.213,21.213c-5.858,5.858-5.858,15.355,0,21.213
         c5.857,5.857,15.355,5.858,21.213,0l21.213-21.213C509.071,99.132,509.071,89.635,503.213,83.777z"/>
     </g>
   </g>
   <g>
     <g>
       <path d="M503.213,284.99L482,263.777c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L482,306.203
         c5.857,5.857,15.355,5.858,21.213,0C509.071,300.345,509.071,290.848,503.213,284.99z"/>
     </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>

    )
}
