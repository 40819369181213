import * as React from "react";
import * as RechartsPrimitive from "recharts";
import "./Chart.scss";


const ChartContext = React.createContext(null);

function useChart() {
    const context = React.useContext(ChartContext);

    if (!context) {
        throw new Error("useChart must be used within a <ChartContainer />");
    }

    return context;
}

const ChartContainer = React.forwardRef(
    ({ id, className, children, config, ...props }, ref) => {
        const uniqueId = React.useRef(`chart-${Math.random()}`).current;
        const chartId = `chart-${id || uniqueId.replace(/:/g, "")}`;

        return (
            <ChartContext.Provider value={{ config }}>
                <div
                    data-chart={chartId}
                    ref={ref}
                    className={`chart-container ${className}`} // Usa la clase de Sass
                    {...props}
                >
                    {/*
                    <ChartStyle id={chartId} config={config} />
                    */}
                    <RechartsPrimitive.ResponsiveContainer>
                        {children}
                    </RechartsPrimitive.ResponsiveContainer>
                </div>
            </ChartContext.Provider>
        );
    }
);
ChartContainer.displayName = "Chart";

{/*

const ChartStyle = ({ id, config }) => {
    const colorConfig = Object.entries(config).filter(
        ([_, config]) => config.theme || config.color
    );

    console.log(colorConfig,"colorConfig");
    if (!colorConfig.length) {
        return null;
    }

    return (
        <style
            dangerouslySetInnerHTML={{
                __html: Object.entries(THEMES)
                    .map(
                        ([theme, prefix]) => `
${prefix} [data-chart=${id}] {
${colorConfig
                                .map(([key, itemConfig]) => {
                                    const color =
                                        itemConfig.theme?.[theme] ||
                                        itemConfig.color;
                                    return color ? `  --color-${key}: ${color};` : null;
                                })
                                .join("\n")}
}
`
                    )
                    .join("\n"),
            }}
        />
    );
};
*/}

const ChartTooltip = RechartsPrimitive.Tooltip;

const ChartTooltipContent = React.forwardRef(
    (
        {
            active,
            payload,
            className,
            indicator = "dot",
            hideLabel = false,
            hideIndicator = false,
            label,
            labelFormatter,
            labelClassName,
            formatter,
            color,
            nameKey,
            labelKey,
        },
        ref
    ) => {
        const { config } = useChart();

        const tooltipLabel = React.useMemo(() => {
            if (hideLabel || !payload?.length) {
                return null;
            }

            const [item] = payload;
            const key = `${labelKey || item.dataKey || item.name || "value"}`;
            const itemConfig = getPayloadConfigFromPayload(config, item, key);
            const value =
                !labelKey && typeof label === "string"
                    ? config[label]?.label || label
                    : itemConfig?.label;

            if (labelFormatter) {
                return (
                    <div className={`tooltip-label ${labelClassName}`}>
                        {labelFormatter(value, payload)}
                    </div>
                );
            }

            if (!value) {
                return null;
            }

            return <div className={`tooltip-label ${labelClassName}`}>{value}</div>;
        }, [
            label,
            labelFormatter,
            payload,
            hideLabel,
            labelClassName,
            config,
            labelKey,
        ]);

        if (!active || !payload?.length) {
            return null;
        }

        const nestLabel = payload.length === 1 && indicator !== "dot";

        return (
            <div
                ref={ref}
                className={`tooltip-container ${className}`}
            >
                {!nestLabel ? tooltipLabel : null}
                <div className="tooltip-content">
                    {payload.map((item, index) => {
                        const key = `${nameKey || item.name || item.dataKey || "value"}`;
                        const itemConfig = getPayloadConfigFromPayload(config, item, key);
                        const indicatorColor = color || item.payload.fill || item.color;

                        return (
                            <div
                                key={item.dataKey}
                                className={`tooltip-item ${indicator === "dot" ? "tooltip-item-dot" : ""}`}
                            >
                                {formatter && item?.value !== undefined && item.name ? (
                                    formatter(item.value, item.name, item, index, item.payload)
                                ) : (
                                    <>
                                        {itemConfig?.icon ? (
                                            <itemConfig.icon />
                                        ) : (
                                            !hideIndicator && (
                                                <div
                                                    className={`tooltip-indicator ${indicator === "dot" ? "tooltip-indicator-dot" : ""
                                                        } ${indicator === "line" ? "tooltip-indicator-line" : ""} ${indicator === "dashed" ? "tooltip-indicator-dashed" : ""}`}
                                                    style={{
                                                        backgroundColor: indicatorColor,
                                                        borderColor: indicatorColor,
                                                    }}
                                                />
                                            )
                                        )}
                                        <div
                                            className={`tooltip-item-content ${nestLabel ? "tooltip-item-nest" : "tooltip-item-standard"}`}
                                        >
                                            <div className="tooltip-item-label">
                                                {nestLabel ? tooltipLabel : null}
                                                <span className="tooltip-item-name">
                                                    {itemConfig?.label || item.name}
                                                </span>
                                            </div>
                                            {item.value && (
                                                <span className="tooltip-item-value">
                                                    {item.value.toLocaleString()}
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
);
ChartTooltipContent.displayName = "ChartTooltip";

const ChartLegend = RechartsPrimitive.Legend;
const ChartLegendContent = React.forwardRef(
    (
        { className, hideIcon = false, payload, verticalAlign = "bottom", nameKey },
        ref
    ) => {
        const { config } = useChart();

        if (!payload?.length) {
            return null;
        }

        return (
            <div
                ref={ref}
                className={`legend-container ${verticalAlign === "top" ? "legend-top" : "legend-bottom"} ${className}`}
            >
                {payload.map((item) => {
                    const key = `${nameKey || item.dataKey || "value"}`;
                    const itemConfig = getPayloadConfigFromPayload(config, item, key);
                    return (
                        <div
                            key={item.value}
                            className="legend-item"
                        >
                            {itemConfig?.icon && !hideIcon ? (
                                <itemConfig.icon />
                            ) : (
                                <div
                                    className="legend-color-box"
                                    style={{
                                        backgroundColor: item.color,
                                    }}
                                />
                            )}
                            {itemConfig?.label}
                        </div>
                    );
                })}
            </div>
        );
    }
);
ChartLegendContent.displayName = "ChartLegend";

// Helper to extract item config from a payload.
function getPayloadConfigFromPayload(config, payload, key) {
    if (typeof payload !== "object" || payload === null) {
        return undefined;
    }

    const payloadPayload =
        "payload" in payload &&
            typeof payload.payload === "object" &&
            payload.payload !== null
            ? payload.payload
            : undefined;

    let configLabelKey = key;

    if (
        key in payload &&
        typeof payload[key] === "string"
    ) {
        configLabelKey = payload[key];
    } else if (
        payloadPayload &&
        key in payloadPayload &&
        typeof payloadPayload[key] === "string"
    ) {
        configLabelKey = payloadPayload[key];
    }

    return configLabelKey in config
        ? config[configLabelKey]
        : config[key];
}

export {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    ChartLegend,
    ChartLegendContent
};