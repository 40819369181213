import moment from "moment";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { BtnAyuda } from "./BtnAyuda";

const Date = ({ item, handleInputChange, index, idx, reg, nombre, error, esEditable }) => {


    /**
     * Lista negra de fechas invalidas para el
     * componente
     */
    const validate = [
        "",
        " ",
        "0000-00-00",
        "0000-00-00 00:00:00",
    ];

    /**
     * Función que valida fechas
     * frente a la lista negra que se envio
     * si es el caso contrario envia un vacio.
     * @param {*} value
     * @returns
     */
    const validateDate = (value) => {
        if(validate.indexOf(value) !== -1) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Convierte un string a una fecha en formato año - mes - dia
     * @param {*} str
     * @returns
     */
    const strToDate = (str) => {;
        const strToTime = moment(str).toDate();
        const date = moment(strToTime).format('YYYY-MM-DD');
        return date;
    }

    /**
     * Validamos si la fecha es valida, si es asi realizamos la conversion de string a fecha
     */
    const [fecha, setFecha] = useState(validateDate(item.valor) ? strToDate(item.valor)  : '');

    return(
        <Form.Group style={{ display: 'block' }}>
            <div style={{display: 'flex', justifyContent: 'flex-start'}} >
                <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : ""  }} >{ item.titulo }{item.es_requerido === true && '*'}</Form.Label>
                {item.ayuda &&
                    <BtnAyuda ayuda={item.ayuda}/>
                }
            </div>

            <Form.Control
                value={fecha}
                type="date"
                name={`asesoria.${index}.${item.nombre}`}
                key={`${index} - ${idx}`}
                style={{ border: error ? '1px solid #dc3545' : null }}
                onChange={(e) => setFecha(e.target.value)}
                disabled={esEditable}
            />

            <input
                type="hidden"
                name={`asesoria.${index}.${item.nombre}`}
                value={fecha}
                ref={reg}
                {...reg(`asesoria.${index}.${item.nombre}`, {
                    required: item.es_requerido
                })}
            />

            {/* <ReactDatePicker
                selected={fecha}
                dateFormat="yyyy-MM-dd"
             /> */}
            { error ?
                (
                    error.type === 'required' ?
                    (
                        <span className="inputsInvalid">
                            Este campo es requerido
                        </span>
                    )
                    : null )
            : null }
        </Form.Group>
    );
}

export default Date;
