import React from 'react'
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

import {Logo} from '../atomos/Logo'

import './styles/PortadaGeneral.scss'

function PortadaGeneral({data}) {

  const portada = 'https://www.ciacosinte.site/crm/upload/images/portada.png'

  const styles = StyleSheet.create({
    viewer: {
      height:'81vh'
    },
    page: {
      backgroundImage: "url(https://www.ciacosinte.site/cia2/static/media/pdfs/fondo_azul.png)",
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    contenido:{
      position: 'absolute',
      top: '0',
      height: '100%',
      width: '100%',
      display: 'grid',
      gridTemplate: 'auto auto 200px / 1fr 1fr',
      color:'white',
      zIndex: 0,
    },
    text: {
      top: '0'
    },
    header: {
      height: '70px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },

    logo:{
      width: 'auto',
      height: '50px',
      padding: '0 20px'
    },

    tipo: {
      position: 'absolute',
      zIndex: 2,
      height: '250px',
      bottom: '140px',
      width: '100%',
      gridRow: '3'
    },

    normativa: {
      height: 'auto',
      margin: '20px 32px'
    },
    normativa_label: {
      color: '#adacac',
      fontSize: '9px',
      height: 'auto',
      width: '100%',
      textAlign: 'left'
    },

    tipo_details:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 32px',
      height: '80px'
    },

    tipo_tipo: {
      fontWeight: 700,
      fontSize: '18px',
      textAlign: 'center',
      textTransform: 'uppercase'

    },
    tipo_periodo: {
      fontSize: '10px',
      opacity: 0.7
    },

    titulo: {
      margin: '0 auto',
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%"
    },
    titulo_label:{
      fontWeight: '700',
      fontSize: '16px',
      textAlign: 'center',
      width: '100%'
    }
  })

  return (
    <Page size="letter" style={styles.page} >
      <View wrap={false} >
        <Image
          src={portada}
          style={{
            position: 'float',
            minWidth: '100%',
            minHeight: '100%',
            zIndex: 1
          }}
        />
        <View style={styles.contenido} >
          <Logo />
          <View style={styles.tipo} >
            <View style={styles.tipo_details}>
              <View>
                <Text style={styles.tipo_tipo} >{data?.tipo}</Text>
              </View>
            </View>
            <View style={styles.titulo} >
              <Text className='titulo_label' style={styles.titulo_label} >{data?.titulo}</Text>
            </View>
            <View style={styles.normativa} >
              <Text style={styles.normativa_label} wrap={true} >{data?.normativa}</Text>
            </View>
          </View>

        </View>
      </View>
    </Page>
  )
}

export {PortadaGeneral}
