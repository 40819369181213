import { useEffect, useState, Fragment } from "react";
import { validarEntorno } from "../../helpers/validarHost";
import { Card } from 'react-bootstrap'
import Dona2 from "./Dona2";
import FunnelGradient from "./FunnelGradient";
import GeneralWidgets from './GeneralWidgets'
import DivergentChartAmplia from './DivergentChartAmplia'
import HerraduraExpandible from './HerraduraExpandible'
import TortadeTorta from './TortadeTorta'
import { MediaTorta } from './MediaTorta'
import SortedBarChart from './SortedBarChart'
import { FullDonut3D } from './FullDonut3D'
import { Histograma } from './Histograma'
import DonaDosniveles from './DonaDosniveles'
import Graficaconsolidado from './GraficaConsolidado'
import { object } from "@amcharts/amcharts4/core";
import { useTranslation } from "react-i18next";

import { RenderGrafica } from '../../helpers/graficas/RenderGrafica'

const PestanaGrafica = ({dataGrafica, sectionOpen, usuariosCliente, regionales}) => {

    // console.log(dataGrafica.data)
    const { t } = useTranslation();
    const isEmptyObj = (obj) => {
        return Object.keys(obj).length === 0;
    }

    return(
        <>
            {/* { !isEmptyObj(dataGrafica.data) ? ( */}
            { dataGrafica?.data ? (
                <>
                    {sectionOpen === 'consolidado' ?
                        <Fragment>
                        {!dataGrafica.data.consolidado?.graficos.compromisosGeneral.data.length > 0
                            && !dataGrafica.data.consolidado?.graficos.regionales.data.length > 0
                            && !dataGrafica.data.consolidado?.graficos.usuarios.data.length > 0
                            && !dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.data.length > 0
                            && !dataGrafica.data.consolidado?.graficos.consolidado_series.data.length > 0
                            ?
                            <Card style={{ width: '100%', padding: '40px' }} className='shadow cardGraficaGeneral cardGraficaAnalistas'>
                                <Card.Body>
                                    <h3 className='titleCard' style={{ textAlign: 'center' }}>{t('formulario.label.no_informacion_relacionada')}</h3>
                                </Card.Body>
                            </Card>
                            :
                            <>
                                {dataGrafica.data.consolidado?.graficos.consolidado_series &&
                                    dataGrafica.data.consolidado?.graficos.consolidado_series.visualizar &&
                                    dataGrafica.data.consolidado?.graficos.consolidado_series.data.length > 0 &&
                                    <div style={{ width: '100%', marginBottom: '20px' }}  >
                                        <Graficaconsolidado
                                            title={dataGrafica.data.consolidado?.graficos.consolidado_series.titulo}
                                            parametrizado={dataGrafica.data.consolidado?.graficos.consolidado_series.parametrizado}
                                            id='consolidado'
                                            data={dataGrafica.data.consolidado?.graficos.consolidado_series.data}
                                            series={dataGrafica.data.consolidado?.graficos.consolidado_series.series.lista}
                                        />
                                    </div>
                                }

                                {usuariosCliente &&  dataGrafica.data.consolidado?.graficos.compromisosGeneral && dataGrafica.data.consolidado?.graficos.compromisosGeneral.data.length > 0 &&
                                    <div style={{ width: '100%', marginBottom: '20px' }}  >
                                        <MediaTorta
                                            id='compromisosGeneral'
                                            data={dataGrafica.data.consolidado?.graficos.compromisosGeneral.data}
                                            title={dataGrafica.data.consolidado?.graficos.compromisosGeneral.titulo}
                                            parametrizado={dataGrafica.data.consolidado?.graficos.compromisosGeneral.parametrizado}
                                            periodo={dataGrafica.data.consolidado?.graficos.compromisosGeneral.periodo}
                                            total={dataGrafica.data.consolidado?.graficos.compromisosGeneral.total}
                                        />
                                    </div>
                                }

                                {regionales && dataGrafica.data.consolidado?.graficos.regionales.data.length > 0 &&
                                    <div style={{ width: '100%', marginBottom: '20px' }}  >
                                        <TortadeTorta
                                            id='regionales'
                                            // data={dataGrafica.data.consolidado?.graficos.regionales.data}
                                            title={dataGrafica.data.consolidado?.graficos.regionales.titulo}
                                            parametrizado={dataGrafica.data.consolidado?.graficos.regionales.parametrizado}
                                            periodo={dataGrafica.data.consolidado?.graficos.regionales.periodo}
                                            total={dataGrafica.data.consolidado?.graficos.regionales.total}
                                        />

                                    </div>
                                }
                                {/* <TortadeTorta id='regionales' data={datosDivergentes.regionales2} title='consolidado? de solicitudes por centro de costo y por estado'  /> */}

                                {usuariosCliente && dataGrafica.data.consolidado?.graficos.usuarios.data.length > 0 &&
                                    <div style={{ width: '100%', marginBottom: '20px' }}  >
                                        <DivergentChartAmplia
                                            id='usuarios'
                                            data={dataGrafica.data.consolidado?.graficos.usuarios.data}
                                            title={dataGrafica.data.consolidado?.graficos.usuarios.titulo}
                                            parametrizado={dataGrafica.data.consolidado?.graficos.usuarios.parametrizado}
                                            periodo={dataGrafica.data.consolidado?.graficos.usuarios.periodo}
                                            total={dataGrafica.data.consolidado?.graficos.usuarios.total}
                                            limite={dataGrafica.data.consolidado?.graficos.usuarios.limite}
                                        />
                                    </div>
                                }

                                {usuariosCliente && dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.data.length > 0 &&
                                    <div style={{ width: '100%', marginBottom: '20px' }}  >
                                        <DivergentChartAmplia
                                            id='compromisosTipoServicio'
                                            data={dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.data}
                                            title={dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.titulo}
                                            parametrizado={dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.parametrizado}
                                            periodo={dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.periodo}
                                            total={dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.total}
                                            limite={dataGrafica.data.consolidado?.graficos.compromisosTipoServicio.limite}
                                        />
                                    </div>
                                }
                                {dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio && dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.data.length > 0 &&

                                    <div style={{ width: '100%', marginBottom: '20px' }}  >
                                        <Histograma
                                            id='solicitudesTipoServiciostatic'
                                            data={dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.data}
                                            title={dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.titulo}
                                            parametrizado={dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.parametrizado}
                                            periodo={dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.periodo}
                                            total={dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.total}
                                            limite={dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.limite}
                                            series={dataGrafica.data.consolidado?.graficos.solicitudesTipoServicio.series}
                                        />
                                    </div>
                                }


                            </>

                        }
                        </Fragment>
                    :
                    sectionOpen === 'oportunidad' ?
                        <Fragment>
                            {
                                dataGrafica.data.oportunidad?.graficos.respuestas.visualizar
                                    && dataGrafica.data.oportunidad?.graficos.respuestas.data.length > 0
                                    ?
                                    <div style={{ width: '100%' }}>

                                        {dataGrafica.data.oportunidad?.graficos.oportunidad_series &&
                                            dataGrafica.data.oportunidad?.graficos.oportunidad_series.visualizar && dataGrafica.data.oportunidad?.graficos.oportunidad_series.data.length > 0 &&
                                            <div style={{ width: '100%', marginBottom: '20px' }}  >
                                                <Graficaconsolidado
                                                    title={dataGrafica.data.oportunidad?.graficos.oportunidad_series.titulo}
                                                    parametrizado={dataGrafica.data.oportunidad?.graficos.oportunidad_series.parametrizado}
                                                    id='oportunidad'
                                                    data={dataGrafica.data.oportunidad?.graficos.oportunidad_series.data}
                                                    series={dataGrafica.data.oportunidad?.graficos.oportunidad_series.series.lista}
                                                />
                                            </div>
                                        }

                                        <Dona2
                                            id='respuestas'
                                            data={dataGrafica.data.oportunidad?.graficos.respuestas.data}
                                            title={dataGrafica.data.oportunidad?.graficos.respuestas.titulo}
                                            parametrizado={dataGrafica.data.oportunidad?.graficos.respuestas.parametrizado}
                                            periodo={dataGrafica.data.oportunidad?.graficos.respuestas.periodo}
                                            total={dataGrafica.data.oportunidad?.graficos.respuestas.total}
                                        />
                                    </div>
                                    :
                                    <Card style={{ width: '100%', padding: '40px' }} className='shadow cardGraficaGeneral cardGraficaAnalistas'>
                                        <Card.Body>
                                            <h3 className='titleCard' style={{ textAlign: 'center' }}>{t('formulario.label.no_informacion_relacionada')}</h3>
                                        </Card.Body>
                                    </Card>
                            }
                        </Fragment>


                    : sectionOpen === 'novedad' ?
                        <>
                            {!dataGrafica.data.novedades?.graficos?.novedadesFinalizadas.data.length > 0 && !dataGrafica.data.novedades?.graficos?.novedadesEnProceso.data.length > 0
                                ?
                                <Card style={{ width: '100%', padding: '40px' }} className='shadow cardGraficaGeneral cardGraficaAnalistas'>
                                    <Card.Body>
                                        <h3 className='titleCard' style={{ textAlign: 'center' }}>{t('formulario.label.no_informacion_relacionada')}</h3>
                                    </Card.Body>
                                </Card>
                                :
                                <>
                                    {dataGrafica.data.novedades?.graficos.novedades_series &&
                                        dataGrafica.data.novedades?.graficos.novedades_series.visualizar && dataGrafica.data.novedades?.graficos.novedades_series.data.length > 0 &&
                                        <div style={{ width: '100%', marginBottom: '20px' }}  >
                                            <Graficaconsolidado
                                                title={dataGrafica.data.novedades?.graficos.novedades_series.titulo}
                                                parametrizado={dataGrafica.data.novedades?.graficos.novedades_series.parametrizado}
                                                id='novedades'
                                                data={dataGrafica.data.novedades?.graficos.novedades_series.data}
                                                series={dataGrafica.data.novedades?.graficos.novedades_series.series.lista}
                                            />
                                        </div>
                                    }

                                    {
                                        dataGrafica.data.novedades?.graficos.novedadesFinalizadas.visualizar
                                        && dataGrafica.data.novedades?.graficos.novedadesFinalizadas.data.length > 0 &&
                                        <div style={{ marginBottom: '20px', width: '100%' }}>
                                            <FunnelGradient
                                                id='novedadesFinalizadas'
                                                data={dataGrafica.data.novedades?.graficos.novedadesFinalizadas.data}
                                                title={dataGrafica.data.novedades?.graficos.novedadesFinalizadas.titulo}
                                                parametrizado={dataGrafica.data.novedades?.graficos.novedadesFinalizadas.parametrizado}
                                                periodo={dataGrafica.data.novedades?.graficos.novedadesFinalizadas.periodo}
                                                total={dataGrafica.data.novedades?.graficos.novedadesFinalizadas.total}
                                            />
                                        </div>
                                    }

                                    <br />
                                    {
                                        dataGrafica.data.novedades?.graficos.novedadesEnProceso.visualizar
                                        && dataGrafica.data.novedades?.graficos.novedadesEnProceso.data.length > 0 &&
                                        <div style={{ marginBottom: '20px', width: '100%' }}>
                                            <FullDonut3D
                                                id='novedadesEnProceso'
                                                data={dataGrafica.data.novedades?.graficos.novedadesEnProceso.data}
                                                title={dataGrafica.data.novedades?.graficos.novedadesEnProceso.titulo}
                                                parametrizado={dataGrafica.data.novedades?.graficos.novedadesEnProceso.parametrizado}
                                                periodo={dataGrafica.data.novedades?.graficos.novedadesEnProceso.periodo}
                                                total={dataGrafica.data.novedades?.graficos.novedadesEnProceso.total}
                                            />
                                        </div>
                                    }

                                </>
                            }
                        </>
                    :
                    sectionOpen === 'nivelRiesgo' ?
                        <Fragment>
                            {
                                dataGrafica.data.nivelRiesgo?.graficos.nivelRiesgo?.visualizar
                                    && dataGrafica.data.nivelRiesgo?.graficos.nivelRiesgo?.data.length > 0
                                    ?
                                    <div style={{ marginBottom: '20px', width: '100%' }}>

                                        {dataGrafica.data.nivelRiesgo?.graficos.niveles_riesgo_series &&
                                            dataGrafica.data.nivelRiesgo?.graficos.niveles_riesgo_series.visualizar && dataGrafica.data.nivelRiesgo?.graficos.niveles_riesgo_series.data.length > 0 &&
                                            <div style={{ width: '100%', marginBottom: '20px' }}  >
                                                <Graficaconsolidado
                                                    title={dataGrafica.data.nivelRiesgo?.graficos.niveles_riesgo_series.titulo}
                                                    parametrizado={dataGrafica.data.nivelRiesgo?.graficos.niveles_riesgo_series.parametrizado}
                                                    id='novedades'
                                                    data={dataGrafica.data.nivelRiesgo?.graficos.niveles_riesgo_series.data}
                                                    series={dataGrafica.data.nivelRiesgo?.graficos.niveles_riesgo_series.series.lista}
                                                />
                                            </div>
                                        }

                                        <Dona2 id='Nivel de riesgo'
                                            data={dataGrafica.data.nivelRiesgo?.graficos.nivelRiesgo?.data}
                                            title={dataGrafica.data.nivelRiesgo?.graficos.nivelRiesgo?.titulo}
                                            parametrizado={dataGrafica.data.nivelRiesgo?.graficos.nivelRiesgo?.parametrizado}
                                            periodo={dataGrafica.data.nivelRiesgo?.graficos.nivelRiesgo?.periodo}
                                            total={dataGrafica.data.nivelRiesgo?.graficos.nivelRiesgo?.total}
                                        />
                                    </div>
                                    :
                                    <Card style={{ width: '100%', padding: '40px' }} className='shadow cardGraficaGeneral cardGraficaAnalistas'>
                                        <Card.Body>
                                            <h3 className='titleCard' style={{ textAlign: 'center' }}>{t('formulario.label.no_informacion_relacionada')}</h3>
                                        </Card.Body>
                                    </Card>

                            }
                        </Fragment>

                    : sectionOpen === 'anotaciones' ?
                        <>
                            {!dataGrafica.data.anotaciones?.graficos.anotacionesTotal.data.length > 0
                                && !dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.data.length > 0
                                ?
                                <Card style={{ width: '100%', padding: '40px' }} className='shadow cardGraficaGeneral cardGraficaAnalistas'>
                                    <Card.Body>
                                        <h3 className='titleCard' style={{ textAlign: 'center' }}>{t('formulario.label.no_informacion_relacionada')}</h3>
                                    </Card.Body>
                                </Card>
                                :
                                <>

                                    {dataGrafica.data.anotaciones?.graficos.anotaciones_series &&
                                        dataGrafica.data.anotaciones?.graficos.anotaciones_series.visualizar && dataGrafica.data.anotaciones?.graficos.anotaciones_series.data.length > 0 &&
                                        <div style={{ width: '100%', marginBottom: '20px' }}  >
                                            <Graficaconsolidado
                                                title={dataGrafica.data.anotaciones?.graficos.anotaciones_series.titulo}
                                                parametrizado={dataGrafica.data.anotaciones?.graficos.anotaciones_series.parametrizado}
                                                id='novedades'
                                                data={dataGrafica.data.anotaciones?.graficos.anotaciones_series.data}
                                                series={dataGrafica.data.anotaciones?.graficos.anotaciones_series.series.lista}
                                            />
                                        </div>
                                    }


                                    {
                                        dataGrafica.data.anotaciones?.graficos.anotacionesTotal.visualizar
                                        && dataGrafica.data.anotaciones?.graficos.anotacionesTotal.data.length > 0
                                        &&
                                        <Fragment>
                                            {
                                                dataGrafica.data.anotaciones
                                                &&
                                                <div style={{ marginBottom: '20px', width: '100%' }}>
                                                    <DonaDosniveles id='anotacionesTotal'
                                                        data={dataGrafica.data.anotaciones?.graficos.anotacionesTotal.data}
                                                        title={dataGrafica.data.anotaciones?.graficos.anotacionesTotal.titulo}
                                                        parametrizado={dataGrafica.data.anotaciones?.graficos.anotacionesTotal.parametrizado}
                                                        periodo={dataGrafica.data.anotaciones?.graficos.anotacionesTotal.periodo}
                                                        total={dataGrafica.data.anotaciones?.graficos.anotacionesTotal.total}
                                                    />
                                                </div>
                                            }
                                        </Fragment>

                                    }

                                    {
                                        dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.visualizar
                                        && dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.data.length > 0
                                        &&
                                        <Fragment>
                                            {
                                                dataGrafica.data.anotaciones
                                                &&
                                                <div className='contenedorDosCards' style={{ marginBottom: '20px' }}>
                                                    <HerraduraExpandible id='anotacionesCentroCosto'
                                                        data={dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.data}
                                                        title={dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.titulo}
                                                        total={dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.total}
                                                        parametrizado={dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.parametrizado}
                                                        periodo={dataGrafica.data.anotaciones?.graficos.anotacionesCentroCosto.periodo}
                                                    />
                                                </div>
                                            }
                                        </Fragment>

                                    }
                                </>

                            }
                        </>

                    : sectionOpen === 'clientes' ?
                        <>
                            {
                                dataGrafica.data.clientes?.visualizar
                                    && dataGrafica.data.clientes?.graficos.clientesMayores.data.length > 0
                                    ?
                                    <Fragment>

                                        {dataGrafica.data.clientes?.graficos.clientes_series &&
                                            dataGrafica.data.clientes?.graficos.clientes_series.visualizar && dataGrafica.data.clientes?.graficos.clientes_series.data.length > 0 &&
                                            <div style={{ width: '100%', marginBottom: '20px' }}  >
                                                <Graficaconsolidado
                                                    title={dataGrafica.data.clientes?.graficos.clientes_series.titulo}
                                                    parametrizado={dataGrafica.data.clientes?.graficos.clientes_series.parametrizado}
                                                    id='novedades'
                                                    data={dataGrafica.data.clientes?.graficos.clientes_series.data}
                                                    series={dataGrafica.data.clientes?.graficos.clientes_series.series.lista}
                                                />
                                            </div>
                                        }

                                        {
                                            dataGrafica.data.clientes
                                            && dataGrafica.data.clientes?.graficos.clientesMayores.visualizar
                                            &&
                                            <div className='panelFull' style={{ marginBottom: '20px', width: '100%' }}>
                                                <DivergentChartAmplia tipoContenedor='clientesMayores' id='ClientesMayorVolumen'
                                                    data={dataGrafica.data.clientes?.graficos.clientesMayores.data}
                                                    title={dataGrafica.data.clientes?.graficos.clientesMayores.titulo}
                                                    parametrizado={dataGrafica.data.clientes?.graficos.clientesMayores.parametrizado}
                                                    periodo={dataGrafica.data.clientes?.graficos.clientesMayores.periodo}
                                                    limite={dataGrafica.data.clientes?.graficos.clientesMayores.limite}
                                                    total={dataGrafica.data.clientes?.graficos.clientesMayores.total}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                    :
                                    <Card style={{ width: '100%', padding: '40px' }} className='shadow cardGraficaGeneral cardGraficaAnalistas'>
                                        <Card.Body>
                                            <h3 className='titleCard' style={{ textAlign: 'center' }}>{t('formulario.label.no_informacion_relacionada')}</h3>
                                        </Card.Body>
                                    </Card>
                            }
                        </>
                    :

                    // {
                    //     sectionOpen !== 'consolidado' || sectionOpen !== 'oportunidad' || sectionOpen !== 'novedad' || sectionOpen !== 'nivelRiesgo' || sectionOpen !== 'anotaciones'  || sectionOpen !== 'clientes'
                    //     // (sectionOpen !== ('consolidado'||'oportunidad'||'novedad'||'nivelRiesgo'||'anotaciones'||'clientes'))
                    //      ? (
                        (dataGrafica?.data?.din?.graficas && dataGrafica?.data?.din?.graficas?.length > 0
                        ?
                        dataGrafica?.data?.din?.graficas?.map((grafica, index) => {
                            return(
                                <div style={{width: '100%', marginBottom: '15px'}}>
                                <RenderGrafica key={index} grafica={grafica} />
                                </div>
                            )
                        })
                        :
                        <Card style={{ width: '100%', padding: '40px' }} className='shadow cardGraficaGeneral cardGraficaAnalistas'>
                            <Card.Body>
                                <h3 className='titleCard' style={{ textAlign: 'center' }}>{t('formulario.label.no_informacion_relacionada')}</h3>
                            </Card.Body>
                        </Card>)
                        //     )
                        // : null

                    }
                </>
            ) : null}
        </>
    );
}

export default PestanaGrafica;
