import React, { useEffect, useState } from 'react';
import { Form, Col, Card, Button, Row, ButtonGroup } from 'react-bootstrap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import { validarEntorno } from '../../helpers/validarHost';
import NotificationModal from '../modales/NotificationModal';
import EditModal from '../modales/EditModal';
import { useParams } from 'react-router-dom';
import '../gestionAuditorias/PanoramaRiesgos/PestanasInforme/styles/Factores.scss'
import useState2 from 'react-usestateref';
import { useForm } from 'react-hook-form';

import DeleteIcon from '@material-ui/icons/Delete';

/**
 * Tabla para enlistar los archivos, solo tienen funcion de buscar
 * @param {*} param0
 * @returns
 */
export default function TablaRegistrosArchivos({ tipo, deleteFile, data, type }) {


  const { id } = useParams();

  const HASH = window.location.hash;

  useEffect(() => {
    console.log(data, 'this is data')
    console.log(initialState.data, 'this is initialState')
  }, [])


  const [notificacionType, setNotificacionType] = useState('')
  const [notificacionShow, setNotificacionShow] = useState(false)
  const [notificacionMensaje, setNotificacionMensaje] = useState('')
  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false
  })

  const closeModal = () => {
    setNotificacionShow(false);
  }

  const getRiesgos = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: id
    }

    const req = await validarEntorno('JSON/GestionDinamica/tabla_registros_facturas.php', 'POST', dataReq)


    if (req.ok) {
      const res = await req.json()

      setInitialState({ ...initialState, data: res })
    }

  }

  useEffect(() => {
    getRiesgos();
  }, [])

  const getIconButton = (id, value, item) => {
    let icon = '';

    switch (value) {
      case 'eliminar':
        icon = <DeleteIcon className="iconReg deleteReg" onClick={() => deleteFile(item.name)} />;
        break;
      default:
        break;
    }

    return icon;
  }

  return (
    <>
      <Card>
        <Card.Body>
          {data?.length === 0
            ?
            <div id='mensaje_acts_vacias'>No hay registros relacionados</div>
            :
            <>
              <div className='table_reps' >
                {tipo !== 'listas_cargue' ? (
                  <>
                    <div id='cols'>
                      <div className='col_item'>Nombre</div>
                      {type !== 'listado_archivos' ? (<div className='col_item'>Descripción</div>) : null}
                      <div className='col_item'>{type === 'listado_archivos' ? 'Acciones' : 'Estado'}</div>
                      {/* <div className='col_item' id='resaltado_col' >
                      <CheckCircleIcon onClick={() => resaltarMarkerAll(!isAllRecords)}   style={{ cursor:'pointer', color: isAllRecords ? '#13cd19'  : 'rgb(220, 220, 220)' }} />
                    </div>  */}
                    </div>
                    <div id='regs' >
                      {data?.map((item, index) => {
                        console.log(item, 'esta es la data de registros archivos')
                        return (
                          <Card key={index} className='shadow reg_item_factores'>
                            <div className='colreg_factores name-files' key={index} style={{ display: 'inline-block', padding: '0 4px' }} >
                              {type === 'listado_archivos' ? item.name : item.archivo}
                            </div>
                            {type !== 'listado_archivos' ? (<div className='col_item'>{item.descripcion}</div>) : null}
                            <div className='colreg_factores'>
                              {type === 'listado_archivos' ? (
                                <div>
                                  {console.log(initialState, 'initialllll')}
                                  {initialState?.data?.botones_registro?.map((boton, index) => (
                                    (boton.mostrar ? (
                                      getIconButton(item.id, boton.icono, item)
                                    ) : null)
                                  ))}
                                </div>
                              ) : (
                                <div>
                                  {item.estado}
                                </div>
                              )}
                            </div>

                          </Card>
                        )
                      })
                      }
                    </div>
                  </>
                ) : <>
                  <div id='cols'>
                    {
                      data?.map((col, idx) => {
                        return (
                          <div className='col_item' key={idx}>
                            {col.name}
                          </div>
                        )
                      })
                    }
                  </div>
                  <div id='regs' >
                    {data?.map((item, index) => {
                      return (
                        <Card key={index} className='shadow reg_item_factores'>
                          {item.cols?.map((colreg, idx) => {
                            return (
                              <div className='colreg_factores' key={idx}>
                                {idx !== (item.cols.length - 1) ? colreg.valor : (<a href={colreg.valor}>Ver</a>)}
                              </div>
                            )
                          })}
                        </Card>
                      )
                    })
                    }
                  </div>
                </>}

              </div>
            </>
          }
          <div></div>
        </Card.Body>
      </Card>
      <NotificationModal
        isOpen={notificacionShow}
        setIsOpen={closeModal}
        typeModal={notificacionType}
        mensaje={notificacionMensaje}
      />
    </>
  )
}
