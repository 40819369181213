import React from 'react'

import { Card, Form } from 'react-bootstrap'


export default function InformacionRegistroEstudio(props) {

    const {data} = props

    const submitInfoRegistro = (e) => {
        e.preventDefault()
    }

    return (
    <Card className='shadow cardGeneral'>
        <Card.Body className='cardBodyInfoEstudio'>
        <br/>
        <h4 className='titleCard'>Información de estudio</h4>
        <br/>
        <Form noValidate validated={props.validated} onSubmit={submitInfoRegistro} id='formInfoEstudioInteligencia'>
            {data.editarInfoEstudio
            ?
            <>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Nombres</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='nombres' type='text'  placeholder='Nombres'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese los nombres</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Apellidos</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='Apellidos'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese los apellidos</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Tipo de identificación</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Tipo de identificación'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el tipo de identificación</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Número de identificación</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='Número de identificación'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el número de identificación</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Dirección</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Dirección'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la dirección</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Barrio</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='Barrio'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el barrio</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Departamento</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Departamento'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el departamento</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Ciudad</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='Ciudad'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la ciudad</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Teléfono</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Número de teléfono'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el número de teléfono</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Cargo al que aspira</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='Cargo'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el cargo al que aspira</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Celular</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Número de celular'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el número de celular</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Profesión / ocupación</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='Profesión'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la profesión</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Etapa</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Etapa'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la etapa</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Novedad</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='Novedad'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la novedad</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Cliente</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Cliente'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el cliente</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha de solicitud</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text' required  placeholder='Fecha de solicitud'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la fecha de solicitud</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Progreso</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Progreso'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el progreso</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Estado</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text' required  placeholder='Estado'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el estado</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Tipo de estudio</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Tipo de estudio'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el tipo de estudio</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Tipo de visita</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text' required  placeholder='Tipo de visita'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el tipo de visita</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha de finalización</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Fecha de finalización'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la fecha de finalización</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha entrega revisión de campo</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text' required  placeholder='Fecha entrega revisión de campo'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese fecha entrega revisión de campo</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Centro de costoss</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Centro de costos'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el centro de costos</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Cuenta ID</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text' required  placeholder='Cuenta ID'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese ID de cuenta</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                    <Form.Group className='inputGroupObservaciones'>
                        <Form.Label>Observaciones del cargo</Form.Label>
                        <div className='inputContainer'>
                            <Form.Control className='inputInfoEstudio observaciones' as='textarea' name='numeroSopSNN' type='text'  placeholder='Observaciones del cargo'
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Ingrese una observación sobre el cargo</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </div>
                <div className='filasPar'>
                    <Form.Group className='inputGroupObservaciones'>
                        <Form.Label>Descripción de viaticos</Form.Label>
                        <div className='inputContainer'>
                            <Form.Control className='inputInfoEstudio observaciones' as='textarea' name='numeroSopSNN' type='text'  placeholder='Descripción de viaticos'
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Ingrese una observación sobre el cargo</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </div>
                <div className='filasPar'>
                    <Form.Group className='inputGroupObservaciones'>
                        <Form.Label>Objetivo de la Visita</Form.Label>
                        <div className='inputContainer'>
                            <Form.Control className='inputInfoEstudio observaciones' as='textarea' name='numeroSopSNN' type='text'  placeholder='Objetivo de la Visita'
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Ingrese una observación sobre el cargo</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha entrega preliminar</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Fecha entrega preliminar'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Seleccione fecha entrega preliminar</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha finalización preliminar</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text' required  placeholder='Fecha finalización preliminar'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese fecha finalización preliminar</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <br/>
                <button className='confirmButton'> Enviar </button>
                
            </>
            :
            <>
            </>
            }

        </Form>
        </Card.Body>
    </Card>
    )
    }
