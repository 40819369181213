import React, { useState, useEffect } from 'react'
import { Col, Card, Form, Button, Tabs, Tab, ListGroup, Table, Image, Row } from 'react-bootstrap'
import reactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import Select from 'react-select';

import { DropzoneDialogBase, DropzoneArea } from 'material-ui-dropzone';
import { getBase64 } from '../../helpers/fileToBase64';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CloseIcon from '@material-ui/icons/Close';

import SortedBarChart from '../dashboard/SortedBarChart';
import ModalContent from '../gestionAuditorias/PlanEstrategico/ModalContent';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ImageIcon from '@material-ui/icons/Image';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import FormularioArchivos from '../GestionadorDinamico/FormularioArchivos';
import DropzoneFiles from '../../helpers/controles/dropzoneFiles';

import { BarrasLabelsHorizontalesMultibarra } from '../../components/dashboard/BarrasLabelsHorizontalesMultibarra.jsx'

import './EditModal.scss'
import { clippingParents } from '@popperjs/core';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'primereact/checkbox';
import Check from '../common/Check.jsx';

export default function EditModal(props) {

  const { fileObjects, setFileObjects } = props
  const { t } = useTranslation();

  // console.log(props?.dataModal?.data)
  // console.log(props.infoItem)

  // const history = useHistory()

  const [valorScroll, setValorScroll] = useState(0)

  const validarScroll = (e) => {
    // console.log(e.target.scrollTop)
    setValorScroll(e.target.scrollTop)
  }

  // //console.log(props.infoItem)

  // props.ele.imagen !== props.stateForm.image

  // //console.log(props.ele.imagen)
  // //console.log(props.stateForm.imagen)

  // //console.log(props.stateForm)

  // const [transformImage, setTransformImage] = useState()

  // const transformarImagen = async (imagen) => {
  //   // const base64image = await getBase64(imagen)

  //   // //console.log(base64image)

  //   // const data = imagen
  //   // //console.log(data)

  //   const reader = new FileReader();
  //   reader.readAsDataURL(imagen);

  //   //console.log(reader.result)

  //   // const dataURL = imagen?.toDataURL("image/png");
  //   // dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

  //   // //console.log(dataURL)
  // }

  // if(props.ele){
  //   transformarImagen(props.ele.imagen)
  // }



  const [addItem, setAddItem] = useState({
    titulo: '',
    descripcion: ''
  })

  const handleChangeAddItem = ({ target }) => {
    setAddItem({
      ...addItem,
      [target.name]: target.value
    })

    //console.log(addItem)
  }
  // if(props.dataRiesgo.rango){

  // }

  // //console.log(typeof(rango))

  const [showPestana, setShowPestana] = useState('causas')
  const [typeComponente, setTypeComponente] = useState('');
  const [zonacarga, setZonacarga] = useState(true)
  // const [fileObjects, setFileObjects] = useState([]);
  const [fileExist, setFileExist] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    setFileExist(props?.infoItem?.imagen && true)

  }, [props.typeModal])


  if (!props.isOpen) {
    return null;
  };

  const handleEditAccion = () => {
    setFileExist(false)
  }

  const envioDataFileFunction = async (file) => {
    let data = {
      file: file,
      hash: window.location.hash
    }

    console.log(file)

  }



  const handleFilesToAccion = () => {

  }

  const printInfo = (props) => {
    switch (props.typeModal) {
      case 'EditBeneficiario':

        return (
          <>
            {/* <h3 className='pagesTitles'>Editar beneficiario</h3>
            {props.children} */}
            <h3 className='pagesTitles'>{t('formulario.label.editar_beneficiario')}</h3>
            <div className='formEditBeneficiarioContainer'>
              <div>

              </div>
              <div className="" id='idContainerEditBeneficiario'>
                <select
                  defaultValue={props.beneficiarioEditar.tipo_identificacion}
                  name="tipo_identificacion"
                  onChange={props.handleInputChangeEdit}
                  className="noneStiles"
                  type='text'
                >
                  <option value='0' disabled selected>{t('formulario.label.tipo_identificacion')}</option>
                  <option value='2'>{t('formulario.select.cedula_ciudadania')}</option>
                  <option value='3'>{t('formulario.select.cedula_extranjeria')}</option>
                  <option value='4'>{t('formulario.select.tarjeta_identidad')}</option>
                  <option value='9'>{t('formulario.select.registro_civil')}</option>
                  <option value='5'>{t('formulario.select.pasaporte')}</option>
                </select>
                <input
                  defaultValue={props.beneficiarioEditar.identificacion}
                  type='number'
                  onChange={props.handleInputChangeEdit}
                  name="identificacion"
                />
              </div>
              <div className='nombresContenedor'>

                <div className='nombresContenedor'>
                  <Form.Group className='nombresContainerEditBeneficiario'>
                    <label>{t('formulario.label.nombres')}</label>
                    <Form.Control
                      placeholder={t('formulario.label.primer_nombre')}
                      value={props.primerNombreVal}
                      onChange={props.handleInputChangeEdit}
                      name='primerNombre'
                      required
                      style={{ width: '95%' }}
                    />
                    <Form.Control
                      placeholder={t('formulario.label.segundo_nombre')}
                      value={props.segundoNombreVal}
                      onChange={props.handleInputChangeEdit}
                      name='segundoNombre'
                      style={{ width: '95%' }}
                    />

                  </Form.Group>
                  <Form.Group className='nombresContainerEditBeneficiario'>
                    <label>{t('formulario.label.apellidos')}</label>
                    <Form.Control
                      placeholder={t('formulario.label.primer_apellido')}
                      value={props.primerApellidoVal}
                      onChange={props.handleInputChangeEdit}
                      name='primerApellido'
                      type='text'

                    />
                    <Form.Control
                      placeholder={t('formulario.label.segundo_apellido')}
                      value={props.segundoApellidoVal}
                      onChange={props.handleInputChangeEdit}
                      name='segundoApellido'
                      type='text'
                    />
                  </Form.Group>

                </div>
              </div>
              <Form.Group className='formGroupTextArea'
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Form.Label>{t('formulario.label.parentesco')}</Form.Label>
                <Form.Control
                  as='select'
                  defaultValue={props.beneficiarioEditar.parentesco}
                  name="parentesco"
                  onChange={props.handleInputChangeEdit}
                  // className="noneStiles2"
                  required
                >
                  <option disabled >{t('formulario.label.parentesco')}</option>
                  <option value="Padre/Madre">{t('formulario.select.padre_madre')}</option>
                  {/* <option value="Madre">Madre</option> */}
                  <option value="Hijo(a)">{t('formulario.select.hijo')}</option>
                  <option value="Hijo(a) Menor">{t('formulario.select.hijo_menor')}</option>
                  <option value="Hijo(a) Estudiante">{t('formulario.select.hijo_estudiante')}</option>
                  <option value="Hijo(a) invalido">{t('formulario.select.hijo_invalido')}</option>
                  <option value="Hermano(a)">{t('formulario.select.hermano')}</option>
                  <option value="Hermano(a) Invalido">{t('formulario.select.hermano_invalido')}</option>
                  <option value="Conyuge/Compañero(a)">{t('formulario.select.conyuge_companero')}</option>
                  <option value="Herederos indeterminados"> {t('formulario.select.herederos_indeterminados')}</option>
                  <option value="Esposo(a)"> {t('formulario.select.esposo')}</option>
                  <option value="Compañero(a)"> {t('formulario.select.companero')}</option>
                  <option value="Otro">{t('formulario.select.otro')}</option>
                </Form.Control>
              </Form.Group>
            </div>
            <Form.Group
              className='formEditBeneficiarioObservacionesContainer'
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Form.Label>{t('formulario.label.observaciones')}</Form.Label>
              <Form.Control
                as='textarea'
                required
                id='inputObservacionesBeneficiarios'
                defaultValue={props.beneficiarioEditar.observaciones}
                name="observaciones"
                onChange={props.handleInputChangeEdit}
              />
            </Form.Group>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={props.editHandler} >{t('botones.guardar')}</button>
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )

      case 'editSeguimiento':

        return (
          <>
            <h3 className='pagesTitles'>Editar seguimiento</h3>
            {props.children}
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={props.editHandler} >Editar</button>
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )

      case 'addObj':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.anadir_objetivo')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0', width: '85%' }}>

              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.objetivo')}
                </Form.Label>
                <Form.Control as='textarea'
                  placeholder={t('formulario.label.objetivo')}
                  name='descripcion'
                  style={{ minHeight: '100px' }}
                  onChange={(e) => props.handleAddItem(e)}
                >
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  {t('formulario.label.riesgos')}
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  style={{ textAlign: 'justify' }}
                  options={props.dataInicial.riesgos}
                  isMulti
                  // item={item}
                  onChange={(e) => props.handleSelect(e, 'obj')}
                  name='riesgos'
                  placeholder={t('formulario.label.seleccione')}

                // index={index}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  {t('formulario.label.responsables')}
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  style={{ textAlign: 'justify' }}
                  options={props.dataInicial?.responsables}
                  isMulti
                  // item={item}
                  onChange={(e) => props.handleSelectResponsables(e)}
                  name='responsables'
                  placeholder={t('formulario.label.responsables')}

                // index={index}
                />
              </Form.Group>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.sendNewItem('obj')}  >{t('botones.guardar')}</button>
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )

      case 'verObj':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.detalle_objetivo')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0', width: '85%', maxHeight: '85%' }}>

              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.objetivo')}
                </Form.Label>
                <Form.Control as='textarea'
                  disabled
                  value={props.descripcionObj}
                  placeholder={t('formulario.label.objetivo')}
                  name='descripcion'
                  style={{ minHeight: '100px' }}
                >
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  {t('formulario.label.riesgos_asociados')}
                </Form.Label>
                { }
                <Select
                  // closeMenuOnSelect={false}
                  options={props.dataInicial.riesgos}
                  value={props.stateAddItem.riesgos}
                  isMulti
                  // item={item}
                  name='riesgos'
                  placeholder={t('formulario.label.seleccione')}
                // index={index}
                />
              </Form.Group>

              {props?.stateAddItem.responsables !== undefined &&
                <Form.Group>
                  <Form.Label>
                    {t('formulario.label.responsables')}
                  </Form.Label>
                  <Select
                    style={{ textAlign: 'justify' }}
                    options={props.dataInicial?.responsables}
                    value={props.stateAddItem?.responsables}
                    isMulti
                    // item={item}
                    name='responsables'
                    placeholder={t('formulario.label.responsables')}

                  // index={index}
                  />
                </Form.Group>
              }
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              {/* <button className='confirmButton' onClick={() => props.sendNewItem('obj')}  >Guardar</button>   */}
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cerrar')}</button>
            </div>
          </>
        )

      case 'editObj':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.editar_objetivo')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0', width: '85%' }}>
              {/* <Form.Group className='formGroupModal'>
                  <Form.Label>
                  Titulo
                </Form.Label>
                <Form.Control
                  defaultValue={props.tituloObj}
                  type='text'
                  placeholder='Digita el nuevo objetivo'
                  name='titulo'
                  onChange={(e) =>  props.handleAddItem(e)}
                />


              </Form.Group> */}

              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.objetivo')}
                </Form.Label>
                <Form.Control as='textarea'
                  defaultValue={props.descripcionObj}
                  placeholder={t('formulario.label.objetivo')}
                  name='descripcion'
                  style={{ minHeight: '100px', minWidth: '100px' }}
                  onChange={(e) => props.handleAddItem(e)}
                >

                </Form.Control>


              </Form.Group>
              <Form.Group>
                <Form.Label>
                  {t('formulario.label.riesgos')}
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  style={{ textAlign: 'justify' }}
                  options={props.dataInicial.riesgos}
                  defaultValue={props.stateAddItem.riesgos}
                  isMulti
                  // item={item}
                  onChange={(e) => props.handleSelect(e, 'obj')}
                  name='riesgos'
                  placeholder={t('formulario.label.seleccione')}

                // index={index}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  {t('formulario.label.responsables')}
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  style={{ textAlign: 'justify' }}
                  options={props.dataInicial?.responsables}
                  defaultValue={props.stateAddItem?.responsables}
                  isMulti
                  // item={item}
                  onChange={(e) => props.handleSelectResponsables(e)}
                  name='responsables'
                  placeholder={t('formulario.label.responsables')}

                // index={index}
                />
              </Form.Group>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.updateItem('obj')}  >{t('botones.guardar')}</button>
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cerrar')}</button>
            </div>
          </>
        )
      case 'addMeta':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.anadir_meta')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0', width: '85%' }}>
              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.titulo')}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t('formulario.label.digita_nueva_meta')}
                  name='titulo'
                  onChange={(e) => props.handleAddItem(e)}
                  as='textarea'
                  style={{ minHeight: '70px' }}
                />


              </Form.Group>

              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.descripcion')}
                </Form.Label>
                <Form.Control as='textarea'
                  placeholder={t('formulario.label.anade_descripcion_meta')}
                  name='descripcion'
                  style={{ minHeight: '100px' }}
                  onChange={(e) => props.handleAddItem(e)}
                >

                </Form.Control>


              </Form.Group>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.sendNewItem('meta')}  >{t('botones.guardar')}</button>
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'verMeta':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.detalle_meta')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0', width: '85%' }}>
              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.titulo')}
                </Form.Label>
                <Form.Control
                  type='text'
                  disabled
                  value={props.tituloObj}
                  placeholder={t('formulario.label.digita_nueva_meta')}
                  name='titulo'
                  onChange={(e) => props.handleAddItem(e)}
                  as='textarea'
                  style={{ minHeight: '70px' }}
                />

              </Form.Group>

              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.descripcion')}
                </Form.Label>
                <Form.Control as='textarea'
                  disabled
                  value={props.descripcionObj}
                  placeholder={t('formulario.label.anade_descripcion_meta')}
                  name='descripcion'
                  style={{ minHeight: '100px' }}
                >

                </Form.Control>


              </Form.Group>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              {/* <button className='confirmButton' onClick={() => props.sendNewItem('obj')}  >Guardar</button>   */}
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cerrar')}</button>
            </div>
          </>
        )

      case 'editMeta':

        return (

          <>
            <h3 className='pagesTitles' >{t('formulario.label.editar_meta')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px', width: '100%', height: '30%', justifyContent: 'space-around' }}>
              {/*<Form.Group className='formGroupModal'>
                <Form.Control
                  type='text'
                  defaultValue={props.tituloObj}
                  placeholder='Digita la nueva meta'
                  name='titulo'
                  onChange={(e) =>  props.handleAddItem(e)}
                />

        </Form.Group>*/}

              <Form.Group className='formGroupModal' style={{ display: 'flex' }}>
                <Form.Control as='textarea'
                  defaultValue={props.descripcionObj}
                  placeholder={t('formulario.label.anade_descripcion_meta')}
                  name='descripcion'
                  style={{ minHeight: '100px', minWidth: '40vw', padding: '0%' }}
                  onChange={(e) => props.handleAddItem(e)}
                >

                </Form.Control>


              </Form.Group>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.updateItem('meta')}  >{t('botones.guardar')}</button>
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )
      case 'addActividad':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.anadir_actividad')}</h3>
            <div className="activities-section">
              <div className="section-title">
                {/* <h6>{ Object.keys(actividad).length > 0 ? `Editar ${actividad.nombre}` : 'Nueva actividad' }</h6> */}
              </div>
              <Form>
                <Form.Row style={{}}>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.proceso')}</Form.Label>
                      <Form.Control
                        as='select'
                        name='proceso'
                        defaultValue=''
                        placeholder={t('formulario.label.nombre_actividad')}
                        onChange={(e) => props.handleAddItem(e)}
                      >
                        <option value='' disabled >{t('formulario.label.seleccione')}</option>
                        <option value='1' >{t('formulario.select.administrativo')}</option>
                        <option value='2' >{t('formulario.select.comercial')}</option>
                        <option value='3' >{t('formulario.select.seguridad')}</option>
                        <option value='4' >{t('formulario.select.logistico')}</option>
                        <option value='5' >{t('formulario.select.operativo')}</option>
                        <option value='6' >{t('formulario.select.juridico')}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.nombre_actividad')}</Form.Label>
                      <Form.Control
                        name='titulo'
                        placeholder={t('formulario.label.nombre_actividad')}
                        onChange={(e) => props.handleAddItem(e)}
                        as='textarea'
                        style={{ minHeight: '70px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.responsable_cliente')}</Form.Label>
                      <Form.Control

                        name='responsable_cli'
                        placeholder={t('formulario.label.nombre_responsable')}
                        onChange={(e) => props.handleAddItem(e)}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.corresponsable_cliente')}</Form.Label>
                      <Form.Control

                        name='corresponsable_cli'
                        placeholder={t('formulario.label.nombre_corresponsable_cliente')}
                        onChange={(e) => props.handleAddItem(e)}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.responsable_cosinte')}</Form.Label>
                      <Form.Control

                        name='responsable'
                        placeholder={t('formulario.label.nombre_responsable_cosinte')}
                        onChange={(e) => props.handleAddItem(e)}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.periodicidad')}</Form.Label>
                      <Form.Control
                        as='select'
                        name='periodicidad'
                        placeholder={t('formulario.label.periodicidad')}
                        onChange={(e) => props.handleAddItem(e)}

                      >
                        <option value='' selected disabled >{t('formulario.label.seleccione')} </option>
                        <option>{t('formulario.select.mensual')}</option>
                        <option>{t('formulario.select.bimestral')}</option>
                        <option>{t('formulario.select.trimestral')}</option>
                        <option>{t('formulario.select.semestral')}</option>
                        <option>{t('formulario.select.anual')}</option>

                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicPassword">
                      <Form.Label>{t('formulario.label.descripcion_actividad')}</Form.Label>
                      <Form.Control
                        name='descripcion'
                        as="textarea" placeholder={t('formulario.label.descripcion')}
                        style={{ minHeight: '70px' }}
                        onChange={(e) => props.handleAddItem(e)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                {/* <Button onClick={() => setActivitySection(false)}>Enviar</Button> */}
              </Form>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              {
                // (props.stateAdd.titulo !== '' && props.stateAdd.responsable !== '') &&
                <button className='confirmButton' onClick={() => props.sendNewItem('actividad')}  >{t('botones.guardar')}</button>
              }
              <button className='deniedButton' onClick={() => {
                props.setIsOpen(false)
              }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )

      case 'verActividad':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.detalle_actividad')}</h3>
            <div className="activities-section scrollSecundario ">
              <Form >
                <Form.Row style={{}}>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.proceso')}</Form.Label>
                      <Form.Control
                        as='select'
                        name='proceso'
                        disabled
                        defaultValue={props.infoItem.proceso ? props.infoItem.proceso : ''}
                      // onChange={(e) => props.handleAddItem(e) }
                      >
                        <option value='' selected disabled >{t('formulario.label.seleccione')}</option>
                        <option value='1' >Administrativo</option>
                        <option value='2' >Comercial</option>
                        <option value='3' >Seguridad</option>
                        <option value='4' >Logístico</option>
                        <option value='5' >Operativo</option>
                        <option value='6' >Jurídico</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.actividad')}</Form.Label>
                      <Form.Control
                        value={props.infoItem.titulo}
                        disabled
                        name='titulo'
                        placeholder={t('formulario.label.nombre_actividad')}
                        as='textarea'
                        style={{ minHeight: '70px' }}
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.responsable_cliente')}</Form.Label>
                      <Form.Control
                        disabled
                        name='responsable_cli'
                        placeholder={t('formulario.label.nombre_responsable')}
                        defaultValue={props.infoItem.responsable_cli}
                      // onChange={(e) => props.handleEditItem(e) }

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.corresponsable_cliente')}</Form.Label>
                      <Form.Control
                        disabled

                        name='corresponsable_cli'
                        placeholder={t('formulario.label.nombre_corresponsable_cliente')}
                        defaultValue={props.infoItem.corresponsable_cli}
                      // onChange={(e) => props.handleEditItem(e) }

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.responsable_cosinte')}</Form.Label>
                      <Form.Control

                        disabled
                        name='responsable'
                        placeholder={t('formulario.label.nombre_responsable_cosinte')}
                        defaultValue={props.infoItem.responsable}
                      // onChange={(e) => props.handleEditItem(e) }

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.periodicidad')}</Form.Label>
                      <Form.Control
                        disabled
                        as='select'
                        name='periodicidad'
                        placeholder={t('formulario.label.periodicidad')}
                        defaultValue={props.infoItem.periodicidad ? props.infoItem.periodicidad : ''}
                      // onChange={(e) => props.handleEditItem(e) }

                      >
                        <option value='' selected disabled >--Seleccione-- </option>
                        <option>Mensual</option>
                        <option>Bimestral</option>
                        <option>Trimestral</option>
                        <option>Semestral</option>
                        <option>Anual</option>

                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicPassword">
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control
                        disabled
                        name='descripcion'
                        value={props.infoItem.descripcion}
                        as="textarea" placeholder={t('formulario.label.descripcion')}
                        style={{ minHeight: '70px' }}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                {/* <Button onClick={() => setActivitySection(false)}>Enviar</Button> */}
              </Form>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              {(props.stateAdd.titulo !== '' && props.stateAdd.responsable !== '') &&
                <button className='confirmButton' onClick={() => props.sendNewItem('actividad')}  >Guardar</button>
              }
              <button className='deniedButton' onClick={() => {
                props.setIsOpen(false)
              }}>{t('botones.cerrar')}</button>
            </div>
          </>
        )

      case 'editActividad':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.editar_actividad')}</h3>
            <div className="activities-section">
              <Form>
                <Form.Row style={{}}>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.proceso')}</Form.Label>
                      <Form.Control
                        as='select'
                        name='proceso'
                        defaultValue={props.infoItem.proceso ? props.infoItem.proceso : ''}
                        onChange={(e) => props.handleEditItem(e)}
                      >
                        <option value='' disabled >{t('formulario.label.seleccione')}</option>
                        <option value='1' >{t('formulario.select.administrativo')}</option>
                        <option value='2' >{t('formulario.select.comercial')}</option>
                        <option value='3' >{t('formulario.select.seguridad')}</option>
                        <option value='4' >{t('formulario.select.logistico')}</option>
                        <option value='5' >{t('formulario.select.operativo')}</option>
                        <option value='6' >{t('formulario.select.juridico')}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.actividad')}</Form.Label>
                      <Form.Control
                        as='textarea'
                        defaultValue={props.infoItem.titulo}
                        name='titulo'
                        placeholder={t('formulario.label.nombre_actividad')}
                        onChange={(e) => props.handleEditItem(e)}
                        style={{ minHeight: '70px' }}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.responsable_cliente')}</Form.Label>
                      <Form.Control

                        name='responsable_cli'
                        placeholder={t('formulario.label.nombre_responsable')}
                        defaultValue={props.infoItem.responsable_cli}
                        onChange={(e) => props.handleEditItem(e)}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.corresponsable_cliente')}</Form.Label>
                      <Form.Control

                        name='corresponsable_cli'
                        placeholder={t('formulario.label.nombre_corresponsable_cliente')}
                        defaultValue={props.infoItem.corresponsable_cli}
                        onChange={(e) => props.handleEditItem(e)}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.responsable_cosinte')}</Form.Label>
                      <Form.Control

                        name='responsable'
                        placeholder={t('formulario.label.nombre_responsable_cosinte')}
                        defaultValue={props.infoItem.responsable}
                        onChange={(e) => props.handleEditItem(e)}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicEmail">
                      <Form.Label className='labelEditForm'>{t('formulario.label.periodicidad')}</Form.Label>
                      <Form.Control
                        as='select'
                        name='periodicidad'
                        placeholder={t('formulario.label.periodicidad')}
                        defaultValue={props.infoItem.periodicidad}
                        onChange={(e) => props.handleEditItem(e)}

                      >
                        <option value='' selected disabled >{t('formulario.label.seleccione')}</option>
                        <option>{t('formulario.select.mensual')}</option>
                        <option>{t('formulario.select.bimestral')}</option>
                        <option>{t('formulario.select.trimestral')}</option>
                        <option>{t('formulario.select.semestral')}</option>
                        <option>{t('formulario.select.anual')}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupModal' controlId="formBasicPassword">
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control

                        name='descripcion'
                        defaultValue={props.infoItem.descripcion}
                        as="textarea" placeholder={t('formulario.label.descripcion')}
                        style={{ minHeight: '70px' }}
                        onChange={(e) => props.handleEditItem(e)}

                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                {/* <Button onClick={() => setActivitySection(false)}>Enviar</Button> */}
              </Form>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.sendEditItem('actividad')}  >{t('botones.guardar')}</button>
              <button className='deniedButton' onClick={() => {
                props.setIsOpen(false)
              }}>{t('botones.cerrar')}</button>
            </div>
          </>
        )


      case 'addAccion':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.anadir_accion')}</h3>
            <div className="activities-section scrollSecundario">

              <Form>
                <Form.Row style={{}}>
                  <Col xl={12}>
                    <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.accion')}</Form.Label>
                      <Form.Control
                        name='accion'
                        placeholder={t('formulario.label.digita_nueva_accion')}
                        onChange={(e) => props.handleAddItem(e)}
                        as='textarea'
                        style={{ minHeight: '70px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Row xs={12} lg={12} md={12} style={{ width: '100%' }}>
                    <Col  >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label>{t('formulario.label.agendamiento')}</Form.Label>
                        <Form.Control
                          type='date'
                          name='fecha'
                          onChange={(e) => props.handleAddItem(e)}

                        />
                      </Form.Group>
                    </Col>
                    <Col >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label>{t('formulario.label.realizacion_cliente')}</Form.Label>
                        <Form.Control
                          type='date'
                          name='fecha_real_cli'
                          onChange={(e) => props.handleAddItem(e)}

                        />
                      </Form.Group>
                    </Col>
                    <Col >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: '14px', textAlign: 'justify' }}>{t('formulario.label.realizacion_gerente_riesgos')}</Form.Label>
                        <Form.Control
                          type='date'
                          name='fecha_real_gr'
                          onChange={(e) => props.handleAddItem(e)}

                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Col xl={12}>
                                <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                                    <Form.Label>fecha</Form.Label>
                                    <Form.Control
                                      type='date'
                                      name='fecha'
                                      onChange={(e) => props.handleAddItem(e) }

                                    />
                                </Form.Group>
                            </Col> */}
                  <Col xl={6}>
                    <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label>{props?.infoItem?.label ? props?.infoItem?.label : 'Cumplimiento'}</Form.Label>
                      <Form.Control
                        as='select'
                        name='cumplimiento'
                        onChange={(e) => props.handleAddItem(e)}
                      >
                        <option disabled selected>{t('formulario.label.seleccione')} </option>
                        <option value='1' >Realizada</option>
                        <option value='0' >No realizada</option>
                        <option value='2' >{t('formulario.select.en_proceso')}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={6}>
                    <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.cumplimiento_cosinte')}</Form.Label>
                      <Form.Control
                        as='select'
                        name='cumplimiento_cos'
                        onChange={(e) => props.handleAddItem(e)}
                      >
                        <option disabled selected>{t('formulario.label.seleccione')}</option>
                        <option value='1' >Realizada</option>
                        <option value='0' >No realizada</option>
                        <option value='2' >En proceso</option>

                      </Form.Control>

                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupVertical' controlId="formBasicPassword">
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control
                        name='descripcion'
                        as="textarea" placeholder={t('formulario.label.descripcion')}
                        style={{ minHeight: '70px' }}
                        onChange={(e) => props.handleAddItem(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <br />
                    {/* <Form.Group className='formGroupModal' controlId="formBasicPassword">
                                    <Form.Label>Descripción</Form.Label>
                                    <Form.Control
                                      name='descripcion'
                                      as="textarea" placeholder="Descripcion"
                                      style={{minHeight:'70px'}}
                                      onChange={(e) => props.handleAddItem(e) }
                                    />
                                </Form.Group> */}
                    {/* <DropzoneArea
                                  filesLimit={10}
                                  open={zonacarga}
                                  dialogTitle='Subir archivos'
                                  dropzoneText='Arrastra los archivos aqui o haz clic en el ícono'
                                  acceptedFiles={['image/*', 'application/pdf']}
                                  maxFileSize={10000000}
                                  cancelButtonText={"Cancelar"}
                                  submitButtonText={"Cargar"}
                                  fileObjects={fileObjects}
                                  onClose={() => setZonacarga(false)}
                                  // onSave={selectedHandler}
                                  className='zonaDeCarga'
                                  previewText='Vista previa'
                                  onAdd={newFileObjs =>
                                      setFileObjects([].concat(fileObjects, newFileObjs))
                                  }
                                  onDelete={deleteFileObj => {
                                  // setFileObjects([].splice(fileObjects[-1]))
                                  // setFileObjects(() => ({...fileObjects, fileObjects.pop()}})
                                  }}
                                  onSave={() => {
                                  setZonacarga(false);
                                  envioDataFileFunction(fileObjects)
                                  setFileObjects([])
                                  }}
                                  showFileNamesInPreview={false}
                              /> */}

                    {/* <FormularioArchivos
                                fileObjects={fileObjects}
                                setFileObjects={setFileObjects}
                                titulo={'Evidencias'}
                              /> */}






                  </Col>
                </Form.Row>
                {/* <Button onClick={() => setActivitySection(false)}>Enviar</Button> */}
              </Form>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              {
                <button className='confirmButton' onClick={() => props.sendNewItem('accion')}  >{t('botones.guardar')}</button>
              }
              <button className='deniedButton' onClick={() => {
                props.setIsOpen(false)
              }}>{t('botones.cancelar')}</button>
            </div>
          </>
        )

      case 'verAccion':

        return (

          <>
            <h3 className='pagesTitles' style={{ marginBottom: '20px' }}>{t('formulario.label.detalle_accion')}</h3>
            <div className="activities-section">
              <Form>
                <Form.Row style={{}}>
                  <Col xl={12}>
                    <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.accion')}</Form.Label>
                      <Form.Control
                        value={props.infoItem.titulo}
                        disabled
                        name='titulo'
                        placeholder="Nombre de la acción aca"
                        as='textarea'
                        style={{ minHeight: '70px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Row xs={12} lg={12} md={12} style={{ width: '100%' }}>
                    <Col  >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label>{t('formulario.label.agendamiento')}</Form.Label>
                        <Form.Control
                          value={props.infoItem.fecha}
                          disabled
                          type='date'
                          name='fecha'
                        />
                      </Form.Group>
                    </Col>
                    <Col >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label>{t('formulario.label.realizacion_cliente')}</Form.Label>
                        <Form.Control
                          value={props.infoItem.fecha_real_cli}
                          disabled
                          type='date'
                          name='fecha_real_cli'
                        />
                      </Form.Group>
                    </Col>
                    <Col >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: '14px', textAlign: 'justify' }}>{t('formulario.label.realizacion_gerente_riesgos')}</Form.Label>
                        <Form.Control
                          value={props.infoItem.fecha_real_gr}
                          disabled
                          type='date'
                          name='fecha_real_gr'
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <Col xl={6}>
                    <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.cumplimiento')}</Form.Label>
                      <Form.Control
                        as='select'
                        disabled
                        value={props.infoItem.cumplimiento}
                        name='cumplimiento'
                      >
                        <option disabled selected>{t('formulario.label.seleccione')}</option>
                        <option value='1' >Realizada</option>
                        <option value='0' >No realizada</option>
                        <option value='2' >En proceso</option>
                      </Form.Control>
                    </Form.Group>

                  </Col>
                  <Col xl={6}>
                    <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.cumplimiento_cosinte')}</Form.Label>
                      <Form.Control
                        as='select'
                        disabled
                        value={props.infoItem.cumplimiento_cos}
                        name='cumplimiento_cos'
                      >
                        <option disabled selected>{t('formulario.label.seleccione')} </option>
                        <option value='1' >Realizada</option>
                        <option value='0' >No realizada</option>
                        <option value='2' >En proceso</option>

                      </Form.Control>

                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupVertical' controlId="formBasicPassword">
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control
                        name='descripcion'
                        value={props.infoItem.descripcion}
                        disabled
                        as="textarea" placeholder="Descripcion"
                        style={{ minHeight: '70px' }}
                      />
                    </Form.Group>
                  </Col>
                  {props?.infoItem?.evidencias?.length &&
                    <Col xl={12}>
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label>Evidencia</Form.Label>
                        <div className='enlaces_container' style={{ margin: '8px 0 0' }} >
                          {props?.infoItem?.evidencias?.map(evidencia => {
                            return (
                              <a className='enlaces_item' href={evidencia.link} target='_blank'>
                                <div>{evidencia.nombre}</div>
                              </a>
                            )
                          })

                          }

                        </div>
                      </Form.Group>
                    </Col>
                  }
                </Form.Row>
              </Form>
            </div>
            <br></br>
            <div className='buttonModalContainer'>

              <button className='deniedButton' onClick={() => {
                props.setIsOpen(false)
              }}>
                {t('botones.cerrar')}
              </button>
            </div>
          </>
        )

      case 'editAccion':

        return (

          <>
            <h3 className='pagesTitles'>{t('formulario.label.editar_accion')}</h3>
            <div className="activities-section">
              <Form>
                <Form.Row style={{}}>
                  <Col xl={12}>
                    <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label>{t('formulario.label.accion')}</Form.Label>
                      <Form.Control
                        defaultValue={props.infoItem.titulo}
                        name='titulo'
                        placeholder={t('formulario.label.nombre_accion')}
                        onChange={(e) => props.handleEditItem(e)}
                        as='textarea'
                        style={{ minHeight: '70px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Row xs={12} lg={12} md={12} style={{ width: '100%' }}>
                    <Col  >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label>{t('formulario.label.agendamiento')}</Form.Label>
                        <Form.Control
                          defaultValue={props.infoItem.fecha}
                          type='date'
                          name='fecha'
                          onChange={(e) => props.handleEditItem(e)}

                        />
                      </Form.Group>
                    </Col>
                    <Col >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label>{t('formulario.label.realizacion_cliente')}</Form.Label>
                        <Form.Control
                          defaultValue={props.infoItem.fecha_real_cli}
                          type='date'
                          name='fecha_real_cli'
                          onChange={(e) => props.handleEditItem(e)}

                        />
                      </Form.Group>
                    </Col>
                    <Col >
                      <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: '14px', textAlign: 'justify' }}>{t('formulario.label.realizacion_gerente_riesgos')}</Form.Label>
                        <Form.Control
                          defaultValue={props.infoItem.fecha_real_gr}
                          type='date'
                          name='fecha_real_gr'
                          onChange={(e) => props.handleEditItem(e)}

                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <Col xl={6}>
                    <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label style={{ textAlign: 'initial' }}>{props?.infoItem?.label_cumplimiento ? props?.infoItem?.label_cumplimiento : "Cumplimiento"}</Form.Label>
                      <Form.Control
                        as='select'
                        defaultValue={props.infoItem.cumplimiento}
                        name='cumplimiento'
                        onChange={(e) => props.handleEditItem(e)}
                      >
                        <option >{t('formulario.label.seleccione')} </option>
                        <option value='1' >Realizada</option>
                        <option value='0' >No realizada</option>
                        <option value='2' >{t('formulario.select.en_proceso')}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={6}>
                    <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className='formGroupVertical' controlId="formBasicEmail">
                      <Form.Label style={{ textAlign: 'initial' }}>{props?.infoItem?.label_cumplimiento_cos ? props?.infoItem?.label_cumplimiento_cos : 'Cumplimiento Cosinte'}</Form.Label>
                      <Form.Control
                        as='select'
                        defaultValue={props.infoItem.cumplimiento_cos}
                        name='cumplimiento_cos'
                        onChange={(e) => props.handleEditItem(e)}
                      >
                        <option>{t('formulario.label.seleccione')} </option>
                        <option value='1' >Realizada</option>
                        <option value='0' >No realizada</option>
                        <option value='2' >En proceso</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group className='formGroupVertical' controlId="formBasicPassword">
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control

                        name='descripcion'
                        defaultValue={props.infoItem.descripcion}
                        as="textarea" placeholder={t('formulario.label.descripcion')}
                        style={{ minHeight: '70px' }}
                        onChange={(e) => props.handleEditItem(e)}

                      />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <br />
                    <Form.Label>{t('formulario.label.evidencia')}</Form.Label>

                    <DropzoneFiles
                      item={{
                        "nombre": "soportes_accion",
                        "tipo": "15",
                        "valores_opcion": [],
                        "valor": props?.infoItem?.evidencias ? props?.infoItem?.evidencias : [],
                        "titulo": t('formulario.label.soportes'),
                        "col": "12",
                        "es_requerido": false,
                        "id_subnivel": 1,
                        "seccion": "accion",
                        "idGestion": props?.infoItem?.id_accion,
                        "id_gestion": props?.infoItem?.id_accion
                      }}
                    // nombre={nombre}
                    // reg={reg}
                    // error={itemError}
                    // esEditable={esEditable}
                    // index={index}
                    // lastIndex={lastIndex}
                    // idGestion={idGestion}
                    // esMultiple={esMultiple}
                    />


                    {
                      <>
                        {/* <FormularioArchivos
                                fileObjects={fileObjects}
                                setFileObjects={setFileObjects}
                                titulo={'Evidencias'}
                              /> */}
                        {/* <DropzoneArea
                                filesLimit={10}
                                open={zonacarga}
                                dialogTitle='Subir archivos'
                                dropzoneText='Arrastra los archivos aqui o haz clic en el ícono'
                                acceptedFiles={['image/*', 'application/pdf']}
                                maxFileSize={10000000}
                                cancelButtonText={"Cancelar"}
                                submitButtonText={"Cargar"}
                                fileObjects={fileObjects}
                                onClose={() => setZonacarga(false)}
                                className='zonaDeCarga'
                                previewText='Vista previa'
                                onAdd={newFileObjs => {
                                    setFileObjects([].concat(fileObjects, newFileObjs));
                                }}
                                onDelete={deleteFileObj => {
                                }}
                                onSave={() => {
                                setZonacarga(false);
                                envioDataFileFunction(fileObjects)
                                setFileObjects([])
                                }}
                                showFileNamesInPreview={false}
                              /> */}
                        <br />
                        {/* // props?.infoItem?.evidencias?.length && */}
                        {/* <Col xl={12}>
                                  <Form.Group className='formGroupVertical' controlId="formBasicEmail">
                                    <Form.Label>Evidencia</Form.Label>
                                    <div className='enlaces_container' style={{margin: '8px 0 0'}} >
                                      {props?.infoItem?.evidencias?.length ?
                                      props?.infoItem?.evidencias?.map( evidencia => {
                                        return(
                                          <div key={evidencia.id} className='enlaces_item'>
                                            <a style={{width: '95%', textAlign: 'justify' }}  href={evidencia.link} target='_blank'>
                                              <div>{evidencia.nombre}</div>
                                            </a>
                                            <div
                                              style={{zIndex: '2' }}
                                              className='circular_btn'
                                              onClick={() => props.deleteEvidencia(evidencia.id)}
                                            >
                                              <CloseIcon style={{fontSize: '12px'}} />
                                            </div>

                                          </div>
                                        )
                                      })

                                      : <div id='mensaje_acts_vacias'>No hay registros relacionados</div>

                                      }

                                    </div>
                                  </Form.Group>
                                </Col> */}
                      </>
                    }

                    {/* {!fileObjects.length
                                ?
                                <FormularioArchivos
                                  fileObjects={fileObjects}
                                  setFileObjects={setFileObjects}
                                  titulo={'Evidencias'}
                                />
                                // <DropzoneArea
                                //   filesLimit={1}
                                //   open={zonacarga}
                                //   dialogTitle='Subir archivos'
                                //   dropzoneText='Arrastra los archivos aqui o haz clic en el ícono'
                                //   acceptedFiles={['image/*', 'application/pdf']}
                                //   maxFileSize={10000000}
                                //   cancelButtonText={"Cancelar"}
                                //   submitButtonText={"Cargar"}
                                //   fileObjects={fileObjects}
                                //   onClose={() => setZonacarga(false)}
                                //   className='zonaDeCarga'
                                //   previewText='Vista previa'
                                //   onAdd={newFileObjs => {
                                //       setFileObjects([].concat(fileObjects, newFileObjs));
                                //   }}
                                //   onDelete={deleteFileObj => {
                                //   }}
                                //   onSave={() => {
                                //   setZonacarga(false);
                                //   envioDataFileFunction(fileObjects)
                                //   setFileObjects([])
                                //   }}
                                //   showFileNamesInPreview={false}
                                // />
                                : props?.infoItem?.imagen && fileExist === true &&
                                <Form.Group
                                  controlId="formBasicEmail"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    flexWrap: 'wrap'
                                  }}
                                >
                                  <div className='evidenciaItem' style={{margin: '8px 0 0'}} >
                                    <div style={{zIndex: '1', marginBottom: '-20px',display:'flex', justifyContent: 'flex-end', width: '100%'}}>
                                      <button
                                        className='btn_circular_white'
                                        onClick={() => handleEditAccion()}
                                      >
                                        X
                                      </button>
                                    </div>
                                    <img
                                      className='shadow evidenciaItem'
                                      width="100%"
                                      height="220"
                                      src={props.infoItem.imagen}
                                      alt='imagen de evidencia'
                                      style={{
                                        borderRadius: '10px',
                                        margin: '5px 0'
                                      }}
                                    />

                                  </div>

                                </Form.Group>


                              } */}
                  </Col>
                </Form.Row>
                {/* <Button onClick={() => setActivitySection(false)}>Enviar</Button> */}
              </Form>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.sendEditItem('accion')}  >{t('botones.guardar')}</button>
              <button className='deniedButton' onClick={() => {
                props.setIsOpen(false)
              }}>{t('botones.cerrar')}</button>
            </div>
          </>
        )


      case 'addTarea':
        return (
          <>
            <h3 className='pagesTitles'>{t('formulario.label.anadir_tarea')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0', width: '85%' }}>
              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.titulo')}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t('formulario.label.digita_nueva_tarea')}
                  name='titulo'
                  onChange={(e) => props.handleAddItem(e)}
                  as='textarea'
                  style={{ minHeight: '70px' }}
                />


              </Form.Group>

              <Form.Group className='formGroupModal'>
                <Form.Label>
                  {t('formulario.label.descripcion')}
                </Form.Label>
                <Form.Control as='textarea'
                  placeholder={t('formulario.label.anade_descripcion_tarea')}
                  name='descripcion'
                  style={{ minHeight: '100px' }}
                  onChange={(e) => props.handleAddItem(e)}
                >

                </Form.Control>


              </Form.Group>
            </div>
            <br></br>
            <div className='buttonModalContainer'>
              <button className='confirmButton' onClick={() => props.sendNewItem('tarea')}  >Guardar</button>
              <button className='deniedButton' onClick={() => { props.setIsOpen(false) }}>Cancelar</button>
            </div>
          </>
        )

      default:
        break;
    }
  }

  const llamadaComponentes = (tipoComponente, idDepartamento) => {
    // //console.log(tipoComponente, idDepartamento)
    setTypeComponente(tipoComponente)
    props.getComponentes(idDepartamento, tipoComponente)

  }

  const backgroundImpact = (value) => {
    let bc = "#ffffff";

    if (value >= 1 && value <= 2) {
      bc = "#5B99C0";
    }
    else if (value >= 3 && value <= 4) {
      bc = "#009688";
    }
    else if (value >= 5 && value <= 6) {
      bc = "#FFC000";
    }
    else if (value >= 8 && value <= 12) {
      bc = "#F58617"
    } else if (value >= 15) {
      bc = "#ff3a3a";
    } else {
      bc = "#ffffff";
    }


    return bc;
  }

  const impactText = (value) => {
    let impact = "";

    switch (value) {
      case 1:
        impact = t('formulario.select.minimo')
        break;
      case 2:
        impact = t('formulario.select.leve')
        break;
      case 3:
        impact = t('formulario.select.moderado')
        break;
      case 4:
        impact = t('formulario.select.alto')
        break;
      case 5:
        impact = t('formulario.select.critico')
        break;
      default:
        impact = "-"
        break;
    }
    return impact;
  }


  if (props.typeModal === 'detalleRiesgo') {

    const rango = parseInt(props?.estadoSelected === 'actual' ? props.dataRiesgo.rango : props.dataRiesgo.prev_state.rango)


    return reactDOM.createPortal(
      <div className='detalleRiesgoContainer'>
        <div className='detalleRiesgoModal'>
          <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button>
          <div className={(rango >= 13 && rango <= 25) ? 'detalleRiesgoCritico' : (rango >= 5 && rango <= 12) ? 'detalleRiesgoAltoHeader' : 'detalleRiesgoHeader'}>
            <p className='tituloModalRiesgo'>{props.dataRiesgo.tipo}</p>
            <div className='infoGeneralRiesgo' style={{ backgroundColor: (rango >= 13 && rango <= 25) ? `rgba(0, 0, 0, 0.067)` : (rango >= 5 && rango <= 12) ? `#F99F4C` : '#ffcd2d' }} >
              <div id='contenedorProbabilidadImpacto' style={{ display: 'flex', flexDirection: 'column' }}>
                <p className='labelInfoGeneral' >{t('formulario.label.probabilidad')}</p>
                <p className='dataInfoGeneral'>{props?.estadoSelected === 'actual' ? props.dataRiesgo.probabilidad : props.dataRiesgo.prev_state.probabilidad}</p>
                <p className='labelInfoGeneral'>{t('formulario.label.impacto')}</p>
                <p className='dataInfoGeneral'>{props?.estadoSelected === 'actual' ? props.dataRiesgo.impacto : props.dataRiesgo.prev_state.impacto}</p>
              </div>
              <div className='rangoRiesgo' style={{ top: '0' }} >
                <p id='numeroRango' >{props?.estadoSelected === 'actual' ? props.dataRiesgo.rango : props.dataRiesgo.prev_state.rango}</p>
                <p id='labelRango' className='labelInfoGeneral' >{t('formulario.label.rango')}</p>
              </div>
            </div>

            <div style={{
              display: "flex",
              position: "absolute",
              top: "41%",
              justifyContent: "flex-end",
              right: "18%",
              color: "white",
              fontSize: "1rem",
              fontWeight: "bolder"
            }}
            >
              {props?.estadoSelected === 'actual' ? props?.dataRiesgo.fecha : props?.dataRiesgo.prev_state.fecha}
            </div>

          </div>
          <div className='detalleRiesgoMain' style={{ height: '100%', overflowY: 'auto' }}> {/* Permitir scroll en el contenedor principal */}
            <div className='contenedorPestanasRiesgo'>
              <button
                className={showPestana === 'causas' ? 'pestanaActive' : 'pestanaRiesgo'}
                onClick={() => setShowPestana('causas')}
              >
                {t('modales.causas')}
              </button>
              <button
                className={showPestana === 'consecuencias' ? 'pestanaActive' : 'pestanaRiesgo'}
                onClick={() => setShowPestana('consecuencias')}
              >
                {t('modales.consecuencias')}
              </button>
              <button
                className={showPestana === 'acciones' ? 'pestanaActive' : 'pestanaRiesgo'}
                onClick={() => setShowPestana('acciones')}
              >
                {t('modales.acciones')}
              </button>
              <button
                className={showPestana === 'responsable' ? 'pestanaActive' : 'pestanaRiesgo'}
                onClick={() => setShowPestana('responsable')}
              >
                {t('modales.responsable')}
              </button>
            </div>
            <div className='infoPestanaRiesgo' style={{ overflowY: 'auto', height: 'calc(100% - 50px)' }}> {/* Ajusta la altura según sea necesario */}
              <Form.Control
                className='scrollSecundario textContent'
                disabled
                as='textarea'
                style={{
                  cursor: 'text',
                  backgroundColor: '#e0e0e0',
                  resize: 'none',
                  textAlign: 'justify',
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden', // Eliminar scrollbar dentro del textarea
                }}
                value={
                  showPestana === 'causas'
                    ? props.dataRiesgo.prev_state.causas
                    : showPestana === 'consecuencias'
                      ? props.dataRiesgo.prev_state.consecuencias
                      : showPestana === 'acciones'
                        ? props.dataRiesgo.prev_state.acciones
                        : props.dataRiesgo.prev_state.responsable
                }
              />
            </div>
          </div>

          {props.dataRiesgo.prev_state &&
            <div className='handlerstatus_cont' >
              {props?.estadoSelected === 'actual' &&
                <div
                  className='handlerstatus_btn '
                  id='status_prev'
                  data-status={t('botones.estado_previo')}
                  onClick={() => props?.setEstadoSelected('previo')}
                >
                  {t('botones.estado_previo')}
                </div>
              }
              {props?.estadoSelected === 'previo' &&
                <div
                  className='handlerstatus_btn'
                  id='status_act'
                  data-status={t('botones.estado_actual')}
                  onClick={() => props?.setEstadoSelected('actual')}

                >
                  {t('botones.estado_actual')}
                </div>
              }
            </div>
          }
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  }
  else if (props.typeModal === 'detalleNormativa') {

    const { porcentaje } = props.dataRiesgo
    const { porcentaje_ini } = props.dataRiesgo

    const parsePorcentaje = Number(porcentaje || porcentaje_ini)

    return reactDOM.createPortal(
      <div className='detalleNormativaContainer'>
        <div className='detalleNormativaModal'>
          <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button>
          <div className={`detalleNormativaHeader ${parsePorcentaje < 20 ? 'normatividadBaja ' : parsePorcentaje >= 20 && parsePorcentaje < 80 ? 'normatividadMedia' : parsePorcentaje >= 80 ? 'normatividadAlta' : ''}`} >

            <p className='tituloModalNormativa'>{props.dataRiesgo.titulo}</p>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {porcentaje_ini &&
                <div className="porcentajeItem" style={{ borderRight: porcentaje ? '1px solid white ' : 'none' }} >
                  <div className='porcentajeModalNormativa' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '7px' }}>
                    <div>
                      {porcentaje_ini}

                    </div>
                    <div style={{ fontSize: '2rem' }} >
                      %
                    </div>

                  </div>
                  <p style={{ fontSize: '0.9rem' }} >Inicial</p>

                </div>

              }
              {porcentaje &&
                <div className="porcentajeItem" >
                  <div className='porcentajeModalNormativa' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '7px' }}>
                    <div>
                      {porcentaje}

                    </div>
                    <div style={{ fontSize: '2rem' }} >
                      %
                    </div>

                  </div>
                  <p style={{ fontSize: '0.9rem' }} >Actual</p>

                </div>

              }
              {/* {props.dataRiesgo.porcentaje &&
                <p className='porcentajeModalNormativa'>{props.dataRiesgo.porcentaje }%</p>


              } */}

            </div>
            <p className='descripcionModalNormativa'>{props.dataRiesgo.descripcion}</p>

          </div>

        </div>
      </div>,
      document.getElementById('EditModal')
    )

  } else if (props.typeModal === 'detalleAreaDiagnostico') {
    return reactDOM.createPortal(
      <div className='areaDiagnosticoModal'>
        <Card className='cardAreaDiagnostico'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <div style={{ padding: '25px 48px 0', height: '100%' }}>
            {props.dataRiesgo.titulo &&
              <h4 className='pagesTitles'>{props.dataRiesgo.titulo}</h4>
            }
            <BarrasLabelsHorizontalesMultibarra
              data={props.dataRiesgo.grafica.data}
            />
            {/* <SortedBarChart
              height='33vh'
              data={props.dataRiesgo.grafica.data}
              title={props.dataRiesgo.titulo}
              origen='detalleAreaDiagnostico'
            /> */}
            {/* <button className='closeButtonContainerDown' onClick={() => props.setIsOpen(false)}>Cerrar</button> */}
          </div>

        </Card>

      </div>,
      document.getElementById('EditModal')
    )


  } else if (props.typeModal === 'modalSelectsComponente') {


    return reactDOM.createPortal(
      <div className='componentesModal'>
        <div className='cardComponentes'>
          <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button>
          <br />
          <h3 className='pagesTitles'>{t('modales.componentes_comparacion')}</h3>
          <br />
          <p style={{ marginTop: '1rem' }}>{t('modales.seleccione_si_desea')}</p>
          <br />
          <div className="select-button" style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: "1rem 0" }} >
            <button className="confirmButton" onClick={() => llamadaComponentes('clientes', props.idDepartamento)}>{t('botones.clientes')}</button>
            <button className="confirmButton" onClick={() => llamadaComponentes('años', props.idDepartamento)} >{t('botones.anios')}</button>
          </div>

          {props.showLista === true &&
            <>
              <p style={{ marginTop: '1rem', marginBottom: '1em' }}>{t('modales.elija_valores_filtrar')}</p>

              <div className='contenedorSelect'>

                {typeComponente === 'clientes' &&
                  <Select
                    name="clientes"
                    width='100%'
                    options={props.listaComponente.clientes}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(choice) => props.setComponenteSeleccionado(choice)}
                    isMulti={true}>
                  </Select>
                }

                {typeComponente === 'años' &&
                  <Select
                    name="años"
                    width='100%'
                    options={props.listaComponente.years}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(choice) => props.setComponenteSeleccionado(choice)}
                    isMulti={true}>
                  </Select>
                }

                {/*typeComponente === 'clientes' && props.listaComponente.clientes.map((item, index) => {
                  return (
                    <>
                      <label className='componenteItem'
                        style={{
                          color: 'black'
                        }}
                      >
                        <input
                          className='chkComponentes'
                          type='checkbox'
                          key={index}
                          value={item.id}
                          onClick={(e) => props.getComponenteSeleccionado(e, item)}

                        />
                        {`  ${item.nombre}`}
                      </label>
                    </>
                  )
                })*/}

                {/*
                  typeComponente === 'años' && props.listaComponente.years.map((item, index) => {
                    return (
                      <>
                        <label
                          className='componenteItem'
                          style={{
                            color: 'black'
                          }}
                        >
                          <input
                            className='chkComponentes'
                            type='checkbox'
                            key={index}
                            value={item.id}
                            onClick={(e) => props.getComponenteSeleccionado(e, item)}
                          />
                          {`  ${item.nombre}`}
                        </label>
                      </>
                    )
                  })
                */}
              </div>
              <br />
              <div className="select-button" style={{ display: 'flex', width: '100%', justifyContent: 'center' }}  >
                <button onClick={() => props.fillComponentToInputsFilters()} className="confirmButton">{t('botones.confirmar')}</button>
              </div>
            </>
          }
          {/* {props.children} */}
        </div>

      </div>,
      document.getElementById('EditModal')
    )


  } else if (props.typeModal === 'modalSelectsComponentePanelAuditorias') {

    return reactDOM.createPortal(
      <div className='componentesModal'>
        <div className='cardComponentes'>
          <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button>
          <br />
          <h3 className='pagesTitles'>Componentes de comparación</h3>
          <br />
          <p>Seleccione si desea comparar por registros o años</p>
          <br />
          <div className="select-button" style={{ display: 'flex', width: '100%', justifyContent: 'center' }} >
            <button className="confirmButton" onClick={() => llamadaComponentes('clientes', props.idDepartamento)}>Registros</button>
            <button className="confirmButton" onClick={() => llamadaComponentes('años', props.idDepartamento)} >Años</button>
          </div>

          {props.showLista === true &&
            <>
              <p style={{ marginTop: '1em', marginBottom: '1em' }}> Elija los {typeComponente} para filtrar la información del panel </p>
              <div className='contenedorComponentes'>



                {typeComponente === 'clientes' && props.listaComponente.clientes.map((item, index) => {
                  return (
                    <>
                      <label className='componenteItem'>
                        <input
                          className='chkComponentes'
                          type='checkbox'
                          key={index}
                          value={item.id}
                          onClick={(e) => props.getComponenteSeleccionado(e, item)}
                        />
                        {`  ${item.nombre}`}
                      </label>
                    </>
                  )
                })}

                {


                  typeComponente === 'años' && props.listaComponente.years.map((item, index) => {
                    return (
                      <>
                        <label className='componenteItem'>
                          <input
                            className='chkComponentes'
                            type='checkbox'
                            key={index}
                            value={item.id}
                            onClick={(e) => props.getComponenteSeleccionado(e, item)}
                          />
                          {`  ${item.nombre}`}
                        </label>
                      </>
                    )
                  })
                }
              </div>
              <br />
              <div className="buttonContainer">
                <button style={{ margin: '0' }} onClick={() => props.fillComponentToInputsFilters()} className="confirmButton">Confirmar</button>
              </div>
            </>
          }
          {/* {props.children} */}
        </div>

      </div>,
      document.getElementById('EditModal')
    )

  } else if (props.typeModal === 'detalleObs') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <div className='contendorInfoDetalle'>
            <div
              className='headerSeccionDetalle'
              style={{
                backgroundColor:
                  props?.dataModal?.valor === 1
                    ? '#00a00f' :
                    (props?.dataModal?.valor === 2 || props?.dataModal?.observaciones > 0)
                      ? '#f44336' :
                      'whitesmoke',
                color:
                  (props?.dataModal?.valor === 1 || (props?.dataModal?.valor === 2 || props?.dataModal?.observaciones > 0))
                    ? 'white' : 'black'
              }}
            >
              <p className='tituloSeccion' >{props.dataModal.pregunta}</p>
            </div>
            {props.dataModal.peso ?
              <div
                style={{
                  display: 'flex',
                  width: '90%',
                  margin: '5px auto',
                  borderRadius: '6px',
                  overflow: 'hidden'
                }}
              >
                <div style={{ padding: '3px', backgroundColor: 'white' }}>{t('modales.peso')} </div>
                <div style={{ padding: '3px', backgroundColor: 'green', color: 'white', borderTopRightRadius: '6px', borderBottomRightRadius: '6px', }}>{props.dataModal.peso}</div>
              </div>
              : <br />
            }
            {(props.dataModal?.riesgos?.length > 0 || props.dataModal?.normatividades?.length > 0 || props.dataModal?.data?.length > 0)
              ?
              <div className='contenedorAside' >
                <aside
                  className='scrollSecundario'
                  style={{
                    height: '100%',
                    overflowY: 'auto'
                  }}
                >
                  {props?.dataModal?.riesgos?.length > 0 &&
                    <button
                      className='btnSeccionDetalle'
                      style={{
                        backgroundColor: props.seccionesDetalle === 'riesgos'
                          ? 'white' : 'transparent',
                        boxShadow: props.seccionesDetalle === 'riesgos' && '0px 6px 16px -5px rgba(0,0,0,0.31)'
                      }}
                      onClick={() => props.handleSeccionesDetalle('riesgos')}
                    >
                      {t('modales.riesgos')}
                    </button>
                  }
                  {props?.dataModal?.normatividades?.length > 0 &&
                    <button
                      className='btnSeccionDetalle'
                      style={{
                        backgroundColor: props.seccionesDetalle === 'normativas'
                          ? 'white' : 'transparent',
                        boxShadow: props?.seccionesDetalle === 'normativas' && '0px 6px 16px -5px rgba(0,0,0,0.31)'
                      }}
                      onClick={() => props.handleSeccionesDetalle('normativas')}
                    >

                      {t('modales.normativas')}
                    </button>
                  }
                  {props?.dataModal?.data?.length && props?.dataModal?.data?.map((option, index) => {
                    return (
                      <button
                        key={index}
                        className='btnSeccionDetalle'
                        style={{
                          backgroundColor: props.seccionesDetalle === option.id
                            ? 'white' : 'transparent',
                          boxShadow: props?.seccionesDetalle === option.id && '0px 6px 16px -5px rgba(0,0,0,0.31)'
                        }}
                        onClick={() => { props.handleSeccionesDetalle(option.id); props.filterDataOptions(option.id) }}
                      >
                        {option.tipo}
                      </button>
                    )
                  })
                  }
                </aside>
                <main className='scrollSecundario contenedorLists'
                  style={{
                    height: '100%',
                    overflowY: 'auto'
                  }}
                >
                  {props.seccionesDetalle === 'riesgos' && props.dataModal?.riesgos?.map((item, index) => {
                    return (
                      <div key={index} className='detalleItemList' >
                        {item.descripcion}
                      </div>
                    )
                  })
                  }

                  {props.seccionesDetalle === 'normativas' && props.dataModal?.normatividades?.map((item, index) => {
                    return (
                      <div key={index} className='detalleItemList' >
                        {item.descripcion}
                      </div>
                    )
                  })
                  }

                  {props.seccionesDetalle !== 'normativas' && props.seccionesDetalle !== 'riesgos' && props.dataOption !== '' && props?.dataOption?.valores?.map((item, index) => {
                    return (
                      <div key={index} className='detalleItemList' >
                        {item.descripcion}
                      </div>
                    )
                  })

                    // (props.seccionesDetalle !== 'normativas' &&
                    //   props.seccionesDetalle !== 'riesgos' &&
                    //   props.seccionesDetalle === '') &&
                    //   props?.dataModal?.data?.map((item, index) => {
                    //     return(
                    //       <div key={index} className='detalleItemList' >
                    //         { JSON.stringify(item.valores)}
                    //       </div>
                    //     )
                    // })
                  }
                </main>
              </div>
              :
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  height: '20vh',
                  padding: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '85%',
                  margin: '0 auto',
                  color: 'rgb(173, 173, 173)'
                }}
              >
                {t('modales.no_hay_riesgos_normativas')}
              </div>
            }
          </div>
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  }
  else if (props.typeModal === 'infoPrecargadaPanel') {
    return reactDOM.createPortal(
      <div className='componentesModal'>
        <div className='cardComponentesPrecarga'>
          <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button>
          <br />
          <h3 className='pagesTitles'>Configuración de panel</h3>
          <br />

          {props.cargaInicial &&
            <div>
              <div id='detalleCargaInicialContainer' >
                <div className='detalleCargaInicial'>
                  {props.cargaInicial.departamento}
                </div>
                <div className='detalleCargaInicial'>
                  {props.cargaInicial.fechas}
                </div>
              </div>
              <p id='contenedorComponentesCargaInicial' >
                {props.cargaInicial.componentes.map((item) => {
                  return (
                    <>
                      <p className='itemPrecarga' >{item}</p>
                    </>
                  )
                })}
              </p>
            </div>
          }

        </div>

      </div>,
      document.getElementById('EditModal')
    )

  } else if (props.typeModal === 'configuradorPanel') {
    return reactDOM.createPortal(
      <div className='componentesModal'>
        <div className='cardComponentesPrecarga'>
          <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button>
          <br />
          <h3 className='pagesTitles'>{t('formulario.label.configurar_panel')}</h3>
          {
            props.steps &&
            <>
              <Stepper per activeStep={props.activeStep} style={{ marginTop: "32px" }} orientation='vertical'>
                {props.steps.map((label, index) => (
                  (props.filtroTiempo && index === 1) || (props.filtroDepartamento && index === 0) ?
                    <Step key={label} style={{ minWidth: "350px", justifySelf: "center" }}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent TransitionProps={{ unmountOnExit: false }} >
                        <span style={{ fontSize: '2rem' }}>{props.getStepContent(index)}</span>
                        <div style={{ marginBottom: '2rem' }}>
                          <div style={{ display: 'flex', flexDirection: "row" }}>
                            <button
                              variant="contained"
                              style={{ display: props.activeStep === 0 ? 'none' : 'block' }}
                              disabled={props.activeStep === 0}
                              onClick={props.handleBack}
                              // className={classes.button}
                              className='btnSecundario'
                              id='btnAtras'
                            // style={  activeStep === 0 && opacity:'0.5'  }
                            >
                              {t('botones.atras')}
                            </button>
                            <button
                              variant="contained"
                              onClick={() => {

                                props.filtroTiempo && index === 1
                                  ?
                                  props.handleTiemposReset()
                                  :
                                  props.handleReset()
                              }

                              }
                              className='btnSecundario'
                            >
                              {t('botones.limpiar')}
                            </button>
                            <button
                              variant="contained"
                              // color="primary"
                              onClick={props.handleNext}
                              className=''
                              id='btnConfirmar'
                            >
                              {props.activeStep === props.steps.length - 1 ? t('botones.continuar') : t('botones.siguiente')}
                            </button>
                          </div>
                        </div>
                        {props.componenteSeleccionado.length > 0 && props.activeStep === 0 && props.carga.loading === false ? (
                          <div id='contenedorComponentesSeleccionados'>
                            <div className='componenteSeleccionadosLabel' >
                              {t('modales.componentes_seleccionados')}
                            </div>
                            <div className="componenteSeleccionadosList">
                              {props.filterList}
                            </div>
                          </div>
                        ) : null}
                      </StepContent>
                    </Step> : null
                ))}
              </Stepper>
              {props.activeStep === props.length && (
                <div style={{ margin: 'auto', width: '50%', textAlign: 'center' }}>
                  <span style={{ color: 'gray' }}>{t('modales.pulse_boton_finalizar_configuracion')}</span>
                  <div id='contenedorBotonesFinalizarFiltro' >
                    <button
                      variant="contained"

                      id='btnFiltrarPaso3'
                      // className='confirmButton'
                      sytle={{ marginLeft: '30px' }}
                      onClick={() => props.filtrarPanel()}
                    >
                      {t('botones.finalizar')}
                    </button>

                  </div>
                </div>
              )}
            </>
          }

          {/*
              <Stepper per activeStep={activeStep} >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      <Typography>{getStepContent(index)}</Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <button
                            variant="contained"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            // className={classes.button}
                            className='btnSecundario'
                            id='btnAtras'
                          // style={  activeStep === 0 && opacity:'0.5'  }
                          >
                            Atrás
                          </button>
                          <button
                            variant="contained"
                            onClick={() => {

                              activeStep === steps.length - 1
                                ?
                                handleTiemposReset()
                                :
                                handleReset()



                            }

                            }
                            className='btnSecundario'
                          >
                            Limpiar
                          </button>
                          <button
                            variant="contained"
                            // color="primary"
                            onClick={handleNext}
                            className=''
                            id='btnConfirmar'

                          >
                            {activeStep === steps.length - 1 ? 'Continuar' : 'Siguiente'}
                          </button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <div style={{ margin: 'auto', width: '50%', textAlign: 'center' }}>
                  <Typography style={{ color: 'gray' }}>Pulse en el botón de finalizar para establecer la configuración seleccionada</Typography>
                  <div id='contenedorBotonesFinalizarFiltro' >
                    <button
                      variant="contained"

                      id='btnFiltrarPaso3'
                      // className='confirmButton'
                      sytle={{ marginLeft: '30px' }}
                      onClick={() => filtrarPanel()}
                    >
                      Finalizar
                    </button>

                  </div>
                </div>
              )}

              {componenteSeleccionado.length > 0 && activeStep === 0 && carga.loading === false ? (
                <div id='contenedorComponentesSeleccionados'>
                  <div className='componenteSeleccionadosLabel' >
                    Componentes seleccionados :
                  </div>
                  <div className="componenteSeleccionadosList">
                    {filterList}
                  </div>
                </div>
              ) : null}
              */}
          <br />


        </div>

      </div>,
      document.getElementById('EditModal')
    )


  } else if (props.typeModal === 'detalleRiesgoAuditoria') {

    const rango = parseInt(props.dataRiesgo.rango)

    return reactDOM.createPortal(
      <div className='detalleRiesgoAuditoriaContainer'>
        <div className='contenedorModal' >

          <div className='detalleRiesgoAuditoriaModal'>
            <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button>
            <Form className='formRiesgoModal' validated={props.validated} noValidate autoComplete="off" onSubmit={(e) => props.submitData(e)}>
              <div className={!props.activeRequired ? 'detalleRiesgoHeader inactivo' : (rango >= 15 && rango <= 25) ? 'detalleRiesgoCritico' : (rango >= 5 && rango <= 12) ? 'detalleRiesgoAltoHeader' : 'detalleRiesgoHeader'} style={{ background: backgroundImpact(rango) }}>
                <div>
                  <p className='tituloModalRiesgo'>{props.dataRiesgo.tipo}</p>
                  <Form.Check
                    custom
                    type="checkbox"
                    id="active-risk"
                    label={t('formulario.label.activo')}
                    name="activo"
                    defaultChecked={props.activeRequired}
                    onChange={() => props.setActiveRequired(!props.activeRequired)}
                  />
                </div>
                <div
                  className='infoGeneralRiesgo'
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
                >
                  <div id='contenedorProbabilidadImpacto' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="info-content">
                      <div className="info-content-header">
                        <div className="info-content-header-res">
                          <p className='labelInfoGeneral' >{t('modales.probabilidad')}</p>
                        </div>
                        <div className="info-content-header-res">
                          <p className='dataInfoGeneral' id='probabilidad_num' >{props.dataRiesgo.probabilidad}</p>
                        </div>
                      </div>
                    </div>
                    <div className="info-content">
                      <div className="info-content-res">
                        <p id='labelInfoGeneral' className='labelInfoGeneral' >{t('modales.pesos_afirmativos')}</p>
                        <p id='dataInfoGeneral' className='dataInfoGeneral' >{props.dataRiesgo.respuestas_afrimativas}</p>
                      </div>
                      <div className="info-content-res">
                        <p id='labelRango' className='labelInfoGeneral' >{t('modales.total_contramedidas')}</p>
                        <p id='numeroRango' className='dataInfoGeneral' >{props.dataRiesgo.preguntas_contestadas}</p>
                      </div>
                    </div>
                    <div className="info-content">
                      <div className="info-content-res">
                        <p id='labelInfoGeneral' className='labelInfoGeneral' >{t('modales.pesos_negativos')}</p>
                        <p id='dataInfoGeneral' className='dataInfoGeneral'>{props.dataRiesgo.respuestas_negativas}</p>
                      </div>
                      <div className="info-content-res">
                        <p id='labelRango' className='labelInfoGeneral'>{t('modales.probabilidad')}</p>
                        <p id='numeroRango' className='dataInfoGeneral'>{`${props.dataRiesgo.porcentaje_probabilidad}%`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="selects-risk-modal shadow">
                <div className="container-select">
                  <h6>{t('formulario.label.impacto_reputacional')}</h6>
                  <Form.Group>
                    <Form.Label>{t('formulario.label.informacion')}</Form.Label>
                    <Form.Control
                      required={props.activeRequired}
                      disabled={!props.activeRequired}
                      defaultValue={props.dataRiesgo.impacto_reputacional.informacion}
                      name="impacto_reputacional_informacion"
                      onChange={(e) => { props.handleSelectValues(e) }}
                      as="select"
                      className='riesgo_modal_select'
                    >
                      <option value="0">{t('formulario.select.no_aplica')}</option>
                      <option value="1">{t('formulario.select.minimo')}</option>
                      <option value="2">{t('formulario.select.leve')}</option>
                      <option value="3">{t('formulario.select.moderado')}</option>
                      <option value="4">{t('formulario.select.alto')}</option>
                      <option value="5">{t('formulario.select.critico')}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('formulario.label.talento_humano')}</Form.Label>
                    <Form.Control
                      required={props.activeRequired}
                      disabled={!props.activeRequired}
                      defaultValue={props.dataRiesgo.impacto_reputacional.talento_humano}
                      onChange={(e) => { props.handleSelectValues(e) }}
                      name="impacto_reputacional_talento"
                      as="select"
                      className='riesgo_modal_select'
                    >
                      <option value="0">{t('formulario.select.no_aplica')}</option>
                      <option value="1">{t('formulario.select.minimo')}</option>
                      <option value="2">{t('formulario.select.leve')}</option>
                      <option value="3">{t('formulario.select.moderado')}</option>
                      <option value="4">{t('formulario.select.alto')}</option>
                      <option value="5">{t('formulario.select.critico')}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('formulario.label.credibilidad_imagen')}</Form.Label>
                    <Form.Control
                      required={props.activeRequired}
                      disabled={!props.activeRequired}
                      defaultValue={props.dataRiesgo.impacto_reputacional.credibilidad}
                      name="impacto_reputacional_credibilidad"
                      onChange={(e) => { props.handleSelectValues(e) }}
                      as="select"
                      className='riesgo_modal_select'
                    >
                      <option value="0">{t('formulario.select.no_aplica')}</option>
                      <option value="1">{t('formulario.select.minimo')}</option>
                      <option value="2">{t('formulario.select.leve')}</option>
                      <option value="3">{t('formulario.select.moderado')}</option>
                      <option value="4">{t('formulario.select.alto')}</option>
                      <option value="5">{t('formulario.select.critico')}</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="container-select">
                  <h6>{t('formulario.label.impacto_administrativo')}</h6>
                  <Form.Group>
                    <Form.Label>{t('formulario.label.legal')}</Form.Label>
                    <Form.Control
                      required={props.activeRequired}
                      disabled={!props.activeRequired}
                      defaultValue={props.dataRiesgo.impacto_administrativo.legal}
                      onChange={(e) => { props.handleSelectValues(e) }}
                      name="impacto_administrativo_legal"
                      as="select"
                      className='riesgo_modal_select'
                    >
                      <option value="0">{t('formulario.select.no_aplica')}</option>
                      <option value="1">{t('formulario.select.minimo')}</option>
                      <option value="2">{t('formulario.select.leve')}</option>
                      <option value="3">{t('formulario.select.moderado')}</option>
                      <option value="4">{t('formulario.select.alto')}</option>
                      <option value="5">{t('formulario.select.critico')}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('formulario.label.operativo')}</Form.Label>
                    <Form.Control
                      required={props.activeRequired}
                      disabled={!props.activeRequired}
                      defaultValue={props.dataRiesgo.impacto_administrativo.operativo}
                      onChange={(e) => { props.handleSelectValues(e) }}
                      name="impacto_administrativo_operativo"
                      as="select"
                      className='riesgo_modal_select'
                    >
                      <option value="0">{t('formulario.select.no_aplica')}</option>
                      <option value="1">{t('formulario.select.minimo')}</option>
                      <option value="2">{t('formulario.select.leve')}</option>
                      <option value="3">{t('formulario.select.moderado')}</option>
                      <option value="4">{t('formulario.select.alto')}</option>
                      <option value="5">{t('formulario.select.critico')}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('formulario.label.economico')}</Form.Label>
                    <Form.Control
                      required={props.activeRequired}
                      disabled={!props.activeRequired}
                      defaultValue={props.dataRiesgo.impacto_administrativo.economico}
                      name="impacto_administrativo_economico"
                      onChange={(e) => { props.handleSelectValues(e) }}
                      as="select"
                      className='riesgo_modal_select'
                    >
                      <option value="0">{t('formulario.select.no_aplica')}</option>
                      <option value="1">{t('formulario.select.minimo')}</option>
                      <option value="2">{t('formulario.select.leve')}</option>
                      <option value="3">{t('formulario.select.moderado')}</option>
                      <option value="4">{t('formulario.select.alto')}</option>
                      <option value="5">{t('formulario.select.critico')}</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
              {/* <div className="impact-result"> */}
              <ListGroup horizontal className="impact-result" >
                <ListGroup.Item>{t('formulario.label.impacto')}:</ListGroup.Item>
                <ListGroup.Item>{impactText(props.impact)}</ListGroup.Item>
                <input type="hidden" name="impacto" defaultValue={props.impact} />
                <ListGroup.Item>{t('modales.rango')}:</ListGroup.Item>
                <ListGroup.Item style={{ backgroundColor: backgroundImpact(props.range) }}>{props.range}</ListGroup.Item>
                <input type="hidden" name="rango" defaultValue={props.range} />
              </ListGroup>
              {/* </div> */}
              <div className='detalleRiesgoMain'>
                <Tabs
                  className="tabs-risk-form"
                  transition={false}
                  defaultActiveKey={props.tabForm}
                  onSelect={(k) => props.setTabForm(k)}
                >
                  <Tab eventKey="causas" title={t('modales.causas')}>
                    {Array.isArray(props.dataRiesgo.causas) ? (
                      <div className='check__list check__list--scroll mt-3 py-2 px-3'>
                        <ul>
                          {props.dataRiesgo.causas.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Form.Group className="form-modal-audit mt-3">
                        <Form.Control
                          required={props.activeRequired}
                          readOnly={!props.activeRequired}
                          defaultValue={props.dataRiesgo.causas}
                          name="causas"
                          className="textarea-tabs-risk check__list--scroll"
                          as="textarea"
                          placeholder={t('modales.causas')}
                          id="causas"
                        />
                        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Tab>
                  <Tab eventKey="consecuencias" title={t('modales.consecuencias')}>
                    {Array.isArray(props.dataRiesgo.consecuencias) ? (
                      <div className='check__list check__list--scroll mt-3 py-2 px-3'>
                        <ul>
                          {props.dataRiesgo.consecuencias.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Form.Group className="form-modal-audit mt-3">
                        <Form.Control
                          required={props.activeRequired}
                          readOnly={!props.activeRequired}
                          defaultValue={props.dataRiesgo.consecuencias}
                          name="consecuencias"
                          className="textarea-tabs-risk check__list--scroll"
                          as="textarea"
                          placeholder={t('modales.consecuencias')}
                          id="consecuencias"
                        />
                        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Tab>
                  <Tab eventKey="acciones" title={t('modales.acciones')}>
                    {Array.isArray(props.dataRiesgo.acciones) ? (
                      <div className='check__list check__list--scroll mt-3 py-2 px-3'>
                        <ul>
                          {props.dataRiesgo.acciones.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Form.Group className="form-modal-audit mt-3">
                        <Form.Control
                          required={props.activeRequired}
                          readOnly={!props.activeRequired}
                          defaultValue={props.dataRiesgo.acciones}
                          className="textarea-tabs-risk check__list--scroll"
                          name="acciones"
                          as="textarea"
                          placeholder={t('modales.acciones')}
                          id="acciones"
                        />
                        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Tab>
                  <Tab eventKey="responsable" title={t('modales.responsable')}>
                    {Array.isArray(props.dataRiesgo.responsable) ? (
                      <div className='check__list check__list--scroll mt-3 py-2 px-3'>
                        <ul>
                          {props.dataRiesgo.responsable.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Form.Group className="form-modal-audit mt-3">
                        <Form.Control
                          required={props.activeRequired}
                          readOnly={!props.activeRequired}
                          defaultValue={props.dataRiesgo.responsable}
                          className="textarea-tabs-risk check__list--scroll"
                          as="textarea"
                          name="responsable"
                          placeholder={t('modales.responsable')}
                          id="responsable"
                        />
                        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Tab>
                </Tabs>
              </div>
              <br />
              <div className="modal-risk-audit" style={{ paddingBottom: '20px', marginTop: '0 !important' }}>
                {props.permisos?.editar !== false && <Button className="confirmButton" type="submit" style={{ marginLeft: '0 !Important' }} >{t('formulario.actions.enviar')}</Button>}
              </div>
            </Form>
          </div>
        </div>

      </div>,
      document.getElementById('EditModal')
    );
  } else if (props.typeModal === 'planEstrategico') {
    console.log(props)
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCardPE'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          {/* <button className='closeButtonContainer' onClick={() => props.setIsOpen(false)}>x</button> */}
          <ModalContent
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            getDataInicial={props.getDataInicial}
            selectedids={props.selectedids}
            numeracionPadre={props.numeracionPadre}
          />
        </div>
      </div>,
      document.getElementById('EditModal')
    );
  } else if (props.typeModal === 'detalleObservacionesAuditoria') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'>

          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'detalleTercerNivel') {
    return reactDOM.createPortal(
      <div className='componentesModal'>
        <div className='cardComponentesPrecarga'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={props.setIsOpen} />
          </div>
          <h3 className='pagesTitles'>Información</h3>
          {props.item &&
            <div className="container-table-detail">
              {props.item && props.item.descripcion ? (
                <Table className="table-detail">
                  <thead>
                    <tr>
                      <th>Detalle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{props.item.descripcion}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : null}
              {props.item && props.item.riesgos.length > 0 ? (
                <Table className="table-detail">
                  <thead>
                    <tr>
                      <th>Riesgos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.item.riesgos.map((item, index) => (
                      <tr>
                        <td>{item.descripcion}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
              {props.item.normatividades.length > 0 ? (
                <Table className="table-detail">
                  <thead>
                    <tr>
                      <th>Normatividades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.item.normatividades.map((item, index) => (
                      <tr>
                        <td>{item.descripcion}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
            </div>
          }
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'editarObsPregunta') {

    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' >{t('modales.editar_observacion')}</h3>
          <div className=' scrollSecundario contenedorEditorObsCriteriosAud' style={{ width: '90%', margin: 'auto' }}>
            <div className='d-flex justify-content-between'>
              <h4 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.hallazgo')}</h4>
              <div className='d-flex align-items-center justify-content-center gap-2 pr-2'>
                <Form.Label>{t('formulario.label.matriz_riesgos_activo')}</Form.Label>
                <Check
                  initialValue={props.ele.activo}
                  onChange={(e) => props.handleData(e)}
                  name="activo" />
              </div>
            </div>
            <Form.Group className='formGroupVertical'>
              <Form.Label>{t('formulario.label.hallazgo_concreto')}</Form.Label>
              <Form.Control
                as='textarea'
                name="hallazgo"
                style={{ height: '100px' }}
                defaultValue={props.ele.hallazgo}
                onChange={(e) => props.handleData(e)}
              />
            </Form.Group>
            <Form.Group className='formGroupVertical'>
              <Form.Label>{t('formulario.label.redacte_detalle_hallazgo')}</Form.Label>
              <Form.Control
                as='textarea'
                name="descripcion_hallazgo"
                style={{ height: '100px' }}
                defaultValue={props.ele.descripcion_hallazgo}
                onChange={(e) => props.handleData(e)}
              />
            </Form.Group>
            <br />
            <h6 className="pagesTitles">{t('formulario.label.evidencia_fotografica')}</h6>
            {props.ele.detalle_visual
              ?
              (
                <table responsive={true} className="table-modal-audit ">
                  <thead>
                    <th>Id</th>
                    <th>{t('formulario.label.imagen')}</th>
                    <th>{t('formulario.label.descripcion')}</th>
                    <th>{t('formulario.label.acciones')}</th>
                  </thead>
                  <tbody>
                    {props.ele.detalle_visual.map((item, index) => (
                      <tr className="rowTabla"
                        style={{
                          fontSize: '1rem'
                        }}
                      >
                        <td>{item.id}</td>
                        <td className='tdBotones'>
                          <p
                            style={{ cursor: 'pointer' }}
                            // className='botonTablaGeneral botonGuardarTabla'
                            onClick={() => { props.showImage(item.imagen) }}
                          >
                            <ImageIcon fontSize='small' />
                            {t('botones.ver')}
                          </p>
                        </td>
                        {props.indexDetail === index ? (
                          <td>
                            <Form.Control
                              name="hallazgo"
                              defaultValue={item.pie_de_imagen}
                              onChange={(e) => props.handleDescriptionImage(e, index, 'visual')}
                            />
                          </td>
                        ) : (<td>{item.pie_de_imagen}</td>)}
                        <td className='tdBotones'>
                          <div className='botonesTabla'>
                            {props.indexDetail === index ? (
                              <button
                                style={{ cursor: 'pointer' }}
                                className='botonTablaGeneral botonGuardarTabla'
                                onClick={() => { props.editDescriptionImage(item, index, 'visual'); props.items() }}
                              >
                                <SaveIcon fontSize='small' />
                                {t('botones.guardar')}
                              </button>
                            ) : null}
                            <button style={{ cursor: 'pointer' }} className='botonTablaGeneral botonEditarTabla' onClick={() => { props.getIndexDetail(index) }}>
                              {props.indexDetail === index
                                ?
                                <>
                                  <CloseIcon />
                                  {t('botones.cancelar')}
                                </>
                                :
                                <>
                                  <EditIcon fontSize='small' />
                                  {t('botones.editar')}
                                </>

                              }
                            </button>
                            {props.indexDetail !== index && (
                              <button
                                style={{ cursor: 'pointer' }}
                                className='botonTablaGeneral botonEliminarTabla'
                                onClick={() => { props.confirmDeleteImageDetail(index, item, 'visual') }}
                              >
                                <DeleteIcon fontSize='small' />
                                {t('botones.eliminar')}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
              : <p>{t('formulario.label.no_hay_imagenes')}</p>

            }

            <br />
            <Button onClick={() => props.setAddHallazgos(!props.addHallazgos)} className="confirmButton">{t('modales.nueva_evidencia_fotografica_hallazgo')}</Button>
            <br />
            {props.addHallazgos && (
              <>
                <h6 className="pagesTitles">{t('formulario.label.nuevas_evidencias')}</h6>
                {props.pieHallazgo && (props.pieHallazgo.map((item, index) => (
                  <>
                    <Row>
                      <Col xs={6}>
                        <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>{t('formulario.label.imagen')}</Form.Label>
                          <DropzoneArea
                            acceptedFiles={['image/*']}
                            dropzoneText={t('formulario.label.arrastra_suelta_imagen')}
                            name="imagen"
                            onChange={(file) => props.handleImageDataEdit(file, index)}
                            filesLimit={1}
                            showAlerts={false}
                          // onDelete={(file) => { deleteImageData(file) }}
                          // dropzoneClass={validatedFile ? 'validated-dropzone' : null}
                          />
                          {/* {validatedFile ? (<p className="invalid-feedback audit-form">Este campo es obligatorio</p>) : null}   */}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>{t('formulario.label.pie_imagen')}</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="pie_imagen"
                            onChange={(e) => { props.handleChangeHallazgos(e, index) }}
                            className="audit-form-textarea"
                            required={item.imagen !== "" ? true : false}
                          />
                          <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col style={{ textAlign: 'left' }} xs={12}>
                        {props.pieHallazgo.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={() => { props.handleAddClick() }} />}
                        {props.pieHallazgo.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => props.handleRemoveClick(index)} />}
                      </Col>
                    </Row>
                  </>
                )))}
              </>
            )}

            {/* {//console.log(props.ele.imagen)}
          {//console.log(props.stateForm)} */}

            {/* {props.ele.imagen === props.stateForm.imagen  &&
            <div>
              <a href={props.ele.imagen} target="_blank"> Ver imagen precargada </a>
            </div>

          } */}
            <br />
            <h4 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.riesgos_asociados_consecuencias')}</h4>
            <Form.Group className='formGroupVertical'>
              <Form.Control
                as='textarea'
                name="comentario"
                style={{ height: '100px' }}
                defaultValue={props.ele.comentario}
                onChange={(e) => props.handleData(e)}
              />
            </Form.Group>
            <br />
            <h4 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.recomendaciones')}</h4>
            <Form.Group className='formGroupVertical'>
              <Form.Control
                as='textarea'
                name="recomendacion"
                style={{ height: '100px' }}
                defaultValue={props.ele.recomendacion}
                onChange={(e) => props.handleData(e)}
              />
            </Form.Group>
            <br />
            <h6 className="pagesTitles">{t('formulario.label.evidencia_fotografica')}</h6>
            {props.ele.recomendaciones && (
              <table responsive={true} className="table-modal-audit ">
                <thead>
                  <th>Id</th>
                  <th>{t('formulario.label.imagen')}</th>
                  <th>{t('formulario.label.recomendacion')}</th>
                  <th>{t('formulario.label.acciones')}</th>
                </thead>
                <tbody>
                  {props.ele.recomendaciones.map((item, index) => (
                    <tr className="rowTabla"
                      style={{
                        fontSize: '1rem'
                      }}
                    >
                      <td>{item.id}</td>
                      <td className='tdBotones'>
                        <div className='botonesTabla'>

                          <button
                            style={{ cursor: 'pointer' }}
                            className='botonTablaGeneral botonGuardarTabla'
                            onClick={() => { props.showImage(item.imagen) }}
                          >
                            <ImageIcon fontSize='small' />
                            {t('botones.ver_imagen')}
                          </button>
                        </div>
                      </td>
                      {props.indexRecomend === index ? (
                        <td>
                          <Form.Control
                            name="hallazgo"
                            defaultValue={item.pie_de_imagen}
                            onChange={(e) => props.handleDescriptionImage(e, index, 'recomendacion')}
                          />
                        </td>
                      ) : (<td>{item.pie_de_imagen}</td>)}
                      <td className='tdBotones'>
                        <div className='botonesTabla'>
                          {props.indexRecomend === index ? (
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonGuardarTabla'
                              onClick={() => { props.editDescriptionImage(item, index, 'recomendacion') }}
                            >
                              <SaveIcon fontSize='small' />
                              {t('botones.guardar')}
                            </button>
                          ) : null}
                          <button style={{ cursor: 'pointer' }} className='botonTablaGeneral botonEditarTabla' onClick={() => { props.getIndexDetailRecomendations(index) }}>
                            {props.indexRecomend === index
                              ?
                              <>
                                <CloseIcon />
                                {t('botones.cancelar')}
                              </>
                              :
                              <>
                                <EditIcon fontSize='small' />
                                {t('botones.editar')}
                              </>

                            }
                          </button>
                          {props.indexRecomend !== index && (
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonEliminarTabla'
                              onClick={() => { props.confirmDeleteImageDetail(index, item, 'recomendacion') }}
                            >
                              <DeleteIcon fontSize='small' />
                              {t('botones.eliminar')}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <br />
            <Button onClick={() => props.setAddRecomendaciones(!props.addRecomendaciones)} className="confirmButton">{t('modales.nueva_evidencia_fotografica_recomendacion')}</Button>
            <br />
            {props.addRecomendaciones && (
              <>
                <h6 className="pagesTitles">{t('formulario.label.nuevas_recomendaciones')}</h6>
                {props.pieRecomendacion && (props.pieRecomendacion.map((item, index) => (
                  <>
                    <Row>
                      <Col xs={6}>
                        <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>{t('formulario.label.imagen')}</Form.Label>
                          <DropzoneArea
                            acceptedFiles={['image/*']}
                            dropzoneText={t('formulario.label.arrastra_suelta_imagen')}
                            name="imagen"
                            onChange={(file) => props.handleImageDataRecomendacionEdit(file, index)}
                            filesLimit={1}
                            showAlerts={false}
                          // onDelete={(file) => { deleteImageData(file) }}
                          // dropzoneClass={validatedFile ? 'validated-dropzone' : null}
                          />
                          {/* {validatedFile ? (<p className="invalid-feedback audit-form">Este campo es obligatorio</p>) : null}   */}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>{t('formulario.label.pie_imagen')}</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="pie_imagen"
                            onChange={(e) => { props.handleChangeRecomendaciones(e, index) }}
                            className="audit-form-textarea"
                          // required={input.imagen !== "" ? true : false}sc
                          />
                          <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col style={{ textAlign: 'left' }} xs={12}>
                        {props.pieRecomendacion.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={() => { props.handleAddClickRecomendacion() }} />}
                        {props.pieRecomendacion.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => props.handleRemoveClickRecomendacion(index)} />}
                      </Col>
                    </Row>
                  </>
                )))}
              </>
            )}
            <h4 style={{ textAlign: 'left' }} className="pagesTitles">{t('formulario.label.responsable')}</h4>
            <Form.Group className='formGroupVertical'>
              <Form.Control
                as='textarea'
                name="responsable"
                style={{ height: '100px' }}
                defaultValue={props.ele.responsable}
                onChange={(e) => props.handleData(e)}
              />
            </Form.Group>

          </div>
          <div style={{ display: 'flex' }}>
            <button className='confirmButton' onClick={() => { props.enviarEdicionObs('seccion_preguntas') }}>{t('botones.guardar')}</button>
            <button className='botonEliminar' onClick={() => { props.setIsOpen(false) }}  >{t('botones.cancelar')}</button>

          </div>

        </div>
      </div>,
      document.getElementById('EditModal')
    )

  } else if (props.typeModal === 'editarObsCuestPregunta') {

    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' >{t('modales.editar_observacion')}</h3>
          <div className=' scrollSecundario contenedorEditorObsCriteriosAud' style={{ width: '90%', margin: 'auto' }}>
            {/* <DropzoneArea
            acceptedFiles={['image/*']}
            dropzoneText="Inserta una nueva imagen"
            name="imagen"
            onChange={(file) => props.handleImage(file)}
            filesLimit={1}
            showAlerts={false}
            // initialFiles={props.ele.imagen}
          /> */}

            <Form.Group className='formGroupVertical'>
              <Form.Label> {t('formulario.label.descripcion')} </Form.Label>
              <Form.Control
                as='textarea'
                name="descripcion"
                defaultValue={props.ele.descripcion}
                style={{ height: '100px' }}
                onChange={(e) => props.handleItem(e)}
              />
            </Form.Group>

            {props.ele.pie_de_imagen && props.ele.pie_de_imagen !== '' ? (
              <Form.Group className='formGroupVertical'>
                <Form.Label>{t('formulario.label.pie_imagen')}</Form.Label>
                <Form.Control
                  as='textarea'
                  name="pie_de_imagen"
                  defaultValue={props.ele.pie_de_imagen}
                  style={{ height: '100px' }}
                  onChange={(e) => props.handleItem(e)}
                />
              </Form.Group>
            ) : null}

            {(props.ele.imagen === props.stateForm.imagen) && props.ele.imagen !== '' ? (
              <>
                <div>
                  {/* <a href={props.ele.imagen} target="_blank"> Ver imagen precargada </a> */}
                  <table responsive={true} className="table-modal-audit ">
                    <thead>
                      <th>{t('modales.imagen')}</th>
                      <th>{t('modales.acciones')}</th>
                    </thead>
                    <tbody>
                      <tr className="rowTabla">
                        <td>
                          <div className="botonesTabla">
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonGuardarTabla'
                              onClick={() => { setShowImage(!showImage) }}
                            >
                              <ImageIcon fontSize='small' />
                              {t('botones.ver_imagen')}
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className='botonesTabla'>
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonEliminarTabla'
                              onClick={() => { props.confirmDeleteImage() }}
                            >
                              <DeleteIcon fontSize='small' />
                              {t('botones.eliminar')}
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
              </>
            ) : (
              <DropzoneArea
                acceptedFiles={['image/*']}
                dropzoneText={t('formulario.label.inserta_nueva_imagen')}
                name="imagen"
                onChange={(file) => props.handleImageModal(file)}
                filesLimit={1}
                showAlerts={false}
              // initialFiles={props.ele.imagen}
              />
            )}
            {showImage ? (
              <div>
                <Image src={props.ele.imagen} fluid />
              </div>
            ) : null}
            {/* <Form.Group className='formGroupVertical'>
            <Form.Label> Hallazgo </Form.Label>
            <Form.Control
                as='textarea'
                name="hallazgo"
                defaultValue={props.ele.hallazgo}
                onChange={(e) => props.handleData(e)}
            />
          </Form.Group>
          <Form.Group className='formGroupVertical'>
            <Form.Label> Comentario </Form.Label>
            <Form.Control
                as='textarea'
                name="comentario"
                defaultValue={props.ele.comentario}
                onChange={(e) => props.handleData(e)}
            />
          </Form.Group> */}
            <br />

          </div>
          <div style={{ display: 'flex' }}>
            <button className='confirmButton' onClick={() => props.editDataDetailModal()}>{t('botones.guardar')}</button>
            <button className='botonEliminar' onClick={() => { props.setIsOpen(false) }}  >{t('botones.cancelar')}</button>

          </div>

        </div>
      </div>,
      document.getElementById('EditModal')
    )

  } else if (props.typeModal === 'obtenerDescripcionCoordenadas') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard' style={{ minHeight: 200 }}>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' >Añadir descripción</h3>
          <div className=' scrollSecundario contenedorEditorObsCriteriosAud' style={{ width: '90%', margin: 'auto', height: 175 }}>
            <Form.Group className='formGroupVertical'>
              <Form.Label> Descripción </Form.Label>
              <Form.Control
                as='textarea'
                name="descripcion"
                defaultValue={props.descripcion}
                onChange={(e) => props.handleData(e)}
                style={{ height: 115 }}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex' }}>
            <button
              className='confirmButton'
              onClick={(e) => { !props.isForm ? props.setConfirmarUbicacion(props.lat, props.lng) : props.setFormUbicacion(e) }}>
              Guardar
            </button>
            <button
              className='botonEliminar'
              onClick={() => { props.setIsOpen(false) }}>
              Cancelar
            </button>
          </div>
        </div>
      </div>,
      document.getElementById('EditModal')
    );
  } else if (props.typeModal === 'obtenerDescripcionCoordenadasActi') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard' style={{ minHeight: 200 }}>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' >{t('formulario.label.anadir_descripcion')}</h3>
          <div className=' scrollSecundario contenedorEditorObsCriteriosAud' style={{ width: '90%', margin: 'auto', height: 175 }}>
            <Form.Group className='formGroupVertical'>
              <Form.Label> {t('formulario.label.descripcion')} </Form.Label>
              <Form.Control
                as='textarea'
                name="descripcion"
                defaultValue={props.descripcion}
                onChange={(e) => props.handleData(e)}
                style={{ height: 115 }}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex' }}>
            <button
              className='confirmButton'
              onClick={(e) => { !props.isForm ? props.setConfirmarUbicacion(e) : props.setFormUbicacion(e) }}>
              {t('botones.guardar')}
            </button>
            <button
              className='botonEliminar'
              onClick={() => { props.setIsOpen(false) }}>
              {t('botones.cancelar')}
            </button>
          </div>
        </div>
      </div>,
      document.getElementById('EditModal')
    );
  } else if (props.typeModal === 'obtenerCoordenadasDes') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard' style={{ minHeight: 200 }}>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' >{t('formulario.label.anadir_descripcion')}</h3>
          <div className=' scrollSecundario contenedorEditorObsCriteriosAud' style={{ width: '90%', margin: 'auto', height: 175 }}>
            <Form.Group className='formGroupVertical'>
              <Form.Label> {t('formulario.label.descripcion')} </Form.Label>
              <Form.Control
                as='textarea'
                name="descripcion"
                defaultValue={props.descripcion}
                onChange={(e) => props.handleData(e)}
                style={{ height: 115 }}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex' }}>
            <button
              className='confirmButton'
              onClick={(e) => { !props.isForm ? props.setConfirmarUbicacion(props.lat, props.lng) : props.setFormUbicacion(e) }}>
              {t('botones.guardar')}
            </button>
            <button
              className='botonEliminar'
              onClick={() => { props.setIsOpen(false) }}>
              {t('botones.cancelar')}
            </button>
          </div>
        </div>
      </div>,
      document.getElementById('EditModal')
    );
  } else if (props.typeModal === 'tablaInformeGerencial') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCardTablaGerencia'>
          <div id='contenedorTablaGerencia' className='scrollSecundario' >
            <table id='tablaInformeGerencial'>
              <thead>
                <tr>
                  {props.dataInforme.columnas.length > 0 && props.dataInforme.columnas.map((col, index) => {
                    return (
                      <th key={index}>
                        {col}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {props.dataInforme.registros.length > 0 && props.dataInforme.registros.map((reg, index) => {
                  return (
                    <tr key={index}>
                      {reg.id_inv ? <td>{reg.id_inv}</td> : null}
                      {reg.estado ? <td>{reg.estado}</td> : null}
                      {reg.fecha_sol_cli ? <td>{reg.fecha_sol_cli}</td> : null}
                      {reg.fecha_sol ? <td>{reg.fecha_sol}</td> : null}
                      {reg.fecha_fin ? <td>{reg.fecha_fin}</td> : null}
                      {reg.mes_sol ? <td>{reg.mes_sol}</td> : null}
                      {reg.mes_fin ? <td>{reg.mes_fin}</td> : null}
                      {reg.fecha_rev_cal ? <td>{reg.fecha_rev_cal}</td> : null}
                      {reg.cuenta ? <td>{reg.cuenta}</td> : null}
                      {reg.tipo ? <td>{reg.tipo}</td> : null}
                      {reg.region ? <td>{reg.region}</td> : null}
                      {reg.valor ? <td>{reg.valor}</td> : null}
                      {reg.tipo_id_pen ? <td>{reg.tipo_id_pen}</td> : null}
                      {reg.id_pen ? <td>{reg.id_pen}</td> : null}
                      {reg.radicado ? <td>{reg.radicado}</td> : null}
                      {reg.nombres ? <td>{reg.nombres}</td> : null}
                      {reg.ciudad_verificacion ? <td>{reg.ciudad_verificacion}</td> : null}
                      {reg.id_analista ? <td>{reg.id_analista}</td> : null}
                      {reg.analista ? <td>{reg.analista}</td> : null}
                      {reg.cantidad ? <td>{reg.cantidad}</td> : null}
                      {reg.conclusion ? <td>{reg.conclusion}</td> : null}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          {printInfo(props)}
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'detalleItemAnalista') {

    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div
          className='editModalCard'
          style={{
            backgroundColor: '#ededed',
            minHeight: 'auto',
            maxHeight: '57%'
          }}
        >
          {props.children}

          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          {printInfo(props)}
        </div>
      </div>,
      document.getElementById('EditModal')
    )

  } else if (props.typeModal === 'modalInfoGestorDinamico') {

    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div
          className='editModalCard'
          style={{
            backgroundColor: '#ededed',
            minHeight: 'auto',
            // maxHeight: '80%'
          }}
        >
          <p className='tituloGestorDinamico'> {props.modal?.data?.tituloModal} </p>
          <div
            className=' contenidoGestorDinamico'
            onScroll={(e) => validarScroll(e)}
          // style={{overflowY: 'hidden'}}
          >
            <Form.Control className='scrollSecundario textContent' disabled as='textarea' style={{ cursor: 'none', backgroundColor: '#fff', resize: 'none', cursor: 'pointer', textAlign: 'justify', width: '100%', height: '100%' }} value={props.modal?.data?.contenidoModal} />
          </div>
          <div className='autorizacion-cont'>

            {props.data.verificacion &&
              <>
                <br />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                  <p>Para aceptar, digita el código de verificación enviado al correo:</p>
                  <Form.Control
                    type='password'
                    name='verificacion'
                    placeholder='Código de verificación'
                    style={{ width: '45%', textAlign: 'center', marginTop: '5px' }}
                    onChange={(e) => props.validarVerificacion(e.target.value)}
                  />
                </div>
              </>
            }
            {props.tipoInformacion === 'autorizacion' &&
              <div className='buttonContainer'>
                {props.modal.data.verificacion && props.checkVerificacion &&
                  <button
                    className='confirmButton'
                    onClick={() => props.envioTratamientoDatos()}
                    // disabled={(valorScroll >= 20 || (valorScroll === 0 )) ? false : true }
                    style={{
                      margin: '20px 5px 0'
                      // opacity: ((valorScroll >= 20) || (valorScroll === 0 )) ? '1' : '0'
                    }}
                  >
                    Acepto
                  </button>
                }
                <Link to='/home'>
                  <button
                    className='warningButton'
                    // onClick={ () => history.push('/home')}
                    onMouseOver={() => props.setShowAlerta(true)}
                    onMouseOut={() => props.setShowAlerta(false)}
                    // disabled={(valorScroll >= 20 || (valorScroll === 0 )) ? false : true }
                    style={{
                      margin: '20px 5px 0'
                      // opacity: ((valorScroll >= 20) || (valorScroll === 0 )) ? '1' : '0'
                    }}
                  >
                    No acepto
                  </button>

                </Link>
              </div>
            }

          </div>


          {props.tipoInformacion !== 'autorizacion' &&
            <div className='closeButtonContainer'>
              <CloseIcon onClick={() => props.setIsOpen({ ...props.modal, show: false })} />
            </div>
          }

          {printInfo(props)}
          {props.tipoInformacion === 'autorizacion' && props?.data?.mensaje && props.showAlerta &&
            <div className='autMensaje-cont' >
              {props.data.mensaje}
            </div>
          }
        </div>
      </div>,
      document.getElementById('EditModal')
    )


  } else if (props.typeModal === 'homeModal') {

    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div
          className='editModalCard'
          style={{
            backgroundColor: '#ededed',
            minHeight: 'auto',
            // maxHeight: '80%'
          }}
        >
          {props.children}
          {props.showClose === true &&
            <div className='closeButtonContainer'>
              <CloseIcon onClick={() => { props.setIsOpen(false) }} />
            </div>}
        </div>
      </div>,
      document.getElementById('EditModal')
    )


  } else {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'
          style={{
            overflowY: 'auto'
          }}
        >
          {props.children ? props.children : null}
          {/* <Form.Control
                        as='textarea'

              // className={ is_edit ? 'unavailable-input' : 'available-input'}
              // disabled={is_edit}
              // defaultValue={descripcion}
              // name="descripcion"
              // onChange={handleChangeData}
          /> */}
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          {printInfo(props)}
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  }

}
