import { Checkbox } from 'primereact/checkbox'
import { useState, useEffect } from 'react'

function Check({initialValue = true, onChange = () => {}, performInitialUpdate, ...props}) {
    const [checked, setChecked] = useState(initialValue)

    useEffect(() => {
        if (performInitialUpdate) {
            const event = {target: {value : initialValue, name: props?.name}}
            onChange(event)
        }
    }, [])

    return (
        <Checkbox 
        className='checkbox__green'
        checked={checked} 
        value={!checked} 
        onChange={(e) => { setChecked(e.checked); onChange(e)}} {...props} />
    )
}

export default Check