import React from 'react'
import { Card } from './Card'
import styles from './Widgets.module.scss'
import { useEventBus } from '../../context/EventBus'

export default function Widgets({widgets, max_width = "300px", className, props }) {

    const eventBus = useEventBus();

    const handleOnClick = (widget) => {
        const data = {
            index: widget?.target_pestana_id,
            payload: {
                id_widget: widget?.id,
                ...widget?.payload
            }
        }
        
        eventBus.emit('NAVEGAR_PESTANA', data);
    }

    return (
        <Card className={`${styles.widgets} ${className}`} {...props}>
            {widgets?.map((widget, index) => (
                <div key={index} className={styles.widget} style={{ borderBottom: `4px solid ${widget.color}`, maxWidth: max_width }} onClick={() => handleOnClick(widget)}>
                    <div className={styles.widget__header}>
                        {widget.titulo &&
                            <h3 className={styles.widget__title}>{widget.titulo}</h3>
                        }
                    </div>
                    {widget.descripcion &&
                        <p className={styles.widget__description}>{widget.descripcion}</p>
                    }
                </div>
            ))}
        </Card>
    )
}
