import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Accordion, Card } from 'react-bootstrap';

import NotificationModal from '../../modales/NotificationModal'
import EditModal from '../../modales/EditModal';


import { Table, Button, Form, Col } from 'react-bootstrap';
import { validarEntorno } from '../../../helpers/validarHost';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { DropzoneDialog } from 'material-ui-dropzone';
import './ModalContent.scss';
import { useTranslation } from 'react-i18next';

const ModalContent = (props) => {
  const { t } = useTranslation();
  const [idsItemsModal, setIdsItemsModal] = useState({})
  const [fileObjects, setFileObjects] = useState([]);
  const [modal, setModal] = useState({
    type: '',
    show: false
  })
  const [confirmDialog, setConfirmDialog] = useState(false); //Indica si se muestra el modal de confirmación
  const [eliminacionPendiente, setEliminacionPendiente] = useState(null); //Indica si se está esperando la respuesta de eliminación
  // //console.log(props.selectedids)

  const [notification, setNotification] = useState({
    tipo: '',
    show: false,
    mensaje: ''
  })

  const { id } = useParams()
  const hash = window.location.hash

  const [editTarea, setEditTarea] = useState(false)
  const [changeTareaInputs, setChangeTareaInputs] = useState({
    titulo_tarea: '',
    descripcion_tarea: ''
  })

  const [inputNuevaAccion, setInputNuevaAccion] = useState({
    titulo: '',
    descripcion: '',
    fecha: '',
    cumplimiento: '',
    cumplimiento_cos: ''
  })

  const [inputNuevaAct, setInputNuevaAct] = useState({
    titulo: '',
    descripcion: '',
    // responsable: '',
    corresponsable: '',
    corresponsable_cli: '',
    proceso: '',
    responsable_cli: '',
    periodicidad: '',
    // evidencias: fileObjects
  })
  const [infoItem, setInfoItem] = useState({
    titulo: '',
    descripcion: '',
    fecha: '',
    corresponsable_cli: '',
    responsable_cli: '',
    responsable: '',
    periodicidad: '',
    cumplimiento: '',
    evidencias: [],
    cumplimiento_cos: ''
  })
  const [actividades, setActividades] = useState([]);
  const [actividad, setActividad] = useState({});
  const [activitySection, setActivitySection] = useState(false);
  const [acciones, setAcciones] = useState([]);
  const [actionSection, setActionSection] = useState(false);
  const [actionFormSection, setActionFormSection] = useState(false);
  const [accion, setAccion] = useState({});
  const [open, setOpen] = useState(false);
  const [filename, setFileName] = useState('');
  const [dataTarea, setDataTarea] = useState({
    data: {}
  })

  const handleSelect = (value, tipo) => {

    // setFormulario({
    //   ...formulario,
    //   [tipo] : value.value
    // })


    console.log(value, tipo)
    // //console.log(formulario)
  }


  const obtenerId = (tipo, itemId) => {
    // console.log(tipo, itemId)
    if (tipo === 'act') {
      setIdsItemsModal({
        ...idsItemsModal,
        id_actividad: itemId
      })
      // //console.log(idsItemsModal)
    } else if (tipo === 'accion') {
      setIdsItemsModal({
        ...idsItemsModal,
        id_accion: itemId
      })
      // //console.log(idsItemsModal)

    }
  }


  const items = () => {
    const dataEnviar = {
      id_meta: props.selectedids.id_meta,
      id_objetivo: props.selectedids.id_objetivo,
      id_tarea: props.selectedids.id_tarea,
      hash: window.location.hash,
      id: id
    }

    //console.log(dataEnviar)

    validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataEnviar).then(res => {
      if (res.ok) {
        res.json()
          .then(dataRespuesta => {
            setDataTarea({
              dataTarea,
              data: dataRespuesta
            })
            setActividades(dataRespuesta.actividades)
            setChangeTareaInputs({
              // ...changeTareaInputs,
              titulo_tarea: res?.titulo_tarea,
              descripcion_tarea: res?.descripcion_tarea
            })
          });
      }
    });
  }

  const itemActivity = (item) => {
    setActionSection(false);
    setActividad(item);
    setActivitySection(true);
  }

  const itemActions = (id) => {
    const dataEnviar = {
      id_actividad: id,
      hash: window.location.hash,
    }

    validarEntorno('JSON/gestionar_acciones_act_plan_estrategico.html', 'POST', dataEnviar).then(res => {
      if (res.ok) {
        res.json()
          .then(dataRespuesta => {
            setAcciones(dataRespuesta.acciones);
            setActivitySection(false);
            setActionSection(true);
          });
      }
    });
  }

  const itemActionsForm = (item) => {
    setAccion(item);
    setActionFormSection(true);
  }

  const newActivity = (tipo) => {

    if (tipo === 'accion') {
      setModal({
        type: 'addAccion',
        show: true
      })

    } else {
      setModal({
        type: 'addActividad',
        show: true
      })
    }

    // setActividad({});
    // setActivitySection(true);
    // setActionSection(false);
  }


  useEffect(() => {
    items();
  }, [props.selectedids]);


  const renderTableData = () => {
    return actividades.map((item, index) => {
      return (
        <tr key={item.id_actividad}>
          <td>{item.nombre}</td>
          <td>{`${item.porcentaje}%`}</td>
          <td>
            <Button id="button-pe-edit" className="buttons-pe" onClick={() => { itemActivity(item) }}><EditIcon /></Button>
            <Button id="button-pe-actions" className="buttons-pe" onClick={() => { itemActions(item.id_actividad) }}><ListIcon /></Button>
            <Button id="button-pe-delete" className="buttons-pe"><DeleteIcon /></Button>
          </td>
        </tr>
      );
    });
  }

  const renderTableDataActions = () => {
    return acciones.map((item, index) => {
      return (
        <tr key={item.id_accion}>
          <td>{item.nombre}</td>
          <td>{`${item.porcentaje}%`}</td>
          <td>
            <Button id="button-pe-edit" className="buttons-pe" onClick={() => { itemActionsForm(item) }}><EditIcon /></Button>
            <Button id="button-pe-delete" className="buttons-pe"><DeleteIcon /></Button>
          </td>
        </tr>
      );
    });
  }

  const handleChangeTarea = ({ target }) => {
    setChangeTareaInputs({
      ...changeTareaInputs,
      [target.name]: target.value
    })

    //console.log(changeTareaInputs)
  }

  const updateTarea = async () => {

    const dataReq = {
      action: 'editar_tarea',
      hash: hash,
      id: id,
      titulo_tarea: changeTareaInputs.titulo_tarea ? changeTareaInputs.titulo_tarea : '',
      descripcion_tarea: changeTareaInputs.descripcion_tarea ? changeTareaInputs.descripcion_tarea : '',
      id_meta: props.selectedids.id_meta,
      id_objetivo: props.selectedids.id_objetivo,
      id_tarea: props.selectedids.id_tarea,
    }

    console.log(dataReq)

    if (changeTareaInputs.titulo_tarea !== '' && changeTareaInputs.descripcion_tarea !== '') {

      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()

        setChangeTareaInputs({
          ...changeTareaInputs,
          titulo_tarea: res.titulo,
          descripcion_tarea: res.descripcion
        })
        setDataTarea({
          ...dataTarea,
          data: {
            ...dataTarea.data,
            titulo: res.titulo,
            descripcion: res.descripcion
          }
        })

        // setEditTarea(false)

      } else {
        return 'error'
      }

    }


    //console.log(dataReq)

    setEditTarea(false)
  }


  const handleAdd = ({ target }) => {
    setInputNuevaAct({
      ...inputNuevaAct,
      [target.name]: target.value
    })

    console.log(inputNuevaAct)
  }

  const handleEdit = ({ target }) => {
    setInfoItem({
      ...infoItem,
      [target.name]: target.value
    })

    console.log(infoItem)
  }

  const actionActividad = async (tipo) => {

    console.log(props.selectedids)

    if (tipo === 'actividad') {
      const dataReq = {
        action: 'crear_act',
        hash: hash,
        id: id,
        id_meta: props.selectedids.id_meta,
        id_objetivo: props.selectedids.id_objetivo,
        id_tarea: props.selectedids.id_tarea,
        data: inputNuevaAct
      }

      //console.log(dataReq)

      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

      if (req.ok) {


        const res = await req.json()


        setNotification({
          tipo: 'actividadAgregada',
          show: true
        })

        setModal({
          type: '',
          show: false
        })

        setDataTarea({
          dataTarea,
          data: res
        })
        setActividades(res.actividades)

        setInfoItem({})

        setInputNuevaAct({

        })

        // setInputNuevaAct({
        //     ...inputNuevaAct,
        //     titulo_tarea: res.titulo,
        //     descripcion_tarea: res.descripcion
        // })

      } else {
        return 'error'
      }

    } else if (tipo === 'accion') {
      const dataReq = {
        action: 'crear_accion',
        hash: hash,
        id: id,
        id_meta: props.selectedids.id_meta,
        id_objetivo: props.selectedids.id_objetivo,
        id_tarea: props.selectedids.id_tarea,
        id_act: idsItemsModal.id_actividad,
        evidencia: fileObjects,
        data: inputNuevaAct
      }

      console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

      if (req.ok) {


        const res = await req.json()


        setNotification({
          tipo: 'accionAgregada',
          show: true
        })

        setModal({
          type: '',
          show: false
        })

        setDataTarea({
          dataTarea,
          data: res
        })
        setActividades(res.actividades)

        setInfoItem({})

        setInputNuevaAct({

        })

        // setInputNuevaAct({
        //     ...inputNuevaAct,
        //     titulo_tarea: res.titulo,
        //     descripcion_tarea: res.descripcion
        // })

      } else {
        return 'error'
      }

    }

  }

  const deleteEvidencia = async (id) => {

    const items = infoItem

    const itemsFiltered = items.evidencias.filter(item => item.id !== id)

    setInfoItem({ ...infoItem, evidencias: itemsFiltered })

    deleteItem('evidencia', id)

  }

  const updateItem = async (tipo) => {
    if (tipo === 'actividad') {
      const dataReq = {
        action: 'editar_act',
        hash: hash,
        id: id,
        id_meta: props.selectedids.id_meta,
        id_objetivo: props.selectedids.id_objetivo,
        id_tarea: props.selectedids.id_tarea,
        id_actividad: idsItemsModal.id_actividad,
        updates: infoItem
      }

      console.log(dataReq)

      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

      if (req.ok) {


        const res = await req.json()


        setNotification({
          tipo: 'actividadActualizada',
          show: true
        })

        setModal({
          type: '',
          show: false
        })

        setDataTarea({
          dataTarea,
          data: res
        })
        setActividades(res.actividades)

        // setInputNuevaAct({
        //     ...inputNuevaAct,
        //     titulo_tarea: res.titulo,
        //     descripcion_tarea: res.descripcion
        // })

      } else {
        return 'error'
      }
    } else if (tipo === 'accion') {
      const dataReq = {
        action: 'editar_accion',
        hash: hash,
        id: id,
        id_meta: props.selectedids.id_meta,
        id_objetivo: props.selectedids.id_objetivo,
        id_tarea: props.selectedids.id_tarea,
        id_actividad: idsItemsModal.id_actividad,
        id_accion: idsItemsModal.id_accion,

        updates: { ...infoItem, evidencias: fileObjects }
      }

      console.log(dataReq)

      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

      if (req.ok) {


        const res = await req.json()


        setNotification({
          tipo: 'accionActualizada',
          show: true
        })

        setModal({
          type: '',
          show: false
        })

        setDataTarea({
          dataTarea,
          data: res
        })
        setActividades(res.actividades)
        setFileObjects([])

        setTimeout(() => {
          items();
        }, 1000);

        // setInputNuevaAct({
        //     ...inputNuevaAct,
        //     titulo_tarea: res.titulo,
        //     descripcion_tarea: res.descripcion
        // })

      } else {
        return 'error'
      }
    }

  }

  const confirmDeleteItem = async () => {
    if (eliminacionPendiente) {
      setConfirmDialog(false);
      const tipo = eliminacionPendiente.tipo;
      const idItem = eliminacionPendiente.idItem;
      if (tipo === 'actividad') {
        const dataReq = {
          action: 'eliminar_act',
          hash: hash,
          id: id,
          id_meta: props.selectedids.id_meta,
          id_objetivo: props.selectedids.id_objetivo,
          id_tarea: props.selectedids.id_tarea,
          id_actividad: idsItemsModal.id_actividad
        }

        //console.log(dataReq)

        const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

        if (req.ok) {


          const res = await req.json()


          if (res.estado === 'ok') {
            setNotification({
              tipo: 'actividadEliminada',
              show: true
            })

            setModal({
              type: '',
              show: false
            })

            setDataTarea({
              dataTarea,
              data: res
            })
            setActividades(res.actividades)
          } else if (res.estado === 'false') {
            setNotification({
              tipo: 'errorFormSolicitud',
              show: true,
              mensaje: res.mensaje
            })
          }


          // setInputNuevaAct({
          //     ...inputNuevaAct,
          //     titulo_tarea: res.titulo,
          //     descripcion_tarea: res.descripcion
          // })

        } else {
          return 'error'
        }
      } else if (tipo === 'accion') {
        const dataReq = {
          action: 'eliminar_accion',
          hash: hash,
          id: id,
          id_meta: props.selectedids.id_meta,
          id_objetivo: props.selectedids.id_objetivo,
          id_tarea: props.selectedids.id_tarea,
          id_actividad: idsItemsModal.id_actividad,
          id_accion: idsItemsModal.id_accion

        }

        //console.log(dataReq)

        const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

        if (req.ok) {


          const res = await req.json()


          if (res.estado === 'ok') {
            setNotification({
              tipo: 'accionEliminada',
              show: true
            })

            setModal({
              type: '',
              show: false
            })

            setDataTarea({
              dataTarea,
              data: res
            })
            setActividades(res.actividades)
          } else if (res.estado === 'false') {
            setNotification({
              tipo: 'errorFormSolicitud',
              show: true,
              mensaje: res.mensaje
            })
          }


          // setInputNuevaAct({
          //     ...inputNuevaAct,
          //     titulo_tarea: res.titulo,
          //     descripcion_tarea: res.descripcion
          // })

        } else {
          return 'error'
        }
      } else if (tipo === 'tarea') {

        const dataReq = {
          action: 'eliminar_tarea',
          hash: hash,
          id: id,
          id_meta: props.selectedids.id_meta,
          id_objetivo: props.selectedids.id_objetivo,
          id_tarea: props.selectedids.id_tarea

        }

        //console.log(dataReq)

        const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

        if (req.ok) {
          const res = await req.json()
          if (res.estado === 'ok') {
            setNotification({
              tipo: 'tareaEliminada',
              show: true
            })

            // setModal({
            //     type: '',
            //     show: false
            // })

            setDataTarea({
              dataTarea,
              data: res
            })
            props.getDataInicial()
            // props.setIsOpen(false)
            // setActividades(res.actividades)
          } else if (res.estado === 'false') {
            setNotification({
              tipo: 'errorFormSolicitud',
              show: true,
              mensaje: res.mensaje
            })
          }
        } else {
          return 'error'
        }
      } else if (tipo === 'evidencia') {

        const dataReq = {
          action: 'eliminar_evidencia',
          hash: hash,
          id: id,
          id_meta: props.selectedids.id_meta,
          id_objetivo: props.selectedids.id_objetivo,
          id_tarea: props.selectedids.id_tarea,
          id_actividad: idsItemsModal.id_actividad,
          id_accion: idsItemsModal.id_accion,
          id_evidencia: idItem

        }

        //console.log(dataReq)

        const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_tarea_pe.html', 'POST', dataReq)

        if (req.ok) {
          const res = await req.json()
          if (res.estado === 'ok') {
            // setNotification({
            //     tipo: 'accionEliminada',
            //     show: true
            // })
            // setModal({
            //     type: '',
            //     // show: false
            // })
            // setDataTarea({
            //     dataTarea,
            //     data: res
            // })
            setActividades(res.actividades)
          } else if (res.estado === 'false') {
            setNotification({
              tipo: 'errorFormSolicitud',
              show: true,
              mensaje: res.mensaje
            })
          }
        } else {
          return 'error'
        }

      }
    }

  }

  const deleteItem = async (tipo, idItem) => {
    setEliminacionPendiente({ tipo, idItem });
    setConfirmDialog(true);
  }

  const openModal = (tipo, show, item) => {

    // console.log(item)

    if (tipo === 'verActividad' || tipo === 'editActividad') {
      setModal({
        type: tipo,
        show: show
      })
      setInfoItem({
        titulo: item.nombre,
        descripcion: item.descripcion,
        proceso: item.proceso,
        responsable: item.responsable,
        corresponsable: item.corresponsable ? item.corresponsable : null,
        responsable_cli: item.responsable_cli ? item.responsable_cli : null,
        corresponsable_cli: item.corresponsable_cli ? item.corresponsable_cli : null,
        periodicidad: item.periodicidad ? item.periodicidad : null
      })
    } else if (tipo === 'verAccion' || tipo === 'editAccion') {
      setModal({
        type: tipo,
        show: show
      })

      setInfoItem({
        id_accion: item.id_accion,
        titulo: item.nombre,
        accion: item.accion,
        label_cumplimiento: item.label_cumplimiento,
        label_cumplimiento_cos: item.label_cumplimiento_cos,
        descripcion: item.descripcion,
        cumplimiento: item.cumplimiento,
        fecha: item.fecha,
        fecha_real_cli: item.fecha_real_cli,
        fecha_real_gr: item.fecha_real_gr,
        cumplimiento: item.cumplimiento,
        cumplimiento: item.cumplimiento,
        evidencias: item.evidencias,
        cumplimiento_cos: item.cumplimiento_cosinte
      })
    }

  }

  return (
    <div className="ContainerModalStrategic">
      <div id='contenedorDataGeneralTarea'>
        <p id='tituloTarea'>
          {editTarea === false
            ?
            <>

              <p style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '80%' }}><div className='numeracionLabel'>{props.numeracionPadre}</div>{' '}{dataTarea?.data.titulo}</p>
              <div className='btn_gestion_tarea_container' >
                {dataTarea?.data.permisos?.editar !== false &&
                  <button
                    className='btnEditarTarea'
                    onClick={() => setEditTarea(true)}>
                    <EditOutlinedIcon fontSize='small' />
                  </button>
                }
                {dataTarea?.data.permisos?.eliminar !== false &&
                  <button
                    onClick={() => deleteItem('tarea', dataTarea)}
                    className='btnEditarTarea'>
                    <DeleteOutlineOutlinedIcon fontSize='small' />
                  </button>
                }
              </div>
            </>
            :
            <>
              {
                (editTarea === true)

                &&
                <button
                  className='btnEditarTareaDone'
                  onClick={() => updateTarea()}
                >
                  <DoneIcon fontSize='small' />
                </button>
              }
              <textarea
                id='inputTituloTarea'
                name='titulo_tarea'
                type='text'
                defaultValue={dataTarea.data.titulo}
                onChange={(e) => handleChangeTarea(e)}
              />
            </>
          }
        </p>
        {editTarea === false
          ?
          <p id='descripcionTarea'>{changeTareaInputs.descripcion_tarea}</p>
          : <textarea
            id='inputDescTarea'
            name='descripcion_tarea'
            className='scrollSecundario'
            defaultValue={dataTarea.data.descripcion}
            onChange={(e) => handleChangeTarea(e)}
          />
        }


      </div>
      <br />
      <h4 className='pagesTitles' style={{ textAlign: 'justify' }}>{t('modales.lista_actividades')}</h4>
      {actividades.length > 0 &&
        <div id='contenedorAcordeonActividades' className='scrollSecundario'>
          <Accordion defaultActiveKey="0">
            {actividades.map((act, indexAct) => {
              return (
                <>
                  <Card key={act.id_actividad}>
                    <Accordion.Toggle
                      className='toggleObj'
                      as={Card.Header}
                      eventKey={act.id_actividad}
                      onMouseDown={() => obtenerId('act', act.id_actividad)}
                    >
                      <div
                        style={{ minHeight: '35px', width: '100%', display: 'flex', justifyContent: 'space-between' }}
                      >
                        <p style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '80%' }} >
                          <div className='numeracionLabel'>
                            {props.numeracionPadre}.{(indexAct + 1)}
                          </div>
                          <p>{act.nombre}</p>
                        </p>
                        <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div className='contenedorAccionesObj'>
                            {act.permisos?.ver !== false &&
                              <button
                                onClick={() => openModal('verActividad', true, act)}
                                className='detailButtonPE accionObj'>
                                <InfoOutlinedIcon fontSize='small' />
                              </button>
                            }
                            {act.permisos?.editar !== false &&
                              <button
                                onClick={() => openModal('editActividad', true, act)}
                                // onClick={() => openModal('editObj', true, obj)}
                                className='editButtonPE accionObj'>
                                <EditOutlinedIcon fontSize='small' />
                              </button>
                            }
                            {act.permisos?.eliminar !== false &&
                              <button
                                className='deleteButtonPE accionObj'
                                onClick={() => deleteItem('actividad', act)} >
                                <DeleteOutlineOutlinedIcon fontSize='small' />
                              </button>
                            }
                          </div>
                          {act.porcentaje_cumplimiento}%
                        </p>
                      </div>

                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={act.id_actividad}>
                      <Card.Body>
                        {act?.acciones?.length && <h5 className='pagesTitles' style={{ textAlign: 'justify', marginBottom: '10px', color: '#8B97A7' }} >{t('modales.lista_acciones')}</h5>}
                        {act.acciones && act.acciones.length > 0
                          ?
                          <div>
                            {
                              act.acciones.map((accion) => {
                                return (
                                  <>
                                    <div
                                      className='toggleObj'
                                      style={{ margin: 'auto', minHeight: '30px', width: '90%', display: 'flex', justifyContent: 'space-between' }}
                                      onMouseDown={() => obtenerId('accion', accion.id_accion)}

                                    >
                                      <p style={{ maxWidth: '80%' }} >
                                        {accion.nombre}
                                      </p>
                                      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='contenedorAccionesObj'>
                                          {accion.permisos?.ver !== false &&
                                            <button
                                              onClick={() => openModal('verAccion', true, accion)}
                                              className='detailButtonPE accionObj'>
                                              <InfoOutlinedIcon fontSize='small' />
                                            </button>
                                          }
                                          {accion.permisos?.editar !== false &&
                                            <button
                                              onClick={() => openModal('editAccion', true, accion)}
                                              // onClick={() => openModal('editObj', true, obj)}
                                              className='editButtonPE accionObj'>
                                              <EditOutlinedIcon fontSize='small' />
                                            </button>
                                          }
                                          {accion.permisos?.eliminar !== false &&
                                            <button
                                              className='deleteButtonPE accionObj'
                                              onClick={() => deleteItem('accion', accion)}>
                                              <DeleteOutlineOutlinedIcon fontSize='small' />
                                            </button>
                                          }
                                        </div>
                                        {accion.porcentaje_cumplimiento}%
                                      </p>
                                    </div>
                                  </>
                                )
                              })
                            }
                          </div>
                          : null

                        }
                        {act.permisos?.agregar !== false &&
                          <button
                            onClick={() => newActivity('accion')}
                            id='buttonAddMeta'
                            className='confirmButton'
                            style={{ color: 'black' }}
                          >
                            {t('botones.anadir_accion')}
                          </button>
                        }

                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>
              )
            })}
          </Accordion>
        </div>
      }
      <br />
      {dataTarea?.data.permisos?.agregar !== false &&
        <button id='buttonAddObj' className='confirmButton' onClick={() => newActivity()} >{t('botones.anadir_actividad')}</button>
      }

      {actionSection ? (
        <div className="action-section">
          <div className="container-button-add">
            <Button>Añadir Accion</Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Acción</th>
                <th>Porcentaje</th>
                <th>Gestionar</th>
              </tr>
            </thead>
            <tbody>
              {renderTableDataActions()}
            </tbody>
          </Table>
          {actionFormSection ? (
            <>
              <div className="section-title">
                <h6>Editar {accion.nombre}</h6>
              </div>
              <Form>
                <Form.Row>
                  <Col xl={6}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Nombre de la actividad</Form.Label>
                      <Form.Control placeholder="Nombre de la actividad" defaultValue={accion.nombre} />
                    </Form.Group>
                  </Col>
                  <Col xl={6}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Observaciones</Form.Label>
                      <Form.Control type="textarea" placeholder="Observaciones" defaultValue={accion.observaciones} />
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Nombre de la actividad</Form.Label>
                      <Form.Control placeholder="Nombre de la actividad" defaultValue={accion.nombre} />
                    </Form.Group>
                  </Col>
                  <Col xl={6}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Observaciones</Form.Label>
                      <Form.Control type="textarea" placeholder="Observaciones" defaultValue={accion.observaciones} />
                    </Form.Group>
                  </Col>
                  <Col xl={6}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Documento</Form.Label>
                      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                        Subir Archivo
                      </Button>
                      <DropzoneDialog
                        open={open}
                        onClose={() => setOpen(false)}
                        onSave={(files) => {
                          //console.log('Files:', files);
                          setOpen(false);
                          setFileName(files[0].name)
                        }}
                        showFileNamesInPreview={true}
                      />
                      <Form.Label>{filename}</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={12}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Observaciones</Form.Label>
                      <Form.Control as="textarea" placeholder="Observaciones" defaultValue={accion.observaciones} />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Button onClick={() => setActionFormSection(false)}>Enviar</Button>
              </Form>
            </>
          ) : null}
        </div>
      ) : null}
      <EditModal
        stateAdd={inputNuevaAct}
        typeModal={modal.type}
        isOpen={modal.show}
        setIsOpen={setModal}
        handleAddItem={handleAdd}
        handleEditItem={handleEdit}
        handleNotification={setNotification}
        items={items}
        // stateAddItem={addItem}
        sendNewItem={actionActividad}
        sendEditItem={updateItem}
        deleteItem={deleteItem}
        updateItem={props.updateItem}
        infoItem={infoItem}
        handleSelect={handleSelect}
        fileObjects={fileObjects}
        setFileObjects={setFileObjects}
        deleteEvidencia={deleteEvidencia}
      />
      <NotificationModal
        typeModal={notification.tipo}
        isOpen={notification.show}
        setIsOpen={setNotification}
        setIsOpenTarea={props.setIsOpen}
        mensaje={notification.mensaje}
      />
      {/* Modal de confirmación de eliminación */}
      <NotificationModal
        typeModal={"deleteItemConfirm"}
        isOpen={confirmDialog}
        setIsOpen={setConfirmDialog}
        handleEliminar={confirmDeleteItem}
        itemName={'elemento'}
      />
    </div>
  );
}

export default ModalContent;
