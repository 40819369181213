import React, { useState, useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal'
import { DropzoneDialogBase } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Tabla from '../../../common/Tabla'
import './styles/GestorDocumentalPestana.scss'

const COLUMNAS = [
  {
    label: 'Id',
    value: 'id'
  },
  {
    label: 'Tipo de archivo',
    value: 'tipoDocumento'
  },
  {
    label: 'Descripción',
    value: 'descripcion'
  },
  {
    label: 'Enlace',
    value: 'url'
  }
]

export const GestorDocumentalPestaña = ({ ele }) => {

  const { t } = useTranslation();
  const { id: codigo } = useParams()
  const [openModal, setOpenModal] = useState(false)
  const [filas, setFilas] = useState([{}])
  const [modalrespuestaAddanddelete, setModalrespuestaAddanddelete] = useState(false)
  const [modalConfirmarEliminacion, setModalConfirmarEliminacion] = useState(false)
  const [editarVisible, setEditarVisible] = useState(false)
  const [tipomodalRespuestaAddandDelete, setTipomodalRespuestaAddandDelete] = useState('')
  const [mensaje, setMensaje] = useState('')
  const [elementoEliminado, setElementoEliminado] = useState('')
  const [zonacarga, setZonacarga] = useState(false)
  const [tipomodalEditar, setTipomodalEditar] = useState('')
  const [modalOpenEditar, setModalOpenEditar] = useState(false)
  const [fileObjects, setFileObjects] = useState([]);
  const [dataSelect, setDataSelect] = useState({
    "tipoDocumento": "",
    "descripcion": "",
    "id": ""
  })

  const changeInput = ({ target }, id) => {
    setDataSelect({
      ...dataSelect,
      [target.name]: target.value
    })
  }


  const confirmarEliminacion = (item) => {

    const data = {
      idSolicitud: codigo,
      idFile: item.id,
      hash: window.location.hash
    }

    setElementoEliminado(data)

    setModalConfirmarEliminacion(true)
  }


  /* eliminar archivo */
  const deleteFile = async (item) => {

    const data = {
      idSolicitud: codigo,
      idFile: item.idFile,
      hash: window.location.hash
    }

    const respuesta = await validarEntorno('JSON/gestor_documental_eliminar_archivo_json.html', 'POST', data);
    const body = await respuesta.json();

    if (body.ok) {
      // ok
      setModalrespuestaAddanddelete(true)
      setTipomodalRespuestaAddandDelete('SuccessDeletefile')
      //
      const mapFilas = body.files.map((item, i) => {
        return {
          id: item.id,
          tipoDocumento: item?.opcionesDocumentos?.[0]?.documento,
          opcionesDocumentos: item.opcionesDocumentos,
          descripcion: item.descripcion,
          url: item.url
        }
      })
      setFilas(mapFilas)
      setModalConfirmarEliminacion(false)

    } else {
      // errado
      setModalrespuestaAddanddelete(true)
      setTipomodalRespuestaAddandDelete('ErrorFile')
      setMensaje(body.mensaje)
    }
  }

  const envioDataFileFunction = async (file) => {
    let data = {
      file: file,
      hash: window.location.hash,
      idSolicitud: codigo
    }



    const respuesta = await validarEntorno('JSON/gestor_documental_json.html', 'POST', data)
    const body = await respuesta.json();

    if (body.ok) {
      setModalrespuestaAddanddelete(true)
      setTipomodalRespuestaAddandDelete('SuccessFile')
      const mapFilas = body.files.map((item, i) => {
        return {
          id: item.id,
          tipoDocumento: item?.opcionesDocumentos?.[0]?.documento,
          opcionesDocumentos: item.opcionesDocumentos,
          descripcion: item.descripcion,
          url: item.url
        }
      }
      )
      setFilas(mapFilas)
    } else {
      setModalrespuestaAddanddelete(true)
      setTipomodalRespuestaAddandDelete('ErrorFile')
      setMensaje(body.mensaje)
    }
  }

  const handleEditArchivo = async () => {
      const resultado = await validarEntorno('JSON/gestionar_solicitud_gestor_documental_editar_archivo_json.html', 'POST', dataSelect);
      const body = await resultado.json()

      if (body.ok) {
        setMensaje(body.mensaje)
        setTipomodalEditar('edicionDataRealizada')
        setModalOpenEditar(true)
        const mapFilas = body.files.map((item, i) => {
          return {
            id: item.id,
            tipoDocumento: item?.opcionesDocumentos?.[0].documento,
            opcionesDocumentos: item.opcionesDocumentos,
            descripcion: item.descripcion,
            url: item.url
          }
        })
        setFilas(mapFilas)
        setEditarVisible(false)
      } else {
        setMensaje(body.mensaje)
        setTipomodalEditar('edicionRechazada')
        setModalOpenEditar(true)
      }
  }

  useEffect(() => {

    let data = {
      hash: window.location.hash,
      idSolicitud: codigo
    }

    const dataTabla = async () => {
      const respuesta = await validarEntorno('JSON/gestor_documental_json.html', 'POST', data)
      const body = await respuesta.json();
      if (body.ok) {
        const mapFilas = body.files.map((item, i) => {
          return {
            id: item.id,
            tipoDocumento: item?.opcionesDocumentos?.[0].documento,
            opcionesDocumentos: item.opcionesDocumentos,
            descripcion: item.descripcion,
            url: item.url
          }
        })

        setFilas(mapFilas)
      } else {
        setMensaje(body.mensaje)
      }
    }

    dataTabla()
  }, [])


  return (
    <div>
      {/*
      <Card className='card__box__surface p-4 card__plain__top__left'>
        <Card.Body className='p-0'>
          <h4 className='titleInsideCard'>{t('formulario.label.archivos_adjuntos')} {filesList?.cuenta ? `- ${filesList.cuenta}` : null}</h4>
          {ele.editar &&
            <button className='confirmButton mt-3' onClick={() => setZonacarga(true)}> {t('botones.cargar_archivos')}</button>
          }
          <div className='tableContainer filesTableContainer p-0 mt-3'>
            <table className=' table tablaArchivosGestionSolicitudInteligencia'>

              {filesList?.files
                &&
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t('formulario.label.tipo_archivo')}</th>
                    <th>{t('formulario.label.descripcion')}</th>
                    <th>{t('formulario.label.enlace')}</th>
                    {ele.editar &&
                      <th>{t('formulario.label.acciones')}</th>
                    }
                  </tr>
                </thead>}


              <tbody>

                {filesList?.files?.map((item, i) => {
                  return (
                    <tr className='fileRow' key={item.id + i} name='id' onMouseEnter={() => { setDataSelect({ ...dataSelect, id: item.id }) }} >
                      <td>{item.id}</td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            as='select'
                            disabled={ele?.editar ? false : true}
                            name="tipoDocumento"
                            onChange={(e) => changeInput(e, item.id)}
                            defaultValue={item.id}
                            style={{ gridColumn: '1 / 3' }}
                          >

                            {
                              item.opcionesDocumentos.map((documento, index) => {
                                return <option key={index + index * 2} value={documento.id}>{documento.documento}</option>
                              })
                            }

                          </Form.Control>

                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className='formGroupTextArea' >
                          <Form.Control
                            as='textarea'
                            name="descripcion"
                            defaultValue={item.descripcion}
                            onChange={(e) => changeInput(e, item.id)}
                            className='textAreaGeneral'
                            style={{ gridColumn: '1 / 3' }}
                            disabled={ele?.editar ? false : true} />
                        </Form.Group>
                      </td>
                      <td >
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                          <button
                            className="confirmButton buttonDocs"
                            style={{ marginRight: '0px', width: '100%' }}
                            onClick={() => openDataUri(item.url)}
                          >
                            {t('botones.ver')}
                          </button>
                        </div>
                      </td>
                      {ele.editar &&
                        <td style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                          <button className='deleteButton botonEliminar' style={{ marginRight: '10px' }} onClick={(e) => confirmarEliminacion(item)}>{t('botones.eliminar')}</button>
                          {
                            editar === item.id
                              ?
                              <button className='deleteButton botonEditar' onClick={(e) => handleEditArchivo(item)}>{t('botones.editar')}</button>
                              : <Fragment></Fragment>
                          }

                        </td>

                      }
                    </tr>
                  )
                })}


              </tbody>
            </table>
          </div>

        </Card.Body>
      </Card>
      */}
      
      <Card className='card__box__surface p-4 card__plain__top__left'>
      <h4 className='titleInsideCard'>{t('formulario.label.archivos_adjuntos')}</h4>
        <Tabla
          botonCrearVisible={ele?.editar ? true : false}
          botonEliminarVisible={ele?.editar ? true : false}
          handleEliminar={confirmarEliminacion}
          columnas={COLUMNAS}
          filas={filas}
          handleCrear={() => setZonacarga(true)}
          handleEditar={(item) => {
            setDataSelect({
              tipoDocumento: item?.opcionesDocumentos?.[0].id,
              opcionesDocumentos: item.opcionesDocumentos,
              descripcion: item.descripcion,
              id: item.id
            })
            setEditarVisible(true);
          }}
        />
      </Card>

      <DropzoneDialogBase
        filesLimit={100}
        open={zonacarga}
        dialogTitle={t('formulario.label.subir_archivos')}
        dropzoneText={t('formulario.label.arrastra_archivos_icono')}
        acceptedFiles={['image/*', 'application/pdf']}
        maxFileSize={350000000}
        cancelButtonText={t('botones.cancelar')}
        submitButtonText={t('botones.cargar')}
        fileObjects={fileObjects}
        onClose={() => setZonacarga(false)}
        className='zonaDeCarga'
        previewText='Vista previa'
        onAdd={newFileObjs => {
          setFileObjects([].concat(fileObjects, newFileObjs));
        }}
        onSave={() => {
          setZonacarga(false);
          envioDataFileFunction(fileObjects)
          setFileObjects([])
        }}
        showFileNamesInPreview={false}
      />

      <Dialog header="Editar registro" visible={editarVisible} style={{ width: '50vw' }} onHide={() => setEditarVisible(false)}>
        {dataSelect && (
          <>
            <Form.Group>
              <Form.Label>Tipo de archivo</Form.Label>
              <Form.Control
                as='select'
                disabled={ele?.editar ? false : true}
                name="tipoDocumento"
                onChange={(e) => changeInput(e, dataSelect.id)}
                style={{ gridColumn: '1 / 3' }}
              >
                {
                  dataSelect?.opcionesDocumentos?.map((documento, index) => {
                    return <option key={index + index * 2} value={documento.id}>{documento.documento}</option>
                  })
                }
              </Form.Control>
            </Form.Group>
            <Form.Group className='formGroupTextArea' >
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as='textarea'
                name="descripcion"
                defaultValue={dataSelect.descripcion}
                onChange={(e) => changeInput(e, dataSelect.id)}
                className='textAreaGeneral'
                style={{ gridColumn: '1 / 3' }} />
            </Form.Group>
            <div className='d-flex justify-content-end'>
              <Button size='small' label={t('botones.guardar')} onClick={(e) => handleEditArchivo()} />
            </div>
          </>
        )}
      </Dialog>

      <NotificationModal typeModal='solicitudSinCrear' isOpen={openModal} setIsOpen={setOpenModal}  ></NotificationModal>
      <NotificationModal typeModal='eliminarArchivoGestionar' isOpen={modalConfirmarEliminacion} setIsOpen={setModalConfirmarEliminacion} itemAEliminar={elementoEliminado} accionEliminar={deleteFile}  ></NotificationModal>
      <NotificationModal typeModal={tipomodalRespuestaAddandDelete} isOpen={modalrespuestaAddanddelete} setIsOpen={setModalrespuestaAddanddelete} mensaje={mensaje} />
      <NotificationModal typeModal={tipomodalEditar} isOpen={modalOpenEditar} setIsOpen={setModalOpenEditar} mensaje={mensaje} />
    </div>
  )
}
