 import React, {  useEffect, useState } from 'react';
import { Form, Col, Card, Button, Row, ButtonGroup } from 'react-bootstrap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import { validarEntorno } from '../../helpers/validarHost';
import NotificationModal from '../modales/NotificationModal';
import EditModal from '../modales/EditModal';
import { useParams } from 'react-router-dom';
import '../gestionAuditorias/PanoramaRiesgos/PestanasInforme/styles/Factores.scss'
import Loader from '../icons/Loader'
import { Resaltado } from '../icons/Resaltado'
import { LeftSVG } from '../icons/LeftSVG';
import { RightSVG } from '../icons/RightSVG';
import useState2 from 'react-usestateref';
import { useForm } from 'react-hook-form';

import DeleteIcon from '@material-ui/icons/Delete';

import InputType from '../../helpers/InputType';

export default function TablaRegistrosCargue({ tipo, band, setBand }) {

  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();

  const iconos = {
        eliminar: {
            ruta: 'https://www.ciacosinte.site/cia_2/build/static/media/borrar.svg'
        },
        otro: {
            ruta: ''
        },
  }

  const { id } = useParams();

  const HASH = window.location.hash;

  const [filtrosForm, setFiltrosForm] = useState({});
  const [modalMode, setModalMode] = useState('')
  const [areaOpen, setAreaOpen] = useState('')
  // const [areaOpenLabel, setAreaOpenLabel] = useState('')
  const [actsLista, setActsLista] = useState({
    actividades:[]
  })
  const [registrosLista, setRegistrosLista] = useState([]);
  const [modal, setModal] = useState({
    // data: {},
    type: '',
    show: false
  })
  const [notificacionType, setNotificacionType] = useState('')
  const [notificacionShow, setNotificacionShow] = useState(false)
  const [notificacionMensaje, setNotificacionMensaje] = useState('')
  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false
  })

  const [filtros, setFiltros, filtrosRef] = useState2({
    limite: 10,
    pagina: 1
  });

  const [isAllRecords, setIsAllRecords] = useState(false);


  const closeModal = () => {
    setNotificacionShow(false);
    setBand(!band);
  }

  const getRiesgos = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: id
    }

    const req = await validarEntorno('JSON/GestionDinamica/tabla_registros_facturas.php', 'POST', dataReq)

    if(req.ok){
      const res = await req.json()

      setInitialState({...initialState, data: res})
      //setAreaOpen(res.factores[0]?.id)
      // setAreaOpenLabel(res.factores[0]?.value)

    //   getActividades(res.factores[0]?.id);
    }

  }

  const filterData = async (e) => {

    setInitialState({...initialState, loading: true})

    const { name, value } = e.target;

    setFiltros({
        ...filtros,
        [name]: value
    });

    const dataReq = {
        hash: HASH,
        // dataForm: filtrosForm.filtros,
        id: id,
        id_factor: areaOpen,
        limite: filtrosRef.current.limite,
        pagina: filtrosRef.current.pagina,
    };

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_factores_act.html','POST', dataReq);

    if(req.ok){
        const res = await req.json()
        setInitialState({...initialState, loading: false })
        setActsLista(res)
        console.log(res)
    }else{
        setInitialState({...initialState, loading: false, error: req.error })
    }
  }

  const paginateData = async (page) => {
    setInitialState({...initialState, loading: true})
    setFiltros({
        ...filtros,
        pagina: page
    });

    const dataReq = {

        hash: HASH,
        // dataForm: filtrosForm?.filtros,
        limite: filtrosRef.current.limite,
        pagina: filtrosRef.current.pagina,
        id: id,
        id_factor: areaOpen
    };

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_factores_act.html','POST', dataReq);

    if(req.ok){
        const res = await req.json()
        setInitialState({...initialState, loading: false })
        setActsLista(res)

        // console.log(res)
    }else{
        setInitialState({...initialState, loading: false, error: req.error })
    }
}

const resaltarMarker = (tipo, id, idx) => {
    let x = { ...initialState }
    let y = {...x.data}
    const b = [...y.regs]
    const c = {...b[idx]}
    c.check = tipo
    b[idx] = c
    y.regs = b
    x.data = y
    addItemToState(id, tipo)
    setInitialState(x)
}

const resaltarMarkerAll = (tipo) => {
  setIsAllRecords(tipo);
  let x = { ...initialState }
  let y = {...x.data}
  let b = [...y.regs]

  const newArray = [];
  const idReg = []

  b.map((data) => {
    data.check = tipo;
    newArray.push(data);
    idReg.push(data.id);
  });

  addManyItems(idReg, tipo);

  b = newArray;
  y.regs = b;
  x.data = y;
  setInitialState(x);
}

const addItemToState = (id, tipo) => {
    const y = [...registrosLista];
    if (!y.includes(id) && tipo){
        y.push(id);
        // debugger;
    } else if(y.includes(id) && !tipo) {
        const i = y.indexOf(id);
        if (i !== -1) {
            y.splice(i, 1);
        }
    }
    setRegistrosLista(y);
}

const addManyItems = (arr, tipo) => {
  if (tipo) {
    setRegistrosLista(arr);
  } else {
    setRegistrosLista([]);
  }
}

  // useEffect(() => {
  //   // getRiesgos();
  //   // setAreaOpen(initialState?.data[0]?.id);
  //   getActividades(areaOpen);
  // }, [filtrosForm])

const sendDataStatus = async (valor) => {

    if (registrosLista.length > 0) {
        const dataReq = {
            hash: HASH,
            id: id,
            id_factor: areaOpen,
            estado: valor,
            registros: registrosLista
        };

        const req = await validarEntorno('JSON/GestionDinamica/enviar_datos_registros.php','POST', dataReq);

        if (req.ok) {
            const res = await req.json();

            if (res.estado === 'OK') {
                setNotificacionType('envio_peticion_correcto');
                setRegistrosLista([]);
            } else {
                setNotificacionType('envio_peticion_erroneo');
            }
            setNotificacionShow(true)
            setNotificacionMensaje(res.mensaje);
        }

    } else {
        setNotificacionType('envio_peticion_alerta');
        setNotificacionShow(true)
        setNotificacionMensaje('No hay registros que enviar');
    }

}

  useEffect(() => {
    getRiesgos();
    setAreaOpen(initialState?.data[0]?.id);
  }, [])


  useEffect(() => {
    if (band) {
      getRiesgos();
    }
  }, [band]);

  const setActionRow = async (reg, value) => {
    const dataReq = {
        hash: HASH,
        id: reg,
        operacion: value
    };

    const req = await validarEntorno('JSON/GestionDinamica/accion_boton_reg_fac.php','POST', dataReq);

    if (req.ok) {
        const res = await req.json();

        if (res.estado === 'OK') {
            setNotificacionType('envio_peticion_correcto');
            setInitialState({...initialState, data: {}});
            setTimeout(() => {
                getRiesgos();
            }, 2000);
        } else {
            setNotificacionType('envio_peticion_erroneo');
        }
        setNotificacionShow(true)
        setNotificacionMensaje(res.mensaje);
    }

  }

  const getIconButton = (id, value) => {
    let icon = '';

    switch (value) {
        case 'eliminar':
            icon = <DeleteIcon className="iconReg deleteReg" onClick={() => setActionRow(id, value)}  />;
            break;
        default:
            break;
    }

    return icon;
  }

  return (
    <>
    <Card>
      <Card.Body>
        <div className='info_factor'>
          <div>{`Registros`}</div>
        </div>
        {initialState?.data?.length === 0
          ?
          <div id='mensaje_acts_vacias'>No hay registros relacionados</div>
          :
          <>
            {(tipo !== 'listas_cargue' && initialState?.data?.buttons?.length > 0) ? (
                <div className="buttonRegContainer">
                    <ButtonGroup aria-label="Basic example">
                        {initialState?.data?.buttons.map((button, index) => (
                            <button onClick={() => {sendDataStatus(button.valor)}}>{button.label}</button>
                        ))}
                    </ButtonGroup>
                </div>
            ) : null}
            <div id='header_tabla' className='shadow' >
              <div id='header_tabla-numero_registros'>
                <p> Registros: </p>
                <Form.Control
                    className='inputGroup'
                    id='cantidad_regs'
                    name='limite'
                    as='select'
                    onChange={(e) => {filterData(e)}}
                    value={filtros.limite}
                >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>150</option>
                </Form.Control>
              </div>
                  <div id='header_tabla-paginado'>
                      {initialState?.data?.pag_total > 1 &&
                          (
                              <div style={{ cursor: 'pointer' }}
                                onClick={() => { paginateData(initialState?.data?.pag_ant) }}
                              >
                                  <LeftSVG />
                              </div>
                          )
                      }
                      <div className='paginadoItems' id='pagactualnum'>
                          <p>Pag</p>
                          <p>{initialState?.data?.pag_actual}</p>
                      </div>
                      <div className='paginadoItems' id='pag_total_num'>
                          <p>de</p>
                          <p>{initialState?.data?.pag_total}</p>
                      </div>

                      {initialState?.pag_total !== initialState?.data?.pag_actual && (
                          <div style={{ cursor: 'pointer' }}
                            onClick={() => { paginateData(initialState?.data?.pag_sig) }}
                          >
                              <RightSVG />
                          </div>
                      )}
                  </div>
                  {/* <div
                    className='btn_filtros'
                    style={{ cursor: 'pointer', display: 'flex', justifyContent:'space-around'}}
                    onClick={() => {setModal({...modal, show: true}); setModalMode('filtros')}}

                  >
                      <p>
                          Filtros
                      </p> */}
                      {/* <SettingsIcon id='btn_filtros-icon' /> */}
                      {/* <p id='total_regs'>
                          {actsLista?.total_regs}
                      </p> */}
                  {/* </div> */}
            </div>
            <br/>
            <div className='table_reps' >
            { tipo !== 'listas_cargue' ? (
              <>
                <div id='cols'>
                    {
                        initialState?.data?.cols?.map((col, idx) => {
                        return(
                          <div className='col_item' key = {idx}>
                            {col}
                          </div>
                        )
                      })
                    }
                    <div className='col_item' id='resaltado_col' >
                      <CheckCircleIcon onClick={() => resaltarMarkerAll(!isAllRecords)}   style={{ cursor:'pointer', color: isAllRecords ? '#13cd19'  : 'rgb(220, 220, 220)' }} />
                    </div>
                </div>
                <div id='regs' >
                  {initialState?.data?.regs?.map((item, index) => {

                    return(
                      <Card key={index} className='shadow reg_item_factores'>
                        {item.cols?.map((colreg, idx) => {
                          return(
                            <div className='colreg_factores' key={idx}>
                              {colreg.valor}
                            </div>
                          )
                        })}
                        <div className='colreg_factores'>
                              <div>
                                  {initialState?.data?.botones_registro.map((boton, index) => (
                                      (boton.mostrar ? (
                                        getIconButton(item.id, boton.icono)
                                      ) : null)
                                  ))}
                              </div>

                        </div>
                        <div className='colreg_factores'>
                          <CheckCircleIcon
                            style={{
                              cursor:'pointer',
                              color: item.check === true ? '#13cd19'  : 'rgb(220, 220, 220)'
                            }}
                            onClick={() => {resaltarMarker(!item.check, item.id, index)}}
                          />
                        </div>
                      </Card>
                    )
                  })
                  }
                </div>
              </>
            ) : <>
                <div id='cols'>
                    {
                        initialState?.data?.cols?.map((col, idx) => {
                        return(
                          <div className='col_item' key = {idx}>
                            {col}
                          </div>
                        )
                      })
                    }
                </div>
                <div id='regs' >
                  {initialState?.data?.regs?.map((item, index) => {
                    return(
                      <Card key={index} className='shadow reg_item_factores'>
                        {item.cols?.map((colreg, idx) => {
                          return(
                            <div className='colreg_factores' key={idx}>
                              { idx !== (item.cols.length - 1) ? colreg.valor : (<a href={colreg.valor}>Ver</a>)}
                            </div>
                          )
                        })}
                      </Card>
                    )
                  })
                  }
                </div>
              </> }

            </div>
          </>
        }
        <div></div>
      </Card.Body>
    </Card>
    <NotificationModal
      isOpen={notificacionShow}
      setIsOpen={closeModal}
      typeModal={notificacionType}
      mensaje={notificacionMensaje}
    />
    </>
  )
}
