import { Col, Form } from "react-bootstrap";
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from "react";
import { BtnAyuda } from "./BtnAyuda";

const Multiple = ({ item, handleInputChange, index, idx, reg, nombre, esEditable }) => {

  const { control } = useForm();

  const [data, setData] = useState({ opcion_multiple: item.valor });



  // useEffect(() => {
  //     console.log(item)
  // }, [])


  const getMultiples = (value) => {
    setData({
      ...data,
      opcion_multiple: value
    })
  }

  const getDataValues = (arr) => {
    // console.log(arr)
    const arrValues = [];
    arr.map((item, index) => {
      arrValues.push(item.value)
    })

    return arrValues;
  }

  return (
    <Form.Group style={{ display: 'block' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
        <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : "" }}>{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
        {item.ayuda &&
          <BtnAyuda ayuda={item.ayuda} />
        }
      </div>
      <Controller
        control={control}
        name={`${nombre}.${item.nombre}`}
        {...reg(`asesoria.${index}.${item.nombre}`, {
          required: item.es_requerido
        })}
        render={({ onChange, value }) => (
          <Select
            id='selectPersonalGerencia'
            ref={reg}
            classNamePrefix="select__multiple"
            options={item.valores_opcion}
            name={`${nombre}.${item.nombre}`}
            defaultValue={item.valor}
            value={item.valores_opcion.find(c => c.value === value)}
            onChange={(e) => onChange(getMultiples(e))}
            closeMenuOnSelect={false}
            isMulti={true}
            placeholder='-- Seleccione --'
            isDisabled={esEditable}
            menuPosition="fixed"
          />
        )}
      />

      <input type={"hidden"} name={`asesoria.${index}.${item.nombre}`} value={getDataValues(data.opcion_multiple)} ref={reg} />

    </Form.Group>
  );

}

export default Multiple;
