import React, { useState } from 'react'

import {GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow, onZoomChanged} from 'react-google-maps'

import EditModal from '../../../components/modales/EditModal';

import '../styles/Heatmap.scss'

const HeatMap = (props) => {

  const { 
    setPunto,
    descripcion,
    setDescripcion
  } = props

  const [ openInfo, setOpenInfo ] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState({
    lat: '',
    lng: ''
  })

  const onSelect = () => {
    setOpenInfo(!openInfo);
  }

  const closeModal =  () => {

    setShowDescriptionModal(false);
  }

  const openDialogMap = (e) => {
    setSelectedPoints({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    })
    setShowDescriptionModal(true);
  }

  const getPoint = () => {
    if (descripcion !== '') {
      setPunto({
        lat: selectedPoints.lat,
        lng: selectedPoints.lng
      });
      setShowDescriptionModal(false);
    } else {
      setSelectedPoints({
        lat: '',
        lng: ''
      })
    }
  }

  const handleData = (e) => {
    setDescripcion(e.target.value);
  }

  const defaultMapOptions = {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  const handleZoomChanged = (e) => {

    const zm = GoogleMap.bind()

    console.log(zm) 


  }

  return(
    <>
        <GoogleMap 
          onZoomChanged={handleZoomChanged}
          defaultZoom={props.zoom ? props.zoom : 5} 
          zoom={props.zoom ? props.zoom : 2} 
          defaultCenter={{ 
            lng: props.marker.lng !== '' ? parseFloat(props.marker.lng) : -74.0705,
            lat: props.marker.lat !== '' ? parseFloat(props.marker.lat) : 4.612639
          }}
          center={{ 
            lng: props.marker.lng !== '' ? parseFloat(props.marker.lng) : -74.0705,
            lat: props.marker.lat !== '' ? parseFloat(props.marker.lat) : 4.612639
          }}
          onClick={(e) => openDialogMap(e)}
          defaultOptions={defaultMapOptions}
        >
          { props.marker.lng !== '' && props.marker.lat !== '' && 
            (
              <Marker 
                position={{
                  lng: parseFloat(props.marker.lng),
                  lat: parseFloat(props.marker.lat)
                }}  
                onClick={() => onSelect()}
              >
                { openInfo && 
                  <InfoWindow onCloseClick={() =>{setOpenInfo(!openInfo)}}>
                    <div className='contenedorInfoWindow inv'>
                        <h4 className='titluloInfo'>Dirección</h4>
                        <div className='textInfo'>
                          <p>{ descripcion }</p>
                        </div>
                    </div>
                  </InfoWindow> 
                }
              </Marker>
            )
          }
        </GoogleMap>
      <EditModal
        typeModal="obtenerCoordenadasDes" 
        isOpen={showDescriptionModal}
        setIsOpen={closeModal}
        handleData={handleData}
        descripcion={descripcion}
        setConfirmarUbicacion={getPoint}
        // setFormUbicacion={getCoordForm}
        // invalidDes={invalidDes}
        // lat={lat}
        // lng={lng}
        // isForm={isForm}
      />
    </>
  );
}

export default withScriptjs(
  withGoogleMap(
    HeatMap
  )
)

