import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import { useTranslation } from 'react-i18next';
// import {AMCHART} from '../../helpers/amchartLicense'

am4core.useTheme(am4themes_kelly);
am4core.useTheme(am4themes_animated);



export default function Dona2(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  // am4core.addLicense(AMCHART);

  const getImagenGrafica = () => {

    //FUNCION PARA EXPORTAR LA IMAGEN Y OBTENERLA
    // let imgData = chart.exporting.getImage("png")


    //FUNCION PARA EXPORTAR IMAGEN Y DESCARGARLA
    // chart.exporting.export("png");

  }


  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.PieChart3D);


    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = props.data;

    chart.radius = am4core.percent(70);
    chart.innerRadius = am4core.percent(30);
    chart.startAngle = 180;
    chart.endAngle = 360;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "country";

    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = true;
    series.slices.template.propertyFields.fill = "fill";
    series.slices.template.inert = false;
    series.labels.template.text = "[bold]{country}: {value.percent.formatNumber('.')}%";
    series.slices.template.tooltipText = "{country}: {value}";

    series.alignLabels = false;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'right'
    chart.legend.valueLabels.template.text = "{percent}";
    // chart.legend.labels.template.text = "{category}";

    chart.legend.labels.template.text = "{country}";

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": t('botones.imagen') },
        // { "type": "csv", "label": "Tabla" },
        { "type": "print", "label": t('botones.imprimir') }
      ]
    }];

    getImagenGrafica()
  }, [])


  return (
    <Card className="shadow cardGraficaGeneral cardGraficaAnalistas">
      {/* <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br />
      <Card.Body className='bodyGraficasGeneral'>
        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> {props.title}
        <br/>
        {props.parametrizado
          ? props.periodo
            ?
            <>
              {props.parametrizado} - {props.periodo }
            </>
            :
            <>
              {props.parametrizado}
            </>


          : null
        }
        </h4>
        <br/>
        <br/>
        <div id={props.id} style={{ width: '85%', minHeight: '240px', margin: '0 auto' }}></div>
        <br/>
        <h4 className='pagesTitles'>
        Total: {props?.total}
        </h4>
      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
