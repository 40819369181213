import { Card, Col, Row } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ButtonGroup } from '@material-ui/core';

// import { InformacionGeneral } from '../../components/gerenciaGeneral/inteligencia/Pestañas/InformacionGeneral';
import { BeneficiariosPestaña } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/BeneficiariosPestaña';
import { EstudioBasicoPestaña } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/EstudioBasicoPestaña';
import { EstudioConfiabilidadPestaña } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/EstudioConfiabilidadPestaña';
import { GestorDocumentalPestaña } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/GestorDocumentalPestaña';
import { InformacionGeneralPestaña } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/InformacionGeneralPestaña';
import { SolicitudObservacion } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/SolicitudObservacion';
import { AprobarObservacion } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/AprobarObservacion';
import { AprobarErrorRadicacion } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/AprobarErrorRadicacion';
import { AprobarSolicitudDesbloqueo } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/AprobarSolicitudDesbloqueo';
import { Aprobaciones } from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/Aprobaciones';
import DocumentosCosinte from '../../components/gerenciaGeneral/inteligencia/PestañasSolicitudCreada/DocumentosCosinte';
import GestionAuditorias from '../../components/gestionAuditorias/Dashboard/GestionAuditorias';
// AprobarSolicitudDesbloqueo
import NotificationModal from '../../components/modales/NotificationModal'

import GetAppIcon from '@material-ui/icons/GetApp';
import { HelpSVG } from '../../components/icons/HelpSVG';
import SuccessIcon from '../../helpers/SuccessIcon/SuccessIcon'
import { ErrorSVG } from '../../components/icons/ErrorSVG'
import { WarningModalSVG } from '../../components/icons/WarningModalSVG'
// import { hashListo } from '../../helpers/hash';
import PopUpModal from '../../helpers/PopUpModal';
import { validarEntorno } from '../../helpers/validarHost';
import { useTranslation } from 'react-i18next';
import SolicitarDinamico from '../../components/gerenciaGeneral/inteligencia/Pestañas/SolicitarDinamico';
import FormularioDinamico from '../../components/GestionadorDinamico/FormularioDinamico';




export const SolicitudRealizada = ({ props }) => {
  const { t } = useTranslation();
  let { id } = useParams();
  const HASH = window.location.hash;

  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})
  const [indexStatus, setIndexStatus] = useState('');
  const [itemStatus, setItemStatus] = useState({})
  const [modalNotificacion, setModalNotificacion] = useState({
    show: false,
    mensaje: "",
    tipo: ""
  });




  useEffect(() => {
    const dataEnviar = {
      idSolicitud: id,
      hash: window.location.hash,
    }
    validarEntorno('JSON/gestionar_solicitud_cliente_json.html', 'POST', dataEnviar).then(res => {
      if (res.ok) {
        res.json()
          .then(dataRespuesta => {
            setData(dataRespuesta)
            // console.log(data?.informacion_solicitud?.estado_cos?.valor)
            setPestaña(dataRespuesta.data[0].pestana)
          })
      }
    })
  }, [id])

  const [pestaña, setPestaña] = useState('')

  const handleOpenModalNotification = (item, index, msg, type) => {
    setModalNotificacion({
      ...modalNotificacion,
      show: true,
      mensaje: msg,
      tipo: type
    });

    setIndexStatus(index);
    setItemStatus(item);
  }

  const handleCloseModalNotification = () => {
    setModalNotificacion({
      ...modalNotificacion,
      show: false,
      mensaje: "",
      tipo: ""
    });
    setIndexStatus('');
    setItemStatus({});
  }

  const handleEstadoSolicitud = async () => {

    const dataReq = {
      id_solicitud: itemStatus.id,
      hash: HASH,
      id_registro: id,

    }

    console.log(dataReq);

    const req = await validarEntorno('JSON/GestionDinamica/respuesta_cambio_estado_gestion.php', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();
      if (res.estado === "OK") {
        //     const opcRes = [...opcionesRegistro];
        //     const itemOpc = {...opcRes[indexStatus]};
        //     itemOpc.deshabilitado = !itemOpc.deshabilitado;
        //     opcRes[indexStatus] = itemOpc;
        //     setOpcionesRegistro(opcRes);
        //     setEstadoSolicitud(itemStatus.valor);
        setItemStatus({})
        handleOpenModalNotification({}, {}, res.mensaje, 'eliminacion_confirmada');
      } else {
        handleOpenModalNotification(null, null, res.mensaje, 'falloPeticionesGestion');
      }
    } else {
      handleOpenModalNotification(null, null, "Ha ocurrido un error, intente nuevamente", 'falloPeticionesGestion');
    }
  }


  const informacionSolicitud = data.informacion_solicitud



  return (
    <Fragment>
      {
        data.data
        &&
        <Fragment>
          <div className="d-flex" style={{ flexDirection: 'column' }}>
            <div className='titleBox'>
              {/* <h3 className="mt-5" style={{ color: '#213265', fontWeight: '800' }}>Solicitudes de estudio____</h3> */}
              <div className='titleContainer'>
                <h5 className='pagesTitles'>{data.titulo} - {data.tipoSolicitud}</h5>

              </div>
              <div onClick={() => setOpen(true)}>
                <HelpSVG />
              </div>
            </div>

            <br />
            {data.informacion_solicitud &&
              <>
                <Card className='card__box__surface cardGeneral'>
                  <Card.Body>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
                        {data?.informacion_solicitud?.conclusion !== {}
                          ?
                          <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'space-around' }}>
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'space-around',
                                justifyContent: 'space-around'

                              }}

                            >
                              {informacionSolicitud.conclusion.icono === 'exito'
                                ? <div>
                                  <SuccessIcon />
                                </div>
                                : informacionSolicitud.conclusion.icono === 'error'
                                  ? <div>
                                    <ErrorSVG />
                                  </div>
                                  : informacionSolicitud.conclusion.icono === 'advertencia'
                                    ? <div>
                                      <WarningModalSVG />
                                    </div>
                                    : informacionSolicitud.conclusion.icono === 'proceso'
                                      ? <div>
                                        <img alt='icono de carga' style={{ width: '95px' }} src="https://www.ciacosinte.site/cia2/static/media/rombo.svg" />
                                        {/* <a href="https://icons8.com/icon/TXtRzGNlGEgi/rhombus-loader">Rhombus Loader icon by Icons8</a> */}
                                      </div>
                                      : informacionSolicitud.conclusion.icono === '' &&
                                      <div>
                                        <WarningModalSVG />

                                      </div>
                              }
                              {informacionSolicitud.conclusion.valor !== ''
                                ?

                                <h3>{informacionSolicitud.conclusion.valor}</h3>
                                :
                                <h3>Verificar estado</h3>

                              }
                            </div>
                            <br />
                            {informacionSolicitud.generarPDF.visualizar === true &&
                              <div className='buttonGenerarPDF'>
                                <a href={informacionSolicitud.generarPDF.link} rel='noreferrer' target='_blank'>
                                  <button className='confirmButton'>
                                    {t('botones.generar_pdf')}
                                    <GetAppIcon />
                                  </button>
                                </a>
                              </div>
                            }

                          </div>

                          :
                          <div>
                            <WarningModalSVG />
                            <h3>Verificar estado </h3>
                          </div>
                        }
                      </Col>
                      <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
                        {informacionSolicitud.estado.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p className='labelInfo'>{t('formulario.label.estado')}:</p>
                            <p className='labelInfoContent'>{informacionSolicitud.estado.valor}</p>
                          </div>
                        }
                        {informacionSolicitud.fecha_solicitud.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p className='labelInfo'>{informacionSolicitud.fecha_solicitud.nombre}</p>
                            <p className='labelInfoContent'>{informacionSolicitud.fecha_solicitud.valor}</p>
                          </div>

                        }

                        <div className='infoBasicaEditarRegistro'>
                          <p>{informacionSolicitud.perfil.tipo}</p>
                          <p className='labelInfoContent'>
                            {informacionSolicitud.perfil.nombre}
                          </p>
                        </div>
                        <div className='infoBasicaEditarRegistro'>
                          <p className='labelInfo'>{t('formulario.label.tipo_solicitud')}</p>
                          <p className='labelInfoContent'>{data.tipoSolicitud}</p>
                        </div>
                        {informacionSolicitud.fecha_finalizacion.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p className='labelInfo'>{t('formulario.label.fecha_finalizacion')}</p>
                            <p className='labelInfoContent'>{informacionSolicitud.fecha_finalizacion.valor}</p>
                          </div>
                        }

                      </Col>
                      <Col xs={6} lg={4} md={3} className='listPopUp mt-3'>
                        {informacionSolicitud.identificador.visualizar === true
                          ?
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.identificador.tipo}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.identificador.valor}
                            </p>
                          </div>
                          : null
                        }
                        <div className='infoBasicaEditarRegistro'>
                          <p>ID</p>
                          <p className='labelInfoContent'>
                            {id}
                          </p>
                        </div>
                        {informacionSolicitud.estado_cos.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{t('formulario.label.estado_cos')}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.estado_cos.valor}
                            </p>
                          </div>
                        }
                        {informacionSolicitud.fecha_cosinte.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.fecha_cosinte.nombre}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.fecha_cosinte.valor}
                            </p>
                          </div>
                        }
                        {informacionSolicitud.fecha_limite.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{t('formulario.label.fecha_limite')}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.fecha_limite.valor}
                            </p>
                          </div>
                        }
                      </Col>
                      {/* <Col className='col-12 buttonGenerarPDF'>
                                        <a href={`https://ciacosinte.site/crm/upload/informe_investigacion_PDF.html?id=${idRegistro}`} target='_blank'>
                                            <button className='confirmButton'>
                                                Generar PDF
                                                <GetAppIcon/>
                                            </button>

                                        </a>
                                    </Col> */}
                    </Row>
                    <br />
                    <Row id="container-botones-informe">
                      <Col>
                        <ButtonGroup aria-label="text group">
                          {data.botones.length > 0 && (data.botones.map((item, index) => (
                            <button
                              className='btnAccionesInfoGeneral'
                              key={index}
                              style={{ opacity: !item.disabled ? 1 : 0.25, textTransform: 'none' }}
                              disabled={item.disabled}
                              // className="button-group-informe"
                              onClick={() => { handleOpenModalNotification(item, index, `${item.mensaje ? item.mensaje : 'Deseas continuar con la solicitud?'}`, 'cambio_estado_gestion') }}
                            >

                              {item.texto}
                            </button>
                          )))}
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <br />
                <br />
              </>
            }
            <br />


            <div style={{ display: 'flex', textAlign: 'center', marginTop: "24px" }}>
              {
                data.data.map((ele, index) => {

                  return <div
                    key={index}
                    className="d-flex pestañasLi"
                    id='pestanasGestorSolicitud'
                    style={{ background: pestaña !== ele.pestana && 'rgba(203, 215, 230, 0.5)', border: pestaña !== ele.pestana && 'none' }}
                    onClick={() => setPestaña(ele.pestana)}
                  >
                    <p style={{ width: 'auto', fontSize: '1rem' }} className=" p-0 m-0">
                      {ele.pestana === 'Estudio básico'
                        ? 'Información general'
                        : ele.pestana === 'Estudio confiabilidad'
                          ? 'Información general'
                          : ele.pestana === 'Información general investigacion'
                            ? 'Información general'
                            : ele.pestana === 'Gestor documental'
                              ? 'Archivos adjuntos'
                              : ele.pestana === 'docs_cosinte'
                                ? 'Gestor documental Cosinte'
                                : ele.pestana === 'Beneficiarios'
                                  ? 'Beneficiarios'
                                  : ele.pestana === 'Solicitud observacion-objecion'
                                    ? 'Solicitud de observación u objeción'
                                    : ele.pestana === 'aprobacion_observacion_objecion'
                                      ? 'Aprobar observación u objeción'
                                      : ele.pestana === 'aprobacion_error_radicacion'
                                        ? 'Aprobar error de radicación'
                                        : ele.pestana === 'aprobaciones'
                                          ? 'Aprobaciones'
                                          : ele.pestana === 'aprobar_solicitud_desbloqueo_investigacion'
                                            ? 'Aprobar solicitud de desbloqueo investigación'
                                            : ele.pestana === 'gestion_de_riesgos' ?
                                              'Gestión de riesgos'
                                              : ele.pestana === 'aprobar_solicitud_desbloqueo_investigacion'
                                                ? 'Aprobar solicitud de desbloqueo investigación'
                                                : ele.pestana === 'dinamico' ?
                                                  ele?.titulo : ele.pestana
                      }
                    </p>
                    <b style={{
                      width: '25px',
                      fontSize: '30px',
                      marginTop: '-20px',
                      fontWeight: '900',
                      color: index === 0
                        ? '#00A20B'
                        : index === 1
                          ? '#00A20B'
                          : index === 2
                            ? '#00A20B'
                            : index === 3
                              ? '#00A20B'
                              : index === 4
                                ? '#00A20B'
                                : index === 5
                                  ? '#00A20B'
                                  : index === 6
                                    ? '#00A20B'
                                    : index === 7
                                      ? '#00A20B'
                                      : index === 8
                                        ? '#00A20B'
                                        : 'red'
                    }}>.</b>

                  </div>
                })
              }
            </div>
            {data.data.map((ele, index) => {
              if (ele.pestana === pestaña) {
                return pestaña === 'Estudio básico'
                  ? <EstudioBasicoPestaña ele={ele} />
                  : pestaña === 'Estudio confiabilidad'
                    ? <EstudioConfiabilidadPestaña ele={ele} />
                    : pestaña === 'Beneficiarios'
                      ? <BeneficiariosPestaña ele={ele} />
                      : pestaña === 'Gestor documental'
                        ? <GestorDocumentalPestaña ele={ele} />
                        : pestaña === 'docs_cosinte'
                          ? <DocumentosCosinte ele={ele} />
                          : pestaña === 'Información general investigacion'
                            ? <InformacionGeneralPestaña ele={ele} />
                            : pestaña === 'Solicitud observacion-objecion'
                              ? <SolicitudObservacion ele={ele} />
                              : pestaña === 'aprobacion_observacion_objecion'
                                ? <AprobarObservacion ele={ele} />
                                : pestaña === 'aprobacion_error_radicacion'
                                  ? <AprobarErrorRadicacion ele={ele} />
                                  : pestaña === 'aprobaciones'
                                    ? <Aprobaciones ele={ele} />
                                    : pestaña === 'aprobar_solicitud_desbloqueo_investigacion'
                                      ? <AprobarSolicitudDesbloqueo ele={ele} />
                                      : pestaña === 'gestion_de_riesgos'
                                        ? <GestionAuditorias ele={ele} />
                                        : pestaña === 'dinamico'
                                          ? <Card className='card__box__surface p-4 card__plain__top__left'><FormularioDinamico idSegundoNivel={ele?.id_segundo_nivel} titulo={ele?.titulo} seccionPt={ele?.seccion}/> </Card>
                                          : ""
              }
              return <Fragment key={index}></Fragment>
            })
            }
          </div>
        </Fragment>
      }
      <PopUpModal titulo={data.titulo} texto={data.texto} open={open} setOpen={setOpen} />
      <NotificationModal
        isOpen={modalNotificacion.show}
        setIsOpen={handleCloseModalNotification}
        typeModal={modalNotificacion.tipo}
        message={modalNotificacion.mensaje}
        handleEstadoSolicitud={handleEstadoSolicitud}
      />
    </Fragment>
  )
}
