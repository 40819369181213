import React, { useState } from 'react';
// import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ReCAPTCHA from "react-google-recaptcha";
import '../styles/SignIn.scss';
import { Alert } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Modal, Button } from 'react-bootstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


// import {userService, authenticationService} from '@/services'

// import {Link} from 'react-router-dom'

import { inicioSesion } from '../../reducers/auth.reducer'
import PopUpModal from '../../helpers/PopUpModal';
import { validarEntorno } from '../../helpers/validarHost';

import NotificationModal from '../modales/NotificationModal'
import { CambioContraseña } from '../../pages/Navbar/Perfil/CambioContraseña';




export const FormLogin = ({ setForm }) => {

  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [tipoModal, setTipoModal] = useState('')
  const [tipoMensaje, setTipoMensaje] = useState('')
  const [mensajeModal, setMensajeModal] = useState('')
  const [open, setOpen] = useState(false)
  const { register, handleSubmit, errors } = useForm();
  const [captchaValidate, setCaptchaValidate] = useState('')
  const [validateCaptcha, setValidateCaptcha] = useState(false)
  const [actualizarContrasena, setActualizarContrasena] = useState(false)
  const [contrasenaVisible, setContrasenaVisible] = useState(false)


  const { host } = window.location;



  //console.log(host);

  function onChange(value) {
    setCaptchaValidate(value)
  }

  const onSubmit = async (data) => {

    // listo para produccion
    if (host === "www.cosinte.co" || host === "cosinte.co" || host === "www.ciacosinte.site" || host === "ciacosinte.site" || host === "localhost:3000") {
      setValidateCaptcha(false)


     
        data.captcha = captchaValidate

        const respuesta = await validarEntorno('JSON/ejemploRespuestaSuccess_json.html', 'POST', data)

        if (respuesta.ok) {
          const respuestaLoginData = await respuesta.json()
          console.log(respuestaLoginData)
          const { logged } = respuestaLoginData
          const { contrasena_expirada } = respuestaLoginData

          //Si la contraseña esta expirada se abre el modal para cambiarla
          if (contrasena_expirada) {
            setActualizarContrasena(true)
            return
          }

          if (!logged) {
            setTipoModal('loginUserError')
            setMensajeModal(respuestaLoginData.msg)
            setTipoMensaje(respuestaLoginData.tipo)
            setModal(true)

          } else {

            localStorage.setItem('login', JSON.stringify(respuestaLoginData))
            dispatch(inicioSesion(respuestaLoginData))

            if (respuestaLoginData?.link) {
              window.open(respuestaLoginData?.link, "_self")
            }

          }
    
      }
    } else {
      data.captcha = validateCaptcha
      const respuesta = await validarEntorno('JSON/ejemploRespuestaSuccess_json.html', 'POST', data)
      if (respuesta.ok) {
        const respuestaLoginData = await respuesta.json()
        const { logged } = respuestaLoginData
        if (!logged) {
          setTipoModal('loginUserError')
          setMensajeModal(respuestaLoginData.msg)
          setModal(true)

        } else {
          localStorage.setItem('login', JSON.stringify(respuestaLoginData))
          dispatch(inicioSesion(respuestaLoginData))

          if (respuestaLoginData?.link) {
            window.open(respuestaLoginData?.link, "_self")
          }

        }
      }
    }
  }

  /**
   * Funcion para cerrar el modal de cambio de contraseña
   */

  const handleCloseModalContrasena = () => {
    setActualizarContrasena(false)
    //Limpiar los campos del formulario
    const inputs = document.querySelectorAll('input')
    inputs.forEach(input => {
      input.value = ''
    })

  }

  /**
   * Funcion para mostrar u ocultar la contraseña en el input
   */

  const handleTogglePass = () => {
    const input = document.querySelector(`input[name="password"]`)
    if (input.type === 'password') {
      input.type = 'text'
      setContrasenaVisible(true)
    } else {
      input.type = 'password'
      setContrasenaVisible(false)
    }
  }

  return (
    <div id='signInContainer'>
      <span><b> Inicio de sesión </b></span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Usuario
          <input type='text' name='username' ref={register({
            required: { value: true, message: 'El usuario es requerido' },
            minLength: { value: 4, message: 'El usuario debe tener mínimo 4 caracteres' },

          })} id='username' >
          </input>
        </label>
        {errors?.username &&
          <Alert className="mt-2" variant="danger">{errors?.username?.message}</Alert>
        }

        <label >
          Contraseña
          <div className='password__container__login'>
            <input type='password' ref={register({
              required: { value: true, message: 'La contraseña es requerida' },
              minLength: { value: 5, message: 'El campo debe tener mas de 5 caracteres ' }
            })} name="password" id='password'></input>
            <div className='button_show_hide' onClick={() => { handleTogglePass() }}>{contrasenaVisible ? <VisibilityOffIcon></VisibilityOffIcon> : <VisibilityIcon></VisibilityIcon>}</div>
          </div>
        </label>
        {errors?.password && <Alert className="mt-2 alert" variant="danger">{errors?.password?.message}</Alert>}
        <br />
        <ReCAPTCHA
          className='g-recaptcha'
          sitekey="6LfxJ78mAAAAAPcy6fqRRgmoZUBXrKDxgw6wRhpm"
          onChange={onChange}
        />

        <div id='formOptions'>
          <p id='helpText' style={{ cursor: 'pointer' }} onClick={() => setForm(false)}>Olvidé la contraseña</p>
          <button id='loginButton' type="submit" >
            Ingresar
          </button>
        </div>
      </form>
      <div id='help' onClick={() => setOpen(true)}>
        <p id='helpText'>
          Ayuda
        </p>
        <HelpOutlineIcon />
      </div>

      <div>
        <PopUpModal
          open={open}
          setOpen={setOpen}
          titulo='CIA 2.0 - Central de Información Actualizada'
          texto='Bienvenido a la plataforma CIA 2.0, ingresa con tu usuario y contraseña.'
        />

      </div>
      <NotificationModal typeModal={tipoModal} setIsOpen={setModal} isOpen={modal} mensaje={mensajeModal} tipoMensaje={tipoMensaje} />
      <Modal show={actualizarContrasena} size='xl' style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} onHide={() => setActualizarContrasena(false)}>
        <Modal.Body>
          <div className='header__contrasena__expirada'>
            <h1 className='contrasena__expirada'>Su contraseña ha expirado</h1>
            <p className='contrasena__expirada__detalle'>Para garantizar la seguridad de su información, por favor diligencie el siguiente formulario siguiendo las indicaciones</p>
          </div>
          <CambioContraseña onClose={handleCloseModalContrasena}></CambioContraseña>
        </Modal.Body>
      </Modal>
    </div>
  )
}
