import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
// import Hidden from '@material-ui/core/Hidden';
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";

import { DesplegarMenuSVG } from "../components/icons/DesplegarMenuSVG";

import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import "./styles/Dashboard.scss";

import Navbar from "../components/Navbar";
import MenuGerencia from "../components/menu/MenuGerencia";
import MenuPresidencia from "../components/menu/MenuPresidencia";
// import MenuDocs from '../components/menu/MenuDocs'
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  menuButton: {
    marginRight: 30,
  },
  hide: {
    display: "none",
  },

  drawer: {
    // [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "transparent",
    whiteSpace: "nowrap",
    borderRight: "0",
    // zIndex: 180
    // },
  },
  drawerOpen: {
    width: drawerWidth,
    borderRight: "0",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "transparent",
    overflowY: "auto",

    scrollbarWidth: "thin",
    scrollbarColor: "gray-500 transparent",

    "&::-moz-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-moz-scrollbar-thumb": {
      backgroundColor: "gray-500",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  // [theme.breakpoints.up('md')]: {
  //   width: '100%',
  //   marginLeft: drawerWidth,
  //   height: 70,
  //   boxShadow: 0,
  // },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  // menuButton: {
  //   marginRight: theme.spacing(2),
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerClose: {
    borderRight: "0",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "visible",
    width: "80px",
    backgroundColor: "transparent",
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 70,
    marginBottom: 70,
    // padding: '30 0',
    // backgroundColor: 'transparent',
    boxShadow: "none",
    border: "none",
  },
  content: {
    // width: 100,
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: `{#ededed}`,
  },
}));

function ResponsiveDrawer(props) {
  // const { window } = props;
  const { t } = useTranslation();
  const { menu } = useSelector((state) => state.menu);
  const classes = useStyles();
  const theme = useTheme();
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  // const [openAccordion, setOpenAccordion] = React.useState();

  // const handleDrawerToggle = () => {
  //   setOpen(!open);
  // };

  const closeAccordions = () => {
    let element = document.getElementsByClassName("collapse show");

    for (var i = 0; i < element.length; i++) {
      element[i]?.classList?.remove("show");
    }
    return element;
  };

  function handleDrawerOpen() {
    setOpen(true);
  }

  const handleDrawerClose = async () => {
    setOpen(false);
    await closeAccordions();
  };

  // const contadorVistas = window.localStorage.setItem('contador_vistas_media', 'xxxxxx')

  // useEffect(() => {
  // const vistasObtenidas = Number(window.localStorage.getItem('contador_vistas_media'))

  // const aumentarVistas = vistasObtenidas + 1

  // window.localStorage.setItem('contador_vistas_media', 'aumentarVistas')

  // }, [])

  useEffect(() => {
    if (!menu?.presidencia?.activo && !menu?.gerenciaGeneral?.activo) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [menu]);

  return (
    <div id="dbContainer" className={classes.root}>
      <CssBaseline />
      <AppBar
        id="navbar"
        // position='fixed'
        // className='MuiAppBar-colorTransparent'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {(menu?.presidencia?.activo || menu?.gerenciaGeneral?.activo) && (
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              className={
                // classes.menuButton
                clsx(classes.menuButton, {
                  [classes.hide]: open,
                })
              }
            >
              <MenuIcon />
            </IconButton>
          )}
          <Navbar
            enlace={menu?.correo}
            permisos={menu?.permisos}
            idiomas={menu?.idioma}
          />
        </Toolbar>
      </AppBar>

      <nav
        id="menu"
        aria-label="menus"
        //onMouseEnter={handleDrawerOpen}
        // onMouseLeave={handleDrawerClose}
      >
        {/* <Hidden mdUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className='menuScroll'
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <MenuPresidencia />
            <MenuGerencia />
          </Drawer>
        </Hidden> */}
        <Drawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
            // paper: classes.drawerPaper,
          }}
          variant="permanent"
          // backgroundcolor= '#e0e0e0'
          // open
          // onClick={() => setOpen(!open)}
        >
          {(menu?.presidencia?.activo || menu?.gerenciaGeneral?.activo) && (
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}

                <DesplegarMenuSVG onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </DesplegarMenuSVG>
              </IconButton>
            </div>
          )}
          {menu?.presidencia?.activo && (
            <MenuPresidencia menuIsOpen={open} onOpenMenu={handleDrawerOpen} />
          )}
          {menu?.gerenciaGeneral?.activo && (
            <MenuGerencia menuIsOpen={open} onOpenMenu={handleDrawerOpen} />
          )}
          {/* {
            menu?.gerenciaGeneral?.activo
            &&
            <MenuDocs menuIsOpen={open} onOpenMenu={handleDrawerOpen} />

          } */}
        </Drawer>
      </nav>

      <main id="db" className={classes.content} >
        {props.children}
        <p id="footer">
          CIA © {moment().format("YYYY")} - {t("dashboard.por")} COSINTE LTDA
          SAS
        </p>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
