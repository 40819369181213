import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Row, Table } from 'react-bootstrap';
import Heatmap from '../../../../../helpers/Mapas/Descripcion/Heatmap';
import Loader from '../../../../icons/Loader';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { credenciales } from '../../../../../helpers/credentials.google';
import { validarEntorno } from '../../../../../helpers/validarHost';
import HeatmapActivities from '../../../../../helpers/Mapas/Descripcion/HeatmapActivities';
import NotificationModal from '../../../../modales/NotificationModal';
import OpcionesCiudades from '../../../../../helpers/OpcionesCiudades';
import { municipios } from '../../../../../helpers/municipios';

import ModalPanorama from '../../../../modales/ModalPanorama';
import ModalActividades from '../../../../modales/ModalActividades';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';

import './styles/FormFactores.scss';
import { NightsStay } from '@material-ui/icons';
import EditModal from '../../../../modales/EditModal';
import { useTranslation } from 'react-i18next';

const FormFactores = ({ factor, idPanorama, permisos, actividades, idSubseccion, dataSelect, dataSelect2, dataZonasSelect }) => {
  const { t } = useTranslation();
  // console.log(dataSelect)
  // console.log(typeof(idSubseccion))
  // console.log(dataSelect2)
  // console.log(dataZonasSelect)
  // console.log(permisos)
  const [regToDel, setRegToDel] = useState({})
  const [actToDel, setActToDel] = useState({})
  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })
  const [switchSeccion, setSwitchSeccion] = useState(permisos?.registros ? 'registros' : 'actividades')
  const [addSolucion, setAddSolucion] = useState(false);
  const [addUbicacion, setAddUbicacion] = useState(false);
  const [addActividad, setAddActividad] = useState(false);
  const [typeModalNotification, setTypeModalNotification] = useState('');
  const [addUbicacionActividad, setAddUbicacionActividad] = useState(false);
  const [inputList, setInputList] = useState([{ descripcion_principal: "", fecha: "", zona: "", municipio: "", porcentaje: "", tipo: "", resguardo: "", campo_2: "", sector_industrial: "", departamento: "", comunidad: "", programa: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
  const [activitiesList, setActivitiesList] = useState([{ resumen: "", descripcion_principal_act: "", addUbicacionAct: false, fecha_act: "", campo_act: "", ubicaciones_act: [{ setOpenMdlAct: false, descripcion: "", tipo_mapa: "", coords_act: [] }] }]);
  const [stateInforme, setStateInforme] = useState({});
  const [tipoMapa, setTipoMapa] = useState('');
  const [tipoMapaActividad, setTipoMapaActividad] = useState('');
  const [isForm, setIsForm] = useState(false);
  const [coord, setCoord] = useState({
    lat: 0,
    lng: 0
  });
  const [coordAct, setCoordAct] = useState({
    lat: 0,
    lng: 0
  });
  const [indexEdit, setIndexEdit] = useState('');
  const [coordEdit, setCoordEdit] = useState({
    lat: '',
    lng: ''
  });

  const [invalidLat, setInvalidLat] = useState(false);
  const [invalidLng, setInvalidLng] = useState(false);
  const [indicador, setIndicador] = useState('');
  const [formNumber, setFormNumber] = useState(0);
  const [formNumberAct, setFormNumberAct] = useState(0);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showDescriptionModalAct, setShowDescriptionModalAct] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [latAct, setLatAct] = useState('');
  const [lngAct, setLngAct] = useState('');
  const [seccionSelectValues, setSeccionSelectValues] = useState([]);
  const [tipoUbicacion, setTipoUbicacion] = useState('');
  const [showCreateModalNotification, setShowCreateModalNotification] = useState(false);
  const [showErrorModalNotification, setShowErrorModalNotification] = useState(false);
  const [showEditDeleteModalNotification, setShowEditDeleteModalNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState('');
  const [messageShowEditDeleteNotification, setMessageEditDeleteNotification] = useState('');
  const [typeNotification, setTypeNotification] = useState('');
  const [itemToErase, setItemToErase] = useState('');
  const [dataFil, setDataFil] = useState([]);
  const [indexDelete, setIndexDelete] = useState('');
  const [typeOperation, setTypeOperation] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [seccionPoligono, setSeccionPoligono] = useState([]);
  const [descriptionFactorModal, setDescriptionFactorModal] = useState({
    show: false,
    tipo: 'modalEdicionFactor'
  });

  const { id } = useParams()


  useEffect(() => {
    // setSwitchSeccion(permisos?.registros === true ? 'registros' : 'actividades')
    cleanComponent();
    getRegistrosSeccion();

    // getSeccionSelectValues();
  }, [idSubseccion]);

  const handleCloseCreateModalNotification = () => setShowCreateModalNotification(false);
  const handleCloseErrorModalNotification = () => setShowErrorModalNotification(false);

  const handleCloseModalEditDeleteNotification = () => {
    setShowEditDeleteModalNotification(false);
    setMessageEditDeleteNotification('');
    setTypeNotification('');
    setItemToErase('');
    setTypeOperation('')
  }

  const handleOpenMessageModalDeleteEdit = (tipo, index, item) => {
    let message = '';
    if (tipo === 'registro') {
      message = t('modales.seguro_eliminar_registro');
      setRegToDel(item);
    } else if (tipo === 'actividad') {
      message = t('modales.seguro_eliminar_actividad');
      setActToDel(item);
    }
    setTypeOperation(tipo);
    setIndexDelete(index);
    setTypeNotification('confirmacion_eliminacion_ra');
    setMessageEditDeleteNotification(message)
    setShowEditDeleteModalNotification(true);
  }

  const [puntosCalor, setPuntosCalor] = useState([
    {
      "locacion": "cali",
      "tipo": "cultivo_ilicito",
      "coord": {
        "lat": 3.4334185,
        "lng": -76.4517656
      },
      "titulo": "Disturbios en Cali dejan un muerto y policías y manifestantes heridos",
      "descripcion": "Los disturbios se prolongaron hasta la noche y dejaron un hombre de 42 años muerto por arma de fuego, tres policías lesionados y varios manifestantes detenidos. El comandante de la Policía Metropolitana de Cali, brigadier general Juan Carlos León, afirmó que la persona que falleció llegó sin signos vitales a un centro médico."
    },
    {
      "locacion": "Pasto",
      "tipo": "cultivo_ilicito",
      "coord": {
        "lat": 1.2047533,
        "lng": -77.2699356
      }
    },
    {
      "locacion": "Medellin",
      "tipo": "atentado",
      "coord": {
        "lat": 6.2338284,
        "lng": -75.5758217
      }
    },
    {
      "locacion": "Barranquilla",
      "tipo": "atentado",
      "coord": {
        "lat": 11.0041324,
        "lng": -74.81128
      },
      "titulo": "Ejemplo titulo quilla",
      "descripcion": "Tronco e calor valecita  "

    }
  ]);

  const ubicacionBase = {
    lat: 1.206845,
    lng: -77.2756767
  };

  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;
  const hash = window.location.hash;

  //Inicio Funciones para conceptos

  const handleInputChange = (e, index, idx) => {

    const { name, value } = e.target;
    const list = [...inputList];
    const obj = { ...list[index] };
    obj[name] = value;
    list[index] = obj;
    setInputList(list);

    setStateInforme({
      ...stateInforme,
      [e.target.name]: e.target.value
    })

    // console.log(stateInforme)

    if (e.target.name === 'ubicacion') {
      filterDataArray(e.target.value);
    }

    if (e.target.name === 'departamento') {
      filterDataDepartamentos(e.target.value);
    }

    switch (e.target.value) {
      case 'puntos_calor':
        setTipoMapa('mapaCalor')
        break;
      case 'trazado':
        setTipoMapa('trazado')

        break;
      case 'puntos_simples':
        setTipoMapa('simples')
        break;
      default:
        break;
    }
  };

  const filterDataArray = (value) => {

    const df = dataSelect2.filter((item) => {
      return item.municipio === value
    });

    console.log(df);

    setDataFil(df);
  }

  const filterDataDepartamentos = (value) => {


    const df = dataSelect2.filter((item) => {
      return item.departamento === value
    });

    setDataFil(df);
  }

  const handleInputChangeUbi = (e, index, idx) => {
    const { value } = e.target;
    const list = [...inputList];
    console.log(list)
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    console.log(ubi, formNumber);
    ubi.tipo_mapa = value;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);

    console.log(inputList)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { descripcion_principal: "", municipio: "", zona: "", porcentaje: "", fecha: "", resguardo: "", sector_industrial: "", departamento: "", comunidad: "", programa: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClickUbi = (index) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    if (ubi.length >= 1) {
      const ubic = { setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] };
      ubi.push(ubic);
      item.ubicaciones = ubi;
      item.addUbicacion = true;
      list[index] = item;
      setInputList(list);
    }
  }

  const handleRemoveClickUbi = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    ubi.splice(idx, 1);
    item.ubicaciones = ubi;
    list[index] = item;
    setInputList(list);
  }

  const addRemoveUbi = (index) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    if (ubi.length > 1) {
      ubi[0].addUbicacion = !ubi[0].addUbicacion;
      item.ubicaciones = ubi;
      list[index] = item;
      setAddUbicacion(true);
      setInputList(list);
    } else {
      setAddUbicacion(!addUbicacion)
    }
    setTipoMapa('');
    setIndicador(0);
  }

  const getCoordinates = (lat, lng, index, descripcion) => {
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[formNumber] };
    const coords = [...ubi.coords];
    const crd = {
      lat: lat,
      lng: lng,
      descripcion
    };
    coords.push(crd);
    ubi.coords = coords;
    ubi.setOpenMdl = false;
    ubicaciones[formNumber] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
    setIsForm(false);
  }

  const handleLatLng = (e) => {
    const { name, value } = e.target;

    setCoord({
      ...coord,
      [name]: parseFloat(value)
    })
  }

  const showModal = (e, index, idx) => {

    e.preventDefault();

    console.log(index, idx);

    if ((!Number.isNaN(coord.lat) && coord.lat !== 0) && (!Number.isNaN(coord.lng) && coord.lng !== 0)) {
      setShowDescriptionModal(true);
      setIndicador(index);
      setIsForm(true);
      setFormNumber(idx);
    } else {
      if (Number.isNaN(coord.lat) || coord.lat === 0) {
        setInvalidLat(true);
      } else {
        setInvalidLat(false);
      }

      if (Number.isNaN(coord.lng) || coord.lng === 0) {
        setInvalidLng(true);
      } else {
        setInvalidLng(false);
      }
    }
  }

  const submitLatLng = (e, index, descripcion) => {
    e.preventDefault();
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[formNumber] };
    const coords = [...ubi.coords];
    const crd = {
      lat: coord.lat,
      lng: coord.lng,
      descripcion
    }
    coords.push(crd);
    ubi.coords = coords;
    ubi.setOpenMdl = false;
    ubicaciones[formNumber] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
    setIsForm(false);
    setInvalidLat(false);
    setInvalidLng(false);
    setCoord({
      lat: 0,
      lng: 0
    });
    const lat = document.getElementById("input-lat");
    const lng = document.getElementById("input-lng");
    lat.value = 0;
    lng.value = 0
  }

  // const getSeccionSelectValues = async () => {
  //     // console.log('Hola')
  // }

  const handleInputChangeOpenModal = (index, idx) => {
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
    setFormNumber(idx);
  }

  const handleInputChangeOpenModalForm = (e, index, idx) => {
    e.preventDefault();
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setFormNumber(idx);
    setInputList(list);
    setIsForm(true);
  }

  //Cierre Funciones para conceptos

  //Inicio Funciones para actividades

  const getActividadToDelete = (item) => {
    setActToDel(item)
  }

  const handleInputChangeAct = (e, index, idx) => {

    const { name, value } = e.target;
    const list = [...activitiesList];
    const obj = { ...list[index] };
    obj[name] = value;
    list[index] = obj;
    setActivitiesList(list);

    setStateInforme({
      ...stateInforme,
      [e.target.name]: e.target.value
    })

    console.log(stateInforme)

    switch (e.target.value) {
      case 'puntos_calor':
        setTipoMapaActividad('mapaCalor')
        break;
      case 'trazado':
        setTipoMapaActividad('trazado')
        break;
      case 'puntos_simples':
        setTipoMapaActividad('simples')
        break;
      default:
        break;
    }
  };

  const handleInputChangeUbiAct = (e, index, idx) => {
    const { value } = e.target;
    const list = [...activitiesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    ubi.tipo_mapa_act = value;
    ubicaciones[idx] = ubi;
    obj.ubicaciones_act = ubicaciones;
    list[index] = obj;
    setActivitiesList(list);
    console.log(activitiesList)
  }

  const handleAddClickAct = () => {
    setActivitiesList([...activitiesList, { descripcion_principal_act: "", addUbicacionAct: false, fecha_act: "", campo_act: "", ubicaciones_act: [{ setOpenMdlAct: false, descripcion: "", tipo_mapa: "", coords_act: [] }] }]);
  };

  const handleRemoveClickAct = index => {
    const list = [...activitiesList];
    list.splice(index, 1);
    setActivitiesList(list);
  };

  const handleAddClickUbiAct = (index) => {
    const list = [...activitiesList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones_act];
    if (ubi.length >= 1) {
      const ubic = { setOpenMdlAct: false, descripcion: "", tipo_mapa_act: "", coords_act: [] };
      ubi.push(ubic);
      item.ubicaciones_act = ubi;
      item.addUbicacion = true;
      list[index] = item;
      setActivitiesList(list);
    }
  }

  const handleRemoveClickUbiAct = (index, idx) => {
    const list = [...activitiesList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones_act];
    ubi.splice(idx, 1);
    item.ubicaciones_act = ubi;
    list[index] = item;
    setActivitiesList(list);
  }

  const addRemoveUbiAct = (index) => {
    const list = [...activitiesList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones_act];
    if (!addUbicacionActividad) {
      if (ubi.length >= 1) {
        ubi[0].addUbicacionAct = !ubi[0].addUbicacionAct;
        item.ubicaciones_act = ubi;
        list[index] = item;
        setActivitiesList(list);
      }
      setAddUbicacionActividad(true);
      setTipoMapaActividad('');
      setIndicador(0);
    } else {
      setAddUbicacionActividad(false);
    }
  }

  const getCoordinatesAct = (lat, lng, index, descripcion) => {
    if ((indicador === index)) {
      const list = [...activitiesList];
      const obj = { ...list[index] };
      const ubicaciones = [...obj.ubicaciones_act];
      const ubi = { ...ubicaciones[formNumberAct] };
      const coords = [...ubi.coords_act];
      const crd = {
        lat: lat,
        lng: lng,
        descripcion
      };
      coords.push(crd);
      ubi.coords_act = coords;
      ubi.setOpenMdlAct = false;
      ubicaciones[formNumberAct] = ubi;
      obj.ubicaciones_act = ubicaciones;
      list[index] = obj;
      setActivitiesList(list);
    } else {
      setIndicador(index);
    }
    setIsForm(false);
  }

  const handleLatLngAct = (e) => {
    const { name, value } = e.target;

    setCoordAct({
      ...coordAct,
      [name]: parseFloat(value)
    })
  }

  const showModalAct = (e, index, idx) => {

    e.preventDefault();

    if ((!Number.isNaN(coordAct.lat) && coordAct.lat !== 0) && (!Number.isNaN(coordAct.lng) && coordAct.lng !== 0)) {
      setShowDescriptionModalAct(true);
      setIndicador(index);
      setIsForm(true);
      setFormNumberAct(idx);
    } else {
      if (Number.isNaN(coordAct.lat) || coordAct.lat === 0) {
        setInvalidLat(true);
      } else {
        setInvalidLat(false);
      }

      if (Number.isNaN(coordAct.lng) || coordAct.lng === 0) {
        setInvalidLng(true);
      } else {
        setInvalidLng(false);
      }
    }
  }

  const submitLatLngAct = (e, index, descripcion) => {
    e.preventDefault();
    const list = [...activitiesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[formNumberAct] };
    const coords = [...ubi.coords_act];
    const crd = {
      lat: coordAct.lat,
      lng: coordAct.lng,
      descripcion
    }
    coords.push(crd);
    ubi.coords_act = coords;
    ubi.setOpenMdlAct = false;
    ubi.descripcion = descripcion;
    ubicaciones[formNumberAct] = ubi;
    obj.ubicaciones_act = ubicaciones;
    list[index] = obj;
    setActivitiesList(list);
    setInvalidLat(false);
    setInvalidLng(false);
    setCoord({
      lat: 0,
      lng: 0
    });
    const lat = document.getElementById("input-lat");
    const lng = document.getElementById("input-lng");

    lat.value = 0;
    lng.value = 0;
    setIsForm(false);
  }

  const handleInputChangeOpenModalAct = (index, idx) => {
    const list = [...activitiesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdlAct = !ubi.setOpenMdlAct;
    ubicaciones[idx] = ubi;
    obj.ubicaciones_act = ubicaciones;
    list[index] = obj;
    setActivitiesList(list);
  }

  const handleInputChangeOpenModalActForm = (e, index, idx) => {
    e.preventDefault();
    const list = [...activitiesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdlAct = !ubi.setOpenMdlAct;
    ubicaciones[idx] = ubi;
    obj.ubicaciones_act = ubicaciones;
    list[index] = obj;
    setFormNumberAct(idx);
    setActivitiesList(list);
    setIsForm(true);
  }

  //Cierre Funciones para actividades

  const cleanComponent = () => {

    if (permisos.registros === false && permisos.actividades === true) {
      setSwitchSeccion('actividades')
    } else {
      setSwitchSeccion('registros')
    }
    setAddSolucion(false);
    setAddUbicacion(false);
    setAddActividad(false);
    setAddUbicacionActividad(false);
    setInputList([{ descripcion_principal: "", municipio: "", zona: "", porcentaje: "", fecha: "", resguardo: "", sector_industrial: "", departamento: "", comunidad: "", programa: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
    setActivitiesList([{ resumen: "", descripcion_principal_act: "", fecha_act: "", campo_act: "", ubicaciones_act: [{ setOpenMdlAct: false, descripcion: "", tipo_mapa: "", coords_act: [] }] }]);
    setStateInforme({});
    setTipoMapa('');
    setTipoMapaActividad('');
    setIsForm(false);
    setCoord({ lat: 0, lng: 0 });
    setInvalidLat(false);
    setInvalidLng(false);
    setIndicador('');
    setFormNumber(0);
    setFormNumberAct(0);
    setShowDescriptionModal(false);
    setLat('');
    setLng('');
    setDataFil([]);
  }

  const sendNewAct = async (e) => {

    e.preventDefault()

    const formRegistro = e.currentTarget;

    if (formRegistro.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }




    const dataReq = {
      // conceptos: addSolucion ? inputList : {},
      // actividades: addSolucion ? activitiesList : {},
      hash: hash,
      id: id,
      tipo: "factores",
      id_subseccion: idSubseccion,
      factor: factor,
      formData: {
        descripcion: activitiesList[0].descripcion_principal_act,
        fecha: activitiesList[0].fecha_act,
        actividad: activitiesList[0].campo_act,
        resumen: activitiesList[0].resumen,
        ubicacion: activitiesList[0].ubicacion,
        tipo: stateInforme.tipo,
        mapa: {
          tipo_mapa: activitiesList[0].ubicaciones_act[0].tipo_mapa_act ? activitiesList[0].ubicaciones_act[0].tipo_mapa_act : null,
          ubicaciones: activitiesList[0].ubicaciones_act[0].coords_act ? activitiesList[0].ubicaciones_act[0].coords_act : null
        }
      }
    }

    console.log(dataReq);

    const req = await validarEntorno('JSON/panoramaRiesgos/respuesta_formulario_factores.html', 'POST', dataReq);

    const res = await req.json()

    if (req.ok) {
      setMessageNotification(res.message);
      setShowCreateModalNotification(true);
      getRegistrosSeccion();
      cleanComponent();
      setSwitchSeccion(switchSeccion)

    } else {
      setMessageNotification('Ha ocurrido un fallo en el envio, intente nuevamente');
      setShowErrorModalNotification(true);
    }
  }



  const enviarDatosFormulario = async (e) => {

    e.preventDefault()

    const formRegistro = e.currentTarget;

    if (formRegistro.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    const dataReq = {
      conceptos: addSolucion ? inputList : {},
      actividades: addSolucion ? activitiesList : {},
      hash: hash,
      id: id,
      tipo: "factores",
      id_subseccion: idSubseccion
    }

    console.log(dataReq);

    const req = await validarEntorno('JSON/panoramaRiesgos/respuesta_formulario_factores.html', 'POST', dataReq);

    const res = await req.json()

    if (req.ok) {
      if (res.status === "OK") {
        setMessageNotification(res.message);
        setShowCreateModalNotification(true);
        getRegistrosSeccion();
        cleanComponent();
      } else {
        setMessageNotification(res.message);
        setShowErrorModalNotification(true);
      }
    } else {
      setMessageNotification('Ha ocurrido un fallo en el envio, intente nuevamente');
      setShowErrorModalNotification(true);
    }
  }

  const getFormBySubseccion = (item_input, index) => {
    switch (idSubseccion) {
      case 2:
        return (
          <>
            <Col xs={6}>
              <OpcionesCiudades style={{ display: 'block', marginTop: 8 }} onChange={(e) => handleInputChange(e, index)} />
            </Col>
            {dataFil.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Resguardo</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    defaultValue={item_input.resguardo}
                    onChange={(e) => handleInputChange(e, index)}
                    name='resguardo'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {dataFil.map((item, index) => (
                      <option value={item.id_lis}>{item.valor}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      case 3:
        return (
          <>
            {dataZonasSelect.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Departamento</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    value={item_input.departamento}
                    onChange={(e) => handleInputChange(e, index)}
                    name='departamento'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {(dataZonasSelect.map((item, index) => (
                      <option value={item.valor}>{item.valor}</option>
                    )))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
            {dataFil.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Comunidad</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    value={item_input.comunidad}
                    onChange={(e) => handleInputChange(e, index)}
                    name='comunidad'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {dataFil.map((item, index) => (
                      <option value={item.id_lis}>{item.valor}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      case 8:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                value={item_input.porcentaje}
                onChange={(e) => handleInputChange(e, index)}
                name='porcentaje'
                type='number'
                min='0'
                max='100'
              />
            </Form.Group>
          </Col>
        )
      case 9:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe un porcentaje'
                value={item_input.porcentaje}
                onChange={(e) => handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 10:
        return (
          <>
            {dataZonasSelect.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Zona</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    value={item_input.zona}
                    onChange={(e) => handleInputChange(e, index)}
                    name='zona'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {(dataZonasSelect.map((item, index) => (
                      <option value={item.id_zona}>{item.valor}</option>
                    )))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
            <Col xs={6}>
              <OpcionesCiudades style={{ display: 'block', marginTop: 8 }} onChange={(e) => handleInputChange(e, index)} />
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Programa</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  value={item_input.programa}
                  onChange={(e) => handleInputChange(e, index)}
                  name='programa'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {dataSelect2.length > 0 && (dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )
      case 12:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                value={item_input.porcentaje}
                onChange={(e) => handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 13:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                value={item_input.porcentaje}
                onChange={(e) => handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 15:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                value={item_input.porcentaje}
                onChange={(e) => handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 21:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Vocación</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  value={item_input.campo_2}
                  onChange={(e) => handleInputChange(e, index)}
                  name='campo_2'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {dataSelect2.length > 0 && (dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {item_input.campo_2 === "10" ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Campo</Form.Label>
                  <Form.Control
                    as='select'
                    value={item_input.sector_industrial}
                    onChange={(e) => handleInputChange(e, index)}
                    name='sector_industrial'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">Hidrocarburos</option>
                    <option value="2">Minera</option>
                    <option value="3">Energetica</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      case 22:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Servicio</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  value={item_input.servicio}
                  onChange={(e) => handleInputChange(e, index)}
                  name='servicio'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {dataSelect2.length > 0 && (dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Porcentaje</Form.Label>
                <Form.Control
                  placeholder='Escribe una descripción'
                  value={item_input.porcentaje}
                  onChange={(e) => handleInputChange(e, index)}
                  name='porcentaje'
                  type='number'
                />
              </Form.Group>
            </Col>
          </>
        )
      default:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.tipo')}</Form.Label>
              <Form.Control
                as='select'
                placeholder='Escribe una descripción'
                defaultValue={item_input.tipo}
                onChange={(e) => handleInputChange(e, index)}
                name='tipo'
              >
                <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                {dataSelect2.length > 0 && (dataSelect2.map((item, index) => (
                  <option value={item.valor}>{item.valor}</option>
                )))}
              </Form.Control>
            </Form.Group>
          </Col>
        )
    }
  }

  const closeActivityItem = () => {
    setShowActivityModal(false);
    setEditData({});
  }

  const getRegistrosSeccion = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_factor: factor,
      id_seccion: idSubseccion
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/registros_seccion_factor.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      setSeccionPoligono(res.ubicacion);

      setInitialState({
        ...initialState,
        loading: false,
        error: null,
        data: res
      })

    }
  }

  const editarDatosActividad = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      data: editData,
      id_res: editData.id_res
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/registros_seccion_factor_edicion.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        console.log(res.mensaje);
        const response = res;

        console.log(res);

        setMessageNotification(res.messase);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');

        getRegistrosSeccion();
        closeActivityItem();
      } else {
        setMessageNotification(res.message);
        setShowErrorModalNotification(true);
      }
    }
  }

  const editarCoordenadasActividad = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_act: editData.id_act,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/registros_editar_coordenadas_factores.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const response = res;
        delete response.mensaje;
        delete response.estado;

        setInitialState({
          ...initialState,
          loading: false,
          error: null,
          data: response
        })
      }

      const dataDel = { ...editData };
      const ubi = { ...dataDel.ubicaciones };

      const punto = {
        id_pun: coordEdit.id_res,
        lat: parseFloat(coordEdit.lat),
        lng: parseFloat(coordEdit.lng)
      };

      ubi.puntos[index] = punto;

      setEditData({
        ...editData,
        ubicaciones: ubi
      });

      setIndexEdit('');
    }
  }

  const eliminarCoordenadasActividad = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_act: editData.id_act,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const dataDel = { ...editData };
        const ubi = { ...dataDel.ubicaciones };
        const puntos = [...ubi.puntos];

        puntos.splice(index, 1);

        ubi.puntos = puntos;

        setEditData({
          ...editData,
          ubicaciones: ubi
        });
      }
    }
  }

  const eliminarRegistroActividad = async (index) => {

    // console.log(index)

    if (typeOperation === 'registro') {

      // const dataReq = {
      //     hash: window.location.hash,
      //     id: idPanorama,
      //     id_factor: factor,
      //     id_registro: index,
      //     id_seccion: idSubseccion
      // }

      const dataReq = {
        hash: window.location.hash,
        id: idPanorama,
        id_factor: factor,
        id_registro: regToDel.id_res,
        id_seccion: idSubseccion
      }

      console.log(dataReq);

      const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminacion_registro.html', 'POST', dataReq);

      if (req.ok) {

        const res = await req.json();

        if (res.estado === "OK") {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('eliminacion_confirmada');
          getRegistrosSeccion();
          setIndexDelete('');
        } else {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('falloPeticionesGestion');
        }
      }
    } else if (typeOperation === 'actividad') {

      console.log('Entro actividad');

      const dataReqAct = {
        hash: window.location.hash,
        id: idPanorama,
        id_factor: factor,
        id_res: actToDel?.id_res,
        id_seccion: idSubseccion
      }

      console.log(dataReqAct)

      const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminacion_actividad.html', 'POST', dataReqAct);

      if (req.ok) {

        const res = await req.json();

        if (res.estado === "OK") {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('eliminacion_confirmada');
          getRegistrosSeccion();
          setIndexDelete('');
        } else {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('falloPeticionesGestion');
        }
      }
    }
  }

  const editItem = (item) => {
    setShowEditModal(true);
    setEditData(item);
  }

  const editActivityItem = (item) => {
    setShowActivityModal(true);
    setEditData(item);
  }

  const closeEditItem = () => {
    setShowEditModal(false);
    setEditData({});
  }

  const handleEditData = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    });
  }

  const getIndexEdit = (index, item) => {
    setIndexEdit(index);
    setCoordEdit(item);
  }

  const handleInputCoord = (e) => {
    setCoordEdit({
      ...coordEdit,
      [e.target.name]: e.target.value
    });
  }

  const editarDatosFormulario = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      data: editData,
      id_res: editData.id_res
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/registros_seccion_factor_edicion.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {

        setMessageNotification(res.message);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');

        const response = res;
        delete response.mensaje;
        delete response.estado;

        getRegistrosSeccion();

        closeEditItem();
      } else {
        setShowEditDeleteModalNotification(true);
        setMessageEditDeleteNotification(res.message);
        setTypeNotification('falloPeticionesGestion');
      }
    }
  }

  const editarCoordenadas = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_res: editData.id_res,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_editar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const response = res;
        delete response.mensaje;
        delete response.estado;

        setInitialState({
          ...initialState,
          loading: false,
          error: null,
          data: response
        })
      }

      const dataDel = { ...editData };
      const ubi = { ...dataDel.ubicaciones };

      const punto = {
        id_pun: coordEdit.id_res,
        lat: parseFloat(coordEdit.lat),
        lng: parseFloat(coordEdit.lng)
      };

      ubi.puntos[index] = punto;

      setEditData({
        ...editData,
        ubicaciones: ubi
      });

      setIndexEdit('');
    }
  }

  const eliminarCoordenadas = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_res: editData.id_res,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const dataDel = { ...editData };
        const ubi = { ...dataDel.ubicaciones };
        const puntos = [...ubi.puntos];

        puntos.splice(index, 1);

        ubi.puntos = puntos;

        setEditData({
          ...editData,
          ubicaciones: ubi
        });
      }
    }
  }

  const deleteLastCoord = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubicaciones = [...item.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    const coords = [...ubi.coords];
    coords.pop();
    ubi.coords = coords;
    ubicaciones[idx] = ubi;
    item.ubicaciones = ubicaciones;
    list[index] = item;
    setInputList(list);
  }

  const deleteLastCoordAct = (index, idx) => {
    const list = [...activitiesList];
    const item = { ...list[index] };
    const ubicaciones = [...item.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    const coords = [...ubi.coords_act];
    coords.pop();
    ubi.coords_act = coords;
    ubicaciones[idx] = ubi;
    item.ubicaciones_act = ubicaciones;
    list[index] = item;
    setActivitiesList(list);
  }

  const handleCloseFactorModal = () => {
    setDescriptionFactorModal({
      ...descriptionFactorModal,
      show: false
    })
  }

  return (
    <>
      <br />
      <section style={{ width: '85%', margin: 'auto' }} >
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <DescriptionIcon style={{ cursor: 'pointer' }} onClick={() => { setDescriptionFactorModal({ ...descriptionFactorModal, show: true }) }} />
        </div>
        <div id='contenedorSwitchSeccionesForm' >
          {permisos.registros &&
            <button
              onClick={() => setSwitchSeccion('registros')}
              className={`switchBotonFormFactores switchRegistro ${switchSeccion === 'registros' && 'switchSelected'}`}
            // style={{backgroundColor: switchSeccion === 'registro' && ''}}

            >
              {t('botones.registros')}
            </button>
          }
          {permisos.actividades &&
            <button
              onClick={() => setSwitchSeccion('actividades')}
              className={`switchBotonFormFactores switchActividades ${switchSeccion === 'actividades' && 'switchSelected'}`}

            >
              {t('botones.actividades')}
            </button>
          }
        </div>
        {permisos.registros && switchSeccion === 'registros' &&
          <>
            <br />
            <div className='headerRegistro'>
              <p className='' >{t('panorama_riesgos.registros')}</p>
              <button
                style={{ marginRight: '0px', width: '150px' }}
                className={addSolucion === true ? 'warningButton' : 'confirmButton'}
                onClick={() => setAddSolucion(!addSolucion)}
              >
                {
                  addSolucion === true
                    ? t('botones.cancelar')
                    : t('botones.agregar_registro')
                }

              </button>
            </div>
            <br />

            {addSolucion === true &&
              <div>
                {inputList.map((item_input, index) => (
                  <>
                    <>
                      <Row>
                        <Col xs={12}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                            <Form.Control
                              style={{ minHeight: '100px' }}
                              as='textarea'
                              placeholder={t('formulario.label.escribe_descripcion')}
                              onChange={(e) => handleInputChange(e, index)}
                              value={item_input.descripcion_principal}
                              name='descripcion_principal'
                              required
                            >
                              <Form.Control.Feedback type='invalid'> Agrega una descripción al registro </Form.Control.Feedback>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        {/* <Col xs={6}>
                                            <Form.Group className='formGroupVertical' >
                                                <Form.Label>Fecha</Form.Label>
                                                <Form.Control
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item_input.fecha}
                                                    type="date"
                                                    name='fecha'
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                        </Col> */}
                        {getFormBySubseccion(item_input, index)}
                      </Row>
                    </>
                    <br />
                    <section style={{ width: '85%', margin: 'auto' }} >
                      <div className='headerRegistro'>
                        <h4 className='pagesTitles' >{t('panorama_riesgos.ubicaciones')}</h4>
                        <button
                          style={{ marginRight: '0px', width: '140px' }}
                          className={addUbicacion === true ? 'warningButton' : 'confirmButton'}
                          onClick={() => { addRemoveUbi(index) }}
                        >
                          {
                            addUbicacion === true
                              ? t('botones.cancelar')
                              : t('botones.agregar_ubicacion')
                          }

                        </button>
                      </div>
                      <br />
                      {addUbicacion === false
                        ?
                        <div style={{ display: 'none' }}>
                          Aún no has agregado una ubicación..
                        </div>
                        :
                        <div>
                          {(item_input.ubicaciones) && item_input.ubicaciones.map((ubicacion, idx) => (
                            <>
                              <Form.Group className='formGroupVertical' >
                                <Form.Label>{t('formulario.label.tipo_ubicacion')}</Form.Label>
                                <Form.Control
                                  as='select'
                                  onChange={(e) => handleInputChangeUbi(e, index, idx)}
                                  name='tipo_mapa'
                                  style={{ width: '50%' }}
                                >
                                  <option selected disabled>{t('formulario.label.seleccione')}</option>
                                  <option value='puntos_calor'>{t('formulario.select.puntos_calor')}</option>
                                  <option value='trazado' >{t('formulario.select.trazado')}</option>
                                  <option value='puntos_simples'>{t('formulario.select.punto_simple')}</option>
                                </Form.Control>
                              </Form.Group>
                              {ubicacion.tipo_mapa !== '' &&
                                <div className="containerOcs">
                                  <div id='' className="mapa">
                                    <>
                                      <div className="form-latlng">
                                        <p>{t('formulario.label.agrega_coordenadas')}</p>
                                        <Form>
                                          <Form.Group className='formGroupVertical'>
                                            <Row>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.latitud')}
                                                  name="lat"
                                                  defaultValue={coord.lat}
                                                  type="number"
                                                  onChange={handleLatLng}
                                                  isInvalid={invalidLat}
                                                  id="input-lat"
                                                />
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.longitud')}
                                                  name="lng"
                                                  defaultValue={coord.lng}
                                                  type="number"
                                                  onChange={handleLatLng}
                                                  isInvalid={invalidLng}
                                                  id="input-lng"
                                                />
                                              </Col>
                                              <Col>
                                                <button className="confirmButton" onClick={(e) => handleInputChangeOpenModalForm(e, index, idx)}>{t('botones.ubicar')}</button>
                                              </Col>
                                            </Row>
                                          </Form.Group>
                                        </Form>
                                      </div>
                                      {ubicacion.tipo_mapa === 'puntos_calor'
                                        ?
                                        <>
                                          <p>{t('formulario.label.clic_mapa_puntos_calor')}</p>

                                          <Heatmap
                                            googleMapURL={mapURL}
                                            containerElement={<div style={{ height: '100%' }}></div>}
                                            mapElement={<div style={{ height: '100%' }}></div>}
                                            loadingElement={<Loader />}
                                            latitud={ubicacionBase?.lat}
                                            longitud={ubicacionBase?.lng}
                                            zoom={10}
                                            coords={ubicacion.coords}
                                            getCoordinates={getCoordinates}
                                            tipoMapa={ubicacion.tipo_mapa}
                                            index={index}
                                            idx={idx}
                                            showDescriptionModal={ubicacion.setOpenMdl}
                                            setShowDescriptionModal={handleInputChangeOpenModal}
                                            submitLatLng={submitLatLng}
                                            isForm={isForm}
                                            setFormNumber={setFormNumber}
                                            setLat={setLat}
                                            setLng={setLng}
                                            lat={lat}
                                            lng={lng}
                                            tipo="factores"
                                            seccionPoligono={seccionPoligono}
                                          />
                                        </>
                                        : ubicacion.tipo_mapa === 'trazado'
                                          ?
                                          <>
                                            <p>{t('formulario.label.agrega_puntos_necesarios')} </p>
                                            <Heatmap
                                              googleMapURL={mapURL}
                                              containerElement={<div style={{ height: '100%' }}></div>}
                                              mapElement={<div style={{ height: '100%' }}></div>}
                                              loadingElement={<Loader />}
                                              latitud={ubicacionBase?.lat}
                                              longitud={ubicacionBase?.lng}
                                              zoom={10}
                                              ubicaciones={puntosCalor}
                                              coords={ubicacion.coords}
                                              getCoordinates={getCoordinates}
                                              tipoMapa={ubicacion.tipo_mapa}
                                              index={index}
                                              idx={idx}
                                              showDescriptionModal={ubicacion.setOpenMdl}
                                              setShowDescriptionModal={handleInputChangeOpenModal}
                                              submitLatLng={submitLatLng}
                                              isForm={isForm}
                                              setFormNumber={setFormNumber}
                                              setLat={setLat}
                                              setLng={setLng}
                                              lat={lat}
                                              lng={lng}
                                              tipo="factores"
                                              seccionPoligono={seccionPoligono}
                                            />
                                          </>
                                          : ubicacion.tipo_mapa === 'puntos_simples'
                                            ?
                                            <>
                                              <p>{t('formulario.label.clic_mapa_ubicaciones')} </p>
                                              <Heatmap
                                                googleMapURL={mapURL}
                                                containerElement={<div style={{ height: '100%' }}></div>}
                                                mapElement={<div style={{ height: '100%' }}></div>}
                                                loadingElement={<Loader />}
                                                latitud={ubicacionBase?.lat}
                                                longitud={ubicacionBase?.lng}
                                                zoom={10}
                                                ubicaciones={puntosCalor}
                                                coords={ubicacion.coords}
                                                getCoordinates={getCoordinates}
                                                tipoMapa={ubicacion.tipo_mapa}
                                                index={index}
                                                idx={idx}
                                                showDescriptionModal={ubicacion.setOpenMdl}
                                                setShowDescriptionModal={handleInputChangeOpenModal}
                                                submitLatLng={submitLatLng}
                                                isForm={isForm}
                                                setFormNumber={setFormNumber}
                                                setLat={setLat}
                                                setLng={setLng}
                                                lat={lat}
                                                lng={lng}
                                                tipo="factores"
                                                seccionPoligono={seccionPoligono}
                                              />
                                            </>
                                            : null
                                      }
                                      <br />
                                      <br />
                                      <br />
                                    </>
                                  </div>
                                </div>
                              }
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              {/* {item_input.ubicaciones.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickUbi(index, idx)} />}
                                            {item_input.ubicaciones.length - 1 === idx && <AddCircleIcon className="icon-gray add" onClick={() => handleAddClickUbi(index)} />} */}
                              {ubicacion.coords.length > 0 && (
                                <div className="table-ubi-container">
                                  <Table striped bordered hover className="tabla-ubicaciones">
                                    <thead>
                                      <tr>
                                        <th>{t('formulario.label.latitud')}</th>
                                        <th>{t('formulario.label.longitud')}</th>
                                        <th>{t('formulario.label.descripcion')}</th>
                                        <th>{t('formulario.label.accion')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ubicacion.coords.map((ubi, tbx) => (
                                        <tr className="rowTabla">
                                          <td>{ubi.lat}</td>
                                          <td>{ubi.lng}</td>
                                          <td>{ubi.descripcion}</td>
                                          {tbx === (ubicacion.coords.length - 1) ? (
                                            <td className='tdBotones'>
                                              <div className='botonesTabla '>
                                                <button
                                                  style={{ cursor: 'pointer' }}
                                                  className='botonTablaGeneral botonEliminarTabla'
                                                  onClick={() => { deleteLastCoord(index, idx) }}
                                                >
                                                  <DeleteIcon fontSize='small' />
                                                  {t('botones.eliminar')}
                                                </button>
                                              </div>
                                            </td>
                                          ) : <td></td>}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              )}

                            </>
                          ))}
                        </div>
                      }

                    </section>
                    {/* <hr/> */}
                    {/* {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />}
                                {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />} */}
                  </>
                ))}
                <br />
                <div className='buttonContainer'>
                  <button onClick={(e) => { enviarDatosFormulario(e) }} className='confirmButton'>{t('botones.enviar')}</button>
                </div>
              </div>
            }

            <hr />
            <br />
            <h3 className='pagesTitles' >{t('panorama_riesgos.lista_registros')}</h3>
            <br />

            {initialState?.data?.registros && initialState?.data?.registros.length > 0
              ?
              <table>
                <thead>
                  <th>{t('formulario.label.tipo')}</th>
                  <th>{t('formulario.label.descripcion')}</th>
                  <th>{t('formulario.label.acciones')}</th>
                </thead>
                <tbody>
                  {initialState.data.registros.map((registro, index) => {

                    return (
                      <tr className='rowTabla' key={index} style={{ fontSize: '1rem' }}>
                        <td>{registro.tipo}</td>
                        <td>{registro.descripcion}</td>
                        <td className='tdBotones'>
                          <div className='botonesTabla'>
                            {initialState?.data?.permisos?.editar &&
                              <button
                                style={{ cursor: 'pointer' }}
                                className='botonTablaGeneral botonEditarTabla'
                                onClick={() => { editItem(registro) }}
                              >
                                <EditIcon fontSize='small' />
                                {t('botones.editar')}
                              </button>
                            }
                            {initialState?.data?.permisos?.eliminar &&
                              <button
                                style={{ cursor: 'pointer' }}
                                className='botonTablaGeneral botonEliminarTabla'
                                onClick={() => { handleOpenMessageModalDeleteEdit('registro', index, registro) }}
                              >
                                <DeleteIcon fontSize='small' />
                                {t('botones.eliminar')}
                              </button>
                            }
                          </div>
                        </td>
                      </tr>
                    )

                  })}

                </tbody>

              </table>

              :
              <div className='mensajeTablaSinInfo'>
                {t('formulario.label.no_agregado_registros')}
              </div>
            }


          </>
        }
        {permisos.actividades && switchSeccion === 'actividades' &&
          <>
            <br />
            <div className='headerRegistro'>
              <p>{t('panorama_riesgos.actividades')}</p>
              <button
                style={{ marginRight: '0px', width: '150px' }}
                className={addActividad === true ? 'warningButton' : 'confirmButton'}
                onClick={() => setAddActividad(!addActividad)}
              >
                {
                  addActividad === true
                    ? t('botones.cancelar')
                    : t('botones.agregar_actividad')
                }
              </button>
            </div>
            <br />
            {addActividad === true &&
              <div>
                {activitiesList.map((item_input, index) => (
                  <>
                    <>
                      <Row>
                        <Col xs={12}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.resumen')}</Form.Label>
                            <Form.Control
                              style={{ minHeight: '50px' }}
                              as='textarea'
                              placeholder={t('formulario.label.escribe_descripcion')}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              defaultValue=''
                              name='resumen'
                            >
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                            <Form.Control
                              style={{ minHeight: '100px' }}
                              as='textarea'
                              placeholder={t('formulario.label.escribe_descripcion')}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              value={item_input.descripcion_principal}
                              name='descripcion_principal_act'
                            >
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                            <Form.Control
                              onChange={(e) => handleInputChangeAct(e, index)}
                              value={item_input.fecha}
                              type="date"
                              name='fecha_act'
                            >
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.actividad')}</Form.Label>
                            <Form.Control
                              as='select'
                              placeholder='Escribe una descripción'
                              value={item_input.campo}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              name='campo_act'
                            >
                              <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                              {actividades
                                ?
                                <>
                                  {idSubseccion === (10001 || "10001" || 0)
                                    ?
                                    actividades?.autoridades_civiles?.map((item, index) => (
                                      <option key={index} value={item.nombre}>{item.nombre}</option>
                                    ))
                                    : idSubseccion === (10002 || "10002")
                                      ?
                                      actividades?.resguardos_indigenas?.map((item, index) => (
                                        <option key={index} value={item.nombre}>{item.nombre}</option>
                                      ))

                                      : idSubseccion === (10003 || "10003")
                                        ?
                                        actividades?.comunidades?.map((item, index) => (
                                          <option key={index} value={item.nombre}>{item.nombre}</option>
                                        ))

                                        : idSubseccion === (10004 || "10004")
                                          ?
                                          actividades?.sindicatos?.map((item, index) => (
                                            <option key={index} value={item.nombre}>{item.nombre}</option>
                                          ))

                                          : idSubseccion === (10005 || "10005")
                                            ?
                                            actividades?.lideres_comunales?.map((item, index) => (
                                              <option key={index} value={item.nombre}>{item.nombre}</option>
                                            ))

                                            : idSubseccion === (10006 || "10006")
                                              ?
                                              actividades?.ong?.map((item, index) => (
                                                <option key={index} value={item.nombre}>{item.nombre}</option>
                                              ))

                                              : idSubseccion === (10007 || "10007")
                                                ?
                                                actividades?.etnias?.map((item, index) => (
                                                  <option key={index} value={item.nombre}>{item.nombre}</option>
                                                ))

                                                : idSubseccion === (10008 || "10008")
                                                  ?
                                                  actividades?.poblacion_flotante?.map((item, index) => (
                                                    <option key={index} value={item.nombre}>{item.nombre}</option>
                                                  ))

                                                  : idSubseccion === (10009 || "10009")
                                                    ?
                                                    actividades?.aceptacion_institucional?.map((item, index) => (
                                                      <option key={index} value={item.nombre}>{item.nombre}</option>
                                                    ))

                                                    : idSubseccion === (10010 || "10010")
                                                      ?
                                                      actividades?.programas_de_desarrollo?.map((item, index) => (
                                                        <option key={index} value={item.nombre}>{item.nombre}</option>
                                                      ))

                                                      : idSubseccion === (10011 || "10011")
                                                        ?
                                                        actividades?.indice_de_necesidades?.map((item, index) => (
                                                          <option key={index} value={item.nombre}>{item.nombre}</option>
                                                        ))

                                                        : idSubseccion === (10012 || "10012")
                                                          ?
                                                          actividades?.cobertura_en_salud?.map((item, index) => (
                                                            <option key={index} value={item.nombre}>{item.nombre}</option>
                                                          ))

                                                          : idSubseccion === (10013 || "10013")
                                                            ?
                                                            actividades?.indice_de_alfabetizacion?.map((item, index) => (
                                                              <option key={index} value={item.nombre}>{item.nombre}</option>
                                                            ))

                                                            : idSubseccion == (20001 || "20001")
                                                              ?
                                                              actividades?.partidos_politicos?.map((item, index) => (
                                                                <option key={index} value={item.nombre}>{item.nombre}</option>
                                                              ))

                                                              : idSubseccion == (20002 || "20002")
                                                                ?
                                                                actividades?.indices_aceptacion_autoridades?.map((item, index) => (
                                                                  <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                ))

                                                                : idSubseccion === (30001 || "30001")
                                                                  ?
                                                                  actividades?.vocacion_economica?.map((item, index) => (
                                                                    <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                  ))

                                                                  : idSubseccion === (30002 || "30002")
                                                                    ?
                                                                    actividades?.cobertura_servicios_publicos?.map((item, index) => (
                                                                      <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                    ))

                                                                    : idSubseccion === (30003 || "30003")
                                                                      ?
                                                                      actividades?.iniciativas_regionales?.map((item, index) => (
                                                                        <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                      ))

                                                                      : idSubseccion === (30004 || "30004")
                                                                        ?
                                                                        actividades?.proyectos_productivos?.map((item, index) => (
                                                                          <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                        ))

                                                                        : idSubseccion === (20 || "20")
                                                                          ?
                                                                          actividades?.fuerza_disponible?.map((item, index) => (
                                                                            <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                          ))

                                                                          : idSubseccion === (21 || "21")
                                                                            ?
                                                                            actividades?.amenazas?.map((item, index) => (
                                                                              <option key={index} value={item.nombre}>{item.nombre}</option>
                                                                            ))

                                                                            : null
                                  }
                                </>

                                : null




                              }
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.tipo')}</Form.Label>
                            <Form.Control
                              as='select'
                              placeholder='Escribe una descripción'
                              defaultValue={item_input.tipo}
                              onChange={(e) => handleInputChange(e, index)}
                              name='tipo'
                            >
                              <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                              {dataSelect2.length > 0 && (dataSelect2.map((item, index) => (
                                <option value={item.valor}>{item.valor}</option>
                              )))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6} >
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.ubicacion')}</Form.Label>
                            <Form.Control
                              as='select'
                              defaultValue={item_input.ubicacion}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              name='ubicacion'
                            >
                              <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                              {municipios.length > 0 && (municipios.map((item, index) => (
                                <option value={item.value}>{item.label}</option>
                              )))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                    <br />
                    <section style={{ width: '85%', margin: 'auto' }} >
                      <div className='headerRegistro'>
                        <h4 className='pagesTitles' >{t('panorama_riesgos.ubicaciones')}</h4>
                        <button
                          style={{ marginRight: '0px', width: '140px' }}
                          className={addUbicacionActividad === true ? 'warningButton' : 'confirmButton'}
                          onClick={() => { addRemoveUbiAct(index) }}
                        >
                          {
                            addUbicacionActividad === true
                              ? t('botones.cancelar')
                              : t('botones.agregar_ubicacion')
                          }
                        </button>
                      </div>
                      <br />
                      {addUbicacionActividad === false
                        ?
                        <div style={{ display: 'none' }}>
                          Aún no has agregado una ubicación..
                        </div>
                        :
                        <div>
                          {(item_input.ubicaciones_act) && item_input.ubicaciones_act.map((ubicacion_act, idx) => (
                            <>
                              <Form.Group className='formGroupVertical' >
                                <Form.Label>Tipo de ubicación</Form.Label>
                                <Form.Control
                                  as='select'
                                  onChange={(e) => handleInputChangeUbiAct(e, index, idx)}
                                  name='tipo_mapa'
                                  style={{ width: '50%' }}
                                >
                                  <option selected disabled>--Seleccione--</option>
                                  <option value='puntos_calor'>Puntos de calor</option>
                                  <option value='trazado' >Trazado</option>
                                  <option value='puntos_simples'>Punto simple</option>
                                </Form.Control>
                              </Form.Group>
                              {ubicacion_act.tipo_mapa_act !== '' &&
                                <div className="containerOcs">
                                  <div id='' className="mapa">
                                    <>
                                      <div className="form-latlng">
                                        <p>Agrega las coordenadas en el siguiente formulario o</p>
                                        <Form>
                                          <Form.Group className='formGroupVertical'>
                                            <Row>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.latitud')}
                                                  name="lat"
                                                  defaultValue={coord.lat}
                                                  type="number"
                                                  onChange={handleLatLngAct}
                                                  isInvalid={invalidLat}
                                                  id="input-lat"
                                                />
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.longitud')}
                                                  name="lng"
                                                  defaultValue={coord.lng}
                                                  type="number"
                                                  onChange={handleLatLngAct}
                                                  isInvalid={invalidLng}
                                                  id="input-lng"
                                                />
                                              </Col>
                                              <Col>
                                                <button className="confirmButton" onClick={(e) => handleInputChangeOpenModalActForm(e, index, idx)}>Ubicar</button>
                                              </Col>
                                            </Row>
                                          </Form.Group>
                                        </Form>
                                      </div>
                                      {ubicacion_act.tipo_mapa_act === 'puntos_calor'
                                        ?
                                        <>
                                          <p>Haz click en el mapa tantas veces como sean necesarias, para indicar los puntos de calor a generar </p>

                                          <HeatmapActivities
                                            googleMapURL={mapURL}
                                            containerElement={<div style={{ height: '100%' }}></div>}
                                            mapElement={<div style={{ height: '100%' }}></div>}
                                            loadingElement={<Loader />}
                                            latitud={ubicacionBase?.lat}
                                            longitud={ubicacionBase?.lng}
                                            zoom={10}
                                            coords_map={ubicacion_act.coords_act}
                                            getCoordinatesAct={getCoordinatesAct}
                                            tipoMapa={ubicacion_act.tipo_mapa_act}
                                            index={index}
                                            idx={idx}
                                            showDescriptionModal={ubicacion_act.setOpenMdlAct}
                                            setShowDescriptionModal={handleInputChangeOpenModalAct}
                                            submitLatLngAct={submitLatLngAct}
                                            isForm={isForm}
                                            setFormNumber={setFormNumberAct}
                                            setLat={setLatAct}
                                            setLng={setLngAct}
                                            lat={latAct}
                                            lng={lngAct}
                                            key={`act-${index}-${idx}`}
                                            tipo="factores"
                                            seccionPoligono={seccionPoligono}
                                          />
                                        </>
                                        : ubicacion_act.tipo_mapa_act === 'trazado'
                                          ?
                                          <>
                                            <p>Agrega los puntos necesarios para crear una linea </p>
                                            <HeatmapActivities
                                              googleMapURL={mapURL}
                                              containerElement={<div style={{ height: '100%' }}></div>}
                                              mapElement={<div style={{ height: '100%' }}></div>}
                                              loadingElement={<Loader />}
                                              latitud={ubicacionBase?.lat}
                                              longitud={ubicacionBase?.lng}
                                              zoom={10}
                                              ubicaciones={puntosCalor}
                                              coords_map={ubicacion_act.coords_act}
                                              getCoordinatesAct={getCoordinatesAct}
                                              tipoMapa={ubicacion_act.tipo_mapa_act}
                                              index={index}
                                              idx={idx}
                                              showDescriptionModal={ubicacion_act.setOpenMdlAct}
                                              setShowDescriptionModal={handleInputChangeOpenModalAct}
                                              submitLatLngAct={submitLatLngAct}
                                              isForm={isForm}
                                              setFormNumber={setFormNumberAct}
                                              setLat={setLatAct}
                                              setLng={setLngAct}
                                              lat={latAct}
                                              lng={lngAct}
                                              key={`act-${index}-${idx}`}
                                              tipo="factores"
                                              seccionPoligono={seccionPoligono}
                                            />
                                          </>
                                          : ubicacion_act.tipo_mapa_act === 'puntos_simples'
                                            ?
                                            <>
                                              <p>Haz click en el mapa tantas veces como sean necesarias, para agregar ubicaciones </p>
                                              <HeatmapActivities
                                                googleMapURL={mapURL}
                                                containerElement={<div style={{ height: '100%' }}></div>}
                                                mapElement={<div style={{ height: '100%' }}></div>}
                                                loadingElement={<Loader />}
                                                latitud={ubicacionBase?.lat}
                                                longitud={ubicacionBase?.lng}
                                                zoom={10}
                                                ubicaciones={puntosCalor}
                                                coords_map={ubicacion_act.coords_act}
                                                getCoordinatesAct={getCoordinatesAct}
                                                tipoMapa={ubicacion_act.tipo_mapa_act}
                                                index={index}
                                                idx={idx}
                                                showDescriptionModal={ubicacion_act.setOpenMdlAct}
                                                setShowDescriptionModal={handleInputChangeOpenModalAct}
                                                submitLatLngAct={submitLatLngAct}
                                                isForm={isForm}
                                                setFormNumber={setFormNumberAct}
                                                setLat={setLatAct}
                                                setLng={setLngAct}
                                                lat={latAct}
                                                lng={lngAct}
                                                key={`act-${index}-${idx}`}
                                                tipo="factores"
                                                seccionPoligono={seccionPoligono}
                                              />
                                            </>
                                            : null
                                      }
                                      <br />
                                      <br />
                                      <br />
                                    </>
                                  </div>
                                </div>
                              }
                              <br />
                              <br />
                              <br />
                              <br />
                              {/* {item_input.ubicaciones_act.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickUbiAct(index, idx)} />}
                                            {item_input.ubicaciones_act.length - 1 === idx && <AddCircleIcon className="icon-gray add" onClick={() => handleAddClickUbiAct(index)} />} */}
                              {ubicacion_act.coords_act.length > 0 && (
                                <div className="table-ubi-container">
                                  <Table striped bordered hover className="tabla-ubicaciones">
                                    <thead>
                                      <tr>
                                        <th>Latitud</th>
                                        <th>Longitud</th>
                                        <th>Descripcion</th>
                                        <th>Acciones</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ubicacion_act.coords_act.map((ubi, tbx) => (
                                        <tr className="rowTabla">
                                          <td>{ubi.lat}</td>
                                          <td>{ubi.lng}</td>
                                          <td>{ubi.descripcion}</td>
                                          {tbx === (ubicacion_act.coords_act.length - 1) ? (
                                            <td className='tdBotones'>
                                              <div className='botonesTabla '>
                                                <button
                                                  style={{ cursor: 'pointer' }}
                                                  className='botonTablaGeneral botonEliminarTabla'
                                                  onClick={() => { deleteLastCoordAct(index, idx) }}
                                                >
                                                  <DeleteIcon fontSize='small' />
                                                  Eliminar
                                                </button>
                                              </div>
                                            </td>
                                          ) : <td></td>}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              )}

                            </>
                          ))}
                        </div>
                      }

                    </section>
                    {/* <hr/> */}
                    {/* {activitiesList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickAct(index)} />}
                                {activitiesList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClickAct} />} */}
                  </>
                ))}
                <br />
                <div className='buttonContainer'>
                  <button onClick={(e) => { sendNewAct(e) }} className='confirmButton'>{t('botones.enviar')}</button>
                </div>
              </div>
            }
            <hr />
            <br />
            <h3 className='pagesTitles' >{t('modales.lista_actividades')}</h3>
            <br />
            {(initialState.data.actividades && initialState.data.actividades.length) > 0
              ?
              <table>
                <thead>
                  <th>{t('formulario.label.actividad')}</th>
                  <th>{t('formulario.label.descripcion')}</th>
                  <th>{t('formulario.label.fecha')}</th>
                  <th>{t('formulario.label.acciones')}</th>
                </thead>
                <tbody>
                  {initialState.data.actividades.map((actividad, index) => {

                    return (
                      <tr className='rowTabla' key={index} style={{ fontSize: '0.83rem' }} >
                        <td>{actividad.actividad}</td>
                        <td>{actividad.descripcion}</td>
                        <td>{actividad.fecha}</td>
                        <td className='tdBotones'>
                          <div className='botonesTabla '>
                            {initialState?.data?.permisos?.editar &&
                              <button
                                style={{ cursor: 'pointer' }}
                                className='botonTablaGeneral botonEditarTabla'
                                onClick={() => { editActivityItem(actividad) }}
                              >
                                <EditIcon fontSize='small' />
                                {t('botones.editar')}
                              </button>
                            }
                            {initialState?.data?.permisos?.eliminar &&
                              <button
                                style={{ cursor: 'pointer' }}
                                className='botonTablaGeneral botonEliminarTabla'
                                onClick={() => { handleOpenMessageModalDeleteEdit('actividad', index, actividad) }}
                              >
                                <DeleteIcon fontSize='small' />
                                {t('botones.eliminar')}
                              </button>
                            }
                          </div>
                        </td>
                      </tr>
                    )

                  })}

                </tbody>

              </table>

              :
              <div className='mensajeTablaSinInfo'>
                {t('formulario.label.no_agregado_actividades')}
              </div>
            }
          </>
        }

      </section>

      <NotificationModal
        isOpen={showCreateModalNotification}
        setIsOpen={handleCloseCreateModalNotification}
        typeModal="panoramaCreado"
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showErrorModalNotification}
        setIsOpen={handleCloseErrorModalNotification}
        typeModal="error_panorama"
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showEditDeleteModalNotification}
        setIsOpen={handleCloseModalEditDeleteNotification}
        typeModal={typeNotification}
        message={messageShowEditDeleteNotification}
        item={itemToErase}
        indexDelete={indexDelete}
        handleEliminar={eliminarRegistroActividad}
      />
      <ModalPanorama
        typeModal="factores"
        isOpen={showEditModal}
        setIsOpen={closeEditItem}
        handleInputCoord={handleInputCoord}
        handleInputChange={handleEditData}
        data={editData}
        dataFilter={dataSelect2 ? dataSelect2 : null}
        dataSelect2={dataSelect2 ? dataSelect2 : null}
        googleMapURL={mapURL}
        idSubseccion={idSubseccion}
        dataZonasSelect={dataZonasSelect}
        editarDatosFormulario={editarDatosFormulario}
        indexEdit={indexEdit}
        getIndexEdit={getIndexEdit}
        editarCoordenadas={editarCoordenadas}
        eliminarCoordenadas={eliminarCoordenadas}
        cleanIndex={setIndexEdit}
      />
      <ModalActividades
        isOpen={showActivityModal}
        setIsOpen={closeActivityItem}
        handleInputCoord={handleInputCoord}
        handleInputChange={handleEditData}
        data={editData}
        googleMapURL={mapURL}
        dataSelect2={dataSelect2}
        dataSelect={dataSelect}
        indexEdit={indexEdit}
        getIndexEdit={getIndexEdit}
        editarDatosActividad={editarDatosActividad}
        editarCoordenadas={editarCoordenadasActividad}
        eliminarCoordenadas={eliminarCoordenadasActividad}
      />
      <EditModal
        isOpen={descriptionFactorModal.show}
        typeModal={descriptionFactorModal.tipo}
        setIsOpen={handleCloseFactorModal}
      />
    </>
  );
}

export default FormFactores;
