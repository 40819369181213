import React from 'react'
import { Card } from 'react-bootstrap';

import { Histograma } from '../../components/dashboard/Histograma'
import Graficaconsolidado from '../../components/dashboard/GraficaConsolidado'
import SortedBarChart from '../../components/dashboard/SortedBarChart'
import Divergente from '../../components/dashboard/Divergente'
import { MediaTorta } from '../../components/dashboard/MediaTorta'
import Treemap from '../../components/dashboard/Treemap'
import Dona2 from '../../components/dashboard/Dona2'
import DonaDosniveles from '../../components/dashboard/DonaDosniveles'
import DivergentChartAmplia from '../../components/dashboard/DivergentChartAmplia'
import HerraduraExpandible from '../../components/dashboard/HerraduraExpandible'
import DonaConTotal from '../../components/dashboard/DonaConTotal'
import { BarrasLabelsHorizontales } from '../../components/dashboard/BarrasLabelsHorizontales'
import { RadialRangos } from '../../components/gerenciaGeneral/operaciones/graficas/RadialRangos'
import TimelineMaterial from '../../components/dashboard/TimelineMaterial'

import SaveIcon from '@material-ui/icons/Save';

function RenderGrafica({ grafica }) {


  switch (grafica.tipo) {
    case 'timeline':

      return(
        <Card className='shadow'>
          <TimelineMaterial
            title={'Linea del tiempo'}
            data={grafica.data}
            position="left"
          />
        </Card>
      )
    case 'radial_rangos':

      return(
        <Card className='shadow' >
          <RadialRangos
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            id={grafica.id }
            data={grafica.data}
          />
        </Card>
      )
    case 'series':

      return(
        <Graficaconsolidado
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          id={grafica.id ? grafica.id : 'consolidado'}
          series={grafica.lista}
          data={grafica.data}
        />
      )
    case 'barras_h':
      return(
        <SortedBarChart
          title={grafica.titulo}
          data={grafica.data  || grafica.data_grafica}
          parametrizado={grafica.parametrizado}
          id={grafica.id}
          // periodo={grafica.periodo}
          total={grafica.total}
          height={grafica.height ? grafica.height : '340px'}
          icon={<SaveIcon />}
          labels={grafica.labels}

        />
      )

    case 'histograma':
      return(
        <Histograma
          id='solicitudesTipoServicio'
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
          limite={grafica.limite}
          series={grafica.series}
        />
      )
    case 'tortacontorta':
      return(
        <MediaTorta
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
        />
      )
    case 'mediatorta':
      return(
        <MediaTorta
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
          startAngle={grafica.startAngle ? grafica.startAngle : 180}
          endAngle={grafica.endAngle ? grafica.endAngle : 360}
        />
      )
    case 'divergente':
      return(
        <Divergente
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
        />
      )
    case 'barras_horizontales_new':
      return(
        <Card className='shadow' >
          <BarrasLabelsHorizontales
            id={grafica.id}
            data={grafica.data_grafica}
            title={grafica.titulo}
            parametrizado={grafica.parametrizado}
            periodo={grafica.periodo}
            total={grafica.total}
          />
        </Card>

      )
    case 'treemap':
      return(
        <Treemap
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
          height={grafica.height}
        />
      )
    case 'dona':
      return(
        <Dona2
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
        />
      )
    case 'dona_doble':
      return(
        <DonaDosniveles
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
        />
      )
    case 'dona_total':
      return(
        <DonaConTotal
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
        />
      )
    case 'herradura_expandible':
      return(
        <HerraduraExpandible
          id={grafica.id}
          data={grafica.data}
          title={grafica.titulo}
          parametrizado={grafica.parametrizado}
          periodo={grafica.periodo}
          total={grafica.total}
        />
      )
    default:
      return null;
  }


  return null
}

export { RenderGrafica }