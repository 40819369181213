import React, {useState, useEffect} from 'react'

import { Form, Card, Col, Row } from 'react-bootstrap';

import { validarEntorno } from '../../../helpers/validarHost';
import OpcionesCiudades from '../../../helpers/OpcionesCiudades';


import Select from 'react-select';
import NotificationModal from '../../modales/NotificationModal'

import './styles/VisitaCredito.scss'

export default function VisitaCredito(props) {

  const {actividad_cliente, tipo_visitas, acompanamiento, centro_costos} = props.contenido

  // //console.log(props.contenido)

  const [tipoModal, setTipoModal] = useState({
    tipo: '',
    identificador: '',
    mensaje: ''
  });
  const [modalConfirmacionSolicitud, setModalConfirmacionSolicitud] = useState(false);
  const [validarEmpresa, setValidarEmpresa] = useState(true);
  const [validated, setValidated] = useState(false);
  const [formulario, setFormulario] = useState({
    actividad: '',
    ciudad: '',
    tipo_id: '',
    identificacion: '',
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    tipo_visita: '',
    acompanamiento: '',
    direccion: '',
    telefono: '',
    fecha: '',
    centro_costos: '',
    observacion : ''
  });
  const [isDisabled, setIsDisabled] = useState(false)

  const [primerNombreVal, setPrimerNombreVal] = useState('')
  const [segundoNombreVal, setSegundoNombreVal] = useState('')
  const [primerApellidoVal, setPrimerApellidoVal] = useState('')
  const [SegundoApellidoVal, setSegundoApellidoVal] = useState('')

  useEffect(() => {
    // transformarOpciones()
  }, [])

  // const transformarOpciones = () => {
    
  // }

  // const {empresas} = props.contenido

  const handleDataFormulario = ({target}) => {

    if (target?.name === 'primer_nombre' || target?.name === 'segundo_nombre' || target?.name === 'primer_apellido' || target?.name === 'segundo_apellido'  ) {

      // //console.log(e.target.name)

     // const regular =  e?.target?.value?.replace(reg,'')
      const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑ ]/g)

      const validado = reg1.test(target.value)


      if (!validado) {

          if(target?.name === 'primer_nombre'){
              setPrimerNombreVal(target.value)
          }else if(target?.name === 'segundo_nombre'){
              setSegundoNombreVal(target.value)
          }else if(target?.name === 'primer_apellido'){
              setPrimerApellidoVal(target.value)
          }else if(target?.name === 'segundo_apellido'){
              setSegundoApellidoVal(target.value)
          }


          setFormulario({
            ...formulario,
            [target.name] : target.value 
          })

      }


  } else {
    setFormulario({
      ...formulario,
      [target.name] : target.value 
    })

  }

    // //console.log(formulario)
  }

  const handleSelect = (value, tipo) => {
    
      setFormulario({
        ...formulario,
        [tipo] : value.value 
      })
    

    // //console.log(value, tipo)
    // //console.log(formulario)
  }

  const handleSelectEncargado = (value) => {
    
    setFormulario({
      ...formulario,
      encargado : value.value 
    })
  

  // //console.log(value)
  // //console.log(formulario)
}

  const enviarFormulario = async (e) => {

    e.preventDefault()

    const form = e.currentTarget;

    if (form.checkValidity() === false ) {
        e.preventDefault();

        
    }
    setValidated(true)

    
    if (form.checkValidity() === true) {
      
      setValidarEmpresa(true)
      setIsDisabled(true)


      

      const dataReq = {
        hash: window.location.hash,
        formulario

      } 

      // //console.log(dataReq)

      const req = await validarEntorno('JSON/sol_visita_credito.html','P0ST', dataReq)

      // //console.log(req)
      
      if(req.ok){
        const res = await req.json()
        // registroAuditoriaCreado
        if(res.estado === 'ok'){
          setTipoModal({
            ...tipoModal,
            tipo: 'registroSolVis',
            identificador : res.idSolicitud
          })
          setModalConfirmacionSolicitud(true)

        } else if(res.estado === 'false'){
          setTipoModal({
            ...tipoModal,
            tipo: 'registroAuditoriaError',
            mensaje: res.mensaje
          })
          setModalConfirmacionSolicitud(true)
        }
        setIsDisabled(false)
      }else{
        setTipoModal({
          ...tipoModal,
          tipo: 'errorActualizacionDofa'
        })
        setIsDisabled(false)
      }

    }





  }

  


  return (
    <Card 
      className='shadow cardContainerSolicitud' 
      style={{borderRadius: '0px 10px 10px 10px'}}
    >
      <h5 className='titleCard'>Información de solicitud</h5>

      <Card.Body>
        <Form  className='formEstudioBasicoContainerSolVis' noValidate validated={validated} onSubmit={enviarFormulario}>
          <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <Form.Label style={{marginLeft:'0px'}}>
                  Actividad del cliente
              </Form.Label>
              <div className='inputContainer'>
              <Select 
                options={actividad_cliente}
                // item={item} 
                onChange={(e) => handleSelect(e, 'actividad')} 
                name='actividad'
                // index={index} 
              />
                <Form.Control.Feedback  type='invalid'>Selecciona el tipo de visita </Form.Control.Feedback>
              </div>
          </Form.Group>
              <Form.Group  id='' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Ciudad / departamento
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='ciudad' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la ciudad/departamento </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                     Tipo de identificación
                  </Form.Label>
                  <div className='inputContainer'>
                    <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='tipo_id'  as='select'
                      // isInvalid={validarEmpresa}
                      // isValid={!validarEmpresa}
                      defaultValue={''}
                      >
                        <option value='' disabled>-- Seleccione -- </option>
                        <option value="CC">Cedula de ciudadanía</option>
                        <option value="CE">Cédula extranjera</option>
                        <option value="PA">Pasaporte aéreo</option>
                        <option value="AS">A.S</option>
                    </Form.Control>
                    
                    <Form.Control.Feedback  type='invalid'>Ingresa el tipo de identificación </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Identificación 
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='identificacion' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa el número de identificación </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Primer nombre
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      value={primerNombreVal}
                      name='primer_nombre' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa el primer nombre </Form.Control.Feedback>
                  </div>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Primer apellido
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      value={primerApellidoVal}
                      name='primer_apellido' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa el primer apellido </Form.Control.Feedback>
                  </div>
                  
              </Form.Group>
              <Form.Group  id='' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div className='inputContainer'>
                      <Form.Label style={{marginLeft:'0px'}}>
                        Segundo nombre
                      </Form.Label>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      value={segundoNombreVal}
                      name='segundo_nombre'></Form.Control>
                      {/* <Form.Control.Feedback  type='invalid'>Ingresa el segundo nombre </Form.Control.Feedback> */}
                  </div>
                  
                  <div className='inputContainer'>
                      <Form.Label style={{marginLeft:'0px'}}>
                        Segundo apellido
                      </Form.Label>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      value={SegundoApellidoVal}
                      name='segundo_apellido'></Form.Control>
                      {/* <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback> */}
                  </div>
              </Form.Group>
               <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Tipo de visita
                  </Form.Label>
                  <div className='inputContainer'>
                  <Select 
                    options={tipo_visitas}
                    onChange={(e) => handleSelect(e, 'tipo_visita')} 

                    // item={item} 
                    // onChange={handleSelectCuenta} 
                    name='tipo_visita'
                    // index={index} 
                  />
                    
                      
                      <Form.Control.Feedback  type='invalid'>Selecciona el tipo de visita </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Acompañamiento
                  </Form.Label>
                  <div className='inputContainer'>
                    <Select 
                      options={acompanamiento}
                      onChange={(e) => handleSelect(e, 'acompanamiento')} 
                      
                      // item={item} 
                      // onChange={handleSelectCuenta} 
                      name='acompanamiento'
                      // index={index} 
                    />  
                    <Form.Control.Feedback  type='invalid'>Selecciona el acompañamiento </Form.Control.Feedback>
                  </div>
              </Form.Group>

              
          

              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Dirección
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='direccion' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la dirección </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Teléfono
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='telefono' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa el número de teléfono </Form.Control.Feedback>
                  </div>
              </Form.Group>

              {/* <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <OpcionesCiudades
                  onChange={(e) => handleDataFormulario(e)}
                />
              </Form.Group> */}
              <Form.Group  id='' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Fecha y hora
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      type='date'
                      name='fecha' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Centro de costos
                  </Form.Label>
                  <div className='inputContainer'>
                    <Select 
                      options={centro_costos}
                      onChange={(e) => handleSelect(e, 'centro_costos')} 

                      // item={item} 
                      // onChange={handleSelectCuenta} 
                      name='centro_costos'
                      // index={index} 
                    />
                    <Form.Control.Feedback  type='invalid'>Selecciona la empresa </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <div id='observacionesContainerSolInv'>
                <Form.Group id='observacionesFormGroup'>
                  <Form.Label className='observacionesLabel'>
                      Observación
                  </Form.Label>
                  <div className='textAreaContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='observacion' id='observacionesTextArea' required as='textarea' placeholder='Descripción'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una observación</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>

        <div className='buttonContainers'>
            <button type='submit' className='confirmButton' disabled={isDisabled} >Guardar</button>
        </div>

      </Form>
      </Card.Body>
      {
        modalConfirmacionSolicitud === true &&
          <NotificationModal infoNotificacion={tipoModal.mensaje} identificador={tipoModal.identificador} typeModal={tipoModal.tipo} isOpen={modalConfirmacionSolicitud} setIsOpen={setModalConfirmacionSolicitud} />
      }
      
    </Card>
  )
}
