import React, { useState } from 'react'
import { Card, Form, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { changePassword } from '../../../reducers/profileReducer';
import NotificationModal from '../../../components/modales/NotificationModal'
import { validarEntorno } from '../../../helpers/validarHost';
import LoaderButton from '../../../components/icons/LoaderButton';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ValidacionContrasena from './ValidacionContrasena';
import usePassword from '../../../hooks/usePassword';
import './CambioContraseña.scss';



export const CambioContraseña = (props) => {

  const { t } = useTranslation();
  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)

  const [tipoModal, setTipoModal] = useState(false)
  const [detalle, setDetalle] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [passActual, setPassActual] = useState(false)
  const [passNueva, setPassNueva] = useState(false)
  const [passConfirmar, setPassConfirmar] = useState(false)

  const [info, setInfo] = useState({
    actual: '',
    nuevo: '',
    confirmar: ''
  });

  const {
    longitudValida,
    minusculasMayusculas,
    numericos,
    especiales,
    sinEspaciosVacios,
    formularioValido,
    coinciden,
    cambioInicial
  } = usePassword({ nueva: info.nuevo, confirmacion: info.confirmar });

  const handleChange = e => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    })
  };

  const handleSubmit = async event => {
    setIsLoading(true)
    event.preventDefault();


    if (info.actual === '' || info.nuevo === '' || info.confirmar === '') {

      setTipoModal('rellenarCampos')
      setOpenModal(true)
      setIsLoading(false)
      return
    }
    if (info.nuevo !== info.confirmar) {

      setTipoModal('passwordChangeNewPasswordNotMatch')
      setOpenModal(true)
      setIsLoading(false)
      return
    }


    const requestPassword = await validarEntorno('JSON/respuesta_success_cambio_password_json.html', 'POST', info);
    const body = await requestPassword.json()

    setIsLoading(false)

    if (body.estado === "OK") {
      setDetalle(body.mensaje)
      setTipoModal('passwordChangedSuccess')
      dispatch(changePassword(body));
      setOpenModal(true)
    } else {
      setDetalle(body.mensaje)
      setTipoModal('passwordChangedError')
      setOpenModal(true)
    }
  }

  const showHidePwd = (name, state, setState) => {
    setState(!state)
    const input = document.querySelector(`input[name="${name}"]`)
    if (input.type === 'password') {
      input.type = 'text'
    } else {
      input.type = 'password'
    }
  }

  return (
    <Card className='shadow cardContainer'>
      <Card.Body className='cardbodyPassword'>
        <div>
          <Form id='passwordForm' onSubmit={handleSubmit}>
            <div className='newPasswordForm'>
              <div className='form_cont'>
                <Form.Group className='formGroupVertical'>
                  <Form.Label>
                    {t('formulario.label.contrasena_actual')} *
                  </Form.Label>
                  <div className='inputContainer' style={{ display: 'flex', flexDirection: "row" }} >
                    <Form.Control type='password' name='actual' onChange={handleChange} required placeholder={t('formulario.label.ingrese_contrasena_actual')}></Form.Control>
                    <div className='button_show_hide' onClick={() => showHidePwd('actual', passActual, setPassActual)}>{passActual ? <VisibilityOffIcon></VisibilityOffIcon> : <VisibilityIcon></VisibilityIcon>}</div>
                  </div>
                </Form.Group>
                <Form.Group className='formGroupVertical'>
                  <Form.Label>
                    {t('formulario.label.contrasena_nueva')} *
                  </Form.Label>
                  <div className='inputContainer' style={{ display: 'flex', flexDirection: "row" }}>
                    <Form.Control type='password' name='nuevo' onChange={handleChange} required placeholder={t('formulario.label.ingrese_contrasena_nueva')}></Form.Control>
                    <div className='button_show_hide' onClick={() => showHidePwd('nuevo', passNueva, setPassNueva)}>{passNueva ? <VisibilityOffIcon></VisibilityOffIcon> : <VisibilityIcon></VisibilityIcon>}</div>
                  </div>
                </Form.Group>
                <Form.Group className='formGroupVertical'>
                  <Form.Label>
                    {t('formulario.label.confirmar_nueva_contrasena')} *
                  </Form.Label>
                  <div className='inputContainer' style={{ display: 'flex', flexDirection: "row" }}>
                    <Form.Control type='password' name='confirmar' onChange={handleChange} required placeholder={t('formulario.label.confirme_nueva_contrasena')} ></Form.Control>
                    <div className='button_show_hide' onClick={() => showHidePwd('confirmar', passConfirmar, setPassConfirmar)}>{passConfirmar ? <VisibilityOffIcon></VisibilityOffIcon> : <VisibilityIcon></VisibilityIcon>}</div>
                  </div>
                </Form.Group>

              </div>
              <ValidacionContrasena
                longitudValida={longitudValida}
                minusculasMayusculas={minusculasMayusculas}
                numericos={numericos}
                especiales={especiales}
                sinEspaciosVacios={sinEspaciosVacios}
                formularioValido={formularioValido}
                coinciden={coinciden}
                cambioInicial={cambioInicial}
              />
              <div className='buttonContainer mt-5'>
                <button className='confirmButton' disabled={isLoading || !formularioValido }>{isLoading ? <LoaderButton text={"Guardando"}></LoaderButton> : t('botones.guardar')}</button>
                <NotificationModal typeModal={tipoModal} isOpen={openModal} setIsOpen={setOpenModal} detalle={detalle} onClose={props.onClose} />
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  )
}
