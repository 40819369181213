import React from "react"
import { Label, Pie, PieChart } from "recharts"
import { ChartContainer, ChartTooltipContent, ChartTooltip } from "../../ui/Chart";

function PieDonutText({ data, dataKey = "valor", nameKey = "categoria", label = "Total", maxHeight }) {

    console.log(maxHeight)

    const generateChartConfig = (data) => {
        const config = {};
        data?.forEach(item => {
            config[item[nameKey]] = { label: item.label };
        });
        return config;
    };

    const chartConfig = generateChartConfig(data);

    const total = React.useMemo(() => {
        return data?.reduce((acc, curr) => acc + curr[dataKey], 0);
    }, [dataKey, data]);

    return (
        <ChartContainer
            config={chartConfig}
            className="mx-auto"
            style={{ maxHeight: maxHeight }}
        >
            <PieChart>
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                />
                <Pie
                    data={data}
                    dataKey={dataKey}
                    nameKey={nameKey}
                    innerRadius={60}
                    strokeWidth={5}
                >
                    <Label
                        content={({ viewBox }) => {
                            if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                return (
                                    <text
                                        x={viewBox.cx}
                                        y={viewBox.cy}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                    >
                                        <tspan
                                            x={viewBox.cx}
                                            y={viewBox.cy}
                                            className="fill-foreground text-3xl font-bold"
                                        >
                                            {total.toLocaleString()}
                                        </tspan>
                                        <tspan
                                            x={viewBox.cx}
                                            y={(viewBox.cy || 0) + 24}
                                            className="fill-muted-foreground"
                                        >
                                            {label}
                                        </tspan>
                                    </text>
                                )
                            }
                        }}
                    />
                </Pie>
            </PieChart>
        </ChartContainer>
    )
}

export default PieDonutText