// const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
// import { marker } from 'leaflet'
import React, {useState, Fragment, createRef} from 'react';
import { Button } from 'react-bootstrap';

import {GoogleMap, withScriptjs, withGoogleMap, Marker ,InfoWindow, Polyline, Polygon, OverlayView } from 'react-google-maps'
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";

// 3.4334185,-76.4517656, 1.2047533,-77.2699356

// const cali = { lat: 3.4334185, lng: -76.4517656}

import './Map.scss'


const iconos = {
  terrorismo: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/Terrorismo_map.svg'
  },
  homicidio: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/Homicidio_map.svg'
  },
  secuestro: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/Secuestro_map.svg'
  },
  combinado: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/icono_combinado3.svg'
  },
  zona_alerta: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/zona_alerta.svg'
  },
  oleoducto: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/valvula.svg'
  },
  fuerza_disponible: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_fuerzas.svg',
    baeev: 'https://www.ciacosinte.site/cia2/static/media/BAEEV.svg',
    mecanizado: 'https://www.ciacosinte.site/cia2/static/media/mecanizado.svg',
    caballeria: 'https://www.ciacosinte.site/cia2/static/media/caballeria.svg'
  },
  politico: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_politico.svg'
  },
  economico: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_economico.svg'
  },
  social: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_social.svg'
  },
  amenaza: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_amenaza.svg',
    farc: 'https://www.ciacosinte.site/cia2/static/media/farc.svg',
    eln: 'https://www.ciacosinte.site/cia2/static/media/eln.svg'
  }
}

const Map = (props) => {
  const { data } = props

  const [ openInfo, setOpenInfo ] = useState(false);
  const [ centerDefault, setCenterDefault ] = useState(
    {
      lat: props.latitud,
      lng: props.longitud
    }
  );
  const [ showInfoIndex, setShowInfoIndex ] = useState('');
  const [ coordsCenter, setCoordsCenter ] = useState({
      lat: 0,
      lng: 0
  });
  const [ showPolygonCenter, setShowPolygonCenter ] = useState(false);

  // const [ coords, setCoords ] = useState([]);

  const handleOpenInfo = index => {
    // setOpenInfo(!openInfo)
    setShowInfoIndex(index);
  }

  const onSelect = (item, index) => {
    console.log(item);
    setMarkerSelected(item);
    handleOpenInfo(index);
  }

  const [markerSelected, setMarkerSelected] = useState()

  const getPoints = () => {
    let arr = [];
    if (garmados && garmados.length > 0) {
      garmados.map((garmado) => {
        garmado.coords.map((item) => {
            // eslint-disable-next-line no-undef
            const latlng = new google.maps.LatLng(item.lat, item.lng)
            arr.push(latlng);
        });
      });
    }

    return arr;
  }

  const onLoad = heatmapLayer => {
    console.log('HeatmapLayer onLoad heatmapLayer: ', heatmapLayer)
  }

  const onUnmount = heatmapLayer => {
    console.log('HeatmapLayer onUnmount heatmapLayer: ', heatmapLayer)
  }

  const bounds = new google.maps.LatLngBounds();

  const getCenterPolygon = (poligono) => {
    for (let i = 0; i < poligono.length; i++) {
      bounds.extend(poligono[i]);
    }

    setCoordsCenter({
      lat: bounds.getCenter().lat(),
      lng: bounds.getCenter().lng()
    });

    setShowPolygonCenter(true);

  }


  const getCoordsPolygon = (data) => {
    const polygonCoords = buildCoordinatesArrayFromString(data);

    return polygonCoords;
  }

const buildCoordinatesArrayFromString = (MultiGeometryCoordinates) => {

    var finalData = [];

    var grouped = MultiGeometryCoordinates.split(" ");

    grouped.forEach(function(item, i){
        let a = item.trim().split(',');

        finalData.push({
            lng: parseFloat(a[0]),
            lat: parseFloat(a[1])
        });
    });

    return finalData;
}

  // const getCoordinates = (e) => {

  //   const crd = {
  //     lat: e.latLng.lat(),
  //     lng: e.latLng.lng()
  //   };

  //   setCoords([...coords, crd]);
  // }


  const getPointsRoute = (arr) => {
      const points = [];
      arr.map((item, index) => {
        const objPoints = {
          lat: parseFloat(item.puntos.lat),
          lng: parseFloat(item.puntos.lng)
        }
        points.push(objPoints);
      })

      return points;
  }

  const getPointsHeatmap = (coords) => {

    let arr = [];

    const points = getPointsRoute(coords);

    if (points.length > 0) {
      // eslint-disable-next-line array-callback-return
      points.map((hm) => {      // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(hm.lat, hm.lng)
        arr.push(latlng);
      });
    }

    return arr;
  }

  const getColorPolygone = (tipo) => {
    let color = '#000';

    switch (tipo) {
      case 'asentamientos':
        color = '#213265'
        break;
      case 'presencia_grupos_armados_ilegales':
        color = '#ffe876'
        break;
      case 'inb':
          color = '#007605'
          break;
      default:
        break;
    }
    return color;
  }

  return(
            <>
              <div>
                <GoogleMap
                defaultZoom={props.zoom ? props.zoom : 10}
                zoom={10}
                defaultCenter={{
                  lat: 7.083333,
                  lng: -70.75
                }}
                center={{
                  lat: 7.083333,
                  lng: -70.75
                }}
                defaultOptions={{
                  mapTypeId: "terrain",
                  fullscreenControl: false,
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false
                }}
                // onClick={getCoordinates}
              >
              { data && data?.map((item, index) => (
                ( item.tipo_mapa === 'puntos' ? (
                  (item.puntos && item.puntos.map((punto, index) => {
                    return(
                      <div
                        style={{
                          backgroundColor:'green'
                        }} >
                      <Marker
                        // label={ punto.id }
                        key={index}
                        position={{
                          lat: parseFloat(punto.puntos.lat),
                          lng: parseFloat(punto.puntos.lng)
                        }}

                        // onClick={() => onSelect(item, index)}
                        icon=
                        {punto.suceso === 'HOMICIDIO'
                          ? iconos.homicidio.icono
                          : punto.suceso === 'SECUESTRO'
                            ? iconos.secuestro.icono
                            : punto.suceso === 'TERRORISMO'
                              ? iconos.terrorismo.icono
                              : punto.suceso === 'COMBINADO'
                                ? iconos.combinado.icono
                                : punto.suceso === 'zona_alerta'
                                  ? iconos.zona_alerta.icono
                                  : punto.suceso === 'OLEODUCTO'
                                    ? iconos.oleoducto.icono
                                    : punto.suceso === 'fuerzas'
                                      ? iconos.fuerza_disponible.icono
                                      : punto.suceso === 'politico'
                                        ? iconos.politico.icono
                                        : punto.suceso === 'economico'
                                          ? iconos.economico.icono
                                          : punto.suceso === 'social'
                                            ? iconos.social.icono
                                            : punto.suceso === 'amenaza'
                                              ? iconos.amenaza.icono
                                              : punto.suceso === 'FARC'
                                                ? iconos.amenaza.farc
                                                : punto.suceso === 'ELN'
                                                  ? iconos.amenaza.eln
                                                  : punto.suceso === 'caballeria'
                                                    ? iconos.fuerza_disponible.caballeria
                                                    : punto.suceso === 'baeev'
                                                      ? iconos.fuerza_disponible.baeev
                                                      : punto.suceso === 'mecanizado'
                                                        ? iconos.fuerza_disponible.mecanizado
                                                        : ''
                        }
                      style={{color: 'blue', display:'flex', justifyContent:'center', color: 'white'}}
                      labelAnchor={new google.maps.Point(3, 28)}
                    >
                      <div>
                        {/* <span>Actividad</span>
                        <br /> */}
                        {/* <span style={{ display:'flex', justifyContent:'center', color:'white', fontWeight:'600', fontSize:'12px', textAlign:'center !important'}} >{punto.id}</span> */}
                        {/* <InfoWindow
                          options={{ pixelOffset: new window.google.maps.Size(-100, 180)}}
                          defaultIndex={0}
                          position='left'
                          style={{
                            width: '220px'
                          }}
                        >
                          <div className='contenedorActividadesFactores'>
                            <p className="contenedorActividadesFactores-titulo">Actividades en Saravena, Arauca</p>
                            <div id='factores'>
                              {punto?.actividades?.map((item, index) => {
                                return(
                                  <>

                                  </>
                                  // <div key={index} className='factores_item'>
                                  //   <p className='factor_label' >{item.factor}</p>
                                  //   <div className='factor_actividades-lista' >
                                  //     {item.actividades.map((act, index) => {
                                  //         if(index < 5){
                                  //           return(
                                  //             <>
                                  //               <div className='actividad' key={index}
                                  //                 style={{
                                  //                   backgroundColor: item.factor.toLowerCase() === 'politico' || item.factor.toLowerCase() ===  'político'
                                  //                     ? '#4473C4'
                                  //                     : item.factor.toLowerCase() === 'social'
                                  //                       ? '#009999'
                                  //                       : item.factor.toLowerCase() === 'economico' || item.factor.toLowerCase() === 'económico'
                                  //                         ? '#F9CE36'
                                  //                         : item.factor.toLowerCase() === 'amenaza'
                                  //                           ? '#DA2F0A'
                                  //                           : item.factor.toLowerCase() === 'fuerza'
                                  //                             ? '#FF761A'
                                  //                             : null
                                  //                 }}
                                  //               >
                                  //                 <p  >{act.id}</p>
                                  //               </div>
                                  //             </>
                                  //           )

                                  //         }
                                  //       })
                                  //     }
                                  //     {item.actividades.length > 5 &&
                                  //       <div id='cantidad_extra' >{`+${item.actividades.length - 5} `}</div>
                                  //     }

                                  //   </div>
                                  // </div>
                                )

                              })}

                            </div>
                          </div>
                        </InfoWindow> */}
                      </div>
                    </Marker>
                      {/* <OverlayView

                        position={Marker.position}

                      >
                        <div
                            style={{
                                background: `#203254`,
                                padding: `7px 12px`,
                                fontSize: '11px',
                                color: `white`,
                                borderRadius: '4px',
                            }}
                        >
                            hola
                        </div>
                      </OverlayView> */}
                      {/* <Marker
                        label='hola'
                        icon={iconos.fuerza_disponible.icono}
                        position={{
                          lat: parseFloat(punto.puntos.lat ),
                          lng: parseFloat(punto.puntos.lng - 80.05210)
                        }}
                      /> */}
                    </div>
                    )

                  }))
                ) : ( item.tipo_mapa === 'poligono' ? (
                  <Fragment key={index}>
                    <Polygon
                      path={getCoordsPolygon(item?.puntos)}
                      // editable = {true}
                      icon={iconos.homicidio.icono }
                      label='holaaaaaaaa'
                      title='holaaaaaaaa'
                      labelContent='holaaaaaaaa'
                      defaultLabel= 'asdfasdf'
                      defaultTitle= 'assdfasdfasdfds'
                      // labelContent
                      labelAnchor={new google.maps.Point(0, 2)}
                      defaultIcon= 'asdfasdf'
                      options={{
                          fillColor: item.color,
                          fillOpacity: item.opacidad ? item.opacidad : 0.3 ,
                          strokeColor: item.color,
                          strokeOpacity: 0.15,
                          strokeWeight: 4,
                          color: 'white',
                          label: 'holaaaaaaaa',
                          text: 'holaaaaaaaa',
                          title: 'holaaaaaaaa',
                          content: 'holaaaaaaaa'
                      }}
                    >
                      <MarkerWithLabel
                        labelAnchor={new google.maps.Point(3, 28)}
                      >
                        <div>
                          <span style={{ backgroundColor:'red', display:'flex', justifyContent:'center', color:'white', fontWeight:'600', fontSize:'12px', textAlign:'center !important'}} >{'asdfsd'}</span>
                        </div>
                      </MarkerWithLabel>
                    </Polygon>

                    {showPolygonCenter ? (
                      <Marker position={coordsCenter}>
                        <InfoWindow onCloseClick={() => setShowPolygonCenter(false)}>
                          <div>
                            <h4 className="pageTitule">Indice de necesidades basicas (INB)</h4>
                            <p>Porcentaje de tumaco: 80%</p>
                          </div>
                        </InfoWindow>
                      </Marker>
                    ) : null}
                  </Fragment>
                ) : ( item.tipo_mapa === 'limites' ? (
                  <Polyline
                    defaultPath={getPointsRoute(item)}
                    path={getPointsRoute(item)}
                    geodesic={true}
                    options={{
                      strokeColor: "#ff2527",
                      strokeOpacity: 0.75,
                      strokeWeight: 2
                    }}
                  />
                ) : (item.tipo_mapa === 'oleoducto' ? (
                  <Fragment>
                    <Polyline
                      defaultPath={getPointsRoute(item.puntos)}
                      path={getPointsRoute(item.puntos)}
                      geodesic={true}
                      options={{
                        strokeColor: "#2b2b2b",
                        strokeOpacity: 0.75,
                        strokeWeight: 6
                      }}
                    />
                  </Fragment>
                ) : (item.tipo_mapa === 'puntos_calor' ? (
                    <HeatmapLayer
                      data={getPointsHeatmap(item.puntos)}
                      radius={5000}
                      fillOpacity={1}
                    />
                ) : (item.tipo_mapa === 'puntos_oleducto' ? (
                  <Fragment>
                    {item.puntos.map((coord, index) => (
                            <MarkerWithLabel
                              key={index}
                              position={{
                                lat: parseFloat(coord.puntos.lat),
                                lng: parseFloat(coord.puntos.lng)
                              }}
                              icon={iconos.oleoducto.icono}
                              labelAnchor={new google.maps.Point(0, 2)}
                            >
                              <div
                                style={{
                                  backgroundColor: coord.tipo === 'chequeo' ? 'green' : 'white',
                                  color: coord.tipo === 'chequeo' ? '#fff' : 'black',
                                  fontSize: '7px',
                                  padding: '0 5px',
                                  fontWeight: '800'

                                }}
                              >
                                {coord.id_valv}
                              </div>
                            </MarkerWithLabel>
                    ))}
                  </Fragment>
                ) : ( item.tipo_mapa === 'poligono_fuerza' ? (
                  <Fragment key={index}>
                    <Polygon
                      path={getCoordsPolygon(item.puntos)}
                      // editable = {true}
                      icon={iconos.homicidio.icono }
                      label='holaaaaaaaa'
                      title='holaaaaaaaa'
                      labelContent='holaaaaaaaa'
                      defaultLabel= 'asdfasdf'
                      defaultTitle= 'assdfasdfasdfds'
                      // labelContent
                      labelAnchor={new google.maps.Point(0, 2)}
                      defaultIcon= 'asdfasdf'
                      options={{
                          fillColor: item.color,
                          fillOpacity: item.opacidad ? item.opacidad : 0.3 ,
                          strokeColor: '#2b2601',
                          strokeOpacity: 0.5,
                          strokeWeight: 3,
                          color: 'white',
                          label: 'holaaaaaaaa',
                          text: 'holaaaaaaaa',
                          title: 'holaaaaaaaa',
                          content: 'holaaaaaaaa'
                      }}
                    >
                      <MarkerWithLabel
                        labelAnchor={new google.maps.Point(3, 28)}
                      >
                        <div>
                          <span style={{ backgroundColor:'red', display:'flex', justifyContent:'center', color:'white', fontWeight:'600', fontSize:'12px', textAlign:'center !important'}} >{'asdfsd'}</span>
                        </div>
                      </MarkerWithLabel>
                    </Polygon>


                  </Fragment>
                )


                : null

                )

                )))  )))))}


              </GoogleMap>
              </div>
          </>
  );
}

export default withScriptjs(
  withGoogleMap(
    Map
  )
)

