import React from 'react'
import { Page, Text, View, Document, Image, StyleSheet, PDFViewer, Canvas } from '@react-pdf/renderer';

const Porvenir = () => {

  const styles = StyleSheet.create({
    viewer: {
      height:'81vh'
    },
  })

  return (
    <PDFViewer style={styles.viewer} >
      <Document>
        <Page size="letter" >
          <View>
            <Text>
              holi este es el informe de porvenir
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export {Porvenir};
