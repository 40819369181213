
export const getBase64 = (file) => new Promise((resolve, reject) => {
    if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }
}); 

export const getFile = async (dataurl) => {
    if(typeof(dataurl) === 'string') {
        let arr = dataurl.split(',');
        return arr = arr[1];
    } else{
        const arr = await getBase64(dataurl);
        return arr;
    } 
}
