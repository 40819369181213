import { validarEntorno } from "../helpers/validarHost";

// initial state
const initialState = {
    menu: null
}

const types = {
    cargarMenu: '[Menu] cargar menu',
    cerrarSesion: '[LOGIN] Cerrar Sesion',
}

//reducer
export const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.cargarMenu:

            state = {
                ...state,
                menu: action.payload
            }

            return state

        case types.cerrarSesion:

            state = {
                logged: action.payload.logged,
                msg: "",
                JWT: ""
            }
            return state

        default:
            return state;
    }
}

//actions
export const obtenerMenu = () => {
    return async (dispatch) => {
        // http://127.0.0.1:5500/public/JSON/6.ejemploMenu.json.html
        // https://www.cosinte.co/cia2/JSON/ejemploMenu_json.php
        // https://www.cosinte.co/cia_2/build/JSON/ejemploMenu_json.html

        //const respuesta = await peticionSinToken('http://127.0.0.1:5500/public/JSON/6.ejemploMenu.json.html')
        const respuesta = await validarEntorno('JSON/ejemploMenu_json.html', 'POST', {})

        if (respuesta.ok) {

            const res = await respuesta.json();

            const { logged } = res;

            if (logged) {
                dispatch({
                    type: types.cargarMenu,
                    payload: res
                })
            } else if(logged === false) {
                const respuesta = await validarEntorno('JSON/respuestaLogout_json.html');

                if (respuesta.ok) {
                    const respuestaLogout = await respuesta.json()

                    localStorage.clear()
                    dispatch({
                        type: types.cerrarSesion,
                        payload: respuestaLogout
                    })
                }
            }
        }
    }
}
