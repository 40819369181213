import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Edit, DeleteOutline, Add, Visibility } from '@material-ui/icons';
import Accordion from 'react-bootstrap/Accordion';
import { t } from 'i18next';

/**
 * Componente que muestra un acordeon anidado recursivo
 * @param {Object[]} items Estructura del acordeon
 * @param {Function} onView Función que se ejecuta al hacer click en el botón de ver
 * @param {Function} onAdd Función que se ejecuta al hacer click en el botón de agregar
 * @param {Function} onEdit Función que se ejecuta al hacer click en el botón de editar
 * @param {Function} onDelete Función que se ejecuta al hacer click en el botón de eliminar
 * @param {Object} permisos Permisos del usuario
 * @param {String[]} parentIds Array con los ids de los padres en el árbol
 * @returns {React.Component} NestedAccordion
 */

function NestedAccordion({ items, onView, onAdd, onEdit, onDelete, permisos, parentIds = [], nivel = 1 }) {

  /**
   * Renderiza el header de cada item del acordeon
   */

  const header = (item, parentIds, nivel) => {
    return (
      <span className="flex items-center">
        {item.numeracion && <Badge value={item.numeracion} className="mr-2" severity={'info'} />}
        <span className="white-space-nowrap text-left pl-2 pr-5 flex-1">{item.titulo}</span>
        <div className="flex flex-row items-center ml-auto gap-2">
          <div className="flex items-center gap-2">
            {permisos?.[nivel]?.ver &&
              <Button
                tooltip={permisos?.[nivel]?.ver_l || t("botones.ver")}
                tooltipOptions={{ position: 'top' }}
                icon={<Visibility />}
                rounded
                size='small'
                onClick={(e) => { e.stopPropagation(); onView([...parentIds, item.id], "VER", nivel, permisos?.[nivel]?.ver_l || t("botones.ver")) }} />
            }
            {permisos?.[nivel]?.editar &&
              <Button
                tooltip={permisos?.[nivel]?.editar_l || t("botones.editar")}
                tooltipOptions={{ position: 'top' }}
                icon={<Edit />}
                rounded
                size='small'
                severity='warning'
                onClick={(e) => { e.stopPropagation(); onEdit([...parentIds, item.id], "EDITAR", nivel, permisos?.[nivel]?.editar_l || t("botones.editar")) }} />}
            {permisos?.[nivel]?.eliminar && 
            <Button
              tooltip={permisos?.[nivel]?.eliminar_l || t("botones.eliminar")}
              tooltipOptions={{ position: 'top' }}
              icon={<DeleteOutline />}
              rounded
              size='small'
              severity='danger'
              onClick={(e) => { e.stopPropagation(); onDelete([...parentIds, item.id], "ELIMINAR", nivel, permisos?.[nivel]?.eliminar_l || t("botones.eliminar")) }} />}
            {permisos?.[nivel]?.agregar && 
            <Button
              tooltip={permisos?.[nivel]?.agregar_l || t("botones.agregar")}
              tooltipOptions={{ position: 'top' }}
              icon={<Add />}
              rounded
              size='small'
              severity='success'
              onClick={(e) => { e.stopPropagation(); onAdd([...parentIds, item.id], "AGREGAR", nivel, permisos?.[nivel]?.agregar_l || t("botones.agregar")) }} />}
          </div>
          {item.valor && <Badge value={item.valor} severity={'info'} className='min-w-16' />}
        </div>
      </span>
    )
  }

  return (
    <>
      <Accordion defaultActiveKey="0" className='bg-transparent'>
        {items?.map((item, index) => {
          return (
            <div key={index}>
              <Accordion.Toggle eventKey={index.toString()} className='w-full p-3 rounded-xl my-1 accordion__toggle'>
                {header(item, parentIds, nivel)}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index.toString()}>
                <div className="d-flex flex-row">
                  <div className='accordion__line' />
                  <div className="pl-4 w-100">
                    {item?.items?.map((subitem, index) => {
                      return (
                        <Accordion key={index} defaultActiveKey="0" className='bg-transparent'>
                          <div>
                            <Accordion.Toggle eventKey="0" className='w-full p-3 rounded-xl my-1 accordion__toggle'>
                              {header(subitem, [...parentIds, item.id], nivel + 1)}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <div className="d-flex flex-row">
                                <div className='accordion__line' />
                                <div className="pl-4 w-100">
                                  <NestedAccordion
                                    items={subitem.items}
                                    onView={onView}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                    onAdd={onAdd}
                                    parentIds={[...parentIds, item.id, subitem.id]}
                                    nivel={nivel + 2}
                                    permisos={permisos} />
                                </div>
                              </div>
                            </Accordion.Collapse>
                          </div>
                        </Accordion>
                      )
                    })}
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          )
        })}
      </Accordion>

    </>
  )
}

export default NestedAccordion
