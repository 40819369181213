import * as Icons from '@material-ui/icons';


export function IconoDinamico({ icon, ...props }) {
  const Icon = Icons[icon.tipo];

  if(!Icon) return null; // Si no existe el icono, no se renderiza nada

  return (
    <Icon
      {...props}

      style={{
        color: icon.color ? icon.color : '#213265',
        cursor: 'pointer',
        fontSize: "30px"
      }}
    />
  );
}
