import React from 'react'

export const HelpSVG = (props) => {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" cursor="pointer" width="20.25" height="20.25" viewBox="0 0 24 24">
            <path id="Trazado_97" data-name="Trazado 97" d="M1457.163,129.237a12,12,0,1,0,12,12,11.993,11.993,0,0,0-12-12m0,22.125a10.125,10.125,0,1,1,10.125-10.125A10.119,10.119,0,0,1,1457.163,151.362Zm3.75-12.352a3.757,3.757,0,0,1-2.812,3.631v1.525a.938.938,0,0,1-1.875,0v-2.344a.938.938,0,0,1,.938-.937,1.875,1.875,0,1,0-1.875-1.875.938.938,0,1,1-1.875,0,3.75,3.75,0,0,1,7.5,0Zm-2.578,7.969a1.172,1.172,0,1,1-1.172-1.172A1.172,1.172,0,0,1,1458.335,146.979Z" transform="translate(-1445.163 -129.237)" fill={props.color ? props.color : "#0d2654"} />
        </svg>

    )
}
