import { validarEntorno } from "../helpers/validarHost";



const initialState = {
    beneficiarios: {}
}

// types
const types = {
    agregarBeneficiario: '[Editar Solicitud] agregar beneficiarios',
    eleminarBeneficiario: '[Editar Solicitud] eliminar beneficiario',
    editarBeneficiario: 'ACTUALIZAR_BENEFICIARIO'

}


export const editarSolicitudes = (state = initialState, action) => {

    switch (action.type) {

        case types.agregarBeneficiario:

            state = {
                beneficiarios: [...state.beneficiarios, action.payload]
            }

            return state;
        case types.eleminarBeneficiario:

            const eliminarBeneficiario = state.beneficiarios.filter(ele => ele.identificacion !== action.payload)
            state = {
                ...state,
                beneficiarios: eliminarBeneficiario
            }

            return state;

        case types.editarBeneficiario:

            state = {
                
                beneficiarios: [action.payload]
            }

            return state;



        default:
            return state;
    }
}

export const agregarBeneficiario = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.agregarBeneficiario,
            payload: data
        })
    }
}

export const eliminarBeneficiario = (id) => {
    return (dispatch) => {
        dispatch({
            type: types.eleminarBeneficiario,
            payload: id
        })
    }
}

export const agregarSeguimiento = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.agregarBeneficiario,
            payload: data
        })
    }
}


export const editarBeneficiario = (solicitud) => async (dispatch) => {

    // console.log(solicitud)

    try {
        const updateBeneficiarios = await validarEntorno('JSON/res_editar_beneficiarios.html', 'POST', solicitud)

        // console.log(updateBeneficiarios)

        if(updateBeneficiarios.ok){
            dispatch({
                type:'ACTUALIZAR_BENEFICIARIO',
                payload: await updateBeneficiarios.json()
            })
        }

    } catch (error) {
        alert("Error: " + error.message)
    }


    // return (dispatch) => {

    //     console.log(solicitud)


    //     // const obj = {}
    //     // obj.identificacionTipo = nuevo.identificacionTipo === "" || nuevo.identificacionTipo === undefined ? antiguo.identificacionTipo : nuevo.identificacionTipo
    //     // obj.identificacion = nuevo.identificacion === "" || nuevo.identificacion === undefined ? antiguo.identificacion : nuevo.identificacion
    //     // obj.primerNombre = nuevo.primerNombre === "" || nuevo.primerNombre === undefined ? antiguo.primerNombre : nuevo.primerNombre
    //     // obj.segundoNombre = nuevo.segundoNombre === "" || nuevo.segundoNombre === undefined ? antiguo.segundoNombre : nuevo.segundoNombre
    //     // obj.primerApellido = nuevo.primerApellido === "" || nuevo.primerApellido === undefined ? antiguo.primerApellido : nuevo.primerApellido
    //     // obj.segundoApellido = nuevo.segundoApellido === "" || nuevo.segundoApellido === undefined ? antiguo.segundoApellido : nuevo.segundoApellido
    //     // obj.parentesco = nuevo.parentesco === "" || nuevo.parentesco === undefined ? antiguo.parentesco : nuevo.parentesco
    //     // obj.observaciones = nuevo.observaciones === "" || nuevo.observaciones === undefined ? antiguo.observaciones : nuevo.observaciones

    //     // obj.fechaSolicitud = nuevo.fechaSolicitud === "" || nuevo.fechaSolicitud === undefined ? antiguo.fechaSolicitud : nuevo.fechaSolicitud
    //     // obj.fechaLimite = nuevo.fechaLimite === "" || nuevo.fechaLimite === undefined ? antiguo.fechaLimite : nuevo.fechaLimite
    //     // obj.numeroSop = nuevo.numeroSop === "" || nuevo.numeroSop === undefined ? antiguo.numeroSop : nuevo.numeroSop
    //     // obj.fechaVencimientoProrroga = nuevo.fechaVencimientoProrroga === "" || nuevo.fechaVencimientoProrroga === undefined ? antiguo.fechaVencimientoProrroga : nuevo.fechaVencimientoProrroga
    //     // obj.analista = nuevo.analista === "" || nuevo.analista === undefined ? antiguo.analista : nuevo.analista
    //     // obj.etapa = nuevo.etapa === "" || nuevo.etapa === undefined ? antiguo.etapa : nuevo.etapa
    //     // obj.fechaVencimientoTeorico = nuevo.fechaVencimientoTeorico === "" || nuevo.fechaVencimientoTeorico === undefined ? antiguo.fechaVencimientoTeorico : nuevo.fechaVencimientoTeorico
    //     // obj.numeroRadicado = nuevo.numeroRadicado === "" || nuevo.numeroRadicado === undefined ? antiguo.numeroRadicado : nuevo.numeroRadicado
    //     // obj.fechaInicial = nuevo.fechaInicial === "" || nuevo.fechaInicial === undefined ? antiguo.fechaInicial : nuevo.fechaInicial

    //     // obj.tipoInvestigacion = nuevo.tipoInvestigacion === "" || nuevo.tipoInvestigacion === undefined ? antiguo.tipoInvestigacion : nuevo.tipoInvestigacion
    //     // obj.tipoRiesgo = nuevo.tipoRiesgo === "" || nuevo.tipoRiesgo === undefined ? antiguo.tipoRiesgo : nuevo.tipoRiesgo
    //     // obj.detalleTipoRiesgo = nuevo.detalleTipoRiesgo === "" || nuevo.detalleTipoRiesgo === undefined ? antiguo.detalleTipoRiesgo : nuevo.detalleTipoRiesgo

    //     // obj.direccionUBICA = nuevo.direccionUBICA === "" || nuevo.direccionUBICA === undefined ? antiguo.direccionUBICA : nuevo.direccionUBICA
    //     // obj.telefonoUBICA = nuevo.telefonoUBICA === "" || nuevo.telefonoUBICA === undefined ? antiguo.telefonoUBICA : nuevo.telefonoUBICA

    //     // dispatch({
    //     //     type: types.editarBeneficiario,
    //     //     payload: {
    //     //         idAnt: antiguo.identificacion,
    //     //         obj
    //     //     }
    //     // })


    // }
}

export const editarPreguntasAuditoria = async (data) => {

    try {
        // const updateAuditorias = await validarEntorno('http://192.168.0.8:3001/modify_json', 'POST', data)
    } catch (error) {
        console.log(error);
    }
}