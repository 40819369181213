import React from 'react';
import { Fragment } from 'react';
import { getBase64 } from '../../../helpers/fileToBase64';
import { Form } from 'react-bootstrap';
import { DropzoneArea } from 'material-ui-dropzone'
import './FormObs.scss';

const FormCuesObs = ({ data, setData, validatedFile, setValidatedFile }) => {

    const handleChangeData = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    }

    const handleImageData = async (file) => {
        let imageB64 = '';

        if(file.length > 0) {
            imageB64 = await getBase64(file[0]);
            
            setData({
                ...data,
                imagen: imageB64
            });

            setValidatedFile(false);
        }
    }

    const deleteImageData = (file) => {
        setData({
            ...data,
            imagen: ''
        });
    }

    return (
        <Fragment>
            <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Descripción</Form.Label>
                <Form.Control 
                    as="textarea" 
                    rows={3}
                    name="descripcion"
                    onChange={handleChangeData}
                    className="audit-form-textarea"
                    required
                />
                <Form.Control.Feedback type="invalid">Este campo es obligatorio</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-modal-audit"  controlId="exampleForm.ControlTextarea1">
                <Form.Label>Imagen</Form.Label>
                <DropzoneArea 
                    acceptedFiles={['image/*']}
                    dropzoneText="Arrastra y suelta una imagen o haz click aqui" 
                    name="imagen"
                    onChange={(file) => handleImageData(file)}
                    filesLimit={1}
                    showAlerts={false}
                    onDelete={(file) => { deleteImageData(file) }}
                    dropzoneClass={validatedFile ? 'validated-dropzone' : null}
                />
                {validatedFile ? (<p className="invalid-feedback">Este campo es obligatorio</p>) : null}    
            </Form.Group>
            <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Pie de imagen</Form.Label>
                <Form.Control 
                    as="textarea" 
                    rows={3}
                    name="pie_imagen"
                    onChange={handleChangeData}
                    className="audit-form-textarea"
                    required
                />
                <Form.Control.Feedback type="invalid">Este campo es obligatorio</Form.Control.Feedback>
            </Form.Group>
        </Fragment>
    );
}

export default FormCuesObs;