import React from 'react'

export const DeinedSVG = ({ fill, width, height, color }) => {

    return (
        <svg xmlns="https://www.w3.org/2000/svg" fill={color ? color : "#213265"} width={width === undefined ? "18" : width} height={height === undefined ? "18" : height} viewBox="0 0 26.429 26.429">
            <path id="Icon_ionic-md-close-circle" data-name="Icon ionic-md-close-circle" d="M16.589,3.375A13.214,13.214,0,1,0,29.8,16.589,13.169,13.169,0,0,0,16.589,3.375ZM23.2,21.347l-1.85,1.85-4.757-4.757L11.832,23.2l-1.85-1.85,4.757-4.757L9.982,11.832l1.85-1.85,4.757,4.757,4.757-4.757,1.85,1.85-4.757,4.757Z" transform="translate(-3.375 -3.375)" fill={fill=== undefined ?"#cc1a3a": fill} />
        </svg>

    )
}
