import React from 'react'
import './LoaderButton.scss'

export default function LoaderButton({ text }) {
  return (
    <div className='loader_button_container'>
      <svg
        class="ring"
        viewBox="25 25 50 50"
        stroke-width="5"
      >
        <circle cx="50" cy="50" r="20" />
      </svg>
      {text && <span>{text}</span>}
    </div>
  )
}
