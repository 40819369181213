import React, {useState, useEffect} from 'react'

import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import {AMCHART} from '../../helpers/amchartLicense' 


am4core.useTheme(am4themes_animated);

export default function TortadeTorta(props) {

  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')
  // am4core.addLicense(AMCHART);


  useEffect(() => {
    let container = am4core.create(props.id, am4core.Container);
    container.width = am4core.percent(90);
    container.height = am4core.percent(100);
    container.layout = "horizontal";


    let chart = container.createChild(am4charts.PieChart3D);

    chart.responsive.enabled = true;

    // Add data
    chart.data = props.data ? props.data : [
      {
        "country": "Centro",
        "litres": 90,
        "subData": [{ name: "Finalizados", value: 69 }, { name: "En proceso", value: 10 }, { name: "Cancelados", value: 11 }]
      }, {
        "country": "Norte",
        "litres": 1,
        "subData": [{ name: "Finalizados", value: 1 }]
      }, {
        "country": "Grandes cuentas",
        "litres": 1,
        "subData": [{ name: "Cancelados", value: 1 }]
      }, {
        "country": "Recursos humanos",
        "litres": 13,
        "subData": [{ name: "Finalizados", value: 11 }, { name: "En proceso", value: 2 }]
      }, {
        "country": "pp other",
        "litres": 10,
        "subData": [{ name: "Finalizados", value: 9 }, { name: "En proceso", value: 1 }]
      }
  
  ];

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries3D());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
    pieSeries.labels.template.text = "[bold]{category}: {value.percent.formatNumber('.')}%";
    pieSeries.slices.template.tooltipText = "{category}: {value.formatNumber('###')}"
    pieSeries.colors.list = [
      am4core.color("#2c5e99"),
      am4core.color("#05a4db"),
      am4core.color("#009688"),
      am4core.color("#8BC34A"),
      am4core.color("#F9CF00")
    ];

    pieSeries.slices.template.events.on("hit", function(event) {
      selectSlice(event.target.dataItem);
    })

    let chart2 = container.createChild(am4charts.PieChart);
    chart2.width = am4core.percent(30);
    chart2.radius = am4core.percent(80);

    // Add and configure Series
    let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "value";
    pieSeries2.dataFields.category = "name";
    pieSeries2.slices.template.states.getKey("active").properties.shiftRadius = 0;
    pieSeries2.labels.template.text = "[bold]{category}: {value.percent.formatNumber('.')}%";
    pieSeries2.slices.template.tooltipText = "{category}: {value.formatNumber('###')}"


    //pieSeries2.labels.template.radius = am4core.percent(50);
    //pieSeries2.labels.template.inside = true;
    //pieSeries2.labels.template.fill = am4core.color("#ffffff");
    pieSeries2.labels.template.disabled = false;
    pieSeries2.ticks.template.disabled = true;
    pieSeries2.alignLabels = false;
    pieSeries2.events.on("positionchanged", updateLines);

    // let rgm = new am4core.RadialGradientModifier();
    // rgm.brightnesses.push( -0.5, 0, -0.5);
    // pieSeries.slices.template.fillModifier = rgm;
    // pieSeries.slices.template.strokeModifier = rgm;

    let interfaceColors = new am4core.InterfaceColorSet();

    let line1 = container.createChild(am4core.Line);
    line1.strokeDasharray = "2,2";
    line1.strokeOpacity = 0.5;
    line1.stroke = interfaceColors.getFor("alternativeBackground");
    line1.isMeasured = false;

    let line2 = container.createChild(am4core.Line);
    line2.strokeDasharray = "2,2";
    line2.strokeOpacity = 0.5;
    line2.stroke = interfaceColors.getFor("alternativeBackground");
    line2.isMeasured = false;

    let selectedSlice;

    function selectSlice(dataItem) {

      selectedSlice = dataItem.slice;

      let fill = selectedSlice.fill;

      let count = dataItem.dataContext.subData.length;
      pieSeries2.colors.list = [];
      for (var i = 0; i < count; i++) {
        pieSeries2.colors.list.push(fill?.brighten(i * 2 / count));
      }

      chart2.data = dataItem.dataContext.subData;
      pieSeries2.appear();

      let middleAngle = selectedSlice.middleAngle;
      let firstAngle = pieSeries.slices.getIndex(0).startAngle;
      let animation = pieSeries.animate([{ property: "startAngle", to: firstAngle - middleAngle }, { property: "endAngle", to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut);
      animation.events.on("animationprogress", updateLines);

      selectedSlice.events.on("transformed", updateLines);

    //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
    //  animation.events.on("animationprogress", updateLines)
    }


    function updateLines() {
      if (selectedSlice) {
        let p11 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle) };
        let p12 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc) };

        p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
        p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

        let p21 = { x: 0, y: -pieSeries2.pixelRadius };
        let p22 = { x: 0, y: pieSeries2.pixelRadius };

        p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
        p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

        line1.x1 = p11.x;
        line1.x2 = p21.x;
        line1.y1 = p11.y;
        line1.y2 = p21.y;

        line2.x1 = p12.x;
        line2.x2 = p22.x;
        line2.y1 = p12.y;
        line2.y2 = p22.y;
      }
    }

    chart.events.on("datavalidated", function() {
      setTimeout(function() {
        selectSlice(pieSeries.dataItems.getIndex(0));
      }, 1000);
    });

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "Imagen" },
        // { "type": "csv", "label": "Tabla" },
        { "type": "print", "label": "Imprimir" }
      ]
    }];

  }, [])

  return (
    <Card className="shadow cardGraficaGeneral cardGraficaAnalistas cardDivergente">
     {/*  <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br/>
      <br/>
      <Card.Body>

        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> 
        {/* {props.title} 
        <br/> */}
        {/* {props.parametrizado} - {props.periodo} */}
        </h4>
        <br/>
        <div id={props.id} style={{ width: '100%', margin: '0 auto ', height:  props.height ? props.height : 'auto' }}></div>
        <br/>
        <h4 className='pagesTitles' >
          Total: {props.total}
        </h4>

      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
