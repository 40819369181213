
import { useState, useEffect } from 'react'
import { Form } from "react-bootstrap";
import { BtnAyuda } from "./BtnAyuda";

function Check({ item, reg, index, idx, error, esEditable }) {
    const [checked, setChecked] = useState(item.valor)
    useEffect(() => {
        console.log(item, 'Valor de item');
      }, [item]); // Se ejecuta cada vez que `item` cambie

    return (
        <Form.Group style={{ display: 'block', padding: '0px' }}>

            <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
                <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : "" }}>{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
                {item.ayuda &&
                    <BtnAyuda ayuda={item.ayuda} />
                }
            </div>

            <div class="checkbox-wrapper-4">
                <input
                    className='inp-cbx'
                    type='checkbox'
                    checked={checked}
                    name={`asesoria.${index}.${item.nombre}`}
                    id={`asesoria-${index}-${item.nombre}`}
                    key={`${index} - ${idx}`}
                    ref={reg}
                    {...reg(`asesoria.${index}.${item.nombre}`)}
                    onChange={() => { setChecked(!checked) }}
                    style={{ border: error ? '1px solid #dc3545' : null }}
                    disabled={esEditable}
                />
                <label className="cbx" htmlFor={`asesoria-${index}-${item.nombre}`} >
                    <span>
                        <svg width="12px" height="10px">
                            <use href="#check-4"></use>
                        </svg>
                    </span>
                </label>
                <svg className="inline-svg">
                    <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </symbol>
                </svg>
            </div>
            {error ?
                (
                    error.type === 'required' ?
                        (
                            <span className="inputsInvalid">
                                Este campo es requerido
                            </span>
                        )
                        : null)
                : null}
        </Form.Group>
    )
}

export default Check