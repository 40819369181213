import React from 'react'

export const PerfilIconSVG = ({width, height}) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width ? width : "200"} height={height ? height : "200"} viewBox="0 0 22 22">
      <g id="Grupo_552" data-name="Grupo 552" transform="translate(-234.379 -44.451)">
        <path id="Trazado_312" data-name="Trazado 312" d="M243.155,54.407a3.864,3.864,0,1,0-3.863-3.863A3.867,3.867,0,0,0,243.155,54.407Zm0-5.8a1.936,1.936,0,1,1-1.936,1.936A1.939,1.939,0,0,1,243.155,48.608Z" transform="translate(2.224 1.009)" fill="#cbd7e6"/>
        <path id="Trazado_313" data-name="Trazado 313" d="M249.771,57.353v.779a.963.963,0,1,0,1.926,0v-.779a4.536,4.536,0,0,0-4.531-4.531h-5.8a4.537,4.537,0,0,0-4.532,4.531v.779a.964.964,0,0,0,1.928,0v-.779a2.608,2.608,0,0,1,2.6-2.6h5.8A2.608,2.608,0,0,1,249.771,57.353Z" transform="translate(1.112 3.789)" fill="#cbd7e6"/>
        <path id="Trazado_314" data-name="Trazado 314" d="M235.342,50.841a.966.966,0,0,0,.965-.965V47.2a.821.821,0,0,1,.821-.821H239.8a.964.964,0,0,0,0-1.928h-2.676a2.751,2.751,0,0,0-2.749,2.749v2.676A.966.966,0,0,0,235.342,50.841Z" transform="translate(0 0)" fill="#cbd7e6"/>
        <path id="Trazado_315" data-name="Trazado 315" d="M239.8,59.66h-2.676a.822.822,0,0,1-.821-.821V56.162a.964.964,0,0,0-1.928,0V58.84a2.75,2.75,0,0,0,2.749,2.747H239.8a.963.963,0,0,0,0-1.926Z" transform="translate(0 4.865)" fill="#cbd7e6"/>
        <path id="Trazado_316" data-name="Trazado 316" d="M248.765,44.452h-2.676a.964.964,0,0,0,0,1.928h2.676a.821.821,0,0,1,.821.821v2.676a.964.964,0,1,0,1.928,0V47.2A2.752,2.752,0,0,0,248.765,44.452Z" transform="translate(4.865 0)" fill="#cbd7e6"/>
        <path id="Trazado_317" data-name="Trazado 317" d="M250.55,55.2a.966.966,0,0,0-.965.963V58.84a.822.822,0,0,1-.821.821h-2.676a.963.963,0,0,0,0,1.926h2.676a2.751,2.751,0,0,0,2.749-2.747V56.162A.965.965,0,0,0,250.55,55.2Z" transform="translate(4.865 4.865)" fill="#cbd7e6"/>
      </g>
    </svg>
  )
}
