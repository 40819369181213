import { Bar, BarChart, Rectangle, XAxis } from "recharts";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../ui/Card';
import { ChartContainer } from "../../ui/Chart";
import styles from './CardBarSimple.module.scss';

export default function CardBarSimple() {
  return (
    <Card className={styles.card}>
      <CardHeader className={styles.card__header}>
        <CardTitle className={styles.card__title}>Walking Distance</CardTitle>
        <CardDescription className={styles.card__description}>
          Over the last 7 days, your distance walked and run was 12.5 miles per
          day.
        </CardDescription>
      </CardHeader>
      <CardContent className={styles.card__content}>
        <div className={styles.card__value}>
          12.5
          <span className={styles.card__value__unit}>miles/day</span>
        </div>
        <ChartContainer
          config={{
            steps: {
              label: "Steps",
              color: "hsl(var(--chart-1))",
            },
          }}
          className={styles.chart__container}
        >
          <BarChart
            accessibilityLayer
            margin={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            data={[
              {
                date: "2024-01-01",
                steps: 2000,
              },
              {
                date: "2024-01-02",
                steps: 2100,
              },
              {
                date: "2024-01-03",
                steps: 2200,
              },
              {
                date: "2024-01-04",
                steps: 1300,
              },
              {
                date: "2024-01-05",
                steps: 1400,
              },
              {
                date: "2024-01-06",
                steps: 2500,
              },
              {
                date: "2024-01-07",
                steps: 1600,
              },
            ]}
          >
            <Bar
              dataKey="steps"
              fill="var(--blue-500)"
              radius={2}
              fillOpacity={0.2}
              activeIndex={6}
              activeBar={<Rectangle fillOpacity={0.8} />}
            />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={4}
              hide
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}

