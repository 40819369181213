import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../helpers/validarHost';
import { Card, Row, Col, CardDeck, Form } from 'react-bootstrap';
import EditModal from '../../modales/EditModal';
import NotificationModal from '../../modales/NotificationModal';
import Select from 'react-select';
import Loader from '../../icons/Loader';
import { useForm,  Controller } from 'react-hook-form';

import './AsignacionAud.scss'
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';



export default function AsignacionAud() {

  const {id} = useParams()
  const  { control } = useForm();
  const [idArea, setIdArea] = useState('');
  const [indexAs, setIndexAs] = useState('');
  const [initialState, setInitialState] = useState({
    loading: true,
    data: {
      areas: []
    },
    error: null
  })

  const [personalSelected , setPersonalSelected ] = useState(null);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [modal, setModal] = useState({
      type: '',
      message: '',
      show: false
  });

  const getAsignaciones = async () => {

    const dataReq = {
      hash: window.location.hash,
      id: id
    }

    const req = await validarEntorno('JSON/auditorias/asignacion_aud.html ', 'POST', dataReq)

    if(req.ok){

      const res = await req.json()
      // console.log(res)

      setInitialState({
        ...initialState,
        loading: false,
        data: {
          areas: res.areas
        },
        error: null
      })

      pushAreas(res.areas);

    }
  }

  const pushAreas = (areas) => {

    const areasSend = [];

    areas.map((areas, index) => {
        const obj = {
          id_area: areas.id,
          valores: areas.valores
        }

        areasSend.push(obj);
    });

    setPersonalSelected(areasSend);
  }

  const getPersonal = (idArea, value) => {

    const arr = [...personalSelected];

    const { action } = value

    if (action === 'select-option') {

      const item = {...arr[indexAs]};

      item.valores.push(value.option);

      arr[indexAs] = item;

      setPersonalSelected(arr);

    } else if (action === 'remove-value') {

      const { id } = value.removedValue;

      const item =  {...arr[indexAs]};

      const valores = [...item.valores];

      const pos = valores.findIndex((element) => {
          return element.id === id
      });

      valores.splice(pos, 1);

      item.valores = valores;

      arr[indexAs] = item;

      setPersonalSelected(arr);
    }
  }

  const sendData = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: id,
      dataForm: {
        personal: personalSelected,
        fecha:  moment(startDate).format('YYYY-MM-DD HH:mm:ss')
      }
    }

    const req = await validarEntorno('JSON/auditorias/respuesta_envio_asignacion_aud.html ', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();
      if (res.estado === 'OK') {
        setModal({
          type: 'successGestion',
          message: res.mensaje,
          show: true
        });

      } else {
        setModal({
          type: 'falloPeticionesGestion',
          message: res.mensaje,
          show: true
        });
      }
    } else {
      setModal({
        type: 'falloPeticionesGestion',
        message: "Un error ha ocurrido, intente nuevamente",
        show: true
      });
    }
  }

  const handleCloseModal = () => {
    setModal({
      type: '',
      message: '',
      show: false
    })
  }

  useEffect(() => {
    getAsignaciones()
  }, [])

  return (
    <Card className='shadow cardGeneral' >
      <Card.Body>
        {initialState.loading === true && initialState.error === null
          ?
          <Loader />
          :
          <main>
            {/* <h1 className='pagesTitles' >Asignaciones por área de diagnóstico</h1> */}
            {/* <br/> */}
            <br/>
            {initialState?.data?.areas.map((area, index) => {
              return(
                <div className='itemAreaAsignacion' key={index} onMouseEnter={() => setIdArea(area.id), () => setIndexAs(index)} >
                  <p id='nombreArea' >{area.nombre}</p>
                  <Select
                      // id='selectPersonalGerencia'
                      // ref={reg}
                      // classNamePrefix="addl-class"
                      options={area.valores_opcion}
                      // name={`${nombre}.${item.nombre}`}
                      defaultValue={area.valores}
                      // value={item.valores_opcion.find(c => c.value === value)}
                      // onChange={(e) => onChange(getMultiples(e))}
                      onChange={ getPersonal }
                      closeMenuOnSelect={false}
                      isMulti= {true}
                      placeholder='-- Seleccione --'
                      // isDisabled={esEditable}
                  />
                  {/* <div className='buttonContainerArea'>
                    <button className='confirmButton'  >Guardar</button>
                  </div> */}
                </div>
              )
            })
            }
            <div className='itemAreaAsignacion fecha'>
              <Form.Group>
                <Form.Label id='nombreArea' style={{ marginTop: '-43px'}}>Fecha</Form.Label>
                <br />
                <ReactDatePicker 
                  selected={moment(startDate).format('YYYY-MM-DD HH:mm:ss')}
                  onChange={
                      (date) => {
                          setStartDate(moment(date).toDate())
                      }
                  }
                  showTimeInput
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  name="fecha"
                  // ref={reg}
                  // {...reg(`asesoria.${index}.${item.nombre}`, {
                  //     required: item.es_requerido
                  // })}
                  className="datepicker-form"
                  selected={startDate}
                  // disabled={esEditable}
              />
              </Form.Group>
            </div>
            <div className='buttonContainerArea'>
              <button className='confirmButton' onClick={() => sendData()}>Finalizar</button>
            </div>
            <NotificationModal
                isOpen={modal.show}
                setIsOpen={handleCloseModal}
                typeModal={modal.type}
                mensaje={modal.message}
            />
          </main>
        }
      </Card.Body>
    </Card>
  )
}
