import React, { Fragment, useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Card, Form } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import NotificationModal from '../../../modales/NotificationModal'
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../../helpers/validarHost';
import '../../BeneficiarioEditarEstudio.scss';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EditModal from '../../../modales/EditModal'
import { useSelector } from 'react-redux';
// import { editarBeneficiario } from '../../../../reducers/editSolicitudes';
// import { blue } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';




export const BeneficiariosPestaña = ({ ele }) => {
  const { t } = useTranslation();
  const { id } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [formState, setFormState] = useState()
  const [validated, setValidated] = useState(false)
  const [formEdit, setFormEdit] = useState({
    idSolicitud: id,
    hash: window.location.hash,
    identificacion: '',
    tipo_identificacion: '2',
    primerNombre: '',
    segundoNombre: '',
    primerApellido: '',
    segundoApellido: '',
    parentesco: '',
    observaciones: ''
  })
  const [modalAgregarConfirmacion, setModalAgregarConfirmacion] = useState(false)
  const [notificacionBeneficiarioEliminado, setNotificacionBeneficiarioEliminado] = useState(false)
  const [notificacionEditBeneficiario, setNotificacionEditBeneficiario] = useState(false)

  const [beneficiarioEditar, setBeneficiarioEditar] = useState({

  })

  const [idEliminado, setIdEliminado] = useState('')

  const beneficiarioEliminar = (beneficiario) => {
    setDeleteModal(true)
    setIdEliminado(beneficiario.identificacion)
  }

  // const [tipoIDValue, setTipoIDValue] = useState({ tipo_identificacion: '' })
  const [editModal, setEditModal] = useState(false)
  // const dispatch =
  const [editRows, setEditRows] = useState(false)
  const [beneficiariosArr, setBeneficiariosArr] = useState([])
  const [dataSolicitud, setDataSolicitud] = useState({
    idSolicitud: id,
    hash: window.location.hash,
    pestaña: ele.pestana
  })

  const [primerNombreVal, setPrimerNombreVal] = useState('')
  const [segundoNombreVal, setSegundoNombreVal] = useState('')
  const [primerApellidoVal, setPrimerApellidoVal] = useState('')
  const [SegundoApellidoVal, setSegundoApellidoVal] = useState('')


  useEffect(() => {

    validarEntorno('JSON/gestion_soliciutd_creada_clientes_beneficiarios_json.html', 'POST', dataSolicitud)
      .then(resp => {
        resp.json()
          .then(dataRespuesta => {
            setBeneficiariosArr(dataRespuesta.data)
          })
      })
  }, [])





  const handleInputChangeEdit = ({ target }) => {

    if (target?.name === 'primerNombre' || target?.name === 'segundoNombre' || target?.name === 'primerApellido' || target?.name === 'segundoApellido') {

      // console.log(e.target.name)

      const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑÁÓÍÉÚ ]/g)

      const validado = reg1.test(target.value)

      // console.log(validado + ' validado')

      if (!validado) {

        // console.log(target?.value)

        if (target.name === 'primerNombre') {
          setPrimerNombreVal(target.value)
        } else if (target?.name === 'segundoNombre') {
          setSegundoNombreVal(target.value)
        } else if (target?.name === 'primerApellido') {
          setPrimerApellidoVal(target.value)
        } else if (target?.name === 'segundoApellido') {
          setSegundoApellidoVal(target.value)
        }

        setFormEdit({
          ...formEdit,
          [target.name]: target.value,
        })

      }
    } else {
      setFormEdit({
        ...formEdit,
        [target.name]: target.value
      })

      // console.log(formEdit)
    }

    validarCampos()
  }


  const onSubmit = async (e) => {

    e.preventDefault()

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }
    setValidated(true)


    if (form.checkValidity() === true) {

      setFormEdit({
        ...formEdit,
        idSolicitud: id,
        hash: window.location.hash,
        pestaña: ele.pestana
      })



      setModalAgregarConfirmacion(true)

      const respuesta = await validarEntorno('JSON/gestion_solicitud_creada_cliente_beneficiarios_agregar_json.html', 'POST', formEdit)

      if (respuesta.ok) {

        const tablaActualizada = await respuesta.json()

        setBeneficiariosArr(tablaActualizada.data)

        // const
      }

      // const body = await respuesta.json();


      // setBeneficiariosArr(body)
      // setModalAgregarConfirmacion(true)

    }

  }

  const cleanForm = () => {
    const primerNombre = document.getElementById("primerNombre");
    const segundoNombre = document.getElementById("segundoNombre");
    const primerApellido = document.getElementById("primerApellido");
    const segundoApellido = document.getElementById("segundoApellido");
    const tipoIdentificacion = document.getElementById("tipoIdentificacion");
    const identificacion = document.getElementById("identificacion");
    const parentesco = document.getElementById("parentesco");
    const observaciones = document.getElementById("observaciones");

    setPrimerNombreVal('');
    setSegundoNombreVal('');
    setPrimerApellidoVal('');
    setSegundoApellidoVal('');

    setFormEdit({
      idSolicitud: id,
      hash: window.location.hash,
      identificacion: '',
      tipo_identificacion: '2',
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      parentesco: '',
      observaciones: ''
    });

    primerNombre.value = "";
    segundoNombre.value = "";
    primerApellido.value = "";
    segundoApellido.value = "";
    tipoIdentificacion.value = "2";
    identificacion.value = "";
    parentesco.value = "0";
    observaciones.value = "";
  }

  const closeModal = () => {
    cleanForm();
    setModalAgregarConfirmacion(false);
  }

  // const handleChangeTipoID = ({ target }) => {

  //     setTipoIDValue({
  //         tipo_identificacion: target.value
  //     })

  //     setFormEdit({ ...formEdit, tipo_identificacion: tipoIDValue.tipo_identificacion })
  // }

  const validarCampos = () => {
    if (formEdit.identificacion && formEdit.primer_nombre && formEdit.primer_apellido && formEdit.parentesco !== "") {
      setValidated(true)
    }
  }



  // acciones de tabla
  const handleEdit = (ele, index) => {


    if (editRows[1] === index) {
      setEditRows(false)
    } else {
      setEditRows([ele, index])
      setBeneficiarioEditar({
        ...beneficiarioEditar,
        id: ele.id,
        identificacion: ele.identificacion,
        tipo_identificacion: ele.tipo_identificacion,
        primerNombre: ele.primer_nombre,
        segundoNombre: ele.segundo_nombre,
        primerApellido: ele.primer_apellido,
        segundoApellido: ele.segundo_apellido,
        parentesco: ele.parentesco,
        observaciones: `${ele.observaciones}`
      })

      setPrimerNombreVal(ele.primer_nombre);
      setSegundoNombreVal(ele.segundo_nombre);
      setPrimerApellidoVal(ele.primer_apellido);
      setSegundoApellidoVal(ele.segundo_apellido);

      setFormEdit({
        ...formEdit,
        hash: window.location.hash,
        idSolicitud: id,
        id: ele.id,
        identificacion: ele.identificacion,
        tipo_identificacion: ele.tipo_identificacion,
        primerNombre: ele.primer_nombre,
        segundoNombre: ele.segundo_nombre,
        primerApellido: ele.primer_apellido,
        segundoApellido: ele.segundo_apellido,
        parentesco: ele.parentesco,
        observaciones: ele.observaciones
      })


      setEditModal(true)
    }
  }

  const handleEliminar = async (idBeneficiarioEliminado) => {


    const data = {
      hash: window.location.hash,
      idBeneficiario: idBeneficiarioEliminado,
      idSolicitud: id
    }

    const respuesta = await validarEntorno('JSON/gestion_solicitud_creada_cliente_beneficiarios_agregar_json.html', 'POST', data)

    if (respuesta.ok) {
      const tabla = await respuesta.json()
      setBeneficiariosArr(tabla.data)
    }

    setNotificacionBeneficiarioEliminado(true)


    /*  dispatch(eliminarBeneficiario(id))
     setDeleteModal(false) */
  }

  const dataActualizada = useSelector(store => store.edicionSolicitud)

  const validarDataEditar = () => {

    let isValid = true;

    const data = { ...formEdit };

    if (data.primerNombre && data.segundoNombre && data.primerApellido && data.segundoApellido !== '') {

      const nombres = {
        primerNombre: data.primerNombre,
        segundoNombre: data.segundoNombre,
        primerApellido: data.primerApellido,
        segundioApellido: data.segundoApellido
      }

      const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑ ]/g);

      for (const [clave, valor] of Object.entries(nombres)) {
        const band = reg1.test(valor);
        // console.log(band);
      }
    }

    return isValid;

  }


  const handleEditElement = async (ele) => {

    const beneficiarioEditado = {
      ...formEdit,
    }
    // hash: window.location.hash,
    // idSolicitud: id
    // idBeneficiario: beneficiarioEditar.identificacion,

    const band = validarDataEditar();

    if (band) {
      const updateBeneficiarios = await validarEntorno('JSON/res_editar_beneficiarios.html', 'POST', formEdit)

      if (updateBeneficiarios.ok) {
        const res = await updateBeneficiarios.json()
        setBeneficiariosArr(res.data)
        setNotificacionEditBeneficiario(true)
      }

      setEditRows(false)
      setFormEdit({

        identificacion: '',
        tipo_identificacion: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        parentesco: '',
      })


      // setBeneficiariosArr(dataActualizada?.beneficiarios[0].data)

      setEditModal(false)
    } else {
      setNotificacionEditBeneficiario(true)
    }
  }

  const cerrarModales = () => {
    setNotificacionBeneficiarioEliminado(false)
    setDeleteModal(false)
  }


  return (
    <Fragment>
      {

        ele.agregar
        &&
        <>
          <Card className="card__box__surface card__plain__top__left mb-2">
            <Card.Body>
              <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h5 className='titleCard' style={{ color: '#707070', width: '200px', margin: '0px', marginRight: '0px' }}>{t('formulario.label.beneficiarios')}</h5>

                <div className="" style={{ display: 'flex', alignItems: 'center', color: '#fff', background: '#213265', padding: '0 10px ', borderRadius: '40px' }} >
                  <IconButton style={{ padding: '5px' }} onClick={() => {

                    if (ele.options.agregar) {

                      setFormState(!formState)
                    }
                  }}>
                    <p style={{ color: '#fff', height: '100%', margin: '0', fontSize: '1rem' }}>
                      {t('botones.anadir_beneficiario')}

                    </p>
                    <AddIcon fontSize='large' style={{ background: '#213265', borderRadius: '50%', color: 'white' }} />
                  </IconButton>
                </div>
              </div>
              <br />
              <br />
              {
                formState
                &&
                <>
                  <Form className='formAddBeneficiarioContainer' noValidate validated={validated} onSubmit={onSubmit} >
                    <div className='formItem' id='basicFormA'>

                      <Form.Group>
                        <Form.Label>
                          {t('formulario.label.nombres')}
                        </Form.Label>
                        <div className='namesContainer'>
                          <div >
                            <Form.Control
                              value={primerNombreVal}
                              name='primerNombre'
                              required
                              type='text'
                              placeholder={t('formulario.label.primer_nombre')}
                              style={{ marginRight: '10px' }}
                              onChange={handleInputChangeEdit}
                              id="primerNombre"
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_primer_nombre')}</Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Control
                              value={segundoNombreVal}
                              name='segundoNombre'
                              type='text'
                              placeholder={t('formulario.label.segundo_nombre')}
                              onChange={handleInputChangeEdit}
                              id="segundoNombre"
                            ></Form.Control>
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          {t('formulario.label.apellidos')}
                        </Form.Label>
                        <div className='namesContainer'>
                          <div>
                            <Form.Control
                              value={primerApellidoVal}
                              name='primerApellido'
                              required
                              type='text'
                              // className="place"
                              placeholder={t('formulario.label.primer_apellido')} style={{ marginRight: '10px' }}
                              onChange={handleInputChangeEdit}
                              id="primerApellido"
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_primer_apellido')}</Form.Control.Feedback>

                          </div>
                          <div>
                            <Form.Control
                              value={SegundoApellidoVal}
                              name='segundoApellido'
                              type='text'
                              // className="place"
                              placeholder={t('formulario.label.segundo_apellido')}
                              onChange={handleInputChangeEdit}
                              id="segundoApellido"
                            ></Form.Control>
                          </div>
                        </div>
                      </Form.Group>

                    </div>
                    <div className='formItem' id='basicFormB'>
                      <Form.Group>
                        <Form.Label>{t('formulario.label.identificacion')}</Form.Label>
                        <div className='idContainer'>
                          <Form.Control name='tipo_identificacion' id='input_select' as='select' custom
                            required
                            onChange={handleInputChangeEdit}
                            id="tipoIdentificacion"
                          >
                            <option value='2' selected disabled>{t('formulario.label.seleccione_tipo_identificacion')}</option>
                            <option value='2'>{t('formulario.select.cedula_ciudadania')}</option>
                            <option value='3'>{t('formulario.select.cedula_extranjeria')}</option>
                            <option value='4'>{t('formulario.select.tarjeta_identidad')}</option>
                            <option value='9'>{t('formulario.select.registro_civil')}</option>
                            <option value='5'>{t('formulario.select.pasaporte')}</option>
                          </Form.Control>
                          <Form.Control
                            name='identificacion'
                            required
                            type='number'
                            placeholder={t('formulario.label.identificacion')}
                            min='0'
                            max='9999999999'
                            onChange={handleInputChangeEdit}
                            id="identificacion"
                          ></Form.Control>
                          <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_identificacion')}</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('formulario.label.parentesco')}</Form.Label>
                        <div className='inputContainer' style={{ width: '100%' }}>
                          <Form.Group style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Form.Control name='parentesco' id='typeIdSelect'
                              as='select'
                              required
                              className="place" style={{ width: '100%' }}
                              onChange={handleInputChangeEdit}
                              id="parentesco"
                            >
                              <option style={{ display: 'none' }}></option>
                              <option value="0" disabled>{t('formulario.label.seleccione_parentesco')}</option>
                              <option value="Padre/Madre">{t('formulario.select.padre_madre')}</option>
                              <option value="Hijo(a)">{t('formulario.select.hijo')}</option>
                              <option value="Hijo(a) Menor">{t('formulario.select.hijo_menor')}</option>
                              <option value="Hijo(a) Estudiante">{t('formulario.select.hijo_estudiante')}</option>
                              <option value="Hijo(a) invalido">{t('formulario.select.hijo_invalido')}</option>
                              <option value="Hermano(a)">{t('formulario.select.hermano')}</option>
                              <option value="Hermano(a) Invalido">{t('formulario.select.hermano_invalido')}</option>
                              <option value="Conyuge/Compañero(a)">{t('formulario.select.conyuge_companero')}</option>
                              <option value="Herederos indeterminados">{t('formulario.select.herederos_indeterminados')}</option>
                              <option value="Esposo(a)"> {t('formulario.select.esposo')} </option>
                              <option value="Compañero(a)"> {t('formulario.select.companero')} </option>
                              <option value="Otro">{t('formulario.select.otro')}</option>
                              <option value="Hijo(a) de crianza">{t('formulario.select.hijo_crianza')}</option>
                              <option value="Padre de crianza">{t('formulario.select.padre_crianza')}</option>
                              <option value="Madre de crianza">{t('formulario.select.madre_crianza')}</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_parentesco')}</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </Form.Group>
                    </div>
                    <div id='observacionesAddBeneficiario'>
                      <Form.Group id='observacionesFormGroup'>
                        <Form.Label className='observacionesLabel'>
                          {t('formulario.label.observaciones')}
                        </Form.Label>
                        <Form.Control name='observaciones' id='observacionesTextArea' as='textarea'
                          placeholder={t('formulario.label.observaciones')}
                          // className="place"
                          onChange={handleInputChangeEdit}
                          id="observaciones"
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_observaciones')}</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                      <button type="submit" className=' confirmButton' style={{ textAlign: 'center' }} >
                        {t('botones.enviar')}
                      </button>


                    </div>

                  </Form>
                  <NotificationModal isOpen={modalAgregarConfirmacion} setIsOpen={closeModal} typeModal='addBeneficiarioConfirm' />
                </>

              }
            </Card.Body>
          </Card>
          <br />
        </>
      }

      <Card className="card__box__surface " style={{ borderRadius: (ele.editar ? '10px 0' : '10px 10px 10px 10px') }}>
        <Card.Body>

          <div className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <h5 className='pt-4 titleCard' style={{ color: '#707070' }}>{t('formulario.label.lista_beneficiarios')}</h5>
          </div>
          {
            beneficiariosArr.length === 0
              ?
              <div className="container text-center">
                <h1 className='p-4' style={{ color: '#707070' }}>Aún no has agregado beneficiarios</h1>
              </div>
              :
              <div className='tableContainer p-5'>

                <table className='table'>
                  <thead>
                    <tr>
                      <th>{t('formulario.label.identificacion')}</th>
                      <th>{t('formulario.label.primer_nombre')}</th>
                      <th>{t('formulario.label.segundo_nombre')}</th>
                      <th>{t('formulario.label.primer_apellido')}</th>
                      <th>{t('formulario.label.segundo_apellido')}</th>
                      <th>{t('formulario.label.parentesco')}</th>
                      <th>{t('formulario.label.observaciones')}</th>
                      <th>{t('formulario.label.acciones')}</th>
                    </tr>
                  </thead>
                  <tbody>


                    {
                      beneficiariosArr.map((beneficiario, index) => {
                        return <tr key={index}>
                          <td>{
                            beneficiario.tipo_identificacion
                              ?
                              <>
                                {
                                  beneficiario.tipo_identificacion === '2'
                                    ? 'CC ' + beneficiario.identificacion
                                    : beneficiario.tipo_identificacion === '3'
                                      ? 'CE ' + beneficiario.identificacion
                                      : beneficiario.tipo_identificacion === '4'
                                        ? 'TI ' + beneficiario.identificacion
                                        : beneficiario.tipo_identificacion === '5'
                                          ? 'PA ' + beneficiario.identificacion
                                          : beneficiario.tipo_identificacion === '10'
                                          && 'RC ' + beneficiario.identificacion
                                }
                              </>
                              :
                              beneficiario?.identificacion
                          }
                          </td>
                          <td>{beneficiario.primer_nombre}</td>
                          <td>{beneficiario.segundo_nombre}</td>
                          <td>{beneficiario.primer_apellido}</td>
                          <td>{beneficiario.segundo_apellido}</td>
                          <td>{beneficiario.parentesco}</td>
                          <td>{beneficiario.observaciones}</td>
                          <td style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {ele.options.editar
                              &&
                              <div className='editBeneficiarios buttonBeneficiarios' onClick={() => handleEdit(beneficiario, index)} >
                                <EditIcon fontSize='small' />
                                {t('botones.editar')}
                              </div>
                            }
                            {ele.options.eliminar
                              &&
                              <div className='deleteBeneficiarios buttonBeneficiarios' onClick={() => beneficiarioEliminar(beneficiario)}>
                                <DeleteIcon fontSize='small' />
                                {t('botones.eliminar')}
                              </div>
                            }

                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>


          }
        </Card.Body>
      </Card>
      <NotificationModal isOpen={deleteModal} setIsOpen={setDeleteModal} modalConfirmacion={setNotificacionBeneficiarioEliminado} typeModal='deleteBeneficiarioConfirm' handleEliminar={() => handleEliminar(idEliminado)}  ></NotificationModal>
      <NotificationModal isOpen={notificacionBeneficiarioEliminado} setIsOpen={() => cerrarModales()} typeModal='notificacionBeneficiarioEliminado'  ></NotificationModal>
      <NotificationModal isOpen={notificacionEditBeneficiario} setIsOpen={setNotificacionEditBeneficiario} typeModal='notificacionBeneficiarioEditado'  ></NotificationModal>

      <EditModal
        beneficiarioEditar={beneficiarioEditar}
        handleInputChangeEdit={handleInputChangeEdit}
        isOpen={editModal}
        setIsOpen={setEditModal}
        typeModal='EditBeneficiario'
        editHandler={() => handleEditElement(formEdit)}
        primerNombreVal={primerNombreVal}
        segundoNombreVal={segundoNombreVal}
        primerApellidoVal={primerApellidoVal}
        segundoApellidoVal={SegundoApellidoVal}
      >
        {/* <h3 className='pagesTitles'>Editar beneficiario</h3>
                <div className='formEditBeneficiarioContainer'>
                <div>
                <label>Identificación</label>

                </div>
                <div className="" id='idContainerEditBeneficiario'>
                <select
                    defaultValue={beneficiarioEditar.tipo_identificacion}
                    name="tipo_identificacion"
                    onChange={handleInputChangeEdit}
                    className="noneStiles"
                    type='text'
                    >
                    <option value='0' disabled selected>Seleccione un tipo de identificación</option>
                    <option value='2'>Cédula de ciudadanía</option>
                    <option value='3'>Cédula de extranjería</option>
                    <option value='4'>Tarjeta de identidad</option>
                    <option value='10'>Registro civíl</option>
                    <option value='5'>Pasaporte</option>
                </select>
                <input
                    type='number'
                    onChange={handleInputChangeEdit}
                    name="identificacion"
                    defaultValue={beneficiarioEditar.identificacion}
                />
                </div>
                <div className='nombresContenedor'>

                <div className='nombresContenedor'>
                    <Form.Group className='nombresContainerEditBeneficiario'>
                        <label>Nombres</label>
                        <Form.Control
                            placeholder="Primer nombre"
                            type='text'
                            defaultValue={beneficiarioEditar.primerNombre}
                            onChange={handleInputChangeEdit}
                            name="primerNombre"
                        />
                        <Form.Control
                            type='text'
                            placeholder="Segundo nombre"
                            defaultValue={beneficiarioEditar.segundoNombre}
                            onChange={handleInputChangeEdit}
                            name="segundoNombre"
                        />

                    </Form.Group>
                    <Form.Group className='nombresContainerEditBeneficiario'>
                        <label>Apellidos</label>
                        <Form.Control
                            placeholder="Primer apellido"
                            defaultValue={beneficiarioEditar.primerApellido}
                            onChange={handleInputChangeEdit}
                            name="primerApellido"
                        />
                        <Form.Control
                            placeholder="Segundo apellido"
                            defaultValue={beneficiarioEditar.segundoApellido}
                            onChange={handleInputChangeEdit}
                            name="segundoApellido"
                        />
                    </Form.Group>

                </div>
                <label>Parentesco</label>
                <select
                    defaultValue={beneficiarioEditar.parentesco}
                    name="parentesco"
                    onChange={handleInputChangeEdit}
                    className="noneStiles2"
                    required
                    >
                    <option disabled >Parentesco</option>
                    <option value="Padre">Padre</option>
                    <option value="Madre">Madre</option>
                    <option value="Hijo(a)">Hijo(a)</option>
                    <option value="Hijo(a) Menor">Hijo(a) Menor</option>
                    <option value="Hijo(a) Estudiante">Hijo(a) Estudiante</option>
                    <option value="Hijo(a) invalido">Hijo(a) inválido</option>
                    <option value="Hermano(a)">Hermano(a)</option>
                    <option value="Hermano(a) Invalido">Hermano(a) Invalido</option>
                    <option value="Conyuge/Compañero(a)">Conyuge/Compañero(a)</option>
                    <option value="Herederos indeterminados"> Herederos indeterminados </option>
                    <option value="Esposo(a)"> Esposo(a) </option>
                    <option value="Compañero(a)"> Compañero(a) </option>
                    <option value="Otro">Otro</option>
                </select>
                </div>
                </div>
                <div className='formEditBeneficiarioObservacionesContainer'>
                <label>Observaciones</label>
                <textarea
                required
                id='inputObservacionesBeneficiarios'
                defaultValue={beneficiarioEditar.observaciones}
                name="observaciones"
                onChange={handleInputChangeEdit}
                />
                </div> */}
      </EditModal>
    </Fragment>
  )
}
