import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../../../helpers/validarHost';
import { Card, Row, Col, CardDeck, Form } from 'react-bootstrap';
// import EditModal from '../../modales/EditModal';
import NotificationModal from '../../../../modales/NotificationModal';
import Select from 'react-select';
import Loader from '../../../../icons/Loader';
import { useForm, Controller } from 'react-hook-form';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import './AsignacionAud.scss'
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

export default function AsignacionAud({permisos}) {
  const { t } = useTranslation();
  const { id } = useParams()
  const { register, handleSubmit, control } = useForm();
  const [idArea, setIdArea] = useState('');
  const [indexAs, setIndexAs] = useState('');
  const [initialState, setInitialState] = useState({
    loading: true,
    data: {
      areas: []
    },
    error: null
  })

  const [personalSelected, setPersonalSelected] = useState(null);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [modal, setModal] = useState({
    type: '',
    message: '',
    show: false
  });

  const getAsignaciones = async () => {

    const dataReq = {
      hash: window.location.hash,
      id: id
    }

    const req = await validarEntorno('JSON/auditorias/asignacion_aud.html ', 'POST', dataReq)

    if (req.ok) {

      const res = await req.json()
      // //console.log(res)

      setInitialState({
        ...initialState,
        loading: false,
        data: {
          areas: res.formularios
        },
        error: null
      })

      pushAreas(res.formularios);
    }
  }

  const pushAreas = (areas) => {

    const areasSend = [];

    areas.map((areas, index) => {
      const obj = {
        id_area: areas.id,
        valores: areas.valores
      }

      areasSend.push(obj);
    });

    setPersonalSelected(areasSend);
  }

  // const getPersonal = (value) => {
  //   if (value && Object.keys(value).length > 0) {

  //     //console.log(value);

  //   } else {
  //     //console.log('Eliminado');
  //   }
  // }

  const formatedDates = (arr) => {

    const formatedData = [];

    arr.map((item, index) => {
      const keys = Object.keys(item);
      keys.map((key, idx) => {
        if (key.startsWith('fecha') && item[`${key}`]) {
          const str = item[`${key}`];
          const time = moment(str).format('DD-MM-YYYY');
          item[`${key}`] = time;
        }
      })
      formatedData.push(item);
    })

    return formatedData;
  }

  const onSubmit = async (dataform) => {

    const formattedData = formatedDates(dataform.asignacion);

    const data = {};

    data.asignaciones = formattedData;

    const dataReq = {
      hash: window.location.hash,
      id: id,
      dataForm: data
    }

    console.log(dataReq);

    // console.log(dataReq);

    const req = await validarEntorno('JSON/auditorias/respuesta_envio_asignacion_aud.html ', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();
      if (res.estado === 'OK') {
        setModal({
          type: 'successGestion',
          message: res.mensaje,
          show: true
        });

      } else {
        setModal({
          type: 'falloPeticionesGestion',
          message: res.mensaje,
          show: true
        });
      }
    } else {
      setModal({
        type: 'falloPeticionesGestion',
        message: "Un error ha ocurrido, intente nuevamente",
        show: true
      });
    }
  }

  const handleCloseModal = () => {
    setModal({
      type: '',
      message: '',
      show: false
    })
  }

  useEffect(() => {
    getAsignaciones()
  }, [])

  const areasDiag = [
    {
      "id": "1",
      "value": "seguridad_fisica",
      "label": "Seguridad física"
    },
    {
      "id": "2",
      "value": "seguridad_electronica",
      "label": "Seguridad electrónica"
    },
    {
      "id": "3",
      "value": "recursos_humanos",
      "label": "Recursos humanos"
    },
    {
      "id": "4",
      "value": "seguridad_tics",
      "label": "Seguridad tics"
    },
    {
      "id": "5",
      "value": "conflicto",
      "label": "Conflicto"
    },
    {
      "id": "6",
      "value": "SS_SSTA_PESV",
      "label": "SS-SSTA-PESV"
    },
    {
      "id": "7",
      "value": "seguridad_fitozoosanitaria",
      "label": "Seguridad fitozoosanitaria"
    },
  ];

  const handleAddClick = (index) => {
    const asignacion = { ...initialState };
    const data = { ...asignacion.data };
    const areas = [...data.areas];
    const item = { ...areas[index] }
    const acompanantes = [...item.acompanantes];
    acompanantes.push({ nombre: '', cargo: '' });
    item.acompanantes = acompanantes;
    areas[index] = item;
    data.areas = areas;
    asignacion.data = data;
    setInitialState(asignacion);
  };

  const handleRemoveClick = (index, idx) => {
    const asignacion = { ...initialState };
    const data = { ...asignacion.data };
    const areas = [...data.areas];
    const item = { ...areas[index] };
    const acompanantes = [...item.acompanantes];
    acompanantes.splice(idx, 1);
    item.acompanantes = acompanantes;
    areas[index] = item;
    data.areas = areas;
    asignacion.data = data;
    setInitialState(asignacion);
  };

  const handleChangeAcom = (e, index, idx) => {
    const { name, value } = e.target;

    const nameSplit = name.split('.');
    const key = nameSplit[nameSplit.length - 1];
    const asignacion = { ...initialState };
    const data = { ...asignacion.data };
    const areas = [...data.areas];
    const item = { ...areas[index] };
    const acompanantes = [...item.acompanantes];
    const acomp = { ...acompanantes[idx] };
    acomp[`${key}`] = value;
    acompanantes[idx] = acomp;
    item.acompanantes = acompanantes;
    areas[index] = item;
    data.areas = areas;
    asignacion.data = data;
    setInitialState(asignacion);
  };

  return (
    <Card className='shadow cardGeneral' >
      <Card.Body>
        {initialState.loading === true && initialState.error === null
          ?
          <Loader />
          :
          <main>
            <h4 style={{ textAlign: 'left' }} className='pagesTitles' >{t('gestion_realizada.introduccion_informe.asignacion')}</h4>
            {/* <br/> */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              {initialState?.data?.areas.map((area, index) => {

                const nombre = `asignacion.${index}`;

                return (
                  <>
                    <input name={`${nombre}.id`} type="hidden" value={area.id} ref={register} />
                    <div className='itemAreaAsignacion' key={index} onMouseEnter={() => setIdArea(area.id), () => setIndexAs(index)} >
                      {/* <p id='nombreArea' >{area.nombre}</p> */}
                      <Row>
                        <Col xs={6}>
                          <Form.Label>{t('formulario.label.area_diagnostico')}</Form.Label>
                          <Controller
                            control={control}
                            name={`${nombre}.area_diagnostico`}
                            ref={register}
                            defaultValue={area.valor_areas}
                            render={({ onChange, onBlur, value, ref }) => (
                              <Select
                                // id='selectPersonalGerencia'
                                // classNamePrefix="addl-class"
                                options={areasDiag}
                                // name={`${nombre}.area_diagnostico`}
                                defaultValue={value}
                                // value={item.valores_opcion.find(c => c.value === value)}
                                // onChange={(e) => onChange(getMultiples(e))}
                                onChange={onChange}
                                closeMenuOnSelect={true}
                                // isMulti= {true}
                                placeholder={'formulario.label.seleccione'}
                                isClearable={true}
                                isDisabled={true}
                              />
                            )}
                          />
                          {/* <div className='buttonContainerArea'>
                            <button className='confirmButton'  >Guardar</button>
                          </div> */}
                        </Col>
                        <Col xs={6}>
                          <Form.Label>{t('formulario.label.analista')}</Form.Label>
                          <Controller
                            control={control}
                            name={`${nombre}.analista`}
                            ref={register}
                            defaultValue={area.valores}
                            render={({ onChange, onBlur, value, ref }) => (
                              <Select
                                // id='selectPersonalGerencia'
                                // classNamePrefix="addl-class"
                                options={area.valores_opcion}
                                name={`${nombre}.analista`}
                                defaultValue={value}
                                // value={item.valores_opcion.find(c => c.value === value)}
                                // onChange={(e) => onChange(getMultiples(e))}
                                onChange={onChange}
                                closeMenuOnSelect={true}
                                isMulti= {true}
                                placeholder={t('formulario.label.seleccione')}
                                isClearable={true}
                              // isDisabled={esEditable}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='itemAreaAsignacion fecha'>
                      <Row>
                        <Col xs={6}>
                          {/* <Form.Label id='nombreArea' style={{ marginTop: '-43px'}}>Fecha</Form.Label> */}
                          <Form.Label>{t('formulario.label.fecha_inicial_asignacion')}</Form.Label>
                          <br />
                          <Controller
                            control={control}
                            name={`${nombre}.fecha_inicial`}
                            defaultValue={area.fecha_inicial !== "" ? moment(area.fecha_inicial).toDate() : null}
                            ref={register}
                            render={({ onChange, onBlur, value, ref }) => (
                              <ReactDatePicker
                                selected={value}
                                onChange={onChange}
                                dateFormat="yyyy-MM-dd"
                                name={`${nombre}.fecha_inicial`}
                                // {...reg(`asesoria.${index}.${item.nombre}`, {
                                //     required: item.es_requerido
                                // })}
                                isClearable={true}
                                clearButtonClassName="button-close-timer"
                                className="datepicker-form"
                              // disabled={esEditable}
                              />
                            )}
                          />
                        </Col>
                        <Col xs={6}>
                          {/* <Form.Label id='nombreArea' style={{ marginTop: '-43px'}}>Fecha</Form.Label> */}
                          <Form.Label>{t('formulario.label.fecha_limite')}</Form.Label>
                          <br />
                          <Controller
                            control={control}
                            name={`${nombre}.fecha_limite`}
                            defaultValue={area.fecha_limite !== "" ? moment(area.fecha_limite).toDate() : null}
                            ref={register}
                            render={({ onChange, onBlur, value, ref }) => (
                              <ReactDatePicker
                                selected={value}
                                onChange={onChange}
                                dateFormat="yyyy-MM-dd"
                                name={`${nombre}.fecha_limite`}
                                ref={register}
                                // {...reg(`asesoria.${index}.${item.nombre}`, {
                                //     required: item.es_requerido
                                // })}
                                isClearable={true}
                                className="datepicker-form"
                                clearButtonClassName="button-close-timer"
                              // disabled={esEditable}
                              />
                            )}
                          />
                        </Col>
                        <Col style={{ marginTop: '1em' }} xs={6}>
                          <Form.Label>{t('formulario.label.fecha_finalizacion')}</Form.Label>
                          <br />
                          <Controller
                            control={control}
                            name={`${nombre}.fecha_finalizacion`}
                            defaultValue={area.fecha_finalizacion !== "" ? moment(area.fecha_finalizacion).toDate() : null}
                            render={({ onChange, onBlur, value, ref }) => (
                              <ReactDatePicker
                                selected={value}
                                onChange={onChange}
                                dateFormat="yyyy-MM-dd"
                                name={`${nombre}.fecha_finalizacion`}
                                ref={register}
                                // {...reg(`asesoria.${index}.${item.nombre}`, {
                                //     required: item.es_requerido
                                // })}
                                isClearable={true}
                                className="datepicker-form"
                                clearButtonClassName="button-close-timer"
                              // disabled={esEditable}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='itemAreaAsignacion'>
                      <Form.Label>{t('formulario.label.acompanante')}</Form.Label>
                      <Row>
                        {area.acompanantes.length > 0 ? area.acompanantes.map((acom, idx) => (
                          <>
                            <Col xs={6}>
                              <Form.Group style={{ display: 'block' }}>
                                <Form.Control
                                  name={`${nombre}.acompanante.${idx}.cargo`}
                                  value={acom.cargo}
                                  placeholder={t('formulario.label.acompanante')}
                                  ref={register}
                                  onChange={(e) => handleChangeAcom(e, index, idx)}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <Form.Group style={{ display: 'block' }}>
                                <Form.Control
                                  name={`${nombre}.acompanante.${idx}.nombre`}
                                  value={acom.nombre}
                                  placeholder={t('formulario.label.nombre')}
                                  ref={register}
                                  onChange={(e) => handleChangeAcom(e, index, idx)}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index, idx)} />
                              {area.acompanantes.length - 1 === idx && <AddCircleIcon className="icon-gray add" onClick={() => handleAddClick(index)} />}
                            </Col>
                          </>
                        )) : (
                          <Col xs={12}>
                            <br />
                            <AddCircleIcon className="icon-gray add" onClick={() => handleAddClick(index)} />
                          </Col>
                        )}
                      </Row>
                    </div>
                    <hr />
                  </>
                )
              })
              }
              <div className='buttonContainerArea'>
                {permisos?.editar!==false &&   <button className='confirmButton'>{t('formulario.actions.guardar')}</button>}
              </div>
            </Form>
            <NotificationModal
              isOpen={modal.show}
              setIsOpen={handleCloseModal}
              typeModal={modal.type}
              mensaje={modal.message}
            />
          </main>
        }
      </Card.Body>
    </Card>
  )
}
