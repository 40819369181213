import React from "react";
import { Bar, BarChart, CartesianGrid, XAxis, LabelList } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "../../ui/Chart";

function BarChartComponent({ data, dataKey = "valor", nameKey = "x", maxHeight, color, label }) {

    const generateChartConfig = () => {
        const config = {};
        config[dataKey] = { label: label };
        return config;
    };

    const chartConfig = generateChartConfig();

    return (
        <ChartContainer
            config={chartConfig}
            className="mx-auto"
            style={{ maxHeight: maxHeight }}
        >
            <BarChart data={data}>
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey={nameKey}
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    tickFormatter={(value) => value.slice(0, 3)}
                />
                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                <Bar dataKey={dataKey} fill={color} radius={8}/>
            </BarChart>
        </ChartContainer>
    );
}

export default BarChartComponent;
