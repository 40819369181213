import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { validarEntorno } from '../../../helpers/validarHost';
import { Form, Card, Row, Col } from 'react-bootstrap';

import NotificationModal from '../../modales/NotificationModal'
import LineaRadial from '../../gerenciaGeneral/operaciones/graficas/LineaRadial'
import { RadialRangos } from '../../gerenciaGeneral/operaciones/graficas/RadialRangos'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Accordion from 'react-bootstrap/Accordion'

import GetAppIcon from '@material-ui/icons/GetApp';
import './Dofa.scss'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useTranslation } from 'react-i18next';
import { useEventBus } from '../../../context/EventBus';

export default function Dofa(props) {

  const eventBus = useEventBus();

  var chart = am4core.create("chartdiv", am4charts.RadarChart);

  const { t } = useTranslation();
  const { id } = useParams()
  const hash = window.location.hash

  const [imgGenerada, setImgGenerada] = useState('')

  const [initialState, setInitialState] = useState({
    data: {}
  })

  const [showModal, setShowModal] = useState({
    show: false,
    type: '',
    mensaje: ''
  })

  const [dataGraficaWidgetDofa, setDataWidgetDofa] = useState({
    dataGrafica: [],
    dataAcordeon: {}
  })

  const [dataPesosWidgetDofa, setDataPesosWidgetDofa] = useState({
    fortalezas: [],
    oportunidades: [],
    debilidades: [],
    amenazas: []
  })

  const [inputsEditados, setInputsEditados] = useState({

  })



  const useStyles = makeStyles((theme) => ({
    root: {
      width: 300 + theme.spacing(3) * 2,
    },
    margin: {
      height: theme.spacing(3),
    },
  }));

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  };

  const PrettoSlider = withStyles({
    root: {
      color: '#213265',
      height: 6,

    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 5,
      borderRadius: 2,
    },
    rail: {
      height: 5,
      borderRadius: 2,
    },
  })(Slider);

  const classes = useStyles();

  function valuetext(value) {
    return `${value}`;
  }

  const getDataInicial = async (filtros) => {

    const dataReq = {
      id: id,
      hash: hash
    }

    if (filtros) {
      dataReq.filtros = filtros;
    }

    const req = await validarEntorno('JSON/dofa.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()

      setInitialState({
        ...initialState,
        data: res
      })

      setDataWidgetDofa({
        ...dataGraficaWidgetDofa,
        dataGrafica: res?.contenido?.puntuacion_general_dofa?.data
      })

      setDataPesosWidgetDofa({
        ...dataPesosWidgetDofa,
        fortalezas: res?.contenido?.valores_genericos?.fortalezas,
        oportunidades: res?.contenido?.valores_genericos?.oportunidades,
        debilidades: res?.contenido?.valores_genericos?.debilidades,
        amenazas: res?.contenido?.valores_genericos?.amenazas
      })

    }

  }

  useEffect(() => {

    getDataInicial()



    // console.log(dataGraficaWidgetDofa)
  }, [])


  // const {puntuacion_general_dofa , valores_genericos } = props.dataDofa.contenido

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 10,
      label: '10',
    },
  ];

  const marksNegativos = [
    {
      value: -10,
      label: '-10',
    },
    {
      value: 0,
      label: '0',
    },
  ];

  const handleChangeInputs = ({ target }) => {
    setInputsEditados({
      ...inputsEditados,
      [target.name]: target.value
    })
    console.log(inputsEditados)
  }


  const sendDOFA = async () => {
    const dataReq = {
      hash: window.location.hash,
      inputsEditados,
      id: id
    }

    // console.log(dataReq)

    const req = await validarEntorno('JSON/gestion_dofa.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      // console.log(res)

      setDataWidgetDofa({
        ...dataGraficaWidgetDofa,
        dataGrafica: res?.puntuacion_general_dofa?.data
      })

      setDataPesosWidgetDofa({
        ...dataPesosWidgetDofa,
        fortalezas: res.valores_genericos.fortalezas,
        oportunidades: res.valores_genericos.oportunidades,
        debilidades: res.valores_genericos.debilidades,
        amenazas: res.valores_genericos.amenazas
      })

      setShowModal({
        ...showModal,
        show: true,
        type: 'dofaActualizado'
      })
    } else {
      setShowModal({
        ...showModal,
        show: true,
        type: 'errorActualizacionDofa'
      })
    }
  }

  const [tipoItemToAdd, setTipoItemToAdd] = useState('')
  const [NewItem, setNewItem] = useState('')

  const AddItemDofa = (type) => {
    setShowModal({
      ...showModal,
      show: true,
      type: 'agregarItemDofa'
    })
    setTipoItemToAdd(type)
  }

  const reqAddItem = async (item) => {

    const dataReq = {
      hash: window.location.hash,
      type: tipoItemToAdd,
      item: item.item,
      id: id
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/add_item_dofa.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      // console.log(res)

      setDataWidgetDofa({
        ...dataGraficaWidgetDofa,
        dataGrafica: res?.puntuacion_general_dofa?.data
      })

      setDataPesosWidgetDofa({
        ...dataPesosWidgetDofa,
        fortalezas: res.valores_genericos.fortalezas,
        oportunidades: res.valores_genericos.oportunidades,
        debilidades: res.valores_genericos.debilidades,
        amenazas: res.valores_genericos.amenazas
      })

      setShowModal({
        ...showModal,
        show: false,
        type: ''
      })

      setShowModal({
        ...showModal,
        show: true,
        type: 'dofaActualizado'
      })
    } else {
      setShowModal({
        ...showModal,
        show: true,
        type: 'errorActualizacionDofa'
      })
    }


  }

  const exportGrafica = async () => {

    let imag3e = imgGenerada;


    const dataReq = {
      imagen: imag3e,
      id: id,
      hash: hash
    }
    console.log(dataReq)

    const req = await validarEntorno('JSON/gestion_dofa_inf.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json();
      if (res.estado === 'ok') {
        window.open(res.enlace, '_blank')
      } else {
        setShowModal({ show: true, type: 'errorFormSolicitud', mensaje: res.mensaje })
      }
    }

  }

  const deleteItem = async (item, type) => {


    const dataReq = {
      id: id,
      hash: hash,
      id_reg: item.id,
      tipo: type
    }




    const req = await validarEntorno('JSON/delete_item_dofa.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()

      if (res.estado === 'ok') {

        switch (type) {
          case 'fortalezas':
            let fortalezasFitltered = dataPesosWidgetDofa.fortalezas.filter((ele) => { return ele !== item })
            setDataPesosWidgetDofa({ ...dataPesosWidgetDofa, fortalezas: fortalezasFitltered })
            break;
          case 'debilidades':
            let debilidadesFitltered = dataPesosWidgetDofa.debilidades.filter((ele) => { return ele !== item })
            setDataPesosWidgetDofa({ ...dataPesosWidgetDofa, debilidades: debilidadesFitltered })
            break;
          case 'amenazas':
            let amenazasFitltered = dataPesosWidgetDofa.amenazas.filter((ele) => { return ele !== item })
            setDataPesosWidgetDofa({ ...dataPesosWidgetDofa, amenazas: amenazasFitltered })
            break;
          case 'oportunidades':
            let oportunidadessFitltered = dataPesosWidgetDofa.oportunidades.filter((ele) => { return ele !== item })
            setDataPesosWidgetDofa({ ...dataPesosWidgetDofa, oportunidades: oportunidadessFitltered })
            break;
          default:
            break;
        }

        setShowModal({
          show: true,
          type: 'successAlt',
          mensaje: res.mensaje
        })

      } else {
        setShowModal({
          show: true,
          type: 'errorFormSolicitud',
          mensaje: res.mensaje
        })
      }

    }



  }


  useEffect(() => {
    const handleCallback = (filtros) => {
      getDataInicial(filtros)
    }
    eventBus?.subscribe('FILTROS_ACTUALIZADOS', handleCallback);
    return () => {
      eventBus?.unsubscribe('FILTROS_ACTUALIZADOS', handleCallback);
    };
}, [eventBus]);


  return (
    <>
      <Card className='card__box__surface'>
        <div className='buttonContainerDOFA'>
          {initialState?.data?.contenido?.generar_pdf &&
            // <a href={props.dataDofa.contenido.pdf}>
            <button style={{ width: '130px' }}
              className='confirmButton'

              onClick={() => exportGrafica()}
            >
              {t('botones.generar_pdf')}
              <GetAppIcon />
            </button>
            // </a>
          }
          {Object.entries(inputsEditados).length > 0 && initialState.data.permisos?.editar !== false &&
            <button style={{ width: '130px' }} onClick={() => sendDOFA()} className='confirmButton'>{t('botones.guardar')}</button>
          }

        </div>
        <Card.Body className='bodyGestorDofa'>
          <div className='contenedorAcordeonInputsDofa'>
            <Accordion className=' ' id='AcordeionInputsDofa' defaultActiveKey="0">
              <Card >
                <Accordion.Toggle
                  eventKey='0'
                  as={Card.Header}
                  className='seccionDofaAcordeon'
                // onClick={() => getInformacionObjetivo(objetivo)}
                >
                  <p>
                    {t('gestion_realizada.dofa.fortalezas')}
                  </p>
                  {initialState.data.permisos?.editar !== false &&   <button onClick={() => AddItemDofa('fortaleza')} className='buttonAnadirItem'>{t('botones.anadir')}</button>}
                </Accordion.Toggle>
                <Accordion.Collapse className='' eventKey='0'>
                  <Card.Body className='item_dofa_cont' >
                    {dataPesosWidgetDofa.fortalezas.map((caracteristica) => {
                      return (
                        <div className='item_dofa' key={caracteristica.id}>
                          <p >{caracteristica.nombre}</p>
                          <div style={{ display: 'flex', width: '100%' }}>

                            <div style={{ width: '93%' }} >
                              <input
                                className='inputRange inputRangeFortalezas'
                                name={`fortaleza_${caracteristica.id}`}
                                type="range"
                                min="0"
                                max="10"
                                step="1"
                                defaultValue={caracteristica.valor}
                                onChange={(e) => handleChangeInputs(e)}
                                list={`fortaleza_${caracteristica.id}`}
                              />
                              <datalist className='datalistDOFA' id={`fortaleza_${caracteristica.id}`} >
                                <option value="0" label="0" />
                                <option value="1" label="1" />
                                <option value="2" label="2" />
                                <option value="3" label="3" />
                                <option value="4" label="4" />
                                <option value="5" label="5" />
                                <option value="6" label="6" />
                                <option value="7" label="7" />
                                <option value="8" label="8" />
                                <option value="9" label="9" />
                                <option value="10" label="10" />
                              </datalist>
                            </div>
                            {initialState.data.permisos?.editar !== false &&
                            <div
                              className='btn-container'
                              onClick={() => deleteItem(caracteristica, 'fortalezas')}
                            >
                              <DeleteOutlineIcon style={{ color: 'white' }} fontSize="small" />
                            </div>
                            }
                          </div>
                        </div>
                      )
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card >
                <Accordion.Toggle
                  eventKey='1'
                  as={Card.Header}
                  className='seccionDofaAcordeon'

                // onClick={() => getInformacionObjetivo(objetivo)}
                >
                  <p>
                    {t('gestion_realizada.dofa.oportunidades')}
                  </p>
                  {initialState.data.permisos?.editar !== false &&
                  <button
                    onClick={() => AddItemDofa('oportunidad')}
                    className='buttonAnadirItem'>{t('botones.anadir')}</button>
                  }
                </Accordion.Toggle>
                <Accordion.Collapse className='' eventKey='1'>
                  <Card.Body className='item_dofa_cont' >
                    {dataPesosWidgetDofa.oportunidades.map((caracteristica, index) => {
                      return (
                        <div className='item_dofa' key={index}>
                          <p  >{caracteristica.nombre}</p>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: '93%' }} >
                              <input
                                className='inputRange inputRangeOportunidades'
                                name={`oportunidad_${caracteristica.id}`}
                                type="range"
                                min="0"
                                max="10"
                                step="1"
                                defaultValue={caracteristica.valor}
                                onChange={(e) => handleChangeInputs(e)}
                                list={`oportunidad_${caracteristica.id}`}
                              />
                              <datalist className='datalistDOFA' id={`oportunidad_${caracteristica.id}`} >
                                <option value="0" label="0" />
                                <option value="1" label="1" />
                                <option value="2" label="2" />
                                <option value="3" label="3" />
                                <option value="4" label="4" />
                                <option value="5" label="5" />
                                <option value="6" label="6" />
                                <option value="7" label="7" />
                                <option value="8" label="8" />
                                <option value="9" label="9" />
                                <option value="10" label="10" />
                              </datalist>
                            </div>
                            {initialState.data.permisos?.editar !== false &&
                            <div
                              className='btn-container'
                              onClick={() => deleteItem(caracteristica, 'oportunidades')}
                            >
                              <DeleteOutlineIcon style={{ color: 'white' }} fontSize="small" />
                            </div>
                            }

                          </div>
                        </div>

                      )
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card >
                <Accordion.Toggle
                  eventKey='2'
                  as={Card.Header}
                  className='seccionDofaAcordeon'
                // onClick={() => getInformacionObjetivo(objetivo)}
                >
                  <p>
                    {t('gestion_realizada.dofa.debilidades')}
                  </p>
                  {initialState.data.permisos?.editar !== false &&
                  <button
                    onClick={() => AddItemDofa('debilidad')}

                    className='buttonAnadirItem'>{t('botones.anadir')}</button>
                  }
                </Accordion.Toggle>
                <Accordion.Collapse className='' eventKey='2'>
                  <Card.Body className='' >
                    {dataPesosWidgetDofa.debilidades.map((caracteristica, index) => {
                      return (
                        <div className='item_dofa' key={index} >
                          <p >{caracteristica.nombre}</p>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: '93%' }} >
                              <input
                                className='inputRange inputRangeDebilidades'
                                name={`debilidad_${caracteristica.id}`}
                                type="range"
                                min="-10"
                                max="0"
                                step="1"
                                defaultValue={caracteristica.valor}
                                onChange={(e) => handleChangeInputs(e)}
                                list={`debilidad_${caracteristica.id}`}
                              />
                              <datalist className='datalistDOFANegativos' id={`debilidad_${caracteristica.id}`} >
                                <option value="-10" label="-10" />
                                <option value="-9" label="-9" />
                                <option value="-8" label="-8" />
                                <option value="-7" label="-7" />
                                <option value="-6" label="-6" />
                                <option value="-5" label="-5" />
                                <option value="-4" label="-4" />
                                <option value="-3" label="-3" />
                                <option value="-2" label="-2" />
                                <option value="-1" label="-1" />
                                <option value="0" label="0" />
                              </datalist>

                            </div>
                          </div>
                          {initialState.data.permisos?.editar !== false &&
                          <div
                            className='btn-container'
                            onClick={() => deleteItem(caracteristica, 'debilidades')}
                          >
                            <DeleteOutlineIcon style={{ color: 'white' }} fontSize="small" />
                          </div>
                          }
                        </div>

                      )
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card >
                <Accordion.Toggle
                  eventKey='3'
                  as={Card.Header}
                  className='seccionDofaAcordeon'


                // onClick={() => getInformacionObjetivo(objetivo)}
                >
                  <p>
                    {t('gestion_realizada.dofa.amenazas')}
                  </p>
                  {initialState.data.permisos?.editar !== false &&
                  <button
                    onClick={() => AddItemDofa('amenaza')}
                    className='buttonAnadirItem'>{t('botones.anadir')}</button>
                  }
                </Accordion.Toggle>
                <Accordion.Collapse className='' eventKey='3'>
                  <Card.Body className='' >
                    {dataPesosWidgetDofa.amenazas.map((caracteristica, index) => {
                      return (
                        <div className='item_dofa' key={index} >
                          <p>{caracteristica.nombre}</p>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ width: '93%' }} >
                              <input
                                className='inputRange inputRangeAmenazas'
                                name={`amenaza_${caracteristica.id}`}
                                type="range"
                                min="-10"
                                max="0"
                                step="1"
                                defaultValue={caracteristica.valor}
                                onChange={(e) => handleChangeInputs(e)}
                                list={`amenaza_${caracteristica.id}`}
                              />
                              <datalist className='datalistDOFANegativos' id={`amenaza_${caracteristica.id}`} >
                                <option value="-10" label="-10" />
                                <option value="-9" label="-9" />
                                <option value="-8" label="-8" />
                                <option value="-7" label="-7" />
                                <option value="-6" label="-6" />
                                <option value="-5" label="-5" />
                                <option value="-4" label="-4" />
                                <option value="-3" label="-3" />
                                <option value="-2" label="-2" />
                                <option value="-1" label="-1" />
                                <option value="0" label="0" />
                              </datalist>

                            </div>
                          </div>
                          {initialState.data.permisos?.editar !== false &&
                          <div
                            className='btn-container'
                            onClick={() => deleteItem(caracteristica, 'amenazas')}
                          >
                            <DeleteOutlineIcon style={{ color: 'white' }} fontSize="small" />
                          </div>
                          }

                        </div>

                      )
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <br />
            <h5 className='titleCard'>{t('gestion_realizada.dofa.observaciones')}</h5>
            <div id='observacionesContainerEstudioBasico'>
              <Row>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.fortalezas')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        name='detalle_fortalezas'
                        className='observacionesTextArea'
                        maxLength={2000}
                        defaultValue={initialState?.data?.observaciones?.detalle_fortalezas}
                        required
                        as='textarea'
                        placeholder={t('formulario.label.fortalezas')}
                      />
                      <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las fortalezas</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.conclusion_fortalezas')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        name='conclusion_fortalezas'
                        className='observacionesTextArea'
                        maxLength={2000}
                        defaultValue={initialState?.data?.observaciones?.conclusion_fortalezas}
                        required
                        as='textarea'
                        placeholder={t('formulario.label.conclusion_fortalezas')}
                      />
                      <Form.Control.Feedback type='invalid'>Agrega una conclusión sobre las fortalezas</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.oportunidades')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        defaultValue={initialState?.data?.observaciones?.detalle_oportunidades}
                        name='detalle_oportunidades' className='observacionesTextArea' maxLength={2000} required as='textarea' placeholder={t('formulario.label.oportunidades')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las Oportunidades</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.conclusion_oportunidades')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        defaultValue={initialState?.data?.observaciones?.conclusion_oportunidades}

                        name='conclusion_oportunidades' className='observacionesTextArea' maxLength={2000} required as='textarea'
                        placeholder={t('formulario.label.conclusion_oportunidades')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las debilidades</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.amenazas')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        defaultValue={initialState?.data?.observaciones?.detalle_amenazas}
                        name='detalle_amenazas' className='observacionesTextArea' maxLength={2000} required as='textarea' placeholder={t('formulario.label.amenazas')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las amenazas</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.conclusion_amenazas')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        defaultValue={initialState?.data?.observaciones?.conclusion_amenazas}
                        name='conclusion_amenazas' className='observacionesTextArea' maxLength={2000} required as='textarea' placeholder={t('formulario.label.conclusion_amenazas')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las amenazas</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.debilidades')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        defaultValue={initialState?.data?.observaciones?.detalle_debilidades}
                        name='detalle_debilidades' className='observacionesTextArea' maxLength={2000} required as='textarea' placeholder={t('formulario.label.debilidades')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las amenazas</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='observacionesFormGroupAuditorias'>
                    <Form.Label className='observacionesLabel'>
                      {t('gestion_realizada.dofa.conclusion_debilidades')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        onChange={(e) => handleChangeInputs(e)}
                        defaultValue={initialState?.data?.observaciones?.conclusion_debilidades}
                        name='conclusion_debilidades' className='observacionesTextArea' maxLength={2000} required as='textarea' placeholder={t('formulario.label.conclusion_debilidades')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las amenazas</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className='observacionesFormGroupAuditorias'>
                <Form.Label className='observacionesLabel'>
                  {t('gestion_realizada.dofa.conclusiones_generales')}
                </Form.Label>
                <div className='textAreaContainer'>
                  <Form.Control
                    onChange={(e) => handleChangeInputs(e)}
                    defaultValue={initialState?.data?.observaciones?.conclusiones}
                    name='conclusiones' className='observacionesTextArea' maxLength={2000} required as='textarea' placeholder={t('formulario.label.conclusiones')}></Form.Control>
                  <Form.Control.Feedback type='invalid'>Agrega una descripción sobre las conclusiones</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>


          </div>
          <div className='contenedorAcordeonPuntuacionDofa' >
            <div style={{ minHeight: '500px', maxHeight: '400px' }}>
              {/* <div className=''  style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <button
                  className='confirmButton'
                  style={{width: '120px', margin: '0'}}
                >
                  Capturar
                </button>
              </div> */}
              <RadialRangos
                title={t('gestion_realizada.dofa.puntuacion_general')}
                data={dataGraficaWidgetDofa?.dataGrafica}
                chart={chart}
                id='dofaGestion'
                setImgGenerada={setImgGenerada}
                imgGenerada={imgGenerada}


              />

            </div>


            <div>
              <Accordion className=' ' id='acordeonPesos' defaultActiveKey="0">
                <Card >
                  <Accordion.Toggle
                    eventKey='0'
                    as={Card.Header}
                    className='seccionDofaAcordeon'
                  // onClick={() => getInformacionObjetivo(objetivo)}
                  >
                    {t('gestion_realizada.dofa.fortalezas')}
                  </Accordion.Toggle>
                  <Accordion.Collapse className='' eventKey='0'>
                    <Card.Body className='' >
                      <div className='pesosDofaItem headerPesos'>
                        <p >{t('gestion_realizada.dofa.fortaleza')}</p>
                        <p>{t('gestion_realizada.dofa.peso')}</p>
                      </div>
                      {dataPesosWidgetDofa.fortalezas.length > 0
                        ?
                        dataPesosWidgetDofa.fortalezas.map((caracteristica, index) => {
                          return (
                            <div key={index} className='pesosDofaItem'>
                              <p >{caracteristica.nombre}</p>
                              <p>{caracteristica.porcentaje} </p>
                            </div>

                          )
                        })
                        : t('gestion_realizada.dofa.no_se_han_ingresado_datos_cuadrante')
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card >
                  <Accordion.Toggle
                    eventKey='1'
                    as={Card.Header}
                    className='seccionDofaAcordeon'
                  // onClick={() => getInformacionObjetivo(objetivo)}
                  >
                    {t('gestion_realizada.dofa.oportunidades')}
                  </Accordion.Toggle>
                  <Accordion.Collapse className='' eventKey='1'>
                    <Card.Body className='' >
                      <div className='pesosDofaItem headerPesos'>
                        <p >{t('gestion_realizada.dofa.oportunidad')}</p>
                        <p>{t('gestion_realizada.dofa.peso')}</p>
                      </div>
                      {dataPesosWidgetDofa.oportunidades.length > 0

                        ?

                        dataPesosWidgetDofa.oportunidades.map((caracteristica, index) => {
                          return (
                            <div key={index} className='pesosDofaItem'>
                              <p >{caracteristica.nombre}</p>
                              <p>{caracteristica.porcentaje} </p>
                            </div>

                          )
                        })

                        : t('gestion_realizada.dofa.no_se_han_ingresado_datos_cuadrante')
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card >
                  <Accordion.Toggle
                    eventKey='2'
                    as={Card.Header}
                    className='seccionDofaAcordeon'
                  // onClick={() => getInformacionObjetivo(objetivo)}
                  >
                    {t('gestion_realizada.dofa.debilidades')}
                  </Accordion.Toggle>
                  <Accordion.Collapse className='' eventKey='2'>
                    <Card.Body className='' >
                      <div className='pesosDofaItem headerPesos'>
                        <p >{t('gestion_realizada.dofa.debilidad')}</p>
                        <p>{t('gestion_realizada.dofa.peso')}</p>
                      </div>
                      {dataPesosWidgetDofa.debilidades.length > 0

                        ?

                        dataPesosWidgetDofa.debilidades.map((caracteristica, index) => {
                          return (
                            <div key={index} className='pesosDofaItem'>
                              <p >{caracteristica.nombre}</p>
                              <p>{caracteristica.porcentaje} </p>
                            </div>

                          )
                        })

                        : t('gestion_realizada.dofa.no_se_han_ingresado_datos_cuadrante')
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card >
                  <Accordion.Toggle
                    eventKey='3'
                    as={Card.Header}
                    className='seccionDofaAcordeon'
                  // onClick={() => getInformacionObjetivo(objetivo)}
                  >
                    {t('gestion_realizada.dofa.amenazas')}
                  </Accordion.Toggle>
                  <Accordion.Collapse className='' eventKey='3'>
                    <Card.Body className='' >
                      <div className='pesosDofaItem headerPesos'>
                        <p >{t('gestion_realizada.dofa.amenaza')}</p>
                        <p>{t('gestion_realizada.dofa.peso')}</p>
                      </div>
                      {dataPesosWidgetDofa.amenazas.length > 0
                        ?
                        dataPesosWidgetDofa.amenazas.map((caracteristica, index) => {
                          return (
                            <div key={index} className='pesosDofaItem'>
                              <p >{caracteristica.nombre}</p>
                              <p>{caracteristica.porcentaje} </p>
                            </div>

                          )
                        })
                        : t('gestion_realizada.dofa.no_se_han_ingresado_datos_cuadrante')
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>


            </div>
          </div>
        </Card.Body>
      </Card>
      <NotificationModal setNewItem={setNewItem} sendItem={reqAddItem} tipoItem={tipoItemToAdd} typeModal={showModal.type} isOpen={showModal.show} mensaje={showModal.mensaje} setIsOpen={setShowModal} />
    </>
  )
}
