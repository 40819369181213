import React, { useState, useEffect, useLayoutEffect, createRef } from 'react'
import useState2 from 'react-usestateref';
import { Font, Page, Text, View, Document, Image, StyleSheet, PDFViewer, Canvas, PDFDownloadLink, pdf, BlobProvider, Svg, Circle, PDFviewer } from '@react-pdf/renderer';

import { PortadaGeneral } from './portadas/PortadaGeneral';
import { Lista } from './atomos/panorama/Lista';
import { GraficaPanorama } from './atomos/panorama/GraficaPanorama';

import { Pagina } from './atomos/Pagina'
import { Tabla } from './atomos/Tabla'

import EditModal from '../../modales/EditModal';
import Map from './Map';
import { dataPdf } from './data';

import './styles/PanoramaRiesgos.scss'
import { credenciales } from '../../../helpers/credentials.google';
import Loader from '../../icons/Loader';
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { map } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import html2canvas from 'html2canvas';
import { } from "module";
import { useTranslation } from 'react-i18next';

function PanoramaRiesgos({ id, data, show, update, sendFile }) {

  // console.log(id, data)

  // console.log(data?.data?.paginas[0]?.contenido?.tendencia)
  const { t } = useTranslation();
  const [printImg, setPrintImg] = useState({})
  const [baseMap, setBaseMap, mapRef] = useState2('');

  const [loading, setLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState('')
  const fontSRC = 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;800;900&display=swap'

  Font.register({ family: 'Lato', src: fontSRC });



  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;

  const calificacion_riesgos = {
    "cols": [
      "Riesgo",
      "Rango"
    ],
    "regs": [
      {
        "nombre": "1- 2",
        "rango": 1
      },
      {
        "nombre": "3 - 4",
        "rango": 3
      },
      {
        "nombre": "5 - 6",
        "rango": 5
      },
      {
        "nombre": "8 - 12",
        "rango": 7
      },
      {
        "nombre": "15 - 25",
        "rango": 13
      }

    ]

  }

  const styles = StyleSheet.create({

    grafica: {
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
      width: '60%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row'
    },
    mapa: {
      width: '100%',
    },
    tablas: {
      // backgroundColor: 'blue',
      width: '40%',
      height: '100%',
      padding: '2%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },

    contenedor_grafica: {
      // backgroundColor:'green',
      width: '100%',
      height: '300px',
      padding: '1% 6%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap'

    },

    contenedor_mapa: {
      // backgroundColor:'green',
      width: '100%',
      padding: '1% 6%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    },

    parrafo_container: {
      width: '100%'
    },

    titulo: {
      color: '#4471C4',
      fontWeight: 700,
      fontSize: '13px',
      // backgroundColor:'red',
      width: '100%',
      padding: '1% 6%',
      fontWeight: '800',
      margin: '25px 1px',
    },

    subtitulo: {
      color: '#4471C4',
      fontSize: '13px',
      // backgroundColor:'red',
      width: '100%',
      padding: '1% 6%',
      fontWeight: '800'
    },

    parrafo: {
      // color: '#4471C4',
      fontSize: '11px',
      // backgroundColor:'red',
      width: '100%',
      padding: '1% 6%',
      textAlign: 'justify'
      // fontWeight: '800'
    },

    viewer: {
      height: '81vh',
      width: '100%'
    },
    page: {
      backgroundImage: "url(https://www.ciacosinte.site/cia2/static/media/pdfs/fondo_azul.png)",
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    section1: {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '50%'
    },
    canvas: {
      height: '100%',
      width: '100%'
    },
    text: {
      top: '0',
      fontSize: '25pt'
    },
    otraSec: {
      // backgroundColor: 'yellow',
      position: 'absolute',
      width: '100%',
      height: '79px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white'

    },

    act_contenedor: {
      // position: 'absolute',
      // backgroundColor: 'red',
      minHeight: '100pt',
      width: '100%',
      display: 'flex',
      // flexDirection:
      alignItems: 'center',
      justifyContent: 'space-evenly'
    },
    msj_no_acts: {
      zIndex: 2,
      backgroundColor: '#e0e0e0',
      padding: '30px auto',
      borderRadius: '20px',
      color: 'gray'
    },

    contenido: {
      position: 'absolute',
      height: 'auto',
      width: '110%',
      marginTop: '90px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

    },

    act_analisis: {
      width: '110%',
      margin: '0 -25px -15px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      minHeight: 'auto'
    },

    analisis: {
      padding: '9px',
      fontSize: '11px',
      // margin: '0 auto'
    },

    listadoTitulo: {
      fontSize: '8px',
      padding: '10px 10px 10px 10px',
      marginRight: '5px',
      marginBottom: '5px',
      color: '#4471C4',
      fontWeight: '900'
    },

    listado: {
      fontSize: '7px',
      paddingRight: '10px',
      marginLeft: '10px',
      marginBottom: '2px',
      display: 'flex',
      flexWrap: 'wrap'
    }
  });

  const iconos = {
    amenaza: {
      icono: 'https://www.ciacosinte.site/cia2/static/media/punto_amenaza.svg'
    },
    homicidio: {
      icono: 'https://www.ciacosinte.site/cia2/static/media/Homicidio_map.svg'
    },
    secuestro: {
      icono: 'https://www.ciacosinte.site/cia2/static/media/Secuestro_map.svg'
    },
    combinado: {
      icono: 'https://www.ciacosinte.site/cia2/static/media/icono_combinado3.svg'
    },
    zona_alerta: {
      icono: 'https://www.ciacosinte.site/cia2/static/media/zona_alerta.svg'
    },
    oleoducto: {
      icono: 'https://www.ciacosinte.site/cia2/static/media/valvula.svg'
    },
  }

  // useEffect(() => {

  //   // html2canvas(document.querySelector("#capture"), { useCORS: true }).then(canvas => {
  //   //   document.body.appendChild(canvas)
  //   // });
  // }, [])

  // const grafica = () => {
  //   return(
  //     <div id="grafica-pdf">
  //       <GraficaPanorama
  //         data={data?.data?.paginas[0]?.contenido?.tendencia?.grafica?.data}
  //         setPrintImg={setPrintImg}
  //         printImg={printImg}
  //         id='panorama12345'
  //       />
  //     </div>
  //   )
  // }

  const MyDoc = (
    <Document>
      <PortadaGeneral data={data?.data?.portada} />
      <Page size="letter" wrap={true} style={[styles.page, { padding: '30px' }]}>
        <Text style={styles.titulo} >GEOLOCALIZACIÓN DE LAS PRINCIPALES ACTIVIDADES POR FACTOR</Text>
        <View style={styles.contenedor_mapa} >
          <View style={styles.mapa} >
            <Image src={mapRef.current} style={{ position: 'absolute', width: '100%', height: '612px', zIndex: 2 }} ></Image>
            <View
              style={{
                backgroundColor: '#fff',
                width: '40%',
                height: '100px',
                borderRadius: '5%',
                zIndex: 1,
                position: 'relative',
                top: '500px',
                left: '275px',
                display: 'flex',
                flexDirection: 'row'
              }}>
              <View>
                <View style={styles.listadoTitulo}>
                  <Text>Factores</Text>
                </View>
                <View style={styles.listado}>
                  <Image src="https://www.ciacosinte.site/cia2/static/media/punto_politico.png" style={{ width: '10px', height: '10px', marginTop: '0.5px' }} />
                  <Text>Político</Text>
                </View>
                <View style={styles.listado}>
                  <Image src="https://www.ciacosinte.site/cia2/static/media/punto_social.png" style={{ width: '10px', height: '10px', marginTop: '0.5px' }} />
                  <Text>Social</Text>
                </View>
                <View style={styles.listado}>
                  <Image src="https://www.ciacosinte.site/cia2/static/media/punto_economico.png" style={{ width: '10px', height: '10px', marginTop: '0.5px' }} />
                  <Text>Económico</Text>
                </View>
                <View style={styles.listado}>
                  <Image src="https://www.ciacosinte.site/cia2/static/media/punto_amenaza.png" style={{ width: '10px', height: '10px', marginTop: '0.5px' }} />
                  <Text>Amenazas</Text>
                </View>
                <View style={styles.listado}>
                  <Image src="https://www.ciacosinte.site/cia2/static/media/punto_fuerzas.png" style={{ width: '10px', height: '10px', marginTop: '0.5px' }} />
                  <Text>Fuerza disponible</Text>
                </View>
              </View>
              <View>
                <View>
                  <View style={styles.listadoTitulo}>
                    <Text>General</Text>
                  </View>
                  <View style={[styles.listado, { display: 'flex', flexDirection: 'row' }]}>
                    <Image src="https://www.ciacosinte.site/cia2/static/media/trazado.png" style={{ width: '10px', height: '3px', marginTop: '0.5px' }} />
                    <Text>Trazado del oleoducto</Text>
                  </View>
                  <View style={[styles.listado, { display: 'flex', flexDirection: 'row' }]}>
                    <Image src="https://www.ciacosinte.site/cia2/static/media/valvula.png" style={{ width: '8px', height: '8px', marginTop: '0.5px' }} />
                    <Text>Válvula</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page size="letter" wrap={true} style={[styles.page, { padding: '30px' }]}>
        {data?.data?.factores?.map((pagina, index) => {
          return (
            <View style={{
              width: '100%',
              padding: ' 15px 25px',
              borderRadius: '20px',
              margin: '0 0 20px',
              backgroundColor: pagina.tipo === 'politico'
                ? '#4473C4'
                : pagina.tipo === 'social'
                  ? '#009999'
                  : pagina.tipo === 'economico'
                    ? '#FFC000'
                    : pagina.tipo === 'amenaza'
                      ? '#FF1A1A'
                      : pagina.tipo === 'fuerzas'
                        ? '#FF761A'
                        : '#F2F2F2'
            }}
            >
              <Text
                style={{
                  borderTopRightRadius: '15px',
                  borderTopLeftRadius: '15px',
                  backgroundColor: '#F2F2F2',
                  padding: '1% 3%',
                  width: '110%',
                  height: 'auto',
                  margin: '-16px -25px -2px',
                  fontSize: '25px',
                  fontWeight: 'bold',
                  color:
                    pagina.tipo === 'politico'
                      ? '#4473C4'
                      : pagina.tipo === 'social'
                        ? '#009999'
                        : pagina.tipo === 'economico'
                          ? '#FFC000'
                          : pagina.tipo === 'amenaza'
                            ? '#FF1A1A'
                            : pagina.tipo === 'fuerzas'
                              ? '#FF761A'
                              : 'black'
                }}
              >
                FACTOR: {pagina.tipo === 'fuerzas' ? 'FUERZA DISPONIBLE' : pagina.tipo.toUpperCase()}
              </Text>
              {(pagina?.contenido?.actividades?.length > 0) || (pagina?.contenido?.actividades?.length > 0 && pagina?.tipo !== 'analisis')
                ?
                <Lista
                  color={
                    pagina.tipo === 'politico'
                      ? '#4473C4'
                      : pagina.tipo === 'social'
                        ? '#009999'
                        : pagina.tipo === 'economico'
                          ? '#FFC000'
                          : pagina.tipo === 'amenaza'
                            ? '#FF1A1A'
                            : pagina.tipo === 'fuerzas'
                              ? '#FF761A'
                              : null
                  }
                  lista={pagina?.contenido?.actividades}
                />
                : (pagina?.tipo !== 'analisis') && null
              }
              {pagina?.contenido?.analisis !== '' &&
                <View style={
                  [styles.act_analisis, {
                    color: 'white',
                    backgroundColor: pagina.tipo === 'politico'
                      ? '#4473C4'
                      : pagina.tipo === 'social'
                        ? '#009999'
                        : pagina.tipo === 'economico'
                          ? '#FFC000'
                          : pagina.tipo === 'amenaza'
                            ? '#FF1A1A'
                            : pagina.tipo === 'fuerzas'
                              ? '#FF761A'
                              : '#e0e0e0'

                  }]}
                >
                  <Text
                    style={[styles.analisis, {
                      backgroundColor: 'white',
                      textAlign: 'justify',
                      color: 'black',
                      borderRadius: '10px',
                      margin: '5px'

                    }]}
                  >
                    {pagina?.contenido?.analisis}
                  </Text>

                </View>
              }
            </View>
          )
        })
        }
      </Page>
      {
        data?.data?.paginas.length &&
        data?.data?.paginas[0]?.tipo === 'analisis' &&
        data.data.paginas[0].contenido.tendencia &&
        data?.data?.paginas[0]?.contenido?.prospeccion !== '' &&
        data?.data?.paginas[0]?.contenido?.recomendaciones.length &&

        <Page size="letter" wrap={false} style={[styles.page, { padding: '30px' }]} >
          {data?.data?.paginas[0]?.tipo === 'analisis' &&
            <View style={styles.contenido} >
              <Text style={styles.subtitulo} >ANÁLISIS PROSPECTIVO</Text>

              <View style={styles.parrafo_container} >
                <Text style={styles.parrafo} >{data?.data?.paginas[0]?.contenido?.prospeccion}</Text>
              </View>

            </View>
          }
        </Page>
      }
      {data?.data?.paginas[0]?.contenido?.tendencia !== '' &&
        data?.data?.paginas[0]?.contenido?.recomendaciones.length &&
        <Page size="letter" wrap={false} style={[styles.page, { padding: '30px' }]} >
          <View style={styles.contenido}>
            <Text style={styles.subtitulo} >TENDENCIA DEL RIESGO</Text>
            <View style={styles.parrafo_container}>
              <Text style={styles.parrafo} >{data?.data?.paginas[0]?.contenido?.tendencia?.descripcion}</Text>
            </View>

            <View style={styles.contenedor_grafica} >

              <View View style={styles.grafica} >
                <Image
                  src={printImg}
                  style={{
                    width: '3800px',
                    height: '290px',
                    padding: '5px',
                    // backgroundColor:'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    // backgroundColor:'rgba(0, 0, 0, 0.0)'
                  }}
                />
                <div id="grafica-pdf">
                  <GraficaPanorama
                    data={data?.data?.paginas[0]?.contenido?.tendencia?.grafica?.data}
                    setPrintImg={setPrintImg}
                    printImg={printImg}
                    id='panorama12345'
                  />
                </div>
              </View>
              <View style={styles.tablas} >
                <Tabla data={data.data.paginas[0].contenido.tendencia.riesgos} />
                <Tabla data={calificacion_riesgos} />
              </View>
            </View>
            <Text style={styles.subtitulo}>RECOMENDACIONES</Text>
            <View style={styles.parrafo_container}>
              {data?.data?.paginas[0]?.contenido?.recomendaciones.map((reco, index) => {
                return (
                  <Text key={index} style={styles.parrafo} >{reco.valor}</Text>
                )
              })
              }
            </View>
          </View>
        </Page>
      }
    </Document>
  );


  const getImage = async (e) => {

    // e.preventDefault();

    setLoading(true)
    setLoadingMsg('Capturando el mapa...')
    const map = document.querySelector('#map-container');
    const croppedCanvas = document.createElement('canvas');
    const croppedCanvasContext = croppedCanvas.getContext('2d');
    const cropPositionTop = 0;
    const cropPositionLeft = 0;

    html2canvas(map, { useCORS: true, allowTaint: false })
      .then((canvas) => {
        // console.log(canvas.width, canvas.height);
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;
        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);
        const base64Image = croppedCanvas.toDataURL('image/jpeg', 1.0);

        // console.log(base64Image)
        setBaseMap(base64Image);

        // console.log(baseMap)

        // setLoading(false)

      })
    // .then(() => converPDF())

  }

  const converPDF = async () => {
    // setTimeout( async () => {
    setLoadingMsg('Generando el archivo...')

    const blob = await pdf(MyDoc).toBlob();
    saveAs(blob, 'panorama.pdf')
    const reader = new FileReader();
    reader.readAsDataURL(blob)
    reader.onloadend = async function () {
      const base64data = reader.result;
      await sendFile(base64data)
    }


    // }, 1000);

    // setLoading(false)
    // setLoadingMsg('')

  }

  useEffect(() => {
    (converPDF())
      .then(() => setLoading(false))
      .then(() => setLoadingMsg(''))



  }, [baseMap])



  return (
    <>
      {/* <PDFviewer style={{width: '100%', height: '80%'}} >
          <Document>
            <Page size='letter' >
              <Tabla  data={data?.data?.paginas[0]} />
            </Page>
          </Document>
        </PDFviewer> */}

      <div className='buttonContainer mb-2' >
        {/* { show && <a target='blank' href={data?.pdf?.url} ><button className='confirmButton' style={{width: '130px'}} >Ver PDF</button></a>} */}
        {
          <button
            className='confirmButton'
            // disabled={loading}
            style={{ width: '130px' }}
            onClick={(e) => getImage(e)}
          >
            {t('botones.actualizar_pdf')}
          </button>
        }
      </div>
      {loading &&
        <div>
          <h4 style={{ textAlign: 'center', marginTop: '30px', color: 'gray' }} >{loadingMsg}</h4>
          {/* <Loader />  */}
        </div>
      }
      <div id="grafica-pdf">
        <GraficaPanorama
          data={data?.data?.paginas[0]?.contenido?.tendencia?.grafica?.data}
          setPrintImg={setPrintImg}
          printImg={printImg}
        />
      </div>
      <Map
        data={data?.data?.mapa}
        googleMapURL={mapURL}
        containerElement={<div id="map-container" style={{ height: '700px', width: '55%', margin: '0 auto', borderRadius: '15px', overflow: 'hidden' }}></div>}
        mapElement={<div style={{ height: '100%' }}></div>}
        loadingElement={<div></div>}
      />
    </>
  )
}


export { PanoramaRiesgos }
