import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../helpers/validarHost';
import { Accordion, Form, Card, Row, Col } from 'react-bootstrap';

import NotificationModal from '../../modales/NotificationModal'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';

import EditModal from '../../modales/EditModal';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { getDataFiltros, inputFilterOcensa } from '../../../reducers/inputFilterOcensa';
import { useTranslation } from 'react-i18next';
import './PlanEstrategico.scss'


export default function PlanEstrategico(props) {

  const { filter } = useSelector(store => store.inputFilterOcensa)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    handleChangeState,
    setHandleChangeState,
    setDataTimeline,
    dataTimeline

  } = props;

  //console.log(props)

  const { id } = useParams()
  const hash = window.location.hash


  const [tipoModal, setTipoModal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [TitleIsEditing, setTitleIsEditing] = useState(false);

  const [notification, setNotification] = useState({
    tipo: '',
    show: false,
    mensaje: ''
  })

  // const [handleChangeState, setHandleChangeState] = useState({
  //   id_objetivo: '',
  //   id_meta: '',
  //   id_tarea: '',
  //   id_actividad: '',
  //   id_accion: ''
  // })




  const [riesgosObj, setRiesgosObj] = useState({})

  const openModal = (tipo, show, item) => {
    if (tipo && show) {

      setTipoModal(tipo)
      setShowModal(show);
      setAddItem({
        titulo: item.nombre,
        descripcion: item.descripcion,
        riesgos: item.riesgos,
        responsables: item.responsables
      })

      if (tipo === 'tarea') {
        setTipoModal('planEstrategico');
        setShowModal(show);
        setHandleChangeState({
          ...handleChangeState,
          id_tarea: item.id
        })
      }

    }
    else return null
  }

  // //console.log(props.data)

  const [dataInicial, setDataInicial] = useState({
    permisos: {},
    data: {}
  })

  const [riesgosSelected, setRiesgosSelected] = useState([])

  const getDataInicial = async () => {

    const dataReq = {
      id: id,
      hash: hash,
      filtros: filter
    }

    console.log(dataReq)


    const req = await validarEntorno('JSON/per_panel.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()

      // console.log(res)

      props.setDataInicial({
        ...props.dataInicial,
        data: res,
        riesgos: res.riesgos,
        titulo: res.titulo_plan,
        objetivos: res.contenido.objetivos,
        fecha_inicio: res.fecha_inicio,
        fecha_fin: res.fecha_fin
      })

      if (res.linea_tiempo) {
        setDataTimeline(res.linea_tiempo)
      }

      props.setChangeTitle({
        ...props.changeTitle,
        nuevo_titulo: res.titulo_plan
      })

    }

  }


  useEffect(() => {

    getDataInicial()



    // //console.log(dataInicial)
  }, [])



  const handleChangeNewTitle = ({ target }) => {
    props.setChangeTitle({
      ...props.changeTitle,
      [target.name]: target.value
    })

    // //console.log(changeTitle)
  }

  const cambiarTitulo = async () => {
    const dataReq = {
      id: id,
      hash: hash,
      dataForm: props.changeTitle
      // ,
      // nuevo_titulo: changeTitle.nuevo_titulo
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/auditorias/plan_estrategico/act_titulo_pe.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      props.setDataInicial({
        ...props.dataInicial,
        data: props.data,
        titulo: res.titulo,
        fecha_inicio: res.fecha_inicio,
        fecha_fin: res.fecha_fin
      })
      setTitleIsEditing(false)
      setNotification({
        tipo: 'tituloActualizado',
        show: true
      })
    } else {
      setNotification({
        tipo: 'error',
        show: true
      })
    }
  }


  const [addItem, setAddItem] = useState({
    titulo: '',
    descripcion: ''
  })

  const handleChangeItem = ({ target }) => {
    setAddItem({
      ...addItem,
      [target.name]: target.value
    })
    console.log(target.value)
  }

  const agregarItem = async (tipo) => {

    if (tipo === 'obj') {
      const dataReq = {
        action: 'crear_objetivo',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        riesgos: riesgosSelected
      }

      // console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()

        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'objAgregado',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }

    }
    else if (tipo === 'meta') {
      const dataReq = {
        action: 'crear_meta',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo
      }

      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'metaAgregada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'metaAgregada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    } else if (tipo === 'tarea') {
      const dataReq = {
        action: 'crear_tarea',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo,
        id_meta: handleChangeState.id_meta
      }

      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'tareaAgregada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'tareaAgregada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }

      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    }
  }

  const editarItem = async (tipo) => {

    if (tipo === 'obj') {
      const dataReq = {
        action: 'editar_objetivo',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo
      }

      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()


        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'objActualizado',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'objActualizado',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }

    }
    else if (tipo === 'meta') {
      const dataReq = {
        action: 'editar_meta',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo,
        id_meta: handleChangeState.id_meta
      }

      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'metaActualizada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'metaActualizada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    }
    else if (tipo === 'actividad') {
      const dataReq = {
        action: 'editar_act',
        id: id,
        hash: hash,
        id_objetivo: handleChangeState.id_objetivo,
        id_meta: handleChangeState.id_meta,
        id_tarea: handleChangeState.id_tarea
      }

      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'metaActualizada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'metaActualizada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    }
  }



  const getIds = (tipo, id) => {

    if (tipo === 'obj') {
      setHandleChangeState({
        id_objetivo: id
      })

    } else if (tipo === 'meta') {
      setHandleChangeState({
        ...handleChangeState,
        id_meta: id
      })
    } else if (tipo === 'tarea') {
      setHandleChangeState({
        ...handleChangeState,
        id_tarea: id
      })
    }

    // //console.log(handleChangeState)

  }

  const modalAddItem = (tipo) => {

    if (tipo === 'obj') {
      setTipoModal('addObj');
    } else if (tipo === 'meta') {
      setTipoModal('addMeta');
    } else if (tipo === 'tarea') {
      setTipoModal('addTarea')
    }

    setShowModal(!showModal);
  }

  const deleteItem = async (tipo, item) => {

    if (tipo === 'obj') {
      let dataReq = {
        action: 'eliminar_objetivo',
        id: id,
        hash: hash,
        id_objetivo: item.id

      }
      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'objEliminado',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'objEliminado',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }

    } else if (tipo === 'meta') {
      let dataReq = {
        action: 'eliminar_meta',
        id: id,
        hash: hash,
        id_objetivo: handleChangeState.id_objetivo,
        id_meta: item.id

      }
      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'metaEliminada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'metaEliminada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    }

  }


  //funciones para gestionar tareas

  const openTarea = (item) => {

    getIds('tarea', item.id)

  }

  const handleSelect = (value, tipo) => {

    // setFormulario({
    //   ...formulario,
    //   [tipo] : value.value
    // })


    console.log(value, tipo)
    setRiesgosSelected(value)
    // //console.log(formulario)
  }





  return (
    <>
      <div className=' cardUnderPestana' style={{ padding: '0% 5%', marginTop: "0%" }}>
        <Card.Body>
          {/* {dataInicial.data.pdf && dataInicial.data.pdf !== '' &&
              <div className='buttonContainerDOFA'>
                <a target='_blank' href={dataInicial.data.pdf}>
                  <button style={{width: '130px'}}  className='confirmButton'>
                    Generar PDF
                    <GetAppIcon/>
                  </button>
                </a>
              </div>

          } */}
          <Card className='shadow ' style={{ height: "auto" }}>
            <Card.Body >
              {TitleIsEditing === false
                ?
                <Col style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', height: 'auto', minHeight: "auto" }}>
                  <div style={{ width: '79vh' }}>
                    <h4 className='pagesTitles' style={{ textAlign: 'justify', width: "100%", height: 'auto', minHeight: "28px" }}>{props.dataInicial.titulo}</h4>
                    {/* {props.dataInicial.fecha_inicio &&
                      <div style={{ display: 'flex' }}>
                        <p style={{ color: '#213265' }}>Fecha de inicio</p>
                        <p style={{ color: '#8b97a7' }} >:  {moment(props.dataInicial.fecha_inicio).format('DD MMMM YYYY')}</p>
                      </div>
                    }
                    {props.dataInicial.fecha_fin &&
                      <div style={{ display: 'flex' }}>
                        <p style={{ color: '#213265' }}>Fecha fin</p>
                        <p style={{ color: '#8b97a7' }}>: {moment(props.dataInicial.fecha_fin).format('DD MMMM YYYY')}</p>
                      </div>
                    } */}
                  </div>
                  {/* <div  style={{ height:'50px', width: '120px',  display: 'flex', alignItems:'center', justifyContent:'flex-end '}}>
                    <button
                      className='confirmButtonWhite'
                      onClick={() => setTitleIsEditing(true)}
                      style={{   margin: '0'}}
                    >
                      <EditOutlinedIcon fontSize='small'/>
                      Editar
                    </button> */}

                  {/* </div> */}

                </Col>
                :
                <Row>
                  <Row>
                    <Col>
                      <Row style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                        {/* <input
                          id='inputNewTitlePlanEstrategico'
                          type='text'
                          placeholder='Ingresa un titulo para el plan estratégico'
                          defaultValue={dataInicial.titulo}
                          onChange={(e) => handleChangeNewTitle(e)}
                        /> */}
                        <Row >
                          <Col>
                            <Form.Group xs={12} className='formGroupVertical'>
                              <Form.Label>Título</Form.Label>
                              <Form.Control
                                id=''
                                type='text'
                                name='titulo'
                                placeholder='Ingresa un titulo para el plan estratégico'
                                defaultValue={props.dataInicial.titulo}
                                onChange={(e) => handleChangeNewTitle(e)}
                              >
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row fluid>
                          <Col>
                            <Form.Group className='formGroupVertical'>
                              <Form.Label>Fecha de inicio</Form.Label>
                              <Form.Control
                                type='date'
                                onChange={(e) => handleChangeNewTitle(e)}
                                name='fecha_inicio'
                                defaultValue={props.dataInicial?.data.fecha_inicio}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className='formGroupVertical'>
                              <Form.Label>Fecha fin</Form.Label>
                              <Form.Control
                                type='date'
                                onChange={(e) => handleChangeNewTitle(e)}
                                name='fecha_fin'
                                defaultValue={props.dataInicial?.data.fecha_fin}

                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        {TitleIsEditing === false &&
                          <h4 >{props.dataInicial?.data?.porcentaje_cumplimiento}%</h4>
                        }
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    {(props.changeTitle.nuevo_titulo !== '' || props.dataInicial.titulo === props.changeTitle.nuevo_titulo) &&
                      <p style={{ height: '80px', minWidth: '70px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button
                          className='confirmButton'
                          // id='titleSaveButton'
                          // style={{display:'flex', alignItems:'center', height:'50%', borderRadius:'50%'}}
                          onClick={() => cambiarTitulo()}
                        >{t('botones.confirmar')}
                        </button>
                        <button
                          className='warningButton'
                          // id='cancelarEdicionTituloBoton'
                          onClick={() => setTitleIsEditing(false)}
                        >
                          {t('botones.cancelar')}
                        </button>
                      </p>
                    }
                  </Row>
                </Row>
              }
            </Card.Body>
          </Card>
          <br />
          <Card className='shadow cardUnderPestana' style={{ maxHeight: '81vh' }}>
            <Card.Body style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'auto',
              overflowY: 'auto', maxHeight: '250vh'
            }}>
              <Accordion className=' ' id='AcordeionInputsDofa' defaultActiveKey="0">
                {props.dataInicial?.objetivos?.length && <h5 className='pagesTitles' style={{ textAlign: 'justify', marginBottom: '10px', color: '#8B97A7' }} >{t('gestion_realizada.plan_estrategico.lista_objetivos')}</h5>}
                {props.dataInicial?.objetivos?.map((obj, indexObj) => {
                  return (
                    <>
                      <Card
                        key={obj.id}
                        style={{
                          overflow: 'visible'
                        }}
                      >
                        <Accordion.Toggle
                          eventKey={obj.id}
                          // as={Card.Header}
                          className='toggleObj accordion1'
                          onMouseOver={() => getIds('obj', obj.id)}
                          onClick={() => props.updateTimeline()}
                          style={{
                            backgroundColor: handleChangeState.id_objetivo === obj.id && '#cbd7e6',
                            color: handleChangeState.id_objetivo === obj.id && '',
                            height: 'auto'
                          }}

                        >
                          <div className='options_cont' >
                            <p style={{ display: 'flex', justifyContent: 'flex-end', minHeight: '30px' }}>
                              <div className='contenedorAccionesObj' style={{ width: '60px' }} >
                                <button
                                  onClick={() => openModal('verObj', true, obj)}
                                  className='detailButtonPE accionObj'
                                >
                                  <InfoOutlinedIcon fontSize='small' />
                                </button>
                                {/* <button
                                onClick={() => openModal('editObj', true, obj)}
                                className='editButtonPE accionObj'
                              >
                                <EditOutlinedIcon fontSize='small'/>
                              </button>
                              <button
                                className='deleteButtonPE accionObj'
                                onClick={() => deleteItem('obj', obj)}
                              >
                                <DeleteOutlineOutlinedIcon fontSize='small'/>
                              </button> */}
                              </div>
                              {obj.porcentaje_cumplimiento}%
                            </p>
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                              <div className='numeracionLabel'>
                                {(indexObj + 1)}
                              </div>
                              <p>
                                {obj.nombre}
                              </p>
                            </div>

                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={obj.id}>
                          <Card.Body>
                            <Accordion>
                              {obj?.metas?.length && <h5 className='pagesTitles' style={{ textAlign: 'justify', marginBottom: '10px', color: '#8B97A7' }} >{t('gestion_realizada.plan_estrategico.lista_metas')}</h5>}
                              {obj?.metas?.length > 0
                                &&
                                obj?.metas?.map((meta, indexMeta) => {
                                  return (
                                    <div key={meta.id}>
                                      <Accordion.Toggle
                                        className='accordionToggleMetas accordion1'
                                        // as={Card.Header}
                                        eventKey={meta.id}
                                        onMouseOver={() => getIds('meta', meta.id)}
                                        onClick={() => props.updateTimeline()}

                                        style={{
                                          height: 'auto',
                                          backgroundColor: handleChangeState.id_meta === meta.id && '#cbd7e6',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        <p
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            minHeight: '30px'
                                          }}
                                        >
                                          <div className='contenedorAccionesObj'>
                                            <button
                                              className='detailButtonPE accionObj'
                                              onClick={() => openModal('verMeta', true, meta)}

                                            >
                                              <InfoOutlinedIcon fontSize='small' />

                                            </button>

                                          </div>
                                          {meta.porcentaje_cumplimiento}%
                                        </p>
                                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                                          <span className='numeracionLabel'>
                                            {indexObj + 1}.{indexMeta + 1}
                                          </span>
                                          <p style={{ textAlign: 'justify', width: '100%' }}>
                                            {meta.nombre}
                                          </p>
                                        </div>

                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey={meta.id}>
                                        <div>
                                          {meta?.tareas?.length && <h5 className='pagesTitles' style={{ textAlign: 'justify', margin: '10px 0 10px 20px', color: '#8B97A7' }} >{t('gestion_realizada.plan_estrategico.lista_tareas')}</h5>}
                                          {meta.tareas && meta.tareas.length
                                            &&


                                            meta.tareas.map((tarea, indexTarea) => {
                                              return (
                                                <div
                                                  className='tareaItem'
                                                  onMouseOver={() => getIds('tarea', tarea.id)}
                                                  onClick={() => props.updateTimeline()}

                                                // onClick={() => openModal('tarea', true, tarea)}
                                                >
                                                  <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                                                    <span className='numeracionLabel'>
                                                      {indexObj + 1}.{indexMeta + 1}.{indexTarea + 1}
                                                    </span>
                                                    <p>
                                                      {tarea.nombre}
                                                    </p>
                                                  </div>
                                                  <p>

                                                    {tarea.porcentaje_cumplimiento}%
                                                  </p>


                                                </div>
                                              )
                                            })

                                          }
                                          {/* <div id='contenedorBotonAddTarea'>
                                          <button onClick={() => modalAddItem('tarea')} id='buttonAddTarea' >Añadir tarea</button>

                                        </div> */}
                                        </div>

                                      </Accordion.Collapse>
                                    </div>
                                  )
                                })
                              }
                              {/* <br/>
                            <button onClick={() => modalAddItem('meta')} id='buttonAddMeta' >Añadir meta</button> */}
                            </Accordion>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </>
                  )
                })

                }

              </Accordion>
              {/* <br/>
            <button onClick={() => modalAddItem('obj')} className='confirmButton' id='buttonAddObj'>Añadir objetivo</button> */}
            </Card.Body>
          </Card>

        </Card.Body>
      </div>
      <EditModal
        typeModal={tipoModal}
        isOpen={showModal}
        setIsOpen={setShowModal}
        handleAddItem={handleChangeItem}
        stateAddItem={addItem}
        sendNewItem={agregarItem}
        updateItem={editarItem}
        tituloObj={addItem.titulo}
        descripcionObj={addItem.descripcion}
        selectedids={handleChangeState}
        getDataInicial={getDataInicial}
        dataInicial={props.dataInicial}
        handleSelect={handleSelect}
      />
      <NotificationModal
        typeModal={notification.tipo}
        isOpen={notification.show}
        mensaje={notification.mensaje}
        setIsOpen={setNotification}
      />
    </>
  )
}
