import React, { useEffect, useState, useRef } from 'react';
import { Fragment } from 'react';
import { camelize } from '../../../helpers/textHelpers';
import { useParams } from 'react-router-dom';
import { ListGroup, Row, Col, Card, Accordion, Button, Form } from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GestionAuditoriasModal from '../../modales/GestionAuditoriasModal';
import ListIcon from '@material-ui/icons/List';
import { Badge } from '@material-ui/core';
import OpcionesCiudades from '../../../helpers/OpcionesCiudades';
import './ContainerQuestion.scss';
import NotificationModal from '../../modales/NotificationModal';
import { validarEntorno } from '../../../helpers/validarHost';
import EditModal from '../../modales/EditModal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { CircleOff, Waypoints, Sigma, Check, Ban, XCircle, Weight, Hash, List } from 'lucide-react';
import PopoverStickOnHover from '../../common/StickOnHover';
import { Dialog } from 'primereact/dialog';
import FormularioDinamico from '../../GestionadorDinamico/FormularioDinamico';
import { InputSwitch } from 'primereact/inputswitch';

/* Constantes para el manejo de los eventos */
const NUEVO_HALLAZGO = "NUEVO_HALLAZGO";
const LISTA_HALLAZGOS = "LISTA_HALLAZGOS";
const NUEVA_OBSERVACION = "NUEVA_OBSERVACION";
const LISTA_OBSERVACIONES = "LISTA_OBSERVACIONES";
const EDITAR_COMPONENTE = "EDITAR_COMPONENTE";


const ContainerQuestion = ({ tabId, tipo, ele, filtros }) => {

  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    data: {}
  })
  const { t } = useTranslation();

  const [modalFormOpen, setModalFormOpen] = useState(false); //Indica si se muestra o no la modal del formulario dinámico
  const [submitData, setSubmitData] = useState({}); //Datos que se enviarán al servidor al guardar el formulario
  const [modalTitle, setModalTitle] = useState(''); //Título de la modal
  const [criteriosComponente, setCriteriosComponente] = useState({}); //Criterios del componente

  const [dataQuestion, setDataQuestion] = useState([])
  const [dataOption, setDataOption] = useState({})
  const [seccionDetalle, setSeccionDetalle] = useState('riesgos')
  const [band, setBand] = useState(0);
  const [adk, setAdk] = useState(0);
  const [mode, setmode] = useState('');
  const [show, setShow] = useState(false);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [cuestionario, setCuestionario] = useState([]);
  const [data, setData] = useState({});
  const [idPregunta, setIdPregunta] = useState(0);
  const { id, seccion } = useParams();
  const inputRef = useRef();
  const [validated, setValidated] = useState(false);
  const [validatedFile, setValidatedFile] = useState(false);
  const [showEditModalNotificacion, setShowEditModalNotificacion] = useState(false);
  const [dataThirdLevel, setDataThirdLevel] = useState({
    nombre: '',
    descripcion: '',
    riesgos: [],
    normatividades: []
  });

  const [componenteHabilitado, setComponenteHabilitado] = useState(true);

  const handleOpenModalNotification = () => setShowModalNotification(true);
  const handleCloseModalNotification = () => setShowModalNotification(false);

  const handleOpenEditModalNotification = () => setShowEditModalNotificacion(false);
  const handleCloseEditModalNotification = () => setShowEditModalNotificacion(false);

  const Items = async () => {

    setCuestionario([])

    const dataSend = {
      id_sis: tabId,
      id_com: ele?.id_com ? ele?.id_com : '1',
      hash: window.location.hash,
      id: id,
      tipo: tipo
    };

    if (filtros) {
      dataSend.filtros = filtros;
    }

    const request = await validarEntorno('JSON/gestor_auditorias_preguntas_json.html', 'POST', dataSend);

    if (request.ok) {
      const respuesta = await request.json();

      const lt = respuesta.data;

      if (lt.length > 0) {
        setAdk(lt[0].id);
        setComponenteHabilitado(lt?.[0]?.activo ?? true);
        setCriteriosComponente(lt?.[0]?.criterios);

      }

      // tabId !== 1 && handleEventId(1)

      setCuestionario(lt);
    }
  }

  const getData3thLevel = (item) => {

    setDataThirdLevel({
      nombre: item.value,
      descripcion: item.descripcion,
      riesgos: item.riesgos ? item.riesgos : [],
      normatividades: item.normatividades ? item.normatividades : []
    });
    handleOpenEditModalNotification();

    setModal({ ...modal, tipo: 'detalleObs', show: true, data: item })
    setDataQuestion(item?.data)
  }

  const filterDataOptions = (id) => {
    let dataOption = dataQuestion
    const filterOption = dataOption.filter(option => option.id === id)
    setDataOption(filterOption[0])

  }

  useEffect(() => {
    if (tabId !== 0) {
      Items();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId]);

  const handleClose = (index, action, id, e) => {

    if (action === 'Guardar' && mode === 'form') {
      handleChange(2, index, id, e);
    }

    setValidated(false);
    setValidatedFile(false);
    setShow(false);
    setmode('');
  }

  const handleShow = async (index, mode, id, e) => {
    setShow(true);
    setBand(index);
    setmode(mode);
    setIdPregunta(id);
  }

  /**
   * Dependiendo del estado de la pregunta
   * se retorna el estado en forma de un icono espefico.
   * @param {*} status
   * @returns statusJsx (jsx element)
   */
  const statusIcon = (status) => {

    let statusJsx = '';

    switch (status) {
      case 1:
        statusJsx = <CheckIcon />;
        break;
      case 2:
        statusJsx = <HighlightOffIcon />;
        break;
      case 3:
        statusJsx = <NotInterestedIcon />;
        break;
      case 4:
        statusJsx = null;
        break;
      default:
        break;
    }

    return statusJsx;
  }

  /**
   * Validamos que tipo de campo tiene la pregunta
   * dependiendo de eso mostraremos un input especifico
   * @param {*} item
   * @returns field(jsx element)
   */
  const typeField = (item, is_edit) => {

    const { tipo, pregunta, valor } = item;

    let field = <></>;

    switch (tipo) {
      case 1:
        field = (
          <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
            <Form.Label className="">{pregunta === 'nit' ? pregunta.toUpperCase() : pregunta}</Form.Label>
            <Form.Control
              placeholder={pregunta === 'nit' ? pregunta.toUpperCase() : pregunta}
              name={camelize(pregunta)}
              onChange={handleChangeData}
              defaultValue={valor}
              disabled={is_edit}
              ref={inputRef}
            />
          </Form.Group>
        );
        break;
      case 2:
        field = (
          <Form.Group as={Col} lg={12} role="form" controlId="formGridTextArea">
            <Form.Label className="">{pregunta}</Form.Label>
            <Form.Control
              className="audit-form-textarea"
              as="textarea"
              rows={3}
              name={camelize(pregunta)}
              onChange={handleChangeData}
              defaultValue={valor}
              disabled={is_edit}
              ref={inputRef}
            />
          </Form.Group>
        );
        break;
      case 3:
        field = (
          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaNumber">
            <Form.Label className="">{pregunta}</Form.Label>
            <Form.Control
              className=""
              placeholder={pregunta}
              name={camelize(pregunta)}
              type="number"
              maxLength="10"
              onChange={handleChangeData}
              defaultValue={valor}
              disabled={is_edit}
              ref={inputRef}
            />
          </Form.Group>
        );
        break;
      case 4:
        field = (
          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaEmail">
            <Form.Label className="">{pregunta}</Form.Label>
            <Form.Control
              placeholder={pregunta}
              name={camelize(pregunta)}
              onChange={handleChangeData}
              defaultValue={valor}
              disabled={is_edit}
              ref={inputRef}
              type="date"
            />
          </Form.Group>
        );
        break;
      case 6:
        field = <OpcionesCiudades />;
        break;
      default:
        break;
    }

    return field;
  }

  const handleChange = async (valor, index, id, e) => {

    e.preventDefault();

    //Se obtiene el arreglo para hacer modificaciones sobre el mismo
    let items = [...cuestionario];

    //Obtenemos el indice como parametro, para editar los valores dentro del mismo
    let im = { ...items[index] };

    const filterQuestion = im.preguntas.filter((item) => {
      return item.id === id;
    });

    filterQuestion[0].valor = valor;


    const dataSend = {
      valor,
      id_registro: id,
      id_tab: tabId,
      id_cuestionario: adk,
      hash: window.location.hash
    };

    const response = await validarEntorno('JSON/respuesta_cuestionario_json.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();;

      if (respuesta.data.message === 'OK') {
        if (valor === 2) {
          filterQuestion[0].observaciones = 1;
        }
        //Le indicamos al arreglo que vamos a sobreescribir el objeto del arreglo
        //Por medio de su indice
        items[index] = im;

        //Lo cargamos en nuestro useState que maneja la vista de las preguntas
        setCuestionario(items);
      }
    }
  }

  const handleChangeData = async (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  }

  /**
   * Al ser formularios dinamicos, no se manejan estados
   * para la data por eso se hace el uso de un FormData para
   * enviar los datos desde el formulario por medio del target
   * @param {*} e
   */
  const sendDataForm = async (e) => {

    const dataForm = new FormData(e.target);

    e.preventDefault();

    const result = {};

    for (let [key, value] of dataForm.entries()) {
      result[key] = value;
    }

    const dataSend = {
      formData: result,
      id_registro: id,
      id_tab: tabId,
      id_cuestionario: adk,
      hash: window.location.hash
    };

    const response = await validarEntorno('JSON/respuesta_cuestionario_json.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();;

      if (respuesta.data.message) {
        setShowModalNotification(true);
      }

      Items();

    }
  }

  const getWeightColor = (weight) => {
    let color = 'var(--gray-700)';

    switch (weight) {
      case 1:
        color = "#00a00f";
        break;
      case 2:
        color = "#EAB308";
        break;
      case 3:
        color = '#ff4e00';
        break;
      default:
        break;
    }

    return color;
  }

  const getColorStatus = (status) => {
    let statusColor = 'var(--gray-300)';

    switch (status) {
      case 1:
        statusColor = '#00a00f';
        break;
      case 2:
        statusColor = '#ff4e00';
        break;
      case 3:
        statusColor = '#EAB308';
        break;
      case 4:
        statusColor = '#ffffff';
        break;
      default:
        break;
    }

    return statusColor;
  }

  const renderTooltip = (props) => {

    let message = '';

    if (props.popper.state) {
      message = props.popper.state.options.title;
    }

    return (
      <Tooltip id="tooltip-help" {...props}>
        {message}
      </Tooltip>
    );
  }


  const popover = (categorias) => (
    <div className='card__popover d-flex gap-1 flex-wrap'>
      {categorias?.map((item, index) => (
        <span key={index} className="p-mr-2 p-mb-2 regular__chip ">
          {item}
        </span>
      ))}
    </div>
  );

  /**
   * Controla la apertura de la modal dinámica 
   * @param {*} action Acción a realizar
   * @param {*} item  Item a editar
   */

  const onAction = (action, item) => {

    const { id: id_pregunta, id_cuestionario } = item;

    const datos = {
      hash: window.location.hash,
      id,
      accion: action,
      tab_id: tabId
    }

    if (id_pregunta) {
      datos.id_pregunta = id_pregunta;
    }

    if (id_cuestionario) {
      datos.id_cuestionario = id_cuestionario;
    }

    //console.log(datos)
    setSubmitData(datos)

    switch (action) {
      case NUEVO_HALLAZGO:
        setModalTitle(t('modales.nuevo_hallazgo'));
        break;
      case LISTA_HALLAZGOS:
        setModalTitle(t('modales.listado_hallazgos'));
        break;
      case NUEVA_OBSERVACION:
        setModalTitle(t('gestion_realizada.areas_diagnostico.nueva_descripcion_componente'));
        break;
      case LISTA_OBSERVACIONES:
        setModalTitle(t('gestion_realizada.areas_diagnostico.listado_observaciones'));
        break;
      default:
        setModalTitle("");
        break;
    }
    setModalFormOpen(true);
  }


  const handleToggleComponente = () => {
    return async (e) => {
      e.preventDefault();

      const dataSend = {
        accion: EDITAR_COMPONENTE,
        id: id,
        id_tab: tabId,
        id_cuestionario: adk,
        hash: window.location.hash,
        seccion: seccion,
        activo: !componenteHabilitado
      };


      const response = await validarEntorno('JSON/GestionDinamica/agregar_gestion_simple.php', 'POST', dataSend);

      if (response.ok) {
        // eslint-disable-next-line no-use-before-define
        const respuesta = await response.json();;

        if (respuesta.estado === 'OK') {
          setComponenteHabilitado(!componenteHabilitado);
        }
      }
    }
  }

  const accordionQuestion = cuestionario.map(
    (item, idx) => (
      <Card >
        <div className="headerComponente">
          <div className="tituloComponente" as={Button} variant="link" eventKey={item.id} onClick={() => setAdk(item.id)}>
            {ele?.nombre}
          </div>
          <div className="items-right pr-1">
            {/*
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
              popperConfig={{ title: t('gestion_realizada.areas_diagnostico.nueva_descripcion_componente') }}
            >
              <AddCircleOutlineIcon 
              className='botonOpcionesCriterio' 
              onClick={() => { onAction(NUEVA_OBSERVACION, {}) }}
              //onClick={(e) => handleShow(idx, 'form-obs-third', item.id, e)}
              />
            </OverlayTrigger>
            */}
            <OverlayTrigger
              placement="top"
              overlay={renderTooltip}
              popperConfig={{ title: componenteHabilitado ? t('botones.deshabilitar_componente') : t('botones.habilitar_componente') }}
            >
              <div className='d-flex align-items-center'>
                <InputSwitch checked={componenteHabilitado} onChange={handleToggleComponente()} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
              popperConfig={{ title: t('gestion_realizada.areas_diagnostico.listado_descripciones') }}
            >
              <div className='icon__button'>
                <button onClick={() => { onAction(LISTA_OBSERVACIONES, {}) }}>
                  <List/>
                </button>
              </div>
            </OverlayTrigger>
          </div>
        </div>
        {criteriosComponente && componenteHabilitado && (
          <div>
            <div className='tarjeta__criterios'>
              <div className="criterio">
                <Sigma size={24} color="var(--blue-500)" />
                <div className='criterio__info'>
                  <span>Total</span>
                  <span>{criteriosComponente?.total}</span>
                </div>
              </div>
              <div className="criterio">
                <Check size={24} color={getColorStatus(1)} />
                <div className='criterio__info'>
                  <span>{t("formulario.label.cumple")}</span>
                  <span>{criteriosComponente?.cumple}</span>
                </div>
              </div>
              <div className="criterio">
                <XCircle size={24} color={getColorStatus(2)} />
                <div className='criterio__info'>
                  <span>{t("formulario.label.no_cumple")}</span>
                  <span>{criteriosComponente?.no_cumple}</span>
                </div>
              </div>
              <div className="criterio">
                <Ban size={24} color={getColorStatus(3)} />
                <div className='criterio__info'>
                  <span>{t("formulario.label.no_aplica")}</span>
                  <span>{criteriosComponente?.no_aplica}</span>
                </div>
              </div>
            </div>
          </div>
        )}


        <Card.Body className='scrollSecundario bodyCardQuestions'>
          {/* { tipo === 1 && (
                            <div className="items-audit-left">
                                <Button className="audit-button-modal" id="abm-left" onClick={() => handleShow(idx, 'form-obs')}>Añadir</Button>
                                { item.observaciones > 0  ?
                                    (<Button className="audit-button-modal" id="abm-right" onClick={() => handleShow(idx, 'report')}>Ver</Button>)
                                : null }
                            </div>
                        ) } */}
          <ListGroup >
            {tipo === 1 ? (
              <div className="container-audit-form">
                <Form className="audit-form" onSubmit={sendDataForm}>
                  <Form.Row>
                    {
                      item.preguntas.map((itemP, index) => (
                        typeField(itemP, item.is_edit)
                      ))
                    }
                  </Form.Row>
                  <Form.Row className="btn-form-row">
                    {!item.is_edit ? <Button className="confirmButton" type="submit">Enviar</Button> : null}
                  </Form.Row>
                </Form>
              </div>
            ) : (
              componenteHabilitado ? (
                item.preguntas.map((itemP, index) => (
                  <ListGroup.Item
                    className="item-question"
                    style={{ height: 'auto' }}>
                    <div className="container-question">
                      {itemP?.valor != 0 && (
                        <div className="icon-question-res" style={{ backgroundColor: `${getColorStatus(itemP.valor)}` }}>
                          <span className="sp-icon-res">
                            {itemP.valor ? statusIcon(itemP.valor) : null}
                          </span>
                        </div>
                      )}
                      <div className="question-content" >
                        <p className="p-question">{itemP.pregunta}</p>
                        <div className='question-actions'>
                          <div className="icon-list-question">
                            {itemP?.categorias && itemP?.categorias.length > 0 &&
                              <span className="sp-icon" style={{ backgroundColor: item.valor === 1 ? getColorStatus(item.valor) : 'transparent' }} >
                                <PopoverStickOnHover
                                  component={popover(itemP?.categorias)}
                                  placement="top"
                                  onMouseEnter={() => { }}
                                  delay={200}
                                >
                                  <Waypoints className='botonOpcionesCriterio' size={28} color='var(--blue-800)' />
                                </PopoverStickOnHover>
                              </span>
                            }
                            {itemP.observaciones > 0 ? (

                              <span className="sp-icon">
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                  popperConfig={{ title: t('formulario.label.hallazgo') }}
                                >
                                  <Badge
                                    fontSize="small"
                                    badgeContent={itemP.observaciones}
                                    color="error"
                                    max={5}
                                  >
                                    <AssignmentLateOutlinedIcon
                                      style={{ color: '#f44336' }}
                                      onClick={() => { onAction(LISTA_HALLAZGOS, itemP) }} />
                                  </Badge>
                                </OverlayTrigger>
                              </span>)
                              : null
                            }
                            <span className="sp-icon" style={{ backgroundColor: item.valor === 1 ? getColorStatus(item.valor) : 'transparent' }} >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                                popperConfig={{ title: t('gestion_realizada.areas_diagnostico.normatividad_riesgos') }}
                              >
                                <DataUsageIcon className='botonOpcionesCriterio' onClick={() => getData3thLevel(itemP)} style={{ color: "var(--gray-500)" }} />
                              </OverlayTrigger>
                            </span>
                            <span className="sp-icon" style={{ backgroundColor: item.valor === 1 ? getColorStatus(item.valor) : 'transparent' }}>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                                popperConfig={{ title: t('gestion_realizada.areas_diagnostico.cumplimiento') }}
                              >
                                <CheckIcon style={{ color: itemP.valor === 1 ? '#00a00f' : 'var(--gray-500)' }} className={itemP.valor === 1 ? 'selected-status' : null} onClick={(e) => handleChange(1, idx, itemP.id, e)} />
                              </OverlayTrigger>
                            </span>
                            <span className="sp-icon" style={{ backgroundColor: item.valor === 2 ? getColorStatus(item.valor) : 'transparent' }} >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                                popperConfig={{ title: t('gestion_realizada.areas_diagnostico.incumplimiento') }}
                              >
                                <HighlightOffIcon
                                  style={{ color: itemP.valor === 2 ? '#ff4e00' : 'var(--gray-500)' }}
                                  className={itemP.valor === 2 ? 'selected-status' : null}
                                  onClick={() => { onAction(NUEVO_HALLAZGO, itemP) }} />
                              </OverlayTrigger>
                            </span>
                            <span className="sp-icon" style={{ backgroundColor: item.valor === 3 ? getColorStatus(item.valor) : 'transparent' }}>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                                popperConfig={{ title: t('gestion_realizada.areas_diagnostico.no_aplica') }}
                              >
                                <NotInterestedIcon style={{ color: itemP.valor === 3 ? '#EAB308' : 'var(--gray-500)' }} className={itemP.valor === 3 ? 'selected-status' : null} onClick={(e) => handleChange(3, idx, itemP.id, e)} />
                              </OverlayTrigger>
                            </span>
                          </div>
                          <div className='question-info'>
                            <div className='info-item'>
                              <Weight size={16} color={getWeightColor(itemP.peso)} />
                              <span>{itemP.peso}</span>
                            </div>
                            <div className='info-item'>
                              <Hash size={16} />
                              <span>{itemP.id}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))) : (
                <div className='empty__card'>
                  <div className="icon">
                    <CircleOff size={48} />
                  </div>
                  <span>{t('formulario.label.componente_deshabilitado')}</span>
                </div>)
            )}
          </ListGroup>
        </Card.Body>
      </Card>
    )
  );

  return (
    <Fragment>
      <Accordion className='accordionAuditorias h-100 pt-3' style={{ marginLeft: '20px' }}>
        {accordionQuestion}
      </Accordion>
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal='saveQuestions'
      />
      <EditModal
        isOpen={showEditModalNotificacion}
        setIsOpen={handleCloseEditModalNotification}
        typeModal="detalleTercerNivel"
        item={dataThirdLevel}
      />
      <EditModal
        isOpen={modal.show}
        setIsOpen={setModal}
        typeModal={modal.tipo}
        dataModal={modal.data}
        seccionesDetalle={seccionDetalle}
        handleSeccionesDetalle={setSeccionDetalle}
        filterDataOptions={filterDataOptions}
        infoItem={ele}
        dataOption={dataOption}
      // item={dataThirdLevel}
      />
      <GestionAuditoriasModal
        show={show}
        isOpen={show}
        handleClose={handleClose}
        index={band}
        mode={mode}
        id_registro={id}
        id_cuestionario={adk}
        id_tab={tabId}
        id_pregunta={idPregunta}
        handleOpenModalNotification={handleOpenModalNotification}
        validated={validated}
        setValidated={setValidated}
        validatedFile={validatedFile}
        setValidatedFile={setValidatedFile}
        fetchQuestions={Items}
        handleSubmit={sendDataForm}
      />

      {/*
      <ModalCRUD
        isOpen={modalFormOpen}
        setIsOpen={setModalFormOpen}
        getFormData={submitData}
        submitData={submitData}
        titulo={modalTitle}
        urlEstructura={urlEstructura}
        urlEnvio={urlEnvio}>
      </ModalCRUD>
*/}

      <Dialog
        visible={modalFormOpen}
        onHide={() => { setModalFormOpen(false) }}
        breakpoints={{ '992px': '100vw', '5000px': '75vw' }}
      >
        <div className="modal__form__nested__body" >
          <FormularioDinamico anidado={true} titulo={modalTitle} datosSolicitud={submitData} handleEnviar={()=>{
            setModalFormOpen(false);
            Items();
          }} />
        </div>
      </Dialog>


    </Fragment>
  );

}

export default ContainerQuestion;
