import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../helpers/validarHost';
import { Accordion, Form, Card, Row, Col } from 'react-bootstrap';

import NotificationModal from '../../modales/NotificationModal'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';

import EditModal from '../../modales/EditModal';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment'
import { useTranslation } from 'react-i18next';


import './PlanEstrategico.scss'


export default function PlanEstrategico(props) {

  //console.log(props)

  const { id } = useParams()
  const hash = window.location.hash
  const { t } = useTranslation();
  const [riesgosSelected, setRiesgosSelected] = useState([])
  const [responsablesSelected, setResponsablesSelected] = useState([])
  const [numeracionPadre, setNumeracionPadre] = useState(1)
  const [confirmDialog, setConfirmDialog] = useState(false); //Indica si se muestra el modal de confirmación
  const [eliminacionPendiente, setEliminacionPendiente] = useState(null); //Indica si se está esperando la respuesta de eliminación

  const [tipoModal, setTipoModal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [TitleIsEditing, setTitleIsEditing] = useState(false);

  const [notification, setNotification] = useState({
    tipo: '',
    show: false,
    mensaje: ''
  })

  const [handleChangeState, setHandleChangeState] = useState({
    id_objetivo: '',
    id_meta: '',
    id_tarea: '',
    id_actividad: '',
    id_accion: ''
  })


  const [changeTitle, setChangeTitle] = useState({})

  const [riesgosObj, setRiesgosObj] = useState({})

  const openModal = (tipo, show, item, numeracionPadre = 1) => {
    if (tipo && show) {
      setRiesgosSelected(item.riesgos)
      setResponsablesSelected(item.responsables)

      setTipoModal(tipo)
      setShowModal(show);
      setAddItem({
        titulo: item.nombre,
        descripcion: item.descripcion,
        riesgos: item.riesgos,
        responsables: item.responsables

      })

      if (tipo === 'tarea') {
        setTipoModal('planEstrategico');
        setShowModal(show);
        setNumeracionPadre(numeracionPadre)
        setHandleChangeState({
          ...handleChangeState,
          id_tarea: item.id
        })
      }

    }
    else return null
  }

  // //console.log(props.data)

  const [dataInicial, setDataInicial] = useState({
    permisos: {},
    data: {}
  })



  const getDataInicial = async () => {

    const dataReq = {
      id: id,
      hash: hash
    }

    const req = await validarEntorno('JSON/per.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()

      // console.log(res)

      setDataInicial({
        ...dataInicial,
        data: res,
        riesgos: res.riesgos,
        titulo: res.titulo_plan,
        objetivos: res.contenido.objetivos,
        fecha_inicio: res.fecha_inicio,
        fecha_fin: res.fecha_fin,
        permisos: res.permisos,
        responsables: res.responsables
      })

      setChangeTitle({
        ...changeTitle,
        titulo: res.titulo_plan,
        fecha_inicio: res.fecha_inicio,
        fecha_fin: res.fecha_fin,
      })

    }

  }


  useEffect(() => {

    getDataInicial()



    // //console.log(dataInicial)
  }, [])



  const handleChangeNewTitle = ({ target }) => {
    setChangeTitle({
      ...changeTitle,
      [target.name]: target.value
    })

    // //console.log(changeTitle)
  }

  const cambiarTitulo = async () => {
    const dataReq = {
      id: id,
      hash: hash,
      dataForm: changeTitle
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/auditorias/plan_estrategico/act_titulo_pe.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      setDataInicial({
        ...dataInicial,
        data: props.data,
        titulo: res.titulo ? res.titulo : changeTitle.titulo,
        fecha_inicio: res.fecha_inicio ? res.fecha_inicio : changeTitle.fecha_inicio,
        fecha_fin: res.fecha_fin ? res.fecha_fin : changeTitle.fecha_fin
      })
      setTitleIsEditing(false)
      setNotification({
        tipo: 'tituloActualizado',
        show: true
      })
    } else {
      setNotification({
        tipo: 'error',
        show: true
      })
    }
  }


  const [addItem, setAddItem] = useState({
    titulo: '',
    descripcion: ''
  })

  const handleChangeItem = ({ target }) => {
    setAddItem({
      ...addItem,
      [target.name]: target.value
    })
    console.log(target.value)
  }

  const agregarItem = async (tipo) => {

    if (tipo === 'obj') {
      const dataReq = {
        action: 'crear_objetivo',
        id: id,
        hash: hash,
        titulo: addItem.descripcion,
        descripcion: addItem.descripcion,
        riesgos: riesgosSelected,
        responsables: responsablesSelected
      }

      console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()

        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'objAgregado',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }

    }
    else if (tipo === 'meta') {
      const dataReq = {
        action: 'crear_meta',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo
      }

      console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'metaAgregada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'metaAgregada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    } else if (tipo === 'tarea') {
      const dataReq = {
        action: 'crear_tarea',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo,
        id_meta: handleChangeState.id_meta
      }

      //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'tareaAgregada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'tareaAgregada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }

      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    }
  }

  const editarItem = async (tipo) => {

    if (tipo === 'obj') {
      const dataReq = {
        action: 'editar_objetivo',
        id: id,
        hash: hash,
        titulo: addItem.descripcion,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo,
        riesgos: riesgosSelected,
        responsables: responsablesSelected
      }

      //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()


        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'objActualizado',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'objActualizado',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }

    }
    else if (tipo === 'meta') {
      const dataReq = {
        action: 'editar_meta',
        id: id,
        hash: hash,
        titulo: addItem.titulo,
        descripcion: addItem.descripcion,
        id_objetivo: handleChangeState.id_objetivo,
        id_meta: handleChangeState.id_meta
      }

      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'metaActualizada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'metaActualizada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    }
    else if (tipo === 'actividad') {
      const dataReq = {
        action: 'editar_act',
        id: id,
        hash: hash,
        id_objetivo: handleChangeState.id_objetivo,
        id_meta: handleChangeState.id_meta,
        id_tarea: handleChangeState.id_tarea
      }

      // //console.log(dataReq)


      const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        // setDataInicial({
        //   ...dataInicial,
        //   objetivos: res.objetivos
        // })
        // setShowModal(false)
        // setNotification({
        //   tipo: 'metaActualizada',
        //   show: true
        // })
        if (res.estado === 'ok') {

          await getDataInicial()

          setShowModal(false)
          setNotification({
            tipo: 'metaActualizada',
            show: true,
            mensaje: res.mensaje
          })

        } else {
          setNotification({
            tipo: 'errorFormSolicitud',
            show: true,
            mensaje: res.mensaje
          })
        }
      } else {
        setNotification({
          tipo: 'error',
          show: true
        })
      }
    }
  }



  const getIds = (tipo, id) => {
    console.log(tipo, id)

    if (tipo === 'obj') {
      setHandleChangeState({
        ...handleChangeState,
        id_objetivo: id
      })

    } else if (tipo === 'meta') {
      //Async callback to await setState
      setHandleChangeState(prevState => ({
        ...prevState,
        id_meta: id
      }))

    } else if (tipo === 'tarea') {
      setHandleChangeState({
        ...handleChangeState,
        id_tarea: id
      })
    }


    // //console.log(handleChangeState)

  }

  const modalAddItem = (tipo) => {
    setRiesgosSelected([])
    setResponsablesSelected([])
    setAddItem({
      titulo: '',
      descripcion: ''
    })


    if (tipo === 'obj') {
      setTipoModal('addObj');
    } else if (tipo === 'meta') {
      setTipoModal('addMeta');
    } else if (tipo === 'tarea') {
      setTipoModal('addTarea')
    }

    setShowModal(!showModal);
  }

  const confirmDeleteItem = async () => {
    if (eliminacionPendiente) {
      setConfirmDialog(false);
      const tipo = eliminacionPendiente.tipo;
      const item = eliminacionPendiente.item;
      if (tipo === 'obj') {
        let dataReq = {
          action: 'eliminar_objetivo',
          id: id,
          hash: hash,
          id_objetivo: item.id
        }

        const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

        if (req.ok) {
          const res = await req.json()
          // setDataInicial({
          //   ...dataInicial,
          //   objetivos: res.objetivos
          // })
          // setShowModal(false)
          // setNotification({
          //   tipo: 'objEliminado',
          //   show: true
          // })
          if (res.estado === 'ok') {

            await getDataInicial()

            setShowModal(false)
            setNotification({
              tipo: 'objEliminado',
              show: true,
              mensaje: res.mensaje
            })

          } else {
            setNotification({
              tipo: 'errorFormSolicitud',
              show: true,
              mensaje: res.mensaje
            })
          }
        } else {
          setNotification({
            tipo: 'error',
            show: true
          })
        }

      } else if (tipo === 'meta') {
        let dataReq = {
          action: 'eliminar_meta',
          id: id,
          hash: hash,
          id_objetivo: handleChangeState.id_objetivo,
          id_meta: item.id

        }
        // //console.log(dataReq)


        const req = await validarEntorno('JSON/auditorias/plan_estrategico/gestion_obj_pe.html', 'POST', dataReq)

        if (req.ok) {
          const res = await req.json()
          // setDataInicial({
          //   ...dataInicial,
          //   objetivos: res.objetivos
          // })
          // setShowModal(false)
          // setNotification({
          //   tipo: 'metaEliminada',
          //   show: true
          // })
          if (res.estado === 'ok') {

            await getDataInicial()

            setShowModal(false)
            setNotification({
              tipo: 'metaEliminada',
              show: true,
              mensaje: res.mensaje
            })

          } else {
            setNotification({
              tipo: 'errorFormSolicitud',
              show: true,
              mensaje: res.mensaje
            })
          }
        } else {
          setNotification({
            tipo: 'error',
            show: true
          })
        }
      }
    }
  }

  const deleteItem = async (tipo, item) => {
    setEliminacionPendiente({ tipo, item });
    setConfirmDialog(true);
  }


  //funciones para gestionar tareas

  const openTarea = (item) => {

    getIds('tarea', item.id)

  }

  const handleSelect = (value, tipo) => {

    // setFormulario({
    //   ...formulario,
    //   [tipo] : value.value
    // })


    //console.log(value, tipo)
    setRiesgosSelected(value)
    // //console.log(formulario)
  }

  /**
   * Controla el select de responsables
   * @param {*} value valor del select
   */

  const handleSelectResponsables = (value) => {
    setResponsablesSelected(value)
  }

  return (
    <>
      <Card className='card__box__surface cardUnderPestana' style={{ padding: '3% 5%' }}>
        <Card.Body>
          {dataInicial.data.pdf && dataInicial.data.pdf !== '' &&
            <div className='buttonContainerDOFA'>
              <a target='_blank' href={dataInicial.data.pdf}>
                <button style={{ width: '130px' }} className='confirmButton'>
                  {t('botones.generar_pdf')}
                  <GetAppIcon />
                </button>
              </a>
            </div>

          }
          <Card className='shadow '>
            <Card.Body >
              {TitleIsEditing === false
                ?
                <Col style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', height: 'auto' }}>
                  <div style={{ width: '95%' }}>
                    <h4 className='pagesTitles' style={{ textAlign: 'justify', paddingRight: '8%', marginBottom: '10px', height: 'auto' }}>{dataInicial.titulo}</h4>
                    {dataInicial.fecha_inicio &&
                      <div style={{ display: 'flex' }}>
                        <p style={{ color: '#213265' }}>{t('formulario.label.fecha_inicio')}</p>
                        <p style={{ color: '#8b97a7' }} >:  {moment(dataInicial.fecha_inicio).format('DD MMMM YYYY')}</p>
                      </div>
                    }
                    {dataInicial.fecha_fin &&
                      <div style={{ display: 'flex' }}>
                        <p style={{ color: '#213265' }}>{t('formulario.label.fecha_fin')}</p>
                        <p style={{ color: '#8b97a7' }}>: {moment(dataInicial.fecha_fin).format('DD MMMM YYYY')}</p>
                      </div>
                    }
                  </div>
                  <div style={{ height: '50px', width: '120px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end ' }}>
                    {dataInicial.permisos?.editar !== false &&
                      <button
                        className='confirmButtonWhite'
                        // id='titleEditButton'
                        onClick={() => setTitleIsEditing(true)}
                        style={{ margin: '0' }}>
                        <EditOutlinedIcon fontSize='small' />
                        {t('botones.editar')}
                      </button>
                    }

                  </div>

                </Col>
                :
                <Row>
                  <Row>
                    <Col>
                      <Row style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                        {/* <input
                          id='inputNewTitlePlanEstrategico'
                          type='text'
                          placeholder='Ingresa un titulo para el plan estratégico'
                          defaultValue={dataInicial.titulo}
                          onChange={(e) => handleChangeNewTitle(e)}
                        /> */}
                        <Row >
                          <Col>
                            <Form.Group xs={12} className='formGroupVertical'>
                              <Form.Label>{t('formulario.label.titulo')}</Form.Label>
                              <Form.Control
                                id=''
                                type='text'
                                name='titulo'
                                placeholder={t('formulario.label.ingresa_titulo_plan_estrategico')}
                                defaultValue={dataInicial.titulo}
                                onChange={(e) => handleChangeNewTitle(e)}
                              >
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row fluid>
                          <Col>
                            <Form.Group className='formGroupVertical'>
                              <Form.Label>{t('formulario.label.fecha_inicio')}</Form.Label>
                              <Form.Control
                                type='date'
                                onChange={(e) => handleChangeNewTitle(e)}
                                name='fecha_inicio'
                                defaultValue={dataInicial.fecha_inicio}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className='formGroupVertical'>
                              <Form.Label>{t('formulario.label.fecha_fin')}</Form.Label>
                              <Form.Control
                                type='date'
                                onChange={(e) => handleChangeNewTitle(e)}
                                name='fecha_fin'
                                defaultValue={dataInicial.fecha_fin}

                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        {TitleIsEditing === false &&
                          <h4 >{dataInicial?.data?.porcentaje_cumplimiento}%</h4>
                        }
                      </Row>

                    </Col>
                  </Row>
                  <Row>
                    <p style={{ height: '80px', minWidth: '70px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <button
                        className='confirmButton'
                        // id='titleSaveButton'
                        // style={{display:'flex', alignItems:'center', height:'50%', borderRadius:'50%'}}
                        onClick={() => cambiarTitulo()}
                      >{t('botones.confirmar')}
                      </button>
                      <button
                        className='warningButton'
                        // id='cancelarEdicionTituloBoton'
                        onClick={() => setTitleIsEditing(false)}
                      >
                        {t('botones.cancelar')}
                      </button>
                    </p>
                  </Row>
                </Row>




              }
            </Card.Body>
          </Card>
          <br />
          <Card className='shadow cardUnderPestana'>
            <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Accordion className=' ' id='AcordeionInputsDofa' defaultActiveKey="0">
                {dataInicial?.objetivos?.length && <h5 className='pagesTitles' style={{ textAlign: 'justify', marginBottom: '10px', color: '#8B97A7' }} >{t('gestion_realizada.plan_estrategico.lista_objetivos')}</h5>}
                {dataInicial?.objetivos?.map((obj, indexObj) => {
                  return (
                    <>
                      <Card key={obj.id} onMouseDown={() => { getIds('obj', obj.id) }}>
                        <Accordion.Toggle
                          eventKey={obj.id}
                          as={Card.Header}
                          className='toggleObj'
                        >
                          <div
                            style={{ minHeight: '35px', width: '100%', display: 'flex', justifyContent: 'space-between' }}
                          >
                            <p style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '80%' }}>
                              <div className='numeracionLabel'>
                                {(indexObj + 1)}
                              </div>
                              <p>{obj.nombre}</p>
                            </p>
                            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div className='contenedorAccionesObj'>
                                {obj.permisos?.ver !== false &&
                                  <button
                                    onClick={() => openModal('verObj', true, obj)}
                                    className='detailButtonPE accionObj'>
                                    <InfoOutlinedIcon fontSize='small' />
                                  </button>
                                }
                                {obj.permisos?.editar !== false &&
                                  <button
                                    onClick={() => openModal('editObj', true, obj)}
                                    className='editButtonPE accionObj'>
                                    <EditOutlinedIcon fontSize='small' />
                                  </button>
                                }
                                {obj.permisos?.eliminar !== false &&
                                  <button
                                    className='deleteButtonPE accionObj'
                                    onClick={() => deleteItem('obj', obj)}>
                                    <DeleteOutlineOutlinedIcon fontSize='small' />
                                  </button>
                                }
                              </div>
                              {obj.porcentaje_cumplimiento}%
                            </p>

                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={obj.id}>
                          <Card.Body>
                            <Accordion>
                              {obj?.metas?.length && <h5 className='pagesTitles' style={{ textAlign: 'justify', marginBottom: '10px', color: '#8B97A7' }} >{t('gestion_realizada.plan_estrategico.lista_metas')}</h5>}
                              {obj?.metas?.length > 0
                                &&
                                obj?.metas?.map((meta, indexMeta) => {
                                  return (
                                    <Card key={meta.id} onClick={() => { getIds('meta', meta.id) }}>
                                      <Accordion.Toggle
                                        className='accordionToggleMetas'
                                        as={Card.Header}
                                        eventKey={meta.id}
                                        onMouseDown={() => { getIds('meta', meta.id) }}
                                      >
                                        <p style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '80%' }}>
                                          <span className='numeracionLabel'>
                                            {indexObj + 1}.{indexMeta + 1}
                                          </span>
                                          {meta.nombre}
                                        </p>
                                        <p style={{ justifyItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                          <div className='contenedorAccionesObj'>
                                            {meta.permisos?.ver !== false &&
                                              <button
                                                className='detailButtonPE accionObj'
                                                onClick={() => openModal('verMeta', true, meta)}>
                                                <InfoOutlinedIcon fontSize='small' />
                                              </button>
                                            }
                                            {meta.permisos?.editar !== false &&
                                              <button
                                                onClick={() => openModal('editMeta', true, meta)}
                                                className='editButtonPE accionObj'>
                                                <EditOutlinedIcon fontSize='small' />
                                              </button>
                                            }
                                            {meta.permisos?.eliminar !== false &&
                                              <button
                                                onClick={() => deleteItem('meta', meta)}
                                                className='deleteButtonPE accionObj' >
                                                <DeleteOutlineOutlinedIcon fontSize='small' />
                                              </button>
                                            }
                                          </div>
                                          {meta.porcentaje_cumplimiento}%
                                        </p>

                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey={meta.id}>
                                        <div>
                                          {meta?.tareas?.length && <h5 className='pagesTitles' style={{ textAlign: 'left', margin: '10px 0 10px 20px', color: '#8B97A7' }} >{t('gestion_realizada.plan_estrategico.lista_tareas')}</h5>}
                                          {meta.tareas && meta.tareas.length
                                            &&


                                            meta.tareas.map((tarea, indexTarea) => {
                                              return (
                                                <div
                                                  className='tareaItem'
                                                  onClick={() => openModal('tarea', true, tarea, `${indexObj + 1}.${indexMeta + 1}.${indexTarea + 1}`)}
                                                >
                                                  <div style={{ display: 'flex', alignItems: 'flex-start', maxWidth: '40%' }}>
                                                    <span className='numeracionLabel'>
                                                      {indexObj + 1}.{indexMeta + 1}.{indexTarea + 1}
                                                    </span>
                                                    {tarea.nombre}
                                                  </div>
                                                  <p>
                                                    {tarea.porcentaje_cumplimiento}%
                                                  </p>


                                                </div>
                                              )
                                            })

                                          }
                                          {meta.permisos?.agregar !== false &&
                                            <div id='contenedorBotonAddTarea'>
                                              <button onClick={() => modalAddItem('tarea')} id='buttonAddTarea' >{t('botones.anadir_tarea')} a meta {indexObj + 1}.{indexMeta + 1}</button>
                                            </div>
                                          }
                                        </div>

                                      </Accordion.Collapse>
                                    </Card>
                                  )
                                })
                              }
                              <br />
                              {obj.permisos?.agregar !== false &&
                                <button onClick={() => modalAddItem('meta')} id='buttonAddMeta' >{t('botones.anadir_meta')} a objetivo {indexObj + 1}</button>
                              }
                            </Accordion>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </>
                  )
                })

                }

              </Accordion>
              <br />
              <button onClick={() => modalAddItem('obj')} className='confirmButton' id='buttonAddObj'>{t('botones.anadir_objetivo')}</button>
            </Card.Body>
          </Card>

        </Card.Body>
      </Card>
      <EditModal
        typeModal={tipoModal}
        isOpen={showModal}
        setIsOpen={setShowModal}
        handleAddItem={handleChangeItem}
        stateAddItem={addItem}
        sendNewItem={agregarItem}
        updateItem={editarItem}
        tituloObj={addItem.titulo}
        descripcionObj={addItem.descripcion}
        selectedids={handleChangeState}
        getDataInicial={getDataInicial}
        dataInicial={dataInicial}
        handleSelect={handleSelect}
        handleSelectResponsables={handleSelectResponsables}
        numeracionPadre={numeracionPadre}
      />
      <NotificationModal
        typeModal={notification.tipo}
        isOpen={notification.show}
        mensaje={notification.mensaje}
        setIsOpen={setNotification}
      />
      {/* Modal de confirmación de eliminación */}
      <NotificationModal
        typeModal={"deleteItemConfirm"}
        isOpen={confirmDialog}
        setIsOpen={setConfirmDialog}
        handleEliminar={confirmDeleteItem}
        itemName={'elemento'}
        />
    </>
  )
}
