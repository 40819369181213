import React, {useEffect} from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

// import { Card } from 'react-bootstrap'

function GraficaPanorama(props,) {

  // console.log(props.data)

  let chart = am4core.create('panorama1', am4charts.RadarChart);

  const exportGrafica = async () => {

    let imag3e = await chart.exporting.getImage("png", {scale: 2})

    props.setPrintImg(imag3e)


    // let target = chart.chartAndLegendContainer;

    // let svg = chart.exporting.normalizeSVG(
    //   chart.exporting.serializeElement(target.dom),
    //   {},
    //   target.pixelWidth,
    //   target.pixelHeight
    // );


    // props.setPrintImg(svg)


  }

  chart.startDuration = 0;
  chart.startAngle = -90;
  chart.endAngle = 180;
  chart.innerRadius = am4core.percent(20);
  chart.interpolationDuration = 0;
  chart.padding = 5

  /* Add data */
  chart.data = props.data
  // ? props.data : [{
  //   "country": "Hurto a casetas",
  //   "value1": 2,
  //   "value2": 2,
  //   "value3": 0,
  //   "value4": 0,
  //   "value5": 0
  // }, {
  //   "country": "Secuestro",
  //   "value1": 2,
  //   "value2": 2,
  //   "value3": 2,
  //   "value4": 6,
  //   "value5": 0
  // }, {
  //   "country": "Ataque fuerza pública",
  //   "value1": 2,
  //   "value2": 2,
  //   "value3": 2,
  //   "value4": 6,
  //   "value5": 0
  // }, {
  //   "country": "Atentado oleoducto",
  //   "value1": 2,
  //   "value2": 2,
  //   "value3": 2,
  //   "value4": 6,
  //   "value5": 0
  // }];

  /* Create axes */
  var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "country";
  categoryAxis.renderer.labels.template.horizontalCenter = "right";
  categoryAxis.renderer.labels.template.location = 0.1;
  categoryAxis.renderer.labels.template.fillOpacity = 0.9;
  categoryAxis.renderer.labels.template.fontWeight = 400;
  categoryAxis.renderer.labels.template.fontSize = 9;
  //categoryAxis.renderer.labels.template.colorFont = "#asdr12";
  categoryAxis.renderer.minGridDistance = 1;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;

  var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

  valueAxis.min = 0;
  valueAxis.max = 25;
  valueAxis.strictMinMax = true;
  valueAxis.renderer.minGridDistance = 3;
  valueAxis.renderer.fontSize = 10

  /* Create and configure series */
  var series1 = chart.series.push(new am4charts.RadarColumnSeries());
  series1.dataFields.valueX = "value1";
  series1.dataFields.categoryY = "country";
  series1.name = "Sales";
  series1.strokeWidth = 0;
  series1.showOnInit = false;
  //series1.columns.template.radarColumn.cornerRadiusTopLeft = 2;

  series1.sequencedInterpolation = true;
  series1.stacked = true;
  series1.fill = "#0070C0"

  var series2 = chart.series.push(new am4charts.RadarColumnSeries());
  series2.dataFields.valueX = "value2";
  series2.dataFields.categoryY = "country";
  series2.name = "Marketing";
  series2.strokeWidth = 0;

  series2.sequencedInterpolation = true;
  series2.stacked = true;
  series2.fill = "#00B050"
  series2.showOnInit = false;

  var series3 = chart.series.push(new am4charts.RadarColumnSeries());
  series3.dataFields.valueX = "value3";
  series3.dataFields.categoryY = "country";
  series3.name = "Marketing";
  series3.strokeWidth = 0;

  series3.sequencedInterpolation = true;
  series3.stacked = true;
  series3.fill = "#FFFF00"
  series3.showOnInit = false;

  var series4 = chart.series.push(new am4charts.RadarColumnSeries());
  series4.dataFields.valueX = "value4";
  series4.dataFields.categoryY = "country";
  series4.name = "Marketing";
  series4.strokeWidth = 0;

  series4.sequencedInterpolation = true;
  series4.stacked = true;
  series4.fill = "#F58617"
  series4.showOnInit = false;

  var series5 = chart.series.push(new am4charts.RadarColumnSeries());
  series5.dataFields.valueX = "value5";
  series5.dataFields.categoryY = "country";
  series5.name = "Marketing";
  series5.strokeWidth = 0;

  series5.sequencedInterpolation = true;
  series5.stacked = true;
  series5.fill = "#FF0000"
  series5.showOnInit = false;

  useEffect(() => {
    exportGrafica()
  }, [])

  return (
    <>
      {/* <div id='panorama1' style={{ width: '600px', minHeight: '600px', margin: '0 auto' }}></div> */}
    </>
  )
}

export {GraficaPanorama}
