import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis } from 'recharts';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../../ui/Chart';

// El componente ahora recibe data, labels, y colors como props
export function RadarMultiple({ data, labels, colors }) {
  // Construir la configuración del gráfico usando labels y colors
  const chartConfig = labels.reduce((config, label, index) => {
    config[label] = {
      label,
      color: colors[index] || 'var(--blue-600)', // Color por defecto si no se proporciona
    };
    return config;
  }, {});

  return (
    <ChartContainer
      config={chartConfig}
      className="mx-auto"
    >
      <RadarChart data={data}>
        <ChartTooltip content={<ChartTooltipContent indicator="line" />} />
        <PolarAngleAxis dataKey="x" />
        <PolarGrid />
        {labels.map((label, index) => (
          <Radar
            key={label}
            dataKey={label}
            fill={colors[index] || 'var(--blue-600)'} // Color por defecto si no se proporciona
            fillOpacity={0.6}
          />
        ))}
      </RadarChart>
    </ChartContainer>
  );
}
