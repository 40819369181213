import React from 'react'
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts"
import { ChartContainer, ChartTooltipContent, ChartTooltip } from "../../ui/Chart";

function BarMultiple({ data, labels, colors, nameKey="x" }) {
  // Generar configuraciones para las barras
  const generateChartConfig = (data) => {
    const config = {};
    labels.forEach(label => {
      config[label] = { label };
    });
    return config;
  };

  const chartConfig = generateChartConfig(data);

  return (
    <ChartContainer config={chartConfig}>
      <BarChart accessibilityLayer data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={nameKey}
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="dashed" />}
        />

        {labels.map((label, index) => (
          <Bar key={label} dataKey={label} fill={colors[index]} radius={4} />
        ))}
      </BarChart>
    </ChartContainer>
  )
}

export default BarMultiple
