import { Fragment, useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux"
import InputType from "../../helpers/InputType";
import { validarEntorno } from "../../helpers/validarHost";
import Loader from "../icons/Loader";
import './style/main.scss';
import { filtrosWid } from '../../reducers/reportesReducer'

import './style/Filtros.scss';
import { useTranslation } from "react-i18next";



const Filtros = ({ idTipoReporte, setIdTipoReporte, setModal, modal, initialState, setInitialState, setFiltrosForm, graficas, setGraficas, FiltrosForm, setUrl, aditionalData }) => {
  // const [idTipoReporte, setIdTipoReporte] = useState('')

  const { t } = useTranslation();

  const dispatch = useDispatch()


  const reportesData = useSelector(store => store.reportesReducer)

  const { filtros_presidencia } = reportesData

  // console.log(filtros_presidencia)


  const [inputGroup, setInputGroup] = useState([]);
  const [info, setInfo] = useState();
  const [filtrosRepSelected, setFiltrosRepSelected] = useState([

  ])
  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();

  const [filtersW, setFiltersW] = useState({})


  const getEstructura = async () => {

    const dataReq = {
      filtros_presidencia: Object.keys(filtros_presidencia) ? filtros_presidencia : null,
      // id,
      // seccion,
      // id_segundo_nivel: idSegundoNivel,
      ...aditionalData,
      hash: window.location.hash
    }

     //console.log(dataReq)

    const req = await validarEntorno('JSON/ReportesOperativos/data_inputs_reop.html', 'POST', dataReq);



    if (req.ok) {
      const res = await req.json();
      // console.log(res)
      setInputGroup(res);
      setFiltrosRepSelected(res?.valores_opcion[0]?.filtros)
      setGraficas(res.graficas)
      setUrl(res.enlace)
      // console.log(inputGroup.valores_opcion)
      // setData(res)

    }
  }

  useEffect(() => {
    getEstructura()

  }, [])

  const onSubmit = async (data, props) => {


    setInitialState({ ...initialState, loading: true });
    const dataReq = {
      dataForm: data,
      hash: window.location.hash,
      tipo: "form",
      tipo_reporte: idTipoReporte ? idTipoReporte : null,
      filtros_presidencia: Object.keys(filtros_presidencia) ? filtros_presidencia : null,
      pag_actual: 1,
      limite: 10
    }

    // console.log(data)

    // console.log(dataReq)

    setFiltrosForm(
      {
        tipo_reporte: idTipoReporte ? idTipoReporte : null,
        tipo: "form",
        filtros: data
      }

    )
    // console.log(props.FiltrosForm)
    const req = await validarEntorno(inputGroup?.enlace ? inputGroup?.enlace : 'JSON/ReportesOperativos/rep_op_res.html', 'POST', dataReq);

    if (req.ok) {
      // console.log(data)
      const res = await req.json();

      // const pagina = res.data.pag_actual
      setInitialState({
        ...initialState,
        data: res,
        loading: false,
        limite: 10,
      });

      if (initialState?.data?.pag_total > 1) {
        document.getElementById('cantidad_regs').value = 10;
      } else {
        console.log(null)
      }

    } else {
      setModal({
        ...modal,
        show: true,
        mensaje: 'Ha ocurrido un error, intente nuevamente',
        tipo: 'envio_peticion_erroneo'
      })
    }
    dispatch(filtrosWid(data))
  }

  const sendConfig = async () => {

    const data = getValues();

    const dataReq = {
      dataForm: data,
      hash: window.location.hash,
      tipo: "config"
    }

    const req = await validarEntorno('JSON/ReportesOperativos/resp_tab_re_op.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();
      if (res.estado === "OK") {
        setModal({
          ...modal,
          show: true,
          mensaje: res.mensaje,
          tipo: 'eliminacion_confirmada'
        })
      } else {
        setModal({
          ...modal,
          show: true,
          mensaje: res.mensaje,
          tipo: 'falloPeticionesGestion'
        })
      }
    } else {
      setModal({
        ...modal,
        show: true,
        mensaje: 'Ha ocurrido un error, intente nuevamente',
        tipo: 'envio_peticion_erroneo'
      })
    }
  }

  const filerFilters = (e) => {

    setFiltrosRepSelected([]);

    // console.log('hola ' + e.target.value)
    const filtersFiltered = inputGroup?.valores_opcion?.filter((item) => {
      return item.value == e.target.value
    })

    // setFiltersW(filtersFiltered)
    // console.log(filtersW)
    setFiltrosRepSelected(filtersFiltered[0])

    setIdTipoReporte(filtersFiltered[0].value)

    document.getElementById('tipo_reporte').value = filtersFiltered[0].value;

    reset();
    // dispatch(filtrosWid(filtersFiltered))
    // console.log(filtersFiltered)
  }

  // dispatch(filtrosWid(filtersW))
  // console.log(filtersW)



  return (
    <>
      <br />
      {inputGroup ? (
        <Card className="card-reportes">
          <div className="body-reportes">
            <div className='filtersData'>
              {inputGroup?.titulo &&
                <div id='input16' >
                  <div
                    style={{
                      color: 'white',
                      fontWeight: '700',
                      fontSize:'3rem'
                    }}
                  >
                    {inputGroup.titulo ? inputGroup.titulo : 'Reportes'}
                  </div>
                </div>
              }
              {inputGroup?.total &&
                <div className="contenedorTotalSolicitudes">
                  <div className="solicituesTotalGeneral">
                    {inputGroup?.total?.label} <div className="totalSolicitudes"> {inputGroup?.total?.total} </div>
                  </div>
                </div>

              }


            </div>
            <Form onSubmit={handleSubmit(onSubmit)} style={{ width: '98%', margin: '0 auto', padding: '0' }} >
              <Card.Body>
                <Row>
                  <Fragment>
                    <Card className="shadow">
                      <Card.Body>
                        <div className='filtros_input-cont'>
                          {
                            <div id='tipoReporte'  >
                              <Form.Label style={{ color: '#213265', fontWeight: '700' }}>{t('formulario.label.tipo')}</Form.Label>
                              <Form.Control
                                // className='form-control'
                                as='select'
                                onChange={(e) => filerFilters(e)}
                                // reg={register}
                                name='tipo_reporte'
                                id='tipo_reporte'
                                value={idTipoReporte}
                              >
                                <option value='' disabled >{t('formulario.label.seleccione')}</option>
                                {inputGroup?.valores_opcion?.map((opt, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={opt?.value}
                                    >
                                      {opt?.label}
                                    </option>
                                  )
                                })}
                              </Form.Control>
                            </div>
                          }
                          {(filtrosRepSelected?.filtros?.map((campo, index) => {
                            const fieldName = `asesoria.0`;
                            return (
                              <InputType
                                // key={index}
                                className='filtros_input-item'
                                item={campo}
                                index={0}
                                idx={index}
                                key={`${0} - ${index}`}
                                reg={register}
                                nombre={`${fieldName}`}
                                errors={errors}
                                // col={campo.col ? campo.col : 12}
                                col={12}
                                type="reporte-operacional"
                              />
                            )
                          }))}
                        </div>

                      </Card.Body>
                    </Card>
                    <div style={{ display: 'flex', marginTop: '1em', justifyContent: initialState?.data?.link?.valor ? 'space-between' : 'flex-end', width: '100%' }}>
                      {initialState?.data?.link && initialState?.data?.link &&
                        <a className="configButton" href={initialState?.data?.link?.link} target='_blank'>{initialState?.data?.link?.valor ? initialState.data.link.valor : 'Descarga archivo'}</a>
                      }
                      {/* { idTipoReporte !== '4' ? (  <button type="button" className="configButton" onClick={()=> {sendConfig()}}>Guardar Configuración</button>)
                                            : (<a className="configButton" href="https://www.google.com/">Descarga archivo</a>)
                                            } */}
                      <button type="submit" style={{ margin: 0 }} className="confirmButton">{t('botones.ejecutar')}</button>
                    </div>
                  </Fragment>
                </Row>
              </Card.Body>
            </Form>
          </div>
        </Card>
      ) : (<Loader />)}
    </>
  );
}

export default Filtros;
