import React from 'react'

import './Loader.scss'

export default function Loader({ color }) {
  return (
    <div className='loaderContainer'>
      <div className="lds-ellipsis">
        <div style={{background: color ? color : '#8b97a7'}}></div>
        <div style={{background: color ? color : '#8b97a7'}} ></div>
        <div style={{background: color ? color : '#8b97a7'}} ></div>
        <div style={{background: color ? color : '#8b97a7'}} ></div>
      </div>
    </div>
  )
}
