import React from 'react'

import NoticiasOcensa from '../../../../components/Ocensa/NoticiasOcensa'


export const InformacionGeneral = () => {
    return (
        <div>
            <div className="containerOcs">
            <div id='' className="mapa">
                    <img 
                        alt='' 
                        style={{  width: '100%', height: '100%' }} 
                        src="https://res.cloudinary.com/da6bzgewk/image/upload/v1620835554/WhatsApp_Image_2021-05-12_at_10.43.35_AM_1_ke9xq0.jpg" 

                    />
                </div>

                <NoticiasOcensa/>
            </div>
            <div className="descripcion">
                <textarea style={{width:'100%', height:'200px', borderRadius:'10px'}} >

                </textarea>
            </div>
        </div>
    )
}
