import {credenciales} from './credentials.SMS'

export function EnvioSMS(mensaje) {
  
  const data = {
      toNumber: mensaje.num,
      sms: mensaje.msj,
      flash: 0,
      request_dlvr_rcpt: 0
  }

  if(mensaje.num !== '' && mensaje.msj !== ''){

    //EL MENSAJE DEBE SER DE MAXIMO 160 CARACTERES

    fetch('https://api103.hablame.co/api/sms/v3/send/marketing', {
        'Content-Type': 'application/json',
        'method': 'POST',
        'headers': {
        'account': credenciales.account,
        'apikey': credenciales.key,
        'token': credenciales.token
    },
    // 'body': '{\'toNumber\':\'573002468928\',\'sms\':\'SMS de prueba Hablame\',\'flash\':0,\'sc\':\'890202\',\'request_dlvr_rcpt\':0}'
    
    'body': JSON.stringify(data)
    })
  }
}
