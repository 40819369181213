import React, {useState, useLayoutEffect} from 'react'
import {Link} from 'react-router-dom'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'
import { useTranslation } from 'react-i18next';

import './Treemap.scss'

export default function Treemap(props) {

  const { t } = useTranslation();
  useLayoutEffect(()=>{

    let data = props?.data ? {"type" : props?.data} : {

      "type": [
        {
          "name": "Flujo de caja",
          "id": "ao1",
          "color": "#219ebc",
          "count": 117052849,
          "children": [
            {
              "name": "VIENE BANCOS",
              "id": "tt1",
              "count": 438348163,
              "area": 1,
            }, {
              "name": "INGRESOS ADICIONALES",
              "id": "tt2",
              "count": 75451236,
              "area": 1,
            }, {
              "name": "GASTO ADICIONAL",
              "id": "tt3",
              "count": 58600769,
              "area": 1,
            }, {
              "name": "INGRESOS NO RECIBIDOS",
              "id": "tt4",
              "count": 389913048,
              "area": 1,
            }, {
              "name": "GASTO FINANCIERO",
              "id": "tt4",
              "count": 1953334,
              "area": 1,
            }, {
              "name": "NO PAGOS",
              "id": "tt4",
              "count": 53720600,
              "area": 1,
            }
          ]
        },
        {
          "name": "Ingresos esperados",
          "id": "ao11",
          "color": "#023047",
          "count":	440900000,
          "children": [
            {"name": "	ODL- OBC	","id": "tt5", "count":	160000000	, "area": 1},
            {"name": "	UGPP	","id": "tt5", "count":	110000000, "area": 1	},
            {"name": "	ADECCO SERVICIOS	","id": "tt5", "count":	31000000, "area": 1	},
            {"name": "	ADECCO COLOMBIA	","id": "tt5", "count":	26000000, "area": 1	},
            {"name": "	SERVIENTREGA	","id": "tt5", "count":	21000000, "area": 1	},
            {"name": "	SIMONIZ	","id": "tt5", "count":	18000000, "area": 1	},
            {"name": "	CRUZ VERDE	","id": "tt5", "count":	16000000, "area": 1	},
            {"name": "	MABE COLOMBIA	","id": "tt5", "count":	11200000	, "area": 1},
            {"name": "	EFECTIVO	","id": "tt5", "count":	10000000	, "area": 1, "area": 1},
            {"name": "	BANCO PICHINCHA	","id": "tt5", "count":	9400000	, "area": 1},
            {"name": "	INDUSTRIA AMBIENTAL S.A.S	","id": "tt5", "count":	9000000, "area": 1	},
            {"name": "	BAT	","id": "tt5", "count":	8000000	, "area": 1},
            {"name": "	PEQUEÑOS	","id": "tt5", "count":	7000000	, "area": 1},
            {"name": "	VQ INGENIERIA	","id": "tt5", "count":	2800000	, "area": 1},
            {"name": "	YANBAL DE COLOMBIA	","id": "tt5", "count":	1500000, "area": 1	}




          ]
        },
        {
          "name": "Pasan",
          "id": "ao9",
          "color": "#ffb703",
          "count":	413889846,
          "children": [
            {"name": "	CUENTA CORRIENTE COSINTE	","id": "tt5", "count":	117107978, "area":	1	},
            {"name": "	CUENTA CORRIENTE CONSORCIO	","id": "tt5", "count":	53458, "area":	1	},
            {"name": "	INGRESOS ESPERADOS BANCOS	","id": "tt5", "count":	440900000, "area":	1	},
            {"name": "	PAGOS BANCOS	","id": "tt5", "count":	144064674	, "area":	1}

          ]
        },
        {
          "name": "Pagos por \n departamento",
          "id": "ao10",
          "color": "#fb8500",
          "count":	144064674,
          "children": [
            {"name": "	ADMINISTRATIVO	","id": "tt5", "count":	68471403, "area" : 1	},
            {"name": "	ANAPOIMA	","id": "tt5", "count":	3867512, "area" : 1	},
            {"name": "	INTELIGENCIA	","id": "tt5", "count":	9352065, "area" : 1	},
            {"name": "	INVESTIGACIONES ADMON	","id": "tt5", "count":	4685396, "area" : 1	},
            {"name": "	INVESTIGACIONES ESPECIFICAS	","id": "tt5", "count":	43720, "area" : 1	},
            {"name": "	OPERACIONES	","id": "tt5", "count":	57644578, "area" : 1	}

          ]
        },
        {
          "name": "Pagos por \n medio de pago",
          "id": "ao10",
          "color": "#ff5200",
          "count":	144064674,
          "children": [
            {"name": "	DEBITO AUTOMATICO	","id": "tt5", "count":	48583740, "area" : 1	},
            {"name": "	EFECTIVO	","id": "tt5", "count":	7023420, "area" : 1	},
            {"name": "	PSE	","id": "tt5", "count":	53720600, "area" : 1	},
            {"name": "	TRANSFERENCIA	","id": "tt5", "count":	34816914, "area" : 1	}

          ]
        }
      ]
    };


    String.prototype.toPascalCase = function() {
      return this.match(/[a-z]+/gi)
        .map(function(word) {
          return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        }).join('');
    };

    // create chart
    let container = am4core.create( props.id ? props.id : "chartdiv", am4core.Container);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "vertical";

    let chart1 = container.createChild(am4charts.TreeMap);
    let chart2 = container.createChild(am4charts.TreeMap);
    chart1.data = data.type;
    chart2.data = data.param;

    chart1.padding(15,15,15,15);
    chart2.padding(15,15,15,0);

    chart1.height = am4core.percent(95);
    chart2.height = am4core.percent(0);

    let chartConfig = (chart, astroSeries = []) => {
      // only one level visible initially
      chart.maxLevels = 1;
      // define data fields
      chart.dataFields.value = "area";
      chart.dataFields.name = "name";
      chart.dataFields.children = "children";
      chart.dataFields.color = "color";
      chart.tooltip.disabled = true;

      // astronomical object series template
      let astroObjectSeries = chart.seriesTemplates.create("0");
      astroObjectSeries.hiddenState.transitionDuration = 1000;
      astroObjectSeries.strokeWidth = 8;
      let astroObjectTemplate = astroObjectSeries.columns.template;
      astroObjectTemplate.tooltipText = "";

      astroObjectTemplate.column.cornerRadius(15, 15, 15, 15);

      let txt = astroObjectSeries.bullets.push(new am4charts.LabelBullet());


      txt.locationY = 0.5;
      txt.locationX = 0.5;
      txt.label.text = "[font-size: 1rem; bold]{name}[/]\n[font-size: 1.2rem;bold]{count}";
      txt.label.fill = am4core.color("#ffffff");
      txt.label.fontSize = 15;
      txt.label.tooltipText = "";
      txt.interactionsEnabled = false;


      astroSeries.push(astroObjectSeries);

      // add picto
      /*let image = astroObjectTemplate.createChild(am4core.Image);
      image.opacity = 0.10;
      image.align = "center";
      image.valign = "middle";
      image.width = am4core.percent(80);
      image.height = am4core.percent(80);


      // add adapter for href to load correct image
      image.adapter.add("href", function(href, target) {
        let dataItem = target.parent.dataItem;
        if (dataItem) {
          let astroObjectName = dataItem.treeMapDataItem.name.toPascalCase();
          return `https://www.ciacosinte.site/cia2/static/media/objetivo.svg`;
        }
      });
    */
      let tableTypeSeries = chart.seriesTemplates.create("1");
      tableTypeSeries.columns.template.fillOpacity = 0;
      tableTypeSeries.columns.template.tooltipText = "";

      let tableTypeBullet = tableTypeSeries.bullets.push(new am4charts.LabelBullet());
      tableTypeBullet.locationX = 0.5;
      tableTypeBullet.locationY = 0.5;
      tableTypeBullet.label.text = "[font-size: 0.7rem;]{name}[/]\n[font-size: 1.2rem;bold]{count}";
      tableTypeBullet.interactionsEnabled = false;

      tableTypeBullet.label.fill = am4core.color("#ffffff");

      // deepest series template (either parameter sets or original items)
      let paramSeries = chart.seriesTemplates.create("2");

      paramSeries.columns.template.fillOpacity = 0;
      paramSeries.columns.template.tooltipText = "";

    //PARA HIJOS NIVEL 3
      // let paramBullet = paramSeries.bullets.push(new am4charts.LabelBullet());
      // paramBullet.locationX = 0.5;
      // paramBullet.locationY = 0.5;
      // paramBullet.interactionsEnabled = false;
      // paramBullet.label.fill = am4core.color("#ffffff");
      // paramBullet.label.text = "[font-size: 16px; bold]{name}[/]\n{count}";

      return astroSeries;
    }

    let astroSeries = chartConfig(chart1);
    astroSeries = chartConfig(chart2, astroSeries);

    astroSeries[0].columns.template.events.on("hit", (ev) => {
      chart2.dataItems.each(function(dataItem){
        if(dataItem.name == ev.target.dataItem.dataContext.name){
          chart2.zoomToChartDataItem(dataItem);
        }
      })
    });

    astroSeries[1].columns.template.events.on("hit", (ev) => {
      chart1.dataItems.each(function(dataItem){
        if(dataItem.name == ev.target.dataItem.dataContext.name){
          chart1.zoomToChartDataItem(dataItem);
        }
      })
    });

    chart1.zoomOutButton.events.on("hit", function(){
      chart2.zoomToChartDataItem(chart2.homeDataItem);
    })

    chart2.zoomOutButton.events.on("hit", function(){
      chart1.zoomToChartDataItem(chart1.homeDataItem);
    })

    return () => {
      container.dispose();
    };

  },[props.data])

  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  return (
    <Card className="cardGraficaGeneral cardGraficaAnalistas cardDivergente">

      <Card.Body>
        {(props.title || props.parametrizado) &&
          <>
            <h5 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}>
            {props.title &&
            <>
            {props.title}
            </>
            }
            {props.parametrizado
              ?
                <>
                  {props.parametrizado} - {props.periodo }
                </>
              : null
            }
            </h5>
          </>
        }
        <div id={props.id} style={{ width: '100%', minHeight: props.height ? props.height : '500px', margin: '0 auto ', height: 'auto' }}>
          {props.children}
        </div>
        {props.detailLink &&
          <div style={{width: '92%', display: 'flex', justifyContent: 'flex-end', margin: ' -10px auto 0'}}>
            <Link className='linkDetalle' to={props?.detailLink}>
              <p  >{t('botones.ver_detalle')}</p>
            </Link>
          </div>
        }
        {props.total &&
          <>
            <h6 className='pagesTitles' >
              Total: {props.total}
            </h6>
          </>
        }
      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
