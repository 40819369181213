import { React } from 'react'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap';
import { Accordion, Card, } from 'react-bootstrap'

import '../styles/Menu.scss';

import iconPanel from '../../static/icons/icon-panel.png';
import ayfIcon from '../../static/icons/Administrativoyfinanciero.png'
import iconComercial from '../../static/icons/iconComercial.png'
import iconGH from '../../static/icons/iconGH.png'
import iconJuridico from '../../static/icons/iconJuridico.png'
import iconTI from '../../static/icons/iconTI.png'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


export default function MenuPresidencia(props) {


  const { menu } = useSelector(state => state.menu)
  const { presidencia } = menu;
  const { t } = useTranslation(); //Traductor


  const dinamicTitle = (props) => {
    if (props.menuIsOpen === true) {
      return (
        <p>{t('navegacion.menu_presidencia.titulo')}</p>
      )
    } else if (props.menuIsOpen === false) {
      return <div className='iconMenuGerencia' ><GroupOutlinedIcon /></div>
    }
  }




  return (

    <div id='menuGerenciaContainer'>
      <div id='menuGerenciaTitulo'>
        {dinamicTitle(props)}
      </div>
      <Nav defaultActiveKey='/' className='flex-column options'>

        <Accordion>
          {
            presidencia.panel
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle eventKey='5' onClick={props.onOpenMenu} >
                <Link to='/dashboard-presidencia' className='menuOptions'>
                  <img className='menuIcon' src={iconPanel} alt='panel de presidencia'></img>
                  {t('navegacion.menu_presidencia.panel')}
                </Link>
              </Accordion.Toggle>
            </Card>
          }
          {
            presidencia.administrativoFinanciero.activo
            && <Card className='AccordionCard' id='menuAyf'>
              <Accordion.Toggle eventKey='0' onClick={props.onOpenMenu} className='menuOptions'>
                <img className='menuIcon' src={ayfIcon} alt='panel de presidencia'></img>
                {t('navegacion.menu_presidencia.administrativo_financiero.administrativo_financiero')}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='0'>
                <Card.Body className='accordionOptionsContainer'>

                  <Accordion>
                    {
                      presidencia.administrativoFinanciero.cartera.activo
                      && <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='1' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.administrativo_financiero.administrativo_financiero')}
                        </Accordion.Toggle>

                        {
                          !presidencia.administrativoFinanciero.cartera.solicitar &&
                            !presidencia.administrativoFinanciero.cartera.administrar &&
                            !presidencia.administrativoFinanciero.cartera.reportes &&
                            !presidencia.administrativoFinanciero.cartera.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='1'>
                              <div className='accordionLevel3'>
                                {
                                  presidencia.administrativoFinanciero.cartera.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/administrativo/solicitar'>
                                    <p>
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.cartera.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/administrativo/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.cartera.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/administrativo/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.cartera.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/administrativo/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.cartera.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/administrativo/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>
                                }
                              </div>
                            </Accordion.Collapse>
                        }

                      </Card>
                    }
                    {
                      presidencia.administrativoFinanciero.compras.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='2' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.administrativo_financiero.compras')}
                        </Accordion.Toggle>
                        {
                          !presidencia.administrativoFinanciero.compras.solicitar &&
                            !presidencia.administrativoFinanciero.compras.administrar &&
                            !presidencia.administrativoFinanciero.compras.reportes &&
                            !presidencia.administrativoFinanciero.compras.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='2'>
                              <div className='accordionLevel3'>
                                {
                                  presidencia.administrativoFinanciero.compras.solicitar
                                  && <Link className='optionsLevel3 crearOption' to='/presidencia/ayf/compras/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.compras.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/compras/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.compras.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/compras/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.compras.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/ayf/compras/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>
                                }
                                {
                                  presidencia.administrativoFinanciero.compras.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/ayf/compras/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>
                                }

                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.administrativoFinanciero.contabilidad.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='3' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.administrativo_financiero.contabilidad')}
                        </Accordion.Toggle>
                        {
                          !presidencia.administrativoFinanciero.contabilidad.solicitar &&
                            !presidencia.administrativoFinanciero.contabilidad.administrar &&
                            !presidencia.administrativoFinanciero.contabilidad.reportes &&
                            !presidencia.administrativoFinanciero.contabilidad.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='3'>
                              <div className='accordionLevel3'>
                                {

                                  presidencia.administrativoFinanciero.contabilidad.solicitar
                                  && <Link className='optionsLevel3 crearOption' to='/presidencia/ayf/contabilidad/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.contabilidad.administrar
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/contabilidad/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.contabilidad.procesos
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/contabilidad/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.contabilidad.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/ayf/contabilidad/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.contabilidad.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/ayf/contabilidad/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>
                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {

                      presidencia.administrativoFinanciero.facturacion.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='4' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.administrativo_financiero.facturacion')}
                        </Accordion.Toggle>

                        {

                          !presidencia.administrativoFinanciero.facturacion.solicitar &&
                            !presidencia.administrativoFinanciero.facturacion.administrar &&
                            !presidencia.administrativoFinanciero.facturacion.reportes &&
                            !presidencia.administrativoFinanciero.facturacion.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='4'>
                              <div className='accordionLevel3'>

                                {

                                  presidencia.administrativoFinanciero.facturacion.solicitar
                                  && <Link className='optionsLevel3 crearOption' to='/presidencia/ayf/facturacion/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>


                                }

                                {
                                  presidencia.administrativoFinanciero.facturacion.administrar
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/facturacion/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.facturacion.procesos
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/facturacion/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.facturacion.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/ayf/facturacion/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.facturacion.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/ayf/facturacion/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.administrativoFinanciero.serviciosGenerales.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='5' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.administrativo_financiero.servicios_generales')}
                        </Accordion.Toggle>

                        {
                          !presidencia.administrativoFinanciero.serviciosGenerales.solicitar &&
                            !presidencia.administrativoFinanciero.serviciosGenerales.administrar &&
                            !presidencia.administrativoFinanciero.serviciosGenerales.reportes &&
                            !presidencia.administrativoFinanciero.serviciosGenerales.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='5'>
                              <div className='accordionLevel3'>

                                {

                                  presidencia.administrativoFinanciero.serviciosGenerales.solicitar
                                  && <Link className='optionsLevel3 crearOption' to='/presidencia/ayf/servicios-generales/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.serviciosGenerales.administrar
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/servicios-generales/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.serviciosGenerales.administrar
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/servicios-generales/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.administrativoFinanciero.serviciosGenerales.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/ayf/servicios-generales/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.serviciosGenerales.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/ayf/servicios-generales/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {

                      presidencia.administrativoFinanciero.sistemaGestionIntegral.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='6' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.administrativo_financiero.sistema_gestion_integral')}
                        </Accordion.Toggle>

                        {
                          !presidencia.administrativoFinanciero.sistemaGestionIntegral.solicitar &&
                            !presidencia.administrativoFinanciero.sistemaGestionIntegral.administrar &&
                            !presidencia.administrativoFinanciero.sistemaGestionIntegral.reportes &&
                            !presidencia.administrativoFinanciero.sistemaGestionIntegral.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='6'>
                              <div className='accordionLevel3'>

                                {

                                  presidencia.administrativoFinanciero.sistemaGestionIntegral.solicitar
                                  && <Link className='optionsLevel3 crearOption' to='/presidencia/ayf/SGI/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>


                                }

                                {
                                  presidencia.administrativoFinanciero.sistemaGestionIntegral.administrar
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/SGI/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.sistemaGestionIntegral.procesos
                                  && <Link className='optionsLevel3 adminOption' to="/presidencia/ayf/SGI/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.sistemaGestionIntegral.reportes
                                  &&
                                  <Link className='optionsLevel3 informeOption ' to="/presidencia/ayf/SGI/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.administrativoFinanciero.sistemaGestionIntegral.reportes_operativos
                                  &&
                                  <Link className='optionsLevel3 informeOption-op ' to="/presidencia/ayf/SGI/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>

                        }

                      </Card>
                    }
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          }

          {
            presidencia.comercial.activo
            && <Card className='AccordionCard'>
              <Accordion.Toggle onClick={props.onOpenMenu} eventKey='1' className='menuOptions'>
                <img className='menuIcon' src={iconComercial} alt='panel de presidencia'></img>
                {t('navegacion.menu_presidencia.comercial.comercial')}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='1'>
                <Card.Body className='accordionOptionsContainer'>

                  <Accordion>

                    {
                      presidencia.comercial.gestionComercial.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='1' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.comercial.gestion_comercial')}
                        </Accordion.Toggle>
                        {
                          !presidencia.comercial.gestionComercial.solicitar &&
                            !presidencia.comercial.gestionComercial.administrar &&
                            !presidencia.comercial.gestionComercial.reportes &&
                            !presidencia.comercial.gestionComercial.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='1'>
                              <div className='accordionLevel3'>

                                {
                                  presidencia.comercial.gestionComercial.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/comercial/gestionComercial/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.gestionComercial.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/comercial/gestionComercial/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.gestionComercial.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/comercial/gestionComercial/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.gestionComercial.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/comercial/gestionComercial/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.gestionComercial.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/comercial/gestionComercial/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>

                        }
                      </Card>
                    }
                    {
                      presidencia.comercial.comunicaciones.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='2' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.comercial.comunicaciones')}
                        </Accordion.Toggle>

                        {
                          !presidencia.comercial.comunicaciones.solicitar &&
                            !presidencia.comercial.comunicaciones.administrar &&
                            !presidencia.comercial.comunicaciones.reportes &&
                            !presidencia.comercial.comunicaciones.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='2'>
                              <div className='accordionLevel3'>

                                {
                                  presidencia.comercial.comunicaciones.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/comunicaciones/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.comunicaciones.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/comunicaciones/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.comunicaciones.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/comunicaciones/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.comercial.comunicaciones.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/comunicaciones/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.comunicaciones.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/comunicaciones/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.comercial.encuestas?.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='3' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.comercial.encuestas')}
                        </Accordion.Toggle>

                        {
                          !presidencia.comercial.encuestas.solicitar &&
                            !presidencia.comercial.encuestas.administrar &&
                            !presidencia.comercial.encuestas.reportes &&
                            !presidencia.comercial.encuestas.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='3'>
                              <div className='accordionLevel3'>

                                {
                                  presidencia.comercial.encuestas.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/encuestas/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.encuestas.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/encuestas/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.encuestas.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/encuestas/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.encuestas.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/encuestas/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.comercial.encuestas.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/encuestas/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          }

          {
            presidencia.gestionHumana.activo
            && <Card className='AccordionCard'>
              <Accordion.Toggle onClick={props.onOpenMenu} eventKey='2' className='menuOptions'>
                <img className='menuIcon' src={iconGH} alt='panel de presidencia'></img>
                {t('navegacion.menu_presidencia.gestion_humana.gestion_humana')}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='2'>
                <Card.Body className='accordionOptionsContainer'>

                  <Accordion>
                    {
                      presidencia.gestionHumana.bienestarDesarrollo.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='1' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.bienestar_desarrollo')}
                        </Accordion.Toggle>
                        {
                          !presidencia.gestionHumana.bienestarDesarrollo.solicitar &&
                            !presidencia.gestionHumana.bienestarDesarrollo.administrar &&
                            !presidencia.gestionHumana.bienestarDesarrollo.reportes &&
                            !presidencia.gestionHumana.bienestarDesarrollo.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='1'>
                              <div className='accordionLevel3'>
                                {
                                  presidencia.gestionHumana.bienestarDesarrollo.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/bienestarYdesarrollo/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.bienestarDesarrollo.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/bienestarYdesarrollo/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.bienestarDesarrollo.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/bienestarYdesarrollo/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.bienestarDesarrollo.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/bienestarYdesarrollo/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }


                                {
                                  presidencia.gestionHumana.bienestarDesarrollo.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/bienestarYdesarrollo/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }




                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.gestionHumana.capacitacion.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='2' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.capacitacion')}
                        </Accordion.Toggle>
                        {
                          !presidencia.gestionHumana.capacitacion.solicitar &&
                            !presidencia.gestionHumana.capacitacion.administrar &&
                            !presidencia.gestionHumana.capacitacion.reportes &&
                            !presidencia.gestionHumana.capacitacion.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='2'>
                              <div className='accordionLevel3'>

                                {
                                  presidencia.gestionHumana.capacitacion.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/capacitacion/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.capacitacion.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/capacitacion/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.capacitacion.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/capacitacion/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.capacitacion.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/capacitacion/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }


                                {
                                  presidencia.gestionHumana.capacitacion.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/capacitacion/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.gestionHumana.contratacionDesvinculacion.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='3' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.contratacion_desvinculacion')}
                        </Accordion.Toggle>
                        {
                          !presidencia.gestionHumana.contratacionDesvinculacion.solicitar &&
                            !presidencia.gestionHumana.contratacionDesvinculacion.administrar &&
                            !presidencia.gestionHumana.contratacionDesvinculacion.reportes &&
                            !presidencia.gestionHumana.contratacionDesvinculacion.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='3'>
                              <div className='accordionLevel3'>
                                {
                                  presidencia.gestionHumana.contratacionDesvinculacion.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/contratacionYdesvinculacion/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.contratacionDesvinculacion.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/contratacionYdesvinculacion/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.contratacionDesvinculacion.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/contratacionYdesvinculacion/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.contratacionDesvinculacion.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/contratacionYdesvinculacion/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.contratacionDesvinculacion.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/contratacionYdesvinculacion/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.gestionHumana.nominaSeguridadSocial.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='4' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.nomina_seguridad_social')}
                        </Accordion.Toggle>


                        {

                          !presidencia.gestionHumana.nominaSeguridadSocial.solicitar &&
                            !presidencia.gestionHumana.nominaSeguridadSocial.administrar &&
                            !presidencia.gestionHumana.nominaSeguridadSocial.reportes &&
                            !presidencia.gestionHumana.nominaSeguridadSocial.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='4'>
                              <div className='accordionLevel3'>

                                {
                                  presidencia.gestionHumana.nominaSeguridadSocial.solicitar
                                  &&

                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/nominaYseguridadSocial/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.nominaSeguridadSocial.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/nominaYseguridadSocial/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.nominaSeguridadSocial.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/nominaYseguridadSocial/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.nominaSeguridadSocial.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/nominaYseguridadSocial/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.nominaSeguridadSocial.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/nominaYseguridadSocial/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.gestionHumana.reclutamientoSeleccion.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='5' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.reclutamiento_seleccion')}
                        </Accordion.Toggle>
                        {

                          !presidencia.gestionHumana.reclutamientoSeleccion.solicitar &&
                            !presidencia.gestionHumana.reclutamientoSeleccion.administrar &&
                            !presidencia.gestionHumana.reclutamientoSeleccion.reportes &&
                            !presidencia.gestionHumana.reclutamientoSeleccion.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='5'>
                              <div className='accordionLevel3'>

                                {
                                  presidencia.gestionHumana.reclutamientoSeleccion.solicitar
                                  &&

                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/reclutamiento/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.reclutamientoSeleccion.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/reclutamiento/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.reclutamientoSeleccion.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/reclutamiento/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.reclutamientoSeleccion.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/reclutamiento/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.reclutamientoSeleccion.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/reclutamiento/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia.gestionHumana.sstAmbiental.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='6' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.sst_ambiental')}
                        </Accordion.Toggle>
                        {

                          !presidencia.gestionHumana.sstAmbiental.solicitar &&
                            !presidencia.gestionHumana.sstAmbiental.administrar &&
                            !presidencia.gestionHumana.sstAmbiental.reportes &&
                            !presidencia.gestionHumana.sstAmbiental.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='6'>
                              <div className='accordionLevel3'>
                                {
                                  presidencia.gestionHumana.sstAmbiental.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/SSTyAmbiental/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.sstAmbiental.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/SSTyAmbiental/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.sstAmbiental.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/SSTyAmbiental/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.sstAmbiental.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/SSTyAmbiental/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.sstAmbiental.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/SSTyAmbiental/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia?.gestionHumana?.gestionHumana?.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='7' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.gestion_humana')}
                        </Accordion.Toggle>
                        {

                          !presidencia.gestionHumana.gestionHumana.solicitar &&
                            !presidencia.gestionHumana.gestionHumana.administrar &&
                            !presidencia.gestionHumana.gestionHumana.reportes &&
                            !presidencia.gestionHumana.gestionHumana.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='7'>
                              <div className='accordionLevel3'>
                                {
                                  presidencia.gestionHumana.gestionHumana.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/gestion/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.gestionHumana.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/gestion/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.gestionHumana.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/gestion/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.gestionHumana.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/gestion/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.gestionHumana.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/gestion/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                    {
                      presidencia?.gestionHumana?.sgsst?.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='8' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.gestion_humana.sgsst')}
                        </Accordion.Toggle>
                        {

                          !presidencia.gestionHumana.sgsst.solicitar &&
                            !presidencia.gestionHumana.sgsst.administrar &&
                            !presidencia.gestionHumana.sgsst.reportes &&
                            !presidencia.gestionHumana.sgsst.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='8'>
                              <div className='accordionLevel3'>
                                {
                                  presidencia.gestionHumana.sgsst.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/gestionHumana/sgsst/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.gestionHumana.sgsst.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/sgsst/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.sgsst.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/gestionHumana/sgsst/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.sgsst.reportes
                                  && <Link className='optionsLevel3 informeOption ' to="/presidencia/gestionHumana/sgsst/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.gestionHumana.sgsst.reportes_operativos
                                  && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/gestionHumana/sgssts/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          }

          {
            presidencia.juridico.activo
            &&
            <>
              {presidencia.juridico.solicitar || presidencia.juridico.administrar || presidencia.juridico.reportes || presidencia.juridico.reportes_operativos
                ?
                <Card className='AccordionCard'>
                  <Accordion.Toggle onClick={props.onOpenMenu} eventKey='3' className='menuOptions'>
                    <img className='menuIcon' src={iconJuridico} alt='panel_gerencia'></img>
                    {t('navegacion.menu_presidencia.juridico.juridico')}
                  </Accordion.Toggle>
                  {

                    <Accordion.Collapse eventKey='3'>
                      <div className='accordionLevel3'>

                        {
                          presidencia.juridico.solicitar
                          &&
                          <Link className='optionsLevel3 crearOption' to='/presidencia/juridico/solicitar'>
                            <p >
                              {t('navegacion.comun.solicitar')}
                            </p>
                          </Link>

                        }

                        {
                          presidencia.juridico.administrar
                          &&
                          <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/administrar">
                            <p >
                              {t('navegacion.comun.procesos')}
                            </p>
                          </Link>
                        }

                        {
                          presidencia.juridico.procesos
                          &&
                          <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos">
                            <p >
                              {t('navegacion.comun.procesos')}
                            </p>
                          </Link>
                        }

                        {
                          presidencia.juridico.reportes
                          && <Link className='optionsLevel3 informeOption ' to="/presidencia/juridico/reportes">
                            <p >
                              {t('navegacion.comun.reportes')}
                            </p>
                          </Link>

                        }

                        {
                          presidencia.juridico.reportes_operativos
                          && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/juridico/reportes_operativos">
                            <p >
                              {t('navegacion.comun.operativo')}
                            </p>
                          </Link>

                        }
                      </div>
                    </Accordion.Collapse>

                  }
                </Card>
                :


                <Card className='AccordionCard'>
                  <Accordion.Toggle onClick={props.onOpenMenu} eventKey='3' className='menuOptions'>
                    <img className='menuIcon' src={iconJuridico} alt='panel de presidencia'></img>
                    {t('navegacion.menu_presidencia.juridico.juridico')}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='3'>
                    <Card.Body className='accordionOptionsContainer'>

                      <Accordion>


                        {
                          presidencia.juridico.procesosAdministrativos.activo
                          &&
                          <Card className='AccordionCard'>
                            <Accordion.Toggle eventKey='1' className='accordionOptionsItem'>
                              {t('navegacion.menu_presidencia.juridico.procesos_administrativos')}
                            </Accordion.Toggle>



                            {
                              !presidencia.juridico.procesosAdministrativos.solicitar &&
                                !presidencia.juridico.procesosAdministrativos.administrar &&
                                !presidencia.juridico.procesosAdministrativos.reportes &&
                                !presidencia.juridico.procesosAdministrativos.reportes_operativos
                                ? <></>
                                : <Accordion.Collapse eventKey='1'>
                                  <div className='accordionLevel3'>

                                    {
                                      presidencia.juridico.procesosAdministrativos.solicitar
                                      &&
                                      <Link className='optionsLevel3 crearOption' to='/presidencia/juridico/procesos-administrativos/solicitar'>
                                        <p >
                                          {t('navegacion.comun.solicitar')}
                                        </p>
                                      </Link>

                                    }

                                    {
                                      presidencia.juridico.procesosAdministrativos.administrar
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-administrativos/administrar">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosAdministrativos.procesos
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-administrativos/procesos">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosAdministrativos.reportes
                                      && <Link className='optionsLevel3 informeOption ' to="/presidencia/juridico/procesos-administrativos/reportes">
                                        <p >
                                          {t('navegacion.comun.reportes')}
                                        </p>
                                      </Link>

                                    }

                                    {
                                      presidencia.juridico.procesosAdministrativos.reportes_operativos
                                      && <Link className='optionsLevel3 informeOption-op ' to="/presidencia/juridico/procesos-administrativos/reportes_operativos">
                                        <p >
                                          {t('navegacion.comun.operativo')}
                                        </p>
                                      </Link>

                                    }

                                  </div>
                                </Accordion.Collapse>

                            }
                          </Card>
                        }
                        {
                          presidencia.juridico.procesosCiviles.activo
                          &&
                          <Card className='AccordionCard'>
                            <Accordion.Toggle eventKey='2' className='accordionOptionsItem'>
                              {t('navegacion.menu_presidencia.juridico.procesos_civiles')}
                            </Accordion.Toggle>


                            {
                              !presidencia.juridico.procesosCiviles.solicitar &&
                                !presidencia.juridico.procesosCiviles.administrar &&
                                !presidencia.juridico.procesosCiviles.reportes &&
                                !presidencia.juridico.procesosCiviles.reportes_operativos
                                ? <></>
                                : <Accordion.Collapse eventKey='2'>
                                  <div className='accordionLevel3'>



                                    {
                                      presidencia.juridico.procesosCiviles.solicitar
                                      &&
                                      <Link className='optionsLevel3 crearOption' to='/presidencia/juridico/procesos-civiles/solicitar'>
                                        <p >
                                          {t('navegacion.comun.solicitar')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosCiviles.administrar
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-civiles/administrar">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosCiviles.procesos
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-civiles/procesos">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosCiviles.reportes
                                      &&
                                      <Link className='optionsLevel3 informeOption ' to="/presidencia/juridico/procesos-civiles/reportes">
                                        <p >
                                          {t('navegacion.comun.reportes')}
                                        </p>
                                      </Link>

                                    }

                                    {
                                      presidencia.juridico.procesosCiviles.reportes_operativos
                                      &&
                                      <Link className='optionsLevel3 informeOption-op ' to="/presidencia/juridico/procesos-civiles/reportes_operativos">
                                        <p >
                                          {t('navegacion.comun.operativo')}
                                        </p>
                                      </Link>

                                    }
                                  </div>
                                </Accordion.Collapse>
                            }
                          </Card>
                        }
                        {
                          presidencia.juridico.procesosComeciales.activo
                          &&
                          <Card className='AccordionCard'>
                            <Accordion.Toggle eventKey='3' className='accordionOptionsItem'>
                              {t('navegacion.menu_presidencia.juridico.procesos_comerciales')}
                            </Accordion.Toggle>


                            {
                              !presidencia.juridico.procesosComeciales.solicitar &&
                                !presidencia.juridico.procesosComeciales.administrar &&
                                !presidencia.juridico.procesosComeciales.reportes &&
                                !presidencia.juridico.procesosComeciales.reportes_operativos
                                ? <></>
                                : <Accordion.Collapse eventKey='3'>
                                  <div className='accordionLevel3'>



                                    {
                                      presidencia.juridico.procesosComeciales.solicitar
                                      &&
                                      <Link className='optionsLevel3 crearOption' to='/presidencia/juridico/procesos-comerciales/solicitar'>
                                        <p >
                                          {t('navegacion.comun.solicitar')}
                                        </p>
                                      </Link>
                                    }
                                    {
                                      presidencia.juridico.procesosComeciales.administrar
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-comerciales/administrar">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosComeciales.procesos
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-comerciales/procesos">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosComeciales.reportes
                                      &&
                                      <Link className='optionsLevel3 informeOption ' to="/presidencia/juridico/procesos-comerciales/reportes">
                                        <p >
                                          {t('navegacion.comun.reportes')}
                                        </p>
                                      </Link>

                                    }

                                    {
                                      presidencia.juridico.procesosComeciales.reportes_operativos
                                      &&
                                      <Link className='optionsLevel3 informeOption-op ' to="/presidencia/juridico/procesos-comerciales/reportes_operativos">
                                        <p >
                                          {t('navegacion.comun.operativo')}
                                        </p>
                                      </Link>

                                    }
                                  </div>
                                </Accordion.Collapse>
                            }
                          </Card>
                        }
                        {
                          presidencia.juridico.procesosLaborales.activo
                          &&
                          <Card className='AccordionCard'>
                            <Accordion.Toggle eventKey='4' className='accordionOptionsItem'>
                              {t('navegacion.menu_presidencia.juridico.procesos_laborales')}
                            </Accordion.Toggle>
                            {
                              !presidencia.juridico.procesosLaborales.solicitar &&
                                !presidencia.juridico.procesosLaborales.administrar &&
                                !presidencia.juridico.procesosLaborales.reportes &&
                                !presidencia.juridico.procesosLaborales.reportes_operativos
                                ? <></>
                                : <Accordion.Collapse eventKey='4'>
                                  <div className='accordionLevel3'>

                                    {
                                      presidencia.juridico.procesosLaborales.solicitar
                                      &&
                                      <Link className='optionsLevel3 crearOption' to='/presidencia/juridico/procesos-laborales/solicitar'>
                                        <p >
                                          {t('navegacion.comun.solicitar')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosLaborales.administrar
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-laborales/administrar">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosLaborales.procesos
                                      &&
                                      <Link className='optionsLevel3 adminOption' to="/presidencia/juridico/procesos-laborales/procesos">
                                        <p >
                                          {t('navegacion.comun.procesos')}
                                        </p>
                                      </Link>
                                    }

                                    {
                                      presidencia.juridico.procesosLaborales.reportes
                                      &&
                                      <Link className='optionsLevel3 informeOption ' to="/presidencia/juridico/procesos-laborales/reportes">
                                        <p >
                                          {t('navegacion.comun.reportes')}
                                        </p>
                                      </Link>

                                    }

                                    {
                                      presidencia.juridico.procesosLaborales.reportes_operativos
                                      &&
                                      <Link className='optionsLevel3 informeOption-op ' to="/presidencia/juridico/procesos-laborales/reportes_operativos">
                                        <p >
                                          {t('navegacion.comun.operativo')}
                                        </p>
                                      </Link>

                                    }


                                  </div>
                                </Accordion.Collapse>
                            }
                          </Card>
                        }
                      </Accordion>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

              }
            </>
          }

          {
            presidencia.tecnologiaInformacion.activo
            &&
            <Card className='AccordionCard'>
              <Accordion.Toggle onClick={props.onOpenMenu} eventKey='4' className='menuOptions'>
                <img className='menuIcon' src={iconTI} alt='panel de presidencia'></img>
                {t('navegacion.menu_presidencia.tecnologia_informacion.tecnologia_informacion')}
              </Accordion.Toggle>

              <Accordion.Collapse eventKey='4'>
                <Card.Body className='accordionOptionsContainer'>

                  <Accordion>



                    {
                      presidencia.tecnologiaInformacion.configuracion.activo
                      &&
                      <Card className='AccordionCard'>
                        <Accordion.Toggle eventKey='1' className='accordionOptionsItem'>
                          {t('navegacion.menu_presidencia.tecnologia_informacion.requerimientos')}
                        </Accordion.Toggle>

                        {

                          !presidencia.tecnologiaInformacion.configuracion.solicitar &&
                            !presidencia.tecnologiaInformacion.configuracion.administrar &&
                            !presidencia.tecnologiaInformacion.configuracion.reportes &&
                            !presidencia.tecnologiaInformacion.configuracion.reportes_operativos
                            ? <></>
                            : <Accordion.Collapse eventKey='1'>
                              <div className='accordionLevel3'>

                                {
                                  presidencia.tecnologiaInformacion.configuracion.solicitar
                                  &&
                                  <Link className='optionsLevel3 crearOption' to='/presidencia/TI/solicitar'>
                                    <p >
                                      {t('navegacion.comun.solicitar')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.tecnologiaInformacion.configuracion.administrar
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/TI/administrar">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.tecnologiaInformacion.configuracion.procesos
                                  &&
                                  <Link className='optionsLevel3 adminOption' to="/presidencia/TI/procesos">
                                    <p >
                                      {t('navegacion.comun.procesos')}
                                    </p>
                                  </Link>
                                }

                                {
                                  presidencia.tecnologiaInformacion.configuracion.reportes
                                  &&
                                  <Link className='optionsLevel3 informeOption ' to="/presidencia/TI/reportes">
                                    <p >
                                      {t('navegacion.comun.reportes')}
                                    </p>
                                  </Link>

                                }

                                {
                                  presidencia.tecnologiaInformacion.configuracion.reportes_operativos
                                  &&
                                  <Link className='optionsLevel3 informeOption-op ' to="/presidencia/TI/reportes_operativos">
                                    <p >
                                      {t('navegacion.comun.operativo')}
                                    </p>
                                  </Link>

                                }
                              </div>
                            </Accordion.Collapse>
                        }
                      </Card>
                    }
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          }
        </Accordion>
      </Nav>
    </div>
  )
}

