import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Card, Col, Nav, Row, Accordion } from 'react-bootstrap';
import NotificationModal from '../../../modales/NotificationModal'
import { HelpSVG } from '../../../../components/icons/HelpSVG';
import { validarEntorno } from '../../../../helpers/validarHost';

import Analisis from './Analisis/Analisis'
import Tendencia from './Analisis/Tendencia'
import Recomendaciones from './Analisis/Recomendaciones'
import { useTranslation } from 'react-i18next';

export default function AnalisisProspectivo() {

  const [listItems, setListItems] = useState({});
  const [eventFormId, setEventFormId] = useState(1);
  const [activeFormKey, setActiveFormKey] = useState(1);
  const { t } = useTranslation();

  const [modalNotificacion, setModalNotificacion] = useState({
    show: false,
    mensaje: "",
    tipo: ""
  });

  const [modal, setModal] = useState({
    show: false,
    tipo: 'modalInfoGestorDinamico',
    data: {
      tituloModal: '',
      contenidoModal: ''
    }
  })

  const id = useParams();
  const hash = window.location.hash;

  const items = async () => {

    const dataSend = {
      id_gestion: id,
      hash: window.location.hash
    }

    const request = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_analisis.html', 'POST', dataSend);

    if (request.ok) {
      const respuesta = await request.json();

      setListItems(respuesta);
    }
  }

  const renderForm = () => {
    if (Object.keys(listItems).length !== 0) {
      switch (eventFormId) {
        case 1:
          return <Analisis dataG={listItems.analisis} />
        case 2:
          return <Tendencia dataG={listItems.tendencia} />
        case 3:
          return <Recomendaciones dataG={listItems.recomendaciones} />

        default:
          return <></>
      }
    }
  }

  const eventFormIdValue = (value) => {
    setEventFormId(value);
    setActiveFormKey(value);
  }

  useEffect(() => {
    items();
  }, [activeFormKey])


  return (
    <>
      <Card>
        <Card.Body>
          <Row className="row-content">
            <Col xl={3} lg={3} md={4} xs={0} id="sidebar-wrapper">
              <Nav defaultActiveKey={activeFormKey} activeKey={activeFormKey} className="shadow p-2 flex-column nav-items">
                <Nav.Link className="item-a" eventKey="1" onClick={() => eventFormIdValue(1)}>
                  {t('panorama_riesgos.analisis_prospectivo')}
                </Nav.Link>
                <Nav.Link className="item-a" eventKey="2" onClick={() => eventFormIdValue(2)}>
                  {t('panorama_riesgos.tendencia_riesgo')}
                </Nav.Link>
                <Nav.Link className="item-a" eventKey="3" onClick={() => eventFormIdValue(3)}>
                  {t('panorama_riesgos.recomendaciones')}
                </Nav.Link>
              </Nav>
            </Col>
            <Col xl={9} lg={12} md={8} xs={12} id="page-content-wrapper">
              {renderForm()}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
