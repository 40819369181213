import { Card, Col, Row } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
// import { InformacionGeneral } from '../../components/gerenciaGeneral/inteligencia/Pestañas/InformacionGeneral';
import GestionAuditorias from '../../components/gestionAuditorias/Dashboard/GestionAuditorias';
import MatrizRiesgosOperaciones from '../../components/gestionAuditorias/MatrizRiesgos/MatrizRiesgosOperaciones';
import Dofa from '../../components/gestionAuditorias/DOFA/Dofa';
import AsignacionAud from '../../components/gestionAuditorias/Asignacion/AsignacionAud';
import PlanEstrategico from '../../components/gestionAuditorias/PlanEstrategico/PlanEstrategico';
import IntroduccionInforme from '../../components/gestionAuditorias/Dashboard/forms/introduccion/Main';
import ConclusionInforme from '../../components/gestionAuditorias/Dashboard/forms/Conclusiones/Main';
// AprobarSolicitudDesbloqueo

import './styles/GestionRealizada.scss'

import GetAppIcon from '@material-ui/icons/GetApp';
import { HelpSVG } from '../../components/icons/HelpSVG';
import SuccessIcon from '../../helpers/SuccessIcon/SuccessIcon'
import { ErrorSVG } from '../../components/icons/ErrorSVG'
import { WarningModalSVG } from '../../components/icons/WarningModalSVG'
// import { hashListo } from '../../helpers/hash';
import PopUpModal from '../../helpers/PopUpModal';
import { validarEntorno } from '../../helpers/validarHost';
import { useTranslation } from 'react-i18next';

export const GestionRealizada = ({ props }) => {

  let { id } = useParams();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    const dataEnviar = {
      idSolicitud: id,
      hash: window.location.hash,
    }
    validarEntorno('JSON/gestionar_auditoria_cliente_json.html', 'POST', dataEnviar).then(res => {
      if (res.ok) {
        res.json()
          .then(dataRespuesta => {

            setData(dataRespuesta)
            // console.log(data?.informacion_solicitud?.estado_cos?.valor)

            setPestaña(dataRespuesta.data[0].pestana)
          })
      }
    })
  }, [id])


  const [pestaña, setPestaña] = useState('')

  const informacionSolicitud = data.informacion_solicitud


  return (
    <Fragment>
      {
        data.data
        &&
        <Fragment>
          <div className="d-flex" style={{ flexDirection: 'column' }}>
            <div className='titleBox'>
              {/* <h3 className="mt-5" style={{ color: '#213265', fontWeight: '800' }}>Solicitudes de estudio____</h3> */}
              <div className='titleContainer'>
                <h4 className='pagesTitles'>{data.titulo} - {data.tipoSolicitud} </h4>
              </div>
              <div onClick={() => setOpen(true)}>
                <HelpSVG />
              </div>
            </div>

            <br />
            {data.informacion_solicitud &&
              <>
                <Card className='card__box__surface card cardGeneral'>
                  <Card.Body>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
                        {data?.informacion_solicitud?.conclusion !== {}
                          ?
                          <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'space-around' }}>
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'space-around',
                                justifyContent: 'space-around'

                              }}

                            >
                              {informacionSolicitud.conclusion.icono === 'exito'
                                ? <div>
                                  <SuccessIcon />
                                </div>
                                : informacionSolicitud.conclusion.icono === 'error'
                                  ? <div>
                                    <ErrorSVG />
                                  </div>
                                  : informacionSolicitud.conclusion.icono === 'advertencia'
                                    ? <div>
                                      <WarningModalSVG />
                                    </div>
                                    : informacionSolicitud.conclusion.icono === 'proceso'
                                      ? <div>
                                        <img alt='icono de carga' style={{ width: '95px' }} src="https://www.ciacosinte.site/cia2/static/media/rombo.svg" />
                                        {/* <a href="https://icons8.com/icon/TXtRzGNlGEgi/rhombus-loader">Rhombus Loader icon by Icons8</a> */}
                                      </div>
                                      : informacionSolicitud.conclusion.icono === '' &&
                                      <div>
                                        <WarningModalSVG />

                                      </div>
                              }
                              {informacionSolicitud.conclusion.valor !== ''
                                ?

                                <h3>{informacionSolicitud.conclusion.valor}</h3>
                                :
                                <h3>Verificar estado</h3>

                              }
                            </div>
                            <br />
                            {informacionSolicitud.generarPDF.visualizar === true &&
                              <div className='buttonGenerarPDF'>
                                <a href={informacionSolicitud.generarPDF.link} rel='noreferrer' target='_blank'>
                                  <button className='confirmButton'>
                                    {t('gestion_realizada.comun.generar_pdf')}
                                    <GetAppIcon />
                                  </button>
                                </a>
                              </div>
                            }

                          </div>

                          :
                          <div>
                            <WarningModalSVG />
                            <h3>Verificar estado </h3>
                          </div>
                        }
                      </Col>
                      <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
                        {informacionSolicitud.estado.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p className='labelInfo'>{t('gestion_realizada.comun.estado')}</p>
                            <p className='labelInfoContent'>{informacionSolicitud.estado.valor}</p>
                          </div>
                        }
                        {informacionSolicitud.fecha_solicitud.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p className='labelInfo'>{informacionSolicitud.fecha_solicitud.nombre}</p>
                            <p className='labelInfoContent'>{informacionSolicitud.fecha_solicitud.valor}</p>
                          </div>

                        }
                        {informacionSolicitud.perfil.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.perfil.tipo}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.perfil.nombre}
                            </p>
                          </div>
                        }
                        {informacionSolicitud.analista.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.analista.nombre}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.analista.valor}
                            </p>
                          </div>
                        }
                        <div className='infoBasicaEditarRegistro'>
                          <p className='labelInfo'>{t('gestion_realizada.comun.tipo_solicitud')}</p>
                          <p className='labelInfoContent'>{data.tipoSolicitud}</p>
                        </div>
                        {informacionSolicitud.fecha_finalizacion.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p className='labelInfo'>{t('gestion_realizada.comun.fecha_finalizacion')}</p>
                            <p className='labelInfoContent'>{informacionSolicitud.fecha_finalizacion.valor}</p>
                          </div>
                        }

                      </Col>
                      <Col xs={6} lg={4} md={3} className='listPopUp mt-3'>
                        {informacionSolicitud.identificador.visualizar === true
                          ?
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.identificador.tipo}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.identificador.valor}
                            </p>
                          </div>
                          : null
                        }
                        <div className='infoBasicaEditarRegistro'>
                          <p>ID</p>
                          <p className='labelInfoContent'>
                            {id}
                          </p>
                        </div>
                        {informacionSolicitud.estado_cos.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>Estado-cos</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.estado_cos.valor}
                            </p>
                          </div>
                        }
                        {informacionSolicitud.fecha_cosinte.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.fecha_cosinte.nombre}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.fecha_cosinte.valor}
                            </p>
                          </div>
                        }
                        {informacionSolicitud.fecha_limite.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>Fecha límite</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.fecha_limite.valor}
                            </p>
                          </div>
                        }
                        <div className='infoBasicaEditarRegistro'>
                          <p>{informacionSolicitud.tipo_proceso.nombre}</p>
                          <p className='labelInfoContent'>
                            {informacionSolicitud.tipo_proceso.valor}
                          </p>
                        </div>
                        {informacionSolicitud.responsable.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.responsable.nombre}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.responsable.valor}
                            </p>
                          </div>
                        }
                        {informacionSolicitud.acompanante.visualizar === true &&
                          <div className='infoBasicaEditarRegistro'>
                            <p>{informacionSolicitud.acompanante.nombre}</p>
                            <p className='labelInfoContent'>
                              {informacionSolicitud.acompanante.valor}
                            </p>
                          </div>
                        }
                      </Col>
                      {/* <Col className='col-12 buttonGenerarPDF'>
                                        <a href={`https://ciacosinte.site/crm/upload/informe_investigacion_PDF.html?id=${idRegistro}`} target='_blank'>
                                            <button className='confirmButton'>
                                                Generar PDF
                                                <GetAppIcon/>
                                            </button>

                                        </a>
                                    </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
                <br />
                <br />
              </>
            }
            <br />


            <div className='card__box__surface containerPestañas mt-2 mb-2 card' style={{ display: 'flex', textAlign: 'center', flexDirection:"row"}}>
              <br />
              {
                data.data.map((ele, index) => {
                  return <div
                    key={index}
                    className="pestanaGestorAud"
                    id=''
                    style={{
                      background: pestaña !== ele.pestana && '#CBD7E6',
                      color: pestaña !== ele.pestana && '#213265'

                    }}
                    onClick={() => setPestaña(ele.pestana)}
                  >
                    <p className=" p-0 m-0">
                      {ele.pestana === 'DOFA'
                        ? t('gestion_realizada.titulos.dofa')
                        : ele.pestana === 'introduccion_del_informe'
                          ? t('gestion_realizada.titulos.introduccion_informe') :
                          ele.pestana === 'areas_de_diagnostico'
                            ? t('gestion_realizada.titulos.areas_diagnostico') :
                            ele.pestana === 'matriz_de_riesgos'
                              ? t('gestion_realizada.titulos.matriz_riesgos') :
                              ele.pestana === 'dofa'
                                ? t('gestion_realizada.titulos.dofa') :
                                ele.pestana === 'plan_estrategico'
                                  ? t('gestion_realizada.titulos.plan_estrategico') : ele.pestana === 'cierre_del_informe'
                                    ? t('gestion_realizada.titulos.cierre_informe') : ele.pestana === 'asignacion'
                                      ? t('gestion_realizada.titulos.configuracion') : ''
                      }
                    </p>
                    <b style={{
                      width: '25px',
                      fontSize: '30px',
                      marginTop: '-20px',
                      fontWeight: '900',
                      color: index === 0
                        ? '#00A20B'
                        : index === 1
                          ? '#00A20B'
                          : index === 2
                            ? '#00A20B'
                            : index === 3
                              ? '#00A20B'
                              : index === 4
                                ? '#00A20B'
                                : index === 5
                                  ? '#00A20B'
                                  : index === 6
                                    ? '#00A20B'
                                    : index === 7
                                      ? '#00A20B'
                                      : index === 8
                                        ? '#00A20B'
                                        : index === 9
                                          ? '#00A20B'
                                          : 'red'
                    }}>.</b>

                  </div>
                })
              }
            </div>
            <br />
            {data.data.map((ele, index) => {
              if (ele.pestana === pestaña) {
                return pestaña === 'DOFA'
                  ? ''
                  : pestaña === 'introduccion_del_informe'
                    ? <IntroduccionInforme />
                    : pestaña === 'areas_de_diagnostico'
                      ? <GestionAuditorias type={2} /> :
                      pestaña === 'matriz_de_riesgos'
                        ? <MatrizRiesgosOperaciones data={data.data[index]} /> :
                        pestaña === 'dofa'
                          ? <Dofa dataDofa={data.data[index]} /> :
                          pestaña === 'plan_estrategico'
                            ? <PlanEstrategico data={data.data[index]} /> :
                            pestaña === 'cierre_del_informe'
                              ? <ConclusionInforme /> :
                              pestaña === 'asignacion'
                                ? <AsignacionAud /> : ''
                // AsignacionAud
              }
              return <Fragment key={index}></Fragment>
            })
            }
          </div>
        </Fragment>
      }
      <PopUpModal titulo={data.titulo} texto={data.texto} open={open} setOpen={setOpen} />
    </Fragment>
  )
}
