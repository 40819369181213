import React, {useEffect, useState} from 'react'

import {GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow, Polygon, Geocoder, Circle} from 'react-google-maps'
import Polyline from 'react-google-maps/lib/components/Polyline';

import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import EditModal from '../../../components/modales/EditModal';

// 3.4334185,-76.4517656, 1.2047533,-77.2699356

// const cali = { lat: 3.4334185, lng: -76.4517656}

import '../styles/Heatmap.scss'


const iconos = {
  cultivo_ilicito: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743798/planta_bt7n25.svg'
  },
  atentado: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743964/explosion_qisd6j.svg'
  },
  oleoducto: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/valvula.svg'
  }
}

const stringInfoWindow = 'hola'

const HeatMap = (props) => {

  const {
    coords,
    getCoordinates,
    index,
    tipoMapa,
    idx,
    showDescriptionModal,
    setShowDescriptionModal,
    isForm,
    submitLatLng,
    setFormNumber,
    setLat,
    setLng,
    lat,
    lng,
    marker,
    seccionPoligono,
    latitud,
    longitud,
    color
  } = props

  const [ openInfo, setOpenInfo ] = useState(false);
  const [ descripcion, setDescripcion ] = useState('');
  const [invalidDes, setInvalidDes] = useState(false);
  const [markerSelected, setMarkerSelected] = useState({})

  const handleOpenInfo = () => {
    setOpenInfo(!openInfo)
  }

  const onSelect = item => {
    setMarkerSelected(item);
    console.log(markerSelected)
  }

  const getLatLng = (e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
    setFormNumber(idx);

    const latitud = e.latLng.lat();
    const longitud = e.latLng.lng();

    if(props.tipoMapa === 'trazado' || props.tipoMapa === 'oleoducto'){
      setShowDescriptionModal(index, idx);
    } else {
      getCoord(latitud, longitud);
    }
  }

  const closeModal =  () => {
    setShowDescriptionModal(index, idx);
  }

  const getCoord = (latitud, longitud) => {
      setFormNumber(idx);
      getCoordinates(latitud, longitud, index, descripcion);
      setDescripcion('');
  }

  const getCoordForm = (e) => {
    setShowDescriptionModal(index, idx);
    submitLatLng(e, index, descripcion);
    setDescripcion('');
  }

  const getPoints = () => {
    let arr = [];
    if (coords.length > 0) {
      // eslint-disable-next-line array-callback-return
      coords.map((hm) => {      // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(hm.lat, hm.lng)
        arr.push(latlng);
      });
    }

    return arr;
  }

  const getCenter = () => {
    let puntoCentral = {};

    if (seccionPoligono) {
      if (seccionPoligono[0].puntos.length > 1) {
        /* eslint-disable array-callback-return */
        /* eslint-disable no-undef */
        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < seccionPoligono[0].puntos.length; i++) {
            bounds.extend(seccionPoligono[0].puntos[i]);
        }

        puntoCentral = {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng()
        }

        return puntoCentral;
      } if (coords.length === 1) {

          puntoCentral = {
              lat: seccionPoligono[0].puntos[0].lat,
              lng: seccionPoligono[0].puntos[0].lng
          }

          return puntoCentral;
      }
    } else {
      puntoCentral = {
        lat: latitud,
        lng: longitud
      }

      return puntoCentral;
    }

  }

  const getMapType = () => {
      switch (tipoMapa) {
        case 'puntos_calor':
            return (
              <HeatmapLayer
                data={getPoints()}
                radius={1000}
              />
            );
        case 'trazado':
          return (
            <Polyline
              path={coords}
              geodesic={true}
              options={{
                strokeColor: "#ff2527",
                strokeOpacity: 0.75,
                strokeWeight: 2
              }}
            />
          );
        case 'puntos_simples':
          return (
            coords.map((coord, index) => (
              <Marker
               position={coord}
              />
          )));
        case 'poligono':
          return (
            <Polygon
              path={coords}
              //key={1}
              options={{
                  fillColor: getRiskPolylyneColor(),
                  fillOpacity: 0.4,
                  strokeColor: getRiskPolylyneColor(),
                  strokeOpacity: 1,
                  strokeWeight: 1
              }}
            />
          )
        case 'oleoducto':
          return(
            <>
              <Polyline
                path={coords}
                geodesic={true}
                options={{
                  strokeColor: "#82EA35",
                  strokeOpacity: 0.75,
                  strokeWeight: 5
                }}
              />
              {coords.map((coord, index) => (
                <Marker
                  position={coord}
                  icon={iconos.oleoducto.icono}
                />
              ))}
            </>
          )
        default:
          break;
      }
  }

  const handleChangeData = (e) => {
    const { value } = e.target;
    setDescripcion(value);
  }

  const getRiskPolylyneColor = (risk) => {

    let color = "#000";

    switch (risk) {
      case 'bajo':
        color = '#23A835';
        break;
      case 'medio':
        color = '#DBB93D';
        break;
      case 'alto':
        color = '#EA5335'
        break;
      default:
        color = "#000";
        break;
    }

    return color;
  }

  // useEffect(() => {
  //   showMunicipio()
  // }, [municipioSelected ? municipioSelected : null])

  return(
    <>
      { (props.tipo && props.tipo === 'ubicacion') && !seccionPoligono ? (
        <GoogleMap
          defaultZoom={props.zoom ? props.zoom : 5}
          defaultCenter={{
            lng: parseFloat(props.longitud),
            lat: parseFloat(props.latitud)
          }}
          center={{
            lng: parseFloat(props.longitud),
            lat: parseFloat(props.latitud)
          }}
          // onClick={(e) => getLatLng(e)}
        >
          <>
            <Marker
              position={marker}
            />
            {/* <Circle
              center={marker}
              radius={6000}
              options={{
                strokeColor: "#000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "transparent",
                fillOpacity: 0.35,
              }}
            /> */}
          </>
          { getMapType() }
        </GoogleMap>
      ) : (
        <GoogleMap
          defaultZoom={props.zoom ? props.zoom : 5}
          defaultCenter={ getCenter() }
          onClick={(e) => getLatLng(e)}
        >
          { (seccionPoligono && seccionPoligono.length > 0) && (seccionPoligono.map((seccion, index) => {
              return (
                <Polyline
                  path={seccion.puntos}
                  key={index}
                  options={{
                      fillColor: getRiskPolylyneColor(seccion.tipo_riesgo),
                      fillOpacity: 0.4,
                      strokeColor: getRiskPolylyneColor(seccion.tipo_riesgo),
                      strokeOpacity: 1,
                      strokeWeight: 5
                  }}
                />
              )
            })
          )}
          { getMapType() }
        </GoogleMap>
      ) }
      <EditModal
        typeModal="obtenerCoordenadasDes"
        isOpen={showDescriptionModal}
        setIsOpen={closeModal}
        handleData={handleChangeData}
        descripcion={descripcion}
        setConfirmarUbicacion={getCoord}
        setFormUbicacion={getCoordForm}
        invalidDes={invalidDes}
        lat={lat}
        lng={lng}
        isForm={isForm}
      />
    </>
  );
}

export default withScriptjs(
  withGoogleMap(
    HeatMap
  )
)

