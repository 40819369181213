import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap'
// import {Link} from 'react-router-dom'
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { HelpSVG } from '../../../../components/icons/HelpSVG'
import { WarningModalSVG } from '../../../../components/icons/WarningModalSVG';
import SuccessIcon from '../../../../helpers/SuccessIcon/SuccessIcon';
import { ErrorSVG } from '../../../../components/icons/ErrorSVG';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import PopUpModal from '../../../../helpers/PopUpModal'
// import {EditarEstudio} from '../../../../components/gerenciaGeneral/EditarEstudio'
import { BeneficiariosEditarEstudio } from '../../../../components/gerenciaGeneral/BeneficiariosEditarEstudio';
import GestorDocumentos from '../../../../components/gerenciaGeneral/inteligencia/GestorDocumentos'
import GestorNotas from '../../../../components/gerenciaGeneral/investigaciones/investigacionesAdministrativas/Notas'
import AsignacionAnalistas from '../../../../components/gerenciaGeneral/investigaciones/investigacionesAdministrativas/AsignacionAnalistas'
import ConclusionInvestigacion from '../../../../components/gerenciaGeneral/investigaciones/investigacionesAdministrativas/ConclusionInvestigacion'
import Seguimientos from '../../../../components/gerenciaGeneral/investigaciones/investigacionesAdministrativas/Seguimientos'
import Entrevistas from '../../../../components/gerenciaGeneral/investigaciones/investigacionesAdministrativas/Entrevistas'
import NotificationModal from '../../../../components/modales/NotificationModal';


//PESTAÑAS DE INTELIGENCIA

import InformacionRegistroEstudio from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/InformacionRegistroEstudio'
import InformacionPersonalBasica from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/InformacionEstudio'
import Anotaciones from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Anotaciones'
import AnotacionesGrupoFamiliar from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/AnotacionesGrupoFamiliar'
import AsignacionAnalistasInteligencia from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/AsignacionAnalistas'
import Cesantias from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Cesantias'
import Conclusiones from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Conclusiones'
import Eyedetect from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Eyedetect'
import Financiero from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Financiero'
import InformacionAcademica from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/InformacionAcademica'
import InformacionFamiliar from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/InformacionFamiliar'
import InformacionEstudio from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/InformacionEstudio'
import InformacionLaboral from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/InformacionLaboral'
import InformacionPersonalBasicaInteligencia from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/InformacionPersonalBasica'
import Patrimonial from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Patrimonial'
import Poligrafo from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Poligrafo'
import ProtocoloSeguridad from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/ProtocoloSeguridad'
import PruebasPsicotecnicas from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/PruebasPsicotecnicas'
import Sarlaft from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Sarlaft'
import Soportes from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Soportes'
import Visita from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/Visita'
import VSA from '../../../../components/gerenciaGeneral/inteligencia/editarEstudio/pestanas/VSA'

import './EditarRegistro.scss'
import RegistroFotografico from '../../../../components/gerenciaGeneral/investigaciones/investigacionesAdministrativas/RegistroFotografico';

export default function EditarRegistro(props) {

  const [editarEstudio, setEditarEstudio] = useState(false)
  //   console.log(editarEstudio)
  const [open, setOpen] = useState(false)
  const [notificationOpen, setNotificationOpen] = useState(false)
  const [estadoRegistro, setEstadoRegistro] = useState('')
  const [dataSolicitud, setdataSolicitud] = useState({
    info: {}
  })

  const [validated, setValidated] = useState(false)
  const [pestaña, setPestaña] = useState('InformacionRegistroEstudio');
  const idRegistro = props.match.params.idSolicitud

  //   const hash = window.location.hash


  useEffect(() => {

    fetchDataSolicitud()
    // console.log(idRegistro);
    // console.log(hash)
  }, [])

  //   `https://127.0.0.1:5500/public/JSON/10.EditarRegistroEjemplo.json.html&${idRegistro}`
  // `https://www.cosinte.co/cia_2/registro_solicitudes.html?id=${idRegistro}`
  // https://www.cosinte.co/cia_2/build/JSON/EditarRegistroEjemplo_json.php

  const fetchDataSolicitud = async () => {
    //   const idRegistro = props.match.params.idSolicitud

    try {
      const request = await fetch(`/JSON/10.EditarRegistroEjemplo.json.html`);

      const data = await request.json()
      // setdataSolicitud(data)
      setEstadoRegistro(...estadoRegistro, data.dataSolicitud[0].estadoSolicitud)
      // setFormNew(...formNew, data.dataSolicitud[0])
      return setdataSolicitud({ info: data.dataSolicitud[0] })
    } catch (error) {
      console.log(error.message);
    }
  }

  //   const filtroEstudio = useSelector(state => state.filtroEstudio)
  //     const { data } = filtroEstudio
  //     const dispatch = useDispatch()

  //   useEffect(() => {
  //     let data = data
  //     fetchDataSolicitud();
  //   },[data])

  //   const fetchDataSolicitud = async (e) => {
  //     try {
  //         const data = await fetch()
  //     } catch (error) {
  //         console.log(error.message);
  //     }
  //   }

  const [formNew, setFormNew] = useState({})
  const [formInfoAfiliado, setFormInfoAfiliado] = useState({})
  const [formRevisionCalidad, setFormRevisionCalidad] = useState({})
  const [formSolicitudInvestigacion, setFormSolicitudInvestigacion] = useState({})


  const submitFormInfoCausante = async (e) => {
    e.preventDefault()
    const formCausante = e.currentTarget;

    // si hay errores en el formulario
    if (formCausante.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    // console.log(formCausante)

    setValidated(true);
    setNotificationOpen(true);


    await fetch(URL_BASE, {
      method: 'POST',
      headers: {
        'content-Type': 'application/json'
      },
      body: JSON.stringify(formInfoAfiliado)
    })
  }

  const handleInputFormAfiliado = ({ target }) => {
    setFormInfoAfiliado({
      ...formInfoAfiliado,
      [target.name]: target.value
    })
    setNotificationOpen(false);
    // console.log(formInfoAfiliado);
  }

  const handleInputChangeNew = ({ target }) => {

    // console.log(target.value)
    setFormNew({
      ...formNew,
      [target.name]: target.value
    })
    setNotificationOpen(false);

    // console.log(formNew);
  }

  const submitFormInfoGeneral = async (e) => {
    e.preventDefault()
    const form = e.currentTarget;

    // si hay errores en el formulario
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    // console.log(formNew)

    setValidated(true);
    setNotificationOpen(true);

    await fetch(URL_BASE, {
      method: 'POST',
      headers: {
        'content-Type': 'application/json'
      },
      body: JSON.stringify(formNew)
    })
  }

  const handleSubmitRevisionCalidad = async (e) => {

    const formRevisionSolicitud = e.currentTarget;

    if (formRevisionSolicitud.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    // console.log(formRevisionCalidad)

    setValidated(true);
    setNotificationOpen(true);

    await fetch(URL_BASE, {
      method: 'POST',
      headers: {
        'content-Type': 'application/json'
      },
      body: JSON.stringify(formRevisionCalidad)
    })


  }


  const handleChangeRevisionCalidad = ({ target }) => {
    // console.log(target.value)
    setFormRevisionCalidad({
      ...formRevisionCalidad,
      [target.name]: target.value
    })
    setNotificationOpen(false);
    // console.log(formRevisionCalidad);

    // console.log(formRevisionCalidad);
  }

  const [formState, setFormState] = useState(false)


  const URL_BASE = `https://www.ciacosinte.site/cia2/editar_investigacion.html?id=${idRegistro}`;

  const handleChangeSolicitudObservacion = ({ target }) => {
    // console.log(target.value)
    setFormSolicitudInvestigacion({
      ...formSolicitudInvestigacion,
      [target.name]: target.value
    })
    setNotificationOpen(false);
    // console.log(formSolicitudInvestigacion);

    // console.log(formRevisionCalidad);
  }

  const submitFormSolicitudObservacion = async (e) => {

    const formSolicitudInvestigacion = e.currentTarget;

    if (formSolicitudInvestigacion.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    // console.log(formSolicitudInvestigacion)

    setValidated(true);
    setNotificationOpen(true);

    await fetch(URL_BASE, {
      method: 'POST',
      headers: {
        'content-Type': 'application/json'
      },
      body: JSON.stringify(formSolicitudInvestigacion)
    })


  }



  return (
    <div>
      <>
        <div style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
          <div md={6}>
            <div className='titleContainer'>
              <h2 className='pagesTitles'>
                {dataSolicitud.info.titulo}
              </h2>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'right', cursor: 'pointer', margin: '-32px 1px 1px auto', width: '120px', display: 'block', }} onClick={() => setOpen(true)} >
          <HelpSVG />
        </div>
        {/* <EditarEstudio setEditarEstudio={setEditarEstudio} />  */}
        <PopUpModal open={open} setOpen={setOpen} />

      </>
      <>
        <IconButton onClick={() => {
          setEditarEstudio(false)
        }}>
          <ArrowBackIcon fontSize='large' style={{ background: '#213265', borderRadius: '50%', color: 'white' }} />
        </IconButton>
        {/* Body resumen */}
        <br />

        {dataSolicitud.info.perfil === 'investigaciones'
          ?
          <>
            <Card id='cardInformacionBasica' className="shadow" style={{ borderRadius: '10px' }}>
              <Card.Body>

                <Row>
                  <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
                    {dataSolicitud.info.estadoSolicitud
                      ?
                      <>
                        <div>
                          {estadoRegistro === 'aprobado'
                            ? <div>
                              <SuccessIcon />
                              <h3>{`${estadoRegistro}`}</h3>
                            </div>
                            : estadoRegistro === 'denegado'
                              ? <div>
                                <ErrorSVG />
                                <h3>{`${estadoRegistro}`}</h3>
                              </div>
                              : estadoRegistro === 'pendiente de aprobacion' || ''
                                ? <div>
                                  <WarningModalSVG />
                                  <h3>{`${estadoRegistro}`}</h3>
                                </div>
                                : <div>
                                  <WarningModalSVG />
                                  <h3>Verificar estado </h3>
                                </div>
                          }
                        </div>
                        <br />
                        <div className='col-12 buttonGenerarPDF'>
                          <a href={`https://ciacosinte.site/crm/upload/informe_investigacion_PDF.html?id=${idRegistro}`} rel="noreferrer" target='_blank'>
                            <button className='confirmButton'>
                              Generar PDF
                              <GetAppIcon />
                            </button>

                          </a>

                        </div>

                      </>

                      : null
                    }
                  </Col>
                  <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
                    <div className='infoBasicaEditarRegistro'>
                      <p className='labelInfo'>Estado:</p>
                      <p className='labelInfoContent'>{dataSolicitud.info.estadoSolicitud}</p>
                    </div>
                    <div className='infoBasicaEditarRegistro'>
                      <p className='labelInfo'>Fecha de solicitud:</p>
                      <p className='labelInfoContent'>{dataSolicitud.info.fechaSolicitud}</p>
                    </div>
                    <div className='infoBasicaEditarRegistro'>
                      <p>Radicado:</p>
                      <p className='labelInfoContent'>
                        {dataSolicitud.info.radicado}
                      </p>
                    </div>
                    <div className='infoBasicaEditarRegistro'>
                      <p>Analista</p>
                      <p className='labelInfoContent'>
                        {dataSolicitud.info.analista}
                        <br />
                        {dataSolicitud.info.analista_correo}
                      </p>
                    </div>

                  </Col>
                  <Col xs={6} lg={4} md={3} className='listPopUp mt-3'>
                    <div className='infoBasicaEditarRegistro'>
                      <p>ID:</p>
                      <p className='labelInfoContent'>
                        {idRegistro}
                      </p>
                    </div>
                    <div className='infoBasicaEditarRegistro'>
                      <p className='labelInfo'>Estado-Cosinte:</p>
                      <p className='labelInfoContent'>{dataSolicitud.info.estadoCosinte}</p>
                    </div>
                    <div className='infoBasicaEditarRegistro'>
                      <p>Etapa:</p>
                      <p className='labelInfoContent'>
                        {dataSolicitud.info.etapa}
                      </p>
                    </div>
                    <div className='infoBasicaEditarRegistro'>
                      <p>Fecha de solicitud Cosinte:</p>
                      <p className='labelInfoContent'>
                        {dataSolicitud.info.fechaSolicitudCosinte}
                      </p>
                    </div>
                    <div className='infoBasicaEditarRegistro'>
                      <p>Fecha límite:</p>
                      <p className='labelInfoContent'>
                        {dataSolicitud.info.fechaLimite}
                      </p>
                    </div>
                  </Col>
                  {/* <Col className='col-12 buttonGenerarPDF'>
                            <a href={`https://ciacosinte.site/crm/upload/informe_investigacion_PDF.html?id=${idRegistro}`} target='_blank'>
                                <button className='confirmButton'>
                                    Generar PDF
                                    <GetAppIcon/>
                                </button>

                            </a>
                        </Col> */}
                </Row>
              </Card.Body>
            </Card>
            <br />
            {dataSolicitud.info.acciones === true
              ?
              <>
                <h4 className='titleCard'>Acciones</h4>
                <Card className="shadow cardGeneral" id='actionsButtonsCard'>
                  <Card.Body id='actionsButtonsCardBody'>
                    <div className='actionsButtonsContainer'>
                      <button className='ActionsbuttonsEditarRegistro' >Enviar observación a revisión</button>
                      <button className='ActionsbuttonsEditarRegistro' >Finalizar Observación</button>
                      <button className='ActionsbuttonsEditarRegistro' >Finalizar Investigación </button>
                      <button className='ActionsbuttonsEditarRegistro' >Enviar a Investigador</button>
                      <button className='ActionsbuttonsEditarRegistro' >Enviar a Aprobación</button>
                      <button className='ActionsbuttonsEditarRegistro' >Enviar Investigación a Revisión</button>
                    </div>
                  </Card.Body>
                </Card>
              </>
              : null
            }
            <br />
            {dataSolicitud.info.pestanas === true
              ?
              <>
                <div id='contenedorPestanas' className='pestañasEditarRegistroScroll'>
                  <div className='pestañasEditarRegistroContainer' style={{ display: 'flex', textAlign: 'center' }}>
                    {dataSolicitud.info.pestanaInfoGeneral === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'informacionEstudio' && '#CBD7E6' }}
                          onClick={() => setPestaña('informacionEstudio')}
                        >
                          <p className=" p-1 m-0"> Información general </p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanainfoCausante === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'infoAfiliado' && '#CBD7E6' }}
                          onClick={() => setPestaña('infoAfiliado')}
                        >
                          <p className=" p-1 m-0"> Información del causante </p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaRevisionCalidad === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'revisionCalidad' && '#CBD7E6' }}
                          onClick={() => setPestaña('revisionCalidad')}
                        >
                          <p className=" p-1 m-0"> Revisión de calidad* </p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaBeneficiarios === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'beneficiarios' && '#CBD7E6' }}
                          onClick={() => setPestaña('beneficiarios')}
                        >
                          <p className=" p-1 m-0">Beneficiarios</p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaSolicitudObservacion === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'solicitudObservacion' && '#CBD7E6' }}
                          onClick={() => setPestaña('solicitudObservacion')}
                        >
                          <p className=" p-1 m-0">Solicitud de observación</p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaAsignacionAnalista === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'asignacionAnalistas' && '#CBD7E6' }}
                          onClick={() => setPestaña('asignacionAnalistas')}
                        >
                          <p className=" p-1 m-0">Asignación de analista </p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaSoportesDocumentales === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'gestorDocumental' && '#CBD7E6' }}
                          onClick={() => setPestaña('gestorDocumental')}
                        >
                          <p className=" p-1 m-0">Soportes documentales </p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaRegistroFotografico === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'registroFotografico' && '#CBD7E6' }}
                          onClick={() => setPestaña('registroFotografico')}
                        >
                          <p className=" p-1 m-0">Registro fotográfico</p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaSeguimientos === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'seguimientos' && '#CBD7E6' }}
                          onClick={() => setPestaña('seguimientos')}
                        >
                          <p className=" p-1 m-0">Seguimientos</p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaEntrevistas === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'entrevistas' && '#CBD7E6' }}
                          onClick={() => setPestaña('entrevistas')}
                        >
                          <p className=" p-1 m-0">Entrevistas</p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }

                    {dataSolicitud.info.pestanaNotas === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'notas' && '#CBD7E6' }}
                          onClick={() => setPestaña('notas')}
                        >
                          <p className=" p-1 m-0">Notas</p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                    {dataSolicitud.info.pestanaConclusiones === true
                      ?
                      <>
                        <div
                          className="d-flex pestañasLi"
                          style={{ background: pestaña !== 'conclusiones' && '#CBD7E6' }}
                          onClick={() => setPestaña('conclusiones')}
                        >
                          <p className=" p-1 m-0">Conclusión de la investigación</p>
                          <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                        </div>
                      </>
                      : null
                    }
                  </div>
                </div>
              </>
              : null
            }

            {
              dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'informacionEstudio'
                ? <Card className="shadow cardContainer" >
                  <Card.Body id='cardBodyInfoGeneral'>
                    <Form className='formInfoGeneral' noValidate validated={validated} onSubmit={submitFormInfoGeneral}>
                      {dataSolicitud.info.pestanaInfoGeneral === true && dataSolicitud.info.infoGeneralSeccionBase[0].activo === true
                        ?
                        <>
                          {dataSolicitud.info.editarInfoGeneralSeccionBase === true
                            ?
                            <div className='contenedorinfoGeneralRegistro'>
                              <div className='formItem' id='basicFormA'>
                                <Form.Row>
                                  <div className='dateContainer'>
                                    {dataSolicitud.info.infoGeneralSeccionBase[0].fechaSolicitud === true
                                      ?
                                      <Form.Group>
                                        <Form.Label>
                                          Fecha de solicitud
                                        </Form.Label>
                                        <div className='inputContainer'>
                                          <Form.Control name='fecha_solicitud' required type='date'
                                            onChange={handleInputChangeNew}
                                            defaultValue={dataSolicitud.info.fechaSolicitud || ''}
                                          ></Form.Control>
                                          <Form.Control.Feedback type='invalid'>ingrese fecha de solicitud</Form.Control.Feedback>
                                        </div>
                                      </Form.Group>
                                      : null
                                    }
                                    {dataSolicitud.info.infoGeneralSeccionBase[0].fechaLimiteShow === true
                                      ?
                                      <Form.Group>
                                        <Form.Label>
                                          Fecha limite
                                        </Form.Label>
                                        <div className='inputContainer'>
                                          <Form.Control name='fecha_limite' required type='date'
                                            onChange={handleInputChangeNew}
                                            defaultValue={dataSolicitud.info.fechaLimite || ''}
                                          ></Form.Control>
                                          <Form.Control.Feedback type='invalid'>ingrese fecha límite</Form.Control.Feedback>
                                        </div>
                                      </Form.Group>
                                      : null
                                    }
                                  </div>
                                </Form.Row>
                                {dataSolicitud.info.infoGeneralSeccionBase[0].numeroSOP === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Número Sop/SNN
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='numeroSop' required type='text' placeholder='Sop/SNN'
                                        // style={{ marginRight: '10px' }}
                                        onChange={handleInputChangeNew}
                                        defaultValue={dataSolicitud.info.numeroSop || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Ingresa el número Sop/SNN</Form.Control.Feedback>

                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].fechaVencimientoProrroga === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Fecha de vencimiento de prórroga
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='fecha_prorroga' required type='date'
                                        onChange={handleInputChangeNew}
                                        defaultValue={dataSolicitud.info.fechaFinProrroga || ''}

                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>ingrese fecha de vencimiento de prórroga</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].analista === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Analista
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='analista' required type='text' placeholder='Analista/Investigador'
                                        onChange={handleInputChangeNew}
                                        defaultValue={dataSolicitud.info.analista || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Ingresa el analista encargado</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>

                                  : null
                                }
                              </div>
                              <div className='formItem' id='basicFormB'>
                                {dataSolicitud.info.infoGeneralSeccionBase[0].etapa === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Etapa
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='etapa' required type='text' placeholder='Etapa'
                                        style={{ marginRight: '10px' }}
                                        defaultValue={dataSolicitud.info.etapa || ''}
                                        onChange={handleInputChangeNew}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Etapa</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].fechaVencimientoTeorico === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Fecha de vencimiento teórico
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='fechaVencimientoTeorico' required type='date'
                                        onChange={handleInputChangeNew}
                                        defaultValue={dataSolicitud.info.fechaVencimientoTeorico || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>ingrese fecha de vencimiento teórico</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].radicado === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Número de radicado
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='radicado' required type='text' placeholder='Número de radicado'
                                        onChange={handleInputChangeNew}
                                        defaultValue={dataSolicitud.info.radicadoRegistro || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Ingrese el número de radicado</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].fechaInicial === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Fecha inicial
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='fechaInicial' required type='date'
                                        onChange={handleInputChangeNew}
                                        defaultValue={dataSolicitud.info.fechaInicial || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>ingrese fecha inicial</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                              </div>
                              <hr />
                              <br />
                            </div>
                            :
                            <div className='contenedorinfoGeneralRegistro'>
                              <div className='formItem' id='basicFormA'>
                                <Form.Row>
                                  <div className='dateContainer'>
                                    {dataSolicitud.info.infoGeneralSeccionBase[0].fechaSolicitud === true
                                      ?
                                      <Form.Group>
                                        <Form.Label>
                                          Fecha de solicitud
                                        </Form.Label>
                                        <div className='inputContainer'>
                                          <Form.Control name='fecha_solicitud' required type='date'

                                            value={dataSolicitud.info.fechaSolicitud || ''}
                                          ></Form.Control>
                                          <Form.Control.Feedback type='invalid'>ingrese fecha de solicitud</Form.Control.Feedback>
                                        </div>
                                      </Form.Group>
                                      : null
                                    }
                                    {dataSolicitud.info.infoGeneralSeccionBase[0].fechaLimiteShow === true
                                      ?
                                      <Form.Group>
                                        <Form.Label>
                                          Fecha limite
                                        </Form.Label>
                                        <div className='inputContainer'>
                                          <Form.Control name='fecha_limite' required type='date'

                                            value={dataSolicitud.info.fechaLimite || ''}
                                          ></Form.Control>
                                          <Form.Control.Feedback type='invalid'>ingrese fecha límite</Form.Control.Feedback>
                                        </div>
                                      </Form.Group>
                                      : null
                                    }
                                  </div>
                                </Form.Row>
                                {dataSolicitud.info.infoGeneralSeccionBase[0].numeroSOP === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Número Sop/SNN
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='numeroSop' required type='text' placeholder='Sop/SNN'
                                        // style={{ marginRight: '10px' }}

                                        value={dataSolicitud.info.numeroSop || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Ingresa el número Sop/SNN</Form.Control.Feedback>

                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].fechaVencimientoProrroga === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Fecha de vencimiento de prórroga
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='fecha_prorroga' required type='date'

                                        value={dataSolicitud.info.fechaFinProrroga || ''}

                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>ingrese fecha de vencimiento de prórroga</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].analista === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Analista
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='analista' required type='text' placeholder='Analista/Investigador'

                                        value={dataSolicitud.info.analista || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Ingresa el analista encargado</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>

                                  : null
                                }
                              </div>
                              <div className='formItem' id='basicFormB'>
                                {dataSolicitud.info.infoGeneralSeccionBase[0].etapa === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Etapa
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='etapa' required type='text' placeholder='Etapa'
                                        style={{ marginRight: '10px' }}
                                        value={dataSolicitud.info.etapa || ''}

                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Etapa</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].fechaVencimientoTeorico === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Fecha de vencimiento teórico
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='fechaVencimientoTeorico' required type='date'

                                        value={dataSolicitud.info.fechaVencimientoTeorico || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>ingrese fecha de vencimiento teórico</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].radicado === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Número de radicado
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='radicado' required type='text' placeholder='Número de radicado'

                                        value={dataSolicitud.info.radicadoRegistro || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>Ingrese el número de radicado</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                                {dataSolicitud.info.infoGeneralSeccionBase[0].fechaInicial === true
                                  ?
                                  <Form.Group>
                                    <Form.Label>
                                      Fecha inicial
                                    </Form.Label>
                                    <div className='inputContainer'>
                                      <Form.Control name='fechaInicial' required type='date'

                                        value={dataSolicitud.info.fechaInicial || ''}
                                      ></Form.Control>
                                      <Form.Control.Feedback type='invalid'>ingrese fecha inicial</Form.Control.Feedback>
                                    </div>
                                  </Form.Group>
                                  : null
                                }
                              </div>
                              <hr />
                              <br />
                            </div>
                          }
                        </>
                        : null

                      }
                      {dataSolicitud.info.infoGeneralSeccionTipoInvestigacion === true
                        ?
                        <>
                          {dataSolicitud.info.editarInfoGeneralTipoInvestigacion === true
                            ?
                            <div id='InputsInvestigacion'>
                              <hr />
                              <div id='tiposContainer'>
                                <Form.Group className='tiposFormGroup'>
                                  <Form.Label>
                                    Tipo de investigación
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='tipo' required type='text' placeholder='Tipo de investigación'
                                      style={{ marginRight: '10px' }}
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.tipoInvestigacion || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa el tipo de investigación</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='tiposFormGroup'>
                                  <Form.Label>
                                    Tipo de riesgo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='riesgo' required type='text' placeholder='Tipo de riesgo'
                                      style={{ marginRight: '10px' }}
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.riesgo_detalle || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Tipo de riesgo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='tiposFormGroup'>
                                  <Form.Label>
                                    Detalle del tipo de riesgo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control id='detalleTipoRiesgo' name='detalle_riesgo' required type='text' placeholder='Detalle del tipo de riesgo'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.detalleTipoRiesgo || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa el detalle del tipo de riesgo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>
                            :
                            <div id='InputsInvestigacion'>
                              <hr />
                              <div id='tiposContainer'>
                                <Form.Group className='tiposFormGroup'>
                                  <Form.Label>
                                    Tipo de investigación
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='tipo' required type='text' placeholder='Tipo de investigación'
                                      style={{ marginRight: '10px' }}
                                      value={dataSolicitud.info.tipoInvestigacion || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa el tipo de investigación</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='tiposFormGroup'>
                                  <Form.Label>
                                    Tipo de riesgo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='riesgo' required type='text' placeholder='Tipo de riesgo'
                                      style={{ marginRight: '10px' }}
                                      value={dataSolicitud.info.riesgo_detalle || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Tipo de riesgo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='tiposFormGroup'>
                                  <Form.Label>
                                    Detalle del tipo de riesgo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control id='detalleTipoRiesgo' name='detalle_riesgo' required type='text' placeholder='Detalle del tipo de riesgo'
                                      value={dataSolicitud.info.detalleTipoRiesgo || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa el detalle del tipo de riesgo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>


                          }
                        </>
                        : null
                      }
                      {dataSolicitud.info.infoGeneralSeccionInfoUBICA === true
                        ?
                        <>
                          {dataSolicitud.info.editarInfoUBICA === true
                            ?
                            <div id='UBICAContainer'>
                              <h5 className='titleCard'>Información UBICA</h5>
                              <br />
                              <div className='UBICAinputsContainer'>
                                <Form.Group className='UBICAinputFormGroup'>
                                  <Form.Label>
                                    Dirección UBICA
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='direccion_ubica' required type='text' placeholder='dirección UBICA'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.direccionUBICA || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingrese dirección UBICA</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='UBICAinputFormGroup'>
                                  <Form.Label>
                                    Teléfonos UBICA
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='telefono_ubica' required type='text' placeholder='teléfono UBICA'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.telefonoUBICA || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese teléfono UBICA</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>
                            :
                            <div id='UBICAContainer'>
                              <h5 className='titleCard'>Información UBICA</h5>
                              <br />
                              <div className='UBICAinputsContainer'>
                                <Form.Group className='UBICAinputFormGroup'>
                                  <Form.Label>
                                    Dirección UBICA
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='direccion_ubica' required type='text' placeholder='dirección UBICA'
                                      value={dataSolicitud.info.direccionUBICA || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingrese dirección UBICA</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='UBICAinputFormGroup'>
                                  <Form.Label>
                                    Teléfonos UBICA
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='telefono_ubica' required type='text' placeholder='teléfono UBICA'
                                      value={dataSolicitud.info.telefonoUBICA || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese teléfono UBICA</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>
                          }
                        </>
                        : null
                      }
                      {dataSolicitud.info.infoGeneralSeccionDictamen === true
                        ?
                        <>
                          {dataSolicitud.info.editarInfoGeneralSeccionDictamen === true
                            ?
                            <div id='DictamenContainer'>
                              <h5 className='titleCard'>Dictamen</h5>
                              <br />
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Número de dictamen
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='numeroDictamen' required type='text' placeholder='Número de dictamen'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.numeroDictamenRegistro || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese número de dictamen</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Fecha de dictamen
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='fechaDictamen' required type='date' placeholder='Fecha de dictamen'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.fechaDictamenRegistro || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese fecha de dictamen</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Junta (regional o nacional)
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='junta' required type='text' placeholder='Junta'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.juntaDictamenRegistro || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese junta </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>

                            :
                            <div id='DictamenContainer'>
                              <h5 className='titleCard'>Dictamen</h5>
                              <br />
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Número de dictamen
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='numeroDictamen' required type='text' placeholder='Número de dictamen'
                                      value={dataSolicitud.info.numeroDictamenRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese número de dictamen</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Fecha de dictamen
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='fechaDictamen' required type='date' placeholder='Fecha de dictamen'
                                      value={dataSolicitud.info.fechaDictamenRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese fecha de dictamen</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Junta (regional o nacional)
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='junta' required type='text' placeholder='Junta'
                                      value={dataSolicitud.info.juntaDictamenRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese junta </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>

                          }
                        </>
                        : null
                      }
                      {dataSolicitud.info.infoGeneralSeccionInfoAdicional === true
                        ?
                        <>
                          {dataSolicitud.info.editarInfoGeneralInfoAdicional === true
                            ?
                            <div id='informacionAdicional'>
                              <h5 className='titleCard'>Información adicional - vista de actualización</h5>
                              <br />
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Sexo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='sexo' required type='text' placeholder='sexo'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.sexoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese sexo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Fecha de nacimiento
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='fechaNacimiento' required type='date'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.fechaNacimientoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese fecha de nacimiento </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Fecha de muerte
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='fechaMuerte' required type='date'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.fechaMuerteInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese fecha de muerte</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Estado civíl
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='estadoCivil' required type='text' placeholder='Estado civíl'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.estadoCivilInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese estado civíl </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Estado
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='estado' required type='text' placeholder='estado'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.estadoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese estado</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    EPS
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='eps' required type='text' placeholder='EPS'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.EPSInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese EPS </Form.Control.Feedback>
                                  </div>
                                </Form.Group>

                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Prestamo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='prestamo' required type='text' placeholder='Préstamo'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.prestamoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese préstamo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Condición
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='condicion' required type='text' placeholder='condición'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.condicionInfoAdicionalRegistro || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese condición </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Nombre responsable
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='nombreResponsable' required type='text' placeholder='Nombre responsable'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.nombreResponsableInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese nombre del responsable </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Parentesco responsable
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='parentescoResponsable' required type='text' placeholder='Parentesco del responsable'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.parentescoResponsableInfoAdicionalRegistro || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese parentesco del responsable</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='dictamenInputsContainer'>

                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Correo electrónico
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='correo' required type='text' placeholder='Correo eletrónico'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.correoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese correo eletrónico</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>

                            :
                            <div id='informacionAdicional'>
                              <h5 className='titleCard'>Información adicional - vista de actualización</h5>
                              <br />
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Sexo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='sexo' required type='text' placeholder='sexo'
                                      value={dataSolicitud.info.sexoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese sexo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Fecha de nacimiento
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='fechaNacimiento' required type='date'
                                      value={dataSolicitud.info.fechaNacimientoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese fecha de nacimiento </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Fecha de muerte
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='fechaMuerte' required type='date'
                                      value={dataSolicitud.info.fechaMuerteInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese fecha de muerte</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Estado civíl
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='estadoCivil' required type='text' placeholder='Estado civíl'
                                      value={dataSolicitud.info.estadoCivilInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese estado civíl </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Estado
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='estado' required type='text' placeholder='estado'
                                      value={dataSolicitud.info.estadoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese estado</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    EPS
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='eps' required type='text' placeholder='EPS'
                                      value={dataSolicitud.info.EPSInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese EPS </Form.Control.Feedback>
                                  </div>
                                </Form.Group>

                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Prestamo
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='prestamo' required type='text' placeholder='Préstamo'
                                      value={dataSolicitud.info.prestamoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese préstamo</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Condición
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='condicion' required type='text' placeholder='condición'
                                      value={dataSolicitud.info.condicionInfoAdicionalRegistro || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese condición </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='dictamenInputsContainer'>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Nombre responsable
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='nombreResponsable' required type='text' placeholder='Nombre responsable'
                                      value={dataSolicitud.info.nombreResponsableInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese nombre del responsable </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Parentesco responsable
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='parentescoResponsable' required type='text' placeholder='Parentesco del responsable'
                                      value={dataSolicitud.info.parentescoResponsableInfoAdicionalRegistro || ''}

                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese parentesco del responsable</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='dictamenInputsContainer'>

                                <Form.Group className='dictamenInputFormGroup'>
                                  <Form.Label>
                                    Correo electrónico
                                  </Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control name='correo' required type='text' placeholder='Correo eletrónico'
                                      value={dataSolicitud.info.correoInfoAdicionalRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>ingrese correo eletrónico</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <br />
                              <hr />
                            </div>

                          }
                        </>
                        : null
                      }
                      {dataSolicitud.info.infoGeneralSeccionVerificacionCausante === true
                        ?
                        <>
                          {dataSolicitud.info.editarInfoGeneralInfoAdicional === true
                            ?
                            <div id='verificacionCausanteContainer'>
                              <br />
                              <h5 className='titleCard'>Verificación de causante/afiliado</h5>
                              <br />
                              <div className='formGroupsContainer'>
                                <Form.Group className='formGroupContenedorGeneral'>
                                  <Form.Label>Verificación Ciudad</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control type='text' required name='ciudad' placeholder='Verificación de la ciudad'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.ciudadVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese verificación de ciudad</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='formGroupContenedorGeneral'>
                                  <Form.Label>Verificación dirección</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='text' name='verificacionDireccion' placeholder='Verificación de la dirección'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.direccionVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese verificación de dirección</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='formGroupContenedorGeneral'>
                                  <Form.Label>Verificación teléfonos</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='text' name='verificacionTelefonos' placeholder='Verificación de teléfonos'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.telefonoVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese verificación de teléfonos</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='formGroupsContainer'>
                                <Form.Group className='formGroupContenedorGeneral col-lg-6'>
                                  <Form.Label>Fecha de la visita</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='date' name='fechaVisita'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.fechaVisitaVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese fecha de la visita</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='formGroupContenedorGeneral col-lg-6'>
                                  <Form.Label>Fecha de programación de la visita</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='date' name='programacionVisita'
                                      onChange={handleInputChangeNew}
                                      defaultValue={dataSolicitud.info.fechaProgramacionVisitaVerificacionCausanteRegistro || ''}

                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese fecha de programación de la visita</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div></div>
                              <div></div>
                            </div>

                            :
                            <div id='verificacionCausanteContainer'>
                              <br />
                              <h5 className='titleCard'>Verificación de causante/afiliado</h5>
                              <br />
                              <div className='formGroupsContainer'>
                                <Form.Group className='formGroupContenedorGeneral'>
                                  <Form.Label>Verificación Ciudad</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control type='text' required name='ciudad' placeholder='Verificación de la ciudad'
                                      value={dataSolicitud.info.ciudadVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese verificación de ciudad</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='formGroupContenedorGeneral'>
                                  <Form.Label>Verificación dirección</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='text' name='verificacionDireccion' placeholder='Verificación de la dirección'
                                      value={dataSolicitud.info.direccionVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese verificación de dirección</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='formGroupContenedorGeneral'>
                                  <Form.Label>Verificación teléfonos</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='text' name='verificacionTelefonos' placeholder='Verificación de teléfonos'
                                      value={dataSolicitud.info.telefonoVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese verificación de teléfonos</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className='formGroupsContainer'>
                                <Form.Group className='formGroupContenedorGeneral col-lg-6'>
                                  <Form.Label>Fecha de la visita</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='date' name='fechaVisita'
                                      value={dataSolicitud.info.fechaVisitaVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese fecha de la visita</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                                <Form.Group className='formGroupContenedorGeneral col-lg-6'>
                                  <Form.Label>Fecha de programación de la visita</Form.Label>
                                  <div className='inputContainer'>
                                    <Form.Control required type='date' name='programacionVisita'
                                      value={dataSolicitud.info.fechaProgramacionVisitaVerificacionCausanteRegistro || ''}
                                    />
                                    <Form.Control.Feedback type='invalid'>Ingrese fecha de programación de la visita</Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                              <div></div>
                              <div></div>
                            </div>
                          }
                        </>
                        : null
                      }
                      <div className='buttonContainer'>
                        <button type="submit" className='confirmButton' >
                          enviar
                        </button>
                      </div>
                    </Form>
                    <NotificationModal isOpen={notificationOpen} setIsOpen={setNotificationOpen} typeModal='actualizacionInfoGeneral' />
                  </Card.Body>
                  <PopUpModal open={open} setOpen={setOpen} />
                </Card>
                : dataSolicitud.info.pestanainfoCausante === true && pestaña === 'infoAfiliado'
                  ? <Card className='shadow cardGeneral'>
                    <Card.Body>
                      <br />
                      <h4 className='titleCard'>Información del causante/afiliado</h4>
                      <br />
                      <Form noValidate validated={validated} onSubmit={submitFormInfoCausante} id='formulario1'>
                        {dataSolicitud.info.editarInfoCausante === true
                          ?
                          <>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Número Sop/SNN</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='numeroSopSNN' type='text' placeholder='número Sop/SNN'
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.numeroSop}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese el número Sop/SNN</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>
                                  Identificación
                                </Form.Label>
                                <div>
                                  <div className='tipoIDContainer'>
                                    <Form.Control name='tipo_identificacion' as='select' required
                                      onChange={handleInputFormAfiliado}
                                      defaultValue={dataSolicitud.info.tipoIDCausanteRegistro || ''}
                                    >
                                      <option value='2' selected>Seleccione un tipo de identificación</option>
                                      <option value='2'>Cédula de ciudadanía</option>
                                      <option value='3'>Cédula de extranjería</option>
                                      <option value='4'>Tarjeta de identidad</option>
                                      <option value='9'>Registro civíl</option>
                                      <option value='5'>Pasaporte</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingrese el tipo de identificación del causante</Form.Control.Feedback>
                                  </div>
                                  <div className='inputContainer'>
                                    <Form.Control
                                      name='identificacion'
                                      min='0'
                                      max='9999999999'
                                      type='number'
                                      placeholder='Número de identificación'
                                      style={{ marginRight: '10px' }}
                                      onChange={handleInputFormAfiliado}
                                      defaultValue={dataSolicitud.info.numeroIDCausanteRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingrese el número de identificación del causante</Form.Control.Feedback>
                                  </div>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Primer nombre *</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='primer_nombre' required type='text' placeholder='Primer nombre'
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.primerNombreCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese el primer nombre</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Segundo nombre</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='segundo_nombre' type='text' placeholder='Segundo nombre'
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.segundoNombreCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese el segundo nombre</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>
                                  Primer apellido *
                                </Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='primer_apellido' required type='text' placeholder='Primer apellido'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.primerApellidoCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese primer apellido</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>
                                  Segundo apellido
                                </Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='segundo_apellido' type='text' placeholder='Segundo apellido'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.segundoApellidoCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese segundo apellido del causante</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Ciudad</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='ciudadCausante' type='text' placeholder='Ciudad'
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.ciudadCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese ciudad de vivienda</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Dirección</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='direccionCausante' type='text' placeholder='Dirección de residencia'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.direccionCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese direccón de residencia</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Teléfono</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='telefonoCausante' type='text' placeholder='Teléfono móvil y/o fijo'
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.telefonoCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese ciudad de vivienda</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Prioridad</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='prioridadCausante' type='text' placeholder='Prioridad'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputFormAfiliado}
                                    defaultValue={dataSolicitud.info.prioridadCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese prioridad</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <br />
                            <div>
                              <Form.Label>observaciones*</Form.Label>
                              <Form.Control name='observaciones' required as='textarea'
                                onChange={handleInputFormAfiliado}
                                defaultValue={dataSolicitud.info.observacionesCausanteRegistro || ''}
                              ></Form.Control>
                            </div>
                          </>
                          :
                          <>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Número Sop/SNN</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='numeroSopSNN' type='text' placeholder='número Sop/SNN'

                                    value={dataSolicitud.info.numeroSop}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese el número Sop/SNN</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>
                                  Identificación
                                </Form.Label>
                                <div>
                                  <div className='tipoIDContainer'>
                                    <Form.Control name='tipo_identificacion' as='select' required

                                      value={dataSolicitud.info.tipoIDCausanteRegistro || ''}
                                    >
                                      <option value='2' >Seleccione un tipo de identificación</option>
                                      <option value='2'>Cédula de ciudadanía</option>
                                      <option value='3'>Cédula de extranjería</option>
                                      <option value='4'>Tarjeta de identidad</option>
                                      <option value='9'>Registro civíl</option>
                                      <option value='5'>Pasaporte</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingrese el tipo de identificación del causante</Form.Control.Feedback>
                                  </div>
                                  <div className='inputContainer'>
                                    <Form.Control
                                      min='0'
                                      max='9999999999'
                                      name='identificacion'
                                      type='number'
                                      placeholder='Número de identificación'
                                      style={{ marginRight: '10px' }}
                                      value={dataSolicitud.info.numeroIDCausanteRegistro || ''}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingrese el número de identificación del causante</Form.Control.Feedback>
                                  </div>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Primer nombre *</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='primer_nombre' required type='text' placeholder='Primer nombre'

                                    value={dataSolicitud.info.primerNombreCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese el primer nombre</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Segundo nombre</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='segundo_nombre' type='text' placeholder='Segundo nombre'

                                    value={dataSolicitud.info.segundoNombreCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese el segundo nombre</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>
                                  Primer apellido *
                                </Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='primer_apellido' required type='text' placeholder='Primer apellido'
                                    style={{ marginRight: '10px' }}

                                    value={dataSolicitud.info.primerApellidoCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese primer apellido</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>
                                  Segundo apellido
                                </Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='segundo_apellido' type='text' placeholder='Segundo apellido'
                                    style={{ marginRight: '10px' }}

                                    value={dataSolicitud.info.segundoApellidoCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese segundo apellido del causante</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Ciudad</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='ciudadCausante' type='text' placeholder='Ciudad'

                                    value={dataSolicitud.info.ciudadCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese ciudad de vivienda</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Dirección</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='direccionCausante' type='text' placeholder='Dirección de residencia'
                                    style={{ marginRight: '10px' }}

                                    value={dataSolicitud.info.direccionCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese direccón de residencia</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Teléfono</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='telefonoCausante' type='text' placeholder='Teléfono móvil y/o fijo'

                                    value={dataSolicitud.info.telefonoCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese ciudad de vivienda</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Prioridad</Form.Label>
                                <div className='inputContainer'>
                                  <Form.Control name='prioridadCausante' type='text' placeholder='Prioridad'
                                    style={{ marginRight: '10px' }}

                                    value={dataSolicitud.info.prioridadCausanteRegistro || ''}
                                  ></Form.Control>
                                  <Form.Control.Feedback type='invalid'>Ingrese prioridad</Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <br />
                            <div>
                              <Form.Label>observaciones*</Form.Label>
                              <Form.Control name='observaciones' required as='textarea'

                                value={dataSolicitud.info.observacionesCausanteRegistro || ''}
                              ></Form.Control>
                            </div>
                          </>
                        }
                        <br />
                        <div className='buttonContainer'>
                          <button type='submit' className='confirmButton'>Enviar</button>
                        </div>
                      </Form>
                      <NotificationModal isOpen={notificationOpen} setIsOpen={setNotificationOpen} typeModal='actualizacionInfoGeneral' />
                    </Card.Body>
                  </Card>
                  : dataSolicitud.info.pestanaRevisionCalidad === true && pestaña === 'revisionCalidad'
                    ? <Card className='shadow cardGeneral'>
                      <Card.Body>
                        <Form noValidate validated={validated} onSubmit={handleSubmitRevisionCalidad} >
                          <Form.Label>
                            Revisión de calidad
                          </Form.Label>
                          <br />
                          {dataSolicitud.info.editarRevisionCalidad === !true
                            ?
                            <>
                              <div>
                                <Form.Control name='descripcion_calidad' required as='textarea'
                                  onChange={handleChangeRevisionCalidad}
                                  defaultValue={dataSolicitud.info.detalleRevisionCalidad || ''}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                              </div>
                              <div className='buttonContainer'>
                                <button type="submit" className='confirmButton' value='Guardar' name='btnEnviar1'>
                                  enviar
                                </button>
                              </div>
                            </>
                            :
                            <>
                              <div>
                                <Form.Control name='descripcion_calidad' as='textarea'
                                  value={dataSolicitud.info.detalleRevisionCalidad || ''}
                                ></Form.Control>
                              </div>
                            </>
                          }
                        </Form>
                      </Card.Body>
                    </Card>
                    /* Pestaña Beneficiarios */
                    : dataSolicitud.info.pestanaBeneficiarios === true && pestaña === 'beneficiarios'
                      ? <BeneficiariosEditarEstudio idSolicitud={idRegistro} setFormState={setFormState} formState={formState} dataSolicitud={dataSolicitud.info} />
                      : dataSolicitud.info.pestanaSolicitudObservacion === true && pestaña === 'solicitudObservacion'
                        /* Pestaña Solicitud de Observaciones */
                        ? <Card className='shadow cardGeneral'>
                          <Card.Body>
                            <h3 className='titleCard'>solicitud objeción</h3>
                            <br />
                            <Form noValidate validate={validated} onSubmit={submitFormSolicitudObservacion}>
                              <Form.Label>Observación</Form.Label>
                              <br />
                              {dataSolicitud.info.editarSolicitudObservacion === true
                                ?
                                <div>
                                  <Form.Control name='solicitud_investigacion' placeholder='Escriba aqui sus observaciones' id='textAreaSolicitudObservacion' required as='textarea'
                                    onChange={handleChangeSolicitudObservacion}
                                    defaultValue={dataSolicitud.info.observacionSolicitudObjecion || ''}
                                  ></Form.Control>
                                </div>
                                :
                                <div>
                                  <Form.Control id='textAreaSolicitudObservacion' required as='textarea'
                                    value={dataSolicitud.info.observacionSolicitudObjecion || ''}
                                  ></Form.Control>
                                </div>
                              }
                              <br />
                              <div className='buttonContainer'>
                                <button type="submit" className='confirmButton' >
                                  enviar
                                </button>
                              </div>
                            </Form>
                          </Card.Body>
                        </Card>
                        : dataSolicitud.info.pestanaAsignacionAnalista === true && pestaña === 'asignacionAnalistas'
                          /* Asignación de analista */
                          ? <AsignacionAnalistas dataSolicitud={dataSolicitud.info} />
                          : dataSolicitud.info.pestanaSoportesDocumentales === true && pestaña === 'gestorDocumental'
                            /* Soportes documentales */
                            ? <GestorDocumentos dataSolicitud={dataSolicitud.info} />
                            : dataSolicitud.info.pestanaRegistroFotografico === true && pestaña === 'registroFotografico'
                              /* Registro Fotografico */
                              ? <RegistroFotografico dataSolicitud={dataSolicitud.info} idSolicitud={idRegistro} />
                              : dataSolicitud.info.pestanaConclusiones === true && pestaña === 'conclusiones'
                                /* Conclisiones */
                                ? <ConclusionInvestigacion dataSolicitud={dataSolicitud.info} />
                                : dataSolicitud.info.pestanaSeguimientos === true && pestaña === 'seguimientos'
                                  /* Seguimientos */
                                  ? <Seguimientos idSolicitud={idRegistro} setFormState={setFormState} formState={formState} dataSolicitud={dataSolicitud.info} />
                                  : dataSolicitud.info.pestanaEntrevistas === true && pestaña === 'entrevistas'
                                    /* Entrevistas */
                                    ? <Entrevistas setFormState={setFormState} formState={formState} dataSolicitud={dataSolicitud.info} />
                                    : dataSolicitud.info.pestanaNotas === true && pestaña === 'notas'
                                    /* Notas */
                                    && <GestorNotas dataSolicitud={dataSolicitud.info} />
            }


          </>
          : dataSolicitud.info.perfil === 'inteligencia'
            ?
            <>
              <Card id='informacionSolicitud' className="shadow cardGeneral" >
                <Card.Body>
                  <h3 className='pagesTitles'>Información de solicitud</h3>

                  <Row style={{ width: '80%', margin: ' 0 auto', display: 'flex', justifyContent: 'space-around' }}>

                    <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>Tipo de registro:</p>
                        <p className='labelInfoContent'>{dataSolicitud.info.estadoSolicitud}</p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>Tipo de identificación:</p>
                        <p className='labelInfoContent'>{dataSolicitud.info.fechaSolicitud}</p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Nombre:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.radicado}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Cargo al que aspira:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.radicado}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Dirección:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.radicado}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Ciudad:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.radicado}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>No. Celular:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.radicado}
                        </p>
                      </div>


                    </Col>
                    <Col xs={6} lg={4} md={3} className='listPopUp mt-3'>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Fecha solicitud: </p>
                        <p className='labelInfoContent'>
                          {idRegistro}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>No. Identificación: </p>
                        <p className='labelInfoContent'>
                          {idRegistro}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>Apellidos:</p>
                        <p className='labelInfoContent'>{dataSolicitud.info.estadoCosinte}</p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Barrio:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.etapa}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Departamento:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.fechaSolicitudCosinte}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Teléfono:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.fechaLimite}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <br />

              <Card id='informacionEstudio' className="shadow" style={{ borderRadius: '10px' }}>
                <Card.Body>
                  <h3 className='pagesTitles'>Información de estudio</h3>
                  <br />
                  <Row>
                    <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
                      {dataSolicitud.info.estadoSolicitud
                        ?
                        <>
                          <div>
                            {estadoRegistro === 'aprobado'
                              ? <div>
                                <SuccessIcon />
                                <h3>{`${estadoRegistro}`}</h3>
                              </div>
                              : estadoRegistro === 'denegado'
                                ? <div>
                                  <ErrorSVG />
                                  <h3>{`${estadoRegistro}`}</h3>
                                </div>
                                : estadoRegistro === 'pendiente de aprobacion' || ''
                                  ? <div>
                                    <WarningModalSVG />
                                    <h3>{`${estadoRegistro}`}</h3>
                                  </div>
                                  : <div>
                                    <WarningModalSVG />
                                    <h3>Verificar estado </h3>
                                  </div>
                            }
                          </div>
                          <br />
                          <div className='col-12 buttonGenerarPDF'>
                            <a href={`https://ciacosinte.site/crm/upload/informe_investigacion_PDF.html?id=${idRegistro}`} rel="noreferrer" target='_blank'>
                              <button className='confirmButton'>
                                Generar PDF
                                <GetAppIcon />
                              </button>

                            </a>

                          </div>

                        </>

                        : null
                      }
                    </Col>
                    <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>Estado:</p>
                        <p className='labelInfoContent'>{dataSolicitud.info.estadoSolicitud}</p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>Fecha de solicitud:</p>
                        <p className='labelInfoContent'>{dataSolicitud.info.fechaSolicitud}</p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Radicado:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.radicado}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Analista</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.analista}
                          <br />
                          {dataSolicitud.info.analista_correo}
                        </p>
                      </div>

                    </Col>
                    <Col xs={6} lg={4} md={3} className='listPopUp mt-3'>
                      <div className='infoBasicaEditarRegistro'>
                        <p>ID:</p>
                        <p className='labelInfoContent'>
                          {idRegistro}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>Estado-Cosinte:</p>
                        <p className='labelInfoContent'>{dataSolicitud.info.estadoCosinte}</p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Etapa:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.etapa}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Fecha de solicitud Cosinte:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.fechaSolicitudCosinte}
                        </p>
                      </div>
                      <div className='infoBasicaEditarRegistro'>
                        <p>Fecha límite:</p>
                        <p className='labelInfoContent'>
                          {dataSolicitud.info.fechaLimite}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <br />

              {dataSolicitud.info.pestanas === true
                ?
                <>
                  <div id='contenedorPestanas' className='pestañasEditarRegistroScroll'>
                    <div className='pestañasEditarRegistroContainer' style={{ display: 'flex', textAlign: 'center' }}>
                      {dataSolicitud.info.pestanaInformacionRegistro === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'InformacionRegistroEstudio' && '#CBD7E6' }}
                            onClick={() => setPestaña('InformacionRegistroEstudio')}
                          >
                            <p className=" p-1 m-0"> Información de registro </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaInfoGeneral === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'informacionEstudio' && '#CBD7E6' }}
                            onClick={() => setPestaña('informacionEstudio')}
                          >
                            <p className=" p-1 m-0"> Información de estudio </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaAsignacionAnalista === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'asignacionAnalistas' && '#CBD7E6' }}
                            onClick={() => setPestaña('asignacionAnalistas')}
                          >
                            <p className=" p-1 m-0">Asignación de analistas </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanainfoCausante === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'informacion_basica' && '#CBD7E6' }}
                            onClick={() => setPestaña('informacion_basica')}
                          >
                            <p className=" p-1 m-0"> Información personal básica </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanainfoCausante === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'informacion_completa' && '#CBD7E6' }}
                            onClick={() => setPestaña('informacion_completa')}
                          >
                            <p className=" p-1 m-0"> Info. personal completa </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanainfoCausante === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'informacion_familiar' && '#CBD7E6' }}
                            onClick={() => setPestaña('informacion_familiar')}
                          >
                            <p className=" p-1 m-0"> Informacion familiar </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }


                      {dataSolicitud.info.pestanainfoCausante === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'informacion_academica' && '#CBD7E6' }}
                            onClick={() => setPestaña('informacion_academica')}
                          >
                            <p className=" p-1 m-0"> Información académica </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }

                      {dataSolicitud.info.pestanainfoCausante === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'informacion_laboral' && '#CBD7E6' }}
                            onClick={() => setPestaña('informacion_laboral')}
                          >
                            <p className=" p-1 m-0"> Información laboral </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaRevisionCalidad === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'visita' && '#CBD7E6' }}
                            onClick={() => setPestaña('visita')}
                          >
                            <p className=" p-1 m-0"> Visita </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaBeneficiarios === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'anotaciones' && '#CBD7E6' }}
                            onClick={() => setPestaña('anotaciones')}
                          >
                            <p className=" p-1 m-0">Anotaciones</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaSolicitudObservacion === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'conclusiones' && '#CBD7E6' }}
                            onClick={() => setPestaña('conclusiones')}
                          >
                            <p className=" p-1 m-0">Conclusiones</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }

                      {dataSolicitud.info.pestanaSoportesDocumentales === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'financiero' && '#CBD7E6' }}
                            onClick={() => setPestaña('financiero')}
                          >
                            <p className=" p-1 m-0">Financiero </p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaRegistroFotografico === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'poligrafo' && '#CBD7E6' }}
                            onClick={() => setPestaña('poligrafo')}
                          >
                            <p className=" p-1 m-0">Polígrafo</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaSeguimientos === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'eyedetect' && '#CBD7E6' }}
                            onClick={() => setPestaña('eyedetect')}
                          >
                            <p className=" p-1 m-0">Eyedetect</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaEntrevistas === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'patrimonial' && '#CBD7E6' }}
                            onClick={() => setPestaña('patrimonial')}
                          >
                            <p className=" p-1 m-0">Patrimonial</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }

                      {dataSolicitud.info.pestanaNotas === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'pruebas_psicotecnicas' && '#CBD7E6' }}
                            onClick={() => setPestaña('pruebas_psicotecnicas')}
                          >
                            <p className=" p-1 m-0">Pruebas psicotécnicas</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaConclusiones === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'vsa' && '#CBD7E6' }}
                            onClick={() => setPestaña('vsa')}
                          >
                            <p className=" p-1 m-0">VSA</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaConclusiones === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'anotaciones_grupo_familiar' && '#CBD7E6' }}
                            onClick={() => setPestaña('anotaciones_grupo_familiar')}
                          >
                            <p className=" p-1 m-0">Anotaciones grupo familiar</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaConclusiones === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'sarlaft' && '#CBD7E6' }}
                            onClick={() => setPestaña('sarlaft')}
                          >
                            <p className=" p-1 m-0">SARLAFT</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaConclusiones === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'soportes' && '#CBD7E6' }}
                            onClick={() => setPestaña('soportes')}
                          >
                            <p className=" p-1 m-0">Soportes</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                      {dataSolicitud.info.pestanaConclusiones === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'protocolo_seguridad' && '#CBD7E6' }}
                            onClick={() => setPestaña('protocolo_seguridad')}
                          >
                            <p className=" p-1 m-0">Protocolo de seguridad</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }

                      {dataSolicitud.info.pestanaConclusiones === true
                        ?
                        <>
                          <div
                            className="d-flex pestañasLi"
                            style={{ background: pestaña !== 'cesantias' && '#CBD7E6' }}
                            onClick={() => setPestaña('cesantias')}
                          >
                            <p className=" p-1 m-0">Cesantías</p>
                            <b style={{ width: '25px', fontSize: '30px', marginTop: '-20px', fontWeight: '900', color: '#00A20B' }}>.</b>
                          </div>
                        </>
                        : null
                      }
                    </div>
                  </div>
                </>
                : null
              }

              {dataSolicitud.info.pestanaInformacionEstudio === true && pestaña === 'InformacionRegistroEstudio'
                ? <InformacionRegistroEstudio validated={validated} data={dataSolicitud.info} />
                : dataSolicitud.info.pestanaInformacionEstudio === true && pestaña === 'informacionEstudio'
                  ?
                  <InformacionEstudio validated={validated} data={dataSolicitud.info} />
                  : dataSolicitud.info.pestanaAsignacionAnalistas === true && pestaña === 'asignacionAnalistas'
                    ?
                    <AsignacionAnalistasInteligencia />
                    : dataSolicitud.info.pestanaInformacionPersonalBasica === true && pestaña === 'informacion_basica'
                      ?
                      <InformacionPersonalBasicaInteligencia />
                      : dataSolicitud.info.pestanaInformacionPersonalCompleta === true && pestaña === 'informacion_completa'
                        ?
                        <InformacionPersonalBasica />
                        : dataSolicitud.info.pestanaInformacionFamiliar === true && pestaña === 'informacion_familiar'
                          ?
                          <InformacionFamiliar />
                          : dataSolicitud.info.pestanaInformacionAcademica === true && pestaña === 'informacion_academica'
                            ?
                            <InformacionAcademica />
                            : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'informacion_laboral'
                              ?
                              <InformacionLaboral />
                              : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'visita'
                                ?
                                <Visita />
                                : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'anotaciones'
                                  ?
                                  <Anotaciones />
                                  : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'conclusiones'
                                    ?
                                    <Conclusiones />
                                    : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'financiero'
                                      ?
                                      <Financiero />
                                      : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'poligrafo'
                                        ?
                                        <Poligrafo />
                                        : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'eyedetect'
                                          ?
                                          <Eyedetect />
                                          : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'patrimonial'
                                            ?
                                            <Patrimonial />
                                            : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'pruebas_psicotecnicas'
                                              ?
                                              <PruebasPsicotecnicas />
                                              : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'vsa'
                                                ?
                                                <VSA />
                                                : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'anotaciones_grupo_familiar'
                                                  ?
                                                  <AnotacionesGrupoFamiliar />
                                                  : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'sarlaft'
                                                    ?
                                                    <Sarlaft />
                                                    : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'soportes'
                                                      ?
                                                      <Soportes />
                                                      : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'protocolo_seguridad'
                                                        ?
                                                        <ProtocoloSeguridad />
                                                        : dataSolicitud.info.pestanaInfoGeneral === true && pestaña === 'cesantias'
                                                          ? <Cesantias />
                                                          : null


              }

            </>
            :
            <>

            </>
        }


      </>
    </div>
  )


}
