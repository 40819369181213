export const municipios = [
        {
          "label": "El Encanto - Amazonas",
          "value": "El Encanto - Amazonas"
        },
        {
          "label": "La Chorrera - Amazonas",
          "value": "La Chorrera - Amazonas"
        },
        {
          "label": "La Pedrera - Amazonas",
          "value": "La Pedrera - Amazonas"
        },
        {
          "label": "La Victoria - Amazonas",
          "value": "La Victoria - Amazonas"
        },
        {
          "label": "Leticia - Amazonas",
          "value": "Leticia - Amazonas"
        },
        {
          "label": "Miriti - Amazonas",
          "value": "Miriti - Amazonas"
        },
        {
          "label": "Puerto Alegria - Amazonas",
          "value": "Puerto Alegria - Amazonas"
        },
        {
          "label": "Puerto Arica - Amazonas",
          "value": "Puerto Arica - Amazonas"
        },
        {
          "label": "Puerto Nariño - Amazonas",
          "value": "Puerto Nariño - Amazonas"
        },
        {
          "label": "Puerto Santander - Amazonas",
          "value": "Puerto Santander - Amazonas"
        },
        {
          "label": "Turapaca - Amazonas",
          "value": "Turapaca - Amazonas"
        },
        {
          "label": "Abejorral - Antioquia",
          "value": "Abejorral - Antioquia"
        },
        {
          "label": "Abriaqui - Antioquia",
          "value": "Abriaqui - Antioquia"
        },
        {
          "label": "Alejandria - Antioquia",
          "value": "Alejandria - Antioquia"
        },
        {
          "label": "Amaga - Antioquia",
          "value": "Amaga - Antioquia"
        },
        {
          "label": "Amalfi - Antioquia",
          "value": "Amalfi - Antioquia"
        },
        {
          "label": "Andes - Antioquia",
          "value": "Andes - Antioquia"
        },
        {
          "label": "Angelopolis - Antioquia",
          "value": "Angelopolis - Antioquia"
        },
        {
          "label": "Angostura - Antioquia",
          "value": "Angostura - Antioquia"
        },
        {
          "label": "Anori - Antioquia",
          "value": "Anori - Antioquia"
        },
        {
          "label": "Antioquia - Antioquia",
          "value": "Antioquia - Antioquia"
        },
        {
          "label": "Anza - Antioquia",
          "value": "Anza - Antioquia"
        },
        {
          "label": "Apartado - Antioquia",
          "value": "Apartado - Antioquia"
        },
        {
          "label": "Arboletes - Antioquia",
          "value": "Arboletes - Antioquia"
        },
        {
          "label": "Argelia - Antioquia",
          "value": "Argelia - Antioquia"
        },
        {
          "label": "Armenia - Antioquia",
          "value": "Armenia - Antioquia"
        },
        {
          "label": "Barbosa - Antioquia",
          "value": "Barbosa - Antioquia"
        },
        {
          "label": "Bello - Antioquia",
          "value": "Bello - Antioquia"
        },
        {
          "label": "Belmira - Antioquia",
          "value": "Belmira - Antioquia"
        },
        {
          "label": "Betania - Antioquia",
          "value": "Betania - Antioquia"
        },
        {
          "label": "Betulia - Antioquia",
          "value": "Betulia - Antioquia"
        },
        {
          "label": "Bolivar - Antioquia",
          "value": "Bolivar - Antioquia"
        },
        {
          "label": "Briceño - Antioquia",
          "value": "Briceño - Antioquia"
        },
        {
          "label": "Buritica - Antioquia",
          "value": "Buritica - Antioquia"
        },
        {
          "label": "Caceres - Antioquia",
          "value": "Caceres - Antioquia"
        },
        {
          "label": "Caicedo - Antioquia",
          "value": "Caicedo - Antioquia"
        },
        {
          "label": "Caldas - Antioquia",
          "value": "Caldas - Antioquia"
        },
        {
          "label": "Campamento - Antioquia",
          "value": "Campamento - Antioquia"
        },
        {
          "label": "Canasgordas - Antioquia",
          "value": "Canasgordas - Antioquia"
        },
        {
          "label": "Caracoli - Antioquia",
          "value": "Caracoli - Antioquia"
        },
        {
          "label": "Caramanta - Antioquia",
          "value": "Caramanta - Antioquia"
        },
        {
          "label": "Carepa - Antioquia",
          "value": "Carepa - Antioquia"
        },
        {
          "label": "Carmen de Viboral - Antioquia",
          "value": "Carmen de Viboral - Antioquia"
        },
        {
          "label": "Carolina del Principe - Antioquia",
          "value": "Carolina del Principe - Antioquia"
        },
        {
          "label": "Caucasia - Antioquia",
          "value": "Caucasia - Antioquia"
        },
        {
          "label": "Chigorodo - Antioquia",
          "value": "Chigorodo - Antioquia"
        },
        {
          "label": "Cisneros - Antioquia",
          "value": "Cisneros - Antioquia"
        },
        {
          "label": "Cocorna - Antioquia",
          "value": "Cocorna - Antioquia"
        },
        {
          "label": "Concepcion - Antioquia",
          "value": "Concepcion - Antioquia"
        },
        {
          "label": "Concordia - Antioquia",
          "value": "Concordia - Antioquia"
        },
        {
          "label": "Copacabana - Antioquia",
          "value": "Copacabana - Antioquia"
        },
        {
          "label": "Dabeiba - Antioquia",
          "value": "Dabeiba - Antioquia"
        },
        {
          "label": "Donmatias - Antioquia",
          "value": "Donmatias - Antioquia"
        },
        {
          "label": "Doradal - Antioquia",
          "value": "Doradal - Antioquia"
        },
        {
          "label": "Ebejico - Antioquia",
          "value": "Ebejico - Antioquia"
        },
        {
          "label": "El Bagre - Antioquia",
          "value": "El Bagre - Antioquia"
        },
        {
          "label": "El Penol - Antioquia",
          "value": "El Penol - Antioquia"
        },
        {
          "label": "El Retiro - Antioquia",
          "value": "El Retiro - Antioquia"
        },
        {
          "label": "Entrerrios - Antioquia",
          "value": "Entrerrios - Antioquia"
        },
        {
          "label": "Envigado - Antioquia",
          "value": "Envigado - Antioquia"
        },
        {
          "label": "Fredonia - Antioquia",
          "value": "Fredonia - Antioquia"
        },
        {
          "label": "Frontino - Antioquia",
          "value": "Frontino - Antioquia"
        },
        {
          "label": "Giraldo - Antioquia",
          "value": "Giraldo - Antioquia"
        },
        {
          "label": "Girardota - Antioquia",
          "value": "Girardota - Antioquia"
        },
        {
          "label": "Gomez Plata - Antioquia",
          "value": "Gomez Plata - Antioquia"
        },
        {
          "label": "Granada - Antioquia",
          "value": "Granada - Antioquia"
        },
        {
          "label": "Guadalupe - Antioquia",
          "value": "Guadalupe - Antioquia"
        },
        {
          "label": "Guarne - Antioquia",
          "value": "Guarne - Antioquia"
        },
        {
          "label": "Guataque - Antioquia",
          "value": "Guataque - Antioquia"
        },
        {
          "label": "Heliconia - Antioquia",
          "value": "Heliconia - Antioquia"
        },
        {
          "label": "Hispania - Antioquia",
          "value": "Hispania - Antioquia"
        },
        {
          "label": "Itagui - Antioquia",
          "value": "Itagui - Antioquia"
        },
        {
          "label": "Ituango - Antioquia",
          "value": "Ituango - Antioquia"
        },
        {
          "label": "Jardin - Antioquia",
          "value": "Jardin - Antioquia"
        },
        {
          "label": "Jerico - Antioquia",
          "value": "Jerico - Antioquia"
        },
        {
          "label": "La Ceja - Antioquia",
          "value": "La Ceja - Antioquia"
        },
        {
          "label": "La Estrella - Antioquia",
          "value": "La Estrella - Antioquia"
        },
        {
          "label": "La Pintada - Antioquia",
          "value": "La Pintada - Antioquia"
        },
        {
          "label": "La Union - Antioquia",
          "value": "La Union - Antioquia"
        },
        {
          "label": "Liborina - Antioquia",
          "value": "Liborina - Antioquia"
        },
        {
          "label": "Maceo - Antioquia",
          "value": "Maceo - Antioquia"
        },
        {
          "label": "Marinilla - Antioquia",
          "value": "Marinilla - Antioquia"
        },
        {
          "label": "Medellin - Antioquia",
          "value": "Medellin - Antioquia"
        },
        {
          "label": "Montebello - Antioquia",
          "value": "Montebello - Antioquia"
        },
        {
          "label": "Murindo - Antioquia",
          "value": "Murindo - Antioquia"
        },
        {
          "label": "Mutata - Antioquia",
          "value": "Mutata - Antioquia"
        },
        {
          "label": "Narino - Antioquia",
          "value": "Narino - Antioquia"
        },
        {
          "label": "Nechi - Antioquia",
          "value": "Nechi - Antioquia"
        },
        {
          "label": "Necocli - Antioquia",
          "value": "Necocli - Antioquia"
        },
        {
          "label": "Olaya - Antioquia",
          "value": "Olaya - Antioquia"
        },
        {
          "label": "Peque - Antioquia",
          "value": "Peque - Antioquia"
        },
        {
          "label": "Pueblorrico - Antioquia",
          "value": "Pueblorrico - Antioquia"
        },
        {
          "label": "Puerto Berrio - Antioquia",
          "value": "Puerto Berrio - Antioquia"
        },
        {
          "label": "Puerto Nare - Antioquia",
          "value": "Puerto Nare - Antioquia"
        },
        {
          "label": "Puerto Triunfo - Antioquia",
          "value": "Puerto Triunfo - Antioquia"
        },
        {
          "label": "Remedios - Antioquia",
          "value": "Remedios - Antioquia"
        },
        {
          "label": "Rionegro - Antioquia",
          "value": "Rionegro - Antioquia"
        },
        {
          "label": "Sabanalarga - Antioquia",
          "value": "Sabanalarga - Antioquia"
        },
        {
          "label": "Sabaneta - Antioquia",
          "value": "Sabaneta - Antioquia"
        },
        {
          "label": "Salgar - Antioquia",
          "value": "Salgar - Antioquia"
        },
        {
          "label": "San Andres de Cuerquia - Antioquia",
          "value": "San Andres de Cuerquia - Antioquia"
        },
        {
          "label": "San Carlos - Antioquia",
          "value": "San Carlos - Antioquia"
        },
        {
          "label": "San Francisco - Antioquia",
          "value": "San Francisco - Antioquia"
        },
        {
          "label": "San Jeronimo - Antioquia",
          "value": "San Jeronimo - Antioquia"
        },
        {
          "label": "San Jose de La Montaña - Antioquia",
          "value": "San Jose de La Montaña - Antioquia"
        },
        {
          "label": "San Juan de Uraba - Antioquia",
          "value": "San Juan de Uraba - Antioquia"
        },
        {
          "label": "San Luis - Antioquia",
          "value": "San Luis - Antioquia"
        },
        {
          "label": "San Pedro de Los Milagros - Antioquia",
          "value": "San Pedro de Los Milagros - Antioquia"
        },
        {
          "label": "San Pedro de Uraba - Antioquia",
          "value": "San Pedro de Uraba - Antioquia"
        },
        {
          "label": "San Rafael - Antioquia",
          "value": "San Rafael - Antioquia"
        },
        {
          "label": "San Roque - Antioquia",
          "value": "San Roque - Antioquia"
        },
        {
          "label": "San Vicente - Antioquia",
          "value": "San Vicente - Antioquia"
        },
        {
          "label": "Santa Barbara - Antioquia",
          "value": "Santa Barbara - Antioquia"
        },
        {
          "label": "Santa Fe de Antioquia - Antioquia",
          "value": "Santa Fe de Antioquia - Antioquia"
        },
        {
          "label": "Santa Rosa de Osos - Antioquia",
          "value": "Santa Rosa de Osos - Antioquia"
        },
        {
          "label": "Santo Domingo - Antioquia",
          "value": "Santo Domingo - Antioquia"
        },
        {
          "label": "Santuario - Antioquia",
          "value": "Santuario - Antioquia"
        },
        {
          "label": "Segovia - Antioquia",
          "value": "Segovia - Antioquia"
        },
        {
          "label": "Sonson - Antioquia",
          "value": "Sonson - Antioquia"
        },
        {
          "label": "Sopetran - Antioquia",
          "value": "Sopetran - Antioquia"
        },
        {
          "label": "Tamesis - Antioquia",
          "value": "Tamesis - Antioquia"
        },
        {
          "label": "Taraza - Antioquia",
          "value": "Taraza - Antioquia"
        },
        {
          "label": "Tarso - Antioquia",
          "value": "Tarso - Antioquia"
        },
        {
          "label": "Titiribi - Antioquia",
          "value": "Titiribi - Antioquia"
        },
        {
          "label": "Toledo - Antioquia",
          "value": "Toledo - Antioquia"
        },
        {
          "label": "Turbo - Antioquia",
          "value": "Turbo - Antioquia"
        },
        {
          "label": "Uramita - Antioquia",
          "value": "Uramita - Antioquia"
        },
        {
          "label": "Urrao - Antioquia",
          "value": "Urrao - Antioquia"
        },
        {
          "label": "Valdivia - Antioquia",
          "value": "Valdivia - Antioquia"
        },
        {
          "label": "Valparaiso - Antioquia",
          "value": "Valparaiso - Antioquia"
        },
        {
          "label": "Vegachi - Antioquia",
          "value": "Vegachi - Antioquia"
        },
        {
          "label": "Venecia - Antioquia",
          "value": "Venecia - Antioquia"
        },
        {
          "label": "Vigia del Fuerte - Antioquia",
          "value": "Vigia del Fuerte - Antioquia"
        },
        {
          "label": "Yali - Antioquia",
          "value": "Yali - Antioquia"
        },
        {
          "label": "Yarumal - Antioquia",
          "value": "Yarumal - Antioquia"
        },
        {
          "label": "Yolombo - Antioquia",
          "value": "Yolombo - Antioquia"
        },
        {
          "label": "Yondo - Antioquia",
          "value": "Yondo - Antioquia"
        },
        {
          "label": "Zaragoza - Antioquia",
          "value": "Zaragoza - Antioquia"
        },
        {
          "label": "Arauca - Arauca",
          "value": "Arauca - Arauca"
        },
        {
          "label": "Arauquita - Arauca",
          "value": "Arauquita - Arauca"
        },
        {
          "label": "Cravo Norte - Arauca",
          "value": "Cravo Norte - Arauca"
        },
        {
          "label": "Fortul - Arauca",
          "value": "Fortul - Arauca"
        },
        {
          "label": "Puerto Rondon - Arauca",
          "value": "Puerto Rondon - Arauca"
        },
        {
          "label": "Saravena - Arauca",
          "value": "Saravena - Arauca"
        },
        {
          "label": "Tame - Arauca",
          "value": "Tame - Arauca"
        },
        {
          "label": "Baranoa - Atlántico",
          "value": "Baranoa - Atlántico"
        },
        {
          "label": "Barranquilla - Atlántico",
          "value": "Barranquilla - Atlántico"
        },
        {
          "label": "Campo de La Cruz - Atlántico",
          "value": "Campo de La Cruz - Atlántico"
        },
        {
          "label": "Candelaria - Atlántico",
          "value": "Candelaria - Atlántico"
        },
        {
          "label": "Galapa - Atlántico",
          "value": "Galapa - Atlántico"
        },
        {
          "label": "Juan de Acosta - Atlántico",
          "value": "Juan de Acosta - Atlántico"
        },
        {
          "label": "Luruaco - Atlántico",
          "value": "Luruaco - Atlántico"
        },
        {
          "label": "Malambo - Atlántico",
          "value": "Malambo - Atlántico"
        },
        {
          "label": "Manati - Atlántico",
          "value": "Manati - Atlántico"
        },
        {
          "label": "Palmar de Varela - Atlántico",
          "value": "Palmar de Varela - Atlántico"
        },
        {
          "label": "Piojo - Atlántico",
          "value": "Piojo - Atlántico"
        },
        {
          "label": "Polo Nuevo - Atlántico",
          "value": "Polo Nuevo - Atlántico"
        },
        {
          "label": "Ponedera - Atlántico",
          "value": "Ponedera - Atlántico"
        },
        {
          "label": "Puerto Colombia - Atlántico",
          "value": "Puerto Colombia - Atlántico"
        },
        {
          "label": "Repelon - Atlántico",
          "value": "Repelon - Atlántico"
        },
        {
          "label": "Sabanagrande - Atlántico",
          "value": "Sabanagrande - Atlántico"
        },
        {
          "label": "Sabanalarga - Atlántico",
          "value": "Sabanalarga - Atlántico"
        },
        {
          "label": "Santa Lucia - Atlántico",
          "value": "Santa Lucia - Atlántico"
        },
        {
          "label": "Santo Tomas - Atlántico",
          "value": "Santo Tomas - Atlántico"
        },
        {
          "label": "Soledad - Atlántico",
          "value": "Soledad - Atlántico"
        },
        {
          "label": "Suan - Atlántico",
          "value": "Suan - Atlántico"
        },
        {
          "label": "Tubara - Atlántico",
          "value": "Tubara - Atlántico"
        },
        {
          "label": "Usiacuri - Atlántico",
          "value": "Usiacuri - Atlántico"
        },
        {
          "label": "Achi - Bolívar",
          "value": "Achi - Bolívar"
        },
        {
          "label": "Altos del Rosario - Bolívar",
          "value": "Altos del Rosario - Bolívar"
        },
        {
          "label": "Arenal - Bolívar",
          "value": "Arenal - Bolívar"
        },
        {
          "label": "Arjona - Bolívar",
          "value": "Arjona - Bolívar"
        },
        {
          "label": "Arroyohondo - Bolívar",
          "value": "Arroyohondo - Bolívar"
        },
        {
          "label": "Barranco de Loba - Bolívar",
          "value": "Barranco de Loba - Bolívar"
        },
        {
          "label": "Brazuelo de Papayal - Bolívar",
          "value": "Brazuelo de Papayal - Bolívar"
        },
        {
          "label": "Calamar - Bolívar",
          "value": "Calamar - Bolívar"
        },
        {
          "label": "Cantagallo - Bolívar",
          "value": "Cantagallo - Bolívar"
        },
        {
          "label": "Cartagena de Indias - Bolívar",
          "value": "Cartagena de Indias - Bolívar"
        },
        {
          "label": "Cicuco - Bolívar",
          "value": "Cicuco - Bolívar"
        },
        {
          "label": "Clemencia - Bolívar",
          "value": "Clemencia - Bolívar"
        },
        {
          "label": "Cordoba - Bolívar",
          "value": "Cordoba - Bolívar"
        },
        {
          "label": "El Carmen de Bolivar - Bolívar",
          "value": "El Carmen de Bolivar - Bolívar"
        },
        {
          "label": "El Guamo - Bolívar",
          "value": "El Guamo - Bolívar"
        },
        {
          "label": "El Penion - Bolívar",
          "value": "El Penion - Bolívar"
        },
        {
          "label": "Hatillo de Loba - Bolívar",
          "value": "Hatillo de Loba - Bolívar"
        },
        {
          "label": "Magangue - Bolívar",
          "value": "Magangue - Bolívar"
        },
        {
          "label": "Mahates - Bolívar",
          "value": "Mahates - Bolívar"
        },
        {
          "label": "Margarita - Bolívar",
          "value": "Margarita - Bolívar"
        },
        {
          "label": "Maria La Baja - Bolívar",
          "value": "Maria La Baja - Bolívar"
        },
        {
          "label": "Montecristo - Bolívar",
          "value": "Montecristo - Bolívar"
        },
        {
          "label": "Morales - Bolívar",
          "value": "Morales - Bolívar"
        },
        {
          "label": "Morales - Bolívar",
          "value": "Morales - Bolívar"
        },
        {
          "label": "Norosi - Bolívar",
          "value": "Norosi - Bolívar"
        },
        {
          "label": "Pinillos - Bolívar",
          "value": "Pinillos - Bolívar"
        },
        {
          "label": "Regidor - Bolívar",
          "value": "Regidor - Bolívar"
        },
        {
          "label": "Rio Viejo - Bolívar",
          "value": "Rio Viejo - Bolívar"
        },
        {
          "label": "San Cristobal - Bolívar",
          "value": "San Cristobal - Bolívar"
        },
        {
          "label": "San Estanislao - Bolívar",
          "value": "San Estanislao - Bolívar"
        },
        {
          "label": "San Fernando - Bolívar",
          "value": "San Fernando - Bolívar"
        },
        {
          "label": "San Jacinto - Bolívar",
          "value": "San Jacinto - Bolívar"
        },
        {
          "label": "San Jacinto del Cauca - Bolívar",
          "value": "San Jacinto del Cauca - Bolívar"
        },
        {
          "label": "San Juan de Nepomuceno - Bolívar",
          "value": "San Juan de Nepomuceno - Bolívar"
        },
        {
          "label": "San Martin de Loba - Bolívar",
          "value": "San Martin de Loba - Bolívar"
        },
        {
          "label": "San Pablo - Bolívar",
          "value": "San Pablo - Bolívar"
        },
        {
          "label": "San Pablo Norte - Bolívar",
          "value": "San Pablo Norte - Bolívar"
        },
        {
          "label": "Santa Catalina - Bolívar",
          "value": "Santa Catalina - Bolívar"
        },
        {
          "label": "Santa Cruz de Mompox - Bolívar",
          "value": "Santa Cruz de Mompox - Bolívar"
        },
        {
          "label": "Santa Rosa - Bolívar",
          "value": "Santa Rosa - Bolívar"
        },
        {
          "label": "Santa Rosa del Sur - Bolívar",
          "value": "Santa Rosa del Sur - Bolívar"
        },
        {
          "label": "Simiti - Bolívar",
          "value": "Simiti - Bolívar"
        },
        {
          "label": "Soplaviento - Bolívar",
          "value": "Soplaviento - Bolívar"
        },
        {
          "label": "Talaigua Nuevo - Bolívar",
          "value": "Talaigua Nuevo - Bolívar"
        },
        {
          "label": "Tuquisio - Bolívar",
          "value": "Tuquisio - Bolívar"
        },
        {
          "label": "Turbaco - Bolívar",
          "value": "Turbaco - Bolívar"
        },
        {
          "label": "Turbana - Bolívar",
          "value": "Turbana - Bolívar"
        },
        {
          "label": "Villanueva - Bolívar",
          "value": "Villanueva - Bolívar"
        },
        {
          "label": "Zambrano - Bolívar",
          "value": "Zambrano - Bolívar"
        },
        {
          "label": "Aquitania - Boyacá",
          "value": "Aquitania - Boyacá"
        },
        {
          "label": "Arcabuco - Boyacá",
          "value": "Arcabuco - Boyacá"
        },
        {
          "label": "Belén - Boyacá",
          "value": "Belén - Boyacá"
        },
        {
          "label": "Berbeo - Boyacá",
          "value": "Berbeo - Boyacá"
        },
        {
          "label": "Betéitiva - Boyacá",
          "value": "Betéitiva - Boyacá"
        },
        {
          "label": "Boavita - Boyacá",
          "value": "Boavita - Boyacá"
        },
        {
          "label": "Boyacá - Boyacá",
          "value": "Boyacá - Boyacá"
        },
        {
          "label": "Briceño - Boyacá",
          "value": "Briceño - Boyacá"
        },
        {
          "label": "Buenavista - Boyacá",
          "value": "Buenavista - Boyacá"
        },
        {
          "label": "Busbanzá - Boyacá",
          "value": "Busbanzá - Boyacá"
        },
        {
          "label": "Caldas - Boyacá",
          "value": "Caldas - Boyacá"
        },
        {
          "label": "Campo Hermoso - Boyacá",
          "value": "Campo Hermoso - Boyacá"
        },
        {
          "label": "Cerinza - Boyacá",
          "value": "Cerinza - Boyacá"
        },
        {
          "label": "Chinavita - Boyacá",
          "value": "Chinavita - Boyacá"
        },
        {
          "label": "Chiquinquirá - Boyacá",
          "value": "Chiquinquirá - Boyacá"
        },
        {
          "label": "Chíquiza - Boyacá",
          "value": "Chíquiza - Boyacá"
        },
        {
          "label": "Chiscas - Boyacá",
          "value": "Chiscas - Boyacá"
        },
        {
          "label": "Chita - Boyacá",
          "value": "Chita - Boyacá"
        },
        {
          "label": "Chitaraque - Boyacá",
          "value": "Chitaraque - Boyacá"
        },
        {
          "label": "Chivatá - Boyacá",
          "value": "Chivatá - Boyacá"
        },
        {
          "label": "Chivor - Boyacá",
          "value": "Chivor - Boyacá"
        },
        {
          "label": "Ciénega - Boyacá",
          "value": "Ciénega - Boyacá"
        },
        {
          "label": "Cómbita - Boyacá",
          "value": "Cómbita - Boyacá"
        },
        {
          "label": "Coper - Boyacá",
          "value": "Coper - Boyacá"
        },
        {
          "label": "Corrales - Boyacá",
          "value": "Corrales - Boyacá"
        },
        {
          "label": "Covarachía - Boyacá",
          "value": "Covarachía - Boyacá"
        },
        {
          "label": "Cubara - Boyacá",
          "value": "Cubara - Boyacá"
        },
        {
          "label": "Cucaita - Boyacá",
          "value": "Cucaita - Boyacá"
        },
        {
          "label": "Cuitiva - Boyacá",
          "value": "Cuitiva - Boyacá"
        },
        {
          "label": "Duitama - Boyacá",
          "value": "Duitama - Boyacá"
        },
        {
          "label": "El Cocuy - Boyacá",
          "value": "El Cocuy - Boyacá"
        },
        {
          "label": "El Espino - Boyacá",
          "value": "El Espino - Boyacá"
        },
        {
          "label": "Firavitoba - Boyacá",
          "value": "Firavitoba - Boyacá"
        },
        {
          "label": "Floresta - Boyacá",
          "value": "Floresta - Boyacá"
        },
        {
          "label": "Gachantivá - Boyacá",
          "value": "Gachantivá - Boyacá"
        },
        {
          "label": "Gámeza - Boyacá",
          "value": "Gámeza - Boyacá"
        },
        {
          "label": "Garagoa - Boyacá",
          "value": "Garagoa - Boyacá"
        },
        {
          "label": "Guacamayas - Boyacá",
          "value": "Guacamayas - Boyacá"
        },
        {
          "label": "Guateque - Boyacá",
          "value": "Guateque - Boyacá"
        },
        {
          "label": "Güicán - Boyacá",
          "value": "Güicán - Boyacá"
        },
        {
          "label": "Iza - Boyacá",
          "value": "Iza - Boyacá"
        },
        {
          "label": "Jenesano - Boyacá",
          "value": "Jenesano - Boyacá"
        },
        {
          "label": "Jericó - Boyacá",
          "value": "Jericó - Boyacá"
        },
        {
          "label": "La Uvita - Boyacá",
          "value": "La Uvita - Boyacá"
        },
        {
          "label": "La Victoria - Boyacá",
          "value": "La Victoria - Boyacá"
        },
        {
          "label": "Labranza Grande - Boyacá",
          "value": "Labranza Grande - Boyacá"
        },
        {
          "label": "Macanal - Boyacá",
          "value": "Macanal - Boyacá"
        },
        {
          "label": "Maripí - Boyacá",
          "value": "Maripí - Boyacá"
        },
        {
          "label": "Miraflores - Boyacá",
          "value": "Miraflores - Boyacá"
        },
        {
          "label": "Mongua - Boyacá",
          "value": "Mongua - Boyacá"
        },
        {
          "label": "Monguí - Boyacá",
          "value": "Monguí - Boyacá"
        },
        {
          "label": "Moniquirá - Boyacá",
          "value": "Moniquirá - Boyacá"
        },
        {
          "label": "Motavita - Boyacá",
          "value": "Motavita - Boyacá"
        },
        {
          "label": "Muzo - Boyacá",
          "value": "Muzo - Boyacá"
        },
        {
          "label": "Nobsa - Boyacá",
          "value": "Nobsa - Boyacá"
        },
        {
          "label": "Nuevo Colón - Boyacá",
          "value": "Nuevo Colón - Boyacá"
        },
        {
          "label": "Oicatá - Boyacá",
          "value": "Oicatá - Boyacá"
        },
        {
          "label": "Otanche - Boyacá",
          "value": "Otanche - Boyacá"
        },
        {
          "label": "Pachavita - Boyacá",
          "value": "Pachavita - Boyacá"
        },
        {
          "label": "Páez - Boyacá",
          "value": "Páez - Boyacá"
        },
        {
          "label": "Paipa - Boyacá",
          "value": "Paipa - Boyacá"
        },
        {
          "label": "Pajarito - Boyacá",
          "value": "Pajarito - Boyacá"
        },
        {
          "label": "Panqueba - Boyacá",
          "value": "Panqueba - Boyacá"
        },
        {
          "label": "Pauna - Boyacá",
          "value": "Pauna - Boyacá"
        },
        {
          "label": "Paya - Boyacá",
          "value": "Paya - Boyacá"
        },
        {
          "label": "Paz de Río - Boyacá",
          "value": "Paz de Río - Boyacá"
        },
        {
          "label": "Pesca - Boyacá",
          "value": "Pesca - Boyacá"
        },
        {
          "label": "Pisba - Boyacá",
          "value": "Pisba - Boyacá"
        },
        {
          "label": "Puerto Boyaca - Boyacá",
          "value": "Puerto Boyaca - Boyacá"
        },
        {
          "label": "Quípama - Boyacá",
          "value": "Quípama - Boyacá"
        },
        {
          "label": "Ramiriquí - Boyacá",
          "value": "Ramiriquí - Boyacá"
        },
        {
          "label": "Ráquira - Boyacá",
          "value": "Ráquira - Boyacá"
        },
        {
          "label": "Rondón - Boyacá",
          "value": "Rondón - Boyacá"
        },
        {
          "label": "Saboyá - Boyacá",
          "value": "Saboyá - Boyacá"
        },
        {
          "label": "Sáchica - Boyacá",
          "value": "Sáchica - Boyacá"
        },
        {
          "label": "Samacá - Boyacá",
          "value": "Samacá - Boyacá"
        },
        {
          "label": "San Eduardo - Boyacá",
          "value": "San Eduardo - Boyacá"
        },
        {
          "label": "San José de Pare - Boyacá",
          "value": "San José de Pare - Boyacá"
        },
        {
          "label": "San Luís de Gaceno - Boyacá",
          "value": "San Luís de Gaceno - Boyacá"
        },
        {
          "label": "San Mateo - Boyacá",
          "value": "San Mateo - Boyacá"
        },
        {
          "label": "San Miguel de Sema - Boyacá",
          "value": "San Miguel de Sema - Boyacá"
        },
        {
          "label": "San Pablo de Borbur - Boyacá",
          "value": "San Pablo de Borbur - Boyacá"
        },
        {
          "label": "Santa María - Boyacá",
          "value": "Santa María - Boyacá"
        },
        {
          "label": "Santa Rosa de Viterbo - Boyacá",
          "value": "Santa Rosa de Viterbo - Boyacá"
        },
        {
          "label": "Santa Sofía - Boyacá",
          "value": "Santa Sofía - Boyacá"
        },
        {
          "label": "Santana - Boyacá",
          "value": "Santana - Boyacá"
        },
        {
          "label": "Sativanorte - Boyacá",
          "value": "Sativanorte - Boyacá"
        },
        {
          "label": "Sativasur - Boyacá",
          "value": "Sativasur - Boyacá"
        },
        {
          "label": "Siachoque - Boyacá",
          "value": "Siachoque - Boyacá"
        },
        {
          "label": "Soatá - Boyacá",
          "value": "Soatá - Boyacá"
        },
        {
          "label": "Socha - Boyacá",
          "value": "Socha - Boyacá"
        },
        {
          "label": "Socotá - Boyacá",
          "value": "Socotá - Boyacá"
        },
        {
          "label": "Sogamoso - Boyacá",
          "value": "Sogamoso - Boyacá"
        },
        {
          "label": "Somondoco - Boyacá",
          "value": "Somondoco - Boyacá"
        },
        {
          "label": "Sora - Boyacá",
          "value": "Sora - Boyacá"
        },
        {
          "label": "Soracá - Boyacá",
          "value": "Soracá - Boyacá"
        },
        {
          "label": "Sotaquirá - Boyacá",
          "value": "Sotaquirá - Boyacá"
        },
        {
          "label": "Susacón - Boyacá",
          "value": "Susacón - Boyacá"
        },
        {
          "label": "Sutarmachán - Boyacá",
          "value": "Sutarmachán - Boyacá"
        },
        {
          "label": "Sutatenza - Boyacá",
          "value": "Sutatenza - Boyacá"
        },
        {
          "label": "Tasco - Boyacá",
          "value": "Tasco - Boyacá"
        },
        {
          "label": "Tenza - Boyacá",
          "value": "Tenza - Boyacá"
        },
        {
          "label": "Tibaná - Boyacá",
          "value": "Tibaná - Boyacá"
        },
        {
          "label": "Tibasosa - Boyacá",
          "value": "Tibasosa - Boyacá"
        },
        {
          "label": "Tinjacá - Boyacá",
          "value": "Tinjacá - Boyacá"
        },
        {
          "label": "Tipacoque - Boyacá",
          "value": "Tipacoque - Boyacá"
        },
        {
          "label": "Toca - Boyacá",
          "value": "Toca - Boyacá"
        },
        {
          "label": "Togüí - Boyacá",
          "value": "Togüí - Boyacá"
        },
        {
          "label": "Tópaga - Boyacá",
          "value": "Tópaga - Boyacá"
        },
        {
          "label": "Tota - Boyacá",
          "value": "Tota - Boyacá"
        },
        {
          "label": "Tunja - Boyacá",
          "value": "Tunja - Boyacá"
        },
        {
          "label": "Tununguá - Boyacá",
          "value": "Tununguá - Boyacá"
        },
        {
          "label": "Turmequé - Boyacá",
          "value": "Turmequé - Boyacá"
        },
        {
          "label": "Tuta - Boyacá",
          "value": "Tuta - Boyacá"
        },
        {
          "label": "Tutazá - Boyacá",
          "value": "Tutazá - Boyacá"
        },
        {
          "label": "Umbita - Boyacá",
          "value": "Umbita - Boyacá"
        },
        {
          "label": "Venta Quemada - Boyacá",
          "value": "Venta Quemada - Boyacá"
        },
        {
          "label": "Villa de Leyva - Boyacá",
          "value": "Villa de Leyva - Boyacá"
        },
        {
          "label": "Viracachá - Boyacá",
          "value": "Viracachá - Boyacá"
        },
        {
          "label": "Zetaquira - Boyacá",
          "value": "Zetaquira - Boyacá"
        },
        {
          "label": "Aguadas - Caldas",
          "value": "Aguadas - Caldas"
        },
        {
          "label": "Anserma - Caldas",
          "value": "Anserma - Caldas"
        },
        {
          "label": "Aranzazu - Caldas",
          "value": "Aranzazu - Caldas"
        },
        {
          "label": "Belalcazar - Caldas",
          "value": "Belalcazar - Caldas"
        },
        {
          "label": "Chinchiná - Caldas",
          "value": "Chinchiná - Caldas"
        },
        {
          "label": "Filadelfia - Caldas",
          "value": "Filadelfia - Caldas"
        },
        {
          "label": "La Dorada - Caldas",
          "value": "La Dorada - Caldas"
        },
        {
          "label": "La Merced - Caldas",
          "value": "La Merced - Caldas"
        },
        {
          "label": "Manizales - Caldas",
          "value": "Manizales - Caldas"
        },
        {
          "label": "Manzanares - Caldas",
          "value": "Manzanares - Caldas"
        },
        {
          "label": "Marmato - Caldas",
          "value": "Marmato - Caldas"
        },
        {
          "label": "Marquetalia - Caldas",
          "value": "Marquetalia - Caldas"
        },
        {
          "label": "Marulanda - Caldas",
          "value": "Marulanda - Caldas"
        },
        {
          "label": "Neira - Caldas",
          "value": "Neira - Caldas"
        },
        {
          "label": "Norcasia - Caldas",
          "value": "Norcasia - Caldas"
        },
        {
          "label": "Pacora - Caldas",
          "value": "Pacora - Caldas"
        },
        {
          "label": "Palestina - Caldas",
          "value": "Palestina - Caldas"
        },
        {
          "label": "Pensilvania - Caldas",
          "value": "Pensilvania - Caldas"
        },
        {
          "label": "Riosucio - Caldas",
          "value": "Riosucio - Caldas"
        },
        {
          "label": "Risaralda - Caldas",
          "value": "Risaralda - Caldas"
        },
        {
          "label": "Salamina - Caldas",
          "value": "Salamina - Caldas"
        },
        {
          "label": "Samana - Caldas",
          "value": "Samana - Caldas"
        },
        {
          "label": "San Jose - Caldas",
          "value": "San Jose - Caldas"
        },
        {
          "label": "Supía - Caldas",
          "value": "Supía - Caldas"
        },
        {
          "label": "Victoria - Caldas",
          "value": "Victoria - Caldas"
        },
        {
          "label": "Villamaría - Caldas",
          "value": "Villamaría - Caldas"
        },
        {
          "label": "Viterbo - Caldas",
          "value": "Viterbo - Caldas"
        },
        {
          "label": "Albania - Caquetá",
          "value": "Albania - Caquetá"
        },
        {
          "label": "Belén Andaquies - Caquetá",
          "value": "Belén Andaquies - Caquetá"
        },
        {
          "label": "Cartagena del Chaira - Caquetá",
          "value": "Cartagena del Chaira - Caquetá"
        },
        {
          "label": "Curillo - Caquetá",
          "value": "Curillo - Caquetá"
        },
        {
          "label": "El Doncello - Caquetá",
          "value": "El Doncello - Caquetá"
        },
        {
          "label": "El Paujil - Caquetá",
          "value": "El Paujil - Caquetá"
        },
        {
          "label": "Florencia - Caquetá",
          "value": "Florencia - Caquetá"
        },
        {
          "label": "La Montañita - Caquetá",
          "value": "La Montañita - Caquetá"
        },
        {
          "label": "Milán - Caquetá",
          "value": "Milán - Caquetá"
        },
        {
          "label": "Morelia - Caquetá",
          "value": "Morelia - Caquetá"
        },
        {
          "label": "Puerto Rico - Caquetá",
          "value": "Puerto Rico - Caquetá"
        },
        {
          "label": "San  Vicente del Caguan - Caquetá",
          "value": "San  Vicente del Caguan - Caquetá"
        },
        {
          "label": "San José de Fragua - Caquetá",
          "value": "San José de Fragua - Caquetá"
        },
        {
          "label": "Solano - Caquetá",
          "value": "Solano - Caquetá"
        },
        {
          "label": "Solita - Caquetá",
          "value": "Solita - Caquetá"
        },
        {
          "label": "Valparaíso - Caquetá",
          "value": "Valparaíso - Caquetá"
        },
        {
          "label": "Aguazul - Casanare",
          "value": "Aguazul - Casanare"
        },
        {
          "label": "Chameza - Casanare",
          "value": "Chameza - Casanare"
        },
        {
          "label": "Hato Corozal - Casanare",
          "value": "Hato Corozal - Casanare"
        },
        {
          "label": "La Salina - Casanare",
          "value": "La Salina - Casanare"
        },
        {
          "label": "Maní - Casanare",
          "value": "Maní - Casanare"
        },
        {
          "label": "Monterrey - Casanare",
          "value": "Monterrey - Casanare"
        },
        {
          "label": "Nunchia - Casanare",
          "value": "Nunchia - Casanare"
        },
        {
          "label": "Orocue - Casanare",
          "value": "Orocue - Casanare"
        },
        {
          "label": "Paz de Ariporo - Casanare",
          "value": "Paz de Ariporo - Casanare"
        },
        {
          "label": "Pore - Casanare",
          "value": "Pore - Casanare"
        },
        {
          "label": "Recetor - Casanare",
          "value": "Recetor - Casanare"
        },
        {
          "label": "Sabana Larga - Casanare",
          "value": "Sabana Larga - Casanare"
        },
        {
          "label": "Sacama - Casanare",
          "value": "Sacama - Casanare"
        },
        {
          "label": "San Luis de Palenque - Casanare",
          "value": "San Luis de Palenque - Casanare"
        },
        {
          "label": "Tamara - Casanare",
          "value": "Tamara - Casanare"
        },
        {
          "label": "Tauramena - Casanare",
          "value": "Tauramena - Casanare"
        },
        {
          "label": "Trinidad - Casanare",
          "value": "Trinidad - Casanare"
        },
        {
          "label": "Villanueva - Casanare",
          "value": "Villanueva - Casanare"
        },
        {
          "label": "Yopal - Casanare",
          "value": "Yopal - Casanare"
        },
        {
          "label": "Almaguer - Cauca",
          "value": "Almaguer - Cauca"
        },
        {
          "label": "Argelia - Cauca",
          "value": "Argelia - Cauca"
        },
        {
          "label": "Balboa - Cauca",
          "value": "Balboa - Cauca"
        },
        {
          "label": "Bolívar - Cauca",
          "value": "Bolívar - Cauca"
        },
        {
          "label": "Buenos Aires - Cauca",
          "value": "Buenos Aires - Cauca"
        },
        {
          "label": "Cajibio - Cauca",
          "value": "Cajibio - Cauca"
        },
        {
          "label": "Caldono - Cauca",
          "value": "Caldono - Cauca"
        },
        {
          "label": "Caloto - Cauca",
          "value": "Caloto - Cauca"
        },
        {
          "label": "Corinto - Cauca",
          "value": "Corinto - Cauca"
        },
        {
          "label": "El Tambo - Cauca",
          "value": "El Tambo - Cauca"
        },
        {
          "label": "Florencia - Cauca",
          "value": "Florencia - Cauca"
        },
        {
          "label": "Guachené - Cauca",
          "value": "Guachené - Cauca"
        },
        {
          "label": "Guapi - Cauca",
          "value": "Guapi - Cauca"
        },
        {
          "label": "Inza - Cauca",
          "value": "Inza - Cauca"
        },
        {
          "label": "Jambaló - Cauca",
          "value": "Jambaló - Cauca"
        },
        {
          "label": "La Sierra - Cauca",
          "value": "La Sierra - Cauca"
        },
        {
          "label": "La Vega - Cauca",
          "value": "La Vega - Cauca"
        },
        {
          "label": "López - Cauca",
          "value": "López - Cauca"
        },
        {
          "label": "Mercaderes - Cauca",
          "value": "Mercaderes - Cauca"
        },
        {
          "label": "Miranda - Cauca",
          "value": "Miranda - Cauca"
        },
        {
          "label": "Morales - Cauca",
          "value": "Morales - Cauca"
        },
        {
          "label": "Padilla - Cauca",
          "value": "Padilla - Cauca"
        },
        {
          "label": "Páez - Cauca",
          "value": "Páez - Cauca"
        },
        {
          "label": "Patia (el Bordo) - Cauca",
          "value": "Patia (el Bordo) - Cauca"
        },
        {
          "label": "Piamonte - Cauca",
          "value": "Piamonte - Cauca"
        },
        {
          "label": "Piendamo - Cauca",
          "value": "Piendamo - Cauca"
        },
        {
          "label": "Popayán - Cauca",
          "value": "Popayán - Cauca"
        },
        {
          "label": "Puerto Tejada - Cauca",
          "value": "Puerto Tejada - Cauca"
        },
        {
          "label": "Purace - Cauca",
          "value": "Purace - Cauca"
        },
        {
          "label": "Rosas - Cauca",
          "value": "Rosas - Cauca"
        },
        {
          "label": "San Sebastián - Cauca",
          "value": "San Sebastián - Cauca"
        },
        {
          "label": "Santa Rosa - Cauca",
          "value": "Santa Rosa - Cauca"
        },
        {
          "label": "Santander de Quilichao - Cauca",
          "value": "Santander de Quilichao - Cauca"
        },
        {
          "label": "Silvia - Cauca",
          "value": "Silvia - Cauca"
        },
        {
          "label": "Sotara - Cauca",
          "value": "Sotara - Cauca"
        },
        {
          "label": "Suárez - Cauca",
          "value": "Suárez - Cauca"
        },
        {
          "label": "Sucre - Cauca",
          "value": "Sucre - Cauca"
        },
        {
          "label": "Timbío - Cauca",
          "value": "Timbío - Cauca"
        },
        {
          "label": "Timbiquí - Cauca",
          "value": "Timbiquí - Cauca"
        },
        {
          "label": "Toribio - Cauca",
          "value": "Toribio - Cauca"
        },
        {
          "label": "Totoro - Cauca",
          "value": "Totoro - Cauca"
        },
        {
          "label": "Villa Rica - Cauca",
          "value": "Villa Rica - Cauca"
        },
        {
          "label": "Aguachica - Cesar",
          "value": "Aguachica - Cesar"
        },
        {
          "label": "Agustín Codazzi - Cesar",
          "value": "Agustín Codazzi - Cesar"
        },
        {
          "label": "Astrea - Cesar",
          "value": "Astrea - Cesar"
        },
        {
          "label": "Becerril - Cesar",
          "value": "Becerril - Cesar"
        },
        {
          "label": "Bosconia - Cesar",
          "value": "Bosconia - Cesar"
        },
        {
          "label": "Chimichagua - Cesar",
          "value": "Chimichagua - Cesar"
        },
        {
          "label": "Chiriguaná - Cesar",
          "value": "Chiriguaná - Cesar"
        },
        {
          "label": "Curumaní - Cesar",
          "value": "Curumaní - Cesar"
        },
        {
          "label": "El Copey - Cesar",
          "value": "El Copey - Cesar"
        },
        {
          "label": "El Paso - Cesar",
          "value": "El Paso - Cesar"
        },
        {
          "label": "Gamarra - Cesar",
          "value": "Gamarra - Cesar"
        },
        {
          "label": "González - Cesar",
          "value": "González - Cesar"
        },
        {
          "label": "La Gloria - Cesar",
          "value": "La Gloria - Cesar"
        },
        {
          "label": "La Jagua Ibirico - Cesar",
          "value": "La Jagua Ibirico - Cesar"
        },
        {
          "label": "Manaure Balcón del Cesar - Cesar",
          "value": "Manaure Balcón del Cesar - Cesar"
        },
        {
          "label": "Pailitas - Cesar",
          "value": "Pailitas - Cesar"
        },
        {
          "label": "Pelaya - Cesar",
          "value": "Pelaya - Cesar"
        },
        {
          "label": "Pueblo Bello - Cesar",
          "value": "Pueblo Bello - Cesar"
        },
        {
          "label": "Río de Oro - Cesar",
          "value": "Río de Oro - Cesar"
        },
        {
          "label": "Robles (la Paz) - Cesar",
          "value": "Robles (la Paz) - Cesar"
        },
        {
          "label": "San Alberto - Cesar",
          "value": "San Alberto - Cesar"
        },
        {
          "label": "San Diego - Cesar",
          "value": "San Diego - Cesar"
        },
        {
          "label": "San Martín - Cesar",
          "value": "San Martín - Cesar"
        },
        {
          "label": "Tamalameque - Cesar",
          "value": "Tamalameque - Cesar"
        },
        {
          "label": "Valledupar - Cesar",
          "value": "Valledupar - Cesar"
        },
        {
          "label": "Acandi - Chocó",
          "value": "Acandi - Chocó"
        },
        {
          "label": "Alto Baudo (pie de Pato) - Chocó",
          "value": "Alto Baudo (pie de Pato) - Chocó"
        },
        {
          "label": "Atrato - Chocó",
          "value": "Atrato - Chocó"
        },
        {
          "label": "Bagado - Chocó",
          "value": "Bagado - Chocó"
        },
        {
          "label": "Bahia Solano (mutis) - Chocó",
          "value": "Bahia Solano (mutis) - Chocó"
        },
        {
          "label": "Bajo Baudo (pizarro) - Chocó",
          "value": "Bajo Baudo (pizarro) - Chocó"
        },
        {
          "label": "Bojaya (bellavista) - Chocó",
          "value": "Bojaya (bellavista) - Chocó"
        },
        {
          "label": "Canton de San Pablo - Chocó",
          "value": "Canton de San Pablo - Chocó"
        },
        {
          "label": "Carmen del Darien - Chocó",
          "value": "Carmen del Darien - Chocó"
        },
        {
          "label": "Certegui - Chocó",
          "value": "Certegui - Chocó"
        },
        {
          "label": "Condoto - Chocó",
          "value": "Condoto - Chocó"
        },
        {
          "label": "El Carmen - Chocó",
          "value": "El Carmen - Chocó"
        },
        {
          "label": "El Carmen de Atrato - Chocó",
          "value": "El Carmen de Atrato - Chocó"
        },
        {
          "label": "Istmina - Chocó",
          "value": "Istmina - Chocó"
        },
        {
          "label": "Jurado - Chocó",
          "value": "Jurado - Chocó"
        },
        {
          "label": "Litoral del San Juan - Chocó",
          "value": "Litoral del San Juan - Chocó"
        },
        {
          "label": "Lloro - Chocó",
          "value": "Lloro - Chocó"
        },
        {
          "label": "Medio Atrato - Chocó",
          "value": "Medio Atrato - Chocó"
        },
        {
          "label": "Medio Baudo (boca de Pepe) - Chocó",
          "value": "Medio Baudo (boca de Pepe) - Chocó"
        },
        {
          "label": "Medio San Juan - Chocó",
          "value": "Medio San Juan - Chocó"
        },
        {
          "label": "Novita - Chocó",
          "value": "Novita - Chocó"
        },
        {
          "label": "Nuqui - Chocó",
          "value": "Nuqui - Chocó"
        },
        {
          "label": "Quibdó - Chocó",
          "value": "Quibdo - Chocó"
        },
        {
          "label": "Rio Iro - Chocó",
          "value": "Rio Iro - Chocó"
        },
        {
          "label": "Rio Quito - Chocó",
          "value": "Rio Quito - Chocó"
        },
        {
          "label": "Riosucio - Chocó",
          "value": "Riosucio - Chocó"
        },
        {
          "label": "San Jose del Palmar - Chocó",
          "value": "San Jose del Palmar - Chocó"
        },
        {
          "label": "Sipi - Chocó",
          "value": "Sipi - Chocó"
        },
        {
          "label": "Tado - Chocó",
          "value": "Tado - Chocó"
        },
        {
          "label": "Unguia - Chocó",
          "value": "Unguia - Chocó"
        },
        {
          "label": "Unión Panamericana - Chocó",
          "value": "Unión Panamericana - Chocó"
        },
        {
          "label": "Ayapel - Córdoba",
          "value": "Ayapel - Córdoba"
        },
        {
          "label": "Buenavista - Córdoba",
          "value": "Buenavista - Córdoba"
        },
        {
          "label": "Canalete - Córdoba",
          "value": "Canalete - Córdoba"
        },
        {
          "label": "Cereté - Córdoba",
          "value": "Cereté - Córdoba"
        },
        {
          "label": "Chima - Córdoba",
          "value": "Chima - Córdoba"
        },
        {
          "label": "Chinú - Córdoba",
          "value": "Chinú - Córdoba"
        },
        {
          "label": "Cienaga de Oro - Córdoba",
          "value": "Cienaga de Oro - Córdoba"
        },
        {
          "label": "Cotorra - Córdoba",
          "value": "Cotorra - Córdoba"
        },
        {
          "label": "La Apartada - Córdoba",
          "value": "La Apartada - Córdoba"
        },
        {
          "label": "Lorica - Córdoba",
          "value": "Lorica - Córdoba"
        },
        {
          "label": "Los Córdobas - Córdoba",
          "value": "Los Córdobas - Córdoba"
        },
        {
          "label": "Momil - Córdoba",
          "value": "Momil - Córdoba"
        },
        {
          "label": "Moñitos - Córdoba",
          "value": "Moñitos - Córdoba"
        },
        {
          "label": "Montelíbano - Córdoba",
          "value": "Montelíbano - Córdoba"
        },
        {
          "label": "Montería - Córdoba",
          "value": "Montería - Córdoba"
        },
        {
          "label": "Planeta Rica - Córdoba",
          "value": "Planeta Rica - Córdoba"
        },
        {
          "label": "Pueblo Nuevo - Córdoba",
          "value": "Pueblo Nuevo - Córdoba"
        },
        {
          "label": "Puerto Escondido - Córdoba",
          "value": "Puerto Escondido - Córdoba"
        },
        {
          "label": "Puerto Libertador - Córdoba",
          "value": "Puerto Libertador - Córdoba"
        },
        {
          "label": "Purísima - Córdoba",
          "value": "Purísima - Córdoba"
        },
        {
          "label": "Sahagún - Córdoba",
          "value": "Sahagún - Córdoba"
        },
        {
          "label": "San Andrés Sotavento - Córdoba",
          "value": "San Andrés Sotavento - Córdoba"
        },
        {
          "label": "San Antero - Córdoba",
          "value": "San Antero - Córdoba"
        },
        {
          "label": "San Bernardo Viento - Córdoba",
          "value": "San Bernardo Viento - Córdoba"
        },
        {
          "label": "San Carlos - Córdoba",
          "value": "San Carlos - Córdoba"
        },
        {
          "label": "San Pelayo - Córdoba",
          "value": "San Pelayo - Córdoba"
        },
        {
          "label": "San José de Uré - Córdoba",
          "value": "San José de Uré - Córdoba"
        },
        {
          "label": "Tierralta - Córdoba",
          "value": "Tierralta - Córdoba"
        },
        {
          "label": "Tuchín - Córdoba",
          "value": "Tuchín - Córdoba"
        },
        {
          "label": "Valencia - Córdoba",
          "value": "Valencia - Córdoba"
        },
        {
          "label": "Agua de Dios - Cundinamarca",
          "value": "Agua de Dios - Cundinamarca"
        },
        {
          "label": "Alban - Cundinamarca",
          "value": "Alban - Cundinamarca"
        },
        {
          "label": "Anapoima - Cundinamarca",
          "value": "Anapoima - Cundinamarca"
        },
        {
          "label": "Anolaima - Cundinamarca",
          "value": "Anolaima - Cundinamarca"
        },
        {
          "label": "Arbelaez - Cundinamarca",
          "value": "Arbelaez - Cundinamarca"
        },
        {
          "label": "Beltrán - Cundinamarca",
          "value": "Beltrán - Cundinamarca"
        },
        {
          "label": "Bituima - Cundinamarca",
          "value": "Bituima - Cundinamarca"
        },
        {
          "label": "Bogotá - Cundinamarca",
          "value": "Bogotá - Cundinamarca"
        },
        {
          "label": "Bojacá - Cundinamarca",
          "value": "Bojacá - Cundinamarca"
        },
        {
          "label": "Cabrera - Cundinamarca",
          "value": "Cabrera - Cundinamarca"
        },
        {
          "label": "Cachipay - Cundinamarca",
          "value": "Cachipay - Cundinamarca"
        },
        {
          "label": "Cajicá - Cundinamarca",
          "value": "Cajicá - Cundinamarca"
        },
        {
          "label": "Caparrapí - Cundinamarca",
          "value": "Caparrapí - Cundinamarca"
        },
        {
          "label": "Caqueza - Cundinamarca",
          "value": "Caqueza - Cundinamarca"
        },
        {
          "label": "Carmen de Carupa - Cundinamarca",
          "value": "Carmen de Carupa - Cundinamarca"
        },
        {
          "label": "Chaguaní - Cundinamarca",
          "value": "Chaguaní - Cundinamarca"
        },
        {
          "label": "Chia - Cundinamarca",
          "value": "Chia - Cundinamarca"
        },
        {
          "label": "Chipaque - Cundinamarca",
          "value": "Chipaque - Cundinamarca"
        },
        {
          "label": "Choachí - Cundinamarca",
          "value": "Choachí - Cundinamarca"
        },
        {
          "label": "Chocontá - Cundinamarca",
          "value": "Chocontá - Cundinamarca"
        },
        {
          "label": "Cogua - Cundinamarca",
          "value": "Cogua - Cundinamarca"
        },
        {
          "label": "Cota - Cundinamarca",
          "value": "Cota - Cundinamarca"
        },
        {
          "label": "Cucunubá - Cundinamarca",
          "value": "Cucunubá - Cundinamarca"
        },
        {
          "label": "El Colegio - Cundinamarca",
          "value": "El Colegio - Cundinamarca"
        },
        {
          "label": "El Peñón - Cundinamarca",
          "value": "El Peñón - Cundinamarca"
        },
        {
          "label": "El Rosal1 - Cundinamarca",
          "value": "El Rosal1 - Cundinamarca"
        },
        {
          "label": "Facatativa - Cundinamarca",
          "value": "Facatativa - Cundinamarca"
        },
        {
          "label": "Fómeque - Cundinamarca",
          "value": "Fómeque - Cundinamarca"
        },
        {
          "label": "Fosca - Cundinamarca",
          "value": "Fosca - Cundinamarca"
        },
        {
          "label": "Funza - Cundinamarca",
          "value": "Funza - Cundinamarca"
        },
        {
          "label": "Fúquene - Cundinamarca",
          "value": "Fúquene - Cundinamarca"
        },
        {
          "label": "Fusagasuga - Cundinamarca",
          "value": "Fusagasuga - Cundinamarca"
        },
        {
          "label": "Gachalá - Cundinamarca",
          "value": "Gachalá - Cundinamarca"
        },
        {
          "label": "Gachancipá - Cundinamarca",
          "value": "Gachancipá - Cundinamarca"
        },
        {
          "label": "Gacheta - Cundinamarca",
          "value": "Gacheta - Cundinamarca"
        },
        {
          "label": "Gama - Cundinamarca",
          "value": "Gama - Cundinamarca"
        },
        {
          "label": "Girardot - Cundinamarca",
          "value": "Girardot - Cundinamarca"
        },
        {
          "label": "Granada2 - Cundinamarca",
          "value": "Granada2 - Cundinamarca"
        },
        {
          "label": "Guachetá - Cundinamarca",
          "value": "Guachetá - Cundinamarca"
        },
        {
          "label": "Guaduas - Cundinamarca",
          "value": "Guaduas - Cundinamarca"
        },
        {
          "label": "Guasca - Cundinamarca",
          "value": "Guasca - Cundinamarca"
        },
        {
          "label": "Guataquí - Cundinamarca",
          "value": "Guataquí - Cundinamarca"
        },
        {
          "label": "Guatavita - Cundinamarca",
          "value": "Guatavita - Cundinamarca"
        },
        {
          "label": "Guayabal de Siquima - Cundinamarca",
          "value": "Guayabal de Siquima - Cundinamarca"
        },
        {
          "label": "Guayabetal - Cundinamarca",
          "value": "Guayabetal - Cundinamarca"
        },
        {
          "label": "Gutiérrez - Cundinamarca",
          "value": "Gutiérrez - Cundinamarca"
        },
        {
          "label": "Jerusalén - Cundinamarca",
          "value": "Jerusalén - Cundinamarca"
        },
        {
          "label": "Junín - Cundinamarca",
          "value": "Junín - Cundinamarca"
        },
        {
          "label": "La Calera - Cundinamarca",
          "value": "La Calera - Cundinamarca"
        },
        {
          "label": "La cabaña - Cundinamarca",
          "value": "La cabaña - Cundinamarca"
        },
        {
          "label": "La Mesa - Cundinamarca",
          "value": "La Mesa - Cundinamarca"
        },
        {
          "label": "La Palma - Cundinamarca",
          "value": "La Palma - Cundinamarca"
        },
        {
          "label": "La Peña - Cundinamarca",
          "value": "La Peña - Cundinamarca"
        },
        {
          "label": "La Vega - Cundinamarca",
          "value": "La Vega - Cundinamarca"
        },
        {
          "label": "Lenguazaque - Cundinamarca",
          "value": "Lenguazaque - Cundinamarca"
        },
        {
          "label": "Machetá - Cundinamarca",
          "value": "Machetá - Cundinamarca"
        },
        {
          "label": "Madrid - Cundinamarca",
          "value": "Madrid - Cundinamarca"
        },
        {
          "label": "Manta - Cundinamarca",
          "value": "Manta - Cundinamarca"
        },
        {
          "label": "Medina - Cundinamarca",
          "value": "Medina - Cundinamarca"
        },
        {
          "label": "Mosquera - Cundinamarca",
          "value": "Mosquera - Cundinamarca"
        },
        {
          "label": "Nariño - Cundinamarca",
          "value": "Nariño - Cundinamarca"
        },
        {
          "label": "Nemocón - Cundinamarca",
          "value": "Nemocón - Cundinamarca"
        },
        {
          "label": "Nilo - Cundinamarca",
          "value": "Nilo - Cundinamarca"
        },
        {
          "label": "Nimaima - Cundinamarca",
          "value": "Nimaima - Cundinamarca"
        },
        {
          "label": "Nocaima - Cundinamarca",
          "value": "Nocaima - Cundinamarca"
        },
        {
          "label": "Ospina Pérez - Cundinamarca",
          "value": "Ospina Pérez - Cundinamarca"
        },
        {
          "label": "Pacho - Cundinamarca",
          "value": "Pacho - Cundinamarca"
        },
        {
          "label": "Paime - Cundinamarca",
          "value": "Paime - Cundinamarca"
        },
        {
          "label": "Pandi - Cundinamarca",
          "value": "Pandi - Cundinamarca"
        },
        {
          "label": "Paratebueno - Cundinamarca",
          "value": "Paratebueno - Cundinamarca"
        },
        {
          "label": "Pasca - Cundinamarca",
          "value": "Pasca - Cundinamarca"
        },
        {
          "label": "Puerto Salgar - Cundinamarca",
          "value": "Puerto Salgar - Cundinamarca"
        },
        {
          "label": "Pulí - Cundinamarca",
          "value": "Pulí - Cundinamarca"
        },
        {
          "label": "Quebradanegra - Cundinamarca",
          "value": "Quebradanegra - Cundinamarca"
        },
        {
          "label": "Quetame - Cundinamarca",
          "value": "Quetame - Cundinamarca"
        },
        {
          "label": "Quipile - Cundinamarca",
          "value": "Quipile - Cundinamarca"
        },
        {
          "label": "Rafael Reyes - Cundinamarca",
          "value": "Rafael Reyes - Cundinamarca"
        },
        {
          "label": "Ricaurte - Cundinamarca",
          "value": "Ricaurte - Cundinamarca"
        },
        {
          "label": "San  Antonio del  Tequendama - Cundinamarca",
          "value": "San  Antonio del  Tequendama - Cundinamarca"
        },
        {
          "label": "San Bernardo - Cundinamarca",
          "value": "San Bernardo - Cundinamarca"
        },
        {
          "label": "San Cayetano - Cundinamarca",
          "value": "San Cayetano - Cundinamarca"
        },
        {
          "label": "San Francisco - Cundinamarca",
          "value": "San Francisco - Cundinamarca"
        },
        {
          "label": "San Juan de Rioseco - Cundinamarca",
          "value": "San Juan de Rioseco - Cundinamarca"
        },
        {
          "label": "Sasaima - Cundinamarca",
          "value": "Sasaima - Cundinamarca"
        },
        {
          "label": "Sesquilé - Cundinamarca",
          "value": "Sesquilé - Cundinamarca"
        },
        {
          "label": "Sibaté - Cundinamarca",
          "value": "Sibaté - Cundinamarca"
        },
        {
          "label": "Silvania - Cundinamarca",
          "value": "Silvania - Cundinamarca"
        },
        {
          "label": "Simijaca - Cundinamarca",
          "value": "Simijaca - Cundinamarca"
        },
        {
          "label": "Soacha - Cundinamarca",
          "value": "Soacha - Cundinamarca"
        },
        {
          "label": "Sopo - Cundinamarca",
          "value": "Sopo - Cundinamarca"
        },
        {
          "label": "Subachoque - Cundinamarca",
          "value": "Subachoque - Cundinamarca"
        },
        {
          "label": "Suesca - Cundinamarca",
          "value": "Suesca - Cundinamarca"
        },
        {
          "label": "Supatá - Cundinamarca",
          "value": "Supatá - Cundinamarca"
        },
        {
          "label": "Susa - Cundinamarca",
          "value": "Susa - Cundinamarca"
        },
        {
          "label": "Sutatausa - Cundinamarca",
          "value": "Sutatausa - Cundinamarca"
        },
        {
          "label": "Tabio - Cundinamarca",
          "value": "Tabio - Cundinamarca"
        },
        {
          "label": "Tausa - Cundinamarca",
          "value": "Tausa - Cundinamarca"
        },
        {
          "label": "Tena - Cundinamarca",
          "value": "Tena - Cundinamarca"
        },
        {
          "label": "Tenjo - Cundinamarca",
          "value": "Tenjo - Cundinamarca"
        },
        {
          "label": "Tibacuy - Cundinamarca",
          "value": "Tibacuy - Cundinamarca"
        },
        {
          "label": "Tibirita - Cundinamarca",
          "value": "Tibirita - Cundinamarca"
        },
        {
          "label": "Tocaima - Cundinamarca",
          "value": "Tocaima - Cundinamarca"
        },
        {
          "label": "Tocancipá - Cundinamarca",
          "value": "Tocancipá - Cundinamarca"
        },
        {
          "label": "Topaipí - Cundinamarca",
          "value": "Topaipí - Cundinamarca"
        },
        {
          "label": "Ubalá - Cundinamarca",
          "value": "Ubalá - Cundinamarca"
        },
        {
          "label": "Ubaque - Cundinamarca",
          "value": "Ubaque - Cundinamarca"
        },
        {
          "label": "Ubaté - Cundinamarca",
          "value": "Ubaté - Cundinamarca"
        },
        {
          "label": "Une - Cundinamarca",
          "value": "Une - Cundinamarca"
        },
        {
          "label": "Utica - Cundinamarca",
          "value": "Utica - Cundinamarca"
        },
        {
          "label": "Vergara - Cundinamarca",
          "value": "Vergara - Cundinamarca"
        },
        {
          "label": "Viani - Cundinamarca",
          "value": "Viani - Cundinamarca"
        },
        {
          "label": "Villa Gomez - Cundinamarca",
          "value": "Villa Gomez - Cundinamarca"
        },
        {
          "label": "Venecia - Cundinamarca",
          "value": "Venecia - Cundinamarca"
        },
        {
          "label": "Villa Pinzón - Cundinamarca",
          "value": "Villa Pinzón - Cundinamarca"
        },
        {
          "label": "Villeta - Cundinamarca",
          "value": "Villeta - Cundinamarca"
        },
        {
          "label": "Viota - Cundinamarca",
          "value": "Viota - Cundinamarca"
        },
        {
          "label": "Yacopí - Cundinamarca",
          "value": "Yacopí - Cundinamarca"
        },
        {
          "label": "Zipacón - Cundinamarca",
          "value": "Zipacón - Cundinamarca"
        },
        {
          "label": "Zipaquirá - Cundinamarca",
          "value": "Zipaquirá - Cundinamarca"
        },
        {
          "label": "Barranco Minas - Guainía",
          "value": "Barranco Minas - Guainía"
        },
        {
          "label": "Cacahual - Guainía",
          "value": "Cacahual - Guainía"
        },
        {
          "label": "Inírida - Guainía",
          "value": "Inírida - Guainía"
        },
        {
          "label": "La Guadalupe - Guainía",
          "value": "La Guadalupe - Guainía"
        },
        {
          "label": "Mapiripana - Guainía",
          "value": "Mapiripana - Guainía"
        },
        {
          "label": "Morichal - Guainía",
          "value": "Morichal - Guainía"
        },
        {
          "label": "Pana Pana - Guainía",
          "value": "Pana Pana - Guainía"
        },
        {
          "label": "Puerto Colombia - Guainía",
          "value": "Puerto Colombia - Guainía"
        },
        {
          "label": "San Felipe - Guainía",
          "value": "San Felipe - Guainía"
        },
        {
          "label": "Calamar - Guaviare",
          "value": "Calamar - Guaviare"
        },
        {
          "label": "El Retorno - Guaviare",
          "value": "El Retorno - Guaviare"
        },
        {
          "label": "Miraflorez - Guaviare",
          "value": "Miraflorez - Guaviare"
        },
        {
          "label": "San José del Guaviare - Guaviare",
          "value": "San José del Guaviare - Guaviare"
        },
        {
          "label": "Acevedo - Huila",
          "value": "Acevedo - Huila"
        },
        {
          "label": "Agrado - Huila",
          "value": "Agrado - Huila"
        },
        {
          "label": "Aipe - Huila",
          "value": "Aipe - Huila"
        },
        {
          "label": "Algeciras - Huila",
          "value": "Algeciras - Huila"
        },
        {
          "label": "Altamira - Huila",
          "value": "Altamira - Huila"
        },
        {
          "label": "Baraya - Huila",
          "value": "Baraya - Huila"
        },
        {
          "label": "Campo Alegre - Huila",
          "value": "Campo Alegre - Huila"
        },
        {
          "label": "Colombia - Huila",
          "value": "Colombia - Huila"
        },
        {
          "label": "Elias - Huila",
          "value": "Elias - Huila"
        },
        {
          "label": "Garzón - Huila",
          "value": "Garzón - Huila"
        },
        {
          "label": "Gigante - Huila",
          "value": "Gigante - Huila"
        },
        {
          "label": "Guadalupe - Huila",
          "value": "Guadalupe - Huila"
        },
        {
          "label": "Hobo - Huila",
          "value": "Hobo - Huila"
        },
        {
          "label": "Iquira - Huila",
          "value": "Iquira - Huila"
        },
        {
          "label": "Isnos - Huila",
          "value": "Isnos - Huila"
        },
        {
          "label": "La Argentina - Huila",
          "value": "La Argentina - Huila"
        },
        {
          "label": "La Plata - Huila",
          "value": "La Plata - Huila"
        },
        {
          "label": "Nataga - Huila",
          "value": "Nataga - Huila"
        },
        {
          "label": "Neiva - Huila",
          "value": "Neiva - Huila"
        },
        {
          "label": "Oporapa - Huila",
          "value": "Oporapa - Huila"
        },
        {
          "label": "Paicol - Huila",
          "value": "Paicol - Huila"
        },
        {
          "label": "Palermo - Huila",
          "value": "Palermo - Huila"
        },
        {
          "label": "Palestina - Huila",
          "value": "Palestina - Huila"
        },
        {
          "label": "Pital - Huila",
          "value": "Pital - Huila"
        },
        {
          "label": "Pitalito - Huila",
          "value": "Pitalito - Huila"
        },
        {
          "label": "Rivera - Huila",
          "value": "Rivera - Huila"
        },
        {
          "label": "Salado Blanco - Huila",
          "value": "Salado Blanco - Huila"
        },
        {
          "label": "San Agustín - Huila",
          "value": "San Agustín - Huila"
        },
        {
          "label": "Santa Maria - Huila",
          "value": "Santa Maria - Huila"
        },
        {
          "label": "Suaza - Huila",
          "value": "Suaza - Huila"
        },
        {
          "label": "Tarqui - Huila",
          "value": "Tarqui - Huila"
        },
        {
          "label": "Tello - Huila",
          "value": "Tello - Huila"
        },
        {
          "label": "Teruel - Huila",
          "value": "Teruel - Huila"
        },
        {
          "label": "Tesalia - Huila",
          "value": "Tesalia - Huila"
        },
        {
          "label": "Timana - Huila",
          "value": "Timana - Huila"
        },
        {
          "label": "Villavieja - Huila",
          "value": "Villavieja - Huila"
        },
        {
          "label": "Yaguara - Huila",
          "value": "Yaguara - Huila"
        },
        {
          "label": "Albania - La guajira",
          "value": "Albania - La guajira"
        },
        {
          "label": "Barrancas - La guajira",
          "value": "Barrancas - La guajira"
        },
        {
          "label": "Dibulla - La guajira",
          "value": "Dibulla - La guajira"
        },
        {
          "label": "Distracción - La guajira",
          "value": "Distracción - La guajira"
        },
        {
          "label": "El Molino - La guajira",
          "value": "El Molino - La guajira"
        },
        {
          "label": "Fonseca - La guajira",
          "value": "Fonseca - La guajira"
        },
        {
          "label": "Hato Nuevo - La guajira",
          "value": "Hato Nuevo - La guajira"
        },
        {
          "label": "La Jagua del Pilar - La guajira",
          "value": "La Jagua del Pilar - La guajira"
        },
        {
          "label": "Maicao - La guajira",
          "value": "Maicao - La guajira"
        },
        {
          "label": "Manaure - La guajira",
          "value": "Manaure - La guajira"
        },
        {
          "label": "Riohacha - La guajira",
          "value": "Riohacha - La guajira"
        },
        {
          "label": "San Juan del Cesar - La guajira",
          "value": "San Juan del Cesar - La guajira"
        },
        {
          "label": "Uribia - La guajira",
          "value": "Uribia - La guajira"
        },
        {
          "label": "Urumita - La guajira",
          "value": "Urumita - La guajira"
        },
        {
          "label": "Villanueva - La guajira",
          "value": "Villanueva - La guajira"
        },
        {
          "label": "Algarrobo - Magdalena",
          "value": "Algarrobo - Magdalena"
        },
        {
          "label": "Aracataca - Magdalena",
          "value": "Aracataca - Magdalena"
        },
        {
          "label": "Ariguani - Magdalena",
          "value": "Ariguani - Magdalena"
        },
        {
          "label": "Cerro San Antonio - Magdalena",
          "value": "Cerro San Antonio - Magdalena"
        },
        {
          "label": "Chivolo - Magdalena",
          "value": "Chivolo - Magdalena"
        },
        {
          "label": "Cienaga - Magdalena",
          "value": "Cienaga - Magdalena"
        },
        {
          "label": "Concordia - Magdalena",
          "value": "Concordia - Magdalena"
        },
        {
          "label": "El Banco - Magdalena",
          "value": "El Banco - Magdalena"
        },
        {
          "label": "El Piñon - Magdalena",
          "value": "El Piñon - Magdalena"
        },
        {
          "label": "El Reten - Magdalena",
          "value": "El Reten - Magdalena"
        },
        {
          "label": "Fundacion - Magdalena",
          "value": "Fundacion - Magdalena"
        },
        {
          "label": "Guamal - Magdalena",
          "value": "Guamal - Magdalena"
        },
        {
          "label": "Nueva Granada - Magdalena",
          "value": "Nueva Granada - Magdalena"
        },
        {
          "label": "Pedraza - Magdalena",
          "value": "Pedraza - Magdalena"
        },
        {
          "label": "Pijiño del Carmen - Magdalena",
          "value": "Pijiño del Carmen - Magdalena"
        },
        {
          "label": "Pivijay - Magdalena",
          "value": "Pivijay - Magdalena"
        },
        {
          "label": "Plato - Magdalena",
          "value": "Plato - Magdalena"
        },
        {
          "label": "Pueblo Viejo - Magdalena",
          "value": "Pueblo Viejo - Magdalena"
        },
        {
          "label": "Remolino - Magdalena",
          "value": "Remolino - Magdalena"
        },
        {
          "label": "Sabanas de San Angel - Magdalena",
          "value": "Sabanas de San Angel - Magdalena"
        },
        {
          "label": "Salamina - Magdalena",
          "value": "Salamina - Magdalena"
        },
        {
          "label": "San Sebastian de Buenavista - Magdalena",
          "value": "San Sebastian de Buenavista - Magdalena"
        },
        {
          "label": "San Zenon - Magdalena",
          "value": "San Zenon - Magdalena"
        },
        {
          "label": "Santa Ana - Magdalena",
          "value": "Santa Ana - Magdalena"
        },
        {
          "label": "Santa Barbara de Pinto - Magdalena",
          "value": "Santa Barbara de Pinto - Magdalena"
        },
        {
          "label": "Santa Marta - Magdalena",
          "value": "Santa Marta - Magdalena"
        },
        {
          "label": "Sitionuevo - Magdalena",
          "value": "Sitionuevo - Magdalena"
        },
        {
          "label": "Tenerife - Magdalena",
          "value": "Tenerife - Magdalena"
        },
        {
          "label": "Zapayan - Magdalena",
          "value": "Zapayan - Magdalena"
        },
        {
          "label": "Zona Bananera - Magdalena",
          "value": "Zona Bananera - Magdalena"
        },
        {
          "label": "Acacias - Meta",
          "value": "Acacias - Meta"
        },
        {
          "label": "Barranca de Upia - Meta",
          "value": "Barranca de Upia - Meta"
        },
        {
          "label": "Cabuyaro - Meta",
          "value": "Cabuyaro - Meta"
        },
        {
          "label": "Castilla La Nueva - Meta",
          "value": "Castilla La Nueva - Meta"
        },
        {
          "label": "Cubarral - Meta",
          "value": "Cubarral - Meta"
        },
        {
          "label": "Cumaral - Meta",
          "value": "Cumaral - Meta"
        },
        {
          "label": "El Calvario - Meta",
          "value": "El Calvario - Meta"
        },
        {
          "label": "El Castillo - Meta",
          "value": "El Castillo - Meta"
        },
        {
          "label": "El Dorado - Meta",
          "value": "El Dorado - Meta"
        },
        {
          "label": "Fuente de Oro - Meta",
          "value": "Fuente de Oro - Meta"
        },
        {
          "label": "Granada - Meta",
          "value": "Granada - Meta"
        },
        {
          "label": "Guamal - Meta",
          "value": "Guamal - Meta"
        },
        {
          "label": "La Macarena - Meta",
          "value": "La Macarena - Meta"
        },
        {
          "label": "La Uribe - Meta",
          "value": "La Uribe - Meta"
        },
        {
          "label": "Lejanías - Meta",
          "value": "Lejanías - Meta"
        },
        {
          "label": "Mapiripán - Meta",
          "value": "Mapiripán - Meta"
        },
        {
          "label": "Mesetas - Meta",
          "value": "Mesetas - Meta"
        },
        {
          "label": "Planadas - Meta",
          "value": "Planadas - Meta"
        },
        {
          "label": "Puerto Concordia - Meta",
          "value": "Puerto Concordia - Meta"
        },
        {
          "label": "Puerto Gaitán - Meta",
          "value": "Puerto Gaitán - Meta"
        },
        {
          "label": "Puerto Lleras - Meta",
          "value": "Puerto Lleras - Meta"
        },
        {
          "label": "Puerto López - Meta",
          "value": "Puerto López - Meta"
        },
        {
          "label": "Puerto Rico - Meta",
          "value": "Puerto Rico - Meta"
        },
        {
          "label": "Restrepo - Meta",
          "value": "Restrepo - Meta"
        },
        {
          "label": "San  Juan de Arama - Meta",
          "value": "San  Juan de Arama - Meta"
        },
        {
          "label": "San Carlos Guaroa - Meta",
          "value": "San Carlos Guaroa - Meta"
        },
        {
          "label": "San Juanito - Meta",
          "value": "San Juanito - Meta"
        },
        {
          "label": "San Martín - Meta",
          "value": "San Martín - Meta"
        },
        {
          "label": "Villavicencio - Meta",
          "value": "Villavicencio - Meta"
        },
        {
          "label": "Vista Hermosa - Meta",
          "value": "Vista Hermosa - Meta"
        },
        {
          "label": "Alban - Nariño",
          "value": "Alban - Nariño"
        },
        {
          "label": "Aldaña - Nariño",
          "value": "Aldaña - Nariño"
        },
        {
          "label": "Ancuya - Nariño",
          "value": "Ancuya - Nariño"
        },
        {
          "label": "Arboleda - Nariño",
          "value": "Arboleda - Nariño"
        },
        {
          "label": "Barbacoas - Nariño",
          "value": "Barbacoas - Nariño"
        },
        {
          "label": "Belen - Nariño",
          "value": "Belen - Nariño"
        },
        {
          "label": "Buesaco - Nariño",
          "value": "Buesaco - Nariño"
        },
        {
          "label": "Chachagui - Nariño",
          "value": "Chachagui - Nariño"
        },
        {
          "label": "Colon (genova) - Nariño",
          "value": "Colon (genova) - Nariño"
        },
        {
          "label": "Consaca - Nariño",
          "value": "Consaca - Nariño"
        },
        {
          "label": "Contadero - Nariño",
          "value": "Contadero - Nariño"
        },
        {
          "label": "Cordoba - Nariño",
          "value": "Cordoba - Nariño"
        },
        {
          "label": "Cuaspud - Nariño",
          "value": "Cuaspud - Nariño"
        },
        {
          "label": "Cumbal - Nariño",
          "value": "Cumbal - Nariño"
        },
        {
          "label": "Cumbitara - Nariño",
          "value": "Cumbitara - Nariño"
        },
        {
          "label": "El Charco - Nariño",
          "value": "El Charco - Nariño"
        },
        {
          "label": "El Peñol - Nariño",
          "value": "El Peñol - Nariño"
        },
        {
          "label": "El Rosario - Nariño",
          "value": "El Rosario - Nariño"
        },
        {
          "label": "El Tablón - Nariño",
          "value": "El Tablón - Nariño"
        },
        {
          "label": "El Tambo - Nariño",
          "value": "El Tambo - Nariño"
        },
        {
          "label": "Funes - Nariño",
          "value": "Funes - Nariño"
        },
        {
          "label": "Guachucal - Nariño",
          "value": "Guachucal - Nariño"
        },
        {
          "label": "Guaitarilla - Nariño",
          "value": "Guaitarilla - Nariño"
        },
        {
          "label": "Gualmatan - Nariño",
          "value": "Gualmatan - Nariño"
        },
        {
          "label": "Iles - Nariño",
          "value": "Iles - Nariño"
        },
        {
          "label": "Imues - Nariño",
          "value": "Imues - Nariño"
        },
        {
          "label": "Ipiales - Nariño",
          "value": "Ipiales - Nariño"
        },
        {
          "label": "La Cruz - Nariño",
          "value": "La Cruz - Nariño"
        },
        {
          "label": "La Florida - Nariño",
          "value": "La Florida - Nariño"
        },
        {
          "label": "La Llanada - Nariño",
          "value": "La Llanada - Nariño"
        },
        {
          "label": "La Tola - Nariño",
          "value": "La Tola - Nariño"
        },
        {
          "label": "La Union - Nariño",
          "value": "La Union - Nariño"
        },
        {
          "label": "Leiva - Nariño",
          "value": "Leiva - Nariño"
        },
        {
          "label": "Linares - Nariño",
          "value": "Linares - Nariño"
        },
        {
          "label": "Los Andes - Nariño",
          "value": "Los Andes - Nariño"
        },
        {
          "label": "Magui - Nariño",
          "value": "Magui - Nariño"
        },
        {
          "label": "Mallama - Nariño",
          "value": "Mallama - Nariño"
        },
        {
          "label": "Mosqueza - Nariño",
          "value": "Mosqueza - Nariño"
        },
        {
          "label": "Nariño - Nariño",
          "value": "Nariño - Nariño"
        },
        {
          "label": "Olaya Herrera - Nariño",
          "value": "Olaya Herrera - Nariño"
        },
        {
          "label": "Ospina - Nariño",
          "value": "Ospina - Nariño"
        },
        {
          "label": "Pasto - Nariño",
          "value": "Pasto - Nariño"
        },
        {
          "label": "Pizarro - Nariño",
          "value": "Pizarro - Nariño"
        },
        {
          "label": "Policarpa - Nariño",
          "value": "Policarpa - Nariño"
        },
        {
          "label": "Potosi - Nariño",
          "value": "Potosi - Nariño"
        },
        {
          "label": "Providencia - Nariño",
          "value": "Providencia - Nariño"
        },
        {
          "label": "Puerres - Nariño",
          "value": "Puerres - Nariño"
        },
        {
          "label": "Pupiales - Nariño",
          "value": "Pupiales - Nariño"
        },
        {
          "label": "Ricaurte - Nariño",
          "value": "Ricaurte - Nariño"
        },
        {
          "label": "Roberto Payan - Nariño",
          "value": "Roberto Payan - Nariño"
        },
        {
          "label": "Salahonda - Nariño",
          "value": "Salahonda - Nariño"
        },
        {
          "label": "Samaniego - Nariño",
          "value": "Samaniego - Nariño"
        },
        {
          "label": "San Bernardo - Nariño",
          "value": "San Bernardo - Nariño"
        },
        {
          "label": "San Lorenzo - Nariño",
          "value": "San Lorenzo - Nariño"
        },
        {
          "label": "San Pablo - Nariño",
          "value": "San Pablo - Nariño"
        },
        {
          "label": "San Pedro de Cartago - Nariño",
          "value": "San Pedro de Cartago - Nariño"
        },
        {
          "label": "Sandona - Nariño",
          "value": "Sandona - Nariño"
        },
        {
          "label": "Santa Barbara - Nariño",
          "value": "Santa Barbara - Nariño"
        },
        {
          "label": "Santacruz - Nariño",
          "value": "Santacruz - Nariño"
        },
        {
          "label": "Sapuyes - Nariño",
          "value": "Sapuyes - Nariño"
        },
        {
          "label": "Taminango - Nariño",
          "value": "Taminango - Nariño"
        },
        {
          "label": "Tangua - Nariño",
          "value": "Tangua - Nariño"
        },
        {
          "label": "Tumaco - Nariño",
          "value": "Tumaco - Nariño"
        },
        {
          "label": "Tuquerres - Nariño",
          "value": "Tuquerres - Nariño"
        },
        {
          "label": "Yacuanquer - Nariño",
          "value": "Yacuanquer - Nariño"
        },
        {
          "label": "Abrego - Norte de Santander",
          "value": "Abrego - Norte de Santander"
        },
        {
          "label": "Arboledas - Norte de Santander",
          "value": "Arboledas - Norte de Santander"
        },
        {
          "label": "Bochalema - Norte de Santander",
          "value": "Bochalema - Norte de Santander"
        },
        {
          "label": "Bucarasica - Norte de Santander",
          "value": "Bucarasica - Norte de Santander"
        },
        {
          "label": "Cáchira - Norte de Santander",
          "value": "Cáchira - Norte de Santander"
        },
        {
          "label": "Cácota - Norte de Santander",
          "value": "Cácota - Norte de Santander"
        },
        {
          "label": "Chinácota - Norte de Santander",
          "value": "Chinácota - Norte de Santander"
        },
        {
          "label": "Chitagá - Norte de Santander",
          "value": "Chitagá - Norte de Santander"
        },
        {
          "label": "Convención - Norte de Santander",
          "value": "Convención - Norte de Santander"
        },
        {
          "label": "Cúcuta - Norte de Santander",
          "value": "Cúcuta - Norte de Santander"
        },
        {
          "label": "Cucutilla - Norte de Santander",
          "value": "Cucutilla - Norte de Santander"
        },
        {
          "label": "Durania - Norte de Santander",
          "value": "Durania - Norte de Santander"
        },
        {
          "label": "El Carmen - Norte de Santander",
          "value": "El Carmen - Norte de Santander"
        },
        {
          "label": "El Tarra - Norte de Santander",
          "value": "El Tarra - Norte de Santander"
        },
        {
          "label": "El Zulia - Norte de Santander",
          "value": "El Zulia - Norte de Santander"
        },
        {
          "label": "Gramalote - Norte de Santander",
          "value": "Gramalote - Norte de Santander"
        },
        {
          "label": "Hacari - Norte de Santander",
          "value": "Hacari - Norte de Santander"
        },
        {
          "label": "Herrán - Norte de Santander",
          "value": "Herrán - Norte de Santander"
        },
        {
          "label": "La Esperanza - Norte de Santander",
          "value": "La Esperanza - Norte de Santander"
        },
        {
          "label": "La Playa - Norte de Santander",
          "value": "La Playa - Norte de Santander"
        },
        {
          "label": "Labateca - Norte de Santander",
          "value": "Labateca - Norte de Santander"
        },
        {
          "label": "Los Patios - Norte de Santander",
          "value": "Los Patios - Norte de Santander"
        },
        {
          "label": "Lourdes - Norte de Santander",
          "value": "Lourdes - Norte de Santander"
        },
        {
          "label": "Mutiscua - Norte de Santander",
          "value": "Mutiscua - Norte de Santander"
        },
        {
          "label": "Ocaña - Norte de Santander",
          "value": "Ocaña - Norte de Santander"
        },
        {
          "label": "Pamplona - Norte de Santander",
          "value": "Pamplona - Norte de Santander"
        },
        {
          "label": "Pamplonita - Norte de Santander",
          "value": "Pamplonita - Norte de Santander"
        },
        {
          "label": "Puerto Santander - Norte de Santander",
          "value": "Puerto Santander - Norte de Santander"
        },
        {
          "label": "Ragonvalia - Norte de Santander",
          "value": "Ragonvalia - Norte de Santander"
        },
        {
          "label": "Salazar - Norte de Santander",
          "value": "Salazar - Norte de Santander"
        },
        {
          "label": "San Calixto - Norte de Santander",
          "value": "San Calixto - Norte de Santander"
        },
        {
          "label": "San Cayetano - Norte de Santander",
          "value": "San Cayetano - Norte de Santander"
        },
        {
          "label": "Santiago - Norte de Santander",
          "value": "Santiago - Norte de Santander"
        },
        {
          "label": "Sardinata - Norte de Santander",
          "value": "Sardinata - Norte de Santander"
        },
        {
          "label": "Silos - Norte de Santander",
          "value": "Silos - Norte de Santander"
        },
        {
          "label": "Teorama - Norte de Santander",
          "value": "Teorama - Norte de Santander"
        },
        {
          "label": "Tibú - Norte de Santander",
          "value": "Tibú - Norte de Santander"
        },
        {
          "label": "Toledo - Norte de Santander",
          "value": "Toledo - Norte de Santander"
        },
        {
          "label": "Villa Caro - Norte de Santander",
          "value": "Villa Caro - Norte de Santander"
        },
        {
          "label": "Villa del Rosario - Norte de Santander",
          "value": "Villa del Rosario - Norte de Santander"
        },
        {
          "label": "Colón - Putumayo",
          "value": "Colón - Putumayo"
        },
        {
          "label": "Mocoa - Putumayo",
          "value": "Mocoa - Putumayo"
        },
        {
          "label": "Orito - Putumayo",
          "value": "Orito - Putumayo"
        },
        {
          "label": "Puerto Asís - Putumayo",
          "value": "Puerto Asís - Putumayo"
        },
        {
          "label": "Puerto Caycedo - Putumayo",
          "value": "Puerto Caycedo - Putumayo"
        },
        {
          "label": "Puerto Guzmán - Putumayo",
          "value": "Puerto Guzmán - Putumayo"
        },
        {
          "label": "Puerto Leguízamo - Putumayo",
          "value": "Puerto Leguízamo - Putumayo"
        },
        {
          "label": "San Francisco - Putumayo",
          "value": "San Francisco - Putumayo"
        },
        {
          "label": "San Miguel - Putumayo",
          "value": "San Miguel - Putumayo"
        },
        {
          "label": "Santiago - Putumayo",
          "value": "Santiago - Putumayo"
        },
        {
          "label": "Sibundoy - Putumayo",
          "value": "Sibundoy - Putumayo"
        },
        {
          "label": "Valle del Guamuez - Putumayo",
          "value": "Valle del Guamuez - Putumayo"
        },
        {
          "label": "Villagarzón - Putumayo",
          "value": "Villagarzón - Putumayo"
        },
        {
          "label": "Armenia - Quindío",
          "value": "Armenia - Quindío"
        },
        {
          "label": "Barcelona - Quindío",
          "value": "Barcelona - Quindío"
        },
        {
          "label": "Buenavista - Quindío",
          "value": "Buenavista - Quindío"
        },
        {
          "label": "Calarcá - Quindío",
          "value": "Calarcá - Quindío"
        },
        {
          "label": "Circasia - Quindío",
          "value": "Circasia - Quindío"
        },
        {
          "label": "Córdoba - Quindío",
          "value": "Córdoba - Quindío"
        },
        {
          "label": "Filandia - Quindío",
          "value": "Filandia - Quindío"
        },
        {
          "label": "Génova - Quindío",
          "value": "Génova - Quindío"
        },
        {
          "label": "La Tebaida - Quindío",
          "value": "La Tebaida - Quindío"
        },
        {
          "label": "Montenegro - Quindío",
          "value": "Montenegro - Quindío"
        },
        {
          "label": "Pijao - Quindío",
          "value": "Pijao - Quindío"
        },
        {
          "label": "Quimbaya - Quindío",
          "value": "Quimbaya - Quindío"
        },
        {
          "label": "Salento - Quindío",
          "value": "Salento - Quindío"
        },
        {
          "label": "Apia - Risaralda",
          "value": "Apia - Risaralda"
        },
        {
          "label": "Balboa - Risaralda",
          "value": "Balboa - Risaralda"
        },
        {
          "label": "Belén de Umbría - Risaralda",
          "value": "Belén de Umbría - Risaralda"
        },
        {
          "label": "Dos Quebradas - Risaralda",
          "value": "Dos Quebradas - Risaralda"
        },
        {
          "label": "Guatica - Risaralda",
          "value": "Guatica - Risaralda"
        },
        {
          "label": "La Celia - Risaralda",
          "value": "La Celia - Risaralda"
        },
        {
          "label": "La Virginia - Risaralda",
          "value": "La Virginia - Risaralda"
        },
        {
          "label": "Marsella - Risaralda",
          "value": "Marsella - Risaralda"
        },
        {
          "label": "Mistrato - Risaralda",
          "value": "Mistrato - Risaralda"
        },
        {
          "label": "Pereira - Risaralda",
          "value": "Pereira - Risaralda"
        },
        {
          "label": "Pueblo Rico - Risaralda",
          "value": "Pueblo Rico - Risaralda"
        },
        {
          "label": "Quinchía - Risaralda",
          "value": "Quinchía - Risaralda"
        },
        {
          "label": "Santa Rosa de Cabal - Risaralda",
          "value": "Santa Rosa de Cabal - Risaralda"
        },
        {
          "label": "Santuario - Risaralda",
          "value": "Santuario - Risaralda"
        },
        {
          "label": "Providencia - San Andrés y Providencia",
          "value": "Providencia - San Andrés y Providencia"
        },
        {
          "label": "San Andres - San Andrés y Providencia",
          "value": "San Andres - San Andrés y Providencia"
        },
        {
          "label": "Santa Catalina - San Andrés y Providencia",
          "value": "Santa Catalina - San Andrés y Providencia"
        },
        {
          "label": "Aguada - Santander",
          "value": "Aguada - Santander"
        },
        {
          "label": "Albania - Santander",
          "value": "Albania - Santander"
        },
        {
          "label": "Aratoca - Santander",
          "value": "Aratoca - Santander"
        },
        {
          "label": "Barbosa - Santander",
          "value": "Barbosa - Santander"
        },
        {
          "label": "Barichara - Santander",
          "value": "Barichara - Santander"
        },
        {
          "label": "Barrancabermeja - Santander",
          "value": "Barrancabermeja - Santander"
        },
        {
          "label": "Betulia - Santander",
          "value": "Betulia - Santander"
        },
        {
          "label": "Bolívar - Santander",
          "value": "Bolívar - Santander"
        },
        {
          "label": "Bucaramanga - Santander",
          "value": "Bucaramanga - Santander"
        },
        {
          "label": "Cabrera - Santander",
          "value": "Cabrera - Santander"
        },
        {
          "label": "California - Santander",
          "value": "California - Santander"
        },
        {
          "label": "Capitanejo - Santander",
          "value": "Capitanejo - Santander"
        },
        {
          "label": "Carcasi - Santander",
          "value": "Carcasi - Santander"
        },
        {
          "label": "Cepita - Santander",
          "value": "Cepita - Santander"
        },
        {
          "label": "Cerrito - Santander",
          "value": "Cerrito - Santander"
        },
        {
          "label": "Charalá - Santander",
          "value": "Charalá - Santander"
        },
        {
          "label": "Charta - Santander",
          "value": "Charta - Santander"
        },
        {
          "label": "Chima - Santander",
          "value": "Chima - Santander"
        },
        {
          "label": "Chipatá - Santander",
          "value": "Chipatá - Santander"
        },
        {
          "label": "Cimitarra - Santander",
          "value": "Cimitarra - Santander"
        },
        {
          "label": "Concepción - Santander",
          "value": "Concepción - Santander"
        },
        {
          "label": "Confines - Santander",
          "value": "Confines - Santander"
        },
        {
          "label": "Contratación - Santander",
          "value": "Contratación - Santander"
        },
        {
          "label": "Coromoro - Santander",
          "value": "Coromoro - Santander"
        },
        {
          "label": "Curití - Santander",
          "value": "Curití - Santander"
        },
        {
          "label": "El Carmen - Santander",
          "value": "El Carmen - Santander"
        },
        {
          "label": "El Guacamayo - Santander",
          "value": "El Guacamayo - Santander"
        },
        {
          "label": "El Peñón - Santander",
          "value": "El Peñón - Santander"
        },
        {
          "label": "El Playón - Santander",
          "value": "El Playón - Santander"
        },
        {
          "label": "Encino - Santander",
          "value": "Encino - Santander"
        },
        {
          "label": "Enciso - Santander",
          "value": "Enciso - Santander"
        },
        {
          "label": "Florián - Santander",
          "value": "Florián - Santander"
        },
        {
          "label": "Floridablanca - Santander",
          "value": "Floridablanca - Santander"
        },
        {
          "label": "Galán - Santander",
          "value": "Galán - Santander"
        },
        {
          "label": "Gambita - Santander",
          "value": "Gambita - Santander"
        },
        {
          "label": "Girón - Santander",
          "value": "Girón - Santander"
        },
        {
          "label": "Guaca - Santander",
          "value": "Guaca - Santander"
        },
        {
          "label": "Guadalupe - Santander",
          "value": "Guadalupe - Santander"
        },
        {
          "label": "Guapota - Santander",
          "value": "Guapota - Santander"
        },
        {
          "label": "Guavatá - Santander",
          "value": "Guavatá - Santander"
        },
        {
          "label": "Guepsa - Santander",
          "value": "Guepsa - Santander"
        },
        {
          "label": "Hato - Santander",
          "value": "Hato - Santander"
        },
        {
          "label": "Jesús Maria - Santander",
          "value": "Jesús Maria - Santander"
        },
        {
          "label": "Jordán - Santander",
          "value": "Jordán - Santander"
        },
        {
          "label": "La Belleza - Santander",
          "value": "La Belleza - Santander"
        },
        {
          "label": "La Paz - Santander",
          "value": "La Paz - Santander"
        },
        {
          "label": "Landazuri - Santander",
          "value": "Landazuri - Santander"
        },
        {
          "label": "Lebrija - Santander",
          "value": "Lebrija - Santander"
        },
        {
          "label": "Los Santos - Santander",
          "value": "Los Santos - Santander"
        },
        {
          "label": "Macaravita - Santander",
          "value": "Macaravita - Santander"
        },
        {
          "label": "Málaga - Santander",
          "value": "Málaga - Santander"
        },
        {
          "label": "Matanza - Santander",
          "value": "Matanza - Santander"
        },
        {
          "label": "Mogotes - Santander",
          "value": "Mogotes - Santander"
        },
        {
          "label": "Molagavita - Santander",
          "value": "Molagavita - Santander"
        },
        {
          "label": "Ocamonte - Santander",
          "value": "Ocamonte - Santander"
        },
        {
          "label": "Oiba - Santander",
          "value": "Oiba - Santander"
        },
        {
          "label": "Onzaga - Santander",
          "value": "Onzaga - Santander"
        },
        {
          "label": "Palmar - Santander",
          "value": "Palmar - Santander"
        },
        {
          "label": "Palmas del Socorro - Santander",
          "value": "Palmas del Socorro - Santander"
        },
        {
          "label": "Páramo - Santander",
          "value": "Páramo - Santander"
        },
        {
          "label": "Piedecuesta - Santander",
          "value": "Piedecuesta - Santander"
        },
        {
          "label": "Pinchote - Santander",
          "value": "Pinchote - Santander"
        },
        {
          "label": "Puente Nacional - Santander",
          "value": "Puente Nacional - Santander"
        },
        {
          "label": "Puerto Parra - Santander",
          "value": "Puerto Parra - Santander"
        },
        {
          "label": "Puerto Wilches - Santander",
          "value": "Puerto Wilches - Santander"
        },
        {
          "label": "Rionegro - Santander",
          "value": "Rionegro - Santander"
        },
        {
          "label": "Sabana de Torres - Santander",
          "value": "Sabana de Torres - Santander"
        },
        {
          "label": "San Andrés - Santander",
          "value": "San Andrés - Santander"
        },
        {
          "label": "San Benito - Santander",
          "value": "San Benito - Santander"
        },
        {
          "label": "San Gil - Santander",
          "value": "San Gil - Santander"
        },
        {
          "label": "San Joaquín - Santander",
          "value": "San Joaquín - Santander"
        },
        {
          "label": "San José de Miranda - Santander",
          "value": "San José de Miranda - Santander"
        },
        {
          "label": "San Miguel - Santander",
          "value": "San Miguel - Santander"
        },
        {
          "label": "San Vicente de Chucurí - Santander",
          "value": "San Vicente de Chucurí - Santander"
        },
        {
          "label": "Santa Bárbara - Santander",
          "value": "Santa Bárbara - Santander"
        },
        {
          "label": "Santa Helena - Santander",
          "value": "Santa Helena - Santander"
        },
        {
          "label": "Simacota - Santander",
          "value": "Simacota - Santander"
        },
        {
          "label": "Socorro - Santander",
          "value": "Socorro - Santander"
        },
        {
          "label": "Suaita - Santander",
          "value": "Suaita - Santander"
        },
        {
          "label": "Sucre - Santander",
          "value": "Sucre - Santander"
        },
        {
          "label": "Surata - Santander",
          "value": "Surata - Santander"
        },
        {
          "label": "Tona - Santander",
          "value": "Tona - Santander"
        },
        {
          "label": "Valle San José - Santander",
          "value": "Valle San José - Santander"
        },
        {
          "label": "Vélez - Santander",
          "value": "Vélez - Santander"
        },
        {
          "label": "Vetas - Santander",
          "value": "Vetas - Santander"
        },
        {
          "label": "Villanueva - Santander",
          "value": "Villanueva - Santander"
        },
        {
          "label": "Zapatoca - Santander",
          "value": "Zapatoca - Santander"
        },
        {
          "label": "Buenavista - Sucre",
          "value": "Buenavista - Sucre"
        },
        {
          "label": "Caimito - Sucre",
          "value": "Caimito - Sucre"
        },
        {
          "label": "Chalán - Sucre",
          "value": "Chalán - Sucre"
        },
        {
          "label": "Coloso - Sucre",
          "value": "Coloso - Sucre"
        },
        {
          "label": "Corozal - Sucre",
          "value": "Corozal - Sucre"
        },
        {
          "label": "Coveñas - Sucre",
          "value": "Coveñas - Sucre"
        },
        {
          "label": "El Roble - Sucre",
          "value": "El Roble - Sucre"
        },
        {
          "label": "Galeras - Sucre",
          "value": "Galeras - Sucre"
        },
        {
          "label": "Guaranda - Sucre",
          "value": "Guaranda - Sucre"
        },
        {
          "label": "La Unión - Sucre",
          "value": "La Unión - Sucre"
        },
        {
          "label": "Los Palmitos - Sucre",
          "value": "Los Palmitos - Sucre"
        },
        {
          "label": "Majagual - Sucre",
          "value": "Majagual - Sucre"
        },
        {
          "label": "Morroa - Sucre",
          "value": "Morroa - Sucre"
        },
        {
          "label": "Ovejas - Sucre",
          "value": "Ovejas - Sucre"
        },
        {
          "label": "Palmito - Sucre",
          "value": "Palmito - Sucre"
        },
        {
          "label": "Sampues - Sucre",
          "value": "Sampues - Sucre"
        },
        {
          "label": "San Benito Abad - Sucre",
          "value": "San Benito Abad - Sucre"
        },
        {
          "label": "San Juan de Betulia - Sucre",
          "value": "San Juan de Betulia - Sucre"
        },
        {
          "label": "San Marcos - Sucre",
          "value": "San Marcos - Sucre"
        },
        {
          "label": "San Onofre - Sucre",
          "value": "San Onofre - Sucre"
        },
        {
          "label": "San Pedro - Sucre",
          "value": "San Pedro - Sucre"
        },
        {
          "label": "Sincé - Sucre",
          "value": "Sincé - Sucre"
        },
        {
          "label": "Sincelejo - Sucre",
          "value": "Sincelejo - Sucre"
        },
        {
          "label": "Sucre - Sucre",
          "value": "Sucre - Sucre"
        },
        {
          "label": "Tolú - Sucre",
          "value": "Tolú - Sucre"
        },
        {
          "label": "Toluviejo - Sucre",
          "value": "Toluviejo - Sucre"
        },
        {
          "label": "Alpujarra - Tolima",
          "value": "Alpujarra - Tolima"
        },
        {
          "label": "Alvarado - Tolima",
          "value": "Alvarado - Tolima"
        },
        {
          "label": "Ambalema - Tolima",
          "value": "Ambalema - Tolima"
        },
        {
          "label": "Anzoategui - Tolima",
          "value": "Anzoategui - Tolima"
        },
        {
          "label": "Armero (guayabal) - Tolima",
          "value": "Armero (guayabal) - Tolima"
        },
        {
          "label": "Ataco - Tolima",
          "value": "Ataco - Tolima"
        },
        {
          "label": "Cajamarca - Tolima",
          "value": "Cajamarca - Tolima"
        },
        {
          "label": "Carmen de Apicalá - Tolima",
          "value": "Carmen de Apicalá - Tolima"
        },
        {
          "label": "Casabianca - Tolima",
          "value": "Casabianca - Tolima"
        },
        {
          "label": "Chaparral - Tolima",
          "value": "Chaparral - Tolima"
        },
        {
          "label": "Chicoral - Tolima",
          "value": "Chicoral - Tolima"
        },
        {
          "label": "Coello - Tolima",
          "value": "Coello - Tolima"
        },
        {
          "label": "Coyaima - Tolima",
          "value": "Coyaima - Tolima"
        },
        {
          "label": "Cunday - Tolima",
          "value": "Cunday - Tolima"
        },
        {
          "label": "Dolores - Tolima",
          "value": "Dolores - Tolima"
        },
        {
          "label": "Espinal - Tolima",
          "value": "Espinal - Tolima"
        },
        {
          "label": "Falán - Tolima",
          "value": "Falán - Tolima"
        },
        {
          "label": "Flandes - Tolima",
          "value": "Flandes - Tolima"
        },
        {
          "label": "Fresno - Tolima",
          "value": "Fresno - Tolima"
        },
        {
          "label": "Guamo - Tolima",
          "value": "Guamo - Tolima"
        },
        {
          "label": "Herveo - Tolima",
          "value": "Herveo - Tolima"
        },
        {
          "label": "Honda - Tolima",
          "value": "Honda - Tolima"
        },
        {
          "label": "Ibagué - Tolima",
          "value": "Ibagué - Tolima"
        },
        {
          "label": "Icononzo - Tolima",
          "value": "Icononzo - Tolima"
        },
        {
          "label": "Lérida - Tolima",
          "value": "Lérida - Tolima"
        },
        {
          "label": "Líbano - Tolima",
          "value": "Líbano - Tolima"
        },
        {
          "label": "Mariquita - Tolima",
          "value": "Mariquita - Tolima"
        },
        {
          "label": "Melgar - Tolima",
          "value": "Melgar - Tolima"
        },
        {
          "label": "Murillo - Tolima",
          "value": "Murillo - Tolima"
        },
        {
          "label": "Natagaima - Tolima",
          "value": "Natagaima - Tolima"
        },
        {
          "label": "Ortega - Tolima",
          "value": "Ortega - Tolima"
        },
        {
          "label": "Palocabildo - Tolima",
          "value": "Palocabildo - Tolima"
        },
        {
          "label": "Piedras - Tolima",
          "value": "Piedras - Tolima"
        },
        {
          "label": "Planadas - Tolima",
          "value": "Planadas - Tolima"
        },
        {
          "label": "Prado - Tolima",
          "value": "Prado - Tolima"
        },
        {
          "label": "Piedras - Tolima",
          "value": "Piedras - Tolima"
        },
        {
          "label": "Purificación - Tolima",
          "value": "Purificación - Tolima"
        },
        {
          "label": "Rioblanco - Tolima",
          "value": "Rioblanco - Tolima"
        },
        {
          "label": "Roncesvalles - Tolima",
          "value": "Roncesvalles - Tolima"
        },
        {
          "label": "Rovira - Tolima",
          "value": "Rovira - Tolima"
        },
        {
          "label": "Saldaña - Tolima",
          "value": "Saldaña - Tolima"
        },
        {
          "label": "San Antonio - Tolima",
          "value": "San Antonio - Tolima"
        },
        {
          "label": "San Luis - Tolima",
          "value": "San Luis - Tolima"
        },
        {
          "label": "Santa Isabel - Tolima",
          "value": "Santa Isabel - Tolima"
        },
        {
          "label": "Suárez - Tolima",
          "value": "Suárez - Tolima"
        },
        {
          "label": "Valle de San Juan - Tolima",
          "value": "Valle de San Juan - Tolima"
        },
        {
          "label": "Venadillo - Tolima",
          "value": "Venadillo - Tolima"
        },
        {
          "label": "Villahermosa - Tolima",
          "value": "Villahermosa - Tolima"
        },
        {
          "label": "Villarrica - Tolima",
          "value": "Villarrica - Tolima"
        },
        {
          "label": "Alcalá - Valle del cauca",
          "value": "Alcalá - Valle del cauca"
        },
        {
          "label": "Andalucía - Valle del cauca",
          "value": "Andalucía - Valle del cauca"
        },
        {
          "label": "Anserma Nuevo - Valle del cauca",
          "value": "Anserma Nuevo - Valle del cauca"
        },
        {
          "label": "Argelia - Valle del cauca",
          "value": "Argelia - Valle del cauca"
        },
        {
          "label": "Bolívar - Valle del cauca",
          "value": "Bolívar - Valle del cauca"
        },
        {
          "label": "Buenaventura - Valle del cauca",
          "value": "Buenaventura - Valle del cauca"
        },
        {
          "label": "Buga - Valle del cauca",
          "value": "Buga - Valle del cauca"
        },
        {
          "label": "Bugalagrande - Valle del cauca",
          "value": "Bugalagrande - Valle del cauca"
        },
        {
          "label": "Caicedonia - Valle del cauca",
          "value": "Caicedonia - Valle del cauca"
        },
        {
          "label": "Cali - Valle del cauca",
          "value": "Cali - Valle del cauca"
        },
        {
          "label": "Calima (darien) - Valle del cauca",
          "value": "Calima (darien) - Valle del cauca"
        },
        {
          "label": "Candelaria - Valle del cauca",
          "value": "Candelaria - Valle del cauca"
        },
        {
          "label": "Cartago - Valle del cauca",
          "value": "Cartago - Valle del cauca"
        },
        {
          "label": "Dagua - Valle del cauca",
          "value": "Dagua - Valle del cauca"
        },
        {
          "label": "El Aguila - Valle del cauca",
          "value": "El Aguila - Valle del cauca"
        },
        {
          "label": "El Cairo - Valle del cauca",
          "value": "El Cairo - Valle del cauca"
        },
        {
          "label": "El Cerrito - Valle del cauca",
          "value": "El Cerrito - Valle del cauca"
        },
        {
          "label": "El Dovio - Valle del cauca",
          "value": "El Dovio - Valle del cauca"
        },
        {
          "label": "Florida - Valle del cauca",
          "value": "Florida - Valle del cauca"
        },
        {
          "label": "Ginebra - Valle del cauca",
          "value": "Ginebra - Valle del cauca"
        },
        {
          "label": "Guacari - Valle del cauca",
          "value": "Guacari - Valle del cauca  "
        },
        {
          "label": "Jamundí - Valle del cauca",
          "value": "Jamundí - Valle del cauca "
        },
        {
          "label": "La Cumbre - Valle del cauca",
          "value": "La Cumbre - Valle del cauca"
        },
        {
          "label": "La Unión - Valle del cauca",
          "value": "La Unión - Valle del cauca"
        },
        {
          "label": "La Victoria - Valle del cauca",
          "value": "La Victoria - Valle del cauca"
        },
        {
          "label": "Obando - Valle del cauca",
          "value": "Obando - Valle del cauca"
        },
        {
          "label": "Palmira - Valle del cauca",
          "value": "Palmira - Valle del cauca"
        },
        {
          "label": "Pradera - Valle del cauca",
          "value": "Pradera - Valle del cauca"
        },
        {
          "label": "Restrepo - Valle del cauca",
          "value": "Restrepo - Valle del cauca"
        },
        {
          "label": "Rio Frío - Valle del cauca",
          "value": "Rio Frío - Valle del cauca"
        },
        {
          "label": "Roldanillo - Valle del cauca",
          "value": "Roldanillo - Valle del cauca"
        },
        {
          "label": "San Pedro - Valle del cauca",
          "value": "San Pedro - Valle del cauca"
        },
        {
          "label": "Sevilla - Valle del cauca",
          "value": "Sevilla - Valle del cauca"
        },
        {
          "label": "Toro - Valle del cauca",
          "value": "Toro - Valle del cauca"
        },
        {
          "label": "Trujillo - Valle del cauca",
          "value": "Trujillo - Valle del cauca"
        },
        {
          "label": "Tulúa - Valle del cauca",
          "value": "Tulúa - Valle del cauca"
        },
        {
          "label": "Ulloa - Valle del cauca",
          "value": "Ulloa - Valle del cauca"
        },
        {
          "label": "Versalles - Valle del cauca",
          "value": "Versalles - Valle del cauca"
        },
        {
          "label": "Vijes - Valle del cauca",
          "value": "Vijes - Valle del cauca"
        },
        {
          "label": "Yotoco - Valle del cauca",
          "value": "Yotoco - Valle del cauca"
        },
        {
          "label": "Yumbo - Valle del cauca",
          "value": "Yumbo - Valle del cauca"
        },
        {
          "label": "Zarzal - Valle del cauca",
          "value": "Zarzal - Valle del cauca"
        },
        {
          "label": "Carurú - Vaupés",
          "value": "Carurú - Vaupés"
        },
        {
          "label": "Mitú - Vaupés",
          "value": "Mitú - Vaupés"
        },
        {
          "label": "Pacoa - Vaupés",
          "value": "Pacoa - Vaupés"
        },
        {
          "label": "Papunaua - Vaupés",
          "value": "Papunaua - Vaupés"
        },
        {
          "label": "Taraira - Vaupés",
          "value": "Taraira - Vaupés"
        },
        {
          "label": "Yavaraté - Vaupés",
          "value": "Yavaraté - Vaupés"
        },
        {
          "label": "Cumaribo - Vichada",
          "value": "Cumaribo - Vichada"
        },
        {
          "label": "La Primavera - Vichada",
          "value": "La Primavera - Vichada"
        },
        {
          "label": "Puerto Carreño - Vichada",
          "value": "Puerto Carreño - Vichada"
        },
        {
          "label": "Santa Rosalia - Vichada",
          "value": "Santa Rosalia - Vichada"
        }
];