import { createContext, useContext, useMemo, useState } from 'react';

const initialState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const JoyrideContext = createContext({
  state: initialState,
  setState: () => undefined,
});
JoyrideContext.displayName = 'JoyrideContext';

export function JoyrideProvider(props) {
  const [state, setState] = useState(initialState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  return (<JoyrideContext.Provider value={value} {...props} />);
}

export function useJoyrideContext() {
  const context = useContext(JoyrideContext);

  if (!context) {
    throw new Error('useJoyrideContext must be used within a JoyrideProvider');
  }

  return context;
}
