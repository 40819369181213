import { validarEntorno } from "../helpers/validarHost";

//state
const dataProfile = {
  response: {},
  solicitudCambio: {
    cambio: null,
    detalle: ''
  }
}

//types
const OBTENER_DATA_PERFIL = 'OBTENER_DATA_PERFIL'
const CAMBIO_PASSWORD = 'CAMBIO_PASSWORD'

//reducer

export default function profileReducer(state = dataProfile, action) {

  switch (action.type) {
    case OBTENER_DATA_PERFIL:
      state = {
        ...state,
        response: action.payload
      }
      return state
    case CAMBIO_PASSWORD:
      state = {
        ...state,
        solicitudCambio: action.payload
      }
      return state
    default:
      return state
  }
}

//actions

export const getDataPerfil = (ruta) => async (dispatch) => {
  try {
    const requestRuta = await validarEntorno('JSON/ejemplo_data_perfil_json.html', 'POST', ruta
      // , {
      //   method: 'POST',
      //   body: JSON.stringify({ruta})
      // }
    )

    if (requestRuta.ok) {
      const res = await requestRuta.json()
      dispatch({
        type: 'OBTENER_DATA_PERFIL',
        payload: res
      })
    }
  } catch (error) {
    console.log(error.message)
  }
}



export const changePassword = (data) => async (dispatch) => {


  dispatch({
    type: 'CAMBIO_PASSWORD',
    payload: data
  });
}

// <!-- {
//   "actual" : false,
//   "cambio" : false,
//   "detalle": "La contraseña actual no coincide."
// } -->



// export const fetchProfileAction = () => {
//   return async (dispatch) => {
//     const requestPerfil = await fetch('/JSON/ejemplo_data_perfil_json.html')
//     // const jsonPerfil = requestPerfil.json()

//     if(requestPerfil.ok){
//       dispatch({
//         type: OBTENER_DATA_PERFIL,
//         payload: await requestPerfil.json()
//       })
//     }
//   }
// }





