import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../../../helpers/validarHost';
import NotificationModal from '../../../../modales/NotificationModal';
import { useTranslation } from 'react-i18next';

const Alcance = ({ dataG, is_edit, permisos }) => {

  const { t } = useTranslation();

  const [data, setData] = useState({
    alcance: dataG ? dataG.definicion : '',
  });

  const [showModalNotification, setShowModalNotification] = useState(false);

  const { id } = useParams();

  const handleOpenModalNotification = () => setShowModalNotification(true);
  const handleCloseModalNotification = () => setShowModalNotification(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  }

  const sendDataForm = async (e) => {

    e.preventDefault();

    const result = {};

    const dataSend = {
      formData: data,
      id_registro: id,
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    // //console.log(dataSend)


    const response = await validarEntorno('JSON/respuesta_cuestionario_json.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();;

      if (respuesta.data.message) {
        setShowModalNotification(true);
      }
    }
  }

  return (
    <div className="container-audit-form">
      <h4 style={{ textAlign: 'start' }} className="pagesTitles">{t('gestion_realizada.introduccion_informe.alcance')}</h4>
      <Form className="audit-form" onSubmit={sendDataForm}>
        <Form.Row>
          <Form.Group as={Col} lg={12} role="form" controlId="formGridTextArea">
            <Form.Control
              className="audit-form-textarea"
              as="textarea"
              name="alcance"
              onChange={handleChange}
              defaultValue={data.alcance}
              disabled={data.is_edit}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row className="btn-form-row">
          {permisos?.editar!==false && !is_edit && <Button className="confirmButton" type="submit">{t('formulario.actions.enviar')}</Button>}
        </Form.Row>
      </Form>
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal='saveQuestions'
      />
    </div>
  )
}

export default Alcance;
