import moment from 'moment'
import 'moment/locale/es-mx'

moment.locale('es-mx')

export const notificationsList = [
  {
    id: '1',
    Emisor: 'Oscar',
    body: 'Este es un tipo de notificación',
    date:  new Date().getTime()
  },
  {
    id: '2',
    Emisor: 'user',
    body: 'informe revisado',
    date:  new Date()
    
  },
  {
    id: '3',
    Emisor: 'Gestion humana',
    body: 'Mostrar notificaciones en tabla',
    date:  new Date()
    
  },
  {
    id: '4',
    Emisor: 'Inteligencia',
    body: 'Estilizar los inputs para que no se desorganicen',
    date:  new Date()
    
  },
  {
    id: '5',
    Emisor: 'Juridica',
    body: 'Replicar menu 3',
    date:  new Date()
    
  },
  {
    id: '6',
    Emisor: 'Oscar',
    body: 'lorem ipsum',
    date:  new Date()
    
  },
  {
    id: '7',
    Emisor: 'Oscar',
    body: 'lorem ipsum',
    date:  new Date()
    
  },
  {
    id: '8',
    Emisor: 'Oscar',
    body: 'Este es un tipo de notificación',
    date:  new Date().getTime()
  },
  {
    id: '9',
    Emisor: 'user',
    body: 'informe revisado',
    date:  new Date()
    
  },
  {
    id: '10',
    Emisor: 'Gestion humana',
    body: 'Mostrar notificaciones en tabla',
    date:  new Date()
    
  },


]