import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import {
  // useHistory,
  useParams
} from 'react-router-dom';
import { EditSVG } from '../icons/EditSVG'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { HelpSVG } from '../../components/icons/HelpSVG';
import PopUpModal from '../../helpers/PopUpModal';


import { useDispatch, useSelector } from 'react-redux'
import { DebounceInput } from 'react-debounce-input';


import '../styles/Filtro.scss'
import { ModalTablaEstudios } from './ModalTablaEstudios'
import { ModalTablaRows } from './ModalTablaRows';
import { validarEntorno } from '../../helpers/validarHost';
import SortedBarChart from '../dashboard/SortedBarChart';
import Dona2 from '../dashboard/Dona2';
import { ReactReduxContext } from 'react-redux';

import Loader from '../icons/Loader'
import Tabla from './Table';
import { useTranslation } from 'react-i18next';

export const TablaEstudios = ({ setEditarEstudio, dataSelected, setDataSelected, variableMostarTabla, leyendas }) => {

  const { t } = useTranslation();
  const { id, variable } = useParams()

  const location = window.location.href

  const [data, setData] = useState({});
  const [open, setOpen] = useState(false)

  // const history = useHistory()

  // const validateDataDate = () => {
  //     const data = localStorage.getItem('data-graphs');
  //     const dataParsed = JSON.parse(data);
  //     const currentDate = moment();
  //     const diffTime = currentDate.diff(dataParsed.date, 'minutes');

  //     const objDiff = {
  //         diff: diffTime,
  //         data: dataParsed.data
  //     }

  //     return objDiff;
  // }

  // const dataDiff = validateDataDate();


  const filtroEstudio = useSelector(state => state.filtroEstudio)
  const { selectBusqueda } = filtroEstudio
  const { grafica1, grafica2 } = selectBusqueda

  const [filtros, setFiltros] = useState(false)
  const [modalTablaCampos, setModalTablaCampos] = useState(false)
  const [dataEnviarEnModal, setDataEnviarEnModal] = useState(false)


  const [openModal, setOpenModal] = useState(false)

  const [tipoModal, setTipoModal] = useState(false)
  const [contenido, setContenido] = useState(true)

  const [dataEliminar, setDataEliminar] = useState(false)


  const [busquedaActiva, setBusquedaActiva] = useState(false)
  const [numeroPaginas, setNumeroPaginas] = useState(10);

  const [showLoader, setShowLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const { store } = useContext(ReactReduxContext);


  const [formNew, setFormNew] = useState({
    tipoSolicitud: '',
    estado: '',
    busqueda: '',
    limite: '',
    tipo: ''
  })


  const dispatch = useDispatch()

  const handleInputChange = ({ target }) => {
    setFormNew({
      ...formNew,
      [target.name]: target.value,
    })

    //console.log(formNew)
  }


  const [palabra, setPalabra] = useState('')

  const [modal, setModal] = useState(false)

  const envioDataFiltro = async () => {

    setData([]);

    setShowTable(true);

    const { numeroRegistros, ...rest } = formNew
    if (rest.tipoSolicitud === '') {
      rest.tipoSolicitud = '0'

    }
    if (rest.estado === '') {
      rest.estado = '0'
    }
    if (rest.tipo && rest.tipo === '') {
      rest.tipo = '0'
    }



    rest.limite = numeroPaginas;

    setFormNew(rest);

    // dispatch(obtenerDataSegunFiltro(rest))

    const dataFinal = {
      ...rest,
      changePage: 1,
      hash: window.location.hash
    }

    const respuesta = await validarEntorno('JSON/ejemploRespuestaTabla.json.html', 'POST', dataFinal)

    setShowTable(true);

    if (respuesta.ok) {
      const res = await respuesta.json();
      setData(res);
    }

    const tipoSolicitud = selectBusqueda.tiposSolicitud.filter(ele => {

      return ele.id === Number(rest.tipoSolicitud) && ele.label
    })

    const estado = selectBusqueda.Estado.filter(ele => {

      return ele.id === Number(rest.estado) && ele.label
    })

    setFiltros({
      tipoSolicitud: tipoSolicitud[0]?.label,
      estado: estado[0]?.label,
      busqueda: rest.busqueda
    })

    setBusquedaActiva(true)
    setShowTableLoader(true)
  }

  const getDataPaginado = async (rest) => {
    setData([]);

    setFormNew(rest);

    const dataFinal = {
      ...rest,
      hash: window.location.hash
    }

    const respuesta = await validarEntorno('JSON/ejemploRespuestaTabla.json.html', 'POST', dataFinal)

    if (respuesta.ok) {
      const res = await respuesta.json();
      setData(res);
    }
  }

  const getDataParametrizado = async () => {
    if (variable) {
      setData([]);

      // setFormNew(rest);

      const dataFinal = {
        nombreWidget: variable,
        hash: window.location.hash
      }

      const respuesta = await validarEntorno('JSON/ejemploRespuestaTabla.json.html', 'POST', dataFinal)

      if (respuesta.ok) {
        const res = await respuesta.json();
        setData(res);
        setShowTable(true);
      }
    }
  }

  const handleOpenAndDataModal = (contenido) => {
    setDataEnviarEnModal(contenido.contenidoModal)
    setModalTablaCampos(true)
  }


  const handleDeleteSolicitud = (data) => {
    // AQUI QUEDE HACER PETICION JSON Y MODAL DE CONFIRMACION DE ELIMINAR.

    setTipoModal('Verificacioneliminarsolicitud')
    setOpenModal(true)
    setDataEliminar(data)


  }

  const confirmacioneliminarsolicitud = async () => {

    if (contenido === true) {

      const respuesta = await validarEntorno('JSON/respuesta_eliminar_solicitud_json.html', 'POST', dataEliminar)
      const body = await respuesta.json()
      if (body.ok) {
        setOpenModal(false)
        setContenido(body)
        setTipoModal('ConfirmacionEliminarSolicitud')
        setOpenModal(true)

      }

      return
    }



  }

  const [loader, setLoader] = useState(false)
  const [showTableLoader, setShowTableLoader] = useState(false)

  const validarLocacion = (url, ele) => {

    //console.log( 'url : ' + url )
    //console.log('ele : ' + ele)

    const valorCadena = url.includes("cosinte")

    // console.log(valorCadena)

    return (
      <>
        {true ?
          <>
            {

              ele?.informes?.gestor_inf?.visualizar
              &&
              <a target="_blank" href={`https://www.ciacosinte.site/#/gerencia/auditorias/panorama_riesgos/informe_${ele.informes.gestor_inf.id}`} style={{ cursor: 'pointer' }}

              >
                <div style={{ marginLeft: 2 }} title={t('tabla_estudios.gestionar_informe')}
                // onClick={() => {
                //     // history.push(`/gerencia/inteligencia/solicitar`)
                //     history.push(`/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`)
                // }}

                >
                  <EditSVG width="24" height="24" fill={ele.informes.gestor_inf.color ? ele.informes.gestor_inf.color : "rgb(33, 50, 101)"} />
                </div>
              </a>
            }
            {

              ele?.informes?.gestionar?.visualizar
              &&
              <a target="_blank" href={`#/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`} style={{ cursor: 'pointer' }}

              >
                <div style={{ marginLeft: 2 }} title={t('tabla_estudios.gestionar_solicitud')}
                // onClick={() => {
                //     // history.push(`/gerencia/inteligencia/solicitar`)
                //     history.push(`/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`)
                // }}

                >
                  <EditSVG width="24" height="24" fill={ele.informes.gestionar.color ? ele.informes.gestionar.color : "rgb(33, 50, 101)"} />
                </div>
              </a>
            }

            {

              ele?.informes?.gestor_dinamico?.visualizar
              &&
              <a target="_blank" href={`./#/gerencia/${data.seccion}/gestor/creada/${ele.informes.gestor_dinamico.id}`} rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                <div style={{ marginLeft: 2 }} title={t('tabla_estudios.gestionar_registro')}
                // onClick={() => {
                //     // history.push(`/gerencia/inteligencia/solicitar`)
                // history.push(`/gerencia/${data.seccion}/gestor/creada/${ele.informes.gestor_dinamico.id}`)
                // }}
                >
                  <EditSVG width="24" height="24" fill={ele.informes.gestor_dinamico.color ? ele.informes.gestor_dinamico.color : "rgb(33, 50, 101)"} />
                </div>
              </a>
            }

          </>
          :
          <>
            {

              ele?.informes?.gestor_inf?.visualizar
              &&
              <a target="_blank" href={`https://localhost:3000/#/gerencia/auditorias/panorama_riesgos/informe_${ele.informes.gestor_inf.id}`} style={{ cursor: 'pointer' }}

              >
                <div style={{ marginLeft: 2 }} title={t('tabla_estudios.gestionar_informe')}
                // onClick={() => {
                //     // history.push(`/gerencia/inteligencia/solicitar`)
                //     history.push(`/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`)
                // }}

                >
                  <EditSVG width="24" height="24" fill={ele.informes.gestor_inf.color ? ele.informes.gestor_inf.color : "rgb(33, 50, 101)"} />
                </div>
              </a>
            }

            {

              ele?.informes?.gestionar?.visualizar
              &&
              <a target="_blank" href={`https://localhost:3000/#/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`} style={{ cursor: 'pointer' }}

              >
                <div style={{ marginLeft: 2 }} title={t('tabla_estudios.gestionar_solicitud')}
                // onClick={() => {
                //     // history.push(`/gerencia/inteligencia/solicitar`)
                //     history.push(`/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`)
                // }}

                >
                  <EditSVG width="24" height="24" fill={ele.informes.gestionar.color ? ele.informes.gestionar.color : "rgb(33, 50, 101)"} />
                </div>
              </a>
            }

            {

              ele?.informes?.gestor_dinamico?.visualizar
              &&
              <a target="_blank" href={`https://localhost:3000/#/gerencia/${data.seccion}/gestor/creada/${ele.informes.gestor_dinamico.id}`} rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                <div style={{ marginLeft: 2 }} title={t('tabla_estudios.gestionar_registro')}
                // onClick={() => {
                //     // history.push(`/gerencia/inteligencia/solicitar`)
                // history.push(`/gerencia/${data.seccion}/gestor/creada/${ele.informes.gestor_dinamico.id}`)
                // }}
                >
                  <EditSVG width="24" height="24" fill={ele.informes.gestor_dinamico.color ? ele.informes.gestor_dinamico.color : "rgb(33, 50, 101)"} />
                </div>
              </a>
            }
            {/* {

                        ele?.informes?.gestionar?.visualizar
                        &&
                        <div style={{ marginLeft: 2, cursor: 'pointer' }} title="Gestionar solicitud"
                            onClick={() => {
                                history.push(`/gerencia/${data.seccion}/solicitar/creada/${ele.informes.gestionar.id}`)
                            }}

                            >
                                <EditSVG width="24" height="24" fill="rgb(33, 50, 101)" />
                        </div>
                        }

                        {

                        ele?.informes?.gestor_dinamico?.visualizar
                        &&
                        <div style={{ marginLeft: 2 }} title="Gestionar registro"
                            onClick={() => {
                                // history.push(`/gerencia/inteligencia/solicitar`)
                            history.push(`/gerencia/${data.seccion}/gestor/creada/${ele.informes.gestor_dinamico.id}`)
                            }}
                        >
                            <EditSVG width="24" height="24" fill="rgb(33, 50, 101)" />
                        </div>
                        } */}
          </>

        }
      </>
    )
  }

  useEffect(() => {
    // validarLocacion()
    getDataParametrizado();
  }, [])


  if (loader === false) {


    return (
      <Fragment>

        <PopUpModal leyendas={leyendas} titulo={selectBusqueda?.titulo} texto={selectBusqueda?.texto} open={open} setOpen={setOpen} />

        {/* Filtros */}
        {selectBusqueda.tiposSolicitud &&
          <Card className="shadow tarjetaSelectsProcesos" style={{ borderRadius: '10px', }}>
            <div className='fondoAzulTarjetaSelects'>

              <div className="helpIcon" style={{ margin: '10px 20px' }} >
                <div className='contenedorIcono' onClick={() => setOpen(true)}>

                  <HelpSVG />

                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className='tituloSelectsProcesos' >{t('tabla_estudios.consulta_procesos')}</p>
                {filtroEstudio.selectBusqueda.numeroSolicitudes > 0 &&
                  <p className='' >
                    {
                      !variableMostarTabla &&
                      <div className='contenedorTotalSolicitudes'>
                        <p className='solicituesTotalGeneral'>
                          {t('tabla_estudios.solicitudes_realizadas')} <div className='totalSolicitudes'> {filtroEstudio.selectBusqueda.numeroSolicitudes} </div>
                        </p>
                      </div>
                    }
                  </p>
                }

              </div>
              <Card.Body className='contenedorFiltrosMain'>
                <div className='contenedorFiltros'>
                  <Form.Group className='filtroItem' >
                    <label style={{ flex: 'none' }} className='form-label'>{t('tabla_estudios.tipo_solicitud')}</label>
                    <Form.Control as='select' defaultValue='0' className="form-control " id="select_tipo" name="tipoSolicitud" onChange={e => {
                      handleInputChange(e)
                    }} >
                      <option value='0' selected disabled>{t('formulario.label.seleccione')}</option>
                      {
                        selectBusqueda.tiposSolicitud.map((ele, i) => {
                          return <option key={i} value={ele.id}>{ele.label}</option>

                        })
                      }

                    </Form.Control>
                  </Form.Group>
                  <Form.Group className='filtroItem' id='estadoFiltro' >
                    <label style={{ flex: 'none' }} className='form-label' >{t('tabla_estudios.estado')}</label>
                    <Form.Control as='select' defaultValue='0' className="form-control " id="select_estado" name="estado" onChange={handleInputChange} >
                      <option value='0' selected disabled>{t('formulario.label.seleccione_estado')}</option>
                      {
                        selectBusqueda.Estado.map((ele, i) => {
                          return <option key={i} value={ele.id}>{ele.label}</option>
                        })
                      }

                    </Form.Control>
                  </Form.Group>
                  {selectBusqueda.tipo && selectBusqueda.tipo.length &&
                    <Form.Group className='filtroItem ' id='estadoFiltro' >
                      <label style={{ flex: 'none' }} className='form-label' >{t('tabla_estudios.tipo_busqueda')}</label>
                      <Form.Control as='select' defaultValue='0' className="form-control " id="select_busqueda" name="tipo_busqueda" onChange={handleInputChange} >
                        {/* <option value='0' selected disabled>--Seleccione el estado--</option> */}
                        {
                          selectBusqueda.tipo.map((ele, i) => {
                            return <option key={i} value={ele.id}>{ele.label}</option>
                          })
                        }

                      </Form.Control>
                    </Form.Group>
                  }
                </div>
                <Form.Group className='filtroItem filtroItemBusqueda' id='buscadorGeneral'>
                  <label style={{ flex: 'none', marginRight: '10px' }} className='form-label' >{t('tabla_estudios.buscador')}</label>
                  <div style={{ display: 'flex' }}>
                    <div id="inputGroup">
                      <DebounceInput value={formNew.busqueda} debounceTimeout={100} id="inputBuscadorGeneral" name="busqueda"
                        // onKeyPress={envioDataFiltro}
                        onKeyDown={e => e.keyCode === 13 && envioDataFiltro()}
                        onChange={e => {
                          handleInputChange(e)
                        }} />
                      <CloseIcon fontSize="small" style={{ cursor: 'pointer', color: "#707070", marginRight: '5px' }} onClick={() => {

                        document.getElementById('select_tipo').value = '0';
                        document.getElementById('select_estado').value = '0';
                        // document.getElementById('select_busqueda').value = '0';



                        setFormNew({ ...formNew, busqueda: '', tipoSolicitud: '', estado: '', tipo: '' })
                      }} />
                    </div>
                    <button
                      className='btn-busqueda'
                      style={{
                        paddingRight: '10px',
                        borderTopRightRadius: '8px !important',
                        borderBottomRightRadius: '8px !important',
                        border: '1px solid #213265',
                        height: '33px',
                        width: '140px',
                        background: '#213265',
                        color: 'white'
                      }}
                      onClick={() => { envioDataFiltro() }}
                    // onKeyPress={envioDataFiltro}
                    >
                      <SearchIcon fontSize="small" />
                      {t('tabla_estudios.buscar')}
                    </button>

                  </div>
                </Form.Group>
              </Card.Body>
            </div>
          </Card>
        }
        <br />

        {(!showTable && !busquedaActiva && !variableMostarTabla) &&
          <Fragment>
            {
              grafica1?.visualizar
              &&
              <SortedBarChart title={grafica1.titulo} data={grafica1.data} total={grafica1.total} />
            }

            <br />

            {
              grafica2?.visualizar
              &&
              <Dona2 id='Nivel de cumplimiento' data={grafica2.data} title={grafica2.titulo} total={grafica2.total} />
            }
          </Fragment>
        }

        {showTable && (
          ((data && Object.keys(data).length > 0) ? (
            <Tabla
              data={data}
              variableMostarTabla={variableMostarTabla}
              filtros={filtros}
              formNew={formNew}
              setFormNew={setFormNew}
              tipoModal={tipoModal}
              openModal={openModal}
              setOpenModal={setOpenModal}
              contenido={contenido}
              setContenido={setContenido}
              confirmacioneliminarsolicitud={confirmacioneliminarsolicitud}
              dataEnviarEnModal={dataEnviarEnModal}
              ModalTablaRows={ModalTablaRows}
              modalTablaCampos={modalTablaCampos}
              setModalTablaCampos={setModalTablaCampos}
              showLoader={showLoader}
              handleOpenAndDataModal={handleOpenAndDataModal}
              validarLocacion={validarLocacion}
              handleDeleteSolicitud={handleDeleteSolicitud}
              setNumeroPaginas={setNumeroPaginas}
              getDataPaginado={getDataPaginado}
              numeroPaginas={numeroPaginas}
            />
          ) : (
            <div className="loaderContainerTable    ">
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          ))
        )}

        {modal && <ModalTablaEstudios setModal={setModal} modal={modal} dataSelected={dataSelected} />}



      </Fragment >
    )

  } else {
    return <Loader />
  }

}
