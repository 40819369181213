import React, {useState, useEffect} from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'

export default function Divergente(props) {


  // Themes begin
  am4core.useTheme(am4themes_animated);

  useEffect(()=>{

   
      let chart = am4core.create(props.id, am4charts.XYChart)




    // Create chart instance

    // Add data
    chart.data = props.data;


    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 5;
    categoryAxis.renderer.axisFills.template.disabled = false;
    categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;


    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = -100;
    valueAxis.max = 100;
    valueAxis.renderer.minGridDistance = 50;
    valueAxis.renderer.ticks.template.length = 17;
    valueAxis.renderer.ticks.template.disabled = false;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
    valueAxis.renderer.labels.template.adapter.add("text", function(text) {
      return text;
    })

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";

    // Use only absolute numbers
    chart.numberFormatter.numberFormat = "#.#s";

    // Create series
    function createSeries(field, name, color) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "category";
      series.stacked = true;
      series.name = name;
      series.stroke = color;
      series.fill = color;
      
      let label = series.bullets.push(new am4charts.LabelBullet());
      label.label.text = "{valueX}";
      label.label.fill = am4core.color("#fff");
      label.label.strokeWidth = 0;
      label.label.truncate = false;
      label.label.hideOversized = true;
      label.locationX = 0.3;
      return series;
    }

    let interfaceColors = new am4core.InterfaceColorSet();
    let positiveColor = '#8BC34A';
    let negativeColor = '#05a4db';

    createSeries("negative1", "Solicitudes", negativeColor);
    createSeries("positive2", "Finalizados",  positiveColor);

    chart.legend.events.on("layoutvalidated", function(event){
      chart.legend.itemContainers.each((container)=>{
        if(container.dataItem.dataContext.name === "Never"){
          container.toBack();
        }
      })
    })

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.align = "left";
    chart.exporting.formatOptions.getKey("svg").disabled = true;




  },[])

  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  return (
    <>
      {props.class 
        ?
        <div className="shadow cardGraficaGeneral cardDivergente ">
          <div
            className="d-flex flex-row-reverse bd-highlight p-1"
            style={{ cursor: 'pointer', top: '20px', right: '20px' }}
            onClick={() => setOpen(true)}
          >
            <HelpSVG className="bd-highlight" />
          </div>
          <Card.Body>
            <h4 className='titleCard' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> Por {props.id} - 23 abril de 2021 </h4>
            <br/>
              <div id={props.tipoContenedor} style={{ width: '100%', minHeight: '600px', margin: '0 auto ', height: 'auto' }}></div>

          </Card.Body>
        <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
      </div>

        :
        
        <Card className="shadow cardGraficaGeneral cardCombinada ">
          <div
            className="d-flex flex-row-reverse bd-highlight p-1"
            style={{ cursor: 'pointer', top: '20px', right: '20px' }}
            onClick={() => setOpen(true)}
          >
            <HelpSVG className="bd-highlight" />
          </div>
          <Card.Body>
            <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}>Por {props.id} - Abril de 2021
            <br/>
            Total: 1180
            </h4>
            
            <br/>
            {!props.tipoContenedor
              ?
              <div id={props.id} style={{ width: '90%', minHeight: '450px', margin: '0 auto ', height: 'auto' }}></div>
              :
              <div id={props.tipoContenedor} style={{ width: '90%', minHeight: '600px', margin: '0 auto ', height: 'auto' }}></div>
            }

          </Card.Body>
        <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
      </Card>


      }


    </>

  )
}
