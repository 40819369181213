import { Fragment, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';


export const MediosComunicacion = () => {

    const [fileInputs, setFileInputs] = useState(false)

    const handleChange = (e) => {
        // console.log(e)
    }


    const handleFile = (e) => {

        // console.log(e)
        setFileInputs(true)
    }

    return (
        <Fragment>
            <h5 className='titleInsideCard'>Medios de comunicación</h5>
            <Card.Body>
                <div className='formEstudioBasicoContainer' >
                    <div className='formItem' id='basicFormA'>
                        <Form.Group >
                            <Form.Label>Medio de comunicación</Form.Label>
                            <div className='inputContainer'>
                                <Form.Control
                                    name="politico"
                                    onChange={handleChange}
                                    required={true}
                                    as='select'
                                    type='text'
                                >

                                    <option value="0">
                                        Seleccione
                                    </option>

                                    <option value="prensaNacional">
                                        Prensa Nacional
                                    </option>

                                    <option value="prensaLocal">
                                        Prensa Local
                                    </option>
                                    <option value="radio">
                                        Radio
                                    </option>
                                    <option value="redesSociales">
                                        Redes Sociales
                                    </option>
                                    <option value="noticieros">
                                        Noticieros
                                    </option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Link
                                    </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='link' required type='text' placeholder='Link' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>



                        <Form.Group >
                            <Form.Label>Fuente</Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='fuente' required type='text' placeholder='Fuente' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa una fuente</Form.Control.Feedback>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Fecha
                                    </Form.Label>
                            <div className='inputContainer'>

                                <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                    </div>
                    <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>



                        <Form.Group>
                            <Form.Label>
                                Archivos soporte
                                    </Form.Label>
                            <div className='addButtonContainer' style={{ cursor: 'pointer', width: '100%', marginTop: '-25px', paddingTop: '0px' }} >
                                <input id='selectFile' className='selectFile' name='selectFile' type='file' onChange={handleFile} style={{ cursor: 'pointer' }} />
                                <label id='addButton' htmlFor='selectFile' style={{ cursor: 'pointer' }} >
                                    Añadir documento
                                <AddIcon />
                                </label>
                            </div>

                        </Form.Group>

                        {
                            fileInputs
                                ?
                                <Fragment>
                                    <Form.Group>
                                        <Form.Label>
                                            Fecha documento
                                    </Form.Label>
                                        <div className='inputContainer'>

                                            <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                    <Form.Group id='observacionesFormGroup'>
                                        <Form.Label className='observacionesLabel'>
                                            Descripción archivo
                                    </Form.Label>
                                        <div className='textAreaContainer'>
                                            <Form.Control name='descripcionFile' id='observacionesTextArea' maxLength={2000} style={{ height: '50px' }} required as='textarea' placeholder='Observaciones'></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa la descripcion</Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </Fragment>
                                : <Form.Group id='observacionesFormGroup'>
                                    <Form.Label className='observacionesLabel'>
                                        Descripción
                                    </Form.Label>
                                    <div className='textAreaContainer'>
                                        <Form.Control onChange={handleChange} style={{ height: '70px' }} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                        }

                    </div>

                    {
                        fileInputs
                        &&
                        <div id='observacionesContainerEstudioBasico'>
                            <Form.Group id='observacionesFormGroup'>
                                <Form.Label className='observacionesLabel'>
                                    Descripción
                                    </Form.Label>
                                <div className='textAreaContainer'>
                                    <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    }
                </div>

            </Card.Body>

        </Fragment>
    )
}
