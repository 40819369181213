import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import './GeneralWidgets.scss'
import { valueToRelative } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import { validarEntorno } from '../../helpers/validarHost'
import { obtenerDataSegunFiltro } from '../../reducers/filtroTipoEstudio'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'



export default function GeneralWidgets({ cards, filtrosForm, filtrosRef, setFiltrosForm, seccion, idTipoReporte, setIdTipoReporte, setModal, modal, initialState, setInitialState, ...props }) {
  const { t } = useTranslation();

  //   const reportesData = useSelector(store => store.reportesReducer)
  //   const {filtros_presidencia} = reportesData

  //   //console.log(reportesData.filtrosWidg)
  // //   const HASH = window.location.hash;


  // //   const {cards} = props

  // // //   const [cardC, setCardC] = useState()
  // //   const dispatch = useDispatch()


  // //   const [fecha, setFecha] = useState()


  // //   let history = useHistory();


  // //   useEffect(() => {
  // //     getFecha()
  // //     //console.log(cardC)
  // //     // getDataWidgets()
  // //   }, [])

  // //   const getFecha = () => {
  // //     const date = new Date();
  // //     setFecha(date)
  // //   }

  // //   const getDataWidgets = async () => {
  // //     const request = await fetch('/public/JSON/dashboard_json.html')

  // //     const data = await request.json()

  // //   }


  // //   // const handleClickWidget = async (nombreWidget = null) => {


  // //   //   if (nombreWidget) {

  // //   //     //console.log(nombreWidget);
  // //   //     const data = {
  // //   //       nombreWidget,
  // //   //       hash: window.location.hash
  // //   //     }

  // //   //     dispatch(obtenerDataSegunFiltro(data))

  // //   //     history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)
  // //   //   }
  // //   // }

  //   // const handleClickWidget = async (nombreWidget, card) => {



  //   //   console.log(card);

  //   //   if (card.data.enlace && card.data.enlace.tipo === 'enlace') {
  //   //     if (card.data.enlace.link) {
  //   //       window.open(card.data.enlace.link, '_blank')
  //   //     }
  //   //   }

  //   //   if (nombreWidget !== '') {

  //   //     console.log(nombreWidget);
  //   //     const data = {
  //   //       nombreWidget,
  //   //       hash: window.location.hash
  //   //     }

  //   //     dispatch(obtenerDataSegunFiltro(data))

  //   //     history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)
  //   //   }




  // //     // const req = await validarEntorno( 'JSON/ReportesOperativos/rep_op_res.html', 'POST', dataReq);

  // //     // if(req.ok){
  // //     //   const res = await req.json()
  // //     //   //console.log(res);
  // //     // }



  // //     //console.log(card)
  // //     setCardC(card)

  // //       //console.log(cardC);

  // //       if(card.data.enlace && card.data.enlace.tipo === 'enlace'){
  // //         if(card.data.enlace.link){
  // //           window.open(card.data.enlace.link, '_blank')
  // //         }
  // //       }


  // //         // if(card.data.enlace){
  // //         //   window.open(card.data.enlace, '_blank')
  // //         //   //console.log(card.data.enlace);
  // //         // }


  // //       if (nombreWidget !== '') {

  // //         //console.log(nombreWidget);
  // //         const data = {
  // //           hash: window.location.hash,
  // //           nombreWidget

  // //         }

  // //         dispatch(obtenerDataSegunFiltro(data))

  // //         history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)
  // //       }

  // //       const data = {
  // //         nombreWidget,
  // //         hash: window.location.hash
  // //       }

  // //       dispatch(obtenerDataSegunFiltro(data))

  // //       history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)


  //   // const {cards} = props


  //   const dispatch = useDispatch()


  //   const [fecha, setFecha] = useState()


  //   let history = useHistory();


  //   useEffect(() => {
  //     getFecha()
  //     // getDataWidgets()
  //   }, [])

  //   const getFecha = () => {
  //     const date = new Date();
  //     setFecha(date)
  //   }

  //   const getDataWidgets = async () => {
  //     const request = await fetch('/public/JSON/dashboard_json.html')

  //     const data = await request.json()

  //   }


  //   // const handleClickWidget = async (nombreWidget = null) => {


  //   //   if (nombreWidget) {

  //   //     //console.log(nombreWidget);
  //   //     const data = {
  //   //       nombreWidget,
  //   //       hash: window.location.hash
  //   //     }

  //   //     dispatch(obtenerDataSegunFiltro(data))

  //   //     history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)
  //   //   }
  //   // }

  //   const handleClickWidget = async (nombreWidget, card, initialState, filtros_presidencia, idTipoReporte, setIdTipoReporte, setModal, modal, setInitialState, data) => {

  // //realizado por configuracion Nathalia
  //     // const dataReq = {
  //     //   hash: window.location.hash,
  //     //   id: card.data.id,
  //     //   enlace: card.data.enlace,
  //     //   filtros: reportesData.filtrosWidg
  //     // }


  //     // //console.log(dataReq)
  //     // const req = await validarEntorno('JSON/ReportesOperativos/rep_op_res_widget.html', 'POST', dataReq);
  //     // // //console.log(req)
  //     // if(req.ok){
  //     //   const respuesta = await req.json();
  //     //   //console.log(respuesta)
  //     // }

  //     // setInitialState({ ...initialState, loading: true });
  //     // const dataReq = {
  //     //   dataForm: reportesData.filtrosWidg,
  //     //   hash: window.location.hash,
  //     //   tipo: "form",
  //     //   tipo_reporte: idTipoReporte ? idTipoReporte : null,
  //     //   filtros_presidencia: Object.keys(reportesData.filtros_presidencia) ? reportesData.filtros_presidencia : null,
  //     //   pag_actual: 1,
  //     //   id: card.data.id,
  //     //   enlace: card.data.enlace,
  //     // }

  //     // // console.log(data)

  //     // console.log(dataReq)

  //     // // setFiltrosForm(
  //     // //   {
  //     // //     tipo_reporte: idTipoReporte ? idTipoReporte : null,
  //     // //     tipo: "form",
  //     // //     filtros: reportesData.filtrosWidg
  //     // //   }

  //     // // )
  //     // // console.log(props.FiltrosForm)
  //     // const req = await validarEntorno('JSON/ReportesOperativos/rep_op_res_widget.html', 'POST', dataReq);

  //     // if (req.ok) {
  //     //   const res = await req.json();

  //     //   // const pagina = res.data.pag_actual
  //     //   // setInitialState({
  //     //   //   ...initialState,
  //     //   //   data: res,
  //     //   //   loading: false
  //     //   // });

  //     //   document.getElementById('cantidad_regs').value = '10'
  //     // } else {
  //     //   setModal({
  //     //     ...modal,
  //     //     show: true,
  //     //     mensaje: 'Ha ocurrido un error, intente nuevamente',
  //     //     tipo: 'envio_peticion_erroneo'
  //     //   })
  //     //}















  //     // if (card.data.enlace && card.data.enlace.tipo === 'enlace') {
  //     //   if (card.data.enlace) {
  //     //     //console.log('aquientroahorasiiiiii')
  //     //     window.open(card.data.enlace, '_blank')
  //     //   }
  //     // }

  //     // if (nombreWidget !== '') {

  //     //   //console.log(nombreWidget);
  //     //   const data = {
  //     //     hash: window.location.hash,
  //     //     nombreWidget

  //     //   }
  //     //   //console.log('entrando')
  //     //   dispatch(obtenerDataSegunFiltro(data))


  //     //   history.push(`/gerencia/${card.data.enlace ? card.data.enlace : `investigaciones`}/administrar/${card.data.id}`)
  //     // }

  //     //   const data = {
  //     //     nombreWidget,
  //     //     hash: window.location.hash
  //     //   }

  //     //   dispatch(obtenerDataSegunFiltro(data))


  //     // history.push(`/gerencia/${card.data.enlace ? card.data.enlace : `investigaciones`}/administrar/${card.data.id}`)
  //     // window.open(card.data.enlace)
  //       // history.push(card.data.enlace)
  //     // //console.log('aquientroahorasiiiiii')


  //   }
  // const {cards} = props


  const dispatch = useDispatch()


  const [fecha, setFecha] = useState()


  let history = useHistory();


  useEffect(() => {
    getFecha()
    // getDataWidgets()
  }, [])

  const getFecha = () => {
    const date = new Date();
    setFecha(date)
  }

  const getDataWidgets = async () => {
    const request = await fetch('/public/JSON/dashboard_json.html')

    const data = await request.json()

  }


  // const handleClickWidget = async (nombreWidget = null) => {


  //   if (nombreWidget) {

  //     console.log(nombreWidget);
  //     const data = {
  //       nombreWidget,
  //       hash: window.location.hash
  //     }

  //     dispatch(obtenerDataSegunFiltro(data))

  //     history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)
  //   }
  // }

  const handleClickWidget = async (nombreWidget, card) => {

    if (card) {
      if (card.data.enlace && card.data.enlace.tipo === 'enlace') {
        if (card.data.enlace.link) {
          window.open(card.data.enlace.link, '_blank')
        }
      }
    }


    /*
      if (nombreWidget !== '') {

        console.log(nombreWidget);
        const data = {
          seccion,
          nombreWidget,
          hash: window.location.hash
        }

        dispatch(obtenerDataSegunFiltro(data))

        console.log(seccion)

        history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)
      }
      */


    // const data = {
    //   nombreWidget,
    //   hash: window.location.hash
    // }

    // dispatch(obtenerDataSegunFiltro(data))

    // history.push(`/gerencia/${seccion ? seccion : `investigaciones`}/administrar/${nombreWidget}`)
  }
  return (
    <>
      {cards ? (
        <>
          <div className='shadow mainWidgets' >

            <Card id='widgetFecha' className='widgetFecha'>
              <Card.Body>
                <div id='titleDateWidgets'>
                  <p className='diaActual' >
                    {t('dashboard.hoy')}, {moment(new Date()).format('D')}
                  </p>

                  <p style={{ fontSize: '1.2rem', margin: '0' }} >
                    {t('dashboard.de')} {moment().format('MMMM')} {t('dashboard.de')} {moment().format('YYYY')}
                  </p>
                </div>
              </Card.Body>
            </Card>
            <div className='contenedorWidgets'>

              {cards.tarjeta_compromiso_entrega?.visualizar &&
                <Card
                  id=''
                  className="shadow widget widgetInList"
                  onClick={() => handleClickWidget('widgetVencimiento')}
                  style={{ borderBottom: `8px solid #2C5E99` }}
                  target='_blank'
                >
                  <Card.Body style={{ padding: '0 5px' }}>
                    <div className='contenidoWidget'>
                      <div className='labelWidget'>{cards.tarjeta_compromiso_entrega.data.titulo}</div>
                      <div className='cantidadWidget'>
                        <p>
                          {cards.tarjeta_compromiso_entrega.data.valor}
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              }
              {cards.tarjeta_proceso?.visualizar &&
                <Card
                  id=''
                  className="shadow  widget widgetInList"
                  onClick={() => handleClickWidget('widgetProceso')}
                  style={{ borderBottom: `8px solid #05A4DB ` }}
                >
                  <Card.Body style={{ padding: '0 5px' }}>
                    <div className='contenidoWidget'>
                      <div className='labelWidget'>{cards.tarjeta_proceso.data.titulo}</div>
                      <div className='cantidadWidget'>{cards.tarjeta_proceso.data.valor}</div>
                    </div>
                  </Card.Body>
                </Card>
              }

              {
                cards.tarjeta_novedad?.visualizar
                &&
                <Card
                  className="shadow  widget widgetInList"
                  onClick={() => handleClickWidget('widgetNovedad')}
                  style={{ borderBottom: `8px solid #009688` }}
                >

                  <Card.Body style={{ padding: '0 5px' }} >
                    <div className='contenidoWidget'>
                      <div className='labelWidget'>{cards.tarjeta_novedad.data.titulo}</div>
                      <div className='cantidadWidget'>{cards.tarjeta_novedad.data.valor}</div>
                      <div className='labelDias'>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              }

              {
                cards.tarjeta_cancelados?.visualizar
                &&
                <Card
                  className="shadow  widget widgetInList"
                  onClick={() => handleClickWidget('widgetVisitas')}
                  style={{ borderBottom: `8px solid #8BC34A` }}
                >

                  <Card.Body style={{ padding: '0 5px' }} >
                    <div className='contenidoWidget'>
                      <div className='labelWidget'>{cards.tarjeta_cancelados.data.titulo}</div>
                      <div className='cantidadWidget'>{cards.tarjeta_cancelados.data.valor}</div>
                      <div className='labelDias'>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              }

              {

                cards.tarjeta_finalizados?.visualizar
                &&
                <Card
                  id='widgetFinalizados'
                  className="shadow  widget widgetInList"
                  onClick={() => handleClickWidget('widgetFinalizados')}
                  style={{ borderBottom: `8px solid #F9CF00` }}
                >
                  <Card.Body style={{ padding: '0 5px' }} >
                    <div className='contenidoWidget'>
                      <div className='labelWidget'>{cards.tarjeta_finalizados.data.titulo}</div>
                      <div className='cantidadWidget'>{cards.tarjeta_finalizados.data.valor}</div>
                      <div className='labelDias'>
                      </div>
                    </div>
                  </Card.Body>
                </Card>

              }
              {cards.list && cards.list.map((card, index) => {

                const styles = {
                  borderBottom: `8px solid ${card.data.color} `
                }

                return (
                  <Card
                    key={index}
                    id={card.id}
                    className="shadow widget widgetInList"
                    onClick={() => handleClickWidget('', card)}
                    style={styles}
                  >
                    <Card.Link href={card.data.enlace} target='_blank' style={{ padding: '0 5px' }}>


                      <div className='contenidoWidget'
                        onChange={() => handleClickWidget('', card)}
                      >
                        <div className='labelWidget'>{card.data.titulo}</div>
                        <div className='cantidadWidget'>{card.data.valor}</div>

                      </div>


                    </Card.Link>
                  </Card>
                )
              })

              }



              {/* <Card id='widgetFinalizado'  className="shadow cardGraficaGeneral widget">
          <Card.Body>

          </Card.Body>
        </Card> */}
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
