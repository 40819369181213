import React, { useState, useEffect } from 'react'

import FiltroInformes from '../../components/gerenciaGeneral/FiltroInformes'
import PopUpModal from '../../helpers/ModalAyudaReportes'

import {useDispatch, useSelector} from 'react-redux'
import {fetchDataReportes}  from '../../reducers/reportesReducer'


export default function Operaciones() {

  const ruta = window.location.hash
  const reportes = useSelector( store => store.reportesReducer)
  const {infoReportes} =  reportes
  const dispatch = useDispatch()

  const [rutaEnviada, setRutaEnviada] = useState({
    hash: ruta
  })
  

  useEffect(() => {
    dispatch(fetchDataReportes(rutaEnviada))
  }, [dispatch, rutaEnviada])

  const [open, setOpen] = useState(false)


  return (
    <div>
      
      {infoReportes &&
        <>
          <PopUpModal dataModal={infoReportes} open={open} setOpen={setOpen} />
          <br />
          <FiltroInformes dataModal={infoReportes} dataInformes={infoReportes} />

        </>

      }

    </div>
  )
}
