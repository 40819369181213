import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './ListObservations.scss';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getBase64 } from '../../../helpers/fileToBase64';
import LoaderModal from '../../icons/LoaderModal';
import ModalImage from './ModalImage';
import NotificationModal from '../../modales/NotificationModal';
import { validarEntorno } from '../../../helpers/validarHost';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import EditModal from '../../modales/EditModal';

import './styles/ListObservations.scss';


const ListObservations = ({ id_pregunta, mode, handleClose }) => {

  const { id } = useParams()
  const { t } = useTranslation();

  // //console.log(mode)
  const [initialState, setInitialState] = useState({
    error: null,
    loading: true
  })
  const [notification, setNotification] = useState({
    show: false,
    tipo: '',
    mensaje: ''
  })
  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [dataForm, setDataForm] = useState({});
  const [show, setShow] = useState(false);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [showDeleteModalNotification, setShowDeleteModalNotification] = useState(false);
  const [modal, setModal] = useState({
    tipo: 'detalleObservacionesAuditoria',
    show: false
  })
  const handleCloseModalNotification = () => setShowModalNotification(false);
  const handleCloseDeleteModalNotification = () => setShowDeleteModalNotification(false);

  const handleCloseImage = () => setShow(false);
  const handleShowImage = () => setShow(true);
  const [indexEdit, setIndexEdit] = useState('');
  const [indexDetail, setIndexDetail] = useState('');
  const [indexRecomend, setIndexRecomend] = useState('');

  const [indexCuesDetail, setIndexCuesDetail] = useState('');
  const [itemCuesDetail, setItemCuesDetail] = useState({});
  const [tipoCuesDetail, setTipoCuesDetail] = useState('');
  const [image, setImage] = useState('');
  const [obs, setObs] = useState({})

  const [pieHallazgo, setPieHallazgo] = useState([{ imagen: "", pie_imagen: "" }]);
  const [pieRecomendacion, setPieRecomendacion] = useState([{ imagen: "", pie_imagen: "" }]);
  const [imagenIsLoad, setImagenIsLoad] = useState(false);
  const [addHallazgos, setAddHallazgos] = useState(false);
  const [addRecomendaciones, setAddRecomendaciones] = useState(false);

  const [pestanaHallazgo, setPestanaHallazgo] = useState('')
  const [idPestana, setIdPestana] = useState('')


  const Items = async () => {

    const dataSend = {
      id_pregunta: id_pregunta,
      hash: window.location.hash,
      id
    }

    let request = '';

    if (mode === 'report') {
      request = await validarEntorno('JSON/observaciones_gestion_preguntas_check_json.html', 'POST', dataSend);
    } else if (mode === 'report-third') {
      request = await validarEntorno('JSON/observaciones_gestion_preguntas_json.html', 'POST', dataSend);
    }

    if (request.ok) {
      setInitialState({ ...initialState, loading: false })
      const respuesta = await request.json();
      const lt = respuesta.data;
      setData(lt);
    }
  }

  useEffect(() => {
    // //console.log('UseEffect');
    setTimeout(() => {
      Items();
    }, 2000);
    // if(obs.id !== ''){}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_pregunta]);

  // const renderTableHeader = () => {
  //    const tableHeader = (
  //         <thead>
  //             <th>Id</th>
  //             <th>Hallazgo</th>
  //             <th>Comentario</th>
  //             <th>Recomendación</th>
  //             <th>Acciones</th>
  //         </thead>
  //    )

  //    return tableHeader;
  // };

  const renderTableHeaderTwo = () => {
    const tableHeader = (
      <thead>
        <th>{t('modales.id')}</th>
        <th>{t('modales.descripcion')}</th>
        <th>{t('modales.acciones')}</th>
      </thead>
    )

    return tableHeader;
  };

  const renderTableData = () => {
    return data.map((item, index) => {
      const { id, descripcion, imagen, pie_de_imagen, is_edit } = item;
      return (
        <tr style={{ fontSize: '1rem' }} className="rowTabla" key={id}>
          <td style={{ width: '10%' }}>{id}</td>
          <td className="td-auditorias" style={{ width: '50%' }}>{descripcion}</td>
          <td className="tdBotones">
            <div className='botonesTabla' style={{ width: '70%', margin: '0 auto' }}>
              {/* <Button className="editBeneficiarios buttonBeneficiarios modal-audit" onClick={() => { availableInputs(index) }}>
                                <EditIcon fontSize='small'/>
                                Editar
                            </Button> */}
              <button
                style={{ cursor: 'pointer' }}
                className="botonTablaGeneral botonEditarTabla" onClick={() => openModalEdicion(item, 'editarObsCuestPregunta')}>
                <EditIcon fontSize='small' />
                {t('botones.editar')}
              </button>
              <button
                style={{ cursor: 'pointer' }}
                className="botonTablaGeneral botonEliminarTabla"
                // onClick={(e) => { deleteData(e, index, 'total_cuestionario') }}
                onClick={() => { confirmDeleteDetail(index) }}
              >
                <DeleteIcon fontSize='small' />
                {t('botones.eliminar')}
              </button>

            </div>
          </td>
          <ModalImage image={imagen} show={show} handleClose={handleCloseImage} />
        </tr>
      )
    })
  }

  const openModalEdicion = (item, tipo) => {
    setModal({ ...modal, tipo: tipo, show: true })

    if (item) {
      setItem(item)
    }
    setDataForm({})

    if (tipo === 'editarObsPregunta') {
      setObs(item)
    }

    // //console.log(modal)
  }

  const enviarEdicionObsPregunta = async (tipo) => {

    const dataReq = {
      hash: window.location.hash,
      id: id,
      id_pregunta: id_pregunta,
      id_observacion: item.id,
      cambios: dataForm,
      nuevos_hallazgos: addHallazgos ? pieHallazgo : {},
      nuevas_recomendaciones: addRecomendaciones ? pieRecomendacion : {},
      tipo: tipo
    }

    let req = '';

    console.log(dataReq)

    if (tipo === 'total_cuestionario') {
      req = await validarEntorno('JSON/auditorias/areas_diagnostico/actualizar_obs_cuestionario.html', 'POST', dataReq);
    } else {
      req = await validarEntorno('JSON/auditorias/areas_diagnostico/actualizar_obs_pregunta.html', 'POST', dataReq);
    }

    if (req.ok) {

      const res = await req.json()

      if (res.data.message === 'OK') {
        setNotification({ ...notification, show: true, tipo: 'obsUpdated' })

        // let jsonData = [...data];
        // let item = {...jsonData[index]};
        // item['is_edit'] = !item['is_edit'];
        // setDataForm(item);
        // jsonData[index] = dataForm;
        // setData(jsonData);

        setItem({})
        setIndexEdit('');
        setAddRecomendaciones(false);
        setAddHallazgos(false);
        setData([]);

        setTimeout(() => {
          Items();
        }, 2000);

      } else if (res.estado === 'false') {
        setNotification({ ...notification, show: true, tipo: 'edicionRechazada', mensaje: res.mensaje })
      }

      setModal({
        ...modal,
        show: false,
        tipo: ''
      })
    }
  }

  const updatePestana = (tipo, id, idx) => {
    // console.log(tipo, id)
    setPestanaHallazgo(`${tipo}${id}`)

  }

  const updateIdHallazgo = (id) => {
    // console.log(id)
    setIdPestana(id)
    setPestanaHallazgo(`hallazgo${id}`)


  }

  const renderTableData2 = () => {
    return data.map((item, index) => {
      const { id, descripcion, imagen, pie_de_imagen, is_edit, hallazgo, comentario, recomendacion, responsable } = item;
      return (
        <>
          <div className=' shadow hallazgo_item' onMouseEnter={() => updateIdHallazgo(id)} >
            <div className='hallazgo_item-header'>

              <p className='id_hallazgo' >{`${t('formulario.label.hallazgo')} ${id}`}</p>
              <div className=''>
                <td className="">
                  <div className='btns_hallazgo' >
                    <button
                      style={{ cursor: 'pointer' }}
                      className="botonTablaGeneral botonEditarTabla" onClick={() => openModalEdicion(item, 'editarObsPregunta')}>
                      <EditIcon fontSize='small' />
                      {t('botones.editar')}
                    </button>
                    <button
                      style={{ cursor: 'pointer' }}
                      className="botonTablaGeneral botonEliminarTabla"
                      // onClick={(e) => { deleteData(e, index, 'pregunta') }}
                      onClick={() => { confirmDeleteDetail(index) }}
                    >
                      <DeleteIcon fontSize='small' />
                      {t('botones.eliminar')}
                    </button>
                  </div>
                </td>
              </div>
            </div>
            <div className='hallazgo_item-pestanas'>
              {item.hallazgo &&
                <div
                  className='pestana_hallazgo'
                  onClick={() => updatePestana('hallazgo', id)}
                  style={{
                    backgroundColor: hallazgo && (pestanaHallazgo === `hallazgo${id}`) && '#6888B8',
                    color: hallazgo && (pestanaHallazgo === `hallazgo${id}`) && '#fff'
                  }}
                >
                  {t('formulario.label.hallazgo')}
                </div>
              }
              {item.comentario &&
                <div
                  className='pestana_hallazgo'
                  onClick={() => updatePestana('comentario', id)}
                  style={{
                    backgroundColor: hallazgo && pestanaHallazgo === `comentario${id}` && '#6888B8',
                    color: hallazgo && pestanaHallazgo === `comentario${id}` && '#fff'
                  }}
                >
                  {t('formulario.label.comentario')}
                </div>
              }
              {item.recomendacion &&
                <div
                  className='pestana_hallazgo'
                  onClick={() => updatePestana('recomendacion', id)}
                  style={{
                    backgroundColor: hallazgo && pestanaHallazgo === `recomendacion${id}` && '#6888B8',
                    color: hallazgo && pestanaHallazgo === `recomendacion${id}` && '#fff'
                  }}
                >
                  {t('formulario.label.recomendacion')}
                </div>
              }
              {item.responsable &&
                <div
                  className='pestana_hallazgo'
                  onClick={() => updatePestana('responsable', id)}
                  style={{
                    backgroundColor: hallazgo && pestanaHallazgo === `responsable${id}` && '#6888B8',
                    color: hallazgo && pestanaHallazgo === `responsable${id}` && '#fff'
                  }}
                >
                  {t('formulario.label.responsable')}
                </div>
              }
            </div>
            <div className='hallazgo_item-main'>
              <p className='hallazgo_item_contenido'>
                {pestanaHallazgo === `hallazgo${id}`
                  ?
                  hallazgo
                  :
                  pestanaHallazgo === `comentario${id}`
                    ?
                    comentario
                    :
                    pestanaHallazgo === `recomendacion${id}`
                      ?
                      recomendacion
                      : pestanaHallazgo === `responsable${id}` ? responsable
                        :
                        hallazgo
                }
              </p>
            </div>
          </div>
          {/* <tr className='rowTabla' key={id}
                    style={{
                        fontSize: '1rem'
                    }}
                >
                    <td style={{ width: '10%' }}>{id}</td>
                    <td className="td-auditorias" style={{ width: '20%' }}>{hallazgo}</td>
                    <td className="td-auditorias" style={{ width: '20%' }}>{comentario}</td>
                    <td className="td-auditorias" style={{ width: '20%' }}>{recomendacion}</td>
                    <td className="tdBotones">
                        <div className='botonesTabla' style={{ width: '80%', margin: '0 auto' }}>

                            <button
                                style={{ cursor: 'pointer' }}
                                className="botonTablaGeneral botonEditarTabla" onClick={() => openModalEdicion(item, 'editarObsPregunta')}>
                                <EditIcon fontSize='small'/>
                                Editar
                            </button>
                            <button
                                style={{ cursor: 'pointer' }}
                                className="botonTablaGeneral botonEliminarTabla"
                                // onClick={(e) => { deleteData(e, index, 'pregunta') }}
                                onClick={() => {confirmDeleteDetail(index)}}
                            >
                                <DeleteIcon fontSize='small' />
                                Eliminar
                            </button>

                        </div>

                    </td>
                </tr> */}
          <ModalImage image={imagen} show={show} handleClose={handleCloseImage} />
        </>

      )
    })
  }

  const handleChangeData = async (e) => {
    const value = e.target.value;
    setDataForm({
      ...dataForm,
      [e.target.name]: value
    });
    // //console.log(dataForm)
  }

  const handleImageData = async (file) => {
    let imageB64 = '';

    if (file.length > 0) {
      imageB64 = await getBase64(file[0]);

      setDataForm({
        ...dataForm,
        imagen: imageB64
      });
    }

    // //console.log(dataForm)

  }

  // const handleImage = async (file) => {
  //     let imageB64 = '';

  //     imageB64 = await getBase64(file[0]);

  //     setDataForm({
  //         ...dataForm,
  //         imagen: imageB64
  //     });

  //     // //console.log(dataForm)

  // }

  const availableInputs = (index) => {
    if (indexEdit !== index) {
      setIndexEdit(index);
    } else {
      setIndexEdit('');
    }
    // //console.log(data)
  }

  const editDataDetail = async (e, index) => {

    e.preventDefault();

    const dataReq = {
      hash: window.location.hash,
      id: id,
      id_pregunta: item.id_pregunta,
      id_observacion: item.id,
      data: dataForm
    }

    const response = await validarEntorno('JSON/respuesta_observacion_pregunta_denegada.html', 'POST', dataReq);

    if (response.ok) {

      const respuesta = await response.json();

      if (respuesta.data.message) {
        setShowModalNotification(true);
      }
    }

    availableInputs(index);
  }

  const deleteData = async (e, index, tipo) => {

    e.preventDefault();

    let jsonData = [...data];

    let item = { ...jsonData[index] };

    const dataReq = {
      hash: window.location.hash,
      id: id,
      id_cuestionaripo: item.id_cuestionario,
      id_observacion: item.id,
      tipo: tipo
    }

    let response = '';

    if (tipo === 'total_cuestionario') {
      response = await validarEntorno('JSON/auditorias/areas_diagnostico/respuesta_eliminar_observacion_cuestionario_json.html', 'POST', dataReq);
    } else {
      response = await validarEntorno('JSON/auditorias/areas_diagnostico/respuesta_eliminar_observacion_pregunta_json.html', 'POST', dataReq);
    }

    if (response.ok) {
      const respuesta = await response.json();

      // Items()

      if (respuesta.data.message) {
        jsonData.splice(index, 1);

        setData(jsonData);

        setShowDeleteModalNotification(true);

        setNotification({
          ...notification,
          show: false
        })
      }
    }
  }

  const handleDescriptionImage = (e, index, tipo) => {
    const itm = { ...item };
    const { name, value } = e.target;

    if (tipo === 'visual') {
      const visuales = [...itm.detalle_visual];
      const visual = { ...visuales[index] };
      visual.pie_de_imagen = value;
      visuales[index] = visual;
      itm.detalle_visual = visuales;
      setItem(itm);
    } else if (tipo === 'recomendacion') {
      const recomendaciones = [...itm.recomendaciones];
      const recomendacion = { ...recomendaciones[index] };
      recomendacion.pie_de_imagen = value;
      recomendaciones[index] = recomendacion;
      itm.recomendaciones = recomendaciones;
      setItem(itm);
    }

  }

  const editDescriptionImage = async (item, index, tipo) => {

    const dataReq = {
      hash: window.location.hash,
      id_registro: id,
      id_evidencia: item.id,
      id: obs.id,
      item,
      tipo
    }

    //console.log(dataReq)

    const req = await validarEntorno('JSON/auditorias/areas_diagnostico/respuesta_editar_descripcion_imagen.html', 'POST', dataReq);

    if (req.ok) {

      const res = await req.json();

      setNotification({
        show: true,
        mensaje: res.mensaje,
        tipo: 'panoramaCreado'
      })

      if (tipo === 'visual') {
        setIndexDetail('');
      } else if (tipo === 'recomendacion') {
        setIndexRecomend('');
      }
    }
  }

  const deleteDescriptionImage = async (item, index, tipo) => {


    // //console.log(item, index);

    const dataReq = {
      hash: window.location.hash,
      id_registro: id,
      id_evidencia: item.id,
      id: obs.id,
      id_pregunta: id_pregunta,
      item,
      tipo
    }

    //console.log(dataReq);

    const req = await validarEntorno('JSON/auditorias/areas_diagnostico/respuesta_eliminar_descripcion_imagen.html', 'POST', dataReq);

    if (req.ok) {

      const res = await req.json();

      deleteDescription(index, tipo);

      setNotification({
        ...notification,
        show: false,
        tipo: ''
      })
    }
  }

  const confirmDeleteImageDetail = (index, item, tipo) => {

    setNotification({
      ...notification,
      show: true,
      tipo: 'deleteImagenDetail'
    });
    setIndexCuesDetail(index);
    setItemCuesDetail(item);
    setTipoCuesDetail(tipo);
  }

  const deleteDescription = (index, tipo) => {

    const itm = { ...item };
    // //console.log(itm)

    if (tipo === 'visual') {
      const visuales = [...itm.detalle_visual];
      visuales.splice(index, 1)
      itm.detalle_visual = visuales;
      setItem(itm);
    } else if (tipo === 'recomendacion') {
      const recomendaciones = [...itm.recomendaciones];
      recomendaciones.splice(index, 1)
      itm.recomendaciones = recomendaciones;
      setItem(itm);
    }
  }

  const getIndexDetail = (index) => {
    if (indexDetail === index) {
      setIndexDetail('');
    } else {
      setIndexDetail(index);
    }
  }

  const getIndexDetailRecomendations = (index) => {
    if (indexRecomend === index) {
      setIndexRecomend('');
    } else {
      setIndexRecomend(index);
    }
  }

  const deleteImage = async () => {
    setItem({
      ...item,
      imagen: ""
    })

    setNotification({
      ...notification,
      show: false,
      tipo: ''
    })
  }

  const confirmDeleteImage = () => {
    setNotification({
      ...notification,
      show: true,
      tipo: 'deleteImagen'
    })
  }

  const showImage = (image) => {

    setImage(image);

    setNotification({
      ...notification,
      show: true,
      tipo: 'showImage'
    })
  }

  const handleItem = (e) => {
    const { value, name } = e.target;

    setItem({
      ...item,
      [name]: value
    })
  }

  const editDataDetailModal = async () => {

    const dataReq = {
      hash: window.location.hash,
      id_registro: id,
      id: item.id,
      item,
    }

    //console.log(dataReq);

    const req = await validarEntorno('JSON/auditorias/areas_diagnostico/respuesta_editar_descripcion_imagen.html', 'POST', dataReq);

    if (req.ok) {

      const res = await req.json();

      if (res.estado === 'OK') {

        Items();

        setModal({
          ...modal,
          show: false
        });

        setNotification({
          show: true,
          tipo: 'successGestion',
          mensaje: res.mensaje
        })
      }
    }
  }

  const confirmDeleteDetail = (index) => {
    setNotification({
      ...notification,
      show: true,
      tipo: 'confirmacion_eliminacion_evidencia',
      mensaje: t('modales.esta_seguro_eliminar_evidencia')
    });

    setIndexDetail(index);
  }

  const handleImageModal = async (file) => {
    let imageB64 = '';

    //console.log(file[0]);

    imageB64 = await getBase64(file[0]);

    setItem({
      ...item,
      imagen: imageB64
    });
  }

  const handleAddClick = () => {
    setPieHallazgo([...pieHallazgo, { imagen: "", pie_imagen: "" }]);
  };

  const handleRemoveClick = index => {
    const list = [...pieHallazgo];
    list.splice(index, 1);
    setPieHallazgo(list);
  };

  const handleAddClickRecomendacion = () => {
    setPieRecomendacion([...pieRecomendacion, { imagen: "", pie_imagen: "" }]);
  };

  const handleRemoveClickRecomendacion = index => {
    const list = [...pieRecomendacion];
    list.splice(index, 1);
    setPieRecomendacion(list);
  };

  const handleChangeHallazgos = (e, index) => {
    const { name, value } = e.target;
    const list = [...pieHallazgo];
    list[index][name] = value;
    setPieHallazgo(list);
  };

  const handleChangeRecomendaciones = (e, index) => {
    const { name, value } = e.target;
    const list = [...pieRecomendacion];
    list[index][name] = value;
    setPieRecomendacion(list);
  };


  const handleImageDataEdit = async (file, index) => {
    let imageB64 = '';

    if (file.length > 0) {
      imageB64 = await getBase64(file[0]);

      const hallazgo = [...pieHallazgo];
      const item = { ...hallazgo[index] };
      item.imagen = imageB64;
      hallazgo[index] = item;
      setPieHallazgo(hallazgo);
      setImagenIsLoad(true)
    }
  }

  const handleImageDataRecomendacionEdit = async (file, index) => {
    let imageB64 = '';

    if (file.length > 0) {
      imageB64 = await getBase64(file[0]);
      const hallazgo = [...pieRecomendacion];
      const item = { ...hallazgo[index] };
      item.imagen = imageB64;
      hallazgo[index] = item;
      setPieRecomendacion(hallazgo);
      setImagenIsLoad(true)
    }
  }

  const handleCloseEditModal = () => {
    setModal({
      ...modal,
      show: false,
    });

    setPieHallazgo([{ imagen: "", pie_imagen: "" }]);
    setPieRecomendacion([{ imagen: "", pie_imagen: "" }]);

    setAddHallazgos(false);
    setAddRecomendaciones(false);
  }

  return (
    <>
      <Card className="card-content-table ">
        <Card.Body style={{ padding: '0' }} >
          {data?.length > 0 ? (

            <table responsive={true} className="table-modal-audit ">
              {mode === 'report' ? null : renderTableHeaderTwo()}
              <tbody>
                {mode === 'report'
                  ?
                  renderTableData2()
                  :
                  renderTableData()
                }
              </tbody>
            </table>
          ) : data.length === 0 && initialState.loading === false
            ?
            <div
              className='mensajeTablaSinInfo'
            >
              {t('modales.no_hay_observaciones')}
            </div>

            :

            (
              <div>
                <LoaderModal />
              </div>
            )
          }
          <NotificationModal
            isOpen={showModalNotification}
            setIsOpen={handleCloseModalNotification}
            typeModal="saveQuestions"
          />
          <NotificationModal
            isOpen={showDeleteModalNotification}
            setIsOpen={handleCloseDeleteModalNotification}
            typeModal="deleteObservation"
          />
          <NotificationModal
            isOpen={notification.show}
            setIsOpen={() => setNotification(false)}
            typeModal={notification.tipo}
            mensaje={notification.mensaje}
            handleEliminar={deleteData}
            deleteImage={deleteImage}
            tipo={tipoCuesDetail}
            index={indexCuesDetail}
            item={itemCuesDetail}
            deleteDescriptionImage={deleteDescriptionImage}
            img={image}
            indexDetail={indexDetail}
            items={Items}
          />
          <EditModal
            typeModal={modal.tipo}
            isOpen={modal.show}
            setIsOpen={handleCloseEditModal}
            ele={item ? item : null}
            handleImage={handleImageData}
            handleData={handleChangeData}
            stateForm={dataForm}
            enviarEdicionObs={enviarEdicionObsPregunta}
            editDescriptionImage={editDescriptionImage}
            handleDescriptionImage={handleDescriptionImage}
            indexDetail={indexDetail}
            getIndexDetail={getIndexDetail}
            getIndexDetailRecomendations={getIndexDetailRecomendations}
            indexRecomend={indexRecomend}
            confirmDeleteImage={confirmDeleteImage}
            editDataDetailModal={editDataDetailModal}
            handleItem={handleItem}
            handleImageModal={handleImageModal}
            confirmDeleteImageDetail={confirmDeleteImageDetail}
            showImage={showImage}
            items={Items}
            pieHallazgo={pieHallazgo}
            pieRecomendacion={pieRecomendacion}
            setPieHallazgo={setPieHallazgo}
            setPieHallazgo={setPieRecomendacion}
            handleAddClick={handleAddClick}
            handleAddClickRecomendacion={handleAddClickRecomendacion}
            handleRemoveClick={handleRemoveClick}
            handleRemoveClickRecomendacion={handleRemoveClickRecomendacion}
            handleImageDataEdit={handleImageDataEdit}
            handleImageDataRecomendacionEdit={handleImageDataRecomendacionEdit}
            handleChangeRecomendaciones={handleChangeRecomendaciones}
            handleChangeHallazgos={handleChangeHallazgos}
            setAddHallazgos={setAddHallazgos}
            setAddRecomendaciones={setAddRecomendaciones}
            addHallazgos={addHallazgos}
            addRecomendaciones={addRecomendaciones}
          />
        </Card.Body>
      </Card>
    </>
  );

}

export default ListObservations;
