import React, {useState, useEffect} from 'react'
import { Modal } from 'react-bootstrap'
import { IconSVG } from '../../components/icons/CloseSVG'

import '../../helpers/PopUpModal.scss'


export default function AyudaPerfilModal(props) {

    //console.log(props.dataModal)

    const [ data, setData] = useState({
        tituloModal: '',
        contenidoModal: ''
    })

    const handleClose = () => {
        props.setOpen && props.setOpen(false)
        props.setIsOpen && props.setIsOpen(false)

    }

    useEffect(() => {
        setData({
            tituloModal: props.dataModal.tituloModal,
            contenidoModal: props.dataModal.contenidoModal
        })
    }, [])

    if(!props.open){
        return null;
    };


    return (
        <Modal className='loginModal modalesGenerales' show={props.open || props.isOpen} centered onHide={handleClose} >
        <Modal.Header  >
          <Modal.Title>
              <h4 className='pagesTitles'>{props.dataModal.tituloModal || ''}</h4>
          </Modal.Title>
          <div onClick={handleClose}>
              <IconSVG/>
          </div>
        </Modal.Header>
        <Modal.Body>
            {props.dataModal.contenidoModal || ''}
        </Modal.Body>
    </Modal>
    )
}
