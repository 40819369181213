import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { ContainerD } from '../../components/dashboard/Container'
import PanelOcensa from '../../components/Ocensa/PanelOcensa'
import PanelPresidencia from '../../components/presidencia/Main';
import { validarEntorno } from '../../helpers/validarHost';
import Gerencia from './Gerencia'
import ReportesOperativos from '../../components/ReportesOperativos/Main'
import Operaciones from '../../pages/gerenciaGeneral/Operaciones';
import EditModal from '../../components/modales/EditModal'
import { Alert } from '../../components/Alert'
import InputType from '../../helpers/InputType'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PanelDinamico from '../../components/paneles/PanelDinamico';
import { EventBusProvider } from '../../context/EventBus';


export default function PanelGerencia() {

  window.localStorage.setItem('contador_vistas_media', 0)
  const { t } = useTranslation();
  const HASH = window.location.hash

  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();

  const { id } = useParams()

  const [perfilPanel, setPerfilPanel] = useState({})
  const [validated, setValidated] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    data: {}
  })

  const [alert, setAlert] = useState({
    open: false,
    type: '',
    content: '',
    vertical: 'bottom',
    horizontal: 'left'
  })

  useEffect(() => {
    cargaPanel()
  }, [])

  const cargaPanel = async () => {

    const dataReq = {
      hash: window.location.hash,
      id: id ? id : null
    }

    //console.log(dataReq)

    const request = await validarEntorno('JSON/carga_panel.html', 'POST', dataReq)

    if (request.ok) {


      const respuesta = await request.json()


      // //console.log(respuesta)
      setPerfilPanel(respuesta)
      setModal({
        ...modal,
        show: respuesta?.modal?.show,
        tipo: respuesta?.modal?.tipo,
        data: respuesta?.modal

      })

      //console.log(perfilPanel)
    }
  }

  // const  perfil  = 'perfilPanel'
  const { perfil, panel_dinamico } = perfilPanel

  const onSubmit = async (data, e) => {

    setValidated(true)

    const { asesoria } = data;

    const arrayToObject = Object.assign({}, ...asesoria);


    const dataReq = {
      dataForm: arrayToObject,
      hash: HASH,
    }

    // console.log(dataReq)

    const req = await validarEntorno('JSON/ReportesOperativos/fom_regs.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      if (res.estado === 'ok') {
        setModal({ ...modal, show: false })
      } else {
        setAlert({ ...alert, open: true, type: 'error', content: res.mensaje })

      }
    }

  }

  return (
    <>
      {
        panel_dinamico ?
         <EventBusProvider> <PanelDinamico info={perfilPanel} /></EventBusProvider> :
          perfil === 'operaciones' || perfil === 'panorama_riesgos' || perfil === 'gerencia_riesgos'
            ?
            <>
              <PanelOcensa dataPanel={perfilPanel} perfil={perfil} />
            </>
            : perfil === 'gerencia_com' || perfil === 'inteligencia' || perfil === 'investigaciones_administrativas' || perfil === 'presidencia'
              ?
              <>
                <ContainerD perfil={perfil} dataPanel={perfilPanel?.campos} />
              </>
              : perfil === 'panel_gerencial'
                ? <Gerencia />
                : perfil === 'panel_presidencia'
                  ? <PanelPresidencia />
                  : perfil === 'rep_op_tar'
                    ? <Operaciones />
                    : perfil === 'rep_op'
                      ? <ReportesOperativos />
                      : null
      }

      {modal?.data &&
        <EditModal className='shadow' isOpen={modal.show} setIsOpen={setModal} showClose={false} typeModal={modal.tipo}>
          <div display="flex" flexDirection="column" style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
            <h2 className='pagesTitles'
              style={{ margin: '0 auto', position: 'sticky', backgroundColor: "#ededed", zIndex: "10", width: '100%', boxSizing: 'border-box', top: "0", padding: '0 0 72px 0' }}>
              {modal.data.titulo}
            </h2>
            <Form noValidated onSubmit={handleSubmit(onSubmit)}
              style={{
                width: '100%',
                padding: '0',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                height: '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  margin: '0 auto',
                  padding: '0',
                  display: 'flex',
                  flexWrap: 'wrap',
                  overflowY: 'auto',
                }}
              >

                {modal?.data?.campos?.map((campo, index) => {
                  const fieldName = `asesoria.0`;
                  return (
                    <>
                      <InputType
                        item={campo}
                        index={index}
                        idx={index}
                        key={`${0} - ${index}`}
                        reg={register}
                        nombre={`${fieldName}`}
                        errors={errors}
                        col={campo.col}
                        type=""
                      />
                    </>
                  )
                })
                }
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', position: 'sticky', backgroundColor: "#ededed", boxSizing: 'border-box', bottom: "0", padding: '0', marginTop: '5rem' }}>
                {/* <button type="button" className="configButton" onClick={()=> {sendConfig()}}>Guardar Configuración</button> */}
                <button
                  type="submit"
                  style={{ margin: '20px 0 0 0' }} className="confirmButton">{modal.data.boton ? modal.data.boton : t('botones.enviar')}</button>
              </div>
            </Form></div>

        </EditModal>
      }
      <Alert
        alert={alert}
        setAlert={setAlert}
      />

    </>
  )
}

// gestionhumana_hv@cosinte.com
