import React, { Fragment, useState } from 'react'
import { Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { validarEntorno } from '../../../../helpers/validarHost';
import { envioSegundaPestaña } from '../../../../reducers/crearSolicitudReducer';
import GenericModal from '../../../modales/NotificationModal';
import { useTranslation } from 'react-i18next';

export const InformacionSolicitud = ({ index, selects, selectSeleccionado }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  // const [modal, setModal] = useState(false)
  const [validated, setValidated] = useState(false);
  // const crearSolicitud = useSelector(state => state.crearSolicitud)


  const [tipoModal, setTipoModal] = useState('')
  const [modalF, setModalF] = useState(false)
  const [mensajeModal, setMensajeModal] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [segundaPestaña, setSegundaPestaña] = useState({
    identificacion: '',
    observaciones: '',
    select: selects.label
  })



  const handleInputchangeSecondPestaña = (e) => {
    setSegundaPestaña({
      ...segundaPestaña,
      [e.target.name]: e.target.value,
    })
  }




  const handleSubmitSecondPestaña = async (e) => {
    e.preventDefault()

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }

    setValidated(true)

    if (form.checkValidity() === true) {

      setIsDisabled(true)
      // return <ModalConfirmacion isOpen={showModal} />

      //const respuesta = await peticionSinToken('https://127.0.0.1:5500/public/JSON/11.respuestaSegundaPesta%C3%B1aJson.html')
      const respuesta = await validarEntorno('JSON/respuestaSegundaPestañaJson.html', 'POST', segundaPestaña)

      if (respuesta.ok) {

        const body = await respuesta.json()
        if (body.ok.estado) {
          dispatch(envioSegundaPestaña(segundaPestaña))
          setTipoModal('crearSolicitud')
          setMensajeModal('')
          setModalF(true)
          setIsDisabled(false)
        } else {
          setMensajeModal(body.ok.mensaje)
          setModalF(true)
          setIsDisabled(false)
          setTipoModal('errorFormSolicitud')
        }
      }

    }
  }

  return (
    <Fragment>
      <Card key={index} className='shadow cardContainerSolicitud'>
        <h5 className='titleInsideCard'>{t('formulario.label.informacion_solicitud')}</h5>
        <Card.Body>
          <Form className='formEstudioBasicoContainer' noValidate validated={validated} onSubmit={handleSubmitSecondPestaña}>
            <div className='formItem' >
              <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Form.Label style={{ marginLeft: '0px' }}>
                  {t('formulario.label.identificacion')}
                </Form.Label>
                <div className='inputContainer'>
                  <Form.Control
                    onChange={handleInputchangeSecondPestaña}
                    name='identificacion'
                    type='number'
                    placeholder={t('formulario.label.identificacion')}
                    min='0'
                    max='9999999999'
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_numero_identificacion')}</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div id='observacionesContainerEstudioBasico'>
              <Form.Group id='observacionesFormGroup'>
                <Form.Label className='observacionesLabel'>
                  {t('formulario.label.observaciones')}
                </Form.Label>
                <div className='textAreaContainer'>
                  <Form.Control onChange={handleInputchangeSecondPestaña} name='observaciones' id='observacionesTextArea' required as='textarea' placeholder={t('formulario.label.observaciones')}></Form.Control>
                  <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_observaciones')}</Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className='buttonContainer'>
              <button className='confirmButton' disabled={isDisabled} >{t('botones.guardar')}</button>
              {/* <ModalConfirmacion typeModal='error' isOpen={showModal} setIsOpen={setShowModal} />                */}
            </div>
          </Form>
          <GenericModal typeModal={tipoModal} setIsOpen={setModalF} isOpen={modalF} mensaje={mensajeModal} />

        </Card.Body>
      </Card>


    </Fragment>
  )
}
