import React from 'react';
import ReactDatePicker from "react-datepicker";
import { useState } from "react";
import { BtnAyuda } from "./BtnAyuda";
import { Form } from "react-bootstrap";
import moment from "moment";
import './styles/controles.scss';

export default function RangeDatePicker({ item, handleInputChange, index, idx, reg, nombre, error, esEditable, type,  }) {

  /**
   * Fechas no válidas para el componente
   */

  const validate = [
    "",
    " ",
    "0000-00-00",
    "0000-00-00 00:00:00",
  ];

  /**
* Función que valida fechas
* frente a la lista negra que se envio
* si es el caso contrario envia un vacio.
* @param {*} value
* @returns
*/
  const validateDate = (value) => {
    if (validate.indexOf(value) !== -1) {
      return false;
    } else {
      return true;
    }
  }

  const [startDate, setStartDate] = useState((validateDate(item?.valor?.fecha_inicio) === true) ? moment(item.valor.fecha_inicio).toDate() : ""); //Almacena la fecha de inicio
  const [endDate, setEndDate] = useState((validateDate(item?.valor?.fecha_fin) === true) ? moment(item.valor.fecha_fin).toDate() : ""); //Almacena la fecha de fin

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
  const isValidStartDate = moment(formattedStartDate).isValid();
  const isValidEndDate = moment(formattedEndDate).isValid();
  const minMaxDate = validateDate(item?.fecha_min) === true && validateDate(item?.fecha_max) === true ? [{ start: moment(item.fecha_min).toDate(), end: moment(item.fecha_max).toDate()}] : [{ start: "-", end: "-"}];
  const isValidMinMaxDate = moment(minMaxDate[0].start).isValid() && moment(minMaxDate[0].end).isValid() && minMaxDate[0].end > minMaxDate[0].start;

  return (
    <div>
      <Form.Group style={{ display: 'block' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
          <Form.Label
            style={{
              color: item.label_color ? item.label_color : "#000",
              fontSize: item.font_size ? item.font_size : ""
            }}
          >
            {item.titulo}{item.es_requerido === true && '*'}
          </Form.Label>
          {item.ayuda &&
            <BtnAyuda ayuda={item.ayuda} />
          }
        </div>
        <ReactDatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          className='form-control'
          isClearable={true}
          disabled={esEditable}
          clearButtonClassName={esEditable ? 'd-none' : 'button-close-datepicker'}
          includeDateIntervals={isValidMinMaxDate ? minMaxDate : undefined}
          monthsShown={item.meses_mostrados ? item.meses_mostrados : 1}
        />
        <input type={"hidden"} name={`${nombre}.${item.nombre}.fecha_inicio`} value={isValidStartDate ? formattedStartDate : ""} ref={reg} {...reg(`${nombre}.${item.nombre}.fecha_inicio`, {
          required: item.es_requerido
        })}
        />

        <input type={"hidden"} name={`${nombre}.${item.nombre}.fecha_fin`} value={isValidEndDate ? formattedEndDate : ""} ref={reg} {...reg(`${nombre}.${item.nombre}.fecha_fin`, {
          required: item.es_requerido
        })}
        />

        {error ?
          (
            error.fecha_inicio?.type === 'required' || error.fecha_fin?.type === 'required' ?
              (
                <span className="inputsInvalid">
                  Este campo es requerido
                </span>
              )
              : null)
          : null}
      </Form.Group>
    </div>
  )
}
