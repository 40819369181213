import React from 'react';
import useState from 'react-usestateref';
import { Card, Row, Form } from 'react-bootstrap';
import { validarEntorno } from '../helpers/validarHost';
import Loader from './icons/Loader'
import { Resaltado } from './icons/Resaltado'
import { LeftSVG } from './icons/LeftSVG';
import { RightSVG } from './icons/RightSVG';
import InputType from '../helpers/InputType';
import { useForm } from 'react-hook-form';

import EditModal from './modales/EditModal';

import './ReportesOperativos/style/Tabla.scss';
// import GeneralWidgets from '../dashboard/GeneralWidgets';

const Tabla = ({ idTipoReporte, initialState, setInitialState }) => {

  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();

  const HASH = window.location.hash;

  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    data: {}
  })

  const [validated, setValidated] = useState(false);

  const [regSelected, setRegSelected] = useState('')

  const [allCheckedSelected, setAllCheckedSelected] = useState('');

  const [filtros, setFiltros, filtrosRef] = useState({
    limite: 10,
    pagina: 1
  });

  const [colores, setColores, coloresRef] = useState(initialState?.data?.resaltado ? initialState.data.resaltado : []);

  //FUNCION PARA PINTAR LOS RESALTADORES

  const resaltarMarker = async (tipo, color, id, idx) => {

    // console.log(tipo, id)
    const x = { ...initialState }
    const a = { ...x.data }
    const b = [...a.regs]
    const c = { ...b[idx] }
    c.valor_estado = c.valor_estado === '' ? tipo : '';
    c.resaltado = c.resaltado === '' ? color : '';
    b[idx] = c
    a.regs = b
    x.data = a
    await setInitialState(x)
    updateRegs()
  }

  //FUNCION PARA PINTAR TODOS
  const resaltarTodos = async (valor, color) => {

    // console.log(valor, color);

    const data = initialState.data
    const regs = initialState.data.regs

    const todosResaltados = regs.map((item) => {
      return {
        ...item,
        valor_estado: valor,
        resaltado: color
      }
    })

    data.regs = todosResaltados
    await setInitialState({ ...initialState, data: data, })
    updateRegs()
    setAllCheckedSelected(valor)
  }

  const updateRegs = async () => {

    const dataReq = {
      hash: HASH,
      data: initialState?.data
    }

    // console.log(dataReq)

    const req = await validarEntorno('JSON/ReportesOperativos/actualizar_rep_op_res.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      // console.log(res)
    } else {
    }

  }

  const filterData = async (e) => {

    setInitialState({ ...initialState, loading: true })

    const { name, value } = e.target;

    setFiltros({
      ...filtros,
      [name]: value
    });

    const dataReq = {
      hash: HASH,
      limite: filtrosRef.current.limite,
      pagina: filtrosRef.current.pagina,
    };

    // console.log(dataReq)

    const req = await validarEntorno('JSON/ReportesOperativos/rep_op_res.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, data: res, loading: false })
      // console.log(res)
    } else {
      setInitialState({ ...initialState, loading: false, error: req.error })
    }
  }

  const paginateData = async (page) => {
    setInitialState({ ...initialState, loading: true })
    setFiltros({
      ...filtros,
      pagina: page
    });

    const dataReq = {

      hash: HASH,
      limite: filtrosRef.current.limite,
      pagina: filtrosRef.current.pagina,
      tipo_reporte: idTipoReporte
    };

    // console.log(dataReq)

    const req = await validarEntorno('JSON/ReportesOperativos/rep_op_res.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, data: res, loading: false })
      // console.log(res)
    } else {
      setInitialState({ ...initialState, loading: false, error: req.error })
    }
  }

  const linkOrTxt = (valor) => {

    // console.log(valor)

    const http = 'http'
    const validacion = valor?.includes(http)

    if (validacion === true) {
      return (
        <a
          style={{ textAlign: 'center' }}
          target={'_blank'}
          rel="noreferrer"
          href={valor}
        >
          {valor}
        </a>
      )
    }

    return (
      <>{valor}</>
    )
  }


  const funcionx = (modal) => {
    setModal({ ...modal, show: true, data: modal, tipo: modal.tipo })
  }

  const onSubmit = async (data, e) => {

    setValidated(true)

    const { asesoria } = data;

    const arrayToObject = Object.assign({}, ...asesoria);


    const dataReq = {
      dataForm: arrayToObject,
      id: idTipoReporte,
      hash: HASH,
      id_reg: regSelected

    }

    // console.log(dataReq)

    setModal({ ...modal, show: false })
  }


  return (
    <>
      {initialState.data.file && initialState.data.file !== '' &&
        <a href={initialState.data.file} target='blank'>
          <button style={{ margin: '0 0 20px', width: '140px' }} className="confirmButton">Descargar archivo</button>
        </a>
      }
      {/* {(initialState.data?.widgets) ? (
                <div style={{ width: '100%', marginBottom: '20px' }} >
                    <GeneralWidgets cards={initialState.data.widgets} seccion={initialState.data.seccion} />
                </div>
            ) : ''} */}
      <Card className='generalCard' style={{ marginBottom: '10px' }} >
        {initialState.loading === true && initialState.error === null
          ?
          <Loader />
          :
          <Card.Body>
            <div id='header_tabla' className='shadow' >
              <div id='header_tabla-numero_registros'>

                <p> Registros: </p>
                <Form.Control
                  className='inputGroup'
                  id='cantidad_regs'
                  name='limite'
                  as='select'
                  onChange={(e) => { filterData(e) }}
                  value={filtros.limite}
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>150</option>
                </Form.Control>
              </div>
              <div id='header_tabla-paginado'>
                {initialState?.data?.pag_total > 1 &&
                  (
                    <div style={{ cursor: 'pointer' }} onClick={() => { paginateData(initialState?.data?.pag_ant) }}>
                      <LeftSVG />
                    </div>
                  )
                }
                <div className='paginadoItems' id='pagactualnum'>
                  <p>Pag</p>
                  <p>{initialState?.data?.pag_actual}</p>
                </div>
                <div className='paginadoItems' id='pag_total_num'>
                  <p>de</p>
                  <p>{initialState?.data?.pag_total}</p>
                </div>

                {initialState?.data?.pag_total !== initialState?.data?.pag_actual && (
                  <div style={{ cursor: 'pointer' }} onClick={() => { paginateData(initialState?.data?.pag_sig) }}>
                    <RightSVG />
                  </div>
                )}
              </div>
              <div id='header_tabla-total_registros'>
                <p>
                  Total de registros:
                </p>
                <p id='total_regs'>
                  {initialState?.data?.total_regs}
                </p>
              </div>
            </div>
            <div className='table_reps-container' id='tabla_reportes'>
              <div className='table_reps'>
                <div id='cols'>
                  {idTipoReporte !== '4' && (coloresRef?.current && coloresRef?.current?.length > 0) ? (
                    <div className='col_item' id='resaltado_col' >
                      <div className='colors_container' style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }} >
                        {coloresRef?.current?.map((item, index) => (
                          <div className='contenedor_resaltador'
                            style={{
                              backgroundColor: allCheckedSelected === item.valor ? item.color : '#f7f7f7',
                              cursor: 'pointer'
                            }}
                            onClick={() => resaltarTodos(item.valor, item.color)}
                          >
                            <Resaltado fill={allCheckedSelected === item.valor ? '#f7f7f7' : item.color} />
                          </div>
                        ))}
                      </div>
                    </div>) : null
                  }
                  {
                    initialState?.data?.cols?.map((col, idx) => {
                      return (
                        <div className='col_item' key={idx}>
                          {col}
                        </div>
                      )
                    })
                  }
                </div>
                <div id='regs'>
                  {
                    initialState?.data?.regs?.map((reg, idx) => {
                      return (
                        <div className='shadow reg_item' key={idx}
                          style={{ borderBottom: `4px solid ${reg.resaltado === 'verde' ? '#0AC51D' : reg.resaltado === 'amarillo' ? '#F5CF06' : '#f7f7f7'}` }}
                          onMouseOver={() => setRegSelected(reg.id)}
                        >
                          {idTipoReporte !== '4' && (coloresRef?.current && coloresRef?.current?.length > 0) ? (
                            <div className='col_item'>
                              <div className='colors_container' style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }} >
                                {coloresRef?.current?.map((item, index) => (
                                  <div className='contenedor_resaltador'
                                    style={{
                                      backgroundColor: reg.valor_estado === item.valor ? item.color : '#f7f7f7',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => resaltarMarker(item.valor, item.color, reg.id, idx)}
                                  >
                                    <Resaltado fill={reg.valor_estado === item.valor ? '#f7f7f7' : item.color} />
                                  </div>
                                ))}
                              </div>
                            </div>) : null
                          }
                          {reg?.cols?.map((colreg, idx) => {
                            return (
                              <>
                                <div
                                  className={`colreg ${colreg?.modal?.show && 'link_modal'} `}
                                  key={idx}
                                  style={{ cursor: colreg?.modal?.show && 'pointer', textDecoration: colreg?.modal?.show && 'underline' }}
                                  onClick={() => { (colreg?.modal && colreg?.modal?.show) ? funcionx(colreg?.modal) : console.log('no') }}
                                >
                                  {linkOrTxt(colreg.valor)}
                                </div>
                              </>
                            )
                          })
                          }

                        </div>
                      )
                    })
                  }

                </div>

              </div>


            </div>


          </Card.Body>

        }


      </Card>


      <EditModal
        isOpen={modal.show}
        setIsOpen={setModal}
      >
        <h2 className='pagesTitles' >{modal?.data?.contenido?.titulo}</h2>
        {modal.tipo === 'tabla' &&
          <div className='table_reps-container' id='tabla_reportes' style={{
            minHeight: '361px',
            display: 'flex',
            justifyContent: 'center',
            width: '96%'
          }}>
            <div className='table_reps' style={{ width: '100%', paddingRight: modal?.data?.contenido?.regs.length >= 10 ? '10px' : '0px' }}>
              <div id='cols'>
                {
                  modal?.data?.contenido?.cols?.map((col, idx) => {
                    return (
                      <div className='col_item' key={idx}>
                        {col}
                      </div>
                    )
                  })
                }
              </div>
              <div id='regs'>
                {
                  modal?.data?.contenido?.regs?.map((reg, idx) => {
                    return (
                      <div className='shadow reg_item' key={idx}
                      // style={{borderBottom : `4px solid ${ reg.resaltado === 'verde' ? '#0AC51D' : reg.resaltado === 'amarillo' ? '#F5CF06' : '#f7f7f7' }` }}
                      >
                        {reg?.cols?.map((colreg, idx) => {
                          return (
                            <div
                              className='colreg'
                              style={{ overflow: 'hidden' }}
                              key={idx}
                            // style={{ cursor: colreg?.modal?.show && 'pointer' }}
                            // onClick={ () => { colreg?.modal?.show ? funcionx(colreg?.modal) : console.log('no') }}
                            >
                              {linkOrTxt(colreg?.valor)}
                            </div>
                          )
                        })
                        }

                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        }
        {modal.tipo === 'form' &&
          <>
            <Form noValidated onSubmit={handleSubmit(onSubmit)}
              style={{
                width: '100%',
                padding: '0',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  width: '100%',
                  margin: '0 auto',
                  padding: '0',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}

              >

                {modal.data.campos.map((campo, index) => {
                  const fieldName = `asesoria.0`;
                  return (
                    <>
                      <InputType
                        item={campo}
                        index={index}
                        idx={index}
                        key={`${0} - ${index}`}
                        reg={register}
                        nombre={`${fieldName}`}
                        errors={errors}
                        col={campo.col}
                        type=""
                      />
                    </>
                  )
                })
                }
              </div>
              <div style={{ display: 'flex', marginTop: '1em', justifyContent: 'center' }}>
                {/* <button type="button" className="configButton" onClick={()=> {sendConfig()}}>Guardar Configuración</button> */}
                <button
                  type="submit"
                  style={{ margin: '20px 0 0 0' }} className="confirmButton">Guardar</button>
              </div>
            </Form>
          </>
        }
      </EditModal>


    </>


  );
}

export default Tabla;
