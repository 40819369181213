import { React, useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { HelpSVG } from '../../../components/icons/HelpSVG';
import { PerfilIconSVG } from '../../../components/icons/PerfilIconSVG';
import  PestanasDinamicas from '../../../components/gerenciaGeneral/inteligencia/PestanasDinamicas';

import AyudaPerfilModal from '../../../components/modales/AyudaPerfilModal'
import { makeStyles } from '@material-ui/core/styles';

// import { useDispatch, useSelector } from 'react-redux'
// import {fetchProfileAction}  from '../../../reducers/profileReducer'
// import { changePassword } from '../../../reducers/profileReducer'
import { validarEntorno } from "../../../helpers/validarHost";
import {useTranslation} from "react-i18next";

import './Perfil.scss'
// import { SignalCellularNullRounded } from '@material-ui/icons';

export default function Perfil() {


  const { t } = useTranslation();
  const rutaPerfil = window.location.hash

  // const dataPerfil = useSelector(store => store.profileReducer)
  // const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [response, setResponse] = useState({})


  // const [showModal, setShowModal] = useState(false);
  // const [pestaña, setPestaña] = useState('cambiarContrasena');
  // const [fuente, setFuente] = useState({
  //   fontSize: 16,
  // })

  const getDataPerfil = async (ruta) => {
    console.log(ruta)
    try {
      const requestRuta = await validarEntorno('JSON/ejemplo_data_perfil_json.html', 'POST', ruta)
      const res = await requestRuta.json()

      if (requestRuta.ok) {
        setOpen(res.showModal)
        setResponse(res)
      }
    } catch (error) {
      console.log(error.message)
    }
  }


  useEffect(() => {
    // dispatch(getDataPerfil(rutaPerfil))
    getDataPerfil(rutaPerfil)


  }, [])

  // const { response } = dataPerfil

  // useEffect(() => {
  //   setOpen(response.showModal ? response.showModal : false)
  // }, [])





  // const [tipoModal, setTipoModal] = useState('')

  // const handleFontSize = (e, value) => {

  //   setFuente({ fontSize: value })


  //   const root = document.getElementById('root');


  //   root.style.cssText = ` font-size: ${fuente.fontSize}px `;



  // }





  const useStyles = makeStyles((theme) => ({
    root: {
      width: 600,
    },
    margin: {
      height: theme.spacing(3),
    },
  }));

  // const marks = [
  //   {
  //     value: 10,
  //     label: '10px',
  //   },
  //   {
  //     value: 12,
  //     label: '12px',
  //   },
  //   {
  //     value: 14,
  //     label: '14px',
  //   },
  //   {
  //     value: 16,
  //     label: '16px',
  //   },
  //   {
  //     value: 18,
  //     label: '18px',
  //   },
  //   {
  //     value: 20,
  //     label: '20px',
  //   },
  //   {
  //     value: 22,
  //     label: '22px',
  //   },
  //   {
  //     value: 24,
  //     label: '24px',
  //   },

  // ];

  // function valuetext(value) {
  //   return `${value}px`;
  // }

  // const classes = useStyles();

  return (
    <div >
      <div className='titleBox' >
        <div className='titleContainer'>
          <h3 className='pagesTitles'>{t('formulario.label.perfil')}</h3>

        </div>
        <div className='iconoAyuda' onClick={() => setOpen(true)} >
          <HelpSVG />
        </div>
      </div>

      <br />
      <Card id='infoGeneralPerfil' className="shadow " style={{ borderRadius: '10px' }}>
        <Card.Body>
          <Row xs={12} className='dataBoxContainer'>
            <Col xs={11} md={3} id='iconPerfilContainer'>
              <PerfilIconSVG />
            </Col>
            <Col xs={12} md={4} className='listPopUp '>
              <div className='dataBox'>
                <p className='dataTitle'>{t('formulario.label.nombres')}:</p>
                <span className='dataProfile'>{response.nombres || ''}</span>
              </div>
              <div className='dataBox'>
                <p className='dataTitle'>{t('formulario.label.apellidos')}:</p>
                <span className='dataProfile'>{response.apellidos || ''}</span>
              </div>

              <div className='dataBox'>
                <p className='dataTitle'>{t('formulario.label.correo')}:</p>
                <span className='dataProfile'>{response.correo || ''}</span>
              </div>
            </Col>
            <Col xs={12} md={5} className='listPopUp'>
              <div className='dataBox'>
                <p className='dataTitle'>{t('formulario.label.usuario')}:</p>
                <span className='dataProfile'>{response.usuario || ''}</span>
              </div>
              <div className='dataBox'>
                <p className='dataTitle'>{t('formulario.label.perfil')}:</p>
                <span className='dataProfile'>{response.perfil || ''}</span>
              </div>
              <div className='dataBox'>
                <p className='dataTitle'>{t('formulario.label.centro_costos')}:</p>
                <span className='dataProfile'>{response.centroCostos || ''}</span>
              </div>

            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />


      <PestanasDinamicas selectSeleccionado={null} pestanas={response.pestanas} padre={'perfil'}  />
      <AyudaPerfilModal dataModal={response} open={open} setOpen={setOpen} />
    </div>
  )
}
