import React, {useState, useEffect} from 'react'
import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


/* Chart code */
// Themes begin
am4core.useTheme(am4themes_kelly);
am4core.useTheme(am4themes_animated);

export default function PartitionedBarChart(props) {


  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')
  
  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);
    // Add data
  
    chart.colors.list = [
      am4core.color("#2c5e99"),
      am4core.color("#05a4db"),
      am4core.color("#009688"),
      am4core.color("#8BC34A"),
      am4core.color("#F9CF00"),
      am4core.color("#F39C11"),
      am4core.color("#F46036"),
      am4core.color("#D7263D"),
      am4core.color("#674ea7")
    ];
  
  
    // Create axes
    let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = "caracteristica";
    yAxis.renderer.grid.template.location = 0;
    yAxis.renderer.labels.template.fontSize = 12;
    yAxis.renderer.labels.template.fontWeight = 700;
    yAxis.renderer.minGridDistance = 10;
  
  
  
  
    let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
  
  
    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = "valor";
    series.dataFields.categoryY = "caracteristica";
    series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
    series.columns.template.strokeWidth = 0;
    
    series.columns.template.column.cornerRadiusBottomRight = 15;
    series.columns.template.column.cornerRadiusTopRight = 15;
    series.columns.template.adapter.add("fill", function(fill, target) {
      if (target.dataItem) {
        switch(target.dataItem.dataContext.seccion) {
          case "amenazas":
            return chart.colors.getIndex(5);
            break;
          case "debilidades":
            return chart.colors.getIndex(4);
            break;
          case "oportunidades":
            return chart.colors.getIndex(1);
            break;
          case "fortalezas":
            return chart.colors.getIndex(3);
            break;
          default:
            break;
        }
      }
      return fill;
    });

    let labelBullet = series.bullets.push(new am4charts.LabelBullet())
    labelBullet.label.horizontalCenter = "left";
    labelBullet.label.truncate = false;
    labelBullet.label.hideOversized = false;
    labelBullet.label.dx = 10;
    labelBullet.label.text = props.labels ? "{label}" : "{values.valueX.workingValue.formatNumber('#')}";
    labelBullet.label.fill = '#000';
    labelBullet.label.fontWeight = '700';
    labelBullet.label.fontSize = '16px';
    labelBullet.locationX = 0;

    
    
    
    
    
    
    let axisBreaks = {};
    let legendData = [];
    
    // Add ranges
    function addRange(label, start, end, color) {
      let range = yAxis.axisRanges.create();
      range.category = start;
      range.endCategory = end;
      range.label.text = label;
      range.label.disabled = false;
      range.label.fill = color;
      range.label.location = 0;
      range.label.dx = -210;
      range.label.dy = 7;
      range.label.fontWeight = "bold";
      range.label.fontSize = 12;
      range.label.horizontalCenter = "left"
      range.label.inside = true;
      
      range.grid.stroke = am4core.color("#396478");
      range.grid.strokeOpacity = 1;
      range.tick.length = 200;
      range.tick.disabled = false;
      range.tick.strokeOpacity = 0.6;
      range.tick.stroke = am4core.color("#396478");
      range.tick.location = 0;
      
      range.locations.category = 1;
      let axisBreak = yAxis.axisBreaks.create();
      axisBreak.startCategory = start;
      axisBreak.endCategory = end;
      axisBreak.breakSize = 1;
      axisBreak.fillShape.disabled = true;
      axisBreak.startLine.disabled = true;
      axisBreak.endLine.disabled = true;
      axisBreaks[label] = axisBreak;  
    
      legendData.push({name:label, fill:color});
    }
    
    
    chart.cursor = new am4charts.XYCursor();
    
    
    let legend = new am4charts.Legend();
    legend.position = "bottom";
    legend.scrollable = true;
    legend.valign = "top";
    legend.reverseOrder = true;
    
    chart.legend = legend;
    legend.data = legendData;
    
    legend.itemContainers.template.events.on("toggled", function(event){
      let name = event.target.dataItem.dataContext.name;
      let axisBreak = axisBreaks[name];
      if(event.target.isActive){
        axisBreak.animate({property:"breakSize", to:0}, 1000, am4core.ease.cubicOut);
        yAxis.dataItems.each(function(dataItem){
          if(dataItem.dataContext.seccion == name){
            dataItem.hide(1000, 500);
          }
        })
        series.dataItems.each(function(dataItem){
          if(dataItem.dataContext.seccion == name){
            dataItem.hide(1000, 0, 0, ["valueX"]);
          }
        })    
      }
      else{
        axisBreak.animate({property:"breakSize", to:1}, 1000, am4core.ease.cubicOut);
        yAxis.dataItems.each(function(dataItem){
          if(dataItem.dataContext.seccion == name){
            dataItem.show(1000);
          }
        })  
    
        series.dataItems.each(function(dataItem){
          if(dataItem.dataContext.seccion === name){
            dataItem.show(1000, 0, ["valueX"]);
          }
        })        
      }
    })
    chart.data = props.data;



  }, [props.data])

  return (
    <Card className="" style={{height: '90%', width:'99%'}}>
      <Card.Body style={{height:'100%'}}>
        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> 
          {props.title} 
          <br/>
          {props.parametrizado
            ?
              <>
                {props.parametrizado} - {props.periodo }
              </>

            : null
          }
        </h4>        

        <div id={props.id} style={{ width: '100%',minHeight:'100%', margin: '0 auto', overflowX:'auto' }}></div>

      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
