import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { IconSVG } from '../icons/CloseSVG'

export const ModalTablaRows = ({ open, setOpen, data }) => {


    const handleClose = () => {
        setOpen(!open)
    }


    return (
        <Modal id='modalDetallesTabla' show={open} onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='modalesGenerales'

        >
            <Modal.Body>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'rgb(33, 50, 101)' }}>
                    <div>
                        <h4 style={{ fontWeight: '900' }}> {data.titulo}</h4>
                    </div>
                    <div style={{ textAlign: 'right' }} onClick={handleClose}>
                        <IconSVG />
                    </div>
                </div>

                <div className="container mt-5">
                    <dl>
                        {
                            data?.data?.map(ele => {
                                return (
                                    <div style={{ background: '#e0e0e0', borderRadius: '7px', color: 'rgb(33, 50, 101)' }}>
                                        <dt className="mt-3 pt-2 pl-3">
                                            {
                                                <h4>{ele.subTitulo}</h4>
                                            }
                                        </dt>


                                        <dd className=" pt-2 pl-3 pb-2" style={{ background: '#cbd7e6', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px' }}>
                                            {ele.link !== ""
                                                ?
                                                <a rel='noreferrer' href={ele.link} style={{ color: 'black' }} target="_blank">{ele.item}</a>
                                                : <p>{ele.item}</p>
                                            }
                                        </dd>

                                    </div>

                                )
                            })
                        }
                    </dl>
                </div>

            </Modal.Body>
        </Modal>
    )
}
