// peticiones sin token
export const peticionSinToken = async(url, tipoPeticion = "GET", data) => {

    if (tipoPeticion === "GET") {
        return await fetch(url)
    }
    // console.log(data)
    if (tipoPeticion !== "GET") {
        return await fetch(url, {
            method: tipoPeticion,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)

        })
    }
    
    if (data === null || data === undefined) {
        return await fetch(url, {
            method: tipoPeticion,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    }
    
    return await fetch(url, {
        method: tipoPeticion,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

}


export const peticionConToken = (url, tipoPeticion = "GET", data = {}) => {

    if (tipoPeticion === "GET") {
        return fetch(url, {
            method: tipoPeticion,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('x-token') || ''
            },

        });

    } else {
        return fetch(url, {
            method: tipoPeticion,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('x-token') || ''
            },
            body: JSON.stringify(data)
        })
    }
}