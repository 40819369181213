import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { Form, Card } from 'react-bootstrap';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal'
import { useTranslation } from 'react-i18next';


export const SolicitudObservacion = ({ ele }) => {

  const { t } = useTranslation();
  const { id } = useParams()


  const [changeForm, setChangeForm] = useState({
    observacion: ''
  })

  const [confirmacionEnvio, setConfirmacionEnvio] = useState(false)

  useEffect(() => {
    setChangeForm({
      observacion: ''
    })
  }, [])

  const handleChange = ({ target }) => {
    setChangeForm({
      ...changeForm,
      observacion: target.value

    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const data = {
      hash: window.location.hash,
      idSolicitud: id,
      formData: changeForm
    }


    const envioSolicitudObservacion = await validarEntorno('JSON/solicitud_observacion.html', 'POST', data)

    if (envioSolicitudObservacion.ok) {

      const respuesta = await envioSolicitudObservacion.json()

      if (respuesta.solicitud === 'ok') {
        setConfirmacionEnvio(true)
        setChangeForm({
          observacion: ''
        })
      }

    }

  }



  return (
    <>
      <Card className='card__box__surface p-5 card__plain__top__left'>

        <Card.Body className='p-0'>
          <h5 className='titleCard'>{t('formulario.label.solicitud_observacion_objecion')}</h5>
          <br />
          <div className='contenedorTextAreaGeneral'>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='formGroupTextArea'>
                <Form.Label className='observacionesLabel' style={{ marginLeft: '0px' }}>
                  {t('formulario.label.observaciones')}
                </Form.Label>
                <br />
                <Form.Control
                  required
                  disabled={!ele.editar}
                  className='textAreaGeneral'
                  name='observaciones'
                  id='observacionesTextArea'
                  as='textarea'
                  placeholder={t('formulario.label.observaciones')}
                  onChange={(e) => handleChange(e)}
                  defaultValue={ele.valuesInputs.observaciones}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
              </Form.Group>
              <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                {changeForm.observacion !== ''
                  ?
                  <button type="submit" className='botonAddBeneficiario confirmButton'>
                    {t('botones.guardar')}
                  </button>
                  :
                  <button disabled className='botonAddBeneficiario botonDeshabilitado'>
                    {t('formulario.label.para_solitar_observacion_objecion')}
                  </button>
                }

              </div>

            </Form>
          </div>
        </Card.Body>
      </Card>
      <NotificationModal
        typeModal='crearSolicitud'
        isOpen={confirmacionEnvio}
        setIsOpen={setConfirmacionEnvio}
      />
    </>
  )
}
