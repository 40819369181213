import React, {useEffect, useState} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { renderToString } from 'react-dom/server';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {GoogleMap} from 'react-google-maps';
import Heatmap from '../../helpers/Mapas/PDF/Heatmap';
import {credenciales} from '../../helpers/credentials.google';
import {validarEntorno} from '../../helpers/validarHost'
import Loader from '../icons/Loader';
import Graphs from './Graficas';
import { PDFViewer, Document, StyleSheet, Page, Text } from '@react-pdf/renderer';

//plantillas
import {PanoramaRiesgos} from './plantillas/PanoramaRiesgos'
import {Porvenir} from './plantillas/Porvenir'



const GeneradorPDF = ({id, data, graficas, plantilla, show, update}) => {


  const [initialState, setInitialState] = useState({
    error: null,
    data: {},
    loading: false
  })

  const HASH = window.location.hash
  
  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;

  const ubicacionBase = {
    lat: 1.206845,
    lng: -77.2756767
  };

  const containerStyle = {
    width: '550px',
    height: '400px'
  };

  const styles = StyleSheet.create({
    viewer: {
      height:'81vh'
    },
  })


  const maps = () => (
    data.map((item, index) => (
      <>
        {/* <Col xs={6}>
          <Heatmap 
            googleMapURL= {mapURL}
            containerElement = {<div style={containerStyle}></div>}
            mapElement = {<div style={{height:'100%'}}></div>}
            loadingElement = {<Loader />}
            lat={ubicacionBase.lat}
            lng={ubicacionBase.lng}
            zoom={10}
            data={item}
          />
        </Col> */}
      </>
    ))
  );

  const graphs = () =>  (
    graficas.map((item, index) => (
      <>
        <Col xs={6}>
          <Graphs 
            data={item}
          />
        </Col>
      </>
    ))
  ) 

  const printDocument = async () => {
    const inputMaps = document.getElementById('pdf-maps');
    const html2Maps = await html2canvas(inputMaps, {useCORS: true});
    const imgDataMaps = html2Maps.toDataURL('image/jpeg,1.0');

    const inputGraphs = document.getElementById('pdf-graphs');
    const html2Graphs = await html2canvas(inputGraphs, {useCORS: true});
    const imgDataGraphs = html2Graphs.toDataURL('image/jpeg,1.0'); 
  }

  const getDocumento = async () => {
    const dataReq = {
      hash: HASH,
      tipo: plantilla,
      id: id
    }

    const req = await validarEntorno('JSON/GestorPDF/generar_pdf.html', 'POST', dataReq)

    if(req.ok){
      const res = await req.json()

      // console.log(res)

      setInitialState({...initialState, 
        data: plantilla === 'panorama' 
        ?  res?.panorama 
        : res
      
      })
      
    }
  }

  const sendFile = async (file) => {
    const dataReq = {
      hash: HASH,
      id: id,
      file: file
    }

    // console.log(dataReq)

    const req = await validarEntorno('JSON/GestorPDF/guardar_pdf.html', 'POST', dataReq)

    if(req.ok){
      const res = await req.json()

      if(res.estado === 'ok'){
      
      }else{

      }

    }
  }


  const setPlantilla = tipo => {

    // console.log(tipo)

    switch (tipo) {
      case 'porvenir':
        return <Porvenir data={initialState.data} />
    
      case 'panorama':
        return <PanoramaRiesgos id={id} data={initialState?.data} show={show} update={update} sendFile={sendFile} />
      default:
        <Page>
          <Text>No es ninguno</Text>
        </Page>
        break;
    }

  }

  useEffect(() => {
    
    getDocumento();
    // plantilla = 'porvenir'

  }, [])

    return (
      <>
       {setPlantilla(plantilla)}
      </>
    )
}

export {GeneradorPDF};