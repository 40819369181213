import React, { useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Card } from 'react-bootstrap';
import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG';
// import {AMCHART} from '../../helpers/amchartLicense' 



am4core.useTheme(am4themes_animated);
// am4core.addLicense(AMCHART);


export const FullDonut3D = (props) => {

    const [open, setOpen] = useState(false)

    const [popUp, setPopUp] = useState('')


    useEffect(() => {


        let chart = am4core.create(props.id, am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.data = props.data;
        chart.colors.list = [
            am4core.color("#2c5e99"),
            am4core.color("#05a4db"),
            am4core.color("#8BC34A"),
            am4core.color("#009688"),
            am4core.color("#F9CF00")
        ];

        chart.radius = am4core.percent(60);

        chart.innerRadius = am4core.percent(30);
        chart.depth = 100;

        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.useDefaultMarker = true;
        chart.legend.maxWidth = 300;
        chart.legend.valueLabels.template.text = "{percent}";

        chart.legend.labels.template.text = "{category}";
        // chart.legend.labels.template.text = "Series: [bold {color}]{name}[/]";
        // chart.legend.labels.template.text = " [bold {color}]{name}: {litres}";



        let series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "litres";
        series.dataFields.depthValue = "litres";
        series.dataFields.category = "country";
        series.slices.template.propertyFields.fill = "fill";

        // series.labels.template.disabled = true;
        // series.ticks.template.disabled = true;

        series.slices.template.cornerRadius = 5;
        series.colors.step = 3;
        series.slices.template.tooltipText = "{category}: {value}";
        series.labels.template.text = "[bold]{category}: {value.percent.formatNumber('.')}%";

        // series.renderer.inside = true;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.verticalAlign = "top";
        // chart.exporting.menu.horizontalAlign = "left";
        chart.exporting.formatOptions.getKey("svg").disabled = true;

        chart.exporting.menu.items = [{
            "label": "...",
            "menu": [
                { "type": "png", "label": "Imagen" },
                { "type": "print", "label": "Imprimir" }
            ]
        }];

    }, [])

    return (
        <Card className="shadow cardGraficaGeneral cardGraficaTipos">
            {/*   <div className="d-flex flex-row-reverse bd-highlight p-1" style={{ cursor: 'pointer' }}
                onClick={() => setOpen(true)}
            >
                <HelpSVG className="bd-highlight" />
            </div> */}
            <br />
            <Card.Body>
                <h4 className='pagesTitles' style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>{props.title ? props.title : "Solicitudes"}
                    <br />
                    {props.parametrizado
                        ?
                        <>
                            {props.parametrizado} - {props.periodo}
                        </>

                        : null
                    }
                </h4>
                <br />
                <div id={props.id} style={{ width: '95%', minHeight: '360px', margin: '0 2.5% ' }}></div>
                <br />
                <h4 className='pagesTitles' >
                    Total: {props.total}
                </h4>
            </Card.Body>
            <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />

        </Card>
    )
}
