import React, {useState, useEffect} from 'react'

import { Form, Card, Col, Row } from 'react-bootstrap';

import { validarEntorno } from '../../../helpers/validarHost';
import OpcionesCiudades from '../../../helpers/OpcionesCiudades';

import Select from 'react-select';
import NotificationModal from '../../modales/NotificationModal'

import './styles/SolicitudVisita.scss'

export default function SolicitudVisita(props) {

  // const {cuentas, encargados} = props.contenido

  // ////console.log(props.contenido)

  const [tipoModal, setTipoModal] = useState({
    tipo: '',
    identificador: '',
    mensaje: ''
  });
  const [modalConfirmacionSolicitud, setModalConfirmacionSolicitud] = useState(false);
  const [validarEmpresa, setValidarEmpresa] = useState(true);
  const [validated, setValidated] = useState(false);
  const [formulario, setFormulario] = useState({
    nombre: '',
    nit: '',
    tipo_id: '',
    identificacion: '',
    nombre_rep: '',
    apellido_rep: '',
    direccion: '',
    email: '',
    telefono: '',
    fecha_visita: '',
    cod_cartera: '',
    num_cartera: '',
    tipo_cartera: '',
    observacion : ''
  });
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    transformarOpciones()
  }, [])

  const transformarOpciones = () => {
    
  }

  // const {empresas} = props.contenido

  const handleDataFormulario = ({target}) => {
    setFormulario({
      ...formulario,
      [target.name] : target.value 
    })

    ////console.log(formulario)
  }

  const handleSelectCuenta = (value) => {
    
      setFormulario({
        ...formulario,
        cuenta : value.value 
      })
    

    ////console.log(value)
    ////console.log(formulario)
  }

  const handleSelectEncargado = (value) => {
    
    setFormulario({
      ...formulario,
      encargado : value.value 
    })
  

  ////console.log(value)
  ////console.log(formulario)
}

  const enviarFormulario = async (e) => {

    e.preventDefault()

    const form = e.currentTarget;

    if (form.checkValidity() === false ) {
        e.preventDefault();
        if(formulario.id_empresa === ''){
          setValidarEmpresa(false)
        }

    }
    setValidated(true)
    setIsDisabled(true)

    
    if (form.checkValidity() === true) {
      
      setValidarEmpresa(false)
      

      const dataReq = {
        hash: window.location.hash,
        formulario

      } 

      ////console.log(dataReq)

      const req = await validarEntorno('JSON/sol_visita.html','P0ST', dataReq)

      ////console.log(req)
      
      if(req.ok){
        const res = await req.json()
        // registroAuditoriaCreado
        if(res.estado === 'ok'){
          setTipoModal({
            ...tipoModal,
            tipo: 'registroSolVis',
            identificador : res.idSolicitud
          })
          setModalConfirmacionSolicitud(true)

        } else if(res.estado === 'false'){
          setTipoModal({
            ...tipoModal,
            tipo: 'registroAuditoriaError',
            mensaje: res.mensaje
          })
          setModalConfirmacionSolicitud(true)
        }
        setIsDisabled(false)
      }else{
        setTipoModal({
          ...tipoModal,
          tipo: 'errorActualizacionDofa'
        })
        setIsDisabled(false)
      }

    }





  }


  return (
    <Card 
      className='shadow cardContainerSolicitud' 
      style={{borderRadius: '0px 10px 10px 10px'}}
    >
      <h5 className='titleCard'>Información de solicitud</h5>

      <Card.Body>
        <Form  className='formEstudioBasicoContainerSolVis' noValidate validated={validated} onSubmit={enviarFormulario}>
              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Nombre de la empresa
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='nombre' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa el nombre de la empresa </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      NIT
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='nit' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa el NIT </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                     Tipo ID representante legal
                  </Form.Label>
                  <div className='inputContainer'>
                    <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='tipo_id'  as='select'
                      isInvalid={validarEmpresa}
                      isValid={!validarEmpresa}
                      defaultValue={''}
                      >
                        <option value='' disabled>-- Seleccione -- </option>
                        <option value="CC">Cedula de ciudadanía</option>
                        <option value="CE">Cédula extranjera</option>
                        <option value="PA">Pasaporte aéreo</option>
                        <option value="AS">A.S</option>
                    </Form.Control>
                    
                    <Form.Control.Feedback  type='invalid'>Ingresa el tipo de identificación </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Identificación representante legal
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='identificacion' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Nombres
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='nombre_rep' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Apellidos
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='apellido_rep' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              
          <div id='observacionesContainerEstudioBasico'>
                <Form.Group id='observacionesFormGroup'>
                  <Form.Label className='observacionesLabel'>
                      Dirección
                  </Form.Label>
                  <div className='textAreaContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='direccion' id='observacionesTextArea' required as='textarea' placeholder='Descripción'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>

              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Email
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='email' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Teléfonos
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='telefono' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <OpcionesCiudades
                  onChange={(e) => handleDataFormulario(e)}
                />
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Fecha visita
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      type='date'
                      name='fecha_visita' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Código cartera
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='cod_cartera' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='nitEmpresa' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Número cartera
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='num_cartera' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group  id='' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Tipo tarifa
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                        onChange={(e) => handleDataFormulario(e)} 
                        as='select'
                        name='tipo_tarifa' required 
                        defaultValue=''
                      >

                      <option value='' disabled>-- Seleccione -- </option>
                        <option value="CC">1</option>
                        <option value="CE">2</option>
                        <option value="PA">3</option>
                        <option value="AS">4</option>

                      </Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <div id='observacionesContainerSolInv'>
                <Form.Group id='observacionesFormGroup'>
                  <Form.Label className='observacionesLabel'>
                      Observación
                  </Form.Label>
                  <div className='textAreaContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='observacion' id='observacionesTextArea' required as='textarea' placeholder='Descripción'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>


          <div className='buttonContainers'>
              <button className='confirmButton' disabled={isDisabled} >Guardar</button>
          </div>
      </Form>
      </Card.Body>
      {
        modalConfirmacionSolicitud === true &&
          <NotificationModal infoNotificacion={tipoModal.mensaje} identificador={tipoModal.identificador} typeModal={tipoModal.tipo} isOpen={modalConfirmacionSolicitud} setIsOpen={setModalConfirmacionSolicitud} />
      }
      
    </Card>
  )
}
