import React from 'react';
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '../../ui/Chart';

// El componente ahora recibe data, labels y colors como props
export function BarStacked({ data, labels, colors }) {
  // Construir la configuración del gráfico usando labels y colors
  const chartConfig = labels.reduce((config, label, index) => {
    config[label] = {
      label,
      color: colors[index] || 'var(--blue-600)', // Color por defecto si no se proporciona
    };
    return config;
  }, {});

  return (
    <ChartContainer config={chartConfig}>
      <BarChart accessibilityLayer data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="x"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)} // Puedes ajustar esto si es necesario
        />
        <ChartTooltip content={<ChartTooltipContent hideLabel />} />
        <ChartLegend content={<ChartLegendContent />} />
        {labels.map((label, index) => (
          <Bar
            key={label}
            dataKey={label}
            stackId="a"
            fill={colors[index] || 'var(--blue-600)'} // Color por defecto si no se proporciona
            radius={[4, 4, 0, 0]}
          />
        ))}
      </BarChart>
    </ChartContainer>
  );
}
