import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import InputType from "../../helpers/InputType";
import { validarEntorno } from "../../helpers/validarHost";
import Loader from "../icons/Loader";
import { getDataFiltros, inputFilterOcensa } from "../../reducers/inputFilterOcensa"
import { useDispatch } from "react-redux";
import ModalHelp2 from "../../helpers/ModalHelp2";
import { t } from "i18next";
const FiltroOcensa = (props) => {
  const [inputSelectO, setInputSelectO] = useState(JSON.parse(localStorage.getItem('select')));


  const { filter } = useSelector(store=> store.inputFilterOcensa)
  const dispatch = useDispatch()

  // const [inputSelectO, setInputSelectO] = useState(JSON.parse(localStorage.getItem('select')));
  const [data, setData] = useState([]);

  const { id } = useParams()
  const hash = window.location.hash
  useEffect(() => {
    getInfo();
  }, []);



  const getInfo = async () => {
    const get = await fetch("JSON/getSelects.html");
    const data = await get.json();
    // console.log(data.selects);
    setInputSelectO(filter)
    setData(data.selects);
  };

  function handleOnChange(e) {

    setInputSelectO({
      ...inputSelectO,
      [e.target.name]: e.target.value,
    });
    console.log(inputSelectO)
  }

  async function sendData() {

    getDataInicial()
    // dispatch(getDataFiltros(inputSelectO))
    // // setInputSelectO({...dataInputs})
    // // console.log({...dataInputs})
  }

  const getDataInicial = async () => {

    props.setBanderaLoader(true)
    dispatch(getDataFiltros(inputSelectO))
    const dataReq = {
      id: id,
      hash: hash,
      filtros: inputSelectO
    }

    // console.log(dataReq)

    props.setBanderaButton(false)
    props.setFiltrosUpdate(inputSelectO)

  const req = await validarEntorno('JSON/per_panel.html', 'POST', dataReq)

    // const reque = await validarEntorno('JSON/update_timeline.html', 'POST', dataReq)

    // if (req.ok && reque.ok) {
      if(req.ok){
      const res = await req.json()

      // console.log(res)

      props.setDataInicial({
        ...props.dataInicial,
        data: res,
        riesgos: res.riesgos,
        titulo: res.titulo_plan,
        objetivos: res.contenido.objetivos,
        fecha_inicio: res.fecha_inicio,
        fecha_fin: res.fecha_fin,
      })


      if (res.linea_tiempo) {
        props.setDataTimeline(res.linea_tiempo)
      }

      props.setChangeTitle({
        ...props.changeTitle,
        nuevo_titulo: res.titulo_plan
      })
      props.setBanderaLoader(false)
    }
    props.setBanderaLoader(false)





  // const requ = await validarEntorno('JSON/update_timeline.html', 'POST', dataReq)

  // if (req.ok) {
  //   const res = await req.json()

  const reque = await validarEntorno('JSON/update_timeline.html', 'POST', dataReq)


  if(inputSelectO < 0){
    console.log('este es el error de inputSelects en update')
  } else{
    if  (reque.ok)  {
        let res = await reque.json();

        console.log(res)

        props.setDataTimeline({

          data: res?.data
        })
        console.log('dataReq de updatetimeline',dataReq)
        // console.log(res)
      }
  }

  // if  (reque.ok)  {
  //   let res = await reque.json();

  //   console.log(res)

  //   props.setDataTimeline({

  //     data: res?.data
  //   })
  //   console.log('dataReq de updatetimeline',dataReq)
  //   // console.log(res)
  // }


  }




    // const req = await validarEntorno('JSON/update_timeline.html', 'POST', dataReq)

    // if  (req.ok)  {
    //   let res = await req.json();

    //   console.log(res)

    //   setDataTimeline({

    //     data: res?.data
    //   })

    // }


  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div className="contenedorFiltros" style={{ width: '90%', padding: "0%", margin:"0%", height: "50%"}} >

          {data.length > 0 &&
            data.map((sel, i) => {
              //esta intentando renderizar map antes de cargarse
              return (

                <div className="filtroItem form-group">
                  <label style={{ flex: '0 0 auto', color: "rgba(4,4,70,0.8836485277704832)", fontWeight:"bolder" }}>{sel.value}</label>
                  <select
                    id="component-select"
                    defaultValue={""}
                    className="form-control  form-control"
                    style={{margin:"0"}}
                    name={sel.name}
                    value={inputSelectO[sel.name]}
                    onChange={handleOnChange}
                  >
                    
                    {sel?.options?.map((opt, i) => {
                      return <option value={opt.value}>{opt.text}</option>;
                    })}
                  </select>
                </div>
              );
            })}
        <div style={{paddingBottom:"10%"}}>
        <ModalHelp2 />
        </div>
        </div>
        <div style={{paddingRight:"3%", paddingTop:"1%"}}>
        <button className="confirmButton" onClick={() => sendData()}>{t('botones.enviar')}</button>
        </div>
        </div>


    </>
  );
};

export default FiltroOcensa;


