import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';

import { Form, Card } from 'react-bootstrap';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal'


export const AprobarObservacion = ({ele}) => {

  const { id } = useParams()



  // Estado que toma los cambios en el form
  const [changeForm, setChangeForm] = useState({
    aprobacion: '',
    descripcion: '',
    observacion: ''
  })
  // Estado para controlar el modal de confirmación
  const [confirmacionEnvio, setConfirmacionEnvio] = useState(false)

  useEffect(() => {
    setChangeForm({
      aprobacion: '',
      descripcion: '',
      observacion: ''
    })
  }, [])

  // Función que actualiza el estado de cambios del form
  const handleChange = ({target}) => {
    setChangeForm({
      ...changeForm,
      [target.name]: target.value
      
    })
  }

  // Función que envia el form
  const handleSubmit = async (e) => {
    e.preventDefault()

    const data = {
      hash: window.location.hash,
      idSolicitud: id,
      formData: changeForm
    }



    const envioSolicitudObservacion = await validarEntorno('JSON/aprobar_observacion_inv_adm.html', 'POST', data)

    if(envioSolicitudObservacion.ok){

      const respuesta = await envioSolicitudObservacion.json()

      if(respuesta.solicitud === 'ok'){
        setConfirmacionEnvio(true)
        setChangeForm({
          aprobacion: '',
          descripcion: '',
          observacion: ''
        })
      }

    }

  }

  

  return (
    <>
      <Card className='shadow cardUnderPestana'>

        <Card.Body>
        <h5 className='titleCard'>Aprobar observación u objeción</h5>
        <br/>
        <div className='contenedorTextAreaGeneral'>
          <Form onSubmit={handleSubmit}>
            <div style={{display:'flex',  }}>
              <Form.Group  style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%', marginRight: '12px' }}>
                <Form.Label className='observacionesLabel' style={{marginLeft: '0px'}}>
                    Aprobación *
                </Form.Label>
                <Form.Control 
                  required
                  // className='textAreaGeneral' 
                  name='aprobacion' 
                  id='observacionesTextArea' 
                  as='select'
                  placeholder='Observaciones'
                  onChange={(e) => handleChange(e)}
                  disabled={!ele.editar}  
                  style={{width:'100%'}}
                  defaultValue={ele.valuesInputs.aprobacion}
                >
                  <option disabled value = ''>--Seleccione--</option>
                  <option>Aprobar</option>
                  <option>No aprobar</option>
                </Form.Control>
                <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
              </Form.Group>
              <Form.Group style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%' }} >
                <Form.Label className='observacionesLabel' style={{marginLeft: '0px'}}>
                    Descripción *
                </Form.Label>
                <Form.Control 
                  // className='textAreaGeneral' 
                  name='descripcion' 
                  id='observacionesTextArea' 
                  as='select'
                  placeholder='Observaciones'
                  onChange={(e) => handleChange(e)}
                  disabled={!ele.editar}
                  defaultValue={ele.valuesInputs.descripcion}
                >
                  <option value="" disabled>--Seleccione--</option>	
                  <option value="Aprobado"> Aprobado </option>			
                  <option value="Otra causa"> Otra causa </option>			
                  <option value="Errores de Redacción en el Informe"> Errores de Redacción en el Informe </option>
                  <option value="No coincide contenido con la acreditación colocada (Acredita - No acredita)"> No coincide contenido con la acreditación colocada (Acredita - No acredita) </option>
                  <option value="Faltó validar algún tipo de convivencia dependencia"> Faltó validar algún tipo de convivencia dependencia </option>
                  <option value="No se validó el certificado de escolaridad correcto"> No se validó el certificado de escolaridad correcto </option>
                  <option value="No validan los extremos de convivencia"> No validan los extremos de convivencia </option>
                </Form.Control>
              </Form.Group>

            </div>
            <br/>
            <Form.Group className='formGroupTextArea'>
              <Form.Label className='observacionesLabel' style={{marginLeft: '0px'}}>
                  Observaciones
              </Form.Label>
              <Form.Control 
                className='textAreaGeneral' 
                name='observacion' 
                id='observacionesTextArea' 
                as='textarea'
                placeholder='Observaciones'
                onChange={(e) => handleChange(e)}
                defaultValue={ele.valuesInputs.observaciones}
                disabled={!ele.editar}
              ></Form.Control>
            </Form.Group>
            <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                {changeForm.descripcion !== '' && changeForm.aprobacion !== ''
                    ?
                    <button type="submit" className='botonAddBeneficiario confirmButton'>
                        Guardar
                    </button>
                    :
                    <button disabled className='botonAddBeneficiario botonDeshabilitado'>
                    Para solicitar una observación u objeción, escribe una breve descripción de la solicitud.
                    </button>
                }
                
            </div>

          </Form>
        </div>
        </Card.Body>
      </Card>
      <NotificationModal 
        typeModal='crearSolicitud' 
        isOpen={confirmacionEnvio} 
        setIsOpen={setConfirmacionEnvio} 
      />
    </>
  )
}
