import { validarEntorno } from '../../../helpers/validarHost'

export const submitFilters = async (data) => {

  // EL ENVIO DE FILTROS SE DEBE HACER DESDE EL COMPONENTE DONDE SE LLAMA DEBIDO A
  // LAS DINAMICAS QUE PUEDA TENER EL COMPONENTE CON LA PETICION

  // console.log(url)
  // console.log(data.asesoria[0])

  // const req = await validarEntorno(url, 'POST', data)

  // if(req.ok){
  //   return req;
  // }else{
  //   return null;
  // }
}

// module.exports = {
//   submitFilters
// }
