export const camelize = (str) => {
    
    /**
     * Primero removemos los acentos con la funcion removeAccents
     * la variable es el acronimo de string without accents
    */
    const strwa = removeAccents(str);

    /**
     * Despues removemos los parentesis con la funcion removeParenteses
     * la variable es el acronimo de string with parentheses
    */
    const strwp = removeParentheses(strwa);

    const lower = strwp.toLowerCase();

    return lower.replace(/ /g,"_");
    
}

const removeParentheses = (str) => {
    return str.replace(/ *\([^)]*\) */g, "");
}

const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
} 

export const getKeyObject = (str) => {

    const wa = removeAccents(str)

    const lc = wa.toLowerCase();

    return lc.replace(" ", "_");
} 

const removeUnderscore = (str) => {
    return str.replace(/_/g, ' ');
}

export const capitalizeFirstLetter = (str) => {
    const ltt = removeUnderscore(str);
    return ltt.charAt(0).toUpperCase() + ltt.slice(1);
}