import React, {useState, useEffect, Fragment} from 'react'
import {Form, Card} from 'react-bootstrap'

import Select from 'react-select'
import Loader from '../../components/icons/Loader'
import PageError from '../Error'

import { validarEntorno } from '../../helpers/validarHost'
import DivergentChartAmplia from '../../components/dashboard/DivergentChartAmplia'
import GraficaConsolidado from '../../components/dashboard/GraficaConsolidado'

import './styles/Gerencia.scss'
import EditModal from '../../components/modales/EditModal'

export default function Gerencia(props) {

  const [modal, setModal] = useState({
    show: false,
    tipo: ''
  })

  const [ filtros, setFiltros ] = useState({
    fecha_inicial: '',
    fecha_final: '',
    personal: {},
    widget: '',
    seleccion_widget: '',
    informe: ''
  })

  // const [dataGrafica, setDataGrafica] = useState([])

  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })

  const [dataInforme, setDataInforme] = useState({
    opciones: [],
    columnas: [],
    registros: [],
    graficas: []
  })

  const [ personal, setPersonal] = useState([])
  const [ widgetSelected, setWidgetSelected ] = useState({ widget: {} })
  const [ opcionSelected, setOpcionSelected ] = useState({ seleccionWidget: {} })
  const [ itemSelected, setItemSelected ] = useState({ item: {}})
  const [ isShowGraphics, setIsShowGraphics] = useState(false);

  useEffect(() => {
    // .then(() => getRegistrosInforme() )
    // getRegistrosInforme()
    getDataGerencia()
  }, [])

  useEffect(() => {
    getRegistrosInforme();
  }, [widgetSelected, opcionSelected])

  useEffect(() => {
    getTablaInforme();
  }, [itemSelected])
  
  const getDataGerencia = async () => {

    // console.log('getDataGerencia')

    const dataReq = {
      hash: window.location.hash
    }


    const req = await validarEntorno('JSON/gerencial.html','POST', dataReq)
    
    if(req.ok){
      const res = await req.json()
      setInitialState({
        loading: false,
        error: null,
        data: res
      })
      getPersonalF(res)
      setFiltros({
        ...filtros,
        widget: res.widgets[0].id,
        seleccion_widget: res.widgets[0].opciones[0].id
      })
  
      const dataReq = {
        // filtros: filtros,
        filtros: {
          widget: widgetSelected?.widget.id,
          seleccion_widget: filtros.seleccion_widget
        },
        hash: window.location.hash
      }    
  
      // console.log(dataReq)
  
      // await validarEntorno('JSON/gerencial_reg_informe.html', 'POST', dataReq)
      //   .then(res => {
      //     res.json()
      //       .then( data => {
      //         setDataGrafica(data.grafica)
      //         setDataInforme({
      //           ...dataInforme,
      //           opciones: data.opciones,
      //           columnas: data.tabla.columnas,
      //           registros: data.tabla.registros,
      //           graficas: data.grafica
      //         })
      //       })
      //   })

    // }else{
    //   setInitialState({
    //     loading: false,
    //     error: true,
    //     data: {}
    //   })
    // }

  }
}

  const getPersonalF = (res) => {

      let getPersonal = res.personal?.map((persona) => {
        return {...persona, label: persona.nombre, value: persona.id}
      })
      setPersonal(getPersonal)
      // console.log(personal)
      // console.log(getPersonal)
  }

  const getFiltros = (e, item, tipo) => {
    if(e){
      const {name, value} = e.target
  
      setFiltros({
        ...filtros,
        [name] : value
      })
      
    }else if(item){
      setFiltros({
        ...filtros,
        // [name] : value
      })
    }
    // console.log(filtros)
  }

  const getPersonal = (value) => {
    setFiltros({
      ...filtros,
      personal : value
    })
    // console.log(filtros)
  }

  const getRegistrosInforme = async (item, tipo) => { 

    const dataReq = {
      filtros: filtros,
      hash: window.location.hash
    }    

    // console.log(dataReq)
    // console.log('siuuuu')

    setIsShowGraphics(true);

    const req = await validarEntorno('JSON/gerencial_reg_informe.html', 'POST', dataReq)

    if(req.ok){
      const res = await req.json(req)
      setDataInforme({
        ...dataInforme,
        opciones: res.opciones,
        columnas: res.tabla.columnas,
        registros: res.tabla.registros,
        graficas: res.grafica
      })
      setFiltros({
        ...filtros,
        informe: res.opciones[0].id
      })
      setIsShowGraphics(false);

      // setFiltros({
      //   ...filtros,
      //   informe: res.opciones[0].id
      // })

      if (item) {
        if(tipo && tipo === 'widget'){
          setFiltros({
            ...filtros,
            widget: item.id,
            informe: res.opciones[0].id, 
          })
        }else if(tipo && tipo === 'opcionWidget'){
          setFiltros({
            ...filtros,
            seleccion_widget: item.id,
            informe: res.opciones[0].id
          })
        }
      }
    }

    // console.log(dataInforme)
    // console.log(filtros)
  }

  const getTablaInforme = async () => {

    const dataReq = {
      hash: window.location.hash,
      filtros: filtros
    }

    setIsShowGraphics(true);
  
    const req = await validarEntorno('JSON/gerencial_data_informe.html', 'POST', dataReq)

    if(req.ok){
      const res = await req.json()

      setDataInforme({
        ...dataInforme,
        columnas: res.tabla.columnas,
        registros: res.tabla.registros,
        graficas: res.grafica
      });
      setIsShowGraphics(false); 
    }
  }

  const getValores = (item, tipo) => {

    console.log(item);

    switch (tipo) {
      case 'widget': 
        setFiltros({
          ...filtros,
          widget: item.id,
          seleccion_widget: item.opciones[0].id,
          informe: item.opciones[0].id
        })

        setWidgetSelected({ widget: item })

        setOpcionSelected({seleccionWidget: item.opciones[0]})

        // s
        break;
      case 'opcionWidget':
        setFiltros({
          ...filtros,
          seleccion_widget: item.id,
          informe: dataInforme.opciones[0].id
        })
        setOpcionSelected({...opcionSelected, seleccionWidget: item})
        
        // filtros.seleccion_widget !== '' && (filtros.seleccion_widget !== item.id) && getRegistrosInforme(item, 'opcionWidget')
        break;
      case 'tipoInforme':
        setFiltros({
          ...filtros,
          informe: item?.id
        });
        setItemSelected({...itemSelected, item: item })
        // filtros.informe !== '' && (filtros.informe !== item.id) && getTablaInforme()
        // console.log(filtros)
        break;
      default:
        break;
    }

  }


  return (
    <>
      { initialState.loading === false && initialState.error === null 
        ?
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Card 
            id='filtroGerencia' 
            className='shadow cardGeneral '
            style={{
              marginTop:'20px'
            }}
          >
            <Card.Body id='contenedorFiltrosGerencia' >
              <Form.Group id='fechaInicial' className='formGroupVertical'>
                <Form.Label>Fecha inicial</Form.Label>
                <Form.Control 
                  // defaultValue=''
                  type='date' 
                  name='fecha_inicial'
                  onChange={ (e) => getFiltros(e) }
                />
              </Form.Group>
              <Form.Group className='formGroupVertical'>
                <Form.Label>Fecha final</Form.Label>
                <Form.Control 
                  type='date' 
                  name='fecha_final'
                  onChange={ (e) => getFiltros(e) }
                />
              </Form.Group>
              <Form.Group className='formGroupVertical'>
                <Form.Label>Consulta</Form.Label>
                <Select 
                  id='selectPersonalGerencia'
                  name='personal' 
                  options={personal}
                  onChange={ getPersonal }
                  isMulti= {true}
                  closeMenuOnSelect={false}
                  placeholder= '-- Seleccione --'

                />
              </Form.Group>
            </Card.Body>
            {/* <p>Campos opcionales</p> */}
          </Card>
          <br/>
          <Card 
            id='widgetsGerencia' 
            className='shadow cardGeneral'
            style={{
              backgroundImage: widgetSelected.widget.id === 'inv_adm' 
                ? `url( "https://res.cloudinary.com/da6bzgewk/image/upload/v1627835051/investigaciones_gtpxfa.jpg" )`
                : widgetSelected.widget.id === 'ugpp'
                  ? 'url("https://res.cloudinary.com/da6bzgewk/image/upload/v1627835047/ugpp_couimp.jpg")'
                  : widgetSelected.widget.id === 'inteligencia'
                    ? 'url("https://res.cloudinary.com/da6bzgewk/image/upload/v1627835045/inteligencia_e7u3wv.jpg")'
                    : widgetSelected.widget.id === 'cods_descuentos'
                      ? 'url("https://res.cloudinary.com/da6bzgewk/image/upload/v1627835053/oferta_ceio8l.jpg")'
                      : widgetSelected.widget.id === 'visitas_maf'
                        ? 'url("https://res.cloudinary.com/da6bzgewk/image/upload/v1627835047/financiacion_w5oylj.jpg")'
                        : 'url("https://res.cloudinary.com/da6bzgewk/image/upload/v1627835047/ugpp_couimp.jpg")',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden'
              
            
            }}

          >
            <Card.Body id='contenidoWidgets' 
            
            >
            <div id='mainWidgets' >
              {widgetSelected.widget !== {} &&
                <div id='contenedorContenidoWidget'>
                  <div style={{width:'90%'}}>
                    <h2 id='tituloContenidoWidget'>{widgetSelected.widget.titulo ? widgetSelected.widget.titulo : 'Panel gerencial'}</h2>
                    <p id='contenidoWidget' >
                      {widgetSelected.widget.contenido ? widgetSelected.widget.contenido : 'Para iniciar seleccione uno de los departamentos'}
                    </p>
                  </div>
                  <div id='contenedorOpcionesWidget' className='scrollSecundario' >
                    {widgetSelected.widget.opciones && widgetSelected.widget.opciones.length > 0 &&
                      widgetSelected.widget.opciones.map((opcion) => {
                        return(
                          <div 
                            className={ filtros.seleccion_widget !== opcion.id ? 'opcionWidget' : 'opcionWidget opcionWidgetSelected'} 
                            key={opcion.id}
                            onClick={() => getValores(opcion, 'opcionWidget')}
                          >
                            <p id='labelOpcion' >
                              {opcion.titulo}
                            </p>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              }
            </div>
            <div id='selectoresWidget' className='scrollSecundario' >
              {initialState.data.widgets.map((widget, index) => {
                return(
                  <Fragment key={index}>
                    <div 
                      className={ widgetSelected.widget.id === widget.id ?  'selectorWidgetSelected' : 'selectorWidget'}
                      onClick={() => getValores( widget, 'widget')}
                    >{widget.titulo}</div>
                  </Fragment>
                )
              })}
            </div>
            </Card.Body>
          </Card>
          <br/>
          {filtros.widget !== '' && filtros.seleccion_widget !== '' && 
          
          <Card id='contenidoGerencia' className='shadow cardGeneral'>
            <Card.Body>
              <div id='infoComplementaria'>
                <div>
                  <p>Mostrando información para: </p>
                  <div id='divEstructuraBasica'>
                    <div className='divInfoComplementaria' >
                      {filtros.fecha_inicial !== '' && <>{`Fecha inicial: ${filtros.fecha_inicial}`}<br/></>}
                      {filtros.fecha_final !== '' && <>{`Fecha final: ${filtros.fecha_final}`}<br/></>}
                    </div>
                    <div className='divInfoComplementaria' >
                      {widgetSelected.widget && <>{`Sección: ${widgetSelected.widget.titulo}`}<br/></>}
                      
                      {opcionSelected.seleccionWidget && <>{`Tipo: ${opcionSelected.seleccionWidget.titulo}`}<br/></>}
                    </div>
                    <div className='divInfoComplementaria' >
                      {/* {opcionSelected.seleccionWidget && <>{`Tipo: ${opcionSelected.seleccionWidget.titulo}`}<br/></>} */}
                      {filtros.personal.length > 0 && 
                      <>
                        <p>Consulta: 
                        {filtros?.personal.map((personal) => {
                          return(
                            <Fragment key={personal.id}>
                              {` ${personal.nombre}, `} 
                            </Fragment>
                          )
                        })}
                        {/* {`Consulta: ${filtros.personal.id ? filtros.personal.id : '' }`}<br/> */}
                        </p>
                      </>
                      }

                    </div>
                  </div>                  
                </div>
                <div>
                  { dataInforme.registros.length > 0 ? (
                     <button className='confirmButton' onClick={() => setModal({...modal, show: true, tipo: 'tablaInformeGerencial'})} >Ver tabla</button>
                  ) : null }
                </div>
              </div>
              <hr/>
              <div id='seccionDataInforme'>
                <div id='columnTiposInforme'>
                  {dataInforme.opciones.length > 0 && dataInforme.opciones.map((tipo) => {
                    return(
                      <div
                        className={ filtros.informe !== tipo.id ? 'tipoInforme' : 'tipoInformeSelected'} 
                        key={tipo.id}
                        onClick={() => getValores(tipo, 'tipoInforme')}
                      >
                        {tipo.titulo}
                      </div>
                    )
                  })}
                </div>
                <div id='contenedorTablaInforme'>
                  <div id='contenedorGraficasInforme'>
                    { !isShowGraphics ? (
                      ( dataInforme?.graficas && dataInforme?.graficas?.length > 0 && 
                        dataInforme?.graficas.map((grafica, index) => (
                            <Fragment key={index}>
                              {grafica.tipo === 'series'
                                ?<>
                                  <GraficaConsolidado
                                        title={grafica.titulo}
                                        id='seriesPanelGerencial'
                                        data={grafica.series.data}
                                        series={grafica.series.lista}
                                        seriesOcultas = {grafica.ocultar_series}
                                        // parametrizado = {permisos.panelGeneral.consolidado.graficos.consolidado_series.parametrizado}
                                        // series={permisos.panelGeneral.consolidado.graficos.consolidado_series.series.lista}
                                    />
                                  
                                <br/>
                                </>
                                : grafica.tipo === 'divergente'
                                  ?
                                  <DivergentChartAmplia
                                      id='divergenteValores'
                                      data={grafica.data}
                                      title={grafica.titulo}
                                      total={grafica.total}
                                      convenciones='bottom'
                                      // parametrizado={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.parametrizado}
                                      // periodo={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.periodo}
                                      // total={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.total}
                                      limite={grafica.limite}
                                  />
                                  : null
                              }
                            </Fragment>
                          )  
                        ))
                    ) : <Loader /> }
                  </div>
                  <br/>
                  <br/>
                  {/* <table id='tablaInforme'>
                    <thead>
                      <tr>
                      {dataInforme.columnas.length > 0 && dataInforme.columnas.map((col, index) => {
                        return(
                          <th key={index}>
                            {col}
                          </th>
                        )
                      })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataInforme.registros.length > 0 && dataInforme.registros.map((reg, index) => {
                        return(
                          <tr key={index}>
                            {reg.id_inv ? <td>{reg.id_inv}</td> : null}
                            {reg.estado ? <td>{reg.estado}</td> : null}
                            {reg.fecha_sol_cli ? <td>{reg.fecha_sol_cli}</td> : null}
                            {reg.fecha_sol ? <td>{reg.fecha_sol}</td> : null}
                            {reg.fecha_fin ? <td>{reg.fecha_fin}</td> : null}
                            {reg.mes_sol ? <td>{reg.mes_sol}</td> : null}
                            {reg.mes_fin ? <td>{reg.mes_fin}</td> : null}
                            {reg.fecha_rev_cal ? <td>{reg.fecha_rev_cal}</td> : null}
                            {reg.cuenta ? <td>{reg.cuenta}</td> : null}
                            {reg.tipo ? <td>{reg.tipo}</td> : null}
                            {reg.region ? <td>{reg.region}</td> : null}
                            {reg.valor ? <td>{reg.valor}</td> : null}
                            {reg.tipo_id_pen ? <td>{reg.tipo_id_pen}</td> : null}
                            {reg.id_pen ? <td>{reg.id_pen}</td> : null}
                            {reg.radicado ? <td>{reg.radicado}</td> : null}
                            {reg.nombres ? <td>{reg.nombres}</td> : null}
                            {reg.ciudad_verificacion ? <td>{reg.ciudad_verificacion}</td> : null}
                            {reg.id_analista ? <td>{reg.id_analista}</td> : null}
                            {reg.analista ? <td>{reg.analista}</td> : null}
                            {reg.cantidad ? <td>{reg.cantidad}</td> : null}
                            {reg.conclusion ? <td>{reg.conclusion}</td> : null}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table> */}
                </div>
              </div>

            </Card.Body>
          </Card>
          }        
        </div>
        : 
        initialState.loading === true && initialState.error === null
          ? <Loader />
          : <PageError />
      }
    <EditModal isOpen={modal.show} setIsOpen={setModal} typeModal={modal.tipo} dataInforme={dataInforme} />
    </>  
  )
}
