import React from 'react'
import reactDOM from 'react-dom'

import CloseIcon from '@material-ui/icons/Close';

import {AnalistasDisponibles} from './AnalistasDisponibles'

import './modalAgregarAnalista.scss'

export default function ModalAgregarAnalista(props) {


  if(!props.isOpen){
    return null;
  };

  const handleBuscarAnalista = (e) => {
    // console.log(e.target.value);
  }

  const handleSeleccionAnalista = (e) => {
    // setAnalistaSeleccion(
    //   ...analistaSeleccion,
    //   analistaSeleccion.push(e.curretTarget),
    //   console.log(analistaSeleccion)
    // )
    // console.log(e.target.value);
  }

  return reactDOM.createPortal (
    <div className='agregarAnalistaModalContainer'>
      <div className='agregarAnalistaCard'>
        <div className='closeButtonContainer'>
          <CloseIcon onClick={() => {props.setIsOpen(false)}}/>
        </div>
        <h4 className='pagesTitles'>Agregar analista/investigador</h4>
        <div className='inputBuscadorContainer'>
          <input id='inputBuscadorAnalistas' onChange={handleBuscarAnalista} type='text' placeholder='Ingrese el nombre del analista a buscar'></input>
          <button className='buttonBuscador'>Buscar</button>
        </div>
        <p>A continuación se listan los analistas disponibles. Seleccione los analistas a agregar y a continuación, pulse en el botón de agregar.</p>
        <div className='tableAnalistasContainer'>
          <table className='tableAnalistas'>
            <thead>
              <tr>
                <th>Seleccionar</th>
                <th>Nombres y apellidos</th>
                <th>Teléfono particular</th>
                <th>Cargo</th>
              </tr>
            </thead>
            <tbody>
              {AnalistasDisponibles.map((item, index) => {
                return(
                  <tr key={index}>
                    <td><input value={item} onChange={handleSeleccionAnalista} type='checkbox'></input></td>
                    <td>{item.nombre}</td>
                    <td>{item.telefono}</td>
                    <td>{item.Cargo}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <button className='buttonAddAnalista'>Agregar</button>
        </div>
      </div>
    </div>,
    document.getElementById('AgregarAnalistaModal')
  )
}

// Tareas:
// Información general: organizar formulario y añadir accion al form 
// Información causante: añadir accion al form 
// Revisión de calidad: añadir accion al form
// Beneficiarios: añadir accion al form
// solicitud observacion: Preguntar por estructura
// Asignación analista: Preguntar de que forma se seleccionan los analistas en el modal
// soportes documentales: Es lo mismo que gestor documental?
//registro fotografico: crear crud para añadir imagenes
//Conclusiones: crear form 
//notas: complementar form del modal

