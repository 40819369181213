import React, {useState, useEffect} from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';

export default function DonaConTotal(props) {

  // console.log(props)

  //am4core.useTheme(am4themes_animated);

  const renderColores = (colorList) => {

    if(colorList !== ''){

      const arrayColors = colorList.map((color, index) => {
        
        return am4core.color(color) 
      })

      return arrayColors;

    }else{
      return [
        am4core.color("#F9CF00"),
        am4core.color("#8BC34A"),
        am4core.color("#009688"),
        am4core.color("#05a4db"),
        am4core.color("#2c5e99")
      ]
    }

    // props.colores ? props.colores : [
    //   am4core.color("#F9CF00"),
    //   am4core.color("#8BC34A"),
    //   am4core.color("#009688"),
    //   am4core.color("#05a4db"),
    //   am4core.color("#2c5e99")
    // ];

  }

  useEffect(() => {

    let chart = am4core.create(props.id, am4charts.PieChart);
    chart.startAngle = 160;
    chart.endAngle = 380;

    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(40);
    chart.responsive.enabled = true;

    // Add data
    chart.data = props.data;




    // Add and configure Series
    // let pieSeries = chart.series.push(new am4charts.PieSeries());
    // pieSeries.dataFields.value = "litres";
    // pieSeries.dataFields.category = "country";
    // pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
    // pieSeries.slices.template.strokeWidth = 1;
    // pieSeries.slices.template.strokeOpacity = 1;

    // Disabling labels and ticks on inner circle
    // pieSeries.labels.template.disabled = true;
    // pieSeries.ticks.template.disabled = true;

    // Disable sliding out of slices
    // pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    // pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
    // pieSeries.radius = am4core.percent(40);
    // pieSeries.innerRadius = am4core.percent(30);

    // let cs = chart.colors;
    // cs.list = chart.colors.list;

    // cs.stepOptions = {
    //   lightness: -0.05,
    //   hue: 0
    // };
    // cs.wrap = false;


    // Add second series
    let pieSeries2 = chart.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "cantidad";
    pieSeries2.dataFields.category = "estado";
    // pieSeries2.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
    pieSeries2.slices.template.strokeWidth = 1;
    pieSeries2.slices.template.strokeOpacity = 1;
    pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0.05;
    pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;

    
    pieSeries2.labels.template.disabled = false;
    pieSeries2.alignLabels = false;
    pieSeries2.labels.template.text ="{value}";
    pieSeries2.labels.template.radius = am4core.percent(-25);
    pieSeries2.labels.template.fill = am4core.color("white");

    pieSeries2.ticks.template.disabled = true;

    chart.legend = new am4charts.Legend();
    // pieSeries2.legend.labels.template.text = "{estado}";
    chart.legend.labels.template.fontSize = "11px";
    chart.legend.position = props.legendPosition ? props.legendPosition : 'bottom'
    chart.legend.maxWidth = 160;
    chart.legend.scrollable = true;
    pieSeries2.colors.list = renderColores(props.colores ? props.colores : '' )

    let label = chart.seriesContainer.createChild(am4core.Label);
    label.textAlign = "middle";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.adapter.add("text", function(text, target){
      return `[font-size:14px color:#213265]${props.tipo ? props.tipo : 'Solicitudes'}[/]:\n[bold font-size:16px color:#213265]` + ( props.total ? props.total : pieSeries2.dataItem.values.value.sum) + "[/]";
    })
    
  }, [props.data])

  return (
    <Card style={{ backgroundColor: '#ededed' }} className="cardGraficaGeneral cardGraficaAnalistas cardDivergente">
      <Card.Body>
        {props.title ? (
          <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> 
            {props.title} 
            <br/>
            {props.parametrizado
              ?
                <>
                  {props.parametrizado} - {props.periodo }
                </>
  
              : null
            }
          </h4>
        ) : null}
        <div id={props.id} style={{ width: props.width ? props.width : 'calc(50vh - 10px)', margin: '0 auto', minHeight: props.minHeight ? props.minHeight : '350px', height: 'auto' }}></div>
      </Card.Body>
      {/* <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} /> */}
    </Card>
  )
}
