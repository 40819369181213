// import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TablaEstudios } from '../../../components/gerenciaGeneral/TablaEstudios';
import { opcionesBusquedaInicial } from '../../../reducers/filtroTipoEstudio';

import Loader from '../../../components/icons/Loader'
import moment from 'moment';


const Investigaciones = () => {

    const dispatch = useDispatch()

    const [loader, setLoader] = useState(true)

    const [mostarData, setMostarData] = useState(false)
    const { variable, id, tipoReporte } = useParams()

    const validateDataDate = () => {

      if (localStorage.getItem('data-graphs') !== null) {

            const data = localStorage.getItem('data-graphs');

            const dataParsed = JSON.parse(data);
            const currentDate = moment();
            const diffTime = currentDate.diff(dataParsed.date, 'minutes');

            const objDiff = {
                diff: diffTime,
                data: dataParsed.data,
                hash: dataParsed.hash
            }

            return objDiff;
        }
    }

    const dataDiff = validateDataDate();

    // console.log(selectBusqueda)

    useEffect(() => {

        if (id) {
            setMostarData(true)
        } else if (variable) {

            setMostarData(true)
        }

        dispatch(opcionesBusquedaInicial({ hash: window.location.hash }))
        .then(() => setLoader(false) )

        // if((localStorage.getItem('data-graphs') === null) || (dataDiff.diff > 60 && window.location.hash === dataDiff.hash)) {
        //     dispatch(restablecerData());

        //     setTimeout(() => {
        //         dispatch(opcionesBusquedaInicial({ hash: window.location.hash }))
        //         .then(() => setLoader(false) )
        //     }, 1500);
        // } else {
        //     setLoader(false);
        // }
    }, [dispatch])

    const dataInicial =  useSelector(store => store.filtroEstudio)
    const { selectBusqueda } = dataInicial;


    let dataSelect = {}

    if ((localStorage.getItem('data-graphs') === null) || (dataDiff.diff > 60 && window.location.hash === dataDiff.hash)) {
        dataSelect = selectBusqueda
    } else {
        dataSelect = dataDiff.data;
    }

    const [editarEstudio, setEditarEstudio] = useState(false)
    const [dataSelected, setDataSelected] = useState({})
    const [open, setOpen] = useState(false)

    if(loader === false){

    return (
        <div>
            {
                !editarEstudio
                    && <Fragment>
                        <br />
                        <TablaEstudios
                            editarEstudio={editarEstudio}
                            setEditarEstudio={setEditarEstudio}
                            dataSelected={dataSelected}
                            setDataSelected={setDataSelected}
                            variableMostarTabla={mostarData}
                            leyendas={dataSelect?.leyendas}
                        />
                    </Fragment>
            }

        </div>
    )

    }else return <Loader/>

}
export default Investigaciones;
