import React, { useRef } from 'react';
import md5 from 'md5';
import { Payment } from '@material-ui/icons';
import boton_pago from '../assets/img/boton_pagar_mediano.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function PayULatamForm({ sol }) {
  const solifacInputRef = useRef(null);
  const merchantIdInputRef = useRef(null);
  const accountIdInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const referenceCodeInputRef = useRef(null);
  const amountInputRef = useRef(null);
  const taxInputRef = useRef(null);
  const taxReturnBaseInputRef = useRef(null);
  const currencyInputRef = useRef(null);
  const signatureInputRef = useRef(null);
  const buyerEmailInputRef = useRef(null);
  const responseUrlInputRef = useRef(null);

  console.log(sol);

  const handleFormSubmit = (event) => {
    try {
      event.preventDefault();
      event.target.reset();

      const apiKey = '679rnp8d6e3rcubrmlg4uk7fkf'; //Pruebas 4Vj8eK4rloUd272L48hsrarnUA - Produccion 679rnp8d6e3rcubrmlg4uk7fkf
      const merchantId = '513877';//Pruebas 508029 - Produccion 513877
      const accountId = '515243'; //Pruebas 512321 - Produccion 515243
      const referenceCode = sol.referencia;
      const description = sol.descripcion;
      const amount = sol.valor_unitario;
      const currency = 'COP';
      const signature = md5(
        apiKey +
        '~' +
        merchantId +
        '~' +
        referenceCode +
        '~' +
        amount +
        '~' +
        currency
      );
      const buyerEmail = sol.email;
      const form = event.target;
      // Pruebas https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/
      // Produccion https://gateway.payulatam.com/ppp-web-gateway/
      form.action = 'https://gateway.payulatam.com/ppp-web-gateway/';
      form.target = '_blank';

      solifacInputRef.current.value = sol.facturado;
      merchantIdInputRef.current.value = merchantId;
      accountIdInputRef.current.value = accountId;
      descriptionInputRef.current.value = description || referenceCode;
      referenceCodeInputRef.current.value = referenceCode;
      amountInputRef.current.value = amount;
      taxInputRef.current.value = '0';
      taxReturnBaseInputRef.current.value = '0';
      currencyInputRef.current.value = currency;
      signatureInputRef.current.value = signature;
      buyerEmailInputRef.current.value = buyerEmail;
      responseUrlInputRef.current.value =
        'https://www.ciacosinte.site/crm/payu_resp.html';
      form.submit();
    } catch (error) {
      console.log(error, "HUBO UN ERROR");
    }
  };

  return (
    <form onSubmit={handleFormSubmit} method="post" style={{ marginTop: "0" , paddingRight:"0"}}>
      <input type="hidden" name="solifac" ref={solifacInputRef} />
      <input type="hidden" name="merchantId" ref={merchantIdInputRef} />
      <input type="hidden" name="accountId" ref={accountIdInputRef} />
      <input type="hidden" name="description" ref={descriptionInputRef} />
      <input
        type="hidden"
        name="referenceCode"
        ref={referenceCodeInputRef}
      />
      <input type="hidden" name="amount" ref={amountInputRef} />
      <input type="hidden" name="tax" ref={taxInputRef} />
      <input
        type="hidden"
        name="taxReturnBase"
        ref={taxReturnBaseInputRef}
      />
      <input type="hidden" name="currency" ref={currencyInputRef} />
      <input type="hidden" name="signature" ref={signatureInputRef} />
      <input type="hidden" name="buyerEmail" ref={buyerEmailInputRef} />
      <input
        type="hidden"
        name="responseUrl"
        ref={responseUrlInputRef}
        value="https://www.ciacosinte.site/crm/payu_resp.html"
      />
      {sol?.tooltip ?
      <OverlayTrigger overlay={<Tooltip> ${sol.tooltip}</Tooltip> }>
        <button type="submit" className="boton__pago">
          <img src={boton_pago} alt="Pagar" width={"100px"} />
        </button>
      </OverlayTrigger>
      :
      <button type="submit" className="boton__pago">
        <img src={boton_pago} alt="Pagar" width={"100px"} />
      </button>
      }
    </form>
  );
}

export default PayULatamForm;
