import React, {useState, useEffect} from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'
import { useTranslation } from 'react-i18next';

export default function Divergente(props) {

  const { t } = useTranslation();

  // Themes begin
  am4core.useTheme(am4themes_animated);

  useEffect(()=>{


      let chart = am4core.create(props.id, am4charts.XYChart)




    // Create chart instance

    // Add data
    chart.data = props.data;


    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = -1;
    categoryAxis.renderer.axisFills.template.disabled = false;
    categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;
    categoryAxis.sortBySeries = categoryAxis;

    categoryAxis.renderer.labels.template.adapter.add("text", function(text) {
      return "[bold]" + text ;
    });


    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = props.limite ? props.limite : 150;
    valueAxis.renderer.minGridDistance = 50;
    valueAxis.renderer.ticks.template.length = 17;
    valueAxis.renderer.ticks.template.disabled = false;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
    valueAxis.renderer.labels.template.adapter.add("text", function(text) {
      return text;
    })

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.position =  props.convenciones ? props.convenciones : "right";

    // Use only absolute numbers
    chart.numberFormatter.numberFormat = "#.#s";

    // Create series
    function createSeries(field, name, color) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "category";
      series.stacked = true;
      series.name = name;
      series.stroke = color;
      series.fill = color;

      let label = series.bullets.push(new am4charts.LabelBullet());
      label.label.text = "{valueX}";
      label.label.fill = am4core.color("#fff");
      label.label.strokeWidth = 0.5;
      label.label.truncate = false;
      label.label.hideOversized = true;
      label.locationX = 0.4;
      return series;
    }


    if(props.id === 'ClientesMayorVolumen'){

      let positiveColor1 = '#05a4db';
      let positiveColor2 = '#2c5e99';
      let negativeColor1 = '#8BC34A';
      let negativeColor2 = '#009688';

      createSeries("negative1", "En proceso", positiveColor1);
      createSeries("positive1", "Finalizados", negativeColor2);
      createSeries("negative2", "Con novedad", negativeColor1);
      createSeries("positive2", "Finalizados", positiveColor2);
    }else if(props.id === 'divergenteValores'){
      let positiveColor2 = '#2c5e99';
      createSeries("positive2", "Registros", positiveColor2);

    }else if(props.id === 'ClientesMenorVolumen'){
      // let positiveColor1 = '#05a4db';
      let positiveColor2 = '#F46036';
      let negativeColor1 = '#F9CF00';
      // let negativeColor2 = '#009688';

      createSeries("negative1", "En proceso", negativeColor1);
      // createSeries("positive1", "En proceso", positiveColor1);
      // createSeries("negative2", "Con novedad", negativeColor2);
      createSeries("positive2", "Finalizados", positiveColor2);

    }else if(props.id === 'compromisosTipoServicio'){
      let positiveColor1 = '#8BC34A';
      let positiveColor2 = '#009688';
      // let negativeColor1 = '#F46036';
      let negativeColor2 = '#2c5e99';

      createSeries("negative2", "En proceso", negativeColor2);
      // createSeries("negative1", "Con novedad", negativeColor1);
      createSeries("positive1", "Finalizados", positiveColor1);
      createSeries("positive2", "Cancelados", positiveColor2);
    }else if(props.id === 'usuarios'){
      let positiveColor1 = '#2c5e99';
      // let negativeColor2 = '#F46036';
      let negativeColor1 = '#F9CF00';
      let positiveColor2 = '#009688';

      createSeries("positive1", "En proceso", positiveColor1);
      // createSeries("negative2", "Con novedad", negativeColor2);
      createSeries("positive2", "Finalizados", positiveColor2);
      createSeries("negative1", "Cancelados", negativeColor1);
    }else if(props.id === 'nivelRiesgos'){
      let positiveColor1 = '#F46036';
      let negativeColor1 = '#F9CF00';

      createSeries("negative1", "Riesgo medio", negativeColor1);
      createSeries("positive1", "Riesgo alto", positiveColor1);
    }else if(props.id === 'porServicio'){
      let positiveColor1 = '#8BC34A';
      let positiveColor2 = '#009688';
      // let negativeColor1 = '#F46036';
      let negativeColor2 = '#2c5e99';

      createSeries("positive1", "En proceso", positiveColor1);
      // createSeries("negative2", "Con novedad", negativeColor2);
      createSeries("positive2", "Finalizados", positiveColor2);
      createSeries("negative1", "Cancelados", negativeColor2);

    }



    chart.legend.events.on("layoutvalidated", function(event){
      chart.legend.itemContainers.each((container)=>{
        if(container.dataItem.dataContext.name == "Never"){
          container.toBack();
        }
      })
    })

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": t('botones.imagen') },
        // { "type": "csv", "label": "Tabla" },
        { "type": "print", "label": t('botones.imprimir') }
      ]
    }];


  },[props.data])

  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  return (
    <Card className="shadow cardGraficaGeneral cardGraficaAnalistas cardDivergente">
      {/* <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br />
      <Card.Body>
        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}>
        {props.title}
        <br/>
        {props.parametrizado
          ?
            <>
              {props.parametrizado} - {props.periodo }
            </>

          : null
        }
        </h4>
        <br/>
        <div id={props.id} style={{ width: '100%', minHeight: '450px', margin: '0 auto ', height: 'auto' }}></div>
        <br/>
        <h4 className='pagesTitles' >
          Total: {props.total}
        </h4>
      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
