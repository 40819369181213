import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import '../gestionAuditorias/PanoramaRiesgos/PestanasInforme/styles/Factores.scss'
import Loader from '../icons/Loader'
import useState2 from 'react-usestateref';
import DeleteIcon from '@material-ui/icons/Delete';
import { t } from 'i18next';
import { Chip } from 'primereact/chip';


export default function TablaDinamico({ tipo, deleteFile, data, setData, newFiles, statusNewTable }) {


  const [datosTabla, setDatosTabla] = useState([]);
  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false
  })

  const [filtros, setFiltros, filtrosRef] = useState2({
    limite: 10,
    pagina: 1
  });

  const [isAllRecords, setIsAllRecords] = useState(false);

  // useEffect(() => {
  //   // getRiesgos();
  //   // setAreaOpen(initialState?.data[0]?.id);
  //   getActividades(areaOpen);
  // }, [filtrosForm])

  const processData = () => {
    // console.log(data, 'this is dataaaal')
    const arr = [];
    data.map((item) => (

      // (typeof item === "string" ? item : item['link'])
      arr.push(splitUrl((typeof item === "string" ? item : item['link']), (typeof item === "string" ? '' : item['nombre'])))
    ));

    setData(arr);
    // console.log(arr, 'this is arr')

  }

  const splitUrl = (str, name) => {
    if (name) {
      // console.log(str);
      const sptUrl = name; //str.split('/');
      const obj = {
        name: sptUrl,
        enlace: `https://www.ciacosinte.site${str}`
      }
      return obj;
    } else if (str) {
      // console.log(str);
      const sptUrl = str.split('/');
      const obj = {
        name: sptUrl[sptUrl.length - 1],
        enlace: `https://www.ciacosinte.site${str}`
      }
      return obj;
    }
  }

  useEffect(() => {
    processData();
  }, [])


  return (
    <>
      <Card>
        <Card.Body>
          {newFiles.length > 0 && (
            <>
              <div id='cols'>
                <div className='col_item'>Nuevos archivos</div>
              </div>
              <div className='table_reps' style={{ marginBottom: '5%' }}>
                <>
                  <div id='cols'>
                    <div className='col_item'>Nombre</div>
                    <div className='col_item'>{statusNewTable !== 'resultado' ? `Acciones` : `Estado`}</div>
                  </div>
                  <div id='regs' >
                    {newFiles.map((item, index) => {
                      return (
                        <Card key={index} className='shadow reg_item_factores'>
                          <div className='colreg_factores name-files' key={index}>
                            {item?.name}
                          </div>
                          <div className='colreg_factores'>
                            <div>
                              {!item.estado ? (<>
                                <DeleteIcon className="iconReg deleteReg" onClick={() => deleteFile(item)} />
                                <span className='danger__chip ml-2'>PENDIENTE</span>
                                </>) :
                                (<div className='colreg_factores name-files' key={index}>{item?.estado}</div>)
                              }
                            </div>
                          </div>
                        </Card>
                      )
                    })
                    }
                  </div>
                </>
              </div>

            </>
          )}
          {data?.length === 0
            ?
            <div id='mensaje_acts_vacias'>{t('formulario.label.no_hay_registros_relacionados')}</div>
            :
            <>
              <div id='cols'>
                <div className='col_item'>Archivos cargados</div>
              </div>
              <div className='table_reps'>
                <>
                  <div id='cols'>
                    <div className='col_item'>Nombre</div>
                    <div className='col_item'>Enlace</div>
                    <div className='col_item'>Acciones</div>
                  </div>
                  <div id='regs' >
                    {data?.map((item, index) => {
                      return (
                        <Card key={index} className='shadow reg_item_factores'>
                          <div className='colreg_factores' key={index}>{item.name}</div>
                          <div className='colreg_factores'>
                            {item.enlace ? <a href={item.enlace} target="_blank" rel='noreferrer'>Enlace</a> : 'n/a'}
                          </div>
                          <div className='colreg_factores'>
                            <div>
                              <DeleteIcon className="iconReg deleteReg" onClick={() => deleteFile(item)} />
                            </div>
                          </div>
                        </Card>
                      )
                    })
                    }
                  </div>
                </>
              </div>
            </>
          }
          <br />

        </Card.Body>
      </Card>
    </>
  )
}
