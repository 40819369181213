import React from 'react'

export const CalendarSVG = () => {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 13.637 15">
            <path id="Trazado_123" data-name="Trazado 123" d="M6178.59,1382.918h-1.363v-.682a.682.682,0,1,0-1.363,0v.682h-4.091v-.682a.682.682,0,1,0-1.363,0v.682h-1.364a2.048,2.048,0,0,0-2.046,2.046v9.546a2.048,2.048,0,0,0,2.046,2.046h9.545a2.048,2.048,0,0,0,2.046-2.046v-9.546A2.048,2.048,0,0,0,6178.59,1382.918Zm-9.545,1.364h1.364v.682a.682.682,0,1,0,1.363,0v-.682h4.091v.682a.682.682,0,1,0,1.363,0v-.682h1.363a.683.683,0,0,1,.683.682v2.046h-10.909v-2.046A.682.682,0,0,1,6169.045,1384.282Zm9.545,10.909h-9.545a.682.682,0,0,1-.682-.682v-6.136h10.909v6.136A.683.683,0,0,1,6178.59,1395.191Z" transform="translate(-6166.999 -1381.555)" fill="#8d8d8d" />
        </svg>

    )
}
