import { Col, Form } from "react-bootstrap";
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { validarEntorno } from "../validarHost";
import TablaDinamica from '../../components/TablaDinamica'
import { BtnAyuda } from "./BtnAyuda";
import { useTranslation } from "react-i18next";

const MultipleUnique = ({ item, handleInputChange, index, idx, reg, nombre, error, esEditable, esMultiple }) => {
  const { t } = useTranslation();
  const { id } = useParams()
  const HASH = window.location.hash
  const { control } = useForm();
  const [table, setTable] = useState({})
  const [data, setData] = useState(item.valor[0] ? item.valor[0].value : '');

  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false,
  });
  useEffect(() => {
    if (esMultiple === true) {
      if (data.length === 0) {

        setData(null)
      }
    }


  }, [])
  const getMultiples = (e) => {
    setData(e ? e.value : '');

    item.nombre.includes('bus_') && getData()

    // if(item.nombre.includes('bus_')){
    //     getData()
    // }

  }

  const getData = async () => {
    const dataReq = {
      id: id,
      hash: HASH
    }

    const req = await validarEntorno('JSON/GestionDinamica/busqueda_din.html', 'POST', dataReq)

    if (req.ok) {

      const res = await req.json();
      setInitialState({ ...initialState, data: res })

    } else {

    }


  }

  return (
    <>
      <Form.Group style={{ display: 'block' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
          <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : "" }}>{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
          {item.ayuda &&
            <BtnAyuda ayuda={item.ayuda} />
          }
        </div>
        <Controller
          control={control}
          name={`${nombre}.${item.nombre}`}
          defaultValue={item.valor[0] ? item.valor[0] : ''}
          {...reg(`asesoria.${index}.${item.nombre}`, {
            required: item.es_requerido
          })}
          render={({ onChange, value }) => (
            <Select
              id='selectPersonalGerencia'
              ref={reg}
              isClearable
              classNamePrefix="addl-class"
              options={item.valores_opcion}
              name={`asesoria.${index}.${item.nombre}`}
              value={value}
              onChange={(e) => onChange(getMultiples(e))}
              closeMenuOnSelect={true}
              placeholder={t('formulario.label.seleccione')}
              isDisabled={esEditable}
              menuPosition="fixed"
            />
          )}
        />
        {error ?
          (
            error.type === 'required' ?
              (
                <span className="inputsInvalid">
                  Este campo es requerido
                </span>
              )
              : null)
          : null}
        <input type={"hidden"} name={`asesoria.${index}.${item.nombre}`} value={data} ref={reg} />
      </Form.Group>
      {item.nombre.includes('bus_') && initialState?.data?.regs?.length &&
        <TablaDinamica
          initialState={initialState}
          setInitialState={setInitialState}

        />

      }
    </>

  );

}

export default MultipleUnique;
