import { createContext, useContext } from 'react'; //useState, useContext, useMemo,

const EventBusContext = createContext();

export const useEventBus = () => {
    return useContext(EventBusContext);
};

export const EventBusProvider = ({ children }) => {
    const eventBus = {
        listeners: {},
        subscribe(event, callback) {
            if (!this.listeners[event]) {
                this.listeners[event] = [];
            }
            this.listeners[event].push(callback);
        },
        emit(event, data) {
            if (this.listeners[event]) {
                this.listeners[event].forEach(callback => callback(data));
            }
        },
        unsubscribe(event, callback) {
            if (this.listeners[event]) {
                this.listeners[event] = this.listeners[event].filter(
                    listener => listener !== callback
                );
            }
        }

    };

    return (
        <EventBusContext.Provider value={eventBus}>
            {children}
        </EventBusContext.Provider>
    );
};