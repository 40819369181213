import React, { Fragment, useState, useEffect } from 'react'
import { Button, Card, Form } from 'react-bootstrap';
import { validarEntorno } from '../../../../helpers/validarHost'
import NotificationModal from '../../../modales/NotificationModal';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function InfoGeneral() {

  const { id } = useParams();
  const HASH = window.location.hash;
  const { t } = useTranslation();



  const [form, setForm] = useState({
    nombre: '',
    fecha_inicial: '',
    fecha_fin: '',
    panoramas: [],
    analistas: []
  })
  const [isDisabled, setIsDisabled] = useState(false)
  const [validated, setValidated] = useState(false);
  const [initialState, setInitialState] = useState({
    error: null,
    data: {},
    loading: false
  })
  const [modal, setModal] = useState({
    show: false,
    tipo: '',
    data: {
      mensaje: '',
      id: ''
    },
  })

  const closeModal = () => {
    setModal({
      show: false,
      tipo: '',
      data: {
        mensaje: '',
        id: ''
      },
    })
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })

    console.log(form)

  }
  //estado bandera para que el boton solo pueda ser accionado una vez

  const [changeB, setChangeB] = useState(false);


  function click() {
    // setChangeB(true);
    console.log(" asdasdasd");
    // return;
  }

  // useEffect(()=>{
  //   setTimeout(()=>{setChangeB(false)}, 20000)
  // },[changeB])
  const handleSubmit = async (e) => {
    setChangeB(true)
    e.preventDefault()

    const dataReq = {
      id: id,
      hash: HASH,
      dataForm: form,
      tipo: 'UPDATE'
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_general_update.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()

      if (res.estado === 'ok') {
        setModal({ ...modal, show: true, tipo: 'successAlt' })
        setChangeB(false)
      } else {
        setModal({ ...modal, show: true, tipo: 'errorFormSolicitud' })
        setChangeB(false)
      }

      console.log(res)
      // setInitialState({...initialState, data: res})

      setModal({
        show: true,
        tipo: 'eliminacion_confirmada',
        data: {
          mensaje: res.mensaje
        }
      })

    }
    setChangeB(false)

  }

  const getMultiples = (value, tipo) => {

    // console.log(value)
    // console.log(tipo)
    setForm({
      ...form,
      [tipo]: value
    })
  }

  const getInfoGeneral = async () => {

    const dataReq = {
      id: id,
      hash: HASH,
      tipo: 'GUARDAR'
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_general.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, data: res })
      setForm({
        nombre: res.valores.nombre,
        fecha_inicial: res.valores.fecha_inicial,
        fecha_fin: res.valores.fecha_fin,
        panoramas: res.valores.panoramas,
        analistas: res.valores.analistas

      })
    }



  }

  useEffect(() => {
    getInfoGeneral()
  }, [])


  return (
    <>
      <Form
        // noValidate validated={validated}
        onSubmit={handleSubmit}
        className='formEstudioBasicoContainer'
      >
        <div className='formItem' id='basicFormA'>
          <Form.Group>
            <Form.Label>
              {t('formulario.label.nombre')}
            </Form.Label>
            <div className='inputContainer'>
              <Form.Control
                onChange={handleChange}
                name='nombre'
                // required
                // type='text'
                placeholder={t('formulario.label.nombre')}
                defaultValue={initialState?.data?.valores?.nombre}
              />
              {/* <Form.Control.Feedback type='invalid'>Este campo es obligatorio </Form.Control.Feedback> */}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('formulario.label.fecha_final')}
            </Form.Label>
            <div className='inputContainer'>
              <Form.Control
                defaultValue={initialState?.data?.valores?.fecha_fin}
                name="fecha_fin"
                type="date"
                onChange={handleChange}
                required
                className="formControlFiltrolGeneral form-control"
              />
              <Form.Control.Feedback type='invalid'>Ingresa una fecha </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('formulario.label.analistas_asignados')}
            </Form.Label>
            <div className='inputContainer'>
              <Select
                id='selectPersonalGerencia'
                // ref={reg}
                classNamePrefix="addl-class"
                // options={item.valores_opcion}
                name={'analistas'}
                // defaultValue={item.valor}
                value={form?.analistas}
                options={initialState?.data?.listas?.analistas}

                // options={contenido?.analistas}
                onChange={(e) => getMultiples(e, 'analistas')}
                // onChange={(e) => onChange(getMultiples(e))}
                closeMenuOnSelect={false}
                isMulti={true}
                placeholder={t('formulario.label.seleccione')}
              // isDisabled={esEditable}
              />
              <Form.Control.Feedback type='invalid'>Ingresa el nombre del responsable </Form.Control.Feedback>
            </div>
          </Form.Group>
        </div>
        <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>
          <Form.Group>
            <Form.Label>
              {t('formulario.label.fecha_inicial')}
            </Form.Label>
            <div className='inputContainer'>
              <Form.Control
                defaultValue={initialState?.data?.valores?.fecha_inicial}

                name="fecha_inicial" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
              <Form.Control.Feedback type='invalid'>Ingresa una fecha </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('formulario.label.fecha_finalizacion')}
            </Form.Label>
            <div className='inputContainer'>
              <Form.Control
                defaultValue={initialState?.data?.valores?.fecha_finalizacion}
                name="fecha_finalizacion"
                type="date"
                onChange={handleChange}
                required
                className="formControlFiltrolGeneral form-control"
              />
              <Form.Control.Feedback type='invalid'>Ingresa una fecha </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('formulario.label.panoramas')}</Form.Label>
            <Select
              id='selectPersonalGerencia90'
              // ref={reg}
              classNamePrefix="w-90"
              // options={item.valores_opcion}
              name={'panoramas'}
              value={form?.panoramas}
              style={{ width: '90%' }}
              // defaultValue={item.valor}
              // value={item.valores_opcion.find(c => c.value === value)}
              // onChange={(e) => onChange(getMultiples(e))}
              onChange={(e) => getMultiples(e, 'panoramas')}
              // options={contenido?.panoramas}
              options={initialState?.data?.listas?.panoramas}

              closeMenuOnSelect={false}
              isMulti={true}
              placeholder={t('formulario.label.seleccione')}
            // isDisabled={esEditable}
            />
            <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
          </Form.Group>

        </div>
        {
          !changeB ?
            <div className='buttonContainer'>
              <Button type='submit' className='confirmButton' onClick={click} >{t('botones.enviar')}</Button>
            </div>
            :
            <div className='buttonContainer'>
              <Button type='submit' className='confirmButton' onClick={click} >{t('botones.enviar')}</Button>
            </div>
        }
      </Form>

      <NotificationModal
        modal={modal}
        typeModal={modal.tipo}
        setIsOpen={closeModal}
        isOpen={modal.show}
        mensaje={modal.data.mensaje}
        codigoSuccess={modal.data.id}
      />
    </>
  )
}
