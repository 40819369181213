import React, { forwardRef, useState, useEffect, Fragment } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { validarEntorno } from '../../../../helpers/validarHost'
import { envioDataCrearSolicitud } from '../../../../reducers/crearSolicitudReducer'
import { CalendarSVG } from '../../../icons/CalendarSVG'
// import DatePicker from "react-datepicker";
import GenericModal from '../../../modales/NotificationModal'
import Select from 'react-select';
import { deptos } from '../../../../helpers/dptos_col.js';
import { useTranslation } from 'react-i18next'


export const EstudioBasicoPestaña = ({ propiedades, ele, index, selects, pestaña, url, selectSeleccionado }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const crearSolicitud = useSelector(state => state.crearSolicitud)
  const codigoFuncion = crearSolicitud.codigo ? crearSolicitud.codigo : undefined
  const [validated, setValidated] = useState(false);
  const { data } = useSelector(state => state.solicitudEstudio)
  const [dataSelects, setDataSelects] = useState(false)
  const [selectCiudad, setSelectCiudad] = useState(false)
  const [DepartamentosCiudadesCol, setDepartamentosCiudadesCol] = useState(false)


  useEffect(() => {
    dataSelectsFuncion(data.data)
    editDeptos();

  }, [])


  const { data: dataArr } = data;
  const dataSelectsFuncion = (data) => {
    data.forEach(element => {
      if (element.pestana === pestaña) {
        setDataSelects(element?.contenido[0]?.data)

      }
    });

  }

  const [campoReg, setCampoReg] = useState('')


  const [tipoModal, setTipoModal] = useState('')
  const [modal, setModal] = useState(false)
  const [mensajeModal, setMensajeModal] = useState('')
  const [codigoSucces, setCodigoSucces] = useState('')

  const [selectCuenta, setSelectCuenta] = useState(false)
  const [selectCentroCostos, setSelectCentroCostos] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [changeB, setChangeB] = useState(false);

  const { label } = selects


  const [info, setInfo] = useState({
    hash: window.location.hash,
    cargo: '',
    centroCostos: '',
    cuenta: '',
    departamento: '',
    primer_nombre: '',
    primer_apellido: '',
    tipo_identificacion: '',
    identificacion: '',
    email: '',
    observaciones: ''
  });



  const [startDate, setStartDate] = useState(Date.now())


  const editDeptos = () => {
    if (deptos) {
      // const transformDeptos = deptos.data.map((depto) => {
      //     return {
      //         "value" : `${depto[1]} - ${depto[2]}`,
      //         "label" : `${depto[1]} - ${depto[2]}`,
      //     }
      // })
      // transformDeptos.sort();
      setDepartamentosCiudadesCol(deptos.data)
    }
  }

  const handleSelect = (e, name) => {
    if (name) {
      setInfo({
        ...info,
        [name]: e?.value,
      })
    }
  }


  const ExampleCustomInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className="dateBox" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
        <div style={{ display: 'flex', paddingLeft: '7px', paddingRight: '7px' }}>
          <label onClick={onClick} ref={ref} className='mr-3'>
            {value}
          </label>
          <i onClick={onClick} ref={ref} >
            <CalendarSVG />
          </i>
        </div>
      </div>

    ),
  );


  const handleChange = e => {

    const reg = new RegExp(/[^0-9_]/g)

    if (e?.target?.name === 'cuenta') {
      setSelectCuenta(true)
    }

    if (e?.target?.name === 'centoCostos' || e?.target?.name === 'centroCostos') {
      setSelectCentroCostos(true)
    }



    if (e?.target?.name === 'radicado') {
      const validate = reg.test(e.target.value)
      if (!validate) {
        setCampoReg(e.target.value)
        setInfo({
          ...info,
          radicado: e.target.value,
        })

      }

    }

    else if (e?.target?.name === 'n_dictamen') {
      // n_dictamen
      const reg2 = new RegExp(/[^0-9-]/g)

      const validado2 = reg2.test(e.target.value)

      if (!validado2) {
        // setCampoReg(e.target.value)
        setInfo({
          ...info,
          [e.target.name]: e.target.value,
        })

      }
    } else {

      if (e?.target?.name) {
        setInfo({
          ...info,
          [e.target.name]: e.target.value,
        })
      } else {
        setInfo({
          ...info,
          fecha_dictamen: e,
        })
      }

    }



  };




  const handleSubmit = async (e) => {
    setChangeB(true)
    let dataReq = info;

    console.log(dataReq)

    e.preventDefault()
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      setChangeB(false)
    }

    setValidated(true)

    if (info.centroCostos === '') {
      e.preventDefault();

      setSelectCentroCostos(true)
      setChangeB(false)

    }

    if (form.checkValidity() === true && (info?.departamento !== "" && info?.centroCostos !== "" && (info?.cuenta !== "" && info?.cuenta !== undefined))) {

      setIsDisabled(true)

      if (info.tipo_identificacion === "") {
        let infoEnviar = info
        infoEnviar.tipo_identificacion = "2"
        infoEnviar.tipoSolicitud = selectSeleccionado
        console.log(infoEnviar)
        const respuesta = await validarEntorno('JSON/informacion_general_json.html', 'POST', infoEnviar)

        if (info.centroCostos === '') {
          e.preventDefault();

          setSelectCentroCostos(true)
          setChangeB(false)
        }

        if (respuesta.ok) {

          const body = await respuesta.json()
          if (body.ok.estado) {
            dispatch(envioDataCrearSolicitud(body))
            setTipoModal('crearSolicitud')
            setMensajeModal('')
            setModal(true)
            setIsDisabled(false)

            setCodigoSucces(body.idSolicitud)
            setChangeB(false)

          } else {
            setIsDisabled(false)
            setMensajeModal(body.ok.mensaje)
            setModal(true)
            setTipoModal('errorFormSolicitud')
            setChangeB(false)
          }

        }

        setChangeB(false)
      } else {



        let infoEnviar = info

        infoEnviar.tipoSolicitud = selectSeleccionado

        // console.log(infoEnviar)

        const respuesta = await validarEntorno('JSON/estudio_inteligencia_json.html', 'POST', infoEnviar)
        if (respuesta.ok) {
          const body = await respuesta.json()
          if (body.ok.estado) {
            dispatch(envioDataCrearSolicitud(body))
            setTipoModal('crearSolicitud')
            setMensajeModal('')
            setModal(true)
            setIsDisabled(false)

            setCodigoSucces(body.idSolicitud)
            setChangeB(false)

          } else {


            setMensajeModal(body.ok.mensaje)
            setModal(true)
            setTipoModal('errorFormSolicitud')
            setChangeB(false)
          }
        }
      }
      //setModal(true)
    }

    setChangeB(false)


  };

  const renderSeccionUno = (elemento) => {
    return elemento?.data?.options?.map(ele => {
      return <option value={ele.value}>{ele.label}</option>
    })

  }

  return (
    <Fragment key={index}>
      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ margin: '0px', paddingRight: '0px' }}>
        {ele?.contenido[0]?.visualizar
          &&
          <Card className='card__box__surface p-5 card__plain__top__left'>
            <h5 className='titleInsideCard'>{t('formulario.label.informacion_solicitud')}</h5>
            <Card.Body>

              <div className='formEstudioBasicoContainer' >
                <div className='formItem' id='basicFormA'>

                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.cargo')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='cargo' required type='text' placeholder={t('formulario.label.cargo')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_cargo')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.tipo_identificacion')}
                    </Form.Label>
                    <div className='inputContainer' >
                      <Form.Control defaultValue='0' onChange={handleChange} className='form-control' name='tipo_identificacion' id='typeIdSelect' as='select' custom required>
                        <option disabled value="0">{t('formulario.label.seleccione')}</option>
                        <option value="2" selected > {t('formulario.select.cedula_ciudadania')} </option>
                        <option value="3"> {t('formulario.select.cedula_extranjeria')} </option>
                        <option value="4"> {t('formulario.select.tarjeta_identidad')} </option>
                        <option value="9"> {t('formulario.select.registro_civil')} </option>
                        <option value="1"> {t('formulario.label.nit')} </option>
                        <option value="5"> {t('formulario.select.pasaporte')} </option>
                        <option value="10"> {t('formulario.select.permiso_proteccion_temporal')} </option>
                        <option value="14"> {t('formulario.select.dni_panama')} </option>
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                    </div>

                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.identificacion')}
                    </Form.Label>
                    <div className='inputContainer'>

                      <Form.Control
                        onChange={handleChange}
                        name='identificacion'
                        type='number'
                        placeholder={t('formulario.label.identificacion')}
                        min='0'
                        max='9999999999'
                        required
                        onWheel={(e) => e.target.blur()}
                      ></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_identificacion')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.nombre')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='primer_nombre' required type='text' placeholder={t('formulario.label.primer_nombre')}></Form.Control>
                      <Form.Control onChange={handleChange} name='segundo_nombre' type='text' placeholder={t('formulario.label.segundo_nombre')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_nombres')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.telefono')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='telefono' type='text' placeholder={t('formulario.label.telefono')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_telefono')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>


                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.correo_electronico_candidato')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='email' z type='email' placeholder={t('formulario.label.correo_electronico')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_correo_valido')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>

                      {t('formulario.label.ciudad_municipo')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Select
                        id='selectPersonalGerencia'
                        isClearable
                        options={DepartamentosCiudadesCol}
                        name={'departamento'}
                        onChange={(e) => handleSelect(e, 'departamento')}
                        closeMenuOnSelect={true}
                        placeholder={t('formulario.label.seleccione')}
                        isDisabled={false}
                      />
                      {!info?.departamento ?
                        (
                          <span className="inputsInvalid">
                            {t('formulario.error.campo_requerido')}
                          </span>
                        )
                        : null}
                    </div>
                  </Form.Group>
                </div>
                <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>

                  {
                    dataSelects && dataSelects[0]?.options?.length > 0
                      ?
                      <>
                        {
                          dataSelects[0]?.options?.length > 0 && dataSelects.map((selects, index) => {
                            return <Form.Group key={index}>
                              <Form.Label>
                                {selects.label}
                              </Form.Label>
                              <div className='inputContainer'>
                                <Select
                                  id={selects.name}
                                  isClearable
                                  options={selects.options}
                                  name={selects.name}
                                  onChange={(e) => handleSelect(e, selects.name)}

                                  closeMenuOnSelect={true}
                                  placeholder='-- Seleccione --'
                                  isDisabled={false}
                                />
                                {(selects.requerido === true && !info?.[selects.name]) ?
                                  (
                                    <span className="inputsInvalid">
                                      {t('formulario.error.campo_requerido')}
                                    </span>
                                  )
                                  : null}
                                {/* <Form.Control
                                                                    name={selects?.name}
                                                                    onChange={handleChange}
                                                                    required={true}
                                                                    as='select'
                                                                    type='text'
                                                                    defaultValue=''
                                                                    // readOnly={codigoFuncion === undefined}
                                                                    style={{ background: 'white' }}
                                                                    isInvalid = {
                                                                        selects?.name === 'cuenta'
                                                                        ?
                                                                        !selectCuenta
                                                                        :
                                                                        !selectCentroCostos
                                                                    }
                                                                    isValid = {
                                                                        selects?.name === 'cuenta'
                                                                        ?
                                                                        selectCuenta
                                                                        :
                                                                        selectCentroCostos
                                                                    }
                                                                >
                                                                    <option value='' disabled>-- Seleccione --</option>

                                                                    {
                                                                        selects.options.map(option => {
                                                                            return <option key={option.value} value={option.value}>{option.label}</option>
                                                                        })
                                                                    }

                                                                </Form.Control>
                                                                <Form.Control.Feedback type='invalid'>Este campo es obligatorio</Form.Control.Feedback> */}
                              </div>
                            </Form.Group>
                          })

                        }
                      </>
                      :
                      <>
                        <Form.Group>
                          <Form.Label>
                            Centro de costos
                          </Form.Label>
                          <div className=''>
                            <Form.Control
                              type='text'
                              name='centroCostos'
                              onChange={(e) => handleChange(e)}
                              style={{ background: 'white' }}
                              placeHolder='Centro de costos'
                              required
                              isInvalid={!selectCentroCostos}
                              isValid={selectCentroCostos}

                            />
                            <Form.Control.Feedback type='invalid' > {t('formulario.error.campo_obligatorio')} </Form.Control.Feedback>

                          </div>
                        </Form.Group>
                        <Form.Group key={index}>
                          <Form.Label>
                            Cuenta
                          </Form.Label>
                          <div className='inputContainer'>
                            <Select
                              id='selectPersonalGerencia'
                              // ref={reg}
                              isClearable
                              // classNamePrefix="addl-class"
                              options={dataSelects[1]?.options}
                              name={'cuenta'}
                              // value={value}
                              // onChange={(e) => onChange(getMultiples(e))}
                              onChange={(e) => handleSelect(e, 'cuenta')}

                              closeMenuOnSelect={true}
                              placeholder='-- Seleccione --'
                              isDisabled={false}
                            />
                            {(info?.cuenta === '' || info?.cuenta === undefined) ?
                              (

                                <span className="inputsInvalid">
                                  {t('formulario.error.campo_requerido')}
                                </span>
                              )
                              : null}
                            {/* <Form.Control
                                                        name='cuenta'
                                                        onChange={handleChange}
                                                        required={true}
                                                        as='select'
                                                        defaultValue=''
                                                        type='text'
                                                        // readOnly={codigoFuncion === undefined}
                                                        style={{ background: 'white' }}
                                                        isInvalid = {!selectCuenta}
                                                        isValid = {selectCuenta}
                                                    >
                                                        <option value='' disabled>-- Seleccione --</option>
                                                        {
                                                            dataSelects[1]?.options?.map(option => {
                                                                return <option key={option.value} value={option.value}>{option.label}</option>
                                                            })
                                                        }
                                                    </Form.Control>
                                                    <Form.Control.Feedback type='invalid'>Este campo es obligatorio</Form.Control.Feedback>
                                                 */}
                          </div>
                        </Form.Group>
                      </>
                  }


                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.apellido')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='primer_apellido' required type='text' placeholder={t('formulario.label.primer_apellido')}></Form.Control>
                      <Form.Control onChange={handleChange} name='segundo_apellido' type='text' placeholder={t('formulario.label.segundo_apellido')}></Form.Control>

                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_apellidos')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.celular')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='celular' type='text' placeholder={t('formulario.label.celular')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa un celular</Form.Control.Feedback>
                    </div>
                  </Form.Group>





                </div>
                <div id='observacionesContainerEstudioBasico'>
                  <Form.Group id='observacionesFormGroup'>
                    <Form.Label className='observacionesLabel'>
                      {t('formulario.label.observaciones')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} as='textarea' placeholder={t('formulario.label.observaciones')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_observaciones')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
                {!changeB ?
                  <div className='buttonContainer'>
                    <button className='confirmButton' disabled={isDisabled} >{t('botones.guardar')}</button>
                    {/* <ModalConfirmacion typeModal='error' isOpen={showModal} setIsOpen={setShowModal} />                */}
                  </div>
                  :
                  <div className='buttonContainer'>
                    <button className='confirmButton' disabled={isDisabled} >{t('botones.guardar')}</button>
                    {/* <ModalConfirmacion typeModal='error' isOpen={showModal} setIsOpen={setShowModal} />                */}
                  </div>

                }

              </div>
            </Card.Body>
          </Card>

        }

        {
          ele?.contenido[1]?.visualizar
          && <Card className='card__box__surface p-5 mt-3' style={{ borderTopLeftRadius: '10px' }}>
            <h5 className='titleInsideCard'>Información Complementaria</h5>
            <Card.Body>
              <div className='formEstudioBasicoContainer' noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='formItem' id='basicFormA'>

                  <Form.Group>
                    <Form.Label>
                      Razón Social del Cliente a prestar el Servicio*
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='cliente' required type='text' placeholder=' Razón Social'>

                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa la razón social</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      NIT del Cliente*
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='cod_suc' required type='number' placeholder='NIT'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa el nit</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      Convenio del Cliente
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='cod_cco' required type='text' placeholder='Convenio del Cliente'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa el convenio del cliente</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                </div>
                <div className='formItem' id='basicFormB' style={{ marginLeft: '10px', }}>

                  <Form.Group >
                    <Form.Label>Regional*</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="bodega"
                        onChange={handleChange}
                        required={true}
                        as='select'
                        type='text'
                      >

                        <option value="">--Ninguno--</option>
                        <option value="Centro"> Centro </option>
                        <option value="Norte"> Norte </option>
                        <option value="Occidente"> Occidente </option>
                        <option value="Outsourcing"> Outsourcing </option>
                        <option value="BPO"> BPO </option>
                        <option value="Grandes cuentas"> Grandes cuentas </option>
                        <option value="Professional"> Professional </option>
                        <option value="Recursos Humanos"> Recursos Humanos </option>
                        <option value="Payroll"> Payroll </option>
                        <option value="Medical"> Medical </option>
                        <option value="pp other"> pp other </option>

                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Re-facturable*</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="alterno"
                        onChange={handleChange}
                        required={true}
                        as='select'
                        type='text'
                      >


                        <option value="">--Ninguno--</option>
                        <option value="Si"> Si </option>
                        <option value="No"> No </option>


                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group >
                    <Form.Label>Clasificador 1</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="clasificador_1"
                        onChange={handleChange}

                        as='select'
                        type='text'
                      >
                        {
                          renderSeccionUno(dataArr[0]?.contenido[1])
                        }
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group >
                    <Form.Label>Clasificador 3</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="clasificador_3"
                        onChange={handleChange}
                        as='select'
                        type='text'
                      >
                        <option value="">--Ninguno--</option>
                        <option value="BO10002"> BO10002 </option>
                        <option value="IMP10003"> IMP10003 </option>
                        <option value="BO10004"> BO10004 </option>
                        <option value="BO00001"> BO00001 </option>
                        <option value="BO00002"> BO00002 </option>
                        <option value="FV00003"> FV00003 </option>
                        <option value="BO00004"> BO00004 </option>
                        <option value="BO00005"> BO00005 </option>
                        <option value="FO00006"> FO00006 </option>
                        <option value="FV00007"> FV00007 </option>
                        <option value="BO00008"> BO00008 </option>
                        <option value="FV00009"> FV00009 </option>
                        <option value="FV00010"> FV00010 </option>
                        <option value="BO00011"> BO00011 </option>
                        <option value="FO00012"> FO00012 </option>
                        <option value="BO00013"> BO00013 </option>
                        <option value="BO00014"> BO00014 </option>
                        <option value="FO00015"> FO00015 </option>
                        <option value="IMP00016"> IMP00016 </option>
                        <option value="BO00017"> BO00017 </option>
                        <option value="BO00018"> BO00018 </option>
                        <option value="FV00019"> FV00019 </option>
                        <option value="BO00020"> BO00020 </option>
                        <option value="FO00021"> FO00021 </option>
                        <option value="IND00022"> IND00022 </option>
                        <option value="FO00023"> FO00023 </option>
                        <option value="BO00024"> BO00024 </option>
                        <option value="BO00025"> BO00025 </option>
                        <option value="IND00026"> IND00026 </option>
                        <option value="LOG00027"> LOG00027 </option>
                        <option value="BO00028"> BO00028 </option>
                        <option value="BO00029"> BO00029 </option>
                        <option value="BO00030"> BO00030 </option>
                        <option value="BO00031"> BO00031 </option>
                        <option value="IMP00032"> IMP00032 </option>
                        <option value="BO00034"> BO00034 </option>
                        <option value="BO00035"> BO00035 </option>
                        <option value="BO00036"> BO00036 </option>
                        <option value="IMP00094"> IMP00094 </option>
                        <option value="BO00138"> BO00138 </option>
                        <option value="BO00038"> BO00038 </option>
                        <option value="BO00039"> BO00039 </option>
                        <option value="BO00040"> BO00040 </option>
                        <option value="IMP00041"> IMP00041 </option>
                        <option value="IMP00042"> IMP00042 </option>
                        <option value="IMP00042"> IMP00042 </option>
                        <option value="FV00043"> FV00043 </option>
                        <option value="FV00044"> FV00044 </option>
                        <option value="BO00045"> BO00045 </option>
                        <option value="IMP00046"> IMP00046 </option>
                        <option value="IMP00047"> IMP00047 </option>
                        <option value="IMP00048"> IMP00048 </option>
                        <option value="BO00049"> BO00049 </option>
                        <option value="FO00050"> FO00050 </option>
                        <option value="IMP00051"> IMP00051 </option>
                        <option value="BO00052"> BO00052 </option>
                        <option value="BO00053"> BO00053 </option>
                        <option value="BO00054"> BO00054 </option>
                        <option value="FO00055"> FO00055 </option>
                        <option value="BO00056"> BO00056 </option>
                        <option value="BO00057"> BO00057 </option>
                        <option value="FO00058"> FO00058 </option>
                        <option value="BO00059"> BO00059 </option>
                        <option value="FO00060"> FO00060 </option>
                        <option value="BO00061"> BO00061 </option>
                        <option value="BO00062"> BO00062 </option>
                        <option value="SST00063"> SST00063 </option>
                        <option value="BO00064"> BO00064 </option>
                        <option value="BO00065"> BO00065 </option>
                        <option value="BO00066"> BO00066 </option>
                        <option value="FO00067"> FO00067 </option>
                        <option value="IMP00068"> IMP00068 </option>
                        <option value="FV00069"> FV00069 </option>
                        <option value="BO00070"> BO00070 </option>
                        <option value="IMP00071"> IMP00071 </option>
                        <option value="BO00072"> BO00072 </option>
                        <option value="IMP00073"> IMP00073 </option>
                        <option value="BO00074"> BO00074 </option>
                        <option value="IMP00075"> IMP00075 </option>
                        <option value="IMP00076"> IMP00076 </option>
                        <option value="IMP00077"> IMP00077 </option>
                        <option value="BO00078"> BO00078 </option>
                        <option value="IMP00079"> IMP00079 </option>
                        <option value="IMP00080"> IMP00080 </option>
                        <option value="IMP00081"> IMP00081 </option>
                        <option value="IMP00082"> IMP00082 </option>
                        <option value="IMP"> IMP </option>
                        <option value="BO"> BO </option>
                        <option value="BO00083"> BO00083 </option>
                        <option value="IMP00084"> IMP00084 </option>
                        <option value="BO00085"> BO00085 </option>
                        <option value="BO00086"> BO00086 </option>
                        <option value="BO00087"> BO00087 </option>
                        <option value="FO00088"> FO00088 </option>
                        <option value="BO00089"> BO00089 </option>
                        <option value="FV00090"> FV00090 </option>
                        <option value="BO00091"> BO00091 </option>
                        <option value="IMP00092"> IMP00092 </option>
                        <option value="BO00139"> BO00139 </option>
                        <option value="FO00140"> FO00140 </option>
                        <option value="BO00095"> BO00095 </option>
                        <option value="BO00096"> BO00096 </option>
                        <option value="BO00097"> BO00097 </option>
                        <option value="IMP00098"> IMP00098 </option>
                        <option value="000142"> 000142 </option>
                        <option value="IMP00100"> IMP00100 </option>
                        <option value="FO00101"> FO00101 </option>
                        <option value="BO00102"> BO00102 </option>
                        <option value="IMP00103"> IMP00103 </option>
                        <option value="IMP00104"> IMP00104 </option>
                        <option value="BO00141"> BO00141 </option>
                        <option value="BO00106"> BO00106 </option>
                        <option value="BO00107"> BO00107 </option>
                        <option value="BO00108"> BO00108 </option>
                        <option value="FO00109"> FO00109 </option>
                        <option value="IMP00110"> IMP00110 </option>
                        <option value="IND00111"> IND00111 </option>
                        <option value="BO00112"> BO00112 </option>
                        <option value="IND00113"> IND00113 </option>
                        <option value="BO00114"> BO00114 </option>
                        <option value="BO00115"> BO00115 </option>
                        <option value="IMP00116"> IMP00116 </option>
                        <option value="FO00117"> FO00117 </option>
                        <option value="BO00118"> BO00118 </option>
                        <option value="BO00119"> BO00119 </option>
                        <option value="FV00120"> FV00120 </option>
                        <option value="BO00121"> BO00121 </option>
                        <option value="FO00122"> FO00122 </option>
                        <option value="IMP00123"> IMP00123 </option>
                        <option value="BO00124"> BO00124 </option>
                        <option value="BO00125"> BO00125 </option>
                        <option value="IMP00126"> IMP00126 </option>
                        <option value="IMP00127"> IMP00127 </option>
                        <option value="BO00128"> BO00128 </option>
                        <option value="BO00129"> BO00129 </option>
                        <option value="LOG00130"> LOG00130 </option>
                        <option value="FO00131"> FO00131 </option>
                        <option value="BO00132"> BO00132 </option>
                        <option value="FO00133"> FO00133 </option>
                        <option value="BO00134"> BO00134 </option>
                        <option value="IMP00135"> IMP00135 </option>
                        <option value="FO00136"> FO00136 </option>
                        <option value="BO00137"> BO00137 </option>
                        <option value="IMP00037"> IMP00037 </option>
                        <option value="BO00093"> BO00093 </option>
                        <option value="IMP00099"> IMP00099 </option>
                        <option value="BO00105"> BO00105 </option>
                        <option value="IMP00224"> IMP00224 </option>
                        <option value="LOG00143"> LOG00143 </option>
                        <option value="IMP00144"> IMP00144 </option>
                        <option value="BO00145"> BO00145 </option>
                        <option value="IMP00146"> IMP00146 </option>
                        <option value="IMP00147"> IMP00147 </option>
                        <option value="IMP00148"> IMP00148 </option>
                        <option value="FO00149"> FO00149 </option>
                        <option value="IMP00150"> IMP00150 </option>
                        <option value="BO00151"> BO00151 </option>
                        <option value="BO00152"> BO00152 </option>
                        <option value="BO00153"> BO00153 </option>
                        <option value="FO00154"> FO00154 </option>
                        <option value="FO00155"> FO00155 </option>
                        <option value="BO00156"> BO00156 </option>
                        <option value="BO00157"> BO00157 </option>
                        <option value="IMP00158"> IMP00158 </option>
                        <option value="IMP00159"> IMP00159 </option>
                        <option value="FO00160"> FO00160 </option>
                        <option value="LO00161"> LO00161 </option>
                        <option value="BO00162"> BO00162 </option>
                        <option value="BO00163"> BO00163 </option>
                        <option value="FO00164"> FO00164 </option>
                        <option value="BO00165"> BO00165 </option>
                        <option value="BO00166"> BO00166 </option>
                        <option value="IMP00167"> IMP00167 </option>
                        <option value="BO00168"> BO00168 </option>
                        <option value="IMP00169"> IMP00169 </option>
                        <option value="BO00170"> BO00170 </option>
                        <option value="IMP00171"> IMP00171 </option>
                        <option value="IMP00172"> IMP00172 </option>
                        <option value="IMP00173"> IMP00173 </option>
                        <option value="IMP00174"> IMP00174 </option>
                        <option value="BO00175"> BO00175 </option>
                        <option value="BO00176"> BO00176 </option>
                        <option value="BO00177"> BO00177 </option>
                        <option value="IMP00178"> IMP00178 </option>
                        <option value="BO00179"> BO00179 </option>
                        <option value="IMP00180"> IMP00180 </option>
                        <option value="BO00181"> BO00181 </option>
                        <option value="BO00182"> BO00182 </option>
                        <option value="IMP00183"> IMP00183 </option>
                        <option value="BO00184"> BO00184 </option>
                        <option value="FO00185"> FO00185 </option>
                        <option value="BO00186"> BO00186 </option>
                        <option value="BO00187"> BO00187 </option>
                        <option value="BO00188"> BO00188 </option>
                        <option value="BO00189"> BO00189 </option>
                        <option value="BO00190"> BO00190 </option>
                        <option value="BO00191"> BO00191 </option>
                        <option value="IMP00192"> IMP00192 </option>
                        <option value="BO00193"> BO00193 </option>
                        <option value="FO00194"> FO00194 </option>
                        <option value="IMP00195"> IMP00195 </option>
                        <option value="BO00196"> BO00196 </option>
                        <option value="FO00197"> FO00197 </option>
                        <option value="BO00198"> BO00198 </option>
                        <option value="BO00199"> BO00199 </option>
                        <option value="BO00200"> BO00200 </option>
                        <option value="IMP00201"> IMP00201 </option>
                        <option value="BO00202"> BO00202 </option>
                        <option value="BO00203"> BO00203 </option>
                        <option value="FO00204"> FO00204 </option>
                        <option value="BO00205"> BO00205 </option>
                        <option value="FV00206"> FV00206 </option>
                        <option value="LOG00207"> LOG00207 </option>
                        <option value="IMP00208"> IMP00208 </option>
                        <option value="IMP00209"> IMP00209 </option>
                        <option value="IMP00210"> IMP00210 </option>
                        <option value="IMP00211"> IMP00211 </option>
                        <option value="IMP00212"> IMP00212 </option>
                        <option value="IMP00213"> IMP00213 </option>
                        <option value="BO000214"> BO000214 </option>
                        <option value="IMP00215"> IMP00215 </option>
                        <option value="BO00216"> BO00216 </option>
                        <option value="BO00217"> BO00217 </option>
                        <option value="IMP00218"> IMP00218 </option>
                        <option value="BO00219"> BO00219 </option>
                        <option value="BO00220"> BO00220 </option>
                        <option value="BO00221"> BO00221 </option>
                        <option value="BO00222"> BO00222 </option>
                        <option value="IMP00223"> IMP00223 </option>
                        <option value="BO00225"> BO00225 </option>
                        <option value="BO00234"> BO00234 </option>
                        <option value="BO00226"> BO00226 </option>
                        <option value="IMP00227"> IMP00227 </option>
                        <option value="IMP00228"> IMP00228 </option>
                        <option value="IMP00229"> IMP00229 </option>
                        <option value="BO00230"> BO00230 </option>
                        <option value="IMP00231"> IMP00231 </option>
                        <option value="BO00232"> BO00232 </option>
                        <option value="IMP00233"> IMP00233 </option>
                        <option value="IND00033"> IND00033 </option>
                        <option value="IMP00235"> IMP00235 </option>
                        <option value="BO00236"> BO00236 </option>
                        <option value="BO00237"> BO00237 </option>
                        <option value="IMP00238"> IMP00238 </option>
                        <option value="BO00239"> BO00239 </option>
                        <option value="BO00240"> BO00240 </option>
                        <option value="BO00241"> BO00241 </option>
                        <option value="BO00242"> BO00242 </option>
                        <option value="IMP00242"> IMP00242 </option>
                        <option value="BO00243"> BO00243 </option>
                        <option value="BO00244"> BO00244 </option>
                        <option value="FO00245"> FO00245 </option>
                        <option value="BO00246"> BO00246 </option>
                        <option value="BO00247"> BO00247 </option>
                        <option value="000248"> 000248 </option>
                        <option value="IMP00249"> IMP00249 </option>
                        <option value="BO00250"> BO00250 </option>
                        <option value="IMP00251"> IMP00251 </option>
                        <option value="IMP00252"> IMP00252 </option>
                        <option value="IMP00253"> IMP00253 </option>
                        <option value="IMP00254"> IMP00254 </option>
                        <option value="IMP00255"> IMP00255 </option>
                        <option value="BO00256"> BO00256 </option>
                        <option value="IMP00257"> IMP00257 </option>
                        <option value="IMP00258"> IMP00258 </option>
                        <option value="IMP00259"> IMP00259 </option>
                        <option value="IND00260"> IND00260 </option>
                        <option value="BO00261"> BO00261 </option>
                        <option value="BO00262"> BO00262 </option>
                        <option value="FV00263"> FV00263 </option>
                        <option value="LOG00264"> LOG00264 </option>
                        <option value="BO00265"> BO00265 </option>
                        <option value="BO00266"> BO00266 </option>
                        <option value="FV00267"> FV00267 </option>
                        <option value="IMP00268"> IMP00268 </option>
                        <option value="BO00269"> BO00269 </option>
                        <option value="IMP00270"> IMP00270 </option>
                        <option value="BO00271"> BO00271 </option>
                        <option value="IND00272"> IND00272 </option>
                        <option value="BO00273"> BO00273 </option>
                        <option value="IMP00274"> IMP00274 </option>
                        <option value="FO00275"> FO00275 </option>





                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                    </div>
                  </Form.Group>


                </div>

              </div>
            </Card.Body>
          </Card>
        }

        {
          ele?.contenido[2]?.visualizar
          && <Card className='card__box__surface p-5 mt-3' style={{ borderTopLeftRadius: '10px' }}>
            <h5 className='titleInsideCard'>Información Complementaria</h5>
            <Card.Body>
              <div className='formEstudioBasicoContainer' noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='formItem' id='basicFormA'>

                  <Form.Group>
                    <Form.Label>
                      Ciudad donde prestaron el servicio*
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='n_dictamen' type='text' placeholder='Número de dictamen'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa el número de dictamen</Form.Control.Feedback>
                    </div>
                  </Form.Group>


                </div>
                <div className='formItem' id='basicFormB' style={{ marginLeft: '10px', }}>
                  <Form.Group>
                    <Form.Label>
                      Empresa en misión para la cual va el candidato*
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='n_dictamen' required type='text' placeholder='Empresa en misión'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa el nombre de la empresa en misión</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                </div>

              </div>
            </Card.Body>
          </Card>
        }

        {
          ele?.contenido[3]?.visualizar
          && <Card className='card__box__surface p-5 mt-3' style={{ borderTopLeftRadius: '10px' }}>
            <h5 className='titleInsideCard'>Información Complementaria</h5>
            <Card.Body>
              <div className='formEstudioBasicoContainer' noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='formItem' id='basicFormA'>



                  <Form.Group >
                    <Form.Label>Temporal*</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="temporal"
                        onChange={handleChange}
                        required={true}
                        as='select'
                        type='text'
                      >

                        <option value="0">--No Aplica--</option>
                        <option value="840"> Alianza Temporales S.A.S. </option>
                        <option value="839"> Temporal Dar Ayuda - Servientrega </option>
                        <option value="842"> Temporal Gente Util - Servientrega </option>
                        <option value="841"> Temporal T&S - Servientrega </option>
                        <option value="1087"> Temporal Adecco - Servientrega </option>

                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Selecciona la temporal</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>

              </div>
            </Card.Body>
          </Card>
        }

        {
          ele?.contenido[4]?.visualizar
          && <Card className='card__box__surface p-5 mt-3' style={{ borderTopLeftRadius: '10px' }}>
            <h5 className='titleInsideCard'>Información Complementaria</h5>
            <Card.Body>
              <div className='formEstudioBasicoContainer' noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='formItem' id='basicFormA'>

                  <Form.Group>
                    <Form.Label>
                      {ele?.contenido[4]?.label ? ele?.contenido[4]?.label : 'Tienda*'}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange} name='tienda' required type='text' placeholder={ele?.contenido[4]?.label ? ele?.contenido[4]?.label : 'Tienda*'}></Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingrese la información de {ele?.contenido[4]?.label ? ele?.contenido[4]?.label : 'Tienda*'}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Card.Body>
          </Card>
        }



      </Form>

      <GenericModal typeModal={tipoModal} setIsOpen={setModal} isOpen={modal} mensaje={mensajeModal} codigoSuccess={codigoSucces} />
      {/* <GenericModal typeModal="crearSolicitud" setIsOpen={setModal} isOpen={modal} /> */}

    </Fragment>
  )
}
