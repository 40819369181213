import React, {useState} from 'react'
import { Card } from 'react-bootstrap'

import ModalAgregarAnalista from './ModalAgregarAnalista'

import './AsignacionAnalistas.scss'

export default function AsignacionAnalistas(props) {

  const [modalAddAnalista,setModalAddAnalista] = useState(false);

  const handleOpenModalAddAnalista = () => {
    setModalAddAnalista(true)
  }

  return (
    <>
      <Card className='shadow cardGeneral'>
        <Card.Body>
          <h4 className='cardTitle'>Asignación de analistas/investigadores</h4>
          {props.dataSolicitud.asignacionAnalistaOpcionAgregar === true
            ?
            <>
              <br/>
              <button id='botonAgregarAnalista' onClick={handleOpenModalAddAnalista}>asignar analista/investigador</button>
            </>
            : null
          }
        </Card.Body>
      </Card>
      <br/>
      <Card className='shadow cardGeneral'>
        <Card.Body>
          <h4 className='cardTitle'>Lista de analistas asignados</h4>
          <br/>
          <div className='tablaAnalistasContainer'>
            <table>
              <thead>
                <tr>
                  <th>Analista</th>
                  <th>Fecha de asignación</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      <ModalAgregarAnalista isOpen={modalAddAnalista} setIsOpen={setModalAddAnalista}/>
    </>
  )
}
