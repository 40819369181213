import { React, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { Card } from 'react-bootstrap'
import NotificationModal from '../../modales/NotificationModal'
// import Form from 'react-bootstrap/Form'
import './GestorDocumentos.scss'
import { useSelector } from 'react-redux';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { validarEntorno } from '../../../helpers/validarHost';

export default function GestorDocumentos(props) {

  // const [showAddFileModal, setShowAddModal] = useState(false);

  const [filesList, setFilesList] = useState()
  const { codigo } = useSelector(state => state.crearSolicitud)
  const dataFiles = [
    {
      id: '1',
      nombre: 'elegante.jpg',
      peso: '400mb',
    },
    {
      id: '2',
      nombre: 'hojadevida.pdf',
      peso: '800mb',
    },
    {
      id: '3',
      nombre: 'copiacedula.pdf',
      peso: '345kb',
    },
    {
      id: '4',
      nombre: 'diploma-grado.pdf',
      peso: '345kb',
    },
  ];

  const [data, setData] = useState(dataFiles);
  const [modalEliminar, setModalEliminar] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalrespuestaAddanddelete, setModalrespuestaAddanddelete] = useState(false)
  const [tipomodalRespuestaAddandDelete, setTipomodalRespuestaAddandDelete] = useState('')
  const [mensaje, setMensaje] = useState('')

  const [fileToDelete, setFileToDelete] = useState(false);

  const [fileToLoad, setFileToLoad] = useState({
    key: '',
    id: '',
    nombre: '',
    peso: '',
  });

  const deleteFile = async (item) => {


    const data = {
      idSolicitud: codigo,
      idfile: item.id,
      hash: window.location.hash 
    }

    const respuesta = await validarEntorno('JSON/gestor_documental_eliminar_archivo_json.html', 'POST', data);
    const body = await respuesta.json();

    if (body.ok) {
      // ok
      setModalrespuestaAddanddelete(true)
      setTipomodalRespuestaAddandDelete('SuccessDelatefile')
      // 
      setFilesList(body)
    } else {
      // errado
      setModalrespuestaAddanddelete(true)
      setTipomodalRespuestaAddandDelete('ErrorFile')
      setMensaje(body.mensaje)
    }

  }




  const selectedHandler = async (e) => {

    if (codigo !== null) {
      if (e.target.files[0]) {
        let data = {};
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)

        data = {
          file: formData,
          hash: window.location.hash,
          idSolicitud: codigo
        }
        const respuesta = await validarEntorno('JSON/gestor_documental_json.html', 'POST', data)
        const body = await respuesta.json();

        if (body.ok) {
          // ok
          setModalrespuestaAddanddelete(true)
          setTipomodalRespuestaAddandDelete('SuccessFile')
          // 
          setFilesList(body)
        } else {
          // errado
          setModalrespuestaAddanddelete(true)
          setTipomodalRespuestaAddandDelete('ErrorFile')
          setMensaje(body.mensaje)
        }

      }
      else {
        return
      }
    } else {
      setOpenModal(true)
    }
  }

  return (
    <div>
      <Card className='shadow'>
        <Card.Body>
          <h4 className='pt-4 pb-4' style={{ color: '#707070' }}>Soportes documentales</h4>
          {
            window.location.hash === "#/gerencia/inteligencia/solicitar"
            &&
            <div className='addButtonContainer' style={{ cursor: 'pointer' }}>
              <input id='selectFile' onChange={selectedHandler} className='selectFile' name='selectFile' type='file' style={{ cursor: 'pointer' }} />
              <label id='addButton' htmlFor='selectFile' style={{ cursor: 'pointer' }}>
                Añadir
                <AddIcon />
              </label>
            </div>


          }
          {props?.dataSolicitud?.soportesDocumentalesOpcionAgregar === true
            ?
            <div className='addButtonContainer'>
              <input id='selectFile' onChange={selectedHandler} className='selectFile' name='selectFile' type='file' />
              <label id='addButton' htmlFor='selectFile'>
                Añadir
                <AddIcon />
              </label>
            </div>
            : null
          }
          <br />
          <div className='filesTableContainer'>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre del archivo</th>
                  <th>Peso del archivo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filesList?.files?.map((item) => {
                  return (
                    <tr className='fileRow' key={item.id}>
                      <td>{item.id}</td>
                      <td ><a target="_blank" href={item.url} style={{ textDecoration: 'none', color: 'black' }} >{item.label}</a></td>
                      <td>{item.peso}</td>

                      <td>
                        <button className='deleteButton' onClick={(e) => deleteFile(item)}>Eliminar</button>
                      </td>

                    </tr>
                  )
                })}
              </tbody>
            </table>


          </div>
          <NotificationModal typeModal='solicitudSinCrear' isOpen={openModal} setIsOpen={setOpenModal}  ></NotificationModal>
          <NotificationModal typeModal={tipomodalRespuestaAddandDelete} isOpen={modalrespuestaAddanddelete} setIsOpen={setModalrespuestaAddanddelete} mensaje={mensaje} />

        </Card.Body>
      </Card>
    </div>
  )
}
