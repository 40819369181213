import React from 'react'

import './styles/login.scss'

import Logo from '../static/icons/cosinte-logo.png';

import SignIn from '../components/auth/SignIn'
// import { validarEntorno } from '../helpers/validarHost';


export default function Login() {
  // const options = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json', Account: '10020192', ApiKey: '6L3kGJE5SVAFqukeGIhHaFvKt5TGNs', Token: '937a8a0cb08094d48a676f4e1b182588' },
  //   body: '{"toNumber":"573006429976","sms":"SMS de prueba Hablame","flash":"0","sc":"890202","request_dlvr_rcpt":"0"}'
  // };

  // fetch('https://api103.hablame.co/api/sms/v3/send/priority', options)
  //   .then(response => response.json())
  //   .then(response => console.log(response))
  //   .catch(err => console.error(err));

  return (
    <div className='mainContainer'>
      <div className='mainCardContainer'>
        <div id='loginContainer'>
          <img src={'static/media/cosinte-logo.54caa07b.png'} alt='logo cosinte'></img>
          <SignIn></SignIn>
        </div>
        <div id='loginImage'>
        </div>
      </div>
    </div>
  )
}
