import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { validarEntorno } from '../../../../helpers/validarHost';
import { GeneradorPDF } from '../../../GestorDocumentos/GeneradorPDF';


export default function Preview() {

  // const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;

  const [initialState, setInitialState] = useState({
    loading: false,
    error: null,
    data: {}
  })
  
  // const [modal, setModal] = useState({
  //   show: false,
  //   tipo: 'modalInfoGestorDinamico',
  //   data: {
  //     tituloModal: '',
  //     contenidoModal: ''
  //   }
  // })
  
  const {id} = useParams();
  const HASH = window.location.hash;

  const items = async () => {

    const dataSend = {
        id: id,
        hash: HASH
    }

    const request = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_preview.html', 'POST', dataSend);

    if (request.ok) {
        const res = await request.json();

        setInitialState({...initialState, data: res});

        console.log(res)
    } 
}


  useEffect(() => {
    items();
  }, [])


  return (
    <>
      <Card>
        <Card.Body>
          <GeneradorPDF show={initialState.data?.pdf?.show} update={initialState.data?.pdf?.update} plantilla='panorama' id={id} />
        </Card.Body>
      </Card>
    </>
  )
}
