import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '../../ui/Card';
import TrendingInfo from "../../ui/TrendingInfo";

const ChartLayout = ({ title, description, children, footerTitle, footerDescription }) => {

    return (
        <Card>
            {(title || description) &&
                <CardHeader>
                    {title && <CardTitle>{title}</CardTitle>}
                    {description && <CardDescription>{description}</CardDescription>}
                </CardHeader>
            }
            <CardContent>
                {children}
            </CardContent>
            {(footerTitle || footerDescription) &&
            <CardFooter>
                <TrendingInfo trendText={footerTitle} periodText={footerDescription} />
            </CardFooter>
            }
        </Card>
    );
};

export default ChartLayout;