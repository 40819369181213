import React, {useState, useEffect} from 'react'
import { Card } from 'react-bootstrap'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_rangeSelector from "@amcharts/amcharts4/plugins/rangeSelector";

import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";
import { useTranslation } from 'react-i18next';

export default function GraficaConsolidado(props) {

  // console.log(props.series)
  const { t } = useTranslation();
  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);

    chart.colors.list = [
      am4core.color("#2c5e99"),
      am4core.color("#05a4db"),
      am4core.color("#009688"),
      am4core.color("#8BC34A"),
      am4core.color("#F9CF00"),
      am4core.color("#F39C11"),
      am4core.color("#F46036"),
      am4core.color("#D7263D"),
      am4core.color("#674ea7")
    ];

    chart.data = props.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    // dateAxis.renderer.labels.template.fill = am4core.color("#8BC34A");

    let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis2.renderer.grid.template.location = 0;
    // dateAxis2.renderer.labels.template.fill = am4core.color("#F9CF00");

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    // valueAxis.renderer.labels.template.fill = am4core.color("#8BC34A");

    valueAxis.renderer.minWidth = 60;

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.tooltip.disabled = true;
    // valueAxis2.renderer.labels.template.fill = am4core.color("#F9CF00");
    valueAxis2.renderer.minWidth = 60;
    valueAxis2.syncWithAxis = valueAxis;

    chart.svgContainer.autoResize = true;

    //chart.language.locale = am4lang_es_ES;

    // props.series.map((serie, index) => {
    //   return(
    //     {`${serie}${index}`} = chart.series.push(new am4charts.LineSeries())
    //     serie.name = {serie}
    //     serie.dataFields.dateX = "date1"
    //     serie.dataFields.valueY = "price1"
    //     serie.tensionX = 0.8
    //     serie.strokeWidth = 3
    //     serie.bullets.push(new am4charts.CircleBullet())
    //   )

    // })
    if(props.series){
      props.series.map((serie, index) => {

        let series
        let scrollbarX

        return (
              series = chart.series.push(new am4charts.LineSeries()),
              series.name = serie.nombre,
              series.dataFields.dateX = serie.dateX,
              series.dataFields.valueY = serie.valueX,
              series.tensionX = 0.8,
              series.strokeWidth = 3,
              series.bullets.push(new am4charts.CircleBullet()),
              series.tooltipText = "{valueY.value}",
              // series.disabled = true
              series.hidden = props.seriesOcultas ? props.seriesOcultas : false

        )
      })
    }

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis2;

    let scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;

    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 300;
    chart.legend.width = 100;

    chart.legend.scrollable = true;

    chart.legend.position = 'right'
    // chart.legend.parent = chart.plotContainer;
    chart.legend.zIndex = 100;

    valueAxis2.renderer.grid.template.strokeOpacity = 0.07;
    dateAxis2.renderer.grid.template.strokeOpacity = 0.07;
    dateAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;


    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.horizontalAlign = "right";
    chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": t('botones.imagen') },
        // { "type": "csv", "label": "Tabla" },
        { "label": t('botones.imprimir'), "type": "print" }
      ]
    }];


// Add range selector
// let selector = new am4plugins_rangeSelector.DateAxisRangeSelector();
// selector.container = document.getElementById("controls");
// selector.axis = dateAxis;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  return (
    <Card className="shadow cardGraficaGeneral cardGraficaAnalistas" style={{width:'100%'}}>
     {/*  <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
        <br/>
      <Card.Body className='bodyGraficasGeneral'>
        <h5 className='pagesTitles' style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}> {props.title}
        <br/>
        {props.parametrizado && props.periodo
          ?
            <>
              {props.parametrizado} - {props.periodo }
            </>

          : props.parametrizado || props.periodo
        }
        </h5>
        <br/>
        <div id={props.id} style={{ width: '95%', minHeight: props.height || '500px', margin: '0 auto', height:'auto' }}></div>
        <br/>
        {props.total &&
          <h4 className='pagesTitles' >
            Total: {props.total}
          </h4>
        }
      </Card.Body>
    </Card>
  )
}
