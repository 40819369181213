import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Heatmap from '../../../../../helpers/Mapas/Descripcion/Heatmap';
import Loader from '../../../../icons/Loader';
import { credenciales } from '../../../../../helpers/credentials.google';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { validarEntorno } from '../../../../../helpers/validarHost';
import NotificationModal from '../../../../modales/NotificationModal';
import ModalPanorama from '../../../../modales/ModalPanorama';

import OpcionesCiudades from '../../../../../helpers/OpcionesCiudades'

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useParams } from 'react-router-dom';
import { municipios } from '../../../../../helpers/municipios';
import OpcionesBuscador from '../../../../../helpers/OpcionesBuscador';
import { useTranslation } from 'react-i18next';


const Rutas = ({ idPanorama, idSubseccion, titulo, tipo, dataFilter }) => {
  const { t } = useTranslation();
  const [regToDel, setRegToDel] = useState({})
  const [addSolucion, setAddSolucion] = useState(false);
  const [inputList, setInputList] = useState([{ municipio: "", descripcion: "", fecha: "", tipo: "", ubicaciones: [{ setOpenMdl: false, tipo_mapa: "", coords: [] }] }]);
  const [indicador, setIndicador] = useState(0);
  const [formNumber, setFormNumber] = useState('');
  const [isForm, setIsForm] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [invalidLat, setInvalidLat] = useState(false);
  const [invalidLng, setInvalidLng] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [coord, setCoord] = useState({
    lat: 0,
    lng: 0
  });

  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })

  const ubicacionBase = {
    lat: 1.206845,
    lng: -77.2756767
  };

  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;
  const hash = window.location.hash;
  const [showCreateModalNotification, setShowCreateModalNotification] = useState(false);
  const [showErrorModalNotification, setShowErrorModalNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState('');
  const [showEditDeleteModalNotification, setShowEditDeleteModalNotification] = useState(false);
  const [messageShowEditDeleteNotification, setMessageEditDeleteNotification] = useState('');
  const [typeModalNotification, setTypeModalNotification] = useState('');
  const [typeNotification, setTypeNotification] = useState('');
  const [itemToErase, setItemToErase] = useState('');
  const [indexDelete, setIndexDelete] = useState('');
  const [typeOperation, setTypeOperation] = useState('');

  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [indexEdit, setIndexEdit] = useState('');
  const [coordEdit, setCoordEdit] = useState({
    lat: '',
    lng: ''
  });

  const handleCloseCreateModalNotification = () => setShowCreateModalNotification(false);
  const handleCloseShowModalNotification = () => setShowErrorModalNotification(false);

  const [seccionPoligono, setSeccionPoligono] = useState([]);

  const { id } = useParams();

  const handleCloseModalEditDeleteNotification = () => {
    setShowEditDeleteModalNotification(false);
    setMessageEditDeleteNotification('');
    setTypeNotification('');
    setItemToErase('');
    setTypeOperation('')
  }

  const handleOpenMessageModalDeleteEdit = (tipo, index, item) => {
    let message = '';
    if (tipo === 'registro') {
      message = t('modales.seguro_eliminar_registro');
      setRegToDel(item);
    } else if (tipo === 'actividad') {
      message = t('modales.seguro_eliminar_actividad');
    }
    setTypeOperation(tipo);
    setIndexDelete(index);
    setTypeNotification('confirmacion_eliminacion_ra');
    setMessageEditDeleteNotification(message)
    setShowEditDeleteModalNotification(true);
  }


  useEffect(() => {
    cleanComponent();
    getRegistrosSeccion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSubseccion]);

  const getCoordinates = (lat, lng, index, descripcion) => {
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[formNumber] };
    const coords = [...ubi.coords];
    const crd = {
      lat: lat,
      lng: lng,
      descripcion
    };
    coords.push(crd);
    ubi.coords = coords;
    ubi.setOpenMdl = false;
    ubicaciones[formNumber] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
    setIsForm(false);
  }

  const submitLatLng = (e, index, idx, tipoMapa, descripcion) => {
    e.preventDefault();
    if ((indicador === index)) {
      const list = [...inputList];
      const obj = { ...list[index] };
      const ubicaciones = [...obj.ubicaciones];
      const ubi = { ...ubicaciones[formNumber] };
      //console.log(ubi, formNumber);
      const coords = [...ubi.coords];
      const crd = {
        lat: coord.lat,
        lng: coord.lng,
        descripcion
      }
      coords.push(crd);
      ubi.coords = coords;
      ubicaciones[formNumber] = ubi;
      ubi.descripcion = descripcion;
      ubi.setOpenMdl = false;
      obj.ubicaciones = ubicaciones;
      list[index] = obj;
      setInputList(list);
      setInvalidLat(false);
      setInvalidLng(false);
      setCoord({
        lat: 0,
        lng: 0
      });
      const lat = document.getElementById("input-lat");
      const lng = document.getElementById("input-lng");

      lat.value = 0;
      lng.value = 0;
    } else {
      setIndicador(index);
    }
    setIsForm(false);
  }

  const handleAddClickUbi = (index) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    if (ubi.length >= 1) {
      const ubic = { setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] };
      ubi.push(ubic);
      item.ubicaciones = ubi;
      item.addUbicacion = true;
      list[index] = item;
      setInputList(list);
    }
  }

  const handleRemoveClickUbi = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    ubi.splice(idx, 1);
    item.ubicaciones = ubi;
    list[index] = item;
    setInputList(list);
  }

  const getRegistrosSeccion = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
    }

    //console.log(dataReq);

    const req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/registros_seccion_poblaciones.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      setSeccionPoligono(res.ubicacion);
      //console.log(res);

      setInitialState({
        ...initialState,
        loading: false,
        error: null,
        data: res
      })

    }
  }


  const handleAddClick = () => {
    setInputList([...inputList, { municipio: "", descripcion: "", fecha: "", tipo: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const enviarDatosFormulario = async () => {

    const dataReq = {
      ubicaciones: addSolucion ? inputList : {},
      hash,
      tipo: tipo,
      id_subseccion: idSubseccion,
      id
    }

    // console.log(JSON.stringify(dataReq));

    const req = await validarEntorno('JSON/panoramaRiesgos/respuesta_envio_descripcion_geo.html', 'POST', dataReq);

    const res = await req.json()

    if (req.ok) {
      if (res.status === "OK") {
        setMessageNotification(res.message);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');
        getRegistrosSeccion();
        cleanComponent();
      } else {
        setMessageNotification(res.message);
        setShowErrorModalNotification(true);
      }
    } else {
      setMessageNotification('Ha ocurrido un fallo en el envio, intente nuevamente');
      setShowErrorModalNotification(true);
    }
  }

  const handleLatLng = (e) => {
    const { name, value } = e.target;

    setCoord({
      ...coord,
      [name]: parseFloat(value)
    })
  }

  const showModal = (e, index, idx) => {

    e.preventDefault();

    //console.log(index, idx);

    if ((!Number.isNaN(coord.lat) && coord.lat !== 0) && (!Number.isNaN(coord.lng) && coord.lng !== 0)) {
      setShowDescriptionModal(true);
      setIndicador(index);
      setIsForm(true);
      setFormNumber(idx);
    } else {
      if (Number.isNaN(coord.lat) || coord.lat === 0) {
        setInvalidLat(true);
      } else {
        setInvalidLat(false);
      }

      if (Number.isNaN(coord.lng) || coord.lng === 0) {
        setInvalidLng(true);
      } else {
        setInvalidLng(false);
      }
    }
  }

  const handleInputChangeUbi = (e, index, idx) => {
    const { value } = e.target;
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.tipo_mapa = value;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
  }

  const handleInputChange = (e, index, idx) => {
    const { name, value } = e.target;
    const list = [...inputList];
    const obj = { ...list[index] };
    obj[name] = value;
    list[index] = obj;
    setInputList(list);
  };

  const handleInputChangeOpenModal = (index, idx) => {
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
  }

  const handleInputChangeOpenModalForm = (e, index, idx) => {
    e.preventDefault();
    //console.log(e, index, idx);
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setFormNumber(idx);
    setInputList(list);
    setIsForm(true);
  }

  const cleanComponent = () => {
    setAddSolucion(false);
    setInputList([{ municipio: "", descripcion: "", fecha: "", tipo: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
    setIsForm(false);
    setCoord({ lat: 0, lng: 0 });
    setInvalidLat(false);
    setInvalidLng(false);
    setIndicador(0);
    setFormNumber(0);
    setShowDescriptionModal(false);
    setLat('');
    setLng('');
  }

  const getCoordsMunicipio = ({ target }) => {
    //console.log(target.name);
    //console.log(target.value);
    // setMunicipio(target.value)

    // showMunicipio(target.value)

    const input = [...inputList];
    const item = { ...input[0] };
    item.municipio = target.value;
    input[0] = item;
    setInputList(input);
  }

  const eliminarRegistroActividad = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_res: regToDel.id_res,
      tipo: 'desc_geo'
    }

    //console.log(dataReq);

    if (typeOperation === 'registro') {

      const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminacion_registro.html', 'POST', dataReq);

      if (req.ok) {

        const res = await req.json();

        if (res.estado === "OK") {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('eliminacion_confirmada');
          const dataDel = { ...initialState.data };
          const dataRegitroDel = [...dataDel.registros];
          dataRegitroDel.splice(index, 1);
          dataDel.registros = dataRegitroDel;
          setInitialState({
            ...initialState,
            data: dataDel
          });
          //getRegistrosSeccion();
          setIndexDelete('');
        } else {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('falloPeticionesGestion');
        }
      }
    }
  }

  const editItem = (item) => {
    setShowEditModal(true);
    setEditData(item);
  }

  const closeEditItem = () => {
    setShowEditModal(false);
    setEditData({});
  }

  const handleEditData = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    });
  }

  const getIndexEdit = (index, item) => {
    setIndexEdit(index);
    setCoordEdit(item);
  }

  const handleInputCoord = (e) => {
    setCoordEdit({
      ...coordEdit,
      [e.target.name]: e.target.value
    });
  }

  const editarDatosFormulario = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      data: editData,
      id_res: editData.id_res,
      id
    }

    //console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/registros_seccion_poblaciones_edicion.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        //console.log(res.mensaje);
        const response = res;

        setMessageNotification(res.mensaje);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');

        delete response.mensaje;
        delete response.estado;

        getRegistrosSeccion();
        closeEditItem();
      }
    }
  }

  const editarCoordenadas = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_res: editData.id_res,
      data: coordEdit,
      id_pun: coordEdit.id_pun,
      id
    }

    //console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_editar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const response = res;
        delete response.mensaje;
        delete response.estado;

        setInitialState({
          ...initialState,
          loading: false,
          error: null,
          data: response
        })
      }

      const dataDel = { ...editData };
      const ubi = { ...dataDel.ubicaciones };

      const punto = {
        id_pun: coordEdit.id_res,
        lat: parseFloat(coordEdit.lat),
        lng: parseFloat(coordEdit.lng)
      };

      ubi.puntos[index] = punto;

      setEditData({
        ...editData,
        ubicaciones: ubi
      });

      setIndexEdit('');
    }
  }

  const eliminarCoordenadas = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_res: editData.id_res,
      data: coordEdit,
      id_pun: coordEdit.id_pun,
      id
    }

    //console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const dataDel = { ...editData };
        const ubi = { ...dataDel.ubicaciones };
        const puntos = [...ubi.puntos];

        puntos.splice(index, 1);

        ubi.puntos = puntos;

        setEditData({
          ...editData,
          ubicaciones: ubi
        });
      }
    }
  }

  const deleteLastCoord = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubicaciones = [...item.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    const coords = [...ubi.coords];
    coords.pop();
    ubi.coords = coords;
    ubicaciones[idx] = ubi;
    item.ubicaciones = ubicaciones;
    list[index] = item;
    setInputList(list);
  }

  const getCoordsMunicipioBuscador = (e) => {
    // console.log(target.name);
    // console.log(target.value);
    if (e) {
      // setMunicipio(e.value)
      // showMunicipio(e.value)
      const input = [...inputList];
      const item = { ...input[0] };
      item.municipio = e.value;
      input[0] = item;
      setInputList(input);
    }
  }

  return (
    <section style={{ width: '90%', margin: 'auto' }} >
      <div className='headerRegistro' >
        <p className='' style={{ width: '70%' }} >{titulo}</p>
        <button
          style={{ marginRight: '0px', width: '180px' }}
          className={addSolucion === true ? 'warningButton' : 'confirmButton'}
          onClick={() => setAddSolucion(!addSolucion)}
        >
          {
            addSolucion === true
              ? t('botones.cancelar')
              : t('botones.agregar_poblacion')
          }
        </button>
      </div>
      <br />
      {addSolucion === false ?
        (
          // <div className='mensajeTablaSinInfo'>
          //     No se han agregado { tipo === 'poblacion' ? 'poblaciones' : 'registros topográficos'}
          // </div>
          null
        ) : (
          <div>
            {inputList.map((item_input, index) => (
              <>
                <>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className='formGroupVertical' >
                        <Form.Label>{t('formulario.label.tipo')}</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => handleInputChange(e, index)}
                          value={item_input.tipo}
                          name='tipo'
                        >
                          <option value='' selected disabled>{t('formulario.label.seleccione')}</option>
                          {dataFilter.data.length > 0 ? dataFilter.data.map((item, index) => (
                            <option key={index} value={item.id_lis}>{item.valor}</option>
                          )) : null}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className='formGroupVertical' >
                        <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                        <Form.Control
                          as='textarea'
                          placeholder={t('formulario.label.escribe_descripcion')}
                          onChange={(e) => handleInputChange(e, index)}
                          value={item_input.descripcion}
                          name='descripcion'
                          style={{ height: 100 }}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col>
                                            <OpcionesCiudades onChange={getCoordsMunicipio}/>
                                        </Col> */}
                    <Col xs={8}>
                      <OpcionesBuscador
                        data={municipios}
                        name="ubicacion"
                        label={t('formulario.label.ubicacion')}
                        handleChange={getCoordsMunicipioBuscador}
                        placeholder={t('formulario.label.seleccione_municipio')}
                      />
                    </Col>
                    {/* <Col xs={6}>
                                            <Form.Group className='formGroupVertical' >
                                                <Form.Label>Fecha</Form.Label>
                                                <Form.Control
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={item_input.fecha}
                                                    type="date"
                                                    name='fecha'
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                        </Col> */}
                  </Row>
                </>
                <div>
                  <section style={{ width: '85%', margin: 'auto' }} >
                    {(item_input.ubicaciones) && item_input.ubicaciones.map((ubicacion, idx) => (
                      <>
                        <Form.Group className='formGroupVertical' >
                          <Form.Label>{t('formulario.label.configuracion_mapa')}</Form.Label>
                          <Form.Control
                            as='select'
                            onChange={(e) => handleInputChangeUbi(e, index, idx)}
                            name='tipo_mapa'
                            style={{ width: '50%' }}
                          >
                            <option selected disabled>{t('formulario.label.seleccione')}</option>
                            <option value='puntos_calor'>{t('formulario.select.puntos_calor')}</option>
                            <option value='poligono' >{t('formulario.select.poligono')}</option>
                          </Form.Control>
                        </Form.Group>
                        <div className="containerOcs">
                          <div id='' className="mapa">
                            <>
                              <div className="form-latlng">
                                <p>{t('formulario.label.agrega_coordenadas')}</p>
                                <Form>
                                  <Form.Group className='formGroupVertical'>
                                    <Row>
                                      <Col>
                                        <Form.Control
                                          placeholder={t('formulario.label.latitud')}
                                          name="lat"
                                          defaultValue={coord.lat}
                                          type="number"
                                          onChange={handleLatLng}
                                          isInvalid={invalidLat}
                                          id="input-lat"
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          placeholder={t('formulario.label.longitud')}
                                          name="lng"
                                          defaultValue={coord.lng}
                                          type="number"
                                          onChange={handleLatLng}
                                          isInvalid={invalidLng}
                                          id="input-lng"
                                        />
                                      </Col>
                                      <Col>
                                        <button className="confirmButton" onClick={(e) => handleInputChangeOpenModalForm(e, index, idx)}>{t('botones.ubicar')}</button>
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </Form>
                              </div>
                              <>
                                <p> {ubicacion.tipo_mapa === 'puntos_calor' ?
                                  'Haz click en el mapa tantas veces como sean necesarias, para indicar los puntos de calor a generar' :
                                  (ubicacion.tipo_mapa === 'poligono' ? 'Haz click en el mapa tantas veces como sean necesarias, para generar la forma del polígono' :
                                    null)
                                }
                                </p>
                                <Heatmap
                                  googleMapURL={mapURL}
                                  containerElement={<div style={{ height: '100%' }}></div>}
                                  mapElement={<div style={{ height: '100%' }}></div>}
                                  loadingElement={<Loader />}
                                  latitud={ubicacionBase?.lat}
                                  longitud={ubicacionBase?.lng}
                                  zoom={10}
                                  coords={ubicacion.coords}
                                  getCoordinates={getCoordinates}
                                  tipoMapa={ubicacion.tipo_mapa}
                                  index={index}
                                  idx={idx}
                                  showDescriptionModal={ubicacion.setOpenMdl}
                                  setShowDescriptionModal={handleInputChangeOpenModal}
                                  submitLatLng={submitLatLng}
                                  isForm={isForm}
                                  setFormNumber={setFormNumber}
                                  setLat={setLat}
                                  setLng={setLng}
                                  lat={lat}
                                  lng={lng}
                                  tipo="poblaciones"
                                  seccionPoligono={seccionPoligono}
                                />
                              </>
                            </>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {/* {item_input.ubicaciones.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickUbi(index, idx)} />}
                                                {item_input.ubicaciones.length - 1 === idx && <AddCircleIcon className="icon-gray add" onClick={() => handleAddClickUbi(index)} />} */}
                        {ubicacion.coords.length > 0 && (
                          <div className="table-ubi-container">
                            <Table striped bordered hover className="tabla-ubicaciones">
                              <thead>
                                <tr>
                                  <th>{t('formulario.label.latitud')}</th>
                                  <th>{t('formulario.label.longitud')}</th>
                                  <th>{t('formulario.label.descripcion')}</th>
                                  <th>{t('formulario.label.accion')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ubicacion.coords.map((ubi, tbx) => (
                                  <tr className="rowTabla" key={tbx} style={{ fontSize: '1rem' }}>
                                    <td>{ubi.lat}</td>
                                    <td>{ubi.lng}</td>
                                    <td>{ubi.descripcion}</td>
                                    {tbx === (ubicacion.coords.length - 1) ? (
                                      <td className='tdBotones'>
                                        <div className='botonesTabla '>
                                          <button
                                            style={{ cursor: 'pointer' }}
                                            className='botonTablaGeneral botonEliminarTabla'
                                            onClick={() => { deleteLastCoord(index, idx) }}
                                          >
                                            <DeleteIcon fontSize='small' />
                                            {t('botones.eliminar')}
                                          </button>
                                        </div>
                                      </td>
                                    ) : <td></td>}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </>
                    ))}
                  </section>
                </div>
                {/* <hr/> */}
                {/* {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />}
                                {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />} */}
              </>
            ))}
          </div>
        )
      }
      <br />
      {addSolucion === true &&
        <div className='buttonContainer'>
          <button
            onClick={() => { enviarDatosFormulario() }}
            className='confirmButton'
          >
            {t('botones.enviar')}
          </button>
        </div>
      }

      {initialState?.data?.registros && initialState?.data?.registros.length > 0
        ?
        <>
          <hr />
          <br />
          <h3 className='pagesTitles' >{t('panorama_riesgos.lista_registros')}</h3>
          <br />
          <table>
            <thead>
              <th>{t('formulario.label.ubicacion')}</th>
              <th>{t('formulario.label.descripcion')}</th>
              <th>{t('formulario.label.acciones')}</th>
            </thead>
            <tbody>
              {initialState.data.registros.map((registro, index) => (
                <tr className="rowTabla" key={index} style={{ fontSize: '1rem' }}>
                  <td>{registro.municipio}</td>
                  <td>{registro.descripcion}</td>
                  {/* <td>{registro.fecha}</td> */}
                  <td className='tdBotones'>
                    <div className='botonesTabla '>
                      {initialState?.data?.permisos?.editar &&
                        <button
                          style={{ cursor: 'pointer' }}
                          className='botonTablaGeneral botonEditarTabla'
                          onClick={() => { editItem(registro) }}
                        >
                          <EditIcon fontSize='small' />
                          {t('botones.editar')}
                        </button>
                      }
                      {initialState?.data?.permisos?.eliminar &&
                        <button
                          style={{ cursor: 'pointer' }}
                          className='botonTablaGeneral botonEliminarTabla'
                          onClick={() => { handleOpenMessageModalDeleteEdit('registro', index, registro) }}
                        >
                          <DeleteIcon fontSize='small' />
                          {t('botones.eliminar')}
                        </button>
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
        :
        <div className='mensajeTablaSinInfo'>
          {t('formulario.label.no_agregado_registros')}
        </div>

      }

      {/* <div className='buttonContainer'>
                <button onClick={() => { enviarDatosFormulario() }} className='confirmButton'>Enviar</button>
            </div> */}
      <NotificationModal
        isOpen={showCreateModalNotification}
        setIsOpen={handleCloseCreateModalNotification}
        typeModal="panoramaCreado"
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showErrorModalNotification}
        setIsOpen={handleCloseShowModalNotification}
        typeModal="error_panorama"
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showEditDeleteModalNotification}
        setIsOpen={handleCloseModalEditDeleteNotification}
        typeModal={typeNotification}
        message={messageShowEditDeleteNotification}
        item={itemToErase}
        indexDelete={indexDelete}
        handleEliminar={eliminarRegistroActividad}
      />
      <ModalPanorama
        typeModal="poblacion"
        isOpen={showEditModal}
        setIsOpen={closeEditItem}
        handleInputChange={handleEditData}
        handleInputCoord={handleInputCoord}
        data={editData}
        dataFilter={dataFilter}
        googleMapURL={mapURL}
        editarDatosFormulario={editarDatosFormulario}
        indexEdit={indexEdit}
        getIndexEdit={getIndexEdit}
        editarCoordenadas={editarCoordenadas}
        eliminarCoordenadas={eliminarCoordenadas}
        cleanIndex={setIndexEdit}

      />
    </section>
  )
}

export default Rutas;
