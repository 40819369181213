import React from 'react'

export const IconSVG = () => {
    return (
        <svg style={{ cursor: "pointer" }} display="right" xmlns="https://www.w3.org/2000/svg" width="20.25" height="20.25" viewBox="0 0 29.25 29.25">
            <g id="Icon_ionic-md-close-circle-outline" data-name="Icon ionic-md-close-circle-outline" transform="translate(-3.375 -3.375)">
                <path id="Trazado_219" data-name="Trazado 219" d="M18,6.328A11.667,11.667,0,1,1,9.745,9.745,11.623,11.623,0,0,1,18,6.328m0-2.953A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z" fill="#213265" />
                <path id="Trazado_220" data-name="Trazado 220" d="M25.313,23.266l-2.046,2.046L18,20.046l-5.266,5.266-2.046-2.046L15.954,18l-5.266-5.266,2.046-2.046L18,15.954l5.266-5.266,2.046,2.046L20.046,18Z" fill="#213265" />
            </g>
        </svg>
    )
}
