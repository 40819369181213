import React, { useState } from 'react'
import { Col, Form, Table, Row } from 'react-bootstrap'
import reactDOM from 'react-dom'

import CloseIcon from '@material-ui/icons/Close';

import './EditModal.scss'
import OpcionesCiudades from '../../helpers/OpcionesCiudades';
import HeatMap from '../../helpers/Mapas/Edicion/Heatmap';
import Loader from '../icons/Loader';
import moment from 'moment';
import './styles/ModalPanorama.scss';

import NotificationModal from './NotificationModal';


import './EditModal.scss'
import OpcionesBuscador from '../../helpers/OpcionesBuscadorEditar';
import { useTranslation } from 'react-i18next';


export default function ModalPanorama(props) {
  const { t } = useTranslation();
  const [showModalNotification, setModalNotification] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [message, setMessage] = useState('');
  const [indexItem, setIndexItem] = useState('');
  const [fecha, setFecha] = useState(moment(props.data.fecha).format('YYYY-MM-DD'));

  const handleOpenShowModalNotification = (tipo, mensaje, index) => {
    setTypeModal(tipo);
    setMessage(mensaje);
    setIndexItem(index);
    setModalNotification(true);
  }

  const handleCloseShowModalNotification = () => {
    setTypeModal('');
    setMessage('');
    setIndexItem('');
    setModalNotification(false);
  }

  const deleteCoords = () => {
    props.eliminarCoordenadas(indexItem);
    handleCloseShowModalNotification();
    handleOpenShowModalNotification('eliminacion_confirmada', 'Coordenadas eliminadas correctamente', '');
  }

  const editCoords = () => {
    props.editarCoordenadas(indexItem);
    handleCloseShowModalNotification();
    handleOpenShowModalNotification('eliminacion_confirmada', 'Coordenadas actualizadas correctamente', '');
  }

  //   //console.log(props.data);

  if (!props.isOpen) {
    return null;
  };

  const tiposRiesgo = [
    {
      "label": "Bajo",
      "value": "bajo"
    },
    {
      "label": "Medio",
      "value": "medio"
    },
    {
      "label": "Alto",
      "value": "alto"
    },
  ]

  const NotiModal = () => (
    <NotificationModal
      isOpen={showModalNotification}
      setIsOpen={handleCloseShowModalNotification}
      typeModal={typeModal}
      message={message}
      handleOpenMessageModalDeleteEdit={props.handleOpenMessageModalDeleteEdit}
      editarCoordenadas={props.editarCoordenadas}
      indexItem={indexItem}
      handleEliminar={deleteCoords}
      handleEditar={editCoords}
    />
  );

  const getFormBySubseccion = (item_input, index) => {

    switch (props.idSubseccion) {
      case 2:
        return (
          <>
            <Col xs={6}>
              <OpcionesCiudades valor={item_input.ubicacion} style={{ display: 'block', marginTop: 8 }} onChange={(e) => props.handleInputChange(e, index)} />
            </Col>
            {props.dataFilter.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Resguardo</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    defaultValue={item_input.resguardo}
                    onChange={(e) => props.handleInputChange(e, index)}
                    name='resguardo'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {props.dataFilter.map((item, index) => (
                      <option value={item.id_lis}>{item.valor}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      case 3:
        return (
          <>
            {props.dataZonasSelect.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Departamento</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    defaultValue={item_input.departamento}
                    onChange={(e) => props.handleInputChange(e, index)}
                    name='departamento'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {(props.dataZonasSelect.map((item, index) => (
                      <option value={item.valor}>{item.valor}</option>
                    )))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
            {props.dataFil.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Comunidad</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    defaultValue={item_input.comunidad}
                    onChange={(e) => props.handleInputChange(e, index)}
                    name='comunidad'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {props.dataFil.map((item, index) => (
                      <option value={item.id_lis}>{item.valor}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      case 8:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                defaultValue={item_input.porcentaje_pob}
                onChange={(e) => props.handleInputChange(e, index)}
                name='porcentaje'
                type='number'
                min='0'
                max='100'
              />
            </Form.Group>
          </Col>
        )
      case 9:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                defaultValue={item_input.porcentaje_acepri}
                onChange={(e) => props.handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 10:
        return (
          <>
            {props.dataZonasSelect.length > 0 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Zona</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    defaultValue={item_input.zona}
                    onChange={(e) => props.handleInputChange(e, index)}
                    name='zona'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    {(props.dataZonasSelect.map((item, index) => (
                      <option value={item.id_zona}>{item.valor}</option>
                    )))}
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
            <Col xs={6}>
              <OpcionesCiudades style={{ display: 'block', marginTop: 8 }} onChange={(e) => props.handleInputChange(e, index)} />
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Programa</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  defaultValue={item_input.programa}
                  onChange={(e) => props.handleInputChange(e, index)}
                  name='programa'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {props.dataSelect2.length > 0 && (props.dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )
      case 12:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                defaultValue={item_input.porcentaje}
                onChange={(e) => props.handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 13:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                defaultValue={item_input.porcentaje_acep}
                onChange={(e) => props.handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 15:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                placeholder='Escribe una descripción'
                defaultValue={item_input.porcentaje_cob}
                onChange={(e) => props.handleInputChange(e, index)}
                name='porcentaje'
                type='number'
              />
            </Form.Group>
          </Col>
        )
      case 21:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Vocación</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  defaultValue={item_input.vocacion}
                  onChange={(e) => props.handleInputChange(e, index)}
                  name='campo_2'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {props.dataSelect2.length > 0 && (props.dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {item_input.vocacion === 10 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Campo</Form.Label>
                  <Form.Control
                    as='select'
                    defaultValue={item_input.sector_industrial}
                    onChange={(e) => props.handleInputChange(e, index)}
                    name='sector_industrial'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">Hidrocarburos</option>
                    <option value="2">Minera</option>
                    <option value="3">Energetica</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      case 22:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Servicio</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  defaultValue={item_input.servicio}
                  onChange={(e) => props.handleInputChange(e, index)}
                  name='servicio'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {props.dataSelect2.length > 0 && (props.dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Porcentaje</Form.Label>
                <Form.Control
                  placeholder='Escribe una descripción'
                  defaultValue={item_input.porcentaje_ser}
                  onChange={(e) => props.handleInputChange(e, index)}
                  name='porcentaje'
                  type='number'
                />
              </Form.Group>
            </Col>
          </>
        )
      default:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.tipo')}</Form.Label>
              <Form.Control
                as='select'
                placeholder='Escribe una descripción'
                defaultValue={item_input.tipo}
                onChange={(e) => props.handleInputChange(e, index)}
                name='tipo'
              >
                <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                {props.dataSelect2.length > 0 && (props.dataSelect2.map((item, index) => (
                  <option value={item.valor}>{item.valor}</option>
                )))}
              </Form.Control>
            </Form.Group>
          </Col>
        )
    }
  }

  const getFormBySubseccionAmenazas = (item_input, index) => {
    switch (props.idSubseccion) {
      case "44":
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  onChange={(e) => props.handleInputChange(e, index)}
                  defaultValue={item_input.dispositivo}
                  name='dispositivo'
                >
                  <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                  {(props.dataSelect2.length > 0) && (props.dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {item_input.dispositivo === "23" ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>{t('formulario.label.composicion')}</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    onChange={(e) => props.handleInputChange(e, index)}
                    defaultValue={item_input.composicion}
                    name='composicion'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">Amapola</option>
                    <option value="2">Cocaínas</option>
                    <option value="3">Marihuana</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : (item_input.dispositivo === "22" || item_input.dispositivo === "23" ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>{t('formulario.label.composicion')}</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder={t('formulario.label.escribe_descripcion')}
                    onChange={(e) => props.handleInputChange(e, index)}
                    defaultValue={item_input.composicion}
                    name='composicion'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="4">Urbano</option>
                    <option value="5">Rural</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null)}
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.fuerza')}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={props.data.descripcion_principal}
                  name='fuerza'
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                <Form.Control
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={fecha}
                  type="date"
                  name='fecha'
                >
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )
      case "45":
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  onChange={(e) => props.handleInputChange(e, index)}
                  defaultValue={item_input.dispositivo}
                  name='dispositivo'
                >
                  <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                  {(props.dataSelect2.length > 0) && (props.dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {item_input.dispositivo !== "" ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>{t('formulario.label.composicion')}</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder={t('formulario.label.escribe_descripcion')}
                    onChange={(e) => props.handleInputChange(e, index)}
                    defaultValue={item_input.composicion}
                    name='composicion'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">Amapola</option>
                    <option value="2">Cocaínas</option>
                    <option value="3">Marihuana</option>
                    <option value="4">Drogas sintéticas</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>Fuerza</Form.Label>
                <Form.Control
                  type="number"
                  placeholder='Escribe una descripción'
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={props.data.descripcion_principal}
                  name='fuerza'
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                <Form.Control
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={fecha}
                  type="date"
                  name='fecha'
                >
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )
      default:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  onChange={(e) => props.handleInputChange(e, index)}
                  defaultValue={item_input.dispositivo}
                  name='dispositivo'
                >
                  <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                  {(props.dataSelect2.length > 0) && (props.dataSelect2.map((item, index) => (
                    <option value={item.valor}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.composicion')}</Form.Label>
                <Form.Control
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, index)}
                  defaultValue={item_input.composicion}
                  name='composicion'
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.fuerza')}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={props.data.fuerza}
                  name='fuerza'
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                <Form.Control
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={props.data.fecha}
                  type="date"
                  name='fecha'
                >
                </Form.Control>
              </Form.Group>
            </Col>
          </>
        )
    }
  }

  const getFormBySubseccionFuerzas = (item_input, index) => {
    switch (props.idSubseccion) {
      case 33:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  onChange={(e) => props.handleInputChange(e, index)}
                  value={item_input.dispositivo}
                  name='dispositivo'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {(props.dataSelect2.length > 0) && (props.dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {item_input.dispositivo === 7 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Unidad</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    onChange={(e) => props.handleInputChange(e, index)}
                    value={item_input.unidad}
                    name='unidad'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">BRIM</option>
                    <option value="2">BRIFLIM</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : (item_input.dispositivo === 8 ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Unidad</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    onChange={(e) => props.handleInputChange(e, index)}
                    value={item_input.unidad}
                    name='unidad'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="3">Fuerzas Navales</option>
                    <option value="4">Bases Navales</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null)}

            {(item_input.unidad === 1 || item_input.unidad === 2) ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Batallon</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    onChange={(e) => props.handleInputChange(e, index)}
                    value={item_input.batallon}
                    name='batallon'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">Batallones de Infantería de Marina</option>
                    <option value="2">Batallones de Asalto Fluvial</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      default:
        return (
          <Col xs={6}>
            <Form.Group className='formGroupVertical' >
              <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
              <Form.Control
                as='select'
                placeholder='Escribe una descripción'
                onChange={(e) => props.handleInputChange(e, index)}
                value={item_input.dispositivo}
                name='dispositivo'
              >
                <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                {(props.dataSelect2.length > 0) && (props.dataSelect2.map((item, index) => (
                  <option value={item.valor}>{item.valor}</option>
                )))}
              </Form.Control>
            </Form.Group>
          </Col>
        )
    }
  }

  if (props.typeModal === 'ubicacion') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard' style={{ width: '75%', minHeight: '65%' }}>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' style={{ textAlign: 'start' }} >{t('formulario.label.editar_registro')}</h3>
          {/* <hr style={{ color: 'black', width: '90%'}}/> */}
          <br />
          <Row className='scrollSecundario' style={{ width: '90%', margin: '0 auto', overflowY: 'scroll' }}>
            <Col xs={6}>
              <OpcionesCiudades disabled={true} valor={props.data.municipio} onChange={props.getCoordsMunicipio} />
            </Col>
            {props.tipo === 'ubicacion' ? (
              <Col xs={6}>
                <OpcionesBuscador
                  data={tiposRiesgo}
                  name="riesgo"
                  label={t('formulario.label.riesgo')}
                  handleChange={(e) => { props.handleEditDataOption(e, props.index) }}
                  placeholder={t('formulario.label.seleccione_opcion')}
                  style={{ width: "100%", minWidth: "350px" }}
                  valor={props.data.tipo_riesgo}
                />
              </Col>
            ) : null}
            <Col xs={12}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  value={props.data.descripcion}
                  name='descripcion'
                  style={{ height: 100 }}
                />
              </Form.Group>
            </Col>
            {(props.data.ubicaciones.tipo !== 'departamental' && (props.data?.ubicaciones?.puntos && props.data?.ubicaciones?.puntos?.length > 0)) ?
              (
                <HeatMap
                  googleMapURL={props.googleMapURL}
                  coords={(props.data?.ubicaciones?.puntos && props.data?.ubicaciones?.puntos?.length > 0) ? props.data?.ubicaciones?.puntos : []}
                  tipoMapa={props.data.ubicaciones.tipo}
                  containerElement={<div style={{ height: '100%' }}></div>}
                  mapElement={<div style={{ height: '100%' }}></div>}
                  loadingElement={<Loader />}
                  zoom={5}
                  riesgo={props.data.tipo_riesgo}
                  marker={props.data.ubicaciones.tipo === 'punto' ? props.data?.ubicaciones?.puntos[0] : []}
                />
              ) : (
                <HeatMap
                  googleMapURL={props.googleMapURL}
                  coords={(props.data?.ubicaciones?.puntos && props.data?.ubicaciones?.puntos?.length > 0) ? props.data?.ubicaciones?.puntos : []}
                  tipoMapa={props.data.ubicaciones.tipo}
                  containerElement={<div style={{ height: '100%' }}></div>}
                  mapElement={<div style={{ height: '100%' }}></div>}
                  loadingElement={<Loader />}
                  zoom={5}
                  riesgo={props.data.tipo_riesgo}
                  ubicacion={props.data.municipio}
                />
              )
            }

            {props.data?.ubicaciones?.puntos?.length > 0 && (
              <div className="table-ubi-container">
                <Table striped bordered hover className="tabla-ubicaciones">
                  <thead>
                    <tr>
                      <th>{t('formulario.label.latitud')}</th>
                      <th>{t('formulario.label.longitud')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.ubicaciones.puntos.map((ubi, index) => (
                      <tr key={index}>
                        <td>{ubi.lat}</td>
                        <td>{ubi.lng}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Row>
          {props.data !== '' &&
            <div className='buttonContainer'>
              <button
                onClick={() => { props.editarDatosFormulario() }}
                className='confirmButton'
              >
                {t('botones.enviar')}
              </button>
              <button className='botonEditar' onClick={() => { props.setIsOpen() }}>{t('botones.cancelar')}</button>
            </div>

          }
        </div>
        {NotiModal()}
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'rutas') {
    //console.log(props.data)
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard' style={{ width: '75%', minHeight: '65%' }}>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' style={{ textAlign: 'start' }} >{t('formulario.label.editar_registro')}</h3>
          {/* <hr style={{ color: 'black', width: '90%'}}/> */}
          <br />
          <Row className='scrollSecundario' style={{ width: '90%', margin: '0 auto', overflowY: 'scroll' }}>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.tipo')}</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultvalue={props.data.tipo}
                  name='tipo'
                >
                  <option value='' disabled>{t('formulario.label.seleccione')}</option>
                  {props.dataFilter.data.length > 0 ? props.dataFilter.data.map((item, index) => (
                    <option key={index} value={item.valor}>{item.valor}</option>
                  )) : null}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  value={props.data.descripcion}
                  name='descripcion'
                  style={{ height: 100 }}
                />
              </Form.Group>
            </Col>
            <HeatMap
              googleMapURL={props.googleMapURL}
              coords={props.data.ubicaciones.puntos}
              tipoMapa={props.data.ubicaciones.tipo}
              containerElement={<div style={{ height: '100%' }}></div>}
              mapElement={<div style={{ height: '100%' }}></div>}
              loadingElement={<Loader />}
              zoom={5}
            />

            {/* <div className='buttonContainer'>
                        <button
                            onClick={() => { props.editarDatosFormulario() }}
                            className='confirmButton'
                        >
                            Enviar
                        </button>
                        <button className='deniedButton' onClick={() => {props.setIsOpen()}}>Cancelar</button>
                    </div> */}
            {props.data.ubicaciones.puntos.length > 0 && (
              <div className="table-ubi-container">
                <Table striped bordered hover className="tabla-ubicaciones">
                  <thead>
                    <tr>
                      <th>{t('formulario.label.latitud')}</th>
                      <th>{t('formulario.label.longitud')}</th>
                      {/* <th>Acciones</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.ubicaciones.puntos.map((ubi, index) => (
                      <tr className="rowTabla">
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lat}
                              name='lat'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lat}</td>)}
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lng}
                              name='lng'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lng}</td>)}
                        {/* <td className='tdBotones'>
                                            <div className='botonesTabla '>
                                                { props.indexEdit === index ? (
                                                        <button
                                                        style={{ cursor: 'pointer' }}
                                                        className='botonTablaGeneral botonGuardarTabla'
                                                        onClick = { () => {handleOpenShowModalNotification('confirmacion_edicion_coords', 'Esta seguro de actualizar estas coordenadas?', index)} }
                                                        >
                                                        <SaveIcon fontSize='small'/>
                                                        Guardar
                                                    </button>
                                                ) : null }
                                                {props.indexEdit === index
                                                    ?
                                                    <>
                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEditarTabla'
                                                            onClick={() => { props.cleanIndex('')  }}
                                                        >
                                                            <CloseIcon fontSize='small'/>
                                                            Cancelar
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEditarTabla'
                                                            onClick={() => { props.getIndexEdit(index, ubi)  }}
                                                        >
                                                            <EditIcon fontSize='small'/>
                                                            Editar
                                                        </button>

                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEliminarTabla'
                                                            onClick = { () => {handleOpenShowModalNotification('confirmacion_eliminacion_coords', 'Esta seguro de eliminar estas coordenadas?', index)} }
                                                        >
                                                            <DeleteIcon fontSize='small'/>
                                                            Eliminar
                                                        </button>
                                                    </>

                                                }
                                            </div>
                                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Row>
          {props.data !== '' &&
            <div className='buttonContainer'>
              <button
                onClick={() => { props.editarDatosFormulario() }}
                className='confirmButton'
              >
                {t('botones.enviar')}
              </button>
              <button className='botonEditar' onClick={() => { props.setIsOpen() }}>{t('botones.cancelar')}</button>
            </div>

          }
        </div>
        {NotiModal()}
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'poblacion') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard' style={{ width: '75%', minHeight: '65%' }}>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' style={{ textAlign: 'start' }} >{t('formulario.label.editar_registro')}</h3>
          {/* <hr style={{ color: 'black', width: '90%'}}/> */}
          <br />
          <Row style={{ width: '80%', margin: '0 auto', overflowY: 'scroll' }}>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.tipo')}</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  value={props.data.tipo}
                  name='tipo'
                >
                  <option value='' selected disabled>{t('formulario.label.seleccione')}</option>
                  {props.dataFilter.data.length > 0 ? props.dataFilter.data.map((item, index) => (
                    <option key={index} value={item.id_lis}>{item.valor}</option>
                  )) : null}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <OpcionesCiudades valor={props.data.municipio} onChange={props.getCoordsMunicipio} disabled={true} />
            </Col>
            <Col xs={12}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  value={props.data.descripcion}
                  name='descripcion'
                  style={{ height: 100 }}
                />
              </Form.Group>
            </Col>
            <HeatMap
              googleMapURL={props.googleMapURL}
              coords={props.data.ubicaciones.puntos}
              tipoMapa={props.data.ubicaciones.tipo}
              containerElement={<div style={{ height: '100%' }}></div>}
              mapElement={<div style={{ height: '100%' }}></div>}
              loadingElement={<Loader />}
              zoom={5}
            />
            <div className='buttonContainer'>
              <button
                onClick={() => { props.editarDatosFormulario() }}
                className='confirmButton'
              >
                {t('botones.enviar')}
              </button>
              <button className='deniedButton' onClick={() => { props.setIsOpen() }}>{t('botones.cancelar')}</button>
            </div>
            {props.data.ubicaciones.puntos.length > 0 && (
              <div className="table-ubi-container">
                <Table striped bordered hover className="tabla-ubicaciones">
                  <thead>
                    <tr>
                      <th>{t('formulario.label.latitud')}</th>
                      <th>{t('formulario.label.longitud')}</th>
                      {/* <th>Acciones</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.ubicaciones.puntos.map((ubi, index) => (
                      <tr className="rowTabla">
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lat}
                              name='lat'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lat}</td>)}
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lng}
                              name='lng'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lng}</td>)}
                        {/* <td className='tdBotones'>
                                            <div className='botonesTabla '>
                                                    { props.indexEdit === index ? (
                                                          <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonGuardarTabla'
                                                            onClick = { () => {handleOpenShowModalNotification('confirmacion_edicion_coords', 'Esta seguro de actualizar estas coordenadas?', index)} }
                                                            >
                                                            <SaveIcon fontSize='small'/>
                                                            Guardar
                                                      </button>
                                                    ) : null }
                                                    <button
                                                        style={{ cursor: 'pointer' }}
                                                        className='botonTablaGeneral botonEditarTabla'
                                                        onClick={() => { props.getIndexEdit(index, ubi)  }}
                                                    >
                                                        <EditIcon fontSize='small'/>
                                                        Editar
                                                    </button>
                                                    <button
                                                        style={{ cursor: 'pointer' }}
                                                        className='botonTablaGeneral botonEliminarTabla'
                                                        onClick = { () => {handleOpenShowModalNotification('confirmacion_eliminacion_coords', 'Esta seguro de eliminar estas coordenadas?', index)} }
                                                    >
                                                        <DeleteIcon fontSize='small'/>
                                                        Eliminar
                                                    </button>
                                            </div>
                                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Row>
        </div>
        {NotiModal()}
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'factores') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard' style={{ width: '75%', minHeight: '65%' }}>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' style={{ textAlign: 'start' }} >{t('formulario.label.editar_registro')}</h3>
          {/* <hr style={{ color: 'black', width: '90%'}}/> */}
          <br />
          <Row className='scrollSecundario' style={{ width: '90%', margin: '0 auto', overflowY: 'scroll' }}>
            <Col xs={12}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.resumen')}</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={props.data.resumen}
                  name='resumen'
                  style={{ height: 50 }}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={props.data.descripcion}
                  name='descripcion'
                  style={{ height: 100 }}
                />
              </Form.Group>
            </Col>

            <Col xs={12}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.tipo')}</Form.Label>
                <Form.Control
                  // style={{minHeight:'100px'}}
                  as='select'
                  onChange={(e) => props.handleInputChange(e, props.index)}
                  defaultValue={props.data.tipo ? props.data.tipo : ''}
                  name='tipo'
                >
                  <option value="" disabled>{t('formulario.label.seleccione')}</option>
                  {(props.dataSelect2.map((item, index) => (
                    <option value={item.valor}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {getFormBySubseccion(props.data, 0)}
            <HeatMap
              googleMapURL={props.googleMapURL}
              coords={props.data.ubicaciones.puntos}
              tipoMapa={props.data.ubicaciones.tipo}
              containerElement={<div style={{ height: '100%' }}></div>}
              mapElement={<div style={{ height: '100%' }}></div>}
              loadingElement={<Loader />}
              zoom={5}
            />
            {(props.data.ubicaciones.puntos && props.data.ubicaciones.puntos.length) > 0 && (
              <div className="table-ubi-container">
                <Table striped bordered hover className="tabla-ubicaciones">
                  <thead>
                    <tr>
                      <th>{t('formulario.label.latitud')}</th>
                      <th>{t('formulario.label.longitud')}</th>
                      {/* <th>Acciones</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.ubicaciones.puntos.map((ubi, index) => (
                      <tr className="rowTabla">
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lat}
                              name='lat'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lat}</td>)}
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lng}
                              name='lng'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lng}</td>)}
                        {/*<td className='tdBotones'>
                                                <div className='botonesTabla '>
                                                        { props.indexEdit === index ? (
                                                            <>
                                                            <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonGuardarTabla'
                                                                onClick = { () => {handleOpenShowModalNotification('confirmacion_edicion_coords', 'Esta seguro de actualizar estas coordenadas?', index)} }
                                                                >
                                                                <SaveIcon fontSize='small'/>
                                                                Guardar
                                                                </button>
                                                                <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonEditarTabla'
                                                                onClick={() => { props.cleanIndex('')  }}
                                                            >
                                                                <CloseIcon fontSize='small'/>
                                                                Cancelar
                                                            </button>
                                                            </>
                                                        ) :
                                                            <>
                                                            <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonEditarTabla'
                                                                onClick={() => { props.getIndexEdit(index, ubi)  }}
                                                            >
                                                                <EditIcon fontSize='small'/>
                                                                Editar
                                                            </button>
                                                            <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonEliminarTabla'
                                                                onClick = { () => {handleOpenShowModalNotification('confirmacion_eliminacion_coords', 'Esta seguro de eliminar estas coordenadas?', index)} }
                                                            >
                                                                <DeleteIcon fontSize='small'/>
                                                                Eliminar
                                                            </button>

                                                            </>
                                                        }
                                                </div>
                                            </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Row>
          <div className='buttonContainer'>
            <button
              onClick={() => { props.editarDatosFormulario() }}
              className='confirmButton'
            >
              {t('botones.enviar')}
            </button>
            <button className='botonEditar' onClick={() => { props.setIsOpen() }}>{t('botones.cancelar')}</button>
          </div>
        </div>
        {NotiModal()}
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'amenazas') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' style={{ textAlign: 'start' }} >{t('formulario.label.editar_registro')}</h3>
          {/* <hr style={{ color: 'black', width: '90%'}}/> */}
          <Row className='scrollSecundario' style={{ width: '90%', height: '90%', margin: '0 auto', overflowY: 'auto' }}>
            {getFormBySubseccionAmenazas(props.data, 0)}
            <HeatMap
              googleMapURL={props.googleMapURL}
              coords={props.data.ubicaciones.puntos}
              tipoMapa={props.data.ubicaciones.tipo}
              containerElement={<div style={{ height: '100%' }}></div>}
              mapElement={<div style={{ height: '100%' }}></div>}
              loadingElement={<Loader />}
              zoom={5}
            />

            {props.data.ubicaciones.puntos.length > 0 && (
              <div className="table-ubi-container">
                <Table striped bordered hover className="tabla-ubicaciones">
                  <thead>
                    <tr>
                      <th>{t('formulario.label.latitud')}</th>
                      <th>{t('formulario.label.longitud')}</th>
                      {/* <th>Acciones</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.ubicaciones.puntos.map((ubi, index) => (
                      <tr className="rowTabla">
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lat}
                              name='lat'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lat}</td>)}
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lng}
                              name='lng'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lng}</td>)}
                        {/* <td className='tdBotones'>
                                            <div className='botonesTabla '>
                                                    {/* { props.indexEdit === index ? (
                                                          <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonGuardarTabla'
                                                            onClick = { () => {handleOpenShowModalNotification('confirmacion_edicion_coords', 'Esta seguro de actualizar estas coordenadas?', index)} }
                                                            >
                                                            <SaveIcon fontSize='small'/>
                                                            Guardar
                                                      </button>
                                                    ) : null } */}

                        {/* {props.indexEdit === index
                                                    ?
                                                    <>
                                                        <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonGuardarTabla'
                                                                onClick = { () => {handleOpenShowModalNotification('confirmacion_edicion_coords', 'Esta seguro de actualizar estas coordenadas?', index)} }
                                                                >
                                                                <SaveIcon fontSize='small'/>
                                                                Guardar
                                                        </button>
                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEditarTabla'
                                                            onClick={() => { props.cleanIndex('')  }}
                                                        >
                                                            <CloseIcon fontSize='small'/>
                                                            Cancelar
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEditarTabla'
                                                            onClick={() => { props.getIndexEdit(index, ubi)  }}
                                                        >
                                                            <EditIcon fontSize='small'/>
                                                            Editar
                                                        </button>
                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEliminarTabla'
                                                            onClick = { () => {handleOpenShowModalNotification('confirmacion_eliminacion_coords', 'Esta seguro de eliminar estas coordenadas?', index)} }
                                                        >
                                                            <DeleteIcon fontSize='small'/>
                                                            Eliminar
                                                        </button>
                                                    </>

                                                    }  */}




                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Row>
          <div className='buttonContainer'>
            <button
              onClick={() => { props.editarDatosFormulario() }}
              className='confirmButton'
            >
              {t('botones.enviar')}
            </button>
            <button className='botonEditar' onClick={() => { props.setIsOpen() }}>{t('botones.cancelar')}</button>
          </div>
        </div>
        {NotiModal()}
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'fuerzas') {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          <br />
          <h3 className='pagesTitles' style={{ textAlign: 'start' }} >{t('formulario.label.editar_registro')}</h3>
          {/* <hr style={{ color: 'black', width: '90%'}}/> */}
          <br />
          <Row className='scrollSecundario' style={{ width: '90%', height: '90%', margin: '0 auto', overflowY: 'scroll' }}>
            {getFormBySubseccionFuerzas(props.data, 0)}
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.composicion')}</Form.Label>
                <Form.Control
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e)}
                  value={props.data.composicion}
                  name='composicion'
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.fuerza')}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => props.handleInputChange(e)}
                  value={props.data.fuerza}
                  name='fuerza'
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                <Form.Control
                  onChange={(e) => { setFecha(props.data.fecha); props.handleInputChange(e) }}
                  defaultValue={props.data.fecha}
                  type="date"
                  name='fecha'
                // onChange={(e) => setFecha(props.data.fecha)}
                >
                </Form.Control>
              </Form.Group>
            </Col>
            <HeatMap
              googleMapURL={props.googleMapURL}
              coords={props.data.ubicaciones.puntos}
              tipoMapa={props.data.ubicaciones.tipo}
              containerElement={<div style={{ height: '100%' }}></div>}
              mapElement={<div style={{ height: '100%' }}></div>}
              loadingElement={<Loader />}
              zoom={5}
            />

            {props.data.ubicaciones.puntos.length > 0 && (
              <div className="table-ubi-container">
                <Table striped bordered hover className="tabla-ubicaciones">
                  <thead>
                    <tr>
                      <th>{t('formulario.label.latitud')}</th>
                      <th>{t('formulario.label.longitud')}</th>
                      {/* <th>Acciones</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.ubicaciones.puntos.map((ubi, index) => (
                      <tr className="rowTabla">
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lat}
                              name='lat'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lat}</td>)}
                        {props.indexEdit === index ? (
                          <td>
                            <Form.Control
                              onChange={(e) => props.handleInputCoord(e)}
                              defaultValue={ubi.lng}
                              name='lng'
                              type="number"
                            />
                          </td>
                        ) : (<td>{ubi.lng}</td>)}
                        {/* <td className='tdBotones'>
                                            <div className='botonesTabla '>
                                                        { props.indexEdit === index ? (
                                                            <button
                                                                style={{ cursor: 'pointer' }}
                                                                className='botonTablaGeneral botonGuardarTabla'
                                                                onClick = { () => {handleOpenShowModalNotification('confirmacion_edicion_coords', 'Esta seguro de actualizar estas coordenadas?', index)} }
                                                                >
                                                                <SaveIcon fontSize='small'/>
                                                                Guardar
                                                        </button>
                                                        ) : null }
                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEditarTabla'
                                                            onClick={() => { props.getIndexEdit(index, ubi)  }}
                                                        >
                                                            <EditIcon fontSize='small'/>
                                                            Editar
                                                        </button>
                                                        <button
                                                            style={{ cursor: 'pointer' }}
                                                            className='botonTablaGeneral botonEliminarTabla'
                                                            onClick = { () => {handleOpenShowModalNotification('confirmacion_eliminacion_coords', 'Esta seguro de eliminar estas coordenadas?', index)} }
                                                        >
                                                            <DeleteIcon fontSize='small'/>
                                                            Eliminar
                                                        </button>
                                                </div>
                                            </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Row>
          <div className='buttonContainer'>
            <button
              onClick={() => { props.editarDatosFormulario() }}
              className='confirmButton'
            >
              {t('botones.enviar')}
            </button>
            <button className='botonEditar' onClick={() => { props.setIsOpen() }}>{t('botones.cancelar')}</button>
          </div>
        </div>
        {NotiModal()}
      </div>,
      document.getElementById('EditModal')
    )
  } else if (props.typeModal === 'modalSelectsComponente') {


    return reactDOM.createPortal(
      <div className='componentesModal'>
        <div className='cardComponentes'>
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  } else {
    return reactDOM.createPortal(
      <div className='editMmodalContainer'>
        <div className='editModalCard'>
          <div>
            {/* <Form.Control
                        as='textarea'

              // className={ is_edit ? 'unavailable-input' : 'available-input'}
              // disabled={is_edit}
              // defaultValue={descripcion}
              // name="descripcion"
              // onChange={handleChangeData}
          /> */}
          </div>
          <div className='closeButtonContainer'>
            <CloseIcon onClick={() => { props.setIsOpen(false) }} />
          </div>
          {/* {printInfo(props)} */}
        </div>
      </div>,
      document.getElementById('EditModal')
    )
  }

}
