import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Nav, Row, Accordion } from 'react-bootstrap';
import Ubicacion from './descripcionMapas/Ubicacion';
import Rutas from './descripcionMapas/Rutas';
import Topografia from './descripcionMapas/Topografia';
import { validarEntorno } from '../../../../helpers/validarHost';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const DescripcionFisicaGeografica = () => {
  const { t } = useTranslation();
  const [listItems, setListItems] = useState({});
  const [eventFormId, setEventFormId] = useState(1);
  const [activeFormKey, setActiveFormKey] = useState(1);
  const [titulo, setTitulo] = useState(t('panorama_riesgos.ubicacion'));
  const [tipo, setTipo] = useState('ubicacion');
  const [tipoMapa, setTipoMapa] = useState('');
  const [dataSelect, setDataSelect] = useState([]);

  const { id } = useParams()

  const eventFormIdValue = (value, titulo, tipo) => {
    setEventFormId(value);
    setActiveFormKey(value);
    setTitulo(titulo);
    setTipo(tipo);
  }

  useEffect(() => {
    getDescripcionData();
  }, [activeFormKey])

  const getDescripcionData = async () => {

    const dataSend = {
      hash: window.location.hash,
      id_sub: activeFormKey,
      tipo: tipo
    };

    const request = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/descripcion_fisica.html', 'POST', dataSend)
    const data = await request.json()

    const dataFilter = data.lista.filter((item) => {
      return item.id_subseccion === activeFormKey;
    });

    setDataSelect({ data: dataFilter })
  }

  const renderForm = () => {
    switch (activeFormKey) {
      case 1:
        return <Ubicacion idPanorama={id} idSubseccion={activeFormKey} titulo={titulo} tipo="ubicacion" />;
      case 2:
        return <Topografia idPanorama={id} idSubseccion={activeFormKey} titulo={titulo} tipo={tipo} dataFilter={dataSelect} />;
      case 3:
        return <Rutas idPanorama={id} idSubseccion={activeFormKey} dataFilter={dataSelect} />;
      case 4:
        return <Topografia idPanorama={id} idSubseccion={activeFormKey} titulo={titulo} tipo={tipo} dataFilter={dataSelect} />;
      case 5:
        return <Ubicacion idPanorama={id} idSubseccion={activeFormKey} titulo={titulo} tipo="otros aspectos" />;
      default:
        break;
    }
  }

  return (
    <Card className=' cardUnderPestana' >
      <Card.Body>
        <Row className="row-content">
          <Col xl={2} lg={3} md={4} xs={0} id="sidebar-wrapper">
            <Nav defaultActiveKey={activeFormKey} activeKey={activeFormKey} className="shadow p-2 flex-column nav-items">
              <Nav.Link
                className="item-a"
                eventKey="1"
                onClick={() => eventFormIdValue(1, t('panorama_riesgos.ubicacion'), 'ubicación')}
              >
                {t('panorama_riesgos.ubicacion')}
              </Nav.Link>
              {/* <Nav.Link
                                className="item-a"
                                eventKey="2"
                                onClick={() => eventFormIdValue(2, 'Descripción topográfica del sector', 'descripción')}
                            >
                                Descripción topográfica del sector
                            </Nav.Link> */}
              <Nav.Link
                className="item-a"
                eventKey="3"
                onClick={() => eventFormIdValue(3, t('panorama_riesgos.rutas'), 'rutas')}
              >
                {t('panorama_riesgos.rutas')}
              </Nav.Link>
              <Nav.Link
                className="item-a"
                eventKey="4"
                onClick={() => eventFormIdValue(4, t('panorama_riesgos.poblaciones'), 'Poblacion')}
              >
                {t('panorama_riesgos.poblaciones')}
              </Nav.Link>
              <Nav.Link
                className="item-a"
                eventKey="5"
                onClick={() => eventFormIdValue(5, t('panorama_riesgos.otros_aspectos'), 'otros aspectos')}
              >
                {t('panorama_riesgos.otros_aspectos')}
              </Nav.Link>
            </Nav>
          </Col>
          <Col xl={10} lg={9} md={8} xs={12} id="page-content-wrapper">
            {renderForm()}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
