import './TrendingInfo.scss';

const TrendingInfo = ({ trendText, periodText}) => {
    return (
        <div className="trending-info">
            <div className="trending-info__grid">
                <div className="trending-info__trend">
                    {trendText && <span>{trendText}</span>}
                </div>
                {periodText &&
                    <span className="trending-info__period">
                        {periodText}
                    </span>
                }
            </div>
        </div>
    );
};

export default TrendingInfo;