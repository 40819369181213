
//state

const inputFilter = {
  filter: {}
}

//types

  const FILTER_OCENSA = 'FILTER_OCENSA'

//reducers

export default function inputFilterOcensa(state= inputFilter, action){
  switch (action.type) {
    case FILTER_OCENSA :
      state = {
        ...inputFilter,
        filter: action.payload
      }
    return state;

    default:
      return state
  }
}

//actions

export const getDataFiltros = (optionsSelected) => async (dispatch) => {

  // console.log(ruta)

  try {
    dispatch({
      type: 'FILTER_OCENSA',
      payload: optionsSelected
    })

  } catch (error) {
    console.log(error.message)
  }
}
