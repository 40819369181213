
export const AnalistasDisponibles = [

  {
    nombre: 'Ana María Gallego',
    telefono: '3216549878',
    Cargo: 'Analista de validación UGPP'
  },
  {
    nombre: 'Elba Liliana Camargo',
    telefono: '3216549878',
    Cargo: 'Analista de investigaciones'
  },
  {
    nombre: 'Ingrid Paola Urbina González',
    telefono: '3216549878',
    Cargo: 'Analista de validación'
  },
  {
    nombre: 'Jaime Reyes',
    telefono: '3216549878',
    Cargo: 'Analista de proyecto'
  },
  {
    nombre: 'James Rodriguez',
    telefono: '3216549878',
    Cargo: 'Grafólogo'
  },

];

