import React from 'react'
import {Form, Col} from 'react-bootstrap'
import Select from 'react-select';

export default function OpcionesBuscador(props) {
  // console.log(props)
  if(!props.noClass){
    return  (
      <Form.Group as={Col} lg={props.col ? props.col : 6} className='inputGroup' style={{paddingLeft: '0px', width:'100%'}}>
        <Form.Label>{props.label}</Form.Label>
        <Select
          options={props.data}
          isSearchable={true}
          name={props.name}
          onChange={(e) => {props.handleChange(e)}}
          placeholder={props?.placeholder}
          isClearable={true}
          style={props.style}
          defaultValue={props.valor ? props.valor : ''}
        />
      </Form.Group>
    )
  }else{
    return  (
      <div lg={12} style={{paddingLeft: '0px', width:'100%'}}>
        <Select
          options={props.data}
          isSearchable={true}
          name={props.name}
          onChange={(e) => {props.handleChange(e)}}
          onFocus={(e) => {props.handleFocus && props.handleFocus(e)}}
          onBlur={(e) => {props.handleBlur && props.handleBlur(e)}}
          placeholder={`${props.placeholder}`}
          isClearable={true}
          style={props.style}
          defaultValue={props.valor ? props.valor : ''}
        />
      </div>
    )
  }

}
