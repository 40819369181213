import React, { Fragment, useState } from 'react';




import { FormLogin } from './FormLogin';
import { FormRecuperarPass } from './FormRecuperarPass';

export default function SignIn() {


  const [form, setForm] = useState(true)

  return (
    <Fragment>
      {
        form
          ? <FormLogin setForm={setForm} />
          : <FormRecuperarPass setForm={setForm} />
      }
    </Fragment>
  )
}
