import React from 'react';
import { Button, Modal, Image } from 'react-bootstrap';
import './FormObs.scss';

const ModalImage = ({image, show, handleClose}) => {
    return(
        <Modal size="sm" className="img-mdl-audit" backdropClassName="fade audit-image-form show" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="center-image-modal">
                    <Image fluid src={ image } rounded />
                </div>
                <div className="button-modal-container">
                    <Button className="deniedButton" onClick={handleClose}>Cerrar</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalImage;