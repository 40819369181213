import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { validarEntorno } from '../../../../../helpers/validarHost';
import NotificationModal from '../../../../modales/NotificationModal';
import { useTranslation } from 'react-i18next';

export default function Tendencia({ dataG }) {

  const { id } = useParams();
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [showModalNotification, setShowModalNotification] = useState(false);

  const handleCloseModalNotification = () => setShowModalNotification(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
    console.log(data)
  }

  const sendDataForm = async (e) => {

    e.preventDefault();

    const dataSend = {
      formData: data,
      id: id,
      tipo: 'tendencia',
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    console.log(dataSend)

    const response = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_analisis_res.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();;

      if (respuesta.message) {
        setShowModalNotification(true);
      }
    }
  }

  return (
    <div className="container-audit-form" style={{ width: '100%' }} >
      <h4 style={{ textAlign: 'start' }} className="pagesTitles">{t('panorama_riesgos.tendencia_riesgo')}</h4>
      <Form className="audit-form" onSubmit={sendDataForm} >
        <Form.Row>
          <Form.Group as={Col} lg={12} role="form" controlId="formGridTextArea">
            <Form.Control
              className="audit-form-textarea"
              as="textarea"
              name="tendencia"
              onChange={handleChange}
              defaultValue={dataG}
            // disabled={data.is_edit}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row className="btn-form-row">
          <Button className="confirmButton" type="submit">{t('botones.enviar')}</Button>
        </Form.Row>
      </Form>
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal='saveQuestions'
      />
    </div>
  )
}
