import { Button } from 'primereact/button'
import React from 'react'
import { Edit, GetApp } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

/**
 * Componente que muestra una tarjeta con información
 * @typedef {Object} ItemTarjeta
 * @property {string} etiqueta Etiqueta del campo
 * @property {string} valor Valor del campo
 *
 * @param {string} titulo Título de la tarjeta
 * @param {ItemTarjeta[]} items Array de objetos con la información a mostrar
 * @param {string} pdf URL del pdf a descargar
 * @param {Function} onEditar Función que se ejecuta al hacer click en el botón de editar
 * @param {Object} permisos Permisos de la tarjeta (pdf, editar)
 */

function TarjetaInformacion({ titulo, items, pdf, onEditar, permisos = { pdf: true, editar: true } }) {
  const { t } = useTranslation(); //hook de i18n
  return (
    <div className='rounded-2x p-4 card__box'>
      <div className='flex flex-row justify-between'>
        <h1 className='text-2xl font-black text-darkblue-600'>{titulo}</h1>
        <div className='flex flex-row gap-4'>

          {pdf && permisos.pdf &&
            <a target='_blank' href={pdf} rel='noreferrer'>
              <Button size='small'>
                {<GetApp></GetApp>}
                {t('botones.generar_pdf')}
              </Button>
            </a>
          }
          <div>
            {permisos.editar &&
              <Button
                onClick={onEditar}
                size='small' >
                <Edit />
                {t('botones.editar')}
              </Button>
            }
          </div>
        </div>

      </div>
      <div className='flex flex-row gap-3 mt-3 flex-wrap'>
        {items?.map((item, index) => (
          <div key={index} className='flex flex-col'>
            <span className='font-bold text-gray-300 text-darkblue-600'>{item.etiqueta}</span>
            <span className='text-gray-800'>{item.valor}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TarjetaInformacion
