import { useEffect } from "react";
import useState from "react-usestateref";
import { Card, Col, Nav, Row, Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { validarEntorno } from "../../helpers/validarHost";
import PanelInformacion from "./PanelInformacion";
import FormularioDinamico from "./FormularioDinamico";
import { HelpSVG } from "../../components/icons/HelpSVG";
import { Alert } from "../../components/Alert";
import { EnvioSMS } from "../../helpers/EnvioSMS";
import { useJoyrideContext } from '../../context/Joyride';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { candidatos } from '../../helpers/stepers';

import "./InformePanoramaRiesgos.scss";

// import AyudaPerfilModal from '../modales/AyudaPerfilModal'
import EditModal from "../modales/EditModal";
import NotificationModal from "../modales/NotificationModal";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../common/Resizable";
import { EventBusProvider } from "../../context/EventBus";

/**
 * Componente principal del gestor dinamico, por este entran todas las peticiones
 * para construir lo que vera el usuario, para la gestión de lo que requeria
 * el mismo, el dinamismo esta establecido para campos y datos involucrados,
 * según una serie de parametros establecidos en el desarrollo en los componentes
 * hijos.
 * @returns
 */
const Main = () => {

  const {
    setState,
    state: { run, stepIndex, steps },
  } = useJoyrideContext();


  /**
   * UseState para mostrar modales
   */
  const [modal, setModal] = useState({
    show: false,
    tipo: "modalInfoGestorDinamico",
    data: {
      tipoInformacion: "",
      tituloModal: "",
      contenidoModal: "",
      mensaje: "",
      verificacion: "",
    },
  });

  /**
   * UseState para mostrar alertas
   */
  const [alert, setAlert] = useState({
    open: false,
    type: "",
    content: "",
    vertical: "bottom",
    horizontal: "left",
  });

  const [showAlerta, setShowAlerta] = useState(false);
  const [checkVerificacion, setCheckVerificacion] = useState(false);
  const [idPrimerNivel, setIdPrimerNivel, primerNivelRef] = useState(1); // id del primer nivel, muestra los sistemas de la gestión
  const [idSegundoNivel, setIdSegundoNivel, segundoNivelRef] = useState(1); // id del segundo nivel, muestra los subsistemas de la gestión
  const [subniveles, setSubniveles] = useState([]); // Si el segundo nivel tiene subniveles, los obtiene y los muestra.
  const [primerNivel, setPrimerNivel] = useState([]); // Items del primer nivel
  const [segundoNivel, setSegundoNivel] = useState([]); // Items del segundo nivel
  const [dataGestion, setDataGestion] = useState([]); // Contiene los datos del cliente relacionados a la gestion
  const [titulo, setTitulo] = useState(""); // Titulo que se le da al gestor, dependiendo de la data enviada
  const [perfil, setPerfil] = useState("");
  const [tituloFormulario, setTituloFormulario] = useState(""); // Titulo del formulario, dependiendo de la data enviada
  const [tipoSolicitud, setTipoSolicitud] = useState("");
  const [opcionesRegistro, setOpcionesRegistro] = useState("");
  const [opcionesFactura, setOpcionesFactura] = useState("");
  const [estadoSolicitud, setEstadoSolicitud] = useState("");
  const [sistemaOpen, setSistemaOpen] = useState("0"); //Indica que sistema esta abirto en ese momento, de manera visual para el usuario
  const [modalNotificacion, setModalNotificacion] = useState({
    show: false,
    mensaje: "",
    tipo: "",
  });
  const [indexStatus, setIndexStatus] = useState("");
  const [itemStatus, setItemStatus] = useState({});
  const [error, setError] = useState(true);
  const [tipoContenido, setTipoContenido] = useState("");
  const [isLoading, setIsLoading] = useState(false); //Indica si la petición de la modal de notificaciones esta cargando
  const [data, setData] = useState({}); //Datos de la gestión
  const hash = window.location.hash;

  const { seccion, id } = useParams(); //Al ser una dinamica, se debe tener en cuenta que seccion (departamento) y id se estan gestionando

  const validarVerificacion = (input) => {
    if (input === modal.data.verificacion) {
      setCheckVerificacion(true);
    } else {
      setCheckVerificacion(false);
    }
  };

  /**
   * Funcion que me permite abrir el modal de notificaciones
   * con valores necesarios que involucren una accion
   * @param {*} item
   * @param {*} index
   * @param {*} msg
   * @param {*} type
   */
  const handleOpenModalNotification = (item, index, msg, type) => {
    setModalNotificacion({
      ...modalNotificacion,
      show: true,
      mensaje: msg,
      tipo: type,
    });

    setIndexStatus(index);
    setItemStatus(item);
  };

  /**
   * Funcion que me permite cerrar el modal de notificaciones
   */
  const handleCloseModalNotification = () => {
    setModalNotificacion({
      ...modalNotificacion,
      show: false,
      mensaje: "",
      tipo: "",
    });
    setIndexStatus("");
    setItemStatus({});
  };

  /**
   * Funcion que me trae datos relevantes de la gestión entre estos estan:
   * 1. Datos del cliente involucrado (dataGestion)
   * 2. Primer nivel (sistemas) (primerNivel)
   * 3. Segundo nivel (subsistemas) (subniveles)
   * En caso de existir subniveles hace la validacion y agrega una capa adicional a la lista
   */
  const getDataGestion = async () => {
    const dataReq = {
      id,
      seccion,
      hash,
    };

    const req = await validarEntorno(
      "JSON/GestionDinamica/informacion_gestion.php",
      "POST",
      dataReq
    );

    if (req.ok) {
      const res = await req.json();

      if (res.estado !== "error") {
        if (res.modal.titulo !== "" && res.modal.descripcion !== "") {
          setModal({
            ...modal,
            show: res.modal.show,
            data: {
              tipoInformacion: res.modal.tipo,
              tituloModal: res.modal.titulo,
              contenidoModal: res.modal.descripcion,
              mensaje: res.modal.alerta ? res.modal.alerta : "",
              verificacion: res.modal.verificacion
                ? res.modal.verificacion
                : "",
            },
          });
        }

        setTitulo(res.titulo);
        setTipoSolicitud(res.tipo_solicitud);
        setPerfil(res.perfil);
        setDataGestion(res?.informacion_solicitud);
        setEstadoSolicitud(res?.informacion_solicitud?.estado_cos?.valor);
        setOpcionesRegistro(res.opciones_registro);
        setOpcionesFactura(res.opciones_facturacion);
        setPrimerNivel(res.primer_nivel);
        setSubniveles(res.segundo_nivel);
        setData(res);

        // console.log(res.primer_nivel[0].id_nivel);

        setIdPrimerNivel(res.primer_nivel[0].id_nivel);

        // console.log(idPrimerNivel);

        const sgFilter = res.segundo_nivel.filter((item) => {
          return item.id_nivel === primerNivelRef.current;
        });

        if (sgFilter[0]?.subniveles) {
          const sgFilter2 = sgFilter.filter((item) => {
            return item.subniveles.length > 0;
          });

          setSegundoNivel(sgFilter2);
          setSistemaOpen(sgFilter2[0].id_subnivel);
          setIdSegundoNivel(sgFilter2[0].subniveles[0].id);
          setTituloFormulario(sgFilter2[0].subniveles[0].nombre);
          setTipoContenido(sgFilter2[0].subniveles[0].tipo);
        } else {
          setSegundoNivel(sgFilter);
          setSistemaOpen(sgFilter[0]?.id_subnivel);
          setIdSegundoNivel(sgFilter[0]?.id_subnivel);
          setTituloFormulario(sgFilter[0]?.nombre);
          setTipoContenido(sgFilter[0].tipo);
        }

        setError(false);

        if (res.sms && res.sms.num && res.sms.msj) {
          EnvioSMS(res.sms);
        }

        if (res.tutorial && res?.modal?.show === false) {
          if (!localStorage.getItem('joyrideSeen')) {
            setState({ run: true, steps: candidatos });
          } else {
            candidatos[0].disableBeacon = false;
            setState({ run: true, steps: candidatos });
          }
        }

      } else {
        handleOpenModalNotification({}, {}, res.mensaje, "alerta_permisos");
      }
    }
  };

  /**
   * Funcion que valida el id seleccionado del sistema
   * Esto hara que al seleccionarlo tome el primer
   * subsistema de la lista para la vista del usuario
   * @param {*} id
   */
  const eventIdValue = (id) => {
    const sgFilter = subniveles.filter((item) => {
      return item.id_nivel === id;
    });

    setIdPrimerNivel(id);

    if (sgFilter[0].subniveles) {
      setSegundoNivel(sgFilter);
      setSistemaOpen(sgFilter[0].id_subnivel);
      setIdSegundoNivel(
        sgFilter[0].subniveles[0]
          ? sgFilter[0].subniveles[0].id
          : sgFilter.id_subnivel
      );
      setTituloFormulario(
        sgFilter[0].subniveles[0]
          ? sgFilter[0].subniveles[0].nombre
          : sgFilter.nombre
      );
      setTipoContenido(
        sgFilter[0].subniveles[0]
          ? sgFilter[0].subniveles[0].tipo
          : sgFilter.tipo
      );
    } else {
      setSegundoNivel(sgFilter);
      setSistemaOpen(sgFilter[0].id_subnivel);
      setIdSegundoNivel(sgFilter[0].id_subnivel);
      setTituloFormulario(sgFilter[0].nombre);
      setTipoContenido(sgFilter[0].tipo);
    }
  };

  /**
   * Seleccion de las subniveles en caso de que estos existan
   * @param {*} item
   */
  const getSeccionOpen = (item) => {
    setSistemaOpen(item.id_subnivel);
    eventFormIdValue(item.subniveles[0], "button-collapse");
  };

  /**
   * Funcion que ayuda a indicar en que seccion del segundo nivel (subsistema) estoy
   * En caso de existir subniveles abre el boton y da la opcion de seleccionar alguno
   * de sus items.
   * @param {*} item
   * @param {*} tipo
   */
  const eventFormIdValue = (item, tipo) => {
    setSistemaOpen(item.id_subnivel);
    setIdSegundoNivel(tipo === "button-collapse" ? item.id : item.id_subnivel);
    setTituloFormulario(item.nombre);
    setTipoContenido(item.tipo);
  };

  /**
   * Funcion ayuda a cambiar el estado de la solicitud, dependiendo de los parametros
   * establecidos por el backend.
   */
  const handleEstadoSolicitud = async () => {
    setIsLoading(true);

    const dataReq = {
      id_solicitud: itemStatus.id_opcion,
      hash,
      id_registro: id,
    };

    //console.log(dataReq);

    const req = await validarEntorno(
      "JSON/GestionDinamica/respuesta_cambio_estado_gestion.php",
      "POST",
      dataReq
    );

    if (req.ok) {
      const res = await req.json();
      if (res.estado === "OK") {
        const opcRes = [...opcionesRegistro];
        const itemOpc = { ...opcRes[indexStatus] };
        itemOpc.deshabilitado = !itemOpc.deshabilitado;
        opcRes[indexStatus] = itemOpc;
        setOpcionesRegistro(opcRes);
        setEstadoSolicitud(itemStatus.valor);
        handleOpenModalNotification(
          {},
          {},
          res.mensaje,
          "eliminacion_confirmada"
        );
      } else {
        handleOpenModalNotification(
          null,
          null,
          res.mensaje,
          "falloPeticionesGestion"
        );
      }
    } else {
      handleOpenModalNotification(
        null,
        null,
        "Ha ocurrido un error, intente nuevamente",
        "falloPeticionesGestion"
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getDataGestion();
  }, []);

  /**
   * Dependiendo de que vengan o no subniveles estos se mostraran, en un diseño
   * especial.
   */
  const getLeftMenu = () => {
    if (segundoNivel && segundoNivel.length > 0) {
      const template = (
        <Nav className="nav-items aside__menu h-100">
          <Accordion activeKey={sistemaOpen}>
            {segundoNivel.map((item, index) =>
              item.subniveles ? (
                <>
                  <Card
                    eventKey={item.id_subnivel}
                    key={item.id_subnivel}
                  >
                    <Accordion.Toggle
                      style={{
                        backgroundColor:
                          sistemaOpen === item.id_subnivel
                            ? "#213265"
                            : "var(--gray-200)",
                        color:
                          sistemaOpen === item.id_subnivel
                            ? "#efefef"
                            : "#213265",
                      }}
                      className="sistemaSegItemToggle"
                      eventKey={item.id_subnivel}
                      onClick={() => getSeccionOpen(item)}
                    >
                      <p style={{ textAlign: "start" }}>{item.nombre}</p>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      className="sistemaSegItemCollapse"
                      eventKey={item.id_subnivel}
                    >
                      <>
                        {item?.subniveles?.map((com) => {
                          return (
                            <>
                              <div
                                key={com.id}
                                className="componenteItem"
                                onClick={() =>
                                  eventFormIdValue(com, "button-collapse")
                                }
                                style={{
                                  backgroundColor:
                                    idSegundoNivel === com.id
                                      ? "#E4A731"
                                      : "#CECECE",
                                }}
                              >
                                <span style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}>{com.nombre}</span>
                              </div>
                            </>
                          );
                        })}
                      </>
                    </Accordion.Collapse>
                  </Card>
                </>
              ) : (
                <Card
                  eventKey={item.id_subnivel}
                  key={item.id_subnivel}
                >
                  <Accordion.Toggle
                    style={{
                      backgroundColor:
                        sistemaOpen === item.id_subnivel
                          ? "#213265"
                          : "var(--gray-200)",
                      color:
                        sistemaOpen === item.id_subnivel
                          ? "#efefef"
                          : "#213265",
                    }}
                    className="sistemaSegItemToggle"
                    eventKey={item.id_subnivel}
                    onClick={() => eventFormIdValue(item, "button-toggle")}
                  >
                    <p style={{ textAlign: "start" }}>{item.nombre}</p>
                  </Accordion.Toggle>
                </Card>
              )
            )}
          </Accordion>
        </Nav>
      );
      return template;
    }
  };

  const envioTratamientoDatos = async () => {
    setModal({ ...modal, show: false });

    const dataReq = {
      hash: window.location.hash,
      autorizacion: true,
      id: id,
    };

    // console.log(dataReq)
    const req = await validarEntorno(
      "JSON/GestionDinamica/tratamiento_datos.html",
      "POST",
      dataReq
    );

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "ok") {
        setAlert({
          ...alert,
          type: res.tipo,
          content: res.mensaje,
          open: true,
        });

        if (data.tutorial) {
          if (!localStorage.getItem('joyrideSeen')) {
            setState({ run: true, steps: candidatos });
          } else {
            candidatos[0].disableBeacon = false;
            setState({ run: true, steps: candidatos });
          }
        }

      } else {
      }
    } else {
      setModalNotificacion({ ...modalNotificacion });
    }
  };

  /**
   * Función que me permite manejar el flujo de la guia de usuario (onboarding tour)
   * @param {*} data
   */

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER].includes(type)) {
      const stepIndex = action === ACTIONS.PREV ? index - 1 : index + 1;
      setState(prevState => ({ ...prevState, stepIndex }));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setState({ run: false });
      localStorage.setItem('joyrideSeen', 'true');
    }
  };

  /**
   * Retornamos la vista con sus funciones
   */
  return (
    <>
      {!error ? (
        <>
          <div className="titleBox">
            {/* <h3 className="mt-5" style={{ color: '#213265', fontWeight: '800' }}>Solicitudes de estudio____</h3> */}
            <div className="titleContainer">
              <h3 className="titulo__pagina">{titulo} {tipoSolicitud ? ` - ${tipoSolicitud}` : ""}</h3>
              {/* <div className='titleLine'></div> */}
            </div>
            {modal?.data?.tituloModal !== "" &&
              modal?.data?.contenidoModal !== "" && (
                <div
                  // onClick={() => setOpen(true)}
                  onClick={() =>
                    setModal({
                      tipo: "",
                      tipo: "modalInfoGestorDinamico",
                      data: modal.data,
                      show: true,
                    })
                  }
                >
                  <HelpSVG />
                </div>
              )}
          </div>
          <br />
          <div>
            <PanelInformacion
              titulo={titulo}
              dataGestion={dataGestion}
              tipoSolicitud={tipoSolicitud}
              opcionesRegistro={opcionesRegistro}
              opcionesFactura={opcionesFactura}
              estadoSolicitud={estadoSolicitud}
              handleOpenModalNotification={handleOpenModalNotification}
              setModal={setModal}
              showModal={modal.show}
              modal={modal}
            />

            <Card
              className="card__box__surface containerPestañas p-3"
              id="pestanasGestor"
              style={{ margin: "16px 0" }}
            >
              <div className="d-flex flex-row gap-3 mr-auto">
                {primerNivel.length > 0 &&
                  primerNivel.map((item, index) => {
                    return (
                      <div
                        id={`seccion-${index}`}
                        className="pestanaGestorPanorama"
                        onClick={() => eventIdValue(item.id_nivel)}
                        style={{
                          background:
                            idPrimerNivel !== item.id_nivel &&
                            "var(--gray-200)",
                          color: idPrimerNivel !== item.id_nivel && "#213265",
                        }}
                      >
                        <p className="p-0 m-0 labelPestana">{item.nombre}</p>
                        <b
                          style={{
                            width: "25px",
                            fontSize: "30px",
                            marginTop: "-20px",
                            fontWeight: "900",
                            color: "#00A20B",
                          }}
                        >
                          .
                        </b>
                      </div>
                    );
                  })}
              </div>
            </Card>
          </div>

          <Card className="card__box__surface cardGeneral">
            <Card.Body className="p-0">
              <Row>
                <ResizablePanelGroup direction="horizontal" autoSaveId="persistence">
                  <ResizablePanel minSize={10} maxSize={50}>
                    <div className="truncate-text h-100" >{getLeftMenu()}</div>
                  </ResizablePanel>
                  <ResizableHandle withHandle={true} indicatorClassName={'indicator__handle--top'} iconClassName={'indicator__handle__icon--large'} />
                  <ResizablePanel minSize={10} defaultSize={80} className={"p-4"} >
                    <EventBusProvider>
                      <FormularioDinamico
                        idPrimerNivel={idPrimerNivel}
                        idSegundoNivel={idSegundoNivel}
                        titulo={tituloFormulario}
                      />
                    </EventBusProvider>
                  </ResizablePanel>
                </ResizablePanelGroup>
              </Row>
            </Card.Body>
          </Card>
        </>
      ) : null}

      <EditModal
        isOpen={modal.show}
        setIsOpen={setModal}
        modal={modal}
        data={modal.data}
        typeModal={modal.tipo}
        tipoInformacion={modal.data.tipoInformacion}
        envioTratamientoDatos={envioTratamientoDatos}
        showAlerta={showAlerta}
        setShowAlerta={setShowAlerta}
        setCheckVerificacion={setCheckVerificacion}
        checkVerificacion={checkVerificacion}
        validarVerificacion={validarVerificacion}
      // dataModal={modal?.data}
      />

      <NotificationModal
        isOpen={modalNotificacion.show}
        setIsOpen={handleCloseModalNotification}
        typeModal={modalNotificacion.tipo}
        message={modalNotificacion.mensaje}
        handleEstadoSolicitud={handleEstadoSolicitud}
        isLoading={isLoading}
      />
      <Alert
        alert={alert}
        setAlert={setAlert}
      // content={
      //     'mensaje': 'hola'
      // }
      />

      <Joyride
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        continuous
        scrollToFirstStep
        showProgress={true}
        showSkipButton={true}
        scrollOffset={350}

        locale={{
          last: 'Finalizar',
          next: 'Siguiente',
          skip: 'Saltar',
          back: 'Atrás',
          open: 'Abrir el recorrido de introducción',
        }}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#213265',
            textColor: 'var(--gray-700)'
          },
        }}
      />
    </>
  );
};

export default Main;
