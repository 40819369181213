import React, { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom';
import { Card, Col, Row, Form } from 'react-bootstrap'
import useState2 from 'react-usestateref';
import { useForm } from 'react-hook-form';
import InputType from '../../helpers/InputType'

import { validarEntorno } from '../../helpers/validarHost'

import EditModal from '../modales/EditModal'

import './styles/DetalleDep.scss'

import Loader from '../icons/Loader'

import DivergentChartAmplia from '../dashboard/DivergentChartAmplia'
import GraficaConsolidado from '../dashboard/GraficaConsolidado'
import Analistas from './Analistas'
import { useTranslation } from 'react-i18next';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Link } from 'react-router-dom';

export default function DetalleDep(props) {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();
  const [inputGroup, setInputGroup, inputGroupRef] = useState2([]);
  const [inputList, setInputList] = useState([{ valor: "", valido: false }]);


  // const [ modal, setModal ] = useState({
  //   type: '',
  //   show: false,
  //   data: {}
  // })

  const [mode, setMode] = useState({
    tipo: ''
  })

  const [filtroSelected, setfiltroSelected] = useState({})

  const [loaderRegs, setLoaderRegs] = useState(false)
  let [arrayFilters, setArrayFilters] = useState([])
  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })

  const [opciones, setOpciones] = useState([])
  const [infoFiltro, setInfoFiltro] = useState({
    tabla: {},
    graficas: []

  })

  const [filtros, setFiltros] = useState({
    fecha_inicial: '',
    fecha_final: ''
  })

  const hash = window.location.hash;
  const { departamento } = useParams()

  const getFilters = (data) => {
    setArrayFilters(data)
    arrayFilters = data;
  }

  const changeMode = async (tipo) => {

    let dataReq = {
      filtros: arrayFilters,
      hash: window.location.hash,
      tipo: tipo
    }

    console.log('changeMode', dataReq)


    const req = await validarEntorno('JSON/PanelPresidencia/get_opciones_presidencia.php', 'POST', dataReq)

    if (req.ok) {



      const res = await req.json()

      // setInitialState({
      //   ...initialState,
      //   loading: false
      // })

      setfiltroSelected(res?.opciones[0])

      setInfoFiltro({
        tabla: {},
        graficas: []
      })

      setOpciones(res.opciones)
      // getValores(res.opciones[0].id)

      setMode(tipo)

    }
    // setInitialState({
    //   initialState,
    //   // data: {...initialState.data},
    //   loading: false

    // })

  }

  const detalleDep = async (data) => {

    // console.log(data?.asesoria)

    const filtros = data?.asesoria;

    await getFilters(filtros)

    setFiltros(data)
    setfiltroSelected(data)

    let dataReq = {
      filtros: data?.asesoria.length ? data?.asesoria : null,
      hash: hash,
      departamento: departamento
    }

    console.log('detalleDep', dataReq)

    const req = await validarEntorno('JSON/PanelPresidencia/detalle_dep_presidencia.php', 'POST', dataReq)

    if (req.ok) {

      const res = await req.json()

      setInitialState({
        ...initialState,
        loading: false,
        error: null,
        data: res
      })
      res.campos && setInputGroup(res.campos)

      changeMode(res.botones[0].nombre)
    }
  }

  const getValores = async (item, tipo) => {


    setfiltroSelected(item)
    setLoaderRegs(true)

    const dataReq = {
      // fecha_inicial: filtros.fecha_inicial ? filtros.fecha_inicial : '',
      // fecha_final: filtros.fecha_final ? filtros.fecha_final : '',
      filtros: arrayFilters,
      hash: window.location.hash,
      tipo: tipo,
      id_filtro: item?.id ? item?.id : 1
    }
    console.log('getValores', dataReq)


    const req = await validarEntorno('JSON/PanelPresidencia/gerencial_data_informe_prueba.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()

      setInfoFiltro({
        tabla: res.tabla,
        graficas: res.grafica
      })

      setLoaderRegs(false)

    }

  }



  const getFechas = ({ target }) => {
    setFiltros({
      ...filtros,
      [target.name]: target.value
    })

  }

  useEffect(() => {
    detalleDep()
    // changeMode(initialState?.data?.botones[0].nombre)
    getValores(filtroSelected)
  }, [])

  return (
    <div
      style={{
        marginTop: '20px'
      }}
    >
      <BreadCrumb
        model={[{ label: departamento, className: 'breadcrumb__item--active' }]}
        home={{
          label: t('navegacion.menu_presidencia.panel'),
          url: '/dashboard-presidencia',
          className: 'breadcrumb__item',
          template: (item) => {
            return <Link to={item.url} className='breadcrumb__item'>{item.label}</Link>
          }
        }}
        className='mb-3' />

      {inputGroup.length > 0 &&
        <>
          <Card id='cardFiltrosFechas' className='shadow cardGeneral'>
            <Card.Body  >
              {!inputGroup.length
                ?
                <p className='pagesTitles' style={{ height: '100%' }} >Cargando filtros...</p>
                :
                <>
                  {(inputGroupRef.current && inputGroupRef.current.length > 0) &&
                    <>
                      <Form noValidate onSubmit={handleSubmit(detalleDep)} style={{ width: '98%', margin: '0 auto', padding: '0' }}>
                        <div id='contenedorFiltrosGerencia'>
                          {(inputGroupRef.current?.map((campo, index) => {
                            const fieldName = `asesoria.0`;
                            return (
                              <InputType
                                item={campo}
                                index={index}
                                idx={index}
                                key={`${0} - ${index}`}
                                reg={register}
                                nombre={`${fieldName}`}
                                errors={errors}
                                col={campo.col}
                                type=""
                                inputList={inputList}
                                setInputList={setInputList}
                              />
                            )
                          }))}
                        </div>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '20px auto 0'
                          }}
                        >
                          <button
                            type="submit"
                            className='confirmButton'
                            style={{
                              marginRight: '0 !important'
                            }}
                          >
                            {t('botones.filtrar')}
                          </button>
                        </div>
                      </Form>
                    </>
                  }
                </>
              }
            </Card.Body>
          </Card>
          <br />
        </>
      }

      <Card id='cardWidgets' className='shadow cardGeneral'>
        <Card.Body id='bodyWidgetsDetalleDep' >
          <div id='infoGeneralDetalleDep' className='colWidgetBar'>
            <h2 id='tituloDep'>{departamento}</h2>
            <p id='corteDep' >{initialState?.data?.corte}</p>
            <h3 id='tituloDep' style={{ fontSize: '1.7rem' }}>{initialState?.data?.total ? `Total: ${initialState?.data?.total}` : null}</h3>
          </div>
          <div id='contenedorWidgetsDetalleDep' className='scrollSecundario colWidgetBar' >
            {initialState?.data?.widgets?.map((widget, index) => {
              return (
                <Card
                  style={{
                    borderBottom: `10px solid ${widget.color}`
                  }}

                  // id={
                  //   widget.id === '1'
                  //     ? 'enProceso'
                  //     : widget.id === '2'
                  //       ? 'canceladas'
                  //       : widget.id === '3'
                  //         ? 'diasFinalizados'
                  //         : null
                  // }
                  // id='diasVencimientso'
                  className="shadow  widgetPr"
                  // onClick={() => handleClickWidget('widgetFinalizados')}
                  key={index}
                >
                  <Card.Body>
                    <div className='contenidoWidget'>
                      <div className='labelWidget'>{widget.nombre}</div>
                      <div className='cantidadWidget'>{widget.valor}</div>
                    </div>
                  </Card.Body>
                </Card>
              )
            })

            }
          </div>
        </Card.Body>
      </Card>
      {/* <br/> */}
      <Card
        id='cardPrincipal'
        className='shadow cardGeneral'
        style={{
          marginTop: '20px'
        }}
      >
        <Card.Body>
          <section id='seccionbtnSeccion'>
            {initialState?.data?.botones?.map((boton, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: mode === boton.nombre ? boton.color : null,
                    color: mode === boton.nombre ? 'white' : null,

                  }}
                  className='modeButton'
                  onClick={() => {
                    changeMode(boton.nombre);
                    getValores(filtroSelected, boton.nombre)
                  }}
                >
                  {boton.nombre}
                </div>
              )

            })}
            {/* <div
              className={`modeButton ${mode === 'registros' ? 'registros' : null} `}
              onClick={() => {changeMode('registros') ; getValores(filtroSelected, 'registros')}}
            >
              Registros
            </div>
            <div
              className={`modeButton ${mode === 'vencidos' ? 'vencidos' : null} `}
              onClick={() => {changeMode('vencidos') ; getValores(filtroSelected, 'vencidos') }}
            >
              Vencidos
            </div> */}
            {/*

            1. Al seleccionar fecha renderizar nuevamente la información de los widgets y las graficas por tipos
            2. Al dar clic en el tipo de filtro, recargar las graficas OK
            3. al cambiar de modo registros a vencidos, mostrar filtros para vencidos OK



            */}
          </section>
          <hr />
          {initialState.loading === false && opciones.length > 0
            ?
            <section>
              <Row>
                <div id='seccionDataInforme'>
                  <div id='columnTiposInforme' >
                    {opciones?.length > 0 && opciones?.map((tipo) => {
                      return (
                        <div
                          className={`tipoInforme`}
                          key={tipo.id}
                          onClick={() => { getValores(tipo, mode); setfiltroSelected(tipo, mode) }}
                          style={{
                            backgroundColor: filtroSelected === tipo.id ? 'rgba(132, 132, 132, 0.433) !important' : null
                          }}
                        >
                          {tipo.nombre}
                        </div>
                      )
                    })}
                  </div>
                  <div id='contenedorGraficasFiltro'>
                    {loaderRegs === true ? <Loader /> :
                      (infoFiltro?.graficas && infoFiltro?.graficas?.length > 0 &&
                        infoFiltro?.graficas.map((grafica, index) => (
                          <Fragment key={index}>
                            {grafica.tipo === 'analistas'
                              ?
                              <>
                                <Analistas
                                  data={grafica.data}
                                  title={grafica.titulo}
                                  titulo_label={grafica.titulo_label}
                                  reg_label={grafica.reg_label}
                                  valor_label={grafica.valor_label}
                                  total={grafica.total}
                                  convenciones='bottom'
                                  mode={mode}
                                  limite={grafica.limite}
                                  filtro={filtroSelected}
                                  fechas={filtros}
                                  filtros_generales={arrayFilters}
                                // modal={modal}
                                // setModal={setModal}
                                />
                                <br />
                              </>
                              : grafica.tipo === 'series'
                                ?
                                <>
                                  <GraficaConsolidado
                                    title={grafica.titulo}
                                    id='seriesPanelGerencial'
                                    data={grafica.series.data}
                                    series={grafica.series.lista}
                                    seriesOcultas={grafica.ocultar_series}
                                  />
                                  <br />
                                </>
                                : grafica.tipo === 'divergente'
                                  ?
                                  <>
                                    <DivergentChartAmplia
                                      id='divergenteValores'
                                      data={grafica.data}
                                      title={grafica.titulo}
                                      total={grafica.total}
                                      convenciones='bottom'
                                      // parametrizado={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.parametrizado}
                                      // periodo={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.periodo}
                                      // total={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.total}
                                      limite={grafica.limite}
                                    />
                                    <br />
                                  </>
                                  : null
                            }
                          </Fragment>
                        )
                        ))
                    }
                  </div>
                </div>

              </Row>
            </section>
            : initialState.loading === false && opciones.length <= 0
              ? <p>{t('formulario.label.no_encontro_informacion')}</p>
              : <Loader />
          }

        </Card.Body>
      </Card>

      {/* <EditModal
        typeModal={modal.type}
        isOpen={modal.show}
        dataModal = {modal.data}
        setIsOpen={setModal}
      /> */}



    </div>
  )
}
