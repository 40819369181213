import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import { Card, CardContent } from '../../ui/Card';
import { ChartContainer } from "../../ui/Chart";
import styles from './RadialBarInfo.module.scss';

export default function RadialBarInfo() {
  return (
    <Card className={styles.card}>
      <CardContent className={styles['card-content']}>
        <div className={styles['info__items']}>
          <div className={styles['info__item']}>
            <div className={styles['item__tag']}>Move</div>
            <div className={styles['item__value']}>
              562/600
              <span className={styles['item__unit']}>
                kcal
              </span>
            </div>
          </div>
          <div className={styles['info__item']}>
            <div className={styles['item__tag']}>Exercise</div>
            <div className={styles['item__value']}>
              73/120
              <span className={styles['item__unit']}>
                min
              </span>
            </div>
          </div>
          <div className={styles['info__item']}>
            <div className={styles['item__tag']}>Stand</div>
            <div className={styles['item__value']}>
              8/12
              <span className={styles['item__unit']}>
                hr
              </span>
            </div>
          </div>
        </div>
        <ChartContainer
          config={{
            move: {
              label: "Move",
              color: "hsl(var(--chart-1))",
            },
            exercise: {
              label: "Exercise",
              color: "hsl(var(--chart-2))",
            },
            stand: {
              label: "Stand",
              color: "hsl(var(--chart-3))",
            },
          }}
          className={styles['chart-container']}
        >
          <RadialBarChart
            margin={{
              left: -10,
              right: -10,
              top: -10,
              bottom: -10,
            }}
            data={[
              {
                activity: "stand",
                value: (8 / 12) * 100,
                fill: "var(--red-400)",
              },
              {
                activity: "exercise",
                value: (46 / 60) * 100,
                fill: "var(--red-200)",
              },
              {
                activity: "move",
                value: (245 / 360) * 100,
                fill: "var(--red-600)",
              },
            ]}
            innerRadius="20%"
            barSize={24}
            startAngle={90}
            endAngle={450}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              dataKey="value"
              tick={false}
            />
            <RadialBar dataKey="value" background cornerRadius={5} />
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}


