import React from 'react'
import { Form, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function OpcionesCiudades(props) {

  const { t } = useTranslation();

  return (
    <Form.Group as={Col} lg={6} className='inputGroup'
      style={{ paddingLeft: '8px' }}
    >
      <Form.Label>{t('formulario.label.ubicacion')}</Form.Label>
      <div className='inputContainer'>
        <Form.Control
          name="ubicacion"
          as="select"
          onChange={(e) => props.onChange(e)}
          style={{ minWidth: '250px' }}
          defaultValue={props.valor ? props.valor : ''}
          disabled={props.disabled ? props.disabled : false}
        >
          <option value="" selected disabled>{t('formulario.label.seleccione_su_opcion')}</option>
          <optgroup label="Amazonas">
            <option value="El Encanto - Amazonas">El Encanto - Amazonas</option>
            <option value="La Chorrera - Amazonas">La Chorrera - Amazonas</option>
            <option value="La Pedrera - Amazonas">La Pedrera - Amazonas</option>
            <option value="La Victoria - Amazonas">La Victoria - Amazonas</option>
            <option value="Leticia - Amazonas">Leticia - Amazonas</option>
            <option value="Miriti - Amazonas">Miriti - Amazonas</option>
            <option value="Puerto Alegria - Amazonas">Puerto Alegria - Amazonas</option>
            <option value="Puerto Arica - Amazonas">Puerto Arica - Amazonas</option>
            <option value="Puerto Nariño - Amazonas">Puerto Nariño - Amazonas</option>
            <option value="Puerto Santander - Amazonas">Puerto Santander - Amazonas</option>
            <option value="Turapaca - Amazonas">Turapaca - Amazonas</option>
          </optgroup>
          <optgroup label="Antioquia">
            <option value="Abejorral - Antioquia">Abejorral - Antioquia</option>
            <option value="Abriaqui - Antioquia">Abriaqui - Antioquia</option>
            <option value="Alejandria - Antioquia">Alejandria - Antioquia</option>
            <option value="Amaga - Antioquia">Amaga - Antioquia</option>
            <option value="Amalfi - Antioquia">Amalfi - Antioquia</option>
            <option value="Andes - Antioquia">Andes - Antioquia</option>
            <option value="Angelopolis - Antioquia">Angelopolis - Antioquia</option>
            <option value="Angostura - Antioquia">Angostura - Antioquia</option>
            <option value="Anori - Antioquia">Anori - Antioquia</option>
            <option value="Antioquia - Antioquia">Antioquia - Antioquia</option>
            <option value="Anza - Antioquia">Anza - Antioquia</option>
            <option value="Apartado - Antioquia">Apartado - Antioquia</option>
            <option value="Arboletes - Antioquia">Arboletes - Antioquia</option>
            <option value="Argelia - Antioquia">Argelia - Antioquia</option>
            <option value="Armenia - Antioquia">Armenia - Antioquia</option>
            <option value="Barbosa - Antioquia">Barbosa - Antioquia</option>
            <option value="Bello - Antioquia">Bello - Antioquia</option>
            <option value="Belmira - Antioquia">Belmira - Antioquia</option>
            <option value="Betania - Antioquia">Betania - Antioquia</option>
            <option value="Betulia - Antioquia">Betulia - Antioquia</option>
            <option value="Bolivar - Antioquia">Bolivar - Antioquia</option>
            <option value="Briceño - Antioquia">Briceño - Antioquia</option>
            <option value="Buritica - Antioquia">Buritica - Antioquia</option>
            <option value="Caceres - Antioquia">Caceres - Antioquia</option>
            <option value="Caicedo - Antioquia">Caicedo - Antioquia</option>
            <option value="Caldas - Antioquia">Caldas - Antioquia</option>
            <option value="Campamento - Antioquia">Campamento - Antioquia</option>
            <option value="Canasgordas - Antioquia">Canasgordas - Antioquia</option>
            <option value="Caracoli - Antioquia">Caracoli - Antioquia</option>
            <option value="Caramanta - Antioquia">Caramanta - Antioquia</option>
            <option value="Carepa - Antioquia">Carepa - Antioquia</option>
            <option value="Carmen de Viboral - Antioquia">Carmen de Viboral - Antioquia</option>
            <option value="Carolina del Principe - Antioquia">Carolina del Principe - Antioquia</option>
            <option value="Caucasia - Antioquia">Caucasia - Antioquia</option>
            <option value="Chigorodo - Antioquia">Chigorodo - Antioquia</option>
            <option value="Cisneros - Antioquia">Cisneros - Antioquia</option>
            <option value="Cocorna - Antioquia">Cocorna - Antioquia</option>
            <option value="Concepcion - Antioquia">Concepcion - Antioquia</option>
            <option value="Concordia - Antioquia">Concordia - Antioquia</option>
            <option value="Copacabana - Antioquia">Copacabana - Antioquia</option>
            <option value="Dabeiba - Antioquia">Dabeiba - Antioquia</option>
            <option value="Donmatias - Antioquia">Donmatias - Antioquia</option>
            <option value="Doradal - Antioquia">Doradal - Antioquia</option>
            <option value="Ebejico - Antioquia">Ebejico - Antioquia</option>
            <option value="El Bagre - Antioquia">El Bagre - Antioquia</option>
            <option value="El Penol - Antioquia">El Penol - Antioquia</option>
            <option value="El Retiro - Antioquia">El Retiro - Antioquia</option>
            <option value="Entrerrios - Antioquia">Entrerrios - Antioquia</option>
            <option value="Envigado - Antioquia">Envigado - Antioquia</option>
            <option value="Fredonia - Antioquia">Fredonia - Antioquia</option>
            <option value="Frontino - Antioquia">Frontino - Antioquia</option>
            <option value="Giraldo - Antioquia">Giraldo - Antioquia</option>
            <option value="Girardota - Antioquia">Girardota - Antioquia</option>
            <option value="Gomez Plata - Antioquia">Gomez Plata - Antioquia</option>
            <option value="Granada - Antioquia">Granada - Antioquia</option>
            <option value="Guadalupe - Antioquia">Guadalupe - Antioquia</option>
            <option value="Guarne - Antioquia">Guarne - Antioquia</option>
            <option value="Guataque - Antioquia">Guataque - Antioquia</option>
            <option value="Heliconia - Antioquia">Heliconia - Antioquia</option>
            <option value="Hispania - Antioquia">Hispania - Antioquia</option>
            <option value="Itagui - Antioquia">Itagui - Antioquia</option>
            <option value="Ituango - Antioquia">Ituango - Antioquia</option>
            <option value="Jardin - Antioquia">Jardin - Antioquia</option>
            <option value="Jerico - Antioquia">Jerico - Antioquia</option>
            <option value="La Ceja - Antioquia">La Ceja - Antioquia</option>
            <option value="La Estrella - Antioquia">La Estrella - Antioquia</option>
            <option value="La Pintada - Antioquia">La Pintada - Antioquia</option>
            <option value="La Union - Antioquia">La Union - Antioquia</option>
            <option value="Liborina - Antioquia">Liborina - Antioquia</option>
            <option value="Maceo - Antioquia">Maceo - Antioquia</option>
            <option value="Marinilla - Antioquia">Marinilla - Antioquia</option>
            <option value="Medellin - Antioquia">Medellin - Antioquia</option>
            <option value="Montebello - Antioquia">Montebello - Antioquia</option>
            <option value="Murindo - Antioquia">Murindo - Antioquia</option>
            <option value="Mutata - Antioquia">Mutata - Antioquia</option>
            <option value="Narino - Antioquia">Narino - Antioquia</option>
            <option value="Nechi - Antioquia">Nechi - Antioquia</option>
            <option value="Necocli - Antioquia">Necocli - Antioquia</option>
            <option value="Olaya - Antioquia">Olaya - Antioquia</option>
            <option value="Peque - Antioquia">Peque - Antioquia</option>
            <option value="Pueblorrico - Antioquia">Pueblorrico - Antioquia</option>
            <option value="Puerto Berrio - Antioquia">Puerto Berrio - Antioquia</option>
            <option value="Puerto Nare - Antioquia">Puerto Nare - Antioquia</option>
            <option value="Puerto Triunfo - Antioquia">Puerto Triunfo - Antioquia</option>
            <option value="Remedios - Antioquia">Remedios - Antioquia</option>
            <option value="Rionegro - Antioquia">Rionegro - Antioquia</option>
            <option value="Sabanalarga - Antioquia">Sabanalarga - Antioquia</option>
            <option value="Sabaneta - Antioquia">Sabaneta - Antioquia</option>
            <option value="Salgar - Antioquia">Salgar - Antioquia</option>
            <option value="San Andres de Cuerquia - Antioquia">San Andres de Cuerquia - Antioquia</option>
            <option value="San Carlos - Antioquia">San Carlos - Antioquia</option>
            <option value="San Francisco - Antioquia">San Francisco - Antioquia</option>
            <option value="San Jeronimo - Antioquia">San Jeronimo - Antioquia</option>
            <option value="San Jose de La Montaña - Antioquia">San Jose de La Montaña - Antioquia</option>
            <option value="San Juan de Uraba - Antioquia">San Juan de Uraba - Antioquia</option>
            <option value="San Luis - Antioquia">San Luis - Antioquia</option>
            <option value="San Pedro de Los Milagros - Antioquia">San Pedro de Los Milagros - Antioquia</option>
            <option value="San Pedro de Uraba - Antioquia">San Pedro de Uraba - Antioquia</option>
            <option value="San Rafael - Antioquia">San Rafael - Antioquia</option>
            <option value="San Roque - Antioquia">San Roque - Antioquia</option>
            <option value="San Vicente - Antioquia">San Vicente - Antioquia</option>
            <option value="Santa Barbara - Antioquia">Santa Barbara - Antioquia</option>
            <option value="Santa Fe de Antioquia - Antioquia">Santa Fe de Antioquia - Antioquia</option>
            <option value="Santa Rosa de Osos - Antioquia">Santa Rosa de Osos - Antioquia</option>
            <option value="Santo Domingo - Antioquia">Santo Domingo - Antioquia</option>
            <option value="Santuario - Antioquia">Santuario - Antioquia</option>
            <option value="Segovia - Antioquia">Segovia - Antioquia</option>
            <option value="Sonson - Antioquia">Sonson - Antioquia</option>
            <option value="Sopetran - Antioquia">Sopetran - Antioquia</option>
            <option value="Tamesis - Antioquia">Tamesis - Antioquia</option>
            <option value="Taraza - Antioquia">Taraza - Antioquia</option>
            <option value="Tarso - Antioquia">Tarso - Antioquia</option>
            <option value="Titiribi - Antioquia">Titiribi - Antioquia</option>
            <option value="Toledo - Antioquia">Toledo - Antioquia</option>
            <option value="Turbo - Antioquia">Turbo - Antioquia</option>
            <option value="Uramita - Antioquia">Uramita - Antioquia</option>
            <option value="Urrao - Antioquia">Urrao - Antioquia</option>
            <option value="Valdivia - Antioquia">Valdivia - Antioquia</option>
            <option value="Valparaiso - Antioquia">Valparaiso - Antioquia</option>
            <option value="Vegachi - Antioquia">Vegachi - Antioquia</option>
            <option value="Venecia - Antioquia">Venecia - Antioquia</option>
            <option value="Vigia del Fuerte - Antioquia">Vigia del Fuerte - Antioquia</option>
            <option value="Yali - Antioquia">Yali - Antioquia</option>
            <option value="Yarumal - Antioquia">Yarumal - Antioquia</option>
            <option value="Yolombo - Antioquia">Yolombo - Antioquia</option>
            <option value="Yondo - Antioquia">Yondo - Antioquia</option>
            <option value="Zaragoza - Antioquia">Zaragoza - Antioquia</option>
          </optgroup>
          <optgroup label="Arauca">
            <option value="Arauca - Arauca">Arauca - Arauca</option>
            <option value="Arauquita - Arauca">Arauquita - Arauca</option>
            <option value="Cravo Norte - Arauca">Cravo Norte - Arauca</option>
            <option value="Fortul - Arauca">Fortul - Arauca</option>
            <option value="Puerto Rondon - Arauca">Puerto Rondon - Arauca</option>
            <option value="Saravena - Arauca">Saravena - Arauca</option>
            <option value="Tame - Arauca">Tame - Arauca</option>
          </optgroup>
          <optgroup label="Atlántico">
            <option value="Baranoa - Atlántico">Baranoa - Atlántico</option>
            <option value="Barranquilla - Atlántico">Barranquilla - Atlántico</option>
            <option value="Campo de La Cruz - Atlántico">Campo de La Cruz - Atlántico</option>
            <option value="Candelaria - Atlántico">Candelaria - Atlántico</option>
            <option value="Galapa - Atlántico">Galapa - Atlántico</option>
            <option value="Juan de Acosta - Atlántico">Juan de Acosta - Atlántico</option>
            <option value="Luruaco - Atlántico">Luruaco - Atlántico</option>
            <option value="Malambo - Atlántico">Malambo - Atlántico</option>
            <option value="Manati - Atlántico">Manati - Atlántico</option>
            <option value="Palmar de Varela - Atlántico">Palmar de Varela - Atlántico</option>
            <option value="Piojo - Atlántico">Piojo - Atlántico</option>
            <option value="Polo Nuevo - Atlántico">Polo Nuevo - Atlántico</option>
            <option value="Ponedera - Atlántico">Ponedera - Atlántico</option>
            <option value="Puerto Colombia - Atlántico">Puerto Colombia - Atlántico</option>
            <option value="Repelon - Atlántico">Repelon - Atlántico</option>
            <option value="Sabanagrande - Atlántico">Sabanagrande - Atlántico</option>
            <option value="Sabanalarga - Atlántico">Sabanalarga - Atlántico</option>
            <option value="Santa Lucia - Atlántico">Santa Lucia - Atlántico</option>
            <option value="Santo Tomas - Atlántico">Santo Tomas - Atlántico</option>
            <option value="Soledad - Atlántico">Soledad - Atlántico</option>
            <option value="Suan - Atlántico">Suan - Atlántico</option>
            <option value="Tubara - Atlántico">Tubara - Atlántico</option>
            <option value="Usiacuri - Atlántico">Usiacuri - Atlántico</option>
          </optgroup>
          <optgroup label="Bolívar">
            <option value="Achi - Bolívar">Achi - Bolívar</option>
            <option value="Altos del Rosario - Bolívar">Altos del Rosario - Bolívar</option>
            <option value="Arenal - Bolívar">Arenal - Bolívar</option>
            <option value="Arjona - Bolívar">Arjona - Bolívar</option>
            <option value="Arroyohondo - Bolívar">Arroyohondo - Bolívar</option>
            <option value="Barranco de Loba - Bolívar">Barranco de Loba - Bolívar</option>
            <option value="Brazuelo de Papayal - Bolívar">Brazuelo de Papayal - Bolívar</option>
            <option value="Calamar - Bolívar">Calamar - Bolívar</option>
            <option value="Cantagallo - Bolívar">Cantagallo - Bolívar</option>
            <option value="Cartagena de Indias - Bolívar">Cartagena de Indias - Bolívar</option>
            <option value="Cicuco - Bolívar">Cicuco - Bolívar</option>
            <option value="Clemencia - Bolívar">Clemencia - Bolívar</option>
            <option value="Cordoba - Bolívar">Cordoba - Bolívar</option>
            <option value="El Carmen de Bolivar - Bolívar">El Carmen de Bolivar - Bolívar</option>
            <option value="El Guamo - Bolívar">El Guamo - Bolívar</option>
            <option value="El Penion - Bolívar">El Penion - Bolívar</option>
            <option value="Hatillo de Loba - Bolívar">Hatillo de Loba - Bolívar</option>
            <option value="Magangue - Bolívar">Magangue - Bolívar</option>
            <option value="Mahates - Bolívar">Mahates - Bolívar</option>
            <option value="Margarita - Bolívar">Margarita - Bolívar</option>
            <option value="Maria La Baja - Bolívar">Maria La Baja - Bolívar</option>
            <option value="Montecristo - Bolívar">Montecristo - Bolívar</option>
            <option value="Morales - Bolívar">Morales - Bolívar</option>
            <option value="Morales - Bolívar">Morales - Bolívar</option>
            <option value="Norosi - Bolívar">Norosi - Bolívar</option>
            <option value="Pinillos - Bolívar">Pinillos - Bolívar</option>
            <option value="Regidor - Bolívar">Regidor - Bolívar</option>
            <option value="Rio Viejo - Bolívar">Rio Viejo - Bolívar</option>
            <option value="San Cristobal - Bolívar">San Cristobal - Bolívar</option>
            <option value="San Estanislao - Bolívar">San Estanislao - Bolívar</option>
            <option value="San Fernando - Bolívar">San Fernando - Bolívar</option>
            <option value="San Jacinto - Bolívar">San Jacinto - Bolívar</option>
            <option value="San Jacinto del Cauca - Bolívar">San Jacinto del Cauca - Bolívar</option>
            <option value="San Juan de Nepomuceno - Bolívar">San Juan de Nepomuceno - Bolívar</option>
            <option value="San Martin de Loba - Bolívar">San Martin de Loba - Bolívar</option>
            <option value="San Pablo - Bolívar">San Pablo - Bolívar</option>
            <option value="San Pablo Norte - Bolívar">San Pablo Norte - Bolívar</option>
            <option value="Santa Catalina - Bolívar">Santa Catalina - Bolívar</option>
            <option value="Santa Cruz de Mompox - Bolívar">Santa Cruz de Mompox - Bolívar</option>
            <option value="Santa Rosa - Bolívar">Santa Rosa - Bolívar</option>
            <option value="Santa Rosa del Sur - Bolívar">Santa Rosa del Sur - Bolívar</option>
            <option value="Simiti - Bolívar">Simiti - Bolívar</option>
            <option value="Soplaviento - Bolívar">Soplaviento - Bolívar</option>
            <option value="Talaigua Nuevo - Bolívar">Talaigua Nuevo - Bolívar</option>
            <option value="Tuquisio - Bolívar">Tuquisio - Bolívar</option>
            <option value="Turbaco - Bolívar">Turbaco - Bolívar</option>
            <option value="Turbana - Bolívar">Turbana - Bolívar</option>
            <option value="Villanueva - Bolívar">Villanueva - Bolívar</option>
            <option value="Zambrano - Bolívar">Zambrano - Bolívar</option>
          </optgroup>
          <optgroup label="Boyacá">
            <option value="Aquitania - Boyacá">Aquitania - Boyacá</option>
            <option value="Arcabuco - Boyacá">Arcabuco - Boyacá</option>
            <option value="Belén - Boyacá">Belén - Boyacá</option>
            <option value="Berbeo - Boyacá">Berbeo - Boyacá</option>
            <option value="Betéitiva - Boyacá">Betéitiva - Boyacá</option>
            <option value="Boavita - Boyacá">Boavita - Boyacá</option>
            <option value="Boyacá - Boyacá">Boyacá - Boyacá</option>
            <option value="Briceño - Boyacá">Briceño - Boyacá</option>
            <option value="Buenavista - Boyacá">Buenavista - Boyacá</option>
            <option value="Busbanzá - Boyacá">Busbanzá - Boyacá</option>
            <option value="Caldas - Boyacá">Caldas - Boyacá</option>
            <option value="Campo Hermoso - Boyacá">Campo Hermoso - Boyacá</option>
            <option value="Cerinza - Boyacá">Cerinza - Boyacá</option>
            <option value="Chinavita - Boyacá">Chinavita - Boyacá</option>
            <option value="Chiquinquirá - Boyacá">Chiquinquirá - Boyacá</option>
            <option value="Chíquiza - Boyacá">Chíquiza - Boyacá</option>
            <option value="Chiscas - Boyacá">Chiscas - Boyacá</option>
            <option value="Chita - Boyacá">Chita - Boyacá</option>
            <option value="Chitaraque - Boyacá">Chitaraque - Boyacá</option>
            <option value="Chivatá - Boyacá">Chivatá - Boyacá</option>
            <option value="Chivor - Boyacá">Chivor - Boyacá</option>
            <option value="Ciénega - Boyacá">Ciénega - Boyacá</option>
            <option value="Cómbita - Boyacá">Cómbita - Boyacá</option>
            <option value="Coper - Boyacá">Coper - Boyacá</option>
            <option value="Corrales - Boyacá">Corrales - Boyacá</option>
            <option value="Covarachía - Boyacá">Covarachía - Boyacá</option>
            <option value="Cubara - Boyacá">Cubara - Boyacá</option>
            <option value="Cucaita - Boyacá">Cucaita - Boyacá</option>
            <option value="Cuitiva - Boyacá">Cuitiva - Boyacá</option>
            <option value="Duitama - Boyacá">Duitama - Boyacá</option>
            <option value="El Cocuy - Boyacá">El Cocuy - Boyacá</option>
            <option value="El Espino - Boyacá">El Espino - Boyacá</option>
            <option value="Firavitoba - Boyacá">Firavitoba - Boyacá</option>
            <option value="Floresta - Boyacá">Floresta - Boyacá</option>
            <option value="Gachantivá - Boyacá">Gachantivá - Boyacá</option>
            <option value="Gámeza - Boyacá">Gámeza - Boyacá</option>
            <option value="Garagoa - Boyacá">Garagoa - Boyacá</option>
            <option value="Guacamayas - Boyacá">Guacamayas - Boyacá</option>
            <option value="Guateque - Boyacá">Guateque - Boyacá</option>
            <option value="Güicán - Boyacá">Güicán - Boyacá</option>
            <option value="Iza - Boyacá">Iza - Boyacá</option>
            <option value="Jenesano - Boyacá">Jenesano - Boyacá</option>
            <option value="Jericó - Boyacá">Jericó - Boyacá</option>
            <option value="La Uvita - Boyacá">La Uvita - Boyacá</option>
            <option value="La Victoria - Boyacá">La Victoria - Boyacá</option>
            <option value="Labranza Grande - Boyacá">Labranza Grande - Boyacá</option>
            <option value="Macanal - Boyacá">Macanal - Boyacá</option>
            <option value="Maripí - Boyacá">Maripí - Boyacá</option>
            <option value="Miraflores - Boyacá">Miraflores - Boyacá</option>
            <option value="Mongua - Boyacá">Mongua - Boyacá</option>
            <option value="Monguí - Boyacá">Monguí - Boyacá</option>
            <option value="Moniquirá - Boyacá">Moniquirá - Boyacá</option>
            <option value="Motavita - Boyacá">Motavita - Boyacá</option>
            <option value="Muzo - Boyacá">Muzo - Boyacá</option>
            <option value="Nobsa - Boyacá">Nobsa - Boyacá</option>
            <option value="Nuevo Colón - Boyacá">Nuevo Colón - Boyacá</option>
            <option value="Oicatá - Boyacá">Oicatá - Boyacá</option>
            <option value="Otanche - Boyacá">Otanche - Boyacá</option>
            <option value="Pachavita - Boyacá">Pachavita - Boyacá</option>
            <option value="Páez - Boyacá">Páez - Boyacá</option>
            <option value="Paipa - Boyacá">Paipa - Boyacá</option>
            <option value="Pajarito - Boyacá">Pajarito - Boyacá</option>
            <option value="Panqueba - Boyacá">Panqueba - Boyacá</option>
            <option value="Pauna - Boyacá">Pauna - Boyacá</option>
            <option value="Paya - Boyacá">Paya - Boyacá</option>
            <option value="Paz de Río - Boyacá">Paz de Río - Boyacá</option>
            <option value="Pesca - Boyacá">Pesca - Boyacá</option>
            <option value="Pisba - Boyacá">Pisba - Boyacá</option>
            <option value="Puerto Boyaca - Boyacá">Puerto Boyaca - Boyacá</option>
            <option value="Quípama - Boyacá">Quípama - Boyacá</option>
            <option value="Ramiriquí - Boyacá">Ramiriquí - Boyacá</option>
            <option value="Ráquira - Boyacá">Ráquira - Boyacá</option>
            <option value="Rondón - Boyacá">Rondón - Boyacá</option>
            <option value="Saboyá - Boyacá">Saboyá - Boyacá</option>
            <option value="Sáchica - Boyacá">Sáchica - Boyacá</option>
            <option value="Samacá - Boyacá">Samacá - Boyacá</option>
            <option value="San Eduardo - Boyacá">San Eduardo - Boyacá</option>
            <option value="San José de Pare - Boyacá">San José de Pare - Boyacá</option>
            <option value="San Luís de Gaceno - Boyacá">San Luís de Gaceno - Boyacá</option>
            <option value="San Mateo - Boyacá">San Mateo - Boyacá</option>
            <option value="San Miguel de Sema - Boyacá">San Miguel de Sema - Boyacá</option>
            <option value="San Pablo de Borbur - Boyacá">San Pablo de Borbur - Boyacá</option>
            <option value="Santa María - Boyacá">Santa María - Boyacá</option>
            <option value="Santa Rosa de Viterbo - Boyacá">Santa Rosa de Viterbo - Boyacá</option>
            <option value="Santa Sofía - Boyacá">Santa Sofía - Boyacá</option>
            <option value="Santana - Boyacá">Santana - Boyacá</option>
            <option value="Sativanorte - Boyacá">Sativanorte - Boyacá</option>
            <option value="Sativasur - Boyacá">Sativasur - Boyacá</option>
            <option value="Siachoque - Boyacá">Siachoque - Boyacá</option>
            <option value="Soatá - Boyacá">Soatá - Boyacá</option>
            <option value="Socha - Boyacá">Socha - Boyacá</option>
            <option value="Socotá - Boyacá">Socotá - Boyacá</option>
            <option value="Sogamoso - Boyacá">Sogamoso - Boyacá</option>
            <option value="Somondoco - Boyacá">Somondoco - Boyacá</option>
            <option value="Sora - Boyacá">Sora - Boyacá</option>
            <option value="Soracá - Boyacá">Soracá - Boyacá</option>
            <option value="Sotaquirá - Boyacá">Sotaquirá - Boyacá</option>
            <option value="Susacón - Boyacá">Susacón - Boyacá</option>
            <option value="Sutarmachán - Boyacá">Sutarmachán - Boyacá</option>
            <option value="Sutatenza - Boyacá">Sutatenza - Boyacá</option>
            <option value="Tasco - Boyacá">Tasco - Boyacá</option>
            <option value="Tenza - Boyacá">Tenza - Boyacá</option>
            <option value="Tibaná - Boyacá">Tibaná - Boyacá</option>
            <option value="Tibasosa - Boyacá">Tibasosa - Boyacá</option>
            <option value="Tinjacá - Boyacá">Tinjacá - Boyacá</option>
            <option value="Tipacoque - Boyacá">Tipacoque - Boyacá</option>
            <option value="Toca - Boyacá">Toca - Boyacá</option>
            <option value="Togüí - Boyacá">Togüí - Boyacá</option>
            <option value="Tópaga - Boyacá">Tópaga - Boyacá</option>
            <option value="Tota - Boyacá">Tota - Boyacá</option>
            <option value="Tunja - Boyacá">Tunja - Boyacá</option>
            <option value="Tununguá - Boyacá">Tununguá - Boyacá</option>
            <option value="Turmequé - Boyacá">Turmequé - Boyacá</option>
            <option value="Tuta - Boyacá">Tuta - Boyacá</option>
            <option value="Tutazá - Boyacá">Tutazá - Boyacá</option>
            <option value="Umbita - Boyacá">Umbita - Boyacá</option>
            <option value="Venta Quemada - Boyacá">Venta Quemada - Boyacá</option>
            <option value="Villa de Leyva - Boyacá">Villa de Leyva - Boyacá</option>
            <option value="Viracachá - Boyacá">Viracachá - Boyacá</option>
            <option value="Zetaquira - Boyacá">Zetaquira - Boyacá</option>
          </optgroup>
          <optgroup label="Caldas">
            <option value="Aguadas - Caldas">Aguadas - Caldas</option>
            <option value="Anserma - Caldas">Anserma - Caldas</option>
            <option value="Aranzazu - Caldas">Aranzazu - Caldas</option>
            <option value="Belalcazar - Caldas">Belalcazar - Caldas</option>
            <option value="Chinchiná - Caldas">Chinchiná - Caldas</option>
            <option value="Filadelfia - Caldas">Filadelfia - Caldas</option>
            <option value="La Dorada - Caldas">La Dorada - Caldas</option>
            <option value="La Merced - Caldas">La Merced - Caldas</option>
            <option value="Manizales - Caldas">Manizales - Caldas</option>
            <option value="Manzanares - Caldas">Manzanares - Caldas</option>
            <option value="Marmato - Caldas">Marmato - Caldas</option>
            <option value="Marquetalia - Caldas">Marquetalia - Caldas</option>
            <option value="Marulanda - Caldas">Marulanda - Caldas</option>
            <option value="Neira - Caldas">Neira - Caldas</option>
            <option value="Norcasia - Caldas">Norcasia - Caldas</option>
            <option value="Pacora - Caldas">Pacora - Caldas</option>
            <option value="Palestina - Caldas">Palestina - Caldas</option>
            <option value="Pensilvania - Caldas">Pensilvania - Caldas</option>
            <option value="Riosucio - Caldas">Riosucio - Caldas</option>
            <option value="Risaralda - Caldas">Risaralda - Caldas</option>
            <option value="Salamina - Caldas">Salamina - Caldas</option>
            <option value="Samana - Caldas">Samana - Caldas</option>
            <option value="San Jose - Caldas">San Jose - Caldas</option>
            <option value="Supía - Caldas">Supía - Caldas</option>
            <option value="Victoria - Caldas">Victoria - Caldas</option>
            <option value="Villamaría - Caldas">Villamaría - Caldas</option>
            <option value="Viterbo - Caldas">Viterbo - Caldas</option>
          </optgroup>
          <optgroup label="Caquetá">
            <option value="Albania - Caquetá">Albania - Caquetá</option>
            <option value="Belén Andaquies - Caquetá">Belén Andaquies - Caquetá</option>
            <option value="Cartagena del Chaira - Caquetá">Cartagena del Chaira - Caquetá</option>
            <option value="Curillo - Caquetá">Curillo - Caquetá</option>
            <option value="El Doncello - Caquetá">El Doncello - Caquetá</option>
            <option value="El Paujil - Caquetá">El Paujil - Caquetá</option>
            <option value="Florencia - Caquetá">Florencia - Caquetá</option>
            <option value="La Montañita - Caquetá">La Montañita - Caquetá</option>
            <option value="Milán - Caquetá">Milán - Caquetá</option>
            <option value="Morelia - Caquetá">Morelia - Caquetá</option>
            <option value="Puerto Rico - Caquetá">Puerto Rico - Caquetá</option>
            <option value="San  Vicente del Caguan - Caquetá">San  Vicente del Caguan - Caquetá</option>
            <option value="San José de Fragua - Caquetá">San José de Fragua - Caquetá</option>
            <option value="Solano - Caquetá">Solano - Caquetá</option>
            <option value="Solita - Caquetá">Solita - Caquetá</option>
            <option value="Valparaíso - Caquetá">Valparaíso - Caquetá</option>
          </optgroup>
          <optgroup label="Casanare">
            <option value="Aguazul - Casanare">Aguazul - Casanare</option>
            <option value="Chameza - Casanare">Chameza - Casanare</option>
            <option value="Hato Corozal - Casanare">Hato Corozal - Casanare</option>
            <option value="La Salina - Casanare">La Salina - Casanare</option>
            <option value="Maní - Casanare">Maní - Casanare</option>
            <option value="Monterrey - Casanare">Monterrey - Casanare</option>
            <option value="Nunchia - Casanare">Nunchia - Casanare</option>
            <option value="Orocue - Casanare">Orocue - Casanare</option>
            <option value="Paz de Ariporo - Casanare">Paz de Ariporo - Casanare</option>
            <option value="Pore - Casanare">Pore - Casanare</option>
            <option value="Recetor - Casanare">Recetor - Casanare</option>
            <option value="Sabana Larga - Casanare">Sabana Larga - Casanare</option>
            <option value="Sacama - Casanare">Sacama - Casanare</option>
            <option value="San Luis de Palenque - Casanare">San Luis de Palenque - Casanare</option>
            <option value="Tamara - Casanare">Tamara - Casanare</option>
            <option value="Tauramena - Casanare">Tauramena - Casanare</option>
            <option value="Trinidad - Casanare">Trinidad - Casanare</option>
            <option value="Villanueva - Casanare">Villanueva - Casanare</option>
            <option value="Yopal - Casanare">Yopal - Casanare</option>
          </optgroup>
          <optgroup label="Cauca">
            <option value="Almaguer - Cauca">Almaguer - Cauca</option>
            <option value="Argelia - Cauca">Argelia - Cauca</option>
            <option value="Balboa - Cauca">Balboa - Cauca</option>
            <option value="Bolívar - Cauca">Bolívar - Cauca</option>
            <option value="Buenos Aires - Cauca">Buenos Aires - Cauca</option>
            <option value="Cajibio - Cauca">Cajibio - Cauca</option>
            <option value="Caldono - Cauca">Caldono - Cauca</option>
            <option value="Caloto - Cauca">Caloto - Cauca</option>
            <option value="Corinto - Cauca">Corinto - Cauca</option>
            <option value="El Tambo - Cauca">El Tambo - Cauca</option>
            <option value="Florencia - Cauca">Florencia - Cauca</option>
            <option value="Guachené - Cauca">Guachené - Cauca</option>
            <option value="Guapi - Cauca">Guapi - Cauca</option>
            <option value="Inza - Cauca">Inza - Cauca</option>
            <option value="Jambaló - Cauca">Jambaló - Cauca</option>
            <option value="La Sierra - Cauca">La Sierra - Cauca</option>
            <option value="La Vega - Cauca">La Vega - Cauca</option>
            <option value="López - Cauca">López - Cauca</option>
            <option value="Mercaderes - Cauca">Mercaderes - Cauca</option>
            <option value="Miranda - Cauca">Miranda - Cauca</option>
            <option value="Morales - Cauca">Morales - Cauca</option>
            <option value="Padilla - Cauca">Padilla - Cauca</option>
            <option value="Páez - Cauca">Páez - Cauca</option>
            <option value="Patia (el Bordo) - Cauca">Patia (el Bordo) - Cauca</option>
            <option value="Piamonte - Cauca">Piamonte - Cauca</option>
            <option value="Piendamo - Cauca">Piendamo - Cauca</option>
            <option value="Popayán - Cauca">Popayán - Cauca</option>
            <option value="Puerto Tejada - Cauca">Puerto Tejada - Cauca</option>
            <option value="Purace - Cauca">Purace - Cauca</option>
            <option value="Rosas - Cauca">Rosas - Cauca</option>
            <option value="San Sebastián - Cauca">San Sebastián - Cauca</option>
            <option value="Santa Rosa - Cauca">Santa Rosa - Cauca</option>
            <option value="Santander de Quilichao - Cauca">Santander de Quilichao - Cauca</option>
            <option value="Silvia - Cauca">Silvia - Cauca</option>
            <option value="Sotara - Cauca">Sotara - Cauca</option>
            <option value="Suárez - Cauca">Suárez - Cauca</option>
            <option value="Sucre - Cauca">Sucre - Cauca</option>
            <option value="Timbío - Cauca">Timbío - Cauca</option>
            <option value="Timbiquí - Cauca">Timbiquí - Cauca</option>
            <option value="Toribio - Cauca">Toribio - Cauca</option>
            <option value="Totoro - Cauca">Totoro - Cauca</option>
            <option value="Villa Rica - Cauca">Villa Rica - Cauca</option>
          </optgroup>
          <optgroup label="Cesar">
            <option value="Aguachica - Cesar">Aguachica - Cesar</option>
            <option value="Agustín Codazzi - Cesar">Agustín Codazzi - Cesar</option>
            <option value="Astrea - Cesar">Astrea - Cesar</option>
            <option value="Becerril - Cesar">Becerril - Cesar</option>
            <option value="Bosconia - Cesar">Bosconia - Cesar</option>
            <option value="Chimichagua - Cesar">Chimichagua - Cesar</option>
            <option value="Chiriguaná - Cesar">Chiriguaná - Cesar</option>
            <option value="Curumaní - Cesar">Curumaní - Cesar</option>
            <option value="El Copey - Cesar">El Copey - Cesar</option>
            <option value="El Paso - Cesar">El Paso - Cesar</option>
            <option value="Gamarra - Cesar">Gamarra - Cesar</option>
            <option value="González - Cesar">González - Cesar</option>
            <option value="La Gloria - Cesar">La Gloria - Cesar</option>
            <option value="La Jagua Ibirico - Cesar">La Jagua Ibirico - Cesar</option>
            <option value="Manaure Balcón del Cesar - Cesar">Manaure Balcón del Cesar - Cesar</option>
            <option value="Pailitas - Cesar">Pailitas - Cesar</option>
            <option value="Pelaya - Cesar">Pelaya - Cesar</option>
            <option value="Pueblo Bello - Cesar">Pueblo Bello - Cesar</option>
            <option value="Río de Oro - Cesar">Río de Oro - Cesar</option>
            <option value="Robles (la Paz) - Cesar">Robles (la Paz) - Cesar</option>
            <option value="San Alberto - Cesar">San Alberto - Cesar</option>
            <option value="San Diego - Cesar">San Diego - Cesar</option>
            <option value="San Martín - Cesar">San Martín - Cesar</option>
            <option value="Tamalameque - Cesar">Tamalameque - Cesar</option>
            <option value="Valledupar - Cesar">Valledupar - Cesar</option>
          </optgroup>
          <optgroup label="Chocó">
            <option value="Acandi - Chocó">Acandi - Chocó</option>
            <option value="Alto Baudo (pie de Pato) - Chocó">Alto Baudo (pie de Pato) - Chocó</option>
            <option value="Atrato - Chocó">Atrato - Chocó</option>
            <option value="Bagado - Chocó">Bagado - Chocó</option>
            <option value="Bahia Solano (mutis) - Chocó">Bahia Solano (mutis) - Chocó</option>
            <option value="Bajo Baudo (pizarro) - Chocó">Bajo Baudo (pizarro) - Chocó</option>
            <option value="Bojaya (bellavista) - Chocó">Bojaya (bellavista) - Chocó</option>
            <option value="Canton de San Pablo - Chocó">Canton de San Pablo - Chocó</option>
            <option value="Carmen del Darien - Chocó">Carmen del Darien - Chocó</option>
            <option value="Certegui - Chocó">Certegui - Chocó</option>
            <option value="Condoto - Chocó">Condoto - Chocó</option>
            <option value="El Carmen - Chocó">El Carmen - Chocó</option>
            <option value="El Carmen de Atrato - Chocó">El Carmen de Atrato - Chocó</option>
            <option value="Istmina - Chocó">Istmina - Chocó</option>
            <option value="Jurado - Chocó">Jurado - Chocó</option>
            <option value="Litoral del San Juan - Chocó">Litoral del San Juan - Chocó</option>
            <option value="Lloro - Chocó">Lloro - Chocó</option>
            <option value="Medio Atrato - Chocó">Medio Atrato - Chocó</option>
            <option value="Medio Baudo (boca de Pepe) - Chocó">Medio Baudo (boca de Pepe) - Chocó</option>
            <option value="Medio San Juan - Chocó">Medio San Juan - Chocó</option>
            <option value="Novita - Chocó">Novita - Chocó</option>
            <option value="Nuqui - Chocó">Nuqui - Chocó</option>
            <option value="Quibdó - Chocó">Quibdo - Chocó</option>
            <option value="Rio Iro - Chocó">Rio Iro - Chocó</option>
            <option value="Rio Quito - Chocó">Rio Quito - Chocó</option>
            <option value="Riosucio - Chocó">Riosucio - Chocó</option>
            <option value="San Jose del Palmar - Chocó">San Jose del Palmar - Chocó</option>
            <option value="Sipi - Chocó">Sipi - Chocó</option>
            <option value="Tado - Chocó">Tado - Chocó</option>
            <option value="Unguia - Chocó">Unguia - Chocó</option>
            <option value="Unión Panamericana - Chocó">Unión Panamericana - Chocó</option>
          </optgroup>
          <optgroup label="Córdoba">
            <option value="Ayapel - Córdoba">Ayapel - Córdoba</option>
            <option value="Buenavista - Córdoba">Buenavista - Córdoba</option>
            <option value="Canalete - Córdoba">Canalete - Córdoba</option>
            <option value="Cereté - Córdoba">Cereté - Córdoba</option>
            <option value="Chima - Córdoba">Chima - Córdoba</option>
            <option value="Chinú - Córdoba">Chinú - Córdoba</option>
            <option value="Cienaga de Oro - Córdoba">Cienaga de Oro - Córdoba</option>
            <option value="Cotorra - Córdoba">Cotorra - Córdoba</option>
            <option value="La Apartada - Córdoba">La Apartada - Córdoba</option>
            <option value="Lorica - Córdoba">Lorica - Córdoba</option>
            <option value="Los Córdobas - Córdoba">Los Córdobas - Córdoba</option>
            <option value="Momil - Córdoba">Momil - Córdoba</option>
            <option value="Moñitos - Córdoba">Moñitos - Córdoba</option>
            <option value="Montelíbano - Córdoba">Montelíbano - Córdoba</option>
            <option value="Montería - Córdoba">Montería - Córdoba</option>
            <option value="Planeta Rica - Córdoba">Planeta Rica - Córdoba</option>
            <option value="Pueblo Nuevo - Córdoba">Pueblo Nuevo - Córdoba</option>
            <option value="Puerto Escondido - Córdoba">Puerto Escondido - Córdoba</option>
            <option value="Puerto Libertador - Córdoba">Puerto Libertador - Córdoba</option>
            <option value="Purísima - Córdoba">Purísima - Córdoba</option>
            <option value="Sahagún - Córdoba">Sahagún - Córdoba</option>
            <option value="San Andrés Sotavento - Córdoba">San Andrés Sotavento - Córdoba</option>
            <option value="San Antero - Córdoba">San Antero - Córdoba</option>
            <option value="San Bernardo Viento - Córdoba">San Bernardo Viento - Córdoba</option>
            <option value="San Carlos - Córdoba">San Carlos - Córdoba</option>
            <option value="San Pelayo - Córdoba">San Pelayo - Córdoba</option>
            <option value="San José de Uré - Córdoba">San José de Uré - Córdoba</option>
            <option value="Tierralta - Córdoba">Tierralta - Córdoba</option>
            <option value="Tuchín - Córdoba">Tuchín - Córdoba</option>
            <option value="Valencia - Córdoba">Valencia - Córdoba</option>
          </optgroup>
          <optgroup label="Cundinamarca">
            <option value="Agua de Dios - Cundinamarca">Agua de Dios - Cundinamarca</option>
            <option value="Alban - Cundinamarca">Alban - Cundinamarca</option>
            <option value="Anapoima - Cundinamarca">Anapoima - Cundinamarca</option>
            <option value="Anolaima - Cundinamarca">Anolaima - Cundinamarca</option>
            <option value="Arbelaez - Cundinamarca">Arbelaez - Cundinamarca</option>
            <option value="Beltrán - Cundinamarca">Beltrán - Cundinamarca</option>
            <option value="Bituima - Cundinamarca">Bituima - Cundinamarca</option>
            <option value="Bogotá - Cundinamarca">Bogotá - Cundinamarca</option>
            <option value="Bojacá - Cundinamarca">Bojacá - Cundinamarca</option>
            <option value="Cabrera - Cundinamarca">Cabrera - Cundinamarca</option>
            <option value="Cachipay - Cundinamarca">Cachipay - Cundinamarca</option>
            <option value="Cajicá - Cundinamarca">Cajicá - Cundinamarca</option>
            <option value="Caparrapí - Cundinamarca">Caparrapí - Cundinamarca</option>
            <option value="Caqueza - Cundinamarca">Caqueza - Cundinamarca</option>
            <option value="Carmen de Carupa - Cundinamarca">Carmen de Carupa - Cundinamarca</option>
            <option value="Chaguaní - Cundinamarca">Chaguaní - Cundinamarca</option>
            <option value="Chia - Cundinamarca">Chia - Cundinamarca</option>
            <option value="Chipaque - Cundinamarca">Chipaque - Cundinamarca</option>
            <option value="Choachí - Cundinamarca">Choachí - Cundinamarca</option>
            <option value="Chocontá - Cundinamarca">Chocontá - Cundinamarca</option>
            <option value="Cogua - Cundinamarca">Cogua - Cundinamarca</option>
            <option value="Cota - Cundinamarca">Cota - Cundinamarca</option>
            <option value="Cucunubá - Cundinamarca">Cucunubá - Cundinamarca</option>
            <option value="El Colegio - Cundinamarca">El Colegio - Cundinamarca</option>
            <option value="El Peñón - Cundinamarca">El Peñón - Cundinamarca</option>
            <option value="El Rosal1 - Cundinamarca">El Rosal1 - Cundinamarca</option>
            <option value="Facatativa - Cundinamarca">Facatativa - Cundinamarca</option>
            <option value="Fómeque - Cundinamarca">Fómeque - Cundinamarca</option>
            <option value="Fosca - Cundinamarca">Fosca - Cundinamarca</option>
            <option value="Funza - Cundinamarca">Funza - Cundinamarca</option>
            <option value="Fúquene - Cundinamarca">Fúquene - Cundinamarca</option>
            <option value="Fusagasuga - Cundinamarca">Fusagasuga - Cundinamarca</option>
            <option value="Gachalá - Cundinamarca">Gachalá - Cundinamarca</option>
            <option value="Gachancipá - Cundinamarca">Gachancipá - Cundinamarca</option>
            <option value="Gacheta - Cundinamarca">Gacheta - Cundinamarca</option>
            <option value="Gama - Cundinamarca">Gama - Cundinamarca</option>
            <option value="Girardot - Cundinamarca">Girardot - Cundinamarca</option>
            <option value="Granada2 - Cundinamarca">Granada2 - Cundinamarca</option>
            <option value="Guachetá - Cundinamarca">Guachetá - Cundinamarca</option>
            <option value="Guaduas - Cundinamarca">Guaduas - Cundinamarca</option>
            <option value="Guasca - Cundinamarca">Guasca - Cundinamarca</option>
            <option value="Guataquí - Cundinamarca">Guataquí - Cundinamarca</option>
            <option value="Guatavita - Cundinamarca">Guatavita - Cundinamarca</option>
            <option value="Guayabal de Siquima - Cundinamarca">Guayabal de Siquima - Cundinamarca</option>
            <option value="Guayabetal - Cundinamarca">Guayabetal - Cundinamarca</option>
            <option value="Gutiérrez - Cundinamarca">Gutiérrez - Cundinamarca</option>
            <option value="Jerusalén - Cundinamarca">Jerusalén - Cundinamarca</option>
            <option value="Junín - Cundinamarca">Junín - Cundinamarca</option>
            <option value="La Calera - Cundinamarca">La Calera - Cundinamarca</option>
            <option value="La cabaña - Cundinamarca">La cabaña - Cundinamarca</option>
            <option value="La Mesa - Cundinamarca">La Mesa - Cundinamarca</option>
            <option value="La Palma - Cundinamarca">La Palma - Cundinamarca</option>
            <option value="La Peña - Cundinamarca">La Peña - Cundinamarca</option>
            <option value="La Vega - Cundinamarca">La Vega - Cundinamarca</option>
            <option value="Lenguazaque - Cundinamarca">Lenguazaque - Cundinamarca</option>
            <option value="Machetá - Cundinamarca">Machetá - Cundinamarca</option>
            <option value="Madrid - Cundinamarca">Madrid - Cundinamarca</option>
            <option value="Manta - Cundinamarca">Manta - Cundinamarca</option>
            <option value="Medina - Cundinamarca">Medina - Cundinamarca</option>
            <option value="Mosquera - Cundinamarca">Mosquera - Cundinamarca</option>
            <option value="Nariño - Cundinamarca">Nariño - Cundinamarca</option>
            <option value="Nemocón - Cundinamarca">Nemocón - Cundinamarca</option>
            <option value="Nilo - Cundinamarca">Nilo - Cundinamarca</option>
            <option value="Nimaima - Cundinamarca">Nimaima - Cundinamarca</option>
            <option value="Nocaima - Cundinamarca">Nocaima - Cundinamarca</option>
            <option value="Ospina Pérez - Cundinamarca">Ospina Pérez - Cundinamarca</option>
            <option value="Pacho - Cundinamarca">Pacho - Cundinamarca</option>
            <option value="Paime - Cundinamarca">Paime - Cundinamarca</option>
            <option value="Pandi - Cundinamarca">Pandi - Cundinamarca</option>
            <option value="Paratebueno - Cundinamarca">Paratebueno - Cundinamarca</option>
            <option value="Pasca - Cundinamarca">Pasca - Cundinamarca</option>
            <option value="Puerto Salgar - Cundinamarca">Puerto Salgar - Cundinamarca</option>
            <option value="Pulí - Cundinamarca">Pulí - Cundinamarca</option>
            <option value="Quebradanegra - Cundinamarca">Quebradanegra - Cundinamarca</option>
            <option value="Quetame - Cundinamarca">Quetame - Cundinamarca</option>
            <option value="Quipile - Cundinamarca">Quipile - Cundinamarca</option>
            <option value="Rafael Reyes - Cundinamarca">Rafael Reyes - Cundinamarca</option>
            <option value="Ricaurte - Cundinamarca">Ricaurte - Cundinamarca</option>
            <option value="San  Antonio del  Tequendama - Cundinamarca">San  Antonio del  Tequendama - Cundinamarca</option>
            <option value="San Bernardo - Cundinamarca">San Bernardo - Cundinamarca</option>
            <option value="San Cayetano - Cundinamarca">San Cayetano - Cundinamarca</option>
            <option value="San Francisco - Cundinamarca">San Francisco - Cundinamarca</option>
            <option value="San Juan de Rioseco - Cundinamarca">San Juan de Rioseco - Cundinamarca</option>
            <option value="Sasaima - Cundinamarca">Sasaima - Cundinamarca</option>
            <option value="Sesquilé - Cundinamarca">Sesquilé - Cundinamarca</option>
            <option value="Sibaté - Cundinamarca">Sibaté - Cundinamarca</option>
            <option value="Silvania - Cundinamarca">Silvania - Cundinamarca</option>
            <option value="Simijaca - Cundinamarca">Simijaca - Cundinamarca</option>
            <option value="Soacha - Cundinamarca">Soacha - Cundinamarca</option>
            <option value="Sopo - Cundinamarca">Sopo - Cundinamarca</option>
            <option value="Subachoque - Cundinamarca">Subachoque - Cundinamarca</option>
            <option value="Suesca - Cundinamarca">Suesca - Cundinamarca</option>
            <option value="Supatá - Cundinamarca">Supatá - Cundinamarca</option>
            <option value="Susa - Cundinamarca">Susa - Cundinamarca</option>
            <option value="Sutatausa - Cundinamarca">Sutatausa - Cundinamarca</option>
            <option value="Tabio - Cundinamarca">Tabio - Cundinamarca</option>
            <option value="Tausa - Cundinamarca">Tausa - Cundinamarca</option>
            <option value="Tena - Cundinamarca">Tena - Cundinamarca</option>
            <option value="Tenjo - Cundinamarca">Tenjo - Cundinamarca</option>
            <option value="Tibacuy - Cundinamarca">Tibacuy - Cundinamarca</option>
            <option value="Tibirita - Cundinamarca">Tibirita - Cundinamarca</option>
            <option value="Tocaima - Cundinamarca">Tocaima - Cundinamarca</option>
            <option value="Tocancipá - Cundinamarca">Tocancipá - Cundinamarca</option>
            <option value="Topaipí - Cundinamarca">Topaipí - Cundinamarca</option>
            <option value="Ubalá - Cundinamarca">Ubalá - Cundinamarca</option>
            <option value="Ubaque - Cundinamarca">Ubaque - Cundinamarca</option>
            <option value="Ubaté - Cundinamarca">Ubaté - Cundinamarca</option>
            <option value="Une - Cundinamarca">Une - Cundinamarca</option>
            <option value="Utica - Cundinamarca">Utica - Cundinamarca</option>
            <option value="Vergara - Cundinamarca">Vergara - Cundinamarca</option>
            <option value="Viani - Cundinamarca">Viani - Cundinamarca</option>
            <option value="Villa Gomez - Cundinamarca">Villa Gomez - Cundinamarca</option>
            <option value="Venecia - Cundinamarca">Venecia - Cundinamarca</option>
            <option value="Villa Pinzón - Cundinamarca">Villa Pinzón - Cundinamarca</option>
            <option value="Villeta - Cundinamarca">Villeta - Cundinamarca</option>
            <option value="Viota - Cundinamarca">Viota - Cundinamarca</option>
            <option value="Yacopí - Cundinamarca">Yacopí - Cundinamarca</option>
            <option value="Zipacón - Cundinamarca">Zipacón - Cundinamarca</option>
            <option value="Zipaquirá - Cundinamarca">Zipaquirá - Cundinamarca</option>
          </optgroup>
          <optgroup label="Guainía">
            <option value="Barranco Minas - Guainía">Barranco Minas - Guainía</option>
            <option value="Cacahual - Guainía">Cacahual - Guainía</option>
            <option value="Inírida - Guainía">Inírida - Guainía</option>
            <option value="La Guadalupe - Guainía">La Guadalupe - Guainía</option>
            <option value="Mapiripana - Guainía">Mapiripana - Guainía</option>
            <option value="Morichal - Guainía">Morichal - Guainía</option>
            <option value="Pana Pana - Guainía">Pana Pana - Guainía</option>
            <option value="Puerto Colombia - Guainía">Puerto Colombia - Guainía</option>
            <option value="San Felipe - Guainía">San Felipe - Guainía</option>
          </optgroup>
          <optgroup label="Guaviare">
            <option value="Calamar - Guaviare">Calamar - Guaviare</option>
            <option value="El Retorno - Guaviare">El Retorno - Guaviare</option>
            <option value="Miraflorez - Guaviare">Miraflorez - Guaviare</option>
            <option value="San José del Guaviare - Guaviare">San José del Guaviare - Guaviare</option>
          </optgroup>
          <optgroup label="Huila">
            <option value="Acevedo - Huila">Acevedo - Huila</option>
            <option value="Agrado - Huila">Agrado - Huila</option>
            <option value="Aipe - Huila">Aipe - Huila</option>
            <option value="Algeciras - Huila">Algeciras - Huila</option>
            <option value="Altamira - Huila">Altamira - Huila</option>
            <option value="Baraya - Huila">Baraya - Huila</option>
            <option value="Campo Alegre - Huila">Campo Alegre - Huila</option>
            <option value="Colombia - Huila">Colombia - Huila</option>
            <option value="Elias - Huila">Elias - Huila</option>
            <option value="Garzón - Huila">Garzón - Huila</option>
            <option value="Gigante - Huila">Gigante - Huila</option>
            <option value="Guadalupe - Huila">Guadalupe - Huila</option>
            <option value="Hobo - Huila">Hobo - Huila</option>
            <option value="Iquira - Huila">Iquira - Huila</option>
            <option value="Isnos - Huila">Isnos - Huila</option>
            <option value="La Argentina - Huila">La Argentina - Huila</option>
            <option value="La Plata - Huila">La Plata - Huila</option>
            <option value="Nataga - Huila">Nataga - Huila</option>
            <option value="Neiva - Huila">Neiva - Huila</option>
            <option value="Oporapa - Huila">Oporapa - Huila</option>
            <option value="Paicol - Huila">Paicol - Huila</option>
            <option value="Palermo - Huila">Palermo - Huila</option>
            <option value="Palestina - Huila">Palestina - Huila</option>
            <option value="Pital - Huila">Pital - Huila</option>
            <option value="Pitalito - Huila">Pitalito - Huila</option>
            <option value="Rivera - Huila">Rivera - Huila</option>
            <option value="Salado Blanco - Huila">Salado Blanco - Huila</option>
            <option value="San Agustín - Huila">San Agustín - Huila</option>
            <option value="Santa Maria - Huila">Santa Maria - Huila</option>
            <option value="Suaza - Huila">Suaza - Huila</option>
            <option value="Tarqui - Huila">Tarqui - Huila</option>
            <option value="Tello - Huila">Tello - Huila</option>
            <option value="Teruel - Huila">Teruel - Huila</option>
            <option value="Tesalia - Huila">Tesalia - Huila</option>
            <option value="Timana - Huila">Timana - Huila</option>
            <option value="Villavieja - Huila">Villavieja - Huila</option>
            <option value="Yaguara - Huila">Yaguara - Huila</option>
          </optgroup>
          <optgroup label="La guajira">
            <option value="Albania - La guajira">Albania - La guajira</option>
            <option value="Barrancas - La guajira">Barrancas - La guajira</option>
            <option value="Dibulla - La guajira">Dibulla - La guajira</option>
            <option value="Distracción - La guajira">Distracción - La guajira</option>
            <option value="El Molino - La guajira">El Molino - La guajira</option>
            <option value="Fonseca - La guajira">Fonseca - La guajira</option>
            <option value="Hato Nuevo - La guajira">Hato Nuevo - La guajira</option>
            <option value="La Jagua del Pilar - La guajira">La Jagua del Pilar - La guajira</option>
            <option value="Maicao - La guajira">Maicao - La guajira</option>
            <option value="Manaure - La guajira">Manaure - La guajira</option>
            <option value="Riohacha - La guajira">Riohacha - La guajira</option>
            <option value="San Juan del Cesar - La guajira">San Juan del Cesar - La guajira</option>
            <option value="Uribia - La guajira">Uribia - La guajira</option>
            <option value="Urumita - La guajira">Urumita - La guajira</option>
            <option value="Villanueva - La guajira">Villanueva - La guajira</option>
          </optgroup>
          <optgroup label="Magdalena">
            <option value="Algarrobo - Magdalena">Algarrobo - Magdalena</option>
            <option value="Aracataca - Magdalena">Aracataca - Magdalena</option>
            <option value="Ariguani - Magdalena">Ariguani - Magdalena</option>
            <option value="Cerro San Antonio - Magdalena">Cerro San Antonio - Magdalena</option>
            <option value="Chivolo - Magdalena">Chivolo - Magdalena</option>
            <option value="Cienaga - Magdalena">Cienaga - Magdalena</option>
            <option value="Concordia - Magdalena">Concordia - Magdalena</option>
            <option value="El Banco - Magdalena">El Banco - Magdalena</option>
            <option value="El Piñon - Magdalena">El Piñon - Magdalena</option>
            <option value="El Reten - Magdalena">El Reten - Magdalena</option>
            <option value="Fundacion - Magdalena">Fundacion - Magdalena</option>
            <option value="Guamal - Magdalena">Guamal - Magdalena</option>
            <option value="Nueva Granada - Magdalena">Nueva Granada - Magdalena</option>
            <option value="Pedraza - Magdalena">Pedraza - Magdalena</option>
            <option value="Pijiño del Carmen - Magdalena">Pijiño del Carmen - Magdalena</option>
            <option value="Pivijay - Magdalena">Pivijay - Magdalena</option>
            <option value="Plato - Magdalena">Plato - Magdalena</option>
            <option value="Pueblo Viejo - Magdalena">Pueblo Viejo - Magdalena</option>
            <option value="Remolino - Magdalena">Remolino - Magdalena</option>
            <option value="Sabanas de San Angel - Magdalena">Sabanas de San Angel - Magdalena</option>
            <option value="Salamina - Magdalena">Salamina - Magdalena</option>
            <option value="San Sebastian de Buenavista - Magdalena">San Sebastian de Buenavista - Magdalena</option>
            <option value="San Zenon - Magdalena">San Zenon - Magdalena</option>
            <option value="Santa Ana - Magdalena">Santa Ana - Magdalena</option>
            <option value="Santa Barbara de Pinto - Magdalena">Santa Barbara de Pinto - Magdalena</option>
            <option value="Santa Marta - Magdalena">Santa Marta - Magdalena</option>
            <option value="Sitionuevo - Magdalena">Sitionuevo - Magdalena</option>
            <option value="Tenerife - Magdalena">Tenerife - Magdalena</option>
            <option value="Zapayan - Magdalena">Zapayan - Magdalena</option>
            <option value="Zona Bananera - Magdalena">Zona Bananera - Magdalena</option>
          </optgroup>
          <optgroup label="Meta">
            <option value="Acacias - Meta">Acacias - Meta</option>
            <option value="Barranca de Upia - Meta">Barranca de Upia - Meta</option>
            <option value="Cabuyaro - Meta">Cabuyaro - Meta</option>
            <option value="Castilla La Nueva - Meta">Castilla La Nueva - Meta</option>
            <option value="Cubarral - Meta">Cubarral - Meta</option>
            <option value="Cumaral - Meta">Cumaral - Meta</option>
            <option value="El Calvario - Meta">El Calvario - Meta</option>
            <option value="El Castillo - Meta">El Castillo - Meta</option>
            <option value="El Dorado - Meta">El Dorado - Meta</option>
            <option value="Fuente de Oro - Meta">Fuente de Oro - Meta</option>
            <option value="Granada - Meta">Granada - Meta</option>
            <option value="Guamal - Meta">Guamal - Meta</option>
            <option value="La Macarena - Meta">La Macarena - Meta</option>
            <option value="La Uribe - Meta">La Uribe - Meta</option>
            <option value="Lejanías - Meta">Lejanías - Meta</option>
            <option value="Mapiripán - Meta">Mapiripán - Meta</option>
            <option value="Mesetas - Meta">Mesetas - Meta</option>
            <option value="Planadas - Meta">Planadas - Meta</option>
            <option value="Puerto Concordia - Meta">Puerto Concordia - Meta</option>
            <option value="Puerto Gaitán - Meta">Puerto Gaitán - Meta</option>
            <option value="Puerto Lleras - Meta">Puerto Lleras - Meta</option>
            <option value="Puerto López - Meta">Puerto López - Meta</option>
            <option value="Puerto Rico - Meta">Puerto Rico - Meta</option>
            <option value="Restrepo - Meta">Restrepo - Meta</option>
            <option value="San  Juan de Arama - Meta">San  Juan de Arama - Meta</option>
            <option value="San Carlos Guaroa - Meta">San Carlos Guaroa - Meta</option>
            <option value="San Juanito - Meta">San Juanito - Meta</option>
            <option value="San Martín - Meta">San Martín - Meta</option>
            <option value="Villavicencio - Meta">Villavicencio - Meta</option>
            <option value="Vista Hermosa - Meta">Vista Hermosa - Meta</option>
          </optgroup>
          <optgroup label="Nariño">
            <option value="Alban - Nariño">Alban - Nariño</option>
            <option value="Aldaña - Nariño">Aldaña - Nariño</option>
            <option value="Ancuya - Nariño">Ancuya - Nariño</option>
            <option value="Arboleda - Nariño">Arboleda - Nariño</option>
            <option value="Barbacoas - Nariño">Barbacoas - Nariño</option>
            <option value="Belen - Nariño">Belen - Nariño</option>
            <option value="Buesaco - Nariño">Buesaco - Nariño</option>
            <option value="Chachagui - Nariño">Chachagui - Nariño</option>
            <option value="Colon (genova) - Nariño">Colon (genova) - Nariño</option>
            <option value="Consaca - Nariño">Consaca - Nariño</option>
            <option value="Contadero - Nariño">Contadero - Nariño</option>
            <option value="Cordoba - Nariño">Cordoba - Nariño</option>
            <option value="Cuaspud - Nariño">Cuaspud - Nariño</option>
            <option value="Cumbal - Nariño">Cumbal - Nariño</option>
            <option value="Cumbitara - Nariño">Cumbitara - Nariño</option>
            <option value="El Charco - Nariño">El Charco - Nariño</option>
            <option value="El Peñol - Nariño">El Peñol - Nariño</option>
            <option value="El Rosario - Nariño">El Rosario - Nariño</option>
            <option value="El Tablón - Nariño">El Tablón - Nariño</option>
            <option value="El Tambo - Nariño">El Tambo - Nariño</option>
            <option value="Funes - Nariño">Funes - Nariño</option>
            <option value="Guachucal - Nariño">Guachucal - Nariño</option>
            <option value="Guaitarilla - Nariño">Guaitarilla - Nariño</option>
            <option value="Gualmatan - Nariño">Gualmatan - Nariño</option>
            <option value="Iles - Nariño">Iles - Nariño</option>
            <option value="Imues - Nariño">Imues - Nariño</option>
            <option value="Ipiales - Nariño">Ipiales - Nariño</option>
            <option value="La Cruz - Nariño">La Cruz - Nariño</option>
            <option value="La Florida - Nariño">La Florida - Nariño</option>
            <option value="La Llanada - Nariño">La Llanada - Nariño</option>
            <option value="La Tola - Nariño">La Tola - Nariño</option>
            <option value="La Union - Nariño">La Union - Nariño</option>
            <option value="Leiva - Nariño">Leiva - Nariño</option>
            <option value="Linares - Nariño">Linares - Nariño</option>
            <option value="Los Andes - Nariño">Los Andes - Nariño</option>
            <option value="Magui - Nariño">Magui - Nariño</option>
            <option value="Mallama - Nariño">Mallama - Nariño</option>
            <option value="Mosqueza - Nariño">Mosqueza - Nariño</option>
            <option value="Nariño - Nariño">Nariño - Nariño</option>
            <option value="Olaya Herrera - Nariño">Olaya Herrera - Nariño</option>
            <option value="Ospina - Nariño">Ospina - Nariño</option>
            <option value="Pasto - Nariño">Pasto - Nariño</option>
            <option value="Pizarro - Nariño">Pizarro - Nariño</option>
            <option value="Policarpa - Nariño">Policarpa - Nariño</option>
            <option value="Potosi - Nariño">Potosi - Nariño</option>
            <option value="Providencia - Nariño">Providencia - Nariño</option>
            <option value="Puerres - Nariño">Puerres - Nariño</option>
            <option value="Pupiales - Nariño">Pupiales - Nariño</option>
            <option value="Ricaurte - Nariño">Ricaurte - Nariño</option>
            <option value="Roberto Payan - Nariño">Roberto Payan - Nariño</option>
            <option value="Salahonda - Nariño">Salahonda - Nariño</option>
            <option value="Samaniego - Nariño">Samaniego - Nariño</option>
            <option value="San Bernardo - Nariño">San Bernardo - Nariño</option>
            <option value="San Lorenzo - Nariño">San Lorenzo - Nariño</option>
            <option value="San Pablo - Nariño">San Pablo - Nariño</option>
            <option value="San Pedro de Cartago - Nariño">San Pedro de Cartago - Nariño</option>
            <option value="Sandona - Nariño">Sandona - Nariño</option>
            <option value="Santa Barbara - Nariño">Santa Barbara - Nariño</option>
            <option value="Santacruz - Nariño">Santacruz - Nariño</option>
            <option value="Sapuyes - Nariño">Sapuyes - Nariño</option>
            <option value="Taminango - Nariño">Taminango - Nariño</option>
            <option value="Tangua - Nariño">Tangua - Nariño</option>
            <option value="Tumaco - Nariño">Tumaco - Nariño</option>
            <option value="Tuquerres - Nariño">Tuquerres - Nariño</option>
            <option value="Yacuanquer - Nariño">Yacuanquer - Nariño</option>
          </optgroup>
          <optgroup label="Norte de Santander">
            <option value="Abrego - Norte de Santander">Abrego - Norte de Santander</option>
            <option value="Arboledas - Norte de Santander">Arboledas - Norte de Santander</option>
            <option value="Bochalema - Norte de Santander">Bochalema - Norte de Santander</option>
            <option value="Bucarasica - Norte de Santander">Bucarasica - Norte de Santander</option>
            <option value="Cáchira - Norte de Santander">Cáchira - Norte de Santander</option>
            <option value="Cácota - Norte de Santander">Cácota - Norte de Santander</option>
            <option value="Chinácota - Norte de Santander">Chinácota - Norte de Santander</option>
            <option value="Chitagá - Norte de Santander">Chitagá - Norte de Santander</option>
            <option value="Convención - Norte de Santander">Convención - Norte de Santander</option>
            <option value="Cúcuta - Norte de Santander">Cúcuta - Norte de Santander</option>
            <option value="Cucutilla - Norte de Santander">Cucutilla - Norte de Santander</option>
            <option value="Durania - Norte de Santander">Durania - Norte de Santander</option>
            <option value="El Carmen - Norte de Santander">El Carmen - Norte de Santander</option>
            <option value="El Tarra - Norte de Santander">El Tarra - Norte de Santander</option>
            <option value="El Zulia - Norte de Santander">El Zulia - Norte de Santander</option>
            <option value="Gramalote - Norte de Santander">Gramalote - Norte de Santander</option>
            <option value="Hacari - Norte de Santander">Hacari - Norte de Santander</option>
            <option value="Herrán - Norte de Santander">Herrán - Norte de Santander</option>
            <option value="La Esperanza - Norte de Santander">La Esperanza - Norte de Santander</option>
            <option value="La Playa - Norte de Santander">La Playa - Norte de Santander</option>
            <option value="Labateca - Norte de Santander">Labateca - Norte de Santander</option>
            <option value="Los Patios - Norte de Santander">Los Patios - Norte de Santander</option>
            <option value="Lourdes - Norte de Santander">Lourdes - Norte de Santander</option>
            <option value="Mutiscua - Norte de Santander">Mutiscua - Norte de Santander</option>
            <option value="Ocaña - Norte de Santander">Ocaña - Norte de Santander</option>
            <option value="Pamplona - Norte de Santander">Pamplona - Norte de Santander</option>
            <option value="Pamplonita - Norte de Santander">Pamplonita - Norte de Santander</option>
            <option value="Puerto Santander - Norte de Santander">Puerto Santander - Norte de Santander</option>
            <option value="Ragonvalia - Norte de Santander">Ragonvalia - Norte de Santander</option>
            <option value="Salazar - Norte de Santander">Salazar - Norte de Santander</option>
            <option value="San Calixto - Norte de Santander">San Calixto - Norte de Santander</option>
            <option value="San Cayetano - Norte de Santander">San Cayetano - Norte de Santander</option>
            <option value="Santiago - Norte de Santander">Santiago - Norte de Santander</option>
            <option value="Sardinata - Norte de Santander">Sardinata - Norte de Santander</option>
            <option value="Silos - Norte de Santander">Silos - Norte de Santander</option>
            <option value="Teorama - Norte de Santander">Teorama - Norte de Santander</option>
            <option value="Tibú - Norte de Santander">Tibú - Norte de Santander</option>
            <option value="Toledo - Norte de Santander">Toledo - Norte de Santander</option>
            <option value="Villa Caro - Norte de Santander">Villa Caro - Norte de Santander</option>
            <option value="Villa del Rosario - Norte de Santander">Villa del Rosario - Norte de Santander</option>
          </optgroup>
          <optgroup label="Putumayo">
            <option value="Colón - Putumayo">Colón - Putumayo</option>
            <option value="Mocoa - Putumayo">Mocoa - Putumayo</option>
            <option value="Orito - Putumayo">Orito - Putumayo</option>
            <option value="Puerto Asís - Putumayo">Puerto Asís - Putumayo</option>
            <option value="Puerto Caycedo - Putumayo">Puerto Caycedo - Putumayo</option>
            <option value="Puerto Guzmán - Putumayo">Puerto Guzmán - Putumayo</option>
            <option value="Puerto Leguízamo - Putumayo">Puerto Leguízamo - Putumayo</option>
            <option value="San Francisco - Putumayo">San Francisco - Putumayo</option>
            <option value="San Miguel - Putumayo">San Miguel - Putumayo</option>
            <option value="Santiago - Putumayo">Santiago - Putumayo</option>
            <option value="Sibundoy - Putumayo">Sibundoy - Putumayo</option>
            <option value="Valle del Guamuez - Putumayo">Valle del Guamuez - Putumayo</option>
            <option value="Villagarzón - Putumayo">Villagarzón - Putumayo</option>
          </optgroup>
          <optgroup label="Quindío">
            <option value="Armenia - Quindío">Armenia - Quindío</option>
            <option value="Barcelona - Quindío">Barcelona - Quindío</option>
            <option value="Buenavista - Quindío">Buenavista - Quindío</option>
            <option value="Calarcá - Quindío">Calarcá - Quindío</option>
            <option value="Circasia - Quindío">Circasia - Quindío</option>
            <option value="Córdoba - Quindío">Córdoba - Quindío</option>
            <option value="Filandia - Quindío">Filandia - Quindío</option>
            <option value="Génova - Quindío">Génova - Quindío</option>
            <option value="La Tebaida - Quindío">La Tebaida - Quindío</option>
            <option value="Montenegro - Quindío">Montenegro - Quindío</option>
            <option value="Pijao - Quindío">Pijao - Quindío</option>
            <option value="Quimbaya - Quindío">Quimbaya - Quindío</option>
            <option value="Salento - Quindío">Salento - Quindío</option>
          </optgroup>
          <optgroup label="Risaralda">
            <option value="Apia - Risaralda">Apia - Risaralda</option>
            <option value="Balboa - Risaralda">Balboa - Risaralda</option>
            <option value="Belén de Umbría - Risaralda">Belén de Umbría - Risaralda</option>
            <option value="Dos Quebradas - Risaralda">Dos Quebradas - Risaralda</option>
            <option value="Guatica - Risaralda">Guatica - Risaralda</option>
            <option value="La Celia - Risaralda">La Celia - Risaralda</option>
            <option value="La Virginia - Risaralda">La Virginia - Risaralda</option>
            <option value="Marsella - Risaralda">Marsella - Risaralda</option>
            <option value="Mistrato - Risaralda">Mistrato - Risaralda</option>
            <option value="Pereira - Risaralda">Pereira - Risaralda</option>
            <option value="Pueblo Rico - Risaralda">Pueblo Rico - Risaralda</option>
            <option value="Quinchía - Risaralda">Quinchía - Risaralda</option>
            <option value="Santa Rosa de Cabal - Risaralda">Santa Rosa de Cabal - Risaralda</option>
            <option value="Santuario - Risaralda">Santuario - Risaralda</option>
          </optgroup>
          <optgroup label="San Andrés y Providencia">
            <option value="Providencia - San Andrés y Providencia">Providencia - San Andrés y Providencia</option>
            <option value="San Andres - San Andrés y Providencia">San Andres - San Andrés y Providencia</option>
            <option value="Santa Catalina - San Andrés y Providencia">Santa Catalina - San Andrés y Providencia</option>
          </optgroup>
          <optgroup label="Santander">
            <option value="Aguada - Santander">Aguada - Santander</option>
            <option value="Albania - Santander">Albania - Santander</option>
            <option value="Aratoca - Santander">Aratoca - Santander</option>
            <option value="Barbosa - Santander">Barbosa - Santander</option>
            <option value="Barichara - Santander">Barichara - Santander</option>
            <option value="Barrancabermeja - Santander">Barrancabermeja - Santander</option>
            <option value="Betulia - Santander">Betulia - Santander</option>
            <option value="Bolívar - Santander">Bolívar - Santander</option>
            <option value="Bucaramanga - Santander">Bucaramanga - Santander</option>
            <option value="Cabrera - Santander">Cabrera - Santander</option>
            <option value="California - Santander">California - Santander</option>
            <option value="Capitanejo - Santander">Capitanejo - Santander</option>
            <option value="Carcasi - Santander">Carcasi - Santander</option>
            <option value="Cepita - Santander">Cepita - Santander</option>
            <option value="Cerrito - Santander">Cerrito - Santander</option>
            <option value="Charalá - Santander">Charalá - Santander</option>
            <option value="Charta - Santander">Charta - Santander</option>
            <option value="Chima - Santander">Chima - Santander</option>
            <option value="Chipatá - Santander">Chipatá - Santander</option>
            <option value="Cimitarra - Santander">Cimitarra - Santander</option>
            <option value="Concepción - Santander">Concepción - Santander</option>
            <option value="Confines - Santander">Confines - Santander</option>
            <option value="Contratación - Santander">Contratación - Santander</option>
            <option value="Coromoro - Santander">Coromoro - Santander</option>
            <option value="Curití - Santander">Curití - Santander</option>
            <option value="El Carmen - Santander">El Carmen - Santander</option>
            <option value="El Guacamayo - Santander">El Guacamayo - Santander</option>
            <option value="El Peñón - Santander">El Peñón - Santander</option>
            <option value="El Playón - Santander">El Playón - Santander</option>
            <option value="Encino - Santander">Encino - Santander</option>
            <option value="Enciso - Santander">Enciso - Santander</option>
            <option value="Florián - Santander">Florián - Santander</option>
            <option value="Floridablanca - Santander">Floridablanca - Santander</option>
            <option value="Galán - Santander">Galán - Santander</option>
            <option value="Gambita - Santander">Gambita - Santander</option>
            <option value="Girón - Santander">Girón - Santander</option>
            <option value="Guaca - Santander">Guaca - Santander</option>
            <option value="Guadalupe - Santander">Guadalupe - Santander</option>
            <option value="Guapota - Santander">Guapota - Santander</option>
            <option value="Guavatá - Santander">Guavatá - Santander</option>
            <option value="Guepsa - Santander">Guepsa - Santander</option>
            <option value="Hato - Santander">Hato - Santander</option>
            <option value="Jesús Maria - Santander">Jesús Maria - Santander</option>
            <option value="Jordán - Santander">Jordán - Santander</option>
            <option value="La Belleza - Santander">La Belleza - Santander</option>
            <option value="La Paz - Santander">La Paz - Santander</option>
            <option value="Landazuri - Santander">Landazuri - Santander</option>
            <option value="Lebrija - Santander">Lebrija - Santander</option>
            <option value="Los Santos - Santander">Los Santos - Santander</option>
            <option value="Macaravita - Santander">Macaravita - Santander</option>
            <option value="Málaga - Santander">Málaga - Santander</option>
            <option value="Matanza - Santander">Matanza - Santander</option>
            <option value="Mogotes - Santander">Mogotes - Santander</option>
            <option value="Molagavita - Santander">Molagavita - Santander</option>
            <option value="Ocamonte - Santander">Ocamonte - Santander</option>
            <option value="Oiba - Santander">Oiba - Santander</option>
            <option value="Onzaga - Santander">Onzaga - Santander</option>
            <option value="Palmar - Santander">Palmar - Santander</option>
            <option value="Palmas del Socorro - Santander">Palmas del Socorro - Santander</option>
            <option value="Páramo - Santander">Páramo - Santander</option>
            <option value="Piedecuesta - Santander">Piedecuesta - Santander</option>
            <option value="Pinchote - Santander">Pinchote - Santander</option>
            <option value="Puente Nacional - Santander">Puente Nacional - Santander</option>
            <option value="Puerto Parra - Santander">Puerto Parra - Santander</option>
            <option value="Puerto Wilches - Santander">Puerto Wilches - Santander</option>
            <option value="Rionegro - Santander">Rionegro - Santander</option>
            <option value="Sabana de Torres - Santander">Sabana de Torres - Santander</option>
            <option value="San Andrés - Santander">San Andrés - Santander</option>
            <option value="San Benito - Santander">San Benito - Santander</option>
            <option value="San Gil - Santander">San Gil - Santander</option>
            <option value="San Joaquín - Santander">San Joaquín - Santander</option>
            <option value="San José de Miranda - Santander">San José de Miranda - Santander</option>
            <option value="San Miguel - Santander">San Miguel - Santander</option>
            <option value="San Vicente de Chucurí - Santander">San Vicente de Chucurí - Santander</option>
            <option value="Santa Bárbara - Santander">Santa Bárbara - Santander</option>
            <option value="Santa Helena - Santander">Santa Helena - Santander</option>
            <option value="Simacota - Santander">Simacota - Santander</option>
            <option value="Socorro - Santander">Socorro - Santander</option>
            <option value="Suaita - Santander">Suaita - Santander</option>
            <option value="Sucre - Santander">Sucre - Santander</option>
            <option value="Surata - Santander">Surata - Santander</option>
            <option value="Tona - Santander">Tona - Santander</option>
            <option value="Valle San José - Santander">Valle San José - Santander</option>
            <option value="Vélez - Santander">Vélez - Santander</option>
            <option value="Vetas - Santander">Vetas - Santander</option>
            <option value="Villanueva - Santander">Villanueva - Santander</option>
            <option value="Zapatoca - Santander">Zapatoca - Santander</option>
          </optgroup>
          <optgroup label="Sucre">
            <option value="Buenavista - Sucre">Buenavista - Sucre</option>
            <option value="Caimito - Sucre">Caimito - Sucre</option>
            <option value="Chalán - Sucre">Chalán - Sucre</option>
            <option value="Coloso - Sucre">Coloso - Sucre</option>
            <option value="Corozal - Sucre">Corozal - Sucre</option>
            <option value="Coveñas - Sucre">Coveñas - Sucre</option>
            <option value="El Roble - Sucre">El Roble - Sucre</option>
            <option value="Galeras - Sucre">Galeras - Sucre</option>
            <option value="Guaranda - Sucre">Guaranda - Sucre</option>
            <option value="La Unión - Sucre">La Unión - Sucre</option>
            <option value="Los Palmitos - Sucre">Los Palmitos - Sucre</option>
            <option value="Majagual - Sucre">Majagual - Sucre</option>
            <option value="Morroa - Sucre">Morroa - Sucre</option>
            <option value="Ovejas - Sucre">Ovejas - Sucre</option>
            <option value="Palmito - Sucre">Palmito - Sucre</option>
            <option value="Sampues - Sucre">Sampues - Sucre</option>
            <option value="San Benito Abad - Sucre">San Benito Abad - Sucre</option>
            <option value="San Juan de Betulia - Sucre">San Juan de Betulia - Sucre</option>
            <option value="San Marcos - Sucre">San Marcos - Sucre</option>
            <option value="San Onofre - Sucre">San Onofre - Sucre</option>
            <option value="San Pedro - Sucre">San Pedro - Sucre</option>
            <option value="Sincé - Sucre">Sincé - Sucre</option>
            <option value="Sincelejo - Sucre">Sincelejo - Sucre</option>
            <option value="Sucre - Sucre">Sucre - Sucre</option>
            <option value="Tolú - Sucre">Tolú - Sucre</option>
            <option value="Toluviejo - Sucre">Toluviejo - Sucre</option>
          </optgroup>
          <optgroup label="Tolima">
            <option value="Alpujarra - Tolima">Alpujarra - Tolima</option>
            <option value="Alvarado - Tolima">Alvarado - Tolima</option>
            <option value="Ambalema - Tolima">Ambalema - Tolima</option>
            <option value="Anzoategui - Tolima">Anzoategui - Tolima</option>
            <option value="Armero (guayabal) - Tolima">Armero (guayabal) - Tolima</option>
            <option value="Ataco - Tolima">Ataco - Tolima</option>
            <option value="Cajamarca - Tolima">Cajamarca - Tolima</option>
            <option value="Carmen de Apicalá - Tolima">Carmen de Apicalá - Tolima</option>
            <option value="Casabianca - Tolima">Casabianca - Tolima</option>
            <option value="Chaparral - Tolima">Chaparral - Tolima</option>
            <option value="Chicoral - Tolima">Chicoral - Tolima</option>
            <option value="Coello - Tolima">Coello - Tolima</option>
            <option value="Coyaima - Tolima">Coyaima - Tolima</option>
            <option value="Cunday - Tolima">Cunday - Tolima</option>
            <option value="Dolores - Tolima">Dolores - Tolima</option>
            <option value="Espinal - Tolima">Espinal - Tolima</option>
            <option value="Falán - Tolima">Falán - Tolima</option>
            <option value="Flandes - Tolima">Flandes - Tolima</option>
            <option value="Fresno - Tolima">Fresno - Tolima</option>
            <option value="Guamo - Tolima">Guamo - Tolima</option>
            <option value="Herveo - Tolima">Herveo - Tolima</option>
            <option value="Honda - Tolima">Honda - Tolima</option>
            <option value="Ibagué - Tolima">Ibagué - Tolima</option>
            <option value="Icononzo - Tolima">Icononzo - Tolima</option>
            <option value="Lérida - Tolima">Lérida - Tolima</option>
            <option value="Líbano - Tolima">Líbano - Tolima</option>
            <option value="Mariquita - Tolima">Mariquita - Tolima</option>
            <option value="Melgar - Tolima">Melgar - Tolima</option>
            <option value="Murillo - Tolima">Murillo - Tolima</option>
            <option value="Natagaima - Tolima">Natagaima - Tolima</option>
            <option value="Ortega - Tolima">Ortega - Tolima</option>
            <option value="Palocabildo - Tolima">Palocabildo - Tolima</option>
            <option value="Piedras - Tolima">Piedras - Tolima</option>
            <option value="Planadas - Tolima">Planadas - Tolima</option>
            <option value="Prado - Tolima">Prado - Tolima</option>
            <option value="Piedras - Tolima">Piedras - Tolima</option>
            <option value="Purificación - Tolima">Purificación - Tolima</option>
            <option value="Rioblanco - Tolima">Rioblanco - Tolima</option>
            <option value="Roncesvalles - Tolima">Roncesvalles - Tolima</option>
            <option value="Rovira - Tolima">Rovira - Tolima</option>
            <option value="Saldaña - Tolima">Saldaña - Tolima</option>
            <option value="San Antonio - Tolima">San Antonio - Tolima</option>
            <option value="San Luis - Tolima">San Luis - Tolima</option>
            <option value="Santa Isabel - Tolima">Santa Isabel - Tolima</option>
            <option value="Suárez - Tolima">Suárez - Tolima</option>
            <option value="Valle de San Juan - Tolima">Valle de San Juan - Tolima</option>
            <option value="Venadillo - Tolima">Venadillo - Tolima</option>
            <option value="Villahermosa - Tolima">Villahermosa - Tolima</option>
            <option value="Villarrica - Tolima">Villarrica - Tolima</option>
          </optgroup>
          <optgroup label="Valle del cauca">
            <option value="Alcalá - Valle del cauca">Alcalá - Valle del cauca</option>
            <option value="Andalucía - Valle del cauca">Andalucía - Valle del cauca</option>
            <option value="Anserma Nuevo - Valle del cauca">Anserma Nuevo - Valle del cauca</option>
            <option value="Argelia - Valle del cauca">Argelia - Valle del cauca</option>
            <option value="Bolívar - Valle del cauca">Bolívar - Valle del cauca</option>
            <option value="Buenaventura - Valle del cauca">Buenaventura - Valle del cauca</option>
            <option value="Buga - Valle del cauca">Buga - Valle del cauca</option>
            <option value="Bugalagrande - Valle del cauca">Bugalagrande - Valle del cauca</option>
            <option value="Caicedonia - Valle del cauca">Caicedonia - Valle del cauca</option>
            <option value="Cali - Valle del cauca">Cali - Valle del cauca</option>
            <option value="Calima (darien) - Valle del cauca">Calima (darien) - Valle del cauca</option>
            <option value="Candelaria - Valle del cauca">Candelaria - Valle del cauca</option>
            <option value="Cartago - Valle del cauca">Cartago - Valle del cauca</option>
            <option value="Dagua - Valle del cauca">Dagua - Valle del cauca</option>
            <option value="El Aguila - Valle del cauca">El Aguila - Valle del cauca</option>
            <option value="El Cairo - Valle del cauca">El Cairo - Valle del cauca</option>
            <option value="El Cerrito - Valle del cauca">El Cerrito - Valle del cauca</option>
            <option value="El Dovio - Valle del cauca">El Dovio - Valle del cauca</option>
            <option value="Florida - Valle del cauca">Florida - Valle del cauca</option>
            <option value="Ginebra - Valle del cauca">Ginebra - Valle del cauca</option>
            <option value="Guacari - Valle del cauca">Guacari - Valle del cauca</option>
            <option value="Jamundí - Valle del cauca">Jamundí - Valle del cauca</option>
            <option value="La Cumbre - Valle del cauca">La Cumbre - Valle del cauca</option>
            <option value="La Unión - Valle del cauca">La Unión - Valle del cauca</option>
            <option value="La Victoria - Valle del cauca">La Victoria - Valle del cauca</option>
            <option value="Obando - Valle del cauca">Obando - Valle del cauca</option>
            <option value="Palmira - Valle del cauca">Palmira - Valle del cauca</option>
            <option value="Pradera - Valle del cauca">Pradera - Valle del cauca</option>
            <option value="Restrepo - Valle del cauca">Restrepo - Valle del cauca</option>
            <option value="Rio Frío - Valle del cauca">Rio Frío - Valle del cauca</option>
            <option value="Roldanillo - Valle del cauca">Roldanillo - Valle del cauca</option>
            <option value="San Pedro - Valle del cauca">San Pedro - Valle del cauca</option>
            <option value="Sevilla - Valle del cauca">Sevilla - Valle del cauca</option>
            <option value="Toro - Valle del cauca">Toro - Valle del cauca</option>
            <option value="Trujillo - Valle del cauca">Trujillo - Valle del cauca</option>
            <option value="Tulúa - Valle del cauca">Tulúa - Valle del cauca</option>
            <option value="Ulloa - Valle del cauca">Ulloa - Valle del cauca</option>
            <option value="Versalles - Valle del cauca">Versalles - Valle del cauca</option>
            <option value="Vijes - Valle del cauca">Vijes - Valle del cauca</option>
            <option value="Yotoco - Valle del cauca">Yotoco - Valle del cauca</option>
            <option value="Yumbo - Valle del cauca">Yumbo - Valle del cauca</option>
            <option value="Zarzal - Valle del cauca">Zarzal - Valle del cauca</option>
          </optgroup>
          <optgroup label="Vaupés">
            <option value="Carurú - Vaupés">Carurú - Vaupés</option>
            <option value="Mitú - Vaupés">Mitú - Vaupés</option>
            <option value="Pacoa - Vaupés">Pacoa - Vaupés</option>
            <option value="Papunaua - Vaupés">Papunaua - Vaupés</option>
            <option value="Taraira - Vaupés">Taraira - Vaupés</option>
            <option value="Yavaraté - Vaupés">Yavaraté - Vaupés</option>
          </optgroup>
          <optgroup label="Vichada">
            <option value="Cumaribo - Vichada">Cumaribo - Vichada</option>
            <option value="La Primavera - Vichada">La Primavera - Vichada</option>
            <option value="Puerto Carreño - Vichada">Puerto Carreño - Vichada</option>
            <option value="Santa Rosalia - Vichada">Santa Rosalia - Vichada</option>
          </optgroup>
        </Form.Control>
        <Form.Control.Feedback type='invalid'>Ingrese la fecha final de Cosinte</Form.Control.Feedback>
      </div>
    </Form.Group>
  )
}
