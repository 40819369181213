// import { peticionSinToken } from "../helpers/peticiones"
// import { validarEntorno } from "../helpers/validarHost"

// inital state
const initialState = {
    codigo: null,
    pestaña1: {
        estado: false,
        error: false
    },
    pestaña2: false,
    pestaña3: false,
    pestaña4: false,
    pestaña5: false
}


// types

const types = {

    proceso: '[Solicitud] Creacion de proceso',
    pestaña2: '[Solicitud] Segunda pestaña',
    pestaña3: '[Solicitud] Tercera pestaña',
    pestaña4: '[Solicitud] Cuarta pestaña',
    pestaña5: '[Solicitud] quinta pestaña'

}


export const solicitudReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.proceso:



            if (!action.payload.ok.estado) {

                // console.log(action.payload)
                state = {
                    ...state,
                    pestaña1: {
                        error: true,
                        estado: false
                    }

                }

            } else {
                state = {
                    ...state,
                    pestaña1: {
                        error: false,
                        estado: true
                    },
                    codigo: action.payload.idSolicitud
                }

            }


            return state


        case types.pestaña2:
            state = {
                ...state,
                pestaña2: true
            }

            return state

        case types.pestaña3:
            state = {
                ...state,
                pestaña3: true
            }
            return state
        case types.pestaña4:
            state = {
                ...state,
                pestaña4: true
            }
            return state
        case types.pestaña5:
            state = {
                ...state,
                pestaña5: true
            }
            return state
        default:
            return state
    }
}

//Actions

export const envioDataCrearSolicitud = (data) => {

    // console.log(data)

    return async (dispatch) => {


        dispatch({
            type: types.proceso,
            payload: data
        })

    }

}


export const envioSegundaPestaña = () => {

    return async (dispatch) => {
        dispatch({
            type: types.pestaña2
        })

    }
}


export const envioTerceraPestaña = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.pestaña3
        })
    }
}

export const envioQuintaPestaña = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.pestaña5
        })
    }
}