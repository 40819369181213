
import { validarEntorno } from "../helpers/validarHost";
// import NotificationModal from '../components/modales/NotificationModal'

const initialState = JSON.parse(localStorage.getItem('login')) || {
    logged: false,
    msg: "",
    JWT: "",
    contadorVistas: ''
}



// listo para recibir data
/* const initialState = JSON.parse(localStorage.getItem('x-token')) || {
    logged: false,
    token: ''
} */

// types
const types = {
    init: '[LOGIN] Inicio Sesion',
    cerrarSesion: '[LOGIN] Cerrar Sesion',
    sumarVista: 'SUMAR_VISTA'
}

// reducer
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.init:


            /*   state = {
                logged: true,
                token: action.payload.JWT
            }
 */

            state = {
                ...state,
                ...action.payload
            }

            return state


        case types.cerrarSesion:

            state = {

                logged: action.payload.logged,
                msg: "",
                JWT: ""

            }
            return state
        
        case types.sumarVista:
            state = {
                ...state,
                contadorVistas: state.contadorVistas + 1

            }
            return state

        default:
            return state
    }
}


export const inicioSesion = (respuestaLoginData) => {

    return async (dispatch) => {


        dispatch({
            type: types.init,
            payload: respuestaLoginData
        })
    }
}

export const vistasMultimedia = () => {

    return async (dispatch) => {


        dispatch({
            type: types.sumarVista
            
        })
    }
}




export const cerrarSesion = () => {
    return async (dispatch) => {

        const respuesta = await validarEntorno('JSON/respuestaLogout_json.html')

        if (respuesta.ok) {
            const respuestaLogout = await respuesta.json()

            localStorage.clear()
            dispatch({
                type: types.cerrarSesion,
                payload: respuestaLogout
            })
        }
    }
}

