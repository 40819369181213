import React from 'react';
import { Fragment } from 'react';
import { getBase64 } from '../../../helpers/fileToBase64';
import { Form } from 'react-bootstrap';
import { DropzoneArea } from 'material-ui-dropzone'
import './FormObs.scss';
import { useTranslation } from 'react-i18next';
import Check from '../../common/Check';

const FormObsThird = ({ data, setData, validatedFile, setValidatedFile }) => {

  const { t } = useTranslation();
  const handleChangeData = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
    console.log(data)
  }

  const handleImageData = async (file) => {

    console.log(file)

    let imageB64 = '';

    if (file.length > 0) {
      imageB64 = await getBase64(file[0]);

      setData({
        ...data,
        imagen: imageB64
      });

      setValidatedFile(false);
    }
  }

  const deleteImageData = (file) => {
    setData({
      ...data,
      imagen: ''
    });
  }

  return (
    <Fragment>
      {/*
      <div className='d-flex justify-content-end'>
        <div className='d-flex align-items-center justify-content-center gap-2 pr-2'>
          <Form.Label>{t('formulario.label.activo')}</Form.Label>
          <Check
            performInitialUpdate={true}
            onChange={(e) => handleChangeData(e)}
            name="activo" />
        </div>
      </div>
      */}
      <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
        <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="descripcion"
          onChange={handleChangeData}
          className="audit-form-textarea"
          required={true}
          isInvalid={!data?.descripcion || data?.descripcion === '' && true}
          isValid={data?.descripcion !== '' && true}

        />
        <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
        <Form.Label>{t('formulario.label.imagen')}</Form.Label>
        <DropzoneArea
          acceptedFiles={['image/*']}
          dropzoneText={t('formulario.label.arrastra_suelta_imagen')}
          name="imagen"
          onChange={(file) => handleImageData(file)}
          filesLimit={1}
          showAlerts={false}
          onDelete={(file) => { deleteImageData(file) }}
          dropzoneClass={validatedFile ? 'validated-dropzone' : null}
        />
        {validatedFile ? (<p className="invalid-feedback">{t('formulario.error.campo_obligatorio')}</p>) : null}
      </Form.Group>
      {data?.imagen?.length > 0 &&
        <Form.Group className="form-modal-audit" controlId="exampleForm.ControlTextarea1">
          <Form.Label>{t('formulario.label.pie_imagen')}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="pie_imagen"
            onChange={handleChangeData}
            className="audit-form-textarea"
            required={true}
            isInvalid={!data?.pie_imagen || data?.pie_imagen === '' && true}
            isValid={data?.pie_imagen !== '' && true}
          />
          <Form.Control.Feedback type="invalid">{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
        </Form.Group>
      }
    </Fragment>
  );
}

export default FormObsThird;
