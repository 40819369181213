import React, { Fragment, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';


export const Alertas = ({ seccion }) => {

    // console.log(seccion)
    const [fileInputs, setFileInputs] = useState(false)

    const handleChange = (e) => {

        // console.log(e)
    }

    const handleFile = (e) => {
        setFileInputs(true)
    }

    return (
        <Fragment>
            <h5 className='titleInsideCard'>Alertas</h5>
            <Card.Body>
                <div className='formEstudioBasicoContainer' >
                    <div className='formItem' id='basicFormA'>
                        <Form.Group>
                            <Form.Label>
                                Fecha
                                        </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Link
                                        </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='link' required type='text' placeholder='Link' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Fuente
                            </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='link' required type='text' placeholder='Fuente' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Prioridad</Form.Label>
                            <div className='inputContainer'>
                                <Form.Control
                                    name="prioridad"
                                    onChange={handleChange}
                                    required={true}
                                    as='select'
                                    type='text'
                                >
                                    <option >Alto</option>
                                    <option >Medio</option>
                                    <option >Bajo</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </div>
                    <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>


                        {
                            seccion === "Orden público"
                            &&
                            <Form.Group >
                                <Form.Label>Tipo de Alteración al orden Público</Form.Label>
                                <div className='inputContainer'>
                                    <Form.Control
                                        name="prioridad"
                                        onChange={handleChange}
                                        required={true}
                                        as='select'
                                        type='text'
                                    >
                                        <option >Seleccione</option>
                                        <option >Delincuencia</option>
                                        <option >Protesta</option>
                                        <option >Subversión</option>
                                        <option >Otro</option>

                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        }


                        {
                            seccion === "Presencia e influencia de actores al margen de la Ley (GAO-GDO-GAOR)"
                            &&
                            <Form.Group >
                                <Form.Label>Actores al Margen de la Ley</Form.Label>
                                <div className='inputContainer'>
                                    <Form.Control
                                        name="prioridad"
                                        onChange={handleChange}
                                        required={true}
                                        as='select'
                                        type='text'
                                    >
                                        <option >Seleccione</option>
                                        <option >GAO</option>
                                        <option >GDO</option>
                                        <option >GAOR</option>

                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        }

                        {
                            seccion === "Índice de aceptación de la Fuerza Pública" || seccion === "Presencia e influencia de la Fuerza Pública en el territorio"
                            && <Form.Group >
                                <Form.Label>Fuerza Pública</Form.Label>
                                <div className='inputContainer'>
                                    <Form.Control
                                        name="prioridad"
                                        onChange={handleChange}
                                        required={true}
                                        as='select'
                                        type='text'
                                    >
                                        <option >Seleccione</option>
                                        <option >Armada</option>
                                        <option >Polícia</option>
                                        <option >Ejercito</option>
                                        <option >Fuerza Aérea</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        }

                        {
                            seccion === "Histórico de posición política de los alcaldes en cuanto al sector de hidrocarburos"
                            && <Form.Group >
                                <Form.Label>Partido Político</Form.Label>
                                <div className='inputContainer'>
                                    <Form.Control
                                        name="prioridad"
                                        onChange={handleChange}
                                        required={true}
                                        as='select'
                                        type='text'
                                    >
                                        <option >Seleccione</option>
                                        <option >Medio</option>
                                        <option >Bajo</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        }

                        <Form.Group>
                            <Form.Label>
                                Archivos soporte
                                        </Form.Label>
                            <div className='addButtonContainer' style={{ cursor: 'pointer', width: '100%', marginTop: '-25px', paddingTop: '0px' }} >
                                <input id='selectFile' className='selectFile' name='selectFile' type='file' onChange={handleFile} style={{ cursor: 'pointer' }} />
                                <label id='addButton' htmlFor='selectFile' style={{ cursor: 'pointer' }} >
                                    Añadir documento
                                    <AddIcon />
                                </label>
                            </div>
                        </Form.Group>
                        {
                            fileInputs
                                ?
                                <Fragment>
                                    <Form.Group>
                                        <Form.Label>
                                            Fecha documento
                                        </Form.Label>
                                        <div className='inputContainer'>

                                            <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                    <Form.Group id='observacionesFormGroup'>
                                        <Form.Label className='observacionesLabel'>
                                            Descripción Archivo
                                        </Form.Label>
                                        <div className='textAreaContainer'>
                                            <Form.Control name='descripcionFile' id='observacionesTextArea' maxLength={2000} style={{ height: '50px' }} required as='textarea' placeholder='Observaciones'></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa la descripcion</Form.Control.Feedback>
                                        </div>
                                    </Form.Group>


                                </Fragment>
                                : <Form.Group id='observacionesFormGroup'>
                                    <Form.Label className='observacionesLabel'>
                                        Descripción
                                </Form.Label>
                                    <div className='textAreaContainer'>
                                        <Form.Control onChange={handleChange} style={{ height: '70px' }} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                        }

                    </div>
                    {
                        fileInputs
                        &&
                        <div id='observacionesContainerEstudioBasico'>
                            <Form.Group id='observacionesFormGroup'>
                                <Form.Label className='observacionesLabel'>
                                    Descripción
                                        </Form.Label>
                                <div className='textAreaContainer'>
                                    <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    }
                </div>
            </Card.Body>
        </Fragment>
    )
}
