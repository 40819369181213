import React, { useState, useEffect, useLayoutEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import CancelIcon from "@material-ui/icons/Cancel";
// import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from "@material-ui/lab/TimelineDot";
// import FastfoodIcon from '@material-ui/icons/Fastfood';
// import LaptopMacIcon from '@material-ui/icons/LaptopMac';
// import HotelIcon from '@material-ui/icons/Hotel';
// import RepeatIcon from '@material-ui/icons/Repeat';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
import moment from "moment";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import "./TimelineMaterial.scss";
import GraficaConsolidado from "./GraficaConsolidado";
import { HelpSVG } from "../../components/icons/HelpSVG";
import { Col, Row, Modal, Card } from 'react-bootstrap';
import { IconSVG } from "../icons/CloseSVG";
import PopUpModal from "../../helpers/PopUpModal";
import ModalHelp from "../../helpers/ModalHelp";
// import { Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  overrides: {
    missingOppositeContent: {
      "&:before": {
        display: "none",
      },
    },
  },
}));

const TimelineMaterial = (props) => {
  const [arrColors, useArrColors] = useState([]);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  // console.log(props)

  const getIcon = (tipo) => {
    switch (tipo) {
      case "completo":
        return (
          <AssignmentTurnedInIcon
            style={{ color: "#1D3557" }}
            fontSize="small"
          />
        );
      case "pendiente":
        return (
          <ArrowForwardIosIcon style={{ color: "#ffb703" }} fontSize="small" />
        );
      case "vencido":
        return <CancelIcon style={{ color: "#fb8500" }} fontSize="small" />;
      case "objetivo":
        return <PlaylistAddCheckIcon fontSize="small" />;
      case "meta":
        return <ArrowRightAltIcon fontSize="small" />;
      case "tarea":
        return <AssignmentTurnedInIcon fontSize="small" />;
      default:
        return <ArrowForwardIosIcon fontSize="small" />;
    }
  };

  const colors = ["#33658A", "#86BBD8", "#2F4858", "#F6AE2D", "#F26419"];

  const randomColor = (length) => {
    let arrayColores = [];

    for (let index = 0; index < props?.data?.length; index++) {
      let x = arrayColores.push(
        colors[Math.floor(Math.random() * (4 - 0 + 1))]
      );
    }

    return arrayColores[Math.floor(Math.random(0, arrayColores?.length))];
  };

  useLayoutEffect(() => {
    randomColor();
  }, [props.data]);

  useEffect(() => { }, [props.data]);

  function openModal() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    //overflowY: "auto", height: 'auto',
    //overflowY:"auto",

    <>
      <div className="cardUnderPestana" style={{ padding: '0% 6%', marginTop: "0%", marginLeft: "0%", maxWidth: '100%', paddingLeft: '0%' }}>
        <Card.Body style={{ minHeight: '30vh', minWidth: '106%' }} >

          <Card className="shadow"
            style={{ height: "auto", marginBottom: "03%", maxWidth: '30vw' }}
          >

            <Card.Body>
              <ModalHelp />
              <div style={{ width: "95%" }}>
                <h4
                  className="pagesTitles"
                  style={{ textAlign: "center", width: "100%", height: "auto", minHeight: "28px" }}
                >
                  {props.title}{" "}
                </h4>
              </div>
            </Card.Body>

          </Card>
          {/* <div style={{ width: "100%", maxHeight: "100vh",
    overflowY: "auto", height: "auto", overflowY:"auto"
    }}> */}

          {/* <Modal
        className="loginModal modalesGenerales"
        show={open}
        centered
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <h4 className="pagesTitles">Estado de Actividades</h4>
          </Modal.Title>
          <div onClick={handleClose}>
            <IconSVG />
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="leyendas-item" style={{display: "flex"}}>
        <div style={{margin: "0px 5px 5px 0px", minWidth: "20px", minHeight: "20px", backgroundColor: "#8BC34A"}}> </div>
        Completo
        </div>

        <div className="leyendas-item" style={{display: "flex"}}>
        <div style={{margin: "0px 5px 5px 0px", minWidth: "20px", minHeight: "20px", backgroundColor: "#F08700"}}> </div>
        Pendiente Cliente
        </div>
        <div className="leyendas-item" style={{display: "flex"}}>
        <div style={{margin: "0px 5px 0px 0px", minWidth: "20px", minHeight: "20px", backgroundColor: "#F9CF00"}}> </div>
        En Proceso
        </div>
        </Modal.Body>
      </Modal>

      <div
        style={{ position: "absolute", right: 50, cursor: "pointer", paddingRight:'3%'}}
        onClick={() => {
          openModal();
        }}
      >
        <HelpSVG />
      </div> */}
          {/* <ModalHelp/>
      <h4
        className="pagesTitles"
        style={{ textAlign: "center", width: "100%", margin: "20px auto " }}
      >

        {props.title}{" "}
      </h4> */}
          <Card className='shadow cardUnderPestana' style={{ marginTop: "3%", overflow: 'auto', maxHeight: '81vh', width: '55vh' }}>
            <Card.Body style={{ display: "flex", overflowY: "auto", overflow: "auto"}}>
              <Timeline align="left" positionLeft>
                {props?.data?.length > 0 &&
                  props?.data?.map((item) => {
                    return (
                      <TimelineItem className="TLItem" style={{ minHeight: 'auto' }}>
                        <TimelineSeparator>
                          <TimelineDot
                            style={{ backgroundColor: "#f2f2f2", color: "#636363" }}
                          > {getIcon(item.tipo)}
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <div
                            className="TLItem-box_title"
                            style={{ backgroundColor: item.color, height: "auto" }}
                          >
                            <p>{item.titulo ? item.titulo : null}</p>
                          </div>
                          <div elevation={2} className="TLItem-box" >
                            <p className="TLItem-box_text" style={{ height: "auto", maxHeight: "auto", overflowY: 'auto' }}>{item.text} </p>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
              </Timeline>
            </Card.Body>
          </Card>
          {/* </div> */}
        </Card.Body>
      </div>

    </>
  );
};

export default TimelineMaterial;
