import React,  { useState, useEffect, Fragment } from 'react';
import { Col, Form } from "react-bootstrap";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { BtnAyuda } from "./BtnAyuda";

function MultipleText({ item, index, idx, reg, nombre, error, esEditable, inputList, setInputList }) {

  // const [inputList, setInputList] = useState([{ valor: "", valido: false }]);
  let re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

  const [validated, setValidated] = useState(false);

  const handleRemoveClick = (index, e) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    console.log(list)

  };

  const handleAddClick = (e, index) => {
    let valorKey = `asesoria.${inputList.length}.${item.nombre}`
    setInputList([...inputList, {valido: false,  [valorKey] : '' }]);

  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    let valorKey = `asesoria.${index}.${item.nombre}`

    const reg = {valido : validarDominio(value, index), [valorKey]: value }
    
    list[index]= reg;

    setInputList(list);
    handleSubmit(e, index);

  };

  const handleSubmit = (event, index) => {

    const form = event.target;
    // console.log(form)
    if (form) {
      event.preventDefault();
      event.stopPropagation();
    }

    validarDominio(event.target.value, index)
    setValidated(true)

  }

  useEffect(() => {
    if(item.valor.length){

      
      const mapValues = item.valor.map((valor, index) => {
        let valorKey = `asesoria.${index}.${item.nombre}`

        // let re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

        let findDominio = re.test(valor)
        // console.log(findDominio2);

        // let findDominio = dominios.some((dominio) => dominio === valor.substring(valor.indexOf('@')).slice(1) )

        return { 'valido' : findDominio , [valorKey] : valor}
      
      })



      setInputList(mapValues)
    }
  }, []);

  const dominios = [
    "cosinte.co",
    "cosinte.com",
    "cosinte.com.co",
    "outlook.com",
    "hotmail.com",
    "gmail.com",
    "yahoo.com"
  ]

  const validarDominio = (valor, index) => {

    let findDominio = re.test(valor)


    // const findDominio = dominios.some((dominio) => dominio === valor.substring(valor.indexOf('@')).slice(1) )
    // console.log(findDominio)
    let listaValores = {...inputList}

    if(findDominio){
      listaValores[index].valido = true
    }else{
      listaValores[index].valido = false
    }
    return findDominio
  }


  return (
    <div>
    {inputList.map((campo, index) => (
      <Fragment key={index}>
          <Form.Group  style={{ display: 'block' }} >
            <div style={{display: 'flex', justifyContent: 'flex-start'}} >
              <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : ""  }}>{ item.titulo }{item.es_requerido === true && '*'}</Form.Label>
              {item.ayuda &&
                <BtnAyuda ayuda={item.ayuda}/>
              }
            </div>
            <Form.Control
              // className="audit-form-textarea"
              key={`${index} - ${idx}`}
              // name='valor'
              name={`asesoria.${index}.${item.nombre}`}
              ref={reg}
              {...reg(`asesoria.${index}.${item.nombre}`, {
                  required: item.es_requerido
              })}
              // value={campo.asesoria[index][item.nombre]}
              value={Object.entries(campo)[1][1] === false ? '' : Object.entries(campo)[1][1]}
              onChange={e => handleInputChange(e, index)}
              style={{ border: error ? '1px solid #dc3545' : null }}
              disabled={esEditable}
            />

            {item.nombre == 'correo' &&  campo.valido === false &&
              <Form.Control.Feedback type='invalid' style={{display: 'block'}} >
                {/* {campo.valor === '' && 'Este campo es obligatorio. '} */}
                Este correo es inválido
              </Form.Control.Feedback>
            }
            {item.nombre == 'correo' && campo.valido === true &&
              <Form.Control.Feedback type='valid' style={{display: 'block'}} >
                ✔
              </Form.Control.Feedback>
            }
            {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={(e) => handleRemoveClick(index, e)} />}
            {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={(e) => handleAddClick(e, index)} />}
          </Form.Group>
      </Fragment>
    ))}
    </div>
  );
}

export { MultipleText }
