import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Heatmap from '../../../../../helpers/Mapas/Descripcion/Heatmap';
import Loader from '../../../../icons/Loader';
import { credenciales } from '../../../../../helpers/credentials.google';
import { validarEntorno } from '../../../../../helpers/validarHost';
import { municipios } from '../../../../../helpers/municipios';
import HeatmapActivities from '../../../../../helpers/Mapas/Descripcion/HeatmapActivities';
import NotificationModal from '../../../../modales/NotificationModal';
import Select from 'react-select'

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalPanorama from '../../../../modales/ModalPanorama';
import ModalActividades from '../../../../modales/ModalActividades';
import { DropzoneArea } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';

const FormAmenazas = ({ factor, idPanorama, permisos, idSubseccion, dataSelect, dataSelect2 }) => {
  const { t } = useTranslation();
  const [regToDel, setRegToDel] = useState({})
  const [actToDel, setActToDel] = useState({})
  const [switchSeccion, setSwitchSeccion] = useState(permisos.registros ? 'registros' : 'acciones')
  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })
  const [addSolucion, setAddSolucion] = useState(false);
  const [addUbicacion, setAddUbicacion] = useState(false);
  const [addActividad, setAddActividad] = useState(false);
  const [addCargueMasivo, setAddCargueMasivo] = useState(false);
  const [addCargueActividad, setAddCargueActividad] = useState(false);
  const [typeModalNotification, setTypeModalNotification] = useState('');
  const [addUbicacionActividad, setAddUbicacionActividad] = useState(false);
  const [inputList, setInputList] = useState([{ dispositivo: "", composicion: "", fuerza: "", ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
  const [accionesList, setAccionesList] = useState([{ autor: "", descripcion_principal_act: "", fecha_act: "", accion_amenaza: "", afectados: "", ubicaciones_act: [{ setOpenMdlAct: false, descripcion: "", tipo_mapa_act: "", coords_act: [] }] }]);
  const [stateInforme, setStateInforme] = useState({});
  const [tipoMapa, setTipoMapa] = useState('');
  const [tipoMapaActividad, setTipoMapaActividad] = useState('');
  const [isForm, setIsForm] = useState(false);
  const [coord, setCoord] = useState({
    lat: 0,
    lng: 0
  });
  const [coordAct, setCoordAct] = useState({
    lat: 0,
    lng: 0
  });
  const [indexEdit, setIndexEdit] = useState('');
  const [coordEdit, setCoordEdit] = useState({
    lat: '',
    lng: ''
  });
  const [invalidLat, setInvalidLat] = useState(false);
  const [invalidLng, setInvalidLng] = useState(false);
  const [indicador, setIndicador] = useState('');
  const [formNumber, setFormNumber] = useState('');
  const [formNumberAct, setFormNumberAct] = useState('');
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  // const [ showDescriptionModalAct, setShowDescriptionModalAct ] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [latAct, setLatAct] = useState('');
  const [lngAct, setLngAct] = useState('');
  const [seccionSelectValues, setSeccionSelectValues] = useState([]);
  const [accionesAmenazas, setAccionesAmenazas] = useState([]);
  // const [ tipoUbicacion, setTipoUbicacion ] = useState('');
  const [showCreateModalNotification, setShowCreateModalNotification] = useState(false);
  const [showErrorModalNotification, setErrorCreateModalNotification] = useState(false)
  const [messageNotification, setMessageNotification] = useState('');
  const [showEditDeleteModalNotification, setShowEditDeleteModalNotification] = useState(false);
  const [messageShowEditDeleteNotification, setMessageEditDeleteNotification] = useState('');
  const [typeNotification, setTypeNotification] = useState('');
  const [itemToErase, setItemToErase] = useState('');
  const [indexDelete, setIndexDelete] = useState('');
  const [typeOperation, setTypeOperation] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [seccionPoligono, setSeccionPoligono] = useState([]);
  const [archivoCargue, setArchivoCargue] = useState({ archivo: [], nombre: '', descripcion: '' });

  useEffect(() => {
    cleanComponent();
    getSeccionSelectValues();
    // getAccionesAmenazas();
    getRegistrosSeccion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSubseccion]);

  useEffect(() => {
    transformOptsAutoresAmenazas(dataSelect2)
  }, [dataSelect2])


  const handleCloseCreateModalNotification = () => setShowCreateModalNotification(false);
  const handleCloseErrorModalNotification = () => setErrorCreateModalNotification(false);

  const handleCloseModalEditDeleteNotification = () => {
    setShowEditDeleteModalNotification(false);
    setMessageEditDeleteNotification('');
    setTypeNotification('');
    setItemToErase('');
    setTypeOperation('')
  }

  const iconos = {
    hoja_calculo: {
      icono: 'https://www.ciacosinte.site/cia_2/build/static/media/sheets.svg'
    }
  }

  const [puntosCalor, setPuntosCalor] = useState([
    {
      "locacion": "cali",
      "tipo": "cultivo_ilicito",
      "coord": {
        "lat": 3.4334185,
        "lng": -76.4517656
      },
      "titulo": "Disturbios en Cali dejan un muerto y policías y manifestantes heridos",
      "descripcion": "Los disturbios se prolongaron hasta la noche y dejaron un hombre de 42 años muerto por arma de fuego, tres policías lesionados y varios manifestantes detenidos. El comandante de la Policía Metropolitana de Cali, brigadier general Juan Carlos León, afirmó que la persona que falleció llegó sin signos vitales a un centro médico."

    },
    {
      "locacion": "Pasto",
      "tipo": "cultivo_ilicito",
      "coord": {
        "lat": 1.2047533,
        "lng": -77.2699356
      }
    },
    {
      "locacion": "Medellin",
      "tipo": "atentado",
      "coord": {
        "lat": 6.2338284,
        "lng": -75.5758217
      }
    },
    {
      "locacion": "Barranquilla",
      "tipo": "atentado",
      "coord": {
        "lat": 11.0041324,
        "lng": -74.81128
      },
      "titulo": "Ejemplo titulo quilla",
      "descripcion": "Tronco e calor valecita  "

    }
  ]);

  const ubicacionBase = {
    lat: 1.206845,
    lng: -77.2756767
  };

  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;
  const hash = window.location.hash;

  const [autoresAmenazas, setAutoresAmenazas] = useState([])

  const transformOptsAutoresAmenazas = (arr) => {
    if (arr.length) {
      const converted = arr.map((ele) => { return ({ 'label': ele.valor, 'value': ele.valor }) })

      console.log(converted)
      setAutoresAmenazas(converted)
    }
  }

  //Inicio Funciones para conceptos

  const handleInputChange = (e, index, idx) => {

    const { name, value } = e.target;
    const list = [...inputList];
    const obj = { ...list[index] };
    obj[name] = value;
    list[index] = obj;
    setInputList(list);

    setStateInforme({
      ...stateInforme,
      [e.target.name]: e.target.value
    })

    console.log(stateInforme)

    switch (e.target.value) {
      case 'puntos_calor':
        setTipoMapa('mapaCalor')
        break;
      case 'trazado':
        setTipoMapa('trazado')

        break;
      case 'puntos_simples':
        setTipoMapa('simples')
        break;
      default:
        break;
    }
  };

  const handleInputChangeUbi = (e, index, idx) => {
    const { value } = e.target;
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    // console.log(ubi, formNumber);
    ubi.tipo_mapa = value;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
  }

  const handleAddClick = () => {
    setInputList([...inputList, { dispositivo: "", composicion: "", fuerza: "", addUbicacion: false, ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClickUbi = (index) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    if (ubi.length >= 1) {
      const ubic = { setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] };
      ubi.push(ubic);
      item.ubicaciones = ubi;
      item.addUbicacion = true;
      list[index] = item;
      setInputList(list);
    }
  }

  const handleRemoveClickUbi = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    ubi.splice(idx, 1);
    item.ubicaciones = ubi;
    list[index] = item;
    setInputList(list);
  }

  const handleInputChangeOpenModalActForm = (e, index, idx) => {
    e.preventDefault();
    const list = [...accionesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdlAct = !ubi.setOpenMdlAct;
    ubicaciones[idx] = ubi;
    obj.ubicaciones_act = ubicaciones;
    list[index] = obj;
    setFormNumberAct(idx);
    setAccionesList(list);
    setIsForm(true);
  }

  const handleInputChangeOpenModal = (index, idx) => {
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
  }

  const addRemoveUbi = (index) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones];
    if (!addUbicacion) {
      if (ubi.length > 1) {
        ubi[0].addUbicacion = !ubi[0].addUbicacion;
        item.ubicaciones = ubi;
        list[index] = item;
        setInputList(list);
      }
      setAddUbicacion(true);
      setTipoMapa('');
      setIndicador(0);
    } else {
      setAddUbicacion(!addUbicacion);
    }
  }

  const getCoordinates = (lat, lng, index, descripcion) => {
    if ((indicador === index)) {
      const list = [...inputList];
      const obj = { ...list[index] };
      const ubicaciones = [...obj.ubicaciones];
      const ubi = { ...ubicaciones[formNumber] };
      const coords = [...ubi.coords];
      const crd = {
        lat: lat,
        lng: lng,
        descripcion
      };
      coords.push(crd);
      ubi.coords = coords;
      ubi.setOpenMdl = false;
      ubicaciones[formNumber] = ubi;
      obj.ubicaciones = ubicaciones;
      list[index] = obj;
      setInputList(list);
    } else {
      setIndicador(index);
    }
    setIsForm(false);
  }

  const handleLatLng = (e) => {
    const { name, value } = e.target;

    setCoord({
      ...coord,
      [name]: parseFloat(value)
    })
  }

  const showModal = (e, index, idx) => {

    e.preventDefault();

    if ((!Number.isNaN(coord.lat) && coord.lat !== 0) && (!Number.isNaN(coord.lng) && coord.lng !== 0)) {
      setShowDescriptionModal(true);
      setIndicador(index);
      setIsForm(true);
      setFormNumber(idx);
    } else {
      if (Number.isNaN(coord.lat) || coord.lat === 0) {
        setInvalidLat(true);
      } else {
        setInvalidLat(false);
      }

      if (Number.isNaN(coord.lng) || coord.lng === 0) {
        setInvalidLng(true);
      } else {
        setInvalidLng(false);
      }
    }
  }

  const submitLatLng = (e, index, descripcion) => {
    e.preventDefault();
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[formNumber] };
    const coords = [...ubi.coords];
    const crd = {
      lat: coord.lat,
      lng: coord.lng,
      descripcion
    }
    coords.push(crd);
    ubi.coords = coords;
    ubi.setOpenMdl = false;
    ubi.descripcion = descripcion;
    ubicaciones[formNumber] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setInputList(list);
    setCoord({
      lat: 0,
      lng: 0
    });
    const lat = document.getElementById("input-lat");
    const lng = document.getElementById("input-lng");

    lat.value = 0;
    lng.value = 0;
    setIsForm(false);
  }


  const getSeccionSelectValues = async () => {
    const dataReq = {
      hash: hash,
      id_subseccion: idSubseccion
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/get_subsecciones.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json();
      setSeccionSelectValues(res.data);
    }
  }

  const getAccionesAmenazas = async () => {
    const dataReq = {
      hash: hash,
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/acciones_amenazas.html', 'POST', dataReq)

    // console.log(req)

    if (req.ok) {
      const res = await req.json();

      // console.log(res)
      setAccionesAmenazas(res.data);
    }
  }


  //Cierre Funciones para conceptos

  //Inicio Funciones para actividades

  const getActividadToDelete = (item) => {
    setActToDel(item)
  }

  const handleInputChangeAct = (e, index, idx) => {

    const { name, value } = e.target;
    const list = [...accionesList];
    const obj = { ...list[index] };
    obj[name] = value;
    list[index] = obj;
    setAccionesList(list);

    setStateInforme({
      ...stateInforme,
      [e.target.name]: e.target.value
    })
    switch (e.target.value) {
      case 'puntos_calor':
        setTipoMapaActividad('mapaCalor')
        break;
      case 'trazado':
        setTipoMapaActividad('trazado')
        break;
      case 'puntos_simples':
        setTipoMapaActividad('simples')
        break;
      default:

        break;
    }

    console.log(stateInforme)
  };

  const handleInputChangeUbiAct = (e, index, idx) => {
    const { value } = e.target;
    const list = [...accionesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    ubi.tipo_mapa_act = value;
    ubicaciones[idx] = ubi;
    obj.ubicaciones_act = ubicaciones;
    list[index] = obj;
    setAccionesList(list);
  }

  const handleAddClickAct = () => {
    setAccionesList([...accionesList, { descripcion_principal_act: "", addUbicacionAct: false, fecha_act: "", accion_amenaza: "", afectados: "", ubicaciones_act: [{ setOpenMdlAct: false, descripcion: "", tipo_mapa_act: "", coords_act: [] }] }]);
  };

  const handleRemoveClickAct = index => {
    const list = [...accionesList];
    list.splice(index, 1);
    setAccionesList(list);
  };

  const handleAddClickUbiAct = (index) => {
    const list = [...accionesList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones_act];
    if (ubi.length >= 1) {
      const ubic = { setOpenMdlAct: false, descripcion: "", tipo_mapa_act: "", coords_act: [] };
      ubi.push(ubic);
      item.ubicaciones_act = ubi;
      item.addUbicacion = true;;
      list[index] = item;
      setAccionesList(list);
    }
  }

  const handleRemoveClickUbiAct = (index, idx) => {
    const list = [...accionesList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones_act];
    ubi.splice(idx, 1);
    item.ubicaciones = ubi;
    list[index] = item;
    setAccionesList(list);
  }

  const addRemoveUbiAct = (index) => {
    const list = [...accionesList];
    const item = { ...list[index] };
    const ubi = [...item.ubicaciones_act];
    if (!addUbicacionActividad) {
      if (ubi.length >= 1) {
        item.ubicaciones_act = ubi;
        list[index] = item;
        // setAddUbicacion(true);
        setAccionesList(list);
      }
      setAddUbicacionActividad(true);
      setTipoMapaActividad('');
      setIndicador(0);
    } else {
      setAddUbicacionActividad(false);
    }
  }

  const getCoordinatesAct = (lat, lng, index, descripcion) => {
    if ((indicador === index)) {
      const list = [...accionesList];
      console.log(list);
      const obj = { ...list[index] };
      const ubicaciones = [...obj.ubicaciones_act];
      const ubi = { ...ubicaciones[formNumberAct] };
      const coords = [...ubi.coords_act];
      const crd = {
        lat: lat,
        lng: lng,
        descripcion
      };
      coords.push(crd);
      ubi.coords_act = coords;
      ubi.setOpenMdlAct = false;
      ubicaciones[formNumberAct] = ubi;
      obj.ubicaciones_act = ubicaciones;
      list[index] = obj;
      setAccionesList(list);
    } else {
      setIndicador(index);
    }
    setIsForm(false);
  }

  const handleLatLngAct = (e) => {
    const { name, value } = e.target;

    setCoordAct({
      ...coordAct,
      [name]: parseFloat(value)
    })
  }

  const showModalAct = (e, index, idx) => {

    e.preventDefault();

    console.log(index, idx);

    if ((!Number.isNaN(coord.lat) && coord.lat !== 0) && (!Number.isNaN(coord.lng) && coord.lng !== 0)) {
      setShowDescriptionModal(true);
      setIndicador(index);
      setIsForm(true);
      setFormNumberAct(idx);
    } else {
      if (Number.isNaN(coord.lat) || coord.lat === 0) {
        setInvalidLat(true);
      } else {
        setInvalidLat(false);
      }

      if (Number.isNaN(coord.lng) || coord.lng === 0) {
        setInvalidLng(true);
      } else {
        setInvalidLng(false);
      }
    }
  }

  const submitLatLngAct = (e, index, descripcion) => {
    e.preventDefault();
    const list = [...accionesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[formNumberAct] };
    const coords = [...ubi.coords_act];
    const crd = {
      lat: coordAct.lat,
      lng: coordAct.lng,
      descripcion
    }
    coords.push(crd);
    ubi.coords_act = coords;
    ubi.setOpenMdlAct = false;
    ubicaciones[formNumberAct] = ubi;
    obj.ubicaciones_act = ubicaciones;
    ubi.descripcion = descripcion;
    list[index] = obj;
    setAccionesList(list);
    setInvalidLat(false);
    setInvalidLng(false);
    setCoord({
      lat: 0,
      lng: 0
    });
    const lat = document.getElementById("input-lat");
    const lng = document.getElementById("input-lng");

    lat.value = 0;
    lng.value = 0;
    setIsForm(false);
  }

  const handleInputChangeOpenModalAct = (index, idx) => {
    const list = [...accionesList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    console.log(ubi);
    ubi.setOpenMdlAct = !ubi.setOpenMdlAct;
    ubicaciones[idx] = ubi;
    obj.ubicaciones_act = ubicaciones;
    list[index] = obj;
    setAccionesList(list);
  }

  //Cierre Funciones para actividades

  const cleanComponent = () => {
    setAddSolucion(false);
    setAddUbicacion(false);
    setAddActividad(false);
    setAddUbicacionActividad(false);
    setInputList([{ dispositivo: "", composicion: "", fuerza: "", jurisdiccion: "", addUbicacion: false, ubicaciones: [{ setOpenMdl: false, descripcion: "", tipo_mapa: "", coords: [] }] }]);
    setAccionesList([{ descripcion_principal_act: "", fecha_act: "", accion_amenaza: "", afectados: "", ubicaciones_act: [{ setOpenMdlAct: false, descripcion: "", tipo_mapa_act: "", coords_act: [] }] }]);
    setStateInforme({});
    setTipoMapa('');
    setTipoMapaActividad('');
    setIsForm(false);
    setCoord({ lat: 0, lng: 0 });
    setInvalidLat(false);
    setInvalidLng(false);
    setIndicador('');
    setFormNumber(0);
    setFormNumberAct(0);
    setShowDescriptionModal(false);
    setLat('');
    setLng('');
  }

  const enviarDatosFormulario = async () => {

    let acciones = accionesList
    acciones[0].autor = stateInforme.autor

    // setAccionesList({...accionesList, accionesList[0].autor: stateInforme.autor})

    // let acciones = []

    // if(stateInforme.autor){
    //     // accionesList.push(stateInforme.autor)
    // }


    const dataReq = {
      conceptos: addSolucion ? inputList : {},
      acciones: addActividad ? acciones : {},
      // autor: stateInforme.autor ? stateInforme.autor : null,
      hash,
      tipo: "amenazas",
      id_subseccion: idSubseccion
    }

    console.log(dataReq);

    const req = await validarEntorno('JSON/panoramaRiesgos/respuesta_formulario_factores.html', 'POST', dataReq);

    const res = await req.json()

    if (req.ok) {
      if (res.status === "OK") {
        setMessageNotification(res.message);
        setShowCreateModalNotification(true);
        getRegistrosSeccion();
        cleanComponent();
      } else {
        setMessageNotification(res.message);
        setErrorCreateModalNotification(true);
      }
    } else {
      setShowCreateModalNotification("Ocurrio un error en el envio, intente de nuevo");
      setMessageNotification(res.message);
    }
  }

  const handleInputChangeOpenModalForm = (e, index, idx) => {
    e.preventDefault();
    console.log(e, index, idx);
    const list = [...inputList];
    const obj = { ...list[index] };
    const ubicaciones = [...obj.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    ubi.setOpenMdl = !ubi.setOpenMdl;
    ubicaciones[idx] = ubi;
    obj.ubicaciones = ubicaciones;
    list[index] = obj;
    setFormNumber(idx);
    setInputList(list);
    setIsForm(true);
  }

  const getFormBySubseccion = (item_input, index) => {
    switch (idSubseccion) {
      case 44:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
                <Form.Control
                  as='select'
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => handleInputChange(e, index)}
                  value={item_input.dispositivo}
                  name='dispositivo'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {(dataSelect2.length > 0) && (dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {item_input.dispositivo === "23" ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Composición</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    onChange={(e) => handleInputChange(e, index)}
                    value={item_input.composicion}
                    name='composicion'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">Amapola</option>
                    <option value="2">Cocaínas</option>
                    <option value="3">Marihuana</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : (item_input.dispositivo === "22" || item_input.dispositivo === "23" ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Composición</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    onChange={(e) => handleInputChange(e, index)}
                    value={item_input.composicion}
                    name='composicion'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="4">Urbano</option>
                    <option value="5">Rural</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null)}
          </>
        )
      case 45:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  onChange={(e) => handleInputChange(e, index)}
                  value={item_input.dispositivo}
                  name='dispositivo'
                >
                  <option value='' disabled selected>--Seleccionar--</option>
                  {(dataSelect2.length > 0) && (dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            {item_input.dispositivo !== "" ? (
              <Col xs={6}>
                <Form.Group className='formGroupVertical' >
                  <Form.Label>Composición</Form.Label>
                  <Form.Control
                    as='select'
                    placeholder='Escribe una descripción'
                    onChange={(e) => handleInputChange(e, index)}
                    value={item_input.composicion}
                    name='composicion'
                  >
                    <option value='' disabled selected>--Seleccionar--</option>
                    <option value="1">Amapola</option>
                    <option value="2">Cocaínas</option>
                    <option value="3">Marihuana</option>
                    <option value="4">Drogas sintéticas</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            ) : null}
          </>
        )
      default:
        return (
          <>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.dispositivo')}</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Escribe una descripción'
                  onChange={(e) => handleInputChange(e, index)}
                  value={item_input.dispositivo}
                  name='dispositivo'
                >
                  <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                  {(dataSelect2.length > 0) && (dataSelect2.map((item, index) => (
                    <option value={item.id_lis}>{item.valor}</option>
                  )))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='formGroupVertical' >
                <Form.Label>{t('formulario.label.composicion')}</Form.Label>
                <Form.Control
                  placeholder={t('formulario.label.escribe_descripcion')}
                  onChange={(e) => handleInputChange(e, index)}
                  value={item_input.composicion}
                  name='composicion'
                />
              </Form.Group>
            </Col>
          </>
        )
    }
  }

  const getRegistrosSeccion = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_factor: factor,
      id_seccion: idSubseccion,
      tipo: switchSeccion
    }

    console.log(dataReq);

    const req = await validarEntorno('JSON/panoramaRiesgos/selectsPanorama/registros_seccion_amenazas.html', 'POST', dataReq)

    console.log(req);

    if (req.ok) {
      const res = await req.json()
      console.log(res);
      setSeccionPoligono(res.ubicacion);

      setInitialState({
        ...initialState,
        loading: false,
        error: null,
        data: res
      })

      // console.log(initialState);
    }
  }


  const eliminarRegistroActividad = async (index) => {


    if (typeOperation === 'registro') {

      const dataReq = {
        hash: window.location.hash,
        id: idPanorama,
        id_factor: factor,
        id_res: regToDel.id_res,
        id_seccion: idSubseccion
      }

      console.log(dataReq);

      const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminacion_registro.html', 'POST', dataReq);


      if (req.ok) {

        const res = await req.json();

        if (res.status === "OK") {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('eliminacion_confirmada');
          getRegistrosSeccion();
          setIndexDelete('');
        } else {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('falloPeticionesGestion');
        }
      }
    } else if (typeOperation === 'actividad') {

      const dataReqAct = {
        hash: window.location.hash,
        id: idPanorama,
        id_factor: factor,
        id_res: actToDel?.id_res,
        id_seccion: idSubseccion
      }

      console.log(dataReqAct)


      const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminacion_actividad.html', 'POST', dataReqAct);

      if (req.ok) {

        const res = await req.json();

        if (res.estado === "OK") {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('eliminacion_confirmada');
          // const dataDel = {...initialState.data};
          // const dataActividadesDel = [...dataDel.actividades];
          // dataActividadesDel.splice(index, 1);
          // dataDel.actividades = dataActividadesDel;
          // setInitialState({
          //     ...initialState,
          //     data: dataDel
          // })
          getRegistrosSeccion();
          setIndexDelete('');
        } else {
          setShowEditDeleteModalNotification(true);
          setMessageEditDeleteNotification(res.message);
          setTypeNotification('fallo_peticion');
        }
      }
    }
  }

  const handleOpenMessageModalDeleteEdit = (tipo, index, item) => {
    let message = '';
    if (tipo === 'registro') {
      message = t('modales.seguro_eliminar_registro');
      setRegToDel(item);
    } else if (tipo === 'actividad') {
      message = t('modales.seguro_eliminar_accion');
      setActToDel(item);
    }
    setTypeOperation(tipo);
    setIndexDelete(index);
    setTypeNotification('confirmacion_eliminacion_ra');
    setMessageEditDeleteNotification(message)
    setShowEditDeleteModalNotification(true);
  }

  const editItem = (item) => {
    setShowEditModal(true);
    setEditData(item);
  }

  const closeEditItem = () => {
    setShowEditModal(false);
    setEditData({});
  }

  const editActivityItem = (item) => {
    console.log(item)
    setShowActivityModal(true);
    setEditData(item);
  }

  const closeActivityItem = () => {
    setShowActivityModal(false);
    setEditData({});
  }


  const handleEditData = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    });
  }

  const getIndexEdit = (index, item) => {
    setIndexEdit(index);
    setCoordEdit(item);
  }

  const handleInputCoord = (e) => {
    setCoordEdit({
      ...coordEdit,
      [e.target.name]: e.target.value
    });
  }

  const editarDatosFormulario = async (tipo) => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      data: editData,
      id_res: editData.id_res
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/registros_seccion_amenazas_edicion.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        console.log(res.mensaje);

        setMessageNotification(res.message);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');

        const response = res;
        // delete response.mensaje;
        // delete response.estado;

        getRegistrosSeccion();

        closeEditItem();
      } else {
        setMessageNotification(res.message);
        setErrorCreateModalNotification(true);
      }
    }
  }

  const enviarCargueMasivo = async () => {

    let dataReq = {};


    if (archivoCargue.archivo.length === 0 || archivoCargue.nombre === '' || archivoCargue.descripcion === '') {
      setMessageNotification('Envia todos los campos');
      setErrorCreateModalNotification(true);
    } else if (addCargueMasivo && archivoCargue.archivo.length > 0) {
      dataReq = {
        formData: archivoCargue,
        hash,
        factor: factor,
        tipo: "fuerza_disponible",
        subtipo: "registro",
        id_subseccion: idSubseccion
      }
    } else if (addCargueActividad && archivoCargue.archivo.length > 0) {
      dataReq = {
        formData: archivoCargue,
        hash,
        factor: factor,
        tipo: "amenaza",
        subtipo: "accion",
        id_subseccion: idSubseccion
      }
    }

    if (Object.keys(dataReq).length > 0) {
      console.log(dataReq)
      const req = await validarEntorno('JSON/panoramaRiesgos/cargueMasivo/cargue_masivo_pnr.json', 'POST', dataReq);

      if (req.ok) {
        const res = await req.json();
        if (res.estado === 'OK') {
          console.log(res.mensaje);
          setMessageNotification(res.mensaje);
          setShowCreateModalNotification(true);
          setTypeModalNotification('panoramaCreado');
          setArchivoCargue({ archivo: [], nombre: '', descripcion: '' });
        } else {
          setMessageNotification(res.mensaje);
          setErrorCreateModalNotification(true);
        }
      }
    } else {
      setMessageNotification("Ha ocurrido un error, intente nuevamente");
      setErrorCreateModalNotification(true);
    }
  }

  const editarDatosActividad = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      data: editData,
      id_res: editData.id_res
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/registros_seccion_amenazas_edicion.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        console.log(res.mensaje);
        const response = res;

        setMessageNotification(res.mensaje);
        setShowCreateModalNotification(true);
        setTypeModalNotification('panoramaCreado');

        delete response.mensaje;
        delete response.estado;

        getRegistrosSeccion();

        closeActivityItem();
      }
    }
  }


  const editarCoordenadas = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_res: editData.id_res,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_editar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const response = res;
        delete response.mensaje;
        delete response.estado;

        setInitialState({
          ...initialState,
          loading: false,
          error: null,
          data: response
        })
      }

      const dataDel = { ...editData };
      const ubi = { ...dataDel.ubicaciones };

      const punto = {
        id_pun: coordEdit.id_res,
        lat: parseFloat(coordEdit.lat),
        lng: parseFloat(coordEdit.lng)
      };

      ubi.puntos[index] = punto;

      setEditData({
        ...editData,
        ubicaciones: ubi
      });

      setIndexEdit('');
    }
  }

  const eliminarCoordenadas = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_res: editData.id_res,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const dataDel = { ...editData };
        const ubi = { ...dataDel.ubicaciones };
        const puntos = [...ubi.puntos];

        puntos.splice(index, 1);

        ubi.puntos = puntos;

        setEditData({
          ...editData,
          ubicaciones: ubi
        });
      }
    }
  }

  const editarCoordenadasActividad = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_act: editData.id_act,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_editar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const response = res;
        delete response.mensaje;
        delete response.estado;

        setInitialState({
          ...initialState,
          loading: false,
          error: null,
          data: response
        })
      }

      const dataDel = { ...editData };
      const ubi = { ...dataDel.ubicaciones };

      const punto = {
        id_pun: coordEdit.id_res,
        lat: parseFloat(coordEdit.lat),
        lng: parseFloat(coordEdit.lng)
      };

      ubi.puntos[index] = punto;

      setEditData({
        ...editData,
        ubicaciones: ubi
      });

      setIndexEdit('');
    }
  }

  const eliminarCoordenadasActividad = async (index) => {

    const dataReq = {
      hash: window.location.hash,
      id: idPanorama,
      id_seccion: idSubseccion,
      id_act: editData.id_act,
      data: coordEdit,
      id_pun: coordEdit.id_pun
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/ManipulacionRegistros/respuesta_eliminar_coordenadas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();

      if (res.estado === "OK") {
        const dataDel = { ...editData };
        const ubi = { ...dataDel.ubicaciones };
        const puntos = [...ubi.puntos];

        puntos.splice(index, 1);

        ubi.puntos = puntos;

        setEditData({
          ...editData,
          ubicaciones: ubi
        });
      }
    }
  }

  const deleteLastCoord = (index, idx) => {
    const list = [...inputList];
    const item = { ...list[index] };
    const ubicaciones = [...item.ubicaciones];
    const ubi = { ...ubicaciones[idx] };
    const coords = [...ubi.coords];
    coords.pop();
    ubi.coords = coords;
    ubicaciones[idx] = ubi;
    item.ubicaciones = ubicaciones;
    list[index] = item;
    setInputList(list);
  }

  const deleteLastCoordAct = (index, idx) => {
    const list = [...accionesList];
    const item = { ...list[index] };
    const ubicaciones = [...item.ubicaciones_act];
    const ubi = { ...ubicaciones[idx] };
    const coords = [...ubi.coords_act];
    coords.pop();
    ubi.coords_act = coords;
    ubicaciones[idx] = ubi;
    item.ubicaciones_act = ubicaciones;
    list[index] = item;
    setAccionesList(list);
  }

  const showDropzone = () => {
    setAddCargueMasivo(!addCargueMasivo);
    setAddSolucion(false);
  }

  const showForm = () => {
    setAddCargueMasivo(false);
    setAddSolucion(!addSolucion);
  }

  const showDropzoneActividad = () => {
    setAddCargueActividad(!addCargueActividad);
    setAddActividad(false);
  }

  const showFormActividad = () => {
    setAddCargueActividad(false);
    setAddActividad(!addActividad);
  }

  const deleteDropZone = (file) => {
    console.log(file);
    setArchivoCargue({ archivo: [], nombre: '', descripcion: '' });
  }

  const handleCargue = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setArchivoCargue({
      ...archivoCargue,
      [name]: value
    })
  }

  const handleInputChangeActAutor = (value) => {
    // console.log(value)
    setStateInforme({
      ...stateInforme,
      ['autor']: value.value
    })
  }

  return (
    <>
      <br />
      <section style={{ width: '95%', margin: 'auto' }} >
        <div id='contenedorSwitchSeccionesForm' >
          {permisos.registros &&
            <button
              onClick={() => setSwitchSeccion('registros')}
              className={`switchBotonFormFactores switchRegistro ${switchSeccion === 'registros' && 'switchSelected'}`}
            // style={{backgroundColor: switchSeccion === 'registro' && ''}}

            >
              {t('botones.registros')}
            </button>
          }
          {permisos.actividades &&
            <button
              onClick={() => setSwitchSeccion('acciones')}
              className={`switchBotonFormFactores switchActividades ${switchSeccion === 'acciones' && 'switchSelected'}`}

            >
              {t('botones.acciones')}
            </button>
          }
        </div>
        {permisos.registros && switchSeccion === 'registros' &&
          <>
            <br />
            <div className='headerRegistro'>
              <p>{t('panorama_riesgos.registros')}</p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ marginRight: '0px', width: '150px', marginRight: '0.5em' }}
                  className={addCargueMasivo === true ? 'warningButton' : 'confirmButton'}
                  onClick={() => showDropzone()}
                >
                  {
                    addCargueMasivo === true
                      ? t('botones.cancelar')
                      : t('botones.cargue_masivo')
                  }
                </button>
                <button
                  style={{ marginRight: '0px', width: '150px' }}
                  className={addSolucion === true ? 'warningButton' : 'confirmButton'}
                  onClick={() => showForm()}
                >
                  {
                    addSolucion === true
                      ? t('botones.cancelar')
                      : t('botones.agregar_registro')
                  }
                </button>
              </div>
            </div>
            <br />

            {addSolucion === true &&
              <div>
                {inputList.map((item_input, index) => (
                  <>
                    <>
                      <Row>
                        {getFormBySubseccion(item_input, index)}
                        <Col xs={6}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.fuerza')}</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder={t('formulario.label.escribe_descripcion')}
                              onChange={(e) => handleInputChange(e, index)}
                              value={item_input.descripcion_principal}
                              name='fuerza'
                              min="0"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                            <Form.Control
                              onChange={(e) => handleInputChange(e, index)}
                              value={item_input.fecha}
                              type="date"
                              name='fecha'
                            >
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                    <br />
                    <section style={{ width: '85%', margin: 'auto' }} >
                      <div className='headerRegistro'>
                        <h4 className='pagesTitles' >{t('panorama_riesgos.ubicaciones')}</h4>
                        <button
                          style={{ marginRight: '0px', width: '140px' }}
                          className={addUbicacion === true ? 'warningButton' : 'confirmButton'}
                          onClick={() => { addRemoveUbi(index) }}
                        >
                          {
                            addUbicacion === true
                              ? t('botones.cancelar')
                              : t('botones.agregar_ubicacion')
                          }

                        </button>
                      </div>
                      <br />
                      {addUbicacion === false
                        ?
                        <div style={{ display: 'none' }}>
                          Aún no has agregado una ubicación..
                        </div>
                        :
                        <div>
                          {(item_input.ubicaciones) && item_input.ubicaciones.map((ubicacion, idx) => (
                            <>
                              <Form.Group className='formGroupVertical' >
                                <Form.Label>{t('formulario.label.tipo_ubicacion')}</Form.Label>
                                <Form.Control
                                  as='select'
                                  onChange={(e) => handleInputChangeUbi(e, index, idx)}
                                  name='tipo_mapa'
                                  style={{ width: '50%' }}
                                  key={`slam-${index}-${idx}`}
                                >
                                  <option selected disabled>{t('formulario.label.seleccione')}</option>
                                  <option value='puntos_calor'>{t('formulario.select.puntos_calor')}</option>
                                  <option value='trazado'>{t('formulario.select.trazado')}</option>
                                  <option value='puntos_simples'>{t('formulario.select.punto_simple')}</option>
                                </Form.Control>
                              </Form.Group>
                              {ubicacion.tipo_mapa !== '' &&
                                <div className="containerOcs">
                                  <div id='' className="mapa">
                                    <>
                                      <div className="form-latlng">
                                        <p>{t('formulario.label.agrega_coordenadas')}</p>
                                        <Form>
                                          <Form.Group className='formGroupVertical'>
                                            <Row>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.latitud')}
                                                  name="lat"
                                                  defaultValue={coord.lat}
                                                  type="number"
                                                  onChange={handleLatLng}
                                                  isInvalid={invalidLat}
                                                  id="input-lat"
                                                  key={`ltam-${index}-${idx}`}
                                                />
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.longitud')}
                                                  name="lng"
                                                  defaultValue={coord.lng}
                                                  type="number"
                                                  onChange={handleLatLng}
                                                  isInvalid={invalidLng}
                                                  id="input-lng"
                                                  key={`lnam-${index}-${idx}`}
                                                />
                                              </Col>
                                              <Col>
                                                <button className="confirmButton" onClick={(e) => handleInputChangeOpenModalForm(e, index, idx)}>{t('botones.ubicar')}</button>
                                              </Col>
                                            </Row>
                                          </Form.Group>
                                        </Form>
                                      </div>
                                      {ubicacion.tipo_mapa === 'puntos_calor'
                                        ?
                                        <>
                                          <p>{t('formulario.label.clic_mapa_puntos_calor')}</p>

                                          <Heatmap
                                            googleMapURL={mapURL}
                                            containerElement={<div style={{ height: '100%' }}></div>}
                                            mapElement={<div style={{ height: '100%' }}></div>}
                                            loadingElement={<Loader />}
                                            latitud={ubicacionBase?.lat}
                                            longitud={ubicacionBase?.lng}
                                            zoom={10}
                                            coords={ubicacion.coords}
                                            getCoordinates={getCoordinates}
                                            tipoMapa={ubicacion.tipo_mapa}
                                            index={index}
                                            idx={idx}
                                            showDescriptionModal={ubicacion.setOpenMdl}
                                            setShowDescriptionModal={handleInputChangeOpenModal}
                                            submitLatLng={submitLatLng}
                                            isForm={isForm}
                                            setFormNumber={setFormNumber}
                                            setLat={setLat}
                                            setLng={setLng}
                                            lat={lat}
                                            lng={lng}
                                            key={`am-${index}-${idx}`}
                                            tipo="amenazas"
                                            seccionPoligono={seccionPoligono}
                                          />
                                        </>
                                        : ubicacion.tipo_mapa === 'trazado'
                                          ?
                                          <>
                                            <p>{t('formulario.label.agrega_puntos_necesarios')}</p>
                                            <Heatmap
                                              googleMapURL={mapURL}
                                              containerElement={<div style={{ height: '100%' }}></div>}
                                              mapElement={<div style={{ height: '100%' }}></div>}
                                              loadingElement={<Loader />}
                                              latitud={ubicacionBase?.lat}
                                              longitud={ubicacionBase?.lng}
                                              zoom={10}
                                              ubicaciones={puntosCalor}
                                              coords={ubicacion.coords}
                                              getCoordinates={getCoordinates}
                                              tipoMapa={ubicacion.tipo_mapa}
                                              index={index}
                                              idx={idx}
                                              showDescriptionModal={ubicacion.setOpenMdl}
                                              setShowDescriptionModal={handleInputChangeOpenModal}
                                              submitLatLng={submitLatLng}
                                              isForm={isForm}
                                              setFormNumber={setFormNumber}
                                              setLat={setLat}
                                              setLng={setLng}
                                              lat={lat}
                                              lng={lng}
                                              key={`am-${index}-${idx}`}
                                              tipo="amenazas"
                                              seccionPoligono={seccionPoligono}
                                            />
                                          </>
                                          : ubicacion.tipo_mapa === 'puntos_simples'
                                            ?
                                            <>
                                              <p>{t('formulario.label.clic_mapa_ubicaciones')}</p>
                                              <Heatmap
                                                googleMapURL={mapURL}
                                                containerElement={<div style={{ height: '100%' }}></div>}
                                                mapElement={<div style={{ height: '100%' }}></div>}
                                                loadingElement={<Loader />}
                                                latitud={ubicacionBase?.lat}
                                                longitud={ubicacionBase?.lng}
                                                zoom={10}
                                                ubicaciones={puntosCalor}
                                                coords={ubicacion.coords}
                                                getCoordinates={getCoordinates}
                                                tipoMapa={ubicacion.tipo_mapa}
                                                index={index}
                                                idx={idx}
                                                showDescriptionModal={ubicacion.setOpenMdl}
                                                setShowDescriptionModal={handleInputChangeOpenModal}
                                                submitLatLng={submitLatLng}
                                                isForm={isForm}
                                                setFormNumber={setFormNumber}
                                                setLat={setLat}
                                                setLng={setLng}
                                                lat={lat}
                                                lng={lng}
                                                key={`am-${index}-${idx}`}
                                                tipo="amenazas"
                                                seccionPoligono={seccionPoligono}
                                              />
                                            </>
                                            : null
                                      }
                                      <br />
                                      <br />
                                      <br />
                                    </>
                                  </div>
                                </div>
                              }
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              {/* {item_input.ubicaciones.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickUbi(index, idx)} />}
                                                {item_input.ubicaciones.length - 1 === idx && <AddCircleIcon className="icon-gray add" onClick={() => handleAddClickUbi(index)} />} */}
                              {ubicacion.coords.length > 0 && (
                                <div className="table-ubi-container">
                                  <Table striped bordered hover className="tabla-ubicaciones">
                                    <thead>
                                      <tr>
                                        <th>{t('formulario.label.latitud')}</th>
                                        <th>{t('formulario.label.longitud')}</th>
                                        <th>{t('formulario.label.descripcion')}</th>
                                        <th>{t('formulario.label.accion')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ubicacion.coords.map((ubi, tbx) => (
                                        <tr className="rowTabla">
                                          <td>{ubi.lat}</td>
                                          <td>{ubi.lng}</td>
                                          <td>{ubi.descripcion}</td>
                                          {tbx === (ubicacion.coords.length - 1) ? (
                                            <td className='tdBotones'>
                                              <div className='botonesTabla '>
                                                <button
                                                  style={{ cursor: 'pointer' }}
                                                  className='botonTablaGeneral botonEliminarTabla'
                                                  onClick={() => { deleteLastCoord(index, idx) }}
                                                >
                                                  <DeleteIcon fontSize='small' />
                                                  Eliminar
                                                </button>
                                              </div>
                                            </td>
                                          ) : <td></td>}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              )}

                            </>
                          ))}
                        </div>
                      }

                    </section>
                    {/* <hr/> */}
                    {/* {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />}
                                    {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />} */}
                  </>
                ))}
              </div>
            }

            {addCargueMasivo === true && (
              <>
                <Row>
                  <Col xs={12}>
                    <DropzoneArea
                      dropzoneText={t('formulario.label.sube_archivo_cargue')}
                      previewText="Sube tu archivo de cargue"
                      onChange={(files) => setArchivoCargue({ ...archivoCargue, archivo: files })}
                      acceptedFiles={['application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                      getPreviewIcon={() => {
                        return <img src={archivoCargue.archivo.length > 0 ? iconos.hoja_calculo.icono : null} width="100" />
                      }}
                      filesLimit={1}
                      onDelete={(file) => deleteDropZone(file)}
                      showAlerts={false}
                    />
                  </Col>
                  <Col xs={6}>
                    <Form.Group className='formGroupVertical'>
                      <Form.Label>{t('formulario.label.nombre')}</Form.Label>
                      <Form.Control
                        placeholder={t('formulario.label.nombre')}
                        name="nombre"
                        onChange={handleCargue}
                        id="input-nombre"
                        value={archivoCargue.nombre}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className='formGroupVertical'>
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder={t('formulario.label.descripcion')}
                        name="descripcion"
                        onChange={handleCargue}
                        id="input-descripcion"
                        style={{ height: '100px' }}
                        value={archivoCargue.descripcion}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            <br />
            <div className='buttonContainer'>
              {addCargueMasivo || addSolucion ? (
                <button
                  onClick={(e) => { enviarDatosFormulario(e) }}
                  className='confirmButton'
                >
                  {t('botones.enviar')}
                </button>
              ) : null}
              {/* { addCargueMasivo ? (
                                    <button
                                        onClick={() => { enviarCargueMasivo() }}
                                        className='confirmButton'
                                    >
                                        Enviar
                                    </button>
                                ) : null }
                                { addCargueActividad ? (
                                    <button
                                        onClick={() => { enviarCargueMasivo() }}
                                        className='confirmButton'
                                    >
                                        Enviar
                                    </button>
                                ) : null }     */}
            </div>

            <hr />
            <br />
            <h3 className='pagesTitles' >{t('panorama_riesgos.lista_registros')}</h3>
            <br />
            {initialState?.data?.registros && initialState?.data?.registros.length > 0
              ?
              <table>
                <thead>
                  <th>{t('formulario.label.dispositivo')}</th>
                  <th>{t('formulario.label.composicion')}</th>
                  <th>{t('formulario.label.fecha')}</th>
                  <th>{t('formulario.label.fuerza')}</th>
                  <th>{t('formulario.label.acciones')}</th>
                </thead>
                <tbody>
                  {initialState.data.registros.map((registro, index) => (
                    <tr className="rowTabla" key={index} style={{ fontSize: '0.83rem' }}>
                      <td>{registro.dispositivo}</td>
                      <td>{registro.composicion}</td>
                      <td>{registro.fecha}</td>
                      <td>{registro.fuerza}</td>
                      <td className='tdBotones'>
                        <div className='botonesTabla '>
                          {initialState?.data?.permisos?.editar &&
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonEditarTabla'
                              onClick={() => { editItem(registro) }}
                            >
                              <EditIcon fontSize='small' />
                              {t('botones.editar')}
                            </button>
                          }
                          {initialState?.data?.permisos?.eliminar &&
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonEliminarTabla'
                              onClick={() => { handleOpenMessageModalDeleteEdit('registro', index, registro) }}
                            >
                              <DeleteIcon fontSize='small' />
                              {t('botones.eliminar')}
                            </button>
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              :
              <div className='mensajeTablaSinInfo'>
                {t('formulario.label.no_agregado_registros')}
              </div>

            }

          </>

        }
        {permisos.registros && switchSeccion === 'acciones' &&
          <>
            <br />
            <div className='headerRegistro'>
              <p>{t('panorama_riesgos.acciones')}</p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ marginRight: '0px', width: '150px', marginRight: '0.5em' }}
                  className={addCargueMasivo === true ? 'warningButton' : 'confirmButton'}
                  onClick={() => showDropzoneActividad()}
                >
                  {
                    addCargueActividad === true
                      ? t('botones.cancelar')
                      : t('botones.cargue_masivo')
                  }
                </button>
                <button
                  style={{ marginRight: '0px', width: '150px' }}
                  className={addActividad === true ? 'warningButton' : 'confirmButton'}
                  onClick={() => showFormActividad()}
                >
                  {
                    addActividad === true
                      ? t('botones.cancelar')
                      : t('botones.agregar_accion')
                  }
                </button>
              </div>
            </div>
            <br />
            {addActividad === true &&
              <div>
                {accionesList?.map((item_input, index) => (
                  <>
                    <>
                      <Row>
                        <Col xs={6}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.fecha')}</Form.Label>
                            <Form.Control
                              onChange={(e) => handleInputChangeAct(e, index)}
                              value={item_input.fecha}
                              type="date"
                              name='fecha'
                            >
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.accion')}</Form.Label>
                            <Form.Control
                              as='select'
                              // type='text'
                              value={item_input.campo}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              name='accion_amenaza'
                            >
                              <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                              {dataSelect?.length > 0 && (dataSelect?.map((item, index) => (
                                <option value={item.id_name}>{item.valor}</option>
                              )))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6} >
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.autor')}</Form.Label>
                            <div style={{ width: '100%' }} >
                              <Select
                                // options={[{value: 'oscar', label: 'oscar'}]}
                                options={autoresAmenazas}
                                style={{ width: '100%' }}
                                placeholder={t('formulario.label.seleccione')}
                                // onChange={(e) => handleInputChangeAct({target: {name: 'autor', value}}, index)}
                                onChange={handleInputChangeActAutor}
                                name='autor'

                              />
                            </div>
                            {/* <Form.Control
                                                            as='select'
                                                            placeholder='Escribe una descripción'
                                                            defaultValue={item_input.tipo}
                                                            onChange={(e) => handleInputChangeAct(e, index)}
                                                            name='autor'
                                                        >
                                                            <option value='' disabled selected>--Seleccionar--</option>
                                                            { dataSelect2.length > 0 && ( dataSelect2.map((item, index) => (
                                                                <option value={item.valor}>{item.valor}</option>
                                                            ))) }
                                                        </Form.Control> */}
                          </Form.Group>
                        </Col>
                        <Col xs={6} >
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.ubicacion')}</Form.Label>
                            <Form.Control
                              as='select'
                              defaultValue={item_input.ubicacion}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              name='ubicacion'
                            >
                              <option value='' disabled selected>{t('formulario.label.seleccione')}</option>
                              {municipios.length > 0 && (municipios.map((item, index) => (
                                <option value={item.value}>{item.label}</option>
                              )))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.resumen')}</Form.Label>
                            <Form.Control
                              style={{ minHeight: '50px' }}
                              as='textarea'
                              placeholder={t('formulario.label.escribe_descripcion')}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              value={item_input.descripcion_principal}
                              name='resumen'
                            >
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group className='formGroupVertical' >
                            <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                            <Form.Control
                              style={{ minHeight: '100px' }}
                              as='textarea'
                              placeholder={t('formulario.label.escribe_descripcion')}
                              onChange={(e) => handleInputChangeAct(e, index)}
                              value={item_input.descripcion_principal}
                              name='descripcion_principal_act'
                            >
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        {/* <Col xs={6}>
                                                    <Form.Group className='formGroupVertical' >
                                                        <Form.Label>Fecha</Form.Label>
                                                        <Form.Control
                                                            onChange={(e) => handleInputChangeAct(e, index)}
                                                            value={item_input.fecha}
                                                            type="date"
                                                            name='fecha_act'
                                                        >
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col> */}

                      </Row>
                    </>
                    <br />
                    <section style={{ width: '85%', margin: 'auto' }} >
                      <div className='headerRegistro'>
                        <h4 className="pagesTitles">{t('panorama_riesgos.ubicaciones')}</h4>
                        <button
                          style={{ marginRight: '0px', width: '140px' }}
                          className={addUbicacionActividad === true ? 'warningButton' : 'confirmButton'}
                          onClick={() => { addRemoveUbiAct(index) }}
                        >
                          {
                            addUbicacionActividad === true
                              ? t('botones.cancelar')
                              : t('botones.agregar_ubicacion')
                          }
                        </button>
                      </div>
                      <br />
                      {addUbicacionActividad === false
                        ?
                        <div style={{ display: 'none' }}>
                          Aún no has agregado una ubicación..
                        </div>
                        :
                        <div>
                          {(item_input.ubicaciones_act) && item_input.ubicaciones_act.map((ubicacion_act, idx) => (
                            <>
                              <Form.Group className='formGroupVertical' >
                                <Form.Label>{t('formulario.label.tipo_ubicacion')}</Form.Label>
                                <Form.Control
                                  as='select'
                                  onChange={(e) => handleInputChangeUbiAct(e, index, idx)}
                                  name='tipo_mapa'
                                  style={{ width: '50%' }}
                                >
                                  <option selected disabled>{t('formulario.label.seleccione')}</option>
                                  <option value='puntos_calor'>{t('formulario.select.puntos_calor')}</option>
                                  <option value='trazado' >{t('formulario.select.trazado')}</option>
                                  <option value='puntos_simples'>{t('formulario.select.punto_simple')}</option>
                                </Form.Control>
                              </Form.Group>
                              {ubicacion_act.tipo_mapa !== '' &&
                                <div className="containerOcs">
                                  <div id='' className="mapa">
                                    <>
                                      <div className="form-latlng">
                                        <p>{t('formulario.label.agrega_coordenadas')}</p>
                                        <Form>
                                          <Form.Group className='formGroupVertical'>
                                            <Row>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.latitud')}
                                                  name="lat"
                                                  defaultValue={coord.lat}
                                                  type="number"
                                                  onChange={handleLatLngAct}
                                                  isInvalid={invalidLat}
                                                  id="input-lat"
                                                />
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  placeholder={t('formulario.label.longitud')}
                                                  name="lng"
                                                  defaultValue={coord.lng}
                                                  type="number"
                                                  onChange={handleLatLngAct}
                                                  isInvalid={invalidLng}
                                                  id="input-lng"
                                                />
                                              </Col>
                                              <Col>
                                                <button className="confirmButton" onClick={(e) => handleInputChangeOpenModalActForm(e, index, idx)}>{t('botones.ubicar')}</button>
                                              </Col>
                                            </Row>
                                          </Form.Group>
                                        </Form>
                                      </div>
                                      {ubicacion_act.tipo_mapa_act === 'puntos_calor'
                                        ?
                                        <>
                                          <p>{t('formulario.label.clic_mapa_puntos_calor')}</p>

                                          <HeatmapActivities
                                            googleMapURL={mapURL}
                                            containerElement={<div style={{ height: '100%' }}></div>}
                                            mapElement={<div style={{ height: '100%' }}></div>}
                                            loadingElement={<Loader />}
                                            latitud={ubicacionBase?.lat}
                                            longitud={ubicacionBase?.lng}
                                            zoom={10}
                                            coords_map={ubicacion_act.coords_act}
                                            getCoordinatesAct={getCoordinatesAct}
                                            tipoMapa={ubicacion_act.tipo_mapa_act}
                                            index={index}
                                            idx={idx}
                                            showDescriptionModal={ubicacion_act.setOpenMdlAct}
                                            setShowDescriptionModal={handleInputChangeOpenModalAct}
                                            submitLatLngAct={submitLatLngAct}
                                            isForm={isForm}
                                            setFormNumber={setFormNumberAct}
                                            setLat={setLatAct}
                                            setLng={setLngAct}
                                            lat={latAct}
                                            lng={lngAct}
                                            key={`act-${index}-${idx}`}
                                            tipo="amenazas"
                                            seccionPoligono={seccionPoligono}
                                          />
                                        </>
                                        : ubicacion_act.tipo_mapa_act === 'trazado'
                                          ?
                                          <>
                                            <p>{t('formulario.label.agrega_puntos_necesarios')}</p>
                                            <HeatmapActivities
                                              googleMapURL={mapURL}
                                              containerElement={<div style={{ height: '100%' }}></div>}
                                              mapElement={<div style={{ height: '100%' }}></div>}
                                              loadingElement={<Loader />}
                                              latitud={ubicacionBase?.lat}
                                              longitud={ubicacionBase?.lng}
                                              zoom={10}
                                              ubicaciones={puntosCalor}
                                              coords_map={ubicacion_act.coords_act}
                                              getCoordinatesAct={getCoordinatesAct}
                                              tipoMapa={ubicacion_act.tipo_mapa_act}
                                              index={index}
                                              idx={idx}
                                              showDescriptionModal={ubicacion_act.setOpenMdlAct}
                                              setShowDescriptionModal={handleInputChangeOpenModalAct}
                                              submitLatLngAct={submitLatLngAct}
                                              isForm={isForm}
                                              setFormNumber={setFormNumberAct}
                                              setLat={setLatAct}
                                              setLng={setLngAct}
                                              lat={latAct}
                                              lng={lngAct}
                                              key={`act-${index}-${idx}`}
                                              tipo="amenazas"
                                              seccionPoligono={seccionPoligono}
                                            />
                                          </>
                                          : ubicacion_act.tipo_mapa_act === 'puntos_simples'
                                            ?
                                            <>
                                              <p>{t('formulario.label.clic_mapa_ubicaciones')}</p>
                                              <HeatmapActivities
                                                googleMapURL={mapURL}
                                                containerElement={<div style={{ height: '100%' }}></div>}
                                                mapElement={<div style={{ height: '100%' }}></div>}
                                                loadingElement={<Loader />}
                                                latitud={ubicacionBase?.lat}
                                                longitud={ubicacionBase?.lng}
                                                zoom={10}
                                                ubicaciones={puntosCalor}
                                                coords_map={ubicacion_act.coords_act}
                                                getCoordinatesAct={getCoordinatesAct}
                                                tipoMapa={ubicacion_act.tipo_mapa_act}
                                                index={index}
                                                idx={idx}
                                                showDescriptionModal={ubicacion_act.setOpenMdlAct}
                                                setShowDescriptionModal={handleInputChangeOpenModalAct}
                                                submitLatLngAct={submitLatLngAct}
                                                isForm={isForm}
                                                setFormNumber={setFormNumberAct}
                                                setLat={setLatAct}
                                                setLng={setLngAct}
                                                lat={latAct}
                                                lng={lngAct}
                                                key={`act-${index}-${idx}`}
                                                tipo="amenazas"
                                                seccionPoligono={seccionPoligono}
                                              />
                                            </>
                                            : null
                                      }
                                      <br />
                                      <br />
                                      <br />
                                    </>
                                  </div>
                                </div>
                              }
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              {/* {item_input.ubicaciones_act.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickUbiAct(index, idx)} />}
                                                    {item_input.ubicaciones_act.length - 1 === idx && <AddCircleIcon className="icon-gray add" onClick={() => handleAddClickUbiAct(index)} />} */}
                              {ubicacion_act.coords_act.length > 0 && (
                                <div className="table-ubi-container">
                                  <Table striped bordered hover className="tabla-ubicaciones">
                                    <thead>
                                      <tr>
                                        <th>Latitud</th>
                                        <th>Longitud</th>
                                        <th>Descripcion</th>
                                        <th>Acciones</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ubicacion_act.coords_act.map((ubi, tbx) => (
                                        <tr className="rowTabla">
                                          <td>{ubi.lat}</td>
                                          <td>{ubi.lng}</td>
                                          <td>{ubi.descripcion}</td>
                                          {tbx === (ubicacion_act.coords_act.length - 1) ? (
                                            <td className='tdBotones'>
                                              <div className='botonesTabla '>
                                                <button
                                                  style={{ cursor: 'pointer' }}
                                                  className='botonTablaGeneral botonEliminarTabla'
                                                  onClick={() => { deleteLastCoordAct(index, idx) }}
                                                >
                                                  <DeleteIcon fontSize='small' />
                                                  Eliminar
                                                </button>
                                              </div>
                                            </td>
                                          ) : <td></td>}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              )}

                            </>
                          ))}
                        </div>
                      }

                    </section>
                    {/* <hr/> */}
                    {/* {accionesList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClickAct(index)} />}
                                        {accionesList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClickAct} />} */}
                  </>
                ))}
              </div>
            }

            {addCargueActividad === true && (
              <>
                <Row>
                  <Col xs={12}>
                    <DropzoneArea
                      dropzoneText={t('formulario.label.sube_archivo_cargue')}
                      previewText="Sube tu archivo de cargue"
                      onChange={(files) => setArchivoCargue({ ...archivoCargue, archivo: files })}
                      acceptedFiles={['application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                      getPreviewIcon={() => {
                        return <img src={archivoCargue.archivo.length > 0 ? iconos.hoja_calculo.icono : null} width="100" />
                      }}
                      filesLimit={1}
                      onDelete={(file) => deleteDropZone(file)}
                      showAlerts={false}
                    />
                  </Col>
                  <Col xs={6}>
                    <Form.Group className='formGroupVertical'>
                      <Form.Label>{t('formulario.label.nombre')}</Form.Label>
                      <Form.Control
                        placeholder={t('formulario.label.nombre')}
                        name="nombre"
                        onChange={handleCargue}
                        id="input-nombre"
                        value={archivoCargue.nombre}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className='formGroupVertical'>
                      <Form.Label>{t('formulario.label.descripcion')}</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder={t('formulario.label.descripcion')}
                        name="descripcion"
                        onChange={handleCargue}
                        id="input-descripcion"
                        style={{ height: '100px' }}
                        value={archivoCargue.descripcion}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <br />
            <div className='buttonContainer'>
              {addActividad ? (
                <button
                  onClick={(e) => { enviarDatosFormulario(e) }}
                  className='confirmButton'
                >
                  {t('botones.enviar')}
                </button>
              ) : null}
              {addCargueMasivo ? (
                <button
                  onClick={() => { enviarCargueMasivo() }}
                  className='confirmButton'
                >
                  {t('botones.enviar')}
                </button>
              ) : null}
              {addCargueActividad ? (
                <button
                  onClick={() => { enviarCargueMasivo() }}
                  className='confirmButton'
                >
                  {t('botones.enviar')}
                </button>
              ) : null}
            </div>

            <hr />
            <br />
            <h3 className='pagesTitles' >{t('panorama_riesgos.lista_acciones')}</h3>
            <br />
            {initialState.data.actividades && initialState.data.actividades.length
              ?
              <table>
                <thead>
                  <th>{t('formulario.label.accion')}</th>
                  <th>{t('formulario.label.descripcion')}</th>
                  <th>{t('formulario.label.fecha')}</th>
                  <th>{t('formulario.label.acciones')}</th>
                </thead>
                <tbody>
                  {initialState.data.actividades.map((actividad, index) => (
                    <tr className='rowTabla' key={index} style={{ fontSize: '0.83rem' }} >
                      <td>{actividad.actividad}</td>
                      <td>{actividad.descripcion}</td>
                      <td>{actividad.fecha}</td>
                      <td className='tdBotones'>
                        <div className='botonesTabla'>
                          {initialState?.data?.permisos?.editar &&
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonEditarTabla'
                              onClick={() => { editActivityItem(actividad) }}
                            >
                              <EditIcon fontSize='small' />
                              {t('botones.editar')}
                            </button>
                          }
                          {initialState?.data?.permisos?.eliminar &&
                            <button
                              style={{ cursor: 'pointer' }}
                              className='botonTablaGeneral botonEliminarTabla'
                              onClick={() => { getActividadToDelete(actividad); handleOpenMessageModalDeleteEdit('actividad', index, actividad) }}
                            >
                              <DeleteIcon fontSize='small' />
                              {t('botones.eliminar')}
                            </button>
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              :
              <div className='mensajeTablaSinInfo'>
                {t('formulario.label.no_agregado_acciones')}
              </div>
            }



            {/* {(initialState.data.cargue && initialState.data.cargue.length > 0)
                                ?
                                <table>
                                    <thead>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Fecha</th>
                                        <th>Acciones</th>
                                    </thead>
                                    <tbody>
                                        {initialState.data.cargue.map( (cargue, index) => (
                                            <tr className='rowTabla' key={index} style={{fontSize: '1rem'}} >
                                                <td>{cargue.cargue}</td>
                                                <td>{cargue.descripcion}</td>
                                                <td>{cargue.fecha}</td>
                                                <td className='tdBotones'>
                                                    enlace
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                :
                                <div className='mensajeTablaSinInfo'>
                                    No se han agregado acciones
                                </div>
                            } */}
          </>
        }
      </section>

      <NotificationModal
        isOpen={showCreateModalNotification}
        setIsOpen={handleCloseCreateModalNotification}
        typeModal="panoramaCreado"
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showErrorModalNotification}
        setIsOpen={handleCloseErrorModalNotification}
        typeModal="error_panorama"
        message={messageNotification}
      />
      <NotificationModal
        isOpen={showEditDeleteModalNotification}
        setIsOpen={handleCloseModalEditDeleteNotification}
        typeModal={typeNotification}
        message={messageShowEditDeleteNotification}
        item={itemToErase}
        indexDelete={indexDelete}
        handleEliminar={eliminarRegistroActividad}
      />
      <ModalPanorama
        typeModal="amenazas"
        isOpen={showEditModal}
        setIsOpen={closeEditItem}
        handleInputCoord={handleInputCoord}
        handleInputChange={handleEditData}
        data={editData}
        dataFilter={dataSelect2 ? dataSelect2 : null}
        dataSelect2={dataSelect2 ? dataSelect2 : null}
        googleMapURL={mapURL}
        idSubseccion={idSubseccion}
        editarDatosFormulario={editarDatosFormulario}
        indexEdit={indexEdit}
        getIndexEdit={getIndexEdit}
        editarCoordenadas={editarCoordenadas}
        eliminarCoordenadas={eliminarCoordenadas}
        cleanIndex={setIndexEdit}

      />
      <ModalActividades
        isOpen={showActivityModal}
        setIsOpen={closeActivityItem}
        handleInputCoord={handleInputCoord}
        handleInputChange={handleEditData}
        data={editData}
        googleMapURL={mapURL}
        dataSelect2={dataSelect2}
        dataSelect={dataSelect}
        indexEdit={indexEdit}
        getIndexEdit={getIndexEdit}
        editarDatosActividad={editarDatosActividad}
        editarCoordenadas={editarCoordenadasActividad}
        eliminarCoordenadas={eliminarCoordenadasActividad}
      />
    </>
  );
}

export default FormAmenazas;
