import React, { useEffect, useState } from 'react';
import { Form, Col, Card, Button, Row } from 'react-bootstrap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal';
import EditModal from '../../../modales/EditModal';
import { useParams } from 'react-router-dom';
import './styles/Factores.scss'
import Loader from '../../../icons/Loader'
import { Resaltado } from '../../../icons/Resaltado'
import { LeftSVG } from '../../../icons/LeftSVG';
import { RightSVG } from '../../../icons/RightSVG';
import useState2 from 'react-usestateref';
import { useForm } from 'react-hook-form';

import InputType from '../../../../helpers/InputType'
import { useTranslation } from 'react-i18next';



export default function Factores() {

  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();

  const { t } = useTranslation();
  const { id } = useParams();

  const HASH = window.location.hash;

  const [filtrosForm, setFiltrosForm] = useState({});
  const [modalMode, setModalMode] = useState('')
  const [areaOpen, setAreaOpen] = useState('')
  // const [areaOpenLabel, setAreaOpenLabel] = useState('')
  const [actsLista, setActsLista] = useState({
    actividades: []
  })
  const [modal, setModal] = useState({
    // data: {},
    type: '',
    show: false
  })
  const [notificacionType, setNotificacionType] = useState('')
  const [notificacionShow, setNotificacionShow] = useState(false)
  const [allCheck, setAllCheck] = useState(false)
  const [notificacionMensaje, setNotificacionMensaje] = useState('')
  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false
  })

  const [filtros, setFiltros, filtrosRef] = useState2({
    limite: 10,
    pagina: 1
  });

  const getRiesgos = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: id
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_factores.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, data: res })
      setAreaOpen(res.factores[0]?.id)
      // setAreaOpenLabel(res.factores[0]?.value)

      getActividades(res.factores[0]?.id);
    }



  }

  const getActividades = async (value, data) => {


    setAreaOpen(value)

    const dataReq = {
      hash: HASH,
      id: id,
      id_factor: value,
      filtros: data ? data : {}

    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_factores_act.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      // console.log(res)
      setActsLista(res)
      setAllCheck(res.todos_check ? res.todos_check : false)
    }


  }

  // const handleChange = (data) => {
  //   // const { name, value } = e.target;

  //   setFiltrosForm({
  //       ...filtrosForm,
  //       data
  //       // [name]: value
  //   });

  //   console.log(filtrosForm, tipo)
  // }

  const filterData = async (e) => {

    setInitialState({ ...initialState, loading: true })

    const { name, value } = e.target;

    setFiltros({
      ...filtros,
      [name]: value
    });

    const dataReq = {
      hash: HASH,
      // dataForm: filtrosForm.filtros,
      id: id,
      id_factor: areaOpen,
      limite: filtrosRef.current.limite,
      pagina: filtrosRef.current.pagina,
    };

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_factores_act.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, loading: false })
      setActsLista(res)
      console.log(res)
    } else {
      setInitialState({ ...initialState, loading: false, error: req.error })
    }
  }

  const paginateData = async (page) => {
    setInitialState({ ...initialState, loading: true })
    setFiltros({
      ...filtros,
      pagina: page
    });

    const dataReq = {

      hash: HASH,
      // dataForm: filtrosForm?.filtros,
      limite: filtrosRef.current.limite,
      pagina: filtrosRef.current.pagina,
      id: id,
      id_factor: areaOpen
    };

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_factores_act.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, loading: false })
      setActsLista(res)

      // console.log(res)
    } else {
      setInitialState({ ...initialState, loading: false, error: req.error })
    }
  }

  const resaltarMarker = async (tipo, id, idx) => {

    let x = { ...actsLista }
    const a = { ...x }
    const b = [...a.regs]
    const c = { ...b[idx] }
    c.check = tipo
    b[idx] = c
    a.regs = b
    x = a
    setActsLista(x)

    console.log(x)

    updateRegs(x)
  }

  const resaltarTodos = () => {

    const lista = actsLista.regs
    const check = lista.map((item) => {
      return { ...item, check: !allCheck }
    })

    actsLista.regs = check;

    setActsLista({ ...actsLista, regs: check })

    setAllCheck(!allCheck)
    updateRegs(actsLista)
  }

  const updateRegs = async (data) => {

    const dataReq = {
      hash: HASH,
      id: id,
      data: data,
      id_factor: areaOpen

    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_factores_act_update.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      console.log(res)
    } else {
    }

  }

  const submitDataModals = async (data) => {

    const dataReq = {
      id: id,
      hash: HASH,
      tipo: modalMode,
      id_factor: areaOpen,
      dataForm: data

    }

    console.log(dataReq)

    if (modalMode === 'filtros') {
      setFiltrosForm(data.asesoria[0])
      getActividades(areaOpen, data.asesoria[0]);
    } else {

      const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_save_analisis.html', 'POST', dataReq)

      if (req.ok) {
        const res = await req.json()
        if (res.estado === 'ok') {

          setNotificacionType('successGestion')
          getActividades(areaOpen)
        } else {
          setNotificacionType('error_panorama')
        }
        setNotificacionShow(true)
        setNotificacionMensaje(res.mensaje)
      }


      //la funcion para analisis

    }

    setModal({ ...modal, show: false, type: '' })


    reset();

  }

  // useEffect(() => {
  //   // getRiesgos();
  //   // setAreaOpen(initialState?.data[0]?.id);
  //   getActividades(areaOpen);
  // }, [filtrosForm])


  useEffect(() => {
    getRiesgos();
    setAreaOpen(initialState?.data[0]?.id);
  }, [])

  return (
    <>


      <Card>
        <Card.Body>
          <div className=' scrollSecundario bodyWidgets'>
            {initialState?.data?.factores?.length > 0 && initialState?.data?.factores?.map((item, index) => {
              return (
                <div
                  style={{ borderBottom: areaOpen === item?.id ? '8px solid #E4A731' : '8px solid #213265' }}
                  className='shadow widget '
                  key={item.id}
                  onClick={() => getActividades(item.id)}
                  eventKey={item.id}
                >
                  <p>{item.value}</p>
                  <p className='widgetPorcentaje'>{item.porcentaje}</p>
                </div>
              )
            })
            }
          </div>
          <br />
          <div className='info_factor'>
            <div>{t('panorama_riesgos.actividades_factor')}</div>
            <button
              className='btn_agregar'
              onClick={() => { setModal({ ...modal, show: true }); setModalMode('analisis') }}
            >{t('panorama_riesgos.analisis')}</button>
          </div>
          {actsLista?.actividades?.length === 0
            ?
            <div id='mensaje_acts_vacias' >
              No hay actividades relacionadas al factor
            </div>
            :
            <>
              <div id='header_tabla' className='shadow' >
                <div id='header_tabla-numero_registros'>
                  <p> {t('formulario.label.registros')}: </p>
                  <Form.Control
                    className='inputGroup'
                    id='cantidad_regs'
                    name='limite'
                    as='select'
                    onChange={(e) => { filterData(e) }}
                    value={filtros.limite}
                  >
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>150</option>
                  </Form.Control>
                </div>
                <div id='header_tabla-paginado'>
                  {actsLista?.pag_total > 1 &&
                    (
                      <div style={{ cursor: 'pointer' }}
                        onClick={() => { paginateData(actsLista?.pag_ant) }}
                      >
                        <LeftSVG />
                      </div>
                    )
                  }
                  <div className='paginadoItems' id='pagactualnum'>
                    <p>Pag</p>
                    <p>{actsLista?.pag_actual}</p>
                  </div>
                  <div className='paginadoItems' id='pag_total_num'>
                    <p>{t('dashboard.de')}</p>
                    <p>{actsLista?.pag_total}</p>
                  </div>

                  {actsLista?.pag_total !== actsLista?.pag_actual && (
                    <div style={{ cursor: 'pointer' }}
                      onClick={() => { paginateData(actsLista?.pag_sig) }}
                    >
                      <RightSVG />
                    </div>
                  )}
                </div>
                {/* <div
                    className='btn_filtros'
                    style={{ cursor: 'pointer', display: 'flex', justifyContent:'space-around'}}
                    onClick={() => {setModal({...modal, show: true}); setModalMode('filtros')}}

                  >
                      <p>
                          Filtros
                      </p>
                      <SettingsIcon id='btn_filtros-icon' />

                  </div> */}
              </div>
              <br />
              <div className='table_reps' >
                <div id='cols'>
                  {
                    actsLista?.cols?.map((col, idx) => {
                      return (
                        <div className='col_item' key={idx}>
                          {col}
                        </div>
                      )
                    })
                  }
                  <div className='col_item' id='resaltado_col' >
                    <CheckCircleIcon
                      onClick={() => resaltarTodos()}
                      style={{
                        cursor: 'pointer',
                        color: allCheck === true ? '#13cd19' : 'rgb(220, 220, 220)'
                      }}
                    />
                  </div>
                </div>
                <div id='regs' >
                  {actsLista?.regs?.map((item, index) => {
                    return (
                      <Card key={index} className='shadow reg_item_factores' >
                        {item.cols?.map((colreg, idx) => {
                          return (
                            <div className='colreg_factores' key={idx}>
                              {colreg.valor}
                            </div>
                          )
                        })}
                        <div className='colreg_factores'>
                          <CheckCircleIcon
                            style={{
                              cursor: 'pointer',
                              color: item.check === true ? '#13cd19' : 'rgb(220, 220, 220)'
                            }}
                            onClick={() => { resaltarMarker(!item.check, item.id, index) }}
                          />
                        </div>
                      </Card>
                    )
                  })

                  }

                </div>
              </div>

            </>
          }

          <div></div>
        </Card.Body>
      </Card>
      <EditModal
        isOpen={modal.show}
        setIsOpen={setModal}
        typeModal={modal.type}
      >
        {modalMode === 'analisis' ?
          <div>
            <h3 className='pagesTitles' >{t('panorama_riesgos.analisis_factor')}</h3>
            <br />
            <p className='' style={{ textAlign: 'justify' }} >{t('panorama_riesgos.agrega_breve_analisis')}</p>
            <br />
            <Form onSubmit={handleSubmit(submitDataModals)} >
              <div>

                <Form.Group className='formGroupVertical'>
                  {/* <Form.Label>
                  Análisis
                </Form.Label> */}


                  <InputType
                    item={{
                      "nombre": "analisis",
                      // "titulo": "Análisis",
                      "valor": actsLista.analisis,
                      "tipo": "4",
                      "es_requerido": false,
                      "col": "12",
                    }}
                    idx={0}
                    index={0}
                    reg={register}
                    nombre={`${`asesoria.0`}`}
                    key={`${0} - ${0}`}
                    errors={errors}
                    col={6}
                    type=""
                  />
                  {/* <Form.Control
                  name='analisis'
                  onChange={ (e) => handleChange(e, 'analisis')}
                  style={{
                    height: '150px'
                  }}
                  as='textarea'

                /> */}
                </Form.Group>
              </div>
              <br />
              <div className='buttonContainer' >
                <button type='submit' className='confirmButton' >{t('botones.enviar')}</button>
              </div>

            </Form>

          </div>
          : modalMode === 'filtros' &&
          <div
            style={{
              width: '97%'
            }}
          >
            <div className='filtros_header' >
              <h2 className='pagesTitles' >Filtros</h2>
              {/* <button>Limpiar filtros</button> */}
            </div>
            <br />
            <div className='filtros_container' >
              <Form onSubmit={handleSubmit(submitDataModals)} className='filtros_form' >
                <div className='filtros_items' >
                  {initialState?.data?.filtros?.map((campo, index) => {
                    const name = `asesoria.0`

                    return (
                      <InputType
                        key={index}
                        item={campo}
                        index={0}
                        idx={index}
                        key={`${0} - ${index}`}
                        reg={register}
                        nombre={`${name}`}
                        errors={errors}
                        col={campo.col}
                        type=""
                      />
                    )
                  })}

                </div>
                <br />
                <div className='buttonContainer' >
                  <button style={{ marginLeft: '0 !important' }} type='submit' className='confirmButton' >{t('botones.enviar')}</button>
                </div>

              </Form>


            </div>
            <br />
          </div>
        }



      </EditModal>
      <NotificationModal
        isOpen={notificacionShow}
        setIsOpen={setNotificacionShow}
        typeModal={notificacionType}
        mensaje={notificacionMensaje}
      />
    </>
  )
}
