import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { notificationsList } from '../pages/Navbar/Notifications/dataNotifications';

import { useDispatch } from 'react-redux';

import { cerrarSesion } from '../reducers/auth.reducer';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { NotificationExistSVG } from './icons/NotificationExistSVG';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlinedIcon';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Tooltip } from 'react-bootstrap'
import i18next from 'i18next';


// import Overlay from 'react-bootstrap/Overlay'
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import Logo from '../static/icons/cia-logo.png'

import './styles/Navbar.scss';
import { validarEntorno } from '../helpers/validarHost';
import Idioma from '../static/icons/idioma.png'
import { DateAxis } from '@amcharts/amcharts5/.internal/charts/xy/axes/DateAxis';
import moment from 'moment';


export default function Navbar(props) {

  // const { permisos } = props

  // console.log(permisos)

  // const [showModal, setShowModal] = useState(true);
  // const [show, setShow] = useState(false);
  // const target = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useDispatch()

  const latest = notificationsList.slice(0, 4);


  const changeIcon = () => {
    if (latest.length) {
      return <NotificationExistSVG />
    } else {
      return <NotificationsNoneIcon />
    }
  }


  const printNotifications = (array) => {
    return (
      array.map((item) => {
        return (
          <div className='notificationPopup' key={item.id}>
            <span>{item.Emisor}</span>
            <p>{item.body}</p>
          </div>
        )
      })
    )
  }
  const latestNotifications = () => {
    // let latest = notificationsList.slice(0,4);

    if (latest.length) {
      return (
        printNotifications(latest)
      )
    } else {
      return 'No tienes notificaciones pendientes'
    }
  }

  const popoverFocus = (
    <Tooltip id="button-tooltip" >
      {latestNotifications()}
      <Link to='/Notifications'>
        <button >
          Ver todas las notificaciones
        </button>
      </Link>
    </Tooltip>
  );

  const handleIdiomaChange = async (option) => {
    setSelectedOption(option);
    //onSelect(option);
    setIsOpen(false);
    const data = { hash: window.location.hash, idiomaUsuario: option.id }
    const request = await validarEntorno('JSON/respuesta_cambiar_idioma.html', 'POST', data);
    if (request.ok) {
      const res = await request.json();
      if (res.estado === 'OK') {
        await i18next.changeLanguage(option.value);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    if (props.idiomas) {
      const idioma = props.idiomas.elementos.find(x => x.id == props.idiomas.idiomaUsuario);
      setSelectedOption(idioma);
      i18next.changeLanguage(idioma.value);
    }
  }, [props.idiomas])

  useEffect(() => {
    moment.locale(i18next.language);
  }, [])

  // <{props.onMouseOut} onMouseOver={props.onMouseOver}>
  //       <OverlayTrigger
  //         show={show}
  //         // target={target.current}
  //         placement='bottom'
  //         className='overlayTrigger'
  //         onHide={() => setShowModal(!showModal)}
  //         rootClose
  //         overlay={popoverFocus}
  //         trigger='click'
  //       >
  //         <button className='navbarButton' ref={props.ref} onClick={()=> setShow(!show) } onMouseOut={props.onMouseOut} onMouseOver={props.onMouseOver}>
  //           {changeIcon()}
  //           {/* <NotificationsNoneIcon /> */}
  //         </button>
  //       </OverlayTrigger>
  //     </
  return (
    <Fragment>
      <nav id='navbarContainer'>
        <Link to='/home'>
          <img src={Logo} alt='cia logo'></img>
        </Link>
        <section id='navbarMenu'>

          {props.idiomas &&
            <div className='dropdown-idioma'>
              <div onClick={toggleDropdown} className='dropdownButton dropdown__toggle'>
                <img src={Idioma} alt="idioma" id='dropdown__image' />
                {selectedOption && (
                  <p className="dropdown__selectedOption">{selectedOption.label}</p>
                )}
              </div>
              {isOpen && (
                <div className="dropdown__menu">
                  {props.idiomas?.elementos?.map((option) => (
                    <div key={option.value} className="dropdown__menu__item" onClick={() => handleIdiomaChange(option)}>
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          }

          {
            // permisos.perfil &&
            <Link to='/perfil'>
              <button className='navbarButton'>
                <PersonOutlineIcon className='navbarIcon' />
              </button>
            </Link>
          }
          {
            // permisos.mensaje &&
            <a href={props.enlace} target='_blank' >
              <button className='navbarButton'>
                <MailOutlineIcon />
              </button>
            </a>
          }
          <button id='btnExit' className='navbarButton' onClick={() => {
            dispatch(cerrarSesion())
          }}>
            <ExitToAppIcon className='navbarIcon' />
          </button>
        </section>
      </nav>
    </Fragment>
  )
}
