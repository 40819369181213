import React, {useState} from 'react'

import LinearProgress from '@material-ui/core/LinearProgress';
// import {createTheme, ThemeProvider } from "@material-ui/core/styles"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import './BarrasLabelsHorizontales.scss'
import { max } from 'moment';



const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#ff5200',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

 const BarrasLabelsHorizontales = ({data, color, title, seccionDofaSelected, total}) => {

  const [detail, setDetail] = useState();

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: seccionDofaSelected === 'Fortalezas'
        ? '#38C945'
        : (seccionDofaSelected === ('oportunidades') || seccionDofaSelected === ('Oportunidades'))
          ? '#4180C8'
          : seccionDofaSelected === 'Amenazas'
            ? '#ff5200'
            : seccionDofaSelected === 'Debilidades'
              ? '#FFBE0A'
              : '#ff5200' ,
    },
  }))(LinearProgress);

  // console.log(seccionDofaSelected)

  const classes = useStylesFacebook();

  const getColorBySeccion = (seccion) => {


    switch (seccion) {
      case 'fortalezas':

        return '#38C945';
      case 'oportunidades' || 'Oportunidades':

        return '#4180C8';
      case 'amenazas':

        return '#ff5200';

      case 'debilidades':

        return '#FFBE0A';
      default:
        return 'gray';
    }




  }



  const colores = [
    "#2c5e99",
    "#05a4db",
    "#009688",
    "#8BC34A",
    "#F9CF00",
    "#F39C11",
    "#F46036",
    "#D7263D",
    "#674ea7"
  ]


  const calcularWidth = (valor) => {
  let max_value = 1; //data[0].valor;
  for (let i = 0; i < data.length; i++){ 
    max_value=Math.max(max_value, data[i].valor);
    
  } 
    let total = 100;
    
    let value = valor;

    let x = ((value * total)/max_value) - 12;
    
    let size;

    if(x <= 0){
      size = `1.2%`;
    }else{

      size = `${x}%`;
    }


    console.log(size+"size")
    console.log(x+"x")
    console.log(value+"value")
    console.log(total+"total")
    console.log(max_value+"max_value")

    


    return size
  }

  const renderGrafica = (data) => {


    return(
      <div className={classes.root} >
        {data?.map( (item, index) => {

          return(
            <div className='itemContainer' key={index}>
              <div style={{width: '100%' }}>
                <p style={{margin: '5px 0px 5px', fontSize:'13px'}} >{item.caracteristica}</p>
              </div>
              <div
                className='barraHorizontal progressBarHorizontalGraph'
                style={{
                }}
              >
                <div
                  onMouseOver={() => setDetail(index)}
                  onMouseLeave={() => setDetail()}
                  className='progressHorizontalGraph'
                  style={{
                    width: item.seccion ?  `${item?.valor * 9.4}%` : calcularWidth(item.valor),
                    backgroundColor: `${item.seccion ? getColorBySeccion(item.seccion) : item.color ? item.color : 'gray'}`
                  }}
                >
                  {/* {item.valor} */}
                {item.detalle && (detail === index) &&
                  <div
                    className='detalleItem'
                    style={{
                      background: item.color
                    }}
                  >
                    {item.detalle}
                  </div>

                }
              </div>
              <div className='valorItem' >{item.valor_label ? item.valor_label : item.valor}</div>
              </div>
              {/* <BorderLinearProgress
                // className='shadow'
                className={`barraHorizontal ${theme.linearProgress}`}
                variant="determinate"
                value={item.valor * 10}
                color={item.color ? item.color : "#F46036"}
                style={{
                  backgroundColor: 'rgb(230, 230, 230)',
                  color: 'green !important',
                  height: '2.5vh',
                  borderTopRightRadius: '7px',
                  borderBottomRightRadius: '7px'
                }}
              /> */}
            </div>
          )

        })
        }
      </div>
    )

  }






  return (
    <div className='mainGraphContainer' >
      {title &&
        <h5
          className='pagesTitles'
        >
          {title}
        </h5>
      }

      <div className='contArray' style={{width: '100%', height: 'auto'}} >
        {(data && data.length) ? renderGrafica(data) : <p style={{width: '100%', textAlign: 'center', margin: '50px auto', color: 'gray'}} >No hay información relacionada.</p>}
      </div>
      {total &&
          <>
            <h5 className='pagesTitles' >
              Total: {total}
            </h5>
          </>
        }
    </div>
  )
}

export {BarrasLabelsHorizontales}
