import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Col, Container, Nav, Row, Accordion } from 'react-bootstrap';
import { validarEntorno } from '../../../../../helpers/validarHost';
import Conclusiones from './Conclusion';
import Recomendaciones from './Recomendaciones';
import Bibliografia from './Bibliografia';
import { useTranslation } from 'react-i18next';

const Main = () => {

  const { id } = useParams()
  const { t } = useTranslation();


  const [listItems, setListItems] = useState({});
  const [eventFormId, setEventFormId] = useState(1);
  const [activeFormKey, setActiveFormKey] = useState(1);

  const items = async () => {
    const dataSend = {
      hash: window.location.hash,
      id: id
    }

    const request = await validarEntorno('JSON/conclusion_del_informe_json.html', 'POST', dataSend);

    if (request.ok) {
      const respuesta = await request.json();

      setListItems(respuesta);
    }
  }

  useEffect(() => {
    items();
  }, [activeFormKey])

  const renderForm = () => {
    if (Object.keys(listItems).length !== 0) {
      switch (eventFormId) {
        case 1:
          return <Conclusiones dataG={listItems.data.conclusiones} permisos={listItems.permisos} />
        case 2:
          return <Recomendaciones dataG={listItems.data.recomendaciones} permisos={listItems.permisos} />
        case 3:
          return <Bibliografia dataG={listItems.data.bibliografias} permisos={listItems.permisos} />
        default:
          return <></>
      }
    }
  }

  const eventFormIdValue = (value) => {
    setEventFormId(value);
    setActiveFormKey(value);
  }

  return (
    <Card className='card__box__surface cardUnderPestana' >
      <Card.Body>
        <Row className="row-content">
          <Col xl={2} lg={3} md={4} xs={0} id="sidebar-wrapper">
            <Nav defaultActiveKey={activeFormKey} activeKey={activeFormKey} className="shadow p-2 flex-column nav-items">
              <Nav.Link className="item-a" eventKey="1" onClick={() => eventFormIdValue(1)}>
                {t('gestion_realizada.cierre_informe.conclusiones')}
              </Nav.Link>
              <Nav.Link className="item-a" eventKey="2" onClick={() => eventFormIdValue(2)}>
                {t('gestion_realizada.cierre_informe.recomendaciones')}
              </Nav.Link>
              <Nav.Link className="item-a" eventKey="3" onClick={() => eventFormIdValue(3)}>
                {t('gestion_realizada.cierre_informe.bibliografia')}
              </Nav.Link>
            </Nav>
          </Col>
          <Col xl={10} lg={9} md={8} xs={12} id="page-content-wrapper">
            {renderForm()}
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
};

export default Main;
