import { Help } from '@material-ui/icons';
import { Tooltip } from 'primereact/tooltip';
import './btnAyuda.scss';

const BtnAyuda = (props) => {

  return (
    <div className='card flex justify-content-center'>
      <Tooltip
        target=".help__icon"
        position="right"
        style={{
          textAlign: 'center',
          maxWidth: '200px',
          fontSize: "12px"
        }} />
      <Help className='help__icon' data-pr-tooltip={props?.ayuda} />
    </div>
  )
}

export { BtnAyuda }