import { peticionSinToken } from "../helpers/peticiones"
import { validarEntorno } from "../helpers/validarHost"

// estado incial
const initialState = {
    data: {
        jsonData: null,
        columns: null,
        limite: null,
        numeroRegistros: null,
        pagina: null,
        totalNumeroPaginas: null
    },
    tipoEstudio: false,
    ordenarPor: false,
    estado: '',
    personalizado: ''
}

// types
const types = {
    cargarData: '[Tabla] cargar data',
    filtro: '[Tabla] filtro de tabla',
    estado: '[Tabla] estado de tabla'

}

// reducer 
export const tablasolicitudReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.cargarData:


            if (state.data.jsonData === null) {

                state = {
                    ...state,
                    data: action.payload
                }



                return state

            } else {

                return state
            }

        case types.filtro:

            state.data = action.payload.dataNew

            const arrFilter = state.data.jsonData.filter((ele) => {
                return ele.tipoEstudio === action.payload.valor.valor
            })


            state.data.jsonData = arrFilter



            return state


        default:
            return state;
    }
}


export const cargarData = () => {
    return async (dispatch) => {

        
        //PRODUCCION
        const respuesta = await validarEntorno('JSON/2.ejemploRespuestaTabla.json.html')
        const body = await respuesta.json();

        if (respuesta.ok) {
            dispatch({
                type: types.cargarData,
                payload: body
            })
        }
    }
}


export const filtroCarga = (valor) => {
    return async (dispatch) => {
        const respuesta = await peticionSinToken('JSON/selectsTablaSolicitudes_json.html', 'POST', valor)

        if (respuesta.ok) {
            dispatch({
                type: types.filtro,
                payload: {
                    valor,
                    dataNew: await respuesta.json()
                }
            })
        }
    }
}


export const estadoCarga = (valor) => {
    return async (dispatch) => {
        const respuesta = await peticionSinToken('JSON/2.ejemploRespuestaTabla.json.html')
        if (respuesta.ok) {
            dispatch({
                type: types.estado,
                payload: {
                    valor
                }
            })
        }
    }
}