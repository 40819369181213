import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Sparkles, RefreshCcw, FileDown, Pencil, Hash } from 'lucide-react'
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { validarEntorno } from '../../helpers/validarHost';
import { useParams } from 'react-router-dom';

function TranscriptionButton({ nombreAudio, url, TextoOriginal, TextoEditado, idGestion }) {

    const [loading, setLoading] = useState(false); //Indica si se está realizando una petición al servidor
    const [transcripcion, setTranscripcion] = useState(TextoOriginal); //Texto transcribido
    const [transcripcionEditada, setTranscripcionEditada] = useState(TextoEditado); //Texto transcribido editado
    const [visible, setVisible] = useState(false); //Indica si se muestra el dialogo
    const [editarVisible, setEditarVisible] = useState(false); //Indica si se muestra el dialogo de edición
    const toast = useRef(null); //Toast de retroalimentación de errores
    const textAreaEditar = useRef(null); //Referencia al textarea de edición
    const { seccion, id } = useParams();

    /**
     * Transcribe el audio y muestra un mensaje de éxito o error
     * en caso de que la transcripción sea exitosa, se muestra el texto transcribido
     * en caso de que falle, se muestra un mensaje de error
     */

    const transcribirAudio = async (e) => {
        e.preventDefault();
        setLoading(true);
        const request = await validarEntorno("JSON/ws/ia/transcripcion_audio.php", "POST", { url, id_gestion: idGestion, hash: window.location.hash, seccion, id });
        const response = await request.json();
        if (request.ok) {
            toast.current.show({ severity: 'success', summary: 'Éxito', detail: response.mensaje, life: 3000 });
            setTranscripcion(response.transcripcion);
            if (!transcripcionEditada) {
                setTranscripcionEditada(response.transcripcion);
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.mensaje, life: 3000 });
        }
        setLoading(false);
    };

    /**
     * Descarga la transcripción en un archivo de texto
     */

    const descargarTranscripcion = (e) => {
        e.preventDefault();
        const element = document.createElement("a");
        const file = new Blob([transcripcion], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${nombreAudio}.txt`;
        document.body.appendChild(element);
        element.click();
    }


    /**
     * Envía la transcripción editada al servidor
     */

    const guardarTranscripcion = async () => {
        const transcripcion = textAreaEditar.current.value;
        const requestDatos = {
            url,
            transcripcion_editada: transcripcion,
            id_gestion: idGestion,
            hash: window.location.hash,
            seccion,
            id
        }
        const request = await validarEntorno("JSON/ws/ia/guardar_transcripcion.html", "POST",  requestDatos );
        const response = await request.json();
        if (request.ok) {
            if(response.estado === 'error') {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.mensaje, life: 3000 });
            }else{
                toast.current.show({ severity: 'success', summary: 'Éxito', detail: response.mensaje, life: 3000 });
                setTranscripcionEditada(transcripcion);
                setEditarVisible(false);
            }
        }
        else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.mensaje, life: 3000 });
        }
    }

    /**
     * Muestra el dialogo con la transcripción
     */

    const mostrarTranscripcion = (e) => {
        e.preventDefault();
        setVisible(true)
    };

    /**
     * Muestra el dialogo de edición de la transcripción
     */

    const mostrarEditarTranscripcion = (e) => {
        e.preventDefault();
        setEditarVisible(true);
    }

    return (
        <>
            <div className='d-flex align-items-center gap-1'>
                <Button
                    loading={loading}
                    label={loading ? 'Transcribiendo...' : (transcripcion ? 'Ver transcripción' : 'Transcribir')}
                    size='small'
                    onClick={transcripcion ? mostrarTranscripcion : transcribirAudio}
                    icon={<Sparkles size={"16px"} color='#F6CD52' />
                    } />
                {transcripcion &&
                    <>
                        {/* 
                        <div className='icon__button'>
                            <button onClick={descargarTranscripcion} title='Descargar transcripción'>
                                <FileDown size={"18px"} color='var(--blue-600)' />
                            </button>
                        </div>
                        */}
                        <div className='icon__button'>
                            <button onClick={mostrarEditarTranscripcion} title='Editar transcripción'>
                                <Pencil size={"18px"} color='var(--blue-600)' />
                            </button>
                        </div>
                        {/* 
                        <div className='icon__button'>
                            <button onClick={transcribirAudio} title='Reintentar transcripción'>
                                <RefreshCcw size={"18px"} color='var(--blue-600)' />
                            </button>
                        </div>
                         */}
                    </>
                }

            </div>

            <Toast ref={toast} />

            <Dialog
                header={nombreAudio ? `Transcripción del audio - ${nombreAudio}` : "Transcripción del audio "}
                visible={visible}
                style={{ width: '50vw' }}
                onHide={() => setVisible(false)}>
                <p className="m-0" style={{ whiteSpace: 'pre-line' }}>
                    {transcripcion}
                </p>
            </Dialog>

            <Dialog
                header={nombreAudio ? `Editar transcripción del audio - ${nombreAudio}` : "Editar transcripción del audio "}
                visible={editarVisible}
                style={{ width: '50vw' }}
                onHide={() => setEditarVisible(false)}>
                    <textarea
                        className='mt-2 mb-2 form-control'
                        style={{ width: '100%', height: '300px' }}
                        defaultValue={transcripcionEditada} 
                        ref={textAreaEditar}
                    />
                    <div className='d-flex justify-content-end gap-2'>
                        <Button label='Cancelar' size='small' severity='danger' onClick={() => setEditarVisible(false)} />
                        <Button label='Guardar' size='small' onClick={() => guardarTranscripcion()} />
                    </div>
              
            </Dialog>
        </>
    )
}

export default TranscriptionButton