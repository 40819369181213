import {React, useState} from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment'
import { HelpSVG } from '../../../components/icons/HelpSVG';
import PopUpModal from '../../../helpers/PopUpModal';

// import { Grid } from '@material-ui/core';


import './Notifications.scss'

import { notificationsList } from './dataNotifications'

export default function Notifications() {

  const [open, setOpen] = useState(false)

  // console.log(notificationsList);
  return (
    <>
      <div className='titleBox' >
        <div className='titleContainer'>
          <h3 className='pagesTitles'>Notificaciones</h3>
          <div className='titleLine'> </div>
        </div>
        <div className='iconoAyuda' onClick={() => setOpen(true)} >
          <HelpSVG  />
        </div>
        <PopUpModal open={open} setOpen={setOpen} />
      </div>
      <Card className="shadow " style={{ borderRadius: '10px' }}>
        <Card.Body className='cardBody'>
          <div className='notificationList'>
            <table>
              <thead>
                <tr>
                  <th>Emisor</th>
                  <th>Notificación</th>
                  <th>Fecha</th>
                </tr>
              </thead>
              <tbody>
                {notificationsList.map((item) => {
                  return(
                    <tr className='notificationItem' key={item.id}>
                      <td id='tdEmisor'>{item.Emisor}</td>
                      <td id='tdNotification'>{item.body}</td>
                      <td id='tdDate'>{moment(item.date).format('LLL')}</td>
                    </tr>
                  )
                })}
              </tbody>
          </table>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}
