import { Form } from "react-bootstrap";
import { BtnAyuda } from "./BtnAyuda";


const Input = ({ item, handleInputChange, index, idx, reg, nombre, error, esEditable }) => {

    return (
        <Form.Group style={{ display: 'block', padding: '0px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
                <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : "" }}>{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
                {item.ayuda &&
                    <BtnAyuda ayuda={item.ayuda} />
                }
            </div>
            <Form.Control
                defaultValue={item.valor}
                name={`asesoria.${index}.${item.nombre}`}
                key={`${index} - ${idx}`}
                ref={reg}
                {...reg(`asesoria.${index}.${item.nombre}`, {
                    required: item.es_requerido
                })}
                style={{ border: error ? '1px solid #dc3545' : null }}
                disabled={esEditable}
            />
            {error ?
                (
                    error.type === 'required' ?
                        (
                            <span className="inputsInvalid">
                                Este campo es requerido
                            </span>
                        )
                        : null)
                : null}
        </Form.Group>
    );

}

export default Input;
