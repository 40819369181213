import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, Form } from 'react-bootstrap';

import NotficationModal from '../modales/NotificationModal'
import NotficationSuccessModal from '../modales/NotificationModal'
import { useDispatch, useSelector } from 'react-redux'
import { getDataReporte } from '../../reducers/reportesReducer'

import { HelpSVG } from '../../components/icons/HelpSVG'
import PopUpModal from '../../helpers/ModalAyudaReportes'

import { getTarjetas } from '../../reducers/reportesReducer'
import { getFiltros } from '../../reducers/reportesReducer'
import { useTranslation } from 'react-i18next';

// import GraficasInformes from '../../static/graficas.jpeg'

// import { tableInformes } from './informes/dataInformes';
// import { filtroTipoEstudio, obtenerData, ordenarPor } from '../../reducers/filtroTipoEstudio';

import '../styles/FiltroInformes.scss'
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
// import { clippingParents } from '@popperjs/core';
// import { castColor } from '@amcharts/amcharts4/core';

export default function FiltroInformes(props) {
  const { t } = useTranslation();
  const { dataInformes } = props
  const dataReportes = useSelector(store => store.reportesReducer)
  const { tarjetas } = dataReportes

  const dispatch = useDispatch()
  const ref = React.useRef();

  const hashReportes = window.location.hash



  const URL_BASE = `https://www.ciacosinte.site/cia2/`;
  const [tipoReporte, setTipoReporte] = useState('1')
  const [open, setOpen] = useState(false)
  const [tarjetasInf, setTarjetasInf] = useState([])


  const [selectedOptions, setSelectedOptions] = useState({
    hash: hashReportes,
    tipo_reporte: "",
    rango: "",
    from_tipo_fecha: "",
    to_tipo_fecha: "",
    investigacion_tipo: ""
  })
  const [notificationOpen, setNotificationOpen] = useState(false)
  const [notificacionSuccess, setNotificacionSuccess] = useState(false)
  const [idRegistro, setIdRegistro] = useState(false)

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  useEffect(() => {
    getTargetasPresidencia(dataInformes?.perfil);
    // console.log(selectedOptions)
  }, [selectedOptions.investigacion_tipo])


  const handleChangeSelect = (e) => {

    const { name, value } = e.target

    setSelectedOptions({
      ...selectedOptions,
      [name]: value,
      tipo_reporte: tipoReporte,
    })
  }

  const getTargetasPresidencia = async (perfil) => {

    if (perfil == 'presidencia_op') {
      dispatch(getTarjetas(selectedOptions))
      setTarjetasInf(tarjetas)
    }
  }

  const submitForm = (e) => {

    dispatch(getFiltros(selectedOptions))



    dataInformes.perfil !== 'presidencia_op' && dispatch(getDataReporte(selectedOptions))

    setNotificacionSuccess(true)
  }

  const optionSelectCard = (idComponente, link) => {
    return (
      <>
        {
          link && link !== ''
            ?
            <Link to={link} target='_blank'>
              <button className='botonDescargaReportes'>{t('botones.generar')}</button>
            </Link>

            :
            <button
              onClick={e => { handleSubmitRangoRequerido(e, idComponente) }}
              className='botonDescargaReportes'
            >
              {t('botones.generar')}
            </button>
        }
      </>
    )
  }


  const handleSubmitRangoRequerido = (e, idComponente) => {
    setIdRegistro(idComponente)

    e.preventDefault()

    // selectedOptions?.rango !== '' || (selectedOptions?.from_tipo_fecha !== '' && selectedOptions?.to_tipo_fecha !== '')
    //   ?
    //   <>
    //   submitForm()
    //   </>
    //   : setNotificationOpen(true)

    const formElement = document.getElementById('formInformeInvestigacion')

    if (selectedOptions.rango !== "" || selectedOptions?.from_tipo_fecha !== "" && selectedOptions?.to_tipo_fecha !== "") {
      submitForm(formElement)
    } else {
      setNotificationOpen(true)
    }
  }


  return (
    <>
      <Card className="shadow " style={{ borderRadius: '15px' }}>

        <div className='shadow headerInformes'>

          <div className='contenedorInfoReportes'>
            <div className='helpIcon' style={{ margin: '-8px 1px 1px auto', width: '30px' }} onClick={() => setOpen(true)} >
              <div className='contenedorIcono'>
                <HelpSVG />
              </div>
            </div>
            <div>
              <p className='tituloInfoReportes'>{t('filtro_informes.genera_tu_informe')}</p>
              <div className='contenedorInstruccionesReportes'>
                <h6 className='textInformes mt-3'>
                  <b className='tituloPasosGenerar'> 1. </b> {t('filtro_informes.escoge_caja_informe')}
                  <br />
                  <b className='tituloPasosGenerar'> 2. </b> {t('filtro_informes.diligencia_filtros')}
                  <br />
                  <b className='tituloPasosGenerar'> 3. </b> {t('filtro_informes.haz_clic_en_generar')}
                  <br />
                </h6>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className='contenedorCardSelects'>
          <Card className="shadow cardSelectsSeguimientoSolicitudes " >
            <Card.Body className='cardBodySelectsSeguimientoSolicitudes'>
              {dataInformes?.opcionesFechade?.length &&
                <Form.Group id='groupSelecFechade' className='fromGroupSelect' >
                  <Form.Label className='labelSelectFiltroInforme' >{t('formulario.label.fecha_de')}</Form.Label>
                  <Form.Control name="tipo_fecha" as="select" className="form-control formControlFiltroGeneral"
                    onChange={handleChangeSelect}
                  >
                    {dataInformes.opcionesFechade.map((ele, index) => {
                      return (
                        <option key={index} value={ele.value}>{ele.label}</option>
                      )
                    })
                    }
                  </Form.Control>

                </Form.Group>
              }

              <Form.Group id='groupSelecFromFecha' className='fromGroupSelect' >
                <Form.Label className='labelSelectFiltroInforme'>{t('formulario.label.desde')} - {t('formulario.label.hasta')}</Form.Label>
                <ReactDatePicker
                  className='formControlFiltroGeneral date__range__picker'
                  isClearable={true}
                  disabled={false}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  monthsShown={dataInformes?.fecha_conf?.meses_mostrados ? dataInformes.fecha_conf.meses_mostrados : 1}
                  includeDateIntervals={
                    dataInformes?.fecha_conf?.fecha_min && dataInformes?.fecha_conf?.fecha_max ?
                      [{ start: moment(dataInformes.fecha_conf.fecha_min).toDate(), end: moment(dataInformes.fecha_conf.fecha_max).toDate() }]
                      : startDate && dataInformes?.fecha_conf?.dias_max ?
                        [{ start: moment(startDate).toDate(), end: moment(startDate).add(dataInformes?.fecha_conf?.dias_max - 1, 'days').toDate() }]
                        : startDate && dataInformes?.fecha_conf?.meses_max ?
                          [{ start: moment(startDate).toDate(), end: moment(startDate).add(dataInformes?.fecha_conf?.meses_max, 'months').toDate() }]
                          : undefined
                  }
                  onChange={(e) => {
                    const [start, end] = e;
                    setSelectedOptions({
                      ...selectedOptions,
                      from_tipo_fecha: moment(start).format('YYYY-MM-DD'),
                      to_tipo_fecha: moment(end).format('YYYY-MM-DD')
                    })
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  clearButtonClassName='button-close-datepicker'
                >
                </ReactDatePicker>
              </Form.Group>
              <Form.Group id='groupSelecRango' className='fromGroupSelect' >
                <Form.Label className='labelSelectFiltroInforme'>{t('formulario.label.rango')}</Form.Label>
                <Form.Control as='select' onChange={handleChangeSelect} name='rango' className='formControlFiltroGeneral' type='date' defaultValue=''>
                  <option style={{ display: 'none' }}></option>
                  <option disabled value=''>{t('formulario.label.seleccione_rango')}</option>
                  {dataInformes?.dataReportes?.length > 0 &&
                    dataInformes?.dataReportes[0].opciones.map((ele, index) => {
                      return (
                        <>
                          <option key={index} value={ele.value} onChange={() => {

                          }
                          }>{ele.label}</option>
                        </>
                      )
                    })}
                </Form.Control>
              </Form.Group>
              <Form.Group id='groupSelectTipoSolicitud' className='fromGroupSelect'>
                <Form.Label id='selectTipoSolicitud' className="labelSelectFiltroInforme ">{t('formulario.label.tipo_solicitud')}</Form.Label>
                <Form.Control name='investigacion_tipo' as='select' value={selectedOptions?.investigacion_tipo} className="formControlFiltroGeneral" onChange={handleChangeSelect}>

                  {dataInformes?.opcionesTipoInvestigacion?.length > 0 &&
                    dataInformes?.opcionesTipoInvestigacion.map((ele, index) => {
                      return (
                        <option key={index} value={ele.value ? ele.value : ''}>{ele.label}</option>
                      )
                    })

                  }
                </Form.Control>
              </Form.Group>
            </Card.Body>
          </Card>

        </div>
        <main className='contenedorTarjetas' style={{ minHeight: '150px' }}>
          {(dataInformes?.perfil === 'presidencia_op') &&
            <>
              {tarjetas?.length > 0 && tarjetas?.map((tarjeta, index) => {

                return (
                  <div style={{ height: '130px' }} key={index}>
                    {tarjeta.visualizar === true &&
                      <Card.Body
                        className='cardBody'
                        id={tarjeta.id}
                        onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: tarjeta?.tipo_reporte }) }}>
                        <div>
                          <h5 className='checboxGroupTitle'>{tarjeta.titulo}</h5>
                        </div>
                        {tarjeta.link
                          ?
                          <a href={tarjeta.link} target='_blank'>
                            <button className='botonDescargaReportes'>{t('botones.generar')}</button>
                          </a>
                          :
                          <Form
                            // id={tarjeta.id+'form'}
                            className='formSelectFiltroInforme'
                            onChange={(e) => {
                              // setTipoReporte(tarjeta?.tipo_reporte)
                              handleChangeSelect(e)
                            }}
                          >
                            {optionSelectCard(tarjeta.tipo_reporte, tarjeta.link)}
                          </Form>
                        }
                      </Card.Body>
                    }
                  </div>
                )
              })}

            </>
          }
          {(dataInformes?.perfil === 'investigaciones-administrativas') &&
            <>
              {dataInformes.tarjetas.length && dataInformes.tarjetas.map((tarjeta, index) => {
                return (
                  <div key={index}>
                    {tarjeta.visualizar === true &&
                      <Card.Body className='cardBody' id={tarjeta.id} onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: tarjeta.tipo_reporte }) }}>
                        <div>
                          <h4 className='checboxGroupTitle'>{tarjeta.titulo}</h4>
                        </div>
                        {tarjeta.link
                          ?
                          <a href={tarjeta.link} target='_blank'>
                            <button className='botonDescargaReportes'>{t('botones.generar')}</button>
                          </a>
                          :
                          <Form target='_blank' id={tarjeta.id + 'form'} className='formSelectFiltroInforme'
                            onChange={(e) => {
                              // setTipoReporte(tarjeta?.tipo_reporte)
                              handleChangeSelect(e)
                            }}
                          >
                            {optionSelectCard(tarjeta.tipo_reporte, tarjeta.link ? tarjeta.link : '')}
                          </Form>
                        }
                      </Card.Body>
                    }
                  </div>
                )
              })}

            </>
          }

          {
            dataInformes.perfil === "inteligencia" &&
            <>
              {dataInformes.cardSeguimientoSolicitudes === true &&
                <Card.Body className='cardBody' id='cardSeguimientoSolicitudes' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '11' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.seguimiento_solicitudes')}</h4>
                  </div>
                  <Form id='formInformeSeguimientoSolicitudes' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('11')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(11)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardEstadoSalud === true &&
                <Card.Body className='cardBody' id='cardEstadoSalud' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '12' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.estado_salud')}</h4>
                  </div>
                  <Form id='formInformeEstadoSalud' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('12')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(12)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardPerfilSociodemografico === true &&
                <Card.Body className='cardBody' id='cardPerfilSocio' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '13' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.perfil_sociodemografico')}</h4>
                  </div>
                  <Form id='formInformePerfilSociodemografico' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('13')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(13)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardNivelEscolaridad === true &&
                <Card.Body className='cardBody' id='cardEscolaridad' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '14' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.nivel_escolaridad')}</h4>
                  </div>
                  <Form id='formInformeNivelEscolaridad' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('14')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(14)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardAntecedentes === true &&
                <Card.Body className='cardBody' id='cardAntecedentes' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '15' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.anotaciones')}</h4>
                  </div>
                  <Form id='formInformeAntecedentes' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('15')
                    handleChangeSelect(e)

                  }}>
                    {optionSelectCard(15)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardBienestar === true &&
                <Card.Body className='cardBody' id='cardBienestar' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '16' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.bienestar')}</h4>
                  </div>
                  <Form id='formInformeBienestar' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('16')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(16)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardInformacionFinanciera === true &&
                <Card.Body className='cardBody' id='cardInfoFinanciera' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '17' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.informacion_financiera')}</h4>
                  </div>
                  <Form id='formInformeInformacionFinanciera' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('17')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(17)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardNivelRiesgo === true &&
                <Card.Body className='cardBody' id='cardNivelRiesgo' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '18' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.nivel_riesgo')}</h4>
                  </div>
                  <Form id='formInformeNivelRiesgo' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('18')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(18)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardInformeDetallado === true &&
                <Card.Body className='cardBody' id='cardPrincipalesClientes' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '20' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.principales_clientes')}</h4>
                  </div>
                  <Form id='formInformeInformeDetallado' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('20')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(20)}

                  </Form>
                </Card.Body>
              }
              {dataInformes.cardInformeDetallado === true &&
                <Card.Body className='cardBody' id='cardRutinaMantenimiento' onMouseEnter={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '21' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.alertas')}</h4>
                  </div>
                  <Form id='formInformeInformeDetallado' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('21')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(21)}
                  </Form>
                </Card.Body>
              }


            </>
          }

          {
            dataInformes.perfil === "ocensa" &&
            <>
              {dataInformes.cardSeguimientoSolicitudes === true &&
                <Card.Body className='cardBody' id='cardSeguimientoSolicitudes' onClick={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '11' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.social')}</h4>
                  </div>
                  <Form id='formInformeSeguimientoSolicitudes' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('11')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(11)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardEstadoSalud === true &&
                <Card.Body className='cardBody' id='cardEstadoSalud' onClick={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '12' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.politico')}</h4>
                  </div>
                  <Form id='formInformeEstadoSalud' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('12')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(12)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardPerfilSociodemografico === true &&
                <Card.Body className='cardBody' id='cardPerfilSocio' onClick={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '13' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.economico')}</h4>
                  </div>
                  <Form id='formInformePerfilSociodemografico' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('13')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(13)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardNivelEscolaridad === true &&
                <Card.Body className='cardBody' id='cardEscolaridad' onClick={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '14' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.ambiental')}</h4>
                  </div>
                  <Form id='formInformeNivelEscolaridad' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('14')
                    handleChangeSelect(e)
                  }}>
                    {optionSelectCard(14)}
                  </Form>
                </Card.Body>
              }
              {dataInformes.cardAntecedentes === true &&
                <Card.Body className='cardBody' id='cardAntecedentes' onClick={() => { setSelectedOptions({ ...selectedOptions, tipo_reporte: '15' }) }}>
                  <div>
                    <h4 className='checboxGroupTitle'>{t('filtro_informes.conflicto')}</h4>
                  </div>
                  <Form id='formInformeAntecedentes' className='formSelectFiltroInforme' onChange={(e) => {
                    setTipoReporte('15')
                    handleChangeSelect(e)

                  }}>
                    {optionSelectCard(15)}
                  </Form>
                </Card.Body>
              }



            </>
          }



        </main>

      </Card>
      <NotficationModal isOpen={notificationOpen} setIsOpen={setNotificationOpen} typeModal='seleccionarFechas' />
      <PopUpModal dataModal={props.dataModal} open={open} setOpen={setOpen} />

      {/* Agregar prop para saber que se renderiza y que no. */}
      <NotficationSuccessModal
        isOpen={notificacionSuccess}
        setIsOpen={setNotificacionSuccess}
        typeModal='informeGenerado'
        idGrafica={idRegistro}
        perfil={dataInformes.perfil}
      />
      <br />
      <br />
    </>
  )
}
