import React from 'react'
import { Card } from './Card'
import { Skeleton } from 'primereact/skeleton';
        

export default function LoadingCard() {
    return (
        <Card>
            <Skeleton width="100%" height='100%'/>
        </Card>
    )
}
