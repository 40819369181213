import { data } from './data';
import Map from './Map';
import {credenciales} from '../../helpers/credentials.google';
import Loader from '../icons/Loader';

const main = () => {

    const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`;
    
    return(
        <Map 
            data={data}
            googleMapURL={mapURL}
            containerElement = {<div style={{height:'100%'}}></div>}
            mapElement = {<div style={{height:'100%'}}></div>}
            loadingElement = {<div></div>}
        />
    );
}

export default main;