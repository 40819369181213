import React from 'react'

export const EditSVG = (props) => {
    const {fill} = props
    return (
        <svg version="1.1" id="Capa_1" fill={fill ? fill : "#213265"} width='20px' height='20px'  x="0px" y="0px"	 viewBox="0 0 512.009 512.009">
            <g>
                <g>
                    <path d="M397.96,330.099c-13.755,0-24.872,11.118-24.872,24.872v78.125c0,13.73-11.143,24.872-24.872,24.872H74.617
                        c-13.73,0-24.872-11.143-24.872-24.872V109.754c0-13.73,11.143-24.872,24.872-24.872h144.509
                        c13.754,0,24.872-11.118,24.872-24.872c0-13.754-11.118-24.872-24.872-24.872H74.617C33.478,35.136,0,68.615,0,109.754v323.342
                        c0,41.139,33.478,74.617,74.617,74.617h273.597c41.139,0,74.617-33.453,74.617-74.617v-78.124
                        C422.832,341.217,411.714,330.099,397.96,330.099z" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M484.193,31.977c-18.057-18.057-41.637-27.161-65.39-27.658c-24.997-0.547-50.143,8.506-69.046,27.434L181.37,200.14
                        c-24.574,24.674-38.105,57.406-38.105,92.177v55.714c0,13.754,11.118,24.872,24.872,24.872h55.714
                        c34.772,0,67.504-13.531,92.202-38.13l168.163-168.163C521.277,129.527,521.277,69.087,484.193,31.977z M280.91,299.581
                        c-15.247,15.197-35.543,23.579-57.057,23.579H193.01v-30.842c0-21.515,8.382-41.811,23.554-57.033l111.006-111.006l64.32,64.32
                        L280.91,299.581z M449.023,131.467L427.06,153.43l-64.32-64.32l21.962-21.962c17.759-17.759,46.611-17.709,64.32,0
                        C466.732,84.881,466.732,113.733,449.023,131.467z" />
                </g>
            </g>
        </svg>
    )
}
