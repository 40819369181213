import { validarEntorno } from "../helpers/validarHost";

//estado inicial
const stateReportes = {
  infoReportes: {},
  dataGraficas: false,
  reporteGenerado: {},
  tarjetas: [],
  filtros_presidencia: {},
  filtrosWidg:{},
}
//types
const DATA_REPORTES = 'DATA_REPORTES'
const OBTENER_DATA_REPORTE = 'OBTENER_DATA_REPORTE'
const OBTENER_TARJETAS = 'OBTENER_TARJETAS'
const GUARDAR_FILTROS = 'GUARDAR_FILTROS'
const GUARDAR_FILTROS_WID = 'GUARDAR_FILTROS_WID'

//reducers
export default function reportesReducer(state = stateReportes, action) {
  switch (action.type) {
    case DATA_REPORTES:
      state = {
        ...state,
        infoReportes: action.payload
      }
    return state;
    case OBTENER_DATA_REPORTE:
      state = {
        ...state,
        reporteGenerado: action.payload
      }
    case OBTENER_TARJETAS:
      state = {
        ...state,
        tarjetas: action.payload.tarjetas
      }
    case GUARDAR_FILTROS:
      state = {
        ...state,
        filtros_presidencia: action.payload
      }

    case GUARDAR_FILTROS_WID:
      state = {
        ...state,
        filtrosWidg: action.payload
      }
    return state;

    default:
      return state
  }
}

//actions

export const fetchDataReportes = (ruta) => async (dispatch) => {

  // console.log(ruta)

  try {
    const requestReportes = await validarEntorno('JSON/inv_adm_reportes_json.html', 'POST', ruta
    // , {
    //   method: 'POST',
    //   body: JSON.stringify({ruta})
    // }
    )

    if(requestReportes.ok){
      dispatch({
        type: 'DATA_REPORTES',
        payload: await requestReportes.json()
      })
    }
  } catch (error) {
    console.log(error.message)
  }
}


export const getDataReporte = (selectedOptions) => async (dispatch) => {
  // console.log(ruta)

  // const data = {
  //   ...selectedOptions,
  //   // tipo_reporte: tipoReporte
  // }
  // console.log(data)
  try {
    const request = await validarEntorno('JSON/data_respuesta_reporte.html', 'POST', selectedOptions)
    if(request.ok){
      dispatch({
        type: 'OBTENER_DATA_REPORTE',
        payload: await request.json()
      })
    }
  } catch (error) {
    console.log('Error obteniendo la informacioń del reporte. ' + error.message)
  }
}

export const getTarjetas = (selectedOptions) => async (dispatch) => {

  const obj = selectedOptions
  try {
    const request = await validarEntorno('JSON/inv_adm_tarjetas.html', 'POST', obj)
    if(request.ok){
      dispatch({
        type: 'OBTENER_TARJETAS',
        payload: await request.json()
      })
    }
  } catch (error) {
    console.log('Error obteniendo las tarjetas. ' + error.message)
  }
}

export const getFiltros = (filtros) => (dispatch) => {

  try {
    dispatch({
      type: 'GUARDAR_FILTROS',
      payload: filtros
    })
  } catch (error) {
    console.log('Error obteniendo los filtros. ' + error.message)
  }
}

export const filtrosWid = (filtros) => (dispatch) => {

  try {
    dispatch({
      type: 'GUARDAR_FILTROS_WID',
      payload: filtros
    })
  } catch (error) {
    console.log('Error obteniendo los filtros. ' + error.message)
  }
}

