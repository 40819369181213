import React from 'react'

import { Card, Form } from 'react-bootstrap'

import './styles/InformacionEstudio.scss'

import OpcionesCiudades from '../../../../../helpers/OpcionesCiudades'


export default function InformacionEstudio(props) {

    const {data} = props

    const submitInfoEstudio = () => {

    }

    return (
    <Card className='shadow cardGeneral'>
        <Card.Body className='cardBodyInfoEstudio'>
        <br/>
        <h4 className='titleCard'>Información de estudio</h4>
        <br/>
        <Form noValidate validated={props.validated} onSubmit={submitInfoEstudio} id='formInfoEstudioInteligencia'>
            {data.editarInfoEstudio
            ?
            <>
                <div className='filasPar'>
                    <Form.Group className='inputGroup'>
                        <Form.Label>Tipo de identificación</Form.Label>
                        <div className='inputContainer'>
                            <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='Tipo de identificación'
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Ingrese el tipo de identificación</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group className='inputGroup'>
                        <Form.Label>Número de identificación</Form.Label>
                        <div className='inputContainer'>
                            <Form.Control name='numeroSopSNN' type='text'  placeholder='Número de identificación'
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Ingrese el número de identificación</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </div>
                <div className='filasPar'>
                    <Form.Group className='inputGroup'>
                        <Form.Label>Nombres</Form.Label>
                        <div className='inputContainer'>
                            <Form.Control className='inputInfoEstudio' name='nombres' type='text'  placeholder='Nombres'
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Ingrese los nombres</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group className='inputGroup'>
                        <Form.Label>Apellidos</Form.Label>
                        <div className='inputContainer'>
                            <Form.Control name='numeroSopSNN' type='text'  placeholder='Apellidos'
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Ingrese los apellidos</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </div>
                <div className='filasPar'>
                <OpcionesCiudades/>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha de expedición</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='date'  placeholder='Fecha de expedición'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese fecha de expedición</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha fin cliente</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' type='date' name='numeroSopSNN'   placeholder='Fecha final cliente'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la fecha final del cliente</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Fecha fin Cosinte</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='date'  placeholder='número Sop/SNN'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese la fecha final de Cosinte</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Número Sop/SNN</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='número Sop/SNN'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el número Sop/SNN</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Número Sop/SNN</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='número Sop/SNN'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el número Sop/SNN</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <div className='filasPar'>
                <Form.Group className='inputGroup'>
                    <Form.Label>Teléfono</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control className='inputInfoEstudio' name='numeroSopSNN' type='text'  placeholder='número Sop/SNN'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el número de teléfono</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className='inputGroup'>
                    <Form.Label>Cargo al que aspira</Form.Label>
                    <div className='inputContainer'>
                        <Form.Control name='numeroSopSNN' type='text'  placeholder='número Sop/SNN'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>Ingrese el número Sop/SNN</Form.Control.Feedback>
                    </div>
                </Form.Group>
                </div>
                <br/>
                <button className='confirmButton'> Enviar </button>
                
            </>
            :
            <>
            </>
            }

        </Form>
        </Card.Body>
    </Card>
    )
    }
