import { Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import '../../helpers/controles/styles/controles.scss';
import TablaRegistros from "../../components/GestionadorDinamico/TablaRegistrosArchivos";
import { useParams } from "react-router-dom";
import Loader from '../icons/Loader';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const FormularioArchivos = ({ idSegundoNivel, fileObjects, setFileObjects, titulo }) => {

    const [data, setData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [type, setType] = useState('');
    const { id } = useParams();
    const HASH = window.location.hash;

    const handleImageData = () => {

        setData([]);

        const files = document.getElementById('input-files').files;

        const filArr = Array.from(files);

        setType('listado_archivos');

        if (filArr.length > 0) {
            let newFiles = filArr.filter(file => !data.find(f => f.name === file.name));
            setData([...newFiles]);
            if (setFileObjects && fileObjects ){

                console.log(newFiles)
                console.log(data)
                setFileObjects(newFiles)
            }
        }
    }


    const deleteFile = (name) => {

        let newFiles = [...data];

        newFiles = newFiles.filter((obj) => {
            return obj.name !== name;
        });
        setData(newFiles);
    }

    const onHandleFiles = async (e) => {

        e.preventDefault();

        setIsLoad(true);

        let formData = new FormData();

        formData.append('hash', HASH);
        formData.append('id', id);
        formData.append('id_segundo_nivel', idSegundoNivel);
        formData.append('tipo', 'form_archivos');

        const files = document.getElementById('input-files').files;

        const fileArr = Array.from(files);

        fileArr.forEach((item, index) => {
            formData.append(`file[${index}]`, item)
        });

        const url = 'JSON/GestionDinamica/enviar_archivos.html';

        // const url = 'https://190.60.31.59/cia2_pre2/JSON/test.php';

        await fetch(url, {
            method: 'POST',
            body: formData,
            processData: false,
            contentType: false,
        })
            .then(response => response.json())
            .then(res => {
                setData(res.data);
                setType('resultado_cargue');
                files.value = null;
                setIsLoad(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoad(false);
            });
    }


    useEffect(() => {
        setData([]);
    }, [idSegundoNivel]);

    return (
        <>
            {!isLoad ? (
                <Form onSubmit={onHandleFiles} style={{padding: '0'}}>
                    <Form.Group style={{ display: 'block' }}>
                        <Form.Label>{ (titulo) ? titulo : `Cargue de varios archivos`}</Form.Label>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <>
                                    <Form.Group style={{ display: 'block', marginLeft: '1em' }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '100%' }}>
                                                <label className="custom-file-upload">
                                                    <input id="input-files" type="file" name={`archivo`} onChange={(e) => handleImageData(e)} multiple />
                                                    <CloudUploadIcon style={{ fontSize: 50 }} />
                                                </label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    {(!fileObjects || !setFileObjects) &&
                                        <div className='buttonContainer'>
                                            <Button type="submit" className='confirmButton'>Enviar</Button>
                                        </div>
                                    }
                                </>
                            </div>
                            <div>
                            </div>
                            <TablaRegistros type={type} data={data} deleteFile={deleteFile} />
                        </div>
                    </Form.Group>
                </Form>
            ) : <Loader />}
        </>
    );

}

export default FormularioArchivos;
