import React, { useEffect, useState } from 'react'
import {  useHistory } from 'react-router';
import {  useParams } from 'react-router-dom';

import { Form, Card } from 'react-bootstrap';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal'


export const AprobarErrorRadicacion = ({ele}) => {

  const { id } = useParams()


  const [changeForm, setChangeForm] = useState({
    aprobacion: '',
    observacion: ''
  })

  const [confirmacionEnvio, setConfirmacionEnvio] = useState(false)

  useEffect(() => {
    setChangeForm({
      aprobacion: '',
      observacion: ''
    })
  }, [])

  const handleChange = ({target}) => {
    setChangeForm({
      ...changeForm,
      [target.name]: target.value
      
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const data = {
      hash: window.location.hash,
      idSolicitud: id,
      formData: changeForm
    }



    const envioSolicitudObservacion = await validarEntorno('JSON/aprobar_error_inv_adm.html', 'POST', data)

    if(envioSolicitudObservacion.ok){

      const respuesta = await envioSolicitudObservacion.json()

      if(respuesta.solicitud === 'ok'){
        setConfirmacionEnvio(true)
        setChangeForm({
          aprobacion: '',
          observacion: ''
        })
      }

    }

  }

  

  return (
    <>
      <Card className='shadow cardUnderPestana'>

        <Card.Body>
        <h5 className='titleCard'>Aprobar error de radicación</h5>
        <br/>
        <div className='contenedorTextAreaGeneral'>
          <Form onSubmit={handleSubmit}>
            <div style={{display:'flex',  }}>
              <Form.Group  style={{display:'flex', flexDirection:'column', alignItems:'flex-start', width:'50%', marginRight: '12px' }}>
                <Form.Label className='observacionesLabel' style={{marginLeft: '0px'}}>
                    Aprobación *
                </Form.Label>
                <Form.Control 
                  required
                  // className='textAreaGeneral' 
                  name='aprobacion' 
                  as='select'
                  placeholder='Observaciones'
                  onChange={(e) => handleChange(e)}
                  style={{width:'100%'}}
                  disabled={!ele.editar}
                  defaultValue={ele.valuesInputs.aprobacion}
                >
                  <option disabled value=''>--Seleccione--</option>
                  <option>Aprobar</option>
                </Form.Control>
                <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
              </Form.Group>
            </div>
            <br/>
            <Form.Group className='formGroupTextArea'>
              <Form.Label className='observacionesLabel' style={{marginLeft: '0px'}}>
                  Observaciones
              </Form.Label>
              <Form.Control 
                className='textAreaGeneral' 
                name='observacion' 
                id='observacionesTextArea' 
                as='textarea'
                placeholder='Observaciones'
                onChange={(e) => handleChange(e)}
                disabled={!ele.editar}
                defaultValue={ele.valuesInputs.observaciones}
              ></Form.Control>
              <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
            </Form.Group>
            <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                {changeForm.aprobacion !== ''
                    ?
                    <button type="submit" className='botonAddBeneficiario confirmButton'>
                        Guardar
                    </button>
                    :
                    <button disabled className='botonAddBeneficiario botonDeshabilitado'>
                    Para solicitar una observación u objeción, completa los campos de solicitud.
                    </button>
                }
                
            </div>

          </Form>
        </div>
        </Card.Body>
      </Card>
      <NotificationModal 
        typeModal='crearSolicitud' 
        isOpen={confirmacionEnvio} 
        setIsOpen={setConfirmacionEnvio} 
      />
    </>
  )
}
