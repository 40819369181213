import { Fragment, useEffect, useState, useRef } from "react";
import { Card, CarouselItem, Col, Form, Row, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import Carousel from 'react-bootstrap/Carousel'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import BuscadorDosNiveles from '../presidencia/BuscadorDosNiveles'

// import moment from 'moment'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

import SaveIcon from '@material-ui/icons/Save';
import AssignmentTurnedInOutlined from '@material-ui/icons/AssignmentTurnedInOutlined';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';

import NotficationModal from '../modales/NotificationModal'
import EditModal from '../modales/EditModal'
import Select from 'react-select';
import { useTranslation } from "react-i18next";

import './Container.scss';
// import { Columns3D } from "./3DColumns";
// import PartitionedBarChart from './PartitionedBarChart'
// import PieChartAsBullets from './PieChartAsBullets'
// import SolidGauge from './SolidGauge'
// import DivergentChart from './Divergente'
import Dona2 from "./Dona2";
// import FunnelGradient from "./FunnelGradient";
import GeneralWidgets from './GeneralWidgets'
// import DivergentChartAmplia from './DivergentChartAmplia'
// import HerraduraExpandible from './HerraduraExpandible'
// import TortadeTorta from './TortadeTorta'
// import { MediaTorta } from './MediaTorta'
import SortedBarChart from './SortedBarChart'
// import { FullDonut3D } from './FullDonut3D'
import DonaDosniveles from './DonaDosniveles'
// import GraficaConsolidado from './GraficaConsolidado'


import { IconoCompromisos } from '../icons/IconoCompromisos'
import { OportunidadSVG } from '../icons/secciones_panel/OportunidadSVG'
import { NovedadSVG } from '../icons/secciones_panel/NovedadSVG'
import { ClientesSVG } from '../icons/secciones_panel/ClientesSVG'
// import {ServiciosSVG} from '../icons/secciones_panel/ServiciosSVG'
import { RiesgoSVG } from '../icons/secciones_panel/RiesgoSVG'
import { AnotacionesSVG } from '../icons/secciones_panel/AnotacionesSVG'

// import { datosDivergentes } from '../../helpers/datosDivergentes'

// import { vistasMultimedia } from '../../reducers/auth.reducer.js'
import { validarEntorno } from '../../helpers/validarHost';
import PestanaGrafica from './PestanaGrafica';



import Loader from '../icons/Loader'
// import LoaderModal from '../icons/Loader'

export const ContainerD = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(0);

  const [permisos, setPermisos] = useState(false)
  const [showSelectComponente, setShowSelectComponente] = useState(false)
  const [idDepartamento, setIdDepartamento] = useState('')
  const [componenteSeleccionado, setComponenteSeleccionado] = useState([]);
  const [tipoModal, setTipoModal] = useState('')
  const [tipoModal2, setTipoModal2] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [choiceDepartamento, setChoiceDepartamento] = useState();
  const refChoiceDepartamento = useRef();

  const [listaComponente, setListaComponente] = useState({
    show: false,
    data: []
  })
  const [sectionOpen, setSectionOpen] = useState('');
  const [data, setData] = useState({})
  const [carga, setCarga] = useState({
    loading: true,
    error: null
    // data: undefined
  })

  const [dataGrafica, setDataGrafica] = useState({
    show: false,
    data: {},
    error: null
  })

  const [inputsFilters, setInputsFilters] = useState({
    tipoComponente: '',
    departamento: '',
    rango: '',
    desde: '',
    hasta: '',
    componentes: [],
    inicial: {}
  })

  const [regionales, setRegionales] = useState(false);
  const [usuariosCliente, setUsuariosCliente] = useState(false);

  const locacion = window.location.hash


  const [notificationOpen, setNotificationOpen] = useState(false)
  const [media, setMedia] = useState({
    tipo: '',
    contenido: ''
  })

  const [perfilMedia, setPerfilMedia] = useState("")

  const [showLoaderGraphs, setShowLoaderGraphs] = useState(false);

  function getSteps() {
    return [t('formulario.label.selecciona_departamento'), t('formulario.label.selecciona_rango_tiempo')];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className="contenedor_step">
            <Form.Label className='label_item_select'>{t('formulario.label.departamento')}</Form.Label>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
              <Select
                id="departamento"
                name="departamento"
                className='input_departamento'
                ref={refChoiceDepartamento}
                value={choiceDepartamento}
                options={data.presidencia.filtros.departamentos}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option.id}
                onChange={(choice) => { getDataInputs2(choice, "departamento"); setChoiceDepartamento(choice) }}>
              </Select>
              <button
                style={{
                  backgroundColor: choiceDepartamento === undefined || choiceDepartamento === null ? '#707070' : '#213265', color: '#fff', borderRadius: "5px", padding: "3px 5px", marginLeft: "24px"
                }}
                onClick={() => { getDataInputs2(choiceDepartamento, "departamento") }}
                disabled={choiceDepartamento === undefined}>
                <SettingsApplicationsIcon id='iconoPrecarga' />
              </button>
            </div>
            {/*
              <select
                className='formControlFiltroGeneral'
                as='select'
                defaultValue={inputsFilters.departamento}
                onChange={(e) => getDataInputs(e)}
                name='departamento'
                id='departamento'
              >
                <option selected disabled value="">--Seleccione--</option>
                {data.presidencia.filtros.departamentos.map((dep) => {
                  return (
                    <option value={dep.id}>
                      {dep.nombre}
                    </option>
                  )
                })}
              </select>
              */}
          </div>
        )
      case 1:
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>


            {inputsFilters.rango === '' &&
              <>
                <Form.Group className='formGroupVertical'>
                  <Form.Label className='label_item_select' >{t('formulario.label.desde')}</Form.Label>
                  <Form.Control
                    style={{ border: '0' }}
                    className='formControlFiltroGeneral'
                    type='date'
                    defaultValue={inputsFilters.desde}
                    name='desde'
                    onChange={(e) => getDataInputs(e)}
                    id='desde'

                  />
                </Form.Group>
                <Form.Group className='formGroupVertical'>
                  <Form.Label className='label_item_select' >{t('formulario.label.hasta')}</Form.Label>
                  <Form.Control
                    style={{ border: '0' }}
                    id='groupSelecToFecha'
                    className='formControlFiltroGeneral'
                    type='date'
                    defaultValue={inputsFilters.hasta}
                    name='hasta'
                    onChange={(e) => getDataInputs(e)}

                  />

                </Form.Group>
              </>
            }

            {(inputsFilters.desde === '' && inputsFilters.hasta === '') &&
              <Form.Group className='formGroupVertical'>
                <Form.Label className='label_item_select' >{t('formulario.label.rango')}</Form.Label>
                {/*}
                <Select
                  name="rango"
                  width='100%'
                  options={data?.presidencia?.filtros?.rangos}
                  getOptionLabel={(option) => option.nombre}
                  getOptionValue={(option) => option.id}
                  onChange={(choice) => getDataInputs2(choice, "rango")}>
                </Select>
          */}
                <select
                  className='formControlFiltroGeneral'
                  as='select'
                  defaultValue={inputsFilters.rango}
                  name='rango'
                  onChange={(e) => getDataInputs(e)}
                  id='rango'
                >
                  <option value="">{t('formulario.label.seleccione')}</option>
                  {data.presidencia.filtros.rangos.map((dep) => {
                    return (
                      <option value={dep.id} >
                        {dep.nombre}
                      </option>
                    )
                  })}
                </select>
              </Form.Group>

            }

          </div>
        )

      default:
        return 'Paso desconocido';
    }
  }

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    //setActiveStep(0);
    setInputsFilters({
      ...inputsFilters,
      tipoComponente: '',
      departamento: '',
      componentes: []
    })

    setComponenteSeleccionado([])

    const clearDep = document.getElementById('departamento');
    setChoiceDepartamento(undefined);
    refChoiceDepartamento.current.select.clearValue();

    clearDep.value = ''

  };

  const handleTiemposReset = () => {
    // setActiveStep(0);
    setInputsFilters({
      ...inputsFilters,
      rango: '',
      desde: '',
      hasta: '',

    });



    const clearRango = document.getElementById('rango');
    const clearDesde = document.getElementById('desde');
    const clearHasta = document.getElementById('groupSelecToFecha');

    // console.log(clearRango)
    // console.log(clearDesde)
    // console.log(clearHasta)

    // groupSelecToFecha

    if (clearRango) {
      clearRango.value = ''

    }

    if (clearDesde || clearHasta) {
      clearDesde.value = ''
      clearHasta.value = ''
    }


    //setActiveStep(1);

  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
      margin: 'auto',
    },
    button: {
      marginRight: theme.spacing(1),
      height: '35px',
      disableRipple: true
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));


  useEffect(() => {
    // para el envio en produccion hay que enviar la data que recibe del window location
    fetch('JSON/dashboard_json.html', {
      method: 'GET',
      // body: location
    })
      .then(data => {
        data.json()
          .then(d => {
            console.log(d)
            const keys = Object.keys(d.panelGeneral);
            const objArr = Object.entries(d.panelGeneral);
            const arrFil = objArr.filter(([key, value]) => value.visualizar);
            // console.log(objArr)
            setSectionOpen(d.panelGeneral.secciones.length ? d.panelGeneral.secciones[0].id : '');
            setPermisos(d)
            setData(d)
            setInputsFilters({
              ...inputsFilters,
              // departamento: d.presidencia.carga_inicial.departamento,
              // rango: d.presidencia.carga_inicial.rango,
              // desde: d.presidencia.carga_inicial.desde,
              // hasta: d.presidencia.carga_inicial.hasta,
              // tipoComponente: d.presidencia.carga_inicial.tipoComponente,
              inicial: d.presidencia.carga_inicial,
              filtroTiempo: d.presidencia.filtroTiempo,
              filtroDepartamento: d.presidencia.filtroDepartamento,
              // componentes: d.presidencia.carga_inicial.componentes
            })
            // console.log(inputsFilters.componentes)
            // setListaComponente({data : d.presidencia.carga_inicial.componentes })
            // console.log(listaComponente.data)
            // setComponenteSeleccionado(d.presidencia.carga_inicial.componentes)

            // console.log(d)
            // contarVistas()
            setPerfilMedia(d.perfil ? d.perfil : '')
            if (d.media === true) {
              setCarga({ loading: false, error: null })
              setNotificationOpen(true)
              setMedia({ tipo: d.typemedia, contenido: d.urlmedia, colorFondo: d.colorFondo, linkMedia: d.linkMedia })
            }
            else {
              setCarga({ ...carga, loading: false })
              setNotificationOpen(false)
            }
          })
      })
  }, [dispatch]);

  useEffect(() => {
    if (sectionOpen !== '') {
      getDataGraphics();
    }
  }, [sectionOpen]);

  const getDataGraphics = async () => {

    setShowLoaderGraphs(true);
    const dataReq = {
      hash: window.location.hash,
      tipo: sectionOpen
    }

    const req = await validarEntorno('JSON/dashboard_graficas.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();
      setDataGrafica({ ...dataGrafica, data: res.datos });
      setRegionales(res.regionales);
      setUsuariosCliente(res.usuariosCliente);
      setShowLoaderGraphs(false);
    }
  }

  const mostrarInfoPrecargada = () => {
    setTipoModal('infoPrecargadaPanel')
    setShowSelectComponente(true)
    // setListaComponente({...listaComponente, show: true})
    // console.log('tipoModal')
  }

  const mostrarConfigurador = () => {
    setTipoModal('configuradorPanel')
    setShowSelectComponente(true)
  }

  const getDataInputs = async ({ target }) => {
    if (target.name === 'departamento') {

      setTipoModal('modalSelectsComponente')
      setShowSelectComponente(true)
      setIdDepartamento(target.value)

      if (idDepartamento !== target.value) {
        setComponenteSeleccionado([]);
        setListaComponente({
          ...listaComponente,
          show: false,
        });
      }

      // const data = {
      //     idDepartamento: target.value,
      //     hash: window.location.hash
      // }

      // const req = await validarEntorno('JSON/get_componentes_presidencia.html', 'POST', data)
      // const res = await req.json()
      // console.log(res)
    }

    setInputsFilters({
      ...inputsFilters,
      [target.name]: target.value
    })
    console.log(inputsFilters)
  }

  const getDataInputs2 = async (target, name) => {
    if (target !== null) {
      if (name === 'departamento') {
        setTipoModal2('modalSelectsComponente')
        setShowModal(true)
        setIdDepartamento(target.id)

        if (idDepartamento !== target.id) {
          setComponenteSeleccionado([]);
          setListaComponente({
            ...listaComponente,
            show: false,
          });
        }
      }

      // const data = {
      //     idDepartamento: target.value,
      //     hash: window.location.hash
      // }

      // const req = await validarEntorno('JSON/get_componentes_presidencia.html', 'POST', data)
      // const res = await req.json()
      // console.log(res)

      setInputsFilters({
        ...inputsFilters,
        [name]: target.id
      })
      console.log(inputsFilters)
    }
  }


  const getComponentes = async (idDep, typeComponente) => {
    const data = {
      idDepartamento: idDep,
      hash: window.location.hash,
      tipoComponente: typeComponente
    }

    setComponenteSeleccionado([]);

    const req = await validarEntorno('JSON/get_componentes_presidencia.html', 'POST', data)
    const res = await req.json()

    setInputsFilters({
      ...inputsFilters,
      tipoComponente: typeComponente
    })

    setListaComponente({ ...listaComponente, show: true, data: res })
    // console.log(listaComponente)

  }

  const getComponenteSeleccionado = (e, item) => {
    console.log(item)

    if (e.target.checked) {
      setComponenteSeleccionado(prevComponenteSeleccionado => [
        ...prevComponenteSeleccionado, item
      ]);
    } else {
      setComponenteSeleccionado(componenteSeleccionado.filter(cpm => cpm.id !== item.id));
    }

    console.log(componenteSeleccionado)
  }

  const fillComponentToInputsFilter = () => {
    const cs = componenteSeleccionado;
    setInputsFilters({
      ...inputsFilters,
      componentes: cs
    });
    setShowModal(false);
  }

  const filtrarPanel = async () => {

    setShowSelectComponente(false)
    setCarga({ loading: true, error: null })
    setActiveStep(0);

    const dataReq = {
      hash: window.location.hash,
      data: inputsFilters
    }

    // console.log(dataReq)

    console.log(dataReq);

    // debugger;

    const req = await validarEntorno('JSON/dashboard_json.html', 'POST', dataReq)

    if (req.ok) {
      const response = await req.json()

      console.log(response)

      setPermisos(response)
      setData(response)
      setPerfilMedia(response.perfil ? response.perfil : '')
      setComponenteSeleccionado([])

      setInputsFilters({
        ...inputsFilters,
        departamento: '',
        rango: '',
        desde: '',
        hasta: '',
        inicial: response.presidencia.carga_inicial,
        filtroTiempo: response.presidencia.filtroTiempo,
        filtroDepartamento: response.presidencia.filtroDepartamento,
      })



      if (response.media === true) {
        setCarga({ loading: false, error: null })
        setNotificationOpen(true)
      }
      else {
        setCarga({ ...carga, loading: false })
        setNotificationOpen(false)
      }

      getDataGraphics();
      setChoiceDepartamento(undefined);

      // window.location.reload()
    }
  }

  const filterList = componenteSeleccionado.map((item) => {
    return (<p className='itemSeleccionado'>{item.nombre}</p>);
  });

  const classes = useStyles();

  return (
    <>

      <EditModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        listaComponente={listaComponente.data}
        typeModal={tipoModal2}
        getComponentes={getComponentes}
        getComponenteSeleccionado={getComponenteSeleccionado}
        setComponenteSeleccionado={setComponenteSeleccionado}
        idDepartamento={idDepartamento}
        showLista={listaComponente.show}
        fillComponentToInputsFilters={fillComponentToInputsFilter}
      >

      </EditModal>

      <EditModal
        showLista={listaComponente.show}
        listaComponente={listaComponente.data}
        typeModal={tipoModal}
        idDepartamento={idDepartamento}
        isOpen={showSelectComponente}
        setIsOpen={setShowSelectComponente}
        getComponentes={getComponentes}
        getComponenteSeleccionado={getComponenteSeleccionado}
        setComponenteSeleccionado={setComponenteSeleccionado}
        fillComponentToInputsFilters={fillComponentToInputsFilter}
        cargaInicial={inputsFilters.inicial}
        steps={steps}
        activeStep={activeStep}
        getStepContent={getStepContent}
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        handleTiemposReset={handleTiemposReset}
        filtrarPanel={filtrarPanel}
        componenteSeleccionado={componenteSeleccionado}
        carga={carga}
        filterList={filterList}
        filtroDepartamento={inputsFilters.filtroDepartamento === undefined ? true : inputsFilters.filtroDepartamento}
        filtroTiempo={inputsFilters.filtroTiempo === undefined ? true : inputsFilters.filtroTiempo}
        length={inputsFilters.filtroTiempo === false || inputsFilters.filtroDepartamento === false ? 1 : 2}
      >
        {/* {listaComponente.show === true &&
                    <>
                    {listaComponente.data.clientes.map((item, index) => {
                        return(
                            <p style={{backgroundColor:'green'}} key={item.id}> {item.nombre} </p>
                        )
                    })}
                    </>

                } */}
      </EditModal>



      {(perfilMedia === 'presidencia') &&
        <Card className='shadow '>
          <Card.Body style={{ padding: '0' }}>


            <div className={classes.root} >

              {
                <>
                  <div id='contenedorEnlacePrecarga'>
                    <p id='enlacePrecarga' onClick={() => mostrarConfigurador()} >
                      <p id='labelInfoPrecargadaDetail'>
                        {t('botones.configurar')}
                      </p>
                      <SettingsApplicationsIcon id='iconoPrecarga' />
                    </p>
                  </div>
                </>
              }
              {data?.presidencia.hasOwnProperty('carga_inicial') && Object.keys(data.presidencia.carga_inicial).length !== 0 ?
                <div className="stepper_inicial">
                  <div className="step_success">
                    <span className="step_success_title">{t('formulario.label.departamento')}</span>
                    <span >{inputsFilters?.inicial?.departamento}</span>
                    <div className="contenedor_componentes_filtro">
                      {inputsFilters?.inicial?.componentes.map((item, index) => {
                        return (
                          <span key={index}>{item}</span>
                        )
                      })}
                    </div>

                    <div className="step_success_icon">
                      <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><polyline points="20 6 9 17 4 12" /></svg>
                    </div>
                  </div>
                  <div className="step_separator"></div>
                  <div className="step_success">
                    <span className="step_success_title">{t('formulario.label.rango_tiempo')}</span>
                    <span className="step_sucess">{inputsFilters?.inicial?.fechas}</span>
                    <div className="step_success_icon">
                      <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><polyline points="20 6 9 17 4 12" /></svg>
                    </div>
                  </div>
                </div>
                :
                <div className="stepper_inicial">
                  <div className="step_success">
                    <span className="step_success_title">{t('formulario.label.departamento')}</span>
                    <span className="step_undefined_text">{t('formulario.label.no_seleccionado')}</span>
                    <div className="step_undefined_icon">
                      ?
                    </div>
                  </div>
                  <div className="step_separator"></div>
                  <div className="step_success">
                    <span className="step_success_title">{t('formulario.label.rango_tiempo')}</span>
                    <span className="step_undefined_text">{t('formulario.label.no_seleccionado')}</span>
                    <div className="step_undefined_icon">
                      ?
                    </div>
                  </div>
                </div>
              }

              {/*
              <Stepper per activeStep={activeStep} >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      <Typography>{getStepContent(index)}</Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <button
                            variant="contained"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            // className={classes.button}
                            className='btnSecundario'
                            id='btnAtras'
                          // style={  activeStep === 0 && opacity:'0.5'  }
                          >
                            Atrás
                          </button>
                          <button
                            variant="contained"
                            onClick={() => {

                              activeStep === steps.length - 1
                                ?
                                handleTiemposReset()
                                :
                                handleReset()



                            }

                            }
                            className='btnSecundario'
                          >
                            Limpiar
                          </button>
                          <button
                            variant="contained"
                            // color="primary"
                            onClick={handleNext}
                            className=''
                            id='btnConfirmar'

                          >
                            {activeStep === steps.length - 1 ? 'Continuar' : 'Siguiente'}
                          </button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <div style={{ margin: 'auto', width: '50%', textAlign: 'center' }}>
                  <Typography style={{ color: 'gray' }}>Pulse en el botón de finalizar para establecer la configuración seleccionada</Typography>
                  <div id='contenedorBotonesFinalizarFiltro' >
                    <button
                      variant="contained"

                      id='btnFiltrarPaso3'
                      // className='confirmButton'
                      sytle={{ marginLeft: '30px' }}
                      onClick={() => filtrarPanel()}
                    >
                      Finalizar
                    </button>

                  </div>
                </div>
              )}

              {componenteSeleccionado.length > 0 && activeStep === 0 && carga.loading === false ? (
                <div id='contenedorComponentesSeleccionados'>
                  <div className='componenteSeleccionadosLabel' >
                    Componentes seleccionados :
                  </div>
                  <div className="componenteSeleccionadosList">
                    {filterList}
                  </div>
                </div>
              ) : null}
              */}

            </div>

          </Card.Body>
        </Card>
      }

      <br />



      {notificationOpen === true
        ?
        <NotficationModal isOpen={notificationOpen} stateContainer={carga} closeNews={setCarga} setIsOpen={setNotificationOpen} typeModal='multimedia' media={media} />

        : carga.loading === false
          ?
          <Fragment>
            {
              permisos
              &&
              <main maxwidth="lg" className=" mainCardsContainer">
                <div className="cardsContainer">
                  {permisos.widgets.visualizar &&
                    <div style={{ width: '100%', marginBottom: '20px' }} >
                      <GeneralWidgets cards={permisos.widgets} seccion={permisos.seccion} />
                    </div>
                  }
                  {permisos && permisos.showCarrusel &&
                    <>
                      <div className='contenedorDosCards' style={{ display: 'flex', marginBottom: '20px' }}>
                        <Carousel className='w-100'>
                          <CarouselItem className='w-100' >
                            <div className='contenedorDosCards '>
                              {permisos.oportunidad.visualizar && permisos.oportunidad.data.data.length > 0 &&
                                <div className='contenedorGraficasDiarias'>
                                  <SortedBarChart
                                    title={permisos.oportunidad.data.titulo}
                                    data={permisos.oportunidad.data.data}
                                    parametrizado={permisos.oportunidad.data.parametrizado}
                                    // periodo={permisos.oportunidad.data.periodo}
                                    total={permisos.oportunidad.data.total}
                                    height='240px'
                                    icon={<SaveIcon />}
                                  />
                                </div>
                              }
                              {permisos.consolidado.visualizar && permisos.consolidado.data.data.length > 0 &&
                                <div className='contenedorGraficasDiarias' >
                                  <DonaDosniveles id='consolidadoFinalizadas'
                                    data={permisos.consolidado.data.data}
                                    title={permisos.consolidado.data.titulo}
                                    parametrizado={permisos.consolidado.data.parametrizado}
                                    periodo={permisos.consolidado.data.periodo}
                                    total={permisos.consolidado.data.total}

                                  />
                                </div>
                              }
                              {permisos.conclusionesConsolidado.visualizar && permisos.conclusionesConsolidado.data.data.length > 0
                                && ((permisos.oportunidad.data.data.length === 0 || permisos.oportunidad.visualizar === false) ||
                                  (permisos.consolidado.data.data.length === 0 || permisos.consolidado.visualizar === false)
                                ) &&
                                <Dona2 id='conclusionesConsolidadoFinalizadas'
                                  data={permisos.conclusionesConsolidado.data.data}
                                  title={permisos.conclusionesConsolidado.data.titulo}
                                  parametrizado={permisos.conclusionesConsolidado.data.parametrizado}
                                  periodo={permisos.conclusionesConsolidado.data.periodo}
                                  total={permisos.conclusionesConsolidado.data.total}
                                />
                              }
                            </div>
                          </CarouselItem>

                          {permisos.conclusionesConsolidado.visualizar && permisos.conclusionesConsolidado.data.data.length > 0
                            &&
                            ((permisos.oportunidad.visualizar && permisos.oportunidad.data.data.length > 0) &&
                              (permisos.consolidado.visualizar && permisos.consolidado.data.data.length > 0)
                            ) &&
                            <CarouselItem className='w-100'>
                              <div className='contenedorDosCards' >
                                <Dona2 id='conclusionesConsolidadoFinalizadas'
                                  data={permisos.conclusionesConsolidado.data.data}
                                  title={permisos.conclusionesConsolidado.data.titulo}
                                  parametrizado={permisos.conclusionesConsolidado.data.parametrizado}
                                  periodo={permisos.conclusionesConsolidado.data.periodo}
                                  total={permisos.conclusionesConsolidado.data.total}
                                />
                              </div>
                            </CarouselItem>
                          }
                        </Carousel>
                      </div>
                    </>
                  }
                  {
                    permisos.panelGeneral.visualizar === true
                    &&
                    <Fragment>
                      {permisos.panelGeneral.titulo && permisos.panelGeneral.titulo !== '' &&
                        <>
                          <br />
                          <h2
                            style={{ textAlign: 'center', width: '100%' }}
                            className='tituloReporteGeneral'
                          >
                            {permisos.panelGeneral.titulo} {permisos.panelGeneral.parametrizado && `- ${permisos.panelGeneral.parametrizado}`}
                          </h2>
                          <br />
                        </>
                      }
                      <br />
                      <br />
                      <div className='contenedorSecciones'  >
                        <div className='seccionesGraficas'>
                          {permisos.panelGeneral.secciones && permisos.panelGeneral.secciones.length &&
                            permisos.panelGeneral.secciones.map((pestana, index) => {
                              return (
                                <>
                                  {pestana.visualizar &&
                                    <button
                                      key={index}
                                      style={{
                                        backgroundColor: sectionOpen === pestana.id ? pestana?.color : 'white',
                                        color: sectionOpen === pestana.id ? 'white' : '#213265'
                                      }}
                                      // id='buttonCompromisos'
                                      className='seccionPanel'
                                      onClick={() => setSectionOpen(pestana.id)}
                                    >
                                      {pestana?.icono === 'aprobaciones'
                                        ? <AssignmentTurnedInOutlined style={{ color: sectionOpen === pestana.id ? '#fff' : '#2c5e99' }}
                                        // color={ sectionOpen === pestana.id ? '#fff' : '#2c5e99' }
                                        />
                                        : pestana.icono === 'finalizaciones'
                                          ? <DoneAllRoundedIcon
                                            style={{ color: sectionOpen === pestana.id ? '#fff' : '#2c5e99' }}
                                          // color={ sectionOpen === pestana.id ? '#fff' : '#2c5e99' }
                                          />
                                          : pestana.icono === 'consolidado'
                                            ? <IconoCompromisos
                                              color={sectionOpen === pestana.id ? '#fff' : '#2c5e99'}
                                            />
                                            : pestana.icono === 'oportunidad'
                                              ? <OportunidadSVG color={sectionOpen === 'oportunidad' ? '#fff' : '#213265'} />
                                              : pestana.icono === 'novedad'
                                                ? <NovedadSVG color={sectionOpen === 'novedad' ? '#fff' : '#2c5e99'} />
                                                : pestana.icono === 'clientes'
                                                  ? <ClientesSVG color={sectionOpen === 'clientes' ? '#fff' : '#2c5e99'} />
                                                  : pestana.icono === 'nivelRiesgo'
                                                    ? <RiesgoSVG color={sectionOpen === 'nivelRiesgo' ? '#fff' : '#2c5e99'} />
                                                    : pestana.icono === 'anotaciones'
                                                      ? <AnotacionesSVG color={sectionOpen === 'anotaciones' ? '#fff' : '#2c5e99'} />
                                                      : pestana.icono === 'pte_aprobaciones'
                                                        ? <AssignmentLateIcon color={sectionOpen === 'anotaciones' ? '#fff' : '#2c5e99'} />
                                                        : null
                                      }
                                      {/* <IconoCompromisos color={ sectionOpen === index ? '#fff' : '#2c5e99' } /> */}
                                      {pestana.titulo}
                                    </button>
                                  }
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                      <br />
                      <br />
                      {!showLoaderGraphs ? (
                        <>
                          {sectionOpen === 'actividades' ?
                            <Card className='shadow' style={{ padding: '30px', borderRadius: '20px', width: '100%' }}>
                              <BuscadorDosNiveles campos={props?.dataPanel} />
                            </Card>
                            :
                            <PestanaGrafica
                              dataGrafica={dataGrafica}
                              sectionOpen={sectionOpen}
                              regionales={regionales}
                              usuariosCliente={usuariosCliente}
                            />
                          }
                        </>

                      ) :
                        <Card style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '40px' }} >
                          <Card.Body style={{ display: 'flex', justifyContent: 'center' }} >
                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                          </Card.Body>
                        </Card>
                      }
                    </Fragment>
                  }
                </div>
              </main>
            }
          </Fragment>
          :
          <Loader />
      }
    </>
  )
}
