import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { Card, Col, Row } from "react-bootstrap";
import ProgressPercentageBar from "../../helpers/ProgressPercentageBar";
import { validarEntorno } from "../../helpers/validarHost";
import './styles/presidencia.scss';
import DonaConTotal from "../dashboard/DonaConTotal";
import SortedBarChart from "../dashboard/SortedBarChart";
import Treemap from "../dashboard/Treemap";
import { BarrasLabelsHorizontales } from "../dashboard/BarrasLabelsHorizontales";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { t } = useTranslation();
  const [dataPanel, setDataPanel] = useState([]);
  const [data, setData] = useState({})
  const [tabla, setTabla] = useState({})


  const getDataPanel = async () => {

    const dataReq = {
      hash: window.location.hash
    }

    const req = await validarEntorno('JSON/PanelPresidencia/dashboard_presidencia.php', 'POST', dataReq);
    if (req.ok) {
      const res = await req.json();
      setDataPanel(res.data);
      setData(res);
      // console.log(data)
    }
  }

  useEffect(() => {
    getDataPanel();
  }, [])

  return (
    <>
      <Card className='shadow cardGeneral' >
        <Card.Body>
          <h3 className='pagesTitles' style={{ textAlign: 'justify', height: 'auto' }}>{t('formulario.label.panel_general')} {data.corte && `, ${data.corte}`}</h3>
        </Card.Body>
      </Card>
      <br />
      {/* {data.graficas?.length > 0 && data.graficas?.filter(graph => (graph.detalle === 'registros'|| graph.detalle === 'panel')).length > 0 &&
                <Card
                    className='shadow cardGeneral'
                    style={{
                        marginBottom:'20px',
                    }}
                >
                    <Card.Body
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <p style={{color: '#8b97a7'}}>Haga clic en la sección para ver detalle ..</p>
                        <div
                            style={{
                                margin:'15px 0 0',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap'
                            }}
                        >
                            {
                                data?.graficas?.map((item) => {
                                    if(item.detalle){
                                        return(
                                            <>
                                            <Link
                                            style={{
                                                backgroundColor: 'whitesmoke',
                                                padding: '4px',
                                                margin: '0 5px',
                                                borderRadius: '7px',
                                                textDecoration: 'none',
                                                color: '#2c5e99'
                                            }}
                                            to={
                                                item.detalle && item.detalle === 'registros'
                                                ? `/dashboard-gerencia/${item.titulo}/detalle_departamento/`
                                                : item.detalle && item.detalle === 'panel'
                                                    ? `/dashboard-gerencia/${item.titulo}/detalle_departamento_panel/`
                                                    : null
                                            }
                                            >
                                                <p>{item.titulo}</p>
                                            </Link>
                                            </>
                                        )
                                    }
                                })
                            }
                        </div>
                    </Card.Body>
                </Card>

            } */}
      <Card style={{ borderRadius: '8px' }} className="shadow container-cards">
        {data?.graficas && data?.graficas?.length > 0 &&
          data?.graficas.map((grafica, index) => {
            return (
              <Link
                to={grafica.tipo !== 'treemap' &&
                  grafica.detalle && grafica.detalle === 'registros'
                  ? `/dashboard-gerencia/${grafica.titulo}/detalle_departamento/`
                  : grafica.tipo !== 'treemap' && grafica.detalle && grafica.detalle === 'panel'
                    ? `/dashboard-gerencia/${grafica.titulo}/detalle_departamento_panel/`
                    : null
                }
                style={{
                  width: `${grafica.col ? grafica.col : '97%'}`,
                  margin: '0',
                  textDecoration: 'none'
                }}
              >
                <div
                  col={grafica.col ? grafica.col : 12}
                  key={index}
                >
                  {grafica.tipo === 'label_horizontales'
                    ?
                    <>
                      <SortedBarChart
                        data={grafica.data_grafica}
                        title={grafica.titulo}
                        total={grafica.total}
                        id={grafica.id}
                        height={`${200 + (40 * grafica?.data_grafica?.length)}px`}
                        labels={grafica.labels}
                      />

                    </>
                    : null
                  }
                  {grafica.tipo === 'barras_horizontales_new'
                    ?
                    <>
                      {/* <SortedBarChart
                                              data={grafica.data_grafica}
                                              title={grafica.titulo}
                                              total={grafica.total}
                                              id={grafica.id}
                                              height={`${200 + (40 * grafica?.data_grafica?.length)}px`}
                                              labels={grafica.labels}
                                          /> */}
                      <BarrasLabelsHorizontales
                        title={grafica.titulo}
                        total={grafica.total}
                        id={grafica.id}
                        data={grafica.data_grafica}
                      />
                    </>
                    : null
                  }
                  {grafica.tipo === 'treemap'
                    ?
                    <Treemap
                      data={grafica?.data_grafica}
                      title={grafica.titulo}
                      total={grafica.total}
                      id={grafica.id}
                      height={`${200 + (40 * grafica?.data_grafica?.length)}px`}
                      labels={grafica.labels}
                      detailLink={grafica?.detalle && grafica?.detalle === 'registros'
                        ? `/dashboard-gerencia/${grafica?.titulo || grafica?.tipo_enlace}/detalle_departamento/`
                        : grafica?.detalle && grafica?.detalle === 'panel'
                          ? `/dashboard-gerencia/${grafica?.titulo || grafica?.tipo_enlace}/detalle_departamento_panel/`
                          : null
                      }
                    >
                      <button>Ver detalle</button>
                    </Treemap>
                    : null
                  }
                  { }
                  {grafica.tipo === 'media_torta'
                    ?
                    <Card>
                      <Card.Body style={{ width: '100%', padding: '2em' }}>
                        <DonaConTotal
                          minHeight='300px'
                          legendPosition={'right'}
                          width='98%'
                          id={grafica.titulo}
                          total={grafica.total}
                          data={grafica?.gestiones}
                          colores={grafica.colores}
                        // tipo={grafica.tipo}
                        />
                        <div style={{ textAlign: 'left' }}>
                          <h3 className="pagesTitles" style={{ width: 'fit-content' }}>{grafica.titulo}</h3>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ProgressPercentageBar data={parseInt(grafica.porcentaje)} />
                          <p className="pagesTitles" style={{ display: 'flex', alignItems: 'center', margin: '0 0 0 10px', color: '#7eebaa' }}>{`${grafica.porcentaje}%`}</p>
                        </div>
                      </Card.Body>
                      <p className='titlePorcentaje' style={{ fontWeight: '500' }} >Porcentaje de solicitudes culminadas</p>
                    </Card>
                    : null
                  }

                </div>
              </Link>
            )
          })
        }
      </Card>
    </>
  );
}

export default Main;
