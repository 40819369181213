import React, { useEffect } from "react";
import Map from '../../helpers/Map'
import { validarEntorno } from '../../helpers/validarHost';
import Accordion from 'react-bootstrap/Accordion'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from 'moment'

import LineaTiempo from '../../components/dashboard/LineaTiempo'
import PartitionedBarChart from '../../components/dashboard/PartitionedBarChart'
// import ArbolForzado from '../gerenciaGeneral/operaciones/graficas/ArbolForzado'
import LineaRadial from '../gerenciaGeneral/operaciones/graficas/LineaRadial'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { Card, CarouselItem, Col, Form, Row, ListGroup } from 'react-bootstrap'
import { HojaCoca } from '../icons/ocensa/HojaCoca.jsx'
import EditModal from '../modales/EditModal'
import {credenciales} from '../../helpers/credentials.google'
import Loader from '../../components/icons/Loader';
import ResumenComponenteModal from '../modales/ResumenComponenteModal'
import './styles/PanelOcensa.scss'
import { makeStyles } from "@material-ui/core";
import useState from 'react-usestateref';
import {RadialRangos} from '../../components/gerenciaGeneral/operaciones/graficas/RadialRangos'
import PanelGerenciaRiesgos from '../Ocensa/PanelGerenciaRiesgos'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PopUpModal from "../../helpers/PopUpModal";
import { useTranslation } from "react-i18next";

// import { clippingParents } from "@popperjs/core";
// import { RedoTwoTone } from "@material-ui/icons";

export default function PanelOcensa(props) {

  registerLocale("ES", es)
  const { t } = useTranslation();

  var chart = am4core.create("chartdiv", am4charts.RadarChart);

  const [fecha, setFecha] = useState('');

  const [data, setData] = useState([])

  const [showFiltros, setShowFiltros] = useState({})

  const [compSelected, setCompSelected] = useState('')

  const [modalComp, setModalComp] = useState({
    tipo: '',
    show: false,
    data: {}
  })

  const [rangos, setRangos] = useState([])


  const [seccionDofaSelected, setSeccionDofaSelected ] = useState('Fortalezas')

  const [detalleAmenazas, setDetalleAmenazas] = useState({
    data: []
  })
  const [detalleFortalezas, setDetalleFortalezas] = useState({
    data: []
  })
  const [detalleDebilidades, setDetalleDebilidades] = useState({
    data: []
  })
  const [detalleOportunidades, setDetalleOportunidades] = useState({
    data: []
  })

  const [cuentas, setCuentas] = useState([]);

  const [seleccionPlan, setSeleccionPlan] = useState({
    idObjetivo: '',
    idMeta: '',
    idTarea: ''
  })

  const [dataTimeline, setDataTimeline] = useState({
    data: []
  })

  const [dataTimelineDefault, setDataTimelineDefault] = useState({
    data: []
  })

  const [dataTimelineEvents, setDataTimelineEvents] = useState({
    data: []
  })

  const [dataTimelineEventsDefault, setDataTimelineEventsDefault] = useState({
    data: []
  })


  const [dataMeta, setDataMeta] = useState({
    data: []
  })

  const [dataTarea, setDataTarea] = useState({
    data: []
  })

  const [activeStep, setActiveStep] = useState(0);

  const [inputsFilters, setInputsFilters] = useState({
    tipoComponente: '',
    departamento: '',
    rango: '',
    desde: '',
    hasta: '',
    componentes: [],
    inicial: {}
  });
  const [showSelectComponente, setShowSelectComponente] = useState(false);
  const [idCuenta, setIdCuenta] = useState('');
  const [componenteSeleccionado, setComponenteSeleccionado] = useState([]);
  const [listaComponente, setListaComponente] = useState({
    show: false,
    data: []
  });
  const [carga, setCarga] = useState({
    loading: true,
    error: null
    // data: undefined
  });

  const [location, setLocation] = useState({
    ltd: 4.4202675,
    lng: -74.4483277
  });
  const [zoom, setZoom] = useState(5);

  const [seccion, setSeccion] = useState([]);
  const [actividad, setActividad] = useState([]);
  const [sucesos, setSucesos] = useState([]);
  const [sucesosFilter, setSucesosFilter] = useState([]);
  const [garmados, setGarmados] = useState([]);
  const [garmadosFilter, setGarmadosFilter] = useState([]);
  const [limites, setLimites] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [limitesFilter, setLimitesFilter] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [noticiasDefault, setNoticiasDefault] = useState([]);
  const [componentes, setComponentes] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [dataPanorama, setDataPanorama] = useState({});
  const [dataPanoramaOriginal, setDataPanoramaOriginal] = useState({});

  const getDataInputs = async ({target}) => {
    if(target.name === 'cuenta'){

        setTipoModal('modalSelectsComponentePanelAuditorias')
        setShowSelectComponente(true)
        setIdCuenta(target.value)

        if(idCuenta !== target.value) {
            setComponenteSeleccionado([]);
            setListaComponente({
                ...listaComponente,
                show: false,
            });
        }

        // const data = {
        //     idDepartamento: target.value,
        //     hash: window.location.hash
        // }

        // const req = await validarEntorno('JSON/get_componentes_presidencia.html', 'POST', data)
        // const res = await req.json()
        // //console.log(res)
    }

    setInputsFilters({
        ...inputsFilters,
        [target.name] : target.value
    })
    //console.log(inputsFilters)
}


// //console.log(props.dataPanel)

  const url = window.location.hash

  const [filtros, setFiltros, filtrosRef] = useState({
    locacion: "",
    componente: "",
    fecha: "",
    seccion: "",
    actividad: ""
  });

  const [dataPanelOcensa, setDataPanelOcensa] = useState({
    data: {}
  })

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
      margin: 'auto',
    },
    button: {
      marginRight: theme.spacing(1),
      height: '35px',
      disableRipple: true
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));


  useEffect(() => {
    getDataPanelGerencia()

    setDetalleFortalezas({
      ...detalleFortalezas,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[0].data
    })
    setDetalleOportunidades({
      ...detalleOportunidades,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[1].data
    })
    setDetalleDebilidades({
      ...detalleDebilidades,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[2].data
    })
    setDetalleAmenazas({
      ...detalleAmenazas,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[3].data
    })
    getDepartamentos();
    getRespuestaPanorama();
  }, [])

  const getDataPanelGerencia = async () => {

    const request = await fetch('JSON/dashboard_gerencia.php')
    const data = await request.json()

    setData(data)

    setShowFiltros(data?.filtros)
    getContenidoFiltros(data);
    //console.log(showFiltros);
    setDataPanelOcensa({data: data})
    //console.log(dataPanelOcensa.data)
    setInputsFilters({...inputsFilters, inicial: data.carga_inicial})
    setCuentas(data.data.cuentas);
    setRangos(data.data.rangos)
    setCarga({ loading: false, error: null });
    setZonas(data.data.zonas)
    setSucesos(data.data.sucesos);
    setSucesosFilter(data.data.sucesos);
    setGarmados(data.data.grupos_armados);
    setGarmadosFilter(data.data.grupos_armados);
    setLimites(data.data.limites);
    setLimitesFilter(data.data.limites);
    setNoticias(data.data.topNoticias);
    setNoticiasDefault(data.data.topNoticias);
    setComponentes(data.data.componentes);
    setDataTimeline(data.data.linea_tiempo);
    setDataTimelineDefault(data.data.linea_tiempo);
    setDataTimelineEvents(data.data.eventos_linea_tiempo);
    setDataTimelineEventsDefault(data.data.eventos_linea_tiempo);
    // //console.log(dataPanelOcensa)
  }


  const getContenidoFiltros = async   (data) => {
    if (!data.filtros.factores) {

      const dataReq = {
        hash: window.location.hash,
        tipo: 'componente',
        id: ''
      }

      const req = await validarEntorno('JSON/panoramaRiesgos/get_secciones_componente.html', 'POST', dataReq);

      const dataSeccion = await req.json();

      delete dataSeccion.permisos;
      delete dataSeccion.registros;

      const comArray = Object.values(dataSeccion);
      const listFactores = [].concat.apply([], comArray);

      setSeccion(listFactores);
    }

    if(!data.filtros.secciones) {
      const dataReq = {
        hash: window.location.hash,
        tipo: 'seccion',
        factor: '',
        id: ''
      }

      const req = await validarEntorno('JSON/panoramaRiesgos/factores_select.html', 'POST', dataReq);

      const dataActividad = await req.json();

      setActividad(dataActividad.lista);
    }
  }

  const getRespuestaPanorama = async () => {
    const request = await fetch('JSON/panoramaRiesgos/respuesta_dashboard_panorama.html');
    const data = await request.json()
    setDataPanorama(data.data);
    setDataPanoramaOriginal(data.data);
  }

  const getDepartamentos = async () => {
    const request = await fetch('JSON/departamentos.html');

    if(request.ok){
      const data = await request.json();
      setDepartamentos(data.data);
    }
  }

  const mapStyles = {
    width: '100%',
    height: '100%'
  };

  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credenciales.mapsKey}&libraries=visualization`


  const getLatLng = ({target:{selectedOptions:[option]}}) =>  {
    const objUbi = {
      lat: option.getAttribute('data-lat'),
      lng: option.getAttribute('data-lng')
    }

    return objUbi;
  }

  const takingFilters = (e) => {

    // console.log(e.target.name + ": " +  e.target.value)

    let stateFilters = {...filtros}

    // let locacion = document.querySelector("#locacion-select")
    let componente = document.querySelector("#componente-select")
    let seccion = document.querySelector("#seccion-select")
    let fecha = document.querySelector("#fecha-select")
    let actividad = document.querySelector("#actividad-select")

    // console.log(componente.value)
    if(e?.target){
      if(e?.target?.name === 'locacion') {



        stateFilters.componente = '';
        stateFilters.fecha = '';
        stateFilters.seccion = '';
        stateFilters.actividad = '';
        stateFilters.locacion = e?.target?.value;

        fecha.value = '';
        componente.value = '';
        seccion.value = '';
        // actividad.value = '';


      }

      if (e?.target?.name === 'fecha') {

        stateFilters.fecha = e?.target?.value;
        stateFilters.componente = '';
        stateFilters.seccion = '';
        stateFilters.actividad = '';

        componente.value = '';
        seccion.value = '';
        // actividad.value = '';


      }

      if (e?.target?.name === 'componente') {

        stateFilters.componente = e?.target?.value;
        stateFilters.seccion = '';
        stateFilters.actividad = '';

        seccion.value = '';
        // actividad.value = '';


      }

      if (e?.target?.name === 'seccion') {

        stateFilters.seccion = e?.target?.value;
        stateFilters.actividad = '';

        // actividad.value = '';

      }

      if(e?.target?.name === 'actividad'){

        stateFilters.actividad = e?.target?.value;

      }
    }

    setFiltros(stateFilters)

    // console.log(stateFilters)

    getFiltros(e, stateFilters)

    // getFiltros(e)

  }

  const getFiltros = async (e, filters) => {



    if(e.target.name === 'locacion') {
      const ubi = getLatLng(e);
      setLocation({
        ltd: parseFloat(ubi.lat),
        lng: parseFloat(ubi.lng)
      });
      //console.log(location);
      setZoom(8);

      // setFiltros({
      //   componente: "0",
      //   seccion: "0",
      //   actividad: "0",
      //   [e.target.name] : e.target.value
      // });
    } else if (e.target.name === 'componente') {

      const idComponente = e.target.value !== '' ? e.target.value : '';

      if (showFiltros.secciones) {
        const dataReq = {
            hash: window.location.hash,
            tipo: 'componente',
            id: e.target.value
        }

        const request = await validarEntorno('JSON/panoramaRiesgos/get_secciones_componente.html', 'POST', dataReq)

        if(request.ok){
          const data = await request.json();

          switch (parseInt(idComponente)) {
            case 1:
              setSeccion(data.social);
              break;
            case 2:
              setSeccion(data.politico);
              break;
            case 3:
              setSeccion(data.economico);
              break;
            case 4:
              setSeccion(data.fuerza_disponible);
              break;
            case 5:
              setSeccion(data.amenazas);
              break;
            default:
              setSeccion('');
              break;
          };
        }

        const seccion = document.getElementById("seccion-select");
        seccion.value = '';
      }

        if (showFiltros.actividades) {
          const actividad = document.getElementById("actividad-select");
          actividad.value = '';
        }

        // setFiltros({ ...filtros,
        //   componente: e.target.value,
        //   seccion: 0,
        //   actividad: 0
        // })
    } else if (e.target.name === 'seccion') {

      setActividad([]);
      // setFiltros({ ...filtros,
      //   seccion: e.target.value,
      //   actividad: 0
      // })

      if (showFiltros.actividades) {

        const actividad = document.getElementById("actividad-select");
        actividad.value = '';

        const idSeccion = e.target.value !== '' ? e.target.value : filtros.componente;

        const dataReq = {
          hash: window.location.hash,
          tipo: 'seccion',
          factor: filtros.componente ,
          id: e.target.value
        }

        const request = await validarEntorno('JSON/panoramaRiesgos/factores_select.html', 'POST', dataReq)

        if (request.ok) {
          const data = await request.json();
          const actividadesFiltradas = data.lista.filter( actividad =>  actividad.id_seccion == idSeccion )
          setActividad(actividadesFiltradas);
        }
      }
    } else if(e.target.name === 'actividad'){
      // setFiltros({
      //   ...filtros,
      //   [e.target.name] : e.target.value
      // });

    }else if(e.target.name === 'fecha'){}
    else {

      const dataReq = {
        filtros: filters,
        hash: window.location.hash,
      }

      console.log(dataReq)


      const req = await validarEntorno('JSON/panoramaRiesgos/respuesta_filtros_panorama.php', 'POST', dataReq);

      if (req.ok) {
        const res = await req.json();
        setDataPanorama(res.data);
        setNoticias(res.topNoticias);
        setDataTimeline(res.linea_tiempo);
        // console.log(dataReq)

      }
    }


}

  // const change

  const getConsultFilter = async (flt) => {

    const request = await validarEntorno('JSON/panoramaRiesgos/respuesta_dashboard_panorama_filtro.json', "POST", flt);

    if (request.ok) {

      const data = await request.json();

      const res = data.data;

      const dataFilter = res.filter((item) => {
        return item.id_componente === parseInt(flt.componente)
      });
    }
  }

  const ubicacionBase = {
    lat: 4.612639,
    lng: -74.0705
  }

  // 1.206845,-77.2756767

  const {
    plan_estrategico,
    widgets,
    perfil,
    ubicaciones,
    riesgos,
    visualizar,
    normatividades,
    dofa,
    linea_tiempo_inicial
  } = props.dataPanel

  // setDataTimeline(linea_tiempo_inicial.data)

  // //console.log(dataTimeline)


  // //console.log(ubicaciones)

  const [detalleRiesgo, setDetalleRiesgo] = useState({
    data: {},
    show: false
  })

    // const [detalleNorma, setDetalleNorma] = useState({
    //   data: {},
    //   show: false
    // })

  // const [detalleDiagnostico, setDetalleDiagnostico] = useState({
  //   data: {},
  //   show: false
  // })

  const [tipoModal, setTipoModal] = useState('')

  const itemsCuentas = async () => {
    const request = await fetch('JSON/lista_cuentas_json.html')
    const data = await request.json()
    setCuentas(data.cuentas);
  }

  const showRiesgoDetails = (riesgo) => {
    // //console.log(riesgo)
    // window.alert('Aqui se muestran los detalles del riesgo : ' + riesgo.tipo)
    setDetalleRiesgo({...detalleRiesgo, data: riesgo,  show: !detalleRiesgo.show})
    setTipoModal('detalleRiesgo')
  }

  const showNormativaDetails = (normativa) => {
    // //console.log(normativa)
    // window.alert('Aqui se muestran los detalles del normativa : ' + normativa.tipo)
    setDetalleRiesgo({...detalleRiesgo, data: normativa,  show: true})
    setTipoModal('detalleNormativa')
  }

  const showAreaDiagnosticoDetails = (area) => {
    // //console.log(area)
    // window.alert('Aqui se muestran los detalles del riesgo : ' + riesgo.tipo)
    setDetalleRiesgo({...detalleRiesgo, data: area,  show: !detalleRiesgo.show})
    setTipoModal('detalleAreaDiagnostico')

  }

  const getInformacionObjetivo = async (objetivo) => {

    const data = {
      hash : window.location.hash,
      id_objetivo : objetivo.id
    }


    if(seleccionPlan.idObjetivo !== objetivo.id){

      setDataMeta({
        data: []
      })

      setSeleccionPlan({
        ...seleccionPlan,
        idObjetivo: objetivo.id
      })

      const req = await validarEntorno('JSON/get_timeline_data.html', 'POST', data)

      const res = await req.json()

      if(req.ok){
        // //console.log(res)
        setDataTimeline({ ...dataTimeline, data: res.data})
        // //console.log(dataTimeline)

      }
    }


  }

  const getInformacionMeta = async (idObj, idMetaSelected) => {

    const data = {
      hash : window.location.hash,
      id_objetivo : idObj,
      id_meta : idMetaSelected
    }

    //console.log(data)


    if(seleccionPlan.idObjetivo === idObj && seleccionPlan.idMeta !== idMetaSelected){

      setDataTarea({
        data: []
      })

      setSeleccionPlan({
        ...seleccionPlan,
        idMeta: idMetaSelected,
        idTarea: ''
      })


      const reqTimeline = await validarEntorno('JSON/get_timeline_data.html', 'POST', data)

      const res = await reqTimeline.json()

      if(reqTimeline.ok){
        //console.log(res)
        setDataTimeline({ ...dataTimeline, data: res.data})
        //console.log(dataTimeline)
        setDataMeta({
          ...dataMeta,
          data: res.tareas
        })
        //console.log(dataMeta)

      }

    }

  }

  const filtrarPanel = async () => {

    setCarga({ loading: true, error: null })
    setActiveStep(0);

    const dataReq = {
        hash: window.location.hash,
        data: inputsFilters
    }

    //console.log(dataReq);


    const req = await validarEntorno('JSON/dashboard_gerencia_filtro.html', 'POST', dataReq)
    const response = await req.json()

    setComponenteSeleccionado([])

    setInputsFilters({
        ...inputsFilters,
        departamento: '',
        rango: '',
        desde: '',
        hasta: ''
    })

    if(response){
        setCarga({ loading: false, error: null })
        setDataPanelOcensa({data: response})
        setSucesos(response.data.sucesos);
        setSucesosFilter(response.data.sucesos);
        setGarmados(response.data.grupos_armados);
        setGarmadosFilter(response.data.grupos_armados)
        setLimites(response.data.limites);
        setLimitesFilter(response.data.limites);
        setNoticias(response.data.topNoticias);
        setNoticiasDefault(response.data.topNoticias);
        setDataTimeline(response.data.linea_tiempo);
        setDataTimelineEvents(response.data.eventos_linea_tiempo);
        setDataTimelineDefault(response.data.linea_tiempo);
        setDataTimelineEventsDefault(response.data.eventos_linea_tiempo);
        //setNotificationOpen(true)
    }
    else {
        setCarga({ ...carga, loading: false })
        //setNotificationOpen(false)
    }

    // window.location.reload()

}

  const getInformacionTarea = async (idTareaSelected) => {

    const data = {
      hash : window.location.hash,
      id_objetivo : seleccionPlan.idObjetivo,
      id_meta : seleccionPlan.idMeta,
      id_tarea: idTareaSelected
    }

    //console.log(data)

    if(seleccionPlan.idTarea !== idTareaSelected){

      setSeleccionPlan({
        ...seleccionPlan,
        idTarea: idTareaSelected
      })

      const reqTimeline = await validarEntorno('JSON/get_timeline_data.html', 'POST', data)

      const res = await reqTimeline.json()

      if(reqTimeline.ok){
        //console.log(res)
        setDataTimeline({ ...dataTimeline, data: res.data})
        //console.log(dataTimeline)
        setDataTarea({
          ...dataTarea,
          data: res.actividades
        })
        //console.log(dataTarea)

      }
    }
  }
  // //console.log(widgets)

  const cargaDataSeccion = (seccion) => {

    switch (seccion.nombre) {
      case 'Amenazas':
        setDetalleAmenazas({...detalleAmenazas, data:  seccion.data})
        break;
      case 'Fortalezas':
        setDetalleFortalezas({...detalleFortalezas, data:  seccion.data})
        break;
      case 'Debilidades':
        setDetalleDebilidades({...detalleDebilidades, data:  seccion.data})
        break;
      case 'Oportunidades':
        setDetalleOportunidades({...detalleOportunidades, data:  seccion.data})
        break;
      default:
        break;
    }

  }

  function getSteps() {
    return ['Selecciona una cuenta', 'Selecciona un rango de tiempo'];
  }

  const steps = getSteps();

  function getStepContent(step) {
    switch (step) {
    case 0:
        return (
            <>
                <Form.Group className='formGroupVertical'>
                    <Form.Label className='labelSelect' >Cuentas</Form.Label>
                    <select
                        className='formControlFiltroGeneral'
                        as='select'
                        defaultValue={inputsFilters.departamento}
                        onChange={(e) => getDataInputs(e)}
                        name='cuenta'
                        id='cuenta'
                        >
                        <option selected disabled value="">--Seleccione--</option>
                        {cuentas.length > 0 && cuentas.map((cuenta) => {
                            return(
                                <option value={cuenta.id}>
                                    {cuenta.nombre}
                                </option>
                            )
                        })}
                    </select>
                </Form.Group>

            </>
        )
    case 1:
        return (
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>


            {inputsFilters.rango === '' &&
                <>
                    <Form.Group className='formGroupVertical'>
                        <Form.Label className='labelSelect' >Desde</Form.Label>
                        <Form.Control
                        style={{border: '0'}}
                          className='formControlFiltroGeneral'
                          type='date'
                          defaultValue={inputsFilters.desde}
                          name='desde'
                          onChange={(e) => getDataInputs(e)}
                          id='desde'
                        />
                    </Form.Group>
                    <Form.Group className='formGroupVertical'>
                        <Form.Label className='labelSelect' >Hasta</Form.Label>
                        <Form.Control
                            style={{border: '0'}}
                            id='groupSelecToFecha'
                            className='formControlFiltroGeneral'
                            type='date'
                            defaultValue={inputsFilters.hasta}
                            name='hasta'
                            onChange={(e) => getDataInputs(e)}

                        />

                    </Form.Group>
                </>
            }

            {(inputsFilters.desde === '' && inputsFilters.hasta === '') &&
                <Form.Group className='formGroupVertical'>
                    <Form.Label className='labelSelect' >Rango</Form.Label>
                    <select
                        className='formControlFiltroGeneral'
                        as='select'
                        defaultValue={inputsFilters.rango }
                        name='rango'
                        onChange={(e) => getDataInputs(e)}
                        id='rango'
                    >
                        <option  value="">{t('formulario.label.seleccione')}</option>
                        {rangos.length > 0 && rangos.map((cuenta) => {
                            return(
                                <option value={cuenta.id} >
                                {cuenta.nombre}
                                </option>
                            )
                        })}
                    </select>
                </Form.Group>

            }

        </div>
        )

      default:
        return 'Paso desconocido';
    }
  }

  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setInputsFilters({
        ...inputsFilters,
        tipoComponente: '',
        departamento: '',
        componentes: []
    })
    //console.log(inputsFilters)

    setComponenteSeleccionado([])

    const clearDep = document.getElementById('cuenta');

    clearDep.value = ''
  };

  const handleTiemposReset = () => {
    // setActiveStep(0);
    setInputsFilters({
        ...inputsFilters,
        rango: '',
        desde: '',
        hasta: '',
    });

    const clearRango = document.getElementById('rango');
    const clearDesde = document.getElementById('desde');
    const clearHasta = document.getElementById('groupSelecToFecha');

    // //console.log(clearRango)
    // //console.log(clearDesde)
    // //console.log(clearHasta)

    // groupSelecToFecha

    if(clearRango){
        clearRango.value = ''

    }

    if(clearDesde || clearHasta){
        clearDesde.value = ''
        clearHasta.value = ''
    }

    setActiveStep(1);

    //console.log(inputsFilters)
  };

  const getComponentes = async (idDep, typeComponente) => {
    const data = {
            id_cuenta: idDep,
            hash: window.location.hash,
            tipoComponente: typeComponente
        }

        //console.log(data)

        setComponenteSeleccionado([]);

        const req = await validarEntorno('JSON/get_componentes_presidencia_aud.html', 'POST', data)
        const res = await req.json()

        setInputsFilters({
            ...inputsFilters,
            tipoComponente: typeComponente
        })

        //console.log(res)

        setListaComponente({...listaComponente, show: true, data: res})
        // //console.log(listaComponente)

}

const getComponenteSeleccionado = (e, item) => {
  //console.log(item)

  if (e.target.checked) {
      setComponenteSeleccionado(prevComponenteSeleccionado => [
          ...prevComponenteSeleccionado, item
      ]);
  } else {
      setComponenteSeleccionado(componenteSeleccionado.filter(cpm => cpm.id !== item.id));
  }

  //console.log(componenteSeleccionado)
}


const fillComponentToInputsFilter = () => {
  const cs = componenteSeleccionado;
  setInputsFilters({
      ...inputsFilters,
      componentes: cs
  });
  setShowSelectComponente(false);
}

const filterList = componenteSeleccionado.map((item) => {
  return (<p className='itemSeleccionado'>{ item.nombre }</p>);
});

const mostrarInfoPrecargada = () => {
  setTipoModal('infoPrecargadaPanel')
  setShowSelectComponente(true)
  // setListaComponente({...listaComponente, show: true})
  // //console.log('tipoModal')
}

const style = {
  maxWidth: "450px",
  height: "350px",
  overflowX: "hidden",
  overflowY: "hidden"
 };

 const containerStyle = {
  maxWidth: "450px",
  height: "350px"
 };

 const clearFilters = () => {

  console.log('Limipiando filtros...')
    setLocation({ ltd: 4.4202675, lng: -74.4483277 });
    setZoom(5);
    setSucesos(sucesosFilter);
    setGarmados(garmadosFilter);
    setLimites(limitesFilter);
    setNoticias(noticiasDefault);
    setDataTimeline(dataTimelineDefault);
    setFiltros({
      locacion: "0",
      componente: "0",
      fecha: "0",
      seccion: "0",
      actividad: "0"
    });

    if (showFiltros.locacion) {
      const locacion = document.getElementById("locacion-select");
      locacion.value = "";
    }

    if (showFiltros.componentes) {
      const componente = document.getElementById("componente-select");
      componente.value = "";
    }

    if (showFiltros.secciones) {
      const seccion = document.getElementById("seccion-select");
      seccion.value = "";
    }
    if (showFiltros.actividades) {
      const actividad = document.getElementById("actividad-select");
      actividad.value = "";
    }

 }

 const showResumen = (item) => {
  setModalComp({
    ...modalComp,
    show: true,
    data: item
  })
 }

  return (

    <>
      <EditModal
        showLista= {listaComponente.show}
        listaComponente={listaComponente.data}
        typeModal={tipoModal}
        idCuenta={idCuenta}
        isOpen={showSelectComponente}
        setIsOpen={setShowSelectComponente}
        getComponentes={getComponentes}
        getComponenteSeleccionado = {getComponenteSeleccionado}
        fillComponentToInputsFilters = {fillComponentToInputsFilter}
        cargaInicial = {inputsFilters.inicial}
      />
      <div id={perfil !== 'gerencia_riesgos' ? 'ocensaContainer' : 'flexContainer'}>
        <>
          <div id="ocensaFormContainer" style={{display:'none'}}>
            {

            }
          <Card className='shadow '>
            <Card.Body style={{ padding:'0', width: '90%', margin: '0 auto' }}>
              <>
                  <div id='contenedorEnlacePrecarga'>
                      <div id='enlacePrecarga' onClick={() => mostrarInfoPrecargada()} >
                          <p id='labelInfoPrecargadaDetail'>
                              Ver configuración
                          </p>
                          <SettingsApplicationsIcon id='iconoPrecarga' />
                      </div>
                  </div>
              </>
              <Stepper activeStep={activeStep} >
                    {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                        <Typography>{getStepContent(index)}</Typography>
                        <div className={classes.actionsContainer}>
                            <div>
                            <button
                                variant="contained"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                // className={classes.button}
                                className='btnSecundario'
                                id='btnAtras'
                                // style={  activeStep === 0 && opacity:'0.5'  }
                            >
                                Atrás
                            </button>
                            <button
                                variant="contained"
                                onClick={() => {

                                    activeStep === steps.length -1
                                    ?
                                    handleTiemposReset()
                                    :
                                    handleReset()



                                    }

                                }
                                className='btnSecundario'
                            >
                                Limpiar
                            </button>
                            <button
                                variant="contained"
                                // color="primary"
                                onClick={handleNext}
                                className=''
                                id='btnConfirmar'

                            >
                                {activeStep === steps.length - 1 ? 'Continuar' : 'Siguiente'}
                            </button>
                            </div>
                        </div>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                  <div style={{margin: 'auto', width:'50%', textAlign:'center'}}>
                  <Typography style={{color:'gray'}}>Pulse en el botón de finalizar para establecer la configuración seleccionada</Typography>
                  <div id='contenedorBotonesFinalizarFiltro' >

                      <button
                              variant="contained"

                          id='btnFiltrarPaso3'
                          sytle={{marginLeft:'30px'}}
                          onClick={() => filtrarPanel()}
                      >
                          Finalizar
                      </button>

                  </div>
                  </div>
                )}
              { componenteSeleccionado.length > 0 && activeStep === 0 && carga.loading === false ? (
                <div id='contenedorComponentesSeleccionados'>
                    <div className='componenteSeleccionadosLabel' >
                        Componentes seleccionados :
                    </div>
                    <div  className="componenteSeleccionadosList">
                        { filterList }
                    </div>
                </div>
              ) : null}
            </Card.Body>
          </Card>
          </div>
        {!carga.loading ? (
          <>{perfil !== 'gerencia_riesgos' && dataPanelOcensa?.data?.data.widgets &&
            <section id='headerOcensa'>

              <div className='shadow mainWidgets scrollSecundario' id='widgetsOcensaContainer' style={{borderRadius: '10px', justifyContent: 'space-around'}} >
                { (perfil === 'operaciones' || perfil === 'panorama_riesgos' ) && dataPanelOcensa?.data?.data.widgets.map((widget, index) => {
                  return(
                    <>
                    <div
                      className='shadow widgetOcensa'
                      key={index}
                      onClick={() => showResumen(widget)}
                    >
                            <div id={ widget?.concepto === 'Fuerza disponible' ? 'widgetFuerzaDisponible' : `widget${widget.id_widget}`} className='contenidoWidget'>
                              <div className='contenedorTextoWidgets'>
                                {/* <p></p> */}
                              </div>
                            </div>
                          <div id={ widget?.concepto === 'Fuerza disponible' ? 'tituloFuerzaDisponible' : `titulo${widget.id_widget}`} className='tituloWidget'>{widget.concepto}
                          </div>
                        </div>
                    </>
                  )
                })}

                {( perfil === 'operaciones' || perfil === 'presidencia'  ) && widgets.map((widget, index) => {
                  const porcentajeWidget = Number(widget.porcentaje.substring(0, widget.porcentaje.length - 1))
                  return(
                    <>
                      {/* {(perfil === 'gerencia_riesgos' || perfil === 'panorama_riesgos' ) &&
                        <div  className='shadow widgetOcensa' key={index}>
                            <div id={`widget${widget.concepto}`} className='contenidoWidget'>
                              <div className='contenedorTextoWidgets'>
                                <p></p>
                              </div>
                            </div>
                          <div id={`titulo${widget.concepto}`} className='tituloWidget'>{widget.concepto}</div>
                        </div>
                      } */}
                      {( perfil === 'operaciones' || perfil === 'presidencia'  ) &&

                          <div
                            className={`shadow widget cardWidgetPorcentaje ${porcentajeWidget < 20 ? 'widgetBajo' : porcentajeWidget >= 20 && porcentajeWidget < 80 ? 'widgetMedio' : porcentajeWidget >= 80 && 'widgetAlto'  }`}
                            key={index}
                            onClick={() => showAreaDiagnosticoDetails(widget)}
                          >
                            <Card.Body className='cardbodywidget'>
                              <div className='contenidoWidget'>
                                <div className='labelWidget'>
                                  {widget.titulo}
                                </div>
                                <div className='cantidadWidget'>
                                  <p>{widget.porcentaje}</p>
                                </div>
                              </div>
                            </Card.Body>
                          </div>
                      }
                    </>
                  )
                })}

              </div>

            </section>
          }


              {( perfil === 'panorama_riesgos' ) && visualizar.mapa &&
                <aside   id={visualizar.noticias ? 'filtrosOcensaContainer' : 'filtrosExtendido'} >
              <div className='shadow' id='filtrosOcensa' style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '10px', width: '100%'}}>
                <h5 className='titleBlue'>{t('formulario.label.filtros')}</h5>
                <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between', width: '100%'}}>
                  <div className='contenedorSelects' >
                    {showFiltros.locacion &&
                      <div className='contenedorSelectGroup'>
                        <label className='labelSelectpanorama' >{t('formulario.label.locacion')}</label>
                        <select id="locacion-select" defaultValue={filtros.locacion ? filtros.locacion : ''} className=' scrollSecundario selectOcensa' name='locacion' onChange={takingFilters} >
                          <option value="" disabled >{t('formulario.label.locacion')}</option>
                          {departamentos.map((item, index) => (
                            <option value={item.id_departamento} data-lat={item.lat} data-lng={item.lng}>{item.nombre}</option>
                          ))}
                        </select>
                      </div>
                    }
                    {showFiltros.fecha &&
                      <div className='contenedorSelectGroup' >
                        <label className='labelSelectpanorama' >{t('formulario.label.fecha')}</label>
                        <input
                          style={{border: 'none'}}
                          className='scrollSecundario selectOcensa'
                          type="month"
                          id="fecha-select"
                          name="fecha"
                          min='2022-01'
                          onChange={takingFilters}
                          // max= {moment().format("YYYY MM")}
                          max= {`${moment().format('YYYY')}-${moment().format('MMMM')}`}
                          defaultValue={`${moment(new Date()).format('YYYY')}-${moment(new Date()).format('MMMM')}`}
                        />
                      </div>
                    }
                    {showFiltros.factores &&
                      <div className='contenedorSelectGroup' >
                      <label className='labelSelectpanorama' >{t('formulario.label.componente')}</label>
                        <select id="componente-select" defaultValue={filtros.componente ? filtros.componente : ''} className='scrollSecundario selectOcensa' name='componente' onChange={takingFilters}>
                          <option value="" selected disabled>Factor</option>
                          {componentes.map((item, index) => (
                            <option value={item.id_componente}>{item.concepto}</option>
                          ))}
                        </select>
                      </div>
                    }
                    {showFiltros.secciones &&
                      <div className='contenedorSelectGroup' >
                        <label className='labelSelectpanorama' >{t('formulario.label.seccion')}</label>
                        <select id="seccion-select" defaultValue={filtros.seccion ? filtros.seccion : ''} className='scrollSecundario selectOcensa' name='seccion' type='select' onChange={takingFilters}>
                          <option value="" selected disabled >{t('formulario.label.seccion')}</option>
                          {seccion.map((item, index) => (
                            <option value={item.id_seccion} data-key={item.llave}>{item.valor}</option>
                          ))}
                        </select>
                      </div>
                    }
                    {showFiltros.actividades &&
                      <div className='contenedorSelectGroup' >
                        <label className='labelSelectpanorama' >{t('formulario.label.actividad')}</label>
                        <select id="actividad-select" defaultValue={filtros.actividad ? filtros.actividad : ''} className='scrollSecundario selectOcensa' name='actividad' type='select' onChange={takingFilters}>
                          <option value="" disabled >{t('formulario.label.actividad')}d</option>
                          {actividad.map((item, index) => (
                            <option value={item.id_list}>{item.valor}</option>
                          ))}
                        </select>
                      </div>
                    }
                  </div>
                  <button className='confirmButton' onClick={(e) => takingFilters(e)} style={{margin: '0'}}>{t('botones.enviar')}</button>
                </div>
              </div>
            </aside>
              }

              {(
                // perfil === 'operaciones' ||
                perfil === 'panorama_riesgos'  ) && visualizar.mapa &&
                <section className='secciones' id={visualizar.noticias ? 'mapaOcensaContainer' : 'mapaExtendido'}  >
                <br/>
                  <div className='shadow' id='mapaOcensa'>

                    <Map
                      googleMapURL= {mapURL}
                      containerElement = {<div style={{height:'100%'}}></div>}
                      mapElement = {<div style={{height:'100%'}}></div>}
                      style={style}
                      containerStyle={containerStyle}
                      loadingElement = {<Loader />}
                      latitud={location.ltd}
                      longitud={location.lng}
                      data={dataPanorama}
                      zoom={zoom}
                    />

                    {/* {filtros.locacion === 'Narino' && filtros.componente === ''
                      ?
                      <>
                      <Map
                        googleMapURL= {mapURL}
                        containerElement = {<div style={{height:'100%'}}></div>}
                        mapElement = {<div style={{height:'100%'}}></div>}
                        loadingElement = {<Loader />}
                        latitud={ubicacionBase?.lat}
                        longitud={ubicacionBase?.lng}
                        zoom= {10}
                        // ubicaciones={ubicaciones}
                      />
                      </>
                      : filtros.locacion === 'Narino' && filtros.componente === 'social'
                        ?
                        <>
                        <img alt='mapa sociopolitico' src='https://res.cloudinary.com/da6bzgewk/image/upload/v1620835563/WhatsApp_Image_2021-05-12_at_10.43.35_AM_2_puxtki.jpg' ></img>
                          {/* <img alt='mapa sociopolitico' src='https://res.cloudinary.com/da6bzgewk/image/upload/v1620835554/WhatsApp_Image_2021-05-12_at_10.43.35_AM_1_ke9xq0.jpg' ></img> */}

                        {/*</>
                        : filtros.locacion === 'Narino' && filtros.componente === 'politico'
                          ?
                          <>
                          <img alt='mapa sociopolitico' src='https://res.cloudinary.com/da6bzgewk/image/upload/v1620835554/WhatsApp_Image_2021-05-12_at_10.43.35_AM_1_ke9xq0.jpg' ></img>

                          </>
                          : filtros.locacion === 'Narino' && filtros.componente === 'economico'
                            ?
                            <>
                            <img alt='mapa sociopolitico' src='https://res.cloudinary.com/da6bzgewk/image/upload/v1620835531/WhatsApp_Image_2021-05-12_at_10.43.35_AM_gdnyju.jpg' ></img>


                              {/* <img alt='mapa sociopolitico' src='https://res.cloudinary.com/da6bzgewk/image/upload/v1620835544/WhatsApp_Image_2021-05-12_at_10.43.32_AM_o51xji.jpg' ></img>

                            </>
                            : filtros.locacion === 'Narino' && filtros.componente === 'ambiental'
                              ?
                              <>
                                <img alt='mapa sociopolitico' src='https://res.cloudinary.com/da6bzgewk/image/upload/v1620843532/WhatsApp_Image_2021-05-12_at_12.47.20_PM_xgstu3.jpg' ></img>

                              </>
                              : filtros.locacion === 'Narino' && filtros.componente === 'conflicto'
                                ?
                                <>
                              <img alt='mapa sociopolitico' src='https://res.cloudinary.com/da6bzgewk/image/upload/v1620850307/bbd4068a-cc20-49fd-9ca6-cee5d6d3b7e7_kdxunr.jpg' ></img>

                                </>
                                : filtros.locacion === 'Principal' && filtros.componente === ''
                                &&
                                <Map
                                  googleMapURL= {mapURL}
                                  containerElement = {<div style={{height:'100%'}}></div>}
                                  mapElement = {<div style={{height:'100%'}}></div>}
                                  loadingElement = {<Loader />}
                                  latitud={4.4202675}
                                  longitud={-74.4483277}
                                  ubicaciones={props.ubicaciones}

                                />
                    } */}


                  </div>
                </section>
              }
            {(perfil === 'x' || perfil === 'panorama_riesgos' ) && visualizar.noticias &&
              <aside className='secciones' id='NoticiasOcensaContainer'>
                <div className='shadow' id='noticiasOcensa'>
                  <h3 className='titleBlue'>Noticias</h3>
                  <br/>
                  <div className='contenedorNoticias'>
                    {noticias?.map((noticia) => {
                      return(
                        <div className='shadow noticiaItem' key={noticia.index}>
                          <h6 className='tituloNoticiaItem' id='tituloNoticiaItem'>{noticia.titulo}</h6>
                          {noticia.imagen
                            ?
                            <div className='noImage' style={{backgroundImage: `url(${noticia.imagen})`}}  >
                              <image src={noticia.imagen}></image>
                            </div>
                            :
                            <div className='noImage' style={{backgroundColor: 'gray'}}></div>
                          }
                          <p className='descripcionNoticiaItem' >{noticia.descripcion}</p>
                          <a id='enlaceNoticiaItem' href={noticia.enlace}>Ver noticia</a>
                        </div>
                      )
                    })}
                  </div>

                </div>
              </aside>
            }

            {perfil === 'gerencia_riesgos' && <PanelGerenciaRiesgos data={data} dataPanel={props.dataPanel} chart={chart} perfil={perfil} />}


        </>
        ) : (
          <div className="loader-center">
              <Loader />
          </div>
        )}

        <EditModal typeModal={tipoModal} setIsOpen={showRiesgoDetails} dataRiesgo={detalleRiesgo.data} isOpen={detalleRiesgo.show}>
        </EditModal>
      </>
      {perfil === 'panorama_riesgos' &&

        <ResumenComponenteModal
          isOpen={modalComp.show}
          setIsOpen={setModalComp}
          typeModal={modalComp.tipo}
          item={modalComp.data}
        />
      }
    </div>
    </>
  )
}
