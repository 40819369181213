import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../ui/Card';
import { ChartContainer, ChartTooltipContent, ChartTooltip } from "../../ui/Chart";
import styles from './LineHeader.module.scss';

export default function LineHeader({
  textos,
  data,
  color,
  label,
}) {
  return (
    <Card className="lg:max-w-md">
      <CardHeader className={styles.card__header}>
      {textos && textos.length>0 && textos.map((texto, index) => (
        <div key={index}>
          <CardDescription className={styles.card__description}>{texto.titulo}</CardDescription>
          <CardTitle className={styles.card__title}>
            {texto.valor}
            <span className={styles.card__title__unit}>
              {texto.unidad}
            </span>
          </CardTitle>
        </div>
      ))}
      </CardHeader>
      <CardContent className={styles.card__content}>
        <ChartContainer
          config={{
            valor: {
              label: label,
            },
          }}
          className="w-full"
        >
          <LineChart
            accessibilityLayer
            margin={{
              left: 14,
              right: 14,
              top: 10,
            }}
            data={data}
          >
            <CartesianGrid
              strokeDasharray="4 4"
              vertical={false}
              stroke="var(--gray-400)"
              strokeOpacity={0.5}
            />
            <YAxis hide domain={["dataMin - 10", "dataMax + 10"]} />
            <XAxis
              dataKey="fecha"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => {
                return new Date(value).toLocaleDateString("es-CO");
              }}
            />
            <Line
              dataKey="valor"
              type="natural"
              stroke={color}
              strokeWidth={2}
              dot={false}
              activeDot={{
                fill:  color,
                stroke: color,
                r: 4,
              }}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  indicator="line"
                  labelFormatter={(value) => {
                    return new Date(value).toLocaleDateString("es-CO", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    });
                  }}
                />
              }
              cursor={false}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}