import { useState, useEffect } from 'react'

/**
 * Custom hook para validar la contraseña del usuario
 * @param {string} nueva - Contraseña nueva
 * @param {string} confirmacion - Confirmación de la contraseña nueva
 * @returns {boolean} longitudValida
 * @returns {boolean} minusculasMayusculas
 * @returns {boolean} numericos
 * @returns {boolean} especiales
 * @returns {boolean} sinEspaciosVacios
 * @returns {boolean} formularioValido
 */

export default function usePassword({nueva, confirmacion}) {

  /* Estados para validar la contraseña, si se cumple la condición se cambia a true */

  const [longitudValida, setLongitudValida] = useState(false)
  const [minusculasMayusculas, setMinusculasMayusculas] = useState(false)
  const [numericos, setNumericos] = useState(false)
  const [especiales, setEspeciales] = useState(false)
  const [sinEspaciosVacios, setSinEspaciosVacios] = useState(false)
  const [formularioValido, setFormularioValido] = useState(false)
  const [coinciden, setCoinciden] = useState(false)
  const [cambioInicial, setCambioInicial] = useState(false)

  /**
   * Hook para validar la contraseña del usuario,
   * se ejecuta cada vez que se cambia el valor de la contraseña o la confirmación
   */

  useEffect(() => {
    setLongitudValida(nueva.length >= 12 && nueva.length <= 14) // La contraseña debe tener entre 12 y 14 caracteres
    setMinusculasMayusculas(nueva.match(/[a-z]/) && nueva.match(/[A-Z]/)) // La contraseña debe tener al menos una letra mayúscula y una minúscula
    setNumericos(nueva.match(/[0-9]/)) // La contraseña debe tener al menos un caracter numérico
    setEspeciales(nueva.match(/[´!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)) // La contraseña debe tener al menos un caracter especial
    setSinEspaciosVacios(!nueva.match(/ /)) // La contraseña no debe tener espacios vacíos
    setCoinciden(nueva === confirmacion) // La contraseña y su confirmación deben coincidir
    if(nueva !== '' || confirmacion !== '') {
      setCambioInicial(true)
    }
  }, [nueva, confirmacion])

  /**
   * Hook para validar el formulario, es decir, si todas las condiciones se cumplen
   * se ejecuta cada vez que se cambia el valor de los estados
   */

  useEffect(() => {
    setFormularioValido(longitudValida && minusculasMayusculas && numericos && especiales && sinEspaciosVacios && coinciden)
  }, [longitudValida, minusculasMayusculas, numericos, especiales, sinEspaciosVacios, coinciden])

  return {
    longitudValida,
    minusculasMayusculas,
    numericos,
    especiales,
    sinEspaciosVacios,
    formularioValido,
    coinciden,
    cambioInicial
  }
}
