import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Col, Form } from 'react-bootstrap';
import InputType from '../../helpers/InputType';
import { useForm } from 'react-hook-form';
import { fetchAndNotify } from '../../helpers/fetchAndNotify';
import NotificationModal from '../modales/NotificationModal';
import { validarEntorno } from '../../helpers/validarHost';
import { useTranslation } from 'react-i18next';
import Loader from '../icons/LoaderModal';

/**
 * Componente que muestra un modal que permite realizar operaciones CRUD
 * @param {boolean} isOpen Estado que controla la apertura del modal
 * @param {Function} setIsOpen Funcion que controla el estado del modal
 * @param {Object} submitData Datos que se enviaran al servidor al guardar el formulario
 * @param {Object} getFormData Datos que se enviaran al servidor para obtener la estructura del formulario
 * @param {boolean} viewMode true si el modal es de solo lectura
 * @param {boolean} deleteMode true si el modal es de eliminacion
 * @param {Function} refresh Funcion que se ejecuta para refrescar la información
 * @returns
 */

export default function ModalCRUD({ isOpen, setIsOpen, submitData, getFormData, viewMode, deleteMode, refresh, urlEstructura, urlEnvio, titulo }) {

  const [camposForm, setCamposForm] = useState(); //array de objetos con la estructura de los campos del formulario
  const [loading, setLoading] = useState(true); //booleano que indica si se esta cargando la informacion de los campos
  const [sending, setSending] = useState(false); //booleano que indica si se esta enviando la informacion del formulario
  const [notificationModal, setNotificationModal] = useState({
    show: false,
    tipo: '',
    titulo: '',
    mensaje: '',
    typeModal: '',
    handleConfirm: () => { },
  }); //estado que controla la apertura del modal de notificacion

  const { register, handleSubmit, errors } = useForm(); //hook de react-hook-form
  const { t } = useTranslation(); //hook de i18n

  /**
   * Funcion que se encarga de cerrar el modal
   */

  function handleOnClose() {
    setIsOpen(false);
  }

  /**
   * Funcion que se encarga de cerrar el modal y refrescar la información
   * en caso de que se haya realizado una operación
   */

  function handleOnCloseAndRefresh() {
    setIsOpen(false);
    refresh?.(undefined, undefined, { refresh: true });
  }

  /**
   * Consulta los campos del formulario y los guarda en el estado
   */

  const fetchCampos = async () => {
    setLoading(true);
    const requestData = {
      ...getFormData,
    };

    if (getFormData.accion) {
      requestData.accion = `OBTENER_${getFormData.accion}`;
    }

   // console.log("DATOS DE ESTRUCTURA", requestData);

    try {
      const request = await validarEntorno(urlEstructura, 'POST', requestData);
      if (request.ok) {
        const response = await request.json();
        setCamposForm(response.campos);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  /**
   * Envia la informacion del formulario al servidor
   * @param {*} data Informacion del formulario
   */

  const onSubmit = async (data) => {
    setSending(true);
    let requestData = {
      ...submitData,
      data: data,
    };


    //console.log("DATOS DE GUARDADO DE FORMULARIO", requestData);
    fetchAndNotify(urlEnvio, 'POST', requestData, setNotificationModal, false, handleOnCloseAndRefresh);
    setSending(false);
  }

  /**
   * Carga los campos del formulario al montar el componente
   */

  useEffect(() => {
    if (isOpen) {
      if (deleteMode) {
        fetchAndNotify(urlEnvio, 'POST', submitData, setNotificationModal, true, handleOnCloseAndRefresh, handleOnClose);
      } else {
        fetchCampos();
      }
    }
  }, [isOpen]);

  return (
    <div>
      {!deleteMode &&
        <Dialog header={titulo} visible={isOpen} style={{ width: '50vw' }} onHide={handleOnClose}>
          {loading ? <Loader /> :
            <Form onSubmit={handleSubmit(onSubmit)} className='p-0'>
              <div className='d-flex flex-wrap'>
                {camposForm?.map((campo, index) => {
                  const fieldName = `asesoria.0`;
                  return (
                    <>
                      <InputType
                        item={campo}
                        index={0}
                        idx={index}
                        key={`${0} - ${index}`}
                        reg={register}
                        nombre={`${fieldName}`}
                        errors={errors}
                        col={campo.col}
                        tipo={campo.tipo}
                        esEditable={viewMode}
                      />
                    </>
                  )
                })}
              </div>
              {!viewMode &&
              <Col className='d-flex justify-content-end mt-3'>
                <Button
                  type="submit"
                  size="small"
                  loading={sending}
                  className="confirmButton m-0">
                  {t('botones.guardar')}
                </Button>
              </Col>
              }
            </Form>
          }
        </Dialog>
      }
      <NotificationModal
        typeModal={notificationModal.typeModal}
        setIsOpen={setNotificationModal}
        isOpen={notificationModal.show}
        mensaje={notificationModal.mensaje}
        modal={notificationModal}
        tipo={notificationModal.tipo}
        onClose={notificationModal.handleOnClose}
        handleConfirm={notificationModal.handleConfirm}
      />
    </div>
  )
}
