
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";


const Redirect = ({ item, handleInputChange, index, idx, reg, nombre, error, handleButtonEstado, esEditable, handleButtonLink }) => {

    return(
      <Form.Group style={{ display: 'block' }}>
      <br  />
      <a href={item.valor} target='_blank' >
      <button
          // disabled={item.valor}
          onClick={() => {handleButtonLink(index,idx)}}
          type="button"
          style={{width: '100%', opacity: !item.valor ?  1 : 0.25, height: '35px'}}
          className="btnAccionesInfoGeneral"

          // href="https://www.google.com/"
      >
          {item.titulo}
      </button>
      </a>


      <input type={"hidden"} name={`${nombre}.${item.nombre}`} value={item.valor} ref={reg} />
  </Form.Group>
    );

}

export default Redirect;
// onClick={event =>  window.location.href='/your-href'}
