import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap'
import { validarEntorno } from '../../../../helpers/validarHost';
import Tabla from '../../../common/Tabla';
import Loader from '../../../icons/Loader';
import { useTranslation } from 'react-i18next';

export default function DocumentosCosinte() {

  const { id: codigo } = useParams()
  const [columnas, setColumnas] = useState([])
  const [filas, setFilas] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    getDocsCosinte()
  }, [])

  const getDocsCosinte = async () => {
    setLoading(true)
    const dataReq = {
      hash: window.location.hash,
      id: codigo
    }
    const req = await validarEntorno('JSON/sol_reg_docs_cosinte.html', 'POST', dataReq)
    if (req.ok) {
      const res = await req.json()
      const columnas = res.columnas.map(col => {
        return {
          value: col,
          label: col
        }
      })
      const registros = res.registros.map(reg => {
        return {
          Tipo: reg.tipo,
          Descripción: reg.descripcion,
          Acciones: reg.enlace
        }
      })
      setColumnas(columnas)
      setFilas(registros)
    }
    setLoading(false)
  }

  return (
    <Card className='card__box__surface p-4 card__plain__top__left'>
      <h4 className='titleInsideCard'> {t('formulario.label.soporte_documental_cosinte')}</h4>
      {loading ? <Loader></Loader> :
        <Tabla
          columnas={columnas}
          filas={filas}
          botonEditarVisible={false}
          botonCrearVisible={false} />
      }
    </Card>
  )
}
