import React, { useState } from 'react'
import { Card, Form } from 'react-bootstrap'

import './ConclusionesInvestigacion.scss'

export default function ConclusionInvestigacion(props) {

  const [formConclusiones, setFormConclusiones] = useState({
    acreditacion: '',
    presuntoFraude: '',
    elementosJuicio: '',
    sintesis: '',
    relacionAnexos: '',
    justificacion: '',
  })

  const handleChangeConclusiones = ({ target }) => {
    setFormConclusiones({
      ...formConclusiones,
      [target.name]: target.value
    })
  }


  return (
    <Card className='shadow cardGeneral'>
      <Card.Body>
        <h4 className='titleCard titleCardConclusiones'>Conclusiones de investigación</h4>
        <br />
        <Form id='formConclusiones' noValidate>
          <div id='contenedorSelectsConclusiones'>
            <Form.Group>
              <Form.Label>
                Acreditación*
              </Form.Label>
              <p>
                SE ACREDITÓ el contenido y la veracidad de la solicitud presentada por Johanna García Martínez, una vez analizadas y revisadas cada una de las pruebas aportadas en la presente investigación administrativa.
              <div className='inputContainer'>

                  <Form.Control name='acreditacion' as='select' custom required value={formConclusiones.acreditacion.value} onChange={handleChangeConclusiones} >
                    <option disabled selected>Seleccione</option>
                    <option value="si">Si</option>
                    <option value="no">No</option>
                  </Form.Control>
                </div>
              </p>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Presunto fraude*
              </Form.Label>
              <div className='inputContainer'>
                <Form.Control name='presuntoFraude' as='select' custom required value={formConclusiones.presuntoFraude.value} onChange={handleChangeConclusiones}>
                  <option disabled selected>Seleccione</option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                  <option value="Fraude en el trámite de incrementos pènsionale">Fraude en el trámite de incrementos pènsionale </option>
                  <option value="Fraude en el trámite de sustituciones pensionales">Fraude en el trámite de sustituciones pensionales </option>
                  <option value="Fraude en el trámite de pensiones de sobreviviente">Fraude en el trámite de pensiones de sobreviviente </option>
                  <option value="Fraude en el trámite de indemnizaciones sustitutivas-sobrevivientes">Fraude en el trámite de indemnizaciones sustitutivas-sobrevivientes </option>
                  <option value="Falsedad en los extremos de convivencia">Falsedad en los extremos de convivencia </option>
                  <option value="No existió UMH o matrimonio">No existió UMH o matrimonio </option>
                  <option value="Vinculo familiar - no marital">Vinculo familiar - no marital </option>
                  <option value="Convivencia por relación contractual">Convivencia por relación contractual </option>
                  <option value="Falsedad en dependencia económica">Falsedad en dependencia económica</option>
                  <option value="Falsedad material en documento">Falsedad material en documento </option>
                  <option value="Falsedad en documento público o privado">Falsedad en documento público o privado </option>
                  <option value="No existe la entidad o institución">No existe la entidad o institución </option>
                </Form.Control>
              </div>
            </Form.Group>
          </div>
          <hr />
          <h5 className='titleCard titleCardConclusiones'>Elementos de juicio</h5>
          <br />
          <div className='textAreaGroupConclusiones'>
            <Form.Control name='elementosJuicio' className='textAreaConclusiones' as='textarea' placeholder='Elementos de juicio'
              onChange={handleChangeConclusiones}
              value={props.dataSolicitud.conclusiones[0].elementosJuicio || '' }
            ></Form.Control>
          </div>
          <hr />
          <h5 className='titleCard titleCardConclusiones'>Síntesis</h5>
          <br />
          <div className='textAreaGroupConclusiones'>
            <Form.Control name='sintesis' className='textAreaConclusiones' as='textarea' placeholder='sintesis'
              onChange={handleChangeConclusiones}
              value={props.dataSolicitud.conclusiones[0].sintesis || ''}
            ></Form.Control>
          </div>
          <hr />
          <h5 className='titleCard titleCardConclusiones'>Relación de anexos</h5>
          <br />
          <div className='textAreaGroupConclusiones'>
            <Form.Control name='relacionAnexos' className='textAreaConclusiones' as='textarea' placeholder='Relacion de anexos'
              onChange={handleChangeConclusiones}
              value={props.dataSolicitud.conclusiones[0].relacionAnexo || ''}
            ></Form.Control>
          </div>
          <hr />
          <h5 className='titleCard titleCardConclusiones'>Justificación</h5>
          <br />
          <div className='textAreaGroupConclusiones'>
            <Form.Control name='justificacion' className='textAreaConclusiones' as='textarea' placeholder='Justificación'
              onChange={handleChangeConclusiones}
              value={props.dataSolicitud.conclusiones[0].justificacion || ''}
            ></Form.Control>
          </div>
          <br />
          <div className='buttonContainer'>
            <button className='confirmButton'>Guardar</button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}
