/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
import React, { useState} from 'react'

import {GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow, Polygon,  Circle} from 'react-google-maps'
import Polyline from 'react-google-maps/lib/components/Polyline';

import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
// import EditModal from '../../../components/modales/EditModal';

import { municipiosPoligono } from '../../../helpers/municipiosPoligono';

// 3.4334185,-76.4517656, 1.2047533,-77.2699356

// const cali = { lat: 3.4334185, lng: -76.4517656}

import '../styles/Heatmap.scss'


const iconos = {
  cultivo_ilicito: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743798/planta_bt7n25.svg'
  },
  atentado: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743964/explosion_qisd6j.svg'
  },
  oleoducto: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/valvula.svg'
  }
}

const styleTextInfoWindow = {
  textAlign: 'justify',
  fontSize: '1.25rem',
  fontWeight: 600,
  color: '#223265'
}


const HeatMap = (props) => {

  const {
    coords,
    getCoordinates,
    index,
    tipoMapa,
    lat,
    lng,
    riesgo,
    ubicacion,
    marker
  } = props

  const [ openInfo, setOpenInfo ] = useState(false);
  const [ descripcion, setDescripcion ] = useState('');
  // const [ invalidDes, setInvalidDes] = useState(false);
  const [ markerSelected, setMarkerSelected] = useState({});
  const [ showInfoIndex, setShowInfoIndex ] = useState('');
  // const [ coordsCenter, setCoordsCenter ] = useState({
  //   lat: 0,
  //   lng: 0
  // });

  // const handleOpenInfo = () => {
  //   setOpenInfo(!openInfo)
  // }

  const onSelect = (item, index) => {
    setMarkerSelected(item);
    setShowInfoIndex(index);
  }

  const getCoord = () => {
      getCoordinates(lat, lng, index, descripcion);
      setDescripcion('');
  }

  const getPoints = () => {
    let arr = [];
    if (coords.length > 0) {
      // eslint-disable-next-line array-callback-return
      coords.map((hm) => {      // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(hm.lat, hm.lng)
        arr.push(latlng);
      });
    }

    return arr;
  }

  const getPolygonState = (value) => {

    if (value) {
      const { Placemark } = municipiosPoligono.kml.Document.Folder;

      const valueSplit = value.trim().split('-');

      const department = valueSplit[0].trim();

      const filterPoints = Placemark.filter((mun, index) => {
          return mun.ExtendedData.SchemaData.SimpleData[1].text === department;
      });

      if (filterPoints.length > 0) {
          const MultiGeometryCoordinates = buildCoordinatesArrayFromString(filterPoints[0].MultiGeometry.Polygon[0].outerBoundaryIs.LinearRing.coordinates);
          return MultiGeometryCoordinates;
      }
    }
  }

   /**
     * Crea una matriz de coordenadas a partir del contenido del nodo MultiGeometryCoordinates de la base de datos GADM.
     */
    const buildCoordinatesArrayFromString = (MultiGeometryCoordinates) => {
      const finalData = [];
      const grouped = MultiGeometryCoordinates.split(" ");

      grouped.forEach(function(item, i){
          let a = item.trim().split(',');

          finalData.push({
              lng: parseFloat(a[0]),
              lat: parseFloat(a[1])
          });
      });

      return finalData;
    }


  const getMapType = () => {
      switch (tipoMapa) {
        case 'puntos_calor':
            return (
              <HeatmapLayer
                data={getPoints()}
                radius={1000}
              />
            );
        case 'trazado':
          return (
            <Polyline
              path={coords}
              geodesic={true}
              options={{
                strokeColor: "#ff2527",
                strokeOpacity: 0.75,
                strokeWeight: 2
              }}
            />
          );
        case 'puntos_simples':
          return (
            coords.map((coord, index) => (
              <Marker
               position={coord}
              />
          )));
        case 'poligono':
          return (
            <Polygon
              path={coords}
              //key={1}
              options={{
                  fillColor: getRiskPolylyneColor(),
                  fillOpacity: 0.4,
                  strokeColor: getRiskPolylyneColor(),
                  strokeOpacity: 1,
                  strokeWeight: 1
              }}
            />
          )
        case 'oleoducto':
          return(
            <>
              <Polyline
                path={coords}
                geodesic={true}
                options={{
                  strokeColor: "#82EA35",
                  strokeOpacity: 0.75,
                  strokeWeight: 5
                }}
              />
              {coords.map((coord, index) => (
                <Marker
                  position={coord}
                  icon={iconos.oleoducto.icono}
                  onClick={() => onSelect(coord, index)}
                >
                  {(showInfoIndex === index) && (
                    <InfoWindow
                      position={markerSelected.coord}
                    >
                      <div className='contenedorInfoWindow' >
                        <p style={styleTextInfoWindow} >{ coord.descripcion }</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </>
          )
          case 'departamento':
            return(
              <>
                <Polygon
                  path={getPolygonState(ubicacion)}
                  //key={1}
                  options={{
                    fillColor: getRiskPolylyneColor(),
                    fillOpacity: 0.4,
                    strokeColor: getRiskPolylyneColor(),
                    strokeOpacity: 1,
                    strokeWeight: 1
                  }}
                />
              </>
            )
          case 'punto':
            return (
              <>
                <Circle
                  center={marker}
                  radius={50000}
                  options={{
                    fillColor: getRiskPolylyneColor(),
                    strokeOpacity: 0.75,
                    strokeWeight: 2,
                    strokeColor: getRiskPolylyneColor()
                  }}
                />
              </>
            );
        default:
          break;
      }
  }

  const handleChangeData = (e) => {
    const { value } = e.target;
    setDescripcion(value);
  }

  // const onSelect = (item, index) => {
  //   console.log(item);
  //   setMarkerSelected(item);
  //   handleOpenInfo(index);
  // }

  const getCenter = () => {
    let puntoCentral = {};

    if (tipoMapa !== 'departamento') {
      if (coords.length > 1) {
          /* eslint-disable array-callback-return */
          /* eslint-disable no-undef */
          const bounds = new google.maps.LatLngBounds();

          for (let i = 0; i < coords.length; i++) {
            bounds.extend(coords[i]);
          }

          puntoCentral = {
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng()
          }

          return puntoCentral;
      } if (coords.length === 1) {

          puntoCentral = {
              lat: coords[0].lat,
              lng: coords[0].lng
          }

          return puntoCentral;
      }
    } else {
      const points = getPolygonState(ubicacion);
      if (points.length > 0) {
        /* eslint-disable array-callback-return */
        /* eslint-disable no-undef */
        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < points.length; i++) {
          bounds.extend(points[i]);
        }

        puntoCentral = {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng()
        }

        console.log(puntoCentral);

        return puntoCentral;
      }
    }
  }

  const getRiskPolylyneColor = () => {

    let color = "#000";

    if (riesgo) {

      const { value } = riesgo[0] ? riesgo[0] : riesgo;

      switch (value) {
        case 'bajo':
          color = '#23A835';
          break;
        case 'medio':
          color = '#DBB93D';
          break;
        case 'alto':
          color = '#EA5335'
          break;
        default:
          color = "#000";
          break;
      }
    }

    return color;
  }

  // useEffect(() => {
  //   showMunicipio()
  // }, [municipioSelected ? municipioSelected : null])

  return(
    <>
      <GoogleMap
        defaultZoom={8}
        zoom={8}
        defaultCenter={getCenter()}
        center={getCenter()}
      >
        { getMapType() }
      </GoogleMap>
    </>
  );
}

export default withScriptjs(
  withGoogleMap(
    HeatMap
  )
)

