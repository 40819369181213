import React from 'react'
import { Page, Text, View, Document, Image, StyleSheet, PDFViewer, Canvas } from '@react-pdf/renderer';

function Logo() {

  const logo = 'https://www.ciacosinte.site/crm/upload/images/logo_transparencia_blanco.png'

  const styles = StyleSheet.create({
    header: {
      // backgroundColor: 'green',
      height: '70px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'

    },
    logo:{
      width: 'auto',
      height: '65px',
      padding: '0 20px'

    },
  })


  return (
    <View style={styles.header}>
      <Image style={styles.logo} src={logo}></Image>
    </View>
  )
}

export {Logo}
