import { validarEntorno } from "../helpers/validarHost";

//intialState
const initialState = {
    idSolicitud: '',
    data: null,
    selects: {
        selects: []
    }
}

//types

const types = {
    cargaData: '[Carga de data] cargar datos desde backend',
    selectInicialSolicitud: '[Carga Select] tipos de solicitud',
    restablecerData: '[Data Inicial] Reestablecer data incial'
}


//reducer
export const solicitudEstudioReducer = (state = initialState, action) => {
    switch (action.type) {


        case types.selectInicialSolicitud:

            state = {
                ...state,
                idSolicitud: action.tipoSolicitud,

                selects: action.payload
            }
            return state

        case types.cargaData:
            state = {
                ...state,
                data: action.payload
            }
            return state;

        case types.restablecerData:
            state = initialState
            return state

        default:
            return state;
    }
}



// actions
export const solicitarData = (tipoSolicitud) => {

    // console.log(tipoSolicitud)

    return async (dispatch) => {


        switch (window.location.hash) {

            case "#/gerencia/investigaciones/solicitar":
                const solicitarInvestigaciones = await validarEntorno('JSON/ejemploCrearSolicitud_json.html', 'POST', tipoSolicitud)

                if (solicitarInvestigaciones?.ok) {
                    const dataRespuestaJson = await solicitarInvestigaciones.json()
                    dispatch({
                        type: types.cargaData,
                        payload: dataRespuestaJson
                    })
                }


                break;

            case "#/gerencia/investigaciones-administrativas/solicitar":
                const solicitar = await validarEntorno('JSON/ejemploCrearSolicitud_json.html', 'POST', tipoSolicitud)

                if (solicitar?.ok) {
                    const dataRespuestaJson = await solicitar.json()
                    dispatch({
                        type: types.cargaData,
                        payload: dataRespuestaJson
                    })
                }


                break;

            case "#/gerencia/inteligencia/solicitar":

                const solicitarInteligencia = await validarEntorno('JSON/crear_solicitud_inteligencia_json.html', 'POST', tipoSolicitud)

                if (solicitarInteligencia?.ok) {
                    const dataRespuestaJson = await solicitarInteligencia.json()
                    dispatch({
                        type: types.cargaData,
                        payload: dataRespuestaJson
                    })
                }


                break;

            case "#/gerencia/auditorias/solicitar":

            const solicitarAuditorias = await validarEntorno('JSON/ejemploCrearSolicitud_json.html', 'POST', tipoSolicitud)

            if (solicitarAuditorias?.ok) {
                const dataRespuestaJson = await solicitarAuditorias.json()
                dispatch({
                    type: types.cargaData,
                    payload: dataRespuestaJson
                })
            }


            break;

            case "#/gerencia/operaciones/solicitar":

            const solicitarOperaciones = await validarEntorno('JSON/ejemploCrearSolicitud_json.html', 'POST', tipoSolicitud)

            if (solicitarOperaciones?.ok) {
                const dataRespuestaJson = await solicitarOperaciones.json()
                dispatch({
                    type: types.cargaData,
                    payload: dataRespuestaJson
                })
            }


            break;

            default:

                const dataReq = {
                    tipo: tipoSolicitud,
                    hash: window.location.hash
                }

                const solicitud = await validarEntorno('JSON/ejemploCrearSolicitud_json.html', 'POST', dataReq)

                if (solicitud?.ok) {
                    const dataRespuestaJson = await solicitud.json()
                    dispatch({
                        type: types.cargaData,
                        payload: dataRespuestaJson
                    })
                }
                
                break;
        }


    }
}

export const selectInicial = (data) => {
    return async (dispatch) => {

        const dataEnv = {
            hash: data
        }

        const respuesta = await validarEntorno('JSON/selectsSolicitudEstudios_json.html', 'POST', dataEnv)

        if (respuesta.ok) {
            dispatch({
                type: types.selectInicialSolicitud,
                payload: await respuesta.json()
            })
        }

    }

}


export const selectInicialDemo = (data) => {
    return async (dispatch) => {

        const dataEnv = {
            hash: data
        }

        const respuesta = await validarEntorno('JSON/selectsSolicitudEstudios_json_col.html', 'POST', dataEnv)

        if (respuesta.ok) {
            dispatch({
                type: types.selectInicialSolicitud,
                payload: await respuesta.json()
            })
        }

    }
}

export const solicitarDataDemo = (tipoSolicitud) => { 
    return async (dispatch) => {

        let solicitarInteligencia = '';

        switch (tipoSolicitud.idSeleccionado) {
            case 'ad_seleccion_multiple':
                solicitarInteligencia = await validarEntorno('JSON/crear_solicitud_inteligencia_json_mul.html', 'POST', tipoSolicitud);

                if (solicitarInteligencia?.ok) {
                    const dataRespuestaJson = await solicitarInteligencia.json()
                    dispatch({
                        type: types.cargaData,
                        payload: dataRespuestaJson
                    })
                }

                break;
            case 'ad_correo_candidatos':
                    solicitarInteligencia = await validarEntorno('JSON/crear_solicitud_inteligencia_json_col.html', 'POST', tipoSolicitud);
    
                    if (solicitarInteligencia?.ok) {
                        const dataRespuestaJson = await solicitarInteligencia.json()
                        dispatch({
                            type: types.cargaData,
                            payload: dataRespuestaJson
                        })
                    }
                    break;
            default:
                break;
        } 
    }
}

export const recargarDataIncial = () => {
    return (dispatch) => {
        dispatch({
            type: types.restablecerData
        })
    }
}