import { Form } from "react-bootstrap";

const Input = ({ item, handleInputChange, index, idx, reg, nombre, error, handleButtonEstado }) => {

  return (
    <Form.Group style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {/* <Form.Label style={{ fontWeight: '700' }}>{item.titulo}:</Form.Label> */}
      <p style={{ width: '100%', textAlign: 'justify', color: "var(--gray-700)", margin: '0px' }}>
        <b>{item.titulo}</b>  : {item.valor}
      </p>
    </Form.Group>
  );

}

export default Input;
