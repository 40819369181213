import React, {useState} from 'react'

import {GoogleMap, withScriptjs, withGoogleMap, Marker, Polygon, Circle} from 'react-google-maps'
import Polyline from 'react-google-maps/lib/components/Polyline';

import EditModal from '../../../components/modales/EditModal';

// 3.4334185,-76.4517656, 1.2047533,-77.2699356

// const cali = { lat: 3.4334185, lng: -76.4517656}

import '../styles/Heatmap.scss'

const iconos = {
  cultivo_ilicito: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743798/planta_bt7n25.svg'
  },
  atentado: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743964/explosion_qisd6j.svg'
  },
  oleoducto: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/valvula.svg'
  }
}


const HeatMapUbi = (props) => {

  const {
    coords,
    getCoordinates,
    index,
    idx,
    showDescriptionModal,
    setShowDescriptionModal,
    isForm,
    submitLatLng,
    setFormNumber,
    setLat,
    setLng,
    lat,
    lng,
    marker,
    color,
    tipoSeleccion
  } = props

  // const [ openInfo, setOpenInfo ] = useState(false);
  const [ descripcion, setDescripcion ] = useState('');
  const [invalidDes, setInvalidDes] = useState(false);
  // const [markerSelected, setMarkerSelected] = useState({})



    const getLatLng = (e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
    setFormNumber(0);

    const latitud = e.latLng.lat();
    const longitud = e.latLng.lng();

    if(props.tipoMapa === 'trazado' || props.tipoMapa === 'oleoducto'){
      setShowDescriptionModal(index, idx);
    } else {
      getCoord(latitud, longitud);
    }
  }


  const closeModal =  () => {
    setShowDescriptionModal(index, idx);
  }

  const getCoord = (latitud, longitud) => {
      setFormNumber(idx);
      getCoordinates(latitud, longitud, index, descripcion);
      setDescripcion('');
  }

  const getCoordForm = (e) => {
    setShowDescriptionModal(index, idx);
    submitLatLng(e, index, descripcion);
    setDescripcion('');
  }

  const getPoints = () => {
    let arr = [];
    if (coords.length > 0) {
      // eslint-disable-next-line array-callback-return
      coords.map((hm) => {      // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(hm.lat, hm.lng)
        arr.push(latlng);
      });
    }

    return arr;
  }

  // const getCenter = () => {
  //   let puntoCentral = {};

  //   console.log(seccionPoligono)

  //   if (seccionPoligono) {
  //     if (seccionPoligono[0].puntos.length > 1) {
  //       /* eslint-disable array-callback-return */
  //       /* eslint-disable no-undef */
  //       const bounds = new google.maps.LatLngBounds();

  //       for (let i = 0; i < seccionPoligono[0].puntos.length; i++) {
  //           bounds.extend(seccionPoligono[0].puntos[i]);
  //       }

  //       puntoCentral = {
  //         lat: bounds.getCenter().lat(),
  //         lng: bounds.getCenter().lng()
  //       }

  //       return puntoCentral;
  //     } if (coords.length === 1) {

  //         puntoCentral = {
  //             lat: seccionPoligono[0].puntos[0].lat,
  //             lng: seccionPoligono[0].puntos[0].lng
  //         }

  //         return puntoCentral;
  //     }
  //   } else {
  //     puntoCentral = {
  //       lat: latitud,
  //       lng: longitud
  //     }

  //     return puntoCentral;
  //   }

  // }

  const getMapType = () => {
      switch (tipoSeleccion) {
        case 'departamental':
            return (
              <Polygon
                path={coords}
                //key={1}
                options={{
                    fillColor: color,
                    fillOpacity: 0.4,
                    strokeColor: color,
                    strokeOpacity: 1,
                    strokeWeight: 1
                }}
              />
            );
        case 'punto':
          return (
            <>
              <Circle
                center={marker}
                radius={50000}
                options={{
                  fillColor: color,
                  strokeOpacity: 0.75,
                  strokeWeight: 2,
                  strokeColor: color
                }}
              />
            </>
          );
        case 'poligono':
          return (
            <Polygon
              path={coords}
              //key={1}
              options={{
                  fillColor: color,
                  fillOpacity: 0.4,
                  strokeColor: color,
                  strokeOpacity: 1,
                  strokeWeight: 1
              }}
            />
          )
        case 'oleoducto':
          return(
            <>
              <Polyline
                path={coords}
                geodesic={true}
                options={{
                  strokeColor: "#82EA35",
                  strokeOpacity: 0.75,
                  strokeWeight: 5
                }}
              />
              {coords.map((coord, index) => (
                <Marker
                  position={coord}
                  icon={iconos.oleoducto.icono}
                />
              ))}
            </>
          )
        default:
          break;
      }
  }

  const handleChangeData = (e) => {
    const { value } = e.target;
    setDescripcion(value);
  }

  // const getRiskPolylyneColor = () => {

  //   let colorRisk = "#000";

  //   switch (colorRisk) {
  //     case 'bajo':
  //       color = '#23A835';
  //       break;
  //     case 'medio':
  //       color = '#DBB93D';
  //       break;
  //     case 'alto':
  //       color = '#EA5335'
  //       break;
  //     default:
  //       color = "#000";
  //       break;
  //   }

  //   return color;
  // }

  // useEffect(() => {
  //   showMunicipio()
  // }, [municipioSelected ? municipioSelected : null])

  return(
    <>
      { (props.tipo && props.tipo === 'ubicacion') && (
        <GoogleMap
          defaultZoom={props.zoom ? props.zoom : 5}
          zoom={props.zoom ? props.zoom : 5}
          defaultCenter={{
            lng: parseFloat(props.longitud),
            lat: parseFloat(props.latitud)
          }}
          center={{
            lng: parseFloat(props.longitud),
            lat: parseFloat(props.latitud)
          }}
          onClick={ tipoSeleccion === 'poligono' ? (e) => getLatLng(e) : undefined}
        >
          <>
            {marker.lat !== '' && marker.lng !== '' ? (
              <Marker
                position={marker}
              />
            ) : null}
          </>
          { getMapType() }
        </GoogleMap>
      )}
      <EditModal
        typeModal="obtenerCoordenadasDes"
        isOpen={showDescriptionModal}
        setIsOpen={closeModal}
        handleData={handleChangeData}
        descripcion={descripcion}
        setConfirmarUbicacion={getCoord}
        setFormUbicacion={getCoordForm}
        invalidDes={invalidDes}
        lat={lat}
        lng={lng}
        isForm={isForm}
      />
    </>
  );
}

export default withScriptjs(
  withGoogleMap(
    HeatMapUbi
  )
)

