import React, {useState, useEffect} from 'react'

import { Form, Card, Col, Row } from 'react-bootstrap';

import { validarEntorno } from '../../../helpers/validarHost';

import Select from 'react-select';
import NotificationModal from '../../modales/NotificationModal'

import './styles/Investigacion.scss'

export default function Investigacion(props) {

  const {cuentas, encargados} = props.contenido

  // console.log(props.contenido)

  const [tipoModal, setTipoModal] = useState({
    tipo: '',
    identificador: '',
    mensaje: ''
  });
  const [modalConfirmacionSolicitud, setModalConfirmacionSolicitud] = useState(false);
  const [validarEmpresa, setValidarEmpresa] = useState(true);
  const [validated, setValidated] = useState(false);
  const [formulario, setFormulario] = useState({
    nombre: '',
    cuenta: '',
    encargado: '',
    descripcion: '',
    fecha_inicio: '',
    fecha_ven : ''

  });
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    transformarOpciones()
  }, [])

  const transformarOpciones = () => {
    
  }

  // const {empresas} = props.contenido

  const handleDataFormulario = ({target}) => {
    setFormulario({
      ...formulario,
      [target.name] : target.value 
    })

    console.log(formulario)
  }

  const handleSelectCuenta = (value) => {
    
      setFormulario({
        ...formulario,
        cuenta : value.value 
      })
    

    console.log(value)
    console.log(formulario)
  }

  const handleSelectEncargado = (value) => {
    
    setFormulario({
      ...formulario,
      encargado : value.value 
    })
  

  console.log(value)
  console.log(formulario)
}

  const enviarFormulario = async (e) => {

    e.preventDefault()

    const form = e.currentTarget;

    if (form.checkValidity() === false ) {
        e.preventDefault();
        if(formulario.id_empresa === ''){
          setValidarEmpresa(false)
        }

    }
    setValidated(true)
    setIsDisabled(true)

    
    if (form.checkValidity() === true) {
      
      setValidarEmpresa(false)
      

      const dataReq = {
        hash: window.location.hash,
        formulario

      } 

      console.log(dataReq)

      const req = await validarEntorno('JSON/sol_inv.html','P0ST', dataReq)

      console.log(req)
      
      if(req.ok){
        const res = await req.json()
        // registroAuditoriaCreado
        if(res.estado === 'ok'){
          setTipoModal({
            ...tipoModal,
            tipo: 'registroInv',
            identificador : res.idSolicitud
          })
          setModalConfirmacionSolicitud(true)

        } else if(res.estado === 'false'){
          setTipoModal({
            ...tipoModal,
            tipo: 'registroAuditoriaError',
            mensaje: res.mensaje
          })
          setModalConfirmacionSolicitud(true)
        }
        setIsDisabled(false)
      }else{
        setTipoModal({
          ...tipoModal,
          tipo: 'errorActualizacionDofa'
        })
        setIsDisabled(false)
      }

    }





  }


  return (
    <Card 
      className='shadow cardContainerSolicitud' 
      style={{borderRadius: '0px 10px 10px 10px'}}
    >
      <h5 className='titleCard'>Información de solicitud</h5>

      <Card.Body>
        <Form  className='formEstudioBasicoContainerInv' noValidate validated={validated} onSubmit={enviarFormulario}>
              <Form.Group  id='nombreInterno' style={{ width: '97.5%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Nombre interno
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='nombre' required ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Cuenta clientes
                  </Form.Label>
                  <div className='inputContainer'>
                  <Select 
                    options={cuentas}
                    // item={item} 
                    onChange={handleSelectCuenta} 
                    name='cuenta'
                    // index={index} 
                  />
                    
                      {/* <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='cuenta' required as='select'
                      isInvalid={validarEmpresa}
                      isValid={!validarEmpresa}
                      defaultValue={''}
                      >
                        <option value='' disabled>-- Seleccione -- </option>
                        {cuentas.map((opcion) => {
                          return(
                              <option key={opcion.id} value={opcion.id}>{opcion.nombre}</option>
                          )
                        })}
                      </Form.Control> */}
                      <Form.Control.Feedback  type='invalid'>Selecciona la empresa </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group style={{width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Encargado
                  </Form.Label>
                  <div className='inputContainer'>
                    <Select 
                      options={encargados}
                      // item={item} 
                      onChange={handleSelectEncargado} 
                      name='encargado'
                      // index={index} 
                    />
                      {/* <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='encargado' required as='select'
                      isInvalid={validarEmpresa}
                      isValid={!validarEmpresa}
                      defaultValue={''}
                      >
                        <option value='' disabled>-- Seleccione -- </option>
                        {encargados.map((opcion) => {
                          return(
                              <option key={opcion.id} value={opcion.id}>{opcion.nombre}</option>
                          )
                        })}
                      </Form.Control> */}
                      <Form.Control.Feedback  type='invalid'>Selecciona la empresa </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group style={{ width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Fecha de inicio
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='fecha_inicio' required type='date' ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>
              <Form.Group style={{ width: '95%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Fecha de vencimiento
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='fecha_ven' required type='date' ></Form.Control>
                      <Form.Control.Feedback  type='invalid'>Ingresa la fecha </Form.Control.Feedback>
                  </div>
              </Form.Group>

          <div id='observacionesContainerEstudioBasico'>
                <Form.Group id='observacionesFormGroup'>
                  <Form.Label className='observacionesLabel'>
                      Descripción
                  </Form.Label>
                  <div className='textAreaContainer'>
                      <Form.Control 
                      onChange={(e) => handleDataFormulario(e)} 
                      name='descripcion' id='observacionesTextArea' required as='textarea' placeholder='Descripción'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Agrega una descripción</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </div>


          <div className='buttonContainers'>
              <button className='confirmButton' disabled={isDisabled} >Guardar</button>
          </div>
      </Form>
      </Card.Body>
      {
        modalConfirmacionSolicitud === true &&
          <NotificationModal infoNotificacion={tipoModal.mensaje} identificador={tipoModal.identificador} typeModal={tipoModal.tipo} isOpen={modalConfirmacionSolicitud} setIsOpen={setModalConfirmacionSolicitud} />
      }
      
    </Card>
  )
}
