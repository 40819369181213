import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { useForm,  Controller } from 'react-hook-form';
import { useEffect, useState } from "react";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TablaRegistros from "../../GestionadorDinamico/TablaRegistrosCargue";
import OpcionesBuscador from "../../../helpers/OpcionesBuscador";
import { validarEntorno } from "../../../helpers/validarHost";
import NotificationModal from '../../modales/NotificationModal';
import Loader from "../../icons/Loader";


const Main = ({ item, handleInputChange, index, idx, reg, nombre, esEditable, esCargueIndividual }) => {

    const  { control } = useForm();

    const iconExcel = 'https://www.ciacosinte.site/cia2/static/media/excel.svg';

    const HASH = window.location.hash;

    const [data, setData] = useState({archivo: '', tipo: ''});

    const [dataSelect, setDataSelect] = useState([]);

    const [tipoCargue, setTipoCargue] = useState('');

    const [nameFile, setNameFile] = useState('');

    const [modal, setModal] = useState({
        show: false,
        message: '',
        type: ''
    });

    const [initialState, setInitialState] = useState({
        data: {},
        error: null,
        loading: false
    })

    const [band, setBand] = useState(false);

    const handleFileData = (e) => {
        const files = e.target.files;

        getNameFile(files[0]);

        if(files.length > 0) {
            setData({
                ...data,
                archivo: files
            });

        }
    }

    const getNameFile = (file) => {
        setNameFile(file.name);
    }

    const handleType = (e) => {
        if (e) {
            setData({
                ...data,
                tipo: e
            })
        } else {
            setData({
                ...data,
                tipo: ''
            })
        }

    }

    const chargeSelect = async () => {
        const dataReq = {
            hash: HASH
        }

        const req = await validarEntorno('JSON/GestionDinamica/select_car_din.php', 'POST', dataReq);

        if (req.ok) {
            const res = await req.json();
            setDataSelect(res.data);
        }
    }

    const getRiesgos = async () => {
        const dataReq = {
          hash: window.location.hashC
        }

        const req = await validarEntorno('JSON/GestionDinamica/tabla_registros_facturas.php', 'POST', dataReq)

        if(req.ok){
          const res = await req.json()

          setInitialState({...initialState, data: res})
          //setAreaOpen(res.factores[0]?.id)
          // setAreaOpenLabel(res.factores[0]?.value)

        //   getActividades(res.factores[0]?.id);
        }

      }

    useEffect(() => {
       chargeSelect()
    }, []);

    const convertFile = (file) => {
        const newObject  = {
            'lastModified'     : file[0].lastModified,
            'lastModifiedDate' : file[0].lastModifiedDate,
            'name'             : file[0].name,
            'size'             : file[0].size,
            'type'             : file[0].type
        };

        return newObject;
    }

    const setModalClose = () => {
        setModal({
            ...modal,
            show: false
        })
        setBand(false);
    }

    const onHandleSubmit = async (e) => {
        e.preventDefault();

        if (data.tipo !== '' && data.archivo !== '') {
            const dataReq = {
                hash: HASH,
                tipo: data.tipo,
                file: convertFile(data.archivo)
            }
            const req = await validarEntorno('JSON/CargueMasivo/envio_cargue.html', 'POST', dataReq);

            if (req.ok) {
                const res = await req.json();

                if (res.estado === 'ok') {
                    setModal({
                        ...modal,
                        show: true,
                        message: res.mensaje,
                        type: 'envio_peticion_correcto'
                    });

                    setBand(true);
                } else {
                    setModal({
                        ...modal,
                        show: true,
                        message: res.mensaje,
                        type: 'envio_peticion_erroneo'
                    });
                }
            } else {
                setModal({
                    ...modal,
                    show: true,
                    message: "Error, intente nuevamente",
                    type: 'envio_peticion_erroneo'
                });
            }
        } else {
            setModal({
                ...modal,
                show: true,
                message: 'Debes completar el formulario',
                type: 'envio_peticion_alerta'
            });
        }
    }

    const deleteFile = () => {
        setData({
            ...data,
            archivo: ''
        });
    }

    return(
        <>
            <Card>
                <Card.Body>
                <div className='headerRegistro' style={{ minHeight: '50px' }}>
                    <p style={{ fontSize: '1.5rem' }} className=''>Cargue Archivos</p>
                </div>
                <Form onSubmit={onHandleSubmit}>
                    <Row>
                        <Col xs={12} style={{  }}>
                            <Form.Group className='formGroupVertical'>
                                    <OpcionesBuscador
                                        data={dataSelect}
                                        name="tipo"
                                        label="Tipo"
                                        handleChange={(e) => handleType(e)}
                                        placeholder="Seleccione un tipo de cargue..."
                                        style={{width: "100%", minWidth: "350px" }}
                                        id="tipo-seleccion"
                                    />
                                    <input type="hidden" ref={reg} name="tipo_cargue" value={tipoCargue} />
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                { data.archivo === '' ? (
                                    <Col xs={6}>
                                        <Form.Group style={{ display: 'block', marginLeft: '1em'}}>
                                            <Form.Label>Archivo</Form.Label>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '100%'}}>
                                                    <label className="custom-file-upload">
                                                        <input type="file" ref={reg} name={`archivo`} onChange={(e) => handleFileData(e)} />
                                                        <CloudUploadIcon style={{ fontSize: 50 }} />
                                                    </label>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                ) : (
                                    <Col xs={6}>
                                        { data.archivo !== '' ?
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                                                <img width={100} height={100} src={iconExcel} />
                                                <p style={{ fontSize: '1.5em' }}>{ nameFile }</p>
                                                <Button style={{height: '40px'}} onClick={deleteFile} className="deniedButton">Eliminar</Button>
                                            </div>
                                        : null }
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button className="confirmButton">Enviar</button>
                    </div>
                </Form>
                </Card.Body>
            </Card>
            {!band ? (
                <TablaRegistros
                    tipo="listas_cargue"
                    band={band}
                    setBand={setBand}
                />
                ) : <Loader />}
            <NotificationModal
                isOpen={modal.show}
                setIsOpen={setModalClose}
                typeModal={modal.type}
                mensaje={modal.message}
            />
        </>
    );

}

export default Main;
