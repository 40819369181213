import react from 'react';
import { Col, Form } from 'react-bootstrap';
import OpcionesCiudades from './OpcionesCiudades';
import { camelize } from './textHelpers';

export const getFormControlByType = (campo) => {

    let field = <></>;

    const { tipo, titulo_campo, valor } = campo;

    switch (tipo) {
        case 1:
            field = (
                <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
                    <Form.Label className="">{ titulo_campo }</Form.Label>
                    <Form.Control 
                        placeholder={titulo_campo} 
                        name={camelize(titulo_campo)}
                        defaultValue={valor}
                    />
                </Form.Group>
            ); 
            break;
        case 2:
            field = (
                <Form.Group as={Col} lg={12} role="form" controlId="formGridTextArea">
                    <Form.Label className="">{ titulo_campo }</Form.Label>
                    <Form.Control
                        className="audit-form-textarea" 
                        as="textarea" 
                        rows={3} 
                        placeholder={titulo_campo} 
                        name={camelize(titulo_campo)}
                        defaultValue={valor}
                    />
                </Form.Group>
            );
            break;
        case 3:
            field = (
                <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaNumber">
                    <Form.Label className="">{titulo_campo}</Form.Label>
                    <Form.Control 
                        className=""
                        placeholder={titulo_campo} 
                        name={camelize(titulo_campo)} 
                        type="number" 
                        maxLength="10" 
                        defaultValue={valor} 
                    />
                </Form.Group>
            );
            break;
        case 4:
            field = (
                <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaEmail">
                    <Form.Label className="">{titulo_campo}</Form.Label>
                    <Form.Control 
                        placeholder={titulo_campo} 
                        name={camelize(titulo_campo)}
                        defaultValue={valor}
                        type="date" 
                    />
                </Form.Group>
            );
            break;
        case 5:
            field = (
                <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaEmail">
                    <Form.Label className="">{titulo_campo}</Form.Label>
                    <Form.Control 
                        placeholder={titulo_campo} 
                        name={camelize(titulo_campo)}
                        defaultValue={valor}
                        type="select" 
                    />
                </Form.Group>
            );
            break;    
        case 6:
            field = <OpcionesCiudades />;
            break;
        default:
            break;
    }

    return field;
}