/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
// import { marker } from 'leaflet'
import React, {useState, useEffect , Fragment} from 'react';

import {GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow, Polyline, Polygon } from 'react-google-maps'
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

import { HojaCoca } from '../components/icons/ocensa/HojaCoca'

// 3.4334185,-76.4517656, 1.2047533,-77.2699356

// const cali = { lat: 3.4334185, lng: -76.4517656}

import './Map.scss'



const iconos = {
  cultivo_ilicito: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/planta.svg'
  },
  atentado: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_amenaza.svg'
  },
  asentamiento_indigena: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/asentamiento_indigena.svg'
  },
  presencia_goes: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_fuerzas.svg'
  },
  sindicato: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_social.svg'
  },
  armada: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_fuerzas.svg'
  },
  comunidad: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_social.svg'
  },
  eln: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_amenaza.svg'
  },
  etnia: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_social.svg'
  },
  indice: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/indice.svg'
  },
  lider_comunal: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_social.svg'
  },
  ejercito: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_fuerzas.svg'
  },
  policia: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_fuerzas.svg'
  },
  politica_amarillo: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_politico.svg',
  },
  politico_rojo: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_politico.svg'
  },
  politico_azul: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_politico.svg'
  },
  politico_verde: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_politico.svg'
  },
  politico_naranja: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_politico.svg'
  },
  oleoducto: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/valvula.svg'
  },
  fuerza_disponible: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_fuerzas.svg',
    baeev: 'https://www.ciacosinte.site/cia2/static/media/BAEEV.svg',
    mecanizado: 'https://www.ciacosinte.site/cia2/static/media/mecanizado.svg',
    caballeria: 'https://www.ciacosinte.site/cia2/static/media/caballeria.svg'
  },
  politico: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_politico.svg'
  },
  economico: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_economico.svg'
  },
  social: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_social.svg'
  },
  amenaza: {
    icono: 'https://www.ciacosinte.site/cia2/static/media/punto_amenaza.svg',
    farc: 'https://www.ciacosinte.site/cia2/static/media/farc.svg',
    eln: 'https://www.ciacosinte.site/cia2/static/media/eln.svg'
  }
}

const stringInfoWindow = 'hola'

const getPointsRoute = (arr) => {
  // console.log(arr)
  const points = [];
  arr.map((item, index) => {
    const objPoints = {
      lat: parseFloat(item.puntos.lat),
      lng: parseFloat(item.puntos.lng)
    }
    points.push(objPoints);
  })

  return points;
}


const Map = (props) => {

  const { data } = props

  const [ openInfo, setOpenInfo ] = useState(false);
  const [ datos, setDatos ] = useState(false);


  const [ centerDefault, setCenterDefault ] = useState(
    {
      lat: props.latitud,
      lng: props.longitud
    }
  );
  const [ showInfoIndex, setShowInfoIndex ] = useState('');
  const [ coordsCenter, setCoordsCenter ] = useState({
      lat: 0,
      lng: 0
  });
  const [ showPolygonCenter, setShowPolygonCenter ] = useState(false);

  // const [ coords, setCoords ] = useState([]);

  const handleOpenInfo = index => {
    // setOpenInfo(!openInfo)
    setShowInfoIndex(index);
  }

  const onSelect = (item, index) => {
    console.log(item);
    setMarkerSelected(item);
    handleOpenInfo(index);
  }

  const [markerSelected, setMarkerSelected] = useState()

  const getPoints = () => {
    let arr = [];
    if (garmados && garmados.length > 0) {
      garmados.map((garmado) => {
        garmado.coords.map((item) => {
            // eslint-disable-next-line no-undef
            const latlng = new google.maps.LatLng(item.lat, item.lng)
            arr.push(latlng);
        });
      });
    }

    return arr;
  }

  const onLoad = heatmapLayer => {
    console.log('HeatmapLayer onLoad heatmapLayer: ', heatmapLayer)
  }

  const onUnmount = heatmapLayer => {
    console.log('HeatmapLayer onUnmount heatmapLayer: ', heatmapLayer)
  }

  const bounds = new google.maps.LatLngBounds();

  const getCenterPolygon = (poligono) => {
    for (let i = 0; i < poligono.length; i++) {
      bounds.extend(poligono[i]);
    }

    setCoordsCenter({
      lat: bounds.getCenter().lat(),
      lng: bounds.getCenter().lng()
    });

    setShowPolygonCenter(true);

  }

  // const getCoordinates = (e) => {

  //   const crd = {
  //     lat: e.latLng.lat(),
  //     lng: e.latLng.lng()
  //   };

  //   setCoords([...coords, crd]);
  // }

  const getColorPolygone = (tipo) => {
    let color = '#000';

    switch (tipo) {
      case 'asentamientos':
        color = '#213265'
        break;
      case 'presencia_grupos_armados_ilegales':
        color = '#ffe876'
        break;
      case 'inb':
          color = '#007605'
          break;
      default:
        break;
    }

    return color;
  }

  const getDatos = () => {
    setDatos(props?.data)
  }

  useEffect(() => {

    getDatos();

  }, [props]);


  return(
    <GoogleMap
      key={props?.data}
      defaultZoom={props.zoom ? props.zoom : 5}
      zoom={props.zoom}
      defaultCenter={{
        lat: props.latitud,
        lng: props.longitud
      }}
      center={{
        lat: props.latitud,
        lng: props.longitud
      }}
      // onClick={getCoordinates}
    >
    { data && data?.map((item, index) => (
      ( item.tipo_mapa === 'puntos' ? (
        <Marker
          key={item.locacion}
          position={item.coord}
          onClick={() => onSelect(item, index)}
          icon=
          {item.tipo === 'cultivo_ilicito'
            ? iconos.cultivo_ilicito.icono
            : item.tipo === 'atentado'
              ? iconos.atentado.icono
              : item.tipo === 'asentamiento_indigena'
                ? iconos.asentamiento_indigena.icono
                : item.tipo === 'presencia_goes'
                  ? iconos.presencia_goes.icono
                  : item.tipo === 'sindicato'
                  ? iconos.sindicato.icono
                    : item.tipo === 'armada'
                    ? iconos.armada.icono
                      : item.tipo === 'comunidad'
                      ? iconos.comunidad.icono
                        : item.tipo === 'eln'
                        ? iconos.amenaza.eln
                          :  item.tipo === 'etnia'
                          ? iconos.etnia.icono
                            : item.tipo === 'indice'
                            ? iconos.indice.icono
                              : item.tipo === 'lider_comunal'
                                ? iconos.lider_comunal.icono
                                : item.tipo === 'ejercito'
                                  ? iconos.ejercito.icono
                                    : item.tipo === 'policia'
                                      ? iconos.policia.icono
                                      : item.tipo === 'politico_amarillo'
                                        ? iconos.politica_amarillo.icono
                                        : item.tipo === 'politico_rojo'
                                          ? iconos.politico_rojo.icono
                                            : item.tipo === 'politico_azul'
                                            ? iconos.politico_azul.icono
                                              : item.tipo === 'politico_verde'
                                                ? iconos.politico_verde.icono
                                                : item.tipo === 'politico_naranja'
                                                  ? iconos.politico_naranja.icono
                                                    : item.tipo === 'disidencias_farc'
                                                      ? iconos.amenaza.icono
                                                        : (item.tipo === 'autoridades_civiles'
                                                          || item.tipo === 'resguardos_indigenas'
                                                          || item.tipo === 'comunidades'
                                                          || item.tipo === 'sindicatos'
                                                          || item.tipo === 'lideres_comunales'
                                                          || item.tipo === 'ong'
                                                          || item.tipo === 'etnias'
                                                          || item.tipo === 'poblacion_flotante'
                                                          || item.tipo === 'aceptacion_institucional'
                                                          || item.tipo === 'programas_de_desarrollo'
                                                          || item.tipo === 'indice_de_necesidades'
                                                          || item.tipo === 'cobertura_en_salud'
                                                          || item.tipo === 'indice_de_alfabetizacion'
                                                        )
                                                          ? iconos.social.icono
                                                            : (item.tipo === 'partidos_politicos'
                                                              || item.tipo === 'indice_de_aceptacion'
                                                            )
                                                              ? iconos.politico.icono
                                                                : (item.tipo === 'vocación_economica'
                                                                  || item.tipo === 'cobertura_servicios_públicos'
                                                                  || item.tipo === 'iniciativas_regionales'
                                                                  || item.tipo === 'proyectos_productivos'
                                                                )
                                                                  ? iconos.economico.icono
                                                                    : (item.tipo === 'ejercito_nacional'
                                                                    || item.tipo === 'armada_nacional'
                                                                    || item.tipo === 'fuerza_aerea'
                                                                    || item.tipo === 'policia_nacional'
                                                                    || item.tipo === 'seguridad_privada'
                                                                  )
                                                                    ? iconos.fuerza_disponible.icono
                                                                      : (item.tipo === 'gao'
                                                                      || item.tipo === 'delincuencia_comun'
                                                                      || item.tipo === 'narcotrafico'
                                                                      || item.tipo === 'microtrafico'
                                                                      || item.tipo === 'otras_amenazas'
                                                                    )
                                                                      ? iconos.amenaza.icono
                                                                        : ''
          }
        style={{color: 'blue'}}
      >
          {(showInfoIndex === index) && (
            <InfoWindow
              position={markerSelected.coord}
              clickable={true}
              onCloseClick={() => setMarkerSelected({})}
              style={{width: '1500px'}}
            >
              <div className='contenedorInfoWindow' >
                {
                  markerSelected.titulo &&
                  <div className='imagenInfo'>
                    <h4 className='titluloInfo'>{item.titulo}</h4>
                    {
                      item.imagen &&
                      <img style={{width: '100px' , borderRadius:'10px' }} src={markerSelected.imagen} alt={`noticia ${markerSelected.titulo} `}></img>
                    }
                  </div>
                }
                <div className='textInfo'>
                  { markerSelected.enlace && <a href={markerSelected.enlace}>Ver más</a> }
                  <p>{markerSelected.descripcion}</p>
                </div>
              </div>
            </InfoWindow>
          )}
          </Marker>
      ) : ( item.tipo_mapa === 'poligono' ? (
        <Fragment key={index}>
          <Polygon
            path={item.coords}
            //key={1}
            options={{
                fillColor: getColorPolygone(item.tipo),
                fillOpacity: 0.4,
                strokeColor: "#000",
                strokeOpacity: 1,
                strokeWeight: 1
            }}
            onClick={() => getCenterPolygon(item.coords)}
          />
          {showPolygonCenter ? (
            <Marker position={coordsCenter}>
              <InfoWindow onCloseClick={() => setShowPolygonCenter(false)}>
                <div>
                  <h4 className="pageTitule">Indice de necesidades basicas (INB)</h4>
                  <p>Porcentaje de tumaco: 80%</p>
                </div>
              </InfoWindow>
            </Marker>
          ) : null}
        </Fragment>
      ) : ( item.tipo_mapa === 'limites' ? (
        <Polyline
          defaultPath={item.coords}
          path={item.coords}
          geodesic={true}
          options={{
            strokeColor: "#ff2527",
            strokeOpacity: 0.75,
            strokeWeight: 2
          }}
        />
      ) :  (item.tipo_mapa === 'oleoducto' ? (
        <Fragment>
          <Polyline
            defaultPath={getPointsRoute(item.puntos)}
            path={getPointsRoute(item.puntos)}
            geodesic={true}
            options={{
              strokeColor: "#2b2b2b",
              strokeOpacity: 0.75,
              strokeWeight: 6
            }}
          />
        </Fragment>
      ) : (item.tipo_mapa === 'puntos_calor' ? (
          <HeatmapLayer
            data={getPointsHeatmap(item.puntos)}
            radius={5000}
            fillOpacity={1}
          />
      ) : (item.tipo_mapa === 'puntos_oleducto' ? (
        <Fragment>
          {item.puntos.map((coord, index) => (
                  <MarkerWithLabel
                    key={index}
                    position={{
                      lat: parseFloat(coord.puntos.lat),
                      lng: parseFloat(coord.puntos.lng)
                    }}
                    icon={iconos.oleoducto.icono}
                    labelAnchor={new google.maps.Point(0, 2)}
                  >
                    <div
                      style={{
                        backgroundColor: coord.tipo === 'chequeo' ? 'green' : 'white',
                        color: coord.tipo === 'chequeo' ? '#fff' : 'black',
                        fontSize: '7px',
                        padding: '0 5px',
                        fontWeight: '800'

                      }}
                    >
                      {coord.id_valv}
                    </div>
                  </MarkerWithLabel>
          ))}
        </Fragment>
      ) : ( item.tipo_mapa === 'poligono_fuerza' ? (
        <Fragment key={index}>
          <Polygon
            path={getCoordsPolygon(item.puntos)}
            // editable = {true}
            icon={iconos.homicidio.icono }
            label='holaaaaaaaa'
            title='holaaaaaaaa'
            labelContent='holaaaaaaaa'
            defaultLabel= 'asdfasdf'
            defaultTitle= 'assdfasdfasdfds'
            // labelContent
            labelAnchor={new google.maps.Point(0, 2)}
            defaultIcon= 'asdfasdf'
            options={{
                fillColor: item.color,
                fillOpacity: item.opacidad ? item.opacidad : 0.3 ,
                strokeColor: '#2b2601',
                strokeOpacity: 0.5,
                strokeWeight: 3,
                color: 'white',
                label: 'holaaaaaaaa',
                text: 'holaaaaaaaa',
                title: 'holaaaaaaaa',
                content: 'holaaaaaaaa'
            }}
          >
            <MarkerWithLabel
              labelAnchor={new google.maps.Point(3, 28)}
            >
              <div>
                <span style={{ backgroundColor:'red', display:'flex', justifyContent:'center', color:'white', fontWeight:'600', fontSize:'12px', textAlign:'center !important'}} >{'asdfsd'}</span>
              </div>
            </MarkerWithLabel>
          </Polygon>


        </Fragment>
      )


      : null

      )

      )))  )))))}

    {/* </Polygon>
    { showPolygonCenter ? (
      <Marker
        position={coordsCenter}
      >
        <InfoWindow onCloseClick={() => setShowPolygonCenter(false)}>
          <div>
            <h4 className="pageTitule">Indice de necesidades basicas (INB)</h4>
            <p>Porcentaje de tumaco: 80%</p>
          </div>
        </InfoWindow>
      </Marker>
    ) :  null } */}

    {/* { coords.length > 0 && (
      coords.map((coord, index) => (
       <Marker
        position={coord}
       >
            <InfoWindow
              position={coord}
            >
              <div>
                <h5>{coord.lat}</h5>
                <br />
                <h5>{coord.lng}</h5>
              </div>
            </InfoWindow>
       </Marker>
      ))
    )} */}

    {/* {
      markerSelected.locacion && ((markerSelected.titulo && markerSelected.descripcion) ) &&
      (
        <InfoWindow
        position={markerSelected.coord}
        clickable={true}
        onCloseClick={() => setMarkerSelected({})}
      >
        <div className='contenedorInfoWindow' >

          {
            markerSelected.titulo &&
            <div className='imagenInfo'>
              <h4 className='titluloInfo'>{markerSelected.titulo}</h4>
              {
                markerSelected.imagen &&
                <img style={{width: '100px' , borderRadius:'10px' }} src={markerSelected.imagen} alt={`noticia ${markerSelected.titulo} `}></img>
              }
            </div>
          }
          <div className='textInfo'>
            { markerSelected.enlace && <a href={markerSelected.enlace}>Ver más</a> }
            <p>{markerSelected.descripcion}</p>
          </div>
        </div>
      </InfoWindow>
      )
    } */}


  {/* {zonas && zonas.length > 0 &&

    zonas.map((poligono, index) => {
      return(
        <Fragment key={index}>
          <Polygon
            path={poligono.coords}
            //key={1}
            options={{
                fillColor: getColorPolygone(poligono.tipo),
                fillOpacity: 0.4,
                strokeColor: "#000",
                strokeOpacity: 1,
                strokeWeight: 1
            }}
            onClick={() => getCenterPolygon(poligono.coords)}
          >
          </Polygon>
          { showPolygonCenter ? (
            <Marker
              position={coordsCenter}
            >
              <InfoWindow onCloseClick={() => setShowPolygonCenter(false)}>
                <div>
                  <h4 className="pageTitule">Indice de necesidades basicas (INB)</h4>
                  <p>Porcentaje de tumaco: 80%</p>
                </div>
              </InfoWindow>
            </Marker>
          ) :  null }
        </Fragment>
      )
    })


  }


  {limites.length > 0 ? (
    ( limites.map((limite) => (
      <Polyline
        defaultPath={limite.coords}
        path={limite.coords}
        geodesic={true}
        options={{
          strokeColor: "#ff2527",
          strokeOpacity: 0.75,
          strokeWeight: 2
        }}
      />
    )))): null} */}
    </GoogleMap>
  );
}

export default withScriptjs(
  withGoogleMap(
    Map
  )
)

// export default Map



// GAO
// GAOR
// GDO
// Grupos armados organizados

// Grupos armados organizados residuales
// grupos delincuenciales organizados
