import React, {useState, useEffect} from 'react'
import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useTranslation } from 'react-i18next';
// import {AMCHART} from '../../helpers/amchartLicense'


export default function DonaDosniveles(props) {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  am4core.useTheme(am4themes_animated);
  // am4core.addLicense(AMCHART);


  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.PieChart3D);


    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(35);

    // Add second series
    let pieSeries2 = chart.series.push(new am4charts.PieSeries3D());
    pieSeries2.dataFields.value = "value";
    pieSeries2.dataFields.category = "category";
    pieSeries2.slices.template.properties.shiftRadius = 0.13
    // pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0.15;
    pieSeries2.slices.template.states.getKey("active").properties.scale = 1.04;
    pieSeries2.slices.template.propertyFields.fill = "fill";
    pieSeries2.labels.template.text = "[bold]{category}: {value.percent.formatNumber('.')}%";
    pieSeries2.slices.template.tooltipText = "{category}: {value}";

    // [bold]{value}[/]
    // Add data
    pieSeries2.data = props.data

    chart.exporting.menu = new am4core.ExportMenu();
    // chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    // chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    // chart.exporting.menu.minWidth = 'auto';
    // chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": t('botones.imagen')},
        // { "type": "csv", "label": "Tabla" },
        { "label": t('botones.imprimir'), "type": "print" }
      ]
    }];

  }, [])

  return (
    <Card className="shadow cardGraficaGeneral cardGraficaAnalistas" style={{width:'100%'}}>
     {/*  <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br />
      <Card.Body className='bodyGraficasGeneral'>
        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> {props.title}
        <br/>
        {props.parametrizado
          ?
            <>
              {props.parametrizado} - {props.periodo }
            </>

          : null
        }
        </h4>
        <br/>
        <br/>
        <div id={props.id} style={{ width: '100%', minHeight: '240px', margin: '0 auto', height:'auto' }}></div>
        <br/>
        <h4 className='pagesTitles' >
          Total: {props.total}
        </h4>
      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
