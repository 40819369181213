import React, { Fragment, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import { Multiselect } from 'multiselect-react-dropdown';



export const MapasCalor = ({ seccion }) => {


    const [dataSelected, setDataSelected] = useState([])





    const tiposAlteraciones = [
        { value: "Delincuencia", cat: "delincuencia" },
        { value: "Protesta", cat: "protesta" },
        { value: "Subversión", cat: "subversión" },
        { value: "Otro", cat: "otro" }
    ]



    const gruposMargenLey = [
        { value: "GAO", cat: "GAO" },
        { value: "GDO", cat: "GDO" },
        { value: "GAOR", cat: "GAOR" }
    ]



    const fuerzaPublica = [
        { value: "Armada", cat: "armada" },
        { value: "Polícia", cat: "polícia" },
        { value: "Ejército", cat: "ejército" },
        { value: "Fuerza Aérea", cat: "fuerzaAérea" },

    ]

    const partidosPoliticos = [
        { value: "Centro Democratico", cat: "centroDemocratico" },
        { value: "Liberal", cat: "liberal" },
        { value: "Conservador", cat: "conservador" },
        { value: "La U", cat: "U" }
    ]
    const selectGenerico = [
        { value: "Ejemplo", cat: "ejemplo" },
    ]

    const style = {

        searchBox: {
            border: "1px solid #707070",
            borderRadius: "7px"
        },
        chips: {
            background: '#213265'
        }
    };

    const [fileInputs, setFileInputs] = useState(false)

    const handleChange = (e) => {
        // console.log(e)
    }

    const handleFile = (e) => {

        // console.log(e)
        setFileInputs(true)
    }

    return (
        <Fragment>
            <h5 className='titleInsideCard'>Mapas de calor</h5>
            <Card.Body>
                <div className='formEstudioBasicoContainer' >
                    <div className='formItem' id='basicFormA'>
                        <Form.Group>
                            <Form.Label>
                                Fecha
                            </Form.Label>
                            <div className='inputContainer'>

                                <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Link
                            </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='link' required type='text' placeholder='Link' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Fuente
                                        </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='fuente' required type='text' placeholder='Fuente' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa la fuente </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Latitud
                                        </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='latitud' required type='text' placeholder='Latitud' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa la fuente </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Longitud
                                        </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='longitud' required type='text' placeholder='Longitud' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa la fuente </Form.Control.Feedback>
                            </div>
                        </Form.Group>





                    </div>
                    <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>

                        <Form.Group>
                            <Form.Label>
                                {seccion === "Histórico de posición política de los alcaldes en cuanto al sector de hidrocarburos"
                                    ? 'Partidos politicos'
                                    : seccion === "Índice de aceptación de la Fuerza Pública"
                                        ? 'Fuerzas publicas'
                                        : seccion === "Presencia e influencia de actores al margen de la Ley (GAO-GDO-GAOR)"
                                            ? 'Actores al Margen de la Ley'
                                            : seccion === "Presencia e influencia de la Fuerza Pública en el territorio"
                                                ? 'Fuerza pública'
                                                : seccion === "Orden público"
                                                    ? 'Tipo de Alteración al orden Público'
                                                    : 'NN'
                                }
                            </Form.Label>
                            <div className='inputContainer'>
                                <Multiselect
                                    options={
                                        seccion === "Histórico de posición política de los alcaldes en cuanto al sector de hidrocarburos"
                                            ? partidosPoliticos
                                            : seccion === "Índice de aceptación de la Fuerza Pública"
                                                ? fuerzaPublica
                                                : seccion === "Presencia e influencia de actores al margen de la Ley (GAO-GDO-GAOR)"
                                                    ? gruposMargenLey
                                                    : seccion === "Presencia e influencia de la Fuerza Pública en el territorio"
                                                        ? fuerzaPublica
                                                        : seccion === "Orden público"
                                                            ? tiposAlteraciones
                                                            : selectGenerico
                                    }
                                    displayValue="value"
                                    labelValue="cat"
                                    placeholder=""
                                    style={style}
                                    onSelect={data => {
                                        setDataSelected(data)

                                    }}
                                    onRemove={data => {
                                        setDataSelected(data)

                                    }
                                    }
                                />
                            </div>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Archivos soporte
                                        </Form.Label>
                            <div className='addButtonContainer' style={{ cursor: 'pointer', width: '100%', marginTop: '-25px', paddingTop: '0px' }} >
                                <input id='selectFile' className='selectFile' name='selectFile' type='file' onChange={handleFile} style={{ cursor: 'pointer' }} />
                                <label id='addButton' htmlFor='selectFile' style={{ cursor: 'pointer' }} >
                                    Añadir documento
                                    <AddIcon />
                                </label>
                            </div>

                        </Form.Group>

                        {
                            fileInputs
                                ?
                                <Fragment>
                                    <Form.Group>
                                        <Form.Label>
                                            Fecha documento
                                        </Form.Label>
                                        <div className='inputContainer'>

                                            <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                    <Form.Group id='observacionesFormGroup'>
                                        <Form.Label className='observacionesLabel'>
                                            Descripción archivo
                                        </Form.Label>
                                        <div className='textAreaContainer'>
                                            <Form.Control name='descripcionFile' id='observacionesTextArea' maxLength={2000} style={{ height: '50px' }} required as='textarea' placeholder='Observaciones'></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa la descripcion</Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                </Fragment>
                                : <Form.Group id='observacionesFormGroup'>
                                    <Form.Label className='observacionesLabel'>
                                        Descripción
                                        </Form.Label>
                                    <div className='textAreaContainer'>
                                        <Form.Control onChange={handleChange} style={{ height: '70px' }} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                        }

                    </div>

                    {
                        fileInputs
                        &&
                        <div id='observacionesContainerEstudioBasico'>
                            <Form.Group id='observacionesFormGroup'>
                                <Form.Label className='observacionesLabel'>
                                    Descripción
                                        </Form.Label>
                                <div className='textAreaContainer'>
                                    <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    }
                </div>

            </Card.Body>
        </Fragment>
    )
}
