import React from "react";
import { Bar, BarChart, CartesianGrid, Cell, LabelList } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "../../ui/Chart";

const chartData = [
  { month: "January", visitors: 186 },
  { month: "February", visitors: 205 },
  { month: "March", visitors: -207 },
  { month: "April", visitors: 173 },
  { month: "May", visitors: -209 },
  { month: "June", visitors: 214 },
];

function BarNegative({ data = chartData, dataKey = "valor", nameKey = "x", maxHeight, color = "var(--blue-500)", color_negativo = "var(--red-500)", label = "label" }) {

  const generateChartConfig = () => {
    const config = {};
    config[dataKey] = { label: label };
    return config;
  };

  const chartConfig = generateChartConfig();

  return (
    <ChartContainer
      config={chartConfig}
      className="mx-auto"
      style={{ maxHeight: maxHeight }}
    >
      <BarChart data={data}>
        <CartesianGrid vertical={false} />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel hideIndicator />}
        />
        <Bar dataKey={dataKey}>
          <LabelList position="top" dataKey={nameKey} fillOpacity={1} />
          {data.map((item) => (
            <Cell
              key={item[nameKey]}
              fill={
                item[dataKey] > 0
                  ? color
                  : color_negativo
              }
            />
          ))}
        </Bar>
      </BarChart>
    </ChartContainer>
  );
}

export default BarNegative;
