import React, {useEffect, useState} from 'react'

import {GoogleMap, withScriptjs, withGoogleMap, Marker, Polygon, InfoWindow} from 'react-google-maps'
import Polyline from 'react-google-maps/lib/components/Polyline';

import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import EditModal from '../../../components/modales/EditModal';

// 3.4334185,-76.4517656, 1.2047533,-77.2699356

// const cali = { lat: 3.4334185, lng: -76.4517656}

import '../styles/Heatmap.scss'


const iconos = {
  cultivo_ilicito: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743798/planta_bt7n25.svg'
  },
  atentado: {
    icono: 'https://res.cloudinary.com/da6bzgewk/image/upload/v1622743964/explosion_qisd6j.svg'
  },
}

const stringInfoWindow = 'hola'

const HeatMapActivities = (props) => {

  const { 
    coords_map, 
    getCoordinatesAct, 
    index, 
    tipoMapa, 
    idx, 
    showDescriptionModal, 
    setShowDescriptionModal, 
    isForm,
    submitLatLngAct,
    setFormNumber,
    setLat,
    setLng,
    lat,
    lng,
    marker,
    seccionPoligono, 
    longitud,
    latitud
  } = props

  const [ openInfo, setOpenInfo ] = useState(false);
  const [ descripcion, setDescripcion ] = useState('');
  const [invalidDes, setInvalidDes] = useState(false);
  const [markerSelected, setMarkerSelected] = useState({})

  const handleOpenInfo = () => {
    setOpenInfo(!openInfo)
  }

  const onSelect = item => {
    setMarkerSelected(item);
    console.log(markerSelected)
  }

  const getLatLng = (e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
    setFormNumber(idx);
    setShowDescriptionModal(index, idx);
  } 

  const closeModal =  () => {
    setShowDescriptionModal(index, idx);
  }

  const getCoordAct = () => {
    getCoordinatesAct(lat, lng, index, descripcion);
    setDescripcion('');
}

  const getCoordFormAct = (e) => {
      setShowDescriptionModal(index, idx);
      submitLatLngAct(e, index, descripcion);
      setDescripcion('');
  }
  
  const getPoints = () => {
    let arr = [];
    if (coords_map.length > 0) {
      // eslint-disable-next-line array-callback-return
      coords_map.map((hm) => {      // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(hm.lat, hm.lng)
        arr.push(latlng);
      });
    }

    return arr;
  }

  const getMapType = () => {
      console.log(coords_map);
      switch (tipoMapa) {
        case 'puntos_calor':
            return (
              <HeatmapLayer
                data={getPoints()}
                radius={1000}
              />
            );
        case 'trazado':
          return (
            <Polyline
              path={coords_map}
              geodesic={true}
              options={{
                strokeColor: "#ff2527",
                strokeOpacity: 0.75,
                strokeWeight: 2
              }}
            />
          );
        case 'puntos_simples':
          return (
            coords_map.map((coord, index) => (
              <Marker 
               position={coord}
              />
          )));
        default:
          break;
      }
  }

  const handleChangeData = (e) => {
    const { value } = e.target;
    setDescripcion(value);
  }

  const getCenter = () => {
    let puntoCentral = {};

    if (seccionPoligono) {
      if (seccionPoligono[0].length > 1) {
          /* eslint-disable array-callback-return */
          /* eslint-disable no-undef */
          const bounds = new google.maps.LatLngBounds();
          for (let i = 0; i < seccionPoligono[0].length; i++) {
              bounds.extend(seccionPoligono[0][i]);
          }

          puntoCentral = {
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng()
          }

          return puntoCentral;
      } else if (coords_map.length === 1) {

          puntoCentral = {
              lat: seccionPoligono[0][0].lat,
              lng: seccionPoligono[0][0].lng
          }

          return puntoCentral;
      }
    } else {
    
      puntoCentral = {
        lat: latitud,
        lng: longitud
      }
      
      return puntoCentral;
    }
  }

  return(
    <>
      <GoogleMap 
        defaultZoom={props.zoom ? props.zoom : 5} 
        defaultCenter={ props.tipo && props.tipo === 'ubicacion' ? { 
          lng: props.longitud,
          lat: props.latitud 
        } : getCenter() }
        onClick={(e) => getLatLng(e)}
      >
        { seccionPoligono && (props.tipo && props.tipo !== 'ubicacion') ? (
           (seccionPoligono.length > 0 && (seccionPoligono.map((seccion, index) => (
            <Polyline
              path={seccion[0]}
              key={index}
              options={{
                  fillColor: "#000",
                  fillOpacity: 0.4,
                  strokeColor: "#000",
                  strokeOpacity: 1,
                  strokeWeight: 1
              }} 
            />
          ))
        ))) : null}
        { coords_map.length > 0 ? getMapType() : null }
      </GoogleMap>
      <EditModal
        typeModal="obtenerDescripcionCoordenadasActi" 
        isOpen={showDescriptionModal}
        setIsOpen={closeModal}
        handleData={handleChangeData}
        descripcion={descripcion}
        setConfirmarUbicacion={getCoordAct}
        setFormUbicacion={getCoordFormAct}
        invalidDes={invalidDes}
        lat={lat}
        lng={lng}
        isForm={isForm}
      />
    </>
  );
}

export default withScriptjs(
  withGoogleMap(
    HeatMapActivities
  )
)

