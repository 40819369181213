import React from 'react'
import { Card, CardHeader, CardDescription, CardTitle } from '../../ui/Card'
import { CardContent } from '@material-ui/core'
import styles from "./Lista.module.scss"
import { Button } from 'primereact/button'

const items = [
    {
        titulo: 'Informe de análisis de riesgos',
        descripcion: 'Ago 2021 - Oct 2021',
        boton: {
            texto: 'Descargar',
            url : 'https://www.google.com'
        }
    },
    {
        titulo: 'Informe de análisis de riesgos',
        descripcion: 'Ago 2021 - Oct 2021',
    },
    {
        titulo: 'Informe de análisis de riesgos',
        descripcion: 'Ago 2021 - Oct 2021',
        boton: {
            texto: 'Descargar',
            url : 'https://www.google.com'
        }
    }
]

export default function Lista() {
    return (
        <Card>
            <CardHeader className="d-flex flex-row justify-between header__underline">
                <div className="">
                    <CardTitle>Area Chart - Interactive</CardTitle>
                    <CardDescription>
                        Showing total visitors for the last 3 months
                    </CardDescription>
                </div>
            </CardHeader>
            <CardContent>
                <div className={styles['lista']}>
                    {items.map((item, index) => (
                        <div key={index} className={styles['item']}>
                            <div className={styles['item__left']}>
                                <span className={styles['item__title']}>{item.titulo}</span>
                                <span className={styles['item__description']}>{item.descripcion}</span>
                            </div>
                            <div className='item__right'>
                                {item?.valor &&
                                    <span className={styles['item__value']}>{item.valor}</span>
                                }
                                <div className={styles['item__value']}>{item.valor}</div>
                                {item?.boton &&
                                    <Button 
                                    className={styles['item__button']} 
                                    size='small' 
                                    onClick={() => window.open(item.boton.url)}
                                    >{item.boton?.texto}</Button>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    )
}
