/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { Card, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useState2 from 'react-usestateref';


import NotificationModal from '../../../modales/NotificationModal';
import { validarEntorno } from '../../../../helpers/validarHost'
import Loader from '../../../icons/Loader';
import InputType from '../../../../helpers/InputType';
import { useTranslation } from 'react-i18next';

export default function SolicitarDinamico({ contenido, selectSeleccionado, setValorSelectInicial, pestaña }) {

  const { inputs } = contenido
  const [banderaPrimerEnvio, setBanderaPrimerEnvio] = useState(false)
  // const [banderaC, setBanderaC] = useState(true)
  // // console.log(pestaña)
  // useEffect(() => {
  //   console.log(banderaC, 'ashdkla')
  // }, [])
  const { t } = useTranslation();

  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();
  const submitButtonRef = useRef(null);

  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false,
  });

  const [modal, setModal] = useState({
    show: false,
    mensaje: '',
    tipo: '',
    redireccion: '',
  });

  const [inputList, setInputList] = useState([{ valor: "", valido: false }]);
  const [inputGroup, setInputGroup, inputGroupRef] = useState2([]);
  const [filtrosRepSelected, setFiltrosRepSelected] = useState([])
  const [validated, setValidated] = useState(false);

  // const filerFilters = (e) => {

  //   setFiltrosRepSelected([]);
  //   // setIdTipoReporte(e.target.value)

  //   // console.log('hola ' + e.target.value)
  //   const filtersFiltered = inputGroup?.valores_opcion?.filter((item) => {
  //       return item.value == e.target.value
  //   })
  //   // console.log(filtersFiltered)
  //   setFiltrosRepSelected(filtersFiltered[0])

  //   reset();
  // }

  const handleCloseModal = () => {
    setModal({
      ...modal,
      show: false,
      mensaje: '',
      tipo: '',
    })
    setValorSelectInicial?.('0');
  }

  const handleCloseModalWarning = () => {
    setModal({
      ...modal,
      show: false,
      mensaje: '',
      tipo: '',
    })
    // setValorSelectInicial('0');
  }

  /**
   * Funcion para validar campos dinamicos por primera ves
   * @param {*} data
   * @returns boolean
   */
  const validateEmpty = (data) => {
    const iterator = [];
    let band = true;
    data?.asesoria?.forEach((item) => (
      iterator?.push(Object.keys(item)[0])
    ));
    inputs?.forEach((element, index) => {
      if ((element.nombre === iterator[index]) && element.es_requerido) {
        if (data.asesoria[index][iterator[index]] === '') {
          band = false;
        }
      }
    });
    return band;
  }
  const [changeB, setChangeB] = useState(false);

  const onSubmit = async (data, e) => {
    if (!banderaPrimerEnvio) {
      e.preventDefault()
      return
    }
    
    setChangeB(true)


    // e.preventDefault()

    if (!validateEmpty(data)) {
      setChangeB(false)
      setModal({
        ...modal,
        show: true,
        mensaje: 'Recuerda validar todos los campos obligatorios',
        tipo: 'envio_peticion_alerta_warning',
        redireccion: ''
      });
      return;
    }
    setChangeB(false)

    setValidated(true)

    setInitialState({ ...initialState, loading: true });

    const { asesoria } = data;

    const arrayToObject = Object.assign({}, ...asesoria);

    const arrPush = [].push(arrayToObject);

    const decorateMultiples = inputList.map((valor) => {

      const transformToArr = Object.entries(valor)[1][1]

      return transformToArr

    })

    const dataReq = {
      dataForm: {
        ...arrayToObject,
        multiples: inputList.length > 0 ? decorateMultiples : null
      },
      hash: window.location.hash,
      id: selectSeleccionado ? selectSeleccionado : pestaña,

    }

    //console.log(dataReq);


    const req = await validarEntorno('JSON/sol_dinamica.html', 'POST', dataReq);
    let res = await req.json();

    //console.log(res)

    if (req.ok) {
      //console.log(res)
      setInitialState({
        ...initialState,
        // data: res,
        // data: res,
        // data: res,
        loading: false
      });

      if (res.estado === 'ok') {
        setModal({
          ...modal,
          show: true,
          mensaje: res?.mensaje ? res?.mensaje : 'Solicitud completa',
          tipo: 'success_din',
          redireccion: res.redireccion
        })
        setChangeB(false)

      } else if (res.estado === 'informacion') {
        // const b = false
        // setBanderaC(b)
        // console.log(b, banderaC, 's')

        setModal({
          ...modal,
          show: true,
          mensaje: res?.informacion ? res?.informacion?.map((e) => {
            return { descripcion: e?.descripcion, titulo: e?.titulo }
          })
            : 'Informacion',
          tipo: 'informacion',
          redireccion: res.redireccion
        })
        setChangeB(false)

      } else {
        setModal({
          ...modal,
          show: true,
          mensaje: res?.mensaje ? res?.mensaje : 'Ha ocurrido un error, intente nuevamente',
          tipo: 'errorFormSolicitud'
        })
        setChangeB(false)

      }

    } else {
      setModal({
        ...modal,
        show: true,
        mensaje: 'Ha ocurrido un error, intente nuevamente',
        tipo: 'error'
      })
      setChangeB(false)

    }
    setChangeB(false)
    reset();
  }

  useEffect(() => {
    setInputGroup([]);
    setTimeout(() => {
      setInputGroup(inputs)
    }, 1000);
  }, [contenido, selectSeleccionado])

  useEffect(() => {
    if (!banderaPrimerEnvio) {
      if (submitButtonRef.current) {
        submitButtonRef.current.click();
        setBanderaPrimerEnvio(true)
      }
    }
  })


  return (
    <>
      <Card className='card__box__surface p-5 card__plain__top__left' >
        <h5 className='titleInsideCard'>{t('formulario.label.informacion_solicitud')}</h5>
        <Card.Body className='p-0'>
          {(inputGroupRef.current && inputGroupRef.current.length > 0) ? (
            <Card className="">
              <div className="">
                <Form noValidate onSubmit={handleSubmit(onSubmit)} style={{ width: '98%', margin: '0 auto', padding: '0' }} >
                  <Card.Body className='p-0'>
                    <Row>
                      <>
                        {(inputGroupRef.current?.map((campo, index) => {
                          const fieldName = `asesoria.0`;
                          return (
                            <InputType
                              item={campo}
                              index={index}
                              idx={index}
                              key={`${0} - ${index}`}
                              reg={register}
                              nombre={`${fieldName}`}
                              errors={errors}
                              col={campo.col}
                              type=""
                              inputList={inputList}
                              setInputList={setInputList}
                            />
                          )
                        }))}
                      </>
                      {!changeB ?
                        <div style={{ display: 'flex', marginTop: '1em', justifyContent: 'flex-end' }}>
                          {/* <button type="button" className="configButton" onClick={()=> {sendConfig()}}>Guardar Configuración</button> */}
                          <button type="submit" style={{ margin: 0 }} className="confirmButton" ref={submitButtonRef}>Guardar</button>
                        </div>
                        :
                        <div style={{ display: 'flex', marginTop: '1em', justifyContent: 'flex-end' }}>
                          {/* <button type="button" className="configButton" onClick={()=> {sendConfig()}}>Guardar Configuración</button> */}
                          <button type="submit" style={{ margin: 0 }} className="confirmButton" ref={submitButtonRef}>Guardar</button>
                        </div>
                      }

                    </Row>
                  </Card.Body>
                </Form>
              </div>
            </Card>
          ) : (<Loader />)}
        </Card.Body>
      </Card>
      <NotificationModal
        // setBandera={setBanderaC}
        // banderaC={banderaC}
        redireccion={modal.redireccion ? modal.redireccion : null}
        typeModal={modal.tipo}
        isOpen={modal.show}
        setIsOpen={handleCloseModal}
        setIsOpenWarning={handleCloseModalWarning}
        mensaje={modal.mensaje}
      />
    </>
  )
}
