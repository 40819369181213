import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from 'react-select';
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { formatValue } from "react-currency-input-field";
import CurrencyInput from "react-currency-input-field";

const CalculoBase = ({ item, index,  reg, error }) => {

  const [anoServicio, setAnoServicio] = useState('');//Año del servicio seleccionado
  const [servicio, setServicio] = useState('');//Servicio seleccionado
  const [regiones, setRegiones] = useState([]);//Regiones del servicio

  /**
   * Funcion que se ejecuta cuando se cambia el valor del select
   * @param {*} e Evento del select
   */

  const getValoresServicio = (e) => {

    setServicio(e);
    const { valores_opcion } = item;
    const { value } = e;
    const filter = valores_opcion.filter((item, index) => {
      return item.value === value;
    });

    setRegiones(filter[0].regiones);
    const inputs = document.querySelectorAll(`input[name^='asesoria.${index}.${item.nombre}.regiones']`);
    inputs.forEach((input, index) => {
      const nameSplit = input.name.split('.')[5];
      const idx = input.name.split('.')[4];
      if (nameSplit === 'base') {
        input.value = filter[0].regiones[idx].campos.base.valor;
        input.readOnly = false;
        if (input.value !== "0" && input.value !== "") {
          input.readOnly = true;
        }
      } else if (nameSplit === 'modificador_porcentaje') {
        input.value = filter[0].regiones[idx].campos.modificador_porcentaje.valor;
      }
      else if (nameSplit === 'modificador_valor') {
        input.value = filter[0].regiones[idx].campos.modificador_valor.valor;
      }
      else if (nameSplit === 'final') {
        input.value = filter[0].regiones[idx].campos.final.valor;
      }
    });

    setAnoServicio(filter[0].year);
  }

  /**
   * Cargar los valores del servicio seleccionado cuando se carga el componente
   * Solo se ejecuta una vez
   */

  useEffect(() => {

    const { valores_opcion } = item;
    const value = item.valor;

    const filter = valores_opcion.filter((item) => {
      return item.value === value;
    });

    let localValores = [];
    if (filter.length > 0) {
      setServicio(filter[0]);
      setRegiones(filter[0].regiones);
      setAnoServicio(filter[0].year);
      localValores= filter[0];
    } else {
      setServicio(valores_opcion[0]);
      setRegiones(valores_opcion[0].regiones);
      setAnoServicio(valores_opcion[0].year);
      localValores = valores_opcion[0];
    }

    const inputs = document.querySelectorAll(`input[name^='asesoria.${index}.${item.nombre}.regiones']`);
    inputs.forEach((input) => {
      const nameSplit = input.name.split('.')[5];
      const idx = input.name.split('.')[4];

      if (nameSplit === 'base' && localValores.regiones[idx].campos.base.valor) {
        input.value = localValores.regiones[idx].campos.base.valor;
        input.readOnly = false;
        if (input.value !== "0" && input.value !== "") {
          input.readOnly = true;
        }
      }

    });

    return () => {
      setServicio('');
      setRegiones([]);
      setAnoServicio('');
    }

  }, []);

  /**
   * Evento que se ejecuta cuando se cambia OnBlur de los inputs de las regiones
   * @param {*} e Evento del input
   * @param {*} index Indice del servicio
   * @param {*} idx Indice de la region
   */

  const onHandleChangeRegion = (e, index, idx) => {
    const { name } = e.target;
    const nameSplit = name.split('.')[5];

    const base = regiones[idx]?.campos?.base?.valor;
    const valor = regiones[idx]?.campos?.modificador_valor?.valor;
    const porcentaje = regiones[idx]?.campos?.modificador_porcentaje?.valor;

    const esBaseVacio = valor === "" || valor === undefined;
    const esValorVacio = valor === "" || valor === undefined;
    const esPorcentajeVacio = porcentaje === "";

    try {
      switch (nameSplit) {
        case 'base':
          if (!esValorVacio || !esPorcentajeVacio) {
            //Tomar primero el valor del modificador de valor  y calcular el porcentaje y el final, si esta vacio el modificador de valor, tomar el porcentaje y calcular el valor y el final
            if (!esValorVacio) {
              const porc = (parseFloat(valor) * 100) / parseFloat(base);
              const valFinal = parseFloat(valor) + parseFloat(base);
              actualizarRegiones(idx, 'campos', porc, 'modificador_porcentaje');
              const tempFinal = !isNaN(valFinal) ? valFinal : '';
              actualizarRegiones(idx, 'campos', tempFinal, 'final');

            } else if (!esPorcentajeVacio) {
              const porc = parseFloat(base) * (parseFloat(porcentaje) / 100);
              const valFinal = (porc) + parseFloat(base);
              actualizarRegiones(idx, 'campos', porc, 'modificador_valor');
              const tempFinal = !isNaN(valFinal) ? valFinal : '';
              actualizarRegiones(idx, 'campos', tempFinal, 'final');
            }
          } else {
            actualizarRegiones(idx, 'campos', base, 'final');
          }
          break;
        case 'modificador_porcentaje':
          if (!esPorcentajeVacio) {
            const porc = parseFloat(base) * (parseFloat(porcentaje) / 100);
            const valFinal = (porc) + parseFloat(base);
            const temPorc = !isNaN(porc) ? porc : '';
            actualizarRegiones(idx, 'campos', temPorc, 'modificador_valor');
            const tempFinal = !isNaN(valFinal) ? valFinal : '';
            actualizarRegiones(idx, 'campos', tempFinal, 'final');
          } else {
            actualizarRegiones(idx, 'campos', '', 'modificador_valor');
            actualizarRegiones(idx, 'campos', base, 'final');
          }
          break;
        case 'modificador_valor':
          if (!esValorVacio && !esBaseVacio) {
            const porc = (parseFloat(valor) * 100) / parseFloat(base);
            const valFinal = parseFloat(valor) + parseFloat(base);
            actualizarRegiones(idx, 'campos', porc, 'modificador_porcentaje');
            const tempFinal = !isNaN(valFinal) ? valFinal : '';
            actualizarRegiones(idx, 'campos', tempFinal, 'final');
          } else if (esValorVacio) {
            actualizarRegiones(idx, 'campos', '', 'modificador_porcentaje');
            actualizarRegiones(idx, 'campos', base, 'final');
          } else {
            actualizarRegiones(idx, 'campos', base, 'final');
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Evento que se ejecuta cuando se presiona una tecla en el input
   * Solo permite numero, punto y el signo menos
   * @param {*} e Evento del input
   */

  const isNumberKey = (e) => {
    // Obten la tecla presionada
    var key = e.key;

    // Permite números del 0 al 9, el punto y el signo menos
    if (
      /[0-9.-]/.test(key) ||         // Números, punto y signo menos
      (e.key === "Minus" && e.location === 0) ||
      (e.key === "Backspace") ||
      (e.key === "Delete") ||
      (e.key === "ArrowLeft") ||
      (e.key === "ArrowRight")
    ) {
      // Permitir la entrada
    } else {
      // Prevenir la entrada
      e.preventDefault();
    }
  }

  /**
   * Actualiza el valor de la region en el estado
   * @param {*} idx Indice de la region
   * @param {*} campo Campo a actualizar
   * @param {*} valor Valor a actualizar
   * @param {*} campoParaActualizar Campo del objeto a actualizar
   */

  function actualizarRegiones(idx, campo, valor, campoParaActualizar) {
    const newRegiones = [...regiones];
    if (newRegiones[idx]?.[campo]?.[campoParaActualizar]) {
      newRegiones[idx][campo][campoParaActualizar].valor = valor;
    }
    setRegiones(newRegiones);
  }

  /**
   * Evento que se ejecuta cuando se cambia el valor del año del servicio
   * @param {*} e  Evento del input
   */
  const onHandleChangeAnio = (e) => { setAnoServicio(e.target.value); }

  return (
    <>
      <div>
        <Row style={{ alignItems: 'center', marginBottom: "1rem" }}>
          <Form.Label>{item.titulo}</Form.Label>
          <Col>
            <Select onChange={(e) => getValoresServicio(e)} options={item.valores_opcion} value={servicio} />
            {error?.nombre && error.nombre?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </Col>
          <Col >
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              <Form.Group style={{ display: 'block' }}>
                <Form.Control
                  ref={reg}
                  name={`asesoria.${index}.${item.nombre}.id`}
                  value={servicio.value}
                  type={'hidden'} />
                <Form.Control
                  ref={reg}
                  {...reg(`asesoria.${index}.${item.nombre}.nombre`, {
                    required: item.es_requerido
                  })}
                  name={`asesoria.${index}.${item.nombre}.nombre`}
                  value={servicio.label}
                  type={'hidden'} />
                <div>
                  <Form.Control
                    ref={reg}
                    {...reg(`asesoria.${index}.${item.nombre}.year`, {
                      required: item.es_requerido
                    })}
                    name={`asesoria.${index}.${item.nombre}.year`}
                    onChange={onHandleChangeAnio}
                    value={anoServicio}
                    placeholder="Año" />
                  {error?.year && error.year?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                </div>
              </Form.Group>

            </div>
          </Col>
        </Row>

        <Accordion style={{ marginBottom: "2rem" }}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header">
            <p style={{ fontSize: '1.2rem', fontWeight: '700', color: '#213265' }}>Valores</p>
          </AccordionSummary>
          <div style={{ padding: '1rem' }}>
            {item.valores_opcion.length > 0 &&
              item.valores_opcion[0].regiones.map((region, idx) => (
                <>
                  <div className="headerRegistro">
                    <p style={{ fontSize: '1rem', padding: '0 0.5rem' }}>{region.nombre}</p>
                  </div>
                  <AccordionDetails>
                    <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                      <Col>
                        <Form.Group style={{ display: 'block', marginRight: '0.5em' }}>
                          <Form.Label style={{ fontSize: '0.8rem' }}>Base</Form.Label>
                          <CurrencyInput
                            defaultValue={regiones[idx]?.campos?.base?.valor}
                            className="form-control"
                            decimalsLimit={6}
                            placeholder="Base"
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.base`}
                            intlConfig={{
                              locale: "es-CO",
                              currency: 'COP'
                            }}
                            onBlur={(e) => onHandleChangeRegion(e, index, idx)}
                            value={regiones[idx]?.campos?.base?.valor}
                            onValueChange={(value) => { actualizarRegiones(idx, 'campos', value, 'base') }}
                            {...reg(`asesoria.${index}.${item.nombre}.regiones.${idx}.base`, {
                              required: item.es_requerido
                            })}
                          />

                          <input
                            ref={reg}
                            type="hidden"
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.base`}
                            value={regiones[idx]?.campos?.base?.valor} />

                          {error && error?.hasOwnProperty('regiones') && error?.regiones.length > 0
                            && error?.regiones[idx]?.base && error.regiones[idx].base?.type === "required" && (
                              <p style={{ color: "red" }}>Este campo es requerido</p>
                            )}

                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group style={{ display: 'block', marginRight: '0.5em' }}>
                          <Form.Label style={{ fontSize: '0.8rem' }}>Modificador Porcentaje</Form.Label>
                          <Form.Control
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.modificador_porcentaje`}
                            onBlur={(e) => onHandleChangeRegion(e, index, idx)}
                            type="number"
                            placeholder="Modificador Porcentaje"
                            defaultValue={regiones[idx]?.campos?.modificador_porcentaje?.valor}
                            onKeyDown={isNumberKey}
                            step="any"
                            value={regiones[idx]?.campos?.modificador_porcentaje?.valor}
                            onChange={(e) => { actualizarRegiones(idx, 'campos', e.target.value, 'modificador_porcentaje') }}
                            {...reg(`asesoria.${index}.${item.nombre}.regiones.${idx}.modificador_porcentaje`, {
                              required: item.es_requerido
                            })}
                          />
                          <input
                            ref={reg}
                            type="hidden"
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.modificador_porcentaje`}
                            value={regiones[idx]?.campos?.modificador_porcentaje?.valor} />

                          {error && error?.hasOwnProperty('regiones') && error?.regiones.length > 0
                            && error?.regiones[idx]?.modificador_porcentaje && error.regiones[idx].modificador_porcentaje?.type === "required" && (
                              <p style={{ color: "red" }}>Este campo es requerido</p>
                          )}

                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group style={{ display: 'block', marginRight: '0.5em' }}>
                          <Form.Label style={{ fontSize: '0.8rem' }}>Modificador Valor</Form.Label>
                          <CurrencyInput
                            defaultValue={regiones[idx]?.campos?.modificador_valor?.valor}
                            className="form-control"
                            decimalsLimit={6}
                            placeholder="Modificador Valor"
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.modificador_valor`}
                            intlConfig={{
                              locale: "es-CO",
                              currency: 'COP'
                            }}
                            onBlur={(e) => onHandleChangeRegion(e, index, idx)}
                            value={regiones[idx]?.campos?.modificador_valor?.valor}
                            onValueChange={(value) => { actualizarRegiones(idx, 'campos', value, 'modificador_valor') }}
                            {...reg(`asesoria.${index}.${item.nombre}.regiones.${idx}.modificador_valor`, {
                              required: item.es_requerido
                            })}
                          />

                          <input
                            ref={reg}
                            type="hidden"
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.modificador_valor`}
                            value={regiones[idx]?.campos?.modificador_valor?.valor} />

                          {error && error?.hasOwnProperty('regiones') && error?.regiones.length > 0
                            && error?.regiones[idx]?.modificador_valor && error.regiones[idx].modificador_valor?.type === "required" && (
                              <p style={{ color: "red" }}>Este campo es requerido</p>
                          )}

                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group style={{ display: 'block' }}>
                          <Form.Label style={{ fontSize: '0.8rem' }}>Final</Form.Label>
                          <CurrencyInput
                            defaultValue={regiones[idx]?.campos?.final?.valor}
                            className="form-control"
                            decimalsLimit={6}
                            placeholder="Final"
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.final`}
                            intlConfig={{
                              locale: "es-CO",
                              currency: 'COP'
                            }}
                            readOnly
                            value={regiones[idx]?.campos?.final?.valor}
                            onValueChange={(value) => { actualizarRegiones(idx, 'campos', value, 'final') }}
                          />
                          <input
                            ref={reg}
                            type="hidden"
                            name={`asesoria.${index}.${item.nombre}.regiones.${idx}.final`}
                            value={regiones[idx]?.campos?.final?.valor} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </AccordionDetails>
                </>
              )
              )}
          </div>
        </Accordion>
      </div>
    </>
  );
}

export default CalculoBase;
