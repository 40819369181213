import React, {useEffect, useState} from 'react'

import LinearProgress from '@material-ui/core/LinearProgress';
import {createTheme, ThemeProvider } from "@material-ui/core/styles"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import './BarrasLabelsHorizontales.scss'



// const useStylesFacebook = makeStyles((theme) => ({
//   root: {
//     position: 'relative',
//   },
//   bottom: {
//     color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
//   },
//   top: {
//     color: '#ff5200',
//     animationDuration: '550ms',
//     position: 'absolute',
//     left: 0,
//   },
//   circle: {
//     strokeLinecap: 'round',
//   },
// }));

 const BarrasLabelsHorizontalesMultibarra = ({data, color, titulo, seccionDofaSelected}) => {

  // const BorderLinearProgress = withStyles((theme) => ({
  //   root: {
  //     height: 10,
  //     borderRadius: 5,
  //   },
  //   colorPrimary: {
  //     backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  //   },
  //   bar: {
  //     borderRadius: 5,
  //     backgroundColor: seccionDofaSelected === 'Fortalezas'
  //       ? '#38C945'
  //       : (seccionDofaSelected === ('oportunidades') || seccionDofaSelected === ('Oportunidades'))
  //         ? '#4180C8'
  //         : seccionDofaSelected === 'Amenazas'
  //           ? '#ff5200'
  //           : seccionDofaSelected === 'Debilidades'
  //             ? '#FFBE0A'
  //             : '#ff5200' ,
  //   },
  // }))(LinearProgress);

  // console.log(seccionDofaSelected)

  // const classes = useStylesFacebook();



  // const colores = [
  //   "#2c5e99",
  //   "#05a4db",
  //   "#009688",
  //   "#8BC34A",
  //   "#F9CF00",
  //   "#F39C11",
  //   "#F46036",
  //   "#D7263D",
  //   "#674ea7"
  // ]

  // const theme = createTheme({

  //   colorPrimary: {
  //     backgroundColor: '#ff5200',
  //   },

  //   bar: {
  //     backgroundColor: '#ff5200',
  //   },

  //   root: {

  //     colorPrimary: {
  //       backgroundColor: '#ff5200',
  //     },

  //     bar: {
  //       backgroundColor: '#ff5200',
  //     },

  //   },
  //   overrides: {

  //     bar: {
  //       backgroundColor: 'red',
  //     },

  //   },
  // })

  const calcularWidth = (valor) => {

    let total = 75;
    let max_value = 70;
    let value = valor;
    let x = ((value * total)/max_value) - 12;
    let size;

    if(x <= 0){
      size = `1.2%`;
    }else{
      if(x >= 80){
        size = `90%`;        
      }else{
        size = `${x}%`;
      }
    }

    return size
  }

  const renderGrafica = (data) => {


    return(
      <div  >
        {data?.map( (item, index) => {

          return(
            <div  key={index}>
            {item.caracteristica &&
              <p style={{ color: 'gray', margin: '5px 0px 5px', fontSize:'1rem', fontWeight: '600'}} >{item.caracteristica}</p>
            }
            <div className='shadow itemContainerProgress' >
              { item?.data?.map((value) => {
                return(
                  <div style={{width: '100%' }}>
                  <div className='progressContainer'>
                    <div
                      className='progressBar'
                      style={{
                        width: calcularWidth(value.valor),
                        backgroundColor: value.color
                      }}
                    >
                    </div>
                      <p style={{fontSize: '0.7rem', width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0 5px'}}>{value.label && `${value.label}`}</p>
                  </div>
                </div>
                )
              })
              }
            </div>
            </div>
          )

        })
        }
      </div>
    )

  }



  return (
    <div className='mainGraphContainer' >
      {titulo && <h2>{titulo}</h2>}

      <div className='contArray' style={{width: '100%', height: 'auto'}} >
        {(data && data.length) ? renderGrafica(data) : <p style={{width: '100%', textAlign: 'center', margin: '50px auto', color: 'gray'}} >No hay información relacionada.</p>}
      </div>
    </div>
  )
}

export {BarrasLabelsHorizontalesMultibarra}
