import React, { Fragment, useState } from 'react'
import { Card } from 'react-bootstrap'
import ModalAgregarNota from './ModalAgregarNota'

import './Notas.scss'

export default function Notas(props) {

  const [modalAddNota, setModalAddNota] = useState(false)

  const handleAddNotas = () => {
    setModalAddNota(true)
    // ////console.log('holi');
    // ////console.log(modalAddNota);
  }

  return (
    <>       
      <Card className="shadow cardGeneral" style={{ borderRadius: '10px' }}>
        <Card.Body>
          <h3 className='titleCard'>Notas</h3>
          <br/>
          {props.dataSolicitud.notasOpcionAgregar === true
            ?
            <button className='botonAgregarNota' onClick={handleAddNotas}>Añadir nota</button>
            : null
          }

        </Card.Body>
      </Card> 
      <br/>
      <Card className="shadow">
        <Card.Body>
          <h4 className='titleCard'>Lista de notas</h4>
          <br/>
          <div className='tableNotasContainer'>
            <table>
              <thead>
                <tr>
                  <td>Fecha y hora</td>
                  <td>Usuario</td>
                  <td>Perfíl</td>
                  <td>Asunto</td>
                  <td>Texto</td>
                </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      <ModalAgregarNota  isOpen={modalAddNota} setIsOpen={setModalAddNota}/>
    </>
  )
}
