import React from 'react'
import {Form, Col} from 'react-bootstrap'
import Select from 'react-select';

export default function  OpcionesBuscador(props) {

  return  (
    <Form.Group as={Col} lg={6} className='inputGroup' style={{paddingLeft: '8px'}}>
      <Form.Label>{props.label}</Form.Label>
      <Select
        options={props.data}
        isSearchable={true}
        name={props.name}
        onChange={(e) => {
          props.handleChange(e); 
          props.setValue(e);
        }}
        placeholder={props.placeholder}
        isClearable={true}
        style={props.style}
        instanceId={props.id}
        defaultValue={props.value}
      />
    </Form.Group>
  )
}
