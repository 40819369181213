import React from 'react';


import './ProgressBarComplete.scss'

export default function ProgressBarComplete(props) {

  const progress = props.dataTiempos.progreso

  return (
    <div>
      <div className='containerProgressComplete'>
        <div className='labelsProgresoCompleteContainer'>
          <div className='labelsWidthDataComplete'>
            <p>Días hábiles:</p>
            <p>{props.dataTiempos.diasHabiles}</p>
          </div>
          <div className='labelsWidthDataComplete'>
            <p>Fecha inicial:</p>
            <p>{props.dataTiempos.fechaInicial}</p>
          </div>
          <div className='labelsWidthDataComplete'>
            <p className='labelProgreso'>Fecha final: </p>
            <p className='dataProgresoComplete'>{props.dataTiempos.fechaFinal}</p>
          </div>
          <div className='labelsWidthDataComplete'>
            <p>Fecha entrega:</p>
            <p>{props.dataTiempos.fechaEntrega}</p>
          </div>
          <div className='labelsWidthDataComplete'>
            <p>Dia actual:</p>
            <p>{props.dataTiempos.diaActual}</p>
          </div>
          <div className='labelsWidthDataComplete'>
            <p>Dias restantes: </p>
            <p>{props.dataTiempos.diasRestantes}</p>
          </div>
        </div>
        <div className='shadow progressbarComplete-container'>
          <div className='progressbarComplete-complete' style={{width: `${progress}%`}}>
            <div className='progressbar-liquidComplete'></div>
          </div>
          <span className='progressComplete'>
            <p>
              Día {props.dataTiempos.diaActual} de {props.dataTiempos.diasTotales}

            </p>
          </span>
        </div>
      </div>
    </div>
  )
}
