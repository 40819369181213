/**
 * Función que permite acceder al recurso en tres casos:
 * 1. Un base 64.
 * 2. Un recurso que proviene del servidor de cosinte
 * 3. Un recurso externo
 * @param {*} uri
 */

const openDataUri = uri => {
  if (uri.startsWith('data:')) {
    const type = uri.split(';')[0].split('/')[1].toLowerCase();

    let sourceWindow = window.open('');

    if (type !== 'pdf') {
      sourceWindow.document.write(
        "<iframe width='100%' height='100%' src=" +
          encodeURI(uri) +
          " height='768' width='1024'></iframe>",
      );
    } else {
      sourceWindow.document.write(
        "<iframe width='100%' height='100%' src=" +
          encodeURI(uri) +
          '></iframe>',
      );
    }
  } else {
    const typeUrl = uri.split('.');
    const extension = typeUrl[typeUrl.length - 1].toLowerCase();

    if (typeUrl[1] === 'ciacosinte') {
      let sourceWindow = window.open('');

      if (extension !== 'pdf') {
        if (extension.startsWith('html')) {
          sourceWindow.document.write(
            "<iframe width='100%' height='100%' src=" +
              encodeURI(uri) +
              " height='768' width='1024' style='background:url(https://www.ciacosinte.site/cia2/static/media/loader.gif) center center no-repeat; background-size:80px'></iframe>",
          );
        } else {
          sourceWindow.document.write(
            "<iframe width='100%' height='100%' src=" +
              encodeURI(uri) +
              " height='768' width='1024'></iframe>",
          );
        }
      } else {
        sourceWindow.document.write(
          "<iframe width='100%' height='100%' src=" +
            encodeURI(uri) +
            '></iframe>',
        );
      }
    } else if (uri.startsWith('/crm')) {
      let sourceWindow = window.open('');
      let domain = 'https://www.ciacosinte.site';
      const completeUri = domain + uri;
      if (extension !== 'pdf') {
        sourceWindow.document.write(
          "<iframe width='100%' height='100%' src=" +
            encodeURI(completeUri) +
            " height='768' width='1024'></iframe>",
        );
      } else {
        sourceWindow.document.write(
          "<iframe width='100%' height='100%' src=" +
            encodeURI(completeUri) +
            '></iframe>',
        );
      }
    } else {
      window.open(uri);
    }
  }
};

export default openDataUri;
