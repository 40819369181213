import { Card } from 'react-bootstrap';
import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { envioDataCrearSolicitud } from '../../../reducers/crearSolicitudReducer';
import { CalendarSVG } from '../../icons/CalendarSVG';
import NotificationModal from '../../modales/NotificationModal';
import { validarEntorno } from '../../../helpers/validarHost';
import './styles/EstudioRiesgos.scss';
import BuscadoresEstudioRiesgos from '../../../helpers/BuscadoresEstudioRiesgos';
import { municipios } from '../../../helpers/municipios';


export const EstudioRiesgos = ({  ele, index, selects, pestaña }) => {

    // console.log(ele);

    const dispatch = useDispatch()
    const crearSolicitud = useSelector(state => state.crearSolicitud)
    const solicitud = useSelector(state => state.solicitudEstudio)
    const [modalConfirmacionSolicitud, setModalConfirmacionSolicitud] = useState(false);

    const [tipoModal, setTipoModal] = useState({
        tipo: '',
        identificador: '',
        mensaje: ''
    });


    // const {idSolicitud} = solicitud?.data?.idSolicitud

    const codigoFuncion = crearSolicitud.codigo ? crearSolicitud.codigo : undefined
    const [validated, setValidated] = useState(false);
    const { data } = useSelector(state => state.solicitudEstudio)
    const [dataSelects, setDataSelects] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [tipoRiesgoSelected, setTipoRiesgoSelected] = useState(false)
    const [primerNombreVal, setPrimerNombreVal] = useState('')
    const [segundoNombreVal, setSegundoNombreVal] = useState('')
    const [primerApellidoVal, setPrimerApellidoVal] = useState('')
    const [SegundoApellidoVal, setSegundoApellidoVal] = useState('')
    const [sucursales, setSucursales] = useState([]);

    // const hash = window.location.hash

    useEffect(() => {
        dataSelectsFuncion(data.data)
        getSucursales();
    }, [])

    const getSucursales = async () => {
        const req= await validarEntorno('JSON/selects_sucursarles_estudio_riesgos.html', 'POST');

        if (req.ok) {
            const res = await req.json();
            if (res.sucursales.length > 0){
                setSucursales(res.sucursales);
            }
        }
    }

    const dataSelectsFuncion = (data) => {
        data.forEach(element => {
            if (element.pestana === pestaña) {
                setDataSelects(element.contenido[0].data)
            }
        });

    }

    const [campoReg, setCampoReg] = useState('')

    // const [tipoModal, setTipoModal] = useState('')
    // const [modal, setModal] = useState(false)
    // const [mensajeModal, setMensajeModal] = useState('')
    // const [codigoSucces, setCodigoSucces] = useState('')

    const { label } = selects


    const [info, setInfo] = useState({
        idSolicitud: solicitud.data.idSolicitud,
        ciudad: '',
        riesgo_detalle: '',
        primer_nombre: '',
        primer_apellido: '',
        tipo_identificacion: '',
        identificacion: '',
        tiene_solicitud: '',
        numero_solicitud: '',
        sucursales: '',
        observaciones: '',
        select: label
    });

    const [startDate, setStartDate] = useState(Date.now())

    const handleSelectCiudad = (e) => {
        if (e && Object.keys(e).length > 1) {
            setInfo({
                ...info,
                ciudad: e.value
            });
        } else {
            setInfo({
                ...info,
                ciudad: ''
            });
        }
    }

    const handleSolicitudHistoria = (e) => {
        if (e && Object.keys(e).length > 1) {
            setInfo({
                ...info,
                tiene_solicitud: e.value
            });
        } else {
            setInfo({
                ...info,
                tiene_solicitud: ''
            });
        }
    }

    const handleSucursales = (e) => {
        if (e && Object.keys(e).length > 1) {
            setInfo({
                ...info,
                sucursales: e.value
            });
        } else {
            setInfo({
                ...info,
                sucursales: ''
            });
        }
    }


    const ExampleCustomInput = forwardRef(
        ({ value, onClick }, ref) => (
            <div className="dateBox" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                <div style={{ display: 'flex', paddingLeft: '7px', paddingRight: '7px' }}>
                    <label onClick={onClick} ref={ref} className='mr-3'>
                        {value}
                    </label>
                    <i onClick={onClick} ref={ref} >
                        <CalendarSVG />
                    </i>
                </div>
            </div>

        ),
    );


    const handleChange = e => {

        const reg = new RegExp(/[^0-9_]/g)

        // const regName = /^[([a-zA-ZáéíóúüñÑ])]$/u


        if (e?.target?.name === 'primer_nombre' || e?.target?.name === 'segundo_nombre' || e?.target?.name === 'primer_apellido' || e?.target?.name === 'segundo_apellido'  ) {

            // console.log(e.target.name)

           // const regular =  e?.target?.value?.replace(reg,'')
            const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑ ]/g)

            const validado = reg1.test(e.target.value)


            if (!validado) {

                if(e.target.name === 'primer_nombre'){
                    setPrimerNombreVal(e.target.value)
                }else if(e?.target?.name === 'segundo_nombre'){
                    setSegundoNombreVal(e.target.value)
                }else if(e?.target?.name === 'primer_apellido'){
                    setPrimerApellidoVal(e.target.value)
                }else if(e?.target?.name === 'segundo_apellido'){
                    setSegundoApellidoVal(e.target.value)
                }

                setInfo({
                    ...info,
                    [e.target.name]: e.target.value,
                })

            }


        } else

        if (e?.target?.name === 'riesgo_detalle' && e?.target?.value !==  ( '' || "1" || 1) ) {
            setTipoRiesgoSelected(true)
            setInfo({
                ...info,
                riesgo_detalle: e.target.value,
            })

        } else if (e?.target?.name === 'numero_solicitud') {
            const validate = reg.test(e.target.value)
            if (!validate) {
                setCampoReg(e.target.value)
                setInfo({
                    ...info,
                    numero_solicitud: e.target.value,
                })

            }

        } else {

            if (e?.target?.name) {
                setInfo({
                    ...info,
                    [e.target.name]: e.target.value,
                })
            } else {
                setInfo({
                    ...info,
                    fecha_dictamen: e,
                })
            }

        }
        // console.log(info)
    };


    const handleSubmit = async (e) => {
        e.preventDefault()

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
        }

        setValidated(true)

        if (form.checkValidity()) {

            setIsDisabled(true)

            let dataForm = info

            const dataReq = {
                hash: window.location.hash,
                dataForm
            }

            console.log(dataReq)

            const res = await validarEntorno('JSON/est_riesg.html', 'POST', dataReq)

            if (res.ok) {
                const body = await res.json();
                if (body.estado === 'ok') {
                    setTipoModal({
                        ...tipoModal,
                        tipo: 'registroSolVis',
                        identificador : body.idSolicitud
                    })
                    setModalConfirmacionSolicitud(true)
                } else {
                    setTipoModal({
                        ...tipoModal,
                        tipo: 'registroAuditoriaError',
                        mensaje: body.mensaje
                      })
                      setModalConfirmacionSolicitud(true)
                    }
                    setIsDisabled(false)
                }
            } else {
                setTipoModal({
                    ...tipoModal,
                    tipo: 'errorActualizacionDofa'
                })
                setIsDisabled(false)
            }

            //setModal(true)
    }


    return (
        <Fragment>
            <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ margin: '0px', paddingRight: '12px' }}>
                {(ele.contenido[0].visualizar)
                    &&
                    <Card className='shadow cardContainerSolicitud'>
                        <h5 className='titleInsideCard'>Información de solicitud</h5>
                        <Card.Body className='bodyEstudioRiesgosContainer'>
                                <Form.Group className='formGroupEstudioRiesgo'>
                                    <Form.Label>
                                        Tipo de identificación
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <Form.Control onChange={handleChange} defaultValue="2" name='tipo_identificacion' as='select' required>
                                            <option value="2" > Cédula de Ciudadanía </option>
                                            <option value="3"> Cédula de Extranjería </option>
                                            <option value="4"> Tarjeta de Identidad </option>
                                            <option value="9"> Registro Civil </option>
                                            <option value="5"> Pasaporte </option>
                                            <option value="1"> NIT </option>
                                        </Form.Control>
                                        {/* <Form.Control
                                            onChange={handleChange}
                                            name='identificacion'
                                            type='number'
                                            placeholder='Identificación'
                                            min='0'
                                            max='9999999999'
                                            required
                                        /> */}
                                        <Form.Control.Feedback type='invalid'>Ingresa un número de identificación</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <Form.Group className='formGroupEstudioRiesgo'>
                                    <Form.Label>
                                        Identificación
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <Form.Control
                                            onChange={handleChange}
                                            name='identificacion'
                                            type='number'
                                            placeholder='Identificación'
                                            min='0'
                                            max='9999999999'
                                            required
                                        />
                                        <Form.Control.Feedback type='invalid'>Ingresa un número de identificación</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <Form.Group className='formGroupEstudioRiesgo' >
                                    <Form.Label>
                                        Nombres
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <Form.Control value={primerNombreVal}  onChange={handleChange} name='primer_nombre' required type='text' placeholder='Primer nombre'></Form.Control>
                                        <Form.Control value={segundoNombreVal} onChange={handleChange} name='segundo_nombre' type='text' placeholder='Segundo nombre'></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa los nombres</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <Form.Group className='formGroupEstudioRiesgo' >
                                    <Form.Label className="label-estudio-riesgos">
                                        Apellidos
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <Form.Control value={primerApellidoVal} onChange={handleChange} name='primer_apellido' required type='text' placeholder='Primer apellido'></Form.Control>
                                        <Form.Control value={SegundoApellidoVal} onChange={handleChange} name='segundo_apellido' type='text' placeholder='Segundo apellido'></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa los apellidos</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <Form.Group className='formGroupEstudioRiesgo' >
                                    <Form.Label className="label-estudio-riesgos">
                                        Ciudad
                                    </Form.Label>
                                    <BuscadoresEstudioRiesgos
                                        handleChange={handleSelectCiudad}
                                        data={municipios}
                                        placeholder="Ciudad"
                                        style={{width:'100%'}}
                                    />

                                </Form.Group>
                                <Form.Group className='formGroupEstudioRiesgo' >
                                    <Form.Label>
                                        Solicitud de historia clínica
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <BuscadoresEstudioRiesgos handleChange={handleSolicitudHistoria} data={[{value: 'si', label: 'Sí'}, {value: 'no', label: 'No'}]} placeholder="--Seleccione--" />
                                    </div>
                                </Form.Group>
                                <Form.Group className='formGroupEstudioRiesgo' >
                                    <Form.Label>
                                        Sucursal
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <BuscadoresEstudioRiesgos handleChange={handleSucursales} data={sucursales} placeholder="Sucursal" />
                                    </div>
                                </Form.Group>
                                <Form.Group className='formGroupEstudioRiesgo' >
                                    <Form.Label className="label-estudio-riesgos">
                                        Numero de solicitud
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <Form.Control onChange={handleChange} name='numero_solicitud' required type='text' placeholder='Numero solicitud' value={campoReg} maxLength={12}></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa el número de solicitud </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <div id='observacionesEstudioRiesgo'>
                                    <Form.Group id='observacionesFormGroup'>
                                        <Form.Label className='observacionesLabel'>
                                            Observaciones
                                        </Form.Label>
                                        <div className='textAreaContainer'>
                                            <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className='buttonContainer' id='btnConfirmarEstudioRiesgo'>
                                    <button className='confirmButton' disabled={isDisabled}>Guardar</button>
                                    {/* <ModalConfirmacion typeModal='error' isOpen={showModal} setIsOpen={setShowModal} />                */}
                                </div>
                        </Card.Body>
                    </Card>
                }

            </Form>

            <NotificationModal infoNotificacion={tipoModal.mensaje} identificador={tipoModal.identificador} typeModal={tipoModal.tipo} isOpen={modalConfirmacionSolicitud} setIsOpen={setModalConfirmacionSolicitud} />
            {/* <GenericModal typeModal="crearSolicitud" setIsOpen={setModal} isOpen={modal} /> */}

        </Fragment>

    )
}
