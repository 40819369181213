import React, { Fragment, useEffect
  // , useState 
} from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { Container } from '@material-ui/core';
// import { Card } from 'react-bootstrap'
import Filtro from '../../components/FiltroTypeSolicitud';
import { recargarDataIncial, selectInicial } from '../../reducers/solicitudesEstudio';


import '../styles/Inteligencia.scss'

// const HOST = 'localhost:3000'
const PATH = '/gerencia/inteligencia'

export default function Inteligencia() {

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(recargarDataIncial())
    dispatch(selectInicial(window.location.hash))

  }, [dispatch])

  // const [open, setOpen] = useState(false)
  const { selects } = useSelector(state => state.solicitudEstudio)


  return (
    <main>
      {
        (selects.selects && selects.selects.length > 0)
        &&
        <Fragment>


          { (selects.selects && selects.selects.length > 0)
            &&
            <Filtro path={PATH} selects={selects} />
          }
        </Fragment>
      }

    </main>
  )
}
