import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
// import {AMCHART} from '../../helpers/amchartLicense'

import { HelpSVG } from '../icons/HelpSVG'
import { DesplegarMenuSVG } from '../icons/DesplegarMenuSVG'


import MoreVertIcon from '@material-ui/icons/MoreVert';


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Chat } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';


export default function SortedBarChart(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  am4core.useTheme(am4themes_animated);
  // am4core.addLicense(AMCHART);

  useEffect(() => {
    let chart = am4core.create( props.id ? props.id : "divbarras", am4charts.XYChart);
    chart.padding(40, 40, 40, 40);

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "network";
    categoryAxis.renderer.tooltipY = "[bold]{network}"
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = false;
    categoryAxis.renderer.labels.template.text = "[bold]{network}"

    categoryAxis.renderer.labels.template.adapter.add("text", function(text) {
      return "[bold]" + text ;
    });

    // categoryAxis.renderer.template.text = "[bold]{network}"
    // categoryAxis.text = "[bolder]{network}"
    // categoryAxis.textDecoration.fontWeight = "bolder"

    // categoryAxis.renderer.labels.template = "[bold]{network}"



    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    // valueAxis.labels.fontWeight = "bold";
    valueAxis.extraMax = 0.1;

    valueAxis.min = 0;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "network";
    series.dataFields.valueX = "MAU";
    // series.columns.template.text = "[bold]{network}"
    series.columns.template.tooltipText = props.labels ? "{label}" : "{valueX.value}"
    series.columns.template.labels = "[bold]{category}";

    series.columns.template.text = "[bold]{network}"
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 15;
    series.columns.template.column.cornerRadiusTopRight = 15;

    chart.colors.list = [
      am4core.color("#ff5200"),
      am4core.color("#F9CF00"),
      am4core.color("#8BC34A"),
      am4core.color("#009688"),
      am4core.color("#05A4DB"),
      am4core.color("#2C5E99"),
    ];

    let labelBullet = series.bullets.push(new am4charts.LabelBullet())
    labelBullet.label.horizontalCenter = "left";
    labelBullet.label.truncate = false;
    labelBullet.label.hideOversized = false;
    labelBullet.label.dx = 10;
    labelBullet.label.text = props.labels ? "{label}" : "{values.valueX.workingValue.formatNumber('#')}";
    labelBullet.label.fill = '#000';
    labelBullet.label.fontWeight = '700';
    labelBullet.label.fontSize = '16px';
    labelBullet.locationX = 0;

    let fillModifier = new am4core.LinearGradientModifier();
    fillModifier.brightnesses = [-0.5, 1, -0.3];
    fillModifier.offsets = [0, 0.5, 1];
    series.chart.fillModifier = fillModifier;

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    categoryAxis.sortBySeries = categoryAxis;
    chart.data = props.data ? props.data : [
      {
        "network": "Facebook",
        "MAU": 2255250000
      },
      {
        "network": "Google+",
        "MAU": 430000000
      },
      {
        "network": "Instagram",
        "MAU": 1000000000
      },
      {
        "network": "Pinterest",
        "MAU": 246500000
      },
      {
        "network": "Reddit",
        "MAU": 355000000
      }

    ]

    chart.exporting.menu = new am4core.ExportMenu();
    // chart.exposting.menu.useWebFonts = false;
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.horizontalAlign = "left";
    chart.exporting.menu.minWidth = 'auto';
    chart.exporting.formatOptions.getKey("svg").disabled = true;
    chart.exporting.formatOptions.getKey("xlsx").disabled = true;
    chart.exporting.formatOptions.getKey("html").disabled = true;
    chart.exporting.formatOptions.getKey("json").disabled = true;
    // chart.exporting.formatOptions.getKey("pdf").disabled = true;


    const iconoMenu = () => {
      return (
        <MoreVertIcon style={{ fontSize: 'small', color: 'red'}}/>
      )
    }

    chart.exporting.menu.items = [{
      "label" :'...',
      "menu": [
        { "type": "png", "label": t('botones.imagen') },
        // { "type": "csv", "label": "Tabla" },
        { "label": t('botones.imprimir'), "type": "print" }
      ]
    }];

    // chart.exporting.menu.items[0].push({
    //   label: "Hello",
    //   type: "custom",
    //   options: {
    //     callback: function() {
    //       console.log("Hello back at ya!");
    //     }
    //   }
    // });


  }, [])

  return (
    <Card className="shadow cardGraficaGeneral cardGraficaAnalistas" style={{width:'100%'}}>
     {/*  <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br />
      <Card.Body className='bodyGraficasGeneral'>
        <h4 className='pagesTitles' style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}> {props.title}
        <br/>
        {props.parametrizado && props.periodo
          ?
            <>
              {props.parametrizado} - {props.periodo }
            </>

          : props.parametrizado || props.periodo
        }
        </h4>
        <br/>
        <br/>
        <div id={props.id ? props.id : "divbarras"} style={{ width: '95%', minHeight: props.height || '500px', margin: '0 auto', height:'auto' }}></div>
        <br/>
        {props.total &&
          <h4 className='pagesTitles' >
            Total: {props.total}
          </h4>
        }
      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
