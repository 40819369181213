import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import './styles/MatrizRiesgos.scss'
import { useTranslation } from 'react-i18next';

export default function MatrizRiesgos() {

  const [inputList, setInputList] = useState([{ riesgo: "", probabilidad: "", impacto: "" }]);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [showModalDeleteNotification, setShowModalDeleteNotification] = useState(false);
  const [showModalConfirmNotification, setShowModalConfirmNotification] = useState(false);
  const [showModalEditNotification, setShowModalEditNotification] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableForm, setDataTableForm] = useState({});
  const [indexDelete, setIndexDelete] = useState('');
  const [indexForm, setIndexForm] = useState('');
  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false
  })
  const { t } = useTranslation();

  const handleOpenModalNotification = () => setShowModalNotification(true);
  const handleCloseModalNotification = () => setShowModalNotification(false);

  const handleOpenModalDeleteNotification = (index) => {
    setShowModalDeleteNotification(true)
    setIndexDelete(index);
  };
  const handleCloseModalDeleteNotification = () => setShowModalDeleteNotification(false);

  const handleOpenModalConfirmNotification = () => setShowModalConfirmNotification(true);
  const handleCloseModalConfirmNotification = () => setShowModalConfirmNotification(false);

  const handleOpenModalEditNotification = () => setShowModalEditNotification(true);
  const handleCloseModalEditNotification = () => setShowModalEditNotification(false);


  const { id } = useParams();

  const getRiesgos = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: id
    }

    const req = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_riesgos.html', 'POST', dataReq)

    if (req.ok) {
      const res = await req.json()
      setInitialState({ ...initialState, data: res })
      setDataTable(res?.riesgos)
    }

  }

  useEffect(() => {
    getRiesgos()
  }, [])


  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (value !== '') {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    }


    console.log(inputList)
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { riesgo: "", probabilidad: "", impacto: "" }]);
  };

  const sendDataForm = async (e) => {

    e.preventDefault();

    const result = {};

    const dataSend = {
      tipo: 'riesgo',
      accion: 'GUARDAR',
      formData: inputList,
      id_registro: id,
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    console.log(dataSend)


    const response = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_riesgos_res.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();

      if (respuesta.message) {
        setDataTable([...dataTable, ...inputList]);
        setInputList([{ riesgo: "", probabilidad: "", impacto: "" }]);
        setShowModalNotification(true);
        getRiesgos();
      }
    }
  }

  const editItem = async (index, item) => {

    // console.log(dataTableForm)

    const dataSend = {
      tipo: 'riesgo',
      accion: 'UPDATE',
      formData: dataTableForm,
      id: id,
      id_registro: dataTableForm.id,
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    console.log(dataSend);

    const response = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_riesgos_res.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();

      if (respuesta.message) {
        let dt = [...dataTable];

        let item = { ...dt[index] };

        item = dataTableForm;

        dt[index] = item;

        setDataTable(dt);

        setDataTableForm({});

        setIndexForm('');

        getRiesgos();

        handleOpenModalEditNotification();
      }
    }

  }

  const deleteItem = async (index) => {

    const dst = dataTable[index];

    const dataSend = {
      tipo: 'riesgo',
      accion: 'DELETE',
      id: id,
      id_registro: dataTableForm.id,

      // id_cuestionario: adk,
      hash: window.location.hash
    };

    console.log(dataSend)


    const response = await validarEntorno('JSON/panoramaRiesgos/GestionInformes/panorama_riesgos_informe_riesgos_res.html', 'POST', dataSend);

    if (response.ok) {
      const respuesta = await response.json();

      if (respuesta.message) {

        const dt = [...dataTable];

        dt.splice(index, 1);

        setDataTable(dt);

        handleCloseModalDeleteNotification();

        handleOpenModalConfirmNotification();

        setDataTableForm({});

        setIndexForm('');
      }
    }
  }


  const handleTableChangeData = async (e) => {
    const value = e.target.value;
    setDataTableForm({
      ...dataTableForm,
      [e.target.name]: value
    });
  }

  const availableInput = (index, item) => {
    if (indexForm !== index) {
      setIndexForm(index);
      setDataTableForm(item);
    } else {
      setIndexForm('');
      setDataTableForm({});
    }
  }


  return (
    <div>
      <h4 style={{ textAlign: 'start' }} className="pagesTitles">{t('panorama_riesgos.anadir_riesgo')}</h4>
      <Form className="audit-form" onSubmit={sendDataForm}>
        <Form.Row>
          {inputList.map((item, index) => (
            <
              //   style={{
              //     width: '100%',
              //     padding: '0'
              //   }}
              //   key={index}
              >
              <Form.Group as={Col} lg={12} role="form" controlId="formGridTextArea">
                <Form.Label className="">{t('formulario.label.riesgo')}</Form.Label>
                <Form.Control
                  //   className="audit-form-textarea"
                  as="select"
                  name="riesgo"
                  value={item.riesgo}
                  // value=''
                  onChange={e => handleInputChange(e, index)}
                  required
                >
                  <option value='' disabled> {t('formulario.label.seleccione')} </option>
                  {initialState?.data?.lista?.length && initialState?.data?.lista?.map((item, index) => {
                    return (
                      <option key={index} value={item.value} >{item.label}</option>
                    )
                  })

                  }
                </Form.Control>
                <Form.Control.Feedback type='invalid'>Este campo es obligatorio</Form.Control.Feedback>
                {/* {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />} */}
                {/* {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />} */}
              </Form.Group>
              <Form.Group as={Col} lg={6} role="form" controlId="formGridTextArea">
                <Form.Label className="">{t('formulario.label.probabilidad')}</Form.Label>
                <Form.Control
                  // className="audit-form-textarea"
                  // as="textarea"
                  as="select"
                  name="probabilidad"
                  value={item.probabilidad}
                  onChange={e => handleInputChange(e, index)}
                  required
                >
                  <option value="" disabled > {t('formulario.label.seleccione')}</option>
                  <option value="1" >1</option>
                  <option value="2" >2</option>
                  <option value="3" >3</option>
                  <option value="4" >4</option>
                  <option value="5" >5</option>
                </Form.Control>
                <Form.Control.Feedback type='invalid'>Este campo es obligatorio</Form.Control.Feedback>
                {/* {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={() => handleRemoveClick(index)} />} */}
                {/* {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />} */}
              </Form.Group>
              <Form.Group as={Col} lg={6} role="form" controlId="formGridTextArea">
                <Form.Label className="">{t('formulario.label.impacto')}</Form.Label>
                <Form.Control
                  // className="audit-form-textarea"
                  // as="textarea"
                  as="select"
                  name="impacto"
                  value={item.impacto}
                  onChange={e => handleInputChange(e, index)}
                  required
                >
                  <option value="" disabled >{t('formulario.label.seleccione')}</option>
                  <option value="1" >1</option>
                  <option value="2" >2</option>
                  <option value="3" >3</option>
                  <option value="4" >4</option>
                  <option value="5" >5</option>
                </Form.Control>
                <Form.Control.Feedback type='invalid'>Este campo es obligatorio</Form.Control.Feedback>
              </Form.Group>
              {inputList.length !== 1 && <RemoveCircleIcon className="icon-gray minus" onClick={(index) => handleRemoveClick(index)} />}
              {inputList.length - 1 === index && <AddCircleIcon className="icon-gray add" onClick={handleAddClick} />}
            </>
          ))}
        </Form.Row>
        <Form.Row className="btn-form-row">
          <Button className="confirmButton" type="submit">{t('botones.enviar')}</Button>
        </Form.Row>
      </Form>
      <br />
      <br />
      <hr />
      <br />
      <div className="table-audit-container" style={{ margin: '0 auto' }} >
        {dataTable.length > 0 ? (
          <>
            <h4 className="pagesTitles">{t('panorama_riesgos.lista_riesgos')}</h4>
            <table   >
              <thead>
                <tr>
                  <th>{t('formulario.label.riesgo')}</th>
                  <th>{t('formulario.label.probabilidad')}</th>
                  <th>{t('formulario.label.impacto')}</th>
                  <th>{t('formulario.label.acciones')}</th>
                </tr>
              </thead>
              <tbody>
                {dataTable?.map((item, index) => (
                  <tr key={index} className='rowTabla' style={{ fontSize: '1rem' }}>
                    {indexForm === index ? (
                      <>
                        <td style={{ width: '70%', padding: '0 20px' }} >
                          <div
                            key={index}
                            as={Col}
                            lg={12}
                            controlId="formGridTextArea"
                            style={{
                              display: 'flex'
                            }}
                          >
                            <Form.Control
                              // className="audit-form-textarea-riesgo"
                              as="select"
                              name="riesgo"
                              defaultValue={item.riesgo}
                              onChange={handleTableChangeData}
                            >
                              <option value='' disabled> -- Seleccione -- </option>
                              {initialState?.data?.lista?.length && initialState?.data?.lista?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value} >{item.label}</option>
                                )
                              })

                              }

                            </Form.Control>
                          </div>
                        </td>
                        <td>
                          <Form.Control
                            className="audit-form-riesgo"
                            name="probabilidad"
                            defaultValue={item.probabilidad}
                            onChange={handleTableChangeData}
                            as="select"
                          >

                            <option value="" disabled > -- Seleccione --</option>
                            <option value="1" >1</option>
                            <option value="2" >2</option>
                            <option value="3" >3</option>
                            <option value="4" >4</option>
                            <option value="5" >5</option>
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            className="audit-form-riesgo"
                            // as="textarea"
                            name="impacto"
                            defaultValue={item.impacto}
                            onChange={handleTableChangeData}
                            as="select"
                          >
                            <option value="" disabled > -- Seleccione --</option>
                            <option value="1" >1</option>
                            <option value="2" >2</option>
                            <option value="3" >3</option>
                            <option value="4" >4</option>
                            <option value="5" >5</option>
                          </Form.Control>
                        </td>

                      </>
                    ) : (
                      <>
                        <td style={{ width: '70%', padding: '0 20px' }} >
                          <div
                            key={index}
                            as={Col}
                            lg={12}
                            controlId="formGridTextArea"
                            style={{
                              display: 'flex'
                            }}
                          >
                            <Form.Control
                              // className="audit-form-textarea-riesgo"
                              as="select"
                              name="riesgo"
                              defaultValue={item.riesgo}
                              onChange={handleTableChangeData}
                              disabled
                            >
                              <option value='' disabled> -- Seleccione -- </option>
                              {initialState?.data?.lista?.length && initialState?.data?.lista?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value} >{item.label}</option>
                                )
                              })

                              }

                            </Form.Control>
                          </div>
                        </td>
                        <td style={{ textAlign: 'center', width: '80%', paddingLeft: '10px' }}>{item.probabilidad}</td>
                        <td style={{ textAlign: 'center', width: '80%', paddingLeft: '10px' }}>{item.impacto}</td>
                      </>
                    )}
                    <td style={{ width: '200px' }} >
                      <div className='' style={{ display: 'flex', justifyContent: 'space-around', borderRadius: '10px', backgroundColor: 'white', width: '200px !important' }} >
                        {indexForm === index ? (
                          <button style={{ cursor: 'pointer' }} className='botonTablaGeneral botonGuardarTabla' onClick={() => { editItem(index, item) }}>
                            <SaveIcon fontSize='small' />
                            {t('botones.guardar')}
                          </button>
                        ) : null}
                        <button style={{ cursor: 'pointer' }} className='botonTablaGeneral botonEditarTabla' onClick={() => { availableInput(index, item) }}>
                          {indexForm === index
                            ?
                            <>
                              <CloseIcon />
                              {t('botones.cancelar')}
                            </>
                            :
                            <>
                              <EditIcon fontSize='small' />
                              {t('botones.editar')}
                            </>
                          }
                        </button>
                        {indexForm !== index && (
                          <div style={{ cursor: 'pointer' }} className='botonTablaGeneral botonEliminarTabla' onClick={() => { handleOpenModalDeleteNotification(index); availableInput(index, item) }} >
                            <DeleteIcon fontSize='small' />
                            {t('botones.eliminar')}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal='saveQuestions'
      />
      <NotificationModal
        isOpen={showModalDeleteNotification}
        itemName="objetivo"
        setIsOpen={handleCloseModalDeleteNotification}
        typeModal='deleteItemConfirm'
        handleEliminar={deleteItem}
        indexDelete={indexDelete}
      />
      <NotificationModal
        isOpen={showModalConfirmNotification}
        itemName="Objetivo"
        setIsOpen={handleCloseModalConfirmNotification}
        typeModal='notificacionItemEliminado'
        handleEliminar={deleteItem}
        indexDelete={indexDelete}
      />
      <NotificationModal
        isOpen={showModalEditNotification}
        itemName="Objetivo"
        setIsOpen={handleCloseModalEditNotification}
        typeModal='notificacionItemActualizacion'
      />
    </div>
  )
}
