import React, { useState } from 'react'
import {useHistory} from 'react-router-dom'

import { Form, Card } from 'react-bootstrap';

// import { envioQuintaPestaña } from '../../../../reducers/crearSolicitudReducer';
import { validarEntorno } from '../../../../helpers/validarHost';
import { useDispatch } from 'react-redux';


import NotificationModal from '../../../modales/NotificationModal'


export const Pqr = () => {

  const dispatch = useDispatch()

  let history = useHistory();

  const [isDisabled, setIsDisabled] = useState(false)

  const [notificacionEnvio, setNotificacionEnvio] = useState(false);
  // const [modal, setModal] = useState(false)
  // const [mensajeModal, setMensajeModal] = useState('')
  // const [tipoModal, setTipoModal] = useState('')
  // const [modalF, setModalF] = useState(false)

  const [validated, setValidated] = useState(false);
  const [dataInputs, setDataInputs] = useState({
    tipo_pqr: '',
    descripcion: ''
  });

//   const [quintaPestaña, setQuintaPestaña] = useState({
//     identificacion: '',
//     observaciones: '',
// })

  const direccionAdministrar = () => {
    history.push('/dashboard-gerencia')
  }



  const handleInputchangeSecondPestaña = (e) => {
    setDataInputs({
      ...dataInputs,
      [e.target.name] : e.target.value
    })
  }

  const handleSubmitSecondPestaña = async (e) => {
    e.preventDefault()

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }

    setValidated(true)

    if (form.checkValidity() === true) {
      setIsDisabled(true)
      const respuesta = await validarEntorno('JSON/res_quinta_pestana.html', 'POST', dataInputs)
  
      if(respuesta.ok){
        const data = await respuesta.json()
        setNotificacionEnvio(true)
        setIsDisabled(false)
      }
    }


    


    

  }




  return (
    <>
      <Card className='shadow cardContainerSolicitud' style={{borderRadius: '0px 10px 10px 10px'}} >
        <h4 className='titleCard'>Nuevo PQR</h4>
        <Card.Body>
        <Form className='formEstudioBasicoContainer' noValidate validated={validated} onSubmit={handleSubmitSecondPestaña}>
          <div className='formItem' >
              <Form.Group required style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  <Form.Label style={{marginLeft:'0px'}}>
                      Tipo de solicitud
                  </Form.Label>
                  <div className='inputContainer'>
                      <Form.Control onChange={handleInputchangeSecondPestaña} name='tipo_pqr' required as='select' placeholder='Tipo de PQR'>
                        <option selected disabled>--Seleccione el tipo de PQR--</option>
                        <option value="Sugerencia">Sugerencia</option>
                        <option value="Petición">Petición</option>
                        <option value="Queja">Queja</option>
                        <option value="Reclamo">Reclamo</option>
                        <option value="Preguntas o Dudas">Preguntas o Dudas</option>
                        <option value="Fallos Técnicos">Fallos Técnicos</option>
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa el tipo de PQR </Form.Control.Feedback>
                  </div>
              </Form.Group>
          </div>
          <div id='observacionesContainerEstudioBasico'>
              <Form.Group id='observacionesFormGroup'>
                  <Form.Label className='observacionesLabel'>
                      Descripción
                  </Form.Label>
                  <div className='textAreaContainer'>
                      <Form.Control onChange={handleInputchangeSecondPestaña} name='descripcion' id='observacionesTextArea' required as='textarea' placeholder='Descripción'></Form.Control>
                      <Form.Control.Feedback type='invalid'>Escribe una breve descripción</Form.Control.Feedback>
                  </div>
              </Form.Group>
          </div>
          <div className='buttonContainer'>
              <button className='confirmButton' disabled={isDisabled} >Guardar</button>
              {/* <ModalConfirmacion typeModal='error' isOpen={showModal} setIsOpen={setShowModal} />                */}
          </div>
        </Form>
        </Card.Body>
      </Card>
      <NotificationModal redireccion={direccionAdministrar} typeModal='pqrSuccess' isOpen={notificacionEnvio} setIsOpen={setNotificacionEnvio}/>
    </>
  )
}
