import React, { useState } from 'react';
//import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
// import { useParams } from 'react-router-dom';
// import { FileImage, CircleCheck, CircleX } from 'lucide-react';
// import { validarEntorno } from '../../helpers/validarHost';
// import { useIdSegundoNivel } from '../../context/IdSegundoNivelContext';
import { PagesContext, usePagesContext } from '../../context/PagesContext'; // Añadido contexto

function PdfImgButton({ url, data_pdf_img: { n_pag_pdf, n_pag_max }, idGestion, onSelectedPagesChange }) {
  //const { idSegundoNivel } = useIdSegundoNivel();
  const [selectedPages, setSelectedPages] = useState([]);
  // const [conversionStatus, setConversionStatus] = useState(""); // Estado de la conversión
  // const [conversionMessage, setConversionMessage] = useState("Convertir a Imagen"); // Mensaje para el botón
  // const [errorMessage, setErrorMessage] = useState(""); // Mensaje de error para selección
  //const { seccion, id } = useParams(); // Obtiene parámetros de la URL

  // Crear una lista de opciones de páginas
  const pageOptions = Array.from({ length: n_pag_pdf }, (_, i) => ({
    label: `Página ${i + 1}`,
    value: i + 1
  }));

  // Función para manejar la selección de páginas
  const handlePageSelection = (e) => {
    const selectedPages = e.value;
  
    // Verificar si la cantidad de páginas seleccionadas no excede el máximo permitido
    if (selectedPages.length <= n_pag_max) {
      console.log("Páginas seleccionadas:", selectedPages);
      
      // Actualiza el estado acumulando las selecciones (puedes usar el spread operator)
      setSelectedPages(prevSelectedPages => {
        // Aquí puedes combinar la selección actual con las anteriores (si es necesario)
        return selectedPages; // Esto reemplaza las páginas seleccionadas con las nuevas, sin acumulación
      });
  
      onSelectedPagesChange(selectedPages);
  
      // Despachar el evento personalizado con las páginas seleccionadas
      const event = new CustomEvent('pagesSelected', { detail: selectedPages });
      window.dispatchEvent(event);
    }

    
  

  // Función para enviar la solicitud al backend
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Datos a enviar en la solicitud
  //   const requestData = {
  //     url,
  //     id_gestion: idGestion,
  //     hash: window.location.hash,
  //     seccion,
  //     id,
  //     idSegundoNivel,
  //     pages: localSelectedPages // Usa las páginas seleccionadas del estado local
  //   };

  //   console.log("Datos de la solicitud:", requestData);

  //   try {
  //     // Realiza la solicitud POST al backend con validarEntorno
  //     const request = await validarEntorno("JSON/ws/ia/pdf_to_image.html", "POST", requestData);

  //     if (request && request.ok) {
  //       const response = await request.json();
  //       // Verifica el estado de la respuesta y actualiza el estado de conversión
  //       setConversionStatus(response.estado === "ok" ? "success" : "error");
  //       setConversionMessage(response.mensaje); // Actualiza el mensaje del botón con el valor de "mensaje" de la respuesta

  //     } else {
  //       setConversionStatus("error");
  //       setConversionMessage("Error al realizar la conversión");
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setConversionStatus("error");
  //     setConversionMessage("Error de conexión");
  //   }
  };

  return (
    <div className='d-flex flex-column' style={{ padding: '10px', backgroundColor: '#f0f4f8', borderRadius: '8px', width: '285px' }}>
      <h6 style={{ textAlign: 'center', marginBottom: '10px', color: '#1E3A8A', fontWeight: 'bold' }}>PDF a Imagen</h6>
      <div className='d-flex flex-column' style={{ width: '100%' }}>
        <label htmlFor="pageSelect">
          {`Seleccione máximo ${n_pag_max} paginas:`}
        </label>
        <MultiSelect
          id="pageSelect"
          value={selectedPages} // Usa el estado local
          options={pageOptions}
          onChange={handlePageSelection}
          placeholder="Seleccione las páginas"
          display="chip"
          style={{ width: '100%', fontSize: '14px', maxHeight: '150px', overflowY: 'auto' }}
          className="p-inputtext-sm p-0 mw-300"
        />
        <style>
          {`
            .p-multiselect-header {
                display: none;
            }
          `}
        </style>
      </div>
      {/* Botón que cambia de estilo según el estado de la conversión */}
      {/* <Button
        className={`p-button-sm ${conversionStatus === "success" ? "p-button-success" : conversionStatus === "error" ? "p-button-danger" : ""}`}
        label={conversionMessage} // Usa el mensaje dinámico
        onClick={handleSubmit}
        style={{ marginTop: '5px', fontSize: '14px', boxShadow: 'none', border: '1px solid #d3d3d3' }}
        icon={conversionStatus === "success" ? <CircleCheck style={{ width: '16px', height: '16px', color: '#FFFFFF', marginLeft: '10px' }} />
          : conversionStatus === "error" ? <CircleX style={{ width: '16px', height: '16px', color: '#FFFFFF', marginLeft: '10px' }} />
            : <FileImage style={{ width: '16px', height: '16px', color: '#FFFFFF', marginLeft: '10px' }} />}
      /> */}
    </div>
  );
}

export default PdfImgButton;
