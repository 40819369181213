import React, {useState, useEffect} from 'react'
import { Col, Form, Button, Tabs, Tab, ListGroup, Table, Row } from 'react-bootstrap'
import reactDOM from 'react-dom'
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import CloseIcon from '@material-ui/icons/Close';
import {GeneradorPDF} from '../GestorDocumentos/GeneradorPDF';

import './EditModal.scss'
import { validarEntorno } from '../../helpers/validarHost';

export default function ModalDocumentos(props) {


    if(!props.isOpen){
        return null;
    };

    return reactDOM.createPortal (
        <div className='editMmodalContainer'>
            <div className='editModalCard' style={{ width: 1200, maxHeight: 1300 }}>
                <GeneradorPDF data={props.data} graficas={props.graficas} />
            </div>
        </div>,
      document.getElementById('DocumentModal')
    )

}
