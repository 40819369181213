import { Form } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import './styles/controles.scss';
import moment from "moment";
import { BtnAyuda } from "./BtnAyuda";

const Datepicker = ({ item, handleInputChange, index, idx, reg, nombre, error, esEditable, type }) => {

// const { control } = useForm();

    /**
     * Lista negra de fechas invalidas para el 
     * componente
     */
    const validate = [
        "",
        " ",
        "0000-00-00",
        "0000-00-00 00:00:00",
    ];

     /**
     * Función que valida fechas
     * frente a la lista negra que se envio
     * si es el caso contrario envia un vacio.
     * @param {*} value 
     * @returns 
     */
    const validateDate = (value) => { 
        if(validate.indexOf(value) !== -1) {
            return false;
        } else {
            return true;
        }
    }
    
    /**
     * Convierte un string a una fecha en formato año - mes - dia
     * @param {*} str 
     * @returns 
     */
    const [startDate, setStartDate] = useState((validateDate(item.valor) === true) ? moment(item.valor).toDate() : "");

    return(
        <Form.Group style={{ display: 'block' }}>
            <div style={{display: 'flex', justifyContent: 'flex-start'}} >
                <Form.Label 
                    style={{ 
                        color: item.label_color ? item.label_color : "#000",
                        fontSize: item.font_size ? item.font_size : "" 
                    }}
                >
                    { item.titulo  }{item.es_requerido === true && '*'}
                </Form.Label>
                {item.ayuda &&
                    <BtnAyuda ayuda={item.ayuda}/>
                }
            </div>
            <ReactDatePicker
                selected={moment(startDate).format('YYYY-MM-DD HH:mm:ss')}
                onChange={
                    (date) => { 
                        setStartDate(date ? moment(date).toDate() : "")    
                    }
                }
                showTimeInput
                dateFormat="yyyy-MM-dd HH:mm:ss"
                name={`asesoria.${index}.${item.nombre}`}
                ref={reg}   
                {...reg(`asesoria.${index}.${item.nombre}`, {
                    required: item.es_requerido
                })}
                className={ (type && type === 'reporte-operacional') ? "form-control" : "form-control " }
                style={{ 
                    border: error ? '0px solid #dc3545' : 'none',
                    width: '95%' 
                
                }}
                selected={startDate}
                disabled={esEditable}
                isClearable={true}
                clearButtonClassName={(type && type === 'reporte-operacional')  ? "button-close-timer-repo" : "button-close-timer"}
            />
            <input type={"hidden"} name={`${nombre}.${item.nombre}`} value={moment(startDate).format('YYYY-MM-DD HH:mm:ss')} ref={reg} />
            { error ? 
                (
                    error.type === 'required' ? 
                    (
                        <span className="inputsInvalid">
                            Este campo es requerido
                        </span>
                    ) 
                    : null ) 
            : null } 
        </Form.Group>
    );

}

export default Datepicker;