import React, {useState, useEffect, Fragment} from 'react'
import { useParams } from 'react-router-dom';
import { Card, Row, Form } from 'react-bootstrap'
import useState2 from 'react-usestateref';
import { useForm } from 'react-hook-form';
import { validarEntorno } from '../../helpers/validarHost'

import './styles/DetalleDep.scss'

import Loader from '../icons/Loader'
import DivergentChartAmplia from '../dashboard/DivergentChartAmplia'
import GraficaConsolidado from '../dashboard/GraficaConsolidado'
import Analistas from './Analistas'
import InputType from '../../helpers/InputType'

export default function DetalleDep(props) {

  const {departamento} = useParams()

  const {perfil} = props


  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();


  const [mode, setMode] = useState({
    tipo: ''
  })

  const [ inputGroup, setInputGroup, inputGroupRef ] = useState2([]);
  const [inputList, setInputList] = useState([{ valor: "", valido: false }]);


  const [ filtroSelected, setfiltroSelected] = useState({})

  const [ loaderRegs, setLoaderRegs] = useState(false)

  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })

  const [opciones, setOpciones] = useState([])
  const [infoFiltro, setInfoFiltro] = useState({
    tabla: {},
    graficas: []

  })

  const [ filtros, setFiltros ] = useState({
    fecha_inicial: '',
    fecha_final: ''
  })

  const [arrayFilters, setArrayFilters] = useState([])
  const hash = window.location.hash;

  const detalleDep = async (data) => {

    setFiltros(data)
    setfiltroSelected(data)
    setArrayFilters(data?.asesoria[0])

    const dataReq = {
      filtros: data?.asesoria[0],
      hash: hash
    }

    console.log(dataReq)


    const req = await validarEntorno(`${perfil === 'gerencia_riesgos' ? 'JSON/PanelGR/detalle_dep_presidencia.php' : 'JSON/PanelPresidencia/detalle_dep_presidencia.php' }`, 'POST', dataReq)

    if(req.ok){

      const res = await req.json()

      console.log(res)

      setInitialState({
        ...initialState,
        loading: false,
        error: null,
        data: res
      })

      let valorbtn = res.botones[0].nombre

      changeMode(res.botones[0]?.nombre ? res.botones[0]?.nombre : 'registros')
      setMode({tipo: res.botones[0]?.nombre ? res.botones[0]?.nombre : 'registros'})
      // changeMode('registros');
      getValores(data?.asesoria[0], data?.asesoria[0], res?.botones[0]?.nombre)
    }
  }

  const getValores = async (filtros, item, tipo) => {


    setfiltroSelected(item)
    setLoaderRegs(true)

    const dataReq = {
      // fecha_inicial: filtros.fecha_inicial ? filtros.fecha_inicial : '',
      // fecha_final: filtros.fecha_final ? filtros.fecha_final : '',
      // dataForm: data,
      filtros: filtros ? filtros : arrayFilters,
      hash: window.location.hash,
      tipo: tipo,
      id: item.id ? item.id : null
      // ,
      // id_filtro: item?.id ? item?.id : 1
    }

    console.log(dataReq)

    const req = await validarEntorno(`${perfil === 'gerencia_riesgos' ? 'JSON/PanelGR/gerencial_data_informe_prueba.html' : 'JSON/PanelPresidencia/gerencial_data_informe_prueba.html'}`, 'POST', dataReq)

    if(req.ok){
      const res = await req.json()

      setInfoFiltro({
        tabla: res.tabla,
        graficas: res.grafica
      })

    setLoaderRegs(false)

    }

  }

  const changeMode = async (tipo) => {

    const dataReq = {
      filtros: arrayFilters,
      // fecha_inicial: filtros.fecha_inicial ? filtros.fecha_inicial : '',
      // fecha_final: filtros.fecha_final ? filtros.fecha_final : '',
      hash: window.location.hash,
      tipo: tipo
    }



    const req = await validarEntorno(`${perfil === 'gerencia_riesgos' ? 'JSON/PanelGR/get_opciones_presidencia.php' :'JSON/PanelPresidencia/get_opciones_presidencia.php'}`, 'POST', dataReq)

    if(req.ok){



      const res = await req.json()

      // setInitialState({
      //   ...initialState,
      //   loading: false
      // })

      setfiltroSelected(res?.opciones[0])

      setInfoFiltro({
        tabla: {},
        graficas: []
      })

      setOpciones(res.opciones)
      // getValores(res.opciones[0].id)

      setMode(tipo)

      // getValores(arrayFilters, {id: res.opciones[0].id}, mode)

    }
    // setInitialState({
    //   initialState,
    //   // data: {...initialState.data},
    //   loading: false

    // })

  }


  useEffect(() => {
    setInputGroup([]);

    // detalleDep()
    // getValores(filtroSelected)
    setTimeout(() => {
      setInputGroup(props?.campos)
    }, 1000)
  }, [])

  return (
    <div
      style={{
        marginTop: '20px',
        width: '100%'
      }}
    >
      <Card id='cardFiltrosFechas' className='shadow cardGeneral' style={{backgroundColor: 'whitesmoke'}}>
      <Card.Body  >
        {!inputGroup.length
          ?
            <p  className='pagesTitles' style={{height: '100%'}} >Cargando filtros...</p>
          :
          <>
            {(inputGroupRef.current && inputGroupRef.current.length > 0) &&
            <>
                <Form  noValidate onSubmit={handleSubmit(detalleDep)} style={{ width:'98%', margin:'0 auto', padding: '0'}}>
                  <div id='contenedorFiltrosGerencia'>
                    {(inputGroupRef.current?.map( (campo, index) => {
                      const fieldName = `asesoria.0`;
                      return (
                        <InputType
                          item={campo}
                          index={index}
                          idx={index}
                          key={`${0} - ${index}`}
                          reg={register}
                          nombre={`${fieldName}`}
                          errors={errors}
                          col={campo.col}
                          type=""
                          inputList={inputList}
                          setInputList={setInputList}
                        />
                      )
                    }))}
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: '20px auto 0'
                    }}
                  >
                    <button
                      type="submit"
                      className='confirmButton'
                      style={{
                        marginRight: '0 !important'
                      }}
                    >
                      Filtrar
                    </button>
                  </div>
              </Form>
            </>
            }
          </>
        }



        {/* <Form.Group id='fechaInicial' className='formGroupVertical'>
          <Form.Label>Fecha inicial</Form.Label>
          <Form.Control
            defaultValue=''
            type='date'
            name='fecha_inicial'
            onChange={ (e) => {getFechas(e)} }
          />
        </Form.Group>
        <Form.Group className='formGroupVertical'>
          <Form.Label>Fecha final</Form.Label>
          <Form.Control
            defaultValue=''
            type='date'
            name='fecha_final'
            onChange={ (e) => {getFechas(e)} }
          />
        </Form.Group> */}
        {/* { inputList.length &&
          <div className='contenedorButtonFiltro'>
            <button
              className='confirmButton'
              style={{
                marginRight: '0 !important'
              }}
              onClick={() => {
                detalleDep();
                changeMode('registros');
                getValores(filtroSelected)
              }}
            >
              Filtrar
            </button>
          </div>
        } */}
      </Card.Body>
      </Card>
      <br/>
      { initialState?.data?.widgets?.length &&
        <Card id='cardWidgets' className='shadow cardGeneral' style={{width: '100%'}}>
          <Card.Body id='bodyWidgetsDetalleDep' >
            <div id='infoGeneralDetalleDep' className='colWidgetBar'>
              <h2 id='tituloDep'>{departamento}</h2>
              <p id='corteDep' >{initialState?.data?.corte}</p>
              <h3 id='tituloDep' style={{fontSize:'1.7rem'}}>{ initialState?.data?.total ? `Total: ${initialState?.data?.total}` : null }</h3>
            </div>
            <div id='contenedorWidgetsDetalleDep' className='scrollSecundario colWidgetBar' style={{maxWidth: '56vw', boxSizing: 'border-box'}} >
              {initialState?.data?.widgets?.map((widget, index) => {
                return(
                  <Card
                    style={{
                      borderBottom: `10px solid ${widget.color}`
                    }}

                    // id={
                    //   widget.id === '1'
                    //     ? 'enProceso'
                    //     : widget.id === '2'
                    //       ? 'canceladas'
                    //       : widget.id === '3'
                    //         ? 'diasFinalizados'
                    //         : null
                    // }
                    // id='diasVencimientso'
                    className="shadow  widgetPr"
                    // onClick={() => handleClickWidget('widgetFinalizados')}
                    key={index}
                  >
                    <Card.Body>
                      <div className='contenidoWidget' style={{height: 'auto'}} >
                        <div className='labelWidget'>{widget.nombre}</div>
                        <div className='cantidadWidget' style={{fontSize: '1.2rem'}}>{widget.valor}</div>
                      </div>
                    </Card.Body>
                  </Card>
                )
              })

              }
            </div>
          </Card.Body>
        </Card>
      }
      {
        <Card
          id='cardPrincipal'
          className='shadow cardGeneral'
          style={{
            marginTop: '20px'
          }}
        >
          <Card.Body>
            <section id='seccionbtnSeccion'>
              {initialState?.data?.botones?.map((boton, index) => {
                return(
                  <div
                    key={index}
                    style={{
                      backgroundColor: mode === boton.nombre ? boton.color : null,
                      color: mode === boton.nombre ? 'white' : null,

                    }}
                    className='modeButton'
                    onClick={() => {
                      changeMode(boton.nombre) ;
                      // setMode({tipo: boton.nombre});
                      getValores(arrayFilters, filtroSelected, boton.nombre)
                    }}
                  >
                    {boton.nombre}
                  </div>
                )

              })}

            </section>
            <hr/>
            {initialState.loading === false && opciones.length > 0
              ?
              <section>
                <Row>
                    <div id='seccionDataInforme'>
                      <div id='columnTiposInforme' >
                        {opciones?.length > 0 && opciones?.map((tipo) => {
                          return(
                            <div
                              className={`tipoInforme`}
                              key={tipo.id}
                              onClick={() => {getValores(arrayFilters, tipo, mode) ; setfiltroSelected(tipo, mode)} }
                              style={{
                                backgroundColor: filtroSelected === tipo.id ? 'rgba(132, 132, 132, 0.433) !important' : null
                              }}
                            >
                              {tipo.nombre}
                            </div>
                          )
                        })}
                      </div>
                      <div id='contenedorGraficasFiltro'>
                        { loaderRegs === true ? <Loader /> :
                          ( infoFiltro?.graficas && infoFiltro?.graficas?.length > 0 &&
                            infoFiltro?.graficas.map((grafica, index) => (
                                <Fragment key={index}>
                                  {grafica.tipo === 'analistas'
                                      ?
                                      <>
                                        <Analistas
                                          data={grafica.data}
                                          title={grafica.titulo}
                                          total={grafica.total}
                                          convenciones='bottom'
                                          mode={mode}
                                          limite={grafica.limite}
                                          filtro={arrayFilters}
                                          fechas={filtros}
                                          filtroSelected={filtroSelected}
                                          // modal={modal}
                                          // setModal={setModal}
                                        />
                                        <br/>
                                      </>
                                      : grafica.tipo === 'series'
                                        ?
                                        <>
                                          <GraficaConsolidado
                                            title={grafica.titulo}
                                            id='seriesPanelGerencial'
                                            data={grafica.series.data}
                                            series={grafica.series.lista}
                                            seriesOcultas = {grafica.ocultar_series}
                                          />
                                          <br/>
                                        </>
                                        : grafica.tipo === 'divergente'
                                          ?
                                          <>
                                            <DivergentChartAmplia
                                              id='divergenteValores'
                                              data={grafica.data}
                                              title={grafica.titulo}
                                              total={grafica.total}
                                              convenciones='bottom'
                                              // parametrizado={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.parametrizado}
                                              // periodo={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.periodo}
                                              // total={permisos.panelGeneral.consolidado.graficos.compromisosTipoServicio.total}
                                              limite={grafica.limite}
                                            />
                                            <br/>
                                          </>
                                          : null
                                  }
                                </Fragment>
                              )
                            ))
                        }
                      </div>
                    </div>

                </Row>
              </section>
              : initialState.loading === false && opciones.length <= 0
                ? <p>No se encontró información</p>
                : <Loader/>
            }

          </Card.Body>
        </Card>

      }

      {/* <EditModal
        typeModal={modal.type}
        isOpen={modal.show}
        dataModal = {modal.data}
        setIsOpen={setModal}
      /> */}



    </div>
  )
}
