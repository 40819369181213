import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'

import PopUpModal from '../../helpers/PopUpModal';
import { HelpSVG } from '../icons/HelpSVG'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useTranslation } from 'react-i18next';
// import {AMCHART} from '../../helpers/amchartLicense'

export default function FunnelGradient(props) {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [popUp, setPopUp] = useState('')

  // am4core.addLicense(AMCHART);


  useEffect(() => {

    let chart = am4core.create(props.id, am4charts.SlicedChart);

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = "{percent}";
    chart.legend.maxWidth = 600;

    // chart.legend.labels.template.text = "{category}: [bold]{value}[/]";



    chart.data = props.data ? props.data : [
      {
      "name": "Disponibilidad del candidato",
      "value": 6
      },
      {
        "name": "Incumplimiento en la visita",
        "value": 1
      },
      {
        "name": "Aspecto laboral incompleto",
        "value": 1
      }
    ];




    let series = chart.series.push(new am4charts.FunnelSeries());
    series.colors.list = [
      am4core.color("#2c5e99"),
      am4core.color("#05a4db"),
      am4core.color("#009688"),
      am4core.color("#8BC34A"),
      am4core.color("#F9CF00")
    ];
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.labels.template.fontSize = 20;


    // let fillModifier = new am4core.LinearGradientModifier();
    // fillModifier.brightnesses = [-0.5, 1, -0.5];
    // fillModifier.offsets = [0, 0.5, 1];
    // series.slices.template.fillModifier = fillModifier;
    series.alignLabels = true;

    series.labels.template.text = "{category}: [bold]{value}[/]";
    series.slices.template.tooltipText = "{category}: {value.percent.formatNumber('.')}%";

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.verticalAlign = "top";
    // chart.exporting.menu.horizontalAlign = "left";
    chart.exporting.formatOptions.getKey("svg").disabled = true;

    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": t('botones.imagen') },
        // { "type": "csv", "label": "Tabla" },
        { "type": "print", "label": t('botones.imprimir') }
      ]
    }];

  }, [])


  return (
    <Card className="shadow cardGraficaGeneral cardGraficaAnalistas">
     {/*  <div
        className="d-flex flex-row-reverse bd-highlight p-1"
        style={{ cursor: 'pointer', top: '20px', right: '20px' }}
        onClick={() => setOpen(true)}
      >
        <HelpSVG className="bd-highlight" />
      </div> */}
      <br/>
      <br/>

      <Card.Body className='bodyGraficasGeneral'>
        <h4 className='pagesTitles' style={{ textAlign: 'center',width: '100%', margin: '0 auto'}}> {props.title}
        <br/>
        {props.parametrizado
          ?
            <>
              {props.parametrizado} - {props.periodo }
            </>

          : null
        }
        </h4>
        <br/>
        <br/>

        <div id={props.id} style={{ width: '90%', minHeight: '440px', margin: '0 auto' }}></div>
        <br/>
        <h4 className='pagesTitles' >
          Total: {props.total}
        </h4>
      </Card.Body>
      <PopUpModal open={open} setOpen={setOpen} titulo={popUp.titulo} texto={popUp.texto} />
    </Card>
  )
}
