import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../pages/login'

export const LoginRoute = () => {
    return (
        <Switch>
            <Route exact path='/login' component={Login}></Route>
        </Switch>
    )
}