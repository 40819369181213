import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Form } from 'react-bootstrap'
import { PerfilIconSVG } from '../icons/PerfilIconSVG'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Loader from '../icons/Loader'

import { validarEntorno } from '../../helpers/validarHost'

import EditModal from '../modales/EditModal'

import DonaConTotal from "../dashboard/DonaConTotal";
import { useTranslation } from 'react-i18next';



import './styles/Analistas.scss'


export default function Analistas(props) {

  const { t } = useTranslation();

  const { data } = props

  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })


  const [modal, setModal] = useState({
    type: '',
    show: false,
    data: {}
  })

  const [item, setItem] = useState({})

  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 255,
      maxWidth: 400,
      overflow: 'hidden',
      display: 'block',
      width: '100%',
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = item.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // console.log(props.filtroSelected)




  const [dataItem, setDataItem] = useState({
    graficas: []
  })

  const showDetail = async (item) => {

    setItem(item)

    const dataReq = {
      filtros_generales: props.filtros_generales,
      filtros: props.filtro,
      id: props.filtroSelected?.id,
      hash: window.location.hash,
      id_item: item.id,
      nombre_item: item.nombre,
      modo: props.mode,
      id_filtro: props.filtro.id
    }


    //console.log(dataReq, 'dataReq')

    const req = await validarEntorno('JSON/PanelPresidencia/detalle_item.html', 'POST', dataReq)


    if (req.ok) {
      const res = await req.json()

      // console.log(res)

      setDataItem({
        ...dataItem,
        graficas: res.graficas
      })
    }




    setModal({
      ...modal,
      show: true,
      type: 'detalleItemAnalista',
      data: item
    })



  }

  return (
    <Card className='shadow'>
      <Card.Body>
        <br />
        <div>
          <h4 className='pagesTitles' style={{ textAlign: 'justify' }} >
            {props.titulo_label ? props.titulo_label : `Total por ${props.title.toLowerCase()} : ${props.total} `}
          </h4>
        </div>
        <div className='itemAnalistaHeader'>
          <div style={{ display: 'flex', alignItems: 'center' }} >
            <p style={{ paddingLeft: '10px', margin: '0px' }} >
              {props.reg_label ? props.reg_label : props.title ? props.title : 'Registro'}
            </p>
          </div>
          <div className='valoresAnalista'>
            {props?.valor_label ? props?.valor_label : t('formulario.label.valor')}
          </div>
        </div>
        {data.map(item => {
          return (
            <div
              className={`itemAnalista`}
              onClick={() => showDetail(item)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }} >
                <PerfilIconSVG
                  height={24}
                  width={24}
                />
                <p style={{ paddingLeft: '10px', margin: '0px' }} >
                  {item.nombre}
                </p>
              </div>
              <div className='valoresAnalista'>
                {item.registros}
                {/* {item.proceso} */}
                {/* <a>Ver detalle</a> */}
              </div>

            </div>
          )
        })}

      </Card.Body>
      <EditModal
        typeModal={modal.type}
        isOpen={modal.show}
        dataModal={modal.data}
        setIsOpen={setModal}
      >
        <div style={{
          maxHeight: '70vh',
          width: '98%',
          overflowY: 'auto',
          marginTop: '30px',
          padding: '0 10px'
        }} >

          {dataItem?.graficas?.map((grafica) => {

            console.log(grafica)

            return (
              <>
                {grafica.tipo === 'donaTotal'
                  ?
                  <DonaConTotal
                    id={grafica.titulo}
                    data={grafica.gestiones}
                    title={item.nombre}
                    total={grafica?.total}
                    tipo={grafica.tipo_valor}
                    legendPosition={'right'}
                    width={'100%'}

                  // #ededed

                  />
                  : grafica.tipo === 'lista' && grafica.data.length > 0
                    ?
                    <div className='lista_container'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '95%',
                          margin: '0 auto'
                        }}
                      >
                        {grafica.titulo && grafica.titulo !== '' && <div style={{ maxWidth: grafica.r_lbl !== '' ? '50%' : '100%', fontSize: '1.5em', height: 'auto', margin: '10px', textAlign: 'left' }} className='pagesTitles'> {grafica.titulo} </div>}
                        {grafica.r_lbl && grafica.r_lbl !== '' && <div style={{ maxWidth: grafica.titulo !== '' ? '50%' : '100%', fontSize: '1.5em', height: 'auto', margin: '10px ', textAlign: 'right' }} className='pagesTitles'> {grafica.r_lbl} </div>}

                      </div>

                      {/* <Paper square elevation={0} className={classes.header}>
                    <Typography>{item[activeStep]}</Typography>
                  </Paper> */}
                      <div className='shadow scrollSecundario lista_item'>
                        {grafica.data[activeStep].map((ele, index) => {
                          return (
                            <div
                              key={index}
                              className='lista_item-ele'
                            >
                              <p
                                className='lista_item-ele-header'
                              >
                                {ele.label}
                              </p>
                              <p
                                className='lista_item-ele-content'
                              >
                                {ele.value}
                              </p>
                            </div>

                          )
                        })}
                      </div>
                      <MobileStepper
                        steps={grafica.data.length}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                          <button
                            className='btn-stepper  '
                            onClick={handleNext}
                            disabled={activeStep === grafica.data.length - 1}
                          >
                            {t('botones.siguiente')}
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                          </button>
                        }
                        backButton={
                          <button
                            className='btn-stepper  '
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            {t('botones.anterior')}
                          </button>
                        }
                      />

                      {/* {grafica.data.map((item, index) => {
                    return(
                      <div
                        key={index}
                        className='shadow lista_item'
                      >
                        {item?.map((ele, index) => {
                          return(
                            <div
                              key={index}
                              className='lista_item-ele'
                            >
                              <p
                                className='lista_item-ele-header'
                              >
                              {ele.label}
                              </p>
                              <p
                                className='lista_item-ele-content'
                              >
                              {ele.value}
                              </p>
                            </div>
                              //   <div className={classes.root}>
                              //   <Paper square elevation={0} className={classes.header}>
                              //     <Typography>{item[activeStep].label}</Typography>
                              //   </Paper>
                              //   <img
                              //     className={classes.img}
                              //     src={item[activeStep].imgPath}
                              //     alt={item[activeStep].label}
                              //   />
                              //   <MobileStepper
                              //     steps={maxSteps}
                              //     position="static"
                              //     variant="text"
                              //     activeStep={activeStep}
                              //     nextButton={
                              //       <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                              //         Next
                              //         {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                              //       </Button>
                              //     }
                              //     backButton={
                              //       <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                              //         {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                              //         Back
                              //       </Button>
                              //     }
                              //   />
                              // </div>
                          )
                        })

                        }

                      </div>
                    )
                  })

                  } */}
                    </div>
                    : null

                }
              </>
            )
          })}

        </div>

      </EditModal>
    </Card>
  )
}
