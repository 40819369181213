import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';

import { Form, Card } from 'react-bootstrap';
import { validarEntorno } from '../../../../helpers/validarHost';
import NotificationModal from '../../../modales/NotificationModal'
import { useTranslation } from 'react-i18next';


export const Aprobaciones = ({ ele }) => {

  // console.log(ele)
  const { t } = useTranslation();
  const [tipoNotificacion, setTipoNotificacion] = useState({
    tipo: '',
    mensaje: ''
  })

  const { id } = useParams()


  const [changeForm, setChangeForm] = useState({
    aprobacion: '',
    prioridad: '',
    observacion: ''
  })

  const [confirmacionEnvio, setConfirmacionEnvio] = useState(false)

  const [modal, setModal] = useState({
    show: false,
    tipo: ''
  })

  useEffect(() => {
    setChangeForm({
      aprobacion: ele.valuesInputs.aprobacion || '',
      prioridad: '',
      observacion: ''
    })
  }, [])

  const handleChange = ({ target }) => {
    setChangeForm({
      ...changeForm,
      [target.name]: target.value

    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const data = {
      hash: window.location.hash,
      idSolicitud: id,
      formData: changeForm
    }

    // console.log(data)



    const envioSolicitudObservacion = await validarEntorno('JSON/aprobaciones_inv_adm.html', 'POST', data)

    if (envioSolicitudObservacion.ok) {


      const respuesta = await envioSolicitudObservacion.json()

      if (respuesta.solicitud === 'ok') {
        setModal({ ...modal, show: true })
        // setConfirmacionEnvio(true)
        setTipoNotificacion({ ...tipoNotificacion, tipo: 'aprobarSolicitud', mensaje: respuesta.mensaje })
        setChangeForm({
          aprobacion: `${respuesta.aprobacion}`,
          prioridad: respuesta.prioridad,
          observacion: `${respuesta.observacion}`
        })
      } else if (respuesta.solicitud === 'error') {
        setModal({ ...modal, show: true })
        setTipoNotificacion({ ...tipoNotificacion, tipo: 'errorFormSolicitud', mensaje: respuesta.mensaje })
      }

    }

  }

  const recargarGestor = () => {
    window.location.reload()
  }


  return (
    <>
      <Card className='card__box__surface p-5 card__plain__top__left'>
        <Card.Body className='p-0'>
          <h5 className='titleCard'>{t('formulario.label.aprobar_investigacion')}</h5>
          <br />
          <div className='contenedorTextAreaGeneral'>
            <Form onSubmit={handleSubmit}>
              <div style={{ display: 'flex', }}>
                <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%', marginRight: '12px' }}>
                  <Form.Label className='observacionesLabel' style={{ marginLeft: '0px' }}>
                    {t('formulario.label.aprobacion')} *
                  </Form.Label>
                  <Form.Control
                    required
                    // className='textAreaGeneral'
                    name='aprobacion'
                    id='observacionesTextArea'
                    as='select'
                    placeholder='Observaciones'
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }}
                    disabled={!ele.editar}
                    defaultValue={ele.valuesInputs.aprobacion}
                  >
                    <option value='' disabled>{t('formulario.label.seleccione_opcion')}</option>
                    {/* <option>Pendiente de aprobación</option> */}
                    <option>{t('formulario.select.aprobar')}</option>
                    <option>{t('formulario.select.no_aprobar')}</option>
                    <option>{t('formulario.select.devolver')}</option>
                    <option>{t('formulario.select.cancelar')}</option>
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>Seleccione el estado de la aprobación</Form.Control.Feedback>
                </Form.Group>

                {ele?.valuesInputs?.prioridad?.visualizar === true &&
                  <Form.Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }} >
                    <Form.Label className='observacionesLabel' style={{ marginLeft: '0px' }}>
                      {t('formulario.label.prioridad')} *
                    </Form.Label>
                    <Form.Control
                      // className='textAreaGeneral'
                      name='prioridad'
                      id='observacionesTextArea'
                      as='select'
                      placeholder='Observaciones'
                      disabled={!ele.editar}
                      defaultValue={ele.valuesInputs.prioridad.valor}
                      onChange={(e) => handleChange(e)}
                    >
                      {/* <option value="" selected="" disabled>--Seleccione una prioridad--</option>	 */}
                      <option selected value="Normal"> {t('formulario.select.normal')} </option>
                      <option value="Tutela"> {t('formulario.select.tutela')} </option>
                      <option value="Desacato"> {t('formulario.select.desacato')} </option>
                    </Form.Control>
                  </Form.Group>
                }

              </div>
              <br />
              <Form.Group className='formGroupTextArea'>
                <Form.Label className='observacionesLabel' style={{ marginLeft: '0px' }}>
                  {t('formulario.label.observaciones')}
                </Form.Label>
                <Form.Control
                  className='textAreaGeneral'
                  name='observacion'
                  id='observacionesTextArea'
                  as='textarea'
                  placeholder={t('formulario.label.observaciones')}
                  onChange={(e) => handleChange(e)}
                  disabled={!ele.editar}
                  defaultValue={ele.valuesInputs.observaciones}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
              </Form.Group>
              <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                {ele.valuesInputs.prioridad.visualizar === true && changeForm.aprobacion !== ''
                  ?
                  <button type="submit" className='botonAddBeneficiario confirmButton'>
                    {t('botones.guardar')}
                  </button>
                  : ele.valuesInputs.prioridad.visualizar === false && changeForm.aprobacion !== ''
                    ?
                    <button type="submit" className='botonAddBeneficiario confirmButton'>
                      {t('botones.guardar')}
                    </button>
                    :
                    <button disabled className='botonAddBeneficiario botonDeshabilitado'>
                      {t('formulario.label.aprobacion_diligencie_campos')}
                    </button>
                }

              </div>

            </Form>
          </div>
        </Card.Body>
      </Card>
      <NotificationModal
        typeModal={tipoNotificacion.tipo}
        isOpen={modal.show}
        modal={modal}
        setIsOpen={setModal}
        recargar={recargarGestor}
        mensaje={tipoNotificacion.mensaje}
      />
    </>
  )
}
