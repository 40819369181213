const deptos = {
  "data": [
  {
    "value": "Amazonas - El encanto",
    "label": "Amazonas - El encanto"
  },
  {
    "value": "Amazonas - La chorrera",
    "label": "Amazonas - La chorrera"
  },
  {
    "value": "Amazonas - La pedrera",
    "label": "Amazonas - La pedrera"
  },
  {
    "value": "Amazonas - La victoria",
    "label": "Amazonas - La victoria"
  },
  {
    "value": "Amazonas - Leticia",
    "label": "Amazonas - Leticia"
  },
  {
    "value": "Amazonas - Miriti",
    "label": "Amazonas - Miriti"
  },
  {
    "value": "Amazonas - Puerto alegria",
    "label": "Amazonas - Puerto alegria"
  },
  {
    "value": "Amazonas - Puerto arica",
    "label": "Amazonas - Puerto arica"
  },
  {
    "value": "Amazonas - Puerto nariño",
    "label": "Amazonas - Puerto nariño"
  },
  {
    "value": "Amazonas - Puerto santander",
    "label": "Amazonas - Puerto santander"
  },
  {
    "value": "Amazonas - Turapaca",
    "label": "Amazonas - Turapaca"
  },
  {
    "value": "Antioquia - Abejorral",
    "label": "Antioquia - Abejorral"
  },
  {
    "value": "Antioquia - Abriaqui",
    "label": "Antioquia - Abriaqui"
  },
  {
    "value": "Antioquia - Alejandria",
    "label": "Antioquia - Alejandria"
  },
  {
    "value": "Antioquia - Amaga",
    "label": "Antioquia - Amaga"
  },
  {
    "value": "Antioquia - Amalfi",
    "label": "Antioquia - Amalfi"
  },
  {
    "value": "Antioquia - Andes",
    "label": "Antioquia - Andes"
  },
  {
    "value": "Antioquia - Angelopolis",
    "label": "Antioquia - Angelopolis"
  },
  {
    "value": "Antioquia - Angostura",
    "label": "Antioquia - Angostura"
  },
  {
    "value": "Antioquia - Anori",
    "label": "Antioquia - Anori"
  },
  {
    "value": "Antioquia - Antioquia",
    "label": "Antioquia - Antioquia"
  },
  {
    "value": "Antioquia - Anza",
    "label": "Antioquia - Anza"
  },
  {
    "value": "Antioquia - Apartado",
    "label": "Antioquia - Apartado"
  },
  {
    "value": "Antioquia - Arboletes",
    "label": "Antioquia - Arboletes"
  },
  {
    "value": "Antioquia - Argelia",
    "label": "Antioquia - Argelia"
  },
  {
    "value": "Antioquia - Armenia",
    "label": "Antioquia - Armenia"
  },
  {
    "value": "Antioquia - Barbosa",
    "label": "Antioquia - Barbosa"
  },
  {
    "value": "Antioquia - Bello",
    "label": "Antioquia - Bello"
  },
  {
    "value": "Antioquia - Belmira",
    "label": "Antioquia - Belmira"
  },
  {
    "value": "Antioquia - Betania",
    "label": "Antioquia - Betania"
  },
  {
    "value": "Antioquia - Betulia",
    "label": "Antioquia - Betulia"
  },
  {
    "value": "Antioquia - Bolivar",
    "label": "Antioquia - Bolivar"
  },
  {
    "value": "Antioquia - Briceño",
    "label": "Antioquia - Briceño"
  },
  {
    "value": "Antioquia - Buritica",
    "label": "Antioquia - Buritica"
  },
  {
    "value": "Antioquia - Caceres",
    "label": "Antioquia - Caceres"
  },
  {
    "value": "Antioquia - Caicedo",
    "label": "Antioquia - Caicedo"
  },
  {
    "value": "Antioquia - Caldas",
    "label": "Antioquia - Caldas"
  },
  {
    "value": "Antioquia - Campamento",
    "label": "Antioquia - Campamento"
  },
  {
    "value": "Antioquia - Canasgordas",
    "label": "Antioquia - Canasgordas"
  },
  {
    "value": "Antioquia - Caracoli",
    "label": "Antioquia - Caracoli"
  },
  {
    "value": "Antioquia - Caramanta",
    "label": "Antioquia - Caramanta"
  },
  {
    "value": "Antioquia - Carepa",
    "label": "Antioquia - Carepa"
  },
  {
    "value": "Antioquia - Carmen de viboral",
    "label": "Antioquia - Carmen de viboral"
  },
  {
    "value": "Antioquia - Carolina del principe",
    "label": "Antioquia - Carolina del principe"
  },
  {
    "value": "Antioquia - Caucasia",
    "label": "Antioquia - Caucasia"
  },
  {
    "value": "Antioquia - Chigorodo",
    "label": "Antioquia - Chigorodo"
  },
  {
    "value": "Antioquia - Cisneros",
    "label": "Antioquia - Cisneros"
  },
  {
    "value": "Antioquia - Cocorna",
    "label": "Antioquia - Cocorna"
  },
  {
    "value": "Antioquia - Concepcion",
    "label": "Antioquia - Concepcion"
  },
  {
    "value": "Antioquia - Concordia",
    "label": "Antioquia - Concordia"
  },
  {
    "value": "Antioquia - Copacabana",
    "label": "Antioquia - Copacabana"
  },
  {
    "value": "Antioquia - Dabeiba",
    "label": "Antioquia - Dabeiba"
  },
  {
    "value": "Antioquia - Donmatias",
    "label": "Antioquia - Donmatias"
  },
  {
    "value": "Antioquia - Ebejico",
    "label": "Antioquia - Ebejico"
  },
  {
    "value": "Antioquia - El bagre",
    "label": "Antioquia - El bagre"
  },
  {
    "value": "Antioquia - El peñol",
    "label": "Antioquia - El peñol"
  },
  {
    "value": "Antioquia - El retiro",
    "label": "Antioquia - El retiro"
  },
  {
    "value": "Antioquia - Entrerrios",
    "label": "Antioquia - Entrerrios"
  },
  {
    "value": "Antioquia - Envigado",
    "label": "Antioquia - Envigado"
  },
  {
    "value": "Antioquia - Fredonia",
    "label": "Antioquia - Fredonia"
  },
  {
    "value": "Antioquia - Frontino",
    "label": "Antioquia - Frontino"
  },
  {
    "value": "Antioquia - Giraldo",
    "label": "Antioquia - Giraldo"
  },
  {
    "value": "Antioquia - Girardota",
    "label": "Antioquia - Girardota"
  },
  {
    "value": "Antioquia - Gomez plata",
    "label": "Antioquia - Gomez plata"
  },
  {
    "value": "Antioquia - Granada",
    "label": "Antioquia - Granada"
  },
  {
    "value": "Antioquia - Guadalupe",
    "label": "Antioquia - Guadalupe"
  },
  {
    "value": "Antioquia - Guarne",
    "label": "Antioquia - Guarne"
  },
  {
    "value": "Antioquia - Guataque",
    "label": "Antioquia - Guataque"
  },
  {
    "value": "Antioquia - Heliconia",
    "label": "Antioquia - Heliconia"
  },
  {
    "value": "Antioquia - Hispania",
    "label": "Antioquia - Hispania"
  },
  {
    "value": "Antioquia - Itagui",
    "label": "Antioquia - Itagui"
  },
  {
    "value": "Antioquia - Ituango",
    "label": "Antioquia - Ituango"
  },
  {
    "value": "Antioquia - Jardin",
    "label": "Antioquia - Jardin"
  },
  {
    "value": "Antioquia - Jerico",
    "label": "Antioquia - Jerico"
  },
  {
    "value": "Antioquia - La ceja",
    "label": "Antioquia - La ceja"
  },
  {
    "value": "Antioquia - La estrella",
    "label": "Antioquia - La estrella"
  },
  {
    "value": "Antioquia - La pintada",
    "label": "Antioquia - La pintada"
  },
  {
    "value": "Antioquia - La union",
    "label": "Antioquia - La union"
  },
  {
    "value": "Antioquia - Liborina",
    "label": "Antioquia - Liborina"
  },
  {
    "value": "Antioquia - Maceo",
    "label": "Antioquia - Maceo"
  },
  {
    "value": "Antioquia - Marinilla",
    "label": "Antioquia - Marinilla"
  },
  {
    "value": "Antioquia - Medellin",
    "label": "Antioquia - Medellin"
  },
  {
    "value": "Antioquia - Montebello",
    "label": "Antioquia - Montebello"
  },
  {
    "value": "Antioquia - Murindo",
    "label": "Antioquia - Murindo"
  },
  {
    "value": "Antioquia - Mutata",
    "label": "Antioquia - Mutata"
  },
  {
    "value": "Antioquia - Narino",
    "label": "Antioquia - Narino"
  },
  {
    "value": "Antioquia - Nechi",
    "label": "Antioquia - Nechi"
  },
  {
    "value": "Antioquia - Necocli",
    "label": "Antioquia - Necocli"
  },
  {
    "value": "Antioquia - Olaya",
    "label": "Antioquia - Olaya"
  },
  {
    "value": "Antioquia - Peque",
    "label": "Antioquia - Peque"
  },
  {
    "value": "Antioquia - Pueblorrico",
    "label": "Antioquia - Pueblorrico"
  },
  {
    "value": "Antioquia - Puerto berrio",
    "label": "Antioquia - Puerto berrio"
  },
  {
    "value": "Antioquia - Puerto nare",
    "label": "Antioquia - Puerto nare"
  },
  {
    "value": "Antioquia - Puerto triunfo",
    "label": "Antioquia - Puerto triunfo"
  },
  {
    "value": "Antioquia - Remedios",
    "label": "Antioquia - Remedios"
  },
  {
    "value": "Antioquia - Rionegro",
    "label": "Antioquia - Rionegro"
  },
  {
    "value": "Antioquia - Sabanalarga",
    "label": "Antioquia - Sabanalarga"
  },
  {
    "value": "Antioquia - Sabaneta",
    "label": "Antioquia - Sabaneta"
  },
  {
    "value": "Antioquia - Salgar",
    "label": "Antioquia - Salgar"
  },
  {
    "value": "Antioquia - San andres de cuerquia",
    "label": "Antioquia - San andres de cuerquia"
  },
  {
    "value": "Antioquia - San carlos",
    "label": "Antioquia - San carlos"
  },
  {
    "value": "Antioquia - San francisco",
    "label": "Antioquia - San francisco"
  },
  {
    "value": "Antioquia - San jeronimo",
    "label": "Antioquia - San jeronimo"
  },
  {
    "value": "Antioquia - San jose de la montaña",
    "label": "Antioquia - San jose de la montaña"
  },
  {
    "value": "Antioquia - San juan de uraba",
    "label": "Antioquia - San juan de uraba"
  },
  {
    "value": "Antioquia - San luis",
    "label": "Antioquia - San luis"
  },
  {
    "value": "Antioquia - San pedro de los milagros",
    "label": "Antioquia - San pedro de los milagros"
  },
  {
    "value": "Antioquia - San pedro de uraba",
    "label": "Antioquia - San pedro de uraba"
  },
  {
    "value": "Antioquia - San rafael",
    "label": "Antioquia - San rafael"
  },
  {
    "value": "Antioquia - San roque",
    "label": "Antioquia - San roque"
  },
  {
    "value": "Antioquia - San vicente",
    "label": "Antioquia - San vicente"
  },
  {
    "value": "Antioquia - Santa barbara",
    "label": "Antioquia - Santa barbara"
  },
  {
    "value": "Antioquia - Santa fe de antioquia",
    "label": "Antioquia - Santa fe de antioquia"
  },
  {
    "value": "Antioquia - Santa rosa de osos",
    "label": "Antioquia - Santa rosa de osos"
  },
  {
    "value": "Antioquia - Santo domingo",
    "label": "Antioquia - Santo domingo"
  },

  {
    "value": "Antioquia - Santuario",
    "label": "Antioquia - Santuario"
  },
  {
    "value": "Antioquia - Segovia",
    "label": "Antioquia - Segovia"
  },
  {
    "value": "Antioquia - Sonson",
    "label": "Antioquia - Sonson"
  },
  {
    "value": "Antioquia - Sopetran",
    "label": "Antioquia - Sopetran"
  },
  {
    "value": "Antioquia - Tamesis",
    "label": "Antioquia - Tamesis"
  },
  {
    "value": "Antioquia - Taraza",
    "label": "Antioquia - Taraza"
  },
  {
    "value": "Antioquia - Tarso",
    "label": "Antioquia - Tarso"
  },
  {
    "value": "Antioquia - Titiribi",
    "label": "Antioquia - Titiribi"
  },
  {
    "value": "Antioquia - Toledo",
    "label": "Antioquia - Toledo"
  },
  {
    "value": "Antioquia - Turbo",
    "label": "Antioquia - Turbo"
  },
  {
    "value": "Antioquia - Uramita",
    "label": "Antioquia - Uramita"
  },
  {
    "value": "Antioquia - Urrao",
    "label": "Antioquia - Urrao"
  },
  {
    "value": "Antioquia - Valdivia",
    "label": "Antioquia - Valdivia"
  },
  {
    "value": "Antioquia - Valparaiso",
    "label": "Antioquia - Valparaiso"
  },
  {
    "value": "Antioquia - Vegachi",
    "label": "Antioquia - Vegachi"
  },
  {
    "value": "Antioquia - Venecia",
    "label": "Antioquia - Venecia"
  },
  {
    "value": "Antioquia - Vigia del fuerte",
    "label": "Antioquia - Vigia del fuerte"
  },
  {
    "value": "Antioquia - Yali",
    "label": "Antioquia - Yali"
  },
  {
    "value": "Antioquia - Yarumal",
    "label": "Antioquia - Yarumal"
  },
  {
    "value": "Antioquia - Yolombo",
    "label": "Antioquia - Yolombo"
  },
  {
    "value": "Antioquia - Yondo",
    "label": "Antioquia - Yondo"
  },
  {
    "value": "Antioquia - Zaragoza",
    "label": "Antioquia - Zaragoza"
  },
  {
    "value": "Arauca - Arauca",
    "label": "Arauca - Arauca"
  },
  {
    "value": "Arauca - Arauquita",
    "label": "Arauca - Arauquita"
  },
  {
    "value": "Arauca - Cravo norte",
    "label": "Arauca - Cravo norte"
  },
  {
    "value": "Arauca - Fortul",
    "label": "Arauca - Fortul"
  },
  {
    "value": "Arauca - Puerto rondon",
    "label": "Arauca - Puerto rondon"
  },
  {
    "value": "Arauca - Saravena",
    "label": "Arauca - Saravena"
  },
  {
    "value": "Arauca - Tame",
    "label": "Arauca - Tame"
  },
  {
    "value": "Atlántico - Baranoa",
    "label": "Atlántico - Baranoa"
  },
  {
    "value": "Atlántico - Barranquilla",
    "label": "Atlántico - Barranquilla"
  },
  {
    "value": "Atlántico - Campo de la cruz",
    "label": "Atlántico - Campo de la cruz"
  },
  {
    "value": "Atlántico - Candelaria",
    "label": "Atlántico - Candelaria"
  },
  {
    "value": "Atlántico - Galapa",
    "label": "Atlántico - Galapa"
  },
  {
    "value": "Atlántico - Juan de acosta",
    "label": "Atlántico - Juan de acosta"
  },
  {
    "value": "Atlántico - Luruaco",
    "label": "Atlántico - Luruaco"
  },
  {
    "value": "Atlántico - Malambo",
    "label": "Atlántico - Malambo"
  },
  {
    "value": "Atlántico - Manati",
    "label": "Atlántico - Manati"
  },
  {
    "value": "Atlántico - Palmar de varela",
    "label": "Atlántico - Palmar de varela"
  },
  {
    "value": "Atlántico - Piojo",
    "label": "Atlántico - Piojo"
  },
  {
    "value": "Atlántico - Polo nuevo",
    "label": "Atlántico - Polo nuevo"
  },
  {
    "value": "Atlántico - Ponedera",
    "label": "Atlántico - Ponedera"
  },
  {
    "value": "Atlántico - Puerto colombia",
    "label": "Atlántico - Puerto colombia"
  },
  {
    "value": "Atlántico - Repelon",
    "label": "Atlántico - Repelon"
  },
  {
    "value": "Atlántico - Sabanagrande",
    "label": "Atlántico - Sabanagrande"
  },
  {
    "value": "Atlántico - Sabanalarga",
    "label": "Atlántico - Sabanalarga"
  },
  {
    "value": "Atlántico - Santa lucia",
    "label": "Atlántico - Santa lucia"
  },
  {
    "value": "Atlántico - Santo tomas",
    "label": "Atlántico - Santo tomas"
  },
  {
    "value": "Atlántico - Soledad",
    "label": "Atlántico - Soledad"
  },
  {
    "value": "Atlántico - Suan",
    "label": "Atlántico - Suan"
  },
  {
    "value": "Atlántico - Tubara",
    "label": "Atlántico - Tubara"
  },
  {
    "value": "Atlántico - Usiacuri",
    "label": "Atlántico - Usiacuri"
  },
  {
    "value": "Bolívar - Achi",
    "label": "Bolívar - Achi"
  },
  {
    "value": "Bolívar - Altos del rosario",
    "label": "Bolívar - Altos del rosario"
  },
  {
    "value": "Bolívar - Arenal",
    "label": "Bolívar - Arenal"
  },
  {
    "value": "Bolívar - Arjona",
    "label": "Bolívar - Arjona"
  },
  {
    "value": "Bolívar - Arroyohondo",
    "label": "Bolívar - Arroyohondo"
  },
  {
    "value": "Bolívar - Barranco de loba",
    "label": "Bolívar - Barranco de loba"
  },
  {
    "value": "Bolívar - Brazuelo de papayal",
    "label": "Bolívar - Brazuelo de papayal"
  },
  {
    "value": "Bolívar - Calamar",
    "label": "Bolívar - Calamar"
  },
  {
    "value": "Bolívar - Cantagallo",
    "label": "Bolívar - Cantagallo"
  },
  {
    "value": "Bolívar - Cartagena de Indias",
    "label": "Bolívar - Cartagena de Indias"
  },
  {
    "value": "Bolívar - Cicuco",
    "label": "Bolívar - Cicuco"
  },
  {
    "value": "Bolívar - Clemencia",
    "label": "Bolívar - Clemencia"
  },
  {
    "value": "Bolívar - Cordoba",
    "label": "Bolívar - Cordoba"
  },
  {
    "value": "Bolívar - El carmen de bolivar",
    "label": "Bolívar - El carmen de bolivar"
  },
  {
    "value": "Bolívar - El guamo",
    "label": "Bolívar - El guamo"
  },
  {
    "value": "Bolívar - El peñón",
    "label": "Bolívar - El peñón"
  },
  {
    "value": "Bolívar - Hatillo de loba",
    "label": "Bolívar - Hatillo de loba"
  },
  {
    "value": "Bolívar - Magangue",
    "label": "Bolívar - Magangue"
  },
  {
    "value": "Bolívar - Mahates",
    "label": "Bolívar - Mahates"
  },
  {
    "value": "Bolívar - Margarita",
    "label": "Bolívar - Margarita"
  },
  {
    "value": "Bolívar - Maria la baja",
    "label": "Bolívar - Maria la baja"
  },
  {
    "value": "Bolívar - Montecristo",
    "label": "Bolívar - Montecristo"
  },
  {
    "value": "Bolívar - Morales",
    "label": "Bolívar - Morales"
  },
  {
    "value": "Bolívar - Morales",
    "label": "Bolívar - Morales"
  },
  {
    "value": "Bolívar - Norosi",
    "label": "Bolívar - Norosi"
  },
  {
    "value": "Bolívar - Pinillos",
    "label": "Bolívar - Pinillos"
  },
  {
    "value": "Bolívar - Regidor",
    "label": "Bolívar - Regidor"
  },
  {
    "value": "Bolívar - Rio viejo",
    "label": "Bolívar - Rio viejo"
  },
  {
    "value": "Bolívar - San cristobal",
    "label": "Bolívar - San cristobal"
  },
  {
    "value": "Bolívar - San estanislao",
    "label": "Bolívar - San estanislao"
  },
  {
    "value": "Bolívar - San fernando",
    "label": "Bolívar - San fernando"
  },
  {
    "value": "Bolívar - San jacinto",
    "label": "Bolívar - San jacinto"
  },
  {
    "value": "Bolívar - San jacinto del cauca",
    "label": "Bolívar - San jacinto del cauca"
  },
  {
    "value": "Bolívar - San juan de nepomuceno",
    "label": "Bolívar - San juan de nepomuceno"
  },
  {
    "value": "Bolívar - San Marcos de Malagana",
    "label": "Bolívar - San Marcos de Malagana"
  },
  {
    "value": "Bolívar - San martin de loba",
    "label": "Bolívar - San martin de loba"
  },
  {
    "value": "Bolívar - San pablo",
    "label": "Bolívar - San pablo"
  },
  {
    "value": "Bolívar - San pablo norte",
    "label": "Bolívar - San pablo norte"
  },
  {
    "value": "Bolívar - Santa catalina",
    "label": "Bolívar - Santa catalina"
  },
  {
    "value": "Bolívar - Santa cruz de mompox",
    "label": "Bolívar - Santa cruz de mompox"
  },
  {
    "value": "Bolívar - Santa rosa",
    "label": "Bolívar - Santa rosa"
  },
  {
    "value": "Bolívar - Santa rosa del sur",
    "label": "Bolívar - Santa rosa del sur"
  },
  {
    "value": "Bolívar - Simiti",
    "label": "Bolívar - Simiti"
  },
  {
    "value": "Bolívar - Soplaviento",
    "label": "Bolívar - Soplaviento"
  },
  {
    "value": "Bolívar - Talaigua nuevo",
    "label": "Bolívar - Talaigua nuevo"
  },
  {
    "value": "Bolívar - Tuquisio",
    "label": "Bolívar - Tuquisio"
  },
  {
    "value": "Bolívar - Turbaco",
    "label": "Bolívar - Turbaco"
  },
  {
    "value": "Bolívar - Turbana",
    "label": "Bolívar - Turbana"
  },
  {
    "value": "Bolívar - Villanueva",
    "label": "Bolívar - Villanueva"
  },
  {
    "value": "Bolívar - Zambrano",
    "label": "Bolívar - Zambrano"
  },
  {
    "value": "Boyacá - Aquitania",
    "label": "Boyacá - Aquitania"
  },
  {
    "value": "Boyacá - Arcabuco",
    "label": "Boyacá - Arcabuco"
  },
  {
    "value": "Boyacá - Belén",
    "label": "Boyacá - Belén"
  },
  {
    "value": "Boyacá - Berbeo",
    "label": "Boyacá - Berbeo"
  },
  {
    "value": "Boyacá - Betéitiva",
    "label": "Boyacá - Betéitiva"
  },
  {
    "value": "Boyacá - Boavita",
    "label": "Boyacá - Boavita"
  },
  {
    "value": "Boyacá - Boyacá",
    "label": "Boyacá - Boyacá"
  },
  {
    "value": "Boyacá - Briceño",
    "label": "Boyacá - Briceño"
  },
  {
    "value": "Boyacá - Buenavista",
    "label": "Boyacá - Buenavista"
  },
  {
    "value": "Boyacá - Busbanzá",
    "label": "Boyacá - Busbanzá"
  },
  {
    "value": "Boyacá - Caldas",
    "label": "Boyacá - Caldas"
  },
  {
    "value": "Boyacá - Campo hermoso",
    "label": "Boyacá - Campo hermoso"
  },
  {
    "value": "Boyacá - Cerinza",
    "label": "Boyacá - Cerinza"
  },
  {
    "value": "Boyacá - Chinavita",
    "label": "Boyacá - Chinavita"
  },
  {
    "value": "Boyacá - Chiquinquirá",
    "label": "Boyacá - Chiquinquirá"
  },
  {
    "value": "Boyacá - Chíquiza",
    "label": "Boyacá - Chíquiza"
  },
  {
    "value": "Boyacá - Chiscas",
    "label": "Boyacá - Chiscas"
  },
  {
    "value": "Boyacá - Chita",
    "label": "Boyacá - Chita"
  },
  {
    "value": "Boyacá - Chitaraque",
    "label": "Boyacá - Chitaraque"
  },
  {
    "value": "Boyacá - Chivatá",
    "label": "Boyacá - Chivatá"
  },
  {
    "value": "Boyacá - Chivor",
    "label": "Boyacá - Chivor"
  },
  {
    "value": "Boyacá - Ciénega",
    "label": "Boyacá - Ciénega"
  },
  {
    "value": "Boyacá - Cómbita",
    "label": "Boyacá - Cómbita"
  },
  {
    "value": "Boyacá - Coper",
    "label": "Boyacá - Coper"
  },
  {
    "value": "Boyacá - Corrales",
    "label": "Boyacá - Corrales"
  },
  {
    "value": "Boyacá - Covarachía",
    "label": "Boyacá - Covarachía"
  },
  {
    "value": "Boyacá - Cubara",
    "label": "Boyacá - Cubara"
  },
  {
    "value": "Boyacá - Cucaita",
    "label": "Boyacá - Cucaita"
  },
  {
    "value": "Boyacá - Cuitiva",
    "label": "Boyacá - Cuitiva"
  },
  {
    "value": "Boyacá - Duitama",
    "label": "Boyacá - Duitama"
  },
  {
    "value": "Boyacá - El cocuy",
    "label": "Boyacá - El cocuy"
  },
  {
    "value": "Boyacá - El espino",
    "label": "Boyacá - El espino"
  },
  {
    "value": "Boyacá - Firavitoba",
    "label": "Boyacá - Firavitoba"
  },
  {
    "value": "Boyacá - Floresta",
    "label": "Boyacá - Floresta"
  },
  {
    "value": "Boyacá - Gachantivá",
    "label": "Boyacá - Gachantivá"
  },
  {
    "value": "Boyacá - Gámeza",
    "label": "Boyacá - Gámeza"
  },
  {
    "value": "Boyacá - Garagoa",
    "label": "Boyacá - Garagoa"
  },
  {
    "value": "Boyacá - Guacamayas",
    "label": "Boyacá - Guacamayas"
  },
  {
    "value": "Boyacá - Guateque",
    "label": "Boyacá - Guateque"
  },
  {
    "value": "Boyacá - Güicán",
    "label": "Boyacá - Güicán"
  },
  {
    "value": "Boyacá - Iza",
    "label": "Boyacá - Iza"
  },
  {
    "value": "Boyacá - Jenesano",
    "label": "Boyacá - Jenesano"
  },
  {
    "value": "Boyacá - Jericó",
    "label": "Boyacá - Jericó"
  },
  {
    "value": "Boyacá - La uvita",
    "label": "Boyacá - La uvita"
  },
  {
    "value": "Boyacá - La victoria",
    "label": "Boyacá - La victoria"
  },
  {
    "value": "Boyacá - Labranza grande",
    "label": "Boyacá - Labranza grande"
  },
  {
    "value": "Boyacá - Macanal",
    "label": "Boyacá - Macanal"
  },
  {
    "value": "Boyacá - Maripí",
    "label": "Boyacá - Maripí"
  },
  {
    "value": "Boyacá - Miraflores",
    "label": "Boyacá - Miraflores"
  },
  {
    "value": "Boyacá - Mongua",
    "label": "Boyacá - Mongua"
  },
  {
    "value": "Boyacá - Monguí",
    "label": "Boyacá - Monguí"
  },
  {
    "value": "Boyacá - Moniquirá",
    "label": "Boyacá - Moniquirá"
  },
  {
    "value": "Boyacá - Motavita",
    "label": "Boyacá - Motavita"
  },
  {
    "value": "Boyacá - Muzo",
    "label": "Boyacá - Muzo"
  },
  {
    "value": "Boyacá - Nobsa",
    "label": "Boyacá - Nobsa"
  },
  {
    "value": "Boyacá - Nuevo colón",
    "label": "Boyacá - Nuevo colón"
  },
  {
    "value": "Boyacá - Oicatá",
    "label": "Boyacá - Oicatá"
  },
  {
    "value": "Boyacá - Otanche",
    "label": "Boyacá - Otanche"
  },
  {
    "value": "Boyacá - Pachavita",
    "label": "Boyacá - Pachavita"
  },
  {
    "value": "Boyacá - Páez",
    "label": "Boyacá - Páez"
  },
  {
    "value": "Boyacá - Paipa",
    "label": "Boyacá - Paipa"
  },
  {
    "value": "Boyacá - Pajarito",
    "label": "Boyacá - Pajarito"
  },
  {
    "value": "Boyacá - Panqueba",
    "label": "Boyacá - Panqueba"
  },
  {
    "value": "Boyacá - Pauna",
    "label": "Boyacá - Pauna"
  },
  {
    "value": "Boyacá - Paya",
    "label": "Boyacá - Paya"
  },
  {
    "value": "Boyacá - Paz de río",
    "label": "Boyacá - Paz de río"
  },
  {
    "value": "Boyacá - Pesca",
    "label": "Boyacá - Pesca"
  },
  {
    "value": "Boyacá - Pisba",
    "label": "Boyacá - Pisba"
  },
  {
    "value": "Boyacá - Puerto boyaca",
    "label": "Boyacá - Puerto boyaca"
  },
  {
    "value": "Boyacá - Quípama",
    "label": "Boyacá - Quípama"
  },
  {
    "value": "Boyacá - Ramiriquí",
    "label": "Boyacá - Ramiriquí"
  },
  {
    "value": "Boyacá - Ráquira",
    "label": "Boyacá - Ráquira"
  },
  {
    "value": "Boyacá - Rondón",
    "label": "Boyacá - Rondón"
  },
  {
    "value": "Boyacá - Saboyá",
    "label": "Boyacá - Saboyá"
  },
  {
    "value": "Boyacá - Sáchica",
    "label": "Boyacá - Sáchica"
  },
  {
    "value": "Boyacá - Samacá",
    "label": "Boyacá - Samacá"
  },
  {
    "value": "Boyacá - San eduardo",
    "label": "Boyacá - San eduardo"
  },
  {
    "value": "Boyacá - San josé de pare",
    "label": "Boyacá - San josé de pare"
  },
  {
    "value": "Boyacá - San luís de gaceno",
    "label": "Boyacá - San luís de gaceno"
  },
  {
    "value": "Boyacá - San mateo",
    "label": "Boyacá - San mateo"
  },
  {
    "value": "Boyacá - San miguel de sema",
    "label": "Boyacá - San miguel de sema"
  },
  {
    "value": "Boyacá - San pablo de borbur",
    "label": "Boyacá - San pablo de borbur"
  },
  {
    "value": "Boyacá - Santa maría",
    "label": "Boyacá - Santa maría"
  },
  {
    "value": "Boyacá - Santa rosa de viterbo",
    "label": "Boyacá - Santa rosa de viterbo"
  },
  {
    "value": "Boyacá - Santa sofía",
    "label": "Boyacá - Santa sofía"
  },
  {
    "value": "Boyacá - Santana",
    "label": "Boyacá - Santana"
  },
  {
    "value": "Boyacá - Sativanorte",
    "label": "Boyacá - Sativanorte"
  },
  {
    "value": "Boyacá - Sativasur",
    "label": "Boyacá - Sativasur"
  },
  {
    "value": "Boyacá - Siachoque",
    "label": "Boyacá - Siachoque"
  },
  {
    "value": "Boyacá - Soatá",
    "label": "Boyacá - Soatá"
  },
  {
    "value": "Boyacá - Socha",
    "label": "Boyacá - Socha"
  },
  {
    "value": "Boyacá - Socotá",
    "label": "Boyacá - Socotá"
  },
  {
    "value": "Boyacá - Sogamoso",
    "label": "Boyacá - Sogamoso"
  },
  {
    "value": "Boyacá - Somondoco",
    "label": "Boyacá - Somondoco"
  },
  {
    "value": "Boyacá - Sora",
    "label": "Boyacá - Sora"
  },
  {
    "value": "Boyacá - Soracá",
    "label": "Boyacá - Soracá"
  },
  {
    "value": "Boyacá - Sotaquirá",
    "label": "Boyacá - Sotaquirá"
  },
  {
    "value": "Boyacá - Susacón",
    "label": "Boyacá - Susacón"
  },
  {
    "value": "Boyacá - Sutarmachán",
    "label": "Boyacá - Sutarmachán"
  },
  {
    "value": "Boyacá - Tasco",
    "label": "Boyacá - Tasco"
  },
  {
    "value": "Boyacá - Tenza",
    "label": "Boyacá - Tenza"
  },
  {
    "value": "Boyacá - Tibaná",
    "label": "Boyacá - Tibaná"
  },
  {
    "value": "Boyacá - Tibasosa",
    "label": "Boyacá - Tibasosa"
  },
  {
    "value": "Boyacá - Tinjacá",
    "label": "Boyacá - Tinjacá"
  },
  {
    "value": "Boyacá - Tipacoque",
    "label": "Boyacá - Tipacoque"
  },
  {
    "value": "Boyacá - Toca",
    "label": "Boyacá - Toca"
  },
  {
    "value": "Boyacá - Togüí",
    "label": "Boyacá - Togüí"
  },
  {
    "value": "Boyacá - Tópaga",
    "label": "Boyacá - Tópaga"
  },
  {
    "value": "Boyacá - Tota",
    "label": "Boyacá - Tota"
  },
  {
    "value": "Boyacá - Tunja",
    "label": "Boyacá - Tunja"
  },
  {
    "value": "Boyacá - Tununguá",
    "label": "Boyacá - Tununguá"
  },
  {
    "value": "Boyacá - Turmequé",
    "label": "Boyacá - Turmequé"
  },
  {
    "value": "Boyacá - Tuta",
    "label": "Boyacá - Tuta"
  },
  {
    "value": "Boyacá - Tutazá",
    "label": "Boyacá - Tutazá"
  },
  {
    "value": "Boyacá - Umbita",
    "label": "Boyacá - Umbita"
  },
  {
    "value": "Boyacá - Venta quemada",
    "label": "Boyacá - Venta quemada"
  },
  {
    "value": "Boyacá - Villa de leyva",
    "label": "Boyacá - Villa de leyva"
  },
  {
    "value": "Boyacá - Viracachá",
    "label": "Boyacá - Viracachá"
  },
  {
    "value": "Boyacá - Zetaquira",
    "label": "Boyacá - Zetaquira"
  },
  {
    "value": "Caldas - Aguadas",
    "label": "Caldas - Aguadas"
  },
  {
    "value": "Caldas - Anserma",
    "label": "Caldas - Anserma"
  },
  {
    "value": "Caldas - Aranzazu",
    "label": "Caldas - Aranzazu"
  },
  {
    "value": "Caldas - Belalcazar",
    "label": "Caldas - Belalcazar"
  },
  {
    "value": "Caldas - Chinchiná",
    "label": "Caldas - Chinchiná"
  },
  {
    "value": "Caldas - Filadelfia",
    "label": "Caldas - Filadelfia"
  },
  {
    "value": "Caldas - La dorada",
    "label": "Caldas - La dorada"
  },
  {
    "value": "Caldas - La merced",
    "label": "Caldas - La merced"
  },
  {
    "value": "Caldas - Manizales",
    "label": "Caldas - Manizales"
  },
  {
    "value": "Caldas - Manzanares",
    "label": "Caldas - Manzanares"
  },
  {
    "value": "Caldas - Marmato",
    "label": "Caldas - Marmato"
  },
  {
    "value": "Caldas - Marquetalia",
    "label": "Caldas - Marquetalia"
  },
  {
    "value": "Caldas - Marulanda",
    "label": "Caldas - Marulanda"
  },
  {
    "value": "Caldas - Neira",
    "label": "Caldas - Neira"
  },
  {
    "value": "Caldas - Norcasia",
    "label": "Caldas - Norcasia"
  },
  {
    "value": "Caldas - Pacora",
    "label": "Caldas - Pacora"
  },
  {
    "value": "Caldas - Palestina",
    "label": "Caldas - Palestina"
  },
  {
    "value": "Caldas - Pensilvania",
    "label": "Caldas - Pensilvania"
  },
  {
    "value": "Caldas - Riosucio",
    "label": "Caldas - Riosucio"
  },
  {
    "value": "Caldas - Risaralda",
    "label": "Caldas - Risaralda"
  },
  {
    "value": "Caldas - Salamina",
    "label": "Caldas - Salamina"
  },
  {
    "value": "Caldas - Samana",
    "label": "Caldas - Samana"
  },
  {
    "value": "Caldas - San jose",
    "label": "Caldas - San jose"
  },
  {
    "value": "Caldas - Supía",
    "label": "Caldas - Supía"
  },
  {
    "value": "Caldas - Victoria",
    "label": "Caldas - Victoria"
  },
  {
    "value": "Caldas - Villamaría",
    "label": "Caldas - Villamaría"
  },
  {
    "value": "Caldas - Viterbo",
    "label": "Caldas - Viterbo"
  },
  {
    "value": "Caquetá - Albania",
    "label": "Caquetá - Albania"
  },
  {
    "value": "Caquetá - Belén andaquies",
    "label": "Caquetá - Belén andaquies"
  },
  {
    "value": "Caquetá - Cartagena del chaira",
    "label": "Caquetá - Cartagena del chaira"
  },
  {
    "value": "Caquetá - Curillo",
    "label": "Caquetá - Curillo"
  },
  {
    "value": "Caquetá - El doncello",
    "label": "Caquetá - El doncello"
  },
  {
    "value": "Caquetá - El paujil",
    "label": "Caquetá - El paujil"
  },
  {
    "value": "Caquetá - Florencia",
    "label": "Caquetá - Florencia"
  },
  {
    "value": "Caquetá - La montañita",
    "label": "Caquetá - La montañita"
  },
  {
    "value": "Caquetá - Milán",
    "label": "Caquetá - Milán"
  },
  {
    "value": "Caquetá - Morelia",
    "label": "Caquetá - Morelia"
  },
  {
    "value": "Caquetá - Puerto rico",
    "label": "Caquetá - Puerto rico"
  },
  {
    "value": "Caquetá - San  vicente del caguan",
    "label": "Caquetá - San  vicente del caguan"
  },
  {
    "value": "Caquetá - San josé de fragua",
    "label": "Caquetá - San josé de fragua"
  },
  {
    "value": "Caquetá - Solano",
    "label": "Caquetá - Solano"
  },
  {
    "value": "Caquetá - Solita",
    "label": "Caquetá - Solita"
  },
  {
    "value": "Caquetá - Valparaíso",
    "label": "Caquetá - Valparaíso"
  },
  {
    "value": "Casanare - Aguazul",
    "label": "Casanare - Aguazul"
  },
  {
    "value": "Casanare - Chameza",
    "label": "Casanare - Chameza"
  },
  {
    "value": "Casanare - Hato corozal",
    "label": "Casanare - Hato corozal"
  },
  {
    "value": "Casanare - La salina",
    "label": "Casanare - La salina"
  },
  {
    "value": "Casanare - Maní",
    "label": "Casanare - Maní"
  },
  {
    "value": "Casanare - Monterrey",
    "label": "Casanare - Monterrey"
  },
  {
    "value": "Casanare - Nunchia",
    "label": "Casanare - Nunchia"
  },
  {
    "value": "Casanare - Orocue",
    "label": "Casanare - Orocue"
  },
  {
    "value": "Casanare - Paz de ariporo",
    "label": "Casanare - Paz de ariporo"
  },
  {
    "value": "Casanare - Pore",
    "label": "Casanare - Pore"
  },
  {
    "value": "Casanare - Recetor",
    "label": "Casanare - Recetor"
  },
  {
    "value": "Casanare - Sabana larga",
    "label": "Casanare - Sabana larga"
  },
  {
    "value": "Casanare - Sacama",
    "label": "Casanare - Sacama"
  },
  {
    "value": "Casanare - San luis de palenque",
    "label": "Casanare - San luis de palenque"
  },
  {
    "value": "Casanare - Tamara",
    "label": "Casanare - Tamara"
  },
  {
    "value": "Casanare - Tauramena",
    "label": "Casanare - Tauramena"
  },
  {
    "value": "Casanare - Trinidad",
    "label": "Casanare - Trinidad"
  },
  {
    "value": "Casanare - Villanueva",
    "label": "Casanare - Villanueva"
  },
  {
    "value": "Casanare - Yopal",
    "label": "Casanare - Yopal"
  },
  {
    "value": "Cauca - Almaguer",
    "label": "Cauca - Almaguer"
  },
  {
    "value": "Cauca - Argelia",
    "label": "Cauca - Argelia"
  },
  {
    "value": "Cauca - Balboa",
    "label": "Cauca - Balboa"
  },
  {
    "value": "Cauca - Bolívar",
    "label": "Cauca - Bolívar"
  },
  {
    "value": "Cauca - Buenos aires",
    "label": "Cauca - Buenos aires"
  },
  {
    "value": "Cauca - Cajibio",
    "label": "Cauca - Cajibio"
  },
  {
    "value": "Cauca - Caldono",
    "label": "Cauca - Caldono"
  },
  {
    "value": "Cauca - Caloto",
    "label": "Cauca - Caloto"
  },
  {
    "value": "Cauca - Corinto",
    "label": "Cauca - Corinto"
  },
  {
    "value": "Cauca - El tambo",
    "label": "Cauca - El tambo"
  },
  {
    "value": "Cauca - Florencia",
    "label": "Cauca - Florencia"
  },
  {
    "value": "Cauca - Guachené",
    "label": "Cauca - Guachené"
  },
  {
    "value": "Cauca - Guapi",
    "label": "Cauca - Guapi"
  },
  {
    "value": "Cauca - Inza",
    "label": "Cauca - Inza"
  },
  {
    "value": "Cauca - Jambaló",
    "label": "Cauca - Jambaló"
  },
  {
    "value": "Cauca - La sierra",
    "label": "Cauca - La sierra"
  },
  {
    "value": "Cauca - La vega",
    "label": "Cauca - La vega"
  },
  {
    "value": "Cauca - López",
    "label": "Cauca - López"
  },
  {
    "value": "Cauca - Mercaderes",
    "label": "Cauca - Mercaderes"
  },
  {
    "value": "Cauca - Miranda",
    "label": "Cauca - Miranda"
  },
  {
    "value": "Cauca - Morales",
    "label": "Cauca - Morales"
  },
  {
    "value": "Cauca - Padilla",
    "label": "Cauca - Padilla"
  },
  {
    "value": "Cauca - Páez",
    "label": "Cauca - Páez"
  },
  {
    "value": "Cauca - Patia (el bordo)",
    "label": "Cauca - Patia (el bordo)"
  },
  {
    "value": "Cauca - Piamonte",
    "label": "Cauca - Piamonte"
  },
  {
    "value": "Cauca - Piendamo",
    "label": "Cauca - Piendamo"
  },
  {
    "value": "Cauca - Popayán",
    "label": "Cauca - Popayán"
  },
  {
    "value": "Cauca - Puerto tejada",
    "label": "Cauca - Puerto tejada"
  },
  {
    "value": "Cauca - Purace",
    "label": "Cauca - Purace"
  },
  {
    "value": "Cauca - Rosas",
    "label": "Cauca - Rosas"
  },
  {
    "value": "Cauca - San sebastián",
    "label": "Cauca - San sebastián"
  },
  {
    "value": "Cauca - Santa rosa",
    "label": "Cauca - Santa rosa"
  },
  {
    "value": "Cauca - Santander de quilichao",
    "label": "Cauca - Santander de quilichao"
  },
  {
    "value": "Cauca - Silvia",
    "label": "Cauca - Silvia"
  },
  {
    "value": "Cauca - Sotara",
    "label": "Cauca - Sotara"
  },
  {
    "value": "Cauca - Suárez",
    "label": "Cauca - Suárez"
  },
  {
    "value": "Cauca - Sucre",
    "label": "Cauca - Sucre"
  },
  {
    "value": "Cauca - Timbío",
    "label": "Cauca - Timbío"
  },
  {
    "value": "Cauca - Timbiquí",
    "label": "Cauca - Timbiquí"
  },
  {
    "value": "Cauca - Toribio",
    "label": "Cauca - Toribio"
  },
  {
    "value": "Cauca - Totoro",
    "label": "Cauca - Totoro"
  },
  {
    "value": "Cauca - Villa rica",
    "label": "Cauca - Villa rica"
  },
  {
    "value": "Cesar - Aguachica",
    "label": "Cesar - Aguachica"
  },
  {
    "value": "Cesar - Agustín codazzi",
    "label": "Cesar - Agustín codazzi"
  },
  {
    "value": "Cesar - Astrea",
    "label": "Cesar - Astrea"
  },
  {
    "value": "Cesar - Becerril",
    "label": "Cesar - Becerril"
  },
  {
    "value": "Cesar - Bosconia",
    "label": "Cesar - Bosconia"
  },
  {
    "value": "Cesar - Chimichagua",
    "label": "Cesar - Chimichagua"
  },
  {
    "value": "Cesar - Chiriguaná",
    "label": "Cesar - Chiriguaná"
  },
  {
    "value": "Cesar - Curumaní",
    "label": "Cesar - Curumaní"
  },
  {
    "value": "Cesar - El copey",
    "label": "Cesar - El copey"
  },
  {
    "value": "Cesar - El paso",
    "label": "Cesar - El paso"
  },
  {
    "value": "Cesar - Gamarra",
    "label": "Cesar - Gamarra"
  },
  {
    "value": "Cesar - González",
    "label": "Cesar - González"
  },
  {
    "value": "Cesar - La gloria",
    "label": "Cesar - La gloria"
  },
  {
    "value": "Cesar - La jagua ibirico",
    "label": "Cesar - La jagua ibirico"
  },
  {
    "value": "Cesar - Manaure balcón del cesar",
    "label": "Cesar - Manaure balcón del cesar"
  },
  {
    "value": "Cesar - Pailitas",
    "label": "Cesar - Pailitas"
  },
  {
    "value": "Cesar - Pelaya",
    "label": "Cesar - Pelaya"
  },
  {
    "value": "Cesar - Pueblo bello",
    "label": "Cesar - Pueblo bello"
  },
  {
    "value": "Cesar - Río de oro",
    "label": "Cesar - Río de oro"
  },
  {
    "value": "Cesar - Robles (la paz)",
    "label": "Cesar - Robles (la paz)"
  },
  {
    "value": "Cesar - San alberto",
    "label": "Cesar - San alberto"
  },
  {
    "value": "Cesar - San diego",
    "label": "Cesar - San diego"
  },
  {
    "value": "Cesar - San martín",
    "label": "Cesar - San martín"
  },
  {
    "value": "Cesar - Tamalameque",
    "label": "Cesar - Tamalameque"
  },
  {
    "value": "Cesar - Valledupar",
    "label": "Cesar - Valledupar"
  },
  {
    "value": "Chocó - Acandi",
    "label": "Chocó - Acandi"
  },
  {
    "value": "Chocó - Alto baudo (pie de pato)",
    "label": "Chocó - Alto baudo (pie de pato)"
  },
  {
    "value": "Chocó - Atrato",
    "label": "Chocó - Atrato"
  },
  {
    "value": "Chocó - Bagado",
    "label": "Chocó - Bagado"
  },
  {
    "value": "Chocó - Bahia solano (mutis)",
    "label": "Chocó - Bahia solano (mutis)"
  },
  {
    "value": "Chocó - Bajo baudo (pizarro)",
    "label": "Chocó - Bajo baudo (pizarro)"
  },
  {
    "value": "Chocó - Bojaya (bellavista)",
    "label": "Chocó - Bojaya (bellavista)"
  },
  {
    "value": "Chocó - Canton de san pablo",
    "label": "Chocó - Canton de san pablo"
  },
  {
    "value": "Chocó - Carmen del darien",
    "label": "Chocó - Carmen del darien"
  },
  {
    "value": "Chocó - Certegui",
    "label": "Chocó - Certegui"
  },
  {
    "value": "Chocó - Condoto",
    "label": "Chocó - Condoto"
  },
  {
    "value": "Chocó - El carmen",
    "label": "Chocó - El carmen"
  },
  {
    "value": "Chocó - Istmina",
    "label": "Chocó - Istmina"
  },
  {
    "value": "Chocó - Jurado",
    "label": "Chocó - Jurado"
  },
  {
    "value": "Chocó - Litoral del san juan",
    "label": "Chocó - Litoral del san juan"
  },
  {
    "value": "Chocó - Lloro",
    "label": "Chocó - Lloro"
  },
  {
    "value": "Chocó - Medio atrato",
    "label": "Chocó - Medio atrato"
  },
  {
    "value": "Chocó - Medio baudo (boca de pepe)",
    "label": "Chocó - Medio baudo (boca de pepe)"
  },
  {
    "value": "Chocó - Medio san juan",
    "label": "Chocó - Medio san juan"
  },
  {
    "value": "Chocó - Novita",
    "label": "Chocó - Novita"
  },
  {
    "value": "Chocó - Nuqui",
    "label": "Chocó - Nuqui"
  },
  {
    "value": "Chocó - Quibdo",
    "label": "Chocó - Quibdo"
  },
  {
    "value": "Chocó - Rio iro",
    "label": "Chocó - Rio iro"
  },
  {
    "value": "Chocó - Rio quito",
    "label": "Chocó - Rio quito"
  },
  {
    "value": "Chocó - Riosucio",
    "label": "Chocó - Riosucio"
  },
  {
    "value": "Chocó - San jose del palmar",
    "label": "Chocó - San jose del palmar"
  },
  {
    "value": "Chocó - Sipi",
    "label": "Chocó - Sipi"
  },
  {
    "value": "Chocó - Tado",
    "label": "Chocó - Tado"
  },
  {
    "value": "Chocó - Unguia",
    "label": "Chocó - Unguia"
  },
  {
    "value": "Chocó - Unión panamericana",
    "label": "Chocó - Unión panamericana"
  },
  {
    "value": "Córdoba - Ayapel",
    "label": "Córdoba - Ayapel"
  },
  {
    "value": "Córdoba - Buenavista",
    "label": "Córdoba - Buenavista"
  },
  {
    "value": "Córdoba - Canalete",
    "label": "Córdoba - Canalete"
  },
  {
    "value": "Córdoba - Cereté",
    "label": "Córdoba - Cereté"
  },
  {
    "value": "Córdoba - Chima",
    "label": "Córdoba - Chima"
  },
  {
    "value": "Córdoba - Chinú",
    "label": "Córdoba - Chinú"
  },
  {
    "value": "Córdoba - Cienaga de oro",
    "label": "Córdoba - Cienaga de oro"
  },
  {
    "value": "Córdoba - Cotorra",
    "label": "Córdoba - Cotorra"
  },
  {
    "value": "Córdoba - La apartada",
    "label": "Córdoba - La apartada"
  },
  {
    "value": "Córdoba - Lorica",
    "label": "Córdoba - Lorica"
  },
  {
    "value": "Córdoba - Los córdobas",
    "label": "Córdoba - Los córdobas"
  },
  {
    "value": "Córdoba - Momil",
    "label": "Córdoba - Momil"
  },
  {
    "value": "Córdoba - Moñitos",
    "label": "Córdoba - Moñitos"
  },
  {
    "value": "Córdoba - Montelíbano",
    "label": "Córdoba - Montelíbano"
  },
  {
    "value": "Córdoba - Montería",
    "label": "Córdoba - Montería"
  },
  {
    "value": "Córdoba - Planeta rica",
    "label": "Córdoba - Planeta rica"
  },
  {
    "value": "Córdoba - Pueblo nuevo",
    "label": "Córdoba - Pueblo nuevo"
  },
  {
    "value": "Córdoba - Puerto escondido",
    "label": "Córdoba - Puerto escondido"
  },
  {
    "value": "Córdoba - Puerto libertador",
    "label": "Córdoba - Puerto libertador"
  },
  {
    "value": "Córdoba - Purísima",
    "label": "Córdoba - Purísima"
  },
  {
    "value": "Córdoba - Sahagún",
    "label": "Córdoba - Sahagún"
  },
  {
    "value": "Córdoba - San andrés sotavento",
    "label": "Córdoba - San andrés sotavento"
  },
  {
    "value": "Córdoba - San antero",
    "label": "Córdoba - San antero"
  },
  {
    "value": "Córdoba - San bernardo viento",
    "label": "Córdoba - San bernardo viento"
  },
  {
    "value": "Córdoba - San carlos",
    "label": "Córdoba - San carlos"
  },
  {
    "value": "Córdoba - San José de Uré",
    "label": "Córdoba - San José de Uré"
  },
  {
    "value": "Córdoba - San pelayo",
    "label": "Córdoba - San pelayo"
  },
  {
    "value": "Córdoba - Tierralta",
    "label": "Córdoba - Tierralta"
  },
  {
    "value": "Córdoba - Valencia",
    "label": "Córdoba - Valencia"
  },
  {
    "value": "Cundinamarca - Agua de dios",
    "label": "Cundinamarca - Agua de dios"
  },
  {
    "value": "Cundinamarca - Alban",
    "label": "Cundinamarca - Alban"
  },
  {
    "value": "Cundinamarca - Anapoima",
    "label": "Cundinamarca - Anapoima"
  },
  {
    "value": "Cundinamarca - Anolaima",
    "label": "Cundinamarca - Anolaima"
  },
  {
    "value": "Cundinamarca - Apulo",
    "label": "Cundinamarca - Apulo"
  },
  {
    "value": "Cundinamarca - Arbelaez",
    "label": "Cundinamarca - Arbelaez"
  },
  {
    "value": "Cundinamarca - Beltrán",
    "label": "Cundinamarca - Beltrán"
  },
  {
    "value": "Cundinamarca - Bituima",
    "label": "Cundinamarca - Bituima"
  },
  {
    "value": "Cundinamarca - Bogotá",
    "label": "Cundinamarca - Bogotá"
  },
  {
    "value": "Cundinamarca - Bojacá",
    "label": "Cundinamarca - Bojacá"
  },
  {
    "value": "Cundinamarca - Cabrera",
    "label": "Cundinamarca - Cabrera"
  },
  {
    "value": "Cundinamarca - Cachipay",
    "label": "Cundinamarca - Cachipay"
  },
  {
    "value": "Cundinamarca - Cajicá",
    "label": "Cundinamarca - Cajicá"
  },
  {
    "value": "Cundinamarca - Caparrapí",
    "label": "Cundinamarca - Caparrapí"
  },
  {
    "value": "Cundinamarca - Caqueza",
    "label": "Cundinamarca - Caqueza"
  },
  {
    "value": "Cundinamarca - Carmen de carupa",
    "label": "Cundinamarca - Carmen de carupa"
  },
  {
    "value": "Cundinamarca - Chaguaní",
    "label": "Cundinamarca - Chaguaní"
  },
  {
    "value": "Cundinamarca - Chia",
    "label": "Cundinamarca - Chia"
  },
  {
    "value": "Cundinamarca - Chipaque",
    "label": "Cundinamarca - Chipaque"
  },
  {
    "value": "Cundinamarca - Choachí",
    "label": "Cundinamarca - Choachí"
  },
  {
    "value": "Cundinamarca - Chocontá",
    "label": "Cundinamarca - Chocontá"
  },
  {
    "value": "Cundinamarca - Cogua",
    "label": "Cundinamarca - Cogua"
  },
  {
    "value": "Cundinamarca - Cota",
    "label": "Cundinamarca - Cota"
  },
  {
    "value": "Cundinamarca - Cucunubá",
    "label": "Cundinamarca - Cucunubá"
  },
  {
    "value": "Cundinamarca - El colegio",
    "label": "Cundinamarca - El colegio"
  },
  {
    "value": "Cundinamarca - El peñón",
    "label": "Cundinamarca - El peñón"
  },
  {
    "value": "Cundinamarca - El rosal1",
    "label": "Cundinamarca - El rosal1"
  },
  {
    "value": "Cundinamarca - Facatativa",
    "label": "Cundinamarca - Facatativa"
  },
  {
    "value": "Cundinamarca - Fómeque",
    "label": "Cundinamarca - Fómeque"
  },
  {
    "value": "Cundinamarca - Fosca",
    "label": "Cundinamarca - Fosca"
  },
  {
    "value": "Cundinamarca - Funza",
    "label": "Cundinamarca - Funza"
  },
  {
    "value": "Cundinamarca - Fúquene",
    "label": "Cundinamarca - Fúquene"
  },
  {
    "value": "Cundinamarca - Fusagasuga",
    "label": "Cundinamarca - Fusagasuga"
  },
  {
    "value": "Cundinamarca - Gachalá",
    "label": "Cundinamarca - Gachalá"
  },
  {
    "value": "Cundinamarca - Gachancipá",
    "label": "Cundinamarca - Gachancipá"
  },
  {
    "value": "Cundinamarca - Gacheta",
    "label": "Cundinamarca - Gacheta"
  },
  {
    "value": "Cundinamarca - Gama",
    "label": "Cundinamarca - Gama"
  },
  {
    "value": "Cundinamarca - Girardot",
    "label": "Cundinamarca - Girardot"
  },
  {
    "value": "Cundinamarca - Granada2",
    "label": "Cundinamarca - Granada2"
  },
  {
    "value": "Cundinamarca - Guachetá",
    "label": "Cundinamarca - Guachetá"
  },
  {
    "value": "Cundinamarca - Guaduas",
    "label": "Cundinamarca - Guaduas"
  },
  {
    "value": "Cundinamarca - Guasca",
    "label": "Cundinamarca - Guasca"
  },
  {
    "value": "Cundinamarca - Guataquí",
    "label": "Cundinamarca - Guataquí"
  },
  {
    "value": "Cundinamarca - Guatavita",
    "label": "Cundinamarca - Guatavita"
  },
  {
    "value": "Cundinamarca - Guayabal de siquima",
    "label": "Cundinamarca - Guayabal de siquima"
  },
  {
    "value": "Cundinamarca - Guayabetal",
    "label": "Cundinamarca - Guayabetal"
  },
  {
    "value": "Cundinamarca - Gutiérrez",
    "label": "Cundinamarca - Gutiérrez"
  },
  {
    "value": "Cundinamarca - Jerusalén",
    "label": "Cundinamarca - Jerusalén"
  },
  {
    "value": "Cundinamarca - Junín",
    "label": "Cundinamarca - Junín"
  },
  {
    "value": "Cundinamarca - La calera",
    "label": "Cundinamarca - La calera"
  },
  {
    "value": "Cundinamarca - La mesa",
    "label": "Cundinamarca - La mesa"
  },
  {
    "value": "Cundinamarca - La palma",
    "label": "Cundinamarca - La palma"
  },
  {
    "value": "Cundinamarca - La peña",
    "label": "Cundinamarca - La peña"
  },
  {
    "value": "Cundinamarca - La vega",
    "label": "Cundinamarca - La vega"
  },
  {
    "value": "Cundinamarca - Lenguazaque",
    "label": "Cundinamarca - Lenguazaque"
  },
  {
    "value": "Cundinamarca - Machetá",
    "label": "Cundinamarca - Machetá"
  },
  {
    "value": "Cundinamarca - Madrid",
    "label": "Cundinamarca - Madrid"
  },
  {
    "value": "Cundinamarca - Manta",
    "label": "Cundinamarca - Manta"
  },
  {
    "value": "Cundinamarca - Medina",
    "label": "Cundinamarca - Medina"
  },
  {
    "value": "Cundinamarca - Mosquera",
    "label": "Cundinamarca - Mosquera"
  },
  {
    "value": "Cundinamarca - Nariño",
    "label": "Cundinamarca - Nariño"
  },
  {
    "value": "Cundinamarca - Nemocón",
    "label": "Cundinamarca - Nemocón"
  },
  {
    "value": "Cundinamarca - Nilo",
    "label": "Cundinamarca - Nilo"
  },
  {
    "value": "Cundinamarca - Nimaima",
    "label": "Cundinamarca - Nimaima"
  },
  {
    "value": "Cundinamarca - Nocaima",
    "label": "Cundinamarca - Nocaima"
  },
  {
    "value": "Cundinamarca - Ospina pérez",
    "label": "Cundinamarca - Ospina pérez"
  },
  {
    "value": "Cundinamarca - Pacho",
    "label": "Cundinamarca - Pacho"
  },
  {
    "value": "Cundinamarca - Paime",
    "label": "Cundinamarca - Paime"
  },
  {
    "value": "Cundinamarca - Pandi",
    "label": "Cundinamarca - Pandi"
  },
  {
    "value": "Cundinamarca - Paratebueno",
    "label": "Cundinamarca - Paratebueno"
  },
  {
    "value": "Cundinamarca - Pasca",
    "label": "Cundinamarca - Pasca"
  },
  {
    "value": "Cundinamarca - Puerto salgar",
    "label": "Cundinamarca - Puerto salgar"
  },
  {
    "value": "Cundinamarca - Pulí",
    "label": "Cundinamarca - Pulí"
  },
  {
    "value": "Cundinamarca - Quebradanegra",
    "label": "Cundinamarca - Quebradanegra"
  },
  {
    "value": "Cundinamarca - Quetame",
    "label": "Cundinamarca - Quetame"
  },
  {
    "value": "Cundinamarca - Quipile",
    "label": "Cundinamarca - Quipile"
  },
  {
    "value": "Cundinamarca - Rafael reyes",
    "label": "Cundinamarca - Rafael reyes"
  },
  {
    "value": "Cundinamarca - Ricaurte",
    "label": "Cundinamarca - Ricaurte"
  },
  {
    "value": "Cundinamarca - San  antonio del  tequendama",
    "label": "Cundinamarca - San  antonio del  tequendama"
  },
  {
    "value": "Cundinamarca - San bernardo",
    "label": "Cundinamarca - San bernardo"
  },
  {
    "value": "Cundinamarca - San cayetano",
    "label": "Cundinamarca - San cayetano"
  },
  {
    "value": "Cundinamarca - San francisco",
    "label": "Cundinamarca - San francisco"
  },
  {
    "value": "Cundinamarca - San juan de rioseco",
    "label": "Cundinamarca - San juan de rioseco"
  },
  {
    "value": "Cundinamarca - Sasaima",
    "label": "Cundinamarca - Sasaima"
  },
  {
    "value": "Cundinamarca - Sesquilé",
    "label": "Cundinamarca - Sesquilé"
  },
  {
    "value": "Cundinamarca - Sibaté",
    "label": "Cundinamarca - Sibaté"
  },
  {
    "value": "Cundinamarca - Silvania",
    "label": "Cundinamarca - Silvania"
  },
  {
    "value": "Cundinamarca - Simijaca",
    "label": "Cundinamarca - Simijaca"
  },
  {
    "value": "Cundinamarca - Soacha",
    "label": "Cundinamarca - Soacha"
  },
  {
    "value": "Cundinamarca - Sopo",
    "label": "Cundinamarca - Sopo"
  },
  {
    "value": "Cundinamarca - Subachoque",
    "label": "Cundinamarca - Subachoque"
  },
  {
    "value": "Cundinamarca - Suesca",
    "label": "Cundinamarca - Suesca"
  },
  {
    "value": "Cundinamarca - Supatá",
    "label": "Cundinamarca - Supatá"
  },
  {
    "value": "Cundinamarca - Susa",
    "label": "Cundinamarca - Susa"
  },
  {
    "value": "Cundinamarca - Sutatausa",
    "label": "Cundinamarca - Sutatausa"
  },
  {
    "value": "Cundinamarca - Tabio",
    "label": "Cundinamarca - Tabio"
  },
  {
    "value": "Cundinamarca - Tausa",
    "label": "Cundinamarca - Tausa"
  },
  {
    "value": "Cundinamarca - Tena",
    "label": "Cundinamarca - Tena"
  },
  {
    "value": "Cundinamarca - Tenjo",
    "label": "Cundinamarca - Tenjo"
  },
  {
    "value": "Cundinamarca - Tibacuy",
    "label": "Cundinamarca - Tibacuy"
  },
  {
    "value": "Cundinamarca - Tibirita",
    "label": "Cundinamarca - Tibirita"
  },
  {
    "value": "Cundinamarca - Tocaima",
    "label": "Cundinamarca - Tocaima"
  },
  {
    "value": "Cundinamarca - Tocancipá",
    "label": "Cundinamarca - Tocancipá"
  },
  {
    "value": "Cundinamarca - Topaipí",
    "label": "Cundinamarca - Topaipí"
  },
  {
    "value": "Cundinamarca - Ubalá",
    "label": "Cundinamarca - Ubalá"
  },
  {
    "value": "Cundinamarca - Ubaque",
    "label": "Cundinamarca - Ubaque"
  },
  {
    "value": "Cundinamarca - Ubaté",
    "label": "Cundinamarca - Ubaté"
  },
  {
    "value": "Cundinamarca - Une",
    "label": "Cundinamarca - Une"
  },
  {
    "value": "Cundinamarca - Utica",
    "label": "Cundinamarca - Utica"
  },
  {
    "value": "Cundinamarca - Vergara",
    "label": "Cundinamarca - Vergara"
  },
  {
    "value": "Cundinamarca - Viani",
    "label": "Cundinamarca - Viani"
  },
  {
    "value": "Cundinamarca - Villa gomez",
    "label": "Cundinamarca - Villa gomez"
  },
  {
    "value": "Cundinamarca - Villa pinzón",
    "label": "Cundinamarca - Villa pinzón"
  },
  {
    "value": "Cundinamarca - Villeta",
    "label": "Cundinamarca - Villeta"
  },
  {
    "value": "Cundinamarca - Viota",
    "label": "Cundinamarca - Viota"
  },
  {
    "value": "Cundinamarca - Yacopí",
    "label": "Cundinamarca - Yacopí"
  },
  {
    "value": "Cundinamarca - Zipacón",
    "label": "Cundinamarca - Zipacón"
  },
  {
    "value": "Cundinamarca - Zipaquirá",
    "label": "Cundinamarca - Zipaquirá"
  },
  {
    "value": "Guainía - Barranco minas",
    "label": "Guainía - Barranco minas"
  },
  {
    "value": "Guainía - Cacahual",
    "label": "Guainía - Cacahual"
  },
  {
    "value": "Guainía - Inírida",
    "label": "Guainía - Inírida"
  },
  {
    "value": "Guainía - La guadalupe",
    "label": "Guainía - La guadalupe"
  },
  {
    "value": "Guainía - Mapiripana",
    "label": "Guainía - Mapiripana"
  },
  {
    "value": "Guainía - Morichal",
    "label": "Guainía - Morichal"
  },
  {
    "value": "Guainía - Pana pana",
    "label": "Guainía - Pana pana"
  },
  {
    "value": "Guainía - Puerto colombia",
    "label": "Guainía - Puerto colombia"
  },
  {
    "value": "Guainía - San felipe",
    "label": "Guainía - San felipe"
  },
  {
    "value": "Guaviare - Calamar",
    "label": "Guaviare - Calamar"
  },
  {
    "value": "Guaviare - El retorno",
    "label": "Guaviare - El retorno"
  },
  {
    "value": "Guaviare - Miraflorez",
    "label": "Guaviare - Miraflorez"
  },
  {
    "value": "Guaviare - San josé del guaviare",
    "label": "Guaviare - San josé del guaviare"
  },
  {
    "value": "Huila - Acevedo",
    "label": "Huila - Acevedo"
  },
  {
    "value": "Huila - Agrado",
    "label": "Huila - Agrado"
  },
  {
    "value": "Huila - Aipe",
    "label": "Huila - Aipe"
  },
  {
    "value": "Huila - Algeciras",
    "label": "Huila - Algeciras"
  },
  {
    "value": "Huila - Altamira",
    "label": "Huila - Altamira"
  },
  {
    "value": "Huila - Baraya",
    "label": "Huila - Baraya"
  },
  {
    "value": "Huila - Campo alegre",
    "label": "Huila - Campo alegre"
  },
  {
    "value": "Huila - Colombia",
    "label": "Huila - Colombia"
  },
  {
    "value": "Huila - Elias",
    "label": "Huila - Elias"
  },
  {
    "value": "Huila - Garzón",
    "label": "Huila - Garzón"
  },
  {
    "value": "Huila - Gigante",
    "label": "Huila - Gigante"
  },
  {
    "value": "Huila - Guadalupe",
    "label": "Huila - Guadalupe"
  },
  {
    "value": "Huila - Hobo",
    "label": "Huila - Hobo"
  },
  {
    "value": "Huila - Iquira",
    "label": "Huila - Iquira"
  },
  {
    "value": "Huila - Isnos",
    "label": "Huila - Isnos"
  },
  {
    "value": "Huila - La argentina",
    "label": "Huila - La argentina"
  },
  {
    "value": "Huila - La plata",
    "label": "Huila - La plata"
  },
  {
    "value": "Huila - Nataga",
    "label": "Huila - Nataga"
  },
  {
    "value": "Huila - Neiva",
    "label": "Huila - Neiva"
  },
  {
    "value": "Huila - Oporapa",
    "label": "Huila - Oporapa"
  },
  {
    "value": "Huila - Paicol",
    "label": "Huila - Paicol"
  },
  {
    "value": "Huila - Palermo",
    "label": "Huila - Palermo"
  },
  {
    "value": "Huila - Palestina",
    "label": "Huila - Palestina"
  },
  {
    "value": "Huila - Pital",
    "label": "Huila - Pital"
  },
  {
    "value": "Huila - Pitalito",
    "label": "Huila - Pitalito"
  },
  {
    "value": "Huila - Rivera",
    "label": "Huila - Rivera"
  },
  {
    "value": "Huila - Salado blanco",
    "label": "Huila - Salado blanco"
  },
  {
    "value": "Huila - San agustín",
    "label": "Huila - San agustín"
  },
  {
    "value": "Huila - Santa maria",
    "label": "Huila - Santa maria"
  },
  {
    "value": "Huila - Suaza",
    "label": "Huila - Suaza"
  },
  {
    "value": "Huila - Tarqui",
    "label": "Huila - Tarqui"
  },
  {
    "value": "Huila - Tello",
    "label": "Huila - Tello"
  },
  {
    "value": "Huila - Teruel",
    "label": "Huila - Teruel"
  },
  {
    "value": "Huila - Tesalia",
    "label": "Huila - Tesalia"
  },
  {
    "value": "Huila - Timana",
    "label": "Huila - Timana"
  },
  {
    "value": "Huila - Villavieja",
    "label": "Huila - Villavieja"
  },
  {
    "value": "Huila - Yaguara",
    "label": "Huila - Yaguara"
  },
  {
    "value": "La guajira - Albania",
    "label": "La guajira - Albania"
  },
  {
    "value": "La guajira - Barrancas",
    "label": "La guajira - Barrancas"
  },
  {
    "value": "La guajira - Dibulla",
    "label": "La guajira - Dibulla"
  },
  {
    "value": "La guajira - Distracción",
    "label": "La guajira - Distracción"
  },
  {
    "value": "La guajira - El molino",
    "label": "La guajira - El molino"
  },
  {
    "value": "La guajira - Fonseca",
    "label": "La guajira - Fonseca"
  },
  {
    "value": "La guajira - Hato nuevo",
    "label": "La guajira - Hato nuevo"
  },
  {
    "value": "La guajira - La jagua del pilar",
    "label": "La guajira - La jagua del pilar"
  },
  {
    "value": "La guajira - Maicao",
    "label": "La guajira - Maicao"
  },
  {
    "value": "La guajira - Manaure",
    "label": "La guajira - Manaure"
  },
  {
    "value": "La guajira - Riohacha",
    "label": "La guajira - Riohacha"
  },
  {
    "value": "La guajira - San juan del cesar",
    "label": "La guajira - San juan del cesar"
  },
  {
    "value": "La guajira - Uribia",
    "label": "La guajira - Uribia"
  },
  {
    "value": "La guajira - Urumita",
    "label": "La guajira - Urumita"
  },
  {
    "value": "La guajira - Villanueva",
    "label": "La guajira - Villanueva"
  },
  {
    "value": "Magdalena - Algarrobo",
    "label": "Magdalena - Algarrobo"
  },
  {
    "value": "Magdalena - Aracataca",
    "label": "Magdalena - Aracataca"
  },
  {
    "value": "Magdalena - Ariguani",
    "label": "Magdalena - Ariguani"
  },
  {
    "value": "Magdalena - Cerro san antonio",
    "label": "Magdalena - Cerro san antonio"
  },
  {
    "value": "Magdalena - Chivolo",
    "label": "Magdalena - Chivolo"
  },
  {
    "value": "Magdalena - Cienaga",
    "label": "Magdalena - Cienaga"
  },
  {
    "value": "Magdalena - Concordia",
    "label": "Magdalena - Concordia"
  },
  {
    "value": "Magdalena - El banco",
    "label": "Magdalena - El banco"
  },
  {
    "value": "Magdalena - El piñon",
    "label": "Magdalena - El piñon"
  },
  {
    "value": "Magdalena - El reten",
    "label": "Magdalena - El reten"
  },
  {
    "value": "Magdalena - Fundacion",
    "label": "Magdalena - Fundacion"
  },
  {
    "value": "Magdalena - Guamal",
    "label": "Magdalena - Guamal"
  },
  {
    "value": "Magdalena - Nueva granada",
    "label": "Magdalena - Nueva granada"
  },
  {
    "value": "Magdalena - Pedraza",
    "label": "Magdalena - Pedraza"
  },
  {
    "value": "Magdalena - Pijiño del carmen",
    "label": "Magdalena - Pijiño del carmen"
  },
  {
    "value": "Magdalena - Pivijay",
    "label": "Magdalena - Pivijay"
  },
  {
    "value": "Magdalena - Plato",
    "label": "Magdalena - Plato"
  },
  {
    "value": "Magdalena - Pueblo viejo",
    "label": "Magdalena - Pueblo viejo"
  },
  {
    "value": "Magdalena - Remolino",
    "label": "Magdalena - Remolino"
  },
  {
    "value": "Magdalena - Sabanas de san angel",
    "label": "Magdalena - Sabanas de san angel"
  },
  {
    "value": "Magdalena - Salamina",
    "label": "Magdalena - Salamina"
  },
  {
    "value": "Magdalena - San sebastian de buenavista",
    "label": "Magdalena - San sebastian de buenavista"
  },
  {
    "value": "Magdalena - San zenon",
    "label": "Magdalena - San zenon"
  },
  {
    "value": "Magdalena - Santa ana",
    "label": "Magdalena - Santa ana"
  },
  {
    "value": "Magdalena - Santa barbara de pinto",
    "label": "Magdalena - Santa barbara de pinto"
  },
  {
    "value": "Magdalena - Santa marta",
    "label": "Magdalena - Santa marta"
  },
  {
    "value": "Magdalena - Sitionuevo",
    "label": "Magdalena - Sitionuevo"
  },
  {
    "value": "Magdalena - Tenerife",
    "label": "Magdalena - Tenerife"
  },
  {
    "value": "Magdalena - Zapayan",
    "label": "Magdalena - Zapayan"
  },
  {
    "value": "Magdalena - Zona bananera",
    "label": "Magdalena - Zona bananera"
  },
  {
    "value": "Meta - Acacias",
    "label": "Meta - Acacias"
  },
  {
    "value": "Meta - Barranca de upia",
    "label": "Meta - Barranca de upia"
  },
  {
    "value": "Meta - Cabuyaro",
    "label": "Meta - Cabuyaro"
  },
  {
    "value": "Meta - Castilla la nueva",
    "label": "Meta - Castilla la nueva"
  },
  {
    "value": "Meta - Cubarral",
    "label": "Meta - Cubarral"
  },
  {
    "value": "Meta - Cumaral",
    "label": "Meta - Cumaral"
  },
  {
    "value": "Meta - El calvario",
    "label": "Meta - El calvario"
  },
  {
    "value": "Meta - El castillo",
    "label": "Meta - El castillo"
  },
  {
    "value": "Meta - El dorado",
    "label": "Meta - El dorado"
  },
  {
    "value": "Meta - Fuente de oro",
    "label": "Meta - Fuente de oro"
  },
  {
    "value": "Meta - Granada",
    "label": "Meta - Granada"
  },
  {
    "value": "Meta - Guamal",
    "label": "Meta - Guamal"
  },
  {
    "value": "Meta - La macarena",
    "label": "Meta - La macarena"
  },
  {
    "value": "Meta - La uribe",
    "label": "Meta - La uribe"
  },
  {
    "value": "Meta - Lejanías",
    "label": "Meta - Lejanías"
  },
  {
    "value": "Meta - Mapiripán",
    "label": "Meta - Mapiripán"
  },
  {
    "value": "Meta - Mesetas",
    "label": "Meta - Mesetas"
  },
  {
    "value": "Meta - Pipiral",
    "label": "Meta - Pipiral"
  },
  {
    "value": "Meta - Planadas",
    "label": "Meta - Planadas"
  },
  {
    "value": "Meta - Puerto concordia",
    "label": "Meta - Puerto concordia"
  },
  {
    "value": "Meta - Puerto gaitán",
    "label": "Meta - Puerto gaitán"
  },
  {
    "value": "Meta - Puerto lleras",
    "label": "Meta - Puerto lleras"
  },
  {
    "value": "Meta - Puerto lópez",
    "label": "Meta - Puerto lópez"
  },
  {
    "value": "Meta - Puerto rico",
    "label": "Meta - Puerto rico"
  },
  {
    "value": "Meta - Restrepo",
    "label": "Meta - Restrepo"
  },
  {
    "value": "Meta - San  juan de arama",
    "label": "Meta - San  juan de arama"
  },
  {
    "value": "Meta - San carlos guaroa",
    "label": "Meta - San carlos guaroa"
  },
  {
    "value": "Meta - San juanito",
    "label": "Meta - San juanito"
  },
  {
    "value": "Meta - San martín",
    "label": "Meta - San martín"
  },
  {
    "value": "Meta - Villavicencio",
    "label": "Meta - Villavicencio"
  },
  {
    "value": "Meta - Vista hermosa",
    "label": "Meta - Vista hermosa"
  },
  {
    "value": "Nariño - Alban",
    "label": "Nariño - Alban"
  },
  {
    "value": "Nariño - Aldaña",
    "label": "Nariño - Aldaña"
  },
  {
    "value": "Nariño - Ancuya",
    "label": "Nariño - Ancuya"
  },
  {
    "value": "Nariño - Arboleda",
    "label": "Nariño - Arboleda"
  },
  {
    "value": "Nariño - Barbacoas",
    "label": "Nariño - Barbacoas"
  },
  {
    "value": "Nariño - Belen",
    "label": "Nariño - Belen"
  },
  {
    "value": "Nariño - Buesaco",
    "label": "Nariño - Buesaco"
  },
  {
    "value": "Nariño - Chachagui",
    "label": "Nariño - Chachagui"
  },
  {
    "value": "Nariño - Colon (genova)",
    "label": "Nariño - Colon (genova)"
  },
  {
    "value": "Nariño - Consaca",
    "label": "Nariño - Consaca"
  },
  {
    "value": "Nariño - Contadero",
    "label": "Nariño - Contadero"
  },
  {
    "value": "Nariño - Cordoba",
    "label": "Nariño - Cordoba"
  },
  {
    "value": "Nariño - Cuaspud",
    "label": "Nariño - Cuaspud"
  },
  {
    "value": "Nariño - Cumbal",
    "label": "Nariño - Cumbal"
  },
  {
    "value": "Nariño - Cumbitara",
    "label": "Nariño - Cumbitara"
  },
  {
    "value": "Nariño - El charco",
    "label": "Nariño - El charco"
  },
  {
    "value": "Nariño - El peñol",
    "label": "Nariño - El peñol"
  },
  {
    "value": "Nariño - El rosario",
    "label": "Nariño - El rosario"
  },
  {
    "value": "Nariño - El tablón",
    "label": "Nariño - El tablón"
  },
  {
    "value": "Nariño - El tambo",
    "label": "Nariño - El tambo"
  },
  {
    "value": "Nariño - Funes",
    "label": "Nariño - Funes"
  },
  {
    "value": "Nariño - Guachucal",
    "label": "Nariño - Guachucal"
  },
  {
    "value": "Nariño - Guaitarilla",
    "label": "Nariño - Guaitarilla"
  },
  {
    "value": "Nariño - Gualmatan",
    "label": "Nariño - Gualmatan"
  },
  {
    "value": "Nariño - Iles",
    "label": "Nariño - Iles"
  },
  {
    "value": "Nariño - Imues",
    "label": "Nariño - Imues"
  },
  {
    "value": "Nariño - Ipiales",
    "label": "Nariño - Ipiales"
  },
  {
    "value": "Nariño - La cruz",
    "label": "Nariño - La cruz"
  },
  {
    "value": "Nariño - La florida",
    "label": "Nariño - La florida"
  },
  {
    "value": "Nariño - La llanada",
    "label": "Nariño - La llanada"
  },
  {
    "value": "Nariño - La tola",
    "label": "Nariño - La tola"
  },
  {
    "value": "Nariño - La union",
    "label": "Nariño - La union"
  },
  {
    "value": "Nariño - Leiva",
    "label": "Nariño - Leiva"
  },
  {
    "value": "Nariño - Linares",
    "label": "Nariño - Linares"
  },
  {
    "value": "Nariño - Los andes",
    "label": "Nariño - Los andes"
  },
  {
    "value": "Nariño - Magui",
    "label": "Nariño - Magui"
  },
  {
    "value": "Nariño - Mallama",
    "label": "Nariño - Mallama"
  },
  {
    "value": "Nariño - Mosqueza",
    "label": "Nariño - Mosqueza"
  },
  {
    "value": "Nariño - Nariño",
    "label": "Nariño - Nariño"
  },
  {
    "value": "Nariño - Olaya herrera",
    "label": "Nariño - Olaya herrera"
  },
  {
    "value": "Nariño - Ospina",
    "label": "Nariño - Ospina"
  },
  {
    "value": "Nariño - Pasto",
    "label": "Nariño - Pasto"
  },
  {
    "value": "Nariño - Pizarro",
    "label": "Nariño - Pizarro"
  },
  {
    "value": "Nariño - Policarpa",
    "label": "Nariño - Policarpa"
  },
  {
    "value": "Nariño - Potosi",
    "label": "Nariño - Potosi"
  },
  {
    "value": "Nariño - Providencia",
    "label": "Nariño - Providencia"
  },
  {
    "value": "Nariño - Puerres",
    "label": "Nariño - Puerres"
  },
  {
    "value": "Nariño - Pupiales",
    "label": "Nariño - Pupiales"
  },
  {
    "value": "Nariño - Ricaurte",
    "label": "Nariño - Ricaurte"
  },
  {
    "value": "Nariño - Roberto payan",
    "label": "Nariño - Roberto payan"
  },
  {
    "value": "Nariño - Salahonda",
    "label": "Nariño - Salahonda"
  },
  {
    "value": "Nariño - Samaniego",
    "label": "Nariño - Samaniego"
  },
  {
    "value": "Nariño - San bernardo",
    "label": "Nariño - San bernardo"
  },
  {
    "value": "Nariño - San lorenzo",
    "label": "Nariño - San lorenzo"
  },
  {
    "value": "Nariño - San pablo",
    "label": "Nariño - San pablo"
  },
  {
    "value": "Nariño - San pedro de cartago",
    "label": "Nariño - San pedro de cartago"
  },
  {
    "value": "Nariño - Sandona",
    "label": "Nariño - Sandona"
  },
  {
    "value": "Nariño - Santa barbara",
    "label": "Nariño - Santa barbara"
  },
  {
    "value": "Nariño - Santacruz",
    "label": "Nariño - Santacruz"
  },
  {
    "value": "Nariño - Sapuyes",
    "label": "Nariño - Sapuyes"
  },
  {
    "value": "Nariño - Taminango",
    "label": "Nariño - Taminango"
  },
  {
    "value": "Nariño - Tangua",
    "label": "Nariño - Tangua"
  },
  {
    "value": "Nariño - Tumaco",
    "label": "Nariño - Tumaco"
  },
  {
    "value": "Nariño - Tuquerres",
    "label": "Nariño - Tuquerres"
  },
  {
    "value": "Nariño - Yacuanquer",
    "label": "Nariño - Yacuanquer"
  },
  {
    "value": "Norte de Santander - Abrego",
    "label": "Norte de Santander - Abrego"
  },
  {
    "value": "Norte de Santander - Arboledas",
    "label": "Norte de Santander - Arboledas"
  },
  {
    "value": "Norte de Santander - Bochalema",
    "label": "Norte de Santander - Bochalema"
  },
  {
    "value": "Norte de Santander - Bucarasica",
    "label": "Norte de Santander - Bucarasica"
  },
  {
    "value": "Norte de Santander - Cáchira",
    "label": "Norte de Santander - Cáchira"
  },
  {
    "value": "Norte de Santander - Cácota",
    "label": "Norte de Santander - Cácota"
  },
  {
    "value": "Norte de Santander - Chinácota",
    "label": "Norte de Santander - Chinácota"
  },
  {
    "value": "Norte de Santander - Chitagá",
    "label": "Norte de Santander - Chitagá"
  },
  {
    "value": "Norte de Santander - Convención",
    "label": "Norte de Santander - Convención"
  },
  {
    "value": "Norte de Santander - Cúcuta",
    "label": "Norte de Santander - Cúcuta"
  },
  {
    "value": "Norte de Santander - Cucutilla",
    "label": "Norte de Santander - Cucutilla"
  },
  {
    "value": "Norte de Santander - Durania",
    "label": "Norte de Santander - Durania"
  },
  {
    "value": "Norte de Santander - El carmen",
    "label": "Norte de Santander - El carmen"
  },
  {
    "value": "Norte de Santander - El tarra",
    "label": "Norte de Santander - El tarra"
  },
  {
    "value": "Norte de Santander - El zulia",
    "label": "Norte de Santander - El zulia"
  },
  {
    "value": "Norte de Santander - Gramalote",
    "label": "Norte de Santander - Gramalote"
  },
  {
    "value": "Norte de Santander - Hacari",
    "label": "Norte de Santander - Hacari"
  },
  {
    "value": "Norte de Santander - Herrán",
    "label": "Norte de Santander - Herrán"
  },
  {
    "value": "Norte de Santander - La esperanza",
    "label": "Norte de Santander - La esperanza"
  },
  {
    "value": "Norte de Santander - La playa",
    "label": "Norte de Santander - La playa"
  },
  {
    "value": "Norte de Santander - Labateca",
    "label": "Norte de Santander - Labateca"
  },
  {
    "value": "Norte de Santander - Los patios",
    "label": "Norte de Santander - Los patios"
  },
  {
    "value": "Norte de Santander - Lourdes",
    "label": "Norte de Santander - Lourdes"
  },
  {
    "value": "Norte de Santander - Mutiscua",
    "label": "Norte de Santander - Mutiscua"
  },
  {
    "value": "Norte de Santander - Ocaña",
    "label": "Norte de Santander - Ocaña"
  },
  {
    "value": "Norte de Santander - Pamplona",
    "label": "Norte de Santander - Pamplona"
  },
  {
    "value": "Norte de Santander - Pamplonita",
    "label": "Norte de Santander - Pamplonita"
  },
  {
    "value": "Norte de Santander - Puerto santander",
    "label": "Norte de Santander - Puerto santander"
  },
  {
    "value": "Norte de Santander - Ragonvalia",
    "label": "Norte de Santander - Ragonvalia"
  },
  {
    "value": "Norte de Santander - Salazar",
    "label": "Norte de Santander - Salazar"
  },
  {
    "value": "Norte de Santander - San calixto",
    "label": "Norte de Santander - San calixto"
  },
  {
    "value": "Norte de Santander - San cayetano",
    "label": "Norte de Santander - San cayetano"
  },
  {
    "value": "Norte de Santander - Santiago",
    "label": "Norte de Santander - Santiago"
  },
  {
    "value": "Norte de Santander - Sardinata",
    "label": "Norte de Santander - Sardinata"
  },
  {
    "value": "Norte de Santander - Silos",
    "label": "Norte de Santander - Silos"
  },
  {
    "value": "Norte de Santander - Teorama",
    "label": "Norte de Santander - Teorama"
  },
  {
    "value": "Norte de Santander - Tibú",
    "label": "Norte de Santander - Tibú"
  },
  {
    "value": "Norte de Santander - Toledo",
    "label": "Norte de Santander - Toledo"
  },
  {
    "value": "Norte de Santander - Villa caro",
    "label": "Norte de Santander - Villa caro"
  },
  {
    "value": "Norte de Santander - Villa del rosario",
    "label": "Norte de Santander - Villa del rosario"
  },
  {
    "value": "Putumayo - Colón",
    "label": "Putumayo - Colón"
  },
  {
    "value": "Putumayo - Mocoa",
    "label": "Putumayo - Mocoa"
  },
  {
    "value": "Putumayo - Orito",
    "label": "Putumayo - Orito"
  },
  {
    "value": "Putumayo - Puerto asís",
    "label": "Putumayo - Puerto asís"
  },
  {
    "value": "Putumayo - Puerto caycedo",
    "label": "Putumayo - Puerto caycedo"
  },
  {
    "value": "Putumayo - Puerto guzmán",
    "label": "Putumayo - Puerto guzmán"
  },
  {
    "value": "Putumayo - Puerto leguízamo",
    "label": "Putumayo - Puerto leguízamo"
  },
  {
    "value": "Putumayo - San francisco",
    "label": "Putumayo - San francisco"
  },
  {
    "value": "Putumayo - San miguel",
    "label": "Putumayo - San miguel"
  },
  {
    "value": "Putumayo - Santiago",
    "label": "Putumayo - Santiago"
  },
  {
    "value": "Putumayo - Sibundoy",
    "label": "Putumayo - Sibundoy"
  },
  {
    "value": "Putumayo - Valle del guamuez",
    "label": "Putumayo - Valle del guamuez"
  },
  {
    "value": "Putumayo - Villagarzón",
    "label": "Putumayo - Villagarzón"
  },
  {
    "value": "Quindío - Armenia",
    "label": "Quindío - Armenia"
  },
  {
    "value": "Quindío - Barcelona",
    "label": "Quindío - Barcelona"
  },
  {
    "value": "Quindío - Buenavista",
    "label": "Quindío - Buenavista"
  },
  {
    "value": "Quindío - Calarcá",
    "label": "Quindío - Calarcá"
  },
  {
    "value": "Quindío - Circasia",
    "label": "Quindío - Circasia"
  },
  {
    "value": "Quindío - Córdoba",
    "label": "Quindío - Córdoba"
  },
  {
    "value": "Quindío - Filandia",
    "label": "Quindío - Filandia"
  },
  {
    "value": "Quindío - Génova",
    "label": "Quindío - Génova"
  },
  {
    "value": "Quindío - La tebaida",
    "label": "Quindío - La tebaida"
  },
  {
    "value": "Quindío - Montenegro",
    "label": "Quindío - Montenegro"
  },
  {
    "value": "Quindío - Pijao",
    "label": "Quindío - Pijao"
  },
  {
    "value": "Quindío - Quimbaya",
    "label": "Quindío - Quimbaya"
  },
  {
    "value": "Quindío - Salento",
    "label": "Quindío - Salento"
  },
  {
    "value": "Risaralda - Apia",
    "label": "Risaralda - Apia"
  },
  {
    "value": "Risaralda - Balboa",
    "label": "Risaralda - Balboa"
  },
  {
    "value": "Risaralda - Belén de umbría",
    "label": "Risaralda - Belén de umbría"
  },
  {
    "value": "Risaralda - Dos quebradas",
    "label": "Risaralda - Dos quebradas"
  },
  {
    "value": "Risaralda - Guatica",
    "label": "Risaralda - Guatica"
  },
  {
    "value": "Risaralda - La celia",
    "label": "Risaralda - La celia"
  },
  {
    "value": "Risaralda - La virginia",
    "label": "Risaralda - La virginia"
  },
  {
    "value": "Risaralda - Marsella",
    "label": "Risaralda - Marsella"
  },
  {
    "value": "Risaralda - Mistrato",
    "label": "Risaralda - Mistrato"
  },
  {
    "value": "Risaralda - Pereira",
    "label": "Risaralda - Pereira"
  },
  {
    "value": "Risaralda - Pueblo rico",
    "label": "Risaralda - Pueblo rico"
  },
  {
    "value": "Risaralda - Quinchía",
    "label": "Risaralda - Quinchía"
  },
  {
    "value": "Risaralda - Santa rosa de cabal",
    "label": "Risaralda - Santa rosa de cabal"
  },
  {
    "value": "Risaralda - Santuario",
    "label": "Risaralda - Santuario"
  },
  {
    "value": "San Andrés y Providencia - Providencia",
    "label": "San Andrés y Providencia - Providencia"
  },
  {
    "value": "San Andrés y Providencia - San andres",
    "label": "San Andrés y Providencia - San andres"
  },
  {
    "value": "San Andrés y Providencia - Santa catalina",
    "label": "San Andrés y Providencia - Santa catalina"
  },
  {
    "value": "Santander - Aguada",
    "label": "Santander - Aguada"
  },
  {
    "value": "Santander - Albania",
    "label": "Santander - Albania"
  },
  {
    "value": "Santander - Aratoca",
    "label": "Santander - Aratoca"
  },
  {
    "value": "Santander - Barbosa",
    "label": "Santander - Barbosa"
  },
  {
    "value": "Santander - Barichara",
    "label": "Santander - Barichara"
  },
  {
    "value": "Santander - Barrancabermeja",
    "label": "Santander - Barrancabermeja"
  },
  {
    "value": "Santander - Betulia",
    "label": "Santander - Betulia"
  },
  {
    "value": "Santander - Bolívar",
    "label": "Santander - Bolívar"
  },
  {
    "value": "Santander - Bucaramanga",
    "label": "Santander - Bucaramanga"
  },
  {
    "value": "Santander - Cabrera",
    "label": "Santander - Cabrera"
  },
  {
    "value": "Santander - California",
    "label": "Santander - California"
  },
  {
    "value": "Santander - Capitanejo",
    "label": "Santander - Capitanejo"
  },
  {
    "value": "Santander - Carcasi",
    "label": "Santander - Carcasi"
  },
  {
    "value": "Santander - Cepita",
    "label": "Santander - Cepita"
  },
  {
    "value": "Santander - Cerrito",
    "label": "Santander - Cerrito"
  },
  {
    "value": "Santander - Charalá",
    "label": "Santander - Charalá"
  },
  {
    "value": "Santander - Charta",
    "label": "Santander - Charta"
  },
  {
    "value": "Santander - Chima",
    "label": "Santander - Chima"
  },
  {
    "value": "Santander - Chipatá",
    "label": "Santander - Chipatá"
  },
  {
    "value": "Santander - Cimitarra",
    "label": "Santander - Cimitarra"
  },
  {
    "value": "Santander - Concepción",
    "label": "Santander - Concepción"
  },
  {
    "value": "Santander - Confines",
    "label": "Santander - Confines"
  },
  {
    "value": "Santander - Contratación",
    "label": "Santander - Contratación"
  },
  {
    "value": "Santander - Coromoro",
    "label": "Santander - Coromoro"
  },
  {
    "value": "Santander - Curití",
    "label": "Santander - Curití"
  },
  {
    "value": "Santander - El carmen",
    "label": "Santander - El carmen"
  },
  {
    "value": "Santander - El guacamayo",
    "label": "Santander - El guacamayo"
  },
  {
    "value": "Santander - El peñón",
    "label": "Santander - El peñón"
  },
  {
    "value": "Santander - El playón",
    "label": "Santander - El playón"
  },
  {
    "value": "Santander - Encino",
    "label": "Santander - Encino"
  },
  {
    "value": "Santander - Enciso",
    "label": "Santander - Enciso"
  },
  {
    "value": "Santander - Florián",
    "label": "Santander - Florián"
  },
  {
    "value": "Santander - Floridablanca",
    "label": "Santander - Floridablanca"
  },
  {
    "value": "Santander - Galán",
    "label": "Santander - Galán"
  },
  {
    "value": "Santander - Gambita",
    "label": "Santander - Gambita"
  },
  {
    "value": "Santander - Girón",
    "label": "Santander - Girón"
  },
  {
    "value": "Santander - Guaca",
    "label": "Santander - Guaca"
  },
  {
    "value": "Santander - Guadalupe",
    "label": "Santander - Guadalupe"
  },
  {
    "value": "Santander - Guapota",
    "label": "Santander - Guapota"
  },
  {
    "value": "Santander - Guavatá",
    "label": "Santander - Guavatá"
  },
  {
    "value": "Santander - Guepsa",
    "label": "Santander - Guepsa"
  },
  {
    "value": "Santander - Hato",
    "label": "Santander - Hato"
  },
  {
    "value": "Santander - Jesús maria",
    "label": "Santander - Jesús maria"
  },
  {
    "value": "Santander - Jordán",
    "label": "Santander - Jordán"
  },
  {
    "value": "Santander - La belleza",
    "label": "Santander - La belleza"
  },
  {
    "value": "Santander - La paz",
    "label": "Santander - La paz"
  },
  {
    "value": "Santander - Landazuri",
    "label": "Santander - Landazuri"
  },
  {
    "value": "Santander - Lebrija",
    "label": "Santander - Lebrija"
  },
  {
    "value": "Santander - Los santos",
    "label": "Santander - Los santos"
  },
  {
    "value": "Santander - Macaravita",
    "label": "Santander - Macaravita"
  },
  {
    "value": "Santander - Málaga",
    "label": "Santander - Málaga"
  },
  {
    "value": "Santander - Matanza",
    "label": "Santander - Matanza"
  },
  {
    "value": "Santander - Mogotes",
    "label": "Santander - Mogotes"
  },
  {
    "value": "Santander - Molagavita",
    "label": "Santander - Molagavita"
  },
  {
    "value": "Santander - Ocamonte",
    "label": "Santander - Ocamonte"
  },
  {
    "value": "Santander - Oiba",
    "label": "Santander - Oiba"
  },
  {
    "value": "Santander - Onzaga",
    "label": "Santander - Onzaga"
  },
  {
    "value": "Santander - Palmar",
    "label": "Santander - Palmar"
  },
  {
    "value": "Santander - Palmas del socorro",
    "label": "Santander - Palmas del socorro"
  },
  {
    "value": "Santander - Páramo",
    "label": "Santander - Páramo"
  },
  {
    "value": "Santander - Piedecuesta",
    "label": "Santander - Piedecuesta"
  },
  {
    "value": "Santander - Pinchote",
    "label": "Santander - Pinchote"
  },
  {
    "value": "Santander - Puente nacional",
    "label": "Santander - Puente nacional"
  },
  {
    "value": "Santander - Puerto parra",
    "label": "Santander - Puerto parra"
  },
  {
    "value": "Santander - Puerto wilches",
    "label": "Santander - Puerto wilches"
  },
  {
    "value": "Santander - Rionegro",
    "label": "Santander - Rionegro"
  },
  {
    "value": "Santander - Sabana de torres",
    "label": "Santander - Sabana de torres"
  },
  {
    "value": "Santander - San andrés",
    "label": "Santander - San andrés"
  },
  {
    "value": "Santander - San benito",
    "label": "Santander - San benito"
  },
  {
    "value": "Santander - San gil",
    "label": "Santander - San gil"
  },
  {
    "value": "Santander - San joaquín",
    "label": "Santander - San joaquín"
  },
  {
    "value": "Santander - San josé de miranda",
    "label": "Santander - San josé de miranda"
  },
  {
    "value": "Santander - San miguel",
    "label": "Santander - San miguel"
  },
  {
    "value": "Santander - San vicente de chucurí",
    "label": "Santander - San vicente de chucurí"
  },
  {
    "value": "Santander - Santa bárbara",
    "label": "Santander - Santa bárbara"
  },
  {
    "value": "Santander - Santa helena",
    "label": "Santander - Santa helena"
  },
  {
    "value": "Santander - Simacota",
    "label": "Santander - Simacota"
  },
  {
    "value": "Santander - Socorro",
    "label": "Santander - Socorro"
  },
  {
    "value": "Santander - Suaita",
    "label": "Santander - Suaita"
  },
  {
    "value": "Santander - Sucre",
    "label": "Santander - Sucre"
  },
  {
    "value": "Santander - Surata",
    "label": "Santander - Surata"
  },
  {
    "value": "Santander - Tona",
    "label": "Santander - Tona"
  },
  {
    "value": "Santander - Valle san josé",
    "label": "Santander - Valle san josé"
  },
  {
    "value": "Santander - Vélez",
    "label": "Santander - Vélez"
  },
  {
    "value": "Santander - Vetas",
    "label": "Santander - Vetas"
  },
  {
    "value": "Santander - Villanueva",
    "label": "Santander - Villanueva"
  },
  {
    "value": "Santander - Zapatoca",
    "label": "Santander - Zapatoca"
  },
  {
    "value": "Sucre - Buenavista",
    "label": "Sucre - Buenavista"
  },
  {
    "value": "Sucre - Caimito",
    "label": "Sucre - Caimito"
  },
  {
    "value": "Sucre - Chalán",
    "label": "Sucre - Chalán"
  },
  {
    "value": "Sucre - Coloso",
    "label": "Sucre - Coloso"
  },
  {
    "value": "Sucre - Corozal",
    "label": "Sucre - Corozal"
  },
  {
    "value": "Sucre - Coveñas",
    "label": "Sucre - Coveñas"
  },
  {
    "value": "Sucre - El roble",
    "label": "Sucre - El roble"
  },
  {
    "value": "Sucre - Galeras",
    "label": "Sucre - Galeras"
  },
  {
    "value": "Sucre - Guaranda",
    "label": "Sucre - Guaranda"
  },
  {
    "value": "Sucre - La unión",
    "label": "Sucre - La unión"
  },
  {
    "value": "Sucre - Los palmitos",
    "label": "Sucre - Los palmitos"
  },
  {
    "value": "Sucre - Majagual",
    "label": "Sucre - Majagual"
  },
  {
    "value": "Sucre - Morroa",
    "label": "Sucre - Morroa"
  },
  {
    "value": "Sucre - Ovejas",
    "label": "Sucre - Ovejas"
  },
  {
    "value": "Sucre - Palmito",
    "label": "Sucre - Palmito"
  },
  {
    "value": "Sucre - Sampues",
    "label": "Sucre - Sampues"
  },
  {
    "value": "Sucre - San benito abad",
    "label": "Sucre - San benito abad"
  },
  {
    "value": "Sucre - San juan de betulia",
    "label": "Sucre - San juan de betulia"
  },
  {
    "value": "Sucre - San marcos",
    "label": "Sucre - San marcos"
  },
  {
    "value": "Sucre - San onofre",
    "label": "Sucre - San onofre"
  },
  {
    "value": "Sucre - San pedro",
    "label": "Sucre - San pedro"
  },
  {
    "value": "Sucre - Sincé",
    "label": "Sucre - Sincé"
  },
  {
    "value": "Sucre - Sincelejo",
    "label": "Sucre - Sincelejo"
  },
  {
    "value": "Sucre - Sucre",
    "label": "Sucre - Sucre"
  },
  {
    "value": "Sucre - Tolú",
    "label": "Sucre - Tolú"
  },
  {
    "value": "Sucre - Toluviejo",
    "label": "Sucre - Toluviejo"
  },
  {
    "value": "Tolima - Alpujarra",
    "label": "Tolima - Alpujarra"
  },
  {
    "value": "Tolima - Alvarado",
    "label": "Tolima - Alvarado"
  },
  {
    "value": "Tolima - Ambalema",
    "label": "Tolima - Ambalema"
  },
  {
    "value": "Tolima - Anzoategui",
    "label": "Tolima - Anzoategui"
  },
  {
    "value": "Tolima - Armero (guayabal)",
    "label": "Tolima - Armero (guayabal)"
  },
  {
    "value": "Tolima - Ataco",
    "label": "Tolima - Ataco"
  },
  {
    "value": "Tolima - Cajamarca",
    "label": "Tolima - Cajamarca"
  },
  {
    "value": "Tolima - Carmen de apicalá",
    "label": "Tolima - Carmen de apicalá"
  },
  {
    "value": "Tolima - Casabianca",
    "label": "Tolima - Casabianca"
  },
  {
    "value": "Tolima - Chaparral",
    "label": "Tolima - Chaparral"
  },
  {
    "value": "Tolima - Chicoral",
    "label": "Tolima - Chicoral"
  },
  {
    "value": "Tolima - Coello",
    "label": "Tolima - Coello"
  },
  {
    "value": "Tolima - Coyaima",
    "label": "Tolima - Coyaima"
  },
  {
    "value": "Tolima - Cunday",
    "label": "Tolima - Cunday"
  },
  {
    "value": "Tolima - Dolores",
    "label": "Tolima - Dolores"
  },
  {
    "value": "Tolima - Espinal",
    "label": "Tolima - Espinal"
  },
  {
    "value": "Tolima - Falán",
    "label": "Tolima - Falán"
  },
  {
    "value": "Tolima - Flandes",
    "label": "Tolima - Flandes"
  },
  {
    "value": "Tolima - Fresno",
    "label": "Tolima - Fresno"
  },
  {
    "value": "Tolima - Guamo",
    "label": "Tolima - Guamo"
  },
  {
    "value": "Tolima - Herveo",
    "label": "Tolima - Herveo"
  },
  {
    "value": "Tolima - Honda",
    "label": "Tolima - Honda"
  },
  {
    "value": "Tolima - Ibagué",
    "label": "Tolima - Ibagué"
  },
  {
    "value": "Tolima - Icononzo",
    "label": "Tolima - Icononzo"
  },
  {
    "value": "Tolima - Lérida",
    "label": "Tolima - Lérida"
  },
  {
    "value": "Tolima - Líbano",
    "label": "Tolima - Líbano"
  },
  {
    "value": "Tolima - Mariquita",
    "label": "Tolima - Mariquita"
  },
  {
    "value": "Tolima - Melgar",
    "label": "Tolima - Melgar"
  },
  {
    "value": "Tolima - Murillo",
    "label": "Tolima - Murillo"
  },
  {
    "value": "Tolima - Natagaima",
    "label": "Tolima - Natagaima"
  },
  {
    "value": "Tolima - Ortega",
    "label": "Tolima - Ortega"
  },
  {
    "value": "Tolima - Palocabildo",
    "label": "Tolima - Palocabildo"
  },
  {
    "value": "Tolima - Piedras",
    "label": "Tolima - Piedras"
  },
  {
    "value": "Tolima - Piedras planadas",
    "label": "Tolima - Piedras planadas"
  },
  {
    "value": "Tolima - Prado",
    "label": "Tolima - Prado"
  },
  {
    "value": "Tolima - Purificación",
    "label": "Tolima - Purificación"
  },
  {
    "value": "Tolima - Rioblanco",
    "label": "Tolima - Rioblanco"
  },
  {
    "value": "Tolima - Roncesvalles",
    "label": "Tolima - Roncesvalles"
  },
  {
    "value": "Tolima - Rovira",
    "label": "Tolima - Rovira"
  },
  {
    "value": "Tolima - Saldaña",
    "label": "Tolima - Saldaña"
  },
  {
    "value": "Tolima - San antonio",
    "label": "Tolima - San antonio"
  },
  {
    "value": "Tolima - San luis",
    "label": "Tolima - San luis"
  },
  {
    "value": "Tolima - Santa isabel",
    "label": "Tolima - Santa isabel"
  },
  {
    "value": "Tolima - Suárez",
    "label": "Tolima - Suárez"
  },
  {
    "value": "Tolima - Valle de san juan",
    "label": "Tolima - Valle de san juan"
  },
  {
    "value": "Tolima - Venadillo",
    "label": "Tolima - Venadillo"
  },
  {
    "value": "Tolima - Villahermosa",
    "label": "Tolima - Villahermosa"
  },
  {
    "value": "Tolima - Villarrica",
    "label": "Tolima - Villarrica"
  },
  {
    "value": "Valle del cauca - Alcalá",
    "label": "Valle del cauca - Alcalá"
  },
  {
    "value": "Valle del cauca - Andalucía",
    "label": "Valle del cauca - Andalucía"
  },
  {
    "value": "Valle del cauca - Anserma nuevo",
    "label": "Valle del cauca - Anserma nuevo"
  },
  {
    "value": "Valle del cauca - Argelia",
    "label": "Valle del cauca - Argelia"
  },
  {
    "value": "Valle del cauca - Bolívar",
    "label": "Valle del cauca - Bolívar"
  },
  {
    "value": "Valle del cauca - Buenaventura",
    "label": "Valle del cauca - Buenaventura"
  },
  {
    "value": "Valle del cauca - Buga",
    "label": "Valle del cauca - Buga"
  },
  {
    "value": "Valle del cauca - Bugalagrande",
    "label": "Valle del cauca - Bugalagrande"
  },
  {
    "value": "Valle del cauca - Caicedonia",
    "label": "Valle del cauca - Caicedonia"
  },
  {
    "value": "Valle del cauca - Cali",
    "label": "Valle del cauca - Cali"
  },
  {
    "value": "Valle del cauca - Calima (darien)",
    "label": "Valle del cauca - Calima (darien)"
  },
  {
    "value": "Valle del cauca - Candelaria",
    "label": "Valle del cauca - Candelaria"
  },
  {
    "value": "Valle del cauca - Cartago",
    "label": "Valle del cauca - Cartago"
  },
  {
    "value": "Valle del cauca - Dagua",
    "label": "Valle del cauca - Dagua"
  },
  {
    "value": "Valle del cauca - El aguila",
    "label": "Valle del cauca - El aguila"
  },
  {
    "value": "Valle del cauca - El cairo",
    "label": "Valle del cauca - El cairo"
  },
  {
    "value": "Valle del cauca - El cerrito",
    "label": "Valle del cauca - El cerrito"
  },
  {
    "value": "Valle del cauca - El dovio",
    "label": "Valle del cauca - El dovio"
  },
  {
    "value": "Valle del cauca - Florida",
    "label": "Valle del cauca - Florida"
  },
  {
    "value": "Valle del cauca - Ginebra guacari",
    "label": "Valle del cauca - Ginebra guacari"
  },
  {
    "value": "Valle del cauca - Jamundí",
    "label": "Valle del cauca - Jamundí"
  },
  {
    "value": "Valle del cauca - La cumbre",
    "label": "Valle del cauca - La cumbre"
  },
  {
    "value": "Valle del cauca - La unión",
    "label": "Valle del cauca - La unión"
  },
  {
    "value": "Valle del cauca - La victoria",
    "label": "Valle del cauca - La victoria"
  },
  {
    "value": "Valle del cauca - Obando",
    "label": "Valle del cauca - Obando"
  },
  {
    "value": "Valle del cauca - Palmira",
    "label": "Valle del cauca - Palmira"
  },
  {
    "value": "Valle del cauca - Pradera",
    "label": "Valle del cauca - Pradera"
  },
  {
    "value": "Valle del cauca - Restrepo",
    "label": "Valle del cauca - Restrepo"
  },
  {
    "value": "Valle del cauca - Rio frío",
    "label": "Valle del cauca - Rio frío"
  },
  {
    "value": "Valle del cauca - Roldanillo",
    "label": "Valle del cauca - Roldanillo"
  },
  {
    "value": "Valle del cauca - San pedro",
    "label": "Valle del cauca - San pedro"
  },
  {
    "value": "Valle del cauca - Sevilla",
    "label": "Valle del cauca - Sevilla"
  },
  {
    "value": "Valle del cauca - Toro",
    "label": "Valle del cauca - Toro"
  },
  {
    "value": "Valle del cauca - Trujillo",
    "label": "Valle del cauca - Trujillo"
  },
  {
    "value": "Valle del cauca - Tulúa",
    "label": "Valle del cauca - Tulúa"
  },
  {
    "value": "Valle del cauca - Ulloa",
    "label": "Valle del cauca - Ulloa"
  },
  {
    "value": "Valle del cauca - Versalles",
    "label": "Valle del cauca - Versalles"
  },
  {
    "value": "Valle del cauca - Vijes",
    "label": "Valle del cauca - Vijes"
  },
  {
    "value": "Valle del cauca - Yotoco",
    "label": "Valle del cauca - Yotoco"
  },
  {
    "value": "Valle del cauca - Yumbo",
    "label": "Valle del cauca - Yumbo"
  },
  {
    "value": "Valle del cauca - Zarzal",
    "label": "Valle del cauca - Zarzal"
  },
  {
    "value": "Vaupés - Carurú",
    "label": "Vaupés - Carurú"
  },
  {
    "value": "Vaupés - Mitú",
    "label": "Vaupés - Mitú"
  },
  {
    "value": "Vaupés - Pacoa",
    "label": "Vaupés - Pacoa"
  },
  {
    "value": "Vaupés - Papunaua",
    "label": "Vaupés - Papunaua"
  },
  {
    "value": "Vaupés - Taraira",
    "label": "Vaupés - Taraira"
  },
  {
    "value": "Vaupés - Yavaraté",
    "label": "Vaupés - Yavaraté"
  },
  {
    "value": "Vichada - Cumaribo",
    "label": "Vichada - Cumaribo"
  },
  {
    "value": "Vichada - La primavera",
    "label": "Vichada - La primavera"
  },
  {
    "value": "Vichada - Puerto carreño",
    "label": "Vichada - Puerto carreño"
  },
  {
    "value": "Vichada - Santa rosalia",
    "label": "Vichada - Santa rosalia"
  }
]
}

export { deptos };
