import React, { Fragment, useState } from 'react'
import { Card, Form} from 'react-bootstrap'

import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { useDispatch, useSelector } from 'react-redux';
import { agregarBeneficiario, editarBeneficiario, eliminarBeneficiario } from '../../../../reducers/editSolicitudes';

import EditModal from '../../../modales/EditModal'
import NotificationModal from '../../../modales/NotificationModal'

import './Entrevistas.scss'

export default function Entrevistas(props) {

  const setFormState = props.setFormState
  const formState = props.formState
  
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false);
  const { beneficiarios } = useSelector(state => state.edicionSolicitud)
  const [identificacionError, setIdentificacionError] = useState('')

  const [formNew, setFormNew] = useState({
    identificacion: '',
    identificacionTipo: '',
    primerNombre: '',
    segundoNombre: '',
    primerApellido: '',
    segundoApellido: '',
    parentesco: ''

  })


  const [formEdit, setFormEdit] = useState({
      identificacion: '',
      identificacionTipo: '',
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      parentesco: ''

  })

  const handleInputChangeNew = ({ target }) => {

    setFormNew({
        ...formNew,
        [target.name]: target.value
    })
  }


  const handleInputChangeEdit = ({ target }) => {
    setFormEdit({
        ...formEdit,
        [target.name]: target.value
    })
  }

  const submitForm = (e) => {
    e.preventDefault()
    setIdentificacionError('')

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
    } else {
        let id = false
        beneficiarios.forEach((ele) => {
            if (ele.identificacion === formNew.identificacion) {
                id = true
            }
        })
        if (id) {
            setIdentificacionError('Numero de identificacion ya esta registrado')
        } else {

            dispatch(agregarBeneficiario(formNew))

            // console.log(identificacionError)
        }

    }
    setValidated(true);

  }

  const [deleteModal, setDeleteModal] = useState(false)

  const handleEliminar = (id) => {
      dispatch(eliminarBeneficiario(id))
      setDeleteModal(false)
  }

  const [editRows, setEditRows] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const handleEdit = (ele, index) => {
      if (editRows[1] === index) {
          setEditRows(false)
      } else {
          setEditRows([ele, index])
          setEditModal(true)
      }


  }

  const handleEditElement = (ele) => {

    dispatch(editarBeneficiario(ele, formEdit))
    setEditRows(false)
    setFormEdit({
        identificacion: '',
        identificacionTipo: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        parentesco: ''
    })

    setEditModal(false)
}


  return (
    <>
    <Card className='shadow cardGeneral'>
      <Card.Body>
        <div className="" style={{ display: 'flex', justifyContent:'space-between', alignItems:'center' }}>
        <h4 className='titleCard'> Entrevistas</h4>
        {props.dataSolicitud.entrevistasOpcionAgregar === true
            ?
            <div className="addIconContainer" >
                <IconButton onClick={() => setFormState(!formState)}>
                    <AddIcon fontSize='large' style={{ background: '#213265', borderRadius: '50%', color: 'white' }} />
                </IconButton>
            </div>
            : null
        }
        </div>
        <br/>
        {
            formState
            &&
            <Form className='formAddEntrevistaContainer' noValidate validated={validated} onSubmit={submitForm}>
                <div className='formItem' id='basicFormA'>
                    <Form.Group>
                        <Form.Label>Beneficiario *</Form.Label>
                        <Form.Control as='select'>
                            <option>juan perez</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tipo *</Form.Label>
                        <Form.Control as='select'>
                            <option default selected>Seleccione una opción</option>
                            <option>Solicitante</option>
                            <option>Denunciante</option>
                            <option>Familiar</option>
                            <option>Testigo extraproceso</option>
                            <option>Vecino</option>
                            <option>Labores de campo</option>
                            <option>Familiares</option>
                            <option>Declarantes</option>
                            <option>Testigo</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='formItem' id='basicFormB'>
                    <div className='namesContainer'>
                        <Form.Group>
                            <Form.Label>Telefono</Form.Label>
                                <Form.Control name='telefono_testigo' required type='text' placeholder='telefono del testigo'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputChangeNew}

                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa el teléfono del testigo</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Dirección</Form.Label>
                                <Form.Control name='direccion_testigo' required type='text' placeholder='Dirección del testigo'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputChangeNew}

                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingrese la dirección del testigo</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className='namesContainer'>
                        <Form.Group>
                            <Form.Label>Fecha de expedición</Form.Label>
                                <Form.Control name='primerNombre' required type='date'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputChangeNew}

                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingrese la fecha de expedición</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ciudad</Form.Label>
                                <Form.Control name='primerNombre' required type='text' placeholder='Ciudad de residencia del testigo'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputChangeNew}

                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingrese la ciudad de residencia del testigo</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className='formItem' id='basicFormC'>
                    <Form.Group>
                        <Form.Label>
                            Nombres
                        </Form.Label>
                        <div className='namesContainer'>
                            <div >
                                <Form.Control name='primerNombre' required type='text' placeholder='Primer nombre'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputChangeNew}

                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa el primer nombre</Form.Control.Feedback>

                            </div>
                            <div>
                                <Form.Control  name='segundoNombre' required type='text'
                                    placeholder='Segundo nombre'
                                    onChange={handleInputChangeNew}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa el segundo nombre</Form.Control.Feedback>
                            </div>
                        </div>
                    </Form.Group>

                  <Form.Group>
                      <Form.Label>
                          Apellidos
                  </Form.Label>
                      <div className='namesContainer'>
                          <div>
                          <Form.Control name='primerApellido' required type='text'
                              // className="place"
                              placeholder='Primer apellido' style={{ marginRight: '10px' }}
                              onChange={handleInputChangeNew}

                          ></Form.Control>
                          <Form.Control.Feedback type='invalid'>Ingresa el primer apellido</Form.Control.Feedback>

                          </div>
                          <div>
                          <Form.Control name='segundoApellido' required type='text'
                              // className="place"
                              placeholder='Segundo apellido'
                              onChange={handleInputChangeNew}
                          ></Form.Control>
                          <Form.Control.Feedback type='invalid'>Ingresa el segundo apellido</Form.Control.Feedback>

                          </div>
                      </div>

                  </Form.Group>

              </div>
                <div className='formItem' id='basicFormD'>
                    <Form.Group>
                        <Form.Label>
                            Identificación
                        </Form.Label>
                        <div className='idContainer'>
                            <Form.Control name='identificacionTipo' id='typeIdSelect' as='select' custom required
                                onChange={handleInputChangeNew}
                                // className="place"
                            >
                                <option disabled selected >Tipo id</option>
                                <option value="CC">Cedula de ciudadanía</option>
                                <option value="CE">Cédula extranjera</option>
                                <option value="PA">Pasaporte aéreo</option>
                                <option value="AS">A.S</option>
                            </Form.Control>
                            <div>
                                <Form.Control 
                                    name='identificacion' 
                                    required 
                                    min='0'
                                    max='9999999999'
                                    type='number' 
                                    placeholder='Identificación'
                                    onChange={handleInputChangeNew}

                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa identificación</Form.Control.Feedback>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Parentesco
                    </Form.Label>
                        <div className='inputContainer'>
                            <Form.Control name='parentesco' id='typeIdSelect' as='select' custom required
                                className="place" style={{ width: '100%' }}
                                onChange={handleInputChangeNew}
                            >
                                <option disabled selected>Parentesco</option>
                                <option value="Padre">Padre</option>
                                <option value="Hijo(a)">Hijo(a)</option>
                                <option value="Hijo(a) Menor">Hijo(a) Menor</option>
                                <option value="Hijo(a) Estudiante">Hijo(a) Estudiante</option>
                                <option value="Hermano(a)">Hermano(a)</option>
                                <option value="Hermano(a) Invalido">Hermano(a) Invalido</option>
                                <option value="Conyuge/Compañero(a)">Conyuge/Compañero(a)</option>
                                <option value="Otro">Otro</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                </div>
                <div className='formItem' id='basicFormE'>
                    <div className='namesContainer'>
                        <Form.Group>
                            <Form.Label>Lugar de entrevista</Form.Label>
                                <Form.Control name='primerNombre' required type='text' placeholder='Lugar de la entrevista'
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputChangeNew}

                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingrese el lugar de la entrevista</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tipo de entrevista</Form.Label>
                                <Form.Control name='primerNombre' required as='select' 
                                    style={{ marginRight: '10px' }}
                                    onChange={handleInputChangeNew}
                                >
                                    <option disabled>Seleccione un opción</option>
                                    <option>Presencial</option>
                                    <option>Telefónica</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingrese el tipo de entrevista</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                
                <div id='observacionesAddBeneficiario'>
                    <Form.Group id='observacionesFormGroup'>
                        <Form.Label className='observacionesLabel'>
                            Entrevista
                        </Form.Label>
                            <Form.Control name='observaciones' id='observacionesTextArea' required as='textarea'
                                placeholder='Entrevista'
                                // className="place"
                                onChange={handleInputChangeNew}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>Detalle la entrevista</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='buttonContainer' id='confirmButtonAddBeneficiarioContainer'>
                    <button type="submit" className='confirmButton'>
                        enviar
                    </button>
                </div>
            </Form>
        }
      </Card.Body>
      
    </Card>
    <br/>
    <Card className="shadow " style={{ borderRadius: '10px' }}>
                <Card.Body>
                    <h6 className='pt-4' style={{ color: '#707070' }}>Lista de entrevistas</h6>
                    {
                        beneficiarios.length === 0
                            ? <div className="container text-center">
                                <h3 className='p-4' style={{ color: '#707070' }}>Aún no se han realizado entrevistas.</h3>
                            </div>

                            : <div className='tableContainer'>

                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Identificación</th>
                                        <th>Primer nombre</th>
                                        <th>Segundo nombre</th>
                                        <th>Primer Apellido</th>
                                        <th>Segundo Apellido</th>
                                        <th>Parentesco</th>
                                        <th>Observaciones</th>
                                        {props.dataSolicitud.entrevistasOpcionEditar === true || props.dataSolicitud.entrevistasOpcionEliminar === true
                                            ? <th>Acciones</th>
                                            : null
                                        }
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        beneficiarios.map((ele, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{ele.identificacionTipo + ' ' + ele.identificacion}</td>
                                                    <td>{ele.primerNombre}</td>
                                                    <td>{ele.segundoNombre}</td>
                                                    <td>{ele.primerApellido}</td>
                                                    <td>{ele.segundoApellido}</td>
                                                    <td>{ele.parentesco}</td>
                                                    <td>{ele.observaciones}</td>
                                                    {props.dataSolicitud.entrevistasOpcionEditar === true || props.dataSolicitud.entrevistasOpcionEliminar === true
                                                        ?
                                                        <td style={{ display: 'flex', justifyContent:'space-evenly' }}>
                                                            {props.dataSolicitud.entrevistasOpcionEditar === true
                                                                ?
                                                                <div className='editBeneficiarios buttonBeneficiarios'   onClick={() => handleEdit(ele, index)} >
                                                                    <EditIcon fontSize='small'/>
                                                                    Editar
                                                                </div>
                                                                : null
                                                            }
                                                            {props.dataSolicitud.entrevistasOpcionEliminar === true
                                                                ?
                                                                <div className='deleteBeneficiarios buttonBeneficiarios'  onClick={() => setDeleteModal(true)}>
                                                                    <DeleteIcon fontSize='small'/>
                                                                    Eliminar
                                                                </div>
                                                                : null
                                                            }
                                                            <EditModal isOpen={editModal} setIsOpen={setEditModal} typeModal='EditBeneficiario' editHandler={() => handleEditElement(ele)}>
                                                                <div className='formEditBeneficiarioContainer'>
                                                                <div>
                                                                    <label>Identificación</label>
                                                                </div>
                                                                <div className="" id='idContainerEditBeneficiario'>
                                                                    <select
                                                                        defaultValue={ele.identificacionTipo}
                                                                        name="identificacionTipo"
                                                                        onChange={handleInputChangeEdit}
                                                                        className="noneStiles">
                                                                        <option value="" selected>seleccione su tipo de identificación</option>
                                                                        <option value="CC" >Cédula de ciudadanía</option>
                                                                        <option value="CE" >Cédula de extranjería</option>
                                                                    </select>
                                                                    <input
                                                                        
                                                                        onChange={handleInputChangeEdit}
                                                                        name="identificacion"
                                                                        defaultValue={ele.identificacion}
                                                                    />
                                                                </div>
                                                                <div className='nombresContenedor'>
                                                                    <div className='nombresContainerEditBeneficiario'>
                                                                    <label>Nombres</label>
                                                                    <input placeholder="Primer nombre"
                                                                            defaultValue={ele.primerNombre}
                                                                            onChange={handleInputChangeEdit}
                                                                            name="primerNombre"
                                                                            />
                                                                    <input
                                                                        placeholder="Segundo nombre"
                                                                        defaultValue={ele.segundoNombre}
                                                                        onChange={handleInputChangeEdit}
                                                                        name="segundoNombre"
                                                                        />
                                                                            
                                                                    </div>
                                                                    <div className='nombresContainerEditBeneficiario'>
                                                                        <label>Apellidos</label>
                                                                        <input
                                                                        placeholder="Primer apellido"
                                                                            defaultValue={ele.primerApellido}
                                                                            onChange={handleInputChangeEdit}
                                                                            name="primerApellido"
                                                                            />
                                                                        <input
                                                                            placeholder="Segundo apellido"
                                                                            defaultValue={ele.segundoApellido}
                                                                            onChange={handleInputChangeEdit}
                                                                            name="segundoApellido"
                                                                            />
                                                                    </div>

                                                                </div>
                                                                    <label>Parentesco</label>
                                                                    <select
                                                                    defaultValue={ele.parentesco}
                                                                    name="parentesco"
                                                                    onChange={handleInputChangeEdit}
                                                                    className="noneStiles2">
                                                                        <option value="" selected>selecciona</option>

                                                                        <option value="Padre">Padre</option>
                                                                        <option value="Hijo(a)"> Hijo(a)</option>
                                                                        <option value="Hijo(a) Menor"> Hijo(a) Menor</option>
                                                                        <option value="Hijo(a) Estudiante">Hijo(a) Estudiante</option>
                                                                        <option value="Hijo(a) invalido"> Hijo(a) invalido</option>
                                                                        <option value="Hermano(a)"> Hermano(a)</option>
                                                                        <option value="Hermano(a) Invalido">Hermano(a) Invalido</option>
                                                                        <option value="Conyuge/Compañero(a)"> Conyuge/Compañero(a)</option>
                                                                        <option value="Otro">Otro</option>
                                                                    </select>

                                                                </div>
                                                                <div className='formEditBeneficiarioObservacionesContainer'>
                                                                    <label>Observaciones</label>
                                                                    <textarea
                                                                    id='inputObservacionesBeneficiarios'
                                                                    defaultValue={ele.observaciones}
                                                                    name="observaciones"
                                                                    onChange={handleInputChangeEdit}
                                                                    />
                                                                </div>
                                                            </EditModal>
                                                            <NotificationModal isOpen={deleteModal} setIsOpen={setDeleteModal} typeModal='deleteSeguimientoConfirm' handleEliminar={() => handleEliminar(ele.identificacion)} ></NotificationModal>
                                                        </td>
                                                        : null
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                            </div> 

                    }

                </Card.Body>
            </Card>
    </>
  )
}
