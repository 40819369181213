import {React, useState} from 'react'
import {Form, Card} from 'react-bootstrap'

// import AddIcon from '@material-ui/icons/Add';
// import { FormatListNumberedRtlOutlined } from '@material-ui/icons';

import './RegistroFotografico.scss'


export default function RegistroFotografico(props) {

  const idRegistro = props.idSolicitud;
  const URL_BASE = `https://www.ciacosinte.site/cia2/editar_investigacion.html?id=${idRegistro}`;

  const dataFiles = [
    {
      id: '1',
      nombre: 'elegante.jpg',
      peso: '400mb',
    },
    {
      id: '2',
      nombre: 'hojadevida.pdf',
      peso: '800mb',
    },
    {
      id: '3',
      nombre: 'copiacedula.pdf',
      peso: '345kb',
    },
    {
      id: '4',
      nombre: 'diploma-grado.pdf',
      peso: '345kb',
    },
  ];

  const [validated, setValidated] = useState(false)
  const [data, setData] = useState(dataFiles);
  // const [modalEliminar, setModalEliminar] = useState(false)
  const [notificationOpen, setNotificationOpen] = useState(false)


  const [dataFormFotos, setDataFormFotos] = useState({})

  const handleChangeAddFoto = ({target}) => {
    setDataFormFotos({
      [target.name] : target.value
    })
    // //console.log(dataFormFotos);
  }

  const submitFormRegistroFotografico = async (e) => {
    e.preventDefault()

    const formFotos = e.currentTarget;
    
    if (formFotos.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
    }
    
    // //console.log(formFotos)

    setValidated(true);
    setNotificationOpen(true);

    await fetch(URL_BASE, {
        method: 'POST',
        headers: {
            'content-Type' : 'application/json'
        },
        body: JSON.stringify(dataFormFotos)
    })
    
    
  }

  return (
    <Card className='shadow cardGeneral'>
      <Card.Body>
        <h4 className='titleCard'>registroFotografico</h4>
        <br/>
        <Form noValidate onSubmit={submitFormRegistroFotografico}>
          {/* <Form.File>

          </Form.File> */}
          {props.dataSolicitud.registroFotograficoOpcionAgregar === true
            ?
              <div className='buttonAddFotoContainer'>
                {/* ENLACE PARA CARGAR DOCUMENTO : https://jquery-file-upload.appspot.com/ */}
              <input id='selectFoto' onChange={handleChangeAddFoto} label='Seleccionar'  name='selectFile'  type='file' />
              
                <button type='submit' className='uploadFoto'>Cargar</button>
            </div>
            : null
          }

        </Form>
        <br/>
        <h5 className='titleCard'>Lista de archivos fotográficos </h5>
        <br/>
        <div className='filesTableContainer'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre del archivo</th>
              <th>Peso del archivo</th>
              {props.dataSolicitud.registroFotograficoOpcionEliminar === true
                ? <th>Acciones</th>
                : null
              }
              

            </tr>
          </thead>
          <tbody>
            {data.map( item => {
              return(
                <tr className='fileRow' key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.nombre}</td>
                  <td>{item.peso}</td>
                  {props.dataSolicitud.registroFotograficoOpcionEliminar === true
                    ?
                    <td>
                      {/* <button className='deleteButton'  onClick={(e) => deleteFile(item)}>Eliminar</button> */}
                    </td>
                    : null
                  }
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      </Card.Body>
    </Card>
  )
}
