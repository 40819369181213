import React, { useEffect, useState } from 'react'
import { IconSVG } from "../components/icons/CloseSVG.jsx";
import { Col, Row, Modal } from 'react-bootstrap';
import { validarEntorno } from './validarHost';
import { PopUpModal } from '../helpers/PopUpModal';
import { HelpSVG } from "../components/icons/HelpSVG";
import { useTranslation } from 'react-i18next';

const ModalHelp = (props) => {
  const { t } = useTranslation();
  const hash = window.location.hash
  const [dataModal, setDataModal] = useState({})
  const [open, setOpen] = useState(false)

  function openModal() {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false)
}


useEffect(() => {
getDataModal()
}, [])

  const getDataModal = async () =>{

    const dataReq = {
      hash: hash
    }

    const req = await validarEntorno('JSON/pop_up_modal.html', 'POST', dataReq)

    if(req.ok){
      const res = await req.json()

      setDataModal({
        ...dataModal,
        data: res,
        titulo: res.titulo,
        texto0: res.data.texto0,
        texto1: res.data.texto1,
        texto2: res.data.texto2

      })
      console.log(dataModal)
    }

  }



  return (

      <>
      <Modal
        className="loginModal modalesGenerales"
        show={open}
        centered
        onHide={handleClose}
      >

        <Modal.Header>
          <Modal.Title>
            <h4 className="pagesTitles">{t('gestion_realizada.plan_estrategico.estado_actividades')}</h4>
          </Modal.Title>
          <div onClick={handleClose}>
            <IconSVG />
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="leyendas-item" style={{display: "flex"}}>
        <div style={{margin: "0px 5px 5px 0px", minWidth: "20px", minHeight: "20px", backgroundColor: "#001250"}}> </div>
        {t('gestion_realizada.plan_estrategico.completo')}
        </div>

        <div className="leyendas-item" style={{display: "flex"}}>
        <div style={{margin: "0px 5px 5px 0px", minWidth: "20px", minHeight: "20px", backgroundColor: "#F08700"}}> </div>
        {t('gestion_realizada.plan_estrategico.pendiente')}
        </div>
        <div className="leyendas-item" style={{display: "flex"}}>
        <di style={{margin: "0px 5px 0px 0px", minWidth: "20px", minHeight: "20px", backgroundColor: "#3c7a89"}}> </di>
        {t('gestion_realizada.plan_estrategico.proceso')}
        </div>
        </Modal.Body>
      </Modal>

      <div
        style={{ position: "absolute", right: 15, cursor: "pointer", paddingRight:'3%'}}
        onClick={() => {
        openModal();
        }}
      >
        <HelpSVG />
      </div>

      </>




  )
}

export default ModalHelp
