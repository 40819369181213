import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validarEntorno } from '../../../helpers/validarHost';
import { Card, Row, Col, CardDeck } from 'react-bootstrap';
import EditModal from '../../modales/EditModal';
import NotificationModal from '../../modales/NotificationModal';
import '../../../root.scss';
import './MatrizRiesgosOperaciones.scss';
import Loader from '../../icons/Loader';

import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import { useEventBus } from '../../../context/EventBus';

const MatrizRiesgosOperacionesDinamico = (props) => {
  const eventBus = useEventBus();
  const { t } = useTranslation();

  const [riesgos, setRiesgos] = useState([]);
  const [tipoModal, setTipoModal] = useState('');
  const [activeRequired, setActiveRequired] = useState(false);
  const [idRiesgo, setIdRiesgo] = useState('');
  const [impact, setImpact] = useState('');
  const [range, setRange] = useState('');
  const [probabilidad, setProbabilidad] = useState(0);
  const [detalleRiesgo, setDetalleRiesgo] = useState({
    data: {},
    show: false
  });
  const [validated, setValidated] = useState(false);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [valueImpacto, setValueImpacto] = useState({
    economico: 0,
    legal: 0,
    operativo: 0,
    informacion: 0,
    credibilidad: 0,
    talento: 0,
  });

  const [initialState, setInitialState] = useState({
    data: {}
  })

  const [tabForm, setTabForm] = useState('causas');

  const handleCloseModalNotification = () => { setShowModalNotification(false) };

  const id_solicitud = useParams();

  const showRiesgoDetails = (riesgo) => {
    // //console.log(riesgo);
    setDetalleRiesgo({ ...detalleRiesgo, data: riesgo, show: !detalleRiesgo.show });
    setIdRiesgo(riesgo.id ? riesgo.id : '');
    setActiveRequired(riesgo.activo ? riesgo.activo : '');
    setImpact(riesgo.impacto ? riesgo.impacto : '');
    setRange(riesgo.rango ? riesgo.rango : '');
    setTipoModal('detalleRiesgoAuditoria');
    setProbabilidad(riesgo.probabilidad ? riesgo.probabilidad : 0);
    if (!detalleRiesgo.show) {
      setValueImpacto({
        economico: riesgo.impacto_administrativo.economico,
        legal: riesgo.impacto_administrativo.legal,
        operativo: riesgo.impacto_administrativo.operativo,
        informacion: riesgo.impacto_reputacional.informacion,
        credibilidad: riesgo.impacto_reputacional.credibilidad,
        talento: riesgo.impacto_reputacional.talento,
      });
    } else {
      setValueImpacto({
        economico: 0,
        legal: 0,
        operativo: 0,
        informacion: 0,
        credibilidad: 0,
        talento: 0,
      });
    }
  }

  const items = (filtros) => {
    const dataEnviar = {
      id_solicitud,
      hash: window.location.hash,
    }

    if (filtros) {
      dataEnviar.filtros = filtros;
    }

    validarEntorno('JSON/data_riesgos_gestion_auditorias_json.html', 'POST', dataEnviar).then(res => {
      if (res.ok) {
        res.json()
          .then(dataRespuesta => {
            setInitialState({ ...initialState, data: dataRespuesta })
            setRiesgos(dataRespuesta.riesgos)
          });
      }
    });
  }

  useEffect(() => {
    items();
  }, [])

  const handleSelectValues = (e) => {

    const split_input = e.currentTarget.name.split('_');

    const temp_valueImpacto = { ...valueImpacto, [split_input[2]]: parseInt(e.currentTarget.value) }
    setValueImpacto(temp_valueImpacto);
    const getObjectImpact = temp_valueImpacto;

    let sum = 0;
    let avg = 0;

    for (let property in getObjectImpact) {
      if (getObjectImpact[property] > 0) {
        sum += parseInt(getObjectImpact[property]);
        avg++;
      }
    }

    let imp = 0;
    if (avg !== 0) {
      imp = Math.ceil(sum / avg);
    }

    setImpact(Math.ceil(sum / avg));
    setRange(probabilidad * imp);
  }

  const submitData = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      //console.log(form);
      e.stopPropagation();
      setValidated(true);
    } else {

      const dataForm = new FormData(e.target);

      const result = {};

      for (let [key, value] of dataForm.entries()) {
        result[key] = value;
      }

      const data_riesgo = {
        id_riesgo: idRiesgo,
        acciones: result.acciones,
        activo: result.activo === 'on' ? true : false,
        causas: result.causas,
        consecuencias: result.consecuencias,
        impacto: result.impacto,
        rango: result.rango,
        responsable: result.responsable,
        impacto_administrativo: {
          economico: result.impacto_administrativo_economico ? result.impacto_administrativo_economico : '',
          legal: result.impacto_administrativo_legal ? result.impacto_administrativo_legal : '',
          operativo: result.impacto_administrativo_operativo ? result.impacto_administrativo_operativo : '',
        },
        impacto_reputacional: {
          informacion: result.impacto_reputacional_informacion ? result.impacto_reputacional_informacion : '',
          credibibilidad: result.impacto_reputacional_credibilidad ? result.impacto_reputacional_credibilidad : '',
          talento_humano: result.impacto_reputacional_talento ? result.impacto_reputacional_talento : ''
        }
      }

      const dataSend = {
        data_riesgo,
        id_solicitud,
        hash: window.location.hash,
      };

      const response = await validarEntorno('JSON/respuesta_actualizacion_riesgo_json.html', 'POST', dataSend);

      if (response.ok) {
        const respuesta = await response.json();

        if (respuesta.data.message) {
          setDetalleRiesgo({ ...detalleRiesgo, show: !detalleRiesgo.show });
          setShowModalNotification(true);
          items();
        }
      }
    }
  }

  const impactText = (value) => {
    let impact = "";

    switch (value) {
      case 1:
        impact = t('gestion_realizada.matriz_riesgos.minimo')
        break;
      case 2:
        impact = t('gestion_realizada.matriz_riesgos.leve')
        break;
      case 3:
        impact = t('gestion_realizada.matriz_riesgos.moderado')
        break;
      case 4:
        impact = t('gestion_realizada.matriz_riesgos.alto')
        break;
      case 5:
        impact = t('gestion_realizada.matriz_riesgos.critico')
        break;
      default:
        impact = "-"
        break;
    }
    return impact;
  }

  useEffect(() => {
    const handleCallback = (filtros) => {
      items(filtros);
    }
    eventBus?.subscribe('FILTROS_ACTUALIZADOS', handleCallback);
    return () => {
      eventBus?.unsubscribe('FILTROS_ACTUALIZADOS', handleCallback);
    };
  }, [eventBus]);

  return (
    <>
      {riesgos.length > 0 ? (
        <Card as={Row} className="card-riesgos shadow">
          <Card.Title>
            {/*
            {props.data.pdf && props.data.pdf !== '' &&
              <div className='buttonContainerDOFA'>
                <a href={props.data.pdf} target='_blank' >
                  <button style={{ width: '130px' }} className='confirmButton'>
                    {t('botones.generar_pdf')}
                    <GetAppIcon />
                  </button>
                </a>
              </div>
            }
            */}
          </Card.Title>
          <Card.Body className="main-card-body">
            <div id='contenedorCardsRiesgos'>
              {(riesgos.map((riesgo, index) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <div key={riesgo.id} style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={() => showRiesgoDetails(riesgo)}>
                  <Card
                    key={index}
                    className={
                      !riesgo.activo ? 'card-riesgo riesgoInactivo' : (riesgo.rango >= '15' && riesgo.rango <= '25') ? 'card-riesgo riesgo5' : (riesgo.rango >= '8' && riesgo.rango <= '12') ? 'card-riesgo riesgo4' : (riesgo.rango >= '5' && riesgo.rango <= '6') ? 'card-riesgo riesgo3' : (riesgo.rango >= '3' && riesgo.rango <= '4') ? 'card-riesgo riesgo2' : (riesgo.rango >= '1' && riesgo.rango <= '2') ? 'card-riesgo riesgo1' : 'card-riesgo riesgoInactivo'
                    }

                  >
                    <Card.Body>
                      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <div className='dataGeneralRiesgo'>
                          <div className='contenedorDatoRiesgo'>
                            <p className='labelDatoRiesgo'>{t('gestion_realizada.matriz_riesgos.probabilidad')}</p>
                            <p className='dataDatoRiesgo' >{riesgo.probabilidad}</p>
                          </div>
                          <div className='contenedorDatoRiesgo'>
                            <p className='labelDatoRiesgo'>{t('gestion_realizada.matriz_riesgos.impacto')}</p>
                            <p className='dataDatoRiesgo' >{impactText(riesgo.impacto)}</p>
                          </div>
                        </div>
                        <div className='rangoRiesgo'>
                          <p style={{ fontSize: '3rem', margin: '0', fontWeight: '800' }}>{riesgo.rango}</p>
                          <p style={{ fontSize: '1.05rem', margin: '0', fontWeight: '600' }} >{t('gestion_realizada.matriz_riesgos.rango')}</p>
                        </div>
                      </div>
                    </Card.Body>
                    <p className={riesgo.activo ? 'tipoRiesgo' : 'tipoRiesgo inactivo'} >
                      {riesgo.tipo}
                    </p>
                  </Card>
                </div>
              )))}
            </div>
          </Card.Body>
        </Card>
      ) : <Loader />}

      <EditModal
        typeModal={tipoModal}
        setIsOpen={showRiesgoDetails}
        dataRiesgo={detalleRiesgo.data}
        isOpen={detalleRiesgo.show}
        validated={validated}
        submitData={submitData}
        activeRequired={activeRequired}
        setActiveRequired={setActiveRequired}
        handleSelectValues={handleSelectValues}
        range={range}
        impact={impact}
        tabForm={tabForm}
        setTabForm={setTabForm}
        permisos={initialState.data?.permisos}
      >
        {/* <h3>holiaaaass</h3> */}
      </EditModal>
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal="updateRisks"
      />
    </>
  );
}

export default MatrizRiesgosOperacionesDinamico;
