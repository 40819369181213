import React, { useEffect, useState } from 'react'
import { Col, Card, Row } from 'react-bootstrap';
import Filtros from './FIltros';
import Tabla from './Tabla';
import NotificationModal from '../modales/NotificationModal';
import GeneralWidgets from '../dashboard/GeneralWidgets';
import { RenderGrafica } from '../../helpers/graficas/RenderGrafica'
import { BreadCrumb } from 'primereact/breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ aditionalData }) {

  const [idTipoReporte, setIdTipoReporte] = useState('')

  const hash = window.location.hash;
  const {t} = useTranslation();

  const [modal, setModal] = useState({
    show: false,
    mensaje: '',
    tipo: ''
  });
  const [initialState, setInitialState] = useState({
    data: {},
    error: null,
    loading: false,
  });

  const [graficas, setGraficas] = useState([])

  const handleCloseModal = () => {
    setModal({
      ...modal,
      show: false,
      mensaje: '',
      tipo: ''
    })
  }

  const [filtrosForm, setFiltrosForm] = useState({});

  const [url, setUrl] = useState('');

  return (
    <div>
      {hash === "#/presidencia/reportes" &&
        <BreadCrumb 
        model={[{ label: t('navegacion.comun.reportes'), className: 'breadcrumb__item--active' }]} 
        home={{ 
          label:  t('navegacion.menu_gerencia.panel'), 
          className: 'breadcrumb__item',
          url: '/dashboard-gerencia' ,
          template: (item) => {
            return <Link to={item.url}>{item.label}</Link>
          }
        }} 
        className='mt-2'/>
      }
      <Filtros
        idTipoReporte={idTipoReporte}
        setIdTipoReporte={setIdTipoReporte}
        initialState={initialState}
        setInitialState={setInitialState}
        setModal={setModal}
        modal={modal}
        filtrosForm={filtrosForm}
        setFiltrosForm={setFiltrosForm}
        graficas={graficas}
        setGraficas={setGraficas}
        setUrl={setUrl}
        aditionalData={aditionalData}
      />
      {!initialState?.data?.total_regs && graficas && graficas.length > 0 &&
        <Card className='shadow' style={{ margin: '10px 0' }} >
          <Card.Body>
            {graficas.map((graph, index) => {
              return (
                <>
                  <RenderGrafica grafica={graph} ></RenderGrafica>
                  <br />
                </>
              )
            })}
          </Card.Body>
        </Card>
      }
      {initialState?.data?.total_regs &&
        <>
          <br />
          <Tabla idTipoReporte={idTipoReporte} initialState={initialState} setInitialState={setInitialState} filtrosForm={filtrosForm} setFiltrosForm={setFiltrosForm} url={url} />
        </>
      }
      <NotificationModal isOpen={modal.show} setIsOpen={handleCloseModal} typeModal={modal.tipo} mensaje={modal.mensaje} />
    </div>
  )
}
