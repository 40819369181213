import React, { useEffect } from "react";
import { validarEntorno } from '../../helpers/validarHost';
import LineaTiempo from '../dashboard/LineaTiempo'
import TimelineMaterial from '../dashboard/TimelineMaterial'
import { Card, Form } from 'react-bootstrap'
// import { HojaCoca } from '../icons/ocensa/HojaCoca.jsx'
import EditModal from '../modales/EditModal'
import Loader from '../icons/Loader';
import ResumenComponenteModal from '../modales/ResumenComponenteModal'
import './styles/PanelOcensa.scss'
import './styles/PanelGerenciaRiesgos.scss'
import { makeStyles } from "@material-ui/core";
import useState from 'react-usestateref';
import { RadialRangos } from '../gerenciaGeneral/operaciones/graficas/RadialRangos'
import BuscadorDosNiveles from '../presidencia/BuscadorDosNiveles'
import AssignmentTurnedInOutlined from '@material-ui/icons/AssignmentTurnedInOutlined';
import PlanEstrategico from '../../components/gestionAuditorias/PlanEstrategico/PlanEstrategicoPanel.jsx'
import { BarrasLabelsHorizontales } from '../../components/dashboard/BarrasLabelsHorizontales.jsx'
import { Filtros } from '../../components/filtros/FiltroDinamico/Filtros.jsx'
import FiltroOcensa from "./FiltroOcensa";
import { dataLoader } from "@amcharts/amcharts4/core";
import ModalHelp from "../../helpers/ModalHelp";
import { useTranslation } from "react-i18next";


export default function PanelOcensa(props) {

  const { t } = useTranslation();
  const HASH = window.location.hash
  let { campos } = props

  const [objetivoSelected, setObjetivoSelected] = useState('')
  const [estadoSelected, setEstadoSelected] = useState('')

  const [sectionOpen, setSectionOpen] = useState('')

  const [showFiltros, setShowFiltros] = useState({})

  const [modalComp, setModalComp] = useState({
    tipo: '',
    show: false,
    data: {}
  })

  const [rangos, setRangos] = useState([])


  const [seccionDofaSelected, setSeccionDofaSelected] = useState(t('formulario.label.fortalezas'))

  const [detalleAmenazas, setDetalleAmenazas] = useState({ data: [] })
  const [detalleFortalezas, setDetalleFortalezas] = useState({ data: [] })
  const [detalleDebilidades, setDetalleDebilidades] = useState({ data: [] })
  const [detalleOportunidades, setDetalleOportunidades] = useState({ data: [] })

  const [cuentas, setCuentas] = useState([]);

  const [handleChangeState, setHandleChangeState] = useState({
    id_objetivo: '',
    id_meta: '',
    id_tarea: '',
    id_actividad: '',
    id_accion: ''
  })

  const [dataTimeline, setDataTimeline] = useState({
    data: []
  })

  const [dataTimelineDefault, setDataTimelineDefault] = useState({
    data: []
  })

  const [dataTimelineEvents, setDataTimelineEvents] = useState({
    data: []
  })

  const [dataTimelineEventsDefault, setDataTimelineEventsDefault] = useState({
    data: []
  })


  const [dataMeta, setDataMeta] = useState({
    data: []
  })

  const [dataTarea, setDataTarea] = useState({
    data: []
  })

  const [activeStep, setActiveStep] = useState(0);

  const [inputsFilters, setInputsFilters] = useState({
    tipoComponente: '',
    departamento: '',
    rango: '',
    desde: '',
    hasta: '',
    componentes: [],
    inicial: {}
  });
  const [showSelectComponente, setShowSelectComponente] = useState(false);
  const [idCuenta, setIdCuenta] = useState('');
  const [componenteSeleccionado, setComponenteSeleccionado] = useState([]);
  const [listaComponente, setListaComponente] = useState({
    show: false,
    data: []
  });
  const [carga, setCarga] = useState({
    loading: true,
    error: null
    // data: undefined
  });
  const [filtrosUpdate, setFiltrosUpdate] = useState(true)




  const [seccion, setSeccion] = useState([]);
  const [actividad, setActividad] = useState([]);
  const [sucesos, setSucesos] = useState([]);
  const [sucesosFilter, setSucesosFilter] = useState([]);
  const [componentes, setComponentes] = useState([]);
  const [filters, setFilters] = useState({});
  const [data, setData] = useState({
    visualizar: [],
    plan_estrategico: [],
    dofa: [],
    widgets: [],
    riesgos: [],
    normatividades: []
  });

  const [changeTitle, setChangeTitle] = useState({
    nuevo_titulo: ''
  });


  const [dataPanelOcensa, setDataPanelOcensa] = useState({
    data: {}
  })

  const [dataInicial, setDataInicial] = useState({
    permisos: {},
    data: {}
  })


  const [banderaButton, setBanderaButton] = useState(false)
  const [banderaLoader, setBanderaLoader] = useState(false)



  const useStyles = makeStyles((theme) => ({
    root: {
      width: '90%',
      margin: 'auto',
    },
    button: {
      marginRight: theme.spacing(1),
      height: '35px',
      disableRipple: true
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));

  const {
    plan_estrategico,
    widgets,
    perfil,
    ubicaciones,
    riesgos,
    visualizar,
    normatividades,
    dofa,
    linea_tiempo_inicial
  } = props.dataPanel


  useEffect(() => {
    getDataPanelGerencia()
    setData({
      ...data,
      widgets: widgets,
      riesgos: riesgos,
      visualizar: visualizar,
      normatividades: normatividades,
      plan_estrategico: plan_estrategico,
      dofa: dofa,
    })
    console.log(banderaButton)

    setDetalleFortalezas({
      ...detalleFortalezas,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[0].data
    })
    setDetalleOportunidades({
      ...detalleOportunidades,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[1].data
    })
    setDetalleDebilidades({
      ...detalleDebilidades,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[2].data
    })
    setDetalleAmenazas({
      ...detalleAmenazas,
      data: props.dataPanel.dofa.graficas.detalle_dofa.pestanas[3].data
    })

  }, [])



  const getDataPanelGerencia = async () => {


    const request = await fetch('JSON/dashboard_gerencia.php')
    const data = await request.json()

    let dataBanderaa = props.bandera

    console.log(dataBanderaa)
    setSectionOpen(data?.pestanas[0]?.id)

    setShowFiltros(data?.filtros)
    getContenidoFiltros(data);
    //console.log(showFiltros);
    setDataPanelOcensa({ data: data })
    //console.log(dataPanelOcensa.data)
    setInputsFilters({ ...inputsFilters, inicial: data.carga_inicial })
    setCuentas(data.data.cuentas);
    setRangos(data.data.rangos)
    setCarga({ loading: false, error: null });
    setComponentes(data.data.componentes);
    setDataTimeline(data.data.linea_tiempo);
    setDataTimelineDefault(data.data.linea_tiempo);
    setDataTimelineEvents(data.data.eventos_linea_tiempo);
    setDataTimelineEventsDefault(data.data.eventos_linea_tiempo);
    // //console.log(dataPanelOcensa)
  }


  const getContenidoFiltros = async (data) => {
    if (!data.filtros.factores) {

      const dataReq = {
        hash: window.location.hash,
        tipo: 'componente',
        id: ''
      }

      const req = await validarEntorno('JSON/panoramaRiesgos/get_secciones_componente.html', 'POST', dataReq);

      const dataSeccion = await req.json();

      delete dataSeccion.permisos;
      delete dataSeccion.registros;

      const comArray = Object.values(dataSeccion);
      const listFactores = [].concat.apply([], comArray);

      setSeccion(listFactores);
    }

    if (!data.filtros.secciones) {
      const dataReq = {
        hash: window.location.hash,
        tipo: 'seccion',
        factor: '',
        id: ''
      }

      const req = await validarEntorno('JSON/panoramaRiesgos/factores_select.html', 'POST', dataReq);

      const dataActividad = await req.json();

      setActividad(dataActividad.lista);
    }
  }





  const [detalleRiesgo, setDetalleRiesgo] = useState({
    data: {},
    show: false
  })


  const [tipoModal, setTipoModal] = useState('')



  const showRiesgoDetails = (riesgo) => {
    setDetalleRiesgo({ ...detalleRiesgo, data: riesgo, show: !detalleRiesgo.show })
    setTipoModal('detalleRiesgo')
    setEstadoSelected('actual')
  }

  const showNormativaDetails = (normativa) => {
    setDetalleRiesgo({ ...detalleRiesgo, data: normativa, show: true })
    setTipoModal('detalleNormativa')
  }

  const showAreaDiagnosticoDetails = (area) => {
    setDetalleRiesgo({ ...detalleRiesgo, data: area, show: !detalleRiesgo.show })
    setTipoModal('detalleAreaDiagnostico')
  }


  function getSteps() {
    return ['Selecciona una cuenta', 'Selecciona un rango de tiempo'];
  }

  const steps = getSteps();

  const classes = useStyles();


  const getComponentes = async (idDep, typeComponente) => {
    const data = {
      id_cuenta: idDep,
      hash: window.location.hash,
      tipoComponente: typeComponente
    }

    //console.log(data)

    setComponenteSeleccionado([]);

    const req = await validarEntorno('JSON/get_componentes_presidencia_aud.html', 'POST', data)
    const res = await req.json()

    setInputsFilters({
      ...inputsFilters,
      tipoComponente: typeComponente
    })

    //console.log(res)

    setListaComponente({ ...listaComponente, show: true, data: res })
    // //console.log(listaComponente)

  }

  const getComponenteSeleccionado = (e, item) => {
    //console.log(item)

    if (e.target.checked) {
      setComponenteSeleccionado(prevComponenteSeleccionado => [
        ...prevComponenteSeleccionado, item
      ]);
    } else {
      setComponenteSeleccionado(componenteSeleccionado.filter(cpm => cpm.id !== item.id));
    }

    //console.log(componenteSeleccionado)
  }


  const fillComponentToInputsFilter = () => {
    const cs = componenteSeleccionado;
    setInputsFilters({
      ...inputsFilters,
      componentes: cs
    });
    setShowSelectComponente(false);
  }

  const filterList = componenteSeleccionado.map((item) => {
    return (<p className='itemSeleccionado'>{item.nombre}</p>);
  });

  const style = {
    maxWidth: "450px",
    height: "350px",
    overflowX: "hidden",
    overflowY: "hidden"
  };

  const containerStyle = {
    maxWidth: "450px",
    height: "350px"
  };

  const showResumen = (item) => {
    setModalComp({
      ...modalComp,
      show: true,
      data: item
    })
  }

  const filterData = async (data) => {

    setFilters(data.asesoria[0])

    const dataReq = {
      hash: HASH,
      filtros: data.asesoria[0]
    }

    // console.log(dataReq)

    const req = await validarEntorno('JSON/filtrado_dinamico.php', 'POST', dataReq)
    if (req.ok) {
      const res = await req.json()
      setData(res)
      setDetalleFortalezas({
        ...detalleFortalezas,
        data: res.dofa.graficas.detalle_dofa.pestanas[0].data
      })
      setDetalleOportunidades({
        ...detalleOportunidades,
        data: res.dofa.graficas.detalle_dofa.pestanas[1].data
      })
      setDetalleDebilidades({
        ...detalleDebilidades,
        data: res.dofa.graficas.detalle_dofa.pestanas[2].data
      })
      setDetalleAmenazas({
        ...detalleAmenazas,
        data: res.dofa.graficas.detalle_dofa.pestanas[3].data
      })
    }
  }

  const updateTimeline = async () => {

    let dataReq;

    if (filtrosUpdate !== false) {
      dataReq = {
        hash: HASH,
        filtros: { ...filtrosUpdate },
        ...handleChangeState

      }
    } else {
      dataReq = {
        hash: HASH,
        filtros: { ...filtrosUpdate },
        ...handleChangeState,
      }
    }

    console.log(dataReq)

    const req = await validarEntorno('JSON/update_timeline.html', 'POST', dataReq)

    if (req.ok) {
      let res = await req.json();

      console.log(res)

      setDataTimeline({

        data: res?.data
      })

    }

  }


  return (

    <>
      {props.data.campos_panel && props.data.campos_panel.length > 0 &&
        <>
          <Filtros
            campos={props.data.campos_panel}
            url='JSON/filtros.html'
            function={filterData}
          />
          <br />
        </>
      }
      {data.widgets &&
        <>
          <div className='shadow mainWidgets scrollSecundario' id='widgetsOcensaContainer' style={{ borderRadius: '10px' }} >

            {data.widgets?.map((widget, index) => {
              const porcentajeWidget = Number(widget.porcentaje.substring(0, widget.porcentaje.length - 1))
              return (
                <>
                  {(perfil === 'gerencia_riesgos') &&

                    <div
                      className={`shadow widget cardWidgetPorcentaje ${porcentajeWidget < 20 ? 'widgetBajo' : porcentajeWidget >= 20 && porcentajeWidget < 80 ? 'widgetMedio' : porcentajeWidget >= 80 && 'widgetAlto'}`}
                      key={index}
                      onClick={() => showAreaDiagnosticoDetails(widget)}
                    >
                      <Card.Body className='cardbodywidget'>
                        <div className='contenidoWidget'>
                          <div className='labelWidget'>
                            {widget.titulo}
                          </div>
                          <div className='cantidadWidget'>
                            <p>{widget.porcentaje}</p>
                          </div>
                        </div>
                      </Card.Body>
                    </div>
                  }

                </>
              )
            }
            )}
          </div>
          <br />

        </>
      }

      <div style={{ maxWidth: '100%' }}>
        <>
          <div className='contenedorSecciones'  >
            <div className='seccionesGraficas'>
              {props.data?.pestanas?.length &&
                props.data?.pestanas?.map((pestana, index) => {
                  return (
                    <>
                      {pestana.visualizar &&
                        <button
                          key={index}
                          style={{
                            backgroundColor: sectionOpen === pestana.id ? pestana?.color : 'white',
                            color: sectionOpen === pestana.id ? 'white' : '#213265'
                          }}
                          // id='buttonCompromisos'
                          className='seccionPanel'
                          onClick={() => setSectionOpen(pestana.id)}
                        >
                          {pestana?.icono === 'dofa'
                            ? <AssignmentTurnedInOutlined style={{ color: sectionOpen === pestana.id ? '#fff' : '#2c5e99' }}
                            // color={ sectionOpen === pestana.id ? '#fff' : '#2c5e99' }
                            />
                            : pestana.icono === 'per'
                              ? <AssignmentTurnedInOutlined
                                style={{ color: sectionOpen === pestana.id ? '#fff' : '#2c5e99' }}
                              // color={ sectionOpen === pestana.id ? '#fff' : '#2c5e99' }
                              />
                              : pestana.icono === 'consolidado'
                                ? <AssignmentTurnedInOutlined
                                  color={sectionOpen === pestana.id ? '#fff' : '#2c5e99'}
                                />
                                : pestana.icono === 'pte_aprobaciones'
                                  ? <AssignmentTurnedInOutlined color={sectionOpen === 'anotaciones' ? '#fff' : '#2c5e99'} />
                                  : null
                          }
                          {/* <IconoCompromisos color={ sectionOpen === index ? '#fff' : '#2c5e99' } /> */}
                          {pestana.titulo}
                        </button>
                      }
                    </>
                  )
                })
              }
            </div>
          </div>
          {!carga.loading ? (
            <>
              {sectionOpen === 'riesgos' &&
                <Card className='shadow riesgosCard'>
                  {data.visualizar.riesgos && data.riesgos.length > 0 && (
                    <aside
                      id={
                        data.normatividades.length === 0
                          ? 'matrizRiesgosExtendido'
                          : 'matrizRiesgosOperaciones'
                      }
                    >
                      <div className='shadow' id='containerRiesgos'>
                        <div style={{ display: 'flex' }}>
                          <h5 className='titleBlue' style={{ marginTop: '10px', marginBottom: '20px' }}>
                            {t('gestion_realizada.dofa.comportamiento_riesgos')}
                          </h5>
                        </div>
                        <div className='contenedorRiesgos scrollSecundario'>
                          {data.riesgos.map((riesgo) => (
                            <div
                              className={
                                riesgo.rango >= 13 && riesgo.rango <= 25
                                  ? 'shadow riesgoItemGeneral riesgoCritico'
                                  : riesgo.rango >= 5 && riesgo.rango <= 12
                                    ? 'shadow riesgoItemGeneral riesgoAltoItem'
                                    : 'shadow riesgoItemGeneral riesgoItem'
                              }
                              key={riesgo.index}
                              onClick={() => showRiesgoDetails(riesgo)}
                            >
                              <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '4px' }}>
                                <div className='dataGeneralRiesgo'>
                                  <div className='contenedorDatoRiesgo'>
                                    <p className='labelDatoRiesgo'>{t('formulario.label.probabilidad')}</p>
                                    <p className='dataDatoRiesgo'>{riesgo.probabilidad}</p>
                                  </div>
                                  <div className='contenedorDatoRiesgo'>
                                    <p className='labelDatoRiesgo'>{t('formulario.label.impacto')}</p>
                                    <p className='dataDatoRiesgo'>{riesgo.impacto}</p>
                                  </div>
                                </div>
                                <div className='rangoRiesgo'>
                                  <p style={{ fontSize: '2rem', margin: '0', fontWeight: '800' }}>{riesgo.rango}</p>
                                  <p style={{ fontSize: '1.0rem', margin: '0', fontWeight: '600' }}>{t('formulario.label.rango')}</p>
                                </div>
                              </div>
                              <p className='tipoRiesgo'>{riesgo.tipo}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </aside>
                  )}

                  {data.visualizar.normatividades && data.normatividades.length > 0 && (
                    <aside
                      id={
                        data.riesgos.length === 0
                          ? 'contenedorNormatividadesExtendido'
                          : 'contenedorNormatividadesOperaciones'
                      }
                    >
                      <div className='shadow' id='normatividadesBody'>
                        <div id='contenedorTituloNormatividades'>
                          <h5 className='titleBlue' style={{ marginTop: '10px', marginBottom: '20px' }}>
                            {visualizar?.procesos?.titulo ? visualizar?.procesos?.titulo : 'Normatividades'}
                          </h5>
                        </div>
                        <div id='contenedorNormativasItems' className='scrollSecundario'>
                          {data.normatividades.map((item, index) => {
                            const parsePorcentaje = Number(item.porcentaje || item.porcentaje_ini);
                            return (
                              <div
                                className={`normatividadItem ${item.visualizar ? 'transformar' : ''} ${parsePorcentaje < 20
                                    ? 'normatividadBaja'
                                    : parsePorcentaje >= 20 && parsePorcentaje < 80
                                      ? 'normatividadMedia'
                                      : parsePorcentaje >= 80
                                        ? 'normatividadAlta'
                                        : ''
                                  }`}
                                key={index}
                                onClick={item?.visualizar ? () => showNormativaDetails(item) : null}
                              >
                                <p>{item.titulo}</p>
                                <div className='porcentajes_cont'>
                                  {item.porcentaje_ini && (
                                    <div
                                      className='porcentajeItem'
                                      style={{ borderRight: item.porcentaje ? '1px solid white' : 'none' }}
                                    >
                                      <div className='porcentajeNorma' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div>{item.porcentaje_ini}</div>
                                        <div style={{ fontSize: '1rem' }}>%</div>
                                      </div>
                                      <p style={{ fontSize: '0.6rem' }}>{t('formulario.label.inicial')}</p>
                                    </div>
                                  )}
                                  {item.porcentaje && (
                                    <div className='porcentajeItem'>
                                      <div className='porcentajeNorma' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div>{item.porcentaje}</div>
                                        <div style={{ fontSize: '1rem' }}>%</div>
                                      </div>
                                      <p style={{ fontSize: '0.6rem' }}>{t('formulario.label.actual')}</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </aside>
                  )}
                </Card>

              }
              {sectionOpen === 'dofa' && data.visualizar.dofa &&
                <Card className='shadow' style={{ padding: '30px', borderRadius: '20px', width: '100%', boxSizing: 'border-box' }} >
                  <section className='secciones' id='estadisticasOcensa'>

                    {data.dofa.graficas.detalle_dofa.visualizar &&
                      <div className='graficasEstadisticas' id='grafica2' style={{ display: 'flex', flexDirection: 'column' }}>
                        <h4 className='pagesTitles ' style={{ textAlign: 'center', width: '100%' }} >{t('gestion_realizada.dofa.detalle_porcentajes_dofa')}</h4>
                        <div className='contenedorSeccionesDofa'>
                          {data.dofa.graficas.detalle_dofa.pestanas.map((pestana, index) => {
                            return (
                              <>
                                <div className={seccionDofaSelected === pestana.nombre ? 'detalleDofaItemSelected' : 'detalleDofaItem'}
                                  key={index}
                                  onClick={() => setSeccionDofaSelected(pestana.nombre)}
                                  style={{
                                    fontWeight: '700'
                                  }}
                                >
                                  {pestana.nombre}
                                </div>
                              </>
                            )
                          })}
                        </div>
                        <div className='dataSeccionDofa' style={{ width: '100%', height: '100%' }}>
                          {
                            <BarrasLabelsHorizontales
                              seccionDofaSelected={seccionDofaSelected}
                              id={`detalle${seccionDofaSelected}`}
                              data={seccionDofaSelected === t('formulario.label.fortalezas')
                                ? detalleFortalezas?.data
                                : seccionDofaSelected === t('formulario.label.amenazas')
                                  ? detalleAmenazas?.data
                                  : seccionDofaSelected === t('formulario.label.oportunidades')
                                    ? detalleOportunidades?.data
                                    : seccionDofaSelected === t('formulario.label.debilidades')
                                      ? detalleDebilidades?.data
                                      : ''
                              }

                            />

                          }
                          {/* {
                            <PartitionedBarChart
                              id={`detalle${seccionDofaSelected}`}
                              data={seccionDofaSelected === 'Fortalezas'
                                ? detalleFortalezas?.data
                                : seccionDofaSelected === 'Amenazas'
                                  ? detalleAmenazas?.data
                                  : seccionDofaSelected === 'Oportunidades'
                                    ? detalleOportunidades?.data
                                    : seccionDofaSelected === 'Debilidades'
                                      ? detalleDebilidades?.data
                                      : ''
                              }
                            />
                          } */}
                        </div>
                      </div>
                    }
                    {
                      <div className='graficasEstadisticas' id='grafica1' style={{ display: 'flex', flexDirection: 'column' }}  >
                        <h4 className='pagesTitles' >{t('gestion_realizada.dofa.puntuacion_general')}</h4>
                        <RadialRangos
                          data={data.dofa?.graficas?.puntuacion_general_dofa?.data}
                          // chart={chart}
                          id='dofaPanel'
                        />
                      </div>

                    }
                  </section>
                </Card>
              }

              {sectionOpen === 'per' && data.visualizar.plan_estrategico &&
                <>
                  {banderaLoader
                    ?
                    <Loader />
                    : <Card className="shadow" style={{
                      borderRadius: '20px'
                    }} id='seccionPlanEstrategico'>
                      <Card
                        style={{
                          paddingTop: '1%',
                          paddingBottom: "0%",
                          paddingLeft: "4%",
                          paddingRight: "4%",
                          marginBottom: "0%",
                          gridColumn: '1 / 3',
                        }}
                      >
                        <Card.Body
                          className="shadow"
                          style={{ height: "90px", padding: "0%", paddingLeft: "1%" }}
                        >
                          <FiltroOcensa
                            setBanderaLoader={setBanderaLoader}
                            setFiltrosUpdate={setFiltrosUpdate}
                            dataInicial={dataInicial}
                            setDataInicial={setDataInicial}
                            setDataTimeline={setDataTimeline}
                            setChangeTitle={setChangeTitle}
                            setBanderaButton={setBanderaButton}
                          />
                        </Card.Body>
                      </Card>

                      {banderaButton ? <Loader /> : (
                        <>
                          <div style={{ flexDirection: 'row', display: 'flex', width: '100% auto', justifyContent: 'space-around' }}>
                            <PlanEstrategico
                              filters={filters}
                              updateTimeline={updateTimeline}
                              handleChangeState={handleChangeState}
                              setHandleChangeState={setHandleChangeState}
                              setDataTimeline={setDataTimeline}
                              dataTimeline={dataTimeline}
                              dataInicial={dataInicial}
                              setDataInicial={setDataInicial}
                              changeTitle={changeTitle}
                              setChangeTitle={setChangeTitle}
                            />
                            <div>
                              <TimelineMaterial
                                title={t('gestion_realizada.plan_estrategico.linea_tiempo')}
                                data={dataTimeline?.data}
                                position="left"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Card>
                  }
                </>
              }
              {sectionOpen === 'actividades' &&
                <Card className='shadow' style={{ padding: '30px', borderRadius: '20px', width: '100%', boxSizing: 'border-box' }}>
                  <BuscadorDosNiveles perfil={perfil} campos={props.data.campos} />
                </Card>
              }


            </>
          ) : (
            <div className="loader-center">
              <Loader />
            </div>
          )}

          <EditModal
            typeModal={tipoModal}
            setIsOpen={showRiesgoDetails}
            dataRiesgo={detalleRiesgo.data}
            isOpen={detalleRiesgo.show}
            estadoSelected={estadoSelected}
            setEstadoSelected={setEstadoSelected}

          >
          </EditModal>
        </>
        {/* <ResumenComponenteModal
          isOpen={modalComp.show}
          setIsOpen={setModalComp}
          typeModal={modalComp.tipo}
          item={modalComp.data}
        /> */}
        <EditModal
          showLista={listaComponente.show}
          listaComponente={listaComponente.data}
          typeModal={tipoModal}
          idCuenta={idCuenta}
          isOpen={showSelectComponente}
          setIsOpen={setShowSelectComponente}
          getComponentes={getComponentes}
          getComponenteSeleccionado={getComponenteSeleccionado}
          fillComponentToInputsFilters={fillComponentToInputsFilter}
          cargaInicial={inputsFilters.inicial}

        />
      </div>
    </>
  )
}