import { Fragment, useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { IconButton } from '@material-ui/core';



export const Estadisitica = ({ seccion }) => {

    const [fileInputs, setFileInputs] = useState(false)
    const [grafico, setGrafico] = useState(false)
    const [countData, setCountData] = useState([])
    let Arr = [];


    const handleChangeGrafico = (e) => {
        setGrafico({
            [e.target.name]: e.target.value
        })
    }

    const handleChange = (e) => {
        // //console.log(e)
    }


    const handleFile = (e) => {
        // //console.log(e)
        setFileInputs(true)
    }



    const handlePopArr = () => {


        for (let index = 0; index < countData.length; index++) {
            if (countData[index] === countData[countData.length - 1]) {

            } else {
                Arr.push(countData[index])
            }
        }

        setCountData(Arr)
    }

    //console.log(countData)

    const renderData = () => {


        return countData.map((ele, index) => {
            return (
                <Fragment key={ele + index}>
                    <hr />
                    <div div className='formEstudioBasicoContainer' >
                        <div className='formItem' id='basicFormA'>



                            {

                                seccion === "Histórico de posición política de los alcaldes en cuanto al sector de hidrocarburos"
                                    ?
                                    <Form.Group >
                                        <Form.Label>Partido Político</Form.Label>
                                        <div className='inputContainer'>
                                            <Form.Control
                                                name="prioridad"
                                                onChange={handleChange}
                                                required={true}
                                                as='select'
                                                type='text'
                                            >
                                                <option >Seleccione</option>
                                                <option >Medio</option>
                                                <option >Bajo</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    : seccion === "Presencia e influencia de la Fuerza Pública en el territorio"
                                        ? <Form.Group >
                                            <Form.Label>Fuerza Pública</Form.Label>
                                            <div className='inputContainer'>
                                                <Form.Control
                                                    name="prioridad"
                                                    onChange={handleChange}
                                                    required={true}
                                                    as='select'
                                                    type='text'
                                                >
                                                    <option >Seleccione</option>
                                                    <option >Armada</option>
                                                    <option >Polícia</option>
                                                    <option >Ejercito</option>
                                                    <option >Fuerza Aérea</option>
                                                </Form.Control>
                                                <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                        : seccion === "Presencia e influencia de actores al margen de la Ley (GAO-GDO-GAOR)"
                                            ? <Form.Group >
                                                <Form.Label>Actores al Margen de la Ley</Form.Label>
                                                <div className='inputContainer'>
                                                    <Form.Control
                                                        name="prioridad"
                                                        onChange={handleChange}
                                                        required={true}
                                                        as='select'
                                                        type='text'
                                                    >
                                                        <option >Seleccione</option>
                                                        <option >GAO</option>
                                                        <option >GDO</option>
                                                        <option >GAOR</option>

                                                    </Form.Control>
                                                    <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            : seccion === "Orden público"
                                                ?
                                                <Form.Group >
                                                    <Form.Label>Tipo de Alteración al orden Público</Form.Label>
                                                    <div className='inputContainer'>
                                                        <Form.Control
                                                            name="prioridad"
                                                            onChange={handleChange}
                                                            required={true}
                                                            as='select'
                                                            type='text'
                                                        >
                                                            <option >Seleccione</option>
                                                            <option >Delincuencia</option>
                                                            <option >Protesta</option>
                                                            <option >Subversión</option>
                                                            <option >Otro</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                                : seccion === "Índice de aceptación de la Fuerza Pública"
                                                    ? <Form.Group >
                                                        <Form.Label>Fuerza Pública</Form.Label>
                                                        <div className='inputContainer'>
                                                            <Form.Control
                                                                name="prioridad"
                                                                onChange={handleChange}
                                                                required={true}
                                                                as='select'
                                                                type='text'
                                                            >
                                                                <option >Seleccione</option>
                                                                <option >Armada</option>
                                                                <option >Polícia</option>
                                                                <option >Ejercito</option>
                                                                <option >Fuerza Aérea</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                    : <Form.Group>
                                                        <Form.Label>
                                                            Nombre campo
                                                        </Form.Label>
                                                        <div className='inputContainer'>
                                                            <Form.Control onChange={handleChange} name='nombreCampo' required type='text' placeholder='Nombre del campo' > </Form.Control>
                                                            <Form.Control.Feedback type='invalid'>Ingresa el nombre del campo </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                            }


                        </div>

                        <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>
                            <Form.Group>
                                <Form.Label>
                                    Valor campo
                    </Form.Label>
                                <div className='inputContainer'>
                                    <Form.Control onChange={handleChange} name='valorCampo' required type='number' placeholder='Valor campo'></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa el nombre del campo </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </Fragment>
            )
        })

    }

    return (
        <Fragment>

            <h5 className='titleInsideCard'>Estadísitica</h5>
            <Card.Body>
                <div className='formEstudioBasicoContainer' >
                    <div className='formItem' id='basicFormA'>
                        <Form.Group>
                            <Form.Label>
                                Link
                                    </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='link' required type='text' placeholder='Link' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group >
                            <Form.Label>Fuente</Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='fuente' required type='text' placeholder='Fuente' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa una fuente</Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Fecha
                                    </Form.Label>
                            <div className='inputContainer'>

                                <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>


                    </div>
                    <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>

                        <Form.Group>
                            <Form.Label>
                                Archivos soporte
                                    </Form.Label>
                            <div className='addButtonContainer' style={{ cursor: 'pointer', width: '100%', marginTop: '-25px', paddingTop: '0px' }} >
                                <input id='selectFile' className='selectFile' name='selectFile' type='file' onChange={handleFile} style={{ cursor: 'pointer' }} />
                                <label id='addButton' htmlFor='selectFile' style={{ cursor: 'pointer' }} >
                                    Añadir documento
                                <AddIcon />
                                </label>
                            </div>

                        </Form.Group>

                        {
                            fileInputs
                                ?
                                <Fragment>
                                    <Form.Group>
                                        <Form.Label>
                                            Fecha documento
                                    </Form.Label>
                                        <div className='inputContainer'>

                                            <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>

                                    <Form.Group id='observacionesFormGroup'>
                                        <Form.Label className='observacionesLabel'>
                                            Descripción archivo
                                    </Form.Label>
                                        <div className='textAreaContainer'>
                                            <Form.Control name='descripcionFile' id='observacionesTextArea' maxLength={2000} style={{ height: '50px' }} required as='textarea' placeholder='Observaciones'></Form.Control>
                                            <Form.Control.Feedback type='invalid'>Ingresa la descripcion</Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </Fragment>
                                : <Form.Group id='observacionesFormGroup'>
                                    <Form.Label className='observacionesLabel'>
                                        Descripción
                                    </Form.Label>
                                    <div className='textAreaContainer'>
                                        <Form.Control onChange={handleChange} style={{ height: '70px' }} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                        }

                    </div>

                    {
                        fileInputs
                        &&
                        <div id='observacionesContainerEstudioBasico'>
                            <Form.Group id='observacionesFormGroup'>
                                <Form.Label className='observacionesLabel'>
                                    Descripción
                                    </Form.Label>
                                <div className='textAreaContainer'>
                                    <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones'></Form.Control>
                                    <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    }
                </div>
            </Card.Body>
            <hr />

            <h5 className='titleInsideCard'>Selección de grafico</h5>
            <Form.Group >
                <Form.Label>Seleccione el tipo de gráfico</Form.Label>
                <div className='inputContainer'>
                    <Form.Control
                        name="grafico"
                        onChange={handleChangeGrafico}
                        required={true}
                        as='select'
                        type='text'
                    >
                        <option value="0">
                            Seleccione
                                    </option>
                        <option value="columnas">
                            Columnas
                                    </option>
                        <option value="barras">
                            Barras
                                    </option>
                        <option value="torta">
                            Torta
                                    </option>
                        <option value="radial">
                            Radial
                                    </option>
                        <option value="líneas">
                            Líneas
                                    </option>
                        <option value="tablas">
                            Tablas
                                    </option>
                        <option value="cargarArchivo">
                            Cargar Archivo
                                    </option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>Ingresa la cuenta asociada</Form.Control.Feedback>
                </div>
            </Form.Group>

            {
                grafico.grafico !== "cargarArchivo" && grafico.grafico
                &&

                <Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Form.Group style={{ width: '60%' }}>
                            <Form.Label>
                                Nombre Grafica
                                    </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control onChange={handleChange} name='nombreGrafica' required type='text' placeholder='Nombre del grafica' ></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa el nombre del campo </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <div>

                            <IconButton onClick={() => {


                                handlePopArr()

                            }}>
                                <RemoveCircleIcon fontSize='large' style={{ background: '#213265', borderRadius: '50%', color: 'white' }} />
                            </IconButton>

                            <IconButton onClick={() => {


                                setCountData([...countData, { [countData.length + 1]: countData.length + 1 }])



                            }}>
                                <AddIcon fontSize='large' style={{ background: '#213265', borderRadius: '50%', color: 'white' }} />
                            </IconButton>
                        </div>
                    </div>

                    {
                        renderData()
                    }
                </Fragment>
            }
            <br />
            <br />
            {
                grafico.grafico === "cargarArchivo"
                &&
                <div className='formEstudioBasicoContainer' >
                    <div className='formItem' id='basicFormA'>
                        <Form.Group>
                            <Form.Label>
                                Archivos soporte
                                    </Form.Label>
                            <div className='addButtonContainer' style={{ cursor: 'pointer', width: '100%', marginTop: '-25px', paddingTop: '0px' }} >
                                <input id='selectFile' className='selectFile' name='selectFile' type='file' onChange={handleFile} style={{ cursor: 'pointer' }} />
                                <label id='addButton' htmlFor='selectFile' style={{ cursor: 'pointer' }} >
                                    Añadir documento
                                <AddIcon />
                                </label>
                            </div>
                        </Form.Group>

                        <Form.Group id='observacionesFormGroup'>
                            <Form.Label className='observacionesLabel'>
                                Descripción
                                    </Form.Label>
                            <div className='textAreaContainer'>
                                <Form.Control onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder='Observaciones' style={{ height: '50px' }}></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa las observaciones</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </div>
                    <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>
                        <Form.Group>
                            <Form.Label>
                                Fecha documento
                                    </Form.Label>
                            <div className='inputContainer'>
                                <Form.Control name="fecha" type="date" onChange={handleChange} required className="formControlFiltrolGeneral form-control"></Form.Control>
                                <Form.Control.Feedback type='invalid'>Ingresa un barrio </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                    </div>

                </div>
            }

        </Fragment >
    )
}
