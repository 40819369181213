import React, { forwardRef, Fragment, useState, useEffect } from 'react';
import { Form, Card } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
// import { envioDataCrearSolicitud } from '../../../../reducers/crearSolicitudReducer';
import { CalendarSVG } from '../../../icons/CalendarSVG';
import GenericModal from '../../../modales/NotificationModal';
// import { peticionSinToken } from '../../../../helpers/peticiones';
import { validarEntorno } from '../../../../helpers/validarHost';
import '../EstudioBasico.scss'
import moment from 'moment';
// import { utc } from "moment";
import 'moment/locale/es-mx';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
moment.locale('es-mx')

export const InformacionGeneralPestaña = ({ ele }) => {

  // console.log(ele)

  const { t } = useTranslation();
  const { id } = useParams()
  // const dispatch = useDispatch()
  const crearSolicitud = useSelector(state => state.crearSolicitud)
  const codigoFuncion = crearSolicitud.codigo ? crearSolicitud.codigo : undefined
  const [validated, setValidated] = useState(false);
  const [campoReg, setCampoReg] = useState(Date.now(moment(ele.valuesInputs.fecha_dictamen).utc(true).format()))


  const [tipoModal, setTipoModal] = useState('')
  const [modal, setModal] = useState(false)
  const [mensajeModal, setMensajeModal] = useState('')
  const [codigoSucces, setCodigoSucces] = useState('')
  const [primerNombreVal, setPrimerNombreVal] = useState('')
  const [segundoNombreVal, setSegundoNombreVal] = useState('')
  const [primerApellidoVal, setPrimerApellidoVal] = useState('')
  const [SegundoApellidoVal, setSegundoApellidoVal] = useState('')

  const ExampleCustomInput = forwardRef(
    ({ value, onClick }, ref) => (
      <div className="dateBox" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
        <div style={{ display: 'flex', paddingLeft: '7px', paddingRight: '7px' }}>
          <label onClick={onClick} ref={ref} className='mr-3'>
            {value}
          </label>
          <i onClick={onClick} ref={ref} >
            <CalendarSVG />
          </i>
        </div>
      </div>

    ),
  );

  const [info, setInfo] = useState({});




  useEffect(() => {
    recargarPestana()
    // setInfo({ ...ele.valuesInputs, pestaña: ele.pestana, idSolicitud: id, hash: window.location.hash})
  }, [])




  const [startDate, setStartDate] = useState(ele.valuesInputs.fecha_dictamen)

  console.log(startDate);

  const recargarPestana = () => {
    const dataEnviar = {
      idSolicitud: id,
      hash: window.location.hash,
    }
    validarEntorno('JSON/gestionar_solicitud_cliente_json.html', 'POST', dataEnviar).then(res => {
      if (res.ok) {
        res.json()
          .then(dataRespuesta => {
            // console.log('Recarga de pestana')
            setInfo({ ...dataRespuesta.data[0].valuesInputs, pestaña: dataRespuesta.data[0].pestana, idSolicitud: id, hash: window.location.hash });
            //console.log(info)
            // setData(dataRespuesta)
            // console.log(data?.informacion_solicitud?.estado_cos?.valor)
            // setPestaña(dataRespuesta.data[0].pestana)
          })
      }
    })
  }

  const handleChange = e => {

    const reg = new RegExp(/[^0-9_]/g)

    if (e?.target?.name === 'primer_nombre' || e?.target?.name === 'segundo_nombre' || e?.target?.name === 'primer_apellido' || e?.target?.name === 'segundo_apellido') {

      // console.log(e.target.name)

      // const regular =  e?.target?.value?.replace(reg,'')
      const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑÁÓÍÉÚ ]/g)
      //const reg1 = new RegExp(/[^a-zA-ZáéíóúüñÑ ]/g)

      const validado = reg1.test(e.target.value)

      // console.log(validado + 'validado')

      if (!validado) {

        setInfo({
          ...info,
          [e.target.name]: e.target.value,
        })

        // console.log(info)
      }


    } else if (e?.target?.name === 'n_dictamen') {
      // n_dictamen
      const reg2 = new RegExp(/[^0-9-]/g)

      const validado2 = reg2.test(e.target.value)

      if (!validado2) {
        // setCampoReg(e.target.value)
        setInfo({
          ...info,
          [e.target.name]: e.target.value,
        })

      }
    } /*else if (e?.target?.name === 'radicado') {
      const validate = reg.test(e.target.value)
      if (!validate) {
        setCampoReg(e.target.value)
        setInfo({
          ...info,
          radicado: e.target.value,
        })

      }

    } */
    else {
      if (e?.target?.name) {
        setInfo({
          ...info,
          [e.target.name]: e.target.value,
        })

        // console.log(info)
      } else {
        setInfo({
          ...info,
          fecha_dictamen: e,
        })
      }

    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault()

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
    }

    setValidated(true)


    if (form.checkValidity() === true) {
      if (info.tipo_identificacion === "") {
        let infoEnviar = info
        infoEnviar.tipo_identificacion = "2"
        infoEnviar.fecha_dictamen = startDate;
        //Local
        //const respuesta = await peticionSinToken('https://127.0.0.1:5500/public/JSON/11.respuestaSolicitudInicialJson.html')
        //Produccion

        console.log(infoEnviar)


        const respuesta = await validarEntorno('JSON/gestionar_solicitud_creada_cliente_informacion_general_json.html', 'POST', infoEnviar)

        if (respuesta.ok) {

          const body = await respuesta.json()

          if (body.ok) {
            setTipoModal('editarSolicitud')
            setMensajeModal(body.mensaje)
            setModal(true)
            recargarPestana()

          } else {


            setMensajeModal(body.mensaje)
            setModal(true)
            setTipoModal('errorFormSolicitud')


          }

        }


      } else {

        console.log(info)

        const respuesta = await validarEntorno('JSON/gestionar_solicitud_creada_cliente_informacion_general_json.html', 'POST', info)
        if (respuesta.ok) {
          const body = await respuesta.json()
          if (body.ok) {
            setTipoModal('editarSolicitud')
            setMensajeModal(body.mensaje)
            setModal(true)
            recargarPestana()


          } else {


            setMensajeModal(body.mensaje)
            setModal(true)
            setTipoModal('errorFormSolicitud')


          }

        }
      }
      //setModal(true)
    }
  };



  const valuesInputs = info



  return (
    <Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ margin: '0px', paddingRight: '0px' }}>
        {ele.contenido[0].visualizar
          &&
          <Card className='card__box__surface p-5 card__plain__top__left'>
            <h5 className='titleInsideCard'>{t('formulario.label.informacion_solicitud')}</h5>
            <Card.Body className='p-0'>
              <div className='formEstudioBasicoContainer' >
                <div className='formItem' id='basicFormA'>
                  <Form.Group >
                    <Form.Label>{t('formulario.label.tipo_investigacion')}</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="tipo_inv"
                        onChange={handleChange}
                        required={true}
                        as='select'
                        type='text'
                        readOnly={codigoFuncion === undefined}
                        value={valuesInputs.tipo_inv}
                        disabled={!ele.editar}
                      // name="riesgo_detalle"
                      // onChange={handleChange}
                      // required={true}
                      // as='select'
                      // type='text'
                      // readOnly={codigoFuncion === undefined}
                      // value={valuesInputs.riesgo_detalle}
                      // disabled={!ele.editar}
                      >
                        <option value='' disabled >{t('formulario.label.seleccione_opcion')}</option>
                        {valuesInputs?.opciones_tipo_inv?.map((opt, idx) => {
                          return (
                            <option value={opt?.valor} key={idx}>{opt?.nombre}</option>
                          )
                        })}

                        {/* <option value="0">Selección</option>
                                                <option value="1">Convivencia</option>
                                                <option value="2">Dependencia económica</option>
                                                <option value="3">Escolaridad</option>
                                                <option value="4">Validación documental</option>
                                                <option value="5">Incremento de pensión por personas a cargo</option>                                               <option value="6">Conservación del derecho a pensión anticipada por hijo inválido</option>

                                                 */}

                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_tipo_investigacion')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>


                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.nombres')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        value={valuesInputs?.primer_nombre}
                        disabled={!ele.editar}
                        onChange={handleChange} name='primer_nombre' required type='text' placeholder={t('formulario.label.primer_nombre')}></Form.Control>
                      <Form.Control
                        value={valuesInputs?.segundo_nombre}
                        disabled={!ele.editar}
                        onChange={handleChange} name='segundo_nombre' type='text' placeholder={t('formulario.label.segundo_nombre')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_nombres')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.apellidos')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        required={valuesInputs?.requerido_primer_apellido === true ? true : false}
                        value={valuesInputs?.primer_apellido}
                        disabled={!ele.editar}
                        onChange={handleChange} name='primer_apellido' type='text' placeholder={t('formulario.label.primer_apellido')}></Form.Control>
                      <Form.Control
                        value={valuesInputs?.segundo_apellido}
                        disabled={!ele.editar}
                        onChange={handleChange} name='segundo_apellido' type='text' placeholder={t('formulario.label.segundo_apellido')}></Form.Control>

                      <Form.Control.Feedback type='invalid'>Ingresa los apellidos</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
                <div className='formItem' id='basicFormB' style={{ marginLeft: '10px' }}>
                  <Form.Group >
                    <Form.Label>{t('formulario.label.tipo_riesgo')}</Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        name="riesgo_detalle"
                        onChange={handleChange}
                        required={true}
                        as='select'
                        type='text'
                        readOnly={codigoFuncion === undefined}
                        value={valuesInputs.riesgo_detalle}
                        disabled={!ele.editar}
                      >
                        <option value='' disabled >{t('formulario.label.seleccione_opcion')}</option>
                        { }
                        <optgroup label="Vejez">
                          <option value="Vejez - Pensión familiar"> Pensión familiar </option>
                          <option value="Vejez - Pensión de vejez"> Pensión de vejez </option>
                          <option value="Vejez - Pensión vejez alto riesgo"> Pensión vejez alto riesgo </option>
                          <option value="Vejez - Pensión vejez convenios internacionales"> Pensión vejez convenios internacionales </option>
                          <option value="Vejez - Pensión vejez compartida"> Pensión vejez compartida </option>
                          <option value="Vejez - Pensión vejez periodista"> Pensión vejez periodista </option>
                          <option value="Vejez - Pensión vejez madre o padre trabajador hijo inválido"> Pensión vejez madre o padre trabajador hijo inválido </option>
                          <option value="Vejez - Pensión vejez convenios internacionales"> Pensión vejez convenios internacionales </option>
                          <option value="Vejez - Pensión especial de vejez anticipada por invalidez"> Pensión especial de vejez anticipada por invalidez </option>
                        </optgroup>
                        <optgroup label="Invalidez">
                          <option value="Invalidez - Pensión invalidez convenios internacionales"> Pensión invalidez convenios internacionales </option>
                          <option value="Invalidez - Pensión invalidez"> Invalidez - Pensión invalidez </option>
                          <option value="Pensión Invalidez - Enfermedad Congénita"> Pensión Invalidez - Enfermedad Congénita </option>
                        </optgroup>
                        <optgroup label="Muerte">
                          <option value="Muerte - Pensión sobrevivientes convenios internacionales"> Pensión sobrevivientes convenios internacionales </option>
                          <option value="Muerte - Pensión sobrevivientes"> Pensión sobrevivientes </option>
                          <option value="Muerte - Pensión sobrevivientes - 2900"> Pensión sobrevivientes - 2900</option>
                          <option value="Muerte - Sustitución pensional"> Sustitución pensional </option>
                          <option value="Muerte - Sustitución provisional ley 1204/08"> Sustitución provisional ley 1204/08 </option>
                        </optgroup>
                        <optgroup label="Indemnización sustitutiva">
                          <option value="Indemnización sustitutiva - Indemnización vejez"> Indemnización vejez </option>
                          <option value="Indemnización sustitutiva - Indemnización invalidez"> Indemnización invalidez </option>
                          <option value="Indemnización sustitutiva - Indemnización sobrevivencia"> Indemnización sobrevivencia </option>
                        </optgroup>
                        <optgroup label="Auxilio funerario">
                          <option value="Auxilio funerario - Auxilio funerario"> Auxilio funerario </option>
                        </optgroup>
                        <optgroup label="Verificación Patrimonial">
                          <option value="Verificación Patrimonial - Verificación Patrimonial"> Verificación Patrimonial </option>
                        </optgroup>
                      </Form.Control>
                      <Form.Control.Feedback type='invalid'>Ingresa el tipo de riesgo</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  {/* <Form.Group>
                                    <Form.Label>
                                        Tipo de investigación
                                    </Form.Label>
                                    <div className='inputContainer'>
                                        <Form.Control
                                            name="tipo_investigacion"
                                            onChange={handleChange}
                                            required={true}
                                            as='select'
                                            type='text'
                                            readOnly={codigoFuncion === undefined}
                                            value={valuesInputs?.tipo_inv}
                                            disabled={!ele.editar}


                                        >
                                            <option value='' disabled>-- Seleccione --</option>
                                            {valuesInputs?.opciones_tipo_inv?.map((opt, idx)=> {
                                                return(
                                                    <option value={opt.valor} key={idx}>{opt?.nombre}</option>
                                                )
                                            })}

                                                                                            <option value='0'>Selección</option>
                                                <option value='1'>Convivencia</option>
                                                <option value='2'>Dependencia económica</option>
                                                <option value='3'>Escolaridad</option>
                                                <option value='4'>Validación documental</option>
                                                <option value='5'>Incremento de pensión por personas a cargo</option>
                                                <option value='6'>Conservación del derecho a pensión anticipada por hijo inválido</option>


                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>Ingresa el tipo de investigación</Form.Control.Feedback>
                                    </div>

                                    </Form.Group> */}
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.identificacion')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control
                        className='form-control'
                        value={valuesInputs?.tipo_identificacion}
                        disabled={!ele.editar}
                        onChange={handleChange} name='tipo_identificacion' id='typeIdSelect' as='select' custom required>
                        <option value="2" > {t('formulario.select.cedula_ciudadania')} </option>
                        <option value="3"> {t('formulario.select.cedula_extranjeria')} </option>
                        <option value="4"> {t('formulario.select.tarjeta_identidad')} </option>
                        <option value="9"> {t('formulario.select.registro_civil')} </option>
                        <option value="5"> {t('formulario.select.pasaporte')} </option>
                      </Form.Control>
                      <Form.Control
                        value={valuesInputs?.identificacion}
                        disabled={!ele.editar}
                        onChange={handleChange}
                        min='0'
                        max='9999999999'
                        name='identificacion' required type='number' placeholder={t('formulario.label.identificacion')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_numero_identificacion')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {t('formulario.label.radicado')}
                    </Form.Label>
                    <div className='inputContainer'>
                      <Form.Control onChange={handleChange}
                        disabled={!ele.editar}
                        name='radicado' required type='text' placeholder='Radicado' value={valuesInputs?.radicado} maxLength={20}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_radicado')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>

                </div>
                <div id='observacionesContainerEstudioBasico'>
                  <Form.Group id='observacionesFormGroup'>
                    <Form.Label className='observacionesLabel'>
                      {t('formulario.label.observaciones')}
                    </Form.Label>
                    <div className='textAreaContainer'>
                      <Form.Control
                        value={valuesInputs?.observaciones}
                        disabled={!ele.editar}
                        onChange={handleChange} name='observaciones' id='observacionesTextArea' maxLength={2000} required as='textarea' placeholder={t('formulario.label.observaciones')}></Form.Control>
                      <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_observaciones')}</Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
                {valuesInputs.hasOwnProperty('inf_adicional') &&
                  <div id='infComplementariaContainer'>
                    <Form.Group id='infComplementariaFormGroup'>
                      <Form.Label className='infComplementariaLabel'>
                        {t('formulario.label.informacion_complementaria')}
                      </Form.Label>
                      <div className='textAreaContainer'>
                        <Form.Control
                          value={valuesInputs?.inf_adicional}
                          disabled={!ele.editar}
                          onChange={handleChange} name='inf_adicional' id='observacionesTextArea' maxLength={2000} as='textarea' placeholder={t('formulario.label.informacion_complementaria')}></Form.Control>
                        <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_informacion_complementaria')}</Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                }

                {
                  ele.editar
                  &&
                  <div className='buttonContainerInformacionGeneral'>
                    <button className='confirmButton m-0'>{t('botones.guardar')}</button>
                    {/* <ModalConfirmacion typeModal='error' isOpen={showModal} setIsOpen={setShowModal} />                */}
                  </div>
                }
              </div>
            </Card.Body>
          </Card>

        }
        <br />
        {
          (ele?.contenido[1]?.visualizar || info?.tipo_investigacion === '4' || valuesInputs.tipo_inv === '4')
          && <Card className='shadow cardContainerSolicitud' style={{ borderTopLeftRadius: '10px' }}>
            <h5 className='titleInsideCard'> </h5>
            <Card.Body>
              <div className='formItem' id='contenedorInputsInfoDictamen'>
                <Form.Group className='formgroupInputsInfoDictamen' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}  >
                  <Form.Label>{t('formulario.label.junta')}</Form.Label>
                  <div className='inputContainer'>
                    <Form.Control
                      name="junta"
                      onChange={handleChange}
                      required={true}
                      as='select'
                      type='text'
                      value={valuesInputs?.junta}
                      disabled={!ele.editar}
                    >
                      <option value="" > {t('formulario.label.seleccione_opcion')} </option>
                      <option value="No Aplica"> No Aplica </option>
                      <option value="Junta Nacional de Calificación de Invalidez"> Junta Nacional de Calificación de Invalidez </option>
                      <option value="Junta Regional de Calificación de Invalidez de Antioquia"> Junta Regional de Calificación de Invalidez de Antioquia </option>
                      <option value="Junta Regional de Calificación de Invalidez de Atlántico"> Junta Regional de Calificación de Invalidez de Atlántico </option>
                      <option value="Junta Regional de Calificación de Invalidez de Bogotá y Cundinamarca"> Junta Regional de Calificación de Invalidez de Bogotá y Cundinamarca </option>
                      <option value="Junta Regional de Calificación de Invalidez de Bolívar"> Junta Regional de Calificación de Invalidez de Bolívar </option>
                      <option value="Junta Regional de Calificación de Invalidez de Boyaca"> Junta Regional de Calificación de Invalidez de Boyaca </option>
                      <option value="Junta Regional de Calificación de Invalidez de Caldas"> Junta Regional de Calificación de Invalidez de Caldas </option>
                      <option value="Junta Regional de Calificación de Invalidez de Cauca"> Junta Regional de Calificación de Invalidez de Cauca </option>
                      <option value="Junta Regional de Calificación de Invalidez de Cesar"> Junta Regional de Calificación de Invalidez de Cesar </option>
                      <option value="Junta Regional de Calificación de Invalidez de Huila"> Junta Regional de Calificación de Invalidez de Huila </option>
                      <option value="Junta Regional de Calificación de Invalidez de Magdalena"> Junta Regional de Calificación de Invalidez de Magdalena </option>
                      <option value="Junta Regional de Calificación de Invalidez de Meta"> Junta Regional de Calificación de Invalidez de Meta </option>
                      <option value="Junta Regional de Calificación de Invalidez de Nariño"> Junta Regional de Calificación de Invalidez de Nariño </option>
                      <option value="Junta Regional de Calificación de Invalidez de Norte de Santander"> Junta Regional de Calificación de Invalidez de Norte de Santander </option>
                      <option value="Junta Regional de Calificación de Invalidez de Quindío"> Junta Regional de Calificación de Invalidez de Quindío </option>
                      <option value="Junta Regional de Calificación de Invalidez de Risaralda"> Junta Regional de Calificación de Invalidez de Risaralda </option>
                      <option value="Junta Regional de Calificación de Invalidez de Santander"> Junta Regional de Calificación de Invalidez de Santander </option>
                      <option value="Junta Regional de Calificación de Invalidez de Sucre"> Junta Regional de Calificación de Invalidez de Sucre </option>
                      <option value="Junta Regional de Calificación de Invalidez de Tolima"> Junta Regional de Calificación de Invalidez de Tolima </option>
                      <option value="Junta Regional de Calificación de Invalidez Valle del Cauca"> Junta Regional de Calificación de Invalidez Valle del Cauca </option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>{t('formulario.error.ingresa_cuenta_asociada')}</Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className='formgroupInputsInfoDictamen' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start' }} >
                  <Form.Label style={{ marginLeft: '0px' }} >
                    {t('formulario.label.numero_dictamen')}
                  </Form.Label>
                  <div className='inputContainer'>
                    <Form.Control onChange={handleChange}
                      value={valuesInputs?.n_dictamen}
                      disabled={!ele.editar}
                      name='n_dictamen' required type='text' placeholder={t('formulario.label.numero_dictamen')}></Form.Control>
                    <Form.Control.Feedback type='invalid'>{t('formulario.error.campo_obligatorio')}</Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className='formgroupInputsInfoDictamen' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                  <Form.Label style={{ marginLeft: '0px' }} >
                    {t('formulario.label.fecha_dictamen')}
                  </Form.Label>
                  <div className='dateContainer'>
                    <div className='dateItem' id='dateInicio'>
                      <DatePicker
                        // value={valuesInputs?.fecha_dictamen}
                        // selectedValue={valuesInputs?.fecha_dictamen}
                        selected={startDate && startDate !== '' ? moment(startDate).toDate() : null}
                        onChange={d => {
                          setStartDate(moment(d).format('YYYY-MM-DD'))
                          handleChange(d)
                        }}
                        customInput={<ExampleCustomInput />}
                        name="fecha_dictamen"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>

                </Form.Group>
              </div>

            </Card.Body>
          </Card>
        }
      </Form>

      <GenericModal typeModal={tipoModal} setIsOpen={setModal} isOpen={modal} mensaje={mensajeModal} codigoSuccess={codigoSucces} />


    </Fragment>

  )
}
