import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import OpcionesCiudades from '../../../../../helpers/OpcionesCiudades';
import { validarEntorno } from '../../../../../helpers/validarHost';
import NotificationModal from '../../../../modales/NotificationModal';
import { useTranslation } from 'react-i18next';

const DatosGenerales = ({ dataG, permisos }) => {

  const { t } = useTranslation();

  const [data, setData] = useState({
    fecha_inicial: dataG ? dataG.fecha_inicial : '',
    fecha_limite: dataG ? dataG.fecha_limite : '',
    fecha_finalizacion: dataG ? dataG.fecha_finalizacion : '',
    nombre_empresa: dataG ? dataG.nombre_empresa : '',
    direccion: dataG ? dataG.direccion : '',
    nit: dataG ? dataG.nit : '',
    pagina_web: dataG ? dataG.pagina_web : '',
    representante_legal: dataG ? dataG.representante_legal : '',
    sede_visitada: dataG ? dataG.sede_visitada : '',
    ubicacion: dataG ? dataG.ubicacion : '',
    auditores: dataG ? dataG.auditores : '',
    acompanantes: dataG ? dataG.acompanantes : '',
    telefono: dataG ? dataG.telefono : '',
    tipo_proceso: dataG ? dataG.tipo_proceso : '',
    responsable: dataG ? dataG.responsable : [],
    is_edit: dataG ? dataG.is_edit : ''
  });

  const [showModalNotification, setShowModalNotification] = useState(false);

  const { id } = useParams();

  const handleOpenModalNotification = () => setShowModalNotification(true);
  const handleCloseModalNotification = () => setShowModalNotification(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });

    // console.log(data)
  }

  const getResponsables = (value) => {

    if (value && Object.keys(value).length > 0) {

      setData({
        ...data,
        responsable: [value]
      });
    } else {
      setData({
        ...data,
        responsable: []
      });
    }
  }


  const sendDataForm = async (e) => {

    e.preventDefault();

    const result = {};

    console.log(data);

    const dataSend = {
      formData: data,
      id_registro: id,
      // id_cuestionario: adk,
      hash: window.location.hash
    };

    console.log(dataSend)

    const response = await validarEntorno('JSON/respuesta_cuestionario_json.html', 'POST', dataSend);

    if (response.ok) {
      // eslint-disable-next-line no-use-before-define
      const respuesta = await response.json();;

      if (respuesta.data.message) {
        setShowModalNotification(true);
      }
    }
  }

  return (
    <div className="container-audit-form">
      <h4 style={{ textAlign: 'start' }} className="pagesTitles">{t('gestion_realizada.introduccion_informe.datos_generales')}</h4>
      <Form className="audit-form" onSubmit={sendDataForm}>
        <Form.Row>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaEmail">
            <Form.Label className="">{t('formulario.label.fecha_inicial')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.fecha_inicial')}
              name="fecha_inicial"
              type="date"
              onChange={handleChange}
              defaultValue={data.fecha_inicial}
              disabled={data.is_edit}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaEmail">
            <Form.Label className="">{t('formulario.label.fecha_limite')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.fecha_limite')}
              name="fecha_limite"
              type="date"
              onChange={handleChange}
              defaultValue={data.fecha_limite}
              disabled={data.is_edit}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaEmail">
            <Form.Label className="">{t('formulario.label.fecha_finalizacion')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.fecha_finalizacion')}
              name="fecha_finalizacion"
              type="date"
              onChange={handleChange}
              defaultValue={data.fecha_finalizacion}
              disabled={data.is_edit}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaNumber">
            <Form.Label>
              {t('formulario.label.tipo_proceso')}
            </Form.Label>
            <Form.Control
              name='tipo_proceso'
              required
              as='select'
              onChange={handleChange}
              defaultValue={data.tipo_proceso}
              disabled={data.is_edit}
            >
              <option selected disabled value=''>{t('formulario.label.seleccione')}</option>
              <option value="1">{t('formulario.select.administrativo')}</option>
              <option value="2">{t('formulario.select.comercial')}</option>
              <option value="3">{t('formulario.select.financiero')}</option>
              <option value="4">{t('formulario.select.logistico')}</option>
              <option value="5">{t('formulario.select.operativo')}</option>
            </Form.Control>
            <Form.Control.Feedback type='invalid'>{t('formulario.label.seleccion_tipo_proceso')}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
            <Form.Label className="">{t('formulario.label.nombre_empresa')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.nombre_empresa')}
              name="nombre_empresa"
              onChange={handleChange}
              defaultValue={data.nombre_empresa}
              disabled={data.is_edit}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaNumber">
            <Form.Label className="">{t('formulario.label.responsable')}</Form.Label>
            <Select
              // id='selectPersonalGerencia'
              // ref={reg}
              // classNamePrefix="addl-class"
              options={dataG.responsables_opcion}
              // name={`${nombre}.${item.nombre}`}
              defaultValue={data.responsable}
              // value={item.valores_opcion.find(c => c.value === value)}
              onChange={getResponsables}
              //onChange={(e) => {getResponsables(e)}}
              closeMenuOnSelect={false}
              // isMulti= {true}
              placeholder={t('formulario.label.seleccione')}
              isClearable={true}
            // isDisabled={esEditable}
            />
          </Form.Group>
        </Form.Row>
        <hr />
        <Form.Row>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
            <Form.Label className="">{t('formulario.label.direccion')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.direccion')}
              name="direccion"
              onChange={handleChange}
              defaultValue={data.direccion}
              disabled={data.is_edit}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
            <Form.Label className="">{t('formulario.label.nit')}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t('formulario.label.nit')}
              name="nit"
              onChange={handleChange}
              defaultValue={data.nit}
              disabled={data.is_edit}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
            <Form.Label className="">{t('formulario.label.pagina_web')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.pagina_web')}
              name="pagina_web"
              onChange={handleChange}
              defaultValue={data.pagina_web}
              disabled={data.is_edit}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
            <Form.Label className="">{t('formulario.label.representante_legal')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.representante_legal')}
              name="representante_legal"
              onChange={handleChange}
              defaultValue={data.representante_legal}
              disabled={data.is_edit}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} role="form" controlId="formGridText">
            <Form.Label className="">{t('formulario.label.sede_visitada')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.sede_visitada')}
              name="sede_visitada"
              onChange={handleChange}
              defaultValue={data.sede_visitada}
              disabled={data.is_edit}
            />
          </Form.Group>
          <OpcionesCiudades onChange={handleChange} valor={data.ubicacion} />
          <Form.Group as={Col} lg={6} role="form" controlId="formGridTextAreaNumber">
            <Form.Label className="">{t('formulario.label.telefono')}</Form.Label>
            <Form.Control
              placeholder={t('formulario.label.telefono')}
              name="telefono"
              type="text"
              // maxLength="10"
              onChange={handleChange}
              defaultValue={data.telefono}
              disabled={data.is_edit}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row className="btn-form-row">
        {permisos?.editar!==false && !data.is_edit && <Button className="confirmButton" type="submit">{t('formulario.actions.enviar')}</Button>}
        </Form.Row>
      </Form>
      <NotificationModal
        isOpen={showModalNotification}
        setIsOpen={handleCloseModalNotification}
        typeModal='saveQuestions'
      />
    </div>
  )
}

export default DatosGenerales;
