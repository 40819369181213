import React from 'react';
import { useRef, useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import './styles/controles.scss';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Slider from '@material-ui/core/Slider';
import { PieChart } from '@material-ui/icons';
import {useTranslation} from "react-i18next";

const Encuesta = ({ item, handleInputChange, index, idx, reg, nombre, esEditable }) => {

  const {t} = useTranslation(); // Hook para traducir textos
  const valoresIniciales = item.preguntas.map((cr) => { return cr.valor; }); //Mapea los valores iniciales de las preguntas de la encuesta ejemplo:[1,5,3,2,4]
  const values = useRef(valoresIniciales); // Referencia a los valores iniciales de las preguntas de la encuesta
  const [progress, setProgress] = useState(0); // Progreso de la encuesta en una escala de 0 a 100
  const maxSumValue = item.preguntas.map((cr) => { return cr.peso; }).reduce((a, b) => a + b, 0); //Suma de los pesos de las preguntas de la encuesta

  /**
   * Dependiendo del valor que se tenga en la encuesta
   * genera un color de avance y retroceso
   * @returns
   */

  const getColor = () => {
    let color = '#000'
    const current = Math.round(progress);
    if (current >= 1 && current <= 40) {
      color = 'danger';
    } else if (current >= 41 && current <= 80) {
      color = 'warning';
    } else if (current >= 81) {
      color = 'success';
    }
    return color;
  }

  /**
   * Actualiza el valor de la encuesta en el arreglo de valores
   * y calcula el progreso de la encuesta
   * @param {*} e Evento
   * @param {*} value Valor seleccionado
   * @param {*} index Indice del valor seleccionado
   */

  const getPollValue = (e, value, index) => {
    if (values.current.length > 0) {
      values.current[index] = value;
    }
    calculateProgress();
  }

  /**
   * Actualiza la barra de progreso de la encuesta en una escala de 0 a 100 con base en los valores seleccionados y el peso de cada criterio
   */

  const calculateProgress = () => {
    let sum = 0;
    for (let i = 0; i < values.current.length; i++) {
      const valorAjustado = (values.current[i] - 1) / (item.preguntas[i].opciones.length - 1) // Ajusta el valor al rango
      sum += valorAjustado * item.preguntas[i].peso;
    }
    const porcentajePonderado = ((sum / maxSumValue) * 100);
    setProgress(porcentajePonderado);
  }

  /**
   * Calcula el progreso de la encuesta al cargar el componente
   */

  useEffect(() => {
    calculateProgress();
  }, []);

  /**
   * Devuelve los valores de la encuesta en un arreglo
   * @returns Arreglo de valores de la encuesta
   * @example [1,5,3,2,4]
   */

  const getValues = () => {
    const arrayValues = [];
    for(let i = 0; i < values.current.length; i++){
      arrayValues.push(values.current[i]);
    }
    return arrayValues;
  }

  return (
    <>
      {(item.preguntas && item.preguntas.length > 0) && (item.preguntas.map((cr, index) =>
      (
        <>
          <Form.Group className='contenedor-pregunta-encuesta' key={index} >
            <Form.Label className='contenedor-encabezado-encuesta'>
              <div>{cr.titulo}</div>
              <div className='contenedor-peso-pregunta'>
                <PieChart />
                <span>{t('modales.peso')}: </span>
                {cr.peso}
              </div>
            </Form.Label>
            {cr?.opciones && cr?.opciones.length > 0 &&
              <Slider
                defaultValue={cr.valor}
                aria-label="Valor asignado"
                valueLabelDisplay="on"
                valueLabelFormat={(x) => cr?.opciones[x - 1].nombre}
                step={1}
                min={1}
                marks={cr.opciones.map((cr) => {
                  return { value: cr.valor, label: cr.valor }
                })}
                max={cr.opciones.length}
                onChangeCommitted={(e, value) => getPollValue(e, value, index)}
              />
            }
          </Form.Group>
        </>
      )
      ))}
      <div className='contenedor-progreso-encuesta'>
        <ProgressBar>
          <ProgressBar striped variant={getColor()} now={progress} animated />
        </ProgressBar>
        <span>{`${Math.round(progress)}%`}</span>
      </div>
      <input type={"hidden"} name={`asesoria.${index}.${item.nombre}`} value={getValues()} ref={reg} />
    </>
  );
}

export default Encuesta;
