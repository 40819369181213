import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from "react";
import './styles/controles.scss';
import TablaRegistros from "../../components/GestionadorDinamico/TablaDinamico";
import { useParams } from "react-router-dom";
import { validarEntorno } from "../validarHost";
import NotificationModal from '../../components/modales/NotificationModal';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from "react-i18next";
import { BtnAyuda } from "./BtnAyuda";

const DropzoneFiles = ({ privateSeccion, item, handleInputChange, index, idx, reg, nombre, esEditable, files, getEstructura, lastIndex, idGestion, esMultiple }) => {

  const [data, setData] = useState(item?.valor ? item?.valor : []);
  const [newFiles, setNewFiles] = useState([]);
  const [sendActive, setSendActive] = useState(false);
  const [statusNewTable, setStatusNewTable] = useState('');
  const { seccion, id } = useParams();
  const HASH = window.location.hash;
  const { t } = useTranslation();


  // useEffect(() => {
  //   console.log(data, 'this is data')
  // }, [])


  const [modal, setModal] = useState({
    show: false,
    message: '',
    type: ''
  });

  const setHideModal = () => {
    setModal({
      show: false,
      message: '',
      type: ''
    })
  }

  const handleImageData = () => {

    const files = document.getElementById(`input-files-${item.nombre}-${index}`).files;
    //Tomar los archivos de newFiles y agregarles los nuevos archivos, evitando duplicados
    if (files.length > 0) {
      setNewFiles(prevFiles => {
        const allFiles = [...prevFiles, ...files];
        const uniqueFiles = allFiles.filter((file, index) => {
          return index === allFiles.findIndex(f => f.name === file.name);
        });
        sendData(uniqueFiles); // Llama a sendData con los archivos únicos
        return uniqueFiles;
      });
    }
  }

  const deleteFileTable = async (item) => {

    if (item.enlace) {
      const res = await deleteFile(item);
      if (res.typeResponse === 'solicitud_OK') {
        setModal({
          ...modal,
          show: true,
          message: res.message,
          type: 'envio_peticion_correcto'
        });
        deleteFileArray(item);
      } else if (res.typeResponse === 'error_aplicacion') {
        setModal({
          ...modal,
          show: true,
          message: res.message,
          type: 'envio_peticion_erroneo'
        });
      } else if (res.typeResponse === 'error_servidor') {
        setModal({
          ...modal,
          show: true,
          message: 'Un error ha ocurrido, intenta de nuevo',
          type: 'envio_peticion_erroneo'
        });
      }
    } else {
      deleteFileArray(item);
    }
  }

  const deleteFileArray = (item) => {
    if (item.enlace) {
      setData(data.filter((dt) => dt.name !== item.name));
    } else {
      setNewFiles(newFiles.filter((nf) => nf.name !== item.name));
    }
  }

  const deleteFile = async (itm) => {

    const dataReq = {
      name_file: itm.enlace ? itm.enlace : itm.name,
      nombre: item.nombre,
      id_gestion: item.idGestion ? item.idGestion : item.id_gestion ? item.id_gestion : idGestion,
      seccion,
      id,
      HASH
    }

    // console.log(dataReq);

    const req = await validarEntorno('JSON/GestionDinamica/del_files_table.html', 'POST', dataReq);

    let typeResponse = '';
    let message = '';

    if (req.ok) {
      const res = await req.json();
      if (res.estado === 'OK') {
        typeResponse = 'solicitud_OK';
      } else {
        typeResponse = 'error_aplicacion';
      }
      message = res.mensaje;
    } else {
      typeResponse = 'error_servidor';
    }

    const objResponse = {
      typeResponse,
      message
    }

    return objResponse;
  }

  const sendData = async (filesToSend = newFiles) => {
    if (filesToSend.length > 0) {
      setSendActive(true)

      let formData = new FormData();


      formData.append('hash', HASH);
      formData.append('id', id);
      formData.append('seccion', item.seccion ? item.seccion : seccion);
      formData.append('nombre', item.nombre);
      formData.append('id_gestion', item.idGestion ? item.idGestion : item.id_gestion ? item.id_gestion : idGestion);
      formData.append('tipo', 'form_dinamico');

      let counter = 0;
      filesToSend.forEach((item) => {
        if (item.size > 0) {
          formData.append(`file[${counter}]`, item)
          counter++;
        }
      });

      // console.log(item.idGestion)

      //JSON/GestionDinamica/enviar_archivos.html
      const url = 'JSON/GestionDinamica/enviar_archivos.html';


      await fetch(url, {
        method: 'POST',
        body: formData,
        processData: false,
        contentType: false,
      })
        .then(response => response.json())
        .then(res => {

          if (res.estado === "OK") {
            setModal({
              ...modal,
              show: true,
              message: res.message,
              type: 'envio_peticion_correcto'
            });
            setStatusNewTable(res.tipo);
            setNewFiles(prevFiles => {
              let newFiles = [...prevFiles];
              res.data.forEach(item => {
                const fileIndex = newFiles.findIndex(file => file.name === item.archivo);
                const newFile = { ...item, name: item.archivo };
                if (fileIndex !== -1) {
                  newFiles[fileIndex] = newFile;
                } else {
                  newFiles.push(newFile);
                }
              });
              return newFiles;
            });
          } else {
            setModal({
              ...modal,
              show: true,
              message: res.message,
              type: 'envio_peticion_erroneo'
            });
          }
          setSendActive(false)
        })
        .catch(error => {
          console.error(error);
          setModal({
            ...modal,
            show: true,
            message: 'Un error ha ocurrido, intenta de nuevo',
            type: 'envio_peticion_erroneo'
          });
        });
    } else {
      setModal({
        ...modal,
        show: true,
        message: 'No se ha enviado ningun archivo',
        type: 'envio_peticion_alerta'
      });
    }

  }

  return (
    <>
      {esMultiple ? (
        idGestion !== '' ? (
          <Form.Group style={{ display: 'block' }}>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
              <Form.Label>{item.titulo}</Form.Label>
              {item.ayuda &&
                <BtnAyuda ayuda={item.ayuda} />
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {(!esEditable) ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <>
                      <Form.Group style={{ display: 'block', marginLeft: '1em' }}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '100%' }}>
                            <label className="custom-file-upload">
                              <input id={`input-files-${item.nombre}-${index}`} type="file" name={`archivo`} onChange={(e) => handleImageData(e)} multiple />
                              <CloudUploadIcon style={{ fontSize: 50 }} />
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </>
                  </div>
                  <div>
                  </div>
                </div>
              ) : null}
              <div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="containerButton" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button disabled={sendActive} style={{ width: 300 }} onClick={sendData} className="confirmButton">{`${t('botones.clic_guardar_archivos')}`}</Button>
                </div>
                <TablaRegistros data={data} setData={setData} deleteFile={deleteFileTable} newFiles={newFiles} statusNewTable={statusNewTable} disabled={sendActive} onClick={sendData} />
              </div>
            </div>
            {/* <div className="containerButton" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button disabled={sendActive} style={{ width: 400 }} onClick={sendData} className="confirmButton">{`Enviar ${item.titulo}`}</Button>
            </div> */}
            <hr />
          </Form.Group>
        ) : null) :
        (
          <Form.Group style={{ display: 'block' }}>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
              <Form.Label>{item.titulo}</Form.Label>
              {item.ayuda &&
                <BtnAyuda ayuda={item.ayuda} />
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {(!esEditable) ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <>
                      <Form.Group style={{ display: 'block', marginLeft: '1em' }}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: '100%' }}>
                            <label className="custom-file-upload">
                              <input id={`input-files-${item.nombre}-${index}`} type="file" name={`archivo`} onChange={(e) => handleImageData(e)} multiple />
                              <CloudUploadIcon style={{ fontSize: 50 }} />
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </>
                  </div>
                  <div>
                  </div>
                </div>
              ) : null}
              <div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="containerButton" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button disabled={sendActive} style={{ width: 300 }} onClick={sendData} className="confirmButton">{`${t('botones.clic_guardar_archivos')}`}</Button>
                </div>
                <TablaRegistros data={data} setData={setData} deleteFile={deleteFileTable} newFiles={newFiles} statusNewTable={statusNewTable} />
              </div>
            </div>

            <hr />
          </Form.Group>
        )
      }

      <NotificationModal
        typeModal={modal.type}
        mensaje={modal.message}
        isOpen={modal.show}
        setIsOpen={setHideModal}
      />
    </>
  );

}

export default DropzoneFiles;