import { Form } from "react-bootstrap";

const Input = ({ item, handleInputChange, index, idx, reg, nombre, error, handleButtonEstado, esEditable }) => {

    return(
        <Form.Group style={{ display: 'block' }}>
            <br />
            <button
                disabled={item.valor}
                onClick={() => {handleButtonEstado(index, idx)}}
                type="button"
                style={{width: '100%', opacity: !item.valor ?  1 : 0.25, height: '35px'}}
                className="confirmButton btn btn-primary"
            >
                {item.titulo}
            </button>
            <input type={"hidden"} name={`${nombre}.${item.nombre}`} value={item.valor} ref={reg} />
        </Form.Group>
    );

}

export default Input;