import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';

const PopoverStickOnHover = ({ component, children, placement, onMouseEnter, delay }) => {
  const [showPopover, setShowPopover] = useState(false);
  const setTimeoutConst = useRef();
  const childRef = useRef();

  const handleMouseEnter = () => {
    setTimeoutConst.current = setTimeout(() => {
      setShowPopover(true);
      if (onMouseEnter) {
        onMouseEnter();
      }
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst.current);
    setShowPopover(false);
  };

  useEffect(() => {
    return () => {
      if (setTimeoutConst.current) {
        clearTimeout(setTimeoutConst.current);
      }
    };
  }, []);

  const child = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      ref: (node) => {
        childRef.current = node;
        const { ref } = child;
        if (typeof ref === 'function') {
          ref(node);
        }
      },
    })
  )[0];

  return (
    <React.Fragment>
      {child}
      <Overlay
        show={showPopover}
        placement={placement}
        target={childRef.current}
        shouldUpdatePosition={true}
      >
        <Popover
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={handleMouseLeave}
          id="popover"
        >
          {component}
        </Popover>
      </Overlay>
    </React.Fragment>
  );
};

PopoverStickOnHover.defaultProps = {
  delay: 0,
};

PopoverStickOnHover.propTypes = {
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  children: PropTypes.element.isRequired,
  component: PropTypes.node.isRequired,
};

export default PopoverStickOnHover;