import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';


import { CierreInforme } from './pestañasInformePanorama/CierreInforme';
import { DescripcionFisicaGeografica } from './pestañasInformePanorama/DescripcionFisicaGeografica';
import { Soluciones } from './pestañasInformePanorama/Soluciones';
import { InformacionGeneral } from './pestañasInformePanorama/InformacionGeneral';
import { Causas } from './pestañasInformePanorama/Causas';
import { Factores } from './pestañasInformePanorama/Factores'
import './pestañasInformePanorama/styles.css'
import './styles/InformePanoramaRiesgos.scss'
import { validarEntorno } from '../../../helpers/validarHost';

import { HelpSVG } from '../../icons/HelpSVG'
import { WarningModalSVG } from '../../icons/WarningModalSVG';
import SuccessIcon from '../../../helpers/SuccessIcon/SuccessIcon';
import { ErrorSVG } from '../../icons/ErrorSVG';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ModalDocumentos from '../../modales/ModalDocumentos';
import { useTranslation } from 'react-i18next';

export const GestorPanoramaRiesgos = ({ titulo }, props) => {
  const { t } = useTranslation();
  const [initialState, setInitialState] = useState({
    loading: true,
    error: null,
    data: {}
  })


  const [documentModal, setDocumentModal] = useState({
    show: false,
    titulo: ''
  });

  const { id } = useParams()


  const [estadoRegistro, setEstadoRegistro] = useState('')
  const [dataSolicitud, setdataSolicitud] = useState({
    info: {}
  })

  const [pestaña, setPestaña] = useState('Descripción Física y Geográfica')
  const [dataPanorama, setDataPanorama] = useState({});

  const hash = window.location.hash;

  const renderFunction = () => {
    switch (pestaña) {
      case 'Descripción Física y Geográfica':
        return <DescripcionFisicaGeografica pestana='descripcion_fisica' />
      case 'factores':
        return <Factores pestana='factores' />
      case 'analisis':
        return <Causas pestana='analisis' />
      // case 'soluciones':
      //     return <Soluciones />
      case 'cierreInforme':
        return <CierreInforme pestana='cierre' />
      default:
        break;
    }
  }



  const getInforme = async () => {
    const dataReq = {
      hash: window.location.hash,
      id: id
    };

    //console.log(dataReq);

    const req = await validarEntorno('JSON/panorama_riesgos.html', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json()
      // //console.log(res)
      setInitialState({
        ...initialState,
        data: res
      })

      setEstadoRegistro(res.informacion_solicitud.estado.valor)

      //console.log(initialState.data);
    }
  }

  const setOpenDocumentModal = () => {
    setDocumentModal({
      show: true,
      titulo: 'Esto es un titulo de prueba'
    })
  }

  const setCloseDocumentModal = () => {
    setDocumentModal({
      show: false,
      titulo: ''
    });
  }

  useEffect(() => {
    getInforme()
  }, [])


  return (
    <Fragment>
      <ModalDocumentos isOpen={documentModal?.show} setIsOpen={setCloseDocumentModal} data={initialState.data.panoramas} graficas={initialState.data.graficas} />
      <p>{titulo}</p>
      <Card className='card__box__surface'>
        <Card.Body>
          <Row style={{ display: 'flex', justifyContent: 'center' }} >

            <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
              {initialState?.data?.informacion_solicitud?.estado?.valor
                ?
                <>
                  <div>
                    {estadoRegistro === 'aprobado'
                      ? <div>
                        <SuccessIcon />
                        <h3>{`${estadoRegistro}`}</h3>
                      </div>
                      : estadoRegistro === 'denegado'
                        ? <div>
                          <ErrorSVG />
                          <h3>{`${estadoRegistro}`}</h3>
                        </div>
                        : estadoRegistro === '  de aprobacion' || ''
                          ? <div>
                            <WarningModalSVG />
                            <h3>{`${estadoRegistro}`}</h3>
                          </div>
                          : <div>
                            <WarningModalSVG />
                            <h3>Verificar estado </h3>
                          </div>
                    }
                  </div>
                  {initialState?.data?.informacion_solicitud?.generarPDF?.visualizar === true &&
                    <>
                      <br />
                      <div className='col-12 buttonGenerarPDF'>
                        <button className='confirmButton' onClick={() => setOpenDocumentModal()}>
                          {t('botones.generar_pdf')}
                          <GetAppIcon />
                        </button>
                        {/* <button className='confirmButton'>
                                    Generar PDF
                                    <GetAppIcon/>
                                </button> */}
                      </div>
                    </>
                  }
                </>

                : null
              }
            </Col>
            <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
              {initialState?.data?.informacion_solicitud?.estado.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p className='labelInfo'>{t('formulario.label.estado')}:</p>
                  <p className='labelInfoContent'>{initialState?.data?.informacion_solicitud.estado.valor}</p>
                </div>
              }
              {initialState?.data?.informacion_solicitud?.fecha_solicitud.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p className='labelInfo'>{initialState?.data.informacion_solicitud.fecha_solicitud.nombre}</p>
                  <p className='labelInfoContent'>{initialState?.data.informacion_solicitud.fecha_solicitud.valor}</p>
                </div>

              }
              {initialState?.data?.informacion_solicitud?.perfil.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.perfil.tipo}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.perfil.nombre}
                  </p>
                </div>
              }
              {initialState?.data?.informacion_solicitud?.analista.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.analista.nombre}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.analista.valor}
                  </p>
                </div>
              }
              <div className='infoBasicaEditarRegistro'>
                <p className='labelInfo'>{t('formulario.label.tipo_solicitud')}</p>
                <p className='labelInfoContent'>{initialState?.data?.informacion_solicitud?.tipo_solicitud?.valor}</p>
              </div>
              {initialState?.data?.informacion_solicitud?.fecha_finalizacion?.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p className='labelInfo'>Fecha de finalización</p>
                  <p className='labelInfoContent'>{initialState?.data?.informacion_solicitud?.fecha_finalizacion?.valor}</p>
                </div>
              }
            </Col>
            <Col xs={6} lg={4} md={3} className='listPopUp mt-3' >
              {initialState?.data?.informacion_solicitud?.identificador.visualizar === true
                ?
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.identificador.tipo}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.identificador.valor}
                  </p>
                </div>
                : null
              }
              <div className='infoBasicaEditarRegistro'>
                <p>ID</p>
                <p className='labelInfoContent'>
                  {id}
                </p>
              </div>
              {initialState?.data?.informacion_solicitud?.estado_cos.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>Estado-cos</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.estado_cos.valor}
                  </p>
                </div>
              }
              {initialState?.data?.informacion_solicitud?.fecha_cosinte.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.fecha_cosinte.nombre}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.fecha_cosinte.valor}
                  </p>
                </div>
              }
              {initialState?.data?.informacion_solicitud?.fecha_limite.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>Fecha límite</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.fecha_limite.valor}
                  </p>
                </div>
              }
              <div className='infoBasicaEditarRegistro'>
                <p>{initialState?.data?.informacion_solicitud?.tipo_proceso.nombre}</p>
                <p className='labelInfoContent'>
                  {initialState?.data?.informacion_solicitud?.tipo_proceso.valor}
                </p>
              </div>
              {initialState?.data?.informacion_solicitud?.responsable.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.responsable.nombre}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.responsable.valor}
                  </p>
                </div>
              }
              {initialState?.data?.informacion_solicitud?.acompanante.visualizar === true &&
                <div className='infoBasicaEditarRegistro'>
                  <p>{initialState?.data?.informacion_solicitud?.acompanante.nombre}</p>
                  <p className='labelInfoContent'>
                    {initialState?.data?.informacion_solicitud?.acompanante.valor}
                  </p>
                </div>
              }
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <div className="card__box__surface containerPestañas p-3 mb-3 card d-flex flex-row gap-3" id='pestanasGestor'>
        {initialState?.data?.permisos?.descripcion_fisica &&
          <div
            className="   pestanaGestorPanorama "
            onClick={() => setPestaña('Descripción Física y Geográfica')}
            style={{
              background: pestaña !== 'Descripción Física y Geográfica' && 'rgb(203, 215, 230)',
              color: pestaña !== 'Descripción Física y Geográfica' && '#213265'

            }}
          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.descripcion_fisica_geografica')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>
        }
        {initialState?.data?.permisos?.factores &&
          <div
            className="  pestanaGestorPanorama"
            onClick={() => setPestaña('factores')}
            style={{
              background: pestaña !== 'factores' && 'rgb(203, 215, 230)',
              color: pestaña !== 'factores' && '#213265'
            }}

          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.factores')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>
        }
        {initialState?.data?.permisos?.analisis &&
          <div
            className="d-flex  pestanaGestorPanorama"
            onClick={() => setPestaña('analisis')}
            style={{
              background: pestaña !== 'analisis' && 'rgb(203, 215, 230)',
              color: pestaña !== 'analisis' && '#213265'

            }}

          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.analisis')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>
        }
        {/* <div
                    className="d-flex pestañasLi"
                    onClick={() => setPestaña('soluciones')}
                    style={{ background: pestaña !== 'soluciones' && 'rgb(203, 215, 230)' }}

                >
                    <p className=" p-0 m-0"> Soluciones </p>
                    <b style={{
                        width: '25px',
                        fontSize: '30px',
                        marginTop: '-20px',
                        fontWeight: '900',
                        color: "#00A20B"
                    }} >.</b>
                </div> */}
        {initialState?.data?.permisos?.cierre_informe &&

          <div
            className="d-flex  pestanaGestorPanorama"
            onClick={() => setPestaña('cierreInforme')}
            style={{
              background: pestaña !== 'cierreInforme' && 'rgb(203, 215, 230)',
              color: pestaña !== 'cierreInforme' && '#213265'

            }}

          >
            <p className=" p-0 m-0  "> {t('panorama_riesgos.cierre_informe')} </p>
            <b style={{
              width: '25px',
              fontSize: '30px',
              marginTop: '-20px',
              fontWeight: '900',
              color: "#00A20B"
            }} >.</b>
          </div>

        }

        {/* <div
                    className="d-flex pestañasLi"
                    onClick={() => setPestaña('Información general')}
                    style={{ background: pestaña !== 'Información general' && 'rgb(203, 215, 230)' }}

                >
                    <p className=" p-0 m-0"> Información general </p>
                    <b style={{
                        width: '25px',
                        fontSize: '30px',
                        marginTop: '-20px',
                        fontWeight: '900',
                        color: "#00A20B"
                    }} >.</b>
                </div> */}
      </div>
      <Card className='card__box__surface'>
        <Card.Body >
          {renderFunction()}
        </Card.Body>
      </Card>
    </Fragment>
  )
}
